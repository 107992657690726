import React, { useRef } from 'react';
import moment from 'moment';
import { Modal, Button } from 'antd';
import ReactToPrint from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import { ItemOilTicket } from './item-oil-ticket';
import { MultipleOilSupplyTicket } from './multiple-oil-supply-ticket';

const MultipleOilTicketDetail = ({ open, data, onClose, companyInfo }) => {
  const multipleOilTicketRef = useRef();

  const handleOk = () => {
    onClose && onClose();
  };

  const handleCancel = () => {
    onClose && onClose();
  };

  const onHandleReportStatus = async () => {
    await handleCancel();
  };

  const pageStyle = `
  @page {
     size: A4;
     margin: 5%;
     padding: 0 0 10%;
     @bottom-right {
      content: counter(page);
     }
  }
`;

  const footer = (
    <div onClick={onHandleReportStatus}>
      <ReactToPrint
        trigger={() => (
          <Button type='primary' icon={<PrinterOutlined />}>
            In phiếu
          </Button>
        )}
        content={() => multipleOilTicketRef.current}
        documentTitle={`Phieu_cap_dau_${moment().format('DD-MM-YYYY')}`}
        pageStyle={pageStyle}
      />
    </div>
  );

  return (
    <>
      <Modal
        title='Danh sách phiếu cấp dầu đã chọn'
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        width='auto'
        footer={footer}
      >
        {data?.map((item) => (
          <div key={item?.id}>
            <ItemOilTicket itemData={item} />
          </div>
        ))}
      </Modal>
      <div style={{ display: 'none' }}>
        <MultipleOilSupplyTicket data={data} ref={multipleOilTicketRef} companyInfo={companyInfo} />
      </div>
    </>
  );
};

export { MultipleOilTicketDetail };
