export const LIST_COLUMNS_TITLE_FIRST_DETAIL_SHEET = [
  '',
  'STT',
  'Loại Hình',
  'Số Cont',
  'Hóa Đơn',
  '',
  '',
  'Nội Dung Phí',
  'Số Tiền',
  '',
  '',
  '',
];

export const LIST_COLUMNS_TITLE_SECOND_DETAIL_SHEET = [
  '',
  '',
  '',
  '',
  'Số HĐ',
  'Ngày',
  'Nhà Cung Cấp',
  '',
  'Chưa Thuế',
  'Tiền Thuế',
  'Cộng',
];

export const MERGE_TWO_ROWS_TITLE_DETAIL_SHEET = ['B', 'C', 'D', 'H'];

export const MERGE_TWO_COLUMNS_TITLE_DETAIL_SHEET = [
  {
    start: 'E',
    end: 'G',
  },
  {
    start: 'I',
    end: 'K',
  },
];

export const BORDER_CELL_TITLE_DETAIL_SHEET = ['E', 'F', 'G', 'I', 'J', 'K'];

export const COLUMNS_BORDER_LIST_DETAIL_SHEET = ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];

export const LIST_COLUMN_SUM_DETAIL_SHEET = ['I', 'J', 'K'];
