import React, { useState } from 'react';
import { Tabs } from 'antd';
import { CusItemUpdateForm } from './cus-update-item-form';

export const ShowContractCreated = ({
  dataDetail,
  detailInfo,
  contTypeList,
  handleDeleteContractCus,
  getDetailByCode,
}) => {
  const [keyTab, setKeyTab] = useState('1');

  const onChangeTabs = (key) => {
    setKeyTab(key);
  };

  const renderItemTab = (item, index) => {
    return {
      key: `${index + 1}`,
      label: item?.customerName || '',
      children: (
        <CusItemUpdateForm
          dataItem={item}
          contTypeList={contTypeList}
          handleDeleteContractCus={handleDeleteContractCus}
          detailInfo={detailInfo}
          getDetailByCode={getDetailByCode}
          keyTab={keyTab}
        />
      ),
    };
  };

  return (
    <div
      style={{
        marginTop: '-30px',
      }}
    >
      <h3>DANH SÁCH HỢP ĐỒNG ĐÃ TẠO:</h3>
      <Tabs defaultActiveKey='1' items={dataDetail?.map(renderItemTab)} onChange={onChangeTabs} />
    </div>
  );
};
