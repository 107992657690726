import { DatePicker } from 'antd';
import { useDispatch } from 'react-redux';
import { DATE_FORMAT } from '~commons/constants';
import moment from 'moment';
const { RangePicker } = DatePicker;

export const RangeDateFilter = ({
  query,
  updateQuery,
  onChange,
  allowClear = true,
  mgLeft = true,
}) => {
  const dispatch = useDispatch();
  const setRangeDate = (value) => {
    const tmpQuery = { ...query, ...value };
    updateQuery && dispatch(updateQuery(tmpQuery));
    onChange && onChange(tmpQuery);
  };
  return (
    <div className={mgLeft ? 'ml-2' : ''}>
      <RangePicker
        format={DATE_FORMAT}
        allowClear={allowClear}
        defaultValue={
          (query.FromDate || query.DateFrom) &&
          query.DateTo && [moment(query.FromDate || query.DateFrom), moment(query.DateTo)]
        }
        onChange={(val) =>
          setRangeDate({
            DateFrom: val?.length && val[0] ? val[0].format('YYYY-MM-DD') : '',
            DateTo: val?.length > 1 && val[1] ? val[1].format('YYYY-MM-DD') : '',
          })
        }
      />
    </div>
  );
};
