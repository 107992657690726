import { useEffect, useState, useCallback } from 'react';
import { Divider, Button, Space, Table } from 'antd';
import moment from 'moment';
import ApiServices from '~services';
import { COLUMNS } from './constants';
import { ReportHeader, RangeDateFilter, Selection } from '~components';
import { debounce } from 'lodash';
import { DATE_FORMAT_SEND_API, SHORTEN_DATE_FORMAT_CUSTOM } from '~commons/constants';
import { DownloadOutlined } from '@ant-design/icons';
const ExcelJS = require('exceljs');
import logo from '~assets/images/logo.png';
const toDataURL = (url) => {
  const promise = new Promise((resolve) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
  return promise;
};

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};

export const ReportDetailTour = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [carList, setCarList] = useState([]);
  const [carId, setCarId] = useState('all');
  const [carCode, setCarCode] = useState('Tất Cả');
  const [loadingExport, setLoadingExport] = useState(false);

  const getCars = async (query = '') => {
    const dataSend = JSON.stringify({ code: query });
    if (loading) return;
    setLoading(true);
    try {
      let { data } = await ApiServices.getCars({ Columns: dataSend });
      if (data?.data) {
        const dataSetDefault = [
          {
            id: 'all',
            code: 'Tất Cả',
          },
        ];
        const dataGetFromApi = data?.data || [];
        const dataEnd = dataSetDefault?.concat(dataGetFromApi);
        setCarList(dataEnd);
      }
    } catch (error) {
      console.log('Get car list failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const getData = async (query = DEFAULT_QUERIES, carIdSelected = '') => {
    setLoading(true);
    if (!query.DateFrom || !query.DateTo) return;
    const dataFrom = moment(query.DateFrom).format(DATE_FORMAT_SEND_API);
    const dataTo = moment(query.DateTo).format(DATE_FORMAT_SEND_API);

    const newQuery = {
      DateFrom: dataFrom,
      DateTo: dataTo,
      carId: carIdSelected === 'all' ? '' : carIdSelected,
    };
    try {
      const res = await ApiServices.getReportDetailTour(newQuery);
      if (res?.data?.data) {
        const dataMap = res?.data?.data?.map((item, index) => {
          item.index = index + 1;
          return item;
        });
        setData(dataMap);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCars();
  }, []);

  const timeoutGetData = useCallback(
    debounce((newFilter, newCarId) => {
      getData(newFilter, newCarId);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter, carId);
  }, [filter, carId]);

  const handleChangeCar = (value) => {
    if (!value) {
      setCarId('all');
      setCarCode('Tất Cả');
      return;
    }
    const finCarCode = carList.find((item) => item.id === value);
    if (finCarCode) {
      setCarCode(finCarCode.code);
    }
    setCarId(value);
  };

  // Export excel
  const handleExportExcel = async (dataExport, filterDate, carCodeLabel) => {
    if (loadingExport) return;
    setLoadingExport(true);

    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet(carCodeLabel, {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ state: 'frozen', ySplit: 5, activeCell: 'A1', showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 17;
    sheetSummary.properties.defaultColWidth = 15;
    sheetSummary.getColumn(1).width = 5;
    // add Image
    const resultTest = await toDataURL(logo);
    const extNameTest = 'png';

    const imageId2Summary = workbook.addImage({
      base64: resultTest.base64Url,
      extension: extNameTest,
    });
    sheetSummary.addImage(imageId2Summary, {
      tl: { col: 1, row: 0 },
      ext: { width: 150, height: 100 },
    });

    sheetSummary.getCell('D1').value = 'Công Ty Cổ Phần Vận Tải U&I';
    sheetSummary.getCell('D2').value = 'Số 9 Ngô Gia Tự, Chánh Nghĩa, TX TDM, Bình Dương';
    sheetSummary.getCell('D3').value = 'MST: 3700970866';

    sheetSummary.getCell('E7').value = 'BẢNG THỐNG KÊ CHI TIẾT TOUR';
    const date = `${moment(filterDate?.DateFrom).format('DD/MM/YYYY')} -> ${moment(
      filterDate?.DateTo
    ).format('DD/MM/YYYY')}`;
    sheetSummary.getCell('E8').value = date;
    sheetSummary.getCell('E9').value = carCodeLabel ? `Xe: ${carCodeLabel}` : '';

    // Add table
    const arrDetail = await dataExport?.map((item) => {
      const dataItem = [
        item.index, // STT
        item?.assignDate && moment(item.assignDate).format('YYYY-MM-DD'), // Ngay DIeu Xe
        item?.subnoNumber || '', // Booking / Bill
        item?.customerName || '', // Booking / Bill
        item?.carCode || '', // XE
        item?.up || '', // Noi Lay
        item?.down || '', // Noi Ha
        item?.contNumber || '', // So Cont
      ];
      return dataItem;
    });

    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B11',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Ngày', totalsRowLabel: '', filterButton: false },
        {
          name: 'Booking / Bill',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Khách hàng',
          filterButton: false,
        },
        {
          name: 'Xe',
          filterButton: false,
        },
        {
          name: 'Nơi Lấy',
          filterButton: false,
        },
        {
          name: 'Nơi Hạ',
          filterButton: false,
        },
        {
          name: 'Số Cont',
          filterButton: false,
        },
      ],
      rows: arrDetail,
    });
    // ========== STYLES =====================
    sheetSummary.getRow(7).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    sheetSummary.getRow(8).font = {
      name: 'Arial',
      family: 2,
      size: 10,
      italic: true,
    };
    sheetSummary.getRow(9).font = {
      name: 'Arial',
      family: 2,
      size: 11,
      bold: true,
    };
    sheetSummary.getRow(7).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(8).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(9).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(11).alignment = { vertical: 'middle', horizontal: 'center' };

    sheetSummary.getColumn(2).width = 5;
    sheetSummary.getColumn(4).width = 25;
    sheetSummary.getColumn(5).width = 25;
    sheetSummary.getColumn(7).width = 25;
    sheetSummary.getColumn(8).width = 25;

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Bang_Thong_Ke_Chi_Tiet_Tour_Từ ${moment(filterDate?.DateFrom).format(
          'DD/MM/YYYY'
        )} - ${moment(filterDate?.DateTo).format('DD/MM/YYYY')}_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoadingExport(false);
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          padding: '20px 10px',
          marginBottom: '15px',
          width: '95%',
        }}
      >
        <Space>
          <div>
            <b
              style={{
                marginLeft: '10px',
              }}
            >
              Chọn ngày:
            </b>
            <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
          </div>
          <div>
            <b>
              Chọn xe:{' '}
              {!carId && (
                <i
                  style={{
                    color: 'red',
                    fontSize: '12px',
                  }}
                >
                  Bạn phải chọn xe
                </i>
              )}
            </b>
            <br />
            <Selection
              status={!carId ? 'error' : ''}
              data={carList}
              placeholder='Chọn Xe'
              fieldValue='code'
              width={200}
              value={carId}
              onChange={handleChangeCar}
              onSearch={getCars}
            />
          </div>
          <div>
            <br />
            <Button
              type='primary'
              icon={<DownloadOutlined />}
              style={{ padding: '0px 20px', marginLeft: '10px' }}
              onClick={() => handleExportExcel(data, filter, carCode)}
              loading={loadingExport}
              disabled={!data?.length}
            >
              Export Excel
            </Button>
          </div>
        </Space>
      </div>
      <div
        style={{
          marginBottom: '50px',
        }}
      >
        <ReportHeader />
        <Divider plain={true}>
          <h1>
            <b>BẢNG THỐNG KÊ CHI TIẾT TOUR</b>
          </h1>
        </Divider>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '-15px',
            marginBottom: '5px',
            textAlign: 'center',
          }}
        >
          <div>
            <i>
              {filter?.DateFrom && moment(filter?.DateFrom).format(SHORTEN_DATE_FORMAT_CUSTOM)}{' '}
              {'-> '}
              {filter?.DateTo && moment(filter?.DateTo).format(SHORTEN_DATE_FORMAT_CUSTOM)}
            </i>
            <br />
            <span>
              Xe: <b className='red'>{carCode}</b>
            </span>
          </div>
        </div>

        <Table
          dataSource={data}
          loading={loading}
          rowKey='index'
          bordered
          columns={COLUMNS}
          pagination={false}
          size='small'
        />
      </div>
    </>
  );
};
