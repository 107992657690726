import React from 'react';
import Utils from '~commons/utils';

export const ViewHistoryForm = ({ data }) => {
  const renderLogHTML = (log, index) => {
    return <div key={`${log}-${index}`} dangerouslySetInnerHTML={{ __html: log }} />;
  };

  const renderLog = (item) => {
    return (
      <div
        key={item?.id}
        style={{
          // border: '1px solid blue',
          padding: '8px',
          borderRadius: '4px',
          marginBottom: '8px',
          marginRight: '8px',
          // overflow: 'auto',
        }}
      >
        <h3
          style={{
            padding: 0,
            margin: 0,
          }}
        >
          {Utils.formatDateHourFn_2(item?.createdOn)} {' - '}
          {item?.createdBy}
        </h3>
        {item?.logs?.map(renderLogHTML)}
      </div>
    );
  };

  return (
    <div
      style={{
        maxHeight: 'calc(100vh - 150px)',
        overflow: 'auto',
        fontSize: '13px',
      }}
    >
      {data?.[0]?.locationName && (
        <h3
          style={{
            marginTop: 0,
            paddingTop: 0,
            textAlign: 'center',
          }}
        >
          Địa điểm: {data?.[0]?.locationName}
        </h3>
      )}
      {data?.map(renderLog)}
    </div>
  );
};
