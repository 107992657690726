import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Divider, Button, Space, Table } from 'antd';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
const ExcelJS = require('exceljs');

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ReportVehicle = () => {
  let query = useQuery();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataKey, setDataKey] = useState('');
  const [onlyShowKey, setOnlyShowKey] = useState(false);

  const getRPVehicleInspection = async () => {
    const arrIdVendor = query.get('id');
    const key = query.get('key');
    switch (key) {
      case 'hetHanDangKiem':
        setDataKey('Đã hết hạn');
        break;
      case 'hetHanDangKiem10':
        setDataKey('Còn 10 ngày');
        break;
      case 'hetHanDangKiem20':
        setDataKey('Còn 20 ngày');
        break;
      case 'hetHanDangKiem30':
        setDataKey('Còn 30 ngày');
        break;
      case 'hetHanPhuHieu':
        setDataKey('Hết hạn phù hiệu');
        setOnlyShowKey(true);
        break;
      case 'hetHanBaoHiem':
        setDataKey('Hết hạn bảo hiểm');
        setOnlyShowKey(true);
        break;
      default:
        break;
    }

    if (arrIdVendor) {
      try {
        setLoading(true);
        let { data } = await ApiServices.getRPVehicleInspection(arrIdVendor.split(','));
        setLoading(false);
        if (data[key]) {
          return getListByArrayIdOfCar(data[key]);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const displayDateExpire = (value, record) => {
    const key = query.get('key');
    if (key === 'hetHanPhuHieu') {
      return record?.cnSignExpDate && Utils.formatDate(record?.cnSignExpDate);
    }
    if (key === 'hetHanBaoHiem') {
      return record?.cnInsurranceExpDate && Utils.formatDate(record?.cnInsurranceExpDate);
    }
    return Utils.formatDate(value);
  };

  const getListByArrayIdOfCar = async (arrId) => {
    try {
      setLoading(true);
      let { data } = await ApiServices.getListByArrayIdOfCar(arrId);
      setLoading(false);
      if (data?.data) {
        const dataSort = data?.data?.sort((a, b) => {
          const key = query.get('key');
          let dateA = a?.inspectionExpirationDate;
          let dateB = b?.inspectionExpirationDate;

          if (key === 'hetHanPhuHieu') {
            dateA = a?.cnSignExpDate;
            dateB = b?.cnSignExpDate;
          }
          if (key === 'hetHanBaoHiem') {
            dateA = a?.cnInsurranceExpDate;
            dateB = b?.cnInsurranceExpDate;
          }

          let da = new Date(dateA);
          let db = new Date(dateB);
          return da - db;
        });
        const dataMap = dataSort?.map((item, index) => {
          item.key = index + 1;
          return item;
        });
        setData(dataMap);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getInit = async () => {
      await getRPVehicleInspection();
    };

    getInit();
  }, []);

  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      width: 50,
      align: 'center',
    },
    {
      title: 'Biển Số',
      dataIndex: 'code',
      width: 100,
      align: 'center',
    },
    {
      title: dataKey,
      dataIndex: 'inspectionExpirationDate',
      width: 130,
      align: 'center',
      render: displayDateExpire,
    },
    {
      title: 'Chủng Loại',
      dataIndex: 'typeCar',
      width: 130,
      align: 'center',
    },
    {
      title: 'Loại Động Cơ',
      dataIndex: 'engineType',
      width: 150,
      align: 'center',
    },
    {
      title: 'Nơi SX',
      dataIndex: 'madeIn',
      width: 130,
      align: 'center',
    },
    {
      title: 'Năm SX',
      dataIndex: 'madeYear',
      width: 100,
      align: 'center',
    },
    {
      title: 'Số Khung',
      dataIndex: 'frameNumber',
      width: 150,
      align: 'center',
    },
  ];

  const exportExcelFile = async () => {
    if (loading) return;
    const key = query.get('key');
    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet(`${dataKey}`, {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 20;
    sheetSummary.properties.defaultColWidth = 15;
    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getColumn(8).width = 25;

    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    {
      onlyShowKey
        ? (sheetSummary.getCell('E2').value = `${dataKey?.toUpperCase()}`)
        : (sheetSummary.getCell('E2').value = 'HẾT HẠN KIỂM ĐỊNH');
    }

    sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(3).font = {
      name: 'Arial',
      family: 2,
      size: 13,
      bold: true,
    };
    {
      onlyShowKey
        ? (sheetSummary.getCell('E3').value = '')
        : (sheetSummary.getCell('E3').value = `(${dataKey})`);
    }

    const arrDetail = await data?.map((item) => {
      const dataItem = [
        item?.key, // STT
        item?.code, // Bien So
        key === 'hetHanPhuHieu'
          ? item?.cnSignExpDate && new Date(moment(item?.cnSignExpDate).format('YYYY-MM-DD'))
          : key === 'hetHanBaoHiem'
          ? item?.cnInsurranceExpDate &&
            new Date(moment(item?.cnInsurranceExpDate).format('YYYY-MM-DD'))
          : item?.inspectionExpirationDate &&
            new Date(moment(item?.inspectionExpirationDate).format('YYYY-MM-DD')), // HH
        item?.typeCar, // Chung Loai
        item?.engineType, // Loai Dong Co
        item?.madeIn, // Noi San Xuat
        item?.madeYear, // Năm San Xuat
        item?.frameNumber, // So Khung
      ];
      return dataItem;
    });
    sheetSummary.getRow(5).alignment = { vertical: 'center', horizontal: 'center' };

    // Add Table
    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'A5',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Biển số', filterButton: false },
        {
          name: `${
            key === 'hetHanPhuHieu'
              ? 'HH Phù Hiệu'
              : key === 'hetHanBaoHiem'
              ? 'HH Bảo Hiểm'
              : 'HH Kiểm Định'
          }`,
          filterButton: false,
        },
        {
          name: 'Chủng loại',
          filterButton: false,
        },
        {
          name: 'Loại Động Cơ',
          filterButton: false,
        },
        {
          name: 'Nơi Sản Xuất',
          filterButton: false,
        },
        {
          name: 'Năm Sản Xuất',
          filterButton: false,
        },
        {
          name: 'Số Khung',
          filterButton: false,
        },
      ],
      rows: arrDetail,
    });

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `${dataKey}_Xuat_Excel_Luc_${moment().format('DD-MM-YY HH:mm')}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ margin: '2rem' }}>
      <Divider>
        {onlyShowKey ? (
          <b
            style={{
              textTransform: 'uppercase',
            }}
          >
            {dataKey}
          </b>
        ) : (
          <>
            <b>HẠN KIỂM ĐỊNH XE </b>(<span className='red'>{dataKey}</span>)
          </>
        )}
      </Divider>
      <Space>
        <Button
          type='primary'
          icon={<DownloadOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px', marginBottom: '20px' }}
          onClick={exportExcelFile}
        >
          Export Excel
        </Button>
      </Space>
      <Table
        rowKey='id'
        size='small'
        bordered
        width={500}
        columns={columns}
        dataSource={data}
        loading={loading}
      />
    </div>
  );
};

export { ReportVehicle };
