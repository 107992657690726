import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showAssignCarToday } from '~store/reducers/white-board/action';
import {
  fetchOrderWaitingReceiveONP,
  fetchOrderWaitingReceiveWMS,
  fetchOrderRevisedONP,
  fetchOrderRevisedWMS,
  fetchOrderCancelONP,
  fetchOrderCancelWMS,
  fetchOrderByTmsNotRead,
  fetchOrderCreateByTmsNotRead,
} from '~store/reducers/order-ONP-WMS/action';
import { WaitingOfDelivery } from './waiting-for-delivery';
import { LiftOff } from './lift-off';
import { LiftOn } from './lift-on';
import { WaitingOfPlan } from './waiting-of-plan';
import styled from 'styled-components';
import { CustomerDetail } from '~components';
import { AssignForm } from './assign-form';
import Header from './header';
import useApiHook from './wb-hook/api-hook';
import { color } from '~theme';
import { PARKING_CONT_WIDTH, WB_POPUP_ASSIGN_HEIGHT } from '~commons/constants';
import { ParkingCont } from './parking-conts';
import { AssignCarList } from './assign-car-list';

const WB_WIDTH = '2100px';
const RELOAD_TIME = 60 * 1000; //giây
const RELOAD_TIME_WAIT_FOR_PLAN = 200 * 1000; //giây
const RELOAD_TIME_RVS_BY_TMS = 80 * 1000; //giây
const RELOAD_TIME_RVS = 100 * 1000; //giây
const RELOAD_TIME_RECEIVE = 140 * 1000; //giây
const RELOAD_TIME_CANCEL = 160 * 1000; //giây

const RELOAD_ON = true; // on - off reload white board
export const WhiteBoard = () => {
  const dispatch = useDispatch();
  const timeOutReceive = useRef(null);
  const timeOutRvs = useRef(null);
  const timeOutCancel = useRef(null);
  const timeOutRvsByTms = useRef(null);

  const { dataShowAssignCarToday } = useSelector((state) => state.whiteBoard);
  const { query: queryWaitingDelivery } = useSelector((state) => state.waitingOfDelivery);
  const { query: queryLiftOn } = useSelector((state) => state.liftOn);
  const { query: queryLiftOff } = useSelector((state) => state.liftOff);
  const { query: queryWaitingPlan } = useSelector((state) => state.waitingOfPlan);
  const { isActiveDetail: isActiveParkingCont } = useSelector((state) => state.parkingCont);
  const { query: queryParkingCont } = useSelector((state) => state.parkingCont);
  const { query: querySingleCont } = useSelector((state) => state.singleCont);

  const {
    reloadWaitingOfPlan,
    reloadLiftOff,
    reloadLiftOn,
    reloadWaitingOfDelivery,
    reloadParkingCont,
    isShowAssignPopup,
    closeAssign,
    reloadSingleCont,
  } = useApiHook();

  // ====== Waiting Of Delivery ======
  useEffect(() => {
    const handleReload = setInterval(() => {
      RELOAD_ON && reloadWaitingOfDelivery(queryWaitingDelivery);
    }, RELOAD_TIME);

    return () => clearInterval(handleReload);
  }, [queryWaitingDelivery]);
  // ====== Lift On ======
  useEffect(() => {
    const handleReload = setInterval(() => {
      RELOAD_ON && reloadLiftOn(queryLiftOn);
    }, RELOAD_TIME);

    return () => clearInterval(handleReload);
  }, [queryLiftOn]);
  // ====== Lift-Off ======
  useEffect(() => {
    const handleReload = setInterval(() => {
      RELOAD_ON && reloadLiftOff(queryLiftOff);
    }, RELOAD_TIME);

    return () => clearInterval(handleReload);
  }, [queryLiftOff]);
  // ====== Waiting Of Plan ======
  useEffect(() => {
    const handleReload = setInterval(() => {
      RELOAD_ON && reloadWaitingOfPlan(queryWaitingPlan);
    }, RELOAD_TIME_WAIT_FOR_PLAN);

    return () => clearInterval(handleReload);
  }, [queryWaitingPlan]);

  // ====== Count Orders from ONP, WMS ==== New New ======
  // Rvs and Create By Tms
  useEffect(() => {
    clearTimeout(timeOutRvsByTms.current);
    timeOutRvsByTms.current = setTimeout(() => {
      dispatch(fetchOrderByTmsNotRead());
      dispatch(fetchOrderCreateByTmsNotRead());
    }, 2000);
  }, []);
  const getAllOrdersRvsByTms = async () => {
    dispatch(fetchOrderByTmsNotRead());
    dispatch(fetchOrderCreateByTmsNotRead());
  };
  useEffect(() => {
    const handleReload = setInterval(() => {
      RELOAD_ON && getAllOrdersRvsByTms();
    }, RELOAD_TIME_RVS_BY_TMS);

    return () => clearInterval(handleReload);
  }, []);
  // Receive
  useEffect(() => {
    clearTimeout(timeOutReceive.current);
    timeOutReceive.current = setTimeout(() => {
      dispatch(fetchOrderWaitingReceiveONP());
      dispatch(fetchOrderWaitingReceiveWMS());
    }, 1800);
  }, []);
  const getAllOrdersReceive = async () => {
    dispatch(fetchOrderWaitingReceiveONP());
    dispatch(fetchOrderWaitingReceiveWMS());
  };
  useEffect(() => {
    const handleReload = setInterval(() => {
      RELOAD_ON && getAllOrdersReceive();
    }, RELOAD_TIME_RECEIVE);

    return () => clearInterval(handleReload);
  }, []);
  // Rvs
  useEffect(() => {
    clearTimeout(timeOutRvs.current);
    timeOutRvs.current = setTimeout(() => {
      dispatch(fetchOrderRevisedONP());
      dispatch(fetchOrderRevisedWMS());
    }, 2000);
  }, []);
  const getAllOrdersRvs = async () => {
    dispatch(fetchOrderRevisedONP());
    dispatch(fetchOrderRevisedWMS());
  };
  useEffect(() => {
    const handleReload = setInterval(() => {
      RELOAD_ON && getAllOrdersRvs();
    }, RELOAD_TIME_RVS);

    return () => clearInterval(handleReload);
  }, []);
  // Cancel
  useEffect(() => {
    clearTimeout(timeOutCancel.current);
    timeOutCancel.current = setTimeout(() => {
      dispatch(fetchOrderCancelONP());
      dispatch(fetchOrderCancelWMS());
    }, 2200);
  }, []);

  const getAllOrdersCancel = async () => {
    dispatch(fetchOrderCancelONP());
    dispatch(fetchOrderCancelWMS());
  };
  useEffect(() => {
    const handleReload = setInterval(() => {
      RELOAD_ON && getAllOrdersCancel();
    }, RELOAD_TIME_CANCEL);

    return () => clearInterval(handleReload);
  }, []);
  // ====== parking cont ======
  useEffect(() => {
    const handleReload = setInterval(() => {
      RELOAD_ON && reloadParkingCont(queryParkingCont);
    }, RELOAD_TIME);

    return () => clearInterval(handleReload);
  }, [queryParkingCont]);

  useEffect(() => {
    const handleReload = setInterval(() => {
      RELOAD_ON && reloadSingleCont(querySingleCont);
    }, RELOAD_TIME);

    return () => clearInterval(handleReload);
  }, [querySingleCont]);

  useEffect(() => {
    document.addEventListener('click', onKeyDownContainer, true);
    return () => {
      document.removeEventListener('click', onKeyDownContainer, true);
    };
  }, []);
  const onKeyDownContainer = (e) => {
    if (e.key === 'Escape') {
      closeAssign();
    }
    if (!e.altKey) {
      return;
    }
    switch (e.key) {
      case '1':
        window.scroll({
          left: 0,
          behavior: 'smooth',
        });
        break;
      case '2':
        window.scroll({
          left: 400,
          behavior: 'smooth',
        });
        break;
      case '3':
        window.scroll({
          left: 900,
          behavior: 'smooth',
        });
        break;
      case '4':
        window.scroll({
          left: 1400,
          behavior: 'smooth',
        });
        break;
      default:
        break;
    }
  };
  const containerWidth = isActiveParkingCont
    ? `calc(${WB_WIDTH} + ${PARKING_CONT_WIDTH})`
    : WB_WIDTH;
  const closeAssignCarList = () => dispatch(showAssignCarToday(null));

  return (
    <Wrapper onKeyDown={onKeyDownContainer} tabIndex='0' width={containerWidth} className='wb'>
      <Header />
      <Container className='whiteboard-container' isShowPopup={isShowAssignPopup}>
        <ParkingCont />
        <WaitingOfDelivery />
        <LiftOff />
        <LiftOn />
        <WaitingOfPlan />
      </Container>
      <AssignForm />
      <AssignCarList
        isShowAssignCar={!!dataShowAssignCarToday}
        setIsShowAssignCar={closeAssignCarList}
        idCar={dataShowAssignCarToday}
        title='Lệnh điều xe trong ngày'
      />
      <CustomerDetail />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: ${(props) => props.width};
  position: relative;
  background: ${color.wbBg};
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  height: fit-content;
  display: flex;
  padding: 0px 8px;
  padding-top: 10px;
  transition: height 0.5s linear;
  min-height: calc(
    100vh - ${({ isShowPopup }) => (isShowPopup ? WB_POPUP_ASSIGN_HEIGHT : '0px')} - 8px
  );
`;
