import React, { useEffect, useState, useRef } from 'react';
import { TableLayout } from '~components';
import { ShippingLineForm } from './shipping-line-form';
import DriverFields from './fields-data';
import { Input, Button, Checkbox, Popconfirm, message } from 'antd';

import ApiServices from '~services';

const ShippingLines = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [shippingLines, setShippingLines] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);
  //-------------------------------------------------------------------
  const [searchTerm, setSearchTerm] = useState('');
  const typingTimeoutref = useRef(null);
  //-------------------------------------------------------------------
  const [searchByColumn, setSearchByColumn] = useState({
    code: '',
    name: '',
    phone: '',
    address: '',
    contactPerson: '',
    contactPosition: '',
  });

  const getShippingLines = async () => {
    let dataFilterSend = { ...filter };
    if (Object.keys(dataFilterSend.Columns).length === 0) {
      delete dataFilterSend['Columns'];
    } else {
      const columnJSONSTRING = JSON.stringify(dataFilterSend.Columns);
      dataFilterSend.Columns = columnJSONSTRING;
    }

    try {
      setLoading(true);
      let { data } = await ApiServices.getShippingLines(dataFilterSend);
      setLoading(false);
      let pag = pagination;
      pag.total = data?.totalRecords || 0;
      pag.pageSize = data?.pageSize;
      pag.current = data?.pageNumber;

      data = data?.data || [];
      setShippingLines(data);
      setPagination(pag);
    } catch (error) {
      console.log('Error - get shippingLine', error);
      console.error(error);
    }
  };

  useEffect(() => {
    getShippingLines();
  }, []);

  //----------- Start ----- Pagination Search ---------
  //----------- Pagination --------------------------
  const onChangePagination = (page, size) => {
    let filterTemp = filter;
    filterTemp.PageNumber = page;
    filterTemp.PageSize = size;
    setFilter(filterTemp);
    getShippingLines();
  };
  const [pagination, setPagination] = useState({
    total: 1,
    showSizeChanger: true,
    onChange: onChangePagination,
  });
  const [filter, setFilter] = useState({
    PageNumber: 1,
    PageSize: 20,
    Text: '',
    Columns: '',
    // QueryField: '',
    // SortColumn: '',
    // SortColumnDirection: ''
  });

  // ------------ Search Text ------------------------
  const handleSearchTermChange = (e) => {
    const val = e.target.value;
    setSearchTerm(val);
    const filterTmp = filter;
    filterTmp.Text = val;

    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }

    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 2) {
        filter.PageNumber = 1;
        setFilter({ ...filterTmp, PageNumber: 1 });
        getShippingLines();
      }
      if (val.length === 0) {
        setFilter({ ...filterTmp, PageNumber: 1 });
        getShippingLines();
      }
    }, 1000);
  };
  // ------------ Search Column ----------------------
  const handleSearchColumnFn = (event, name) => {
    const filterTmp = filter;
    const val = event.target.value;
    let _searchByColumn = { ...searchByColumn };
    _searchByColumn[`${name}`] = val;
    setSearchByColumn(_searchByColumn);
    // Clear timeout
    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }
    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 0) {
        filter.PageNumber = 1;
        let searchColumnFn = { ...filter.Columns };
        searchColumnFn[`${name}`] = val;
        filter.Columns = searchColumnFn;

        setFilter({ ...filterTmp, PageNumber: 1 });
        getShippingLines();
      }
      if (val.length === 0) {
        delete filter.Columns[`${name}`];
        setFilter({ ...filterTmp, PageNumber: 1 });
        getShippingLines();
      }
    }, 1000);
  };
  // ------------- End Search --------------------

  const setFieldsForm = async (value) => {
    const driverFields = await DriverFields(value);
    await setFields(driverFields);
    return driverFields;
  };
  const reload = async () => {
    setSelected(null);
    await getShippingLines();
  };

  const getUpdatedShippingLineForm = async (value, isNew = false, id = null) => {
    const driverForm = await (
      <ShippingLineForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        reload={reload}
        onChange={setFields}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: driverForm,
      },
    ]);
  };

  const onRowSelected = async (value) => {
    await setIsLoadingTab(true);
    await setTitleTab(value.code);
    await setFieldsForm(value)
      .then((res) => {
        return getUpdatedShippingLineForm(res, false, value.id);
      })
      .then(() => setSelected(value));
    setIsLoadingTab(false);
  };
  const deleteShippingLine = async (record) => {
    try {
      const res = await ApiServices.deleteShippingLine(record.id);
      if (res.data) {
        message.success('Xoá Hãng Tàu thành công');
        return reload();
      }
      message.error('Xoá Hãng tàu thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      // title: 'Mã',
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Mã</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.code}
            onChange={(event) => handleSearchColumnFn(event, 'code')}
          />
        </span>
      ),
      dataIndex: 'code',
      align: 'center',
      width: 130,
      render: (value, record) => <a onClick={() => onRowSelected(record)}>{value || 'undefine'}</a>,
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Tên</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.name}
            onChange={(event) => handleSearchColumnFn(event, 'name')}
          />
        </span>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'name',
    }, //
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Điện thoại</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.phone}
            onChange={(event) => handleSearchColumnFn(event, 'phone')}
          />
        </span>
      ),
      width: 100,
      align: 'center',
      dataIndex: 'phone',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Địa chỉ</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.address}
            onChange={(event) => handleSearchColumnFn(event, 'address')}
          />
        </span>
      ),
      width: 300,
      align: 'center',
      dataIndex: 'address',
    }, //
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Người Đại Diện</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.contactPerson}
            onChange={(event) => handleSearchColumnFn(event, 'contactPerson')}
          />
        </span>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'contactPerson',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Chức vụ</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.contactPosition}
            onChange={(event) => handleSearchColumnFn(event, 'contactPosition')}
          />
        </span>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'contactPosition',
    },
    {
      title: 'Trả lời Email',
      width: 120,
      align: 'center',
      dataIndex: 'replyEmail',
      ender: (value) => <Checkbox checked={value} />,
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (record) => (
        <Popconfirm
          onConfirm={() => deleteShippingLine(record)}
          title='Xoá tài xế này？'
          okText='Yes'
          cancelText='No'
        >
          <a style={{ color: 'red' }} href='#'>
            Xoá
          </a>
        </Popconfirm>
      ),
    },
  ];

  const createDriver = async () => {
    await setTitleTab('');
    await setFieldsForm({})
      .then((res) => getUpdatedShippingLineForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };

  const headerContent = (
    <>
      <Button type='primary' onClick={createDriver}>
        Thêm Mới
      </Button>
      <Input.Search
        style={{ width: '200px' }}
        className='mg-l-15'
        placeholder='Search'
        value={searchTerm}
        onChange={handleSearchTermChange}
      />
    </>
  );
  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={shippingLines}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      pagination={pagination}
      isDisplayCheckbox={false}
      headerLength='190px'
    />
  );
};

export { ShippingLines };
