export default {
  FETCH_DATA: 'lo_fetch_data',
  UPDATE_QUERY: 'lo_update_query',
  UPDATE_ONE_DATA: 'lo_update_one_data',
  LOAD_MORE_DATA: 'lo_load_more_data',
  UPDATE_LOADING: 'lo_update_loading',
  UPDATE_SELECTED: 'lo_update_selected',
  UPDATE_SELECTED_KEY: 'lo_update_selected_key',
  SELECTED_DATA_ROW: 'lo_selected_data_row',
};
