import React from 'react';
import { Form, Input, Button, Card, notification } from 'antd';
import jwt_decode from 'jwt-decode';
import apiServices from '~services';
import { useDispatch } from 'react-redux';
import * as appActions from '~store/reducers/app/action';
import Storage from '~storage';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  dispatch(appActions.signOut());

  const onFinish = async (values) => {
    setLoading(true);
    try {
      // const data = {
      //   token:
      //     'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJPYmplY3RJZCI6IjVlOTU4YzdmMTQzMzVlY2VjZDEwNTQ2NyIsIklkIjoiQURNSU4iLCJFbWFpbCI6InVzZXJAZXhhbXBsZS5jb20iLCJGdWxsTmFtZSI6IkFkbWluaXN0cmF0b3IgMSIsIkNvbXBhbnlJZCI6IlVOSUxPRyIsIkRlcGFydG1lbnRJZCI6IkhSTSIsIlNlcmlhbE51bWJlciI6InN0cmluZyIsIkFyckZ1bmN0aW9uIjoiVVNFUlNfTE9HSU4sVVNFUlNfTE9HT1VULDksOCw3LDYwLDYsNTMsNTIsNTEsNTAsNSw0OCw0Niw0Nyw0OSwxLDEyLDEzLDE0LDE1LDE2LDE3LDE4LDE5LDIsMjAsMjQsMjUsMjYsMjcsMjgsMjksNDUsNDQsNDMsNCwzOSwzOCwzNywzNiwzMiwzMSwzMCwzLDEwLDExLDYxLDYyLDU2LDIxIiwiQXJyTWVudSI6IkNBVEVHT1JZX01FTlUsREVQQVJUTUVOVF9NRU5VLExPR09VVF9NRU5VLE1BTkFHRV9NRU5VLFNBTEFSWV9NRU5VLFVTRVJfTUVOVSxQT1NJVElPTl9NRU5VIiwiQXJyTWVtYmVyR3JvdXAiOiJTWVNURU0iLCJuYmYiOjE2MjIwODE4NjksImV4cCI6MTYyMjIyMTIwMCwiaXNzIjoiVU5JTE9HIiwiYXVkIjoiQWNjb3VudGluZyJ9.5MRZLwucRfAxIm1KKv6qVQm-HUWrqqApKsg1TxyEXGA',
      // };
      const { data } = await apiServices.login({
        code: values.username,
        ...values,
      });
      // setLoading(false);

      if (data.data) {
        const decodeToken = await jwt_decode(data.data);
        await Storage.saveDataDecodeToken(decodeToken);

        if (decodeToken.IsDriver === 'True') {
          notification.error({
            message: 'Đăng nhập thất bại',
            description: 'Sai tên tài khoản hoặc mật khẩu !!!',
          });
          setLoading(false);
        } else {
          const signIn = async () => {
            await setInterval(() => {}, 1000);
            return dispatch(
              appActions.signIn({
                token: data.data,
                code: decodeToken.Code,
                fullName: decodeToken.FullName,
                id: decodeToken.ObjectId,
                configs: decodeToken?.Configs ? JSON.parse(decodeToken?.Configs) : {},
              })
            );
          };
          return signIn().then(() => {
            window.location.replace('/');
          });
        }
      }
    } catch (error) {
      notification.error({
        message: 'Đăng nhập thất bại',
        description: 'Sai tên tài khoản hoặc mật khẩu !!!',
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='login-form'>
      <div className='site-card-border-less-wrapper login-content login-card'>
        {/* <Divider>Đăng Nhập Hệ Thống</Divider> */}
        <Card title='' bordered={true}>
          <Form
            {...layout}
            name='basic'
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label='Tài Khoản'
              name='username'
              placeholder='Tài Khoản'
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tài khoản!',
                },
              ]}
              hasFeedback
            >
              <Input placeholder='Tài Khoản' />
            </Form.Item>

            <Form.Item
              label='Mật Khẩu'
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu!',
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder='Mật Khẩu' />
            </Form.Item>
            {/* <Form.Item {...tailLayout} name='remember' valuePropName='checked'>
              <Checkbox>Remember me</Checkbox>
            </Form.Item> */}

            <Form.Item {...tailLayout} style={{ marginBottom: '10px' }}>
              <Button loading={loading} type='primary' htmlType='submit'>
                Đăng Nhập
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export { Login };
