import React, { useState, useEffect } from 'react';
import { Button, Form, InputNumber } from 'antd';
import { Selection } from '~components';
import ApiServices from '~services';

const TransportFeeUpdateForm = ({
  locationSelected,
  dataFeeMainTransport,
  dataUpdate,
  dataFees,
  setDataFees,
  onCloseUpdate,
  reloadAfterUpdate,
  isNew,
  handleSubmitUpdateWhenCreate,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [distanceFindLocations, setDistanceFindLocations] = useState([]);
  const [showErrMessage, setShowErrMessage] = useState(false);
  const [distanceSelected, setDistanceSelected] = useState('');

  const getDistanceByFindLocation = async (arrLocationId, text = '') => {
    setLoading(true);
    try {
      let { data } = await ApiServices.getDistanceByFindLocation(arrLocationId, { name: text });
      if (data?.data) {
        setDistanceFindLocations(data?.data);
      }
    } catch (error) {
      console.log('Error - getDistanceByFindLocation failed:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (locationSelected?.length > 0) {
      getDistanceByFindLocation(locationSelected);
    }
  }, [locationSelected]);

  useEffect(() => {
    setDistanceSelected(dataUpdate?.distanceId);
  }, [dataUpdate]);

  const handleSearchRoute = (text) => {
    if (locationSelected?.length > 0) {
      getDistanceByFindLocation(locationSelected, text);
    }
  };

  const handleUpdateWithUpdateNew = async (dataInfoNew, dataFeeNew, distanceIdNew) => {
    let dataSendUpdate = {
      fees: dataFeeNew,
      idGenerate: dataInfoNew?.idGenerate,
      distanceId: distanceIdNew,
    };
    if (dataInfoNew?.distanceId === distanceIdNew) {
      dataSendUpdate.dataObjectDistance = dataInfoNew?.dataObjectDistance;
    } else {
      const dataObjectDistance = distanceFindLocations?.find((item) => item.id === distanceIdNew);
      dataSendUpdate.dataObjectDistance = dataObjectDistance;
    }
    dataFeeNew.map((el) => {
      dataSendUpdate[el.contTypeId] = el.fee;
    });
    handleSubmitUpdateWhenCreate && handleSubmitUpdateWhenCreate(dataSendUpdate);
  };

  const handleUpdateWithApi = async (dataInfoNew, dataFeeNew, distanceIdNew) => {
    const dataSend = {
      id: dataInfoNew.id,
      contractId: dataInfoNew.contractId,
      distanceId: distanceIdNew,
      // locations: dataInfoNew.locations,
      fees: dataFeeNew,
    };

    setLoading(true);
    try {
      const res = await ApiServices.updateContract(dataInfoNew.id, dataSend);

      if (res?.data?.data) {
        onCloseUpdate();
        reloadAfterUpdate();
        window.TmsNoti.success('Cập nhật thành công');
      } else {
        window.TmsNoti.error('Lỗi', 'Cập nhật thất bại');
      }
    } catch (error) {
      console.log({ error });
      window.TmsNoti.error('Lỗi', 'Cập nhật thất bại');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (dataInfoNew, dataFeeNew, distanceIdNew, hasUpdateNew = false) => {
    const checkHasPrice = dataFeeNew?.some((item) => item?.fee > 0);
    if (!checkHasPrice) {
      setShowErrMessage(true);
      return;
    }

    if (hasUpdateNew) {
      return handleUpdateWithUpdateNew(dataInfoNew, dataFeeNew, distanceIdNew);
    } else {
      return handleUpdateWithApi(dataInfoNew, dataFeeNew, distanceIdNew);
    }
  };

  const onFinish = async () => {};
  const onFinishFailed = () => {};

  const handleChangeDistance = async (value) => {
    setDistanceSelected(value);
  };

  const handleChangeFee = (value, index) => {
    setShowErrMessage(false);
    setDataFees((prev) => {
      prev[index].fee = value;
      return [...prev];
    });
  };

  const currentRouteLocation = dataFeeMainTransport?.map((el) => el?.distanceId);
  const routeLocationAvailable = distanceFindLocations?.filter((item) => {
    return !currentRouteLocation.includes(item.id);
  });

  return (
    <div>
      <Form
        name='basic'
        form={form}
        labelCol={{
          span: 16,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        layout='vertical'
      >
        <Form.Item label='Chọn tuyến đường'>
          <Selection
            data={routeLocationAvailable}
            placeholder='Chọn tuyến đường'
            typeOption='route-location'
            labelDefault={`${dataUpdate?.locationFromName} -> ${dataUpdate?.locationToName}`}
            value={distanceSelected}
            onChange={handleChangeDistance}
            onSearch={handleSearchRoute}
          />
        </Form.Item>
        {dataFees?.map((item, index) => {
          return (
            <Form.Item key={index} label={item?.name}>
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                value={item?.fee}
                onChange={(value) => handleChangeFee(value, index)}
              />
            </Form.Item>
          );
        })}

        {showErrMessage && (
          <div
            style={{
              marginBottom: '20px',
            }}
          >
            <i className='red'>Bạn phải nhập ít nhất 1 chi phí !!!</i>
          </div>
        )}

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button
            type='primary'
            onClick={() => handleUpdate(dataUpdate, dataFees, distanceSelected, isNew)}
            loading={loading}
          >
            Cập nhật
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { TransportFeeUpdateForm };
