import { useCallback } from 'react';
import { Input, Table, DatePicker } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Wrapper } from '~commons/styles';
import { RangeDateFilter } from '~components';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { DATE_FORMAT } from '~commons/constants';
import { TableTitle } from '~commons/styles';
const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().startOf('date').format(),
  DateTo: moment().endOf('date').format(),
};
export const RevisedLog = ({ id, showById = false }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataRow, setDataRow] = useState();
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [revisedDateSearch, setRevisedDateSearch] = useState(null);

  const getDataById = () => {
    setLoading(true);
    return ApiServices.getRvsById(id)
      .then((res) => {
        setData(res.data?.data);
      })
      .catch((err) => {
        console.error('Get Revise by Id Error', err);
        setData([]);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };
  const getData = (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getRvsList(newQuery)
      .then((res) => {
        setDataRow(res?.data);
      })
      .catch((err) => {
        console.error('Get Revise List Error', err);
        setDataRow();
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };
  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );

  useEffect(() => {
    if (showById) {
      if (!id) {
        return setData([]);
      }
      return getDataById();
    }
  }, [id]);
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const handleChangeRevisedDateSearch = (date) => {
    setRevisedDateSearch(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'createdOn');
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Khách Hàng
          {!showById && (
            <Input name='customerName' onChange={onColumnSearch} placeholder='search' />
          )}
        </TableTitle>
      ),
      dataIndex: 'customerName',
      width: 150,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Book/Bill
          <Input name='code' onChange={onColumnSearch} placeholder='search' />
        </TableTitle>
      ),
      dataIndex: 'code',
      width: 150,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Nội dung
          <Input name='content' onChange={onColumnSearch} placeholder='search' />
        </TableTitle>
      ),
      dataIndex: 'content',
      width: 530,
      render: (val) => (
        <span
          dangerouslySetInnerHTML={{
            __html: val,
          }}
        />
      ),
    },
    {
      title: (
        <TableTitle>
          Người sửa
          <Input name='createdBy' onChange={onColumnSearch} placeholder='search' />
        </TableTitle>
      ),
      dataIndex: 'createdBy',
      width: 100,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Ngày Revised
          <DatePicker
            value={revisedDateSearch}
            onChange={handleChangeRevisedDateSearch}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'createdOn',
      render: (value) => Utils.formatDateHourFn_2(value),
      width: 90,
      align: 'center',
    },
  ];

  const pagination = {
    total: dataRow?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <Wrapper>
      {!showById && (
        <>
          <RangeDateFilter query={filter} onChange={setFilter} />
          <br />
        </>
      )}
      <Table
        columns={columns}
        size='small'
        dataSource={dataRow?.data || data}
        rowKey='createdOn'
        bordered
        loading={loading}
        scroll={{ y: 'calc(100vh - 200px)' }}
        pagination={!showById && pagination}
      />
    </Wrapper>
  );
};
