import React from 'react';
import { Divider } from 'antd';
import Utils from '~commons/utils';

const Information = ({ dataInfo }) => {
  return (
    <div
      style={{
        border: '1px solid blue',
        borderRadius: '4px',
      }}
    >
      <Divider
        style={{
          padding: 4,
          margin: 0,
        }}
      >
        Thông tin hồ sơ
      </Divider>
      <div
        style={{
          padding: '2px 8px',
        }}
      >
        <div>
          Khách hàng: <span className='blue'>{dataInfo?.customerName}</span>
        </div>
        <div>
          Số Cont: <span className='blue'>{dataInfo?.contNumber}</span>
        </div>
        <div>
          Số SubNo: <span className='blue'>{dataInfo?.subnoNumber}</span>
        </div>
        <div>
          Loại ĐH: <span className='blue'>{dataInfo?.orderType}</span>
        </div>
        <div>
          Ngày nhận data:{' '}
          <span className='blue'>
            {dataInfo?.billReceivedDate && Utils.formatDate(dataInfo?.billReceivedDate)}
          </span>
        </div>
      </div>
    </div>
  );
};

export { Information };
