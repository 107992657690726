import React, { useEffect, useState, useCallback } from 'react';
import { Button, Space, Input, DatePicker } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import moment from 'moment';
import { Wrapper, TableTitle } from '~commons/styles';
import { RangeDateFilter, TableLayout } from '~components';
import ApiServices from '~services';
import { DATE_FORMAT } from '~commons/constants';
import Utils from '~commons/utils';

const DEFAULT_QUERIES = {
  PageSize: 20,
  PageNumber: 1,
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};

export const ReportAccountantsBookingContUnpaid = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [searchDate, setSearchDate] = useState(null);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = {
      ...query,
    };
    return ApiServices.getReportAccountantsBookingContUnpaid(newQuery)
      .then((res) => {
        if (res?.data) {
          setData(res?.data);
        }
      })
      .catch((err) => {
        console.error('get list report Accountants Booking Cont Unpaid Error: ', err);
        setData();
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );

  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const handleChangeDate = (date) => {
    setSearchDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'bookingDeliveryContRequestDate');
  };

  const columns = [
    // {
    //   title: (
    //     <TableTitle>
    //       SubNo Number
    //       <Input name='subnoNumber' onChange={onColumnSearch} placeholder='Search' />
    //     </TableTitle>
    //   ),
    //   dataIndex: 'subnoNumber',
    //   align: 'center',
    //   width: 150,
    // },
    {
      title: (
        <TableTitle>
          Tên Khách Hàng
          <Input name='customerName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'customerName',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Số Cont
          <Input name='contNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'contNumber',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Ngày YCGC
          <DatePicker
            value={searchDate}
            onChange={handleChangeDate}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'bookingDeliveryContRequestDate',
      align: 'center',
      width: 130,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Xe Hạ
          <Input name='carDown' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'carDown',
      align: 'center',
      width: 130,
    },
    {
      title: (
        <TableTitle>
          Cảng Hạ
          <Input name='portDown' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'portDown',
      align: 'center',
      width: 200,
    },
  ];

  const handleExportExcel = async () => {
    if (!filter?.DateFrom || !filter?.DateTo || loading) return;

    const DateFrom = moment(filter?.DateFrom).format('YYYY-MM-DD');
    const DateTo = moment(filter?.DateTo).format('YYYY-MM-DD');

    setLoading(true);
    try {
      const { data } = await ApiServices.exportReportAccountantsBookingContUnpaid({
        DateFrom,
        DateTo,
      });
      if (data) {
        const outputFilename = `Danh_sach_cont_xuat_chua_giai_tru_${DateFrom}_den_ngay_${DateTo}.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoading(false);
    }
  };

  const pagination = {
    total: data?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <Wrapper>
      <h3>BÁO CÁO DANH SÁCH CONT XUẤT CHƯA GIẢI TRỪ</h3>
      <Space style={{ marginBottom: '10px' }}>
        <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
        <Button
          type='primary'
          icon={<DownloadOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px' }}
          onClick={handleExportExcel}
        >
          Export Excel
        </Button>
      </Space>
      <TableLayout
        columns={columns}
        rowKey='subnoNumber'
        data={data?.data}
        loading={loading}
        pagination={pagination}
        width={900}
        isDisplayCheckbox={false}
      />
    </Wrapper>
  );
};
