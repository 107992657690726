import React from 'react';
import { Table } from 'antd';

const TableDetails = ({ dataDetails }) => {
  const renderDataInDiary = (record, type) => {
    if (!record || !type || !record?.diary || !record?.diary?.[type]) return;
    return record?.diary[type];
  };

  const columns = [
    {
      title: 'Đơn hàng',
      dataIndex: 'code',
      // width: 250,
    },
    {
      title: 'Công việc',
      dataIndex: 'name',
      // width: 180,
    },
    {
      title: 'Số Mooc',
      dataIndex: 'romoocCode',
      align: 'center',
      render: (value, record) => renderDataInDiary(record, 'romoocCode') ?? value,
    },
    {
      title: 'Điểm đi',
      dataIndex: 'beginLocationName',
      align: 'center',
      render: (value, record) => renderDataInDiary(record, 'beginLocationName') ?? value,
    },
    {
      title: 'Điểm đến',
      dataIndex: 'endLocationName',
      align: 'center',
      render: (value, record) => renderDataInDiary(record, 'endLocationName') ?? value,
    },
    {
      title: 'Số Km',
      dataIndex: 'distance',
      align: 'center',
    },
    {
      title: 'Số Dầu',
      dataIndex: 'oil',
      align: 'center',
    },
    {
      title: 'Km hao hụt',
      dataIndex: 'lossOfDistance',
      align: 'center',
    },
    {
      title: 'Dầu hao hụt',
      dataIndex: 'lossOfOil',
      align: 'center',
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
    },
  ];

  const renderRowClassName = (record) => {
    if (record?.isSupport) {
      return 'red';
    }
    return '';
  };

  return (
    <Table
      rowKey='id'
      columns={columns}
      dataSource={dataDetails}
      pagination={false}
      bordered={true}
      size='small'
      rowClassName={renderRowClassName}
    />
  );
};

export { TableDetails };
