import React from 'react';
import { Table } from 'antd';
import Utils from '~commons/utils';

export const ShowDataAfterImport = ({ data }) => {
  const renderNumberCode = (value) => {
    if (!value) return '';

    const dataNumberCode = value?.split('U&I');
    const displayNumberCode = dataNumberCode ? `${dataNumberCode[0]} U&I ${dataNumberCode[1]}` : '';
    return displayNumberCode;
  };
  const displayCost = (_, record) => {
    if (record?.oilToSupply && record?.oilPrice) {
      return Utils.formatNumber(record?.oilToSupply * record?.oilPrice);
    } else {
      return 0;
    }
  };

  const columns = [
    {
      title: 'Số phiếu',
      dataIndex: 'numberCode',
      align: 'center',
      width: 100,
      render: renderNumberCode,
    },
    {
      title: 'Ngày cấp dầu',
      dataIndex: 'updatedOn',
      align: 'center',
      width: 100,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: 'Ngày đổ dầu thực tế',
      dataIndex: 'actualDate',
      align: 'center',
      width: 120,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: 'Số xe',
      dataIndex: 'carCode',
      align: 'center',
      width: 80,
    },
    {
      title: ' Diễn giải',
      dataIndex: 'assignOn',
      align: 'center',
      width: 130,
      render: (value) => <span>Chi dầu ngày {Utils.formatDate(value)}</span>,
    },
    {
      title: 'Họ và tên',
      dataIndex: 'driverMainName',
      align: 'center',
      width: 100,
    },
    {
      title: 'Giá dầu',
      dataIndex: 'oilPrice',
      align: 'center',
      width: 50,
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: 'Số lượng',
      dataIndex: 'oilToSupply',
      align: 'center',
      width: 45,
    },
    {
      title: 'Thành tiền',
      dataIndex: 'totalOil',
      align: 'center',
      width: 50,
      render: displayCost,
    },
    {
      title: 'Số lít đổ',
      dataIndex: 'oilToSupply',
      align: 'center',
      width: 45,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data || []}
      bordered
      size='small'
      key='id'
      // scroll={{ y: `calc(100vh - 220px)` }}
      // loading={loading}
    />
  );
};
