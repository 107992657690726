import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Divider, DatePicker, InputNumber, Row, Col } from 'antd';
import moment from 'moment';
import { DATE_FORMAT } from '~commons/constants';
import ApiServices from '~services';
import { HeaderFormCreate } from './header-form-create';
import { FeeItem } from './fee-item';

const FormCreate = ({ dataInfo, expensesSelected, reload, dissolveDateDefault }) => {
  const formRef = React.useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [vat, setVat] = useState(0);
  const [fieldsExpense, setFieldsExpense] = useState([]);
  const [dateDissolveInit, setDateDissolveInit] = useState(null);

  useEffect(() => {
    setDateDissolveInit(dissolveDateDefault);
  }, []);
  useEffect(() => {
    formRef.current?.resetFields();
  }, [expensesSelected]);

  function financial(x) {
    return Math.round(Number.parseFloat(x));
  }

  const getVatByDateInit = async (date) => {
    setLoading(true);
    try {
      let { data } = await ApiServices.getVatByDate(date);
      if (data?.data) {
        const expenseInit = expensesSelected?.map((item) => {
          let priceNoVat = 0;
          if (item?.fee) {
            const priceNoVatCalc = item?.fee / (1 + data?.data?.vat / 100);
            priceNoVat = Number.parseInt(financial(priceNoVatCalc));
          }

          return {
            name: item?.name,
            pay: item?.pay,
            billRequired: item?.billRequired,
            expenseId: item.id,
            price: priceNoVat ? priceNoVat : 0,
            vat: data?.data?.vat || 0,
            total: item?.fee ? item?.fee : 0,
            supplierName: item?.supplierName
              ? item?.supplierName
              : item?.supplier
              ? item?.supplier
              : '',
            billNumber: item?.billNumber || '',
            billDate: dataInfo?.contNumberDate ? moment(dataInfo.contNumberDate) : null,
          };
        });
        setFieldsExpense(expenseInit);
        setVat(data?.data?.vat);
        setDateDissolveInit(null);
      }
    } catch (error) {
      console.log('Get supplier bill failed:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (expensesSelected?.length) {
      getVatByDateInit(
        dateDissolveInit
          ? moment(dateDissolveInit).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD')
      );
    }
  }, [expensesSelected]);

  const getVatByDate = async (date) => {
    setLoading(true);
    try {
      let { data } = await ApiServices.getVatByDate(date);
      if (data?.data) {
        if (data?.data?.vat !== vat) {
          const expenseInit = fieldsExpense?.map((item) => {
            let total = 0;
            if (item?.fee) {
              const totalCalc = item?.fee * (1 + data?.data?.vat / 100);
              total = Number.parseInt(financial(totalCalc));
            }
            return {
              name: item?.name,
              pay: item?.pay,
              billRequired: item?.billRequired,
              expenseId: item?.expenseId,
              price: item?.fee ? item?.fee : 0,
              vat: data?.data?.vat || 0,
              total: total ? total : 0,
              supplierName: item?.supplierName
                ? item?.supplierName
                : item?.supplier
                ? item?.supplier
                : '',
              billNumber: item?.billNumber || '',
              billDate: item?.billDate ? moment(item?.billDate) : null,
            };
          });
          setFieldsExpense(expenseInit);
          setVat(data?.data?.vat);
        }
      }
    } catch (error) {
      console.log('Get supplier bill failed:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleChangeDissolveDate = async (date) => {
    if (!date) {
      setDateDissolveInit(dissolveDateDefault ? dissolveDateDefault : moment());
      setVat(0);
      return;
    }
    setDateDissolveInit(date);
    const dateFormat = moment(date).format('YYYY-MM-DD');
    return getVatByDate(dateFormat);
  };

  const onFinish = async (values) => {
    if (loading) return;
    let showError = false;

    const dataExpense = fieldsExpense?.map((item) => {
      if (
        !item?.price ||
        !item.vat ||
        !item.total ||
        (item?.billRequired && !item.billNumber) ||
        (item?.billRequired && !item.billDate) ||
        (item?.billRequired && !item.supplierName)
      ) {
        showError = true;
        return;
      }
      return {
        expenseId: item?.expenseId,
        price: item?.price,
        vat: item?.vat,
        total: item?.total,
        supplierName: item?.supplierName,
        billNumber: item?.billNumber,
        billDate: item?.billDate,
      };
    });
    if (showError) {
      return window.TmsNoti?.error('Lỗi', 'Vui lòng nhập đầy đủ thông tin');
    }
    const dataSend = {
      expenses: dataExpense,
      dissolveDate: values?.dissolveDate && moment(values?.dissolveDate).format('YYYY-MM-DD'),
      folder: values?.folder,
      note: values?.note,
      bookingDeliveryContRequestDate:
        dataInfo?.bookingDeliveryContRequestDate &&
        moment(dataInfo?.bookingDeliveryContRequestDate).format('YYYY-MM-DD'),
    };
    setLoading(true);
    return ApiServices.createBookingCont(dataInfo.contId, dataSend)
      .then((res) => {
        if (res?.data) {
          window.TmsNoti.success('Thao tác thành công', '', 5, 'top');
          setFieldsExpense([]);
          reload(dataInfo.contId);
          formRef.current?.resetFields();
        }
      })
      .catch((err) => {
        console.log('Create Booking cont failed: ', err);
      })
      .finally(() => setLoading(false));
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const renderItem = (item, index) => {
    return (
      <FeeItem
        key={item.expenseId}
        index={index}
        data={item}
        vat={vat}
        fieldsExpense={fieldsExpense}
        setFieldsExpense={setFieldsExpense}
        jobId={dataInfo?.jobId}
      />
    );
  };

  return (
    <div>
      <HeaderFormCreate dataInfo={dataInfo} />
      <Divider
        style={{
          color: 'blue',
        }}
      >
        Thông tin chi phí{' '}
      </Divider>
      <Form
        name='basic'
        form={form}
        ref={formRef}
        labelCol={{
          span: 16,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        layout='vertical'
        fields={[
          {
            name: ['dissolveDate'],
            value: dateDissolveInit
              ? moment(dateDissolveInit)
              : dissolveDateDefault
              ? moment(dissolveDateDefault)
              : moment(),
          },
        ]}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label='Ngày giải trừ'
              name='dissolveDate'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập ngày giải trừ!',
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                placeholder='Ngày giải trừ'
                style={{ width: '100%' }}
                format={DATE_FORMAT}
                onChange={handleChangeDissolveDate}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='STT Folder'
              name='folder'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập STT Folder!',
                },
              ]}
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Ghi chú' name='note'>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {fieldsExpense?.length && fieldsExpense?.map(renderItem)}
        <Form.Item
          wrapperCol={{
            offset: 10,
            span: 16,
          }}
        >
          <Button
            type='primary'
            htmlType='submit'
            loading={loading}
            style={{
              padding: '0px 30px',
            }}
          >
            Lưu
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { FormCreate };
