export const COLUMNS = [
  {
    title: 'STT',
    with: 40,
    dataIndex: 'index',
  },
  {
    title: 'Ngày',
    with: 40,
    dataIndex: 'date',
  },
  {
    title: 'Lấy',
    with: 40,
    dataIndex: 'totalUp',
  },
  {
    title: 'Hạ',
    with: 40,
    dataIndex: 'totalDown',
  },
  {
    title: 'Số tour',
    with: 40,
    dataIndex: 'total',
  },
  {
    title: 'Số tour CK',
    with: 40,
    dataIndex: 'totalTransfer',
  },
];
export const EXPORT_HEADER = [
  {
    index: 'STT',
    date: 'Ngày',
    totalUp: 'Lấy',
    totalDown: 'Hạ',
    total: 'Số Tour',
    totalTransfer: 'Số Tour Ck',
  },
];
export const MERGE_COLUMN_SHEET = [];
