export const WEBSITE_URL = '';

// export const SERVER_URL = 'http://113.161.174.80:5657/';

// export const PATH_PREFIX = '/api';

export const Endpoint = {
  login: '/Logins',
  customer: '/Customers',
  gara: '/Garas',
  staticVariables: '/StaticVariables',
  car: '/Cars',
  materialType: '/MaterialTypes',
  unit: '/Units',
  supplierMaterial: '/SupplierMaterials',
  driver: '/Drivers',
  locationType: '/LocationTypes',
  shippingLine: '/ShippingLines',
  port: '/Ports',
  area: '/Sections',
  location: '/Locations',
  material: '/Materials',
  tire: '/Tires',
  tasklist: '/TaskLists',
  romooc: '/Romoocs',
  contType: '/ContTypes',
  cargoType: '/CargoTypes',
  order: '/Orders',
  distance: '/Distances',
  taskJob: '/TaskJobs',
  assigns: '/Assigns',
  user: '/Users',
  group: '/Groups',
  vendor: '/Vendors',
  cont: '/Conts',
  draft: '/Drafts',
  company: '/Companies',
  assign: '/Assigns',
  updateBooking: '/Orders/BookingInformation',
  updateBill: '/Orders/BillInformation',
  updateTransit: '/Orders/TransitInformation',
  addContOrder: '/Orders/AddCont',
  reportVehicle: '/Reports/ReportVehicle',
  reportRomooc: '/Reports/ReportRomooc',
  reportOrderByStatus: '/Reports/ReportOrderByStatus',
  reportOrderByCustomer: '/Reports/ReportOrderByCustomer', //
  reportOrderByVendor: '/Reports/ReportOrderByVendor', //
  reportFuelByVendor: '/Reports/ReportFuelByVendor', ///Reports/RPDetailContByCustomer
  rPDetailContByCustomer: '/Reports/RPDetailContByCustomer',
  rPDetailContByCustomerCommission: '/Reports/RPDetailContByCustomerCommission',
  rPDetailContByCustomerUnitrans: '/Reports/RPDetailContByCustomerUnitrans',
  reportDrivingLicense: '/Reports/RPDrivingLicense',
  oilCoupons: '/OilCoupons',
  logNotifications: '/LogNotifications',
  rPDetailContByShippingLine: '/Reports/RPContByShippingLine',
  rPDetailOilKM: '/Reports/RPDetailOilKM',
  rPDetailAllJob: '/Reports/RPDetailAllJob',
  carInspection: '/CarInspections',
  carInsurance: '/CarInsurances',
  carSign: '/CarSigns',
  romoocInspection: '/RomoocInspections',
  rPRomoocInspection: '/Reports/RPRomoocInspection',
  rPVehicleInspection: '/Reports/RPVehicleInspection',
  replacementCategories: '/ReplacementCategories',
  rPVehicleReplacement: '/Reports/RPVehicleReplacement',
  carReplacementList: '/CarReplacements/CarReplacementList',
  dataTransfers: '/DataTransfers',
  depotCont: '/Conts/DepotConts',
  acceptOrDeclineAssign: '/Assigns/AcceptOrDecline',
  reportTourByMonth: '/Reports/RPTourByMonth',
  reportTourByDate: '/Reports/RPTourByDate',
  reportDetailTour: '/Reports/RPDetailTour',
  transferJobs: '/DataTransfers/TransferJobs',
  getListTransfer: '/TaskJobs/GetListTransfer',
  completeJobsFromPartner: '/TaskJobs/CompleteJobsFromPartner',
  rollBackCont: '/Conts/RollbackCompleteBookingCont',
  searchContNumber: '/Conts/SearchContNumber',
  cancelTransferJobs: '/DataTransfers/TransferBackJobs',
  importData: '/ImportDatas',
  singleCont: '/SingleConts',
  menu: '/Menus',
  group: '/Groups',
  function: '/SystemFunctions',
  goodsMaterialNotes: '/GoodsMaterialNotes',
  taskJobExtras: '/TaskJobExtras',
  dataTransfers: '/DataTransfers',
  oilPrices: '/OilPrices',
  reportOilSummary: '/Reports/RPOilSummary',
  reportOilSummaryExport: '/Reports/RPOilSummaryExport',
  reportRPoilFuelDistribution: '/Reports/RPoilFuelDistribution',
  reportRPoilFuelDistributionExport: '/Reports/RPoilFuelDistributionExport',
  reportRPoilFuelDistribution2: '/Reports/RPoilFuelDistribution2',
  reportRPoilFuelDistribution2Export: '/Reports/RPoilFuelDistribution2Export',
  reportOilKm: '/Reports/RPOilKM',
  reportOilKmExport: '/Reports/RPOilKMExport',
  vats: '/Vats',
  expenses: '/Expenses',
  taskJobsByPartner: '/TaskJobs/GetListByPartner',
  taskJobsReceivedPartner: '/TaskJobs/GetListReceivedByPartner',
  ticket: '/Tickets',
  supplierBill: '/SupplierBills',
  dissolveLocks: '/DissolveLocks',
  managementCosts: '/ManagementCosts',
  managementSalaries: '/ManagementSalaries',
  contracts: '/Contracts',
  exportDatas: '/ExportDatas',
  accountants: '/Accountants',
  carReplacements: '/CarReplacements',
  notifications: '/Notifications',
  reports: '/Reports',
  drivingLicenses: '/DrivingLicenses',
  transitConts: '/TransitConts',
  locationConfigs: '/LocationConfigs',
  // ONP Service
  SPONP: '/SPONP',
  // WMS (Kho)
  WMS: '/SPWMS',
  // Rvs order by TMS
  cancelOrders: '/CancelOrders',
  takePhotos: '/TakePhotos',
  liftingFees: '/LiftingFees',
  oilInput: '/OilCoupons/OilInput',
  oilTotal: '/OilCoupons/OilTotal',
  oilCheck: '/OilCoupons/OilCheck',
};
