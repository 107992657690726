import { message } from 'antd';
import { CONT_ORDER_TYPES, TASK_STATUSES } from '~commons/constants';
import ApiServices from '~services';
const INPUT_CONT_NUMBER_CONDITIONS = [
  {
    sort: 1,
    status: TASK_STATUSES.PREPARE,
    typeOrder: CONT_ORDER_TYPES.BOOKING,
  },
  {
    sort: 6,
    status: TASK_STATUSES.PREPARE,
    typeOrder: CONT_ORDER_TYPES.SUPPORT,
  },
  {
    sort: 1,
    status: 2,
    typeOrder: CONT_ORDER_TYPES.BOOKING,
    prevTypeOrder: CONT_ORDER_TYPES.SUPPORT,
  },
];

const INPUT_CONT_NUMBER_CONDITIONS_FINAL_TASK = [
  {
    sort: 1,
    status: TASK_STATUSES.FINISH,
    typeOrder: CONT_ORDER_TYPES.BOOKING,
  },
  {
    sort: 6,
    status: TASK_STATUSES.FINISH,
    typeOrder: CONT_ORDER_TYPES.SUPPORT,
  },
  {
    sort: -1,
    status: TASK_STATUSES.FINISH,
    typeOrder: CONT_ORDER_TYPES.SUPPORT,
  },
  {
    sort: 1,
    status: TASK_STATUSES.FINISH,
    typeOrder: CONT_ORDER_TYPES.BOOKING,
    prevTypeOrder: CONT_ORDER_TYPES.SUPPORT,
  },
];
const GET_ROMOOC_SORT = 3;
const ROMOOC_TASK_CONDITIONS = [
  {
    sort: GET_ROMOOC_SORT,
    typeOrder: CONT_ORDER_TYPES.SUPPORT,
  },
];
const ENABLE_SUPPORT_SORTS = [5, 6, 7, 8, 9];
const GET_CONT_AGAIN_SORT = 6;

export default function useFinishTaskApi() {
  const checkIsDisabledSuccess = (record, jobs) => {
    if (record.status === TASK_STATUSES.PENDING) {
      return true;
    }
    const isNotPrepare = record.status !== TASK_STATUSES.PREPARE;
    if (record.typeOrder === CONT_ORDER_TYPES.SUPPORT) {
      const nextJob = jobs.length >= record.index && jobs[record.index * 1 + 1];
      if (nextJob?.typeOrder === CONT_ORDER_TYPES.SUPPORT) {
        return isNotPrepare;
      }
      if (
        [CONT_ORDER_TYPES.BOOKING, CONT_ORDER_TYPES.BILL].includes(nextJob?.typeOrder) &&
        record.sort === GET_ROMOOC_SORT
      ) {
        return true;
      }
      const prevJob = jobs.length >= record.index && jobs[record.index * 1 - 1];
      if (prevJob?.typeOrder === CONT_ORDER_TYPES.SUPPORT) {
        return isNotPrepare;
      }
      if (ENABLE_SUPPORT_SORTS.includes(record.sort)) {
        return isNotPrepare;
      }
      return true;
    }
    if (isNotPrepare) {
      const prevJob = jobs.length >= record.index && jobs[record.index * 1 - 1];
      if (
        prevJob?.status === TASK_STATUSES.PREPARE &&
        prevJob?.typeOrder === CONT_ORDER_TYPES.SUPPORT
      ) {
        if (prevJob.sort === GET_CONT_AGAIN_SORT) {
          return true;
        }
        if (prevJob.sort !== GET_ROMOOC_SORT) {
          return true;
        }
        return [CONT_ORDER_TYPES.BOOKING, CONT_ORDER_TYPES.BILL].includes(prevJob?.typeOrder);
      }
    }
    return isNotPrepare;
  };

  const checkIsGetContNumber = (job, jobs) => {
    for (let condition of INPUT_CONT_NUMBER_CONDITIONS) {
      if (
        condition.sort === job.sort &&
        condition.status === job.status &&
        condition.typeOrder === job.typeOrder
      ) {
        if (condition.prevTypeOrder) {
          const prevType = jobs.length >= job.index && jobs[job.index * 1 - 1]?.typeOrder;
          return prevType === condition.prevTypeOrder;
        }
        return true;
      }
    }
    return false;
  };
  // Tại thằng điều IDIOT nên cho thay đổi địa điểm lấy MỌT => 2 task(task lấy mooc và task tiếp theo) => A MINH sửa thành địa điểm nào kug update được nha!!!
  const getDepotTasks = (job, jobs) => {
    if (job.status === TASK_STATUSES.FINISH) {
      return;
    }
    // Ko cho phép thay đổi địa điểm khi đi lấy Romooc
    for (let condition of ROMOOC_TASK_CONDITIONS) {
      if (job.sort === condition.sort && condition.typeOrder === job.typeOrder) {
        return;
      }
    }
    // if (job.sort === CONT_ORDER_TYPES.BOOKING) {
    return jobs.length > job.index + 1 ? [jobs[job.index], jobs[job.index + 1]] : [jobs[job.index]];
    // }
  };

  const checkIsGetContNumberIsFinalTask = (job, jobs) => {
    for (let condition of INPUT_CONT_NUMBER_CONDITIONS_FINAL_TASK) {
      if (
        condition.sort === job.sort &&
        condition.status === job.status &&
        condition.typeOrder === job.typeOrder
      ) {
        if (condition.prevTypeOrder) {
          const prevType = jobs.length >= job.index && jobs[job.index * 1 - 1]?.typeOrder;
          return prevType === condition.prevTypeOrder;
        }
        return true;
      }
    }
    return false;
  };

  const finishTask = async (
    job,
    reload,
    setLoading,
    files = null,
    contNumber = null,
    sealNumber = null
  ) => {
    setLoading(true);
    return ApiServices.changeStatusUploadFile({
      jobId: job.id,
      updateValue: TASK_STATUSES.FINISH,
      files,
      contNumber,
      sealNumber,
    })
      .then(() => {
        reload && reload();
      })
      .catch((err) => {
        console.error(err);
        message.error('Có lỗi xảy ra, vui lòng thử lại sau');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const updateCont = async (
    contId,
    contNumber,
    reload,
    setLoading,
    contFiles = null,
    uploadFiles = null,
    sealNumber = null
  ) => {
    setLoading?.(true);
    return ApiServices.updateContNumber({
      contId,
      contNumber,
      contFiles,
      files: uploadFiles,
      sealNumber,
    })
      .then((res) => {
        if (res?.data?.data === 'success') {
          reload && reload();
          message.success('Cập nhật thành công!');
        }
      })
      .catch((err) => {
        console.error(err);
        message.error('Có lỗi xảy ra, vui lòng thử lại sau');
      })
      .finally(() => {
        setLoading?.(false);
      });
  };
  const onUpdateNote = (id, data, reload, setLoading) => {
    setLoading(true);
    return ApiServices.updateNote(id, data)
      .then((res) => {
        console.log('update note success', res);
        reload && reload();
      })
      .catch(() => {
        message.error('Ghi chú thất bại!');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const changeLocationGetCont = (data, reload, setLoading) => {
    setLoading(true);
    return ApiServices.createTaskJobVehicle(data)
      .then((res) => {
        console.log('update note success', res);
        reload && reload();
      })
      .catch(() => {
        message.error('Cập nhật thất bại!');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkDistances = async (currentLocation, newLocation) => {
    console.log({ currentLocation, newLocation });
    try {
      const { data } = await ApiServices.checkIssetDistances([
        {
          begin: currentLocation,
          end: newLocation,
        },
      ]);
      if (data) return data.data;
    } catch (err) {
      console.error('checkDistances', err);
    }
  };

  const checkDistanceMultiple = async (dataCheck) => {
    if (!dataCheck?.length) return;
    try {
      const { data } = await ApiServices.checkIssetDistances(dataCheck);
      if (data) return data.data;
    } catch (err) {
      console.error('checkDistances', err);
    }
  };

  const createDistance = async (locationFromCode, locationToCode, distanceKm, loss = 0) => {
    const dataSend = [
      {
        locationFromCode,
        locationToCode,
        distanceKm,
        loss,
        companyId: JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId,
      },
    ];

    try {
      const { data } = await ApiServices.createDistanceMultiple(dataSend);
      if (data) {
        return data;
      }
    } catch (err) {
      console.error('Thêm mới khoảng cách thất bại', err);
    }
  };

  const createDistanceMultiple = async (dataList) => {
    const companyId = JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId;
    const dataSend = dataList?.map((item) => {
      return {
        locationFromCode: item?.begin,
        locationToCode: item?.end,
        distanceKm: item?.distanceKm,
        loss: item?.loss,
        companyId,
      };
    });

    try {
      const { data } = await ApiServices.createDistanceMultiple(dataSend);
      if (data) {
        return data;
      }
    } catch (err) {
      console.error('Thêm mới khoảng cách thất bại', err);
    }
  };
  const replaceLocation = (jobsId, locationId, reload) => {
    return ApiServices.replaceLocation({
      jobsId,
      replaceLocation: locationId,
    })
      .then((res) => {
        if (res.data) {
          message.success('Thao tác thành công!');
          reload?.();
        }
      })
      .catch((err) => {
        console.error('Change Depot Location Error', err);
      });
  };

  return {
    checkIsGetContNumber,
    checkIsGetContNumberIsFinalTask,
    finishTask,
    checkIsDisabledSuccess,
    updateCont,
    onUpdateNote,
    changeLocationGetCont,
    checkDistances,
    createDistance,
    getDepotTasks,
    replaceLocation,
    checkDistanceMultiple,
    createDistanceMultiple,
  };
}
