import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';

export const InputSearchColumns = ({ title = '', placeholder = 'Search', onChange, value }) => {
  return (
    <>
      <Wrapper>
        <Label>{title}</Label>
        <Input placeholder={placeholder} allowClear value={value} onChange={onChange} />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: center;
`;
const Label = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  margin-bottom: 4px;
`;
