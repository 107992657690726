import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, InputNumber } from 'antd';
import ApiServices from '~services';

const FormUpdateCarInspection = ({ idCar, inspectionList, getCarDetails }) => {
  const [fields, setFields] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [loading, setLoading] = useState(false);

  const getInit = async () => {
    try {
      setLoading(true);
      let { data } = await ApiServices.getReplacementCategories();
      setLoading(false);
      setIsNew(true);
      setFields([
        {
          errors: [],
          name: ['replacements'],
          value: data,
        },
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (inspectionList) {
      setIsNew(false);
      setFields([
        {
          errors: [],
          name: ['replacements'],
          value: inspectionList,
        },
      ]);
    } else {
      getInit();
    }
  }, [inspectionList]);

  const postCarReplacementList = async (data) => {
    if (!idCar) return;
    try {
      const res = await ApiServices.postCarReplacementList(idCar, data);
      if (res.data) {
        if (isNew) {
          message.success('Thêm hạng mục thành công');
        } else {
          message.success('Cập nhật thành công');
        }
        return getCarDetails();
      } else {
        if (isNew) {
          message.success('Thêm hạng mục thất bại');
        } else {
          message.success('Cập nhật thất bại');
        }
      }
    } catch (err) {
      if (isNew) {
        message.success('Thêm hạng mục thất bại');
      } else {
        message.success('Cập nhật thất bại');
      }
      console.error(err);
    }
  };

  const onSubmitForm = async (value) => {
    const mapValue = value?.replacements.map((item) => {
      if (isNew) {
        return {
          categoryId: item.id,
          name: item.name,
          note: item.note || '',
          replacementKm: item.replacementKm,
        };
      } else {
        return {
          categoryId: item.categoryId,
          name: item.name,
          note: item.note || '',
          replacementKm: item.replacementKm,
        };
      }
    });
    setLoading(true);
    await postCarReplacementList(mapValue);
    setLoading(false);
  };

  return (
    <>
      <Form
        name='global_state'
        layout='inline'
        onFinish={onSubmitForm}
        fields={fields}
        onFieldsChange={(_, allFields) => {
          setFields(allFields);
        }}
      >
        {/* -------------------  Form List   ---------------------------------*/}
        <Form.List name='replacements'>
          {(fields) => (
            <>
              {fields.map((field, index) => (
                <div key={index} style={{ display: 'flex', marginBottom: '5px' }}>
                  <Form.Item
                    label='Tên Hạng Mục'
                    required={false}
                    name={[field.name, 'name']}
                    fieldKey={[field.fieldKey, 'name']}
                    // noStyle
                  >
                    <Input placeholder='Tên Hạng mục' disabled />
                  </Form.Item>
                  <Form.Item
                    label='Số Km'
                    required={false}
                    name={[field.name, 'replacementKm']}
                    fieldKey={[field.fieldKey, 'replacementKm']}
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập số Km!',
                      },
                    ]}
                    // noStyle
                  >
                    <InputNumber min={1} type='number' />
                  </Form.Item>
                  <Form.Item
                    label='Ghi Chú'
                    required={false}
                    name={[field.name, 'note']}
                    fieldKey={[field.fieldKey, 'note']}
                  >
                    <Input.TextArea rows={1} placeholder='Ghi Chú' />
                  </Form.Item>
                </div>
              ))}
            </>
          )}
        </Form.List>
        {/* -------------------  Form List   ---------------------------------*/}
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='mg-l-5'
            loading={loading}
            style={{ marginTop: '10px', marginLeft: '300px' }}
          >
            {isNew ? 'Thêm Mới' : 'Cập Nhật'}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export { FormUpdateCarInspection };
