import { useEffect, useRef, useState, useCallback } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { Input, Space, Select, Badge, message, DatePicker, Button } from 'antd';
import { TableLayout } from '~components';
import { RangeDateFilter, UploadExcelButton } from '~components';
import { DownloadOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import { FormInput } from './form-input';
import { DATE_FORMAT } from '~commons/constants';
import { TableTitle, Wrapper } from '~commons/styles';
import Utils from '~commons/utils';
const ExcelJS = require('exceljs');

const EXCEL_TEMPLATE_URL =
  Utils.getAppUrl() + 'Files/Import_Excel/Phieu_Xuat_Kho_Sua_Chua_Xe_Romooc.xlsx';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().startOf('month').format('YYYY-MM-DD'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD'),
};
const ORDERS_OPTION_SELECTS = [
  {
    label: 'Tất cả',
    value: 0,
  },
  {
    label: 'Trong kho',
    value: 1,
  },
  {
    label: 'Vật tư ngoài',
    value: 2,
  },
];
const statusTag = (text = '', bgColor = '') => {
  return (
    <Badge>
      <div
        style={{
          backgroundColor: bgColor,
          width: '80px',
          padding: '5px 5px',
          textAlign: 'center',
          borderRadius: '4px',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '11px',
        }}
      >
        {text}
      </div>
    </Badge>
  );
};
const { Option } = Select;

export const GoodsMaterialNotesOutbound = ({ headerLength = '220px' }) => {
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [dataGoodMaterialNoteList, setDataGoodMaterialNoteList] = useState([]);
  const [selected, setSelected] = useState();
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [status, setStatus] = useState(0);
  const [tradeDate, setTradeDate] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getGoodsMaterialNotesOutbound(newQuery)
      .then((res) => {
        if (res?.data) {
          setDataGoodMaterialNoteList(res?.data);
        }
      })
      .catch((err) => {
        console.error('Get Good material note list Error', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const onUploadExcel = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importGoodsMaterialNotesOutboundInWarehouse(file)
      .then((res) => {
        if (res?.data) {
          message.success(res?.data?.message);
          timeoutGetData(filter);
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT GOODS MATERIAL NOTES ERROR', err);
        message.error('Thao tác thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };
  const onUploadExcelOutCompany = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importGoodsMaterialNotesOutboundOutCompany(file)
      .then((res) => {
        if (res?.data) {
          message.success(res?.data?.message);
          timeoutGetData(filter);
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT GOODS MATERIAL NOTES ERROR', err);
        message.error('Thao tác thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };

  // Export excel
  const handleExportExcel = async (dataExport) => {
    if (loading) return;

    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Phiếu Xuất Kho', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 30;
    sheetSummary.properties.defaultColWidth = 15;
    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getCell('G2').value = 'PHIẾU XUẤT KHO';
    sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
    const date = `Từ ${moment(filter?.DateFrom).format('DD/MM/YYYY')} Đến ${moment(
      filter?.DateTo
    ).format('DD/MM/YYYY')}`;
    sheetSummary.getCell('G3').value = date;
    // Add table
    const arrDetail = await dataExport?.map((item, indexDetail) => {
      const dataItem = [
        indexDetail + 1, // STT
        item?.ngayChungTu && new Date(moment(item?.ngayChungTu).format('YYYY-MM-DD')), // Ngày chứng từ
        item?.status === 1 ? 'Trong kho' : 'Vật tư ngoài', // Nguồn Vật Liệu
        item?.carRomoocCode, // Biên So / Romooc
        item?.materialName, // Ten Vat Lieu
        item?.materialUnit, // Đơn Vi Tính
        item?.amount, // So Lượng
        item?.price, // Đơn Giá
        item?.vat, // Vat
        item?.totalPrice, // Thanh Tiền
        item?.supplierMaterialName, // Cua hang
        item?.buyer || '', // Nguoi mua
        item?.note, // Ghi Chu
      ];
      return dataItem;
    });

    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B5',
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Ngày Chứng Từ', totalsRowLabel: '', filterButton: false },
        {
          name: 'Nguồn Vật Liệu',
          filterButton: false,
        },
        { name: 'Xe/Romooc', totalsRowLabel: '', filterButton: false },
        {
          name: 'Tên Vật Liệu',
          filterButton: false,
        },
        {
          name: 'Đơn Vị Tính',
          filterButton: false,
        },
        {
          name: 'Số Lượng',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Đơn Giá',
          filterButton: false,
        },
        {
          name: 'VAT',
          filterButton: false,
        },
        {
          name: 'Thành Tiền',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Của Hàng',
          filterButton: false,
        },
        {
          name: 'Người Mua',
          filterButton: false,
        },
        {
          name: 'Ghi Chú',
          filterButton: false,
        },
      ],
      rows: arrDetail,
    });
    // ========== STYLES =====================
    sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getColumn(6).alignment = {
      vertical: 'middle',
      horizontal: 'left',
      wrapText: true,
    };
    sheetSummary.getColumn(12).alignment = {
      vertical: 'middle',
      horizontal: 'left',
      wrapText: true,
    };
    sheetSummary.getColumn(14).alignment = {
      vertical: 'middle',
      horizontal: 'left',
      wrapText: true,
    };
    sheetSummary.getColumn(9).numFmt = '#,##0';
    sheetSummary.getColumn(11).numFmt = '#,##0';

    sheetSummary.getColumn(2).width = 5;
    sheetSummary.getColumn(6).width = 20;
    sheetSummary.getColumn(7).width = 10;
    sheetSummary.getColumn(8).width = 10;
    sheetSummary.getColumn(12).width = 20;
    sheetSummary.getColumn(14).width = 20;
    sheetSummary.getColumn(9).width = 15;
    sheetSummary.getColumn(11).width = 15;

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Phieu_Xuat_Kho_${date}_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const handleGetDataExport = async (query = DEFAULT_QUERIES) => {
    setExportLoading(true);
    const newQuery = {
      ...query,
      export: true,
    };
    return ApiServices.getGoodsMaterialNotesOutbound(newQuery)
      .then((res) => {
        if (res?.data?.data) {
          return handleExportExcel(res?.data?.data);
        }
      })
      .catch((err) => {
        console.error('get Goods Material Notes Warehouse Error: ', err);
      })
      .finally(() => {
        setExportLoading(false);
      });
  };

  const renderStatus = (value) => {
    switch (value) {
      case 1:
        return statusTag('Trong kho', 'green');
      case 2:
        return statusTag('Vật tư ngoài', '#EB455F');

      default:
        break;
    }
  };

  const handleChangeDate = (date) => {
    setTradeDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'createdOn');
  };
  const handleChangeOrderType = (value) => {
    setStatus(value);
    onColumnSearch(value, 'status');
  };
  const columns = [
    {
      title: (
        <TableTitle>
          Ngày chứng từ
          <DatePicker
            value={tradeDate}
            onChange={handleChangeDate}
            placeholder='Ngày chứng từ'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'ngayChungTu',
      align: 'center',
      width: 130,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Nguồn vật liệu
          <Select
            placeholder='Search'
            style={{ width: '100%', fontSize: '11px' }}
            defaultValue=''
            value={status}
            onChange={handleChangeOrderType}
          >
            {ORDERS_OPTION_SELECTS.map((item) => (
              <Option key={item.label + item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </TableTitle>
      ),
      dataIndex: 'status',
      align: 'center',
      width: 120,
      render: renderStatus,
    },
    {
      title: (
        <TableTitle>
          Biển số xe/Romooc
          <Input name='carRomoocCode' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'carRomoocCode',
      align: 'center',
      width: 130,
    },
    {
      title: (
        <TableTitle>
          Tên vật liệu
          <Input name='materialName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'materialName',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Đơn vị tính
          <Input name='materialUnit' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'materialUnit',
      align: 'center',
      width: 80,
    },
    {
      title: (
        <TableTitle>
          S.Lượng
          <Input name='amount' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'amount',
      align: 'center',
      width: 60,
    },
    {
      title: (
        <TableTitle>
          Đơn giá
          <Input name='price' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'price',
      align: 'center',
      width: 100,
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: (
        <TableTitle>
          VAT(%)
          <Input name='vat' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'vat',
      align: 'center',
      width: 60,
    },
    {
      title: (
        <TableTitle>
          Thành tiền
          <Input name='totalPrice' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'totalPrice',
      align: 'center',
      width: 100,
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: (
        <TableTitle>
          Cửa hàng
          <Input name='supplierMaterialName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'supplierMaterialName',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Người mua
          <Input name='buyer' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'buyer',
      align: 'center',
      width: 80,
    },
    {
      title: <TableTitle>Ghi chú</TableTitle>,
      dataIndex: 'note',
      align: 'center',
      width: 100,
    },
  ];
  const onCloseEditTab = () => setSelected();

  const pagination = {
    total: dataGoodMaterialNoteList?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  const headerContent = (
    <Space>
      <RangeDateFilter query={filter} onChange={setFilter} />
      <UploadExcelButton
        loading={loading}
        onChange={onUploadExcel}
        templateUrl={EXCEL_TEMPLATE_URL}
        importLabel='Import vật tư trong kho'
        isShowDownloadTemplate={false}
      />
      <UploadExcelButton
        loading={loading}
        onChange={onUploadExcelOutCompany}
        templateUrl={EXCEL_TEMPLATE_URL}
        importLabel='Import vật tư ngoài'
      />
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        loading={exportLoading}
        style={{ padding: '0px 20px', marginLeft: '10px' }}
        onClick={() => handleGetDataExport(filter)}
      >
        Export Excel
      </Button>
    </Space>
  );

  return (
    <Wrapper>
      <h3>Import Phiếu Xuất</h3>
      <div className='table-container-custom'>
        <TableLayout
          rowKey='id'
          columns={columns}
          data={dataGoodMaterialNoteList?.data}
          loading={loading}
          titleTab={selected?.jobName}
          headerContent={headerContent}
          headerLength={headerLength}
          isDisplayCheckbox={false}
          isScrollX={false}
          customScrollSize='30px'
          tabPanels={[
            {
              title: 'Cập nhật thông tin',
              content: (
                <FormInput
                  ref={formRef}
                  dataInput={selected}
                  reload={getData}
                  onClose={onCloseEditTab}
                />
              ),
            },
          ]}
          closeDrawer={onCloseEditTab}
          selected={selected}
          pagination={pagination}
          checkDisableSelection={(record) =>
            moment(record?.createdOn) < moment().subtract(3, 'days')
          }
        />
      </div>
    </Wrapper>
  );
};
