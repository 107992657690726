import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
const ExcelJS = require('exceljs');
import { debounce } from 'lodash';
import { Space, Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import { RangeDateFilter, Selection } from '~components';
import { ReadNumber } from '~commons/read-number';
import logo from '~assets/images/logo.png';
import Utils from '~commons/utils';

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};
const toDataURL = (url) => {
  const promise = new Promise((resolve) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });

  return promise;
};

const ReportAccountantsTransitExpense = () => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState('');
  const [customerSelected, setCustomerSelected] = useState({});

  const [dataForTable, setDataForTable] = useState([]);
  const [company, setCompany] = useState(null);
  const [vat, setVat] = useState(0);
  console.log({ vat });
  const [dataAllCustomer, setDataAllCustomer] = useState([]);

  const getCustomer = async (query = '') => {
    if (loading) return;
    setLoading(true);
    try {
      let { data } = await ApiServices.getCustomer({ query });
      if (data?.data) {
        const dataSetDefault = [
          {
            id: 'all',
            name: 'Tất Cả',
          },
        ];
        const dataGetFromApi = data?.data || [];
        const dataEnd = dataSetDefault?.concat(dataGetFromApi);
        setCustomers(dataEnd);
      }
    } catch (error) {
      console.log('Get customer list failed:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomer();
  }, []);

  const getData = async (query = DEFAULT_QUERIES, cusId) => {
    setLoading(true);
    const newQuery = { ...query };

    return ApiServices.getReportAccountantsTransitExpense(cusId, newQuery)
      .then((res) => {
        if (res?.data?.data?.company) {
          setCompany(res?.data?.data?.company || null);
        } else {
          setCompany(null);
        }
        if (res?.data?.data?.Vat) {
          setVat(res?.data?.data?.Vat || null);
        } else {
          setVat(null);
        }
        if (res?.data?.data?.transit) {
          setDataForTable(res?.data?.data?.transit || []);
        } else {
          setDataForTable([]);
        }
      })
      .catch((err) => {
        console.error('get Accountants Transit Expense By Customer Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDataAll = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };

    return ApiServices.getReportAccountantsTransitExpenseAllCustomer(newQuery)
      .then((res) => {
        if (res?.data?.data?.company) {
          setCompany(res?.data?.data?.company || null);
        }
        if (res?.data?.data?.Vat) {
          setVat(res?.data?.data?.Vat || null);
        }
        if (res?.data?.data?.all) {
          setDataAllCustomer(res?.data?.data?.all || []);
        }
      })
      .catch((err) => {
        console.error('get Accountants Transit Expense By Customer Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, newCustomerId) => {
      if (newCustomerId === 'all') {
        getDataAll(newFilter);
      } else {
        getData(newFilter, newCustomerId);
      }
    }, 500),
    []
  );
  useEffect(() => {
    if (customerId) {
      timeoutGetData(filter, customerId);
    }
  }, [filter, customerId]);

  const handleChangeCustomer = (value) => {
    if (!value) {
      setCustomerId('');
      setCustomerSelected(null);
      setDataForTable([]);
      setDataAllCustomer([]);
      return;
    }
    if (value === 'all') {
      setDataForTable([]);
      setCustomerId(value);
    } else {
      const dataCustomer = customers.find((item) => item.id === value);
      setCustomerId(value);
      setCustomerSelected(dataCustomer);
      setDataAllCustomer([]);
    }
  };

  const handleExportExcel = async () => {
    if (loading) return;
    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Hàng chuyển kho', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ state: 'frozen', ySplit: 5, activeCell: 'A1', showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 20;
    sheetSummary.properties.defaultColWidth = 20;
    // Add Image
    const resultTest = await toDataURL(logo);
    const extNameTest = 'png';
    // --------- Summary Sheet --------------------
    const imageId2Summary = workbook.addImage({
      base64: resultTest.base64Url,
      extension: extNameTest,
    });

    // sheet.mergeCells('A1:B5');
    sheetSummary.addImage(imageId2Summary, 'A1:B5');
    sheetSummary.getRow(1).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    sheetSummary.getCell('C1').value = company?.name || '';
    sheetSummary.getCell('C2').value = company?.address || '';
    sheetSummary.getCell('C3').value = company?.phone || '';

    sheetSummary.getRow(7).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getCell('G7').value = 'BẢNG TỔNG HỢP CHI PHÍ VẬN CHUYỂN HÀNG HÓA';

    sheetSummary.getCell('G8').value = `Từ Ngày ${moment(filter?.DateFrom).format(
      'DD/MM/YYYY'
    )} -> ${moment(filter?.DateTo).format('DD/MM/YYYY')}`;

    sheetSummary.getRow(9).font = {
      name: 'Arial',
      family: 2,
      size: 10,
      italic: true,
    };
    sheetSummary.mergeCells('B9:E9');
    sheetSummary.getCell('B9').value = `Khách hàng: ${customerSelected?.name}`;

    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getColumn(2).width = 10;
    sheetSummary.getColumn(3).width = 30;
    for (let i = 5; i <= 8; i++) {
      sheetSummary.getColumn(i).width = 8;
    }
    for (let i = 15; i <= 17; i++) {
      sheetSummary.getColumn(i).numFmt = '#,##0';
      sheetSummary.getColumn(i).width = 20;
    }

    sheetSummary.getRow(7).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(8).alignment = { vertical: 'middle', horizontal: 'center' };

    let sumTotal = 0;
    const arrDetail = await dataForTable?.map((item, index) => {
      const vatFee = item?.vat ? (item?.transitFee * item?.vat) / 100 : 0;
      const total = item?.transitFee + vatFee;
      sumTotal += total;

      const dataItem = [
        index + 1, // STT
        Utils.displayOrderTypeOrder(item?.orderType), // Loại Hình
        item?.subnoNumber, // Số phiếu chuyển kho
        item?.transitDate && new Date(moment(item?.transitDate).format('YYYY-MM-DD')), // Ngày Chuyển Kho
        item?.contType === `20"` ? 1 : 0,
        item?.contType === '40C' ? 1 : 0,
        item?.contType === '40T' ? 1 : 0,
        item?.contType === `45"` ? 1 : 0,
        item?.contNumber || '', // Số COnt
        item?.layCont, // DĐ đóng hàng
        item?.haCont, // DD Hạ Hàng
        item?.xeLay, // Xe Lấy Hàng
        item?.driverName, // Tài Xế
        item?.note, // Ghi chu
        item?.transitFee, // Phi Vận Chuyển
        vatFee, // VAT Fee
        total, // Tổng Cộng
      ];
      return dataItem;
    });
    sheetSummary.getRow(11).alignment = { vertical: 'center', horizontal: 'center' };

    // Add Table
    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'A11',
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Loại Hình', filterButton: false },
        {
          name: 'Số Phiếu Chuyển Kho',
          filterButton: false,
        },
        {
          name: 'Ngày Chuyển Kho',
          filterButton: false,
        },
        {
          name: `20"`,
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: `40C`,
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: `40T`,
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: `45"`,
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Số CONTAINER',
          filterButton: false,
        },
        {
          name: 'Địa Điểm Đóng Hàng',
          filterButton: false,
        },
        {
          name: 'Địa Điểm Hạ Hàng',
          filterButton: false,
        },
        {
          name: 'Xe Lấy Hàng',
          filterButton: false,
        },
        {
          name: 'Tài Xế',
          filterButton: false,
        },
        {
          name: 'Ghi Chú',
          filterButton: false,
        },
        {
          name: 'Phí Vận Chuyển',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: `VAT`,
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Tổng Cộng',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
      ],
      rows: arrDetail,
    });
    // Total
    const tableLength = dataForTable?.length;
    const rowAfterTable1 = tableLength + 14;
    const rowAfterTable3 = tableLength + 15;

    sheetSummary.mergeCells(`B${rowAfterTable1}:D${rowAfterTable1}`);
    sheetSummary.getRow(rowAfterTable1).alignment = { vertical: 'center', horizontal: 'center' };
    sheetSummary.getRow(rowAfterTable1).font = {
      name: 'Arial',
      family: 4,
      size: 12,
      bold: true,
    };
    sheetSummary.getCell(`B${rowAfterTable1}`).value = `Tổng Cộng: ${ReadNumber(sumTotal)}`;

    sheetSummary.getRow(rowAfterTable3).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    sheetSummary.getRow(rowAfterTable3).alignment = { vertical: 'center', horizontal: 'center' };
    sheetSummary.mergeCells(`B${rowAfterTable3}:D${rowAfterTable3}`);
    sheetSummary.getCell(`B${rowAfterTable3}`).value = 'Xác nhận của khách hàng';

    sheetSummary.mergeCells(`N${rowAfterTable3}:Q${rowAfterTable3}`);
    sheetSummary.getCell(`N${rowAfterTable3}`).value = 'Lập Bảng';

    const rowAfterTable4 = tableLength + 18;
    sheetSummary.mergeCells(`N${rowAfterTable4}:Q${rowAfterTable4}`);
    sheetSummary.getRow(rowAfterTable4).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    sheetSummary.getRow(rowAfterTable4).alignment = { vertical: 'center', horizontal: 'center' };
    sheetSummary.getCell(`N${rowAfterTable4}`).value = 'Vũ Thị Tươi';

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Bao_Cao_Bang_Chuyen_Kho_${moment(filter?.DateFrom).format(
          'DD-MM-YYYY'
        )}_Den_${moment(filter?.DateTo).format('DD-MM-YYYY')}_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const handleExportExcelAllCustomer = async (logoConvertResult, extNameTestConvert) => {
    if (loading) return;
    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    dataAllCustomer.forEach(async (itemCustomer, indexCustomer) => {
      const sheetSummary = workbook.addWorksheet(itemCustomer?.dataCustomerName, {
        properties: { tabColor: { argb: 'FF00FF00' } },
        views: [{ state: 'frozen', ySplit: 5, activeCell: 'A1', showGridLines: false }],
      });

      sheetSummary.properties.defaultRowHeight = 20;
      sheetSummary.properties.defaultColWidth = 20;
      // Add Image
      // --------- Summary Sheet --------------------
      const imageId2Summary = workbook.addImage({
        base64: logoConvertResult.base64Url,
        extension: extNameTestConvert,
      });
      // sheet.mergeCells('A1:B5');
      sheetSummary.addImage(imageId2Summary, 'A1:B5');
      sheetSummary.getRow(1).font = {
        name: 'Arial',
        family: 4,
        size: 13,
        bold: true,
      };

      sheetSummary.getCell('C1').value = company?.name || '';
      sheetSummary.getCell('C2').value = company?.address || '';
      sheetSummary.getCell('C3').value = company?.phone || '';

      sheetSummary.getRow(7).font = {
        name: 'Arial',
        family: 2,
        size: 16,
        bold: true,
      };
      sheetSummary.getCell('G7').value = 'BẢNG TỔNG HỢP CHI PHÍ VẬN CHUYỂN HÀNG HÓA';

      sheetSummary.getCell('G8').value = `Từ Ngày ${moment(filter?.DateFrom).format(
        'DD/MM/YYYY'
      )} -> ${moment(filter?.DateTo).format('DD/MM/YYYY')}`;

      sheetSummary.getRow(9).font = {
        name: 'Arial',
        family: 2,
        size: 10,
        italic: true,
      };
      sheetSummary.mergeCells('B9:E9');
      sheetSummary.getCell('B9').value = `Khách hàng: ${itemCustomer?.dataCustomerName || ''}`;

      sheetSummary.getColumn(1).width = 5;
      sheetSummary.getColumn(2).width = 10;
      sheetSummary.getColumn(3).width = 30;
      for (let i = 5; i <= 8; i++) {
        sheetSummary.getColumn(i).width = 8;
      }
      for (let i = 15; i <= 17; i++) {
        sheetSummary.getColumn(i).numFmt = '#,##0';
        sheetSummary.getColumn(i).width = 20;
      }

      sheetSummary.getRow(7).alignment = { vertical: 'middle', horizontal: 'center' };
      sheetSummary.getRow(8).alignment = { vertical: 'middle', horizontal: 'center' };

      let sumTotal = 0;

      const arrDetail = itemCustomer?.dataCustomer?.map((item, index) => {
        const vatFee = item?.vat ? (item?.transitFee * item?.vat) / 100 : 0;
        const total = item?.transitFee + vatFee;
        sumTotal += total;

        const dataItem = [
          index + 1, // STT
          Utils.displayOrderTypeOrder(item?.orderType), // Loại Hình
          item?.subnoNumber, // Số phiếu chuyển kho
          item?.transitDate && new Date(moment(item?.transitDate).format('YYYY-MM-DD')), // Ngày Chuyển Kho
          item?.contType === `20"` ? 1 : 0,
          item?.contType === '40C' ? 1 : 0,
          item?.contType === '40T' ? 1 : 0,
          item?.contType === `45"` ? 1 : 0,
          item?.contNumber || '', // Số COnt
          item?.layCont, // DĐ đóng hàng
          item?.haCont, // DD Hạ Hàng
          item?.xeLay, // Xe Lấy Hàng
          item?.driverName, // Tài Xế
          item?.note, // Ghi chu
          item?.transitFee, // Phi Vận Chuyển
          vatFee, // VAT Fee
          total, // Tổng Cộng
        ];
        return dataItem;
      });
      sheetSummary.getRow(11).alignment = { vertical: 'center', horizontal: 'center' };
      // Total
      const tableLength = itemCustomer?.dataCustomer?.length;
      const rowAfterTable1 = tableLength + 14;
      const rowAfterTable3 = tableLength + 15;

      sheetSummary.mergeCells(`B${rowAfterTable1}:D${rowAfterTable1}`);
      sheetSummary.getRow(rowAfterTable1).alignment = { vertical: 'center', horizontal: 'center' };
      sheetSummary.getRow(rowAfterTable1).font = {
        name: 'Arial',
        family: 4,
        size: 12,
        bold: true,
      };
      sheetSummary.getCell(`B${rowAfterTable1}`).value = `Tổng Cộng: ${ReadNumber(sumTotal)}`;

      sheetSummary.getRow(rowAfterTable3).font = {
        name: 'Arial',
        family: 4,
        size: 13,
        bold: true,
      };
      sheetSummary.getRow(rowAfterTable3).alignment = { vertical: 'center', horizontal: 'center' };
      sheetSummary.mergeCells(`B${rowAfterTable3}:D${rowAfterTable3}`);
      sheetSummary.getCell(`B${rowAfterTable3}`).value = 'Xác nhận của khách hàng';

      sheetSummary.mergeCells(`N${rowAfterTable3}:Q${rowAfterTable3}`);
      sheetSummary.getCell(`N${rowAfterTable3}`).value = 'Lập Bảng';

      const rowAfterTable4 = tableLength + 18;
      sheetSummary.mergeCells(`N${rowAfterTable4}:Q${rowAfterTable4}`);
      sheetSummary.getRow(rowAfterTable4).font = {
        name: 'Arial',
        family: 4,
        size: 13,
        bold: true,
      };
      sheetSummary.getRow(rowAfterTable4).alignment = { vertical: 'center', horizontal: 'center' };
      sheetSummary.getCell(`N${rowAfterTable4}`).value = 'Vũ Thị Tươi';
      // Add Table
      await sheetSummary.addTable({
        name: `TableSummary${indexCustomer}`,
        ref: 'A11',
        headerRow: true,
        totalsRow: true,
        style: {
          theme: 'TableStyleMedium23', // 23
          // showRowStripes: true,
        },
        columns: [
          { name: 'STT', totalsRowLabel: '', filterButton: false },
          { name: 'Loại Hình', filterButton: false },
          {
            name: 'Số Phiếu Chuyển Kho',
            filterButton: false,
          },
          {
            name: 'Ngày Chuyển Kho',
            filterButton: false,
          },
          {
            name: `20"`,
            filterButton: false,
            totalsRowFunction: 'sum',
          },
          {
            name: `40C`,
            filterButton: false,
            totalsRowFunction: 'sum',
          },
          {
            name: `40T`,
            filterButton: false,
            totalsRowFunction: 'sum',
          },
          {
            name: `45"`,
            filterButton: false,
            totalsRowFunction: 'sum',
          },
          {
            name: 'Số CONTAINER',
            filterButton: false,
          },
          {
            name: 'Địa Điểm Đóng Hàng',
            filterButton: false,
          },
          {
            name: 'Địa Điểm Hạ Hàng',
            filterButton: false,
          },
          {
            name: 'Xe Lấy Hàng',
            filterButton: false,
          },
          {
            name: 'Tài Xế',
            filterButton: false,
          },
          {
            name: 'Ghi Chú',
            filterButton: false,
          },
          {
            name: 'Phí Vận Chuyển',
            filterButton: false,
            totalsRowFunction: 'sum',
          },
          {
            name: `VAT`,
            filterButton: false,
            totalsRowFunction: 'sum',
          },
          {
            name: 'Tổng Cộng',
            filterButton: false,
            totalsRowFunction: 'sum',
          },
        ],
        rows: arrDetail,
      });
    });

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Chuyen_Kho_Tat_Ca_KH_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };
  const handleExportExcelAll = async () => {
    const resultTest = await toDataURL(logo);
    const extNameTest = 'png';

    return handleExportExcelAllCustomer(resultTest, extNameTest);
  };

  return (
    <>
      <h1>BÁO CÁO BẢNG CHUYỂN KHO</h1>

      <div
        style={{
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          padding: '20px 10px',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      >
        <Space>
          <div>
            <b
              style={{
                marginLeft: '10px',
              }}
            >
              Chọn ngày:
            </b>
            <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
          </div>
          <div>
            <b>
              Khách hàng:{' '}
              {!customerId && (
                <i
                  style={{
                    color: 'red',
                    fontSize: '12px',
                  }}
                >
                  Bạn phải chọn khách hàng
                </i>
              )}
            </b>
            <br />
            <Selection
              status={!customerId ? 'error' : ''}
              data={customers}
              placeholder='Khách hàng'
              fieldValue='name'
              width={400}
              value={customerId}
              onChange={handleChangeCustomer}
              onSearch={getCustomer}
            />
          </div>
          <div>
            <br />
            {dataAllCustomer?.length > 0 ? (
              <Button
                type='primary'
                loading={loading}
                icon={<EyeOutlined />}
                style={{ padding: '0px 20px', marginLeft: '10px' }}
                onClick={handleExportExcelAll}
              >
                Xem Tất Cả
              </Button>
            ) : dataForTable?.length > 0 ? (
              <Button
                type='primary'
                loading={loading}
                icon={<EyeOutlined />}
                style={{ padding: '0px 20px', marginLeft: '10px' }}
                onClick={handleExportExcel}
              >
                Xem
              </Button>
            ) : (
              <b
                style={{
                  color: 'red',
                  fontSize: '15px',
                }}
              >
                Không có dữ liệu
              </b>
            )}
          </div>
        </Space>
      </div>
    </>
  );
};

export { ReportAccountantsTransitExpense };
