import moment from 'moment';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '~commons/constants';

export const KEYS = ['subnoNumber', 'contNumber', 'sealNumber', 'note'];
export const KEYS_OF_DATE = [];
export const KEYS_OF_DATE_TIME = [];
export const KEYS_OF_UPLOAD_FILES = ['files'];
export const formatFields = (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });
  KEYS_OF_DATE.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? moment(data[item], DATE_FORMAT) : null,
    });
  });
  KEYS_OF_DATE_TIME.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? moment(data[item], DATE_TIME_FORMAT) : null,
    });
  });
  KEYS_OF_UPLOAD_FILES.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] || [],
      errors: [],
    });
  });
  return result;
};
