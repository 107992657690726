export const KEYS = ['code', 'controllerName', 'method', 'urlApi', 'description'];
export const formatFields = (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });

  return result;
};
