import React, { useRef, useEffect, useState } from 'react';
import { Form, Input, Button, message, DatePicker, Select, Row, Col } from 'antd';
import { layout, tailLayout } from './constant';
import { DATE_FORMAT } from '~commons/constants';
import ApiServices from '~services';
import { Selection } from '~components';

const { Option } = Select;

const CarForm = ({
  fields,
  isNew,
  id,
  reload,
  onChange,
  setLoading,
  rowData,
  idLocationCurrent,
  nameLocationCurrent,
}) => {
  const inputRef = useRef(null);
  const [locations, setLocations] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [carTypes, setCarTypes] = useState([]);
  const [driverMainNotAssign, setDriversMainNotAssign] = useState([]);
  const [driverSupportNotAssign, setDriversSupportNotAssign] = useState([]);

  const getDataInit = async () => {
    const [getStaticVariable, getVendor] = await Promise.all([
      ApiServices.getStaticVariables(),
      ApiServices.getVendors(),
    ]);
    setCarTypes(getStaticVariable?.data?.CarType || []);
    setVendors(getVendor?.data?.data || []);
  };
  const getCompanies = async (query = '') => {
    const newQuery = {
      Columns: {
        name: query,
      },
    };
    try {
      const res = await ApiServices.getCompanies(query ? newQuery : null);
      if (res?.data?.data) {
        setCompanies(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // New Code
  const getDriverMainListNotAssignCar = async (name = null) => {
    try {
      const { data } = await ApiServices.getDriverMainListNotAssignCar({ name });
      setDriversMainNotAssign(data);
    } catch (err) {
      console.error('ERR - getDriverMainListNotAssignCar', err);
    }
  };
  const getDriverSupportListNotAssignCar = async (name = null) => {
    try {
      const { data } = await ApiServices.getDriverSupportListNotAssignCar({ name });
      setDriversSupportNotAssign(data);
    } catch (err) {
      console.error('ERR -getDriverSupportListNotAssignCar', err);
    }
  };
  console.log({ driverSupportNotAssign });
  // New code
  useEffect(() => {
    getDataInit();
    getDriverMainListNotAssignCar();
    getDriverSupportListNotAssignCar();
    getCompanies();
  }, []);

  const getLocations = async (Query = null) => {
    try {
      let { data } = await ApiServices.getLocations({ Query });
      const dataNew = data.data;
      if (idLocationCurrent && Query === null) {
        const ind = dataNew.findIndex((item) => item.id === idLocationCurrent);
        if (ind === -1) {
          dataNew.push({ id: idLocationCurrent, name: nameLocationCurrent });
        }
      }
      setLocations(dataNew);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocations();
  }, [idLocationCurrent]);

  const onCompareWithApi = (value, cb) => {
    if (inputRef.current) {
      clearTimeout(inputRef.current);
      cb();
    }
    inputRef.current = setTimeout(async () => {
      try {
        const { data } = await ApiServices.existsCodeCar(null, value);
        const isUsed = data;
        cb(isUsed);
      } catch (error) {
        console.error(error);
      }
    }, 1000);
  };

  const checkExistsCode = async (_, value) => {
    if (value.length > 2) {
      const ind = fields.findIndex((item) => {
        return item.name[0] === 'code';
      });
      if (isNew === false && value !== fields[ind].value) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Biển Số này đã tạo rồi !!!'));
        }
      }

      if (isNew === true) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Biển Số này đã tạo rồi !!!'));
        }
      }
    }
  };

  const createdCar = async (data) => {
    try {
      const res = await ApiServices.createCar(data);
      if (res.data) {
        message.success('Thêm mới Xe thành công');
        getDriverMainListNotAssignCar();
        getDriverSupportListNotAssignCar();
        return reload();
      } else {
        message.error('Thêm mới Xe thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới Xe thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const updateCar = async (data) => {
    data.id = id;
    try {
      const res = await ApiServices.updateCar(id, data);
      if (res.data) {
        message.success('Cập nhật Xe thành công');
        getDriverMainListNotAssignCar();
        getDriverSupportListNotAssignCar();
        return reload();
      } else {
        message.error('Cập nhật Xe thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật Xe thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createdCar(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updateCar(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      className='form-input-data'
      name='global_state'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            style={{ marginTop: '10px' }}
            name='code'
            label='Biển Số'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Biển Số',
              },
              {
                validator: checkExistsCode,
              },
              {
                type: 'string',
                min: 3,
                message: 'Bạn phải nhập ít nhất 3 ký tự',
              },
            ]}
          >
            <Input placeholder='Biển Số' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='driverMainCode' label='Tên Tài Xế' style={{ marginTop: '10px' }}>
            <Selection
              data={driverMainNotAssign}
              labelDefault={rowData?.driverMainName}
              fieldValue='name'
              placeholder='Tên tài xế'
              onSearch={getDriverMainListNotAssignCar}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='driverSubCode' label='Tên phụ xe'>
            <Selection
              data={driverSupportNotAssign}
              labelDefault={rowData?.driverSubName}
              fieldValue='name'
              placeholder='Tên phụ xe'
              onSearch={getDriverSupportListNotAssignCar}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='companyId'
            label='Công Ty'
            rules={[
              {
                required: true,
                message: 'Bạn phải chọn Công Ty',
              },
            ]}
          >
            <Selection
              labelDefault={rowData?.companyName || ''}
              data={companies}
              placeholder='Bạn phải chọn Công Ty'
              fieldValue='name'
              onSearch={getCompanies}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='vendorId'
            label='Nhà Xe'
            rules={[
              {
                required: true,
                message: 'Bạn phải chọn Nhà Xe',
              },
            ]}
          >
            <Select showSearch placeholder='Lựa Chọn Nhà Xe'>
              {vendors.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='labelCar' label='Nhãn Hiệu'>
            <Input placeholder='Nhãn Hiệu' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='typeCar' label='Chủng Loại'>
            <Select showSearch placeholder='Chủng Loại'>
              {Object.keys(carTypes).map((item, index) => (
                <Option key={index} value={carTypes[item]}>
                  {carTypes[item]}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='engineType' label='Loại Động Cơ'>
            <Input placeholder='Loại Động Cơ' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='madeIn' label='Nơi Sản Xuất'>
            <Input placeholder='Nơi Sản Xuất' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='madeYear' label='Năm Sản Xuất' type='number'>
            <Input placeholder='Năm Sản Xuất' type='number' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='frameNumber' label='Số Khung'>
            <Input placeholder='Số Khung' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='machineNumber' label='Số Máy'>
            <Input placeholder='Số Máy' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='inspectionNumber' label='Số Kiểm Định'>
            <Input placeholder='Số Kiểm Định' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='inspectionDate' label='Ngày Kiểm Định'>
            <DatePicker
              placeholder='Ngày Kiểm Định'
              style={{ width: '100%' }}
              format={DATE_FORMAT}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='inspectionExpirationDate' label='HH Kiểm Định'>
            <DatePicker
              placeholder='Ngày Hết Hạn Kiểm Định'
              style={{ width: '100%' }}
              format={DATE_FORMAT}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='inspectionCost' label='Chi Phí Kiểm Định'>
            <Input placeholder='Chi Phí Kiểm Định' type='number' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='buyDate' label='Ngày Mua'>
            <DatePicker placeholder='Ngày Mua' style={{ width: '100%' }} format={DATE_FORMAT} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='currentLocationCode'
            label='Đ.Điểm Hiện Tại'
            rules={[
              {
                required: true,
                message: 'Bạn phải chọn Địa Điểm Hiện Tại',
              },
            ]}
          >
            <Selection
              data={locations}
              placeholder='Địa Điểm Hiện Tại'
              fieldValue='name'
              onSearch={getLocations}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='buyCost' label='Giá Mua'>
            <Input placeholder='Giá Mua' type='number' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='useBeginDate' label='Ngày Sử Dụng'>
            <DatePicker placeholder='Ngày Sử Dụng' style={{ width: '100%' }} format={DATE_FORMAT} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='depreciation' label='Khấu Hao'>
            <Input placeholder='Số Tháng Khấu Hao' type='number' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='warrantyExpenses' label='Phí Bảo Hiểm'>
            <Input placeholder='Phí Bảo Hiểm' type='number' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='oilQuota' label='Định Mức Dầu'>
            <Input placeholder='Định Mức Dầu' type='number' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='milestoneMaintenanceKm' label='Mốc Bảo Trì Km'>
            <Input placeholder='Mốc Bảo Trì Km' type='number' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='oilOfCar' label='Dầu Trong Xe'>
            <Input placeholder='Dầu Trong Xe' type='number' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='cnSignNumber' label='Số CN Phù Hiệu'>
            <Input placeholder='Số CN Phù Hiệu' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='cnSignExpDate' label='HH Phù Hiệu'>
            <DatePicker
              placeholder='Hết Hạn Phù Hiệu'
              style={{ width: '100%' }}
              format={DATE_FORMAT}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='cnInsurranceNumber' label='Số CN Bảo Hiểm'>
            <Input placeholder='Số CN Bảo Hiểm' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='cnInsurranceExpDate' label='HH Bảo Hiểm'>
            <DatePicker
              placeholder='Hết Hạn Bảo Hiểm'
              style={{ width: '100%' }}
              format={DATE_FORMAT}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='note' label='Ghi Chú'>
            <Input.TextArea placeholder='Ghi Chú' />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item {...tailLayout} className='submit-form-input-data'>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { CarForm };
