import React from 'react';
import { Table, Checkbox } from 'antd';
import moment from 'moment';
import Utils from '~commons/utils';
import { TableTitle } from '~commons/styles';

export const ShowDataAfterImport = ({ data }) => {
  const renderIsVendor = (value) => {
    if (value) {
      return <Checkbox checked />;
    }
    return '';
  };
  const renderContNumber = (contValue, record) => {
    return (
      <>
        <div>{contValue}</div>
        {record?.commission && (
          <i
            className='red'
            style={{
              fontSize: '11px',
            }}
          >
            Cont Ủy Quyền
          </i>
        )}
      </>
    );
  };

  const renderCreateOnTicket = (value) => {
    if (!value) return;
    return moment(value).calendar();
  };

  const columns = [
    {
      title: <TableTitle>Số phiếu</TableTitle>,
      dataIndex: 'aid',
      align: 'center',
      width: 100,
    },
    {
      title: <TableTitle>Folder</TableTitle>,
      dataIndex: 'folder',
      align: 'center',
      width: 150,
    },
    {
      title: <TableTitle>Số Cont</TableTitle>,
      dataIndex: 'contNumber',
      align: 'center',
      width: 150,
      render: renderContNumber,
    },
    {
      title: <TableTitle>Số SubNo</TableTitle>,
      dataIndex: 'subnoNumber',
      align: 'center',
      width: 200,
    },
    {
      title: <TableTitle>Khách hàng</TableTitle>,
      dataIndex: 'customerName',
      align: 'center',
      width: 200,
    },
    {
      title: <TableTitle>Ngày điều xe</TableTitle>,
      dataIndex: 'assignDate',
      align: 'center',
      width: 130,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: 'Xe ngoài',
      dataIndex: 'isVendor',
      width: 100,
      align: 'center',
      render: renderIsVendor,
    },
    {
      title: <TableTitle>Tài xế/Nhà xe</TableTitle>,
      dataIndex: 'driverMainName',
      align: 'center',
      width: 200,
    },
    {
      title: <TableTitle>Ngày giải trừ</TableTitle>,
      dataIndex: 'dissolveDate',
      align: 'center',
      width: 130,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: <TableTitle>Số tiền</TableTitle>,
      dataIndex: 'total',
      align: 'center',
      width: 150,
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: <TableTitle>Người giải trừ</TableTitle>,
      dataIndex: 'createdBy',
      align: 'center',
      width: 150,
    },
    {
      title: <TableTitle>Thời Gian Tạo Phiếu</TableTitle>,
      dataIndex: 'createdOn',
      align: 'center',
      width: 130,
      render: renderCreateOnTicket,
    },
    {
      title: <TableTitle>Ghi chú</TableTitle>,
      dataIndex: 'note',
      align: 'center',
      width: 150,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data || []}
      bordered
      size='small'
      key='id'
      // scroll={{ y: `calc(100vh - 220px)` }}
      // loading={loading}
    />
  );
};
