import { Checkbox, message, Table } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import ApiServices from '~services';

export const GroupFunction = ({ dataInput, reload, onLoading, menus }) => {
  if (!dataInput?.sysFuncs) {
    return null;
  }
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (!dataInput?.id) {
      setPermissions([]);
      return;
    }
    ApiServices.getPermissions({ groupId: dataInput?.id })
      .then((res) => {
        setPermissions(res.data.data || []);
      })
      .catch((err) => console.error('get permissions by group Id error', err));
  }, [dataInput?.id]);

  const onActionFunctions = (permissionId, addToGroup = true) => {
    onLoading(true);
    return ApiServices.setFunctionsGroup({
      groupId: dataInput?.id,
      arrSysFuncs: [permissionId],
      addToGroup,
    })
      .then((res) => {
        if (res.data) {
          message.success('Thao tác thành công!');
          reload?.();
        }
      })
      .finally(() => onLoading(false));
  };

  const menusWithPermission = useMemo(() => {
    let res = [];
    for (let menu of menus) {
      if (menu.childs?.length) {
        const resMenus = menu.childs?.filter((el) => el.permissions?.length);
        resMenus?.length && res.push(...resMenus);
        continue;
      }

      menu.permissions?.length && res.push(menu);
    }
    return res;
  }, [menus]);

  const onChangeChecked = (e, record) => {
    const checked = e.target.checked;
    if (checked) {
      setPermissions((prev) => [...prev, record.code]);
      onActionFunctions(record.id);
      return;
    }
    setPermissions((prev) => prev.filter((el) => el !== record.code));
    onActionFunctions(record.id, false);
  };
  const renderPermissions = (values) => {
    return values.map((el) => {
      return (
        <Checkbox
          key={el.id}
          checked={permissions?.includes(el.code)}
          onChange={(e) => onChangeChecked(e, el)}
        >
          {el.name}
        </Checkbox>
      );
    });
  };
  const columns = [
    {
      title: 'Tên',
      dataIndex: 'name_vi_VN',
    },
    {
      title: 'Quyền',
      dataIndex: 'permissions',
      render: renderPermissions,
    },
  ];

  return <Table dataSource={menusWithPermission} rowKey='id' columns={columns} />;
};
