import { DATE_FORMAT } from '~commons/constants';
import moment from 'moment';

export const KEYS = [
  'code',
  'name',
  'companyId',
  'companyName',
  'label',
  'typeRomooc',
  'typeContCode',
  'typeContName',
  'madeIn',
  'loads',
  'weight',
  'frameNumber',
  'inspectionNumber',
  'inspectionCost',
  'otherInfo',
  'currentLocationCode',
  'currentLocationName',
  'contNumber',
  'price',
  'depreciation',
  'warrantyExpenses',
  'note',
  'ownerRomooc',
  'geo',
];
export const KEYS_OF_DATE = ['inspectionDate', 'inspectionDeadline', 'useBeginDate'];
export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });

  // KEYS_OF_DATE.forEach((item) => {
  //   result.push({
  //     name: [item],
  //     value: data[item] ? (
  //       data[item] !== '0001-01-01T00:00:00+07:00' ? (
  //         moment(data[item], DATE_FORMAT)
  //       ) : null
  //     ) : null ,
  //   });
  // });

  KEYS_OF_DATE.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? moment(data[item], DATE_FORMAT) : null,
    });
  });

  return result;
};
