import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

import { TableDetails } from './table-details';

const ItemOilTicket = ({ itemData }) => {
  console.log({ itemData });
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const renderCarInfo = () => (
    <>
      <Col span={4} style={{ textAlign: 'center' }}>
        <b>Số Phiếu: </b>
        <br />
        <span className='blue'>{renderNumberCode(itemData?.numberCode)}</span>
      </Col>
      <Col span={4} style={{ textAlign: 'center' }}>
        <b>Số Xe: </b>
        <br />
        <span className='blue'>{itemData?.carCode}</span>
      </Col>
      <Col span={6} style={{ textAlign: 'center' }}>
        <b>Tài Xế: </b>
        <br />
        <span className='blue'>{itemData?.driverMainName}</span>
      </Col>
      <Col span={4} style={{ textAlign: 'center' }}>
        <b>Định mức: </b>
        <br />
        <span className='blue'>{itemData?.oilQuota}</span>
      </Col>
      <Col span={6} style={{ textAlign: 'center' }}>
        <b>Ngày Điều Xe: </b>
        <br />
        <span className='blue'>{itemData?.assignOn}</span>
      </Col>
    </>
  );
  const sumInfo = () => {
    return (
      <>
        <Col span={8} offset={14} style={{ textAlign: 'center', marginTop: '5px', color: 'blue' }}>
          <p>
            Tổng Số Km: <b className='red'>{itemData?.totalKmAll}</b> (Km){' '}
            {itemData?.totalKmSupport > 0 && (
              <span className='red'>(bao gồm {itemData?.totalKmSupport} Km hỗ trợ)</span>
            )}
          </p>
          <p>
            Tổng Số Lít Dầu Cần Chi: <b className='red'>{Math.round(itemData?.newOilToSupply)}</b>{' '}
            (Lit){' '}
            {itemData?.totalOilSupport > 0 && (
              <span className='red'>(bao gồm {itemData?.totalOilSupport} Lít hỗ trợ)</span>
            )}
          </p>
        </Col>
      </>
    );
  };

  const renderNumberCode = (value) => {
    if (!value) return '';

    const dataNumberCode = value?.split('U&I');
    const displayNumberCode = dataNumberCode ? `${dataNumberCode[0]} U&I ${dataNumberCode[1]}` : '';
    return displayNumberCode;
  };

  let jobList = [];
  if (itemData?.jobs?.length > 0) {
    jobList = jobList.concat(itemData?.jobs);
  }
  if (itemData?.supportJobs?.length > 0) {
    const extraJobsMap = itemData?.supportJobs?.map((item) => {
      item.code = 'Điều xe hỗ trợ';
      item.isSupport = true;
      return item;
    });
    jobList = jobList.concat(extraJobsMap);
  }
  return (
    <>
      <div style={{ marginTop: '5px', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          danger
          type='link'
          size='small'
          icon={isOpen ? <DownOutlined /> : <RightOutlined />}
          onClick={handleOpen}
          style={{ marginRight: '5px' }}
        >
          <span style={{ color: 'blue' }}>
            {renderNumberCode(itemData?.numberCode)}
            <span style={{ color: 'black' }}> - Số xe: {itemData?.carCode}</span>
          </span>
        </Button>
      </div>
      {isOpen && (
        <div
          style={{
            borderRadius: '6px',
            transition: 2,
            transitionDelay: 2,
            boxShadow:
              'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          }}
        >
          <Row style={{ marginBottom: '10px', padding: '5px 10px' }}>{renderCarInfo()}</Row>
          <TableDetails dataDetails={jobList ?? []} />
          <Row>{sumInfo(itemData?.car)}</Row>
        </div>
      )}
    </>
  );
};

export { ItemOilTicket };
