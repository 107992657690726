import React, { useState, useEffect, useCallback } from 'react';
import { Input, Col, Row, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import { Selection } from '~components';
import { debounce } from 'lodash';

const FormJobSupportTour = ({
  dataItemStart,
  indexJob,
  handleDeleteStartJob,
  setAssignTransitTourList,
  tourIndex,
}) => {
  const [locationList, setLocationList] = useState([]);
  const [locationId, setLocationId] = useState('');
  const [jobName, setJobName] = useState('');

  const getLocations = async (Query = null) => {
    try {
      const { data } = await ApiServices.getLocations({ Query });
      if (data?.data) {
        setLocationList(data?.data);
      }
    } catch (err) {
      console.error('ERR - get Locations', err);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  const handleChangeLocation = useCallback(
    (id) => {
      setLocationId(id);
      setAssignTransitTourList((prev) => {
        prev[tourIndex].endJob[indexJob].locationId = id;
        return [...prev];
      });
    },
    [locationId]
  );

  const timeoutSetData = useCallback(
    debounce((newValue) => {
      setAssignTransitTourList((prev) => {
        prev[tourIndex].endJob[indexJob].jobName = newValue;
        return [...prev];
      });
    }, 1000),
    []
  );

  const handleChangeJobName = useCallback(
    (event) => {
      const value = event.target.value;
      setJobName(value);
      timeoutSetData(value);
    },
    [jobName]
  );

  return (
    <div>
      <Row
        gutter={16}
        style={{
          marginTop: '10px',
        }}
      >
        <Col span={8}>
          {indexJob === 0 && (
            <>
              Công việc bổ sung <b className='red'>(*)</b>{' '}
            </>
          )}
          <Input status={!jobName && 'error'} value={jobName} onChange={handleChangeJobName} />
        </Col>
        <Col span={14}>
          {indexJob === 0 && (
            <>
              Địa điểm <b className='red'>(*)</b>{' '}
              {!locationId && <i className='red'>Vui lòng chọn Địa điểm</i>}
            </>
          )}

          <Selection
            status={!locationId && 'error'}
            data={locationList}
            placeholder='Địa điểm'
            fieldValue='name'
            value={locationId}
            onChange={handleChangeLocation}
            onSearch={getLocations}
          />
        </Col>
        <Col span={2}>
          {indexJob === 0 && <div>#</div>}
          <Button
            type='primary'
            shape='circle'
            size='small'
            danger
            icon={<CloseOutlined />}
            onClick={() => handleDeleteStartJob(dataItemStart.id)}
          />
        </Col>
      </Row>
    </div>
  );
};

export { FormJobSupportTour };
