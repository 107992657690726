import React, { useState, useEffect } from 'react';
import { Form, Button, message } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';
import { Selection } from '~components';

const TRANSIT_CONT_DATA = [0];

const OilPricesForm = ({ fields, isNew, reload, onChange, setLoading }) => {
  const [assignTransitList, setAssignTransitList] = useState([]);
  const [partners, setPartners] = useState([]);

  const getAssignTransitList = (Query) => {
    ApiServices.getContsByStatus(Query, TRANSIT_CONT_DATA, true)
      .then((res) => {
        if (res?.data?.data) {
          setAssignTransitList(res?.data?.data);
        }
      })
      .catch((err) => console.error('GetContsByStatus Error', err));
  };
  const getPartners = async (query = '') => {
    return ApiServices.getPartnerByCompany({ query })
      .then(({ data }) => {
        setPartners(data?.data || []);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getAssignTransitList();
    getPartners();
  }, []);

  const handleCreate = async (data) => {
    if (!data?.partnerId && !data?.arrTicketTransit?.length) return;
    setLoading(true);
    try {
      const res = await ApiServices.createAssignTransitPartner(
        data.partnerId,
        data.arrTicketTransit
      );
      if (res?.data?.data) {
        message.success('Thêm mới thành công');
        getAssignTransitList();
        return reload();
      } else {
        message.error('Thêm mới thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await handleCreate(value);
      setLoading(false);
      return;
    }
  };
  return (
    <Form
      {...layout}
      name='global_state'
      className='form-input-data'
      layout='vertical'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        name='partnerId'
        label='Đối tác'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn Đối tác',
          },
        ]}
      >
        <Selection data={partners} fieldValue='name' placeholder='Đối tác' />
      </Form.Item>

      <Form.Item
        name='arrTicketTransit'
        label='Phiếu chuyển kho'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn Phiếu chuyển kho',
          },
        ]}
      >
        <Selection
          data={assignTransitList}
          fieldValue='subNoNumber'
          placeholder='Phiếu chuyển kho'
          mode='multiple'
          onSearch={getAssignTransitList}
        />
      </Form.Item>

      <Form.Item {...tailLayout} className='submit-form-input-data'>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { OilPricesForm };
