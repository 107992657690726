import React, { useEffect, useState, useCallback } from 'react';
import { Button, Space, Input, DatePicker } from 'antd';
const ExcelJS = require('exceljs');
import { DownloadOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import moment from 'moment';
import { Wrapper, TableTitle } from '~commons/styles';
import { RangeDateFilter, TableLayout } from '~components';
import ApiServices from '~services';
import { DATE_FORMAT } from '~commons/constants';
import Utils from '~commons/utils';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().subtract(1, 'month').format(),
  DateTo: moment().endOf('date').format(),
};

export const ReportContsDifferenceDepot = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [searchDate, setSearchDate] = useState(null);
  const [oilPrice, setOilPrice] = useState(0);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = {
      ...query,
    };
    return ApiServices.getContDifferenceDepot(newQuery)
      .then((res) => {
        if (res?.data) {
          if (res?.data?.price) {
            setOilPrice(res?.data?.price);
          }
          setData(res?.data);
        }
      })
      .catch((err) => {
        console.error('get Cont Difference Depot Error: ', err);
        setData();
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );

  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const handleChangeDate = (date) => {
    setSearchDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'bookingDeliveryContRequestDate');
  };

  const renderTotal = (_, record) => {
    return Utils.formatNumber(record?.distance * oilPrice);
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Số Booking
          <Input name='code' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'code',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Khách Hàng
          <Input name='customerName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'customerName',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Số Cont
          <Input name='contNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'contNumber',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Loại Cont
          <Input name='contType' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'contType',
      align: 'center',
      width: 100,
    },
    {
      title: (
        <TableTitle>
          Ngày Điều Xe
          <DatePicker
            value={searchDate}
            onChange={handleChangeDate}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'assignDate',
      align: 'center',
      width: 130,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Biển Số Xe
          <Input name='carCode' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'carCode',
      align: 'center',
      width: 130,
    },
    {
      title: (
        <TableTitle>
          Số Romooc
          <Input name='romoocCode' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'romoocCode',
      align: 'center',
      width: 130,
    },
    {
      title: (
        <TableTitle>
          Nơi Lấy Cont Kế Hoạch
          <Input name='planLocation' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'planLocation',
      align: 'center',
      width: 180,
    },
    {
      title: (
        <TableTitle>
          Nơi Lấy Cont Thực Tế
          <Input name='realLocation' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'realLocation',
      align: 'center',
      width: 180,
    },
    {
      title: <TableTitle>Số KM Trái Tuyến</TableTitle>,
      dataIndex: 'distance',
      align: 'center',
      width: 80,
      fixed: 'right',
    },
    {
      title: <TableTitle>Giá Dầu</TableTitle>,
      dataIndex: 'oilPrice',
      align: 'center',
      width: 130,
      fixed: 'right',
      render: () => Utils.formatNumber(oilPrice),
    },
    {
      title: <TableTitle>Thành Tiền</TableTitle>,
      dataIndex: 'totalMoney',
      align: 'center',
      width: 130,
      fixed: 'right',
      render: renderTotal,
    },
  ];

  const exportExcel = async (dataExport) => {
    const oilPriceExport = dataExport?.price;
    if (loading) return;
    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Điều xe trái tuyến', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ state: 'frozen', ySplit: 5, activeCell: 'A1', showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 20;
    sheetSummary.properties.defaultColWidth = 15;

    sheetSummary.getCell('E3').value = 'BẢNG ĐIỀU XE TRÁI TUYẾN';
    sheetSummary.getCell('E4').value = `Từ Ngày ${moment(filter?.DateFrom).format(
      'DD/MM/YYYY'
    )} -> ${moment(filter?.DateTo).format('DD/MM/YYYY')}`;

    sheetSummary.getRow(3).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(4).alignment = { vertical: 'middle', horizontal: 'center' };

    const arrDetail = await dataExport?.data?.map((item) => {
      const dataItem = [
        item?.code, // Số Booking
        item?.customerName, // Khách Hàng
        item?.contNumber || '', // Số COnt
        item?.contType, // Loại Cont
        item?.assignDate && new Date(moment(item?.assignDate).format('YYYY-MM-DD')), // Ngày Điều Xe
        item?.carCode, // Biển Số Xe
        item?.romoocCode, // Số Romooc
        item?.planLocation, // Nơi Lấy Cont Kế Hoạch
        item?.realLocation, // Nơi Lấy Cont Thực Tế
        item?.distance, // Số KM Trái Tuyến
        oilPriceExport, // Giá Dầu
        item?.distance * oilPriceExport, // Thành tiền
      ];
      return dataItem;
    });

    // Add Table
    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'A7',
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'Số Booking', totalsRowLabel: '', filterButton: false },
        { name: 'Khách Hàng', filterButton: false },
        {
          name: 'Số Cont',
          filterButton: false,
        },
        {
          name: 'Loại Cont',
          filterButton: false,
        },
        {
          name: 'Ngày Điều Xe',
          filterButton: false,
        },
        {
          name: 'Biển Số Xe',
          filterButton: false,
        },
        {
          name: 'Số Romooc',
          filterButton: false,
        },
        {
          name: 'Nơi Lấy Cont Kế Hoạch',
          filterButton: false,
        },
        {
          name: 'Nơi Lấy Cont Thực Tế',
          filterButton: false,
        },
        {
          name: 'Số KM Trái Tuyến',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Giá Dầu',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Thành Tiền',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
      ],
      rows: arrDetail,
    });
    // Styles
    sheetSummary.getColumn(2).width = 25;
    sheetSummary.getColumn(8).width = 25;
    sheetSummary.getColumn(9).width = 25;
    sheetSummary.getColumn(10).width = 20;
    for (let i = 11; i <= 12; i++) {
      sheetSummary.getColumn(i).numFmt = '#,##0';
      sheetSummary.getColumn(i).width = 20;
    }

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Bao_Cao_Bang_Dieu_Xe_Trai_Tuyen_${moment(filter?.DateFrom).format(
          'DD-MM-YYYY'
        )}_Den_${moment(filter?.DateTo).format('DD-MM-YYYY')}_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const handleExportExcel = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = {
      ...query,
      export: true,
    };
    return ApiServices.getContDifferenceDepot(newQuery)
      .then((res) => {
        if (res?.data) {
          return exportExcel(res?.data);
        }
      })
      .catch((err) => {
        console.error('get Cont Difference Depot To Export Excel Error: ', err);
        setData();
      })
      .finally(() => setLoading(false));
  };

  const pagination = {
    total: data?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <Wrapper>
      <h3>BẢNG ĐIỀU XE TRÁI TUYẾN</h3>
      <Space style={{ marginBottom: '10px' }}>
        <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />

        {data?.data?.length > 0 ? (
          <Button
            type='primary'
            icon={<DownloadOutlined />}
            style={{ padding: '0px 20px', marginLeft: '10px' }}
            loading={loading}
            onClick={() => handleExportExcel()}
          >
            Export Excel
          </Button>
        ) : (
          <b
            style={{
              color: 'red',
              fontSize: '15px',
            }}
          >
            Không có dữ liệu
          </b>
        )}
      </Space>
      <TableLayout
        columns={columns}
        rowKey='subnoNumber'
        data={data?.data}
        loading={loading}
        pagination={pagination}
        width={900}
        isDisplayCheckbox={false}
      />
    </Wrapper>
  );
};
