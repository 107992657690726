import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, DatePicker, message } from 'antd';
import { DATE_FORMAT } from '~commons/constants';
import ApiServices from '~services';

const UpdateActualDate = ({ dataSelected, handleCancel, reload }) => {
  const [loading, setLoading] = useState(false);
  const [actualDate, setActualDate] = useState(null);

  useEffect(() => {
    setActualDate(null);
  }, [dataSelected]);

  const handleUpdateActualDate = async () => {
    if (loading) return;
    setLoading(true);
    const formatDate = moment(actualDate).format('YYYY-MM-DD');
    return ApiServices.editYCGCDateInOrder(dataSelected?.id, formatDate)
      .then((res) => {
        if (res?.data?.data === 'success') {
          message.success('Cập nhật thành công');
          reload();
        } else {
          message.error('Cập nhật thất bại');
        }
      })
      .catch((err) => {
        console.log({ err });
        message.error('Thao tác thất bại, Vui lòng thử lại sau!: ', err);
      })
      .finally(() => {
        handleCancel();
        setLoading(false);
      });
  };

  const handleChangeActualDate = (date) => {
    setActualDate(date);
  };

  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <b>
          Ngày yêu cầu giao Cont hiện tại:{' '}
          <span style={{ color: 'red' }}>{dataSelected?.deliveryContRequestDate}</span>
        </b>
      </div>
      <b>Chọn yêu cầu giao Cont mới:</b>
      <DatePicker
        allowClear={false}
        placeholder='Ngày yêu cầu giao Cont mới'
        style={{ width: '100%' }}
        format={DATE_FORMAT}
        value={actualDate}
        onChange={handleChangeActualDate}
      />
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          marginTop: '20px',
        }}
      >
        <Button
          type='primary'
          loading={loading}
          disabled={!actualDate}
          onClick={handleUpdateActualDate}
        >
          Cập nhật
        </Button>
      </div>
    </>
  );
};

export { UpdateActualDate };
