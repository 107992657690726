import React, { useEffect, useState, useCallback } from 'react';
import { Table, Button, Input, DatePicker } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { InfoCarInspection } from './inforCarInspection';
import { FormUpdateCarInspection } from './form-update';
import CarInspectionFields from './fields-data';
import { DisplayFilesSimple } from '~components/display-files-simple';
import { TableTitle } from '~commons/styles';
import { debounce } from 'lodash';
import moment from 'moment';
import { DATE_FORMAT } from '~commons/constants';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
};
const RomoocInspections = ({ id }) => {
  const [infoCarInspection, setInfoCarInspection] = useState({});
  const [inspectionList, setInspectionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowFormUpdate, setIsShowFormUpdate] = useState(false);
  const [fields, setFields] = useState({});
  const [isNew, setIsNew] = useState(false);
  const [dataCarInspection, setDataCarInspection] = useState({});
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [inspectionExpirationDateOld, setInspectionExpirationDateOld] = useState(null);
  const [inspectionDateOld, setInspectionDateOld] = useState(null);
  const [inspectionDateNew, setInspectionDateNew] = useState(null);
  const [inspectionExpirationDateNew, setInspectionExpirationDateNew] = useState(null);

  const getCarInspections = async (query = DEFAULT_QUERIES) => {
    const newQuery = { ...query };
    try {
      setLoading(true);
      let { data } = await ApiServices.getRomoocInspection(id, newQuery);
      setLoading(false);

      if (data) {
        const datainspectionList =
          data.inspectionList.data.map((item, index) => {
            item.index = index;
            return item;
          }) || [];
        setInfoCarInspection(data.infoRomooc);
        setInspectionList(datainspectionList);
      }
    } catch (error) {
      console.log('Error - get cars', error);
      console.error(error);
    }
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns };
      }
      Columns[name] = value;
      return { ...prev, Columns };
    });
  };
  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getCarInspections(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  useEffect(() => {
    getCarInspections();
  }, [id]);

  const setFieldsForm = async (value) => {
    const carInspectionFields = await CarInspectionFields(value);
    await setFields(carInspectionFields);
    return carInspectionFields;
  };

  const isShowFormUpdateFn = async () => {
    await setFieldsForm({}).then(() => {
      setIsNew(true);
      setIsShowFormUpdate(true);
    });
  };

  const onRowSelected = async (value) => {
    const _value = {
      ...value,
      inspectionDateNew: Utils.convertToDate(value.inspectionDateNew),
      inspectionExpirationDateNew: Utils.convertToDate(value.inspectionExpirationDateNew),
      files: value?.orderFiles,
    };
    await setFieldsForm(_value).then(() => {
      setIsNew(false);
      setDataCarInspection(value);
      setIsShowFormUpdate(true);
    });
  };
  const handleChangeInspectionDateOld = (date) => {
    setInspectionDateOld(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'inspectionDateOld');
  };
  const handleChangeInspectionExpirationDateOld = (date) => {
    setInspectionExpirationDateOld(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'inspectionExpirationDateOld');
  };
  const handleChangeInspectionDateNew = (date) => {
    setInspectionDateNew(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'inspectionDateNew');
  };
  const handleChangeInspectionExpirationDateNew = (date) => {
    setInspectionExpirationDateNew(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'inspectionExpirationDateNew');
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Số KĐ Cũ
          <Input name='inspectionNumberOld' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'inspectionNumberOld',
      width: 150,
      render: (value, record) => {
        if (record?.index === 0) {
          return <a onClick={() => onRowSelected(record)}>{value ? value : <FormOutlined />}</a>;
        } else {
          return <span>{value}</span>;
        }
      },
    },
    {
      title: (
        <TableTitle>
          Ngày KĐ Cũ
          <DatePicker
            value={inspectionDateOld}
            onChange={handleChangeInspectionDateOld}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'inspectionDateOld',
      width: 150,
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          Ngày HHKĐ Cũ
          <DatePicker
            value={inspectionExpirationDateOld}
            onChange={handleChangeInspectionExpirationDateOld}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'inspectionExpirationDateOld',
      width: 150,
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          Chi Phí KĐ Cũ
          <Input
            name='inspectionCostOld'
            onChange={(event) => onColumnSearch(+event.target.value, 'inspectionCostOld')}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'inspectionCostOld',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Số KĐ Mới
          <Input name='inspectionNumberNew' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'inspectionNumberNew',
      width: 130,
    },
    {
      title: (
        <TableTitle>
          Ngày KĐ Mới
          <DatePicker
            value={inspectionDateNew}
            onChange={handleChangeInspectionDateNew}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'inspectionDateNew',
      width: 150,
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          Ngày HHKĐ Mới
          <DatePicker
            value={inspectionExpirationDateNew}
            onChange={handleChangeInspectionExpirationDateNew}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'inspectionExpirationDateNew',
      width: 150,
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          Chi Phí KĐ Mới
          <Input
            name='inspectionCostNew'
            onChange={(event) => onColumnSearch(+event.target.value, 'inspectionCostNew')}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'inspectionCostNew',
      width: 130,
    },
    {
      title: <TableTitle>File Đính kèm</TableTitle>,
      dataIndex: 'orderFiles',
      width: 150,
      render: (val) => <DisplayFilesSimple files={val} />,
    },
    {
      title: (
        <TableTitle>
          Ghi Chú
          <Input name='note' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'note',
      width: 150,
    },
  ];

  return (
    <>
      {isShowFormUpdate ? (
        <FormUpdateCarInspection
          setIsShowFormUpdate={setIsShowFormUpdate}
          fields={fields}
          onChange={(newFields) => {
            setFields(newFields);
          }}
          romoocId={id}
          getCarInspections={getCarInspections}
          isNew={isNew}
          dataCarInspection={dataCarInspection}
          setLoading={setLoading}
        />
      ) : (
        <>
          <InfoCarInspection infoCarInspection={infoCarInspection} />
          <Button type='primary' className='mg-b-10 mg-t-10' onClick={isShowFormUpdateFn}>
            Thêm Mới
          </Button>
        </>
      )}

      <Table
        rowKey='id'
        bordered
        loading={loading}
        dataSource={inspectionList}
        columns={columns}
        scroll={{ x: 1000, y: 300 }}
      />
    </>
  );
};

export { RomoocInspections };
