import React, { useEffect, useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { TableLayout } from '~components';
import { Input, Button, message, Space, Badge, DatePicker, Popconfirm, Select } from 'antd';
import { PrinterOutlined, DownloadOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import { TableTitle, Wrapper } from '~commons/styles';
import { DATE_FORMAT } from '~commons/constants';
import { OnlyOilTicketDetail } from './only-oil-ticket-detail';
import { MultipleOilTicketDetail } from './multiple-oil-ticket-detail';
import Utils from '~commons/utils';
const ExcelJS = require('exceljs');

const DEFAULT_QUERIES = {
  PageSize: 20,
  PageNumber: 1,
};
const DEFAULT_ASSIGN_DATE = moment();
const STATUS_OPTION_SELECTS = [
  {
    label: 'Tất cả',
    value: '',
  },
  {
    label: 'Đã cấp',
    value: '1',
  },
  {
    label: 'Chưa cấp',
    value: '0',
  },
];
const statusTag = (text = '', bgColor = '', count = null) => {
  return (
    <Badge count={count}>
      <div
        style={{
          backgroundColor: bgColor,
          width: '100px',
          padding: '5px 10px',
          textAlign: 'center',
          borderRadius: '4px',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        {text}
      </div>
    </Badge>
  );
};
const { Option } = Select;

/**
 * cover for TMS-714 by duongnm
 * 65defc351c5425d639b23bac Vũ Văn Biên
 * 65debd98792f2170b807033e Nguyễn Hữu Tài
 */
const DRIVER_ID_LIST = ['65defc351c5425d639b23bac', '65debd98792f2170b807033e'];

const PrintOil = () => {
  const [loading, setLoading] = useState(false);
  const [oilTickets, setOilTickets] = useState([]);
  const [oilTicketDetail, setOilTicketDetail] = useState([]);
  const [selectedOnlyTicket, setSelectedShowOnlyTicket] = useState(null);
  const [assignCarDate, setAssignCarDate] = useState(DEFAULT_ASSIGN_DATE);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [selectedMultipleTicket, setSelectedMultipleTicket] = useState([]);
  const [dataMultipleOilTicketDetail, setDataMultipleOilTicketDetail] = useState([]);
  const [showMultipleTicketDetail, setShowMultipleTicketDetail] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);

  const countSelectedTicket = useMemo(() => {
    return selectedMultipleTicket?.length;
  }, [selectedMultipleTicket]);

  const getCompany = async (companyId) => {
    try {
      const res = await ApiServices.getCompanyDetails(companyId);
      if (res?.data?.data) {
        setCompanyInfo(res?.data?.data);
      }
    } catch (error) {
      console.log(`get Company Details Error:::`, error);
    }
  };

  useEffect(() => {
    const companyId = JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId;
    if (companyId) {
      getCompany(companyId);
    }
  }, []);

  const getData = async (query = DEFAULT_QUERIES, dateAssign = DEFAULT_ASSIGN_DATE) => {
    setLoading(true);
    const newQuery = { ...query };
    const convertAssignCarDate = moment(dateAssign).format('YYYY-MM-DD');
    setSelectedMultipleTicket([]);

    return ApiServices.getOilCoupons(newQuery, convertAssignCarDate)
      .then((res) => {
        if (res?.data) {
          setOilTickets(res?.data);
        }
      })
      .catch((err) => {
        console.error('Get oil tickets Error', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;

      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, assignCarDate) => {
      getData(newFilter, assignCarDate);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter, assignCarDate);
  }, [filter, assignCarDate]);

  const handleChangeAssignCarDate = (date) => {
    const newFilter = { ...filter, PageNumber: 1 };
    setFilter(newFilter);
    setAssignCarDate(date);
  };
  const handleChangeNumberCode = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    if (value) {
      onColumnSearch(`U&I${value}`, name);
    } else {
      onColumnSearch('', name);
    }
  };
  const handleSelectItem = async (record) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await ApiServices.getDetailsOilCoupon(record.id);
      if (res?.data?.data) {
        setOilTicketDetail(res?.data?.data);
        setSelectedShowOnlyTicket(record);
      }
    } catch (error) {
      console.log('Get oil ticket details failed: ', err);
    } finally {
      setLoading(false);
    }
  };
  const handleShowDetails = async (record) => {
    if (loading) return;
    setLoading(true);

    return ApiServices.getDetailsOilCoupon(record.id)
      .then((res) => {
        if (res?.data?.data) {
          setOilTicketDetail(res?.data?.data);
          setSelectedShowOnlyTicket(record);
          setShowDetails(true);
        }
      })
      .catch((err) => {
        console.log('Get oil ticket details failed: ', err);
      })
      .finally(() => setLoading(false));
  };

  const onCloseOnlyTicket = () => {
    setSelectedShowOnlyTicket(null);
    setShowDetails(false);
  };

  // const handleOilProvided = async (oilTicketId) => {
  //   if (loading) return;
  //   setLoading(true);
  //   return ApiServices.printOil({ id: oilTicketId })
  //     .then((res) => {
  //       if (res?.data?.data) {
  //         getData(filter, assignCarDate);
  //         message.success('Xác nhận thành công');
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('Accept provide oild failed:', err);
  //       message.error('Xác nhận thất bại');
  //     })
  //     .finally(() => setLoading(false));
  // };
  const handleOilMultipleProvided = async () => {
    if (loading) return;
    setLoading(true);
    return ApiServices.printMultipleOilTicket({ ids: selectedMultipleTicket })
      .then((res) => {
        if (res?.data?.data) {
          getData(filter, assignCarDate);
          message.success(`Xác nhận cấp dầu cho ${countSelectedTicket} phiếu thành công`);
          setSelectedMultipleTicket([]);
        }
      })
      .catch((err) => {
        console.log('Accept provide oild failed:', err);
        message.error('Xác nhận thất bại');
      })
      .finally(() => setLoading(false));
  };
  const handlePrintMultipleTicket = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await ApiServices.getMultipleOilTicketDetail(selectedMultipleTicket);

      if (res?.data?.data) {
        const dataSort = await res?.data?.data?.sort((a, b) => {
          let da = a.numberCode?.split('U&I')?.[1];
          let db = b.numberCode?.split('U&I')?.[1];
          return da - db;
        });
        setDataMultipleOilTicketDetail(dataSort);
        setShowMultipleTicketDetail(true);
      }
    } catch {
      (err) => {
        console.log('Provide Oil Error: ', err);
      };
    } finally {
      setLoading(false);
      setSelectedMultipleTicket([]);
    }
  };

  const onCloseMultipleTicket = () => {
    setShowMultipleTicketDetail(false);
  };

  // Export excel
  const handleExportExcel = async (dataExport, carAssignDate) => {
    if (loadingExport) return;
    setLoadingExport(true);

    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Phiếu Cấp Dầu', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 15;
    sheetSummary.properties.defaultColWidth = 15;
    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getCell('E2').value = 'PHIẾU CẤP DẦU';
    sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
    const date = `Ngày điều xe: ${moment(carAssignDate).format('DD/MM/YYYY')}`;
    sheetSummary.getCell('E3').value = date;

    // Add table
    const dataSort = dataExport?.sort((a, b) => {
      let da = a.numberCode?.split('U&I')?.[1];
      let db = b.numberCode?.split('U&I')?.[1];
      return da - db;
    });

    const arrDetail = await dataSort?.map((item, indexDetail) => {
      const dataNumberCode = item?.numberCode?.split('U&I');
      const displayNumberCode = dataNumberCode
        ? `${dataNumberCode[0]} U&I ${dataNumberCode[1]}`
        : '';

      const dataItem = [
        indexDetail + 1, // STT
        item?.carCode, // Xe
        item?.driverMainName, // Xe
        item?.code || '', // Mã Phiếu
        displayNumberCode || '', // Số Phiếu
        item?.totalKm, // Số Km
        item?.listTaskJobs?.length ? item?.listTaskJobs?.[0]?.oilQuotaByCar : 0, // Dinh Muc Dau
        item?.totalOil, // Số Lit Dau
        item?.totalLossOfOil, // So Dau Hao Hut
        item?.oilToSupplyExtra, // So Dau Hổ Tro
        item?.oilToSupply, // Số dầu Cần chi
        `Đã cấp ${item?.count} lần`, // Trang Thai
        '', // Ky Nhan
      ];
      return dataItem;
    });

    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B5',
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Xe', totalsRowLabel: '', filterButton: false },
        { name: 'Tài Xế', totalsRowLabel: '', filterButton: false },
        {
          name: 'Mã Phiếu',
          filterButton: false,
        },
        { name: 'Số Phiếu', totalsRowLabel: '', filterButton: false },
        {
          name: 'Số Km',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Định Mức Dầu',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Số Lít Dầu',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Số Dầu Hao Hụt',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Số Dầu Hỗ Trợ',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Số Dầu Cần Chi',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Trạng Thái',
          filterButton: false,
        },
        {
          name: 'Ký Nhận',
          filterButton: false,
        },
      ],
      rows: arrDetail,
    });
    // ========== STYLES =====================
    sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };
    // sheetSummary.getColumn(7).numFmt = '#,##0';
    // sheetSummary.getColumn(8).numFmt = '#,##0';
    // sheetSummary.getColumn(9).numFmt = '#,##0';
    // sheetSummary.getColumn(10).numFmt = '#,##0';
    // sheetSummary.getColumn(11).numFmt = '#,##0';

    sheetSummary.getColumn(2).width = 5;
    sheetSummary.getColumn(5).width = 25;
    sheetSummary.getColumn(5).width = 30;
    sheetSummary.getColumn(6).width = 18;
    sheetSummary.getColumn(7).width = 12;
    sheetSummary.getColumn(8).width = 15;
    sheetSummary.getColumn(9).width = 12;
    sheetSummary.getColumn(10).width = 15;
    sheetSummary.getColumn(11).width = 15;

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Phieu_Cap_Dau_Ngay_Dieu_Xe_${moment(carAssignDate).format(
          'DD/MM/YYYY'
        )}_Xuat_Excel_Luc_${moment().format('DD-MM-YY HH:mm')}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoadingExport(false);
    }
  };

  const handleGetDataExport = async (date) => {
    if (!date || loadingExport) return;
    const formatDate = moment(date).format('YYYY-MM-DD');
    setLoadingExport(true);
    try {
      const res = await ApiServices.getDataExportOilCouponList(formatDate);
      if (res?.data?.data) {
        return handleExportExcel(res?.data?.data, date);
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoadingExport(false);
    }
  };

  const renderStatus = (value, record) => {
    if (value) {
      return statusTag('Đã cấp', 'green', record?.count);
    }
    return statusTag(' Chưa cấp', '#EB455F');
  };
  const renderNumberCode = (value) => {
    if (!value) return '';

    const dataNumberCode = value?.split('U&I');
    const displayNumberCode = dataNumberCode ? `${dataNumberCode[0]} U&I ${dataNumberCode[1]}` : '';
    return displayNumberCode;
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Mã phiếu
          <Input name='code' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'code',
      width: 200,
    },
    {
      title: (
        <TableTitle>
          <div>Trạng thái</div>
          <Select
            placeholder='Search'
            style={{ width: 110 }}
            defaultValue=''
            onChange={(e) => onColumnSearch(e, 'status')}
          >
            {STATUS_OPTION_SELECTS.map((item) => (
              <Option key={item.label + item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </TableTitle>
      ),
      dataIndex: 'status',
      align: 'center',
      width: 120,
      render: renderStatus,
    },
    {
      title: (
        <TableTitle>
          Số Phiếu
          <Input name='numberCode' onChange={handleChangeNumberCode} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'numberCode',
      align: 'center',
      width: 130,
      render: renderNumberCode,
    },

    {
      title: (
        <TableTitle>
          Số xe
          <Input name='carCode' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'carCode',
      align: 'center',
      width: 100,
    },
    {
      title: (
        <TableTitle>
          Tài xế
          <Input name='driverMainName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'driverMainName',
      align: 'center',
      width: 140,
    },
    // {
    //   title: (
    //     <TableTitle>
    //       Phụ xe
    //       <Input name='driverSubName' onChange={onColumnSearch} placeholder='Search' />
    //     </TableTitle>
    //   ),
    //   dataIndex: 'driverSubName',
    //   width: 100,
    // },
    {
      title: <TableTitle>Dầu (Lít)</TableTitle>,
      dataIndex: 'totalOil',
      align: 'center',
      width: 80,
    },
    {
      title: <TableTitle>Dầu hao hụt</TableTitle>,
      dataIndex: 'totalLossOfOil',
      align: 'center',
      width: 100,
    },
    {
      title: <TableTitle>Tổng khoảng cách</TableTitle>,
      dataIndex: 'totalDistance',
      align: 'center',
      width: 100,
      render: (val) => val && Utils.formatNumber(val),
    },
    {
      title: <TableTitle>Tổng khoảng cách hao hụt</TableTitle>,
      dataIndex: 'totalLossDistance',
      align: 'center',
      width: 100,
      render: (val) => val && Utils.formatNumber(val),
    },
    // {
    //   title: (
    //     <TableTitle>
    //       Dầu chưa sử dụng
    //       <Input name='totalLossOfOil' onChange={onColumnSearch} placeholder='Search' />
    //     </TableTitle>
    //   ),
    //   dataIndex: 'totalLossOfOil',
    //   align: 'center',
    //   width: 130,
    // },
    {
      title: '#',
      fixed: 'right',
      align: 'center',
      width: 120,
      render: (record) => {
        return (
          <Button
            type='link'
            size='small'
            style={{ padding: '0px 20px' }}
            onClick={() => handleShowDetails(record)}
          >
            Xem chi tiết
          </Button>
        );
      },
    },
    {
      title: '#',
      fixed: 'right',
      align: 'center',
      width: 180,
      render: (record) => {
        if (DRIVER_ID_LIST.includes(record.id)) {
          return <></>;
        }

        return (
          <Popconfirm
            onConfirm={() => handleSelectItem(record)}
            title='Xác nhận in phiếu dầu?'
            okText='Đồng ý'
            cancelText='Không'
          >
            <Button type='link' danger icon={<PrinterOutlined />}>
              In phiếu dầu
            </Button>
          </Popconfirm>
        );
      },
    },
  ];
  const pagination = {
    total: oilTickets?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  const headerContent = (
    <Space>
      Ngày điều xe:
      <DatePicker
        value={assignCarDate}
        onChange={handleChangeAssignCarDate}
        placeholder='Ngày điều xe'
        style={{ width: '100%' }}
        format={DATE_FORMAT}
        allowClear={false}
      />
      <Badge count={countSelectedTicket}>
        <Popconfirm
          onConfirm={handleOilMultipleProvided}
          title={`Xác nhận cấp dầu cho ${countSelectedTicket} phiếu?`}
          okText='Đồng ý'
          cancelText='Không'
          disabled={countSelectedTicket < 1}
        >
          <Button
            type='primary'
            style={{ padding: '0px 20px', marginLeft: '10px' }}
            disabled={countSelectedTicket < 1}
          >
            Xác nhận cấp dầu
          </Button>
        </Popconfirm>
      </Badge>
      <Badge count={countSelectedTicket}>
        <Button
          danger
          icon={<PrinterOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px' }}
          disabled={countSelectedTicket < 1}
          onClick={handlePrintMultipleTicket}
        >
          In phiếu dầu
        </Button>
      </Badge>
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        style={{ padding: '0px 20px', marginLeft: '10px' }}
        onClick={() => handleGetDataExport(assignCarDate)}
        loading={loadingExport}
      >
        Export Phiếu cấp dầu
      </Button>
    </Space>
  );

  const rowSelection = (selectedRowKeys) => {
    setSelectedMultipleTicket(selectedRowKeys);
  };

  return (
    <Wrapper>
      <TableLayout
        rowKey='id'
        columns={columns}
        data={oilTickets?.data}
        loading={loading}
        selectParent={selectedMultipleTicket}
        onSelectChange={rowSelection}
        checkDisableSelection={(record) => DRIVER_ID_LIST.includes(record.id)}
        selected=''
        closeDrawer={() => {}}
        tabPanels={[]}
        headerContent={headerContent}
        pagination={pagination}
        headerLength='190px'
      />
      <OnlyOilTicketDetail
        selectedItem={selectedOnlyTicket}
        data={oilTicketDetail}
        onClose={onCloseOnlyTicket}
        showDetails={showDetails}
        companyInfo={companyInfo}
      />
      <MultipleOilTicketDetail
        open={showMultipleTicketDetail}
        data={dataMultipleOilTicketDetail}
        onClose={onCloseMultipleTicket}
        companyInfo={companyInfo}
      />
    </Wrapper>
  );
};

export { PrintOil };
