import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, message, Modal } from 'antd';
import moment from 'moment';
const ExcelJS = require('exceljs');
import { DownloadOutlined } from '@ant-design/icons';
import {
  fetchLiftOff,
  loadMore,
  updateQuery,
  updateLoading,
  updateSelected,
  selectedDataRowLiftOff,
} from '~store/reducers/lift-off/action';
import { showListFile, showAssignCarToday } from '~store/reducers/white-board/action';
import { ShowListFile, TableWhiteBoard, WBTableLoadMore } from '~components';
import { getColumns } from './columns';
import { Wrapper, Title } from '~commons/styles';
import { useWBTableScroll } from '~hooks';
import useApiHook from '../wb-hook/api-hook';
import Utils from '~commons/utils';
import { JobsTransfer } from '../job-transfer-button';
import { FormInput } from '../../job-handle/form-input';
import { JOB_HANDLE_WIDTH } from '~commons/constants';
import ApiServices from '~services';
import { ContNumberForm } from '../assign-car-list/cont-number-form';
const WIDTH = '600px';
export const LiftOff = () => {
  const dispatch = useDispatch();

  const { data: liftoffData, query, loading, selected } = useSelector((state) => state.liftOff);
  const { selected: dataSelectedLiftOn } = useSelector((state) => state.liftOn);

  const timeOutRef = useRef(null);
  const timeOutSetQueryRef = useRef(null);

  const { showCustomerDetail, updateAssignRomooc, canCelLiftOff } = useApiHook();

  //================= Scroll ======================
  const [partnerSelect, setPartner] = useState();
  const [contFiles, setContFiles] = useState();
  const [contDataUpdate, setContDataUpdate] = useState();
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [searchDate, setSearchDate] = useState(null);
  const [partnerJobSuccessful, setPartnerJobSuccessful] = useState(); //Job Successful To Partner
  const { tableRef, isScroll } = useWBTableScroll(null);
  const LoadMoreData = async () => {
    if (loadingMore) return;
    if (liftoffData.pageNumber * query.PageSize <= liftoffData.totalRecords) {
      setLoadingMore(true);
      let tmpQuery = { ...query, PageNumber: liftoffData.pageNumber + 1 };
      await dispatch(loadMore(tmpQuery));
      setLoadingMore(false);
    }
  };
  const setLoading = (val) => dispatch(updateLoading(val));

  useEffect(() => {
    if (isScroll) {
      LoadMoreData();
    }
  }, [isScroll]);
  useEffect(() => {
    timeOutRef.current && clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => initData(), 50);
  }, [query]);

  const initData = async () => {
    dispatch(updateLoading(true));
    await dispatch(fetchLiftOff(query));
    dispatch(updateLoading(false));
  };

  const setQuery = (columns) => {
    const tmpQuery = { ...query };
    tmpQuery.Columns = { ...tmpQuery.Columns, ...columns };

    clearTimeout(timeOutSetQueryRef.current);
    timeOutSetQueryRef.current = setTimeout(() => dispatch(updateQuery(tmpQuery)), 250);
  };
  const onSelectChange = (_, selectedRows) => {
    if (partnerSelect) {
      dispatch(updateSelected(selectedRows));
      return;
    }
    const mapData = selectedRows.map((item) => item.jobId);
    !mapData?.length && updateAssignRomooc({});
    dispatch(selectedDataRowLiftOff(mapData, selectedRows));
    dispatch(updateSelected(selectedRows));
  };
  const onSelectChangeTour = (dataRow) => {
    if (!dataRow) return;
    const selectedRows = [dataRow];

    const mapData = selectedRows.map((item) => item.jobId);
    !mapData?.length && updateAssignRomooc({});
    const isCheckPlan = false;
    dispatch(selectedDataRowLiftOff(mapData, selectedRows, isCheckPlan));
  };
  // Export Excel
  const handleExportExcel = async (dataExport) => {
    if (loadingExport) return;

    setLoadingExport(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetName = `${moment().format('DD-MM-YYYY')}`;
    const sheetSummary = workbook.addWorksheet(sheetName, {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 20;
    sheetSummary.properties.defaultColWidth = 15;
    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getCell('E2').value = 'BÁO CÁO GIAO HÀNG XUẤT - TRẢ RỖNG NHẬP';
    sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(3).font = {
      name: 'Arial',
      family: 2,
      size: 10,
      italic: true,
    };
    sheetSummary.getCell('E3').value = `${moment().format('DD-MM-YYYY')}`;

    // Add table
    const arrDetail = await dataExport?.map((item, indexDetail) => {
      const dataItem = [
        indexDetail + 1, // STT
        item?.customerName, // Khach Hang
        item?.contNumber, // So Cont
        item?.gh, // Cang Ha
        item?.booking_CutOffDateTime && moment(item?.booking_CutOffDateTime).format('DD/MM/YYYY'), // Ngay Cat Mabg
        item?.driverName, // Tai Xe
        item?.carCode, //So Xe
      ];
      return dataItem;
    });

    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B5',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        {
          name: 'Khách Hàng',
          filterButton: false,
        },
        {
          name: 'Số Cont',
          filterButton: false,
        },
        {
          name: 'Cảng Hạ',
          filterButton: false,
        },
        { name: 'Ngày Cắt Máng', totalsRowLabel: '', filterButton: false },
        {
          name: 'Họ Tên Tài Xế',
          filterButton: false,
        },
        {
          name: 'Số Xe',
          filterButton: false,
        },
      ],
      rows: arrDetail,
    });

    // ========== STYLES =====================
    sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getColumn(2).width = 5;
    sheetSummary.getColumn(3).width = 30;
    sheetSummary.getColumn(4).width = 20;
    sheetSummary.getColumn(5).width = 30;
    sheetSummary.getColumn(6).alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    sheetSummary.getColumn(6).width = 20;
    sheetSummary.getColumn(7).width = 25;

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Bao_Cao_Giao_Hang_Xuat_Tra_Rong_Nhap_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoadingExport(false);
    }
  };
  const getDriverByIdList = async (driverIdList = []) => {
    try {
      const res = await ApiServices.getListDriversReport(driverIdList);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const handleGetDataToExportExcel = async () => {
    if (loadingExport) return;
    setLoadingExport(true);
    return await ApiServices.getDataToExportWhiteBoardThree()
      .then(async (res) => {
        if (res?.data?.data) {
          const driverIdList = res?.data?.data?.map((item) => item.driverId);
          const dataDriver = await getDriverByIdList(driverIdList);
          if (dataDriver?.length) {
            const dataSend = res?.data?.data?.map((item) => {
              const driver = dataDriver?.find((driver) => driver.id === item.driverId);
              return {
                subnoNumber: item?.subnoNumber,
                customerName: item?.customerName,
                contNumber: item?.contNumber,
                gh: item?.gh,
                booking_CutOffDateTime: item?.booking_CutOffDateTime,
                carCode: item?.carCode
                  ? item?.carCode
                  : item?.childJobName?.[0]
                  ? item?.childJobName?.[0]
                  : '',
                driverName: driver ? driver?.name : '',
              };
            });
            handleExportExcel(dataSend);
          }
        }
      })
      .catch((err) => {
        console.error('Get Data To Export WhiteBoardThree Error: ', err);
      })
      .finally(() => setLoadingExport(false));
  };

  // const onExportReport = async () => {
  //   if (loadingExport) return;
  //   setLoadingExport(true);
  //   await exportReportLiftOff();
  //   setLoadingExport(false);
  // };
  const onChangeTransfer = (partner) => {
    if (!partner) {
      dispatch(updateSelected([]));
    }
    setPartner(partner);
  };
  const onShowJobSuccessful = (record) => {
    setPartnerJobSuccessful(record);
  };
  const onCloseJobSuccessful = () => {
    setPartnerJobSuccessful();
  };
  const onCancelTransferJob = (record) => {
    setLoading(true);
    ApiServices.cancelTransferJob(record?.jobId)
      .then(() => {
        initData();
        message.success('Hủy Chuyển CV thành công!');
      })
      .catch((err) => {
        console.error('Cancel Transfer Jobs error', err);
        message.error('Hủy CV Chuyển thất bại!');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const showOrderFiles = (record) => {
    dispatch(showListFile(record));
  };
  const showContFiles = (files) => setContFiles(files);
  const showAssign = (record) => {
    dispatch(showAssignCarToday(record));
  };
  const showContForm = (record) => {
    setContDataUpdate({
      contFiles: record?.contFiles,
      contNumber: record?.contNumber,
      sealNumber: record?.sealNumber,
      contId: record?.id || record?.contId,
    });
  };
  const onCloseContForm = () => setContDataUpdate();
  const renderRowKey = (record) => record.jobId?.join(',');
  const renderRowClassName = (record) =>
    `${
      record?.assignId || record?.childJobName?.[0]
        ? 'row-assigned'
        : Utils.renderTypeOrderBackground(record?.type)
    } ${record.type}`;

  const handleChangeSearchDate = (dateValue) => {
    setSearchDate(dateValue);
    const dataSearch = dateValue ? moment(dateValue).format('DD/MM') : '';
    setQuery({ allDate: dataSearch });
  };

  return (
    <Wrapper width={WIDTH}>
      <div style={{ display: 'flex' }}>
        <Title>
          Hạ(Giao Hàng Xuất - Trả Rỗng Nhập){' '}
          <b className='red'>({liftoffData?.countNotAssign || 0})</b>
          <b> ({liftoffData?.totalRecords - liftoffData?.countNotAssign})</b>
        </Title>
        <Button
          style={{ marginLeft: 'auto' }}
          shape='round'
          icon={<DownloadOutlined />}
          loading={loadingExport}
          disabled={!liftoffData?.totalRecords}
          onClick={handleGetDataToExportExcel}
        >
          Tải báo cáo
        </Button>
      </div>
      <TableWhiteBoard
        tableRef={tableRef}
        headerLength='190px'
        renderRowKey={renderRowKey}
        rowClassName={renderRowClassName}
        columns={getColumns(
          setQuery,
          showOrderFiles,
          showAssign,
          canCelLiftOff,
          onShowJobSuccessful,
          onCancelTransferJob,
          showContFiles,
          showContForm,
          showCustomerDetail,
          searchDate,
          handleChangeSearchDate,
          onSelectChangeTour,
          selected,
          dataSelectedLiftOn
        )}
        data={liftoffData?.data}
        loading={loading}
        onSelectChange={onSelectChange}
        selectedParent={selected}
        limitSelected={!partnerSelect}
        disableSelectAll={!partnerSelect}
        checkDisableSelection={(record) => !!record?.assignId || record?.childJobName?.length}
      />
      <ShowListFile
        setIsShowFile={setContFiles}
        listFiles={contFiles || []}
        title='Danh Sách Files'
      />
      <WBTableLoadMore loading={loadingMore} total={liftoffData?.data?.length} />
      <JobsTransfer onChange={onChangeTransfer} selected={selected} updateLoading={setLoading} />
      <ContNumberForm
        cont={contDataUpdate}
        isUpdateContNumber
        reload={initData}
        onClose={onCloseContForm}
        setLoading={setLoading}
      />
      <Modal
        title='Hoàn Thành Công Việc Cho ĐT'
        open={!!partnerJobSuccessful}
        footer={null}
        width={JOB_HANDLE_WIDTH}
        onCancel={onCloseJobSuccessful}
      >
        <FormInput
          dataInput={partnerJobSuccessful}
          reload={initData}
          onClose={onCloseJobSuccessful}
        />
      </Modal>
    </Wrapper>
  );
};
