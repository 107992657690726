import React, { forwardRef, useImperativeHandle, useState, useEffect, useCallback } from 'react';
import { Form } from 'antd';
import { Selection } from '~components';
import ApiServices from '~services';
import { debounce } from 'lodash';

const DEFAULT_QUERIES = {
  PageNumber: 1,
  PageSize: 150,
  Columns: {},
};

export const SwapContForm = forwardRef(({ fields, selectedCont, onChange, onSubmit }, ref) => {
  const [formRef] = Form.useForm();

  const [dataLiftOn, setDataLiftOn] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);

  const fetchLiftOn = async (query = DEFAULT_QUERIES) => {
    try {
      const res = await ApiServices.getWhiteBoardTwo(query);
      if (res?.data?.data) {
        setDataLiftOn(res?.data?.data || []);
      } else {
        setDataLiftOn([]);
      }
    } catch (error) {
      setDataLiftOn([]);
      console.error('fetchLiftOn-ERR', error);
    }
  };

  useEffect(() => {
    fetchLiftOn();
  }, [selectedCont]);

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      fetchLiftOn(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const onColumnSearch = (text) => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = text;
      const name = 'allDate';
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;

      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  useImperativeHandle(ref, () => ({
    submitForm() {
      formRef.submit();
    },
  }));

  const filterDataLiftOnToSwap = dataLiftOn
    ?.filter((item) => {
      return item.type === selectedCont.orderType && !item?.assignId;
    })
    ?.map((el) => {
      el.id = el.contId;

      return el;
    });

  return (
    <Form
      name='global_state'
      layout='vertical'
      form={formRef}
      className='form-input-data'
      onFinish={onSubmit}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      {selectedCont?.subnoNumber && (
        <>
          CONT HIỆN TẠI: <b className='blue'>{selectedCont.subnoNumber}</b>
          <hr />
        </>
      )}
      <Form.Item name='contId' label='Chọn Cont để Swap'>
        <Selection
          data={filterDataLiftOnToSwap || []}
          placeholder='Chọn Cont'
          fieldValue='subnoNumber'
          onSearch={onColumnSearch}
        />
      </Form.Item>
    </Form>
  );
});
