// import {  useState } from 'react';
import { Input, Row, Col, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  updateDistanceLocationData,
  checkDistanceLocations,
} from '~store/reducers/white-board/action';
import ApiServices from '~services';

export const InputDistance = () => {
  const dispatch = useDispatch();
  const { checkDistance } = useSelector((state) => state.whiteBoard);

  const onChangeChange = (value, index, type) => {
    if (isNaN(value)) return;
    const newCheckDistance = checkDistance?.data?.map?.((el) => el);
    newCheckDistance[index][type] = +value;
    dispatch(updateDistanceLocationData(newCheckDistance, checkDistance?.onFinish));
  };
  const disabledSubmit = checkDistance?.data?.findIndex((item) => item?.distanceKm) === -1;
  const handleSubmitForm = async () => {
    const dataSend = checkDistance?.data?.map((item) => {
      return {
        locationFromCode: item.begin,
        locationToCode: item.end,
        distanceKm: item.distanceKm,
        loss: item.loss || 0,
        companyId: JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId,
      };
    });
    return ApiServices.createDistanceMultiple(dataSend).then((res) => {
      // console.log({ checkDistance: res.data });
      if (res.data) {
        checkDistance?.onFinish?.(checkDistance?.data);
        onCloseModalDistance();
      }
    });
  };
  const onCloseModalDistance = () => {
    dispatch(checkDistanceLocations());
  };

  const renderInputs = (item, index) => {
    return (
      <Row
        gutter={24}
        key={`${index.begin}${item.end}`}
        align='middle'
        style={{ marginTop: '8px' }}
      >
        <Col span={7}>{item?.beginName}</Col>
        <Col span={1}>{'>'}</Col>
        <Col span={7}>{item?.endName}</Col>
        <Col span={4}>
          <InputStyled
            defaultValue={0}
            min={0}
            isError={!item?.distanceKm}
            className='red'
            size='small'
            value={item?.distanceKm}
            onChange={({ target }) => onChangeChange(target.value, index, 'distanceKm')}
            placeholder='Cự ly'
          />
        </Col>
        <Col span={4}>
          <InputStyled
            defaultValue={0}
            className='red'
            size='small'
            value={item?.loss}
            min={0}
            onChange={({ target }) => onChangeChange(target.value, index, 'loss')}
            placeholder='Hao hụt'
          />
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      width={650}
      title='Danh sách địa điểm chưa có khoảng cách'
      open={!!checkDistance?.data?.length}
      onCancel={onCloseModalDistance}
      okButtonProps={{
        disabled: disabledSubmit,
      }}
      onOk={handleSubmitForm}
    >
      <Row gutter={24}>
        <Col span={7}>
          <b>Điểm bắt đầu</b>
        </Col>
        <Col span={1}></Col>
        <Col span={7}>
          <b>Điểm kết thúc</b>
        </Col>
        <Col span={4}>
          <b>Cự ly(Km)</b>
        </Col>
        <Col span={4}>
          <b>Hao hụt(Km)</b>
        </Col>
      </Row>
      {checkDistance?.data?.map((item, index) => renderInputs(item, index))}
    </Modal>
  );
};
const InputStyled = styled(Input)`
  border-color: #00bfff;
  border-radius: 5px;
  border: ${({ isError }) => (isError ? '1px solid red' : '1px solid #00BFFF')};
  padding: 4px;
`;
