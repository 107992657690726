import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import {
  Input,
  Button,
  Space,
  notification,
  Checkbox,
  DatePicker,
  Modal,
  Table,
  Popconfirm,
  Spin,
} from 'antd';
import {
  DownloadOutlined,
  FormOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { RangeDateFilter, UploadExcelButton } from '~components';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { TableTitle, Wrapper } from '~commons/styles';
import { DATE_FORMAT, DATE_FORMAT_FOR_COPY } from '~commons/constants';
import { UpdateActualDate } from './update-actual-date';
import { ShowDataAfterImport } from './show-data-after-import';

const ExcelJS = require('exceljs');
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 18,
    }}
    spin
  />
);
const DEFAULT_QUERIES = {
  PageSize: 10000,
  PageNumber: 1,
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};

const ReportOilFuelDistribution = () => {
  const [loading, setLoading] = useState(false);
  const [reportOilFuelDistributions, setReportOilFuelDistributions] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [isCheck, setIsCheck] = useState(false);
  const [actualDate, setActualDate] = useState(null);
  const [updateOnDate, setUpdateOnDate] = useState(null);
  const [showUpdateActualDate, setShowUpdateActualDate] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [valueSearch, setValueSearch] = useState({});
  const [dataAfterImport, setDataAfterImport] = useState([]);
  const [dataList, setDataList] = useState([]);
  // const [sumTotal, setSumTotal] = useState(0);
  // const [loadingSum, setLoadingSum] = useState(false);

  const getData = async (query = DEFAULT_QUERIES, check = false) => {
    setLoading(true);
    const newQuery = { ...query };

    return ApiServices.getReportOilFuelDistribution1(null, check, newQuery)
      .then(async (res) => {
        if (res?.data) {
          let dataSortEnd = [];
          let dataSortTmp = [];
          let keyCode = '';
          if (res?.data?.data?.length === 1) {
            dataSortEnd = res?.data?.data;
          } else {
            await res?.data?.data?.map((item, index) => {
              if (index === 0) {
                keyCode = item?.numberCode?.split('U&I')?.[0];
                dataSortTmp.push(item);
                return;
              }
              if (item?.numberCode?.split('U&I')?.[0] === keyCode) {
                dataSortTmp.push(item);
              }

              if (
                item?.numberCode?.split('U&I')?.[0] !== keyCode ||
                index === res?.data?.data?.length - 1
              ) {
                keyCode = item?.numberCode?.split('U&I')?.[0];

                const dataSortTmpClone = [...dataSortTmp];
                const dataSort = dataSortTmpClone?.sort((a, b) => {
                  let da = a.numberCode?.split('U&I')?.[1];
                  let db = b.numberCode?.split('U&I')?.[1];
                  return da - db;
                });

                const dataEnd = dataSortEnd?.concat(dataSort);
                dataSortEnd = dataEnd;
                dataSortTmp = [item];
              }
            });
          }
          setDataList(dataSortEnd || res?.data?.data);
          setReportOilFuelDistributions(res?.data);
        }
      })
      .catch((err) => {
        console.error('get Report Oil Fuel Distribution Error', err);
      })
      .finally(() => setLoading(false));
  };

  // const handleGetDataSum = async (query = DEFAULT_QUERIES, check = false) => {
  //   if (!query?.DateFrom || !query?.DateTo || loadingSum) return;

  //   const DateFrom = moment(query?.DateFrom).format('YYYY-MM-DD');
  //   const DateTo = moment(query?.DateTo).format('YYYY-MM-DD');

  //   setLoadingSum(true);
  //   return ApiServices.getDataExportOilFuelDistribution(DateFrom, DateTo, check)
  //     .then(async (res) => {
  //       if (res?.data?.data) {
  //         const sum = res?.data?.data?.reduce((sum, current) => (sum += current?.oilToSupply), 0);
  //         setSumTotal(sum);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error('get Data Export Oil Fuel Distribution Error: ', err);
  //     })
  //     .finally(() => {
  //       setLoadingSum(false);
  //     });
  // };

  const onColumnSearch = (event, select = '', isSetValue = true) => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (isSetValue) {
        setValueSearch((prev) => {
          return { ...prev, [name]: value };
        });
      }

      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, isCheck) => {
      getData(newFilter, isCheck);
    }, 1000),
    []
  );

  // const timeoutGetDataSumOil = useCallback(
  //   debounce((newFilter, isCheck) => {
  //     handleGetDataSum(newFilter, isCheck);
  //   }, 3500),
  //   []
  // );

  useEffect(() => {
    timeoutGetData(filter, isCheck);
    // timeoutGetDataSumOil(filter, isCheck);
  }, [filter, isCheck]);

  const handleClearFilterColumn = () => {
    const dataClone = { ...filter };
    const dataFilter = {
      PageSize: dataClone?.PageSize,
      PageNumber: dataClone?.PageNumber,
      DateFrom: dataClone?.DateFrom,
      DateTo: dataClone?.DateTo,
    };
    setFilter(dataFilter);
    setActualDate(null);
    setUpdateOnDate(null);
    setValueSearch({});
  };

  const handleChangeFilterByActualDate = (event) => {
    setIsCheck(event.target.checked);
  };

  const handleChangeActualDate = (date) => {
    setActualDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'actualDate', false);
  };
  const handleChangeUpdateOn = (date) => {
    setUpdateOnDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'updatedOn', false);
  };
  const handleChangeNumberCode = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    setValueSearch((prev) => {
      return { ...prev, [name]: value };
    });

    if (value) {
      onColumnSearch(`U&I${value}`, name, false);
    } else {
      onColumnSearch('', name, false);
    }
  };

  const displayCost = (_, record) => {
    if (record?.oilToSupply && record?.oilPrice) {
      return Utils.formatNumber(record?.oilToSupply * record?.oilPrice);
    } else {
      return 0;
    }
  };

  const handleUpdateActualDate = (record) => {
    setShowUpdateActualDate(record);
  };
  const handleCancel = () => {
    setShowUpdateActualDate(null);
  };
  const handleCancelShowDataImported = () => {
    setDataAfterImport([]);
  };
  const reload = () => {
    getData(filter, isCheck);
  };

  const handleDeleteActualDate = async (row) => {
    if (loading) return;
    setLoading(true);
    return ApiServices.deleteActualDate(row?.id, {
      actualDate: null,
    })
      .then((res) => {
        if (res?.data?.data === 'success') {
          window.TmsNoti.success('Thao tác thành công', 'Đã xóa ngày đổ dầu thực tế', 8);
          reload();
        } else {
          window.TmsNoti.error('Cập nhật thất bại', '', 8);
        }
      })
      .catch((err) => {
        window.TmsNoti.error('Cập nhật thất bại', err, 8);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderNumberCode = (value) => {
    if (!value) return '';

    const dataNumberCode = value?.split('U&I');
    const displayNumberCode = dataNumberCode ? `${dataNumberCode[0]} U&I ${dataNumberCode[1]}` : '';
    return displayNumberCode;
  };

  const renderActualDate = (value, record) => {
    return (
      <div>
        <Button type='link' icon={<FormOutlined />} onClick={() => handleUpdateActualDate(record)}>
          {value ? Utils.formatDate(value) : ''}
        </Button>
        {value && (
          <Popconfirm
            onConfirm={() => handleDeleteActualDate(record)}
            placement='rightTop'
            title='Xóa ngày đổ dầu thực tế?'
            okText='Xóa'
            cancelText='Không'
          >
            <Button type='link' danger icon={<DeleteOutlined />}>
              Xóa
            </Button>
          </Popconfirm>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Số phiếu
          <Input
            name='numberCode'
            value={valueSearch?.numberCode}
            onChange={handleChangeNumberCode}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'numberCode',
      align: 'center',
      width: 100,
      render: renderNumberCode,
    },
    {
      title: (
        <TableTitle>
          Ngày cấp dầu
          <DatePicker
            value={updateOnDate}
            onChange={handleChangeUpdateOn}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'updatedOn',
      align: 'center',
      width: 100,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Ngày đổ dầu thực tế
          <DatePicker
            value={actualDate}
            onChange={handleChangeActualDate}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT_FOR_COPY}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'actualDate',
      align: 'center',
      width: 120,
      render: renderActualDate,
    },
    {
      title: (
        <TableTitle>
          Số xe
          <Input
            name='carCode'
            value={valueSearch?.carCode}
            onChange={onColumnSearch}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'carCode',
      align: 'center',
      width: 80,
    },
    {
      title: (
        <TableTitle>
          Diễn giải
          <Input
            name='assignOn'
            value={valueSearch?.assignOn}
            onChange={onColumnSearch}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'assignOn',
      align: 'center',
      width: 130,
      render: (_, record) => (
        <span>Chi dầu ngày {record.updatedOn && Utils.formatDate(record.updatedOn)}</span>
      ),
    },
    {
      title: (
        <TableTitle>
          Họ và tên
          <Input
            name='driverMainName'
            value={valueSearch?.driverMainName}
            onChange={onColumnSearch}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'driverMainName',
      align: 'center',
      width: 100,
    },
    {
      title: (
        <TableTitle>
          Giá dầu
          <Input
            name='oilPrice'
            value={valueSearch?.oilPrice}
            onChange={onColumnSearch}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'oilPrice',
      align: 'center',
      width: 50,
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: (
        <TableTitle>
          Số lượng
          <Input
            name='oilToSupply'
            value={valueSearch?.oilToSupply}
            onChange={onColumnSearch}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'oilToSupply',
      align: 'center',
      width: 45,
    },
    {
      title: (
        <TableTitle>
          Thành tiền
          <Input
            name='totalOil'
            value={valueSearch?.totalOil}
            onChange={onColumnSearch}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'totalOil',
      align: 'center',
      width: 50,
      render: displayCost,
    },
    {
      title: (
        <TableTitle>
          Số lít đổ
          <Input
            name='oilToSupply'
            value={valueSearch?.oilToSupply}
            onChange={onColumnSearch}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'oilToSupply',
      align: 'center',
      width: 45,
    },
  ];

  const sumOilTotal = useMemo(() => {
    return reportOilFuelDistributions?.data?.reduce(
      (sum, current) => (sum += current?.oilToSupply),
      0
    );
  }, [reportOilFuelDistributions?.data]);

  const pagination = {
    total: reportOilFuelDistributions?.totalRecords || 0,
    showTitle: true,
    showTotal: () => {
      return (
        <h1 className='blue'>
          Tổng:{' '}
          {sumOilTotal ? (
            <>
              <b className='red'>{Utils.formatNumber(sumOilTotal) || 0}</b> Lít
            </>
          ) : (
            <Spin indicator={antIcon} />
          )}
        </h1>
      );
    },
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  const handleExportExcel = async (dataExportList = []) => {
    const workbook = new ExcelJS.Workbook();
    // // Ten sheet
    const sheetSummary = workbook.addWorksheet('Cấp phát nhiên liệu', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ state: 'frozen', ySplit: 4, activeCell: 'A1', showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 15;
    sheetSummary.properties.defaultColWidth = 15;
    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getColumn(4).width = 20;
    sheetSummary.getColumn(6).width = 25;
    sheetSummary.getColumn(7).width = 20;

    sheetSummary.getColumn(8).numFmt = '#,##0';
    sheetSummary.getColumn(8).width = 20;
    sheetSummary.getColumn(10).numFmt = '#,##0';
    sheetSummary.getColumn(10).width = 20;

    const date = `Từ ${moment(filter?.DateFrom).format('DD/MM/YYYY')} Đến ${moment(
      filter?.DateTo
    ).format('DD/MM/YYYY')}`;

    sheetSummary.getCell('E2').value = 'BẢNG THEO DÕI CẤP PHÁT NHIÊN LIỆU';
    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getCell('E3').value = date;
    sheetSummary.getRow(3).font = {
      name: 'Arial',
      family: 2,
      size: 10,
      italic: true,
    };
    sheetSummary.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(6).alignment = { vertical: 'middle', horizontal: 'center' };

    const arrDetail = await dataExportList?.map((item) => {
      const dataItem = [
        item?.numberCode && renderNumberCode(item?.numberCode), // Số Phiếu
        item?.updatedOn && new Date(moment(item?.updatedOn).format('YYYY-MM-DD')), // Ngày Cấp Dầu
        item?.actualDate && new Date(moment(item?.actualDate).format('YYYY-MM-DD')), // Ngày Đổ Dầu Thực Tế
        item?.carCode ?? '', // Số Xe
        item?.updatedOn && `Chi dầu ngày ${Utils.formatDate(item?.updatedOn)}`, // Diễn Giải
        item?.driverMainName ?? '', // Họ Tên
        item?.oilPrice ?? 0, // Giá Dầu
        item?.oilToSupply ?? 0, // Số Lượng
        item?.oilToSupply * item?.oilPrice, // Thành Tiền
      ];
      return dataItem;
    });
    // Add Table
    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B6',
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'Số Phiếu', totalsRowLabel: '', filterButton: false },
        { name: 'Ngày Cấp Dầu', filterButton: false },
        { name: 'Ngày Đổ Dầu Thực Tế', filterButton: false },
        {
          name: 'Số Xe',
          filterButton: false,
        },
        {
          name: 'Diễn Giải',
          filterButton: false,
        },
        {
          name: 'Họ Tên',
          filterButton: false,
        },
        {
          name: 'Giá Dầu',
          filterButton: false,
        },
        {
          name: 'Số Lượng',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Thành Tiền',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
      ],
      rows: arrDetail,
    });

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Bang_Theo_Doi_Cap_Phat_Nhien_Lieu_${date}_Export_luc_${moment().format(
          'DD/MM/YYYY HH:mm'
        )}.xlsx`; // name excel
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setExportLoading(false);
    }
  };
  // Goods Material Notes Warehouse

  const handleGetDataExport = async (query = DEFAULT_QUERIES, check = false) => {
    if (!query?.DateFrom || !query?.DateTo || loading) return;

    const DateFrom = moment(query?.DateFrom).format('YYYY-MM-DD');
    const DateTo = moment(query?.DateTo).format('YYYY-MM-DD');

    setExportLoading(true);
    return ApiServices.getDataExportOilFuelDistribution(DateFrom, DateTo, check)
      .then(async (res) => {
        if (res?.data?.data) {
          let dataSortEnd = [];
          let dataSortTmp = [];
          let keyCode = '';
          await res?.data?.data?.map((item, index) => {
            if (index === 0) {
              keyCode = item?.numberCode?.split('U&I')?.[0];
              dataSortTmp.push(item);
              return;
            }
            if (item?.numberCode?.split('U&I')?.[0] === keyCode) {
              dataSortTmp.push(item);
            }

            if (
              item?.numberCode?.split('U&I')?.[0] !== keyCode ||
              index === res?.data?.data?.length - 1
            ) {
              keyCode = item?.numberCode?.split('U&I')?.[0];

              const dataSortTmpClone = [...dataSortTmp];
              const dataSort = dataSortTmpClone?.sort((a, b) => {
                let da = a.numberCode?.split('U&I')?.[1];
                let db = b.numberCode?.split('U&I')?.[1];
                return da - db;
              });

              const dataEnd = dataSortEnd?.concat(dataSort);
              dataSortEnd = dataEnd;
              dataSortTmp = [item];
            }
          });
          return handleExportExcel(dataSortEnd || res?.data?.data);
        }
      })
      .catch((err) => {
        console.error('get Data Export Oil Fuel Distribution Error: ', err);
      })
      .finally(() => {
        setExportLoading(false);
      });
  };
  const handleClick = (dataImport) => {
    notification.close(key);
    setDataAfterImport(dataImport);
  };
  const key = `open${Date.now()}`;
  const btn = (dataImport) => {
    return (
      <Space>
        <Button type='link' size='small' onClick={() => handleClick(dataImport)}>
          Xem chi tiết {'>>'}
        </Button>
      </Space>
    );
  };

  const onUploadExcel = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importActualDateOilFuelDistribution(file)
      .then((res) => {
        if (res?.data?.data) {
          window.TmsNoti.successCustom(
            'Import thành công',
            res?.data?.message || '',
            8,
            () => btn(res?.data?.data || []),
            key
          );
          return getData(filter, isCheck);
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT EXCEL ERROR', err);
        window.TmsNoti.error('Import thất bại', err, 8);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Wrapper>
      <h3>7078 - Bảng theo dõi cấp phát nhiên liệu</h3>
      <Space style={{ marginBottom: '10px' }}>
        <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
        <Checkbox onChange={handleChangeFilterByActualDate}>Ngày đổ dầu thực tế</Checkbox>
        <UploadExcelButton
          isShowDownloadTemplate={false}
          loading={loading}
          onChange={onUploadExcel}
          templateUrl={''}
        />
        <Button
          type='primary'
          icon={<DownloadOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px' }}
          loading={exportLoading}
          onClick={() => handleGetDataExport(filter, isCheck)}
        >
          Export Excel
        </Button>
        <Button
          icon={<CloseCircleOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px' }}
          onClick={handleClearFilterColumn}
        >
          Clear
        </Button>
      </Space>
      <div
        className='table-container-custom'
        style={{
          maxHeight: `calc(100vh - 300px`,
        }}
      >
        <Table
          columns={columns}
          dataSource={dataList || []}
          bordered
          size='small'
          pagination={pagination}
          rowKey='id'
          scroll={{ y: `calc(100vh - 240px)` }}
          loading={loading}
        />
      </div>
      <Modal
        title='Cập nhật ngày đổ dầu thực tế:'
        open={!!showUpdateActualDate}
        onCancel={handleCancel}
        footer={null}
      >
        <UpdateActualDate
          dataSelected={showUpdateActualDate}
          handleCancel={handleCancel}
          reload={reload}
        />
      </Modal>
      <Modal
        title='Danh sách Import thành công'
        open={!!dataAfterImport?.length}
        onCancel={handleCancelShowDataImported}
        footer={null}
        style={{
          top: 20,
        }}
        width='100%'
      >
        <ShowDataAfterImport data={dataAfterImport} />
      </Modal>
    </Wrapper>
  );
};

export { ReportOilFuelDistribution };
