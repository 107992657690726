import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Table, DatePicker } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import Utils from '~commons/utils';
import { TableTitle } from '~commons/styles';
import { Input } from 'antd';
import { DATE_FORMAT } from '~commons/constants';

const INIT_DATA_SEARCH = {
  driverMainName: '',
  folder: '',
};

const TableContent = ({ loading, data = [] }) => {
  const [originData, setOriginData] = useState([]);
  const [dataTableList, setDataTableList] = useState([]);
  const [filter, setFilter] = useState(null);
  const [dissolveDate, setDissolveDate] = useState(null);
  const [dataDefaultSearch, setDataDefaultSearch] = useState(INIT_DATA_SEARCH);

  useEffect(() => {
    if (data) {
      setOriginData(data);
      setDataTableList(data);
      setDissolveDate(null);
      setDataDefaultSearch(INIT_DATA_SEARCH);
    }
  }, [data]);

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;

      if (query?.folder) {
        if (
          !Utils.convertValSearch(item?.folder)?.includes(Utils.convertValSearch(query?.folder))
        ) {
          checkFilter = false;
        }
      }
      if (query?.driverMainName) {
        if (
          !Utils.convertValSearch(item?.driverMainName)?.includes(
            Utils.convertValSearch(query?.driverMainName)
          )
        ) {
          checkFilter = false;
        }
      }

      if (query?.dissolveDate) {
        if (moment(item?.dissolveDate).format('YYYY-MM-DD') !== query?.dissolveDate) {
          checkFilter = false;
        }
      }
      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    setDataTableList(dataFilter);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    []
  );
  useEffect(() => {
    if (originData?.length) {
      timeoutGetData(filter, originData);
    }
  }, [filter]);

  const onColumnSearch = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setDataDefaultSearch((prev) => {
      return { ...prev, [name]: value };
    });
    setFilter((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handleChangeDissolveDate = (date) => {
    setDissolveDate(date);
    const dateSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    setFilter((prev) => {
      return { ...prev, dissolveDate: dateSearch };
    });
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Ngày
          <DatePicker
            value={dissolveDate}
            onChange={handleChangeDissolveDate}
            placeholder='Ngày'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'dissolveDate',
      align: 'center',
      with: 140,
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          STT Folder
          <Input
            name='folder'
            value={dataDefaultSearch?.folder}
            onChange={onColumnSearch}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'folder',
      align: 'center',

      with: 80,
    },

    {
      title: (
        <TableTitle>
          Họ Tên
          <Input
            name='driverMainName'
            value={dataDefaultSearch?.driverMainName}
            onChange={onColumnSearch}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'driverMainName',
      align: 'center',
    },
    {
      title: 'Số Tiền',
      dataIndex: 'total',
      align: 'center',
      with: 150,
      render: (value) => Utils.formatNumber(value),
    },
  ];

  const sumTotalAll = useMemo(() => {
    let calcTotal = 0;
    dataTableList?.map((item) => {
      calcTotal = calcTotal + item?.total;
    });
    return calcTotal;
  }, [dataTableList]);

  return (
    <>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            marginLeft: 'auto',
          }}
        >
          <p
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '15px',
              marginRight: '30px',
              color: 'red',
            }}
          >
            Tổng: {Utils.formatNumber(sumTotalAll)}
          </p>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={dataTableList}
        loading={loading}
        pagination={false}
        bordered={true}
        rowKey='key'
        size='small'
        scroll={{
          y: `calc(100vh - 320px`,
        }}
      />
    </>
  );
};

export { TableContent };
