import React from 'react';
import { Row, Col } from 'antd';
import Utils from '~commons/utils';
import { DisplayInfo } from '~components/display-info';

const HeaderFormCreate = ({ dataInfo }) => {
  return (
    <Row gutter={16}>
      <Col span={6}>
        <DisplayInfo title='Khách hàng' value={dataInfo?.customerName} />
      </Col>
      <Col span={5}>
        <DisplayInfo title='Số Cont' value={dataInfo?.contNumber} />
      </Col>
      <Col span={6}>
        <DisplayInfo title='Số SubNo' value={dataInfo?.subnoNumber} />
      </Col>
      <Col span={3}>
        <DisplayInfo title='Loại ĐH' value={dataInfo?.orderType} />
      </Col>
      <Col span={4}>
        <DisplayInfo
          title='Ngày nhận data'
          value={dataInfo?.billReceivedDate && Utils.formatDate(dataInfo?.billReceivedDate)}
        />
      </Col>
    </Row>
  );
};

export { HeaderFormCreate };
