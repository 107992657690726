import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { Form, Input, Select } from 'antd';
import ApiServices from '~services';
import storage from '~storage';
import { Selection } from '~components';

const { Option } = Select;
const TransitForm = forwardRef(({ fields, onChange, onsubmit, orderDetail }, ref) => {
  const [locations, setLocations] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);
  const [cargoTypes, setCargoTypes] = React.useState([]);

  const [form] = Form.useForm();
  window.TransitForm = form;
  useImperativeHandle(ref, () => ({
    submitForm() {
      form.submit();
    },
  }));
  console.log(fields);

  const getCustomers = async (Query = null) => {
    try {
      const { data } = await ApiServices.getCustomer({ Query });
      setCustomers(data.data);
    } catch (err) {
      console.error('ERR - Get Customers', err);
    }
  };

  const getLocations = async (Query = null) => {
    try {
      const { data } = await ApiServices.getLocations({ Query });
      setLocations(data.data);
    } catch (err) {
      console.error('ERR - Get Locations', err);
    }
  };
  const getCargoTypes = async (Query = null) => {
    try {
      const { data } = await ApiServices.getCargoTypes({ Query });
      setCargoTypes(data.data);
    } catch (err) {
      console.error('ERR - Get CargoTypes', err);
    }
  };

  useEffect(() => {
    getCustomers();
    getLocations();
    getCargoTypes();
  }, []);

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const onSubmitForm = async () => {
    onsubmit();
  };
  const transportType = storage.staticVariables.TransportType;
  const transportTypeDiv = (
    <Select placeholder='Loại vận chuyển' showSearch>
      {Object.keys(transportType).map((item) => (
        <Option key={item} value={transportType[item]}>
          {transportType[item]}
        </Option>
      ))}
    </Select>
  );

  return (
    <Form
      {...layout}
      name='global_state'
      form={form}
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        name='transitNumber'
        label='Số Transit'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Số Transit',
          },
        ]}
      >
        <Input placeholder='Số Transit' />
      </Form.Item>

      <Form.Item name='transportType' label='Loại Vận Chuyển'>
        {transportTypeDiv}
      </Form.Item>

      <Form.Item
        name='customerCode'
        label='Khách hàng'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Mã Khách hàng',
          },
        ]}
      >
        <Selection
          data={customers}
          labelDefault={orderDetail?.customerName}
          placeholder='Mã Khách hàng'
          onSearch={getCustomers}
        />
      </Form.Item>
      <Form.Item
        name='locationOfReceiptCode'
        label='Nơi Lấy Hàng'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Nơi Lấy Hàng',
          },
        ]}
      >
        <Selection
          data={locations}
          labelDefault={
            orderDetail?.listCont && orderDetail?.listCont.length
              ? orderDetail?.listCont[0]?.locationOfReciptName
              : ''
          }
          placeholder='Nơi Lấy Hàng'
          onSearch={getLocations}
        />
      </Form.Item>

      <Form.Item
        name='locationOfDeliveryCode'
        label='Nơi Giao Hàng'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Nơi Giao Hàng',
          },
        ]}
      >
        <Selection
          data={locations}
          labelDefault={
            orderDetail?.listCont && orderDetail?.listCont.length
              ? orderDetail?.listCont[0]?.locationOfDeliveryName
              : ''
          }
          placeholder='Nơi Giao Hàng'
          onSearch={getLocations}
        />
      </Form.Item>

      <Form.Item
        name='cargoTypeCode'
        label='Loại Hàng Hóa'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Loại Hàng Hóa',
          },
        ]}
      >
        <Selection
          data={cargoTypes}
          placeholder='Loại Hàng Hóa'
          fieldValue='name'
          onSearch={getCargoTypes}
          autoSelectFirstOption
        />
      </Form.Item>

      <Form.Item name='note' label='Ghi chú'>
        <Input.TextArea placeholder='Ghi chú' />
      </Form.Item>
    </Form>
  );
});

export { TransitForm };
