import actionTypes from './type';

export function showListFile(dataListFile = []) {
  return (dispatch) => dispatch({ dataListFile, type: actionTypes.SHOW_LIST_FILE });
}
export function showAssignCarToday(dataShowAssignCarToday = []) {
  return (dispatch) =>
    dispatch({ dataShowAssignCarToday, type: actionTypes.SHOW_ASSIGN_CAR_TODAY });
}
export function updateDataAssign(data = []) {
  return (dispatch) => dispatch({ data, type: actionTypes.ASSIGN_DATA_CHANGED });
}
export function updateDataAssignItem(data = []) {
  return (dispatch) => dispatch({ data, type: actionTypes.ASSIGN_ITEM_CHANGED });
}
export function removeOneAssignItem(data) {
  return (dispatch) => dispatch({ data, type: actionTypes.ASSIGN_ITEM_DELETE });
}
export function updateRomoocAssign(data = {}) {
  return (dispatch) => dispatch({ data, type: actionTypes.ASSIGN_ROMOOC_ADDED_CHANGED });
}
export function updateCarAssign(data = {}) {
  return (dispatch) => dispatch({ data, type: actionTypes.ASSIGN_CAR_CHANGED });
}

export function updateDriverAssign(data = null) {
  return (dispatch) => dispatch({ data, type: actionTypes.ASSIGN_DRIVER_CHANGED });
}
export function updateDistanceLocationData(data = []) {
  return (dispatch) => dispatch({ data, type: actionTypes.ASSIGN_LOCATIONS_DISTANCE_DATA_CHANGE });
}
export function checkDistanceLocations(data = [], onFinish = null) {
  return (dispatch) =>
    dispatch({ data, onFinish, type: actionTypes.ASSIGN_LOCATIONS_CHECK_DISTANCES });
}
export function changeAssignFinal(data = null) {
  return (dispatch) => dispatch({ data, type: actionTypes.ASSIGN_FINAL_TASK_CHANGED });
}
export function updateChangeRomoocData(data = null) {
  return (dispatch) => dispatch({ data, type: actionTypes.ASSIGN_ROMOOC_DATA_CHANGED });
}
export function updateShowCustomerDetail(data = null) {
  return (dispatch) => dispatch({ data, type: actionTypes.SHOW_CUSTOMER_DETAIL });
}
export function updateAssignLoading(loading = false) {
  return (dispatch) => dispatch({ loading, type: actionTypes.ASSIGN_LOADING_CHANGED });
}
