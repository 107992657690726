import { DATE_FORMAT } from '~commons/constants';
import moment from 'moment';

export const KEYS = ['inspectionNumberNew', 'inspectionCostNew', 'note', 'files', 'orderFiles'];
export const KEYS_OF_DATE = ['inspectionDateNew', 'inspectionExpirationDateNew'];
export const KEYS_OF_UPLOAD_FILES = ['files'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });

  KEYS_OF_DATE.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? moment(data[item], DATE_FORMAT) : null,
    });
  });

  KEYS_OF_UPLOAD_FILES.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] || [],
      errors: [],
    });
  });

  return result;
};
