import React, { useCallback } from 'react';
import styled from 'styled-components';
import { EditOutlined } from '@ant-design/icons';
import { Input, Popconfirm } from 'antd';

export default function ({ defaultValue, width = null, onSubmit }) {
  const [text, setText] = React.useState(defaultValue);
  const [visible, setVisible] = React.useState(false);
  const inputRef = React.useRef(null);

  const onClickCancel = () => {
    setText(defaultValue);
    setVisible(false);
  };
  const onClickSave = useCallback(() => {
    if (!text || text === defaultValue) return setVisible(false);

    onSubmit && onSubmit(text || defaultValue);
    setVisible(false);
  }, [text]);
  const onClickActive = () => {
    setVisible(true);
    inputRef.current?.focus();
  };
  return (
    <Popconfirm
      placement='left'
      cancelText='Hủy'
      okText='Lưu'
      onConfirm={onClickSave}
      onCancel={onClickCancel}
      cancelButtonProps={{ type: 'danger' }}
      okButtonProps={{
        disabled: !text || text === defaultValue,
        style: {
          minWidth: 100,
          fontWeight: 'bold',
        },
      }}
      visible={visible}
      title={
        <InputArea
          ref={inputRef}
          width={width}
          defaultValue={defaultValue}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              onClickSave();
            }
            if (e.key === 'Escape') {
              onClickCancel();
            }
          }}
          placeholder='Ghi chú'
          onChange={(e) => setText(e.target.value)}
          value={text}
        />
      }
    >
      <Wrapper onClick={onClickActive}>
        {defaultValue ? (
          <Text>{defaultValue}</Text>
        ) : (
          <TextWrapper>
            <Text>{defaultValue || ''}</Text>
            <EditOutlined />
          </TextWrapper>
        )}
      </Wrapper>
    </Popconfirm>
  );
}
const Wrapper = styled.div`
  cursor: text;
  min-height: 40px;
  z-index: 1;
`;
const TextWrapper = styled.div`
  display: flex;
  font-size: 13px;
  align-items: center;
  cursor: pointer;
  ${Wrapper}:hover & {
    border: 1px solid #f5f5f5;
    border-radius: 4px;
  }
`;
const Text = styled.span`
  cursor: pointer;
  flex: 1;
  display: block;
  border-radius: 8px;
  padding: 0px;
  color: var(--wb-note);
`;

const InputArea = styled(Input.TextArea)`
  flex: 1;
  height: 100%;
  font-size: 14px;
  min-height: 60px;
  min-width: 100%;
  height: auto;
  border-radius: 4px;
  padding: 4px 8px;
  border: 1px solid #d9d9d9;
  width: 300px;
`;
