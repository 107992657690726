import Utils from '~commons/utils';

export const KEYS = [
  'customerName',
  'contNumber',
  'sealNumber',
  'subnoNumber',
  'romoocCode',
  'romoocName',
  'lh',
  'gh',
  'tr',
  'note',
  'notePlan',
  'locationId',
];
export const KEYS_OF_DATE = [
  'book_DeliveryContRequestDate',
  'book_LiftingEmptyContAcctualDate',
  'cutRomoocEmptyCont',
  'pickupAcctualDate',
  'deliveryAcctualDate',
];
export const KEYS_OF_DATE_TIME = ['book_CutOffDateTime', 'book_SIDateTime'];

export const KEYS_OF_UPLOAD_FILES = ['contFiles'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });

  KEYS_OF_DATE.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? Utils.convertToDate(data[item]) : null,
    });
  });
  KEYS_OF_DATE_TIME.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? Utils.convertToDate(data[item]) : null,
    });
  });

  KEYS_OF_UPLOAD_FILES.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] || [],
      errors: [],
    });
  });

  return result;
};
