import React, { useRef } from 'react';
import moment from 'moment';
import { Modal, Button, Row, Col } from 'antd';
import { TableDetails } from './table-details';
import ReactToPrint from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import { OilSupplyTicket } from './oil-supply-ticket';

const OnlyOilTicketDetail = ({ selectedItem, onClose, showFooter = false, companyInfo }) => {
  const oilTicketRef = useRef();

  const handleOk = () => {
    onClose && onClose();
  };

  const handleCancel = () => {
    onClose && onClose();
  };
  const renderNumberCode = (value) => {
    if (!value) return '';

    const dataNumberCode = value?.split('U&I');
    const displayNumberCode = dataNumberCode ? `${dataNumberCode[0]} U&I ${dataNumberCode[1]}` : '';
    return displayNumberCode;
  };
  const renderCarInfo = () => (
    <>
      <Col span={4} style={{ textAlign: 'center' }}>
        <b>Số Phiếu: </b>
        <br />
        <span className='blue'>{renderNumberCode(selectedItem?.numberCode)}</span>
      </Col>
      <Col span={4} style={{ textAlign: 'center' }}>
        <b>Số Xe: </b>
        <br />
        <span className='blue'>{selectedItem?.carCode}</span>
      </Col>
      <Col span={6} style={{ textAlign: 'center' }}>
        <b>Tài Xế: </b>
        <br />
        <span className='blue'>{selectedItem?.driverMainName}</span>
      </Col>
      <Col span={4} style={{ textAlign: 'center' }}>
        <b>Định mức: </b>
        <br />
        <span className='blue'>{selectedItem?.oilQuota}</span>
      </Col>
      <Col span={6} style={{ textAlign: 'center' }}>
        <b>Ngày Điều Xe: </b>
        <br />
        <span className='blue'>{selectedItem?.assignOn}</span>
      </Col>
    </>
  );

  const sumInfo = () => {
    return (
      <>
        <Col span={8} offset={14} style={{ textAlign: 'center', marginTop: '5px', color: 'blue' }}>
          <p>
            Tổng Số Km: <b className='red'>{selectedItem?.totalKmAll}</b> (Km){' '}
            {selectedItem?.totalKmSupport > 0 && (
              <span className='red'>(bao gồm {selectedItem?.totalKmSupport} Km hỗ trợ)</span>
            )}
          </p>
          <p>
            Tổng Số Lít Dầu Cần Chi:{' '}
            <b className='red'>{Math.round(selectedItem?.newOilToSupply)}</b> (Lit){' '}
            {selectedItem?.totalOilSupport > 0 && (
              <span className='red'>(bao gồm {selectedItem?.totalOilSupport} Lít hỗ trợ)</span>
            )}
          </p>
        </Col>
      </>
    );
  };

  const onHandleReportStatus = async () => {
    await handleCancel();
  };

  const pageStyle = `
  @page {
     size: A4;
     margin: 5%;
     padding: 0 0 10%;
     @bottom-right {
      content: counter(page);
     }
  }
`;

  const footer = (
    <div onClick={onHandleReportStatus}>
      <ReactToPrint
        trigger={() => (
          <Button type='primary' icon={<PrinterOutlined />}>
            In phiếu
          </Button>
        )}
        content={() => oilTicketRef.current}
        documentTitle={`Phieu_cap_dau_${moment().format('DD-MM-YYYY')}`}
        pageStyle={pageStyle}
      />
    </div>
  );

  let jobList = [];
  if (selectedItem?.jobs?.length > 0) {
    jobList = jobList.concat(selectedItem?.jobs);
  }
  if (selectedItem?.supportJobs?.length > 0) {
    const extraJobsMap = selectedItem?.supportJobs?.map((item) => {
      item.code = 'Điều xe hỗ trợ';
      item.isSupport = true;
      return item;
    });
    jobList = jobList.concat(extraJobsMap);
  }

  return (
    <>
      <Modal
        title='Chi tiết'
        open={selectedItem ? true : false}
        onOk={handleOk}
        onCancel={handleCancel}
        width='auto'
        footer={showFooter ? footer : null}
      >
        <div>
          <Row style={{ marginBottom: '10px' }}>{renderCarInfo()}</Row>
          <TableDetails dataDetails={jobList || []} />
          <Row>{sumInfo()}</Row>
          <div style={{ display: 'none' }}>
            <OilSupplyTicket
              ref={oilTicketRef}
              dataCar={selectedItem}
              itemSelectedToPrint={selectedItem}
              companyInfo={companyInfo}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export { OnlyOilTicketDetail };
