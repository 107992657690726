export default {
  FETCH_ORDERS_WAITING_RECEIVE_ONP_DATA: 'fetch_orders_waiting_receive_ONP_data',
  FETCH_ORDERS_WAITING_RECEIVE_WMS_DATA: 'fetch_orders_waiting_receive_WMS_data',
  FETCH_ORDERS_REVISED_ONP_DATA: 'fetch_orders_revised_ONP_data',
  FETCH_ORDERS_REVISED_WMS_DATA: 'fetch_orders_revised_WMS_data',
  FETCH_ORDERS_CANCEL_ONP_DATA: 'fetch_orders_cancel_ONP_data',
  FETCH_ORDERS_CANCEL_WMS_DATA: 'fetch_orders_cancel_WMS_data',
  // Rvs order by TMS
  FETCH_ORDERS_RVS_BY_TMS_NOT_READ: 'fetch_orders_rvs_by_tms_not_read',
  FETCH_ORDERS_CREATE_BY_TMS_NOT_READ: 'fetch_orders_create_by_tms_not_read',
};
