import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
// import { PrinterOutlined } from '@ant-design/icons';
import { TableLayout } from '~components';
import { TaskJobExtrasForm } from './taskjob-extras-form';
import DriverFields from './fields-data';
import { Input, Button, Space, DatePicker, Badge, Popconfirm, message } from 'antd';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { TableTitle } from '~commons/styles';
import { DATE_FORMAT } from '~commons/constants';
// import { MultipleOilTicketDetail } from './multiple-oil-ticket-detail';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
};

const DEFAULT_ASSIGN_DATE = moment();
// const STATUS_OPTION_SELECTS = [
//   {
//     label: 'Tất cả',
//     value: '',
//   },
//   {
//     label: 'Đã cấp',
//     value: '1',
//   },
//   {
//     label: 'Chưa cấp',
//     value: '0',
//   },
// ];
const statusTag = (text = '', bgColor = '', count = null) => {
  return (
    <Badge count={count}>
      <div
        style={{
          backgroundColor: bgColor,
          width: '100px',
          padding: '5px 10px',
          textAlign: 'center',
          borderRadius: '4px',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        {text}
      </div>
    </Badge>
  );
};
// const { Option } = Select;

const TaskJobExtrasList = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [taskJobExtrasList, setTaskJobExtrasList] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [assignCarDate, setAssignCarDate] = useState(DEFAULT_ASSIGN_DATE);
  const [selectedMultipleTicket, setSelectedMultipleTicket] = useState([]);
  // const [selectMultipleRowData, setSelectMultipleRowData] = useState([]);
  // const [dataMultipleOilTicketDetail, setDataMultipleOilTicketDetail] = useState([]);
  // const [showMultipleTicketDetail, setShowMultipleTicketDetail] = useState(false);
  // const [selectRowData, setSelectRowData] = useState([]);

  // const countSelectedTicket = useMemo(() => {
  //   return selectedMultipleTicket?.length;
  // }, [selectedMultipleTicket]);

  //-------------------------------------------------------------------
  // const onCloseMultipleTicket = () => {
  //   setShowMultipleTicketDetail(false);
  // };

  const getData = async (query = DEFAULT_QUERIES, dateAssign = DEFAULT_ASSIGN_DATE) => {
    setLoading(true);
    const convertAssignCarDate = moment(dateAssign).format('YYYY-MM-DD');

    const newQuery = { ...query };
    setSelectedMultipleTicket([]);
    // setSelectMultipleRowData([]);

    return ApiServices.getTaskJobExtras(newQuery, convertAssignCarDate)
      .then((res) => {
        if (res?.data) {
          setTaskJobExtrasList(res?.data);
        }
      })
      .catch((err) => {
        console.error('Get cont list Error', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? (event ? event : 0) : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = select ? parseInt(value) : value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, assignCarDate) => {
      getData(newFilter, assignCarDate);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter, assignCarDate);
  }, [filter, assignCarDate]);
  // ------------- End Search --------------------

  const setFieldsForm = async (value) => {
    const driverFields = await DriverFields(value);
    await setFields(driverFields);
    return driverFields;
  };
  const reload = async () => {
    setSelected(null);
    await timeoutGetData(filter, assignCarDate);
  };

  const getUpdatedTaskJobExtrasForm = async (value, isNew = false, id = null, dataRow = []) => {
    const driverForm = await (
      <TaskJobExtrasForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        dataRow={dataRow}
        reload={reload}
        onChange={setFields}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: driverForm,
      },
    ]);
  };

  const onRowSelected = async (value) => {
    const _value = {
      ...value,
      assignOn: Utils.convertToDate(value?.assignOn),
    };

    await setIsLoadingTab(true);
    await setTitleTab(_value?.name);
    await setFieldsForm(_value)
      .then((res) => {
        return getUpdatedTaskJobExtrasForm(res, false, _value.id, _value);
      })
      .then(() => setSelected(_value));
    setIsLoadingTab(false);
  };

  // const handleOilProvided = async (taskJobExtrasId) => {
  //   if (loading) return;
  //   setLoading(true);
  //   return ApiServices.printOilOfTaskJobExtras({ ids: [taskJobExtrasId] })
  //     .then((res) => {
  //       if (res?.data?.data) {
  //         getData(filter, assignCarDate);
  //         message.success('Xác nhận thành công');
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('Accept provide oild failed:', err);
  //       message.error('Xác nhận thất bại');
  //     })
  //     .finally(() => setLoading(false));
  // };

  const handleDelete = async (id) => {
    if (loading) return;
    setLoading(true);
    return ApiServices.deleteTaskJobExtras(id)
      .then((res) => {
        if (res?.data?.data) {
          getData(filter, assignCarDate);
          message.success('Xoá thành công');
        }
      })
      .catch((err) => {
        console.log('Delete task job extras failed:', err);
        message.error('Xoá thất bại');
      })
      .finally(() => setLoading(false));
  };

  const renderStatus = (value, record) => {
    if (value) {
      return statusTag('Đã cấp', 'green', record?.count);
    }
    return statusTag(' Chưa cấp', '#EB455F');
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Tên công việc
          <Input name='name' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'name',
      align: 'center',
      width: 200,
      render: (value, record) => (
        <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>
      ),
    },
    {
      title: (
        <TableTitle>
          <div>Trạng thái</div>
          {/* <Select
            placeholder='Search'
            style={{ width: 110 }}
            defaultValue=''
            onChange={(e) => onColumnSearch(e, 'status')}
          >
            {STATUS_OPTION_SELECTS.map((item) => (
              <Option key={item.label + item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select> */}
        </TableTitle>
      ),
      dataIndex: 'status',
      align: 'center',
      width: 120,
      render: renderStatus,
    },
    {
      title: <TableTitle>Ngày điều xe</TableTitle>,
      dataIndex: 'assignOn',
      width: 120,
      align: 'center',
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Số xe
          <Input name='carCode' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'carCode',
      width: 120,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Tài xế
          <Input name='driverName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'driverName',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Phụ xe
          <Input name='driverSubName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'driverSubName',
      align: 'center',
      width: 120,
    },
    {
      title: (
        <TableTitle>
          Địa điểm bắt đầu
          <Input name='beginLocationName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'beginLocationName',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Địa điểm kết thúc
          <Input name='endLocationName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'endLocationName',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Khoảng cách
          <Input name='distance' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'distance',
      width: 120,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Hao hụt
          <Input name='lossOfDistance' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'lossOfDistance',
      width: 120,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Định mức dầu
          <Input name='oilQuotaByCar' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'oilQuotaByCar',
      width: 120,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Dầu
          <Input name='oil' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'oil',
      width: 120,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Dầu hao hụt
          <Input name='lossOfOil' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'lossOfOil',
      width: 120,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Ghi chú
          <Input name='note' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'note',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Người tạo
          <Input name='createdBy' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'createdBy',
      align: 'center',
      width: 120,
    },
    {
      title: <TableTitle>Ngày tạo</TableTitle>,
      dataIndex: 'createdOn',
      width: 120,
      align: 'center',
      render: (val) => Utils.formatDateHourFn_2(val),
    },
    {
      title: 'Action',
      fixed: 'right',
      align: 'center',
      width: 80,
      render: (record) => {
        return (
          <>
            {/* <Popconfirm
              onConfirm={() => handleOilProvided(record?.id)}
              title='Xác nhận cấp dầu?'
              okText='Đồng ý'
              cancelText='Không'
            >
              <Button type='primary' size='small' style={{ padding: '0px 20px' }}>
                In phiếu dầu
              </Button>
            </Popconfirm> */}
            {record?.status === 0 && (
              <Popconfirm
                onConfirm={() => handleDelete(record?.id)}
                title='Xoá công việc này?'
                okText='Đồng ý'
                cancelText='Không'
              >
                <Button
                  type='link'
                  danger
                  size='small'
                  style={{ padding: '0px 20px', marginTop: '5px' }}
                >
                  Xoá
                </Button>
              </Popconfirm>
            )}
          </>
        );
      },
    },
  ];

  const createTaskJobExtras = async () => {
    await setTitleTab('Thêm mới');
    await setFieldsForm({})
      .then((res) => getUpdatedTaskJobExtrasForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };

  const handleChangeAssignCarDate = (date) => {
    setAssignCarDate(date);
  };

  // const handleOilMultipleProvided = async () => {
  //   if (loading) return;
  //   setLoading(true);
  //   return ApiServices.printOilOfTaskJobExtras({ ids: selectedMultipleTicket })
  //     .then((res) => {
  //       if (res?.data?.data) {
  //         getData(filter, assignCarDate);
  //         message.success(`Xác nhận cấp dầu cho ${countSelectedTicket} phiếu thành công`);
  //         setSelectedMultipleTicket([]);
  //         setSelectMultipleRowData([]);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('Accept provide oild failed:', err);
  //       message.error('Xác nhận thất bại');
  //     })
  //     .finally(() => setLoading(false));
  // };

  // const handlePrintMultipleTicket = async () => {
  //   if (loading) return;

  //   setLoading(true);
  //   try {
  //     const resProvided = await ApiServices.printOilOfTaskJobExtras({
  //       ids: selectedMultipleTicket,
  //     });
  //     if (resProvided?.data?.data) {
  //       return ApiServices.getAllDetailOfTaskJobExtras(selectedMultipleTicket)
  //         .then((res) => {
  //           if (res?.data?.data) {
  //             getData(filter, assignCarDate);
  //             console.log('get all detail task job extras: ', res?.data?.data);
  //             setDataMultipleOilTicketDetail(res?.data?.data);
  //             setShowMultipleTicketDetail(true);
  //             setSelectRowData(selectMultipleRowData);
  //           }
  //         })
  //         .catch((err) => {
  //           console.log('Get multiple oil ticket details failed: ', err);
  //         });
  //     }
  //   } catch {
  //     (err) => {
  //       console.log('Provide Oil Error: ', err);
  //     };
  //   } finally {
  //     setLoading(false);
  //     setSelectedMultipleTicket([]);
  //     setSelectMultipleRowData([]);
  //   }
  // };

  const headerContent = (
    <Space>
      Ngày điều xe:
      <DatePicker
        value={assignCarDate}
        onChange={handleChangeAssignCarDate}
        placeholder='Ngày điều xe'
        style={{ width: '100%', marginRight: '20px' }}
        format={DATE_FORMAT}
        allowClear={false}
      />
      <Button type='primary' onClick={createTaskJobExtras}>
        Thêm Mới
      </Button>
      {/* <Badge count={countSelectedTicket}>
        <Popconfirm
          onConfirm={handleOilMultipleProvided}
          title={`Xác nhận cấp dầu cho ${countSelectedTicket} phiếu?`}
          okText='Đồng ý'
          cancelText='Không'
          disabled={countSelectedTicket < 1}
        >
          <Button
            type='primary'
            style={{ padding: '0px 20px', marginLeft: '10px' }}
            disabled={countSelectedTicket < 1}
          >
            Xác nhận cấp dầu
          </Button>
        </Popconfirm>
      </Badge> */}
      {/* <Badge count={countSelectedTicket}>
        <Button
          danger
          icon={<PrinterOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px' }}
          disabled={countSelectedTicket < 1}
          onClick={handlePrintMultipleTicket}
        >
          In phiếu dầu
        </Button>
      </Badge> */}
    </Space>
  );

  const pagination = {
    total: taskJobExtrasList?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  const rowSelection = (selectedRowKeys, rowData) => {
    console.log({ rowData });
    setSelectedMultipleTicket(selectedRowKeys);
    // setSelectMultipleRowData(rowData);
  };
  return (
    <>
      <TableLayout
        rowKey='id'
        columns={columns}
        data={taskJobExtrasList?.data}
        loading={loading}
        selectParent={selectedMultipleTicket}
        onSelectChange={rowSelection}
        selected={selected}
        closeDrawer={() => setSelected(null)}
        isLoadingTab={isLoadingTab}
        headerContent={headerContent}
        titleTab={titleTab}
        tabPanels={tabPanels}
        pagination={pagination}
        width={700}
      />
      {/* <MultipleOilTicketDetail
        open={showMultipleTicketDetail}
        data={dataMultipleOilTicketDetail}
        selectMultipleRowData={selectRowData}
        onClose={onCloseMultipleTicket}
      /> */}
    </>
  );
};

export { TaskJobExtrasList };
