import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Modal } from 'antd';
import moment from 'moment';
import { debounce } from 'lodash';
import { Header } from './header';
import { JobListOfCar } from './job-list-of-car';
import { Information } from './information';
import { Expenses } from './expenses';
import { Tickets } from './ticket-list';
import { FormCreate } from './form-create';
import ApiServices from '~services';
import Utils from '~commons/utils';

const DEFAULT_QUERIES_JOB = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().subtract(1, 'month').format(),
  DateTo: moment().endOf('date').format(),
};

const CreateTicketJobByVendor = () => {
  const [loading, setLoading] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [originDataJobOfCar, setOriginDataJobOfCar] = useState([]);
  const [jobListOfCar, setJobListOfCar] = useState([]);
  const [jobSelected, setJobSelected] = useState(null);
  const [expensesList, setExpensesList] = useState([]);
  const [expensesIdSelected, setExpensesIdSelected] = useState([]);
  const [expensesSelected, setExpensesSelected] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [dissolveDateDefault, setDissolveDateDefault] = useState(moment());
  // const [totalRecords, setTotalRecords] = useState(0);
  // search of Job List
  const [filterJob, setFilterJob] = useState(DEFAULT_QUERIES_JOB);
  const [filterJobSearch, setFilterJobSearch] = useState({});

  const getJobTicketByVendorId = async (vendorId, query = DEFAULT_QUERIES_JOB) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getJobTicketByVendorIdToActionByVendor(vendorId, newQuery)
      .then((res) => {
        if (res?.data?.data) {
          const jobData = res?.data?.data?.jobs?.map((item) => {
            item.key = item?.jobId;
            item.authorizedCont = false;
            return item;
          });
          const commissionData = res?.data?.data?.commissions?.map((item) => {
            item.key = item?.contId;
            item.jobId = item?.contId;
            item.authorizedCont = true;
            item.name = item?.subnoNumber;
            return item;
          });
          const dataEnd = jobData.concat(commissionData);
          setOriginDataJobOfCar(dataEnd || []);
          setJobListOfCar(dataEnd || []);
        } else {
          setOriginDataJobOfCar([]);
          setJobListOfCar([]);
        }
      })
      .catch((err) => {
        console.error('Get Job by VendorId: ', err);
        setOriginDataJobOfCar([]);
        setJobListOfCar([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  ///////////
  const getJobTicketByContId = async (contId) => {
    setLoading(true);
    return ApiServices.getJobTicketByContId(contId)
      .then((res) => {
        if (res?.data?.data) {
          const mapExpenses = res?.data?.data?.expenses?.map((item) => {
            item.key = item.id;
            item.isExpenses = item?.objTicketExpenses ? true : false;
            item.billRequired = item?.billRequired ? true : false;
            item.price = item?.objTicketExpenses ? item?.objTicketExpenses?.price : '';
            item.vat = item?.objTicketExpenses ? item?.objTicketExpenses?.vat : '';
            item.total = item?.objTicketExpenses
              ? item?.objTicketExpenses?.total
              : item?.fee
              ? item?.fee
              : '';
            item.billNumber = item?.objTicketExpenses ? item?.objTicketExpenses?.billNumber : '';
            item.billDate = item?.objTicketExpenses ? item?.objTicketExpenses?.billDate : null;
            item.supplierName = item?.objTicketExpenses
              ? item?.objTicketExpenses?.supplierName
              : item?.supplier
              ? item?.supplier
              : '';
            return item;
          });
          setExpensesList(mapExpenses || []);
          setTicketList(res?.data?.data?.tickets || []);
        }
      })
      .catch((err) => {
        console.error('Get Bill by ContId: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // ==========   get Job Ticket By Vendor Id
  const timeoutGetDataJob = useCallback(
    debounce((vendorId, newFilter) => {
      getJobTicketByVendorId(vendorId, newFilter);
    }, 1000),
    []
  );
  useEffect(() => {
    const vendorId = JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId;
    // const carId = JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.ObjectId;
    const carName = JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.FullName;
    const carCode = JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.Code;

    const dataCar = {
      id: vendorId,
      name: carName,
      code: carCode,
    };
    setSelectedCar(dataCar);
    if (vendorId) {
      timeoutGetDataJob(vendorId, filterJob);
    }
  }, [filterJob]);

  // ==========   get Job Ticket By ContId
  useEffect(() => {
    if (jobSelected?.contId) {
      getJobTicketByContId(jobSelected?.contId);
    }
  }, [jobSelected?.contId]);

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;
      if (query?.contNumber) {
        if (
          !Utils.convertValSearch(item?.contNumber)?.includes(
            Utils.convertValSearch(query?.contNumber)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.name) {
        if (!Utils.convertValSearch(item?.name)?.includes(Utils.convertValSearch(query?.name))) {
          checkFilter = false;
        }
      }
      if (query?.orderType) {
        if (
          !Utils.convertValSearch(item?.orderType)?.includes(
            Utils.convertValSearch(query?.orderType)
          )
        ) {
          checkFilter = false;
        }
      }
      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    setJobListOfCar(dataFilter);
  };

  const timeoutGetDataJobSearch = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    []
  );
  useEffect(() => {
    if (originDataJobOfCar?.length) {
      timeoutGetDataJobSearch(filterJobSearch, originDataJobOfCar);
    }
  }, [filterJobSearch]);

  const onColumnSearchJob = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilterJobSearch((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setExpensesIdSelected([]);
    setExpensesSelected([]);
    setIsModalOpen(false);
  };
  const reload = async () => {
    const vendorId = JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId;
    if (vendorId) {
      await timeoutGetDataJob(vendorId, filterJob);
    } else {
      setJobListOfCar([]);
    }
    setExpensesList([]);
    setTicketList([]);
    setExpensesIdSelected([]);
    setExpensesSelected([]);
    setIsModalOpen(false);
  };

  const clearDataWhenSearch = () => {
    setSelectedCar(null);
    setJobSelected([]);
    setJobListOfCar([]);
    setExpensesList([]);
    setTicketList([]);
    setExpensesIdSelected([]);
    setExpensesSelected([]);
  };

  // const pagination = {
  //   total: totalRecords || 0,
  //   showSizeChanger: false,
  //   pageSize: filter.PageSize,
  //   onChange: (PageNumber) =>
  //     setFilter((prev) => ({
  //       ...prev,
  //       PageNumber,
  //     })),
  // };

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Header
          expensesIdSelected={expensesIdSelected}
          showModal={showModal}
          filter={filterJob}
          setFilter={setFilterJob}
          dissolveDateDefault={dissolveDateDefault}
          setDissolveDateDefault={setDissolveDateDefault}
          clearDataWhenSearch={clearDataWhenSearch}
        />
      </div>
      <div
        style={{
          marginBottom: '10px',
          height: '620px',
        }}
      >
        <Row>
          <Col flex='320px'>
            <div style={{ width: '320px' }}>
              <JobListOfCar
                dataJobs={jobListOfCar}
                setJobSelected={setJobSelected}
                onColumnSearchJob={onColumnSearchJob}
                loadingData={loading}
              />
            </div>
          </Col>
          <Col flex='auto'>
            <div
              style={{
                maxWidth: 'calc(100vw - 600px)',
                marginLeft: '10px',
              }}
            >
              <Expenses
                dataExpenses={expensesList}
                expensesIdSelected={expensesIdSelected}
                setExpensesIdSelected={setExpensesIdSelected}
                setExpensesSelected={setExpensesSelected}
                loadingData={loading}
              />
            </div>
            <div
              style={{
                fontSize: '12px',
                marginLeft: '10px',
                marginTop: '10px',
                borderRadius: '6px',
                border: '1px solid blue',
                padding: '10px',
                maxWidth: 'calc(100vw - 600px)',
              }}
            >
              <Information dataInfo={jobSelected} />
            </div>
            <div
              style={{
                maxWidth: 'calc(100vw - 600px)',
                marginLeft: '10px',
                marginTop: '10px',
              }}
            >
              <Tickets ticketList={ticketList} />
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        title='Thêm phiếu giải trừ xe ngoài'
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        style={{
          top: 20,
        }}
        width='80%'
      >
        <FormCreate
          dataCar={selectedCar}
          dataInfo={jobSelected}
          expensesSelected={expensesSelected}
          reload={reload}
          dissolveDateDefault={dissolveDateDefault}
        />
      </Modal>
    </div>
  );
};

export { CreateTicketJobByVendor };
