import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { InfoCarInspection } from './inforCarInspection';
import { FormUpdateCarInsurance } from './form-update';
import CarInspectionFields from './fields-data';
import { DisplayFilesSimple } from '~components/display-files-simple';
import { TableTitle } from '~commons/styles';

const DrivingCertificate = ({ id }) => {
  const [infoDriver, setInfoDriver] = useState({});
  const [inspectionList, setInspectionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowFormUpdate, setIsShowFormUpdate] = useState(false);
  const [fields, setFields] = useState({});
  const [isNew, setIsNew] = useState(false);
  const [dataCarInspection, setDataCarInspection] = useState({});

  const getCarInsurances = async () => {
    try {
      setLoading(true);
      let { data } = await ApiServices.getDrivingLicenses(id);
      setLoading(false);
      if (data?.data) {
        const inspectionList =
          data?.data?.inspectionList?.data?.map((item, index) => {
            item.index = index;
            return item;
          }) || [];
        setInfoDriver(data?.data?.infoDriver);
        setInspectionList(inspectionList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCarInsurances();
  }, [id]);
  useEffect(() => {
    setIsShowFormUpdate(false);
  }, [id]);

  const setFieldsForm = async (value) => {
    const carInspectionFields = await CarInspectionFields(value);
    await setFields(carInspectionFields);
    return carInspectionFields;
  };

  const isShowFormUpdateFn = async () => {
    await setFieldsForm({}).then(() => {
      setIsNew(true);
      setIsShowFormUpdate(true);
    });
  };

  const onRowSelected = async (value) => {
    const _value = {
      ...value,
      cnInsuranceDate: Utils.convertToDate(value.cnInsuranceDate),
      cnInsuranceExpDate: Utils.convertToDate(value.cnInsuranceExpDate),
      files: value?.orderFiles,
    };
    await setFieldsForm(_value).then(() => {
      setIsNew(false);
      setDataCarInspection(value);
      setIsShowFormUpdate(true);
    });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'cnInsuranceNumber',
      width: 30,
      align: 'center',
      render: (value, record) => {
        if (record?.index === 0) {
          return <a onClick={() => onRowSelected(record)}>{value ? value : <FormOutlined />}</a>;
        } else {
          return <span>{value}</span>;
        }
      },
    },
    {
      title: 'HH Chứng Nhận',
      dataIndex: 'inspectionExpirationDateNew',
      width: 80,
      align: 'center',
      render: (value) => Utils.formatDate(value),
    },
    {
      title: 'Cơ Quan Chứng Nhận',
      dataIndex: 'cnOrganization',
      align: 'center',
      width: 100,
    },
    {
      title: <TableTitle>File Đính kèm</TableTitle>,
      dataIndex: 'orderFiles',
      align: 'center',
      width: 150,
      render: (val) => <DisplayFilesSimple files={val} />,
    },
    {
      title: 'Ghi Chú',
      dataIndex: 'note',
      width: 100,
    },
  ];

  return (
    <>
      {isShowFormUpdate ? (
        <FormUpdateCarInsurance
          setIsShowFormUpdate={setIsShowFormUpdate}
          fields={fields}
          onChange={(newFields) => {
            setFields(newFields);
          }}
          driverId={id}
          getCarInsurances={getCarInsurances}
          isNew={isNew}
          dataCarInspection={dataCarInspection}
          setLoading={setLoading}
        />
      ) : (
        <>
          <InfoCarInspection infoDriver={infoDriver} />
          <Button type='primary' className='mg-b-10 mg-t-10' onClick={isShowFormUpdateFn}>
            Thêm Mới
          </Button>
        </>
      )}
      <div
        className='table-container-custom'
        style={{
          maxHeight: `calc(100vh - 300px`,
        }}
      >
        <Table
          rowKey='id'
          bordered
          size='small'
          loading={loading}
          dataSource={inspectionList}
          columns={columns}
        />
      </div>
    </>
  );
};

export { DrivingCertificate };
