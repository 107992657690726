import { useSelector, useDispatch } from 'react-redux';
import { CONT_ORDER_TYPES } from '~commons/constants';
import {
  fetchWaitingOfPlan,
  planedCont,
  updateLoading as updateLoadingPlan,
  updateSelected as updateSelectedPlan,
  updateOne as updateOnePlan,
} from '~store/reducers/waiting-of-plan/action';
import {
  fetchWaitingOfDelivery,
  updateLoadingReport,
  updateOne as updateOneDelivery,
} from '~store/reducers/waiting-for-delivery/action';
import {
  fetchLiftOn,
  updateLoading as updateLoadingLiftOn,
  updateSelected as updateSelectedLiftOn,
  selectedDataRowLiftOn,
  updateOne as updateOneLiftOn,
} from '~store/reducers/lift-on/action';
import {
  fetchLiftOff,
  updateLoading as updateLoadingLiftOff,
  updateSelected as updateSelectedLiftOff,
  updateOne as updateOneLiftOff,
  selectedDataRowLiftOff,
} from '~store/reducers/lift-off/action';
import {
  fetchData as fetchParkingCont,
  updateLoading as updateLoadingParkingCont,
} from '~store/reducers/parking-cont/action';
import {
  fetchData as fetchSingleCont,
  updateLoading as updateLoadingSingleCont,
} from '~store/reducers/single-cont/action';
import {
  updateDataAssign,
  updateDataAssignItem,
  updateRomoocAssign,
  updateCarAssign,
  checkDistanceLocations,
  removeOneAssignItem,
  updateDriverAssign,
  updateShowCustomerDetail,
  showListFile,
  updateAssignLoading,
} from '~store/reducers/white-board/action';
import ApiServices from '~services';
import { message } from 'antd';
import moment from 'moment';
import Utils from '~commons/utils';

const REVERSE_ROMOOC = 'Reverse';
const getSupportTask = (task, contId, resData) => {
  if (task.romooc) {
    resData.push({
      endLocation: task?.romooc?.newLocationId || task?.romooc?.currentLocationCode,
      romoocId: task?.romooc?.id,
      name: task.taskName,
      contId,
    });
  }
  if (task.location) {
    resData.push({
      endLocation: task?.location.id,
      name: task.taskName,
      contId: task.cont?.id || contId,
    });
  }
  return resData;
};

const getListAssign = (resData = [], dataAssign) => {
  for (let keyAssign in dataAssign) {
    let prevAssign = keyAssign && dataAssign[keyAssign - 1];
    const contId = prevAssign?.contId || dataAssign[keyAssign].contId;
    const name = dataAssign[keyAssign].name;
    const supportTask = dataAssign[keyAssign].supportTask;
    //two previous support Task
    supportTask?.prev && (resData = getSupportTask(supportTask?.prev, supportTask.contId, resData));
    supportTask?.prev2 &&
      (resData = getSupportTask(supportTask?.prev2, supportTask.contId, resData));
    //main task
    if (
      supportTask?.next &&
      supportTask.next.taskName === REVERSE_ROMOOC &&
      supportTask.next.romooc?.id
    ) {
      resData.push({
        id: dataAssign[keyAssign].id,
        endLocation: dataAssign[keyAssign].endLocation,
        name: REVERSE_ROMOOC,
        romoocId: supportTask.next.romooc.id,
        ...(contId && { contId }),
      });
    } else {
      resData.push({
        id: dataAssign[keyAssign].id,
        endLocation: dataAssign[keyAssign].endLocation,
        ...(name && { name }),
        ...(contId && { contId }),
      });
      if (dataAssign[keyAssign].transit?.cont) {
        resData.push({
          name: CONT_ORDER_TYPES.TRANSIT,
          contId: dataAssign[keyAssign].transit.cont,
          endLocation: dataAssign[keyAssign].transit.start,
        });
        resData.push({
          name: CONT_ORDER_TYPES.TRANSIT,
          contId: dataAssign[keyAssign].transit.cont,
          endLocation: dataAssign[keyAssign].transit.end,
        });
      }
      //two next support Task
      supportTask?.next &&
        (resData = getSupportTask(supportTask?.next, supportTask.contId, resData));
    }
    supportTask?.next2 &&
      (resData = getSupportTask(supportTask?.next2, supportTask.contId, resData));
  }
  return resData;
};

const formatDataAssign = (dataAssign, romoocAssign, finalTask, selectedRowsLiftOn) => {
  let resData = [];
  if (romoocAssign.id) {
    resData = [
      {
        endLocation: romoocAssign?.newLocationId || romoocAssign?.currentLocationCode,
        romoocId: romoocAssign?.id,
        contId: selectedRowsLiftOn?.length && selectedRowsLiftOn[0].contId,
      },
    ];
  }
  resData = getListAssign(resData, dataAssign);
  if (finalTask) {
    resData.push({
      endLocation: finalTask.endLocation,
      final: true,
    });
  }
  return resData;
};
export default function useApiHook() {
  const dispatch = useDispatch();
  const {
    selectedRows: selectedRowsLiftOn,
    selectedDataRows: selectedLiftOn,
    query: queryLiftOn,
  } = useSelector((state) => state.liftOn);
  const {
    selectedRows: selectedRowsLiftOff,
    indexSelected: indexSelectedLiftOff,
    selectedDataRows: selectedLiftOff,
    query: queryLiftOff,
  } = useSelector((state) => state.liftOff);
  const { query: queryWaitingDelivery } = useSelector((state) => state.waitingOfDelivery);
  const { query: queryWaitingPlan } = useSelector((state) => state.waitingOfPlan);
  const { query: queryParkingCont } = useSelector((state) => state.parkingCont);
  const { query: querySingleCont } = useSelector((state) => state.singleCont);
  //
  //check is show assign popup
  const isShowAssignPopup = selectedLiftOn?.length || selectedLiftOff?.length;

  const reloadLiftOff = async (query = queryLiftOff) => {
    try {
      await dispatch(updateLoadingLiftOff(true));
      await dispatch(fetchLiftOff(query));
    } catch (err) {
      console.error('Reload Lift Off Error', err);
    }
    await dispatch(updateLoadingLiftOff(false));
  };

  const reloadLiftOn = async (query = queryLiftOn) => {
    try {
      await dispatch(updateLoadingLiftOn(true));
      await dispatch(fetchLiftOn(query));
    } catch (err) {
      console.error('Reload Lift On Error', err);
    }
    await dispatch(updateLoadingLiftOn(false));
  };

  const reloadWaitingOfPlan = async (query = queryWaitingPlan) => {
    try {
      await dispatch(updateLoadingPlan(true));
      await dispatch(fetchWaitingOfPlan(query));
    } catch (err) {
      console.error('Reload Waiting Of Plan Error', err);
    }
    dispatch(updateLoadingPlan(false));
  };

  const reloadWaitingOfDelivery = async (query = queryWaitingDelivery) => {
    try {
      await dispatch(updateLoadingReport(true));
      await dispatch(fetchWaitingOfDelivery(query));
    } catch (err) {
      console.error('Reload Waiting Of Delivery Error', err);
    }
    await dispatch(updateLoadingReport(false));
  };

  const reloadParkingCont = async (query = queryParkingCont) => {
    try {
      await dispatch(updateLoadingParkingCont(true));
      await dispatch(fetchParkingCont(query));
    } catch (err) {
      console.error('Reload Parking Cont Error', err);
    }
    await dispatch(updateLoadingParkingCont(false));
  };

  const reloadSingleCont = async (query = querySingleCont) => {
    try {
      await dispatch(updateLoadingSingleCont(true));
      await dispatch(fetchSingleCont(query));
    } catch (err) {
      console.error('reload Single Cont Error', err);
    }
    dispatch(updateLoadingSingleCont(false));
  };

  const reloadAll = () => {
    reloadWaitingOfPlan();
    reloadLiftOff();
    reloadLiftOn();
    reloadWaitingOfDelivery();
    reloadParkingCont();
  };

  //waitingOfPlan
  const planedContHandle = async (contIds = []) => {
    try {
      dispatch(updateLoadingPlan(true));
      dispatch(updateSelectedPlan([]));
      await dispatch(planedCont(contIds));
    } catch (err) {
      console.error('planed Cont Handle Error', err);
    }
    dispatch(updateLoadingPlan(false));
    reloadLiftOn();
    reloadWaitingOfPlan();
  };
  //ASSIGN
  //clear assign selected
  const clearSelectedLiftOn = () => {
    dispatch(updateSelectedLiftOn([]));
    dispatch(selectedDataRowLiftOn([]));
  };
  const clearSelectedLiftOff = () => {
    dispatch(updateSelectedLiftOff([]));
    dispatch(selectedDataRowLiftOff([]));
  };
  const closeAssign = () => {
    clearSelectedLiftOn();
    clearSelectedLiftOff();

    dispatch(updateDataAssign([]));
    dispatch(updateRomoocAssign({}));
    dispatch(updateCarAssign({}));
    dispatch(updateDriverAssign(null));
  };
  const updateAssignCar = (data) => {
    dispatch(updateCarAssign(data));
  };
  const updateAssignRomooc = (data) => {
    dispatch(updateRomoocAssign(data));
  };
  const updateAssignDriver = (data) => {
    dispatch(updateDriverAssign(data));
  };
  const updateAssignData = (newData) => {
    dispatch(updateDataAssignItem(newData));
  };
  const checkDistances = async (restData) => {
    const dataCheckDistance = restData
      .map((item, index) => {
        if (index === 0) return;
        if (restData[index - 1].endLocation === item.endLocation) return;
        return {
          begin: restData[index - 1].endLocation,
          end: item.endLocation,
          check: restData[index - 1].endLocation + '-' + item.endLocation,
        };
      })
      .filter((data) => data);

    const dataArrCheck = dataCheckDistance.map((item) => item.check);
    const dataCheckDistanceFinal = Utils.uniqueCheckDistance(dataArrCheck).map((data) => {
      return {
        begin: data.split('-')[0],
        end: data.split('-')[1],
      };
    });
    try {
      const { data } = await ApiServices.checkIssetDistances(dataCheckDistanceFinal);
      if (data) return data.data;
    } catch (err) {
      console.error('check Distances Error', err);
    }
  };

  const submitAssign = async (
    carAssign,
    dataAssign,
    romoocAssign,
    finalTask,
    assignDate,
    driverAssign = null
  ) => {
    const resData = formatDataAssign(dataAssign, romoocAssign, finalTask, selectedRowsLiftOn);

    setAssignLoading(true);
    return checkDistances([{ endLocation: carAssign?.currentLocationCode }, ...resData])
      .then(async (dataCheckDistances) => {
        const postData = {
          formAssigns: resData,
          assignDate: moment(assignDate).format(),
          driverId: driverAssign,
        };
        if (!dataCheckDistances) {
          return createAssign(carAssign?.id, postData);
        }
        return dispatch(
          checkDistanceLocations(dataCheckDistances, () => createAssign(carAssign?.id, postData))
        );
      })
      .catch((err) => {
        console.error('submitAssign - checkDistances Error', err);
      })
      .finally(() => setAssignLoading());
  };

  const createAssignPlan = async (resData) => {
    try {
      const { data } = await ApiServices.createAssignPlan(resData);
      if (data?.succeeded === false) return;
      setAssignLoading(false);
      closeAssign();
      reloadLiftOn();
      reloadLiftOff();
      message.success('Kế hoạch thành công!');
    } catch (err) {
      message.error('Kế hoạch thất bại!');
    }
  };

  const submitAssignPlan = async (dataAssign, carAssign, romoocAssign, tour) => {
    const dataSend = {
      jobIds: dataAssign?.map((item) => {
        return { id: item.id, endLocation: item?.endLocation };
      }),
      romoocPlanName: romoocAssign?.code,
      romoocPlanId: romoocAssign?.romoocId,
      carPlanName: carAssign?.code,
      carPlanId: carAssign?.id,
      tour,
    };
    return createAssignPlan(dataSend);
  };

  const createAssign = (carId, resData) => {
    setAssignLoading(true);
    return ApiServices.createAssign(carId, resData)
      .then(({ data }) => {
        if (data?.succeeded === false) return;

        if (data?.data) {
          closeAssign();
          reloadLiftOn();
          reloadLiftOff();
          reloadWaitingOfDelivery();
          reloadParkingCont();
          message.success('Điều xe thành công!');
        }
      })
      .catch((err) => {
        message.error('Điều xe thất bại!');
        console.error('Create Assign Error', err);
      })
      .finally(() => {
        setAssignLoading();
      });
  };

  const transferJobs = (companyId, data, isFormatted = false, romoocId) => {
    const jobs = isFormatted ? data : data.filter((el) => !!el.id).map((el) => el.id);
    setAssignLoading(true);
    return ApiServices.transferJobs({ companyId, jobs, romoocId })
      .then((res) => {
        if (res.data) {
          setAssignLoading();
          closeAssign();
          reloadLiftOn();
          reloadLiftOff();
          reloadWaitingOfDelivery();
          reloadParkingCont();
          return message.success('Chuyển thành công!');
        }
        message.error('Chuyển CV thất bại!');
      })
      .catch((err) => {
        console.log('Transfer Job Error', err);
        message.error('Chuyển CV thất bại!');
      })
      .finally(() => {
        setAssignLoading();
      });
  };

  const transferJobsWbPlan = (companyId, conts) => {
    setAssignLoading(true);
    return ApiServices.transferConts({ companyId, conts })
      .then((res) => {
        if (res.data) {
          setAssignLoading();
          closeAssign();
          reloadWaitingOfPlan();
          return message.success('Chuyển thành công!');
        }
        message.error('Chuyển CV thất bại!');
      })
      .catch((err) => {
        console.log('Transfer Conts Error', err);
        message.error('Chuyển CV thất bại!');
      })
      .finally(() => {
        setAssignLoading();
      });
  };

  const updateContNote = async (contId, data) => {
    return ApiServices.updateContNote(contId, data)
      .then((res) => {
        if (res.data?.modifiedCount) {
          dispatch(
            updateOnePlan({
              id: contId,
              notePlan: data,
            })
          );
        }
      })
      .catch((err) => {
        console.error(err);
        message.error('Ghi chú thất bại!');
      });
  };
  const updateAllNote = (contId, data) => {
    dispatch(
      updateOnePlan({
        id: contId,
        notePlan: data.notePlan,
      })
    );

    dispatch(
      updateOneLiftOn({
        id: contId,
        noteUp: data.noteUp,
      })
    );
    dispatch(
      updateOneDelivery({
        id: contId,
        noteDelivery: data.noteDelivery,
      })
    );

    dispatch(
      updateOneLiftOff({
        id: contId,
        noteDown: data.noteDown,
      })
    );
  };
  const updateNote = (id, data, isLiftOn = true) => {
    return ApiServices.updateNote(id, data)
      .then((res) => {
        if (res.data?.modifiedCount) {
          const nData = {
            id,
            note: data,
          };
          dispatch(isLiftOn ? updateOneLiftOn(nData) : updateOneLiftOff(nData));
        }
      })
      .catch(() => {
        message.error('Ghi chú thất bại!');
      });
  };
  const removeOneAssign = (id) => {
    dispatch(removeOneAssignItem(id));
  };

  const canCelLiftOn = async (contId) => {
    try {
      const { data } = await ApiServices.cancelContsLiftOn([contId]);
      if (data?.succeeded === false) return;
      message.success('Thao tác thành công!');
      reloadLiftOn();
      reloadWaitingOfDelivery();
      reloadWaitingOfPlan();
    } catch (err) {
      message.error('Thao tác thất bại!');
    }
  };
  const canCelLiftOff = async (contId) => {
    try {
      const { data } = await ApiServices.cancelContsLiftOff([contId]);
      if (data?.succeeded === false) return;
      message.success('Thao tác thành công!');
      reloadLiftOff();
      reloadWaitingOfDelivery();
    } catch (err) {
      message.error('Thao tác thất bại!');
    }
  };
  const exportReportLiftOff = async () => {
    try {
      const { data } = await ApiServices.exportWhiteBoardThree();
      if (data) {
        const outputFilename = `Bao_cao_giao_hang_xuat_tra_rong_nhap.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    }
  };
  const showCustomerDetail = (data) => {
    dispatch(updateShowCustomerDetail(data));
  };
  const showOrderFiles = (record) => {
    dispatch(showListFile(record));
  };
  const setAssignLoading = (loading) => {
    dispatch(updateAssignLoading(loading));
  };

  return {
    setAssignLoading,
    showCustomerDetail,
    planedContHandle,
    reloadLiftOff,
    reloadLiftOn,
    reloadWaitingOfPlan,
    reloadWaitingOfDelivery,
    reloadParkingCont,
    reloadAll,
    reloadSingleCont,
    isShowAssignPopup,
    closeAssign,
    updateAssignData,
    submitAssign,
    submitAssignPlan,
    updateAssignRomooc,
    updateAssignCar,
    totalSelect: selectedLiftOn?.length + selectedLiftOff?.length,
    indexSelectedLiftOff,
    totalLiftOffSelect: selectedRowsLiftOff?.length,
    updateNote,
    removeOneAssign,
    updateContNote,
    canCelLiftOn,
    canCelLiftOff,
    exportReportLiftOff,
    updateAssignDriver,
    transferJobs,
    transferJobsWbPlan,
    updateAllNote,
    showOrderFiles,
  };
}
