import React, { useEffect, useState } from 'react';
import { Form, Button, DatePicker, Input, Row, Col, Modal } from 'antd';
import moment from 'moment';
// import moment from 'moment';
import { layout } from './constant';
import ApiServices from '~services';
import { DATE_FORMAT, DATE_FORMAT_SEND_API } from '~commons/constants';
import { CusItemAddForm } from './cus-add-item-form';
import { ShowContractCreated } from '../show-contract-created';
import Utils from '~commons/utils';
import { LoadingView } from '~components';

const initData = {
  contractNumber: '',
  customerId: '',
  contractDate: null,
  expiredDate: null,
  renewExpiredDate: null,
  arrLocations: [],
  note: '',
};

const UpdateForm = ({ isShow, onClose, dataSelect, reload }) => {
  const [form] = Form.useForm();
  const [loadingForm, setLoadingForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [partnerId, setPartnerId] = useState('');
  const [partnerName, setPartnerName] = useState('');
  const [customerList, setCustomerList] = useState([]);
  const [showAddCusItem, setShowAddCusItem] = useState(false);
  const [contractNumber, setContractNumber] = useState('');
  const [dataAll, setDataAll] = useState(initData);
  const [contractDate, setContractDate] = useState(null);
  const [expiredDate, setExpiredDate] = useState(null);

  // Cont Type
  const [contTypeList, setContTypeList] = useState([]);
  // const isadd
  const [isUpdateNew, setIsUpdateNew] = useState(false);

  // get list detail by Code
  const [detailList, setDetailList] = useState([]);
  const [infoDetail, setInfoDetail] = useState(null);

  // get list detail by Code
  const getDetailByCode = async (contractCode) => {
    setLoadingForm(true);
    try {
      const res = await ApiServices.getContractDetailByCode(contractCode);
      if (res?.data?.data) {
        setPartnerId(res?.data?.data?.info?.partnerId || '');
        setPartnerName(res?.data?.data?.info?.partnerName || '');
        setContractNumber(res?.data?.data?.info?.contractNumber || '');
        setContractDate(Utils.convertToDate(res?.data?.data?.info?.contractDate) || null);
        setExpiredDate(Utils.convertToDate(res?.data?.data?.info?.expiredDate) || null);
        setInfoDetail(res?.data?.data?.info);
        if (res?.data?.data?.detailList?.length) {
          setDetailList(res?.data?.data?.detailList);
        }
      }
    } catch (error) {
      console.log('Error -  getContractDetailByCode', error);
    } finally {
      setLoadingForm(false);
    }
  };

  useEffect(() => {
    if (dataSelect?.contractNumber) {
      getDetailByCode(dataSelect?.contractNumber);
    }
  }, [dataSelect]);

  const getContType = async () => {
    setLoadingForm(true);
    try {
      let { data } = await ApiServices.getContTypes();
      if (data?.data) {
        setContTypeList(data?.data);
      }
    } catch (error) {
      console.log('Error - get contType', error);
    } finally {
      setLoadingForm(false);
    }
  };

  const getCustomer = async (query = '') => {
    setLoadingForm(true);
    const newQuery = {
      Columns: {
        shortName: query,
      },
    };
    try {
      const res = await ApiServices.getCustomer(newQuery);
      if (res?.data?.data) {
        setCustomerList(res?.data?.data || []);
      } else {
        setCustomerList([]);
      }
    } catch (error) {
      console.log('Error -  getPartnerCustomerDetails', error);
      setCustomerList([]);
    } finally {
      setLoadingForm(false);
    }
  };

  // useEffect(() => {
  //   getCustomer();
  // }, []);

  useEffect(() => {
    getContType();
    getCustomer();
  }, [dataSelect]);

  const handleDeleteContractCus = async (code, id, contractDetailLength) => {
    if (!code || !id) return;
    setLoading(true);
    return ApiServices.deleteOnlyContractForVendor(code, id)
      .then((res) => {
        if (res?.data?.data) {
          window.TmsNoti.success('Xoá Hợp đồng thành công');
          if (contractDetailLength === 1) {
            onClose();
            reload();
          } else {
            return getDetailByCode(code);
          }
        } else {
          window.TmsNoti.error('Lỗi', 'Xoá Hợp đồng thất baị');
        }
      })
      .catch((err) => {
        console.log('Delete contract failed: ', err);
        window.TmsNoti.error('Lỗi', 'Xoá Hợp đồng thất baị');
      })
      .finally(() => setLoading(false));
  };

  const onSubmitForm = async () => {};

  async function handleChangeExpireDate(dateExpire, newInfoDetail) {
    const convertDateCurrent = dateExpire ? moment(dateExpire).format(DATE_FORMAT_SEND_API) : null;
    const convertDateOrigin = newInfoDetail?.expiredDate
      ? moment(newInfoDetail?.expiredDate).format(DATE_FORMAT_SEND_API)
      : null;
    const convertContractDateOrigin = newInfoDetail?.contractDate
      ? moment(newInfoDetail?.contractDate).format(DATE_FORMAT_SEND_API)
      : null;

    if (moment(convertDateCurrent) < moment(convertContractDateOrigin)) {
      window.TmsNoti.warning('Cảnh báo', 'Ngày hết hạn phải lớn hơn ngày hợp đồng !!!');
      return;
    }

    if (convertDateCurrent && convertDateOrigin && convertDateCurrent !== convertDateOrigin) {
      const codeContract = newInfoDetail?.contractNumber;
      try {
        const dataSend = {
          dateFrom: convertContractDateOrigin, // ngày HĐ
          dateTo: convertDateCurrent, // ngày HHan
        };

        const res = await ApiServices.changeDateOfInfoContract(codeContract, dataSend);
        if (res?.data?.data) {
          window.TmsNoti.success('Thay đổi ngày hết hạn thành công');
          setExpiredDate(dateExpire);
          setDataAll((prev) => {
            prev.expiredDate = convertDateCurrent;
            return prev;
          });
          return getDetailByCode(codeContract);
        } else {
          window.TmsNoti.error('Lỗi', 'Thay đổi ngày hết hạn thất baị');
          setExpiredDate(newInfoDetail?.expiredDate);
          setDataAll((prev) => {
            prev.expiredDate = convertDateOrigin;
            return prev;
          });
        }
      } catch (error) {
        console.log({ error });
        window.TmsNoti.error('Lỗi', 'Thay đổi ngày hết hạn thất baị');
        setExpiredDate(newInfoDetail?.expiredDate);
        setDataAll((prev) => {
          prev.expiredDate = convertDateOrigin;
          return prev;
        });
      }
    } else {
      setExpiredDate(dateExpire);
      setDataAll((prev) => {
        prev.expiredDate = convertDateCurrent;
        return prev;
      });
    }
  }
  async function handleChangeContractDate(dateContract, newInfoDetail) {
    const convertDateCurrent = dateContract
      ? moment(dateContract).format(DATE_FORMAT_SEND_API)
      : null;
    const convertDateOrigin = newInfoDetail?.contractDate
      ? moment(newInfoDetail?.contractDate).format(DATE_FORMAT_SEND_API)
      : null;
    const convertExpireDateOrigin = newInfoDetail?.expiredDate
      ? moment(newInfoDetail?.expiredDate).format(DATE_FORMAT_SEND_API)
      : null;

    if (convertExpireDateOrigin && moment(convertDateCurrent) > moment(convertExpireDateOrigin)) {
      window.TmsNoti.warning('Cảnh báo', 'Ngày hợp đồng phải nhỏ hơn ngày hết hạn hợp đồng !!!');
      return;
    }

    if (convertDateCurrent && convertDateOrigin && convertDateCurrent !== convertDateOrigin) {
      const codeContract = newInfoDetail?.contractNumber;
      try {
        const dataSend = {
          dateFrom: convertDateCurrent, // ngày HĐ
          dateTo: convertExpireDateOrigin, // ngày HHan
        };

        const res = await ApiServices.changeDateOfInfoContract(codeContract, dataSend);
        if (res?.data?.data) {
          window.TmsNoti.success('Thay đổi ngày Hợp đồng thành công');
          setContractDate(dateContract);
          setDataAll((prev) => {
            prev.contractDate = convertDateCurrent;
            return prev;
          });
          return getDetailByCode(codeContract);
        } else {
          window.TmsNoti.error('Lỗi', 'Thay đổi ngày Hợp đồng thất baị');
          setContractDate(newInfoDetail?.contractDate);
          setDataAll((prev) => {
            prev.contractDate = convertDateOrigin;
            return prev;
          });
        }
      } catch (error) {
        console.log({ error });
        window.TmsNoti.error('Lỗi', 'Thay đổi ngày Hợp đồng thất baị');
        setContractDate(newInfoDetail?.contractDate);
        setDataAll((prev) => {
          prev.contractDate = convertDateOrigin;
          return prev;
        });
      }
    } else {
      setContractDate(dateContract);
      setDataAll((prev) => {
        prev.contractDate = convertDateCurrent;
        return prev;
      });
    }
  }

  function handleAddNewCusItem() {
    setShowAddCusItem(true);
  }
  function onCloseAddNewCusItem() {
    setShowAddCusItem(false);
  }

  function handleCloseForm() {
    setPartnerId('');
    setShowAddCusItem(false);
    setContractNumber('');
    setDataAll([]);
    setContractDate(null);
    setExpiredDate(null);
    setCustomerList([]);
    onClose();
  }

  return (
    <Modal
      title={
        <span>
          Cập nhật hợp đồng: <b className='blue'>{contractNumber}</b>
        </span>
      }
      open={isShow}
      onCancel={handleCloseForm}
      footer={null}
      style={{
        top: 20,
      }}
      width='60%'
    >
      <Form
        {...layout}
        form={form}
        name='global_state'
        className='form-input-data'
        onFinish={onSubmitForm}
        fields={{}}
        onFieldsChange={(_, allFields) => {
          onChange(allFields);
        }}
        layout='vertical'
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  <b className='red'>*</b> Nhà xe ngoài
                </span>
              }
            >
              <Input value={partnerName} readOnly />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Số hợp đồng'>
              <Input value={contractNumber} readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  <b className='red'>*</b> Ngày hợp đồng
                </span>
              }
            >
              <DatePicker
                placeholder='Ngày hợp đồng'
                style={{ width: '100%' }}
                format={DATE_FORMAT}
                value={contractDate}
                onChange={(date) => handleChangeContractDate(date, infoDetail)}
                allowClear={false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Ngày hết hạn'>
              <DatePicker
                placeholder='Ngày hết hạn'
                style={{ width: '100%' }}
                format={DATE_FORMAT}
                value={expiredDate}
                onChange={(date) => handleChangeExpireDate(date, infoDetail)}
              />
            </Form.Item>
          </Col>
        </Row>

        {/*   Add New Customer Item    */}
        <div>
          <Button type='primary' onClick={handleAddNewCusItem} disabled={!customerList?.length}>
            Thêm mới
          </Button>
          {!customerList?.length && <i className='red'> Không còn khách hàng để thêm mới</i>}
        </div>
      </Form>
      {/*   Form update  */}
      <ShowContractCreated
        dataDetail={detailList}
        detailInfo={infoDetail}
        contTypeList={contTypeList}
        handleDeleteContractCus={(code, id) =>
          handleDeleteContractCus(code, id, setDetailList?.length)
        }
        getDetailByCode={getDetailByCode}
      />
      {/*   Form Add new  */}
      <CusItemAddForm
        onClose={onCloseAddNewCusItem}
        customerList={customerList}
        dataAll={dataAll}
        contTypeList={contTypeList}
        partnerId={partnerId}
        isUpdateNew={isUpdateNew}
        setIsUpdateNew={setIsUpdateNew}
        contractNumber={contractNumber}
        isShow={showAddCusItem}
        handleCloseForm={onCloseAddNewCusItem}
        detailInfo={infoDetail}
        dataDetail={detailList}
        getDetailByCode={getDetailByCode}
        getCustomer={getCustomer}
      />
      <LoadingView loading={loading || loadingForm} />
    </Modal>
  );
};

export { UpdateForm };
