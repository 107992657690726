export const LIST_COLUMNS_TITLE_FIRST_SUMMARY_SHEET = [
  '',
  'STT',
  'Loại Hình',
  'Số Book / Bill',
  'Ngày Nhận',
  'Số Cont',
  'Loại Cont',
  '',
  '',
  '',
  'Ngày Giao Cont',
  'Ngày Hạ Cont',
  'Nơi Lấy Cont',
  'Nơi Đóng Hàng',
  'Nơi Hạ Cont',
  'Số Xe Hạ Cont',
  'Số Mooc',
  'Số Ngày Neo',
  'Phí Neo',
  'Phí Vận Chuyển',
  'VAT',
  'Thanh lý TK',
  'D/O',
  '',
  'Lưu Bãi',
  '',
  'Chuyển Cont Rớt Tàu',
  '',
  'Vệ Sinh',
  '',
  'Lưu Cont',
  '',
  'Chuyển Tải',
  '',
  'Phụ Thu',
  '',
  'Mua Seal',
  '',
  'Nâng',
  '',
  'Hạ',
  '',
  'Ghi Chú',
  'Tổng',
];

export const LIST_COLUMNS_TITLE_SECOND_SUMMARY_SHEET = [
  '',
  '',
  '',
  '',
  '',
  '',
  `20"`,
  `40C`,
  `40T`,
  `45"`,
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  'Số Tiền', // D/O
  'Số Hóa Đơn', // D/O
  'Số Tiền', // Lưu Bãi
  'Số Hóa Đơn', // Lưu Bãi
  'Số Tiền', // Chuyển Cont Rớt Tàu
  'Số Hóa Đơn', // Chuyển Cont Rớt Tàu
  'Số Tiền', // Vệ Sinh
  'Số Hóa Đơn', // Vệ Sinh
  'Số Tiền', // Lưu Cont
  'Số Hóa Đơn', // Lưu Cont
  'Số Tiền', // Chuyển Tải
  'Số Hóa Đơn', // Chuyển Tải
  'Số Tiền', // Phụ Thu
  'Số Hóa Đơn', // Phụ Thu
  'Số Tiền', // Mua Seal
  'Số Hóa Đơn', // Mua Seal
  'Số Tiền', // Nâng
  'Số Hóa Đơn', // Nâng
  'Số Tiền', // Hạ
  'Số Hóa Đơn', // Hạ
];

export const MERGE_TWO_ROWS_TITLE_SUMMARY_SHEET = [
  'B',
  'C',
  'D',
  'E',
  'F',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'AQ',
  'AR',
];

export const MERGE_TWO_COLUMNS_TITLE_SUMMARY_SHEET = [
  {
    start: 'G',
    end: 'J',
  },
  {
    start: 'W',
    end: 'X',
  },
  {
    start: 'Y',
    end: 'Z',
  },
  {
    start: 'AA',
    end: 'AB',
  },
  {
    start: 'AC',
    end: 'AD',
  },
  {
    start: 'AE',
    end: 'AF',
  },
  {
    start: 'AG',
    end: 'AH',
  },
  {
    start: 'AI',
    end: 'AJ',
  },
  {
    start: 'AK',
    end: 'AL',
  },
  {
    start: 'AM',
    end: 'AN',
  },
  {
    start: 'AO',
    end: 'AP',
  },
];

export const BORDER_CELL_TITLE_SUMMARY_SHEET = [
  'G',
  'H',
  'I',
  'J',
  'W',
  'X',
  'Y',
  'Z',
  'AA',
  'AB',
  'AC',
  'AD',
  'AE',
  'AF',
  'AG',
  'AH',
  'AI',
  'AJ',
  'AK',
  'AL',
  'AM',
  'AN',
  'AO',
  'AP',
];

export const COLUMNS_BORDER_LIST_SUMMARY_SHEET = [
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'AA',
  'AB',
  'AC',
  'AD',
  'AE',
  'AF',
  'AG',
  'AH',
  'AI',
  'AJ',
  'AK',
  'AL',
  'AM',
  'AN',
  'AO',
  'AP',
  'AQ',
  'AR',
];

export const LIST_COLUMN_SUM_SUMMARY_SHEET = [
  'G',
  'H',
  'I',
  'J',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'Y',
  'AA',
  'AC',
  'AE',
  'AG',
  'AI',
  'AK',
  'AM',
  'AO',
  'AR',
];
