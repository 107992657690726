import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
const ExcelJS = require('exceljs');
import { FileExcelOutlined } from '@ant-design/icons';
import { Input, Button, Popconfirm, message, Space, DatePicker } from 'antd';
import { TableLayout, RangeDateFilter } from '~components';
import { CarReplacementsHistoryForm } from './car-replacements-history-form';
import DriverFields from './fields-data';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { TableTitle } from '~commons/styles';
import { DATE_FORMAT, DATE_FORMAT_SEND_API } from '~commons/constants';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().startOf('month').format('YYYY-MM-DD'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD'),
};

const CarReplacementsHistory = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [carReplacementsHistory, setCarReplacementsHistory] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);

  const [replaceDate, setReplaceDate] = useState(null);
  const [expectReplaceDate, setExpectReplaceDate] = useState(null);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getCarReplacements(newQuery)
      .then((res) => {
        if (res?.data) {
          setCarReplacementsHistory(res?.data);
        }
      })
      .catch((err) => {
        console.error('Get Car Replacements History Error: ', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const setFieldsForm = async (value) => {
    const driverFields = await DriverFields(value);
    await setFields(driverFields);
    return driverFields;
  };
  const reload = async () => {
    setSelected(null);
    await getData(filter);
  };

  const getUpdatedOilPriceForm = async (value, isNew = false, dataSelect, id = null) => {
    const driverForm = await (
      <CarReplacementsHistoryForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        dataSelect={dataSelect}
        reload={reload}
        onChange={setFields}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: driverForm,
      },
    ]);
  };

  const onRowSelected = async (value) => {
    const _value = {
      ...value,
      inputDate: Utils.convertToDate(value?.inputDate),
    };

    await setIsLoadingTab(true);
    await setTitleTab('Xem Chi Tiết');
    await setFieldsForm(_value)
      .then((res) => {
        return getUpdatedOilPriceForm(res, false, value, _value.id);
      })
      .then(() => setSelected(_value));
    setIsLoadingTab(false);
  };
  const deleteItem = async (record) => {
    setLoading(true);
    return ApiServices.deleteCarReplacement(record?.id)
      .then((res) => {
        if (res?.data?.data) {
          message.success('Xoá Lịch sử bảo dưỡng thành công');
          return reload();
        } else {
          message.error('Xoá Lịch sử bảo dưỡng thất baị, vui lòng kiểm tra lại thông tin');
        }
      })
      .catch((err) => {
        console.log('Delete oil prices failed: ', err);
        message.error('Xoá Lịch sử bảo dưỡng thất baị, vui lòng kiểm tra lại thông tin');
      })
      .finally(() => setLoading(false));
  };

  const handleChangeReplaceDate = (date) => {
    setReplaceDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'rDate');
  };

  const handleChangeExpectReplaceDate = (date) => {
    setExpectReplaceDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'erDate');
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Ngày Thay
          <DatePicker
            value={replaceDate}
            onChange={handleChangeReplaceDate}
            placeholder='Ngày Thay'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'rDate',
      align: 'center',
      width: 150,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Ngày Thay Dự Kiến
          <DatePicker
            value={expectReplaceDate}
            onChange={handleChangeExpectReplaceDate}
            placeholder='Ngày Thay Dự Kiến'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'erDate',
      align: 'center',
      width: 180,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Số Xe
          <Input name='carCode' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'carCode',
      align: 'center',
      width: 130,
    },
    {
      title: (
        <TableTitle>
          Hạng Mục
          <Input name='categoryName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'categoryName',
      align: 'center',
      width: 200,
    },
    {
      title: (
        <TableTitle>
          Số KM
          <Input
            name='oldEKm'
            onChange={(event) => onColumnSearch(+event?.target?.value, 'oldEKm')}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'oldEKm',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Ghi Chú
          <Input name='note' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'note',
      align: 'center',
      width: 150,
    },
    {
      title: '#',
      fixed: 'right',
      align: 'center',
      width: 90,
      render: (_, record) => (
        <a href='#' onClick={() => onRowSelected(record)}>
          Xem chi tiết
        </a>
      ),
    },
    {
      title: '#',
      fixed: 'right',
      align: 'center',
      width: 90,
      render: (record) => (
        <Popconfirm
          onConfirm={() => deleteItem(record)}
          title='Bạn có chắc chắn xoá?'
          okText='Có'
          cancelText='Không'
        >
          <a href='#' style={{ color: 'red' }}>
            Xoá
          </a>
        </Popconfirm>
      ),
    },
  ];

  const createDriver = async () => {
    await setTitleTab('Thêm mới');
    await setFieldsForm({})
      .then((res) => getUpdatedOilPriceForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };

  const handleExportExcel = async (dataExport) => {
    if (loading) return;

    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('LS Bảo Dưỡng Xe', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 30;
    sheetSummary.properties.defaultColWidth = 15;
    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getColumn(11).width = 25;
    sheetSummary.getColumn(13).width = 30;
    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getCell('E2').value = 'LỊCH SỬ BẢO DƯỠNG XE';
    sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(3).font = {
      name: 'Arial',
      family: 2,
      size: 10,
      italic: true,
    };
    sheetSummary.getCell('E3').value = `Từ Ngày ${moment(filter?.DateFrom).format(
      'DD/MM/YYYY'
    )} -> ${moment(filter?.DateTo).format('DD/MM/YYYY')}`;

    // Add table
    const arrDetail = await dataExport?.map((item, indexDetail) => {
      const dataItem = [
        indexDetail + 1, // STT
        item?.rDate && moment(item?.rDate).format('DD/MM/YYYY'), // Ngay thay
        item?.erDate && moment(item?.erDate).format('DD/MM/YYYY'), // Ngay Thay du Kien
        item?.carCode, //So Xe
        item?.categoryName, // Hang Muc
        item?.oldEKm, // So KM
        item?.note, // Ghi Chu
      ];
      return dataItem;
    });

    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B5',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Ngày Thay', totalsRowLabel: '', filterButton: false },
        { name: 'Ngày Thay Dự Kiến', totalsRowLabel: '', filterButton: false },
        {
          name: 'Số Xe',
          filterButton: false,
        },
        {
          name: 'Hạng Mục',
          filterButton: false,
        },
        {
          name: 'Số KM',
          filterButton: false,
        },
        {
          name: 'Ghi Chú',
          filterButton: false,
        },
      ],
      rows: arrDetail,
    });

    // ========== STYLES =====================
    sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getColumn(2).width = 5;
    sheetSummary.getColumn(6).alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    sheetSummary.getColumn(4).width = 20;
    sheetSummary.getColumn(6).width = 20;
    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Lich_Su_Bao_Duong_Xe_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const handleGetDataToExportExcel = async () => {
    setLoading(true);
    const newQuery = {
      export: true,
      DateFrom: filter?.DateFrom && moment(filter?.DateFrom).format(DATE_FORMAT_SEND_API),
      DateTo: filter?.DateTo && moment(filter?.DateTo).format(DATE_FORMAT_SEND_API),
    };
    return ApiServices.getCarReplacements(newQuery)
      .then((res) => {
        if (res?.data?.data) {
          const dataSort = res?.data?.data?.sort((a, b) => {
            let da = new Date(a.rDate);
            let db = new Date(b.rDate);
            return da - db;
          });
          const dataMap = dataSort?.map((item, index) => {
            item.key = index + 1;
            return item;
          });
          handleExportExcel(dataMap);
        }
      })
      .catch((err) => {
        console.error('Get Car Replacements History Error: ', err);
      })
      .finally(() => setLoading(false));
  };

  const headerContent = (
    <Space>
      <RangeDateFilter query={filter} onChange={setFilter} />
      <Button type='primary' onClick={createDriver}>
        Thêm Mới
      </Button>
      <Button
        type='primary'
        loading={loading}
        icon={<FileExcelOutlined />}
        style={{ padding: '0px 20px', marginLeft: '10px' }}
        disabled={!carReplacementsHistory?.data?.length}
        onClick={handleGetDataToExportExcel}
      >
        Xuất Excel
      </Button>
    </Space>
  );

  const pagination = {
    total: carReplacementsHistory?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={carReplacementsHistory?.data || []}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      pagination={pagination}
      width={500}
      isDisplayCheckbox={false}
    />
  );
};

export { CarReplacementsHistory };
