import React, { useRef, useEffect, useState } from 'react';
import { Form, Input, Button, message, Select, Row, Col } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';
import { Selection } from '~components';

const { Option } = Select;

const CustomerForm = ({ fields, isNew, id, reload, onChange, setLoading, valueRowInit }) => {
  const inputRef = useRef(null);
  const [companies, setCompanies] = useState([]);
  const [getCustomerType, setGetCustomerType] = useState([]);
  // const [locations, setLocations] = useState([]);
  const [vendors, setVendors] = useState([]);

  const getVendor = async (Query = null) => {
    try {
      const { data } = await ApiServices.getVendors({ Query });
      setVendors(data?.data || []);
    } catch (err) {
      console.error('ERR - Get Shipping Lines', err);
    }
  };

  const getDataInit = async () => {
    const [getStaticVariable] = await Promise.all([ApiServices.getStaticVariables()]);

    setGetCustomerType(getStaticVariable.data.CustomerType);
  };

  const getCompanies = async (query = '') => {
    const newQuery = {
      Columns: {
        name: query,
      },
    };
    try {
      const res = await ApiServices.getCompanies(query ? newQuery : null);
      if (res?.data?.data) {
        setCompanies(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const excuteGetData = async () => {
      await getDataInit();
      await getVendor();
    };
    excuteGetData();
    getCompanies();
    // getLocations();
  }, []);

  const onCompareWithApi = (value, cb) => {
    if (inputRef.current) {
      clearTimeout(inputRef.current);
      cb();
    }
    inputRef.current = setTimeout(async () => {
      try {
        const { data } = await ApiServices.existsCodeCustomer(null, value);
        const isUsed = data;
        cb(isUsed);
      } catch (error) {
        console.error(error);
      }
    }, 1000);
  };

  const checkExistsCode = async (_, value) => {
    if (value.length > 1) {
      const ind = fields.findIndex((item) => {
        return item.name[0] === 'code';
      });
      if (isNew === false && value !== fields[ind].value) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Mã Khách Hàng này đã tạo rồi !!!'));
        }
      }

      if (isNew === true) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Mã Khách Hàng này đã tạo rồi !!!'));
        }
      }
    }
  };

  const createdCustomer = async (data) => {
    try {
      const res = await ApiServices.createCustomer(data);
      if (res.data) {
        message.success('Thêm mới Khách Hàng thành công');
        return reload();
      } else {
        message.error('Thêm mới Khách Hàng thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới Khách Hàng thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const updateCustomer = async (data) => {
    data.id = id;
    try {
      const res = await ApiServices.updateCustomer(id, data);
      if (res.data) {
        message.success('Cập nhật Khách Hàng thành công');
        return reload();
      } else {
        message.error('Cập nhật Khách Hàng thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật Khách Hàng thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createdCustomer(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updateCustomer(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      name='global_state'
      className='form-input-data'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            // {...itemLayout}
            style={{ marginTop: '10px' }}
            name='code'
            label='Mã Khách Hàng'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Mã Khách Hàng',
              },
              {
                validator: checkExistsCode,
              },
              {
                type: 'string',
                min: 2,
                message: 'Bạn phải nhập ít nhất 2 ký tự',
              },
            ]}
          >
            <Input placeholder='Mã Khách Hàng' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            style={{ marginTop: '10px' }}
            name='name'
            label='Tên Khách hàng'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập tên KH',
              },
            ]}
          >
            <Input placeholder='Tên Khách Hàng' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='shortName' label='Tên viết tắt'>
            <Input placeholder='Tên viết tắt' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='companyId'
            label='Công Ty'
            rules={[
              {
                required: true,
                message: 'Bạn phải chọn Công Ty',
              },
            ]}
          >
            <Selection
              labelDefault={valueRowInit?.companyName || ''}
              data={companies}
              placeholder='Bạn phải chọn Công Ty'
              fieldValue='name'
              onSearch={getCompanies}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='address'
            label='Địa Chỉ'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Địa chỉ KH',
              },
            ]}
          >
            <Input.TextArea placeholder='Địa Chỉ' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='ownerId'
            label='Nhà Xe'
            rules={[
              {
                required: true,
                message: 'Bạn phải chọn Nhà Xe',
              },
            ]}
          >
            <Select showSearch placeholder='Lựa Chọn Nhà Xe'>
              {vendors.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Form.Item name='phone' label='Số Điện thoại'>
            <Input placeholder='Số Điện thoại Doanh nghiệp' />
          </Form.Item> */}
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='customerType'
            label='Nhóm KH'
            rules={[
              {
                required: true,
                message: 'bạn phải nhập Nhóm KH',
              },
            ]}
          >
            <Select showSearch placeholder='Nhóm Khách Hàng'>
              {Object.keys(getCustomerType).map((item, index) => (
                <Option key={index} value={getCustomerType[item]}>
                  {getCustomerType[item]}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='businessLicenseNumber' label='Số ĐKKD'>
            <Input placeholder='Số ĐKKD' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='contactPerson' label='Nguời đại diện'>
            <Input placeholder='Nguời đại diện' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='position' label='Chức vụ'>
            <Input placeholder='Chức vụ' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='tax' label='Tax'>
            <Input placeholder='Tax' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='fax' label='Fax'>
            <Input placeholder='Fax' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='phone' label='Số Điện thoại'>
            <Input placeholder='Số Điện thoại Doanh nghiệp' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='bankNumber' label='Số Tài khoản NH'>
            <Input placeholder='Số Tài khoản NH' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='bankName' label='Tên NH'>
            <Input placeholder='Tên NH' />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='arrLocations' label='Nơi Giao Hàng'>
            <Selection
              data={locations}
              labelDefaultArray={valueRowInit?.locations}
              placeholder='Nơi Đóng Hàng'
              mode='multiple'
              onSearch={getLocations}
            />
          </Form.Item>
        </Col>
        <Col span={12}></Col>
      </Row> */}

      <Form.Item {...tailLayout} className='submit-form-input-data'>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { CustomerForm };
