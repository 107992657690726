import React from 'react';
import { Table, Typography } from 'antd';
const { Column, ColumnGroup } = Table;
import Utils from '~commons/utils';

const { Text } = Typography;

export const TableDetail = ({ dataTable = [], loading }) => {
  const dataFormat = dataTable?.map((item, index) => {
    item.key = index + 1;
    item.orderTypeDisplay = Utils.displayOrderTypeOrder(item?.orderType);
    item.contType20 = item?.contType === `20"` ? 1 : 0;
    item.contType40C = item?.contType === '40C' ? 1 : 0;
    item.contType40T = item?.contType === '40T' ? 1 : 0;
    item.contType45 = item?.contType === `45"` ? 1 : 0;
    item.NGCDate = item?.deliveryAcctualDate && Utils.formatDate(item?.deliveryAcctualDate);
    item.NHCDate = item?.completeDate && Utils.formatDate(item?.completeDate);
    item.nangDisplay = item?.nang ? Utils.formatNumber(item?.nang) : 0;
    item.haDisplay = item?.ha ? Utils.formatNumber(item?.ha) : 0;

    return item;
  });
  const handleSummary = (pageData) => {
    let sumCont20 = 0;
    let sumCont40C = 0;
    let sumCont40T = 0;
    let sumCont45 = 0;
    let sumTotalNang = 0;
    let sumTotalHa = 0;

    pageData.forEach((page) => {
      sumCont20 += page?.contType20;
      sumCont40C += page?.contType40C;
      sumCont40T += page?.contType40T;
      sumCont45 += page?.contType45;
      sumTotalNang += page?.nang;
      sumTotalHa += page?.ha;
    });
    return (
      <>
        <Table.Summary.Row
          style={{
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '13px',
          }}
        >
          <Table.Summary.Cell index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            <Text type='danger' strong></Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            <Text type='danger' strong>
              {sumCont20}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            <Text type='danger' strong>
              {sumCont40C}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}>
            <Text type='danger' strong>
              {sumCont40T}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7}>
            <Text type='danger' strong>
              {sumCont45}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8}>
            <Text type='danger' strong></Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={9}>
            <Text type='danger' strong></Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            <Text type='danger' strong></Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            <Text type='danger' strong>
              {sumTotalNang && Utils.formatNumber(sumTotalNang)}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}>
            <Text type='danger' strong></Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={13}>
            <Text type='danger' strong>
              {sumTotalHa && Utils.formatNumber(sumTotalHa)}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={14}>
            <Text type='danger' strong></Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };
  return (
    <Table dataSource={dataFormat} bordered size='small' summary={handleSummary} loading={loading}>
      <Column title='STT' dataIndex='key' key='key' align='center' />
      <Column
        title='Loại Hình'
        dataIndex='orderTypeDisplay'
        key='orderTypeDisplay'
        align='center'
      />
      <Column title='Số Cont' dataIndex='contNumber' key='contNumber' align='center' />
      <ColumnGroup title='Loại Cont'>
        <Column title={`20"`} dataIndex='contType20' key='contType20' align='center' />
        <Column title='40C' dataIndex='contType40C' key='contType40C' align='center' />
        <Column title='40T' dataIndex='contType40T' key='contType40T' align='center' />
        <Column title={`45"`} dataIndex='contType45' key='contType45' align='center' />
      </ColumnGroup>
      <Column title='Ngày Giao Cont' dataIndex='NGCDate' key='NGCDate' align='center' />
      <Column title='Ngày Hạ Cont' dataIndex='NHCDate' key='NHCDate' align='center' />
      <Column title='Nơi Đóng Hàng' dataIndex='tmpCont' key='tmpCont' align='center' />
      <ColumnGroup title='Nâng'>
        <Column title='Số Tiền' dataIndex='nangDisplay' key='nangDisplay' align='center' />
        <Column title='Số HĐ' dataIndex='billNumberNang' key='billNumberNang' align='center' />
      </ColumnGroup>
      <ColumnGroup title='Hạ'>
        <Column title='Số Tiền' dataIndex='haDisplay' key='haDisplay' align='center' />
        <Column title='Số HĐ' dataIndex='billNumberHa' key='billNumberHa' align='center' />
      </ColumnGroup>
    </Table>
  );
};
