import actionTypes from './type';

import moment from 'moment';

const currentMonth = moment().month();
const currentYear = moment().year();

const DEFAULT_QUERY_WB = {
  PageNumber: 1,
  PageSize: 50,
  Columns: {},
};

const initialState = {
  data: null,
  query: {
    ...DEFAULT_QUERY_WB,
    Columns: {
      orderType: 'Booking',
    },
    // DateFrom: moment().startOf('months').format(),
    DateFrom: moment(`${currentYear}-${currentMonth}-01`, 'YYYYMMDD').format(),
    DateTo: moment().endOf('month').format(),
  },
  loading: false,
  selected: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_DATA:
      return {
        ...state,
        data: action.data,
      };
    case actionTypes.LOAD_MORE_DATA: {
      let moreData = state.data.data.map((el) => el);

      for (var el of action.data.data) {
        moreData.push(el);
      }
      let newData = {
        ...action.data,
        data: moreData,
      };
      return {
        ...state,
        data: newData,
      };
    }
    case actionTypes.UPDATE_QUERY: {
      let Columns = action.query.Columns;

      for (let key in Columns) {
        if (!Columns[key] && Columns[key] !== 0) {
          delete Columns[key];
        }
      }
      return {
        ...state,
        query: {
          ...action.query,
          Columns,
        },
      };
    }
    case actionTypes.UPDATE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actionTypes.UPDATE_SELECTED:
      return {
        ...state,
        selected: action.selected,
      };
    default:
      return state;
  }
}
