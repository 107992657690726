import { useEffect, useState } from 'react';
import { TableLayout } from '~components';
import ApiServices from '~services';
import { FormInput } from './form-input';
import styled from 'styled-components';
import { Button, message, Popconfirm } from 'antd';
import { GroupUser } from './group-user';
import { GroupFunction } from './group-function';

export const Group = () => {
  const [loading, setLoading] = useState(false);
  const [loadingTab, setLoadingTab] = useState(false);
  const [dataRow, setDataRow] = useState([]);
  const [menus, setMenus] = useState([]);
  const [selected, setSelected] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const getMenus = () => {
    ApiServices.getMenus()
      .then((res) => {
        setMenus(res.data || []);
      })
      .catch((err) => console.error('get Menus Error', err));
  };

  const getData = () => {
    setLoading(true);
    return ApiServices.getGroups()
      .then((res) => {
        const menus = [];
        const keys = [];
        if (res?.data) {
          for (let el of res.data) {
            menus.push({
              ...el,
              children: el.childs,
            });
            // el.childs?.length && keys.push(el.id);
          }
        }

        setDataRow(menus);
        setExpandedRowKeys(keys);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setDataRow([]);
      });
  };
  const onClickShowDetail = (e, record) => {
    e.preventDefault();
    setSelected(record);
  };
  const onClickDelete = (record) => {
    return ApiServices.deleteGroup(record.id)
      .then(() => {
        getData();
        message.success('Thao tác thành công!!!');
      })
      .catch((err) => {
        console.error('update Menu', err);
        message.error('Thao tác thất bại');
      });
  };
  const columns = [
    {
      title: 'Mã Nhóm',
      dataIndex: 'code',
      render: (val, record) => (
        <a
          href='#'
          onClick={(e) => onClickShowDetail(e, record)}
          className={record.childs?.length && 'bold'}
        >
          {val}
        </a>
      ),
    },
    {
      title: 'Tên',
      dataIndex: 'name',
    },
    {
      title: 'Mô tả',
      dataIndex: 'desc',
    },
    {
      title: '#',
      dataIndex: 'action',
      render: (_, record) => (
        <Popconfirm
          className='red pointer'
          title='Xác nhận xóa!'
          onConfirm={() => onClickDelete(record)}
        >
          Xóa
        </Popconfirm>
      ),
    },
  ];
  const onCloseEditTab = () => setSelected();
  const getDetailGroup = (id) => {
    setLoadingTab(true);
    return ApiServices.getGroup(id || selected?.id)
      .then((res) => {
        if (res.data) {
          setSelected({ ...res.data, ...res.data.info });
        }
      })
      .catch((err) => console.error('get Detail Group Error', err))
      .finally(() => setLoadingTab(false));
  };

  useEffect(() => {
    getData();
    getMenus();
  }, []);

  useEffect(() => {
    if (!selected?.id) {
      return;
    }
    getDetailGroup(selected.id);
  }, [selected?.id]);

  const expandable = { expandedRowKeys, onExpandedRowsChange: setExpandedRowKeys };
  const rowSelection = {
    checkStrictly: true,
  };
  const createNew = () => setSelected({});
  const headerContent = (
    <Button type='primary' onClick={createNew}>
      Thêm Mới
    </Button>
  );

  return (
    <>
      <TableStyled
        rowKey='id'
        columns={columns}
        data={dataRow}
        headerContent={headerContent}
        loading={loading}
        titleTab={selected?.name || 'Thêm Mới'}
        expandable={expandable}
        rowSelection={rowSelection}
        width={800}
        tabPanels={[
          {
            title: 'Cập nhật thông tin',
            content: <FormInput dataInput={selected} reload={getData} onClose={onCloseEditTab} />,
          },
          {
            title: 'Quản lý Người Dùng',
            content: (
              <GroupUser dataInput={selected} reload={getDetailGroup} onLoading={setLoadingTab} />
            ),
          },
          {
            title: 'Quản lý Chức năng',
            content: (
              <GroupFunction
                dataInput={selected}
                reload={getDetailGroup}
                onLoading={setLoadingTab}
                menus={menus}
              />
            ),
          },
        ]}
        isLoadingTab={loadingTab}
        closeDrawer={onCloseEditTab}
        selected={selected}
      />
    </>
  );
};

const TableStyled = styled(TableLayout)`
  & .ant-table-row-indent.indent-level-1 {
    display: none;
  }
  & button.ant-table-row-expand-icon-spaced {
    display: none;
  }
`;
