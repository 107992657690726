import React, { useEffect, useState, useRef } from 'react';
import { TableLayout, Selection } from '~components';
import { Button, message, Radio } from 'antd';
import { getColumns } from './columns';
import styled from 'styled-components';
import ApiServices from '~services';
import { AddLocation } from './add-location';
const HEADER_LENGTH = '305px';

export const PartnerCustomerManagement = ({ selectedCompany }) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState('');
  const [vendors, setVendors] = useState([]);
  const [venderCustomerList, setVenderCustomerList] = useState([]);
  const [venderCustomer, setVenderCustomer] = useState([]);
  const [venderCustomerOnly, setVenderCustomerOnly] = useState('');
  const [selectCustomerItem, setSelectCustomerItem] = useState([]);
  const [isAssignFull, setIsAssignFull] = useState(true);
  const [locationOfCustomerList, setLocationOfCustomerList] = useState([]);
  const [locationDelivery, setLocationDelivery] = useState('');
  //-------------------------------------------------------------------
  const onChange = (e) => {
    setIsAssignFull(e.target.value);
  };

  const typingTimeoutref = useRef(null);
  const [searchByColumn, setSearchByColumn] = useState({
    code: '',
    name: '',
    shortName: '',
    phone: '',
  });

  const handleSearchColumnFn = (event, name) => {
    const filterTmp = filter;
    const val = event.target.value;
    let _searchByColumn = { ...searchByColumn };
    _searchByColumn[`${name}`] = val;
    setSearchByColumn(_searchByColumn);
    // Clear timeout
    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }
    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 0) {
        filter.PageNumber = 1;
        let searchColumnFn = { ...filter.Columns };
        searchColumnFn[`${name}`] = val;
        filter.Columns = searchColumnFn;

        setFilter(filterTmp);
        getVendorCustomerDetails();
      }
      if (val.length === 0) {
        delete filter.Columns[`${name}`];
        setFilter(filterTmp);
        getVendorCustomerDetails();
      }
    }, 1000);
  };
  // ------------- End Search --------------------
  const getVendorCustomerDetails = async () => {
    let dataFilterSend = { ...filter };
    if (Object.keys(dataFilterSend.Columns).length === 0) {
      delete dataFilterSend['Columns'];
    } else {
      const columnJSONSTRING = JSON.stringify(dataFilterSend.Columns);
      dataFilterSend.Columns = columnJSONSTRING;
    }
    try {
      setLoading(true);
      let { data } = await ApiServices.getPartnerCustomerDetails(
        selectedCompany.id,
        dataFilterSend
      );
      let pag = pagination;
      pag.total = data?.totalRecords || 0;
      data = data?.data || [];
      setVendors(data);
      setPagination(pag);
    } catch (error) {
      console.log('Error - get vendor details', error);
    } finally {
      setLoading(false);
    }
  };

  const getCustomerListToAssignVendor = async (Query = null) => {
    try {
      setLoading(true);
      let { data } = await ApiServices.GetCustomerToAssignPartner({
        Query,
      });
      setVenderCustomerList(data);
    } catch (error) {
      console.log('Error - customer Vendor details', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVendorCustomerDetails();
    getCustomerListToAssignVendor();
    setVenderCustomer([]);
    setSelectCustomerItem([]);
  }, [selectedCompany?.id]);

  const onHandleChangeCustomer = (value) => {
    setVenderCustomer(value);
  };
  const onHandleChangeCustomerOnly = async (customerId) => {
    if (!customerId) return;

    setLoading(true);
    await ApiServices.getCustomerDetails(customerId)
      .then((res) => {
        if (res?.data?.locations) {
          setLocationOfCustomerList(res?.data?.locations);
        }
      })
      .catch((err) => console.log('Get Customer Details Error: ', err))
      .finally(() => {
        setVenderCustomerOnly(customerId);
        setLoading(false);
      });
  };
  const handleChangeLocationDelivery = (value) => {
    setLocationDelivery(value);
  };
  const onHandleChangeCustomerVendor = async (dataSend) => {
    try {
      setLoading(true);
      const res = await ApiServices.changeCustomerPartner(selectedCompany?.id, dataSend);
      if (res.status === 200) {
        setVenderCustomer([]);
        setSelectCustomerItem([]);
        setVenderCustomerOnly('');
        setLocationDelivery('');
        getVendorCustomerDetails();
        getCustomerListToAssignVendor();
        message.success('Thao tác thành công');
      }
    } catch (error) {
      console.log('Error - add customer to vendor', error);
      message.error('Thao tác thất bại');
    } finally {
      setLoading(false);
    }
  };

  const addCustomerToVendor = async () => {
    const data = {
      customerIds: isAssignFull ? venderCustomer : [venderCustomerOnly],
      add: true,
      locationId: locationDelivery ? locationDelivery : '',
    };

    return onHandleChangeCustomerVendor(data);
  };

  const onChangePagination = (value) => {
    let filterTemp = filter;
    filterTemp.PageNumber = value;
    setFilter(filterTemp);
    getVendorCustomerDetails();
  };
  const [pagination, setPagination] = useState({
    total: 1,
    showSizeChanger: false,
    onChange: onChangePagination,
  });
  const [filter, setFilter] = useState({
    PageNumber: 0,
    PageSize: 10,
    Text: '',
    Columns: '',
  });

  const deleteCustomer = async (record) => {
    const data = {
      customerIds: [record.id],
      add: false,
    };

    return onHandleChangeCustomerVendor(data);
  };

  const deleteMultipleCustomer = async () => {
    const data = {
      customerIds: selectCustomerItem,
      add: false,
    };

    return onHandleChangeCustomerVendor(data);
  };

  const handleAddLocationDelivery = (locationId, customerId) => {
    const data = {
      customerIds: [customerId],
      add: true,
      locationId,
    };

    return onHandleChangeCustomerVendor(data);
  };

  const handleDeleteLocation = (locationId, customerId) => {
    const data = {
      customerIds: [customerId],
      add: false,
      locationId,
    };

    return onHandleChangeCustomerVendor(data);
  };

  const handleLocationDelivery = (value, record) => {
    if (!value || value?.length === 0) return 'Tất cả';

    const hasAddLocation = record?.notAssignedLocations?.length > 0;
    const hasDeleteLocation = record?.assignedLocations?.length > 1;

    return (
      <div>
        <ol>
          {value?.map((item) => (
            <li key={item?.id}>
              {item?.name}
              {hasDeleteLocation && (
                <>
                  - <a onClick={() => handleDeleteLocation(item?.id, record?.id)}>Xóa</a>
                </>
              )}
            </li>
          ))}
        </ol>
        {hasAddLocation && (
          <AddLocation
            dataNotAssign={record?.notAssignedLocations}
            handleAddLocationDelivery={(locationId) =>
              handleAddLocationDelivery(locationId, record?.id)
            }
          />
        )}
      </div>
    );
  };

  const renderHeaderIsAssignFull = () => (
    <Selection
      width={500}
      mode='multiple'
      data={venderCustomerList}
      placeholder='Khách hàng'
      fieldValue='shortName'
      value={venderCustomer}
      onChange={onHandleChangeCustomer}
      onSearch={getCustomerListToAssignVendor}
    />
  );
  const renderHeaderIsAssignOnly = () => (
    <div style={{ display: 'flex' }}>
      <div>
        <b>Khách hàng</b>

        <Selection
          width={400}
          data={venderCustomerList}
          placeholder='Khách hàng'
          fieldValue='shortName'
          value={venderCustomerOnly}
          onChange={onHandleChangeCustomerOnly}
          onSearch={getCustomerListToAssignVendor}
        />
      </div>
      <div>
        <b>Nơi đóng hàng</b>
        <Selection
          width={350}
          data={locationOfCustomerList}
          placeholder='Nơi đóng hàng'
          fieldValue='name'
          value={locationDelivery}
          onChange={handleChangeLocationDelivery}
        />
      </div>
    </div>
  );

  const headerContent = (
    <>
      <div>
        {isAssignFull ? renderHeaderIsAssignFull() : renderHeaderIsAssignOnly()}
        <div
          style={{
            margin: '10px',
          }}
        >
          <Button
            type='primary'
            onClick={addCustomerToVendor}
            style={{ marginLeft: '10px' }}
            disabled={isAssignFull ? venderCustomer.length === 0 : venderCustomerOnly?.length === 0}
          >
            Thêm Mới
          </Button>
          <Button
            type='danger'
            onClick={deleteMultipleCustomer}
            style={{ marginLeft: '15px' }}
            disabled={selectCustomerItem.length === 0}
          >
            Xóa
          </Button>
        </div>
      </div>
      {/* {!venderCustomer.length && (
        <CustomerAvailable>Còn {venderCustomerList?.length} khách hàng có sẵn</CustomerAvailable>
      )} */}
      {venderCustomer.length > 0 && (
        <CustomerAvailable>Đã chọn {venderCustomer?.length} khách hàng</CustomerAvailable>
      )}
    </>
  );

  const onSelectChange = (selectedRowKey) => {
    setSelectCustomerItem(selectedRowKey);
  };

  return (
    <>
      <Radio.Group
        onChange={onChange}
        value={isAssignFull}
        style={{
          marginBottom: '10px',
          marginLeft: '10px',
        }}
      >
        <Radio value={true}>Tất cả</Radio>
        <Radio value={false}>Theo địa điểm đóng hàng</Radio>
      </Radio.Group>

      <TableLayout
        rowKey='id'
        width={900}
        headerLength={HEADER_LENGTH}
        columns={getColumns(
          searchByColumn,
          handleSearchColumnFn,
          deleteCustomer,
          handleLocationDelivery
        )}
        data={vendors}
        loading={loading}
        selected={selected}
        closeDrawer={() => setSelected(null)}
        headerContent={headerContent}
        pagination={pagination}
        selectParent={selectCustomerItem}
        onSelectChange={onSelectChange}
      />
    </>
  );
};

const CustomerAvailable = styled.div`
  color: blue;
  margin-top: 10px;
`;
