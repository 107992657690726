import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, message, DatePicker, Row, Col, Divider, Image, Space } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import moment from 'moment';
import { debounce } from 'lodash';
import ApiServices from '~services';
import { DATE_FORMAT } from '~commons/constants';
import { Selection } from '~components';
import ReactToPrint from 'react-to-print';
import { TableContent } from './table-content';
import { TableContentView } from './table-content-view';
import Utils from '~commons/utils';

const DEFAULT_ASSIGN_DATE = moment();

const AssignCar = ({ idCar, carCode = '' }) => {
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [carAndDateAssign, setCarAndDateAssign] = useState([]);
  const [dataExtrasAssign, setDataExtrasAssign] = useState([]);

  const [cars, setCars] = useState([]);
  const [dataCar, setDataCar] = useState({});
  const [companyInfo, setCompanyInfo] = useState({});

  //
  const [carSelectedId, setCarSelectedId] = useState(null);
  const [assignDate, setAssignDate] = useState(null);
  const [assignDateSelected, setAssignDateSelected] = useState(DEFAULT_ASSIGN_DATE);
  const [messageError, setMessageError] = useState('');
  const [messageSuccess, setMessageSuccess] = useState('');

  const getCars = async (query = '') => {
    return ApiServices.getCars({ query })
      .then(({ data }) => {
        setCars(data.data);
      })
      .catch((err) => console.error(err));
  };

  const getCompanyDetails = async () => {
    const companyId = JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId;
    if (!companyId) return;
    setLoading(true);
    try {
      let res = await ApiServices.getCompanyDetails(companyId);
      if (res?.data?.data) {
        setCompanyInfo(res?.data?.data);
      }
    } catch (error) {
      console.log('Error - get CompanyDetails', error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCars();
    getCompanyDetails();
  }, []);

  const onSearchFn = async (text) => {
    const dataSend = JSON.stringify({ code: text });
    try {
      setLoading(true);
      let { data } = await ApiServices.getCars({ Columns: dataSend });

      setCars(data.data);
    } catch (error) {
      console.log('Error - get cars', error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getByCarAndDateAssign = async (idCar, dateAssign) => {
    const convertAssignDate = moment(dateAssign).format('YYYY-MM-DD');

    try {
      setLoading(true);
      let { data } = await ApiServices.getByCarAndDateAssign(idCar, convertAssignDate);
      setLoading(false);
      const messageGet = data?.message?.split('Ngày');
      const messageNotification = `${messageGet[0]}. Ngày ${moment(convertAssignDate).format(
        'LL'
      )}`;

      if (data.data) {
        setMessageSuccess(messageNotification);
        setMessageError('');
        const mapData = [];
        for (let value of Object.values(data?.data?.jobs)) {
          mapData.push(...value);
        }
        let isGroupAssignId = false;
        const dataEnd = mapData.map((value, index) => {
          value.key = index + 1;
          if (index === 0) {
            value.classRowGroupAssignId = isGroupAssignId;
          } else if (value?.assignId === mapData[index - 1]?.assignId) {
            value.classRowGroupAssignId = isGroupAssignId;
          } else {
            value.classRowGroupAssignId = !isGroupAssignId;
            isGroupAssignId = !isGroupAssignId;
          }

          return value;
        });

        if (data?.data?.extras?.length) {
          const dataMapExtras = data?.data?.extras?.map((extra) => {
            return {
              id: extra.id,
              assignOn: extra.assignOn,
              customerName: extra?.customerName || '',
              romoocCode: extra?.romoocCode || '',
              name: extra?.name || '',
              code: extra?.code || '',
              beginLocationName: extra?.beginLocationName || '',
              endLocationName: extra?.endLocationName || '',
              distance: extra?.distance || 0,
              objCont: null,
              note: extra?.note || '',
              assignTransit: false,
              isSupportAssign: true,
            };
          });
          setDataExtrasAssign(dataMapExtras || []);
        } else {
          setDataExtrasAssign([]);
        }

        setCarAndDateAssign(dataEnd);
        setDataCar(data?.data?.car);
        setAssignDate(data?.data?.assignDate);
      } else {
        setCarAndDateAssign([]);
        setDataExtrasAssign([]);
        setDataCar({});
        setAssignDate(null);
        setMessageError(messageNotification);
        setMessageSuccess('');
      }
    } catch (error) {
      console.log(error);
      message.error(error);
    }
  };

  useEffect(() => {
    if (idCar && carCode) {
      setCarSelectedId(idCar);
    }
  }, [idCar]);

  const timeoutGetData = useCallback(
    debounce((newCarSelectedId, newAssignDateSelected) => {
      getByCarAndDateAssign(newCarSelectedId, newAssignDateSelected);
    }, 500),
    []
  );
  useEffect(() => {
    if (carSelectedId && assignDateSelected) {
      timeoutGetData(carSelectedId, assignDateSelected);
    } else {
      setCarAndDateAssign([]);
      setDataExtrasAssign([]);
      setDataCar({});
      setAssignDate(null);
      setMessageSuccess('');
      setMessageError('');
    }
  }, [carSelectedId, assignDateSelected]);

  const reload = () => {
    if (carSelectedId && assignDateSelected) {
      return timeoutGetData(carSelectedId, assignDateSelected);
    }
  };

  const handleChangeCar = (car) => {
    setCarSelectedId(car);
  };

  const handleChangeAssignDate = (date) => {
    setAssignDateSelected(date);
  };

  const headerContent = (
    <>
      <Space>
        <div>
          <b
            style={{
              marginLeft: '10px',
            }}
          >
            Chọn ngày:
          </b>
          {!assignDateSelected && <i className='red'> Vui lòng chọn ngày</i>}
          <DatePicker
            status={!assignDateSelected ? 'error' : ''}
            placeholder='Ngày điều xe'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            value={assignDateSelected}
            onChange={handleChangeAssignDate}
            allowClear={false}
          />
        </div>
        <div>
          <b>Chọn Xe:</b>
          {!carSelectedId && <i className='red'> Vui lòng chọn Xe</i>}
          <br />
          <Selection
            status={!carSelectedId ? 'error' : ''}
            data={cars}
            placeholder='Chọn Xe'
            fieldValue='code'
            width={400}
            value={carSelectedId}
            onChange={handleChangeCar}
            onSearch={onSearchFn}
            labelDefault={carCode}
            disabled={carCode}
          />
        </div>
      </Space>
    </>
  );

  const pageStyle = `
  @page {
     margin: 4 36 0 0 !important;
  }
`;
  return (
    <>
      <Space
        style={{
          padding: '20px 20px 0px 20px',
          marginBottom: '15px',
        }}
      >
        {headerContent}
        <div>
          <b></b>
          <br />
          <ReactToPrint
            trigger={() => (
              <Button type='primary' disabled={!carAndDateAssign.length} icon={<PrinterOutlined />}>
                Print
              </Button>
            )}
            content={() => componentRef.current}
            documentTitle={`lenh_dieu_xe_${dataCar.code}_${assignDate}`}
            pageStyle={pageStyle}
          />
        </div>
      </Space>
      <div
        style={{
          border: '1px solid blue',
          borderRadius: '10px',
          display: 'none',
        }}
      >
        <div ref={componentRef}>
          <Row style={{ padding: '10px' }}>
            <Col>
              <Image
                src={
                  (companyInfo?.logo &&
                    `${process.env.REACT_APP_API_URL}${companyInfo?.logo?.slice(1)}`) ||
                  `https://ui-avatars.com/api/?name=${companyInfo?.code}&length=3&size=`
                }
                alt={companyInfo?.name}
                style={{ paddingLeft: 12 }}
                width={150}
              />
            </Col>
            <Col style={{ padding: '5px', margin: '0px' }}>
              <h4>{companyInfo?.name}</h4>
              {companyInfo?.arrAddressId?.map((address, index) => {
                return <div key={index}>{address}</div>;
              })}
              <p>MST: {companyInfo?.taxCode}</p>
            </Col>
          </Row>

          <Divider>
            <h1>
              <b>LỆNH ĐIỀU XE TRONG NGÀY</b>
            </h1>
          </Divider>

          <Row
            style={{
              margin: '20px',
            }}
          >
            <Col span={6}>
              <b>Biển Số Xe: </b>
              <span>{dataCar.code}</span>
            </Col>
            <Col span={6}>
              <b>Lái Xe: </b>
              <span>{dataCar.driverMain}</span>
            </Col>
            <Col span={6}>
              <b>Phụ Xe: </b>
              <span>{dataCar.driverSub}</span>
            </Col>
            <Col span={6}>
              <b>Ngày Điều: </b>
              <span>{Utils.formatDateFn(assignDate)}</span>
            </Col>
          </Row>

          <div
            style={{
              margin: '0px 12px 12px 12px',
              fontSize: '10px',
            }}
          >
            <TableContent loading={loading} data={carAndDateAssign} />
          </div>
          <Row>
            <Col offset={1}>Nhân Viên Lái Xe</Col>
            <Col span={6} offset={11} style={{ textAlign: 'center' }}>
              <p>
                Ngày {Utils.formatDateFn(assignDate, 'DD')} Tháng{' '}
                {Utils.formatDateFn(assignDate, 'MM')} Năm {Utils.formatDateFn(assignDate, 'YYYY')}
              </p>
              <p>Bộ Phận Điều Vận</p>
            </Col>
          </Row>
        </div>
      </div>
      {/* Display View */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {messageError && <h3 className='red'>{messageError}</h3>}
        {messageSuccess && <h3>{messageSuccess}</h3>}
        {(!assignDateSelected || !carSelectedId) && (
          <h3 className='red'>Vui lòng nhập đầy đủ thông tin</h3>
        )}
      </div>
      <div
        style={{
          border: '1px solid blue',
          borderRadius: '10px',
          marginBottom: '20px',
        }}
      >
        <div>
          <Row style={{ padding: '10px' }}>
            <Col>
              <Image
                src={
                  (companyInfo?.logo &&
                    `${process.env.REACT_APP_API_URL}${companyInfo?.logo?.slice(1)}`) ||
                  `https://ui-avatars.com/api/?name=${companyInfo?.code}&length=3&size=`
                }
                alt={companyInfo?.name}
                style={{ paddingLeft: 12 }}
                width={150}
              />
            </Col>
            <Col style={{ padding: '5px', margin: '0px' }}>
              <h4>{companyInfo?.name}</h4>
              {companyInfo?.arrAddressId?.map((address, index) => {
                return <div key={index}>{address}</div>;
              })}
              <p>MST: {companyInfo?.taxCode}</p>
            </Col>
          </Row>

          <Divider>
            <h1>
              <b>LỆNH ĐIỀU XE TRONG NGÀY</b>
            </h1>
          </Divider>

          <Row
            style={{
              margin: '20px',
            }}
          >
            <Col span={6}>
              <b>Biển Số Xe: </b>
              <span>{dataCar.code}</span>
            </Col>
            <Col span={6}>
              <b>Lái Xe: </b>
              <span>{dataCar.driverMain}</span>
            </Col>
            <Col span={6}>
              <b>Phụ Xe: </b>
              <span>{dataCar.driverSub}</span>
            </Col>
            <Col span={6}>
              <b>Ngày Điều: </b>
              <span>{Utils.formatDateFn(assignDate)}</span>
            </Col>
          </Row>

          <div
            style={{
              margin: '0px 12px 12px 12px',
            }}
          >
            <TableContentView
              loading={loading}
              data={carAndDateAssign}
              dataExtras={dataExtrasAssign}
              assignDate={assignDate}
              reload={reload}
            />
          </div>
          <Row>
            <Col offset={1}>Nhân Viên Lái Xe</Col>
            <Col span={6} offset={11} style={{ textAlign: 'center' }}>
              <p>
                Ngày {Utils.formatDateFn(assignDate, 'DD')} Tháng{' '}
                {Utils.formatDateFn(assignDate, 'MM')} Năm {Utils.formatDateFn(assignDate, 'YYYY')}
              </p>
              <p>Bộ Phận Điều Vận</p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export { AssignCar };
