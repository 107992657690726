import React from 'react';
import { Form, Button, message, Input, InputNumber, DatePicker } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';
import { DATE_FORMAT } from '~commons/constants';

const OilPricesForm = ({ fields, isNew, id, dataSelect, reload, onChange, setLoading }) => {
  const handleCreate = async (data) => {
    data.companyId = JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId;

    try {
      const res = await ApiServices.createManagementCost(data);
      if (res?.data?.data) {
        message.success('Thêm mới Chi phí quản lí thành công');
        return reload();
      } else {
        message.error('Thêm mới Chi phí quản lí thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới Chi phí quản lí thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const handleUpdate = async (data) => {
    data.id = id;
    data.companyId = dataSelect.companyId;
    try {
      const res = await ApiServices.updateManagementCost(id, data);
      if (res.data) {
        message.success('Cập nhật Chi phí quản lí thành công');
        return reload();
      } else {
        message.error('Cập nhật Chi phí quản lí thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật Chi phí quản lí thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await handleCreate(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await handleUpdate(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      name='global_state'
      className='form-input-data'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        name='name'
        label='Tên chi phí'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập tên chi phí',
          },
        ]}
      >
        <Input placeholder='Vui lòng nhập tên chi phí' />
      </Form.Item>
      <Form.Item
        // {...itemLayout}
        style={{ marginTop: '10px' }}
        name='price'
        label='Giá'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Giá',
          },
          {
            type: 'number',
            min: 0,
            message: 'Giá phải lớn hơn 0',
          },
        ]}
      >
        <InputNumber min={0} style={{ width: '100%' }} placeholder='Vui lòng nhập Giá' />
      </Form.Item>
      <Form.Item
        name='inputDate'
        label='Ngày nhập chi phí'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn ngày nhập chi phí',
          },
        ]}
      >
        <DatePicker
          placeholder='Ngày nhập chi phí'
          style={{ width: '100%' }}
          format={DATE_FORMAT}
        />
      </Form.Item>
      <Form.Item name='note' label='Ghi chú'>
        <Input placeholder='Vui lòng nhập ghi chú' />
      </Form.Item>
      <Form.Item {...tailLayout} className='submit-form-input-data'>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { OilPricesForm };
