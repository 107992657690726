import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchData,
  loadMore,
  updateQuery,
  updateLoading,
  updateSelected,
} from '~store/reducers/booking-cont/action';
import { showListFile } from '~store/reducers/white-board/action';
import {
  RangeDateFilter,
  TableWhiteBoardNew,
  WBTableLoadMore,
  UploadExcelButton,
} from '~components';
import { getColumns } from './columns';
import { Wrapper, Title } from '~commons/styles';
import { useWBTableScroll } from '~hooks';
import { Button, Drawer, message, Badge, Modal, Tabs } from 'antd';
import { CheckCircleOutlined, SwapOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { FormInput } from './form-input';
import { MoveCont } from './move-cont';
import { CompletedAll } from './completed-all';
import ApiServices from '~services';
import { TASK_STATUSES } from '~commons/constants';
import Utils from '~commons/utils';
import { BookingContCompleted } from './completed';
const WIDTH = '700px';
// const EXCEL_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/Import_SoCont_Xuat.xlsx';
const EXCEL_VENDOR_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/Import_SoCont.xlsx';

const objSortType = {
  ascend: 'ASC',
  descend: 'DESC',
};

export const BookingCont = () => {
  const dispatch = useDispatch();
  const timeOutRef = useRef(null);
  const formRef = useRef();

  const {
    data: bookingContData,
    query,
    loading,
    selected,
  } = useSelector((state) => state.bookingCont);
  //================= Scroll ======================
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [updated, setUpdated] = useState(null);
  const { tableRef, isScroll } = useWBTableScroll(null);
  const [showModalMoveCont, setModalMoveCont] = useState(false);
  const [showModalCompletedAll, setModalCompletedAll] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [tabKey, setTabKey] = useState(1);

  const setLoading = (value) => dispatch(updateLoading(value));
  const LoadMoreData = async () => {
    if (loadingMore) return;
    if (bookingContData.pageNumber * query.PageSize <= bookingContData.totalRecords) {
      let tmpQuery = { ...query, PageNumber: bookingContData.pageNumber + 1 };
      setLoadingMore(true);
      await dispatch(loadMore(tmpQuery));
      setLoadingMore(false);
    }
  };
  useEffect(() => {
    if (isScroll) {
      LoadMoreData();
    }
  }, [isScroll]);

  const deleteRow = async (record) => {
    if (record?.id) {
      try {
        const res = await ApiServices.deleteCont(record.id);
        if (res.data) {
          message.success('Xoá cont thành công!');
          setLoading(true);
          initData();
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
  };

  const initData = async () => {
    dispatch(updateSelected([]));
    setSelectedRows([]);
    setModalCompletedAll(false);
    setModalMoveCont(false);
    dispatch(updateLoading(true));
    await dispatch(fetchData(query));
    dispatch(updateLoading(false));
  };

  const initDataSearch = async () => {
    setModalCompletedAll(false);
    setModalMoveCont(false);
    dispatch(updateLoading(true));
    await dispatch(fetchData(query));
    dispatch(updateLoading(false));
  };

  useEffect(() => {
    timeOutRef.current && clearTimeout(timeOutRef.current);
    if (tabKey === 1 || tabKey === '1') {
      timeOutRef.current = setTimeout(() => initDataSearch(), 500);
    }
  }, [query, tabKey]);

  const setQuery = (columns) => {
    const tmpQuery = { ...query };
    tmpQuery.Columns = { ...tmpQuery.Columns, ...columns };
    dispatch(updateQuery(tmpQuery));
  };

  const onSelectChange = () => {};

  const handleSelectRow = (record, selectedStatus) => {
    let listId = [...selected];
    let listRowData = [...selectedRows];
    if (selectedStatus) {
      // add key Id
      listId.push(record?.id);
      dispatch(updateSelected(listId));
      // add key row data
      listRowData.push(record);
      setSelectedRows(listRowData);
    } else {
      // remove key Id
      const removeSelectedId = listId?.filter((el) => el !== record?.id);
      dispatch(updateSelected(removeSelectedId));
      // remove row data
      const removeListRowSelected = listRowData?.filter((el) => el.id !== record?.id);
      setSelectedRows(removeListRowSelected);
    }
  };

  const handleSelectAll = (selectedNew, _, changeRowsNew) => {
    const listKeyIdOld = [...selected];
    const listKeyIdNew = changeRowsNew?.map((item) => item.id);

    let listRowDataOld = [...selectedRows];
    let listRowDataNew = [...changeRowsNew];

    if (selectedNew) {
      // add all id
      const listIdKeyAdd = listKeyIdOld.concat(listKeyIdNew);
      dispatch(updateSelected(listIdKeyAdd));
      // add all data
      const listRowDataAdd = listRowDataOld.concat(listRowDataNew);
      setSelectedRows(listRowDataAdd);
    } else {
      // remove all id
      const listIdKeyRemove = listKeyIdOld?.filter((el) => !listKeyIdNew.includes(el));
      dispatch(updateSelected(listIdKeyRemove));
      // remove all data
      const listRowDataRemove = listRowDataOld?.filter((el) => !listKeyIdNew.includes(el.id));
      setSelectedRows(listRowDataRemove);
    }
  };

  const isNotValidToComplete = (record) => {
    const requiredFields = [
      // 'sealNumber',
      // 'subnoNumber',
      // 'book_DeliveryContRequestDate',
      // 'book_LiftingEmptyContAcctualDate',
      // 'cutRomoocEmptyCont',
      // 'pickupAcctualDate',
      // 'deliveryAcctualDate',
      'contNumber',
      'locationId',
      'book_LiftingEmptyContAcctualDate',
      'cutRomoocEmptyCont',
      'deliveryAcctualDate',
    ];
    for (let fieldData of requiredFields) {
      if (!record[fieldData]) {
        return true;
      }
    }
    return false;
  };
  const onComplete = async (record) => {
    if (loading) return;
    setLoading(true);
    ApiServices.completeBookingCont(record.id)
      .then((res) => {
        if (res.status === 200) {
          message.success('Hoàn thành cont thành công!');
          setUpdated(null);
          return initData();
        }
        message.error('Hoàn thành cont thất bại!');
      })
      .catch(() => {
        message.error('Hoàn thành cont thất bại!');
      })
      .finally(() => setLoading(false));
  };

  // const onUploadExcel = (file) => {
  //   if (loading) return;
  //   setLoading(true);
  //   ApiServices.importContNumber(file)
  //     .then((res) => {
  //       if (res.data) {
  //         message.success(res?.data?.message);
  //         initData();
  //       }
  //     })
  //     .catch((err) => {
  //       console.error('UPLOAD IMPORT CARR ERROR', err);
  //       message.error('Thao tác thất bại, Vui lòng thử lại sau!');
  //     })
  //     .finally(() => setLoading(false));
  // };
  const renderError = (item) => {
    return <div>{item}</div>;
  };
  const onUploadExcelVendor = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importBookingContNumber(file)
      .then((res) => {
        if (res?.data?.data) {
          if (res?.data?.errors?.length > 0) {
            window.TmsNoti.error(res?.data?.message, res?.data?.errors?.map(renderError), 0);
          } else {
            window.TmsNoti.success(res?.data?.message);
            initData();
          }
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT importBookingContNumber ERROR', err);
        message.error('Thao tác thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };

  const onUploadExcelVendorV1 = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importBookingContNumberV1(file)
      .then((res) => {
        if (res?.data?.data) {
          if (res?.data?.errors?.length > 0) {
            window.TmsNoti.error(res?.data?.message, res?.data?.errors?.map(renderError), 0);
          } else {
            window.TmsNoti.success(res?.data?.message);
            initData();
          }
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT importBookingContNumberV1 ERROR', err);
        message.error('Thao tác thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };

  const handleShowPopupMoveCont = () => {
    setModalMoveCont(true);
  };
  const handleClosePopupMoveCont = () => {
    setModalMoveCont(false);
  };
  const handleShowPopupCompletedAll = () => {
    setModalCompletedAll(true);
  };
  const handleClosePopupCompletedAll = () => {
    setModalCompletedAll(false);
  };

  const handleExportExcel = async () => {
    if (!query || loading) return;

    const dateFrom = moment(query?.DateFrom).format('YYYY-MM-DD');
    const dateTo = moment(query?.DateTo).format('YYYY-MM-DD');

    const dataSend = {
      DateFrom: query?.DateFrom && moment(query?.DateFrom).format('YYYY-MM-DD'),
      DateTo: query?.DateTo && moment(query?.DateTo).format('YYYY-MM-DD'),
      Columns: query?.Columns,
      export: true,
    };
    setLoading(true);
    try {
      const { data } = await ApiServices.exportExcelHandleCont(dataSend);
      if (data) {
        const outputFilename = `Xu_Ly_Cont_Xuat_Tu_ngay_${dateFrom}_den_ngay_${dateTo}.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoading(false);
    }
  };

  const handleExportContTemplate = async (newQuery, newSelectedRows = []) => {
    if (!newQuery || loadingExport) return;
    let dataSelect = [];
    if (newSelectedRows?.length > 0) {
      const arrayId = newSelectedRows?.map((item) => item.id);
      dataSelect = arrayId;
    }

    const dateFrom = moment(newQuery?.DateFrom).format('YYYY-MM-DD');
    const dateTo = moment(newQuery?.DateTo).format('YYYY-MM-DD');

    const dataSend = {
      DateFrom: newQuery?.DateFrom && moment(newQuery?.DateFrom).format('YYYY-MM-DD'),
      DateTo: newQuery?.DateTo && moment(newQuery?.DateTo).format('YYYY-MM-DD'),
      Columns: newQuery?.Columns,
    };
    setLoadingExport(true);
    try {
      const { data } = await ApiServices.exportExcelHandleContNumber(dataSelect, dataSend);
      if (data) {
        const outputFilename = `Mau_Import_So_Cont_Xuat_Tu_ngay_${dateFrom}_den_ngay_${dateTo}.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoadingExport(false);
    }
  };

  const checkDisable = (record) =>
    record.status === TASK_STATUSES.FINISH || record.status === TASK_STATUSES.SENT_PARTNER;

  const handleTableChange = (pagination, filters, sorter) => {
    console.log({ pagination, filters, sorter });
    if (sorter?.field && sorter?.order) {
      const tmpQuery = { ...query };
      tmpQuery.SortColumn = sorter?.field;
      tmpQuery.SortColumnDirection = objSortType[sorter?.order];

      dispatch(updateQuery(tmpQuery));
    }
  };
  const onChangeTab = (key) => {
    setTabKey(key);
  };

  const handleUpdateQuery = (dataQuery) => {
    dispatch(updateSelected([]));
    setSelectedRows([]);
    dispatch(updateQuery(dataQuery));
  };

  return (
    <Wrapper ref={tableRef}>
      <Tabs defaultActiveKey='1' onChange={onChangeTab}>
        <Tabs.TabPane tab='Chưa Hoàn Thành' key='1'>
          <Title>
            Total: <b className='red'>({bookingContData?.totalRecords || 0})</b>
            <RangeDateFilter query={query} onChange={handleUpdateQuery} allowClear={false} />
            <span style={{ marginLeft: '10px' }}> </span>
            <Badge count={selectedRows?.filter((item) => item?.status === 0)?.length}>
              <Button
                danger
                icon={<SwapOutlined />}
                style={{ padding: '0px 20px', marginLeft: '10px' }}
                disabled={selectedRows?.filter((item) => item?.status === 0)?.length < 1}
                onClick={handleShowPopupMoveCont}
              >
                Chuyển Đối Tác
              </Button>
            </Badge>
            <Badge
              count={
                selectedRows?.filter((item) => item?.contNumber && !isNotValidToComplete(item))
                  ?.length
              }
            >
              <Button
                type='primary'
                icon={
                  <CheckCircleOutlined
                    style={{
                      fontSize: '15px',
                    }}
                    twoToneColor='#52c41a'
                  />
                }
                style={{ padding: '0px 20px', marginLeft: '20px' }}
                disabled={
                  selectedRows?.filter((item) => item?.contNumber && !isNotValidToComplete(item))
                    ?.length < 1
                }
                onClick={handleShowPopupCompletedAll}
              >
                Hoàn Thành
              </Button>
            </Badge>
            <span style={{ marginLeft: '10px' }}> </span>
            <UploadExcelButton
              loading={loading}
              onChange={onUploadExcelVendor}
              templateUrl={EXCEL_VENDOR_TEMPLATE_URL}
              importLabel='Import Số Cont'
              downloadLabel='Mẫu Import Số Cont'
              isShowDownloadTemplate={false}
            />
            <span style={{ marginLeft: '10px' }}> </span>
            <UploadExcelButton
              loading={loading}
              onChange={onUploadExcelVendorV1}
              templateUrl={EXCEL_VENDOR_TEMPLATE_URL}
              importLabel='Import nhanh số Cont'
              downloadLabel=''
              isShowDownloadTemplate={false}
            />
            <Badge count={selectedRows?.length}>
              <Button
                type='primary'
                icon={<DownloadOutlined />}
                loading={loadingExport}
                style={{ padding: '0px 20px', marginLeft: '10px' }}
                onClick={() => handleExportContTemplate(query, selectedRows)}
              >
                Mẫu Import Số Cont
              </Button>
            </Badge>
            <Button
              type='primary'
              icon={<DownloadOutlined />}
              style={{ padding: '0px 20px', marginLeft: '10px' }}
              onClick={handleExportExcel}
            >
              Xuất Báo Cáo
            </Button>
          </Title>

          <TableWhiteBoardNew
            rowKey='id'
            headerLength='200px'
            columns={getColumns(
              setQuery,
              setUpdated,
              deleteRow,
              isNotValidToComplete,
              onComplete,
              (record) => dispatch(showListFile(record))
            )}
            data={bookingContData?.data || []}
            loading={loading}
            onSelectChange={onSelectChange}
            onHandleSelectRow={handleSelectRow}
            onHandleSelectAll={handleSelectAll}
            isNotSubHeight
            selectedParent={selected}
            checkDisableSelection={checkDisable}
            onChange={handleTableChange}
          />
          <WBTableLoadMore loading={loadingMore} />
          <Drawer
            title={`${updated?.subnoNumber}`}
            placement='right'
            onClose={() => setUpdated(null)}
            open={!!updated}
            width={WIDTH}
            footer={
              updated?.onlyView ? (
                <></>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    type='primary'
                    style={{ minWidth: '160px' }}
                    disabled={!updated || isNotValidToComplete(updated)}
                    onClick={() => updated && onComplete(updated)}
                  >
                    Hoàn Thành
                  </Button>
                  {/* <Button
                type='primary'
                style={{ minWidth: '160px' }}
                onClick={() => formRef.current?.submitForm()}
              >
                Lưu
              </Button> */}
                </div>
              )
            }
          >
            <div style={{ padding: '12px' }}>
              <FormInput
                ref={formRef}
                onClose={() => setUpdated()}
                onsubmit={() => {}}
                dataInput={updated}
                reload={initData}
              />
            </div>
          </Drawer>
          <Modal
            open={showModalMoveCont}
            onCancel={handleClosePopupMoveCont}
            footer={null}
            title='Chuyển Cont Cho Đối Tác'
            width={700}
          >
            <MoveCont
              selectedRows={selectedRows?.filter((item) => item?.status === 0)}
              reload={initData}
            />
          </Modal>
          <Modal
            open={showModalCompletedAll}
            onCancel={handleClosePopupCompletedAll}
            footer={null}
            title='Danh sách hoàn thành'
            width={600}
          >
            <CompletedAll
              selectedRows={selectedRows?.filter(
                (item) => item?.contNumber && !isNotValidToComplete(item)
              )}
              reload={initData}
            />
            {/* {(record?.status !== 0 && record?.status !== 2) || isNotValidToComplete(record)} */}
          </Modal>
        </Tabs.TabPane>
        <Tabs.TabPane tab='Đã Hoàn Thành' key='2'>
          <BookingContCompleted tabKey={tabKey} />
        </Tabs.TabPane>
      </Tabs>
    </Wrapper>
  );
};
