import ApiServices from '~services';
import storage from '~storage';
import { KEYS_OF_DATE, KEYS_OF_DATE_TIME } from './fields-data';
import moment from 'moment';

const formatFields = (data) => {
  let format = {};
  data.forEach((item) => {
    if (KEYS_OF_DATE.includes(item.name[0])) {
      format[item.name[0]] = item.value ? moment(item.value).format('YYYY-MM-DD') : null;
    } else if (KEYS_OF_DATE_TIME.includes(item.name[0])) {
      format[item.name[0]] = item.value ? moment(item.value).format() : null;
    } else {
      format[item.name[0]] = item.value;
    }
  });
  return format;
};
export const formatTransitData = (fields, conts) => {
  let format = formatFields(fields);
  format.orderType = storage?.staticVariables?.OrderType?.Order_Transit;
  let dataConts = [];
  // format.orderFiles = format.orderFiles.fileList;
  if (conts) {
    for (let cont of Object.values(conts)) {
      let count = 0;

      let total = cont.values?.reduce((prev, curr) => prev + curr.total, 0);
      if (total) {
        for (let el of cont.values) {
          if (el.total) {
            for (let i = 0; i < el.total; i++) {
              dataConts.push({
                orderNumber: ++count,
                quantityCont: total,
                code: format.transitNumber,
                isCargo: false,
                cargoWeight: 0,
                typeCont: el.id,
                bill_LiftingLadenContRequestDate: el.date?.format(),
              });
            }
          }
        }
      }
    }
    format.conts = dataConts;
  }
  return format;
};

export const createOrder = async (data, type, cont) => {
  let format = formatFields(data);
  format.orderType = type;
  let formatConts = {};

  const orderType = storage.staticVariables ? storage.staticVariables.OrderType : {};
  switch (type) {
    case orderType.Order_Transit:
      formatConts = cont.map((item) => {
        return {
          ...item,
          isCargo: !!item.isCargo,
          Code: format.transitNumber,
          cargoWeight: item.cargoWeight || 0,
          book_CargoReadyDate: item.cargoReadyDate,
          transit_Date: item.deliveryContRequestDate,
        };
      });
      format.conts = formatConts;
      return ApiServices.createOrderTransit(format);

    case orderType.Order_Bill:
      formatConts = cont.map((item) => {
        return {
          ...item,
          Code: format.billNumber,
          cargoWeight: item.cargoWeight || 0,
          bill_LiftingLadenContRequestDate: item.deliveryContRequestDate,
        };
      });
      format.conts = formatConts;
      format.orderFiles = format.orderFiles.fileList;
      return ApiServices.createOrderBill(format);

    default:
      formatConts = cont.map((item) => {
        let res = {
          ...item,
          Code: format.bookingNumber,
          isCargo: !!item.isCargo,
          cargoWeight: item.cargoWeight || 0,
          book_CargoReadyDate: item.cargoReadyDate,
          book_DeliveryContRequestDate: item.deliveryContRequestDate,
        };
        if (item.isCargo !== true) {
          delete res.book_CargoReadyDate;
        }
        return res;
      });

      format.orderFiles = format.orderFiles.fileList;
      format.conts = formatConts;
      return ApiServices.createOrderBooking(format);
  }
  // return format;
};

export const updateOrder = async (data, type, orderDetail) => {
  let format = formatFields(data);
  format.orderType = type;
  const orderType = storage.staticVariables ? storage.staticVariables.OrderType : {};
  switch (type) {
    case orderType.Order_Transit:
      return ApiServices.updateTransit(orderDetail.id, format);

    case orderType.Order_Bill:
      format.orderFiles = format.orderFiles.fileList?.length
        ? format.orderFiles.fileList
        : format.orderFiles;
      return ApiServices.updateBill(orderDetail.id, format);

    default:
      format.orderFiles = format.orderFiles.fileList?.length
        ? format.orderFiles.fileList
        : format.orderFiles;
      return ApiServices.updateBooking(orderDetail.id, format);
  }
};

export const addCont = async (data, orderDetail, reload, formData) => {
  let format = formatFields(formData);
  let formatCont = {
    ...data,
    locationOfReciptCode: format.locationOfReceiptCode,
    locationOfDeliveryCode: format.locationOfDeliveryCode,
  };
  const orderType = storage.staticVariables ? storage.staticVariables.OrderType : {};
  switch (orderDetail.orderType) {
    case orderType.Order_Transit:
      formatCont = {
        ...formatCont,
        isCargo: !!data.isCargo,
        code: orderDetail.code,
        cargoWeight: data.cargoWeight || 0,
        book_CargoReadyDate: data.cargoReadyDate,
        transit_Date: data.deliveryContRequestDate,
      };
      if (!data.isCargo) {
        delete formatCont.book_CargoReadyDate;
      }
      break;

    case orderType.Order_Bill:
      formatCont = {
        ...formatCont,
        code: orderDetail.code,
        cargoWeight: data.cargoWeight || 0,
        bill_LocationDepotReturnCode: format.locationDepotReturnCode,
        bill_LiftingLadenContRequestDate: data.deliveryContRequestDate,
      };
      break;

    default:
      formatCont = {
        ...formatCont,
        code: orderDetail.code,
        isCargo: !!data.isCargo,
        cargoWeight: data.cargoWeight || 0,
        book_LocationDepotFromCode: format.locationDepotFromCode,
        book_DeliveryContRequestDate: data.deliveryContRequestDate,
      };
      if (!data.isCargo) {
        delete formatCont.book_CargoReadyDate;
      }
      break;
  }
  try {
    const { data } = await ApiServices.addContOrder(orderDetail.id, formatCont);
    if (data) {
      return reload();
    }
  } catch (error) {
    console.error(error);
  }
};
