import React, { useEffect, useState, useCallback } from 'react';
const ExcelJS = require('exceljs');
import moment from 'moment';
import { debounce } from 'lodash';
import { Space, Button, Input } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import { RangeDateFilter, Selection, TableLayout } from '~components';
import { TableTitle } from '~commons/styles';
import Utils from '~commons/utils';
import {
  LIST_COLUMNS_TITLE_FIRST_SUMMARY_SHEET,
  TITLE_SUMMARY_SHEET,
  COLUMNS_BORDER_LIST_SUMMARY_SHEET,
  LIST_COLUMN_SUM_SUMMARY_SHEET,
} from './sheet-summary-constant';

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};

const ReportAccountantsBills = () => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState('');
  const [loadingExport, setLoadingExport] = useState(false);
  ////////////////////
  const [dataForTable, setDataForTable] = useState([]);

  const getCustomer = async (query = '') => {
    if (loading) return;
    setLoading(true);
    try {
      let { data } = await ApiServices.getCustomer({ query });
      // if (data?.data) {
      //   setCustomers(data?.data);
      // }
      const dataSetDefault = [
        {
          id: 'all',
          name: 'Tất Cả',
        },
      ];
      const dataGetFromApi = data?.data || [];
      const dataEnd = dataSetDefault?.concat(dataGetFromApi);
      setCustomers(dataEnd);
    } catch (error) {
      console.log('Get customer list failed:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomer();
  }, []);

  const getData = async (query = DEFAULT_QUERIES, cusId) => {
    setLoading(true);
    const newQuery = { ...query };
    // const customerId1 = '60cc11ea2c250000fb0012f7';
    return ApiServices.getReportAccountantsBills(cusId, newQuery)
      .then((res) => {
        if (res?.data) {
          setDataForTable(res?.data || []);
        }
      })
      .catch((err) => {
        console.error('get Accountants Bills By CustomerExpense Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, newCustomerId) => {
      getData(newFilter, newCustomerId);
    }, 500),
    []
  );
  useEffect(() => {
    if (customerId) {
      timeoutGetData(filter, customerId);
    }
  }, [filter, customerId]);

  const handleChangeCustomer = (value) => {
    if (!value) {
      setCustomerId('');
      setDataForTable([]);
      return;
    }
    setCustomerId(value);
  };

  const renderMoneyVAT = (_, record) => {
    return Utils.formatNumber(record?.total - record?.price);
  };

  const renderOrderType = (value) => {
    if (!value) return '';
    if (value === 'Booking') {
      return 'Xuất';
    }
    if (value === 'Bill') {
      return 'Nhập';
    }
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Khách hàng
          <Input name='customerName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'customerName',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Loại hàng
          <Input name='orderType' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'orderType',
      align: 'center',
      width: 150,
      render: renderOrderType,
    },
    {
      title: (
        <TableTitle>
          Số Cont
          <Input name='contNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'contNumber',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Tên chi phí
          <Input name='expenseName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'expenseName',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          NCC Hóa đơn
          <Input name='supplierName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'supplierName',
      align: 'center',
      width: 180,
    },
    {
      title: (
        <TableTitle>
          Số hóa đơn
          <Input name='billNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'billNumber',
      align: 'center',
      width: 150,
    },
    {
      title: <TableTitle>Ngày hóa đơn</TableTitle>,
      dataIndex: 'billDate',
      align: 'center',
      width: 100,
      render: (val) => val && Utils.formatDate(val),
    },
    {
      title: <TableTitle>Số tiền</TableTitle>,
      dataIndex: 'price',
      align: 'center',
      width: 150,
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: <TableTitle>%VAT</TableTitle>,
      dataIndex: 'vat',
      align: 'center',
      width: 150,
    },
    {
      title: <TableTitle>Số tiền VAT</TableTitle>,
      dataIndex: 'moneyVAT',
      align: 'center',
      width: 150,
      render: renderMoneyVAT,
    },
    {
      title: <TableTitle>Tổng</TableTitle>,
      dataIndex: 'total',
      align: 'center',
      width: 150,
      render: (value) => Utils.formatNumber(value),
    },
  ];

  const handleExportExcel = async (dataExport = []) => {
    setLoadingExport(true);

    const workbook = new ExcelJS.Workbook();
    await dataExport?.forEach(async (itemExport) => {
      const nameSheet = itemExport?.customerName ? itemExport?.customerName?.slice(0, 10) : '';
      // Ten sheet
      const sheetSummary = workbook.addWorksheet(`${nameSheet}...`, {
        properties: { tabColor: { argb: 'FF00FF00' } },
        views: [{ showGridLines: false }],
      });

      sheetSummary.properties.defaultRowHeight = 15;
      sheetSummary.properties.defaultColWidth = 15;
      sheetSummary.getColumn(1).width = 5;
      sheetSummary.getColumn(2).width = 25;
      sheetSummary.getColumn(3).width = 10;
      sheetSummary.getColumn(4).width = 15;
      sheetSummary.getColumn(5).width = 18;
      sheetSummary.getColumn(7).width = 25;

      // Title
      sheetSummary.getRow(2).font = {
        name: 'Arial',
        family: 2,
        size: 16,
        bold: true,
      };
      sheetSummary.getCell('E2').value = `BẢNG KÊ HÓA ĐƠN`;
      sheetSummary.mergeCells('B4:F4');
      sheetSummary.getCell('B4').font = {
        name: 'Arial',
        family: 2,
        size: 11,
        bold: true,
      };
      sheetSummary.getCell('B4').value = `${itemExport?.customerName}`;
      sheetSummary.getCell('E3').value = `Từ Ngày ${moment(filter?.DateFrom).format(
        'DD/MM/YYYY'
      )} -> ${moment(filter?.DateTo).format('DD/MM/YYYY')}`;
      sheetSummary.addRow(['']); // blank Row
      // Body
      sheetSummary.addRow(LIST_COLUMNS_TITLE_FIRST_SUMMARY_SHEET);
      TITLE_SUMMARY_SHEET.forEach((item) => {
        sheetSummary.getCell(`${item}6`).border = {
          top: { style: 'thin', color: { argb: '006699' } },
          left: { style: 'thin', color: { argb: '006699' } },
          right: { style: 'thin', color: { argb: '006699' } },
          bottom: { style: 'thin', color: { argb: '006699' } },
        };
        sheetSummary.getCell(`${item}6`).fill = {
          type: 'gradient',
          gradient: 'angle',
          stops: [
            { position: 0, color: { argb: '96B6C5' } },
            { position: 1, color: { argb: '96B6C5' } },
          ],
        };
      });
      // ================ Summary Body ====================
      itemExport?.children?.map((itemBody, indexRow) => {
        sheetSummary.addRow([
          '',
          itemBody?.customerName || '', // Khach hang
          itemBody?.orderType ? Utils.displayOrderTypeOrder(itemBody?.orderType) : '', // Loại Hinh
          itemBody?.dissolveDate
            ? new Date(moment(itemBody?.dissolveDate).format('YYYY-MM-DD'))
            : '', // Ngay CT
          itemBody?.contNumber || '', // 'Số Cont',
          itemBody?.expenseName || '', //  'Tên Chi Phí',
          itemBody?.supplierName || '', // 'Nhà Cung Cấp Hóa Đơn',
          itemBody?.billNumber || '', // 'Số Hóa Đơn',
          itemBody?.billDate ? new Date(moment(itemBody?.billDate).format('YYYY-MM-DD')) : '', // 'Ngày Hóa Đơn',
          itemBody?.price || 0, // 'Số Tiền',
          itemBody?.total - itemBody?.price || 0, // 'VAT',
          itemBody?.total || 0, // 'Thành Tiền',
        ]);

        COLUMNS_BORDER_LIST_SUMMARY_SHEET.forEach((item) => {
          sheetSummary.getCell(`${item}${indexRow + 7}`).border = {
            top: { style: 'thin', color: { argb: '006699' } },
            left: { style: 'thin', color: { argb: '006699' } },
            right: { style: 'thin', color: { argb: '006699' } },
            bottom: { style: 'thin', color: { argb: '006699' } },
          };
        });
      });
      // SUm
      const dataLength = itemExport?.children?.length;
      const startPosition = 7;
      const endPosition = dataLength + 6;
      const showResult = dataLength + 7;
      if (dataLength > 0) {
        sheetSummary.getCell(`I${showResult}`).value = 'Tổng';
        LIST_COLUMN_SUM_SUMMARY_SHEET.forEach((column) => {
          sheetSummary.getCell(`${column + showResult}`).value = {
            formula: `=SUM(${column + startPosition}:${column + endPosition})`,
          };
        });
        COLUMNS_BORDER_LIST_SUMMARY_SHEET.forEach((item) => {
          sheetSummary.getCell(`${item + showResult}`).border = {
            top: { style: 'thin', color: { argb: '006699' } },
            left: { style: 'thin', color: { argb: '006699' } },
            right: { style: 'thin', color: { argb: '006699' } },
            bottom: { style: 'thin', color: { argb: '006699' } },
          };
          sheetSummary.getCell(`${item + showResult}`).fill = {
            type: 'gradient',
            gradient: 'angle',
            stops: [
              { position: 0, color: { argb: '96B6C5' } },
              { position: 1, color: { argb: '96B6C5' } },
            ],
          };
        });
        sheetSummary.getRow(showResult).font = {
          name: 'Arial',
          family: 4,
          size: 10,
          bold: true,
        };
      }
      sheetSummary.getRow(6).font = {
        name: 'Arial',
        family: 4,
        size: 10,
        bold: true,
      };
      sheetSummary.getRow(6).alignment = { vertical: 'middle', horizontal: 'center' };
      sheetSummary.getColumn(10).numFmt = '#,##0';
      sheetSummary.getColumn(10).width = 15;
      sheetSummary.getColumn(11).numFmt = '#,##0';
      sheetSummary.getColumn(11).width = 15;
      sheetSummary.getColumn(12).numFmt = '#,##0';
      sheetSummary.getColumn(12).width = 15;
    });

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Bang_Ke_Hoa_Don_${moment().format('DD-MM-YY HH:mm')}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoadingExport(false);
    }
  };

  const handleGetDataExport = async (query = DEFAULT_QUERIES, cusId = '') => {
    if (!query?.DateFrom || !query?.DateTo || loading) return;

    const DateFrom = moment(query?.DateFrom).format('YYYY-MM-DD');
    const DateTo = moment(query?.DateTo).format('YYYY-MM-DD');
    let dataSend = {};
    if (cusId === 'all') {
      dataSend = { DateFrom, DateTo };
    } else {
      dataSend = { DateFrom, DateTo, customerId: cusId };
    }
    setLoadingExport(true);
    return ApiServices.getDataToExportReportAccountantsBills(dataSend)
      .then((res) => {
        if (res?.data?.data) {
          let arrayMap = [];
          res?.data?.data?.map((item, index) => {
            if (index === 0) {
              arrayMap = [
                ...arrayMap,
                {
                  customerId: item?.customerId,
                  customerName: item?.customerName,
                  children: [item],
                },
              ];
              return;
            }
            const ind = arrayMap?.findIndex((el) => el.customerId === item.customerId);
            if (ind === -1) {
              arrayMap = [
                ...arrayMap,
                {
                  customerId: item?.customerId,
                  customerName: item?.customerName,
                  children: [item],
                },
              ];
              return;
            }
            arrayMap[ind].children = [...arrayMap[ind].children, item];
          });

          return handleExportExcel(arrayMap || []);
        }
      })
      .catch((err) => {
        console.error('get Data Export Report Accountants Bills Error: ', err);
      })
      .finally(() => {
        setLoadingExport(false);
      });
  };
  const pagination = {
    total: dataForTable?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <>
      <h1>5027 - BẢNG KÊ HÓA ĐƠN</h1>

      <div
        style={{
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          padding: '20px 10px',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      >
        <Space>
          <div>
            <b
              style={{
                marginLeft: '10px',
              }}
            >
              Chọn ngày:
            </b>
            <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
          </div>
          <div>
            <b>
              Khách hàng:{' '}
              {!customerId && (
                <i
                  style={{
                    color: 'red',
                    fontSize: '12px',
                  }}
                >
                  Bạn phải chọn khách hàng
                </i>
              )}
            </b>
            <br />
            <Selection
              status={!customerId ? 'error' : ''}
              data={customers}
              placeholder='Khách hàng'
              fieldValue='name'
              width={400}
              value={customerId}
              onChange={handleChangeCustomer}
              onSearch={getCustomer}
            />
          </div>
          <div>
            <br />
            <Button
              type='primary'
              loading={loadingExport}
              icon={<EyeOutlined />}
              style={{ padding: '0px 20px', marginLeft: '10px' }}
              onClick={() => handleGetDataExport(filter, customerId)}
              disabled={!dataForTable?.data?.length}
            >
              Export Excel
            </Button>
          </div>
        </Space>
      </div>
      <TableLayout
        rowKey='key'
        columns={columns}
        data={dataForTable?.data?.map((item, index) => {
          item.key = item?.customerId + index;
          return item;
        })}
        loading={loading}
        pagination={pagination}
        isDisplayCheckbox={false}
        headerLength='290px'
      />
    </>
  );
};

export { ReportAccountantsBills };
