import actionTypes from './type';

const initialState = {
  dataListFile: [],
  dataShowAssignCarToday: null,
  dataAssign: [],
  romoocAssign: {},
  carAssign: {},
  driverAssign: null,
  finalTask: null,
  checkDistance: null,
  changeRomoocData: null,
  showCustomerDetail: null,
  assignLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.ASSIGN_LOADING_CHANGED: {
      return {
        ...state,
        assignLoading: action.loading,
      };
    }
    case actionTypes.SHOW_CUSTOMER_DETAIL: {
      return {
        ...state,
        showCustomerDetail: action.data,
      };
    }
    case actionTypes.SHOW_LIST_FILE:
      return {
        ...state,
        dataListFile: action.dataListFile,
      };
    case actionTypes.SHOW_ASSIGN_CAR_TODAY:
      return {
        ...state,
        dataShowAssignCarToday: action.dataShowAssignCarToday,
      };
    case actionTypes.ASSIGN_DATA_CHANGED:
      return {
        ...state,
        dataAssign: action.data,
      };
    case actionTypes.ASSIGN_ITEM_CHANGED: {
      let ind = state.dataAssign?.findIndex((item) => item?.id === action.data?.id);

      let arrData;
      if (ind !== -1) {
        arrData = [];
        arrData = state.dataAssign.map((el) => el);
        arrData[ind] = { ...arrData[ind], ...action.data };
      } else {
        arrData = [];
        arrData = state.dataAssign.map((el) => el);
        arrData.push(action.data);
      }
      return {
        ...state,
        dataAssign: arrData,
      };
    }

    case actionTypes.ASSIGN_ITEM_DELETE: {
      let nData = state.dataAssign.map((el) => el);
      nData = nData.filter((el) => el.id !== action.data);
      return {
        ...state,
        dataAssign: nData,
      };
    }

    case actionTypes.ASSIGN_ROMOOC_ADDED_CHANGED:
      return {
        ...state,
        romoocAssign: action.data,
      };
    case actionTypes.ASSIGN_CAR_CHANGED:
      return {
        ...state,
        carAssign: action.data,
      };
    case actionTypes.ASSIGN_LOCATIONS_DISTANCE_DATA_CHANGE: {
      return {
        ...state,
        checkDistance: { ...state.checkDistance, data: action.data },
      };
    }
    case actionTypes.ASSIGN_LOCATIONS_CHECK_DISTANCES:
      return {
        ...state,
        checkDistance: { data: action.data, onFinish: action.onFinish },
      };
    case actionTypes.ASSIGN_FINAL_TASK_CHANGED:
      return {
        ...state,
        finalTask: action.data,
      };
    case actionTypes.ASSIGN_ROMOOC_DATA_CHANGED:
      return {
        ...state,
        changeRomoocData: action.data,
      };
    case actionTypes.ASSIGN_DRIVER_CHANGED:
      return {
        ...state,
        driverAssign: action.data,
      };
    default:
      return state;
  }
}
