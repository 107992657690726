import { createElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as antdIcons from '@ant-design/icons';
import { Cars } from './cars';
import { Customers } from './customers';
import { Drivers } from './drivers';
import { LocationTypes } from './location-types';
import { Ports } from './ports';
import { TaskJobDiary } from './task-job-diary';
import { Gara } from './warehouse-breakdown-maintenance/garas';
import { MaterialType } from './warehouse-breakdown-maintenance/material-types';
import { SupplierMaterial } from './warehouse-breakdown-maintenance/supplier-material';
import { Units } from './warehouse-breakdown-maintenance/units';
import storage from '~storage';
import { Layout } from '~components';
import { Areas } from './areas';
import { ShippingLines } from './shipping-lines';
import { Tires } from './tires';
import { Locations } from './locations';
import { Materials } from './warehouse-breakdown-maintenance/materials';
import { Romoocs } from './romoocs';
import { Distances } from './distances';
import { Vendors } from './vendor';
import { CargoTypes } from './cargo-types';
import { ContTypes } from './cont-types';
import { ContSearchNumber } from './cont-serch-number';
import { OrderList } from './order-list';
import { Booking } from './create-booking';
import { Bill } from './create-bill';
import { Transit } from './create-transit';
import { RevisedLog } from './revised-log';
import { OrdersCancel } from './orders-cancel';
import { WhiteBoard } from './white-board';
import { WhiteBoardNoBD } from './white-board-no-bd';
import { DepotContHandle } from './depot-conts-handle';
import { BookingCont } from './booking-conts';
import { BillCont } from './bill-conts';
import { JobHandle } from './job-handle';
import { AssignCar } from './assign-car';
import { PrintOil } from './print-oil';
import { ReplacementCategories } from './replacement-categories';
import { ReportByCustomer } from './report-detail/report-by-customer';
import { ReportByCustomer1 } from './report-detail/report-by-customer-1';
import { ReportByCustomer2 } from './report-detail/report-by-customer-2';
import { ReportByShippingLine } from './report-detail/report-by-shipping-line';
import { ReportAllJob } from './report-detail/report-job';
import { ReportFuelByVendor } from './report-detail/report-fuel-by-vendor';
import { ReportTourByMonth } from './report-detail/report-tour-by-moth';
import { ReportTourByDate } from './report-detail/report-tour-by-date';
import { Settings } from './settings';
import { Companies } from './companies';
import { HeadCompanies } from './companies-head';
import { UsersManagement } from './users-management';
import { GoodsMaterialNotesOutbound } from './goods-material-notes-outbound';
import { TaskJobExtrasList } from './taskjob-extras-list';
import { OilPrices } from './oil-prices';
import { ReportOilSummary } from './report-oil-summary';
import { ReportOilKm } from './report-oil-km';
import { ReportOilFuelDistribution } from './report-oil-fuel-distribution';
import { VatsManagement } from './vats-management';
import { ExpensesManagement } from './expenses-management';
import { ReportOilFuelDistribution2 } from './report-oil-fuel-distribution-2';
// TaskJob Partner
import { TaskJobsByPartner } from './list-taskjobs-by-partner';
import { TaskJobsReceivedFromPartner } from './list-taskjobs-received-from-partner';
// Data Transfers
import { OrdersWaitingReceiveFromONP } from './orders-waiting-receive-from-onp';
import { OrdersWaitingReceiveFromWMS } from './orders-waiting-receive-from-wms';
import { OrdersRVSFromONP } from './orders-rvs-from-onp';
import { OrdersRVSFromWMS } from './orders-rvs-from-wms';
import { OrdersCancelFromONP } from './orders-cancel-from-onp';
import { OrdersCancelFromWms } from './orders-cancel-from-wms';
// tickets
import { CreateTicketJob } from './ticket-job-create';
import { CreateTicketJobForVendor } from './ticket-job-create-for-vendor';
import { CreateTicketJobByVendor } from './ticket-job-create-by-vendor';
import { UpdateTicketJob } from './ticket-job-update';
import { UpdateTicketJobForUnilog } from './ticket-job-update-for-unilog';
import { UpdateTicketJobVendor } from './ticket-job-update-vendor';
import { CreateTicketBill } from './ticket-bill-create';
import { UpdateTicketBill } from './ticket-bill-update';
import { CreateTicketBooking } from './ticket-booking-create';
import { UpdateTicketBooking } from './ticket-booking-update';
import { TicketDissolveLocks } from './ticket-dissolve-locks';
import { TicketsImportBookingBill } from './tickets-import-booking-bill';
//
import { SupplierBills } from './supplier-bills';
import { ManagementCosts } from './management-costs';
import { ManagementSalary } from './management-salary';
// Contracts
import { ContractService } from './contract-service';
import { ContractServiceForVendor } from './contract-service-for-vendor';
// GoodsMaterialNotesInbound
import { GoodsMaterialNotesInbound } from './goods-material-notes-inbound';
//GoodsMaterialNotesWarehouse
import { GoodsMaterialNotesWarehouse } from './goods-material-notes-warehouse';
// Report - Export Excel
import { ReportCustomerExpense } from './report-customer-expense';
import { ReportAccountantsCustomerExpenseBill } from './report-accountants-customer-expense-bill';
import { ReportAccountantsCustomerExpenseBillForUI } from './report-accountants-customer-expense-bill-for-uilog';
import { ReportAccountantsCustomerExpenseBillForVendor } from './report-accountants-customer-expense-bill-for-vendor';
import { AccountantsGoodExpenseCar } from './accountants-good-expense-car';
import { CarReplacementsHistory } from './car-replacements-history';
import { ReportAccountantsBookingContUnpaid } from './report-accountants-booking-cont-unpaid';
import { ReportAccountantsBills } from './report-accountants-bills';
import { AccountantsGoodExpense } from './accountants-good-expense';
import { AccountantsGoodExpenseDriver } from './accountants-good-expense-driver';
import { AccountantsPartnerExpense } from './accountants-partner-expense';
import { GoodsMaterialNotesOutboundHistory } from './goods-material-notes-outbound-history';
import { ReportAccountantsTransitExpense } from './report-accountants-transit-expense';
import { ReportContsDifferenceDepot } from './report-conts-difference-depot';
import { ReportDetailTour } from './report-detail-tour';
import { TaskJobDiary2 } from './task-job-diary-2';
import { TransitContPage } from './transit-cont-page';
import { LocationConfigs } from './location-configs';
import { AssignTransit } from './assign-transit';
import { AssignTransitPartner } from './assign-transit-partner';
import { TaskJobsTransitReceivedFromPartner } from './taskjobs-transit-received-from-partner';
import { ReportTransitCont } from './report-transit-cont';
import { AppUserAccount } from './app-user-account';
import { JobSkipTakePhoto } from './job-skip-take-photo';
import { LiftingFees } from './lifting-fees';
import { BookingContTracking } from './booking-cont-tracking';
import { BillContTracking } from './bill-cont-tracking';
import { OilCouponsOilInput } from './oilcoupons-oilinput';
import { OilCouponsOilTotal } from './oilcoupons-oiltotal';
import { OilCouponsOilCheck } from './oilcoupons-oilcheck';
//
import { BookingContPartnerList } from './booking-conts-partner-list';
import { BillContPartnerList } from './bill-conts-partner-list';
// auth
import { Menus } from './authorizations/menu';
import { Group } from './authorizations/user-groups';
import { Functions } from './authorizations/functions';
import { PERMISSION_CODES } from '~commons/constants';

const {
  RocketOutlined,
  CarOutlined,
  DollarOutlined,
  BankOutlined,
  UserOutlined,
  SettingOutlined,
  BookOutlined,
  HomeOutlined,
  BorderOutlined,
  LineChartOutlined,
  DeliveredProcedureOutlined,
} = antdIcons;
export const configPathWithComponent = new Map([
  [
    '/cars',
    {
      as: Cars,
      permission: PERMISSION_CODES.CAR_VIEW_ALL,
    },
  ],
  [
    '/romoocs',
    {
      as: Romoocs,
      permission: PERMISSION_CODES.ROMOOC_VIEW_ALL,
    },
  ],
  [
    '/drivers',
    {
      as: Drivers,
      permission: PERMISSION_CODES.DRIVER_VIEW_ALL,
    },
  ],
  [
    '/customers',
    {
      as: Customers,
      permission: PERMISSION_CODES.CUSTOMER_VIEW_ALL,
    },
  ],
  [
    '/location-types',
    {
      as: LocationTypes,
      permission: PERMISSION_CODES.LOCATION_TYPE_VIEW_ALL,
    },
  ],
  [
    '/locations',
    {
      as: Locations,
      permission: PERMISSION_CODES.LOCATION_VIEW_ALL,
    },
  ],
  [
    '/shipping-lines',
    {
      as: ShippingLines,
      permission: PERMISSION_CODES.SHIPPING_LINE_VIEW_ALL,
    },
  ],
  [
    '/ports',
    {
      as: Ports,
      permission: PERMISSION_CODES.PORT_VIEW_ALL,
    },
  ],
  [
    '/distances',
    {
      as: Distances,
      permission: PERMISSION_CODES.DISTANCE_VIEW_ALL,
    },
  ],
  [
    '/vendors',
    {
      as: Vendors,
      permission: PERMISSION_CODES.VENDOR_VIEW_ALL,
    },
  ],
  [
    '/cargo-types',
    {
      as: CargoTypes,
      permission: PERMISSION_CODES.CARGO_TYPE_VIEW_ALL,
    },
  ],
  [
    '/cont-types',
    {
      as: ContTypes,
      permission: PERMISSION_CODES.CONT_TYPE_VIEW_ALL,
    },
  ],
  [
    '/cont-search-number',
    {
      as: ContSearchNumber,
      permission: PERMISSION_CODES.CONT_SEARCH_VIEW_ALL,
    },
  ],
  [
    '/orders',
    {
      as: OrderList,
      permission: PERMISSION_CODES.ORDER_VIEW_ALL,
    },
  ],
  [
    '/bookings',
    {
      as: Booking,
      permission: PERMISSION_CODES.BOOKING_VIEW_ALL,
    },
  ],
  [
    '/bills',
    {
      as: Bill,
      permission: PERMISSION_CODES.BILL_VIEW_ALL,
    },
  ],
  [
    '/transits',
    {
      as: Transit,
      permission: PERMISSION_CODES.TRANSIT_VIEW_ALL,
    },
  ],
  [
    '/revised-logs',
    {
      as: RevisedLog,
      permission: PERMISSION_CODES.REVISE_LOG_VIEW_ALL,
    },
  ],
  //orders-cancel
  [
    '/orders-cancel',
    {
      as: OrdersCancel,
      permission: PERMISSION_CODES.ORDERS_CANCEL_VIEW_ALL,
    },
  ],
  [
    '/whiteboard',
    {
      as: WhiteBoard,
      permission: PERMISSION_CODES.WHITEBOARD_VIEW_ALL,
      hideLayout: true,
    },
  ],
  [
    '/white-board',
    {
      as: WhiteBoardNoBD,
      permission: PERMISSION_CODES.WHITE_BOARD_VIEW_ALL,
      hideLayout: true,
    },
  ],

  [
    '/task-job-diary',
    {
      as: TaskJobDiary,
      permission: PERMISSION_CODES.TASK_JOB_DIARY_VIEW_ALL,
    },
  ],
  [
    '/depot-conts-handle',
    {
      as: DepotContHandle,
      permission: PERMISSION_CODES.DEPORT_CONT_VIEW_ALL,
    },
  ],
  [
    '/booking-conts',
    {
      as: BookingCont,
      permission: PERMISSION_CODES.BOOKING_CONT_VIEW_ALL,
    },
  ],
  [
    '/bill-conts',
    {
      as: BillCont,
      permission: PERMISSION_CODES.BILL_CONT_VIEW_ALL,
    },
  ],
  [
    '/job-handle',
    {
      as: JobHandle,
      permission: PERMISSION_CODES.JOB_HANDLE_VIEW_ALL,
    },
  ],
  [
    '/assign-car',
    {
      as: AssignCar,
      permission: PERMISSION_CODES.ASSIGN_VIEW_ALL,
    },
  ],
  [
    '/print-oil',
    {
      as: PrintOil,
      permission: PERMISSION_CODES.PRINT_OIL_VIEW_ALL,
    },
  ],
  [
    '/units',
    {
      as: Units,
      permission: PERMISSION_CODES.UNIT_VIEW_ALL,
    },
  ],
  [
    '/materials',
    {
      as: Materials,
      permission: PERMISSION_CODES.MATERIAL_VIEW_ALL,
    },
  ],
  [
    '/material-types',
    {
      as: MaterialType,
      permission: PERMISSION_CODES.MATERIAL_TYPE_VIEW_ALL,
    },
  ],
  [
    '/garas',
    {
      as: Gara,
      permission: PERMISSION_CODES.GARA_VIEW_ALL,
    },
  ],
  [
    '/supplier-materials',
    {
      as: SupplierMaterial,
      permission: PERMISSION_CODES.SUPPLIER_MATERIAL_VIEW_ALL,
    },
  ],
  [
    '/replacement-categories',
    {
      as: ReplacementCategories,
      permission: PERMISSION_CODES.REPLACEMENT_CATEGORY_VIEW_ALL,
    },
  ],
  [
    '/areas',
    {
      as: Areas,
      permission: PERMISSION_CODES.AREA_VIEW_ALL,
    },
  ],
  [
    '/tires',
    {
      as: Tires,
      permission: PERMISSION_CODES.TIRE_VIEW_ALL,
    },
  ],
  [
    '/report-by-customer',
    {
      as: ReportByCustomer,
      permission: PERMISSION_CODES.REPORT_BY_CUSTOMER_VIEW_ALL,
    },
  ],
  [
    '/report-by-customer-1',
    {
      as: ReportByCustomer1,
      permission: PERMISSION_CODES.REPORT_BY_CUSTOMER_1_VIEW_ALL,
    },
  ],
  [
    '/report-by-customer-2',
    {
      as: ReportByCustomer2,
      permission: PERMISSION_CODES.REPORT_BY_CUSTOMER_2_VIEW_ALL,
    },
  ],
  [
    '/report-by-shipping-line',
    {
      as: ReportByShippingLine,
      permission: PERMISSION_CODES.REPORT_BY_SHIPPING_LINE_VIEW_ALL,
    },
  ],
  [
    '/report-by-job-detail',
    {
      as: ReportAllJob,
      permission: PERMISSION_CODES.REPORT_BY_JOB_DETAIL_VIEW_ALL,
    },
  ],
  [
    '/report-by-fuel',
    {
      as: ReportFuelByVendor,
      permission: PERMISSION_CODES.REPORT_BY_FUEL_VIEW_ALL,
    },
  ],
  [
    '/report-tour-by-month',
    {
      as: ReportTourByMonth,
      permission: PERMISSION_CODES.REPORT_TOUR_BY_MONTH_VIEW_ALL,
    },
  ],
  [
    '/report-tour-by-date',
    {
      as: ReportTourByDate,
      permission: PERMISSION_CODES.REPORT_TOUR_BY_DATE_VIEW_ALL,
    },
  ],
  [
    '/settings',
    {
      as: Settings,
      permission: PERMISSION_CODES.SETTING_VIEW_ALL,
    },
  ],
  [
    '/menus',
    {
      as: Menus,
      permission: PERMISSION_CODES.MENU_VIEW_ALL,
    },
  ],
  [
    '/groups',
    {
      as: Group,
      permission: PERMISSION_CODES.GROUP_VIEW_ALL,
    },
  ],
  [
    '/functions',
    {
      as: Functions,
      permission: PERMISSION_CODES.FUNCTION_VIEW_ALL,
    },
  ],
  [
    '/companies',
    {
      as: Companies,
      permission: PERMISSION_CODES.COMPANY_VIEW_ALL,
    },
  ],
  [
    '/head-company',
    {
      as: HeadCompanies,
      permission: PERMISSION_CODES.HEAD_COMPANY_VIEW_ALL,
    },
  ],
  [
    '/users-management',
    {
      as: UsersManagement,
      permission: PERMISSION_CODES.USER_MANAGEMENT_VIEW_ALL,
    },
  ],
  [
    '/taskjob-extras',
    {
      as: TaskJobExtrasList,
      permission: PERMISSION_CODES.TASKJOB_EXTRAS_VIEW_ALL,
    },
  ],
  [
    '/oil-prices',
    {
      as: OilPrices,
      permission: PERMISSION_CODES.OIL_PRICES_VIEW_ALL,
    },
  ],
  [
    '/report-oil-summary',
    {
      as: ReportOilSummary,
      permission: PERMISSION_CODES.REPORT_OIL_SUMMARY_VIEW_ALL,
    },
  ],
  [
    '/report-oil-km',
    {
      as: ReportOilKm,
      permission: PERMISSION_CODES.REPORT_OIL_KM_VIEW_ALL,
    },
  ],
  [
    '/report-oil-fuel-distribution',
    {
      as: ReportOilFuelDistribution,
      permission: PERMISSION_CODES.REPORT_OIL_FUEL_DISTRIBUTION_VIEW_ALL,
    },
  ],
  [
    '/vats-management',
    {
      as: VatsManagement,
      permission: PERMISSION_CODES.VATS_MANAGEMENT_VIEW_ALL,
    },
  ],
  [
    '/expenses-management',
    {
      as: ExpensesManagement,
      permission: PERMISSION_CODES.EXPENSES_MANAGEMENT_VIEW_ALL,
    },
  ],
  [
    '/report-oil-fuel-distribution-2',
    {
      as: ReportOilFuelDistribution2,
      permission: PERMISSION_CODES.REPORT_OIL_FUEL_DISTRIBUTION_2_VIEW_ALL,
    },
  ],
  // TaskJobs Partner
  [
    '/task-jobs-by-partner',
    {
      as: TaskJobsByPartner,
      permission: PERMISSION_CODES.TASK_JOBS_GET_LIST_BY_PARTNER_VIEW_ALL,
    },
  ],
  [
    '/task-job-received-from-partner',
    {
      as: TaskJobsReceivedFromPartner,
      permission: PERMISSION_CODES.TASK_JOBS_RECEIVED_FROM_PARTNER_VIEW_ALL,
    },
  ],
  // Orders Recieved
  [
    '/orders-waiting-receive-from-ONP',
    {
      as: OrdersWaitingReceiveFromONP,
      permission: PERMISSION_CODES.ORDERS_WAITING_RECEIVE_FROM_ONP_VIEW_ALL,
    },
  ],
  [
    '/orders-waiting-receive-from-WMS',
    {
      as: OrdersWaitingReceiveFromWMS,
      permission: PERMISSION_CODES.ORDERS_WAITING_RECEIVE_FROM_WMS_VIEW_ALL,
    },
  ],
  // Orders RVS
  [
    '/orders-rvs-from-ONP',
    {
      as: OrdersRVSFromONP,
      permission: PERMISSION_CODES.ORDERS_RVS_FROM_ONP_VIEW_ALL,
    },
  ],
  [
    '/orders-rvs-from-WMS',
    {
      as: OrdersRVSFromWMS,
      permission: PERMISSION_CODES.ORDERS_RVS_FROM_WMS_VIEW_ALL,
    },
  ],
  // Orders Cancel
  [
    '/orders-cancel-from-ONP',
    {
      as: OrdersCancelFromONP,
      permission: PERMISSION_CODES.ORDERS_CANCEL_FROM_ONP_VIEW_ALL,
    },
  ],
  [
    '/orders-cancel-from-WMS',
    {
      as: OrdersCancelFromWms,
      permission: PERMISSION_CODES.ORDERS_CANCEL_FROM_WMS_VIEW_ALL,
    },
  ],
  // Tickets
  [
    '/create-ticket-job',
    {
      as: CreateTicketJob,
      permission: PERMISSION_CODES.CREATE_TICKET_JOB_VIEW_ALL,
    },
  ],
  [
    '/create-ticket-job-for-vendor',
    {
      as: CreateTicketJobForVendor,
      permission: PERMISSION_CODES.CREATE_TICKET_JOB_FOR_VENDOR_VIEW_ALL,
    },
  ],
  [
    '/create-ticket-job-by-vendor',
    {
      as: CreateTicketJobByVendor,
      permission: PERMISSION_CODES.CREATE_TICKET_JOB_BY_VENDOR_VIEW_ALL,
    },
  ],
  [
    '/update-ticket-job',
    {
      as: UpdateTicketJob,
      permission: PERMISSION_CODES.UPDATE_TICKET_JOB_VIEW_ALL,
    },
  ],
  [
    '/update-ticket-job-for-UI',
    {
      as: UpdateTicketJobForUnilog,
      permission: PERMISSION_CODES.UPDATE_TICKET_JOB_FOR_UI_VIEW_ALL,
    },
  ],
  [
    '/update-ticket-job-vendor',
    {
      as: UpdateTicketJobVendor,
      permission: PERMISSION_CODES.UPDATE_TICKET_JOB_VENDOR_VIEW_ALL,
    },
  ],
  [
    '/create-ticket-bill',
    {
      as: CreateTicketBill,
      permission: PERMISSION_CODES.CREATE_TICKET_BILL_VIEW_ALL,
    },
  ],
  [
    '/update-ticket-bill',
    {
      as: UpdateTicketBill,
      permission: PERMISSION_CODES.UPDATE_TICKET_BILL_VIEW_ALL,
    },
  ],
  [
    '/create-ticket-booking',
    {
      as: CreateTicketBooking,
      permission: PERMISSION_CODES.CREATE_TICKET_BOOKING_VIEW_ALL,
    },
  ],
  [
    '/update-ticket-booking',
    {
      as: UpdateTicketBooking,
      permission: PERMISSION_CODES.UPDATE_TICKET_BOOKING_VIEW_ALL,
    },
  ],
  [
    '/ticket-dissolve-locks',
    {
      as: TicketDissolveLocks,
      permission: PERMISSION_CODES.DISSOLVE_LOCKS_VIEW_ALL,
    },
  ],
  [
    '/ticket-import-booking-bill',
    {
      as: TicketsImportBookingBill,
      permission: PERMISSION_CODES.TICKETS_IMPORT_BOOKING_BILL_VIEW_ALL,
    },
  ],
  [
    '/supplier-bills',
    {
      as: SupplierBills,
      permission: PERMISSION_CODES.SUPPLIER_BILLS_VIEW_ALL,
    },
  ],
  [
    '/management-costs',
    {
      as: ManagementCosts,
      permission: PERMISSION_CODES.MANAGEMENT_COSTS_VIEW_ALL,
    },
  ],
  [
    '/management-salary',
    {
      as: ManagementSalary,
      permission: PERMISSION_CODES.MANAGEMENT_SALARY_VIEW_ALL,
    },
  ],
  [
    '/contracts-service',
    {
      as: ContractService,
      permission: PERMISSION_CODES.CONTRACT_SERVICE_VIEW_ALL,
    },
  ],
  [
    '/contracts-service-for-vendor',
    {
      as: ContractServiceForVendor,
      permission: PERMISSION_CODES.CONTRACT_SERVICE_FOR_VENDOR_VIEW_ALL,
    },
  ],

  // GoodsMaterialNotesOutbound
  [
    '/goods-material-notes-outbound',
    {
      as: GoodsMaterialNotesOutbound,
      permission: PERMISSION_CODES.GOODS_MATERIAL_OUTBOUND_NOTES_VIEW_ALL,
    },
  ],
  // GoodsMaterialNotesInbound
  [
    '/goods-material-notes-inbound',
    {
      as: GoodsMaterialNotesInbound,
      permission: PERMISSION_CODES.GOODS_MATERIAL_NOTES_INBOUND_VIEW_ALL,
    },
  ],
  // GoodsMaterialNotesWarehouse
  [
    '/goods-material-notes-warehouse',
    {
      as: GoodsMaterialNotesWarehouse,
      permission: PERMISSION_CODES.GOODS_MATERIAL_NOTES_WAREHOUSE_VIEW_ALL,
    },
  ],
  // Report Accountants
  [
    '/accountants-customerexpense',
    {
      as: ReportCustomerExpense,
      permission: PERMISSION_CODES.ACCOUNTANTS_CUSTOMER_EXPENSE_VIEW_ALL,
    },
  ],
  [
    '/accountants-customer-expense-bill',
    {
      as: ReportAccountantsCustomerExpenseBill,
      permission: PERMISSION_CODES.ACCOUNTANTS_CUSTOMER_EXPENSE_BILL_VIEW_ALL,
    },
  ],
  [
    '/accountants-customer-expense-bill-for-UI',
    {
      as: ReportAccountantsCustomerExpenseBillForUI,
      permission: PERMISSION_CODES.ACCOUNTANTS_CUSTOMER_EXPENSE_BILL_FOR_UI_VIEW_ALL,
    },
  ],
  [
    '/accountants-customer-expense-bill-for-vendor',
    {
      as: ReportAccountantsCustomerExpenseBillForVendor,
      permission: PERMISSION_CODES.ACCOUNTANTS_CUSTOMER_EXPENSE_BILL_FOR_VENDOR_VIEW_ALL,
    },
  ],

  [
    '/accountants-good-expense-car',
    {
      as: AccountantsGoodExpenseCar,
      permission: PERMISSION_CODES.ACCOUNTANTS_GOOD_EXPENSE_CAR_VIEW_ALL,
    },
  ],
  [
    '/car-replacements-history',
    {
      as: CarReplacementsHistory,
      permission: PERMISSION_CODES.CAR_REPLACEMENTS_HISTORY_VIEW_ALL,
    },
  ],
  [
    '/accountants-bookingcontunpaid',
    {
      as: ReportAccountantsBookingContUnpaid,
      permission: PERMISSION_CODES.ACCOUNTANTS_BOOKING_CONT_UNPAID_VIEW_ALL,
    },
  ],
  [
    '/report-accountants-bills',
    {
      as: ReportAccountantsBills,
      permission: PERMISSION_CODES.REPORT_ACCOUNTANTS_BILLS_VIEW_ALL,
    },
  ],
  [
    '/accountants-good-expense',
    {
      as: AccountantsGoodExpense,
      permission: PERMISSION_CODES.ACCOUNTANTS_GOOD_EXPENSE_VIEW_ALL,
    },
  ],
  [
    '/accountants-goods-expense-driver',
    {
      as: AccountantsGoodExpenseDriver,
      permission: PERMISSION_CODES.ACCOUNTANTS_GOOD_EXPENSE_DRIVER_VIEW_ALL,
    },
  ],
  [
    '/accountants-partner-expense',
    {
      as: AccountantsPartnerExpense,
      permission: PERMISSION_CODES.ACCOUNTANTS_PARTNER_EXPENSE_VIEW_ALL,
    },
  ],
  [
    '/goods-material-notes-outbound-history',
    {
      as: GoodsMaterialNotesOutboundHistory,
      permission: PERMISSION_CODES.GOODS_MATERIAL_NOTES_OUTBOUND_HISTORY_VIEW_ALL,
    },
  ],
  [
    '/accountants-transit-expense',
    {
      as: ReportAccountantsTransitExpense,
      permission: PERMISSION_CODES.ACCOUNTANTS_TRANSIT_EXPENSE_VIEW_ALL,
    },
  ],
  [
    '/conts-difference-depot',
    {
      as: ReportContsDifferenceDepot,
      permission: PERMISSION_CODES.CONTS_DIFFERENCE_DEPOT_VIEW_ALL,
    },
  ],
  [
    '/report-detail-tour',
    {
      as: ReportDetailTour,
      permission: PERMISSION_CODES.REPORT_DETAIL_TOUR_VIEW_ALL,
    },
  ],
  [
    '/task-job-diary-2',
    {
      as: TaskJobDiary2,
      permission: PERMISSION_CODES.TASK_JOB_DIARY_2_VIEW_ALL,
    },
  ],

  [
    '/transit-cont-page',
    {
      as: TransitContPage,
      permission: PERMISSION_CODES.TRANSIT_CONT_PAGE_VIEW_ALL,
    },
  ],
  [
    '/location-configs',
    {
      as: LocationConfigs,
      permission: PERMISSION_CODES.LOCATION_CONFIGS_VIEW_ALL,
    },
  ],
  [
    '/assign-transit',
    {
      as: AssignTransit,
      permission: PERMISSION_CODES.ASSIGN_TRANSIT_VIEW_ALL,
    },
  ],
  [
    '/assign-transit-partner',
    {
      as: AssignTransitPartner,
      permission: PERMISSION_CODES.ASSIGN_TRANSIT_PARTNER_VIEW_ALL,
    },
  ],
  [
    '/task-job-transit-received-from-partner',
    {
      as: TaskJobsTransitReceivedFromPartner,
      permission: PERMISSION_CODES.TASK_JOB_TRANSIT_RECEIVED_FROM_PARTNER_VIEW_ALL,
    },
  ],
  [
    '/report-transit-cont',
    {
      as: ReportTransitCont,
      permission: PERMISSION_CODES.REPORT_TRANSIT_CONT_VIEW_ALL,
    },
  ],
  [
    '/app-user-account',
    {
      as: AppUserAccount,
      permission: PERMISSION_CODES.APP_USER_ACCOUNT_VIEW_ALL,
    },
  ],
  [
    '/job-skip-take-photo',
    {
      as: JobSkipTakePhoto,
      permission: PERMISSION_CODES.JOB_SKIP_TAKE_PHOTO_VIEW_ALL,
    },
  ],
  [
    '/lifting-fees',
    {
      as: LiftingFees,
      permission: PERMISSION_CODES.LIFTING_FEES_VIEW_ALL,
    },
  ],
  [
    '/booking-cont-tracking',
    {
      as: BookingContTracking,
      permission: PERMISSION_CODES.BOOKING_CONT_TRACKING_VIEW_ALL,
    },
  ],
  [
    '/bill-cont-tracking',
    {
      as: BillContTracking,
      permission: PERMISSION_CODES.BILL_CONT_TRACKING_VIEW_ALL,
    },
  ],
  [
    '/oil-coupons-oil-input',
    {
      as: OilCouponsOilInput,
      permission: PERMISSION_CODES.OIL_COUPONS_OIL_INPUT_VIEW_ALL,
    },
  ],
  [
    '/oil-coupons-oil-total',
    {
      as: OilCouponsOilTotal,
      permission: PERMISSION_CODES.OIL_COUPONS_OIL_TOTAL_VIEW_ALL,
    },
  ],
  [
    '/oil-coupons-oil-check',
    {
      as: OilCouponsOilCheck,
      permission: PERMISSION_CODES.OIL_COUPONS_OIL_CHECK_VIEW_ALL,
    },
  ],
  [
    '/booking-cont-partner-list',
    {
      as: BookingContPartnerList,
      permission: PERMISSION_CODES.BOOKING_CONT_PARTNER_LIST_VIEW_ALL,
    },
  ],
  [
    '/bill-cont-partner-list',
    {
      as: BillContPartnerList,
      permission: PERMISSION_CODES.BILL_CONT_PARTNER_LIST_VIEW_ALL,
    },
  ],
]);

export const ROUTE_ITEMS = [
  {
    title: 'Danh Mục Vận Tải',
    sub: 'sub1',
    icon: <RocketOutlined />,
    subData: [
      { to: '/cars', inner: 'Xe' },
      { to: '/romoocs', inner: 'Romooc' },
      { to: '/drivers', inner: 'Tài Xế' },
      { to: '/customers', inner: 'Khách Hàng' },
      { to: '/location-types', inner: 'Loại Địa Điểm' },
      { to: '/locations', inner: 'Địa Điểm' },
      { to: '/shipping-lines', inner: 'Hãng Tàu' },
      { to: '/ports', inner: 'Cảng Đến' },
      { to: '/distances', inner: 'Cự Ly' },
      { to: '/vendors', inner: 'Đội Xe Nội Bộ' },
      // {  to: '/task-types', inner: 'Loại Công Việc' },
      // {  to: '/task-lists', inner: 'List Công Việc' },
      { to: '/cargo-types', inner: 'Danh Mục Hàng Hóa' },
      { to: '/cont-types', inner: 'Loại Cont' },
      { to: '/cont-search-number', inner: 'Tìm Số Cont' },
    ],
  },
  {
    title: 'Quản Lý Đơn Hàng',
    sub: 'sub2',
    icon: <BookOutlined />,
    subData: [
      { to: '/orders', inner: 'Danh sách đơn' },
      { to: '/bookings', inner: 'Tạo booking' },
      { to: '/bills', inner: 'Tạo D/O' },
      { to: '/transits', inner: 'Tạo Đơn hàng nội địa' },
      { to: '/revised-logs', inner: 'Xem Revised trong ngày' },
      { to: '/orders-cancel', inner: 'ĐH hủy trong ngày' },
      { to: '/orders-waiting-receive-from-ONP', inner: 'ĐH chờ nhận từ ONP' },
      { to: '/orders-waiting-receive-from-WMS', inner: 'ĐH chờ nhận từ Kho' },
      { to: '/orders-rvs-from-ONP', inner: 'Booking RVS từ ONP' },
      { to: '/orders-rvs-from-WMS', inner: 'Booking RVS từ Kho' },
      { to: '/orders-cancel-from-ONP', inner: 'Booking Huỷ từ ONP' },
      { to: '/orders-cancel-from-WMS', inner: 'Booking Huỷ từ Kho' },
    ],
  },
  {
    icon: <BorderOutlined />,
    subData: { to: '/whiteboard', inner: 'Bảng Trắng', hideLayout: true },
  },
  {
    title: 'Điều Phối',
    sub: 'sub4',
    icon: <CarOutlined />,
    subData: [
      { to: '/task-job-diary', inner: 'Nhật Ký Hành Trình' },
      {
        to: '/depot-conts-handle',
        inner: 'Lấy Rỗng/Trả Rỗng Kế Hoạch',
      },
      { to: '/booking-conts', inner: 'Xử Lý Cont Xuất' },
      { to: '/bill-conts', inner: 'Xử Lý Cont Nhập' },
      { to: '/job-handle', inner: 'Xử Lý Công Việc' },
      { to: '/assign-car', inner: 'Lệnh Điều Xe' },
      { to: '/print-oil', inner: 'Phiếu Cấp Dầu' },
      { to: '/taskjob-extras', inner: 'Điều Xe Hỗ Trợ' },
      { to: '/task-jobs-by-partner', inner: 'DS Công việc xe ngoài' },
    ],
  },
  {
    title: 'Kho - Bảo Trì', // Kho - Bảo Trì Sửa Chữa
    sub: 'sub5',
    icon: <BankOutlined />,
    subData: [
      { to: '/units', inner: 'Đơn Vị Tính' },
      { to: '/materials', inner: 'Vật Tư' },
      { to: '/material-types', inner: 'Loại Vật Tư' },
      { to: '/garas', inner: 'Gara', as: Gara },
      { to: '/supplier-materials', inner: 'NCC Phụ Tùng' },
      { to: '/replacement-categories', inner: 'Hạng Mục Thay Thế' },
      { to: '/goods_material_notes', inner: 'DSSC Xe, Romooc' },
    ],
  },
  {
    title: 'Kế Toán',
    sub: 'sub6',
    icon: <DollarOutlined />,
    subData: [
      { to: '/areas', inner: 'Khu Vực' },
      { to: '/tires', inner: 'Vỏ Xe' },
      { to: '/print-oil', inner: 'Phiếu Cấp Dầu' },
      { to: '/oil-prices', inner: 'Giá Dầu' },
      { to: '/report-oil-summary', inner: 'Bảng Tổng Hợp Số Lít Dầu' },
      { to: '/report-oil-km', inner: 'Bảng Tổng Hợp Cự Ly Km Dầu' },
      { to: '/report-oil-fuel-distribution', inner: 'DS Cấp Phát Nhiên Liệu' },
      { to: '/report-oil-fuel-distribution-2', inner: 'DS Cấp Phát Nhiên Liệu 2' },
      { to: '/vats-management', inner: 'Danh mục VAT' },
      { to: '/expenses-management', inner: 'Danh mục chi phí' },
    ],
  },
  {
    title: 'Giải trừ',
    sub: 'tickets',
    icon: <DeliveredProcedureOutlined />,
    subData: [
      { to: '/create-ticket-job', inner: 'Giải trừ vận tải' },
      { to: '/create-ticket-job-for-vendor', inner: 'Giải trừ xe ngoài' },
      { to: '/update-ticket-job', inner: 'HC Giải trừ vận tải' },
      { to: '/create-ticket-bill', inner: 'Giải trừ Bill' },
      { to: '/update-ticket-bill', inner: 'HC Giải trừ Bill' },
      { to: '/create-ticket-booking', inner: 'Giải trừ Booking' },
      { to: '/update-ticket-booking', inner: 'HC Giải trừ Booking' },
      { to: '/ticket-dissolve-locks', inner: 'Khoá giải trừ' },
    ],
  },
  {
    title: 'Báo Cáo',
    sub: 'report',
    icon: <LineChartOutlined />,
    subData: [
      { to: '/report-by-customer', inner: 'Sản Lượng Theo Khách Hàng' },
      {
        to: '/report-by-shipping-line',
        inner: 'Báo Cáo Hãng tàu',
      },
      {
        to: '/report-by-job-detail',
        inner: 'Báo Cáo CV Chi tiết',
      },
      {
        to: '/report-by-fuel',
        inner: 'Báo Cáo Nhiên Liệu',
      },
      {
        to: '/report-tour-by-month',
        inner: 'Báo Cáo Tour Theo Tháng',
      },
      {
        to: '/report-tour-by-date',
        inner: 'Báo Cáo Tour Theo Ngày',
      },
    ],
  },
  {
    icon: <SettingOutlined />,
    subData: { to: '/settings', inner: 'Cài Đặt' },
  },
  {
    icon: <HomeOutlined />,
    title: 'Phân Quyền User',
    sub: 'author',
    subData: [
      { to: '/menus', inner: 'Menu' },
      { to: '/groups', inner: 'Nhóm Quyền' },
      { to: '/functions', inner: 'Chức năng' },
    ],
  },
  {
    icon: <HomeOutlined />,
    subData: { to: '/companies', inner: 'Công Ty' },
  },
  {
    icon: <UserOutlined />,
    subData: { to: '/users-management', inner: 'Quản Lý Người Dùng' },
  },
];

const getFormattedRouters = (routeItems = ROUTE_ITEMS) => {
  return routeItems.map((item) => {
    if (item.subData?.length) {
      return item.subData.map((route) => {
        const component = configPathWithComponent.get(route.to);
        return (
          component && (
            <PrivateRoute
              key={route.to}
              component={component.as}
              path={route.to}
              hideLayout={route.hideLayout}
            />
          )
        );
      });
    }
    const largeComponent = configPathWithComponent.get(item.subData?.to);
    return (
      largeComponent && (
        <PrivateRoute
          key={item.subData.to}
          component={largeComponent.as}
          path={item.subData.to}
          hideLayout={largeComponent?.hideLayout}
        />
      )
    );
  });
};
export const getAllRoutes = () => {
  let menus = ROUTE_ITEMS;
  if (!storage.user?.isSupper()) {
    menus = storage.menus;
  }
  return getFormattedRouters(menus);
};

export const PrivateRoute = ({
  path = '',
  isNotUseLeftMenu = false,
  isNotUseMenu = false,
  exact = true,
  component = null,
  hideLayout = false,
  ...rest
}) => {
  const getElement = (props) => {
    const content = createElement(component, props);
    return !hideLayout ? (
      <Layout component={content} isNotUseLeftMenu={isNotUseLeftMenu} isNotUseMenu={isNotUseMenu} />
    ) : (
      content
    );
  };
  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      render={(props) =>
        storage.isLoggedIn() ? (
          getElement(props)
        ) : (
          <Redirect
            push
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};
