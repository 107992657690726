import actionTypes from './type';

const initialState = {
  // waiting receive
  orderWaitingReceiveONP: [],
  countOrderWaitingReceiveONP: 0,
  orderWaitingReceiveWMS: [],
  countOrderWaitingReceiveWMS: 0,
  // Revised
  orderRevisedONP: [],
  countOrderRevisedONP: 0,
  orderRevisedWMS: [],
  countOrderRevisedWMS: 0,
  // Cancel
  orderCancelONP: [],
  countOrderCancelONP: 0,
  orderCancelWMS: [],
  countOrderCancelWMS: 0,
  // Rvs by TMS
  orderRvsByTmsNotReadList: [],
  countOrderRvsByTmsNotRead: 0,
  // Create by TMS
  orderCreateByTmsNotReadList: [],
  countOrderCreateByTmsNotRead: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // waiting receive
    case actionTypes.FETCH_ORDERS_WAITING_RECEIVE_ONP_DATA:
      return {
        ...state,
        orderWaitingReceiveONP: action.data,
        countOrderWaitingReceiveONP: action?.data?.length,
      };
    case actionTypes.FETCH_ORDERS_WAITING_RECEIVE_WMS_DATA:
      return {
        ...state,
        orderWaitingReceiveWMS: action.data,
        countOrderWaitingReceiveWMS: action?.data?.length,
      };
    // Revised
    case actionTypes.FETCH_ORDERS_REVISED_ONP_DATA:
      return {
        ...state,
        orderRevisedONP: action.data,
        countOrderRevisedONP: action?.data?.length,
      };
    case actionTypes.FETCH_ORDERS_REVISED_WMS_DATA:
      return {
        ...state,
        orderRevisedWMS: action.data,
        countOrderRevisedWMS: action?.data?.length,
      };
    // Cancel
    case actionTypes.FETCH_ORDERS_CANCEL_ONP_DATA:
      return {
        ...state,
        orderCancelONP: action.data,
        countOrderCancelONP: action?.data?.length,
      };
    case actionTypes.FETCH_ORDERS_CANCEL_WMS_DATA:
      return {
        ...state,
        orderCancelWMS: action.data,
        countOrderCancelWMS: action?.data?.length,
      };
    case actionTypes.FETCH_ORDERS_RVS_BY_TMS_NOT_READ:
      return {
        ...state,
        orderRvsByTmsNotReadList: action.data,
        countOrderRvsByTmsNotRead: action?.data?.length,
      };
    case actionTypes.FETCH_ORDERS_CREATE_BY_TMS_NOT_READ:
      return {
        ...state,
        orderCreateByTmsNotReadList: action.data,
        countOrderCreateByTmsNotRead: action?.data?.length,
      };
    default:
      return state;
  }
}
