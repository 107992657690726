import React, { useEffect, forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Input, message, Button } from 'antd';
import ApiServices from '~services';
import { formatFields } from './fields-data';
import { UploadFile } from '~components/upload-file';
import { TASK_STATUSES } from '~commons/constants';

const defaultFieldData = formatFields({});
export const FormInput = forwardRef(({ dataInput, reload, onClose }, ref) => {
  const [fields, setFields] = useState(defaultFieldData);
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    submitForm() {
      form.submit();
    },
  }));

  useEffect(() => {
    form.resetFields();
    if (!dataInput) {
      setFields(defaultFieldData);
    } else {
      setFields(formatFields(dataInput));
    }
  }, [dataInput?.subnoNumber]);

  const onSubmitForm = (value) => {
    const formData = new FormData();
    formData.append('jobsId', dataInput.jobId?.[0]);
    formData.append('jobsId', dataInput.jobId?.[1]);
    formData.append('updateValue', TASK_STATUSES.FINISH);
    formData.append('contNumber', value.contNumber);
    formData.append('sealNumber', value.sealNumber);
    if (value.files?.length) {
      for (let file of value.files) {
        formData.append('files', file.originFileObj);
      }
    }

    return ApiServices.completeJobsFromPartner(formData)
      .then(() => {
        reload?.();
        onClose?.();
        message.success('Thao tác thành công!!!');
      })
      .catch((err) => {
        console.error('Complete Jobs From Partner Error', err);
        message.error('Thao tác thất bại');
      });
  };
  const onFieldsChanged = (_, allFields) => {
    setFields(allFields);
  };

  return (
    <>
      <Form
        labelCol={{
          span: 6,
        }}
        // labelAlign='left'
        form={form}
        layout='horizontal'
        name='global_state'
        className='form-job-handle'
        onFinish={onSubmitForm}
        fields={fields}
        onFieldsChange={onFieldsChanged}
      >
        <Form.Item name='subnoNumber' label='Số SubNo'>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name='contNumber'
          label='Số Cont'
          rules={[
            {
              required: true,
              message: 'Bạn phải nhập Số Cont',
            },
          ]}
        >
          <Input placeholder='Số cont' />
        </Form.Item>
        <Form.Item name='sealNumber' label='Số Seal'>
          <Input placeholder='Số Seal' />
        </Form.Item>
        <Form.Item name='note' label='Ghi chú'>
          <Input.TextArea rows={3} placeholder='Ghi chú' />
        </Form.Item>
        <Form.Item name='files' label='File đính kèm'>
          <UploadFile />
        </Form.Item>
        <Form.Item label='Thao tác'>
          <Button type='primary' htmlType='submit'>
            Hoàn Thành
          </Button>
        </Form.Item>
      </Form>
    </>
  );
});
