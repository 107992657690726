import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, message, DatePicker, Select, Row, Col } from 'antd';
import { layout, tailLayout } from './constant';
import { DATE_FORMAT } from '~commons/constants'; //
import ApiServices from '~services';
import { Selection } from '~components';

const { Option } = Select;

const RomoocForm = ({
  fields,
  isNew,
  id,
  reload,
  onChange,
  setLoading,
  idLocationCurrent,
  nameLocationCurrent,
  dataSelected,
}) => {
  const inputRef = useRef(null);
  const [staticRomoocType, setStaticRomoocType] = useState([]);
  const [staticRomoocOwner, setStaticRomoocOwner] = useState([]);
  const [locations, setLocations] = useState([]);
  const [contTypes, setContTypes] = useState([]);
  const [companies, setCompanies] = useState([]);

  const getDataInit = async () => {
    const [getStaticVariable] = await Promise.all([ApiServices.getStaticVariables()]);
    setStaticRomoocType(getStaticVariable.data.RomoocType);
    setStaticRomoocOwner(getStaticVariable.data.RomoocOwner);
    setContTypes(getStaticVariable.data.RomoocKind);
  };
  const getCompanies = async (query = '') => {
    const newQuery = {
      Columns: {
        name: query,
      },
    };
    try {
      const res = await ApiServices.getCompanies(query ? newQuery : null);
      if (res?.data?.data) {
        setCompanies(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataInit();
    getCompanies();
  }, []);

  const getLocations = async (Query = null) => {
    try {
      let { data } = await ApiServices.getLocations({ Query });
      const dataNew = data.data;
      if (idLocationCurrent && Query === null) {
        const ind = dataNew.findIndex((item) => item.id === idLocationCurrent);
        if (ind === -1) {
          dataNew.push({ id: idLocationCurrent, name: nameLocationCurrent });
        }
      }
      setLocations(dataNew);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocations();
  }, [idLocationCurrent]);

  const onCompareWithApi = (value, cb) => {
    if (inputRef.current) {
      clearTimeout(inputRef.current);
      cb();
    }
    inputRef.current = setTimeout(async () => {
      try {
        const { data } = await ApiServices.existsCodeRomooc(null, value);
        const isUsed = data;
        cb(isUsed);
      } catch (error) {
        console.error(error);
      }
    }, 1000);
  };

  const checkExistsCode = async (_, value) => {
    if (value.length > 1) {
      const ind = fields.findIndex((item) => {
        return item.name[0] === 'code';
      });
      if (isNew === false && value !== fields[ind].value) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Mã Romooc này đã tạo rồi !!!'));
        }
      }

      if (isNew === true) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Mã Romooc này đã tạo rồi !!!'));
        }
      }
    }
  };

  const createRomooc = async (data) => {
    try {
      const res = await ApiServices.createRomooc(data);
      if (res.data) {
        message.success('Thêm mới Romooc thành công');
        return reload();
      } else {
        message.error('Thêm mới Romooc thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới Romooc thất baị, vui lòng kiểm tra lại thông tin');
    }
  };
  const updateRomooc = async (data) => {
    data.id = id;
    try {
      const res = await ApiServices.updateRomooc(id, data);
      if (res.data) {
        message.success('Cập nhật Romooc thành công');
        return reload();
      } else {
        message.error('Cập nhật Romooc thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật Romooc thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createRomooc(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updateRomooc(value);
    setLoading(false);
  };

  return (
    <Form
      {...layout}
      className='form-input-data'
      name='global_state'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            // {...itemLayout}
            style={{ marginTop: '10px' }}
            name='code'
            label='Mã Số'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Mã Số',
              },
              {
                validator: checkExistsCode,
              },
              {
                type: 'string',
                min: 2,
                message: 'Bạn phải nhập ít nhất 2 ký tự',
              },
            ]}
          >
            <Input placeholder='Mã Số' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            style={{ marginTop: '10px' }}
            name='name'
            label='Số Romooc'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Số Romooc',
              },
            ]}
          >
            <Input placeholder='Số Romooc' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='companyId'
            label='Công Ty'
            rules={[
              {
                required: true,
                message: 'Bạn phải chọn Công Ty',
              },
            ]}
          >
            <Selection
              labelDefault={dataSelected?.companyName || ''}
              data={companies}
              placeholder='Bạn phải chọn Công Ty'
              fieldValue='name'
              onSearch={getCompanies}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='label'
            label='Nhãn Hiệu'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Nhãn Hiệu',
              },
            ]}
          >
            <Input placeholder='Nhãn Hiệu' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='typeRomooc'
            label='Loại Hình'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Loại Hình',
              },
            ]}
          >
            <Select showSearch placeholder='Loại Hình'>
              {Object.keys(staticRomoocType).map((item, index) => (
                <Option key={index} value={staticRomoocType[item]}>
                  {staticRomoocType[item]}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='typeContCode'
            label='Chủng Loại'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Chủng Loại',
              },
            ]}
          >
            <Select showSearch placeholder='Chủng Loại'>
              {Object.keys(contTypes).map((item, index) => (
                <Option key={index} value={contTypes[item]}>
                  {contTypes[item]}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='madeIn' label='Nơi Sản Xuất'>
            <Input placeholder='Nơi Sản Xuất' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='loads'
            label='Tải Trọng'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Tải Trọng',
              },
            ]}
          >
            <Input placeholder='Tải Trọng' type='number' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='weight'
            label='Trọng Lượng'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Trọng Lượng',
              },
            ]}
            type='number'
          >
            <Input placeholder='Trọng Lượng' type='number' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='frameNumber'
            label='Số Khung'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Số Khung',
              },
            ]}
          >
            <Input placeholder='Số Khung' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='inspectionDate' label='Ngày Kiểm Định'>
            <DatePicker
              placeholder='Ngày Kiểm Định'
              style={{ width: '100%' }}
              format={DATE_FORMAT}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='inspectionNumber' label='Số Kiểm Định'>
            <Input placeholder='Số Kiểm Định' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='inspectionDeadline' label='TH Kiểm Định'>
            <DatePicker placeholder='TH Kiểm Định' style={{ width: '100%' }} format={DATE_FORMAT} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='inspectionCost' label='Chi Phí Kiểm Định'>
            <Input placeholder='Chi Phí Kiểm Định' type='number' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='otherInfo' label='Thông Số Khác'>
            <Input.TextArea placeholder='Thông Số Khác' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='currentLocationCode'
            label='Đ.Điểm Hiện Tại'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Địa Điểm Hiện Tại',
              },
            ]}
          >
            <Selection
              data={locations}
              placeholder='Địa Điểm Hiện Tại'
              fieldValue='name'
              onSearch={async (Query) => {
                const { data } = await ApiServices.getLocations({ Query });
                setLocations(data.data);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='contNumber' label='Số Cont'>
            <Input placeholder='Số Cont' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='price' label='Giá'>
            <Input placeholder='Giá' type='number' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='useBeginDate' label='Ngày Sử Dụng'>
            <DatePicker placeholder='Ngày Sử Dụng' style={{ width: '100%' }} format={DATE_FORMAT} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='depreciation' label='Số Tháng KH'>
            <Input placeholder='Số Tháng Khấu Hao' type='number' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='warrantyExpenses' label='Phí BH Mooc'>
            <Input placeholder='Phí Bảo Hành Mooc' type='number' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='ownerRomooc' label='Sở Hữu'>
            <Select showSearch placeholder='Sở Hữu'>
              {Object.keys(staticRomoocOwner).map((item, index) => (
                <Option key={index} value={staticRomoocOwner[item]}>
                  {staticRomoocOwner[item]}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={20}>
          <Form.Item name='note' label='Ghi Chú'>
            <Input.TextArea placeholder='Ghi Chú' />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item {...tailLayout} className='submit-form-input-data'>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { RomoocForm };
