import React, { useState, useEffect } from 'react';
import { Space, Button, Form, Input, Row, Col, Tabs, Popconfirm } from 'antd';
import { Selection } from '~components';
import ApiServices from '~services';
import { tailLayout } from './constant';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';
// fee
import { TransportFee } from '../transport-fee';
import { TransitFee } from '../transit-fee';
import { RomoocFee } from '../romooc-fee';
//
const { TextArea } = Input;

const CusItemUpdateForm = ({
  dataItem,
  contTypeList,
  handleDeleteContractCus,
  detailInfo,
  getDetailByCode,
  keyTab,
}) => {
  const [locations, setLocations] = useState([]);
  const [locationSelected, setLocationSelected] = useState([]);
  const [note, setNote] = useState('');
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  // Data Fee Transport
  const [dataFeeMainTransport, setDataFeeMainTransport] = useState([]);
  // Data Fee Transit
  const [dataFeeMainTransit, setDataFeeMainTransit] = useState([]);
  // Data Fee Romooc
  const [dataFeeRomooc, setDataFeeRomooc] = useState([]);
  const [locationPackingList, setLocationPackingList] = useState([]);

  const getLocations = async (Query = null) => {
    try {
      let { data } = await ApiServices.getLocations({ Query });
      if (data?.data) {
        let dataLocations = data?.data;
        if (dataItem && dataItem?.locations?.length) {
          const listId = data?.data?.map((item) => item.id);
          dataItem?.locations?.map((item) => {
            if (!listId.includes(item.id)) {
              dataLocations.unshift(item);
            }
          });
        }
        setLocations(dataLocations);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocations();
  }, [keyTab, dataItem]);

  useEffect(() => {
    // Transport Fee
    if (dataItem && dataItem?.transport?.length > 0) {
      const dataMapFeeMainTransport = dataItem?.transport?.map((item) => {
        item?.fees?.map((el) => {
          item[el.contTypeId] = el.fee;
        });
        if (item?.locations?.length > 0) {
          item.locationFromName = item?.locations[0]?.name;
          item.locationToName = item?.locations[1]?.name;
        }
        return item;
      });
      setDataFeeMainTransport(dataMapFeeMainTransport);
    } else {
      setDataFeeMainTransport([]);
    }
    // Transit Fee
    if (dataItem && dataItem?.transit?.length > 0) {
      const dataMapFeeMainTransit = dataItem?.transit?.map((item) => {
        item?.fees?.map((el) => {
          item[el.contTypeId] = el.fee;
        });
        if (item?.locations?.length > 0) {
          item.locationFromName = item?.locations[0]?.name;
          item.locationToName = item?.locations[1]?.name;
        }
        return item;
      });
      setDataFeeMainTransit(dataMapFeeMainTransit);
    } else {
      setDataFeeMainTransit([]);
    }
    // Romooc Fee
    if (dataItem && dataItem?.romoocs?.length > 0) {
      setDataFeeRomooc(dataItem?.romoocs);
    } else {
      setDataFeeRomooc([]);
    }
    // locations nơi đóng hàng
    if (dataItem && dataItem?.locations?.length > 0) {
      const arrLocationsDetail = dataItem?.locations?.map((el) => el.id);
      setLocationPackingList(dataItem?.locations);
      setLocationSelected(arrLocationsDetail);
    } else {
      setLocationPackingList([]);
      setLocationSelected([]);
    }

    if (dataItem?.note) {
      setNote(dataItem?.note);
    }
  }, [dataItem, keyTab]);

  ///////////// --- UPDATE ------ /////////////////
  const handleCreate = async (newDataItem, newDetailInfo, newLocationSelected, newNote) => {
    if (loadingUpdate) return;
    if (!newLocationSelected?.length) {
      window.TmsNoti.warning('Thông báo', 'Vui lòng nhập đầy đủ thông tin yêu cầu!!!');
      return;
    }

    const contractIdUpdate = newDataItem?.contractId;

    const transportData = dataFeeMainTransport?.map((item) => {
      let dataTransport = {
        distanceId: item?.distanceId,
        fees: item?.fees,
        contractId: contractIdUpdate,
      };
      if (item?.id) {
        dataTransport.id = item.id;
        // dataTransport.contractId = item.contractId;
      }
      return dataTransport;
    });
    const transitData = dataFeeMainTransit?.map((item) => {
      let dataTransit = {
        distanceId: item?.distanceId,
        fees: item?.fees,
        contractId: contractIdUpdate,
      };
      if (item?.id) {
        dataTransit.id = item.id;
        // dataTransit.contractId = item.contractId;
      }
      return dataTransit;
    });
    const romoocData = dataFeeRomooc?.map((item) => {
      let dataRomooc = {
        locationId: item?.locationId,
        price: item?.price,
        freeDate: item?.freeDate,
        contractId: contractIdUpdate,
      };
      if (item?.id) {
        dataRomooc.id = item.id;
      }
      return dataRomooc;
    });

    const dataSend = {
      contractId: newDataItem?.contractId,
      contractNumber: newDataItem?.contractNumber,
      companyId: newDataItem?.companyId,
      customerId: newDataItem?.customerId,
      customerName: newDataItem?.customerName,
      contractDate:
        newDataItem?.contractDate && moment(newDataItem?.contractDate).format('YYYY-MM-DD'),
      expiredDate:
        newDataItem?.expiredDate && moment(newDataItem?.expiredDate).format('YYYY-MM-DD'),
      renewExpiredDate: null,
      locations: newDataItem.locations,
      arrLocations: newLocationSelected,
      note: newNote,
      partnerId: newDetailInfo?.partnerId,
      transport: transportData?.length > 0 ? transportData : null,
      transit: transitData?.length > 0 ? transitData : null,
      romoocs: romoocData?.length > 0 ? romoocData : null,
    };
    setLoadingUpdate(true);

    try {
      const res = await ApiServices.updateContractForVendor(contractIdUpdate, dataSend);
      if (res?.data?.data) {
        window.TmsNoti.success('Cập nhật Hợp đồng thành công');
        return getDetailByCode(newDataItem?.contractNumber);
      }
    } catch (error) {
      console.log('Update Contract Error:::', error);
      window.TmsNoti.error('Lỗi', 'Cập nhật Hợp đồng thất bại');
    } finally {
      setTimeout(() => {
        setLoadingUpdate(false);
      }, 800);
    }
  };
  ///////////// --- UPDATE ------ /////////////////

  function handleChangeNote(event) {
    const text = event?.target?.value || '';
    setNote(text);
  }
  const handleChangeSelectedLocation = (value, valueAll) => {
    const mapValueAll = valueAll?.map((item) => {
      return {
        id: item.value,
        name: item.children,
      };
    });
    setLocationSelected(value);
    setLocationPackingList(mapValueAll);
  };

  // ======    reloadAfterUpdate
  const reloadAfterUpdate = async () => {
    return getDetailByCode(dataItem?.contractNumber);
  };

  const items = [
    {
      key: '1',
      label: `Phí vận chuyển`,
      children: (
        <TransportFee
          contTypeList={contTypeList}
          dataFeeMainTransport={dataFeeMainTransport}
          setDataFeeMainTransport={setDataFeeMainTransport}
          locationSelected={locationSelected}
          isPartner
          reloadAfterUpdate={reloadAfterUpdate}
          contractStatus={dataItem?.status ? dataItem?.status : 0}
          isNew={false}
          valueDetail={dataItem}
        />
      ),
    },
    {
      key: '2',
      label: `Phí chuyển kho`,
      children: (
        <TransitFee
          contTypeList={contTypeList}
          dataFeeMainTransit={dataFeeMainTransit}
          setDataFeeMainTransit={setDataFeeMainTransit}
          locationSelected={locationSelected}
          isPartner
          reloadAfterUpdate={reloadAfterUpdate}
          // contractStatus={dataItem?.status ? dataItem?.status : 0}
          isNew={false}
          valueDetail={dataItem}
        />
      ),
    },
  ];
  const onChangeTabs = (key) => {
    console.log(key);
  };

  return (
    <div
      style={{
        backgroundColor: 'rgb(234, 238, 243)',
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        borderRadius: '10px',
        fontSize: '12px',
        padding: '10px',
        marginBottom: '20px',
        position: 'relative',
      }}
    >
      <div
        style={{
          width: '30px',
          height: '30px',
          position: 'absolute',
          top: 0,
          right: 0,
          transform: 'translateY(-35%)',
          borderRadius: '50%',
        }}
      >
        <Popconfirm
          title={`Xóa hợp đồng với khách hàng: ${dataItem?.customerName}?`}
          okText='Có'
          cancelText='Không'
          onConfirm={() => handleDeleteContractCus(dataItem?.contractNumber, dataItem?.contractId)}
        >
          <Button type='primary' shape='circle' danger icon={<CloseOutlined />} />
        </Popconfirm>
      </div>
      <Row gutter={16}>
        <Col span={12}>
          <b className='red'>*</b> Nơi đóng hàng
          <Selection
            data={locations}
            placeholder='Nơi đóng hàng'
            fieldValue='name'
            onSearch={getLocations}
            mode='multiple'
            value={locationSelected}
            onChange={handleChangeSelectedLocation}
          />
        </Col>
        <Col span={12}>
          <b>Ghi chú</b>
          <TextArea rows={2} value={note} onChange={handleChangeNote} />
        </Col>
      </Row>

      <Tabs defaultActiveKey='1' items={items} onChange={onChangeTabs} />
      {/* Romooc Fee */}
      <RomoocFee
        dataFeeRomooc={dataFeeRomooc}
        setDataFeeRomooc={setDataFeeRomooc}
        locationSelected={locationSelected}
        locationPackingList={locationPackingList}
        isPartner
        reloadAfterUpdate={reloadAfterUpdate}
        // contractStatus={dataItem?.status ? dataItem?.status : 0}
        isNew={false}
        valueDetail={dataItem}
      />

      <Form.Item
        {...tailLayout}
        className='submit-form-input-data'
        style={{
          marginTop: '30px',
        }}
      >
        <Space size='large'>
          <Button
            type='primary'
            style={{ padding: '0 40px' }}
            loading={loadingUpdate}
            onClick={() => handleCreate(dataItem, detailInfo, locationSelected, note)}
          >
            Cập nhật
          </Button>
        </Space>
      </Form.Item>
    </div>
  );
};

export { CusItemUpdateForm };
