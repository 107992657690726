import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import { Marker } from './markers';

class MapMarker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: '',
      longitude: '',
    };
  }

  // componentDidMount() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       // const pos = {
  //       //   lat: position.coords.latitude,
  //       //   lng: position.coords.longitude,
  //       // };
  //       // console.log(pos);
  //     });
  //   }
  //   // else {
  //   //   // Browser doesn' support Geolocation
  //   // }
  // }

  static defaultProps = {
    center: {
      lat: 10.810153461557944,
      lng: 106.67789345383305,
    },
    zoom: 20,
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
          // bootstrapURLKeys={{ key: "AIzaSyDu0iMFKamdu6Op7LHxlzle4i60oo_hDAU" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <Marker
            lat={10.810153461557944}
            lng={106.67789345383305}
            text='Nơi làm việc'
            title='Nơi làm việc'
            description='Chung cư 675 Nguyễn Kiệm'
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export { MapMarker };
