import { useEffect, useState, useCallback } from 'react';
import { Divider, Button, Space, Table } from 'antd';
import moment from 'moment';
import ApiServices from '~services';
import { COLUMNS } from './constants';
import { ReportHeader, RangeDateFilter, Selection } from '~components';
import { debounce } from 'lodash';
import { DATE_FORMAT_SEND_API } from '~commons/constants';
import { DownloadOutlined } from '@ant-design/icons';
const ExcelJS = require('exceljs');

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};

export const ReportTourByDate = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [carList, setCarList] = useState([]);
  const [carId, setCarId] = useState('all');
  const [carCode, setCarCode] = useState('Tất Cả');
  const [loadingExport, setLoadingExport] = useState(false);

  const getCars = async (query = '') => {
    const dataSend = JSON.stringify({ code: query });
    if (loading) return;
    setLoading(true);
    try {
      let { data } = await ApiServices.getCars({ Columns: dataSend });
      if (data?.data) {
        const dataSetDefault = [
          {
            id: 'all',
            code: 'Tất Cả',
          },
        ];
        const dataGetFromApi = data?.data || [];
        const dataEnd = dataSetDefault?.concat(dataGetFromApi);
        setCarList(dataEnd);
      }
    } catch (error) {
      console.log('Get car list failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const getData = async (query = DEFAULT_QUERIES, carIdSelected = '') => {
    setLoading(true);
    if (!query.DateFrom || !query.DateTo) return;
    const dataFrom = moment(query.DateFrom).format(DATE_FORMAT_SEND_API);
    const dataTo = moment(query.DateTo).format(DATE_FORMAT_SEND_API);

    const newQuery = {
      DateFrom: dataFrom,
      DateTo: dataTo,
      carId: carIdSelected === 'all' ? '' : carIdSelected,
    };
    try {
      const { data } = await ApiServices.getReportTourByDate(newQuery);
      const tmpData = Object.keys(data?.data).map((key, index) => {
        const value = data?.data?.[key];
        const dataDateFromKey = key?.split('/');
        const dateFormat = `${dataDateFromKey[2]}-${dataDateFromKey[1]}-${dataDateFromKey[0]}`;
        return {
          date: key,
          dateExport: dateFormat && moment(dateFormat).format('YYYY/MM/DD'),
          index: index + 1,
          ...value,
          total: (value?.totalUp + value?.totalDown) / 2,
        };
      });
      setData(tmpData);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCars();
  }, []);

  const timeoutGetData = useCallback(
    debounce((newFilter, newCarId) => {
      getData(newFilter, newCarId);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter, carId);
  }, [filter, carId]);

  const handleChangeCar = (value) => {
    if (!value) {
      setCarId('all');
      setCarCode('Tất Cả');
      return;
    }
    const finCarCode = carList.find((item) => item.id === value);
    if (finCarCode) {
      setCarCode(finCarCode.code);
    }
    setCarId(value);
  };

  // Export excel
  const handleExportExcel = async (dataExport, filterDate, carCodeLabel) => {
    if (loadingExport) return;
    setLoadingExport(true);

    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet(carCodeLabel, {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 15;
    sheetSummary.properties.defaultColWidth = 15;
    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getCell('E2').value = 'BÁO CÁO TOUR THEO NGÀY';

    sheetSummary.getRow(3).font = {
      name: 'Arial',
      family: 2,
      size: 10,
      italic: true,
    };
    const date = `Ngày điều xe: Từ Ngày ${moment(filterDate?.DateFrom).format(
      'DD/MM/YYYY'
    )} -> ${moment(filterDate?.DateTo).format('DD/MM/YYYY')}`;
    sheetSummary.getCell('D3').value = date;
    sheetSummary.getRow(4).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(4).font = {
      name: 'Arial',
      family: 2,
      size: 11,
      bold: true,
    };
    sheetSummary.getCell('E4').value = carCodeLabel ? `Xe: ${carCodeLabel}` : '';

    // Add table
    const arrDetail = await dataExport?.map((item) => {
      console.log({ date: item.dateExport });
      const dataItem = [
        item.index, // STT
        item?.dateExport && moment(item.dateExport).format('YYYY-MM-DD'), // Ngay DIeu Xe
        item?.totalUp, // Lay
        item?.totalDown, // Ha
        item?.total, // Số Tour
        item?.totalTransfer, // So Tour Chuyen Kho
      ];
      return dataItem;
    });

    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B5',
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Ngày Điều Xe', totalsRowLabel: '', filterButton: false },
        {
          name: 'Lấy',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Hạ',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Số Tour',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Số Tour Chuyển Kho',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
      ],
      rows: arrDetail,
    });
    // ========== STYLES =====================
    sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };
    for (let i = 4; i <= 7; i++) {
      sheetSummary.getColumn(i).numFmt = '#,##0';
      sheetSummary.getColumn(i).width = i === 7 ? 18 : 15;
    }

    sheetSummary.getColumn(2).width = 5;

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Bao_Cao_Tour_Theo_Ngay_Từ ${moment(filterDate?.DateFrom).format(
          'DD/MM/YYYY'
        )} - ${moment(filterDate?.DateTo).format('DD/MM/YYYY')}_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoadingExport(false);
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          padding: '20px 10px',
          marginBottom: '15px',
          width: '95%',
        }}
      >
        <Space>
          <div>
            <b
              style={{
                marginLeft: '10px',
              }}
            >
              Chọn ngày:
            </b>
            <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
          </div>
          <div>
            <b>
              Chọn xe:{' '}
              {!carId && (
                <i
                  style={{
                    color: 'red',
                    fontSize: '12px',
                  }}
                >
                  Bạn phải chọn xe
                </i>
              )}
            </b>
            <br />
            <Selection
              status={!carId ? 'error' : ''}
              data={carList}
              placeholder='Chọn Xe'
              fieldValue='code'
              width={200}
              value={carId}
              onChange={handleChangeCar}
              onSearch={getCars}
            />
          </div>
          <div>
            <br />
            <Button
              type='primary'
              icon={<DownloadOutlined />}
              style={{ padding: '0px 20px', marginLeft: '10px' }}
              onClick={() => handleExportExcel(data, filter, carCode)}
              loading={loadingExport}
            >
              Export Excel
            </Button>
          </div>
        </Space>
      </div>
      <div
        style={{
          marginBottom: '30px',
        }}
      >
        <ReportHeader />
        <Divider plain={true}>
          <h1>
            <b>BÁO CÁO TOUR THEO NGÀY</b>
          </h1>
        </Divider>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '-15px',
            marginBottom: '5px',
          }}
        >
          <div>
            Xe: <b className='red'>{carCode}</b>
          </div>
        </div>

        <Table
          dataSource={data}
          loading={loading}
          rowKey='index'
          bordered
          columns={COLUMNS}
          pagination={false}
          size='small'
        />
      </div>
    </>
  );
};
