import React, { useState, useEffect } from 'react';
import { Space, Button, Form, Input, Row, Col, Tabs } from 'antd';
import { Selection } from '~components';
import ApiServices from '~services';
import { tailLayout } from './constant';
import moment from 'moment';
// fee
import { TransportFee } from '../transport-fee';
import { TransitFee } from '../transit-fee';
import { RomoocFee } from '../romooc-fee';
//
const { TextArea } = Input;

const CusItemAddForm = ({
  customerList,
  onClose,
  dataAll,
  setDataAll,
  contTypeList,
  partnerId,
  isUpdateNew,
  setIsUpdateNew,
  setIsUpdate,
  setDataSelect,
  reload,
  countClear,
  getCustomer,
}) => {
  const [customerId, setCustomerId] = useState('');
  const [locations, setLocations] = useState([]);
  const [locationSelected, setLocationSelected] = useState([]);
  const [note, setNote] = useState('');
  const [loadingCreate, setLoadingCreate] = useState(false);

  // Data Fee Transport
  const [dataFeeMainTransport, setDataFeeMainTransport] = useState([]);
  // Data Fee Transit
  const [dataFeeMainTransit, setDataFeeMainTransit] = useState([]);
  // Data Fee Romooc
  const [dataFeeRomooc, setDataFeeRomooc] = useState([]);
  const [locationPackingList, setLocationPackingList] = useState([]);

  const getLocations = async (Query = null) => {
    try {
      let { data } = await ApiServices.getLocations({ Query });
      if (data?.data) {
        let dataLocations = data?.data;
        setLocations(dataLocations);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handleClearDataDefault() {
    setCustomerId('');
    setLocationSelected([]);
    setNote('');
    setDataFeeMainTransport([]);
    setDataFeeMainTransit([]);
    setDataFeeRomooc([]);
  }

  useEffect(() => {
    handleClearDataDefault();
  }, [countClear]);

  useEffect(() => {
    getLocations();
  }, []);

  ///////////// --- CREATE ------ /////////////////
  const handleCreate = async (values, newPartnerId, checkIsAddNew) => {
    if (loadingCreate) return;

    if (values?.expiredDate && values.contractDate) {
      if (moment(values?.expiredDate) < moment(values.contractDate)) {
        window.TmsNoti.warning('Cảnh báo', 'Ngày hết hạn phải lớn hơn hoặc bằng ngày hợp đồng !!!');
        return;
      }
    }

    if (
      !newPartnerId ||
      !values?.contractNumber ||
      !values?.customerId ||
      !values?.contractDate ||
      !values?.arrLocations?.length ||
      (!dataFeeMainTransport?.length && !dataFeeMainTransit?.length)
    ) {
      window.TmsNoti.warning('Thông báo', 'Vui lòng nhập đầy đủ thông tin yêu cầu!!!');
      return;
    }

    const partnerIdSend = newPartnerId;
    let codeSend = null;
    if (checkIsAddNew) {
      codeSend = values?.contractNumber;
    } else {
      codeSend = null;
    }
    const transportData = dataFeeMainTransport?.map((item) => {
      return {
        distance: item?.distanceId,
        fees: item?.fees,
      };
    });
    const transitData = dataFeeMainTransit?.map((item) => {
      return {
        distance: item?.distanceId,
        fees: item?.fees,
      };
    });
    const romoocData = dataFeeRomooc?.map((item) => {
      return {
        locationId: item?.locationId,
        price: item?.price,
        freeDate: item?.freeDate,
      };
    });
    const dataSend = {
      info: {
        contractNumber: values?.contractNumber,
        customerId: values?.customerId,
        contractDate: values?.contractDate && moment(values?.contractDate).format('YYYY-MM-DD'),
        expiredDate: values?.expiredDate && moment(values?.expiredDate).format('YYYY-MM-DD'),
        renewExpiredDate: null,
        arrLocations: values?.arrLocations,
        note: values?.note,
      },
      transport: transportData?.length > 0 ? transportData : null,
      transit: transitData?.length > 0 ? transitData : null,
      romoocs: romoocData?.length > 0 ? romoocData : null,
    };
    setLoadingCreate(true);

    return ApiServices.createContractForVendor(partnerIdSend, codeSend, dataSend)
      .then((res) => {
        if (res?.data?.data) {
          window.TmsNoti.success('Thêm mới Hợp đồng thành công');
          setIsUpdateNew(true);
          setIsUpdate(true);
          setDataSelect(res?.data?.data?.info);
          reload();
          handleClose();
        }
      })
      .catch((err) => {
        console.log({ err });
        window.TmsNoti.error('Lỗi', 'Thêm mới Hợp đồng thất bại');
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };
  ///////////// --- UPDATE ------ /////////////////
  const handleChangeCustomer = async (id) => {
    if (!id) return;
    setCustomerId(id);
    setDataAll((prev) => {
      prev.customerId = id;
      return prev;
    });
  };
  function handleChangeNote(event) {
    const text = event?.target?.value || '';
    setNote(text);
    setDataAll((prev) => {
      prev.note = text;
      return prev;
    });
  }
  const handleChangeSelectedLocation = (value, valueAll) => {
    const mapValueAll = valueAll?.map((item) => {
      return {
        id: item.value,
        name: item.children,
      };
    });
    setLocationSelected(value);
    setLocationPackingList(mapValueAll);

    setDataAll((prev) => {
      prev.arrLocations = value;
      return prev;
    });
  };
  // =========   reloadAfterUpdate
  const reloadAfterUpdate = async () => {};

  const items = [
    {
      key: '1',
      label: `Phí vận chuyển`,
      children: (
        <TransportFee
          contTypeList={contTypeList}
          dataFeeMainTransport={dataFeeMainTransport}
          setDataFeeMainTransport={setDataFeeMainTransport}
          locationSelected={locationSelected}
          reloadAfterUpdate={reloadAfterUpdate}
          contractStatus={0}
          isNew={true}
        />
      ),
    },
    {
      key: '2',
      label: `Phí chuyển kho`,
      children: (
        <TransitFee
          contTypeList={contTypeList}
          dataFeeMainTransit={dataFeeMainTransit}
          setDataFeeMainTransit={setDataFeeMainTransit}
          locationSelected={locationSelected}
          isNew={true}
        />
      ),
    },
  ];
  const onChangeTabs = (key) => {
    console.log(key);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div
      style={{
        backgroundColor: 'rgb(234, 238, 243)',
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        borderRadius: '10px',
        fontSize: '12px',
        padding: '10px',
        marginBottom: '20px',
        position: 'relative',
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={
              <span>
                <b className='red'>*</b> Khách hàng
              </span>
            }
          >
            <Selection
              data={customerList}
              fieldValue='shortName'
              placeholder='Khách hàng'
              value={customerId}
              onChange={handleChangeCustomer}
              onSearch={getCustomer}
            />
            <Form.Item label='Ghi chú'>
              <TextArea rows={2} value={note} onChange={handleChangeNote} />
            </Form.Item>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <span>
                <b className='red'>*</b> Nơi đóng hàng
              </span>
            }
          >
            <Selection
              data={locations}
              placeholder='Nơi đóng hàng'
              fieldValue='name'
              onSearch={getLocations}
              mode='multiple'
              value={locationSelected}
              onChange={handleChangeSelectedLocation}
            />
          </Form.Item>
        </Col>
      </Row>
      <i className='red'>
        Note: Bạn phải có ít nhất 1 chi phí vận chuyển hoặc phí chuyển kho, hoặc có cả 2:
      </i>
      <Tabs defaultActiveKey='1' items={items} onChange={onChangeTabs} />
      {/* Romooc Fee */}
      <RomoocFee
        dataFeeRomooc={dataFeeRomooc}
        setDataFeeRomooc={setDataFeeRomooc}
        locationSelected={locationSelected}
        locationPackingList={locationPackingList}
        isNew={true}
      />
      <Form.Item
        {...tailLayout}
        className='submit-form-input-data'
        style={{
          marginTop: '30px',
        }}
      >
        <Space size='large'>
          <Button
            type='primary'
            style={{ padding: '0 40px' }}
            loading={loadingCreate}
            onClick={() => handleCreate(dataAll, partnerId, isUpdateNew)}
          >
            Lưu
          </Button>
        </Space>
      </Form.Item>
    </div>
  );
};

export { CusItemAddForm };
