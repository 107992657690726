import React, { useEffect, useState, useRef } from 'react';
import { TableLayout, UploadExcelButton } from '~components';
import { CustomerForm } from './customer-form';
import CustomerFields from './fields-data';
import { Input, Button, Space, Popconfirm, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import Utils from '~commons/utils';

const EXCEL_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/Customer.xlsx';

export const Customers = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [customers, setCustomers] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [loadingExport, setLoadingExport] = useState(false);
  const [fields, setFields] = useState({});
  console.log(fields);
  //-------------------------------------------------------------------
  const typingTimeoutref = useRef(null);
  //-------------------------------------------------------------------
  const [searchByColumn, setSearchByColumn] = useState({
    code: '',
    name: '',
    shortName: '',
    companyName: '',
    address: '',
    phone: '',
    customerType: '',
    bankNumber: '',
    tax: '',
    businessLicenseNumber: '',
    contactPerson: '',
  });

  const getCustomers = async () => {
    let dataFilterSend = { ...filter };
    if (Object.keys(dataFilterSend.Columns).length === 0) {
      delete dataFilterSend['Columns'];
    } else {
      const columnJSONSTRING = JSON.stringify(dataFilterSend.Columns);
      dataFilterSend.Columns = columnJSONSTRING;
    }

    try {
      setLoading(true);
      let { data } = await ApiServices.getCustomer(dataFilterSend);
      setLoading(false);
      let pag = pagination;
      pag.total = data?.totalRecords || 0;
      pag.pageSize = data?.pageSize;
      pag.current = data?.pageNumber;

      data = data?.data || [];
      setCustomers(data);
      setPagination(pag);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);
  //----------- Start ----- Pagination Search ---------
  //----------- Pagination --------------------------
  const onChangePagination = (page, size) => {
    let filterTemp = filter;
    filterTemp.PageNumber = page;
    filterTemp.PageSize = size;
    setFilter(filterTemp);
    // searchCarsByColumn();
    getCustomers();
  };
  const [pagination, setPagination] = useState({
    total: 1,
    showSizeChanger: true,
    onChange: onChangePagination,
  });
  const [filter, setFilter] = useState({
    PageNumber: 0,
    PageSize: 10,
    Text: '',
    Columns: '',
    // QueryField: '',
    // SortColumn: '',
    // SortColumnDirection: ''
  });

  // ------------ Search Column ----------------------
  const handleSearchColumnFn = (event, name) => {
    const filterTmp = filter;
    const val = event.target.value;
    let _searchByColumn = { ...searchByColumn };
    _searchByColumn[`${name}`] = val;
    setSearchByColumn(_searchByColumn);
    // Clear timeout
    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }
    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 0) {
        filter.PageNumber = 1;
        let searchColumnFn = { ...filter.Columns };
        searchColumnFn[`${name}`] = val;
        filter.Columns = searchColumnFn;

        setFilter({ ...filterTmp, PageNumber: 1 });
        getCustomers();
      }
      if (val.length === 0) {
        delete filter.Columns[`${name}`];
        setFilter({ ...filterTmp, PageNumber: 1 });
        getCustomers();
      }
    }, 1000);
  };
  // ------------- End Search --------------------

  const setFieldsForm = async (value) => {
    const customerFields = await CustomerFields(value);
    await setFields(customerFields);
    return customerFields;
  };
  const reload = async () => {
    setSelected(null);
    await getCustomers();
  };

  const getUpdatedCustomerForm = async (value, isNew = false, id = null, valueRowInit = {}) => {
    const customerForm = await (
      <CustomerForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        valueRowInit={valueRowInit}
        reload={reload}
        onChange={setFields}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: customerForm,
      },
    ]);
  };

  const handleSelectRow = (row) => {
    setLoading(true);

    ApiServices.getCustomerDetails(row?.id)
      .then((res) => {
        if (res?.data) {
          return onRowSelected(res?.data);
        }
      })
      .catch((err) => console.log('Get Customer Details Error: ', err))
      .finally(() => setLoading(false));
  };

  const onRowSelected = async (value) => {
    await setIsLoadingTab(true);
    await setTitleTab(value.code);
    await setFieldsForm(value)
      .then((res) => {
        return getUpdatedCustomerForm(res, false, value.id, value);
      })
      .then(() => setSelected(value));
    setIsLoadingTab(false);
  };

  const deleteCustomer = async (record) => {
    try {
      const res = await ApiServices.deleteCustomer(record.id);
      if (res.data) {
        message.success('Xoá khách hàng thành công');
        return reload();
      }
      message.error('Xoá khách hàng thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Mã</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.code}
            onChange={(event) => handleSearchColumnFn(event, 'code')}
          />
        </span>
      ),
      dataIndex: 'code',
      align: 'center',
      width: 150,
      render: (value, record) => (
        <a onClick={() => handleSelectRow(record)}>{value || 'underFine'}</a>
      ),
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Công Ty</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.companyName}
            onChange={(event) => handleSearchColumnFn(event, 'companyName')}
          />
        </span>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'companyName',
    },

    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Tên Viết Tắt</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.shortName}
            onChange={(event) => handleSearchColumnFn(event, 'shortName')}
          />
        </span>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'shortName',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Tên Khách Hàng</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.name}
            onChange={(event) => handleSearchColumnFn(event, 'name')}
          />
        </span>
      ),
      width: 200,
      align: 'center',
      dataIndex: 'name',
    }, //
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Địa Chỉ</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.address}
            onChange={(event) => handleSearchColumnFn(event, 'address')}
          />
        </span>
      ),
      width: 350,
      align: 'center',
      dataIndex: 'address',
    }, //
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Điện Thoại</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.phone}
            onChange={(event) => handleSearchColumnFn(event, 'phone')}
          />
        </span>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'phone',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Nhóm Khách Hàng</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.customerType}
            onChange={(event) => handleSearchColumnFn(event, 'customerType')}
          />
        </span>
      ),
      width: 130,
      align: 'center',
      dataIndex: 'customerType',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Số Tài Khoản</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.bankNumber}
            onChange={(event) => handleSearchColumnFn(event, 'bankNumber')}
          />
        </span>
      ),
      width: 100,
      align: 'center',
      dataIndex: 'bankNumber',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Tên Ngân Hàng</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.bankName}
            onChange={(event) => handleSearchColumnFn(event, 'bankName')}
          />
        </span>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'bankName',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Mã Số Thuế</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.tax}
            onChange={(event) => handleSearchColumnFn(event, 'tax')}
          />
        </span>
      ),
      width: 130,
      align: 'center',
      dataIndex: 'tax',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Số GPKD</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.businessLicenseNumber}
            onChange={(event) => handleSearchColumnFn(event, 'businessLicenseNumber')}
          />
        </span>
      ),
      width: 100,
      align: 'center',
      dataIndex: 'businessLicenseNumber',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Người Đại Diện</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.contactPerson}
            onChange={(event) => handleSearchColumnFn(event, 'contactPerson')}
          />
        </span>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'contactPerson',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Chức Vụ</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.position}
            onChange={(event) => handleSearchColumnFn(event, 'position')}
          />
        </span>
      ),
      width: 130,
      align: 'center',
      dataIndex: 'position',
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 90,
      align: 'center',
      render: (record) => (
        <Popconfirm
          onConfirm={() => deleteCustomer(record)}
          title='Xoá Khách hàng này？'
          okText='Yes'
          cancelText='No'
        >
          <a style={{ color: 'red' }} href='#'>
            Xoá
          </a>
        </Popconfirm>
      ),
    },
  ];

  const createCustomer = async () => {
    await setTitleTab('');
    await setFieldsForm({})
      .then((res) => getUpdatedCustomerForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };
  const onUploadExcel = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importCustomer(file)
      .then((res) => {
        if (!res.data?.error) {
          message.success(res?.data?.message);
          reload();
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT CARR ERROR', err);
        message.error('Thao tác thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };

  const handleExportExcel = async (query) => {
    if (loadingExport) return;

    setLoadingExport(true);
    try {
      const { data } = await ApiServices.exportCustomer(query);
      if (data) {
        const outputFilename = `Danh_Sach_Khach_Hang.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoadingExport(false);
    }
  };

  const headerContent = (
    <Space>
      <Button type='primary' onClick={createCustomer}>
        Thêm Mới
      </Button>
      <UploadExcelButton
        loading={loading}
        onChange={onUploadExcel}
        templateUrl={EXCEL_TEMPLATE_URL}
      />
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        loading={loadingExport}
        style={{ padding: '0px 20px', marginLeft: '10px' }}
        onClick={() => handleExportExcel(filter)}
      >
        Export Excel
      </Button>
    </Space>
  );
  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={customers}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      width={800}
      pagination={pagination}
      isDisplayCheckbox={false}
      headerLength='190px'
    />
  );
};
