import React, { useState } from 'react';
import { Table, Checkbox, Button, Space } from 'antd';
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import Utils from '~commons/utils';

const TicketIsAdded = ({ dataExpenses }) => {
  const [showTable, setShowTable] = useState(false);

  const handleToggleButton = () => {
    setShowTable(!showTable);
  };

  const renderIsExpenses = (value) => {
    if (value) {
      return <Checkbox checked />;
    }
    return '';
  };

  const columns = [
    {
      title: 'Tên chi phí',
      dataIndex: 'name',
      width: 180,
      fixed: true,
    },
    {
      title: 'Đã giải trừ',
      dataIndex: 'isExpenses',
      width: 100,
      align: 'center',
      render: renderIsExpenses,
    },
    {
      title: 'Chưa VAT',
      dataIndex: 'price',
      width: 100,
      align: 'center',
    },
    {
      title: '%VAT',
      dataIndex: 'vat',
      width: 100,
      align: 'center',
    },
    {
      title: 'Tổng',
      dataIndex: 'total',
      width: 100,
      align: 'center',
    },
    {
      title: 'Số Hoá đơn',
      dataIndex: 'billNumber',
      width: 100,
      align: 'center',
    },
    {
      title: 'Ngày HĐ',
      dataIndex: 'billDate',
      width: 130,
      render: (val) => Utils.formatDate(val),
      align: 'center',
    },
    {
      title: 'NCC Hoá đơn',
      dataIndex: 'supplierName',
      width: 200,
    },
  ];

  return (
    <>
      <Space>
        <Button
          type='link'
          icon={showTable ? <DownOutlined /> : <RightOutlined />}
          onClick={handleToggleButton}
        />
        <b>
          Danh sách chi phí đã được tạo khác{' '}
          <span className='red'>({dataExpenses?.length || 0})</span>
        </b>
      </Space>
      {showTable && (
        <Table
          bordered
          columns={columns}
          dataSource={dataExpenses}
          key='id'
          scroll={{
            x: '100%',
            y: '100%',
          }}
          pagination={false}
        />
      )}
    </>
  );
};

export { TicketIsAdded };
