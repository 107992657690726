import { DATE_FORMAT } from '~commons/constants';
import moment from 'moment';

export const KEYS = ['contractId', 'contractNumber', 'customerId', 'customerName', 'note'];
// export const KEY_OF_NUMBER = ['vat'];
export const KEY_OF_ARRAY = ['arrLocations'];
export const KEYS_OF_DATE = ['contractDate', 'expiredDate', 'renewExpiredDate'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });
  // KEY_OF_NUMBER.forEach((item) => {
  //   result.push({
  //     name: [item],
  //     value: data[item] || 0,
  //   });
  // });

  KEY_OF_ARRAY.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] || [],
    });
  });

  KEYS_OF_DATE.forEach((item) => {
    result.push({
      name: [item],
      value: data[item]
        ? data[item] === null
          ? null
          : data[item] !== '0001-01-01T00:00:00+07:00'
          ? moment(data[item], DATE_FORMAT)
          : null
        : null,
    });
  });
  return result;
};
