import React from 'react';
import { Descriptions } from 'antd';

const InfoCarInspection = ({ infoDriver }) => {
  return (
    <Descriptions bordered column={2} size='small' labelStyle={{ backgroundColor: '#CEE5D0' }}>
      <Descriptions.Item label='Mã Tài Xế'>{infoDriver?.driverCode}</Descriptions.Item>
      <Descriptions.Item label='Tên Xài Xế'>{infoDriver?.driverName}</Descriptions.Item>
      <Descriptions.Item label='CCCD'>{infoDriver?.driverID}</Descriptions.Item>
      <Descriptions.Item label='SĐT'>{infoDriver?.driverPhone}</Descriptions.Item>
    </Descriptions>
  );
};

export { InfoCarInspection };
