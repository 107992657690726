import { memo } from 'react';
import { Image } from 'antd';
import styled from 'styled-components';
import Utils from '~commons/utils';
import { FileImageOutlined } from '@ant-design/icons';

const IMAGE_REGEX = /\.(jpg|jpeg|png|gif|webp|jfif)$/i;
const FileName = styled.div`
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ContFiles = memo(({ files }) => {
  return (
    !!files?.length && (
      <Image.PreviewGroup>
        {files?.map((file) => {
          const url = `${Utils.getAppUrl()}${file.urlImage?.substring(2)}`;
          if (!file?.extension.match(IMAGE_REGEX)) {
            return (
              <FileName
                key={file.code || file.filePath}
                style={{
                  marginBottom: '10px',
                  marginTop: '5px',
                  width: '100%',
                }}
              >
                <a target='_blank' href={url} rel='noreferrer'>
                  <FileImageOutlined
                    style={{
                      fontSize: '15px',
                    }}
                  />{' '}
                  {file.name}
                </a>
              </FileName>
            );
          }
          return (
            <Image
              width={100}
              height={100}
              style={{ padding: '5px' }}
              src={url}
              key={file.code || file.filePath}
            />
          );
        })}
      </Image.PreviewGroup>
    )
  );
});
