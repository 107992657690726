import React, { useEffect, useState } from 'react';
import { Form, Button, message, Input } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';
import { Selection } from '~components';

const InputForm = ({ fields, isNew, id, dataSelect, reload, onChange, setLoading }) => {
  const [contTypeList, setContTypeList] = useState([]);
  const [romoocList, setRomoocList] = useState([]);

  const getContType = async (query = null) => {
    try {
      const { data } = await ApiServices.getContTypes({ query });
      if (data?.data) {
        setContTypeList(data?.data);
      }
    } catch (err) {
      console.error('ERR - Get ContTypes', err);
    }
  };
  const getRomooc = async (query = '') => {
    try {
      const { data } = await ApiServices.getRomoocs({ Columns: { code: query } });
      if (data?.data) {
        setRomoocList(data?.data);
      }
    } catch (err) {
      console.error('ERR - Get Romoocs', err);
    }
  };
  useEffect(() => {
    getContType();
    getRomooc();
  }, []);

  const handleCreate = async (data) => {
    const indTypeCont = contTypeList?.find((item) => item.id === data?.typeCont);
    const dataSend = {
      ...data,
      typeContName: indTypeCont?.name,
    };

    try {
      const res = await ApiServices.createTransitCont(dataSend);
      if (res?.data?.data) {
        message.success('Thêm mới thành công');
        return reload();
      } else {
        message.error('Thêm mới thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const handleUpdate = async (data) => {
    const indTypeCont = contTypeList?.find((item) => item.id === data?.typeCont);
    const dataSend = {
      ...data,
      id,
      typeContName: indTypeCont?.name,
    };

    try {
      const res = await ApiServices.updateTransitCont(id, dataSend);
      if (res.data) {
        message.success('Cập nhật thành công');
        return reload();
      } else {
        message.error('Cập nhật thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await handleCreate(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await handleUpdate(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      name='global_state'
      className='form-input-data'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        name='contNumber'
        label='Số Cont'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập số Cont',
          },
        ]}
      >
        <Input placeholder='Số cont' />
      </Form.Item>
      <Form.Item
        name='typeCont'
        label='Loại Cont'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn loại Cont',
          },
        ]}
      >
        <Selection
          data={contTypeList}
          labelDefault={dataSelect?.typeContName}
          fieldValue='name'
          placeholder='Loại Cont'
          onSearch={getContType}
        />
      </Form.Item>
      <Form.Item name='romoocId' label='Romooc'>
        <Selection
          data={romoocList}
          labelDefault={dataSelect?.romoocCode}
          fieldValue='code'
          placeholder='Romooc'
          onSearch={getRomooc}
        />
      </Form.Item>
      <Form.Item {...tailLayout} className='submit-form-input-data'>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { InputForm };
