import React from 'react';
import { Form, Input, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      // offset: 0,
    },
    sm: {
      span: 24,
      // offset: 4,
    },
  },
};

export const FormList = ({
  title,
  fieldKey,
  rulesFormList = null,
  rulesItem = null,
  placeholder = '',
  actionName = '',
  inputTextArea = false,
}) => {
  return (
    <Form.List
      name={fieldKey}
      rules={rulesFormList}
      // rules={[
      //   {
      //     validator: async (_, names) => {
      //       if (!names || names.length < 1) {
      //         return Promise.reject(new Error('Vui lòng nhập số điện thoại'));
      //       }
      //     },
      //   },
      // ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item
              {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
              label={index === 0 ? title : ''}
              required={false}
              key={field.key}
            >
              <Form.Item
                {...field}
                validateTrigger={['onChange', 'onBlur']}
                rules={rulesItem}
                noStyle
              >
                {inputTextArea ? (
                  <Input.TextArea
                    placeholder={placeholder}
                    style={{
                      width: '80%',
                    }}
                  />
                ) : (
                  <Input
                    placeholder={placeholder}
                    style={{
                      width: '80%',
                    }}
                  />
                )}
              </Form.Item>
              {fields.length > 1 ? (
                <MinusCircleOutlined
                  className='dynamic-delete-button ml-3'
                  onClick={() => remove(field.name)}
                />
              ) : null}
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type='dashed'
              onClick={() => add()}
              style={{
                width: '90%',
                color: '#1890ff',
              }}
              icon={<PlusOutlined />}
            >
              {actionName}
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};
