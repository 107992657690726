export const KEYS = [
  'code', // "string",
  'name', // "string",
  'shortName', // "string",
  'companyId', // "string",
  'ownerId', //
  'companyName', // "string",
  'address', // "string",
  'phone', // "string",
  'fax', // "string",
  'bankNumber', // "string",
  'bankName', // "string",
  'tax', // "string",
  'businessLicenseNumber', // "string",
  'contactPerson', // "string",
  'position', // "string",
  'customerType', // "string",
];

export const KEYS_ARRAY = ['arrLocations'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });

  KEYS_ARRAY.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] || [],
    });
  });
  return result;
};
// export default (data) => {
//   return keyOfForm.map((item) => {
//     return {
//       name: [item],
//       value: data[item] || null,
//       errors: [],
//     };
//   });
// };
