import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, message, DatePicker, Select } from 'antd';
import { layout, tailLayout } from './constant';
import { DATE_FORMAT } from '~commons/constants';
import ApiServices from '~services';
import { Selection } from '~components';
const { Option } = Select;

const TireForm = ({ fields, isNew, id, reload, onChange, setLoading, dataSelected }) => {
  const inputRef = useRef(null);
  const [cars, setCars] = useState([]);
  const [romoocs, setRomoocs] = useState([]);
  const [companies, setCompanies] = useState([]);

  const getDataInit = async () => {
    const [getCar, getRomooc] = await Promise.all([
      ApiServices.getCars(),
      ApiServices.getRomoocs(),
    ]);
    setCars(getCar?.data?.data || []);
    setRomoocs(getRomooc?.data?.data || []);
  };
  const getCompanies = async (query = '') => {
    const newQuery = {
      Columns: {
        name: query,
      },
    };
    try {
      const res = await ApiServices.getCompanies(query ? newQuery : null);
      if (res?.data?.data) {
        setCompanies(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDataInit();
    getCompanies();
  }, []);

  const onCompareWithApi = (value, cb) => {
    if (inputRef.current) {
      clearTimeout(inputRef.current);
      cb();
    }
    inputRef.current = setTimeout(async () => {
      try {
        const { data } = await ApiServices.existsCodeTire(null, value);
        const isUsed = data;
        cb(isUsed);
      } catch (error) {
        console.error(error);
      }
    }, 1000);
  };

  const checkExistsCode = async (_, value) => {
    if (value.length > 2) {
      const ind = fields.findIndex((item) => {
        return item.name[0] === 'code';
      });
      if (isNew === false && value !== fields[ind].value) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Mã Vỏ Xe này đã tạo rồi !!!'));
        }
      }

      if (isNew === true) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Mã Vỏ Xe này đã tạo rồi !!!'));
        }
      }
    }
  };

  const createTire = async (data) => {
    try {
      const res = await ApiServices.createTire(data);
      if (res.data) {
        message.success('Thêm mới Vỏ Xe thành công');
        return reload();
      } else {
        message.error('Thêm mới Vỏ Xe thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới Vỏ Xe thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const updateTire = async (data) => {
    data.id = id;
    try {
      const res = await ApiServices.updateTire(id, data);
      if (res.data) {
        message.success('Cập nhật Vỏ Xe thành công');
        return reload();
      } else {
        message.error('Cập nhật Vỏ Xe thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật Vỏ Xe thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createTire(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updateTire(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      name='global_state'
      className='form-input-data'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        // {...itemLayout}
        style={{ marginTop: '10px' }}
        name='code'
        label='Mã Vỏ Xe'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Mã Vỏ Xe',
          },
          {
            validator: checkExistsCode,
          },
          {
            type: 'string',
            min: 3,
            message: 'Bạn phải nhập ít nhất 3 ký tự',
          },
        ]}
      >
        <Input placeholder='Mã Vỏ Xe' />
      </Form.Item>

      <Form.Item style={{ marginTop: '10px' }} name='carCode' label='Biển Số Xe'>
        <Select showSearch placeholder='Biển Số Xe'>
          {cars.map(({ id, code }) => (
            <Option key={id} value={id}>
              {code}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item style={{ marginTop: '10px' }} name='seriNumber' label='Số Seri'>
        <Input placeholder='Số Seri' />
      </Form.Item>

      <Form.Item style={{ marginTop: '10px' }} name='romoocCode' label='Số Romooc'>
        <Select showSearch placeholder='Số Romooc'>
          {romoocs.map(({ id, code }) => (
            <Option key={id} value={id}>
              {code}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item style={{ marginTop: '10px' }} name='manufactory' label='Nhà Sản Xuất'>
        <Input placeholder='Nhà Sản Xuất' />
      </Form.Item>
      <Form.Item
        name='companyId'
        label='Công Ty'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn Công Ty',
          },
        ]}
      >
        <Selection
          labelDefault={dataSelected?.companyName || ''}
          data={companies}
          placeholder='Bạn phải chọn Công Ty'
          fieldValue='name'
          onSearch={getCompanies}
        />
      </Form.Item>
      <Form.Item
        style={{ marginTop: '10px' }}
        name='useBeginDate'
        label='Ngày Sử Dụng'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Ngày Sử Dụng',
          },
        ]}
      >
        <DatePicker placeholder='Ngày Sử Dụng' style={{ width: '100%' }} format={DATE_FORMAT} />
      </Form.Item>

      <Form.Item name='numberKmRun' label='Số Km Đã Chạy'>
        <Input placeholder='Số Km Đã Chạy' type='number' />
      </Form.Item>

      <Form.Item name='price' label='Chi Phí Mua'>
        <Input placeholder='Chi Phí Mua' type='number' />
      </Form.Item>

      <Form.Item name='depreciation' label='Số Tháng Khấu Hao'>
        <Input placeholder='Số Tháng Khấu Hao' type='number' />
      </Form.Item>

      <Form.Item {...tailLayout} className='submit-form-input-data'>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { TireForm };
