import ApiServices from '~services';
import storage from '~storage';
// import { KEYS_OF_DATE, KEYS_OF_DATE_TIME } from './fields-data';
// import moment from 'moment';

// const formatFields = (data) => {
//   let format = {};
//   data.forEach((item) => {
//     if (KEYS_OF_DATE.includes(item.name[0])) {
//       format[item.name[0]] = item.value ? moment(item.value).format('YYYY-MM-DD') : null;
//     } else if (KEYS_OF_DATE_TIME.includes(item.name[0])) {
//       format[item.name[0]] = item.value ? moment(item.value).format() : null;
//     } else {
//       format[item.name[0]] = item.value;
//     }
//   });
//   return format;
// };
export const updateOrder = async (format, orderDetail) => {
  const orderType = storage.staticVariables ? storage.staticVariables.OrderType : {};
  switch (orderDetail?.orderType) {
    case orderType.Order_Transit:
      return ApiServices.updateTransit(orderDetail.id, format);

    case orderType.Order_Bill:
      format.orderFiles = format.orderFiles.fileList?.length
        ? format.orderFiles.fileList
        : format.orderFiles;
      return ApiServices.updateBill(orderDetail.id, format);

    default:
      format.orderFiles = format.orderFiles.fileList?.length
        ? format.orderFiles.fileList
        : format.orderFiles;
      return ApiServices.updateBooking(orderDetail.id, format);
  }
};
