import React, { useRef, useEffect, useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';
import { Selection } from '~components';

const UnitForm = ({ fields, isNew, id, reload, onChange, setLoading, dataSelected }) => {
  const inputRef = useRef(null);
  const [companies, setCompanies] = useState([]);

  const getCompanies = async (query = '') => {
    const newQuery = {
      Columns: {
        name: query,
      },
    };
    try {
      const res = await ApiServices.getCompanies(query ? newQuery : null);
      if (res?.data?.data) {
        setCompanies(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCompanies();
  }, []);

  const onCompareWithApi = (value, cb) => {
    if (inputRef.current) {
      clearTimeout(inputRef.current);
      cb();
    }
    inputRef.current = setTimeout(async () => {
      try {
        const { data } = await ApiServices.existsCodeUnit(null, value);
        const isUsed = data;
        cb(isUsed);
      } catch (error) {
        console.error(error);
      }
    }, 1000);
  };

  const checkExistsCode = async (_, value) => {
    if (value.length > 1) {
      const ind = fields.findIndex((item) => {
        return item.name[0] === 'code';
      });
      if (isNew === false && value !== fields[ind].value) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Mã Đơn Vị Tính này đã tạo rồi !!!'));
        }
      }

      if (isNew === true) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Mã Đơn Vị Tính này đã tạo rồi !!!'));
        }
      }
    }
  };

  const createdUnit = async (data) => {
    try {
      const res = await ApiServices.createUnit(data);
      if (res.data) {
        message.success('Thêm mới Đơn Vị Tính thành công');
        return reload();
      } else {
        message.error('Thêm mới Đơn Vị Tính thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới Đơn Vị Tính thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const updatedUnit = async (data) => {
    data.id = id;
    try {
      const res = await ApiServices.updateUnit(id, data);
      if (res.data) {
        message.success('Cập nhật Đơn Vị Tính thành công');
        return reload();
      } else {
        message.error('Cập nhật Đơn Vị Tính thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật Đơn Vị Tính thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createdUnit(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updatedUnit(value);
    setLoading(false);
  };

  return (
    <Form
      {...layout}
      name='global_state'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        // {...itemLayout}
        style={{ marginTop: '10px' }}
        name='code'
        label='Mã Đơn Vị Tính'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Mã Đơn Vị Tính',
          },
          {
            validator: checkExistsCode,
          },
          {
            type: 'string',
            min: 2,
            message: 'Bạn phải nhập ít nhất 2 ký tự',
          },
        ]}
      >
        <Input placeholder='Mã Đơn Vị Tính' />
      </Form.Item>
      <Form.Item
        style={{ marginTop: '10px' }}
        name='name'
        label='Đơn Vị Tính'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Đơn Vị Tính',
          },
        ]}
      >
        <Input placeholder='Đơn Vị Tính' />
      </Form.Item>
      <Form.Item
        name='companyId'
        label='Công Ty'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn Công Ty',
          },
        ]}
      >
        <Selection
          labelDefault={dataSelected?.companyName || ''}
          data={companies}
          placeholder='Bạn phải chọn Công Ty'
          fieldValue='name'
          onSearch={getCompanies}
        />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { UnitForm };
