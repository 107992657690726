import React from 'react';
import { render } from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import App from './pages';
import { Provider } from 'react-redux';
import store from './store';
import storage from './storage';
import './assets/styles/index.scss';
import 'moment/locale/vi';
// import reportWebVitals from './reportWebVitals';

const DEVELOPMENT = 'development';
// console.error = function (...errs) {
//   if (!errs[0]?.startsWith?.('Warning: [antd')) {
//     window.console.error(errs);
//   }
//   return function () {};
// };
try {
  if (process.env.NODE_ENV != DEVELOPMENT) {
    console.warn = function () {};
    console.error = function () {};
    console.error = function () {};
  }
} catch (error) {
  console.error(error);
}

storage
  .loadData()
  .then(async () => {
    if (storage.isDarkTheme) {
      return import('./assets/styles/dark-theme.less');
    }
    return import('./assets/styles/ant.less');
  })
  .then(() => {
    if (window.location.protocol === 'http:' && process.env.NODE_ENV !== DEVELOPMENT) {
      window.location.href = window.location.href.replace('http:', 'https:');
    }
    return render(
      <Provider store={store}>
        <HttpsRedirect>
          <App />
        </HttpsRedirect>
      </Provider>,
      document.getElementById('root')
    );
  });
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
