import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Divider, Table, Input, DatePicker } from 'antd';
import { debounce } from 'lodash';
import ApiServices from '~services';
import Utils from '~commons/utils';
import moment from 'moment';
import { TableTitle } from '~commons/styles';
import { DATE_FORMAT } from '~commons/constants';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ReportDrivingLicense = () => {
  let query = useQuery();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataKey, setDataKey] = useState('');
  const [filter, setFilter] = useState(null);
  const [originData, setOriginData] = useState([]);
  const [dateExpiredSearch, setDateExpiredSearch] = useState(null);

  const getRPRomoocInspection = async () => {
    const key = query.get('key');
    switch (key) {
      case 'HetHan':
        setDataKey('Đã hết hạn');
        break;
      case 'HetHan10':
        setDataKey('Còn 10 ngày');
        break;
      case 'HetHan20':
        setDataKey('Còn 20 ngày');
        break;
      default:
        break;
    }

    if (key) {
      try {
        setLoading(true);
        const res = await ApiServices.getReportDrivingLicense();
        if (res?.data?.data) {
          return getListByArrayIdOfRomooc(res?.data?.data[key]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getListByArrayIdOfRomooc = async (arrId) => {
    try {
      setLoading(true);
      let { data } = await ApiServices.getListDriversReport(arrId);
      setLoading(false);
      if (data?.data) {
        const dataSort = data?.data?.sort((a, b) => {
          let da = new Date(a.dateExpired);
          let db = new Date(b.dateExpired);
          return da - db;
        });
        const dataMap = dataSort?.map((item, index) => {
          item.key = index + 1;
          return item;
        });
        setData(dataMap);
        setOriginData(dataMap);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getInit = async () => {
      await getRPRomoocInspection();
    };

    getInit();
  }, []);

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;
      if (query?.code) {
        if (!Utils.convertValSearch(item?.code)?.includes(Utils.convertValSearch(query?.code))) {
          checkFilter = false;
        }
      }
      if (query?.name) {
        if (!Utils.convertValSearch(item?.name)?.includes(Utils.convertValSearch(query?.name))) {
          checkFilter = false;
        }
      }
      if (query?.dateExpired) {
        if (moment(item?.dateExpired).format('YYYY-MM-DD') !== query?.dateExpired) {
          checkFilter = false;
        }
      }
      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    setData(dataFilter);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    []
  );
  useEffect(() => {
    if (originData?.length) {
      timeoutGetData(filter, originData);
    }
  }, [filter]);

  const onColumnSearch = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilter((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleChangeExpiredDate = (date) => {
    setDateExpiredSearch(date);
    const dateSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    setFilter((prev) => {
      return { ...prev, dateExpired: dateSearch };
    });
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      width: 50,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Ngày Hết Hạn
          <DatePicker
            value={dateExpiredSearch}
            onChange={handleChangeExpiredDate}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'dateExpired',
      width: 130,
      align: 'center',
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          Mã
          <Input name='code' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'code',
      width: 130,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Tên Tài Xế
          <Input name='name' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'name',
      width: 150,
      align: 'center',
    },
    {
      title: 'SĐT',
      dataIndex: 'phone',
      width: 100,
      align: 'center',
    },
    {
      title: 'CCCD',
      dataIndex: 'idCard',
      width: 100,
      align: 'center',
    },
  ];

  return (
    <div style={{ margin: '2rem' }}>
      <Divider>
        <b>GIẤY PHÉP LÁI XE </b>(<span className='red'>{dataKey}</span>)
      </Divider>
      <Table
        rowKey='id'
        size='small'
        bordered
        columns={columns}
        dataSource={data}
        loading={loading}
      />
    </div>
  );
};

export { ReportDrivingLicense };
