import React from 'react';
import { Popover } from 'antd';

const Marker = ({ title }) => {
  const content = (
    <div>
      <p>Chung Cư 675 Nguyễn Kiệm</p>
      <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsi4m8n-IIuDhez_MjOZFCwcyGv_pl6-AEug&usqp=CAU' />
    </div>
  );

  return (
    <Popover content={content} title={title}>
      <div className='google-marker'></div>
    </Popover>
  );
};

export { Marker };
