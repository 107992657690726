import React, { useEffect, useState } from 'react';
import { Form, Button, message, Input } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';
import { Selection } from '~components';

const InputForm = ({ fields, isNew, dataSelect, reload, onChange, setLoading }) => {
  const [locations, setLocations] = useState([]);

  const getLocations = async (query = null) => {
    try {
      const { data } = await ApiServices.getTaskLists({ query });
      if (data?.data) {
        setLocations(data?.data);
      }
    } catch (err) {
      console.error('ERR - Get ContTypes', err);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  const handleCreate = async (data) => {
    data.companyId = JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId;

    console.log({ data });

    try {
      const res = await ApiServices.createTakePhoto(data);
      if (res?.data?.data) {
        message.success('Thêm mới thành công');
        return reload();
      } else {
        message.error('Thêm mới thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const handleUpdate = async () => {};
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await handleCreate(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await handleUpdate(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      name='global_state'
      className='form-input-data'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        name='taskListId'
        label='Công việc'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn Công việc',
          },
        ]}
      >
        <Selection
          data={locations}
          labelDefault={dataSelect?.name}
          fieldValue='name'
          placeholder='Công việc'
          onSearch={getLocations}
          disabled={!isNew}
        />
      </Form.Item>
      <Form.Item style={{ marginTop: '10px' }} name='note' label='Ghi chú'>
        <Input placeholder='Ghi chú' />
      </Form.Item>
      <Form.Item {...tailLayout} className='submit-form-input-data'>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { InputForm };
