import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import { Input, Button, Space, Popconfirm, Badge, DatePicker, Modal, Select } from 'antd';
import { TableLayout, RangeDateFilter } from '~components';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { TableTitle } from '~commons/styles';
import {
  CONTRACT_STATUS,
  DATE_FORMAT_SEND_API,
  DATE_FORMAT,
  CONTRACT_STATUS_VAL,
  CONTRACT_STATUS_LIST,
} from '~commons/constants';
import { CreateForm } from './create';
import { UpdateForm } from './update';
import { EditContractHistory } from './edit-contract-history';
import styled from 'styled-components';

const DEFAULT_QUERIES = {
  PageSize: 20,
  PageNumber: 1,
  DateFrom: moment().subtract(2, 'years').format(),
  DateTo: moment().endOf('date').format(),
};
const statusTag = (text = '', bgColor = '') => {
  return (
    <Badge>
      <div
        style={{
          backgroundColor: bgColor,
          width: '100px',
          padding: '5px 10px',
          textAlign: 'center',
          borderRadius: '4px',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        {text}
      </div>
    </Badge>
  );
};

const ContractServiceForVendor = () => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [contractServiceList, setContractServiceList] = useState([]);
  const [statusVal, setStatusVal] = useState('');
  // update
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [dataSelect, setDataSelect] = useState(null);
  // search Column
  const [searchContractDate, setSearchContractDate] = useState(null);
  const [searchExpireDate, setSearchExpireDate] = useState(null);
  // count clear
  const [countClear, setCountClear] = useState(0);
  // History
  const [showHistoryContract, setShowHistoryContract] = useState(false);
  const [dataSelectToHistoryContract, setDataSelectToHistoryContract] = useState(null);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getCurrentContractsPartner(newQuery)
      .then((res) => {
        if (res?.data) {
          setContractServiceList(res?.data);
        }
      })
      .catch((err) => {
        console.error('get Current Contracts Partner Error', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };
  const onColumnSearchForStatus = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value && value !== 0) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const reload = async () => {
    await getData(filter);
  };

  const handleShowUpdate = async (record) => {
    if (!record?.contractNumber) return;
    setShowUpdateForm(true);
    setDataSelect(record);
  };
  async function onCloseUpdateFrom() {
    setShowUpdateForm(false);
    setDataSelect(null);
  }

  const deleteContract = async (record) => {
    if (!record?.contractNumber || loading) return;
    setLoading(true);
    return ApiServices.deleteContractForVendor(record?.contractNumber)
      .then((res) => {
        if (res?.data?.data) {
          window.TmsNoti.success('Xoá Hợp đồng thành công');
          return reload();
        } else {
          window.TmsNoti.error('Lỗi', 'Xoá Hợp đồng thất baị');
        }
      })
      .catch((err) => {
        console.log('Delete contract failed: ', err);
        window.TmsNoti.error('Lỗi', 'Xoá Hợp đồng thất baị');
      })
      .finally(() => setLoading(false));
  };

  const handleCreateContract = async () => {
    setShowCreateForm(true);
    setIsUpdate(false);
    setCountClear((prev) => prev + 1);
  };

  function onCloseCreateFrom() {
    setShowCreateForm(false);
  }

  const renderStatus = (value) => {
    switch (value) {
      case CONTRACT_STATUS.ACCEPT:
        return statusTag(CONTRACT_STATUS_VAL[CONTRACT_STATUS.ACCEPT], 'green');
      case CONTRACT_STATUS.REOPEN:
        return statusTag(CONTRACT_STATUS_VAL[CONTRACT_STATUS.REOPEN], 'orange');
      case CONTRACT_STATUS.CANCEL:
        return statusTag(CONTRACT_STATUS_VAL[CONTRACT_STATUS.CANCEL], 'red');
      default:
        return statusTag(CONTRACT_STATUS_VAL[CONTRACT_STATUS.INIT], '#867070');
    }
  };

  const handleShowHistory = (record) => {
    setDataSelectToHistoryContract(record);
    setShowHistoryContract(true);
  };
  const onCloseHistoryContract = () => {
    setDataSelectToHistoryContract(null);
    setShowHistoryContract(false);
  };

  const handleAcceptContract = async (record) => {
    if (!record?.contractNumber) return;
    setLoading(true);
    return ApiServices.acceptContractServiceForVendor(record?.contractNumber)
      .then((res) => {
        if (res?.data?.data) {
          window.TmsNoti.success('Xác nhận Hợp đồng thành công');
          return reload();
        } else {
          window.TmsNoti.error(
            'Lỗi',
            'Xác nhận Hợp đồng thất baị, vui lòng kiểm tra lại thông tin'
          );
        }
      })
      .catch((err) => {
        console.log('Accept contract failed: ', err);
        window.TmsNoti.error('Lỗi', 'Xác nhận Hợp đồng thất baị, vui lòng kiểm tra lại thông tin');
      })
      .finally(() => setLoading(false));
  };
  const handleReOpenContract = async (record) => {
    if (!record?.contractNumber) return;
    setLoading(true);
    return ApiServices.reOpenContractServiceForVendor(record?.contractNumber)
      .then((res) => {
        if (res?.data?.data) {
          window.TmsNoti.success('Mở lại Hợp đồng thành công');
          return reload();
        } else {
          window.TmsNoti.error('Lỗi', 'Mở lại Hợp đồng thất baị, vui lòng kiểm tra lại thông tin');
        }
      })
      .catch((err) => {
        console.log('Accept contract failed: ', err);
        window.TmsNoti.error('Lỗi', 'Mở lại Hợp đồng thất baị, vui lòng kiểm tra lại thông tin');
      })
      .finally(() => setLoading(false));
  };
  const handleActionStatusContract = (record) => {
    if (record?.status === CONTRACT_STATUS.INIT || record?.status === CONTRACT_STATUS.REOPEN) {
      return (
        <Popconfirm
          onConfirm={() => handleAcceptContract(record)}
          title='Xác nhận Hợp đồng này?'
          okText='Có'
          cancelText='Không'
        >
          <a href='#' style={{ color: 'green' }}>
            Xác nhận
          </a>
        </Popconfirm>
      );
    }
    if (record?.status === CONTRACT_STATUS.ACCEPT) {
      return (
        <Popconfirm
          onConfirm={() => handleReOpenContract(record)}
          title='Mở lại Hợp đồng này?'
          okText='Có'
          cancelText='Không'
        >
          <a href='#' style={{ color: 'orange' }}>
            Mở lại
          </a>
        </Popconfirm>
      );
    }
  };

  const handleActionContract = (record) => {
    if (record?.status === CONTRACT_STATUS.INIT) {
      return (
        <Popconfirm
          onConfirm={() => deleteContract(record)}
          title='Xoá Hợp đồng này?'
          okText='Có'
          cancelText='Không'
        >
          <a href='#' style={{ color: 'red' }}>
            Xoá
          </a>
        </Popconfirm>
      );
    }
    if (record?.status !== CONTRACT_STATUS.INIT) {
      return (
        <a href='#' onClick={() => handleShowHistory(record)}>
          Xem LS chỉnh sửa
        </a>
      );
    }
  };

  const onChangeSelect = (value) => {
    setStatusVal(value);
    onColumnSearchForStatus(value, 'status');
  };

  const handleChangeSearchContractDate = (date) => {
    setSearchContractDate(date);
    const dataSearch = date ? moment(date).format(DATE_FORMAT_SEND_API) : null;
    onColumnSearch(dataSearch, 'contractDate');
  };

  const handleChangeSearchExpireDate = (date) => {
    setSearchExpireDate(date);
    const dataSearch = date ? moment(date).format(DATE_FORMAT_SEND_API) : null;
    onColumnSearch(dataSearch, 'expiredDate');
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Số hợp đồng
          <Input name='contractNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'contractNumber',
      align: 'center',
      width: 200,
      render: (value, record) => (
        <a onClick={() => handleShowUpdate(record)}>{value || 'underFine'}</a>
      ),
    },
    {
      title: (
        <TableTitle>
          Trạng thái
          <SelectStyled
            placeholder='Trạng thái'
            value={statusVal}
            onChange={onChangeSelect}
            size='small'
            width='100%'
          >
            <Select.Option value=''>Tất cả</Select.Option>
            {CONTRACT_STATUS_LIST?.map((item, index) => {
              const { value, title } = item;
              return (
                <Select.Option key={index} value={value}>
                  {title}
                </Select.Option>
              );
            })}
          </SelectStyled>
        </TableTitle>
      ),
      dataIndex: 'status',
      align: 'center',
      width: 120,
      render: renderStatus,
    },
    {
      title: (
        <TableTitle>
          <div>Ngày hợp đồng</div>
          <DatePicker
            suffixIcon={null}
            value={searchContractDate}
            onChange={handleChangeSearchContractDate}
            placeholder='Search'
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'contractDate',
      align: 'center',
      width: 150,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          <div>Ngày hết hạn</div>
          <DatePicker
            suffixIcon={null}
            value={searchExpireDate}
            onChange={handleChangeSearchExpireDate}
            placeholder='Search'
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'expiredDate',
      align: 'center',
      width: 150,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Đối tác
          <Input name='partnerName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'partnerName',
      align: 'center',
      width: 180,
    },
    {
      title: '#',
      fixed: 'right',
      align: 'center',
      width: 90,
      render: handleActionStatusContract,
    },
    {
      title: '#',
      fixed: 'right',
      align: 'center',
      width: 120,
      render: handleActionContract,
    },
  ];

  const headerContent = (
    <Space>
      <RangeDateFilter query={filter} onChange={setFilter} />
      <Button type='primary' onClick={handleCreateContract}>
        Thêm Mới
      </Button>
    </Space>
  );

  const pagination = {
    total: contractServiceList?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <>
      <TableLayout
        rowKey='contractNumber'
        columns={columns}
        data={contractServiceList?.data || []}
        loading={loading}
        headerContent={headerContent}
        pagination={pagination}
        width={900}
        isDisplayCheckbox={false}
        headerLength='190px'
      />

      <CreateForm
        showCreateForm={showCreateForm}
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
        onCloseCreateFrom={onCloseCreateFrom}
        reload={reload}
        countClear={countClear}
      />
      <UpdateForm
        isShow={showUpdateForm}
        onClose={onCloseUpdateFrom}
        dataSelect={dataSelect}
        reload={reload}
      />
      <Modal
        title='Lịch sử chỉnh sửa Hợp đồng'
        open={showHistoryContract}
        onCancel={onCloseHistoryContract}
        footer={null}
        style={{
          top: 20,
        }}
        width='80%'
      >
        <EditContractHistory dataRow={dataSelectToHistoryContract} />
      </Modal>
    </>
  );
};

const SelectStyled = styled(Select)`
  width: ${({ width }) => width};
`;
export { ContractServiceForVendor };
