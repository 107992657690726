import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Space } from 'antd';
import { debounce } from 'lodash';
import { Table, Popconfirm, Button, message, Input, Select, DatePicker } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import { SubRow } from './sub-row';
import { NavLink } from 'react-router-dom';
import Utils from '~commons/utils';
import { UpdateInformation } from './update-order-information';
import { UpdateDetailCont } from './list-cont-order-update';
import { CreateOrderByCopy } from './copy-order-to-create';
import { RangeDateFilter, UploadExcelButton } from '~components';
import moment from 'moment';
import { ORDER_OPTION_SELECTS, ORDER_STATUS } from '~commons/constants';
import { OrderStatus } from '~components/common-status';
import { OrderTypeSelect } from '~components/common-select';
import { TableTitle } from '~commons/styles';
import { DATE_FORMAT } from '~commons/constants';
import { ShowHistoryOrder } from './show-history-order';

const HEADER_LENGTH = '250px';
const { Option } = Select;
// const BOOK_EXCEL_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/Booking.xlsx';
const BILL_EXCEL_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/Bill.xlsx';
// const TRANSIT_EXCEL_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/Transit.xlsx';
export const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [updateOrder, setUpdateOrder] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [updateOrderContDetail, setUpdateContOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showRevise, setShowRevise] = useState(false);
  const [bookCutOffTimeSearch, setBookCutOffTimeSearch] = useState(null);
  const [bookSIDateSearch, setBookSIDateSearch] = useState(null);
  const [bill_LastFreeDayDEM, setBill_LastFreeDayDEM] = useState(null);
  const [bill_LastFreeDayDET, setBill_LastFreeDayDET] = useState(null);
  const [book_LiftingEmptyContRequestDate, setBook_LiftingEmptyContRequestDate] = useState(null);
  const [dataHistoryOrder, setDataHistoryOrder] = useState([]);

  // copy order
  const [copyOrderId, setCopyOrderId] = useState(null);
  const [showCreateOrderByCopy, setShowCreateOrderByCopy] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);

  const [filter, setFilter] = useState({
    PageNumber: 1,
    PageSize: 10,
    Text: '',
    Columns: '',
    DateFrom: moment().startOf('month').format(),
    DateTo: moment().endOf('month').format(),
  });

  const getOrders = async (currentFilter) => {
    setLoading(true);
    const filterFormatted = { ...currentFilter };
    if (filterFormatted?.Columns && Object.keys(filterFormatted?.Columns).length) {
      const columnsSearch = {};
      for (const key of Object.keys(filterFormatted?.Columns)) {
        (filterFormatted.Columns[key] === 0 || filterFormatted.Columns[key]) &&
          (columnsSearch[key] = filterFormatted.Columns[key]);
      }
      filterFormatted.Columns = JSON.stringify(columnsSearch);
    } else {
      delete filterFormatted?.Columns;
    }

    ApiServices.getOrders(filterFormatted)
      .then((res) => {
        setTotalRecords(res.data?.totalRecords);
        setOrders(res.data?.data || []);
      })
      .catch((err) => {
        console.error('Get Orders Error', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getOrders(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value && value !== 0) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, PageNumber: 1, Columns };
    });
  };

  const onChangeFilter = (val) => {
    setFilter({ ...filter, ...val });
  };
  const reload = async () => {
    await getOrders();
  };
  //----------- Start ----- Pagination Search ---------
  const searchByColumn = filter?.Columns;

  const deleteOrder = async (record) => {
    try {
      const res = await ApiServices.deleteOrder(record.id);
      if (res.data) {
        message.success('Xoá Đơn Hàng thành công');
        return reload();
      }
      message.error('Xoá Đơn Hàng thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };

  const isDisabledEditInfo = (record) => {
    return ![ORDER_STATUS.INITIAL, ORDER_STATUS.PLANED, ORDER_STATUS.DOING].includes(record.status);
  };
  // const isDisabledEditCont = (record) => {
  //   return ![ORDER_STATUS.INITIAL, ORDER_STATUS.PLANED].includes(record.status);
  // };
  const onClickShowRevise = async (record) => {
    try {
      const res = await ApiServices.getRvsById(record?.id);
      if (res?.data?.data) {
        setDataHistoryOrder(res?.data?.data);
        setShowRevise(true);
      } else {
        setDataHistoryOrder([]);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleChangeBookCutOffTimeSearch = (date) => {
    setBookCutOffTimeSearch(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'book_CutOffDateTime');
  };
  const handleChangeBookSIDateSearch = (date) => {
    setBookSIDateSearch(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'book_SIDateTime');
  };
  const handleChangeBill_LastFreeDayDEM = (date) => {
    setBill_LastFreeDayDEM(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'bill_LastFreeDayDEM');
  };

  const handleChangeBill_LastFreeDayDET = (date) => {
    setBill_LastFreeDayDET(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'bill_LastFreeDayDET');
  };
  const handleChangeBook_LiftingEmptyContRequestDate = (date) => {
    setBook_LiftingEmptyContRequestDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'book_LiftingEmptyContRequestDate');
  };

  const showNote = (value, index) => {
    return <div key={index}>**{value}</div>;
  };

  const renderNote = (value) => {
    if (!value) return '';
    const splitNote = value.split(';');
    if (splitNote?.length) {
      return splitNote?.map(showNote);
    } else {
      return <span>{value === 'null' ? '' : value}</span>;
    }
  };

  const columns = [
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Trạng Thái</div>
          <Select
            placeholder='Search'
            style={{ width: 100, fontSize: '11px' }}
            defaultValue=''
            onChange={(status) => onColumnSearch(status, 'status')}
          >
            {ORDER_OPTION_SELECTS.map((item) => (
              <Option key={item.label + item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </span>
      ),
      dataIndex: 'status',
      width: 120,
      align: 'center',
      render: (value) => <OrderStatus status={value} />,
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Đơn Hàng</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn?.code}
            name='code'
            onChange={onColumnSearch}
          />
        </span>
      ),
      dataIndex: 'code',
      width: 150,
      align: 'center',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Khách Hàng</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn?.customerName}
            name='customerName'
            onChange={onColumnSearch}
          />
        </span>
      ),
      dataIndex: 'customerName',
      width: 120,
      align: 'center',
    },
    {
      title: 'SL Cont',
      dataIndex: 'arrIdCont',
      width: 50,
      align: 'center',
      render: (value) => value?.length,
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Loại ĐH</div>
          <OrderTypeSelect
            value={searchByColumn?.orderType}
            onChange={(event) => onColumnSearch(event?.target?.value, 'orderType')}
            name='orderType'
          />
        </span>
      ),
      dataIndex: 'orderType',
      width: 96,
      align: 'center',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Nguồn</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn?.source}
            name='source'
            onChange={onColumnSearch}
          />
        </span>
      ),
      dataIndex: 'source',
      width: 100,
      align: 'center',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Hãng Tàu</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn?.shippingLineName}
            name='shippingLineName'
            onChange={onColumnSearch}
          />
        </span>
      ),
      dataIndex: 'shippingLineName',
      width: 120,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Cắt SI
          <DatePicker
            value={bookSIDateSearch}
            onChange={handleChangeBookSIDateSearch}
            placeholder='Search'
            style={{ width: '100%', fontSize: '10px' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'book_SIDateTime',
      width: 120,
      align: 'center',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: (
        <TableTitle>
          Cắt Máng
          <DatePicker
            value={bookCutOffTimeSearch}
            onChange={handleChangeBookCutOffTimeSearch}
            placeholder='Search'
            style={{ width: '100%', fontSize: '10px' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'book_CutOffDateTime',
      width: 120,
      align: 'center',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: (
        <TableTitle>
          HH Lưu Cont
          <DatePicker
            value={bill_LastFreeDayDEM}
            onChange={handleChangeBill_LastFreeDayDEM}
            placeholder='Search'
            style={{ width: '100%', fontSize: '10px' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'bill_LastFreeDayDEM',
      width: 120,
      align: 'center',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: (
        <TableTitle>
          YC Lấy Rỗng
          <DatePicker
            value={book_LiftingEmptyContRequestDate}
            onChange={handleChangeBook_LiftingEmptyContRequestDate}
            placeholder='Search'
            style={{ width: '100%', fontSize: '10px' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'book_LiftingEmptyContRequestDate',
      width: 120,
      align: 'center',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: (
        <TableTitle>
          HH Trả Rỗng
          <DatePicker
            value={bill_LastFreeDayDET}
            onChange={handleChangeBill_LastFreeDayDET}
            placeholder='Search'
            style={{ width: '100%', fontSize: '10px' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'bill_LastFreeDayDET',
      width: 120,
      align: 'center',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Ghi Chú</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn?.note}
            name='note'
            onChange={onColumnSearch}
          />
        </span>
      ),
      dataIndex: 'note',
      width: 250,
      render: renderNote,
    },
    {
      title: 'Người Tạo',
      dataIndex: 'createdBy',
      align: 'center',
      width: 100,
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Ngày Booking/Bill/Ck</div>
        </span>
      ),
      width: 80,
      dataIndex: 'createdOn',
      render: (value, record) => Utils.formatDateHourFn(value, record),
    },
    {
      title: '#',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <>
          {record?.status !== 9 && (
            <Button
              onClick={() => {
                setUpdateOrder(record.id);
                setShowDetail(false);
              }}
              disabled={isDisabledEditInfo(record)}
              type='link'
              size='small'
            >
              Sửa Thông Tin
            </Button>
          )}
          {record?.orderType !== 'Transit' && (
            <Button
              onClick={() => setUpdateContOrder(record.id)}
              // disabled={isDisabledEditCont(record)}
              type='link'
              size='small'
            >
              Sửa C.Tiết Cont
            </Button>
          )}
          <Button
            onClick={() => {
              setUpdateOrder(record.id);
              setShowDetail(true);
            }}
            type='link'
            size='small'
          >
            Xem chi tiết
          </Button>
        </>
      ),
    },
    {
      title: '#',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (record) => {
        return (
          <>
            <Popconfirm
              onConfirm={() => deleteOrder(record)}
              title='Xoá Đơn Hàng này?'
              okText='Yes'
              cancelText='No'
              disabled={record.status === 0 ? false : true}
            >
              <Button
                disabled={record.status === 0 ? false : true}
                type='primary'
                danger
                className='ml-2'
                size='small'
              >
                Xoá
              </Button>
            </Popconfirm>
            <Button type='link' size='small' danger onClick={() => onClickShowRevise(record)}>
              Xem ls
            </Button>
            <hr />
            <Button
              onClick={() => {
                setCopyOrderId(record.id);
                setShowCreateOrderByCopy(false);
              }}
              type='link'
              size='small'
            >
              Copy và Tạo
            </Button>
          </>
        );
      },
    },
  ];

  const handleExportExcel = async (query) => {
    if (loading) return;
    const dateFrom = query?.DateFrom;
    const dateTo = query?.DateTo;
    const newQuery = {
      // DateFrom: dateFrom,
      // DateTo: dateTo,
      ...query,
      export: true,
    };
    setLoading(true);
    try {
      const { data } = await ApiServices.exportOrders(newQuery);
      if (data) {
        const outputFilename = `Danh_Sach_Don_Hang_Tu_Ngay_${moment(dateFrom).format(
          'DD-MM-YYYY'
        )}_Den_Ngay_${moment(dateTo).format('DD-MM-YYYY')}.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoading(false);
    }
  };

  const onUploadExcel = (file, api) => {
    if (loading) return;
    setLoading(true);
    api(file)
      .then((res) => {
        // TH 1: Tất cả đều import thất bại (data = null)
        if (res?.data?.errors?.length > 0) {
          window.TmsNoti?.error('Import không thành công');
          res?.data?.errors?.length > 0 &&
            window.TmsNoti?.error(
              'Lý do',
              res?.data?.errors?.map((item, index) => (
                <div key={index}>
                  <b className='red'>
                    {index + 1}
                    {')'}
                  </b>
                  {item}
                </div>
              )),
              20
            );
        }
        if (res.data?.message === 'success') {
          message.success('Import thành công!');
          return reload();
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT CARR ERROR', err);
        message.error('Thao tác thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };

  // const onUploadExcelBooking = (file) => onUploadExcel(file, ApiServices.importBooking);
  const onUploadExcelBill = (file) => onUploadExcel(file, ApiServices.importBill);
  // const onUploadExcelTransit = (file) => onUploadExcel(file, ApiServices.importTransit);
  const headerContent = (
    <>
      <Space className='mg-r-10'>
        <NavLink to='/bookings'>
          <Button type='primary'>Tạo Booking</Button>
        </NavLink>
        <NavLink to='/bills'>
          <Button type='primary' className='ml-2'>
            Tạo D/O
          </Button>
        </NavLink>
        <NavLink to='/transits'>
          <Button type='primary' className='ml-2'>
            Tạo Đơn Nội Địa
          </Button>
        </NavLink>
        <RangeDateFilter onChange={onChangeFilter} query={filter} />
        <Button
          type='primary'
          icon={<DownloadOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px' }}
          onClick={() => handleExportExcel(filter)}
          loading={loading}
        >
          Export Excel
        </Button>
      </Space>
      <Space className='mg-t-10'>
        {/* <UploadExcelButton
          loading={loading}
          onChange={onUploadExcelBooking}
          templateUrl={BOOK_EXCEL_TEMPLATE_URL}
          importLabel='Import Booking'
          downloadLabel='Tải Mẫu Excel Booking'
        /> */}
        <UploadExcelButton
          loading={loading}
          onChange={onUploadExcelBill}
          templateUrl={BILL_EXCEL_TEMPLATE_URL}
          importLabel='Import D/O từ ONP'
          downloadLabel='Tải Mẫu Excel D/O'
        />
        {/* <UploadExcelButton
          loading={loading}
          onChange={onUploadExcelTransit}
          templateUrl={TRANSIT_EXCEL_TEMPLATE_URL}
          importLabel='Import CK'
          downloadLabel='Tải Mẫu Excel CK'
        /> */}
      </Space>
    </>
  );
  const rowSelection = () => {};
  const onHandleRow = (record, _, __, expanded) => {
    return <SubRow id={record.id} expanded={expanded} />;
  };

  const showRowDataSource = (record) => {
    switch (record.source) {
      case 'ONP':
        return 'order-from-ONP';
      case 'WMS':
        return 'order-from-WMS';
      default:
        break;
    }
  };
  const closeShowRevise = () => {
    setShowRevise(false);
    setDataHistoryOrder([]);
  };
  const updateListOrder = () => {
    timeoutGetData(filter);
  };

  const closeUpdateInfo = () => {
    setUpdateOrder(null);
    setShowDetail(false);
  };
  const closeCreateOrderByCopy = () => {
    setCopyOrderId(null);
    setShowCreateOrderByCopy(false);
  };
  const closeUpdateCont = () => setUpdateContOrder();

  const pagination = {
    total: totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <div className='table-content'>
      <div style={{ marginBottom: 16 }}>{headerContent}</div>
      <UpdateInformation
        orderId={updateOrder}
        showDetail={showDetail}
        onClose={closeUpdateInfo}
        reload={() => timeoutGetData(filter)}
      />
      <CreateOrderByCopy
        orderId={copyOrderId}
        showDetail={showCreateOrderByCopy}
        onClose={closeCreateOrderByCopy}
        reload={() => timeoutGetData(filter)}
      />
      <UpdateDetailCont
        orderId={updateOrderContDetail}
        onClose={closeUpdateCont}
        reload={updateListOrder}
      />
      <Table
        rowKey='id'
        className='components-table-demo-nested'
        rowClassName={showRowDataSource}
        bordered
        columns={columns}
        rowSelection={rowSelection}
        loading={loading}
        scroll={{ y: `calc(100vh - ${HEADER_LENGTH})` }}
        expandable={{
          expandedRowRender: onHandleRow,
          expandIconColumnIndex: 1,
        }}
        dataSource={orders}
        pagination={pagination}
        size='small'
      />
      <Modal
        title='Lịch sử thay đổi đơn hàng'
        open={showRevise}
        onCancel={closeShowRevise}
        onOk={closeShowRevise}
        width='80%'
      >
        <ShowHistoryOrder data={dataHistoryOrder} />
      </Modal>
    </div>
  );
};
