import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Typography, Checkbox, message } from 'antd';
import { Selection } from '~components';
import styled from 'styled-components';
import ApiServices from '~services';
import useApiHook from '../wb-hook/api-hook';
import useDataSelectSearch from '~hooks/use-data-select-search';
import { CONT_ORDER_TYPES, TASK_SORT_STATUSES, TOUR_KINDS } from '~commons/constants';

// const ANCHORAGE_QUERY_STATUS = 1;
const LABEL_START = 'Điểm bắt đầu';
const LABEL_END = 'Điểm kết thúc';
const LABEL_CHANGE_TASK_JOB = {
  START: {
    [CONT_ORDER_TYPES.BILL + TOUR_KINDS.LIFT_ON]: 'Lấy hàng nhập',
    [CONT_ORDER_TYPES.BOOKING + TOUR_KINDS.LIFT_ON]: 'Lấy rỗng xuất',
    [CONT_ORDER_TYPES.BILL + TOUR_KINDS.LIFT_OFF]: 'Lấy rỗng nhập',
    [CONT_ORDER_TYPES.BOOKING + TOUR_KINDS.LIFT_OFF]: 'Lấy hàng xuất',
  },
  END: {
    [CONT_ORDER_TYPES.BILL + TOUR_KINDS.LIFT_ON]: 'Giao hàng nhập',
    [CONT_ORDER_TYPES.BOOKING + TOUR_KINDS.LIFT_ON]: 'Giao rỗng xuất',
    [CONT_ORDER_TYPES.BILL + TOUR_KINDS.LIFT_OFF]: 'Giao rỗng nhập',
    [CONT_ORDER_TYPES.BOOKING + TOUR_KINDS.LIFT_OFF]: 'Giao hàng xuất',
  },
};
const TRANSIT_CONT_DATA = [0];
export const ChangeTaskJobForm = ({ job, onClose, setLoading, reload }) => {
  const isOpen = !!job;
  const [tasklist, setTasklist] = useState([]);
  const [taskjob, setTaskJob] = useState();
  const [conts, setConts] = useState([]);
  const [anchorage, setAnchorage] = useState(false);
  const [val, setVal] = useState();
  const [showSelectLocation, setShowSelectLocation] = useState(false);
  const [locations, getLocations] = useDataSelectSearch(ApiServices.getLocations);
  const { reloadLiftOff, reloadLiftOn, reloadParkingCont, reloadWaitingOfDelivery } = useApiHook();

  const selectedTaskJob = (selected = []) => {
    return ApiServices.getListByArrayId(selected)
      .then(({ data }) => {
        if (data) {
          const dataIndex = data?.data?.findIndex(
            (item) =>
              (item.kind === TOUR_KINDS.LIFT_ON &&
                (item.sort === TASK_SORT_STATUSES.LIFT_ON_START ||
                  item.sort === TASK_SORT_STATUSES.LIFT_ON_END)) ||
              (item.kind === TOUR_KINDS.LIFT_OFF &&
                (item.sort === TASK_SORT_STATUSES.LIFT_OFF_START ||
                  item.sort === TASK_SORT_STATUSES.LIFT_OFF_END))
          );
          const tasks = data.data;
          if (dataIndex !== -1) {
            setShowSelectLocation(true);
            setVal({
              start: {
                endLocation: tasks[0]?.endLocationCode,
                endLocationName: tasks[0]?.endLocationName,
                jobId: tasks[0]?.id,
              },
              end: {
                endLocation: tasks[1]?.endLocationCode,
                endLocationName: tasks[1]?.endLocationName,
                jobId: tasks[1]?.id,
              },
            });
          } else {
            setVal();
          }
        }
      })
      .catch((error) => {
        console.error('get-List-TaskJob-By-Arr-ID-ERR', error);
        setVal();
      });
  };
  const getContData = (Query) => {
    ApiServices.getContsByStatus(Query, TRANSIT_CONT_DATA, true)
      .then((res) => {
        setConts(res.data?.data);
      })
      .catch((err) => console.error('GetContsByStatus Error', err));
  };
  const handleClose = () => {
    onClose();
    setTasklist([]);
    setTaskJob(null);
    setShowSelectLocation(false);
    setVal();
  };
  const onSubmit = useCallback(async () => {
    const dataSend = {
      assignId: job?.assignId,
      jobId_Out: job?.jobIdOutList,
      jobId_In: [
        {
          ...val?.start,
        },
        {
          ...val?.end,
          anchorage: hasAnchorage && anchorage,
        },
      ],
      transitCont: val.transitCont,
    };

    try {
      setLoading(true);
      const { status } = await ApiServices.changeTaskJob(dataSend);
      if (status === 200) {
        handleClose();
        setLoading(false);
        message.success('Thay đổi thành công');
        reloadLiftOff();
        reloadLiftOn();
        reloadParkingCont();
        reloadWaitingOfDelivery();
        reload();
      } else {
        message.error('Thay đổi thất bại');
      }
    } catch (error) {
      console.error(error);
      message.error('Thay đổi thất bại');
    } finally {
      setLoading(false);
    }
  }, [val, anchorage]);
  const getTaskJobsAvailable = async (Query) => {
    const queryColumn = {
      Columns: JSON.stringify({
        subnoNumber: Query,
      }),
    };
    try {
      let res;
      if (job?.kind === TOUR_KINDS.LIFT_ON) {
        res = await ApiServices.getTaskJobsListToChangeKindA(job?.contId, queryColumn);
      } else {
        res = await ApiServices.getTaskJobsListToChangeKindB(job?.contId, queryColumn);
      }
      if (res.data) {
        setTasklist(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const contData = useMemo(() => {
    const ind = tasklist.findIndex((item) => item.contId === taskjob);
    if (ind === -1) {
      return null;
    }
    return tasklist[ind];
  }, [taskjob, tasklist]);

  const hasAnchorage =
    (contData?.kind !== TOUR_KINDS.LIFT_OFF && contData?.type === CONT_ORDER_TYPES.BOOKING) ||
    (job?.kind !== TOUR_KINDS.LIFT_ON && contData?.type === CONT_ORDER_TYPES.BILL);
  const disabledButton =
    !val || ((!val?.start?.endLocation || !val?.end?.endLocation) && showSelectLocation);
  const onSearchLocation = (Query) => getLocations({ Query }, 'name');
  const labelStartInput =
    LABEL_CHANGE_TASK_JOB.START[contData?.type + contData?.kind] || LABEL_START;
  const labelEndInput = LABEL_CHANGE_TASK_JOB.END[contData?.type + contData?.kind] || LABEL_END;
  const isTransit = job?.kind === TOUR_KINDS.TRANSIT;

  useEffect(() => {
    if (job?.contId) {
      let isMounted = true;
      isMounted && getTaskJobsAvailable();
      return () => {
        isMounted = false;
      };
    }
  }, [job?.contId]);

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    if (taskjob) {
      const ind = tasklist.findIndex((item) => item.contId === taskjob);
      if (ind !== -1) {
        selectedTaskJob([tasklist[ind]?.jobId]);
      } else {
        setShowSelectLocation(false);
      }
    }
  }, [taskjob]);

  useEffect(() => {
    isTransit && getContData();
  }, [isTransit]);

  const onChangeTransitCont = (cont, objCont) => {
    if (!cont) {
      setVal();
      setShowSelectLocation(false);
      return;
    }
    const data = objCont.data;
    setVal({
      start: {
        endLocation: data?.locationOfReciptCode,
        endLocationName: data?.locationOfReciptName,
        jobId: null,
      },
      end: {
        endLocation: data?.locationOfDeliveryCode,
        endLocationName: data?.locationOfDeliveryName,
        jobId: null,
      },
      transitCont: cont,
    });
    setShowSelectLocation(true);
  };
  const onChangeTaskJob = (value) => {
    setTaskJob(value);
  };
  const onChangeEndLocation = (val) => {
    setVal((prev) => ({
      ...prev,
      end: {
        ...prev?.end,
        endLocation: val,
      },
    }));
  };
  const onChangeStartLocation = (val) => {
    setVal((prev) => ({
      ...prev,
      start: {
        ...prev?.start,
        endLocation: val,
      },
    }));
  };
  const onChangeAnchorage = (e) => {
    setAnchorage(e.target.checked);
  };

  return (
    <>
      <Modal
        title={`Thay đổi công việc "${job?.objCont?.subNoNumber}"`}
        width={500}
        style={{
          top: 20,
        }}
        open={isOpen}
        onOk={onSubmit}
        onCancel={handleClose}
        okButtonProps={{
          disabled: disabledButton,
        }}
      >
        <Wrapper>
          <Typography>Chọn bộ công việc thay thế</Typography>
          {isTransit ? (
            <Selection
              data={conts}
              width={460}
              onSearch={getContData}
              fieldValue='subNoNumber'
              placeholder='Cont chuyển kho'
              onChange={onChangeTransitCont}
              value={val?.transitCont}
            />
          ) : (
            <Selection
              data={tasklist}
              width={460}
              onSearch={getTaskJobsAvailable}
              value={taskjob}
              onChange={onChangeTaskJob}
              fieldValue='name'
              placeholder='Chọn bộ công việc thay thế'
              typeOption='changeTaskJob'
            />
          )}

          {val && showSelectLocation && (
            <>
              <Typography>{labelStartInput}</Typography>
              <Selection
                data={locations}
                onSearch={onSearchLocation}
                fieldValue='name'
                placeholder='Nhập địa điểm'
                onChange={onChangeStartLocation}
                value={val?.start?.endLocation}
                labelDefault={val?.start?.endLocationName}
              />
              <ReturnPark>
                <Typography>{labelEndInput}</Typography>
                {hasAnchorage && (
                  <CheckboxStyled value={anchorage} onChange={onChangeAnchorage}>
                    Lưu cont
                  </CheckboxStyled>
                )}
              </ReturnPark>

              <Selection
                data={locations}
                onSearch={onSearchLocation}
                fieldValue='name'
                placeholder='Nhập địa điểm'
                value={val?.end?.endLocation}
                onChange={onChangeEndLocation}
                labelDefault={val?.end?.endLocationName}
              />
            </>
          )}
        </Wrapper>
      </Modal>
    </>
  );
};
const Wrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const ReturnPark = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const CheckboxStyled = styled(Checkbox)`
  font-size: 16px;
  font-weight: 500;
`;
