import React from 'react';
import { Table, Typography } from 'antd';
import { COLUMNS } from './constants';
const { Text } = Typography;
export const TableData = ({ loading, data }) => {
  return (
    <Table
      dataSource={data}
      loading={loading}
      bordered
      columns={COLUMNS}
      pagination={false}
      scroll={{ x: '100%', y: '100%' }}
      summary={(pageData) => {
        let total = {
          Bill_20: 0,
          Bill_40C: 0,
          Bill_40T: 0,
          Bill_45: 0,
          Book_20: 0,
          Book_40C: 0,
          Book_40T: 0,
          Book_45: 0,
          Transit_20: 0,
          Transit_40C: 0,
          Transit_40T: 0,
          Transit_45: 0,
          sum: 0,
        };
        pageData.forEach((item) => {
          total = {
            Bill_20: total.Bill_20 + item.Bill_20,
            Bill_40C: total.Bill_40C + item.Bill_40C,
            Bill_40T: total.Bill_40T + item.Bill_40T,
            Bill_45: total.Bill_45 + item.Bill_45,
            Book_20: total.Book_20 + item.Book_20,
            Book_40C: total.Book_40C + item.Book_40C,
            Book_40T: total.Book_40T + item.Book_40T,
            Book_45: total.Book_45 + item.Book_45,
            Transit_20: total.Transit_20 + item.Transit_20,
            Transit_40C: total.Transit_40C + item.Transit_40C,
            Transit_40T: total.Transit_40T + item.Transit_40T,
            Transit_45: total.Transit_45 + item.Transit_45,
            sum: total.sum + item.sum,
          };
        });
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell>
                <Text mark strong>
                  Tổng
                </Text>
              </Table.Summary.Cell>
              {Object.keys(total).map((key) => {
                return (
                  <Table.Summary.Cell key={`Cell_total_${key}`}>
                    <Text strong type='danger'>
                      {total[key]}
                    </Text>
                  </Table.Summary.Cell>
                );
              })}
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
};
