import React from 'react';
import { Table } from 'antd';
import Utils from '~commons/utils';

const ShowListContDetail = ({ dataInfoDetail, dataListContDetail = [] }) => {
  const titleDate =
    dataInfoDetail?.orderType === 'Transit'
      ? 'Ngày Chuyển Kho'
      : dataInfoDetail?.orderType === 'Bill'
      ? 'Ngày yêu cầu lấy cont'
      : 'Ngày yêu cầu giao cont';
  const renderYCGCDate = (_, record) => {
    let date = '';
    if (dataInfoDetail?.orderType === 'Booking') {
      date = record?.book_DeliveryContRequestDate
        ? Utils.formatDate(record?.book_DeliveryContRequestDate)
        : '';
    }
    if (dataInfoDetail?.orderType === 'Bill') {
      date = record?.bill_LiftingLadenContRequestDate
        ? Utils.formatDate(record?.bill_LiftingLadenContRequestDate)
        : '';
    }
    if (dataInfoDetail?.orderType === 'Transit') {
      date = record?.transit_Date ? Utils.formatDate(record?.transit_Date) : '';
    }
    return date;
  };

  const renderTypeCont = (value, record) => {
    const infoTypeCont = record?.contTypeObj?.find((item) => item?.id === value);
    if (!infoTypeCont) return <></>;
    return <div>{infoTypeCont?.name}</div>;
  };
  const columns = [
    {
      title: 'STT',
      dataIndex: 'orderNumber',
      align: 'center',
    },
    {
      title: 'Subno',
      dataIndex: 'subNoNumber',
      align: 'center',
    },
    {
      title: 'Loại Cont',
      dataIndex: 'typeCont',
      align: 'center',
      render: (val, record) => renderTypeCont(val, record),
    },
    {
      title: 'Số Cont',
      dataIndex: 'contNumber',
      align: 'center',
    },
    {
      title: titleDate,
      dataIndex: 'deliveryContRequestDate',
      align: 'center',
      render: (val, record) => renderYCGCDate(val, record),
    },
    {
      title: 'Trọng tải',
      dataIndex: 'cargoWeight',
      align: 'center',
    },
  ];

  return (
    <>
      <Table
        size='small'
        key='id'
        columns={columns}
        dataSource={dataListContDetail}
        bordered
        // scroll={{ x: '100%', y: '100%' }}
        pagination={false}
      />
    </>
  );
};
export { ShowListContDetail };
