import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { Wrapper, Title } from '~commons/styles';
import ApiServices from '~services';
import { TableLayoutNew } from '~components';
import { getColumns } from './columns';
import { showListFile } from '~store/reducers/white-board/action';

const DEFAULT_QUERIES = {
  Columns: {
    orderType: 'Booking',
  },
};

export const CompletedList = ({ partnerSelectedId, tabKey, filter }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataCompletedList, setDataCompletedList] = useState([]);
  const [filterNew, setFilterNew] = useState(DEFAULT_QUERIES);

  const getData = async (partnerId, queryDate, query = DEFAULT_QUERIES) => {
    const newQuery = {
      ...query,
      DateFrom: queryDate?.DateFrom,
      DateTo: queryDate?.DateTo,
    };
    setLoading(true);
    try {
      const res = await ApiServices.getListContCompletedByPartnerId(partnerId, newQuery);
      if (res?.data) {
        setDataCompletedList(res?.data);
      }
    } catch (error) {
      console.log('getListContCompletedByPartnerId:::', error);
    } finally {
      setLoading(false);
    }
  };

  const onColumnSearch = (event, select = '') => {
    setFilterNew((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };
  const onColumnSearchSelect = (event, select = '') => {
    setFilterNew((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value && value !== 0) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newPartnerId, filterOrigin, newFilter) => {
      getData(newPartnerId, filterOrigin, newFilter);
    }, 500),
    []
  );

  useEffect(() => {
    if (partnerSelectedId && tabKey === '2') {
      timeoutGetData(partnerSelectedId, filter, filterNew);
    }
  }, [partnerSelectedId, filter, tabKey, filterNew]);

  const showOrderFiles = (record) => {
    dispatch(showListFile(record));
  };

  const pagination = {
    total: dataCompletedList?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filterNew.PageSize,
    current: filterNew.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilterNew((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <Wrapper>
      <Title>
        Total: <b className='red'>({dataCompletedList?.totalRecords || 0})</b>
        <span style={{ marginLeft: '10px' }}> </span>
      </Title>
      <TableLayoutNew
        rowKey='id'
        headerLength='320px'
        columns={getColumns(onColumnSearch, onColumnSearchSelect, showOrderFiles)}
        data={dataCompletedList?.data || []}
        loading={loading}
        // selected={selected}
        // selectedParent={selectedMultipleTicket}
        // onHandleSelectRow={handleSelectRow}
        // onHandleSelectAll={handleSelectAll}
        // closeDrawer={() => setSelected(null)}
        pagination={pagination}
        // rowClassName={renderRowClassName}
      />
    </Wrapper>
  );
};
