import React, { useRef } from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';

const ShippingLineForm = ({ fields, isNew, id, reload, onChange, setLoading }) => {
  const inputRef = useRef(null);

  const onCompareWithApi = (value, cb) => {
    if (inputRef.current) {
      clearTimeout(inputRef.current);
      cb();
    }
    inputRef.current = setTimeout(async () => {
      try {
        const { data } = await ApiServices.existsCodeShippingLine(null, value);
        const isUsed = data;
        cb(isUsed);
      } catch (error) {
        console.error(error);
      }
    }, 1000);
  };

  const checkExistsCode = async (_, value) => {
    if (value.length > 1) {
      const ind = fields.findIndex((item) => {
        return item.name[0] === 'code';
      });
      if (isNew === false && value !== fields[ind].value) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Mã Hãng Tàu này đã tạo rồi !!!'));
        }
      }

      if (isNew === true) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Mã Hãng Tàu này đã tạo rồi !!!'));
        }
      }
    }
  };

  const createShippingLine = async (data) => {
    try {
      const res = await ApiServices.createShippingLine(data);
      if (res.data) {
        message.success('Tạo mới Hãng tàu thành công');
        return reload();
      } else {
        message.error('Tạo mới Hãng tàu thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Tạo mới Hãng tàu thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const updateShippingLine = async (data) => {
    data.id = id;
    try {
      const res = await ApiServices.updateShippingLine(id, data);
      if (res.data) {
        message.success('Cập nhật hãng tàu thành công');
        return reload();
      } else {
        message.error('Cập nhật hãng tàu thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật hãng tàu thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createShippingLine(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updateShippingLine(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      name='global_state'
      className='form-input-data'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        // {...itemLayout}
        style={{ marginTop: '10px' }}
        name='code'
        label='Mã Hãng Tàu'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Mã Hãng Tàu',
          },
          {
            validator: checkExistsCode,
          },
          {
            type: 'string',
            min: 2,
            message: 'Bạn phải nhập ít nhất 2 ký tự',
          },
        ]}
      >
        <Input placeholder='Mã Hãng Tàu' />
      </Form.Item>

      <Form.Item
        style={{ marginTop: '10px' }}
        name='name'
        label='Tên Hãng Tàu'
        rules={[
          {
            required: true,
            message: 'bạn phải nhập Tên Hãng Tàu',
          },
        ]}
      >
        <Input placeholder='Tên Hãng Tàu' />
      </Form.Item>
      <Form.Item
        name='address'
        label='Địa chỉ'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Địa chỉ',
          },
        ]}
      >
        <Input placeholder='Địa chỉ' />
      </Form.Item>
      <Form.Item
        name='phone'
        label='Số Điện thoại'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Số Điện thoại',
          },
        ]}
      >
        <Input placeholder='Số Điện thoại' type='number' />
      </Form.Item>
      <Form.Item name='fax' label='Fax'>
        <Input placeholder='Fax' />
      </Form.Item>

      <Form.Item name='contactPerson' label='Người Liên Hệ'>
        <Input placeholder='Người Liên Hệ' />
      </Form.Item>
      <Form.Item name='contactPosition' label='Chức vụ'>
        <Input placeholder='Chức vụ' />
      </Form.Item>
      <Form.Item valuePropName='replyEmail' label='Trả lời Email'>
        <Checkbox />
      </Form.Item>
      {/* <Form.Item valuePropName="isActive" label="Hoạt động">
        <Checkbox />
      </Form.Item> */}
      <Form.Item {...tailLayout} className='submit-form-input-data'>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Tạo Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { ShippingLineForm };
