import { useState } from 'react';
import { Button, Select, Space } from 'antd';
import styled from 'styled-components';
import { CONT_ORDER_TYPES } from '~commons/constants';
import storage from '~storage';

const { BOOKING, BILL, TRANSIT } = CONT_ORDER_TYPES;
export const OrderTypeSelect = ({
  value = '',
  name,
  onChange,
  selectChange,
  width = '100%',
  size = 'small',
}) => {
  const [val, setVal] = useState(value);

  const onChangeSelect = (value) => {
    setVal?.(value);
    selectChange?.(value);
    return onChange?.({
      target: {
        name,
        value,
      },
    });
  };

  return (
    <SelectStyled
      placeholder='Loại ĐH'
      value={val}
      onChange={onChangeSelect}
      size={size}
      width={width}
    >
      <Select.Option value=''>Tất cả</Select.Option>
      <Select.Option value={BOOKING}>Book</Select.Option>
      <Select.Option value={BILL}>Bill</Select.Option>
      <Select.Option value={TRANSIT}>Transit</Select.Option>
    </SelectStyled>
  );
};

export const WBOrderTypeSelect = ({ value, onChange }) => {
  const [val, setVal] = useState(value);

  const getType = (option) => option == val && 'danger';
  const onChangeData = (value) => {
    setVal(value);
    onChange?.(value);
  };
  const onclick = (option) => {
    if (option === val) {
      return onChangeData();
    }
    onChangeData(option);
  };
  return (
    <ChooseSelector block direction='vertical' size='small' className='order-choose'>
      {/* <SelectSelector type={getType()} onClick={() => onclick()}>
        Tất cả
      </SelectSelector> */}
      <SelectSelector type={getType(BOOKING)} onClick={() => onclick(BOOKING)}>
        Book
      </SelectSelector>
      <SelectSelector type={getType(BILL)} onClick={() => onclick(BILL)}>
        Bill
      </SelectSelector>
    </ChooseSelector>
  );
};

export const TransportTypeSelect = ({ onChange, value, disabled = false }) => {
  const types = storage.staticVariables?.TransportType;
  return (
    <Select placeholder='Loại vận chuyển' disabled={disabled} onChange={onChange} value={value}>
      {Object.keys(types).map((item) => (
        <Select.Option key={item} value={types[item]}>
          {types[item]}
        </Select.Option>
      ))}
    </Select>
  );
};
const SelectSelector = styled(Button)`
  font-size: 10px;
  font-weight: 500;
  padding: 0;
  height: 19px;
  border-radius: 4px;
  ${({ type }) => type && 'font-weight: bold;'}
`;
const ChooseSelector = styled(Space.Compact)`
  margin-left: 4px;
  width: 38px;
`;
const SelectStyled = styled(Select)`
  width: ${({ width }) => width};
`;
