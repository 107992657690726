import React from 'react';
import { Table } from 'antd';
import DrawerLeft from './drawer-left';

class TableLayout extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedRowKeys: [],
      loading: false,
      isDrawerVisible: false,
    };

    this.onSelectChange = this.onSelectChange.bind(this);
  }
  onSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys });
  }
  render() {
    const {
      columns,
      data,
      loading,
      selected,
      closeDrawer,
      headerContent,
      titleTab,
      tabPanels,
      isLoadingTab,
      selectParent,
      onSelectChange,
      isSelectRadioBox,
      checkDisableSelection,
      pagination,
      rowClassName,
      rowKey = null,
      width,
      headerLength = '220px',
      isDisplayCheckbox = true,
      isScrollX = true,
      customScrollSize = '0px',
      onChange,
    } = this.props;
    const type = isSelectRadioBox ? 'radio' : 'checkbox';
    const rowSelection = {
      type,
      selectedRowKeys: selectParent,
      onChange: (selectedRowKeys, selectedRows) => onSelectChange(selectedRowKeys, selectedRows),
      getCheckboxProps: checkDisableSelection
        ? (record) => ({
            disabled: checkDisableSelection(record),
            key: record.id,
          })
        : null,
    };

    const rowClass = (record) => rowClassName(record);

    return (
      <div className='table-content'>
        <div style={{ marginBottom: 10 }}>{headerContent}</div>
        <Table
          className={this.props?.className}
          rowClassName={rowClassName ? rowClass : null}
          bordered={true}
          expandable={this.props?.expandable}
          rowKey={rowKey ? rowKey : 'key'}
          rowSelection={
            isDisplayCheckbox
              ? this.props?.rowSelection || onSelectChange
                ? rowSelection
                : { type }
              : null
          }
          columns={columns}
          dataSource={data}
          scroll={
            this.props?.scroll || isScrollX
              ? { x: '100%', y: `calc(100vh - ${headerLength})` }
              : { y: `calc(100vh - ${headerLength} - ${customScrollSize})` }
          }
          loading={loading}
          pagination={pagination}
          size='small'
          onChange={onChange}
        />
        <DrawerLeft
          isLoadingTab={isLoadingTab}
          visible={!!selected}
          title={titleTab}
          onClose={closeDrawer}
          tabPanels={tabPanels}
          width={width}
        />
      </div>
    );
  }
}

export default TableLayout;
