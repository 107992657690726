import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, DatePicker, Tooltip } from 'antd';
import { Selection } from '~components';
import ApiServices from '~services';
import { DATE_FORMAT } from '~commons/constants';
import { Item } from './item';
import moment from 'moment';
import styled from 'styled-components';
import { color } from '~theme';
import { ViewHistoryForm } from './view-history-form';

const JOB_NAME_TITLE = {
  a_Booking: 'Lấy Rỗng Xuất',
  b_Booking: 'Hạ Hàng Xuất',
  a_Bill: 'Lấy Hàng Nhập',
  b_Bill: 'Trả Rỗng Nhập',
};
const ACTION_KEY = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  NO_ACTION: '',
};

export const Form = ({ contTypes = [], dataCreate, setDataCreate, setLoadingBtn, keyAction }) => {
  const [portList, setPortList] = useState([]);
  const [portId, setPortId] = useState('');
  const [effectDate, setEffectDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataHistory, setDataHistory] = useState([]);
  const [supplierBill, setSupplierBill] = useState([]);
  const [supplierNameFee, setSupplierNameFee] = useState('');

  const getHistory = async (record) => {
    if (!record.locationId || loading) return;

    setLoading(true);
    try {
      const res = await ApiServices.getLiftingFeeHistory(record.locationId);
      if (res?.data?.data?.length) {
        setDataHistory(res?.data?.data || []);
      } else {
        setDataHistory([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getSupplierBill = async (query = '') => {
    setLoading(true);
    try {
      let { data } = await ApiServices.getSupplierBills({ query });
      if (data?.data) {
        setSupplierBill(data?.data);
      }
    } catch (error) {
      console.log('Get supplier bill failed:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataCreate?.effectiveDate) {
      setEffectDate(moment(dataCreate?.effectiveDate));
    } else {
      setEffectDate(null);
    }

    if (dataCreate?.locationId) {
      setPortId(dataCreate?.locationId);
    } else {
      setPortId('');
    }
    if (dataCreate?.supplierName) {
      setSupplierNameFee(dataCreate?.supplierName);
    } else {
      setSupplierNameFee('');
    }

    if (keyAction === ACTION_KEY.UPDATE && dataCreate?.locationId) {
      getHistory(dataCreate);
    }
  }, [dataCreate]);

  const getPorts = async (query = '') => {
    const newQuery = {
      PageNumber: 1,
      PageSize: 20,
      // Columns: { typeLocationName: 'Cảng', name: query },
      Columns: { name: query },
    };
    return ApiServices.getLocations(newQuery)
      .then((res) => {
        if (res?.data?.data) {
          setPortList(res?.data?.data);
        }
      })
      .catch((err) => console.error('getPorts Error', err));
  };

  useEffect(() => {
    getPorts();
    getSupplierBill();
  }, []);

  const handleChangePort = useCallback(
    (id) => {
      setPortId(id);
      setDataCreate((prev) => {
        return { ...prev, locationId: id };
      });
    },
    [setDataCreate]
  );
  const handleChangeSupplier = useCallback(
    (value) => {
      setSupplierNameFee(value);
      setDataCreate((prev) => {
        return { ...prev, supplierName: value };
      });
    },
    [setDataCreate]
  );

  const handleChangeEffectDate = useCallback(
    (date) => {
      setEffectDate(date);
      setDataCreate((prev) => {
        return { ...prev, effectiveDate: date };
      });
    },
    [setDataCreate]
  );

  const renderTitleCont = () => {
    const numberSpan = Math.floor(24 / (contTypes?.length + 3));
    return (
      <Row
        gutter={24}
        style={{
          textAlign: 'center',
        }}
      >
        <Col span={numberSpan * 2}>
          <b>Công việc</b>
        </Col>
        <Col span={numberSpan}>
          <b>{`20"`}</b>
        </Col>
        <Col span={numberSpan}>
          <b>40C</b>
        </Col>
        <Col span={numberSpan}>
          <b>40T</b>
        </Col>
        <Col span={numberSpan}>
          <b>45</b>
        </Col>
      </Row>
    );
  };

  const renderInputTypeCont = (contTypesList = [], dataInit = [], jobName = '') => {
    if (!jobName) return;
    const numberSpan = Math.floor(24 / (contTypesList?.length + 3));

    return (
      <Row gutter={24} align='middle' style={{ marginTop: '8px' }} key={jobName}>
        <Col
          span={numberSpan * 2}
          style={{
            textAlign: 'end',
          }}
        >
          {JOB_NAME_TITLE[jobName]}
        </Col>

        {dataInit?.map((item) => {
          return (
            <Col span={numberSpan} key={item.id}>
              <Item
                jobName={jobName}
                dataItem={item}
                dataCreate={dataCreate}
                setDataCreate={setDataCreate}
                setLoadingBtn={setLoadingBtn}
              />
            </Col>
          );
        })}
      </Row>
    );
  };
  return (
    <div>
      <div
        style={{
          marginBottom: '10px',
        }}
      >
        <b>
          Cảng/Bãi <span className='red'>(*)</span>:
        </b>{' '}
        <Selection
          data={portList}
          labelDefault={dataCreate?.locationName}
          fieldValue='name'
          placeholder='Cảng/Bãi'
          disabled={keyAction === ACTION_KEY.UPDATE}
          width={500}
          value={portId}
          onChange={handleChangePort}
          onSearch={getPorts}
        />
        {!portId ? (
          <div>
            <i className='red'>Vui lòng chọn Cảng / Bãi</i>
          </div>
        ) : (
          <i></i>
        )}
        {keyAction === ACTION_KEY.UPDATE && dataHistory?.length > 0 && (
          <Tooltip
            title={
              <div
                style={{
                  maxHeight: 'calc(100vh - 100px)',
                  overflow: 'auto',
                  // width: '500px',
                }}
              >
                <ViewHistoryForm data={dataHistory} />
              </div>
            }
            color={color.warning}
          >
            <Icon>!</Icon>
          </Tooltip>
        )}
      </div>
      <div
        style={{
          marginBottom: '10px',
        }}
      >
        <b>NCC Hóa Đơn:</b>
        <Selection
          labelDefault={dataCreate?.supplierName ? dataCreate?.supplierName : ''}
          data={supplierBill}
          placeholder='Chọn NCC Hoá đơn'
          fieldValue='name'
          fieldView='name'
          typeOption='supplier'
          width={500}
          value={supplierNameFee}
          onChange={handleChangeSupplier}
          onSearch={getSupplierBill}
        />
      </div>
      <div
        style={{
          marginBottom: '10px',
        }}
      >
        <b>Ngày Hiệu Lực: </b>
        <DatePicker
          placeholder='Ngày Hiệu Lực'
          style={{ width: '200px' }}
          format={DATE_FORMAT}
          value={effectDate}
          allowClear={keyAction === ACTION_KEY.UPDATE ? false : true}
          onChange={handleChangeEffectDate}
        />
      </div>
      {renderTitleCont()}
      {/* Lấy Rỗng Xuất === a_Booking  */}
      {renderInputTypeCont(contTypes, dataCreate['a_Booking'], 'a_Booking')}
      {/* Trả Rỗng Nhập === b_Bill  */}
      {renderInputTypeCont(contTypes, dataCreate['b_Bill'], 'b_Bill')}
      {/* Hạ Hàng Xuất === b_Booking  */}
      {renderInputTypeCont(contTypes, dataCreate['b_Booking'], 'b_Booking')}
      {/* Lấy Hàng Nhập === a_Bill  */}
      {renderInputTypeCont(contTypes, dataCreate['a_Bill'], 'a_Bill')}
    </div>
  );
};

const Icon = styled.span`
  margin-left: 20px;
  font-size: 25px;
  font-weight: bold;
  color: ${color.error};
  cursor: pointer;
  padding: 0 4px;
  //   &:hover {
  //     background: ${color.border};
  //     border-radius: 2px;
  //   }
`;
