import React, { useState } from 'react';
import { Modal, Table, Button, Popover, Steps } from 'antd';
import { Empty } from 'antd';
import { PlantStatus } from '~components/common-status';
const { Step } = Steps;

function ModalInfoDetails({
  isModalVisible,
  handleCancel,
  infoDetails,
  loadingModal,
  dataProcess,
}) {
  const [isShowDetail, setIsShowDetail] = useState(true);
  const columns = [
    {
      title: 'Trạng Thái',
      dataIndex: 'status',
      width: 100,
      render: (value) => <span>{<PlantStatus status={value} />}</span>,
    },
    {
      title: 'SubNo',
      dataIndex: 'code',
      width: 200,
    },
    {
      title: 'Tên CV',
      width: 130,
      dataIndex: 'name',
    },
    {
      title: 'ĐĐ Bắt Đầu',
      width: 150,
      dataIndex: 'beginLocationName',
    },
    {
      title: 'ĐĐ Kết Thúc',
      width: 150,
      dataIndex: 'endLocationName',
    },
    {
      title: 'Romooc',
      width: 100,
      dataIndex: 'romoocCode',
    },
    {
      title: 'Số Xe',
      width: 90,
      dataIndex: 'carCode',
    },
    {
      title: 'Tài Xế',
      width: 150,
      dataIndex: 'driverName',
    },
    {
      title: 'Trọng Tải',
      dataIndex: 'objCont',
      width: 80,
      render: (value) => <span>{value.cargoWeight}</span>,
    },
    {
      title: 'Số Seal',
      width: 100,
      dataIndex: 'objCont',
      render: (value) => <span>{value.sealNumber}</span>,
    },
    {
      title: 'Số Cont',
      width: 100,
      dataIndex: 'objCont',
      render: (value) => <span>{value.contNumber}</span>,
    },
    {
      title: 'Ghi Chú',
      width: 150,
      dataIndex: 'note',
    },
  ];

  const customDot = (dot, item) => {
    return (
      <Popover
        style={{ color: 'red' }}
        key={item.key}
        content={
          <span key={item.key} className={item.status === 'finish' ? 'green' : null}>
            step {item.index} status: {item.status}
          </span>
        }
      >
        {dot}
      </Popover>
    );
  };
  return (
    <Modal
      title='Chi Tiết Công Việc'
      open={isModalVisible}
      onCancel={handleCancel}
      footer={[
        <Button key='A01' type='primary' onClick={() => setIsShowDetail(!isShowDetail)}>
          {isShowDetail ? 'Thu gọn' : 'Xem Chi Tiết'}
        </Button>,
      ]}
      width={'auto'}
      style={{ top: 40 }}
    >
      {dataProcess && dataProcess.length ? (
        <>
          <Steps progressDot={customDot}>
            {dataProcess.map((item, index) => {
              return (
                <Step
                  status={
                    item.status === 9
                      ? 'finish'
                      : item.status === 3
                      ? 'process'
                      : index === 0
                      ? 'process'
                      : item.status === -1
                      ? 'preparing'
                      : 'wait'
                  }
                  // : (item.status === 3 ? 'process' : (index === 0 ? 'process' : 'wait')) }
                  title={item.name}
                  key={item.id}
                  description={<span className='blue'>({item.description})</span>}
                />
              );
            })}
          </Steps>
          {isShowDetail && (
            <div className='list-order-container'>
              <Table
                bordered
                size='small'
                columns={columns}
                dataSource={infoDetails}
                pagination={{
                  pageSize: 10,
                }}
                style={{
                  marginTop: '10px',
                }}
                loading={loadingModal}
                scroll={{ x: '100%', y: '400px' }}
              />
            </div>
          )}
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Modal>
  );
}

export { ModalInfoDetails };
