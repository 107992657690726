import { Button, Input, DatePicker, Tooltip } from 'antd';
import Utils from '~commons/utils';
import { ContRevisedContent } from '~components';
import { WBOrderTypeSelect } from '~components/common-select';
import { CONT_STATUSES } from '~commons/constants';
import { SHORTEN_DATE_FORMAT_WB } from '~commons/constants';

function actionWaitingOfDelivery(
  record,
  onClickReportEmpty,
  onClickReportCargoReady,
  onClickReportSingleCont
) {
  switch (record.orderType) {
    case 'Booking':
      return (
        <div>
          <Button
            shape='circle'
            type='link'
            style={{ color: 'black' }}
            onClick={() => onClickReportCargoReady([record.id], record)}
            disabled={record?.book_CargoReadyDate || !record?.yes?.length}
          >
            {record?.book_CargoReadyDate ? ' Đã BX' : 'Xuất'}
          </Button>
          {record.status === CONT_STATUSES.REDOING && (
            <>
              <hr />
              <Button
                shape='circle'
                type='link'
                style={{ color: 'red' }}
                onClick={() => onClickReportSingleCont(record.id)}
              >
                Empty
              </Button>
            </>
          )}
        </div>
      );
    case 'Bill':
      return (
        <div>
          <Button
            shape='circle'
            type='link'
            style={{ color: 'black' }}
            onClick={() => onClickReportEmpty([record.id], record)}
            disabled={record?.bill_EmptyContDate || !record?.yes?.length}
          >
            {record?.bill_EmptyContDate ? ' Đã BR' : 'Rỗng'}
          </Button>
        </div>
      );
    default:
      return;
  }
}

export const getColumns = (
  setQuery,
  onClickReportEmpty,
  onClickReportCargoReady,
  setDataListFile,
  onHandleClickEditRomooc,
  showContForm,
  showCustomerDetail,
  onClickReportSingleCont,
  searchDate,
  handleChangeSearchDate,
  showSwapCont
) => [
  {
    title: (
      <span style={{ textAlign: 'center', fontSize: '10px' }}>
        <div>Khách hàng</div>
        <Input
          style={{ fontSize: '11px' }}
          placeholder=''
          allowClear
          onChange={(event) => setQuery({ customerName: event.target.value })}
        />
      </span>
    ),
    width: '80px',
    dataIndex: 'customerName',
    align: 'center',
    render: (value, record) => (
      <>
        <ContRevisedContent data={record} />
        {Utils.formatCustomerForWBDelivery(
          value,
          record,
          setDataListFile,
          showCustomerDetail,
          record?.noteDelivery
        )}
        {!!record?.contFiles?.length && (
          <Button
            type='link'
            style={{ margin: 'auto' }}
            disabled={!record?.contFiles?.length}
            onClick={() => setDataListFile({ files: record?.contFiles })}
          >
            Cont File
          </Button>
        )}
      </>
    ),
  },
  {
    title: (
      <span style={{ textAlign: 'center', fontSize: '10px' }}>
        <div>SubNo</div>
        <Input
          style={{ fontSize: '11px' }}
          placeholder=''
          allowClear
          onChange={(event) => setQuery({ allLocation: event.target.value })}
        />
      </span>
    ),
    width: '120px',
    dataIndex: 'subnoNumber',
    render: (value, record) => (
      <>
        {Utils.formatSubNoWhiteBoardWaitingOfDelivery(
          value,
          record,
          showContForm,
          record?.note,
          showSwapCont
        )}
      </>
    ),
  },
  {
    title: (
      <span style={{ textAlign: 'center', fontSize: '10px' }}>
        <Tooltip
          title={
            <div>
              <div>CM: Cắt Máng (Book)</div>
              <div>HHTR: Hết Hạn Trả Rỗng (Bill)</div>
            </div>
          }
          placement='top'
          color='#108ee9'
        >
          <div style={{ color: 'red', cursor: 'pointer' }}>CM/HHTR</div>
        </Tooltip>
        <DatePicker
          suffixIcon={null}
          value={searchDate}
          onChange={handleChangeSearchDate}
          placeholder=''
          style={{ width: '100%', padding: '2px 1px', fontSize: '9px' }}
          format={SHORTEN_DATE_FORMAT_WB}
          allowClear={true}
        />
      </span>
    ),
    width: '55px',
    align: 'center',
    dataIndex: 'orderType',
    render: (value, record) => <b>{Utils.renderDateWhiteBoardWaitingOfDelivery(value, record)}</b>,
  },
  {
    title: (
      <span style={{ textAlign: 'center', fontSize: '10px' }}>
        <div>Mooc</div>
        <Input
          placeholder=''
          style={{ padding: '3px 0px', fontSize: '11px' }}
          allowClear
          onChange={(event) => setQuery({ romoocCode: event.target.value })}
        />
      </span>
    ),
    width: '50px',
    dataIndex: 'romoocCode',
    align: 'center',
    render: (value, record) => (
      <a href='#' onClick={() => onHandleClickEditRomooc(record)}>
        {value}
      </a>
    ),
  },
  {
    title: <WBOrderTypeSelect onChange={(orderType) => setQuery({ orderType })} />,
    width: '40px',
    dataIndex: 'action',
    align: 'center',
    render: (_, record) =>
      actionWaitingOfDelivery(
        record,
        onClickReportEmpty,
        onClickReportCargoReady,
        onClickReportSingleCont
      ),
  },
];
