import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import { Button, Popconfirm, message, Space } from 'antd';
import { TableLayout } from '~components';
import { InputForm } from './input-form';
import DriverFields from './fields-data';
import ApiServices from '~services';
import { TableTitle } from '~commons/styles';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().startOf('month').format('YYYY-MM-DD'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD'),
};

const JobSkipTakePhoto = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [managementCosts, setManagementCosts] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);
  const [filter] = useState(DEFAULT_QUERIES);

  const getData = async () => {
    setLoading(true);
    return ApiServices.getTakePhotos()
      .then((res) => {
        if (res?.data) {
          setManagementCosts(res?.data);
        }
      })
      .catch((err) => {
        console.error('Get Vats Paging Error', err);
      })
      .finally(() => setLoading(false));
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const setFieldsForm = async (value) => {
    const driverFields = await DriverFields(value);
    await setFields(driverFields);
    return driverFields;
  };
  const reload = async () => {
    setSelected(null);
    await getData(filter);
  };

  const getUpdatedOilPriceForm = async (value, isNew = false, dataSelect, id = null) => {
    const driverForm = await (
      <InputForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        dataSelect={dataSelect}
        reload={reload}
        onChange={setFields}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: driverForm,
      },
    ]);
  };

  const deleteSupplierBills = async (record) => {
    setLoading(true);
    return ApiServices.deleteTakePhoto(record?.id)
      .then((res) => {
        if (res?.data?.data) {
          message.success('Xoá thành công');
          return reload();
        } else {
          message.error('Xoá thất baị, vui lòng kiểm tra lại thông tin');
        }
      })
      .catch((err) => {
        console.log('Delete oil prices failed: ', err);
        message.error('Xoá thất baị, vui lòng kiểm tra lại thông tin');
      })
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      title: <TableTitle>Name</TableTitle>,
      dataIndex: 'name',
      align: 'center',
      width: 150,
    },
    {
      title: <TableTitle>Sort</TableTitle>,
      dataIndex: 'sort',
      align: 'center',
      width: 150,
    },
    {
      title: <TableTitle>TypeOrder</TableTitle>,
      dataIndex: 'typeOrder',
      align: 'center',
      width: 150,
    },
    {
      title: <TableTitle>CompanyId</TableTitle>,
      dataIndex: 'companyId',
      align: 'center',
      width: 150,
    },
    {
      title: <TableTitle>Description</TableTitle>,
      dataIndex: 'description',
      align: 'center',
      width: 150,
    },
    {
      title: <TableTitle>Note</TableTitle>,
      dataIndex: 'note',
      align: 'center',
      width: 150,
    },
    {
      title: 'Action',
      fixed: 'right',
      align: 'center',
      width: 90,
      render: (record) => (
        <Popconfirm
          onConfirm={() => deleteSupplierBills(record)}
          title='Bạn có chắc chắn xóa?'
          okText='Có'
          cancelText='Không'
        >
          <a href='#' style={{ color: 'red' }}>
            Xoá
          </a>
        </Popconfirm>
      ),
    },
  ];

  const createDriver = async () => {
    await setTitleTab('Thêm mới');
    await setFieldsForm({})
      .then((res) => getUpdatedOilPriceForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };

  const headerContent = (
    <Space>
      <Button type='primary' onClick={createDriver}>
        Thêm Mới
      </Button>
    </Space>
  );

  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={managementCosts?.data || []}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      pagination={false}
      isDisplayCheckbox={false}
    />
  );
};

export { JobSkipTakePhoto };
