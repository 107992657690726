import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Selection } from '~components';
import { Button, Input } from 'antd';
import useApiHook from '../wb-hook/api-hook';
import { useSelector } from 'react-redux';
import { PlusOutlined, PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';
import {
  CONT_ORDER_TYPES,
  END_TASK_SUPPORT_OPTIONS,
  TASK_END_OPTIONS,
  TASK_SUPPORT_LIfT_ON_END_OPTIONS,
  TASK_SUPPORT_OPTIONS,
  TASK_SUPPORT_SHOW_ROMOOC,
  TOUR_KINDS,
} from '~commons/constants';
import { SimpleSelection } from '~components/selection';
import useDataSelectSearch from '~hooks/use-data-select-search';
import ApiServices from '~services';

const RELOCATION_VALUE = 'Relocation';
const MOOC_NGOAI = 'MOOC_NGOAI';
const TRANSIT_CONT_DATA = [0];

export const AssignItem = ({
  data,
  ind,
  parentLocations,
  orderIndex,
  listData,
  romoocs,
  conts,
}) => {
  const { updateAssignData, updateAssignRomooc, removeOneAssign } = useApiHook();

  const { dataAssign, romoocAssign, carAssign } = useSelector((state) => state.whiteBoard);
  const { selectedDataRows: selectedDataRowsLiftOff, indexSelected: indexSelectedLiftOff } =
    useSelector((state) => state.liftOff);
  const prevRomoocCodeLiftOff = useMemo(() => {
    if (selectedDataRowsLiftOff && indexSelectedLiftOff === 0) {
      return selectedDataRowsLiftOff?.[0]?.[0]?.romoocCode || '';
    }
  }, [selectedDataRowsLiftOff]);

  const [location, setLocation] = useState();
  const [addPrev, setAddPrev] = useState(true);
  const [addNext, setAddNext] = useState(true);
  const [supportTask, setSupportTask] = useState({});
  const [transit, setTransit] = useState({});
  const [endTaskName, setEndTaskName] = useState(TASK_END_OPTIONS[0].value);
  const [romoocPlan, setRomoocPlan] = useState();
  const [expandTransit, setExpandTransit] = useState(false);

  const [locations, getLocations, setLocations] = useDataSelectSearch(ApiServices.getLocations);

  const prevAssign = useMemo(() => {
    if (!ind) {
      return null;
    }
    const dataItem = listData[ind - 1];
    return dataAssign.find((item) => item.id === dataItem.id);
  }, [ind, dataAssign, listData]);
  const currentAssign = useMemo(() => {
    if (!data.id) {
      return null;
    }
    return dataAssign.find((item) => item.id === data.id);
  }, [dataAssign, data?.id]);

  const isDisabledLocation = prevAssign?.anchorage === true;
  const isDisabledLocationReportProblem = data?.endLocationName?.includes('trouble_');
  const isShowSelectLiftOn =
    ind === 1 &&
    ((data.kind === TOUR_KINDS.LIFT_ON && data?.objCont?.orderType === CONT_ORDER_TYPES.BOOKING) ||
      (data.kind === TOUR_KINDS.LIFT_OFF && data?.objCont?.orderType === CONT_ORDER_TYPES.BILL));
  const contId = data.objCont?.id;
  const onChangePrev = (prev) => setSupportTask((previous) => ({ ...previous, prev, contId }));
  const onChangePrev2 = (prev2) => setSupportTask((previous) => ({ ...previous, prev2, contId }));
  const onChangeNext = (next) => setSupportTask((previous) => ({ ...previous, next, contId }));
  const onChangeNext2 = (next2) => setSupportTask((previous) => ({ ...previous, next2, contId }));
  const onChangeTransit = (val) => setTransit(val);

  useEffect(() => {
    if (orderIndex === 1 && data.kind === TOUR_KINDS.LIFT_ON) {
      let romoocAssign =
        supportTask.prev?.taskName === TASK_SUPPORT_OPTIONS[0].value && supportTask.prev.romooc;
      if (!romoocAssign) {
        romoocAssign =
          supportTask.prev2?.taskName === TASK_SUPPORT_OPTIONS[0].value && supportTask.prev2.romooc;
      }
      // Remove check romoocAssign to save assign plan
      // remove  romoocAssign &&
      updateAssignRomooc({
        romoocId: romoocAssign?.id,
        code: romoocAssign?.code,
        newLocationId: romoocAssign?.newLocationId,
      });
    }
  }, [supportTask.prev, supportTask.prev2, orderIndex]);

  useEffect(() => setLocations(parentLocations), [parentLocations]);

  // update romooc label when car selected with romooc available
  useEffect(() => {
    if (
      (data.kind === TOUR_KINDS.LIFT_ON ||
        (!data.Kind && data?.objCont?.orderType === CONT_ORDER_TYPES.BOOKING)) &&
      orderIndex === 1
    ) {
      if (carAssign?.romoocId) {
        return updateAssignRomooc({ romoocId: carAssign.romoocId, code: carAssign.romoocName });
      }
      // updateAssignRomooc();
    }
  }, [carAssign]);

  useEffect(() => setEndTaskName(TASK_END_OPTIONS[0].value), [isShowSelectLiftOn]);

  useEffect(() => {
    if (data?.endLocationCode) {
      setLocation(data.endLocationCode);
    }
    if (orderIndex === 1 && data.kind === TOUR_KINDS.LIFT_ON && data.romoocPlanId) {
      setRomoocPlan({
        id: data.romoocPlanId,
        code: data.romoocPlanName,
        currentLocationCode: data.romoocLocationPlanId,
        currentLocationName: data.romoocLocationPlanName,
      });
    }
    if (data.kind === TOUR_KINDS.LIFT_OFF && indexSelectedLiftOff === 0) {
      updateAssignRomooc({ romoocId: data.romoocId, code: data.romoocCode });
    }
  }, [data]);

  useEffect(() => removeOneAssign(data.id || data.endLocation), []);

  useEffect(
    () => (isDisabledLocation ? setLocation() : setLocation(data?.endLocationCode)),
    [isDisabledLocation]
  );

  useEffect(
    () =>
      updateAssignData({
        id: data.id,
        endLocation: location,
        orderIndex,
        supportTask,
        kind: data.kind,
        transit,
        ...(endTaskName !== TASK_END_OPTIONS[0].value && { name: endTaskName }),
      }),
    [data.id, location, supportTask, endTaskName, transit]
  );

  const renderLabelSelection = (data) => {
    if (ind === 0) {
      if (data?.typeOrder === CONT_ORDER_TYPES.BILL) {
        return 'Điểm Lấy hàng';
      }
      return 'Điểm Lấy Rỗng';
    }
    if (data?.typeOrder === CONT_ORDER_TYPES.BILL) {
      return 'Điểm Giao hàng';
    }
    return 'Điểm Đóng hàng';
  };
  const onSearchLocation = (Query) => getLocations({ Query }, 'name');
  const onClickAddPrev = () => setAddPrev(false);
  const onClickAddNext = () => setAddNext(false);
  //Hiển thị Romooc Của xe
  const selectedRomoocCode =
    prevAssign?.supportTask?.prev?.romooc?.code || prevAssign?.supportTask?.prev2?.romooc?.code;

  const romoocLabel =
    selectedRomoocCode ||
    currentAssign?.romoocCode ||
    data.romoocCode ||
    (data.kind === 'A' && indexSelectedLiftOff === 0
      ? prevRomoocCodeLiftOff
        ? prevRomoocCodeLiftOff
        : ''
      : romoocAssign.code);
  //Show task name for second task
  const getLabelSelectTask = (el) => el[data?.objCont?.orderType];
  const dataOptionSecondTask =
    (!data.Kind && data?.objCont?.orderType === CONT_ORDER_TYPES.BOOKING) ||
    data.kind === TOUR_KINDS.LIFT_ON
      ? TASK_SUPPORT_LIfT_ON_END_OPTIONS
      : END_TASK_SUPPORT_OPTIONS;
  const isLiftOff =
    data.kind === TOUR_KINDS.LIFT_OFF ||
    (!data.kind && data?.objCont?.orderType === CONT_ORDER_TYPES.BILL);
  const firstDataOptions = isLiftOff
    ? TASK_SUPPORT_OPTIONS.filter((_, ind) => ind !== 0)
    : TASK_SUPPORT_OPTIONS;
  // Show transit when LIFT_ON && BOOKING || LIFT_OFF && BILL
  const showAddTransit =
    (data.kind === TOUR_KINDS.LIFT_ON && data?.objCont?.orderType === CONT_ORDER_TYPES.BOOKING) ||
    (data.kind === TOUR_KINDS.LIFT_OFF && data?.objCont?.orderType === CONT_ORDER_TYPES.BILL);
  const onClickExpandTransit = () => setExpandTransit((prev) => !prev);

  return (
    <HeaderWrapper key={data.id}>
      {ind === 0 && (
        <>
          <SupportItem
            data={firstDataOptions}
            parentRomoocs={romoocs}
            defaultRomooc={romoocPlan}
            parentLocations={parentLocations}
            isShowAdd={addPrev}
            defaultValueTask={isLiftOff && TASK_SUPPORT_OPTIONS[1].value}
            parentConts={conts}
            onAddClick={onClickAddPrev}
            onChange={onChangePrev}
          />
          {!addPrev && (
            <SupportItem
              data={firstDataOptions}
              parentConts={conts}
              defaultValueTask={isLiftOff && TASK_SUPPORT_OPTIONS[1].value}
              parentRomoocs={romoocs}
              parentLocations={parentLocations}
              onChange={onChangePrev2}
            />
          )}
        </>
      )}
      <div>
        {isShowSelectLiftOn ? (
          <SimpleSelection
            data={TASK_END_OPTIONS}
            width={170}
            placeholder='Lẫy Rỗng'
            getLabel={getLabelSelectTask}
            value={endTaskName}
            onChange={setEndTaskName}
          />
        ) : (
          <Input value={data.name} style={{ width: '170px' }} disabled />
        )}
      </div>
      <Selection
        data={locations}
        width={200}
        onSearch={onSearchLocation}
        fieldValue='name'
        placeholder={renderLabelSelection(data)}
        onChange={setLocation}
        value={location}
        labelDefault={!isDisabledLocation && data?.endLocationName}
        disabled={isDisabledLocation || isDisabledLocationReportProblem}
      />
      {ind === 0 && showAddTransit && (
        <>
          <Button
            icon={expandTransit ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
            style={{ width: 140 }}
            onClick={onClickExpandTransit}
          >
            Chuyển Kho
          </Button>
          <TransitInput
            expand={expandTransit}
            onChange={onChangeTransit}
            locations={parentLocations}
          />
        </>
      )}

      {ind === 1 && (
        <>
          <RomoocInput
            value={romoocLabel || '-'}
            readOnly
            disabled={data.kind !== TOUR_KINDS.LIFT_ON}
          />
          <SupportItem
            parentConts={conts}
            parentRomoocs={romoocs}
            parentLocations={parentLocations}
            defaultValueTask={TASK_SUPPORT_OPTIONS[6].value}
            data={dataOptionSecondTask}
            isShowAdd={addNext}
            onAddClick={onClickAddNext}
            onChange={onChangeNext}
          />
          {!addNext && (
            <SupportItem
              parentConts={conts}
              parentRomoocs={romoocs}
              parentLocations={parentLocations}
              data={dataOptionSecondTask}
              onChange={onChangeNext2}
              defaultValueTask={TASK_SUPPORT_OPTIONS[5].value}
            />
          )}
        </>
      )}
    </HeaderWrapper>
  );
};
const SupportItem = ({
  defaultValueTask,
  parentRomoocs,
  parentConts,
  parentLocations,
  onChange,
  isShowAdd = false,
  onAddClick,
  data = TASK_SUPPORT_OPTIONS,
  defaultRomooc,
}) => {
  const [task, setTask] = useState({
    taskName: defaultValueTask || TASK_SUPPORT_OPTIONS[0].value,
    romooc: defaultRomooc?.id,
  });
  // Change Romooc Location
  // const [romoocLocationCurrent, setRomoocLocationCurrent] = useState(null);
  const [changeLocationLoading, setChangeLocationLoading] = useState(false);

  const { data: singleContData } = useSelector((state) => state.singleCont);
  const [locations, getLocations, setLocations] = useDataSelectSearch(ApiServices.getLocations);
  const [romoocs, getRomoocs, setRomoocs] = useDataSelectSearch(ApiServices.getRomoocsWhiteboard);
  const [conts, getConts, setConts] = useDataSelectSearch(ApiServices.getContsByStatus);

  const romoocCodeOfSingle = singleContData?.data?.map((item) => item?.romoocCode);
  const dataRomoocsAfterCheckSingleCont = romoocs?.map((romoocItem) => {
    if (romoocCodeOfSingle?.includes(romoocItem?.code)) {
      romoocItem.isSingleCont = true;
    } else {
      romoocItem.isSingleCont = false;
    }
    return romoocItem;
  });

  useEffect(() => {
    setTask((prev) => ({ ...prev, romooc: defaultRomooc }));
  }, [defaultRomooc]);

  useEffect(() => {
    onChange?.(task);
  }, [task]);

  useEffect(() => {
    setConts(parentConts);
  }, [parentConts]);

  useEffect(() => {
    setLocations(parentLocations);
  }, [parentLocations]);

  useEffect(() => {
    setRomoocs(parentRomoocs);
  }, [parentRomoocs]);

  const onChangeSelectTask = (taskName) => setTask({ taskName, romooc: '', location: '' });
  const onChangeRomooc = (_, obj) => {
    console.log({ _, obj });
    setTask((prev) => ({ ...prev, romooc: obj?.data ? obj?.data : null }));
  };
  const onChangeRomoocLocation = (val) =>
    setTask((prev) => ({ ...prev, romooc: { ...prev.romooc, newLocationId: val } }));
  const onChangeLocation = (_, obj) => setTask((prev) => ({ ...prev, location: obj?.data }));
  const onChangeCont = (_, obj) => setTask((prev) => ({ ...prev, cont: obj?.data }));
  const onSearchLocation = (Query) => getLocations({ Query }, 'name');
  const onSearchRomooc = (Query) => getRomoocs({ Query }, 'code');
  const onSearchCont = (textSearch) => getConts(textSearch);
  const isShowRomooc = TASK_SUPPORT_SHOW_ROMOOC.includes(task.taskName);

  const onChangeCurrentLocationOfRomooc = async (locationId) => {
    console.log({ idRomoooc: task.romooc });
    console.log({ locationId });
    if (changeLocationLoading || !task?.romooc?.id || !locationId) return;
    setChangeLocationLoading(true);
    return ApiServices.updateCurrentLocationOfRomooc(task?.romooc?.id, locationId)
      .then((res) => {
        console.log({ ChangeRomooc: res });
        if (res?.data?.data === 'success') {
          const locationInfo = locations?.find((item) => item?.id === locationId);
          console.log({ locationInfo });

          const indRomooc = dataRomoocsAfterCheckSingleCont?.findIndex(
            (item) => item?.id === task?.romooc?.id
          );
          if (indRomooc !== -1) {
            dataRomoocsAfterCheckSingleCont[indRomooc].currentLocationCode = locationId;
            dataRomoocsAfterCheckSingleCont[indRomooc].currentLocationName = locationInfo?.name;
          }
          setTask((prev) => ({
            ...prev,
            romooc: {
              ...prev.romooc,
              currentLocationCode: locationId,
              currentLocationName: locationInfo?.name,
            },
          }));
        }
      })
      .catch((err) => console.log('Cập nhật địa điểm hiện tại của xe thất bại', err))
      .finally(() => setChangeLocationLoading(false));
  };

  const renderContent = () => {
    if (task.taskName === RELOCATION_VALUE) {
      return (
        <>
          <Selection
            width={200}
            data={conts}
            placeholder='Chọn cont'
            onChange={onChangeCont}
            onSearch={onSearchCont}
            fieldValue='subNoNumber'
            value={task.cont?.id}
            style={{
              marginRight: 8,
              fontSize: 10,
            }}
          />
          <Selection
            data={locations}
            width={140}
            placeholder='Vị trí'
            onChange={onChangeLocation}
            onSearch={onSearchLocation}
            value={task.location?.id}
          />
        </>
      );
    }
    return isShowRomooc ? (
      <>
        <Selection
          width={200}
          placeholder='Romooc'
          fieldValue='code'
          data={dataRomoocsAfterCheckSingleCont}
          value={task.romooc?.id}
          onChange={onChangeRomooc}
          onSearch={onSearchRomooc}
          labelDefault={defaultRomooc?.code}
          typeOption='romoocWhiteBoard'
        />
        {task.romooc?.code !== MOOC_NGOAI ? (
          // <ShowLocation
          //   style={{ marginLeft: 8 }}
          //   placeholder='Vị trí'
          //   disabled
          //   value={task.romooc?.currentLocationName}
          // />
          <Selection
            data={locations}
            width={140}
            style={{ marginLeft: 8 }}
            placeholder='Vị trí'
            onChange={onChangeCurrentLocationOfRomooc}
            onSearch={onSearchLocation}
            labelDefault={task.romooc?.currentLocationName}
            value={task.romooc?.currentLocationCode}
            allowClear={false}
          />
        ) : (
          <Selection
            style={{ marginLeft: 8 }}
            data={locations}
            width={140}
            placeholder='Vị trí'
            value={task.romooc?.newLocationId || task.romooc?.currentLocationCode}
            onChange={onChangeRomoocLocation}
            labelDefault={task.romooc?.currentLocationName}
          />
        )}
      </>
    ) : (
      <>
        <Selection
          data={locations}
          width={200}
          placeholder='Vị trí'
          onChange={onChangeLocation}
          onSearch={onSearchLocation}
          value={task.location?.id}
        />
      </>
    );
  };

  return (
    <>
      <SimpleSelection
        data={data}
        width={170}
        placeholder='Lẫy Rỗng'
        value={task.taskName}
        onChange={onChangeSelectTask}
      />
      <ValueSupport>{renderContent()}</ValueSupport>
      <AddItemContainer>
        {isShowAdd && (
          <AddItem type='ghost' onClick={onAddClick}>
            <AddIcon />
          </AddItem>
        )}
      </AddItemContainer>
    </>
  );
};
const TransitInput = ({ expand = false, onChange, locations }) => {
  const [conts, setConts] = useState([]);
  const [transit, setTransit] = useState({});

  const [startLocations, getStartLocations, setStartLocations] = useDataSelectSearch(
    ApiServices.getLocations
  );
  const [endLocations, getEndLocations, setEndLocations] = useDataSelectSearch(
    ApiServices.getLocations
  );
  const getContData = (Query) => {
    ApiServices.getContsByStatus(Query, TRANSIT_CONT_DATA, true)
      .then((res) => {
        setConts(res.data?.data);
      })
      .catch((err) => console.error('GetContsByStatus Error', err));
  };
  const onSearchStartLocation = (Query) => {
    getStartLocations({ Query }, 'name');
  };
  const onSearchEndLocation = (Query) => {
    getEndLocations({ Query }, 'name');
  };

  useEffect(() => {
    if (expand) {
      !conts.length && getContData();
      setStartLocations(locations);
      setEndLocations(locations);
      return;
    }
    setTransit({});
  }, [expand]);

  useEffect(() => {
    onChange?.(transit);
  }, [transit]);

  const onChangeCont = (cont, { data }) => {
    setTransit((prev) => ({
      ...prev,
      cont,
      objectCont: data,
      start: data?.locationOfReciptCode,
      end: data?.locationOfDeliveryCode,
    }));
  };
  const onChangeStart = (start) => setTransit((prev) => ({ ...prev, start }));
  const onChangeEnd = (end) => setTransit((prev) => ({ ...prev, end }));

  if (!expand) {
    return null;
  }
  return (
    <>
      <Selection
        data={conts}
        width={170}
        onSearch={getContData}
        value={transit?.cont}
        onChange={onChangeCont}
        fieldValue='subNoNumber'
        placeholder='Cont chuyển kho'
      />
      <Selection
        data={startLocations}
        width={200}
        value={transit?.start}
        onChange={onChangeStart}
        onSearch={onSearchStartLocation}
        labelDefault={transit.objectCont?.locationOfReciptName}
        fieldValue='name'
        placeholder='Điểm Giao Cont'
      />
      <Selection
        data={endLocations}
        width={140}
        onChange={onChangeEnd}
        value={transit?.end}
        onSearch={onSearchEndLocation}
        labelDefault={transit.objectCont?.locationOfDeliveryName}
        fieldValue='name'
        placeholder='Điểm Giao Kho'
      />
    </>
  );
};

const AddIcon = styled(PlusOutlined)`
  color: white;
`;
const AddItemContainer = styled.div`
  width: 30px;
`;
const AddItem = styled(Button)`
  width: 30px;
  padding: 4px 8px;
`;
const ValueSupport = styled.div`
  // flex: 1;
`;
// const ShowLocation = styled(Input)`
//   width: 140px;
// `;
const RomoocInput = styled(Input)`
  width: 140px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 4px;
  align-items: center;
  & .ant-input[disabled] {
    color: black;
  }
`;
