export const KEYS = ['name', 'address', 'note'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });

  return result;
};
