import React from 'react';
import { Form, Input, Button, DatePicker, message } from 'antd';
import { DATE_FORMAT } from '~commons/constants';
import ApiServices from '~services';
import { UploadFile } from '~components/upload-file';
import moment from 'moment';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};
const tailLayout = {
  wrapperCol: { offset: 10, span: 16 },
};

const FormUpdateCarInspection = ({
  setIsShowFormUpdate,
  fields,
  onChange,
  idCar,
  getCarInspections,
  isNew,
  dataCarInspection,
  setLoading,
}) => {
  const createCarInspection = async (data) => {
    data.carId = idCar;
    data.cnSignDate = moment(data?.cnSignDate).format('YYYY-MM-DD');
    data.cnSignExpDate = moment(data?.cnSignExpDate).format('YYYY-MM-DD');
    try {
      const res = await ApiServices.createCarSignFormData(data);
      if (res.data) {
        message.success('Thêm mới phù hiệu xe thành công');
        setIsShowFormUpdate(false);
        return getCarInspections();
      } else {
        message.error('Thêm mới phù hiệu xe thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới phù hiệu xe thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };

  const updateCarInspection = async (data) => {
    const id = dataCarInspection.id;
    data.id = id;
    data.carId = idCar;
    data.cnSignDate = moment(data?.cnSignDate).format('YYYY-MM-DD');
    data.cnSignExpDate = moment(data?.cnSignExpDate).format('YYYY-MM-DD');
    if (data?.files?.length > 0) {
      const checkRemove = data?.files?.filter((item) => item?.id)?.map((el) => el.id);
      if (checkRemove?.length > 0) {
        const mapDataAfterMoved = dataCarInspection?.orderFiles?.filter((item) => {
          const hasMove = checkRemove?.includes(item?.id);
          if (hasMove) {
            return item;
          }
        });
        data.orderFiles = mapDataAfterMoved;
      } else {
        data.orderFiles = null;
      }
    } else {
      data.orderFiles = null;
    }
    try {
      const res = await ApiServices.updateCarSignFormData(id, data);
      if (res.data) {
        message.success('Cập nhật phù hiệu xe thành công');
        setIsShowFormUpdate(false);
        return getCarInspections();
      } else {
        message.error('Cập nhật phù hiệu xe thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật phù hiệu xe thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };

  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createCarInspection(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updateCarInspection(value);
    setLoading(false);
  };

  return (
    <Form
      {...layout}
      name='control-hooks'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        label='Số Chứng Nhận'
        name='cnSignNumber'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập số chứng nhận',
          },
        ]}
      >
        <Input placeholder='Số Chứng Nhận' />
      </Form.Item>
      <Form.Item
        label='Ngày Chứng Nhận'
        name='cnSignDate'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Ngày Chứng Nhận',
          },
        ]}
      >
        <DatePicker
          placeholder='Ngày Chứng Nhận'
          style={{ width: '100%' }}
          format={DATE_FORMAT}
          // rules={(e) => {
          //   console.log.getFieldValue();
          // }}
        />
      </Form.Item>
      <Form.Item
        label='HH Chứng Nhận'
        name='cnSignExpDate'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập ngày HH Chứng Nhận',
          },
        ]}
      >
        <DatePicker
          placeholder='HH Chứng Nhận'
          style={{ width: '100%' }}
          format={DATE_FORMAT}
          // rules={(e) => {
          //   console.log.getFieldValue();
          // }}
        />
      </Form.Item>
      <Form.Item
        label='Cơ Quan Chứng Nhận'
        name='cnOrganization'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Cơ Quan Chứng Nhận',
          },
        ]}
      >
        <Input placeholder='Cơ Quan Chứng Nhận' />
      </Form.Item>
      <Form.Item name='files' label='File Đính Kèm'>
        <UploadFile showUpdated />
      </Form.Item>
      <Form.Item label='Ghi Chú' name='note'>
        <Input.TextArea placeholder='Ghi Chú' />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type='danger' onClick={() => setIsShowFormUpdate(false)}>
          Hủy
        </Button>
        <Button type='primary' htmlType='submit' className='mg-l-5'>
          {isNew ? 'Thêm Mới' : 'Cập Nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { FormUpdateCarInspection };
