import { Button, Input, DatePicker } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import Utils from '~commons/utils';
import { ContRevisedContent } from '~components';
import { WBOrderTypeSelect } from '~components/common-select';
import { SHORTEN_DATE_FORMAT_WB } from '~commons/constants';

export const getColumns = (
  setQuery,
  onClickSend,
  setDataListFile,
  updateContNote,
  showCustomerDetail,
  searchDate,
  handleChangeSearchDate,
  partnerSelect
) => [
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Khách hàng</div>
        <Input
          style={{
            fontSize: '11px',
          }}
          placeholder=''
          allowClear
          onChange={(event) => setQuery({ customerName: event.target.value })}
        />
      </span>
    ),
    width: '30%',
    dataIndex: 'customerName',
    align: 'center',
    render: (value, record) => (
      <>
        {Utils.formatCustomerWaitForPlan(
          value,
          record,
          setDataListFile,
          showCustomerDetail,
          record?.notePlan
        )}
      </>
    ),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>SubNo</div>
        <Input
          style={{
            fontSize: '11px',
          }}
          placeholder=''
          allowClear
          onChange={(event) => setQuery({ allLocation: event.target.value })}
        />
      </span>
    ),
    width: '45%',
    dataIndex: 'subnoNumber',
    render: (value, record) => (
      <>
        <ContRevisedContent data={record} />
        {Utils.formatSubNoForTask(value, record)}
        {record?.note && <div className='blue'>{record?.note}</div>}
      </>
    ),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ngày YC</div>
        <DatePicker
          suffixIcon={null}
          value={searchDate}
          onChange={handleChangeSearchDate}
          placeholder=''
          style={{ width: '100%', padding: '3px 1px', fontSize: '10px' }}
          format={SHORTEN_DATE_FORMAT_WB}
          allowClear={true}
        />
      </span>
    ),
    width: '18%',
    dataIndex: 'orderType',
    align: 'center',
    render: (value, record) => (
      <div style={{ color: 'red' }}>
        {Utils.renderDateRequestForPlan(value, record, 'book_DeliveryContRequestDate')}
      </div>
    ),
  },
  {
    title: <WBOrderTypeSelect onChange={(orderType) => setQuery({ orderType })} />,
    width: '12%',
    dataIndex: 'action',
    render: (_, record) => (
      <Button
        type='link'
        icon={<SendOutlined />}
        disabled={partnerSelect}
        onClick={() => onClickSend([record.id])}
      />
    ),
  },
];
