import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { Input, Space, DatePicker, message } from 'antd';
import { TableLayout } from '~components';
import { RangeDateFilter, BtnConfirm } from '~components';
import ApiServices from '~services';
import { DATE_FORMAT } from '~commons/constants';
import { TableTitle, Wrapper } from '~commons/styles';
import Utils from '~commons/utils';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().startOf('date').format('YYYY-MM-DD'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD'),
};

export const GoodsMaterialNotesOutboundHistory = ({ headerLength = '220px' }) => {
  const [loading, setLoading] = useState(false);
  const [dataGoodMaterialNoteList, setDataGoodMaterialNoteList] = useState([]);
  const [selected, setSelected] = useState();
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [tradeDate, setTradeDate] = useState(null);
  const [tradingDate, setTradingDate] = useState(null);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getGoodsMaterialNotesOutboundHistory(newQuery)
      .then((res) => {
        if (res?.data) {
          setDataGoodMaterialNoteList(res?.data);
        }
      })
      .catch((err) => {
        console.error('Get Good material notes outbound history list Error: ', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const handleDeleteItem = async (record) => {
    setLoading(true);
    return ApiServices.deleteGoodsMaterialNotesTicket(record.id)
      .then((res) => {
        if (res?.data) {
          message.success('Xoá thành công');
          timeoutGetData(filter);
        }
      })
      .catch((err) => {
        message.error('Xoá thất bại, vui lòng thử lại sau!');
        console.log('Delete goods material ticket failer: ', err);
      })
      .finally(() => setLoading(false));
  };

  const handleChangeDate = (date) => {
    setTradeDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'createdOn');
  };
  const handleChangeTradingDate = (date) => {
    setTradingDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'tradingDate');
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Số phiếu
          <Input name='code' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'code',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Ngày Import
          <DatePicker
            value={tradeDate}
            onChange={handleChangeDate}
            placeholder='Ngày Import'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'createdOn',
      align: 'center',
      width: 150,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Ngày Giao Dịch
          <DatePicker
            value={tradingDate}
            onChange={handleChangeTradingDate}
            placeholder='Ngày Giao Dịch'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'tradingDate',
      align: 'center',
      width: 150,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Người Import
          <Input name='createdBy' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'createdBy',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Ghi chú
          <Input name='note' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'note',
      align: 'center',
      width: 150,
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 75,
      render: (record) => <BtnConfirm onConfirm={() => handleDeleteItem(record)}>Xóa</BtnConfirm>,
    },
  ];
  const onCloseEditTab = () => setSelected();

  const pagination = {
    total: dataGoodMaterialNoteList?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  const headerContent = (
    <Space>
      <RangeDateFilter query={filter} onChange={setFilter} />
    </Space>
  );

  return (
    <Wrapper>
      <h3>Lịch Sử Import File Sửa Chửa</h3>
      <TableLayout
        rowKey='id'
        columns={columns}
        data={dataGoodMaterialNoteList?.data}
        loading={loading}
        titleTab={selected?.jobName}
        headerContent={headerContent}
        headerLength={headerLength}
        closeDrawer={onCloseEditTab}
        selected={selected}
        pagination={pagination}
        isDisplayCheckbox={false}
      />
    </Wrapper>
  );
};
