export const color = {
  primary: '#1890ff', // primary color for all components
  link: '#1890ff', // link color
  success: '#52c41a', // success state color
  warning: '#faad14', // warning state color
  error: '#f5222d',
  wbBg: '#E8F0F2',
  liftOff: '#F7941DCC',
  liftOn: '#21409ACC',
  border: '#f0f0f0',
};
