import { Button, Input, Select } from 'antd';
import Utils from '~commons/utils';
import { CONT_DEPOT_TYPES, CONT_ORDER_TYPES, CONT_DEPOT_STATUSES } from '~commons/constants';
import { DepotInput } from '~components';
import styled from 'styled-components';
import { ContStatus } from '~components/common-status';
const { Option } = Select;
const SuccessButton = styled(Button)`
  padding: 0 8px;
  &.ant-btn-link:not([disabled]) {
    color: green;
  }
`;

function displayCutOffAndHHLCDate(value, record) {
  if (record?.orderType === CONT_ORDER_TYPES.BOOKING) {
    return (
      <div>
        <b className='blue'>CM: </b>
        {Utils.formatDate(record?.book_CutOffDateTime)}
      </div>
    );
  }
  if (record?.orderType === CONT_ORDER_TYPES.BILL) {
    return (
      <div>
        <b className='blue'>HHLC: </b>
        {Utils.formatDate(record?.bill_LastFreeDayDEM)}
      </div>
    );
  }
  return;
}

function displayCutOffAndHHTRDate(value, record) {
  if (record?.orderType === CONT_ORDER_TYPES.BOOKING) {
    return (
      <div>
        <b className='blue'>SI: </b>
        {Utils.formatDate(record?.book_SIDateTime)}
      </div>
    );
  }
  if (record?.orderType === CONT_ORDER_TYPES.BILL) {
    return (
      <div>
        <b className='blue'>HHTR: </b>
        {Utils.formatDate(record?.bill_LastFreeDayDET)}
      </div>
    );
  }
  return;
}

function displayRequestContDate(_, record) {
  if (record?.orderType === CONT_ORDER_TYPES.BOOKING) {
    return (
      <div>
        <b className='blue'>YCGC: </b>
        {Utils.formatDate(record?.book_DeliveryContRequestDate)}
      </div>
    );
  }
  if (record?.orderType === CONT_ORDER_TYPES.BILL) {
    return (
      <div>
        <b className='blue'>YCLC: </b>
        {Utils.formatDate(record?.bill_LiftingLadenContRequestDate)}
      </div>
    );
  }
  return;
}

export const getColumns = (setQuery, locations, getLocations, onLocationChange, showListFile) => [
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>SubNo</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(event) => setQuery({ subnoNumber: event.target.value })}
        />
      </span>
    ),
    width: '200px',
    dataIndex: 'subnoNumber',
    render: (value, record) => (
      <>
        {value}
        <div>
          <SuccessButton type='link' onClick={() => showListFile(record)}>
            File Đk
          </SuccessButton>
        </div>
      </>
    ),
  },

  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Loại ĐH</div>
        <Select
          // mode="multiple"
          placeholder='Search'
          defaultValue={''}
          style={{ width: 90 }}
          onChange={(orderType) => setQuery({ orderType })}
        >
          {CONT_DEPOT_TYPES.map((item) => (
            <Option key={item.label + item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </span>
    ),
    width: '100px',
    dataIndex: 'orderType',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Trạng thái</div>
        <Select
          placeholder='Search'
          defaultValue={''}
          style={{ width: 90 }}
          onChange={(status) => setQuery({ status })}
        >
          {CONT_DEPOT_STATUSES.map((item) => (
            <Option key={item.label + item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </span>
    ),
    width: '100px',
    textAlign: 'center',
    dataIndex: 'status',
    render: (value, record) => (
      <>
        <ContStatus status={value} revisedText='Đang xử lý' />
        {record.partnerName && (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            ({record.partnerName})
          </div>
        )}
      </>
    ),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Khách hàng</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(event) => setQuery({ customerName: event.target.value })}
        />
      </span>
    ),
    width: '150px',
    dataIndex: 'customerName',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Lấy Rỗng/Trả Rỗng</div>
      </span>
    ),
    width: '180px',
    dataIndex: 'lr',
    render: (value, record) => (
      <>
        <DepotInput
          labelDefault={record.lr || record.tr}
          locations={locations}
          onSearch={getLocations}
          onChange={(val) => onLocationChange(record.id, val)}
        />
      </>
    ),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Hạ Hàng/Lấy Hàng</div>
      </span>
    ),
    width: '150px',
    dataIndex: 'gh',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ghi chú</div>
      </span>
    ),
    width: '250px',
    dataIndex: 'note',
  },

  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Số cont</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(event) => setQuery({ contNumber: event.target.value })}
        />
      </span>
    ),
    width: '100px',
    dataIndex: 'contNumber',
  },
  {
    //
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Số seal</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(event) => setQuery({ contNumber: event.target.value })}
        />
      </span>
    ),
    width: '100px',
    dataIndex: 'sealNumber',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Cắt Máng/HHLC</div>
      </span>
    ),
    width: '150px',
    dataIndex: 'book_CutOffDateTime',
    render: displayCutOffAndHHLCDate,
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Cắt SI/HHTR</div>
      </span>
    ),
    width: '150px',
    dataIndex: 'book_SIDateTime',
    render: displayCutOffAndHHTRDate,
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>YC Giao/Lấy Cont</div>
      </span>
    ),
    width: '150px',
    dataIndex: 'book_DeliveryContRequestDate',
    render: displayRequestContDate,
  },
];
