import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { Space, Button, Table, message, Popconfirm, Input } from 'antd';
import { CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import { RangeDateFilter } from '~components';
import Utils from '~commons/utils';
import { TableTitle } from '~commons/styles';
const ExcelJS = require('exceljs');
const { Column, ColumnGroup } = Table;

const DEFAULT_QUERIES = {
  PageSize: 1500,
  PageNumber: 1,
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};

const GoodsMaterialNotesWarehouse = () => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [dataList, setDataList] = useState([]);
  const [dataInit, setDataInit] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query, SortColumn: 'materialName', SortColumnDirection: 'asc' };
    return ApiServices.getGoodsMaterialNotesWarehouse(newQuery)
      .then((res) => {
        if (res?.data) {
          setDataInit(res?.data);
        }
        if (res?.data?.data) {
          const dataMap = res?.data?.data
            ?.map((item) => {
              item.endMount = item?.beginning_Amount + item?.input_Amount - item?.output_Amount;
              item.endMoney = item?.beginning_Money + item?.input_Money - item?.output_Money;
              return item;
            })
            ?.filter((el) => el.endMount > 0);
          setDataList(dataMap);
        }
      })
      .catch((err) => {
        console.error('get Goods Material Notes Warehouse Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, PageNumber: 1, Columns };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const handlePostWarehouseClosing = async () => {
    if (loading) return;

    setLoading(true);

    return ApiServices.postGoodsMaterialNotesWarehouseClosing()
      .then((res) => {
        if (res) {
          message.success('Chốt tồn thành công');
        }
      })
      .catch((err) => {
        message.error('Chốt tồn thất bại!');
        console.log({ err });
      })
      .finally(() => setLoading(false));
  };

  const pagination = {
    total: dataInit?.totalRecords || 0,
    showSizeChanger: false,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  const handleExportExcel = async (dataExportList = []) => {
    const workbook = new ExcelJS.Workbook();
    // // Ten sheet
    const sheetSummary = workbook.addWorksheet('Xuất Nhập Tồn', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ state: 'frozen', ySplit: 4, activeCell: 'A1', showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 15;
    sheetSummary.properties.defaultColWidth = 15;

    const date = `Từ ${moment(filter?.DateFrom).format('DD/MM/YYYY')} Đến ${moment(
      filter?.DateTo
    ).format('DD/MM/YYYY')}`;
    sheetSummary.addRow(['']); // blank Row
    sheetSummary.addRow(['', '', '', 'XUẤT NHẬP TỒN VẬT TƯ']); // blank Row
    sheetSummary.addRow(['', '', '', date]); // blank Row
    sheetSummary.addRow(['']); // blank Row
    sheetSummary.addRow(['']); // blank Row
    // add Title

    sheetSummary.addRow([
      'Tên Vật Tư',
      'Mã Vật Tư',
      'Đơn Vị Tính',
      'Nhà Cung Cấp',
      'Đơn Giá',
      'Đầu Kỳ',
      '',
      'Nhập',
      '',
      'Xuất',
      '',
      'Cuối Kỳ',
      '',
    ]);
    sheetSummary.addRow([
      '',
      '',
      '',
      '',
      '',
      'Số Lượng',
      'Thành Tiền',
      'Số Lượng',
      'Thành Tiền',
      'Số Lượng',
      'Thành Tiền',
      'Số Lượng',
      'Thành Tiền',
    ]);
    const listMerge1 = ['A', 'B', 'C', 'D', 'E'];
    listMerge1.forEach((item) => {
      sheetSummary.mergeCells(`${item}6:${item}7`);
      sheetSummary.getCell(`${item}6`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetSummary.getCell(`${item}6`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });
    const listMerge2 = [
      {
        start: 'F',
        end: 'G',
      },
      {
        start: 'H',
        end: 'I',
      },
      {
        start: 'J',
        end: 'K',
      },
      {
        start: 'L',
        end: 'M',
      },
    ];
    listMerge2.forEach((item) => {
      sheetSummary.mergeCells(`${item.start}6:${item.end}6`);
      sheetSummary.getCell(`${item.start}6`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetSummary.getCell(`${item.start}6`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });
    const listMerge3 = ['F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'];
    listMerge3.forEach((item) => {
      sheetSummary.getCell(`${item}7`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetSummary.getCell(`${item}7`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });

    // Add body

    dataExportList?.map((item, indexRow) => {
      sheetSummary.addRow([
        item?.materialName,
        item?.materialCode || '',
        item?.materialUnit,
        item?.supplierMaterialName,
        item?.price,
        item?.beginning_Amount,
        item?.beginning_Money,
        item?.input_Amount,
        item?.input_Money,
        item?.output_Amount,
        item?.output_Money,
        item?.endMount,
        item?.endMoney,
      ]);
      const columnsBorderList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'];
      columnsBorderList.forEach((item) => {
        sheetSummary.getCell(`${item}${indexRow + 8}`).border = {
          top: { style: 'thin', color: { argb: '006699' } },
          left: { style: 'thin', color: { argb: '006699' } },
          right: { style: 'thin', color: { argb: '006699' } },
          bottom: { style: 'thin', color: { argb: '006699' } },
        };
        if (indexRow % 2 === 0) {
          sheetSummary.getCell(`${item}${indexRow + 8}`).fill = {
            type: 'gradient',
            gradient: 'angle',
            stops: [
              { position: 0, color: { argb: 'D8D8D8' } },
              { position: 1, color: { argb: 'D8D8D8' } },
            ],
          };
        }
      });
    });
    // Styles
    for (let i = 5; i <= 13; i++) {
      sheetSummary.getColumn(i).numFmt = '#,##0';
      sheetSummary.getColumn(i).width = 15;
    }

    sheetSummary.getColumn(1).width = 30;
    sheetSummary.getColumn(4).width = 30;

    sheetSummary.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(6).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(7).alignment = { vertical: 'middle', horizontal: 'center' };

    sheetSummary.mergeCells('D2:F2');
    sheetSummary.mergeCells('D3:F3');
    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getRow(3).font = {
      name: 'Arial',
      family: 2,
      size: 10,
      italic: true,
    };
    sheetSummary.getRow(6).font = {
      name: 'Arial',
      family: 2,
      size: 13,
      bold: true,
    };
    sheetSummary.getRow(7).font = {
      name: 'Arial',
      family: 2,
      size: 13,
      bold: true,
    };
    sheetSummary.getCell('A6').fill = {
      type: 'gradient',
      gradient: 'angle',
      stops: [
        { position: 0, color: { argb: '96B6C5' } },
        { position: 1, color: { argb: '96B6C5' } },
      ],
    };
    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Xuat_Nhap_Ton_Vat_Tu_${date}_Export_luc_${moment().format(
          'DD/MM/YYYY HH:mm'
        )}.xlsx`; // name excel
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setExportLoading(false);
    }
  };

  const handleGetDataExport = async (query = DEFAULT_QUERIES) => {
    setExportLoading(true);
    const newQuery = {
      ...query,
      SortColumn: 'materialName',
      SortColumnDirection: 'asc',
      export: true,
    };
    return ApiServices.getGoodsMaterialNotesWarehouse(newQuery)
      .then((res) => {
        if (res?.data?.data) {
          const dataMap = res?.data?.data
            ?.map((item) => {
              item.endMount = item?.beginning_Amount + item?.input_Amount - item?.output_Amount;
              item.endMoney = item?.beginning_Money + item?.input_Money - item?.output_Money;
              return item;
            })
            .filter((el) => el.endMount > 0);
          return handleExportExcel(dataMap);
        }
      })
      .catch((err) => {
        console.error('get Goods Material Notes Warehouse Error: ', err);
      })
      .finally(() => {
        setExportLoading(false);
      });
  };
  return (
    <>
      <h1>XUẤT NHẬP TỒN VẬT TƯ</h1>

      <div
        style={{
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          padding: '20px 10px',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      >
        <Space>
          <div>
            <b
              style={{
                marginLeft: '10px',
              }}
            >
              Chọn ngày:
            </b>
            <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
          </div>
          <div
            style={{
              marginLeft: 'auto',
            }}
          >
            <br />
            <Popconfirm
              title='Xác nhận chốt tồn'
              okText='Có'
              cancelText='Không'
              onConfirm={handlePostWarehouseClosing}
            >
              <Button
                type='primary'
                loading={loading}
                icon={<CheckCircleOutlined />}
                style={{ padding: '0px 20px', marginLeft: '10px' }}
              >
                Chốt Tồn Hiện Tại
              </Button>
            </Popconfirm>
          </div>
          <div>
            <br />
            <Button
              type='primary'
              icon={<DownloadOutlined />}
              loading={exportLoading}
              style={{ padding: '0px 20px', marginLeft: '10px' }}
              onClick={() => handleGetDataExport(filter)}
            >
              Export Excel
            </Button>
          </div>
        </Space>
      </div>
      <div className='table-container-custom'>
        <Table
          dataSource={dataList}
          bordered
          size='small'
          pagination={pagination}
          key='materialName'
          scroll={{ y: `calc(100vh - 300px` }}
        >
          <Column
            title={
              <TableTitle>
                Tên Vật Tư
                <Input name='materialName' onChange={onColumnSearch} placeholder='Search' />
              </TableTitle>
            }
            dataIndex='materialName'
            key='materialName'
            align='center'
            width={80}
          />
          <Column
            title={
              <TableTitle>
                Mã Vật Tư
                <Input name='materialCode' onChange={onColumnSearch} placeholder='Search' />
              </TableTitle>
            }
            dataIndex='materialCode'
            key='materialCode'
            align='center'
            width={50}
          />
          <Column
            title='ĐV Tính'
            dataIndex='materialUnit'
            key='materialUnit'
            align='center'
            width={15}
          />
          <Column
            title={
              <TableTitle>
                Nhà Cung Cấp
                <Input name='supplierMaterialName' onChange={onColumnSearch} placeholder='Search' />
              </TableTitle>
            }
            dataIndex='supplierMaterialName'
            key='supplierMaterialName'
            align='center'
            width={100}
          />
          <Column
            title='Đơn Giá'
            dataIndex='price'
            key='price'
            align='center'
            render={(value) => Utils.formatNumber(value)}
            width={30}
            fixed='right'
          />

          <ColumnGroup title='Đầu Kỳ'>
            <Column
              title='SL'
              dataIndex='beginning_Amount'
              key='beginning_Amount'
              align='center'
              width={15}
              fixed='right'
            />
            <Column
              title='Thành Tiền'
              dataIndex='beginning_Money'
              key='beginning_Money'
              align='center'
              render={(value) => Utils.formatNumber(value)}
              width={30}
              fixed='right'
            />
          </ColumnGroup>
          <ColumnGroup title='Nhập'>
            <Column
              title='SL'
              dataIndex='input_Amount'
              key='input_Amount'
              align='center'
              width={15}
              fixed='right'
            />
            <Column
              title='Thành Tiền'
              dataIndex='input_Money'
              key='input_Money'
              align='center'
              render={(value) => Utils.formatNumber(value)}
              width={30}
              fixed='right'
            />
          </ColumnGroup>
          <ColumnGroup title='Xuất'>
            <Column
              title='SL'
              dataIndex='output_Amount'
              key='output_Amount'
              align='center'
              width={15}
              fixed='right'
            />
            <Column
              title='Thành Tiền'
              dataIndex='output_Money'
              key='output_Money'
              align='center'
              render={(value) => Utils.formatNumber(value)}
              width={30}
              fixed='right'
            />
          </ColumnGroup>
          <ColumnGroup title='Cuối kỳ'>
            <Column
              title='SL'
              dataIndex='endMount'
              key='endMount'
              align='center'
              width={15}
              fixed='right'
            />
            <Column
              title='Thành Tiền'
              dataIndex='endMoney'
              key='endMoney'
              align='center'
              render={(value) => Utils.formatNumber(value)}
              width={30}
              fixed='right'
            />
          </ColumnGroup>
        </Table>
      </div>
    </>
  );
};

export { GoodsMaterialNotesWarehouse };
