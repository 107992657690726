import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { TableLayout } from '~components';
import { UnitForm } from './unit-form';
import UnitFields from './fields-data';
import { Button, Popconfirm, message, Input } from 'antd';

import ApiServices from '~services';

const Units = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [unit, setUnit] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);
  //-------------------------------------------------------------------
  const [searchTerm, setSearchTerm] = useState('');
  const typingTimeoutref = useRef(null);
  //-------------------------------------------------------------------
  const [searchByColumn, setSearchByColumn] = useState({
    code: '',
    name: '',
    companyName: '',
  });

  const getUnit = async () => {
    let dataFilterSend = { ...filter };
    if (Object.keys(dataFilterSend.Columns).length === 0) {
      delete dataFilterSend['Columns'];
    } else {
      const columnJSONSTRING = JSON.stringify(dataFilterSend.Columns);
      dataFilterSend.Columns = columnJSONSTRING;
    }

    try {
      setLoading(true);
      let { data } = await ApiServices.getUnit(dataFilterSend);
      setLoading(false);
      let pag = pagination;
      pag.total = data?.totalRecords || 0;
      pag.pageSize = data?.pageSize;
      pag.current = data?.pageNumber;

      data = data?.data || [];
      setUnit(data);
      setPagination(pag);
    } catch (error) {
      console.log('Error - get cars', error);
      console.error(error);
    }
  };

  useEffect(() => {
    getUnit();
  }, []);

  //----------- Start ----- Pagination Search ---------
  //----------- Pagination --------------------------
  const onChangePagination = (page, size) => {
    let filterTemp = filter;
    filterTemp.PageNumber = page;
    filterTemp.PageSize = size;
    setFilter(filterTemp);

    getUnit();
  };
  const [pagination, setPagination] = useState({
    total: 1,
    showSizeChanger: true,
    onChange: onChangePagination,
  });
  const [filter, setFilter] = useState({
    PageNumber: 1,
    PageSize: 20,
    Text: '',
    Columns: '',
  });

  // ------------ Search Text ------------------------
  const handleSearchTermChange = (e) => {
    const val = e.target.value;
    setSearchTerm(val);
    const filterTmp = filter;
    filterTmp.Text = val;

    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }

    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 2) {
        filter.PageNumber = 1;
        setFilter({ ...filterTmp, PageNumber: 1 });
        getUnit();
      }
      if (val.length === 0) {
        setFilter({ ...filterTmp, PageNumber: 1 });
        getUnit();
      }
    }, 1000);
  };
  // ------------ Search Column ----------------------
  const handleSearchColumnFn = (event, name) => {
    const filterTmp = filter;
    const val = event.target.value;
    let _searchByColumn = { ...searchByColumn };
    _searchByColumn[`${name}`] = val;
    setSearchByColumn(_searchByColumn);
    // Clear timeout
    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }
    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 0) {
        filter.PageNumber = 1;
        let searchColumnFn = { ...filter.Columns };
        searchColumnFn[`${name}`] = val;
        filter.Columns = searchColumnFn;

        setFilter({ ...filterTmp, PageNumber: 1 });
        getUnit();
      }
      if (val.length === 0) {
        delete filter.Columns[`${name}`];
        setFilter({ ...filterTmp, PageNumber: 1 });
        getUnit();
      }
    }, 1000);
  };
  // ------------- End Search --------------------

  const setFieldsForm = async (value) => {
    const unitFields = await UnitFields(value);
    await setFields(unitFields);
    return unitFields;
  };
  const reload = async () => {
    setSelected(null);
    await getUnit();
  };

  const getUpdatedMaterialTypeForm = async (value, isNew = false, id = null, dataSelected) => {
    const unitForm = await (
      <UnitForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        reload={reload}
        onChange={setFields}
        dataSelected={dataSelected}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: unitForm,
      },
    ]);
  };

  const onRowSelected = async (value) => {
    await setIsLoadingTab(true);
    await setTitleTab(value.code);
    await setFieldsForm(value)
      .then((res) => {
        return getUpdatedMaterialTypeForm(res, false, value.id, value);
      })
      .then(() => setSelected(value));
    setIsLoadingTab(false);
  };
  const deleteUnit = async (record) => {
    try {
      const res = await ApiServices.deleteUnit(record.id);
      if (res.data) {
        message.success('Xoá Đơn Vị Tính thành công');
        return reload();
      }
      message.error('Xoá Đơn Vị Tính thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Mã Đơn Vị Tính</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.code}
            onChange={(event) => handleSearchColumnFn(event, 'code')}
          />
        </span>
      ),
      dataIndex: 'code',
      width: 150,
      align: 'center',
      render: (value, record) => (
        <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>
      ),
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Đơn Vị Tính</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.name}
            onChange={(event) => handleSearchColumnFn(event, 'name')}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Công Ty</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.companyName}
            onChange={(event) => handleSearchColumnFn(event, 'companyName')}
          />
        </span>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'companyName',
    },
    {
      title: '#',
      fixed: 'right',
      width: 75,
      render: (record) => (
        <Popconfirm
          onConfirm={() => deleteUnit(record)}
          title='Xoá Đơn Vị Tính này？'
          okText='Yes'
          cancelText='No'
        >
          <a href='#'>Xoá</a>
        </Popconfirm>
      ),
    },
  ];

  const createGara = async () => {
    await setTitleTab('');
    await setFieldsForm({})
      .then((res) => getUpdatedMaterialTypeForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };

  const handleExportExcel = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const { data } = await ApiServices.exportUnit();
      if (data) {
        const outputFilename = `Danh_Sach_Don_Vi_Tinh_Xuat_Excel_Vao_Luc_${moment().format(
          'DD-MM-YYYY HHgiomm'
        )}.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoading(false);
    }
  };

  const headerContent = (
    <>
      <Button type='primary' onClick={createGara}>
        Thêm Mới
      </Button>
      <Input.Search
        style={{ width: '200px' }}
        className='mg-l-15'
        placeholder='Search'
        value={searchTerm}
        onChange={handleSearchTermChange}
      />
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        style={{ padding: '0px 20px', marginLeft: '10px' }}
        onClick={handleExportExcel}
      >
        Export Excel
      </Button>
    </>
  );

  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={unit}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      pagination={pagination}
      isDisplayCheckbox={false}
      headerLength='190px'
    />
  );
};

export { Units };
