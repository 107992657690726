import { InputSearchColumns, BtnConfirm } from '~components';
import Utils from '~commons/utils';

export const getColumns = (onRowSelected, searchByColumn, handleSearchColumnFn, deleteRomooc) => [
  {
    title: 'STT',
    width: 50,
    dataIndex: 'index',
    align: 'center',
  },
  {
    title: (
      <InputSearchColumns
        title='Mã Số Romooc'
        value={searchByColumn?.code}
        onChange={(e) => handleSearchColumnFn(e, 'code')}
      />
    ),
    dataIndex: 'code',
    align: 'center',
    width: 100,
    render: (value, record) => <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>,
  },
  {
    title: (
      <InputSearchColumns
        title='Số Romooc'
        value={searchByColumn?.name}
        onChange={(e) => handleSearchColumnFn(e, 'name')}
      />
    ),
    width: 100,
    align: 'center',
    dataIndex: 'name',
  },
  // {
  //   title: (
  //     <InputSearchColumns
  //       title='Công ty'
  //       value={searchByColumn?.companyName}
  //       onChange={(e) => handleSearchColumnFn(e, 'companyName')}
  //     />
  //   ),
  //   width: 150,
  //   align: 'center',
  //   dataIndex: 'companyName',
  // },
  {
    title: (
      <InputSearchColumns
        title='Nhãn hiệu'
        value={searchByColumn?.label}
        onChange={(e) => handleSearchColumnFn(e, 'label')}
      />
    ),
    width: 100,
    align: 'center',
    dataIndex: 'label',
  },
  {
    title: (
      <InputSearchColumns
        title='Loại hình'
        value={searchByColumn?.typeRomooc}
        onChange={(e) => handleSearchColumnFn(e, 'typeRomooc')}
      />
    ),
    width: 150,
    align: 'center',
    dataIndex: 'typeRomooc',
  },
  {
    title: (
      <InputSearchColumns
        title='Chủng loại'
        value={searchByColumn?.typeContCode}
        onChange={(e) => handleSearchColumnFn(e, 'typeContCode')}
      />
    ),
    width: 100,
    align: 'center',
    dataIndex: 'typeContCode',
  },
  {
    title: (
      <InputSearchColumns
        title='Nơi SX'
        value={searchByColumn?.madeIn}
        onChange={(e) => handleSearchColumnFn(e, 'madeIn')}
      />
    ),
    width: 100,
    align: 'center',
    dataIndex: 'madeIn',
  },
  {
    title: (
      <InputSearchColumns
        title='Tải trọng'
        value={searchByColumn?.loads}
        onChange={(e) => handleSearchColumnFn(e, 'loads')}
      />
    ),
    width: 80,
    align: 'center',
    dataIndex: 'loads',
  },
  {
    title: (
      <InputSearchColumns
        title='Trọng lượng'
        value={searchByColumn?.weight}
        onChange={(e) => handleSearchColumnFn(e, 'weight')}
      />
    ),
    width: 90,
    align: 'center',
    dataIndex: 'weight',
  },
  {
    title: (
      <InputSearchColumns
        title='Số khung'
        value={searchByColumn?.frameNumber}
        onChange={(e) => handleSearchColumnFn(e, 'frameNumber')}
      />
    ),
    width: 150,
    align: 'center',
    dataIndex: 'frameNumber',
  },
  {
    title: 'Ngày KĐ',
    width: 90,
    align: 'center',
    dataIndex: 'inspectionDate',
    render: (value) => Utils.formatDate(value),
  },
  {
    width: 100,
    align: 'center',
    title: 'HH Kiểm Định',
    dataIndex: 'inspectionDeadline',
    render: (value) => Utils.formatDate(value),
  },
  {
    width: 100,
    align: 'center',
    title: 'Ngày sử dụng',
    dataIndex: 'useBeginDate',
    render: (value) => Utils.formatDate(value),
  },
  {
    title: (
      <InputSearchColumns
        title='Số KĐ'
        value={searchByColumn.inspectionNumber}
        onChange={(e) => handleSearchColumnFn(e, 'inspectionNumber')}
      />
    ),
    width: 100,
    align: 'center',
    dataIndex: 'inspectionNumber',
  },
  {
    title: (
      <InputSearchColumns
        title='Chi phí KĐ'
        value={searchByColumn.inspectionCost}
        onChange={(e) => handleSearchColumnFn(e, 'inspectionCost')}
      />
    ),
    width: 100,
    align: 'center',
    dataIndex: 'inspectionCost',
  },
  {
    title: (
      <InputSearchColumns
        title='Thông số khác'
        value={searchByColumn.otherInfo}
        onChange={(e) => handleSearchColumnFn(e, 'otherInfo')}
      />
    ),
    width: 100,
    align: 'center',
    dataIndex: 'otherInfo',
  },
  {
    title: (
      <InputSearchColumns
        title='Địa điểm hiện tại'
        value={searchByColumn.currentLocationName}
        onChange={(e) => handleSearchColumnFn(e, 'currentLocationName')}
      />
    ),
    width: 150,
    align: 'center',
    dataIndex: 'currentLocationName',
  },
  {
    title: (
      <InputSearchColumns
        title='Số Cont'
        value={searchByColumn.contNumber}
        onChange={(e) => handleSearchColumnFn(e, 'contNumber')}
      />
    ),
    width: 100,
    align: 'center',
    dataIndex: 'contNumber',
  },
  {
    title: (
      <InputSearchColumns
        title='Chi phí mua'
        value={searchByColumn.price}
        onChange={(e) => handleSearchColumnFn(e, 'price')}
      />
    ),
    width: 90,
    align: 'center',
    dataIndex: 'price',
  },
  {
    width: 80,
    align: 'center',
    title: 'Số tháng khấu hao',
    dataIndex: 'depreciation',
  },
  {
    width: 90,
    align: 'center',
    title: 'Phí BH Mooc tháng',
    dataIndex: 'warrantyExpenses',
  },
  {
    title: (
      <InputSearchColumns
        title='Sở hữu'
        value={searchByColumn.ownerRomooc}
        onChange={(e) => handleSearchColumnFn(e, 'ownerRomooc')}
      />
    ),
    width: 100,
    align: 'center',
    dataIndex: 'ownerRomooc',
  },
  {
    title: 'Ghi Chú',
    width: 150,
    align: 'center',
    dataIndex: 'note',
  },
  // {
  //   title: 'geo',
  //   width: 100,
  //   dataIndex: 'geo',
  //   sorter: (a, b) => a.geo.toString().localeCompare(b.geo.toString()),
  // },
  {
    title: 'Action',
    fixed: 'right',
    width: 75,
    render: (record) => <BtnConfirm onConfirm={() => deleteRomooc(record)}>Xóa</BtnConfirm>,
  },
];
