export const KEYS = ['code', 'name', 'companyId', 'companyName'];
export default (data) => {
  return KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });
};
