export const calculation = (data, contTypes) => {
  let calContType = {};
  data.forEach((element) => {
    calContType[element.typeCont] = calContType[element.typeCont]
      ? calContType[element.typeCont] + 1
      : 1;
  });

  return data.map((item, index) => {
    return {
      ...item,
      contOder: index + 1,
      quantityCont: calContType[item.typeCont],
      typeCont: contTypes[item.typeCont],
    };
  });
};

export const calculationForCreate = (data) => {
  let calContType = {};
  data.forEach((element) => {
    calContType[element.typeCont] = calContType[element.typeCont]
      ? calContType[element.typeCont] + 1
      : 1;
  });

  return data.map((item, index) => {
    return {
      ...item,
      orderNumber: index + 1,
      quantityCont: calContType[item.typeCont],
    };
  });
};
