import moment from 'moment';

export const KEYS = [
  'customerName',
  'contNumber',
  'sealNumber',
  'subnoNumber',
  'romoocCode',
  'romoocName',
  'lh',
  'gh',
  'tr',
  'note',
  'notePlan',
  'locationId',
];
export const KEYS_OF_DATE = [
  'bill_LiftingLadenContRequestDate',
  'pickupAcctualDate',
  'deliveryAcctualDate',
  'actualContainerEndDate',
  'bill_AcctualDateDET',
];
export const KEYS_OF_DATE_TIME = ['bill_LastFreeDayDET', 'bill_LastFreeDayDEM'];

export const KEYS_OF_UPLOAD_FILES = ['contFiles'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });

  KEYS_OF_DATE.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? moment(data[item]) : null,
    });
  });
  KEYS_OF_DATE_TIME.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? moment(data[item]) : null,
    });
  });

  KEYS_OF_UPLOAD_FILES.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] || [],
      errors: [],
    });
  });

  return result;
};
