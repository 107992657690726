const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};

export { layout, tailLayout };
