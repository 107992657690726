import React from 'react';
import { Button, Popconfirm } from 'antd';

export const BtnConfirm = ({
  placement = 'topRight',
  title = 'Bạn có chắc chắn muốn xóa?',
  okText = 'Có',
  cancelText = 'Không',
  type = 'link',
  onConfirm,
  children,
  icon = null,
  ...props
}) => {
  return (
    <Popconfirm
      {...props}
      placement={placement}
      title={title}
      okText={okText}
      cancelText={cancelText}
      onConfirm={onConfirm}
    >
      <Button type={type} danger icon={icon ? icon : null}>
        {children}
      </Button>
    </Popconfirm>
  );
};
