import { useState, useEffect } from 'react';
import { Table, Input } from 'antd';
import { TableTitle } from '~commons/styles';

const BillCont = ({ dataBillCont = [], setSelectedBillCont, onColumnSearch, pagination }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [dataBillCont]);

  const handleSelectRow = (record) => {
    if (record) {
      setSelectedRowKeys([record?.key]);
      setSelectedBillCont(record);
    }
  };

  const renderRow = (value, record) => {
    return (
      <div
        style={{
          cursor: 'pointer',
        }}
        onClick={() => handleSelectRow(record)}
      >
        {value}
      </div>
    );
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Sub No
          <Input name='subnoNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'subnoNumber',
      align: 'center',
      render: renderRow,
    },
    {
      title: (
        <TableTitle>
          Số Cont
          <Input name='contNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'contNumber',
      align: 'center',
      render: renderRow,
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (rowKey, selectedRows) => {
      setSelectedRowKeys(rowKey);
      selectedRows?.[0] && setSelectedBillCont(selectedRows?.[0]);
    },
  };
  return (
    <div
      className='table-container-custom'
      style={{
        maxHeight: 'calc(100vh - 100px)',
        overflow: 'auto',
      }}
    >
      <Table
        bordered
        size='small'
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        rowKey='key'
        columns={columns}
        dataSource={dataBillCont}
        // scroll={{
        //   x: '100%',
        //   y: 500,
        // }}
        pagination={pagination}
      />
    </div>
  );
};
export { BillCont };
