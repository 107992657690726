import { Form, Input, Button, message, Row, Col } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';
import { FormList } from '~components';

const CompanyForm = ({ fields, isNew, id, reload, onChange, setLoading }) => {
  function taxValidation(tax) {
    let taxId = tax.indexOf('-') == -1 ? tax : tax.split('-')[0];
    let arrayCheck = [31, 29, 23, 19, 17, 13, 7, 5, 3];
    let checkNumber = 0;
    for (let i = 0; i < 9; i++) {
      checkNumber += taxId[i] * arrayCheck[i];
    }
    let lastNumber = 10 - (checkNumber % 11);
    return taxId[9] == lastNumber;
  }

  const createDriver = async (data) => {
    const checkTax = await taxValidation(data?.taxCode);

    if (!checkTax) {
      window.TmsNoti?.error('Lỗi', 'Mã số thuế không chính xác');
      return;
    }
    try {
      const res = await ApiServices.createCompany(data);
      if (res?.data) {
        message.success('Thêm mới công ty thành công');
        return reload();
      } else {
        message.error('Thêm mới công ty thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới công ty thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const updateDriver = async (data) => {
    data.id = id;
    const checkTax = await taxValidation(data?.taxCode);

    if (!checkTax) {
      window.TmsNoti?.error('Lỗi', 'Mã số thuế không chính xác');
      return;
    }
    try {
      const res = await ApiServices.updateCompany(id, data);
      if (res.data) {
        message.success('Cập nhật Công ty thành công');
        return reload();
      } else {
        message.error('Cập nhật Công ty thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật Công ty thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createDriver(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updateDriver(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      name='global_state'
      className='form-input-data'
      layout='vertical'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            // {...itemLayout}
            style={{ marginTop: '10px' }}
            name='code'
            label='Mã công ty'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Mã công ty',
              },
              // {
              //   validator: checkExistsCode,
              // },
              {
                type: 'string',
                min: 4,
                message: 'Bạn phải nhập ít nhất 4 ký tự',
              },
            ]}
          >
            <Input className='red' placeholder='Mã công ty' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            style={{ marginTop: '10px' }}
            name='name'
            label='Tên Công Ty'
            rules={[
              {
                required: true,
                message: 'bạn phải nhập Tên Công Ty',
              },
            ]}
          >
            <Input placeholder='Tên Công Ty' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='title'
            label='Tên Viết Tắt'
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên viết tắt',
              },
            ]}
          >
            <Input placeholder='Title' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='taxCode'
            label='Mã Số Thuế'
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập Mã số thuế',
              },
            ]}
          >
            <Input placeholder='Mã Số Thuế' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <FormList
            title='Số điện thoại'
            fieldKey='arrPhone'
            rulesItem={[
              {
                required: true,
                whitespace: true,
                message: 'Vui lòng nhập số điện thoại',
              },
            ]}
            placeholder='Số điện thoại'
            actionName='Thêm số điện thoại'
          />
        </Col>
        <Col span={12}>
          <FormList
            title='Fax'
            fieldKey='arrFax'
            rulesItem={[
              {
                required: true,
                whitespace: true,
                message: 'Vui lòng nhập fax',
              },
            ]}
            placeholder='Fax'
            actionName='Thêm Fax'
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <FormList
            title='Email'
            fieldKey='arrEmail'
            rulesItem={[
              {
                required: true,
                whitespace: true,
                message: 'Vui lòng nhập Email',
              },
            ]}
            placeholder='Email'
            actionName='Thêm Email'
          />
        </Col>
        <Col span={12}>
          <FormList
            title='Địa chỉ'
            fieldKey='arrAddressId'
            rulesItem={[
              {
                required: true,
                whitespace: true,
                message: 'Vui lòng nhập địa chỉ',
              },
            ]}
            placeholder='Địa chỉ'
            actionName='Thêm địa chỉ'
            inputTextArea={true}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='ownerName' label='Sở Hửu'>
            <Input placeholder='Sở Hữu' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='contactName' label='Người Liên Hệ'>
            <Input placeholder='Người Liên Hệ' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <FormList
            title='SĐT liên hệ'
            fieldKey='arrContactPhone'
            rulesItem={[
              {
                required: true,
                whitespace: true,
                message: 'Vui lòng nhập SĐT liên hệ',
              },
            ]}
            placeholder='Email'
            actionName='Thêm SĐT liên hệ'
          />
        </Col>
        <Col span={12}></Col>
      </Row>

      <Form.Item {...tailLayout} className='submit-form-input-data'>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { CompanyForm };
