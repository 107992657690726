import React, { useEffect, useState } from 'react';
import { Selection } from '~components';
import { Button } from 'antd';
import ApiServices from '~services';
import { ViewAuthority } from './view-authority';

const AuthorityLv2 = ({ dataSelect }) => {
  const [loadingPartner, setLoadingPartner] = useState(false);
  const [loadingCus, setLoadingCus] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);

  const [partnerByCompanyId, setPartnerByCompanyId] = useState([]);
  const [partnerId, setPartnerId] = useState('');
  const [customerList, setCustomerList] = useState([]);
  const [customerIdList, setCustomerIdList] = useState([]);

  const getPartnerByCompanyId = async (companyId) => {
    setLoadingPartner(true);
    try {
      const res = await ApiServices.getPartnerByCompanyId(companyId);
      if (res?.data?.data) {
        setPartnerByCompanyId(res?.data?.data || []);
        await getCustomerOfCompany(companyId, res?.data?.data);
      }
    } catch (error) {
      console.log('Error - get Partner By CompanyId', error);
    } finally {
      setLoadingPartner(false);
    }
  };

  const getCustomerOfCompany = async (companyId, dataPartner = [], query = null) => {
    let idActive = [];
    dataPartner?.forEach((element) => {
      if (element?.customers?.length) {
        const idList = element?.customers?.map((item) => item?.customerId);
        idActive = idActive.concat(idList);
      }
    });

    setLoadingCus(true);
    try {
      const res = await ApiServices.getPartnerCustomerDetailsAll(companyId, { query });
      if (res?.data?.data) {
        const dataAvailable = res?.data?.data?.filter((el) => !idActive.includes(el?.id));
        setCustomerList(dataAvailable || []);
      }
    } catch (error) {
      console.log('Error - get Customer Of Company', error);
    } finally {
      setLoadingCus(false);
    }
  };

  useEffect(() => {
    if (dataSelect?.id) {
      const getInit = async () => {
        await getPartnerByCompanyId(dataSelect?.id);
      };
      getInit();
    }
  }, [dataSelect]);

  const reload = async () => {
    setPartnerId('');
    setCustomerIdList([]);
    await getPartnerByCompanyId(dataSelect?.id);
  };

  const handleAdd = async (companyId, partnerId, customerIdList) => {
    setLoadingAdd(true);
    try {
      const res = await ApiServices.changeCustomerPartnerLv2(companyId, partnerId, {
        customerIds: customerIdList,
        add: true,
      });
      if (res?.data?.data === 'success') {
        window.TmsNoti?.success('Thao tác thành công');
        reload();
      }
    } catch (error) {
      console.log('Error - get Partner By CompanyId', error);
    } finally {
      setLoadingAdd(false);
    }
  };

  const handleDelete = async (partnerId, dataSend) => {
    const companyId = dataSelect?.id;
    if (!companyId || !partnerId || !dataSend?.length) return;
    setLoadingAdd(true);
    try {
      const res = await ApiServices.changeCustomerPartnerLv2(companyId, partnerId, {
        customerIds: dataSend,
        add: false,
      });

      if (res?.data?.data === 'success') {
        window.TmsNoti?.success('Xóa thành công');
        reload();
      }
    } catch (error) {
      console.log('Error - get Partner By CompanyId', error);
    } finally {
      setLoadingAdd(false);
    }
  };
  const deleteItem = async (dataRow) => {
    const { partnerId, customerId } = dataRow;
    return handleDelete(partnerId, [customerId]);
  };

  const deleteItemAll = async (dataRow) => {
    const dataSend = dataRow?.children?.map((item) => item?.customerId);
    return handleDelete(dataRow?.id, dataSend);
  };

  const handleChangePartner = async (value) => {
    setPartnerId(value);
  };

  const handleChangeCustomer = async (value) => {
    setCustomerIdList(value);
  };

  const handleSearchCustomer = async (text) => {
    await getCustomerOfCompany(dataSelect?.id, partnerByCompanyId, text);
  };

  return (
    <>
      <h1>
        Đối tác <span className='red'>(*)</span>
      </h1>
      <Selection
        width={400}
        data={partnerByCompanyId}
        placeholder='Đối tác'
        fieldValue='name'
        value={partnerId}
        onChange={handleChangePartner}
        loading={loadingPartner}
      />

      <h1
        style={{
          marginTop: '5px',
        }}
      >
        Khách hàng <span className='red'>(*)</span>
      </h1>
      <Selection
        width={800}
        mode='multiple'
        data={customerList}
        placeholder='Khách hàng'
        fieldValue='shortName'
        loading={loadingCus}
        value={customerIdList}
        onChange={handleChangeCustomer}
        onSearch={handleSearchCustomer}
      />
      <div>
        <div
          style={{
            margin: '10px',
          }}
        >
          <Button
            type='primary'
            onClick={() => handleAdd(dataSelect?.id, partnerId, customerIdList)}
            style={{ marginLeft: '5px' }}
            disabled={!partnerId || !customerIdList?.length}
          >
            Cấu hình
          </Button>
        </div>
      </div>
      <ViewAuthority
        dataView={partnerByCompanyId || []}
        deleteItem={deleteItem}
        deleteItemAll={deleteItemAll}
        loading={loadingAdd}
      />
    </>
  );
};

export { AuthorityLv2 };
