import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { messaging } from '../libs/firebase';
import Storage from '~storage';

import { Login } from './login';
import { Home } from './home';
import { OrderForm } from './order-form';
import { ChangePassWord } from './change-password';
import { NotFound } from './not-found';
import { ReportVehicle } from './home/report-vehicle-table';
import { ReportRomooc } from './home/report-romooc-table';
import { ReportVehicleReplacement } from './home/report-vehicle-replacement';
import { ReportDrivingLicense } from './home/report-driving-license-table';
import { ShowListFile } from '~components';
import { useDispatch, useSelector } from 'react-redux';
import { showListFile } from '~store/reducers/white-board/action';
import ApiServices from '~services';
import { getAllRoutes, PrivateRoute } from './routes';
import { InputDistance } from '~components/input-distance';
import { onMessage, getToken } from 'firebase/messaging';
import { notification } from 'antd';

import {
  fetchOrderWaitingReceiveONP,
  fetchOrderWaitingReceiveWMS,
  fetchOrderRevisedONP,
  fetchOrderRevisedWMS,
  fetchOrderCancelONP,
  fetchOrderCancelWMS,
  fetchOrderByTmsNotRead,
  fetchOrderCreateByTmsNotRead,
} from '~store/reducers/order-ONP-WMS/action';

const KEY_ORDER = {
  RECEIVE_ONP: '01',
  RVS_ONP: '02',
  CANCEL_ONP: '03',
  RECEIVE_WMS: '04',
  RVS_WMS: '05',
  CANCEL_WMS: '06',
  RVS_BY_TMS: '07',
  CREATE_BY_TMS: '08',
};
const App = () => {
  const { dataListFile } = useSelector((state) => state.whiteBoard);
  const dispatch = useDispatch();
  const serviceWorkerRef = useRef();

  useEffect(() => {
    const handleReload = setInterval(() => {
      const token = JSON.parse(localStorage.getItem('user_data_tms'))?.token;
      if (!token) {
        console.log('logOut');
        // window.location.replace('/login');
        return;
      }
      const tokenUser = Storage?.user?.token;
      if (!tokenUser) {
        console.log('logOut');
        // window.location.replace('/login');
        return;
      }
      if (token !== tokenUser) {
        console.log('Reload Page');
      }
      // console.log({ Storage });
      // console.log({ tokenUser: Storage?.user?.token });

      // console.log({ token });
    }, 5000);

    return () => clearInterval(handleReload);
  }, []);

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    window.TmsNoti = {
      error: (message = '', description = '', duration = 8) => {
        if (!message) return;
        api['error']({
          message,
          description,
          duration,
        });
      },
      success: (message = '', description = '', duration = 4, placement = 'topRight') => {
        if (!message) return;
        api['success']({
          message,
          description,
          duration,
          placement,
        });
      },
      successCustom: (message = '', description = '', duration = 4, btn, key) => {
        if (!message) return;
        api['success']({
          message,
          description,
          duration,
          btn: btn(),
          key,
        });
      },
      warning: (message = '', description = '', duration = 4, placement = 'topRight') => {
        if (!message) return;
        api['warning']({
          message,
          description,
          duration,
          placement,
        });
      },
    };
  }, []);

  useEffect(() => {
    const getDataInit = async () => {
      await getTokenFirebase();
    };
    getDataInit();
    return () => serviceWorkerRef.current?.unregister?.();
  }, []);

  const deviceRegister = () => {
    getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_MSG_PUBLIC_VAPID_KEY,
    })
      .then((data) => {
        console.log('token', data);
        const getTokenByFireBase = Storage.getTokenFireBase();

        if (data !== getTokenByFireBase) {
          getUserInit(data);
        }
      })
      .catch((err) => console.error(err));
  };

  const getTokenFirebase = async () => {
    Notification.requestPermission((result) => {
      if (result === 'granted') {
        navigator.serviceWorker.register('./firebase-messaging-sw.js').then((res) => {
          serviceWorkerRef.current = res;
          navigator.serviceWorker.ready
            .then((registration) => {
              deviceRegister();
              onMessage(messaging, (payload) => {
                console.log('[firebase-messaging-sw.js] Received background message ');
                console.log({ payload });
                const keyGetOrderByNoti = payload?.notification?.body
                  ? payload?.notification?.body?.split('-')[0]
                  : '';
                switch (keyGetOrderByNoti) {
                  case KEY_ORDER.RECEIVE_ONP:
                    dispatch(fetchOrderWaitingReceiveONP());
                    break;
                  case KEY_ORDER.RVS_ONP:
                    dispatch(fetchOrderRevisedONP());
                    break;
                  case KEY_ORDER.CANCEL_ONP:
                    dispatch(fetchOrderCancelONP());
                    break;
                  case KEY_ORDER.RECEIVE_WMS:
                    dispatch(fetchOrderWaitingReceiveWMS());
                    break;
                  case KEY_ORDER.RVS_WMS:
                    dispatch(fetchOrderRevisedWMS());
                    break;
                  case KEY_ORDER.CANCEL_WMS:
                    dispatch(fetchOrderCancelWMS());
                    break;
                  case KEY_ORDER.RVS_BY_TMS:
                    dispatch(fetchOrderByTmsNotRead());
                    break;
                  case KEY_ORDER.CREATE_BY_TMS:
                    dispatch(fetchOrderCreateByTmsNotRead());
                    break;

                  default:
                    break;
                }

                const notificationTitle = payload.notification.title;
                const notificationOptions = {
                  body: payload.notification.body,
                  icon: '/logo.png',
                  action: 'new-message',
                };
                registration.showNotification(notificationTitle, notificationOptions);
              });
            })
            .catch((error) => console.error({ error }));
        });
      }
    });
  };

  const getUserInit = async (tokenFb) => {
    // Remove Token Firebase Old
    await Storage.removeTokenFireBase();

    const getDataDecodeByToken = Storage.getDataDecodeToken();

    if (getDataDecodeByToken) {
      try {
        let { data } = await ApiServices.getUserDetails(getDataDecodeByToken.ObjectId);

        if (data.data) {
          const deviceIdList = data.data.deviceId;
          if (deviceIdList?.length > 0) {
            if (tokenFb === deviceIdList[0]) {
              await Storage.saveTokenFireBase(tokenFb);
            } else {
              await deleteTokenFromUser(data.data, tokenFb);
            }
          } else {
            await addTokenToUser(data.data, tokenFb);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteTokenFromUser = async (dataUser, tokenFirb) => {
    try {
      const dataSend = {
        userId: dataUser.id,
        isDriver: dataUser.isDriver,
        deviceToken: dataUser.deviceId[0],
      };

      let { data } = await ApiServices.registerNotify(false, dataSend);

      if (data) {
        addTokenToUser(dataUser, tokenFirb);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addTokenToUser = async (dataUser, tokenFirebaseSend) => {
    try {
      const dataSend = {
        userId: dataUser.id,
        isDriver: dataUser.isDriver,
        deviceToken: tokenFirebaseSend,
      };

      let { data } = await ApiServices.registerNotify(true, dataSend);

      if (data) {
        await Storage.saveTokenFireBase(tokenFirebaseSend);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const setShowFile = () => dispatch(showListFile([]));

  return (
    <Router>
      {contextHolder}
      <ShowListFile
        setIsShowFile={setShowFile}
        listFiles={dataListFile?.files}
        title={`Danh Sách Files của (${dataListFile?.subnoNumber})`}
      />
      <InputDistance />
      <Switch>
        {getAllRoutes()}
        <Route path='/login' exact component={Login} />
        <PrivateRoute path='/order/:id' exact component={OrderForm} />
        {/* <PrivateRoute path='/order-plans' exact component={<OrderPlans />} /> */}
        <PrivateRoute path='/change-password' component={ChangePassWord} />
        <Route path='/report-vehicle' exact component={ReportVehicle} />
        <Route path='/report-romooc' exact component={ReportRomooc} />
        <Route path='/report-driving-license' exact component={ReportDrivingLicense} />
        <Route path='/report-vehicle-replacement' exact component={ReportVehicleReplacement} />
        <PrivateRoute path='/' exact component={Home} />

        <Route path='*' component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
