import React, { useEffect, useState, useCallback } from 'react';
const ExcelJS = require('exceljs');
import moment from 'moment';
import { debounce } from 'lodash';
import { Space, Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import { RangeDateFilter, Selection } from '~components';
import logo from '~assets/images/logo.png';
import Utils from '~commons/utils';

const toDataURL = (url) => {
  const promise = new Promise((resolve) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
  return promise;
};

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};

const ReportCustomerExpense = () => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [summary, setSummary] = useState(null);
  const [detail, setDetail] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState('');
  const [customerSelected, setCustomerSelected] = useState({});

  const getCustomer = async (query = '') => {
    if (loading) return;
    setLoading(true);
    try {
      let { data } = await ApiServices.getCustomer({ query });
      if (data?.data) {
        setCustomers(data?.data);
      }
    } catch (error) {
      console.log('Get customer list failed:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomer();
  }, []);

  const getData = async (query = DEFAULT_QUERIES, cusId) => {
    setLoading(true);
    const newQuery = { ...query };
    // const customerId1 = '60cc11ea2c250000fb00133a';
    return ApiServices.getAccountantsByCustomerExpense(cusId, newQuery)
      .then((res) => {
        if (res?.data?.data?.company) {
          setCompany(res?.data?.data?.company);
        } else {
          setCompany(null);
        }
        if (res?.data?.data?.summary) {
          setSummary(res?.data?.data?.summary);
        } else {
          setSummary(null);
        }
        if (res?.data?.data?.detail) {
          const dataMap = res?.data?.data?.detail?.map((item) => {
            let listExpense = {
              thanhLyTK: 0,
              dO: 0,
              luuBai: 0,
              chuyenContRottau: 0,
              veSinh: 0,
              luuCont: 0,
              chuyenTai: 0,
              phuThu: 0,
              muaSeal: 0,
              nang: 0,
              ha: 0,
              totals: 0,
            };
            item?.objExpense?.map((el) => {
              switch (el?.code) {
                case 'thanh-ly-tk':
                  listExpense.thanhLyTK = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'do':
                  listExpense.dO = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'luu-bai':
                  listExpense.luuBai = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'chuyen-cont-rot-tau':
                  listExpense.chuyenContRottau = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 've-sinh':
                  listExpense.veSinh = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'luu-cont':
                  listExpense.luuCont = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'chuyen-tai':
                  listExpense.chuyenTai = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'phu-thu':
                  listExpense.phuThu = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'mua-seal':
                  listExpense.muaSeal = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'nang':
                  listExpense.nang = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                case 'ha':
                  listExpense.ha = el?.total;
                  listExpense.totals = listExpense.totals + el.total;
                  return;
                default:
                  break;
              }
            });
            return { ...item, ...listExpense };
          });
          setDetail(dataMap || []);
        } else {
          setDetail([]);
        }
      })
      .catch((err) => {
        setCompany(null);
        setSummary(null);
        setDetail([]);
        console.error('get Accountants By CustomerExpense Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, newCustomerId) => {
      getData(newFilter, newCustomerId);
    }, 500),
    []
  );
  useEffect(() => {
    if (customerId) {
      timeoutGetData(filter, customerId);
    }
  }, [filter, customerId]);

  const exportExcelFile = async () => {
    if (loading) return;
    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Summary', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ state: 'frozen', ySplit: 5, activeCell: 'A1', showGridLines: false }],
    });
    const customerSheet = workbook.addWorksheet(
      customerSelected?.code ? `${customerSelected?.code}` : 'Chi tiết',
      {
        properties: { tabColor: { argb: 'FF00FF00' } },
        views: [{ state: 'frozen', ySplit: 5, activeCell: 'A1', showGridLines: false }],
      }
    );
    // chieu cao - chieu rong cot
    sheetSummary.properties.defaultRowHeight = 15;
    sheetSummary.properties.defaultColWidth = 15;
    sheetSummary.getColumn(1).width = 3;
    sheetSummary.getColumn(2).width = 5;
    sheetSummary.getColumn(3).width = 25;

    sheetSummary.getRow(9).alignment = { vertical: 'middle', horizontal: 'center' };

    customerSheet.properties.defaultRowHeight = 15;
    customerSheet.properties.defaultColWidth = 15;
    customerSheet.getColumn(1).width = 5;
    customerSheet.getColumn(3).width = 25;
    customerSheet.getColumn(6).width = 5;
    customerSheet.getColumn(7).width = 5;
    customerSheet.getColumn(8).width = 5;
    customerSheet.getColumn(9).width = 5;
    customerSheet.getColumn(12).width = 20;
    customerSheet.getColumn(13).width = 20;
    customerSheet.getColumn(14).width = 25;

    customerSheet.getRow(9).alignment = { vertical: 'middle', horizontal: 'center' };
    // Add Image
    // const pathLogo = company?.logo?.split('~')[1];
    // const urlLogo = `${process.env.REACT_APP_API_URL}${pathLogo}`;
    // const resultTest = await toDataURL(urlLogo);
    // const splittedTest = urlLogo.split('.');
    // const extNameTest = splittedTest[splittedTest.length - 1];
    const resultTest = await toDataURL(logo);
    const extNameTest = 'png';
    // --------- Summary Sheet --------------------

    const imageId2Summary = workbook.addImage({
      base64: resultTest.base64Url,
      extension: extNameTest,
    });

    sheetSummary.addImage(imageId2Summary, {
      tl: { col: 2, row: 0 },
      ext: { width: 150, height: 100 },
    });

    sheetSummary.getRow(1).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    sheetSummary.getCell('D1').value = company?.name || '';
    sheetSummary.getCell('D2').value = company?.address || '';
    sheetSummary.getCell('D3').value = company?.phone || '';

    sheetSummary.getRow(6).commit(); // now rows 1 and two are committed.
    sheetSummary.getRow(7).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    sheetSummary.getCell('E7').value = `BÁO CÁO CHI TIẾT CHI PHÍ KHÁCH HÀNG Từ ${moment(
      filter?.DateFrom
    ).format('DD/MM/YYYY')} Đến ${moment(filter?.DateTo).format('DD/MM/YYYY')}`;
    sheetSummary.getRow(8).commit(); // now rows 1 and two are committed.
    // customerSheet.getCell('Q10').numFmt = '#,##;[Red]-#,##';
    for (let i = 4; i <= 16; i++) {
      sheetSummary.getColumn(i).numFmt = '#,##0';
      sheetSummary.getColumn(i).width = 15;
    }
    sheetSummary.getColumn(22).numFmt = '#,##0';
    sheetSummary.getColumn(22).width = 15;

    // add a table to a sheet
    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B9',
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Khách hàng', totalsRowLabel: 'Tổng', filterButton: false },
        { name: 'Phí neo', totalsRowFunction: 'sum', filterButton: false },
        { name: 'Phí Vận Chuyển', totalsRowFunction: 'sum', filterButton: false },
        { name: 'Thanh lý TK', totalsRowFunction: 'sum', filterButton: false },
        { name: 'D/O', totalsRowFunction: 'sum', filterButton: false },
        { name: 'Lưu Bãi', totalsRowFunction: 'sum', filterButton: false },
        { name: 'Chuyển Cont Rớt Tàu', totalsRowFunction: 'sum', filterButton: false },
        { name: 'Vệ Sinh', totalsRowFunction: 'sum', filterButton: false },
        { name: 'Lưu Cont', totalsRowFunction: 'sum', filterButton: false },
        { name: 'Chuyển Tải', totalsRowFunction: 'sum', filterButton: false },
        { name: 'Phụ Thu', totalsRowFunction: 'sum', filterButton: false },
        { name: 'Mua Seal', totalsRowFunction: 'sum', filterButton: false },
        { name: 'Nâng', totalsRowFunction: 'sum', filterButton: false },
        { name: 'Hạ', totalsRowFunction: 'sum', filterButton: false },
        { name: 'Ghi Chú', filterButton: false },
        { name: '#16', filterButton: false },
        { name: '#17', filterButton: false },
        { name: '#18', filterButton: false },
        { name: '#19', filterButton: false },
        { name: 'Tổng', totalsRowFunction: 'sum', filterButton: false },
      ],
      rows: [
        [
          1,
          summary?.name,
          summary?.phiNeoRomooc,
          summary?.phiVanChuyen,
          summary?.thanhLyTK,
          summary?.dO,
          summary?.luuBai,
          summary?.chuyenContRottau,
          summary?.veSinh,
          summary?.luuCont,
          summary?.chuyenTai,
          summary?.phuThu,
          summary?.muaSeal,
          summary?.nang,
          summary?.ha,
          '',
          '',
          '',
          '',
          '',
          summary?.phiNeoRomooc +
            summary?.phiVanChuyen +
            summary?.thanhLyTK +
            summary?.dO +
            summary?.luuBai +
            summary?.chuyenContRottau +
            summary?.veSinh +
            summary?.luuCont +
            summary?.chuyenTai +
            summary?.phuThu +
            summary?.muaSeal +
            summary?.nang +
            summary?.ha,
        ],
      ],
    });

    // --------- Detail Sheet --------------------

    const imageId2 = workbook.addImage({
      base64: resultTest.base64Url,
      extension: extNameTest,
    });

    customerSheet.mergeCells('A1:B5');
    customerSheet.addImage(imageId2, 'A1:B5');

    customerSheet.getRow(1).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    customerSheet.getCell('C1').value = company?.name || '';
    customerSheet.getCell('C2').value = company?.address || '';
    customerSheet.getCell('C3').value = company?.phone || '';

    customerSheet.getRow(6).commit(); // now rows 1 and two are committed.
    customerSheet.getRow(7).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    customerSheet.getCell('E7').value = `BÁO CÁO CHI TIẾT CHI PHÍ KHÁCH HÀNG ${
      customerSelected?.name
    } Từ ${moment(filter?.DateFrom).format('DD/MM/YYYY')} Đến ${moment(filter?.DateTo).format(
      'DD/MM/YYYY'
    )}`;
    customerSheet.getRow(8).commit(); // now rows 1 and two are committed.
    // customerSheet.getCell('Q10').numFmt = '#,##;[Red]-#,##';
    for (let i = 18; i <= 43; i++) {
      customerSheet.getColumn(i).numFmt = '#,##0';
      customerSheet.getColumn(i).width = 15;
    }

    const arrDetail = await detail?.map((item, index) => {
      const dateStart = item?.actualContainerBeginDate;
      const dateEnd = item?.actualContainerEndDate;
      const free = item?.romoocFeeDateContract ? item.romoocFeeDateContract : 0;

      const countSunday = Utils.countCheckDay(dateStart, dateEnd);
      const dateTru =
        Utils.getDaysDiff(
          moment(dateStart).format('YYYY-MM-DD'),
          moment(dateEnd).format('YYYY-MM-DD')
        ) - countSunday;

      const dateTruFinal = dateTru > free ? dateTru - free : 0;
      // * Phí neo Romooc = (Số ngày neo - số ngày neo đc miễn phí trong HĐ ) * Phí neo Romooc
      // => ( RomoocFeeActualContainerDate - RomoocFeeDateContract ) * RomoocFee
      // ( Tức là Số ngày neo > số ngày neo đc miễn phí trong HĐ, thì mới tính! Còn lại = 0 hết )

      const calcRomoocFee = dateTruFinal * item?.romoocFee;

      const dataItem = [
        index + 1, // STT
        Utils.displayOrderTypeOrder(item?.orderType), // Loại hình
        item?.subnoNumber, // So Book / Bill
        item?.createdOn && new Date(moment(item?.createdOn).format('YYYY-MM-DD')), // Ngày nhận
        item?.contNumber, // Số cont
        item?.contType === `20"` ? 1 : 0,
        item?.contType === '40C' ? 1 : 0,
        item?.contType === '40T' ? 1 : 0,
        item?.contType === `45"` ? 1 : 0,
        item?.deliveryAcctualDate &&
          new Date(moment(item?.deliveryAcctualDate).format('YYYY-MM-DD')), // Ngày giao cont
        item?.completeDate && new Date(moment(item?.completeDate).format('YYYY-MM-DD')), // Ngày hạ cont
        item?.layCont || '', // Nơi lấy cont
        item?.tmpCont || '', // Nơi đóng/rút hàng
        item?.haCont || '', // Nơi hạ cont
        item?.xeHa || '', // So xe = Book: Số xe Hạ = Bill: Số xe Nâng
        item?.romooc || '', // Romooc
        dateTruFinal || 0, // So ngay neo
        // item?.romoocFeeDateContract || 0, // số ngay neo miễn phí
        // item?.romoocFee || 0, // Phi neo
        calcRomoocFee, // Phí neo
        item?.transportFee || 0, // Phi Van Chuyen
        item?.vat || 0, // VAT
        item?.thanhLyTK,
        item?.dO,
        item?.luuBai,
        item?.chuyenContRottau,
        item?.veSinh,
        item?.luuCont,
        item?.chuyenTai,
        item?.phuThu,
        item?.muaSeal,
        item?.nang,
        item?.ha,
        item?.note || '', // ghi chu
        '',
        '',
        '',
        '',
        '',
        calcRomoocFee + item?.transportFee + item?.vat + item?.totals,
      ];

      return dataItem;
    });

    // add a table to a sheet
    await customerSheet.addTable({
      name: 'TableDetail',
      ref: 'A9',
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleMedium23',
        showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Loại hình', totalsRowLabel: '', filterButton: false },
        { name: 'Số Book / Bill', totalsRowLabel: '', filterButton: false },
        {
          name: 'Ngày nhận',
          totalsRowLabel: '',
          filterButton: false,
        },
        { name: 'Số Cont', totalsRowLabel: '', filterButton: false },
        { name: `20"`, totalsRowFunction: 'sum', filterButton: false },
        { name: `40C`, totalsRowFunction: 'sum', filterButton: false },
        { name: `40T`, totalsRowFunction: 'sum', filterButton: false },
        { name: `45"`, totalsRowFunction: 'sum', filterButton: false },
        {
          name: 'Ngày Giao Cont',
          filterButton: false,
        },
        {
          name: 'Ngày Hạ Cont',
          filterButton: false,
        },
        {
          name: 'Nơi Lấy Cont',
          filterButton: false,
        },
        {
          name: 'Nơi Đóng / Rút Hàng',
          filterButton: false,
        },
        {
          name: 'Nơi Hạ Cont',
          filterButton: false,
        },
        {
          name: 'Số Xe Hạ Cont',
          filterButton: false,
        },
        {
          name: 'Số Romooc',
          filterButton: false,
        },
        {
          name: 'Số ngày neo',
          totalsRowFunction: 'sum',
          filterButton: false,
        },
        // {
        //   name: 'Số ngày neo miễn phí',
        //   totalsRowFunction: 'sum',
        //   filterButton: false,
        // },
        {
          name: 'Phí neo',
          totalsRowFunction: 'sum',
          filterButton: false,
        },
        {
          name: 'Phí Vận Chuyển',
          totalsRowFunction: 'sum',
          filterButton: false,
        },
        {
          name: 'VAT',
          totalsRowFunction: 'sum',
          filterButton: false,
        },
        {
          name: 'Thanh Lý TK',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'D/O',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Lưu Bãi',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Chuyển Cont Rớt Tàu',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Vệ Sinh',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Lưu Cont',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Chuyển Tải',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Phụ Thu',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Mua Seal',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Nâng',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Hạ',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Ghi Chú',
          filterButton: false,
        },
        {
          name: '#16',
          filterButton: false,
        },
        {
          name: '#17',
          filterButton: false,
        },
        {
          name: '#18',
          filterButton: false,
        },
        {
          name: '#19',
          filterButton: false,
        },
        {
          name: '#20',
          filterButton: false,
        },
        {
          name: 'Tổng',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
      ],
      rows: arrDetail,
    });
    sheetSummary.getColumn(9).width = 20;
    customerSheet.getColumn(24).width = 20;
    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `BC_Chi_Tiet_Chi_Phi_KH_${customerSelected?.name}_Tu_${moment(
          filter?.DateFrom
        ).format('DD-MM-YYYY')}_Den_${moment(filter?.DateTo).format(
          'DD-MM-YYYY'
        )}_Xuat_Excel_Luc_${moment().format('DD-MM-YY HH:mm')}.xlsx`; // name excel
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeCustomer = (value) => {
    if (!value) {
      setCustomerId('');
      setCustomerSelected(null);
      setCompany(null);
      setSummary(null);
      setDetail([]);
      return;
    }
    const dataCustomer = customers.find((item) => item.id === value);
    setCustomerId(value);
    setCustomerSelected(dataCustomer);
  };

  return (
    <>
      <h1>BẢNG BÁO CÁO CHI TIẾT CHI PHÍ KHÁCH HÀNG</h1>

      <div
        style={{
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          padding: '20px 10px',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      >
        <Space>
          <div>
            <b
              style={{
                marginLeft: '10px',
              }}
            >
              Chọn ngày:
            </b>
            <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
          </div>
          <div>
            <b>
              Khách hàng:{' '}
              {!customerId && (
                <i
                  style={{
                    color: 'red',
                    fontSize: '12px',
                  }}
                >
                  Bạn phải chọn khách hàng
                </i>
              )}
            </b>
            <br />
            <Selection
              status={!customerId ? 'error' : ''}
              data={customers}
              placeholder='Khách hàng'
              fieldValue='name'
              width={400}
              value={customerId}
              onChange={handleChangeCustomer}
              onSearch={getCustomer}
            />
          </div>
          <div>
            <br />
            {company || summary || detail?.length > 0 ? (
              <Button
                type='primary'
                loading={loading}
                icon={<EyeOutlined />}
                style={{ padding: '0px 20px', marginLeft: '10px' }}
                disabled={!company || !summary || !detail?.length}
                onClick={exportExcelFile}
              >
                Xem
              </Button>
            ) : (
              <b
                style={{
                  color: 'red',
                  fontSize: '15px',
                }}
              >
                Không có dữ liệu
              </b>
            )}
          </div>
        </Space>
      </div>
    </>
  );
};

export { ReportCustomerExpense };
