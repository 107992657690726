import { useCallback, useEffect, useState } from 'react';
import { TableLayout } from '~components';
import ApiServices from '~services';
import { FormInput } from './form-input';
import styled from 'styled-components';
import { Button, Input, message, Popconfirm, Space } from 'antd';
import { debounce } from 'lodash';
import { TableTitle } from '~commons/styles';
import { SyncOutlined } from '@ant-design/icons';

const DEFAULT_FILTER = {
  PageNumber: 0,
  PageSize: 10,
  Text: '',
};
export const Functions = () => {
  const [loading, setLoading] = useState(false);
  const [dataRow, setDataRow] = useState([]);
  const [selected, setSelected] = useState();
  const [filter, setFilter] = useState(DEFAULT_FILTER);

  const getData = (query) => {
    setLoading(true);
    const Columns = {};
    if (query?.Columns) {
      for (const key of Object.keys(query?.Columns)) {
        Columns[key] = query.Columns[key];
      }
    }
    return ApiServices.getFunctions({ ...query, Columns })
      .then((res) => {
        setDataRow(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setDataRow([]);
      });
  };
  const onClickShowDetail = (e, record) => {
    e.preventDefault();
    setSelected(record);
  };

  const handleSyncData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await ApiServices.syncDataFunctionsImport();
      if (res?.data?.data === 'success') {
        window.TmsNoti.success('Đồng bộ thành công', res?.data?.message);
      } else {
        window.TmsNoti.error('Lỗi', 'Đồng bộ thất baị');
      }
    } catch (error) {
      console.log('Sync Data System Functions', { error });
      window.TmsNoti.error('Lỗi', 'Đồng bộ thất baị');
    } finally {
      setLoading(false);
    }
  };

  const onClickDelete = (record) => {
    return ApiServices.deleteFunction(record.id)
      .then(() => {
        getData(filter);
        message.success('Thao tác thành công!!!');
      })
      .catch((err) => {
        console.error('update Menu', err);
        message.error('Thao tác thất bại');
      });
  };
  const onChangeColumnSearch = (event) => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = event.target.value;
      const name = event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns };
      }
      Columns[name] = value;
      return { ...prev, Columns };
    });
  };
  const dataSearch = filter.Columns;

  const columns = [
    {
      title: (
        <TableTitle>
          Mã
          <Input value={dataSearch?.code} name='code' onChange={onChangeColumnSearch} />
        </TableTitle>
      ),
      dataIndex: 'code',
      render: (val, record) => (
        <a href='#' onClick={(e) => onClickShowDetail(e, record)}>
          {val}
        </a>
      ),
    },
    {
      title: (
        <TableTitle>
          Tên
          <Input
            value={dataSearch?.controllerName}
            name='controllerName'
            onChange={onChangeColumnSearch}
          />
        </TableTitle>
      ),
      dataIndex: 'controllerName',
    },
    {
      title: (
        <TableTitle>
          Phương thức
          <Input value={dataSearch?.method} name='method' onChange={onChangeColumnSearch} />
        </TableTitle>
      ),
      dataIndex: 'method',
    },
    {
      title: (
        <TableTitle>
          Url
          <Input value={dataSearch?.urlApi} name='urlApi' onChange={onChangeColumnSearch} />
        </TableTitle>
      ),
      dataIndex: 'urlApi',
    },
    {
      dataIndex: 'description',
      title: (
        <TableTitle>
          Mô tả
          <Input
            value={dataSearch?.description}
            name='description'
            onChange={onChangeColumnSearch}
          />
        </TableTitle>
      ),
    },
    {
      title: 'Người Tạo',
      dataIndex: 'createdBy',
    },
    {
      title: '#',
      dataIndex: 'action',
      render: (_, record) => (
        <Popconfirm
          className='red pointer'
          title='Xác nhận xóa!'
          onConfirm={() => onClickDelete(record)}
        >
          Xóa
        </Popconfirm>
      ),
    },
  ];
  const onCloseEditTab = () => setSelected();

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const createNew = () => setSelected({});
  const headerContent = (
    <Space>
      <Button type='primary' onClick={createNew}>
        Thêm Mới
      </Button>
      <Button type='primary' icon={<SyncOutlined />} loading={loading} onClick={handleSyncData}>
        Đồng bộ
      </Button>
    </Space>
  );

  const pagination = {
    total: dataRow?.totalRecords || 0,
    showSizeChanger: false,
    pageSize: filter.PageSize,
    onChange: (PageNumber) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
      })),
  };

  return (
    <>
      <TableStyled
        rowKey='id'
        columns={columns}
        data={dataRow?.data}
        headerContent={headerContent}
        pagination={pagination}
        loading={loading}
        titleTab={selected?.code || 'Thêm Mới'}
        tabPanels={[
          {
            title: 'Cập nhật thông tin',
            content: <FormInput dataInput={selected} reload={getData} onClose={onCloseEditTab} />,
          },
        ]}
        isLoadingTab={false}
        closeDrawer={onCloseEditTab}
        selected={selected}
        isDisplayCheckbox={false}
      />
    </>
  );
};

const TableStyled = styled(TableLayout)`
  & .ant-table-row-indent.indent-level-1 {
    display: none;
  }
  & button.ant-table-row-expand-icon-spaced {
    display: none;
  }
`;
