import React from 'react';
import { Row, Col } from 'antd';
import { DisplayInfo } from '~components/display-info';

const ViewInfo = ({ dataView }) => {
  return (
    <div>
      <Row gutter={16}>
        <Col span={6}>
          <DisplayInfo title='Mã Công ty' value={dataView?.code} />
        </Col>
        <Col span={6}>
          <DisplayInfo title='Tên viết tắt' value={dataView?.title} />
        </Col>
        <Col span={6}>
          <DisplayInfo title='Tên Công ty' value={dataView?.name} />
        </Col>
        <Col span={6}>
          <DisplayInfo title='Mã số thuế' value={dataView?.taxCode} />
        </Col>
        <Col span={6}>
          <DisplayInfo title='Địa chỉ' valueArr={dataView?.arrAddressId || []} renderArr={true} />
        </Col>
        <Col span={6}>
          <DisplayInfo title='Số điện thoại' valueArr={dataView?.arrPhone || []} renderArr={true} />
        </Col>
        <Col span={6}>
          <DisplayInfo title='Fax' valueArr={dataView?.arrFax || []} renderArr={true} />
        </Col>
        <Col span={6}>
          <DisplayInfo title='Email' valueArr={dataView?.arrEmail || []} renderArr={true} />
        </Col>
        <Col span={6}>
          <DisplayInfo title='Chủ công ty' value={dataView?.ownerName} />
        </Col>
        <Col span={6}>
          <DisplayInfo title='Người liên hệ' value={dataView?.contactName} />
        </Col>
        <Col span={6}>
          <DisplayInfo
            title='SĐT liên hệ'
            valueArr={dataView?.arrContactPhone || []}
            renderArr={true}
          />
        </Col>
      </Row>
    </div>
  );
};

export { ViewInfo };
