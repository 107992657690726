import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import ApiServices from '~services';
const VehicleReplacement = ({ arrIdSelectVendor }) => {
  const [dataReplacement, setDataReplacement] = useState([]);
  const getInit = async () => {
    try {
      const { data } = await ApiServices.getRPVehicleReplacement(arrIdSelectVendor);
      if (data) {
        setDataReplacement(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    arrIdSelectVendor?.length && getInit();
  }, [arrIdSelectVendor]);

  const columns = [
    {
      title: 'Cảnh Báo',
      dataIndex: 'warningName',
      width: 200,
      render: (value, record) => (
        <a
          target='_blank'
          href={`/report-vehicle-replacement?categoryId=${record.categoryId}&vendorId=${arrIdSelectVendor}`}
          rel='noreferrer'
        >
          {value || ''}
        </a>
      ),
    },
    {
      title: 'S.Lượng',
      dataIndex: 'count',
      width: 50,
      align: 'center',
    },
  ];

  return (
    <Table
      rowKey='categoryId'
      dataSource={dataReplacement}
      columns={columns}
      size='small'
      bordered
      pagination={false}
    />
  );
};

export { VehicleReplacement };
