import React, { useEffect, useState, useRef } from 'react';
import { TableLayout, UploadExcelButton } from '~components';
import { LocationsForm } from './locations-form';
import LocationsFields from './fields-data';
import { Button, Popconfirm, message, Input, Space, Tabs } from 'antd';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { LocationNoteUse } from './location-not-use';
import { DownloadOutlined } from '@ant-design/icons';

const EXCEL_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/Location.xlsx';
const Locations = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [locations, setLocations] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  const [keyTab, setKeyTab] = useState(1);
  const [loadingExport, setLoadingExport] = useState(false);
  console.log(fields);
  const [loadingChangeLocation, setLoadingChangeLocation] = useState(false);
  //-------------------------------------------------------------------

  const typingTimeoutref = useRef(null);
  //-------------------------------------------------------------------
  const [searchByColumn, setSearchByColumn] = useState({
    code: '',
    name: '',
    typeLocationName: '',
    address: '',
    companyName: '',
    note: '',
  });

  const getLocations = async () => {
    let dataFilterSend = { ...filter };
    if (Object.keys(dataFilterSend.Columns).length === 0) {
      delete dataFilterSend['Columns'];
    } else {
      const columnJSONSTRING = JSON.stringify(dataFilterSend.Columns);
      dataFilterSend.Columns = columnJSONSTRING;
    }

    try {
      setLoading(true);
      let { data } = await ApiServices.getLocations(dataFilterSend);
      setLoading(false);
      let pag = pagination;
      pag.total = data?.totalRecords || 0;
      pag.pageSize = data?.pageSize;
      pag.current = data?.pageNumber;

      data = data?.data || [];
      setLocations(data);
      setPagination(pag);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  //----------- Start ----- Pagination Search ---------
  //----------- Pagination --------------------------
  const onChangePagination = (page, size) => {
    let filterTemp = filter;
    filterTemp.PageNumber = page;
    filterTemp.PageSize = size;
    setFilter(filterTemp);
    getLocations();
  };
  const [pagination, setPagination] = useState({
    total: 1,
    showSizeChanger: true,
    onChange: onChangePagination,
  });
  const [filter, setFilter] = useState({
    PageNumber: 1,
    PageSize: 20,
    Text: '',
    Columns: '',
  });

  // ------------ Search Column ----------------------
  const handleSearchColumnFn = (event, name) => {
    const filterTmp = filter;
    const val = event.target.value;
    let _searchByColumn = { ...searchByColumn };
    _searchByColumn[`${name}`] = val;
    setSearchByColumn(_searchByColumn);
    // Clear timeout
    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }
    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 0) {
        filter.PageNumber = 1;
        let searchColumnFn = { ...filter.Columns };
        searchColumnFn[`${name}`] = val;
        filter.Columns = searchColumnFn;

        setFilter({ ...filterTmp, PageNumber: 1 });
        getLocations();
      }
      if (val.length === 0) {
        delete filter.Columns[`${name}`];
        setFilter({ ...filterTmp, PageNumber: 1 });
        getLocations();
      }
    }, 1000);
  };
  // ------------- End Search --------------------

  const setFieldsForm = async (value) => {
    const locationsFields = await LocationsFields(value);
    await setFields(locationsFields);
    return locationsFields;
  };
  const reload = async () => {
    setSelected(null);
    await getLocations();
  };

  const getUpdatedForm = async (value, isNew = false, id = null, selectedVal) => {
    const locationsForm = await (
      <LocationsForm
        addressVal={isNew ? null : selectedVal?.address}
        position={isNew ? null : selectedVal?.geo?.coordinates}
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        status={selectedVal?.status}
        id={id}
        valueSelected={selectedVal}
        reload={reload}
        onChange={setFields}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: locationsForm,
      },
    ]);
  };

  const onRowSelected = async (value) => {
    await setIsLoadingTab(true);
    await setTitleTab(value.code);
    await setFieldsForm(value)
      .then((res) => {
        return getUpdatedForm(res, false, value.id, value);
      })
      .then(() => setSelected(value));
    setIsLoadingTab(false);
  };
  const deleteLocation = async (record) => {
    try {
      const res = await ApiServices.deleteLocation(record.id);
      if (res.data) {
        message.success('Xoá Địa điểm thành công');
        return reload();
      }
      message.error('Xoá Địa điểm thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };

  const changeNotUse = async (record) => {
    if (loadingChangeLocation) return;
    setLoadingChangeLocation(true);
    try {
      const res = await ApiServices.changeLocation(record.id);
      if (res?.data?.data === 'success') {
        message.success('Chuyển không sử dụng, thành công');
        return reload();
      }
    } catch (error) {
      message.error('Chuyển không sử dụng, thất bại, vui lòng thử lại sau!');
      console.log(error);
    } finally {
      setLoadingChangeLocation(false);
    }
  };

  const columns = [
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Mã Địa Điểm</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.code}
            onChange={(event) => handleSearchColumnFn(event, 'code')}
          />
        </span>
      ),
      dataIndex: 'code',
      align: 'center',
      width: 150,
      render: (value, record) => (
        <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>
      ),
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Tên Địa Điểm</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.name}
            onChange={(event) => handleSearchColumnFn(event, 'name')}
          />
        </span>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Loại Địa Điểm</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.typeLocationName}
            onChange={(event) => handleSearchColumnFn(event, 'typeLocationName')}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'typeLocationName',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Địa Chỉ</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.address}
            onChange={(event) => handleSearchColumnFn(event, 'address')}
          />
        </span>
      ),
      width: 300,
      dataIndex: 'address',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Công Ty</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.companyName}
            onChange={(event) => handleSearchColumnFn(event, 'companyName')}
          />
        </span>
      ),
      width: 180,
      dataIndex: 'companyName',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Ghi Chú</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.note}
            onChange={(event) => handleSearchColumnFn(event, 'note')}
          />
        </span>
      ),
      width: 200,
      dataIndex: 'note',
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 110,
      align: 'center',
      render: (record) => (
        <Space direction='vertical'>
          <Popconfirm
            onConfirm={() => deleteLocation(record)}
            title='Xoá Địa điểm này?'
            okText='Có'
            cancelText='Không'
          >
            <a style={{ color: 'red' }} href='#'>
              Xoá
            </a>
          </Popconfirm>
          <Popconfirm
            onConfirm={() => changeNotUse(record)}
            title='Chuyển địa điểm này sang Không sử dụng'
            okText='Có'
            cancelText='Không'
          >
            <a style={{ color: 'blue' }} href='#'>
              Chuyển Không Sử Dụng
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const createdLocations = async () => {
    await setTitleTab('');
    await setFieldsForm({})
      .then((res) => getUpdatedForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };

  const onUploadExcel = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importLocation(file)
      .then((res) => {
        if (!res.data?.error) {
          message.success(res?.data?.message);
          reload();
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT CARR ERROR', err);
        message.error('Thao tác thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };

  const handleExportExcel = async (query) => {
    if (loadingExport) return;

    setLoadingExport(true);
    try {
      const { data } = await ApiServices.exportLocation(query);
      if (data) {
        const outputFilename = `Danh_Sac_Dia_Diem.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoadingExport(false);
    }
  };

  const headerContent = (
    <Space>
      <Button type='primary' onClick={createdLocations}>
        Thêm Mới
      </Button>
      <UploadExcelButton
        loading={loading}
        onChange={onUploadExcel}
        templateUrl={EXCEL_TEMPLATE_URL}
      />
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        loading={loadingExport}
        style={{ padding: '0px 20px', marginLeft: '10px' }}
        onClick={() => handleExportExcel(filter)}
      >
        Export Excel
      </Button>
    </Space>
  );

  const onChangeTab = (key) => {
    setKeyTab(key);
  };

  return (
    <div>
      <h3>DANH SÁCH ĐỊA ĐIỂM</h3>
      <Tabs defaultActiveKey='1' onChange={onChangeTab}>
        <Tabs.TabPane tab='Đang Sử Dụng' key='1'>
          <div
            style={{
              width: '100%',
              maxHeight: '100vh',
              height: 'calc(100vh - 120px)',
            }}
          >
            <TableLayout
              rowKey='id'
              columns={columns}
              data={locations}
              loading={loading}
              selected={selected}
              closeDrawer={() => setSelected(null)}
              isLoadingTab={isLoadingTab}
              headerContent={headerContent}
              titleTab={titleTab}
              tabPanels={tabPanels}
              pagination={pagination}
              isDisplayCheckbox={false}
              headerLength='280px'
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab='Không Sử Dụng' key='2'>
          <LocationNoteUse keyTab={keyTab} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export { Locations };
