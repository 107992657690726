import { Button, Input, Tabs, Tag, Badge } from 'antd';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { JOB_HANDLE_WIDTH } from '~commons/constants';
import Utils from '~commons/utils';
import { TableLayout } from '~components';
import ApiServices from '~services';
import { FormInput } from './form-input';

const ActionButton = styled(Button)`
  padding: 0;
`;
const JobStatus = ({ status }) => {
  switch (status) {
    case 0:
      return <Tag>Khởi tạo</Tag>;

    default:
      return <Tag color='success'>Hoàn thành</Tag>;
  }
};

const statusTag = (text = '', bgColor = '') => {
  return (
    <Badge>
      <div
        style={{
          backgroundColor: bgColor,
          width: '100px',
          padding: '5px 10px',
          textAlign: 'center',
          borderRadius: '4px',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        {text}
      </div>
    </Badge>
  );
};

export const JobHandle = () => {
  const [loading, setLoading] = useState(false);
  const [dataRow, setDataRow] = useState([]);
  const [columnSearch, setColumnSearch] = useState({});
  const [selected, setSelected] = useState();
  const [loadingTab] = useState(false);
  const [tab, setTab] = useState('a');
  const [filter, setFilter] = useState({
    PageNumber: 0,
    PageSize: 10,
    Text: '',
  });

  const timeoutRef = useRef();
  const formRef = useRef();

  const getData = () => {
    setLoading(true);
    const newFilter = { ...filter };
    Object.keys(columnSearch).length && (newFilter.Columns = columnSearch);

    return ApiServices.getListTransferJobs(newFilter)
      .then(({ data }) => {
        setDataRow(data?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error('Error get list transfer A ', err);
      });
  };
  const onChangeColumnSearch = (event) => {
    setColumnSearch((prev) => {
      const newColumnSearch = { ...prev };
      const value = event.target.value;
      const name = event.target.name;
      if (!value) {
        delete newColumnSearch[name];
        return newColumnSearch;
      }
      newColumnSearch[name] = value;
      return newColumnSearch;
    });
  };

  const renderTyOrder = (value) => {
    switch (value) {
      case 'Booking':
        return statusTag('Booking', 'green');
      case 'Bill':
        return statusTag('Bill', '#EB455F');

      default:
        break;
    }
  };

  const columns = [
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Trạng Thái</div>
        </span>
      ),
      dataIndex: 'status',
      width: 100,
      render: (val) => <JobStatus status={val} />,
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Tên Khách Hàng</div>
          <Input
            placeholder='Search'
            allowClear
            name='customerName'
            onChange={onChangeColumnSearch}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'customerName',
    }, //
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Subno</div>
          <Input placeholder='Search' name='subnoNumber' onChange={onChangeColumnSearch} />
        </span>
      ),
      width: 200,
      dataIndex: 'subnoNumber',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Loại ĐH</div>
          <Input placeholder='Search' name='type' onChange={onChangeColumnSearch} />
        </span>
      ),
      width: 120,
      dataIndex: 'type',
      render: renderTyOrder,
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Cắt Máng</div>
          <Input placeholder='Search' allowClear />
        </span>
      ),
      width: 120,
      dataIndex: 'booking_CutOffDateTime',
      render: (val) => Utils.formatDate(val),
    }, //
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Cắt SI</div>
          <Input placeholder='Search' allowClear />
        </span>
      ),
      width: 120,
      dataIndex: 'booking_SIDateTime',
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Nơi Lấy Rỗng</div>
          <Input placeholder='Search' allowClear />
        </span>
      ),
      width: 120,
      dataIndex: 'lr',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Nơi Lấy Hàng</div>
          <Input placeholder='Search' allowClear />
        </span>
      ),
      width: 120,
      dataIndex: 'lh',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>YCLR</div>
          <Input placeholder='Search' allowClear />
        </span>
      ),
      width: 120,
      dataIndex: 'booking_DeliveryContRequestDate',
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Số Cont</div>
          <Input
            placeholder='Search'
            allowClear
            name='contNumber'
            onChange={onChangeColumnSearch}
          />
        </span>
      ),
      width: 120,
      dataIndex: 'contNumber',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Số Seal</div>
          <Input
            placeholder='Search'
            allowClear
            name='sealNumber'
            onChange={onChangeColumnSearch}
          />
        </span>
      ),
      width: 120,
      dataIndex: 'sealNumber',
    },
    {
      title: '#',
      width: 84,
      dataIndex: 'action',
      fixed: 'right',
      render: (_, record) => (
        <>
          <ActionButton type='link' onClick={() => onClickFinishJob(record)}>
            Hoàn thành
          </ActionButton>
        </>
      ),
    },
  ];
  const onClickFinishJob = (record) => {
    setSelected(record);
  };
  const onCloseEditTab = () => setSelected();

  useEffect(() => {
    timeoutRef.current = setTimeout(() => getData(), [500]);
    return () => clearTimeout(timeoutRef.current);
  }, [columnSearch, filter.PageNumber]);

  const onChangeTab = (val) => {
    setTab(val);
  };
  const pagination = {
    total: dataRow?.[tab]?.totalRecords || 0,
    showSizeChanger: false,
    pageSize: filter.PageSize,
    onChange: (PageNumber) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
      })),
  };

  return (
    <TableLayout
      rowKey='subnoNumber'
      headerContent={
        <Tabs activeKey={tab} onChange={onChangeTab}>
          <Tabs.TabPane tab='Lấy Rỗng Xuất/Lấy Hàng Nhập' key='a' />
          <Tabs.TabPane tab='Giao Hàng Xuất - Trả Rỗng Nhập' key='b' />
        </Tabs>
      }
      columns={columns}
      data={dataRow?.[tab]?.data}
      loading={loading}
      titleTab={selected?.jobName}
      width={JOB_HANDLE_WIDTH}
      isDisplayCheckbox={false}
      tabPanels={[
        {
          title: 'Cập nhật thông tin',
          content: (
            <FormInput
              ref={formRef}
              dataInput={selected}
              reload={getData}
              onClose={onCloseEditTab}
            />
          ),
        },
      ]}
      closeDrawer={onCloseEditTab}
      selected={selected}
      pagination={pagination}
      isLoadingTab={loadingTab}
    />
  );
};
