import React, { useEffect, useState, useCallback } from 'react';
import { Input, Modal, Typography, Row, Col } from 'antd';
import styled from 'styled-components';
import useFinishTaskApi from '~pages/white-board/assign-car-list/use-finish-task-api';
import { Selection } from '~components';
import ApiServices from '~services';

export const ChangeLocationForm = ({ job, onClose, reload, setLoading }) => {
  const isOpen = !!job;
  const { changeLocationGetCont, createDistanceMultiple, checkDistanceMultiple } =
    useFinishTaskApi();
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(null);
  const [note, setNote] = useState('');
  const [isDistance, setIsDistance] = useState(false);
  const [errorLocation, setErrorLocation] = useState('');
  //
  const [locationNotDistanceList, setLocationNotDistanceList] = useState([]);

  const checkSubmit = locationNotDistanceList?.filter((item) => !item.distanceKm);
  const disabledButton = !location || (isDistance && checkSubmit?.length > 0);

  const getLocations = (Query) => {
    return ApiServices.getLocations({ Query })
      .then((result) => {
        return setLocations(result.data?.data);
      })
      .catch((err) => console.log('Get Location Error', err));
  };

  useEffect(() => {
    let isMounted = true;
    isMounted && getLocations();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleClose = () => {
    setLocation(null);
    setNote('');
    setIsDistance(false);
    setLocationNotDistanceList([]);
    setErrorLocation('');
    onClose();
  };

  const onChangeLocation = (value) => {
    if (value === job?.endLocationCode) {
      setErrorLocation('Giống vị trí hiện tại, Xin vui lòng chọn vị trí khác');
      setLocation(null);
      setIsDistance(false);
    } else {
      setLocation(value);
      setIsDistance(false);
      errorLocation && setErrorLocation('');
    }
  };

  const onChangeNewLocation = async () => {
    await changeLocationGetCont(
      {
        assignId: job.assignId,
        locationNewDepot: location,
        note,
        assignCompleted: true,
      },
      reload,
      setLoading
    );
  };

  const onSubmit = async () => {
    if (!location) {
      return;
    }

    if (isDistance) {
      const createNewDistance = await createDistanceMultiple(locationNotDistanceList);
      if (createNewDistance === 'success') {
        await onChangeNewLocation();
        handleClose();
        setIsDistance(false);
      }
    } else {
      const dataCheckLocation = [
        {
          begin: job?.endLocationCode,
          end: location,
        },
        {
          begin: location,
          end: job?.endLocationCodeNext,
        },
      ];
      const checkDistance = await checkDistanceMultiple(dataCheckLocation);
      if (checkDistance?.length > 0) {
        const dataMap = checkDistance?.map((item) => {
          item.distanceKm = 0;
          item.loss = 0;
          return item;
        });
        setLocationNotDistanceList(dataMap);
      } else {
        setLocationNotDistanceList([]);
      }
      if (!checkDistance) {
        await onChangeNewLocation();
        handleClose();
        setIsDistance(false);
      } else {
        setIsDistance(true);
      }
    }
  };

  const handleChangeDistance = useCallback(
    (event, index) => {
      if (Number.isNaN(+event.target.value)) {
        return;
      }
      setLocationNotDistanceList((prev) => {
        prev[index].distanceKm = +event.target.value;
        return [...prev];
      });
    },
    [locationNotDistanceList]
  );

  const handleChangeLoss = useCallback(
    (event, index) => {
      if (Number.isNaN(+event.target.value)) {
        return;
      }
      setLocationNotDistanceList((prev) => {
        prev[index].loss = +event.target.value;
        return [...prev];
      });
    },
    [locationNotDistanceList]
  );
  const renderLocationNotDistance = (item, index) => {
    return (
      <div key={index}>
        <div className='blue'>
          {item?.beginName} <span className='red'>{'=>'}</span> {item?.endName}
        </div>
        <Row gutter={16}>
          <Col span={12}>
            <Typography>Khoảng cách</Typography>
            <Input
              style={{
                borderColor: '#00BFFF',
                borderRadius: '5px',
                border: item?.distanceKm ? '1px solid #00BFFF' : '1px solid red',
                padding: '4px',
              }}
              className='red'
              size='small'
              value={item?.distanceKm}
              onChange={(event) => handleChangeDistance(event, index)}
              placeholder='Cự ly'
            />
          </Col>
          <Col span={12}>
            <Typography>Hao hụt</Typography>
            <Input
              style={{
                borderColor: '#00BFFF',
                borderRadius: '5px',
                border: '1px solid #00BFFF',
                padding: '4px',
              }}
              defaultValue={0}
              className='red'
              size='small'
              value={item?.loss}
              min={0}
              onChange={(event) => handleChangeLoss(event, index)}
              placeholder='Hao hụt'
            />
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      <Modal
        title='Chọn địa điểm lấy cont'
        width={500}
        style={{
          top: 20,
        }}
        open={isOpen}
        onOk={onSubmit}
        onCancel={handleClose}
        okButtonProps={{
          disabled: disabledButton,
        }}
      >
        <Wrapper>
          <Typography>Địa điểm lấy cont hiện tại:</Typography>
          <InputStyled value={job?.endLocationName || '-'} disabled />
          <Typography>Địa điểm lấy cont mới:</Typography>
          <Selection
            data={locations}
            width={460}
            onSearch={getLocations}
            value={location}
            onChange={(value) => onChangeLocation(value)}
            fieldValue='name'
            placeholder='Chọn địa điểm'
          />
          {errorLocation && <div className='red'>{errorLocation}</div>}
          {isDistance &&
            locationNotDistanceList?.length &&
            locationNotDistanceList?.map(renderLocationNotDistance)}
          <Typography>Ghi chú:</Typography>
          <Input.TextArea
            placeholder='Ghi Chú'
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </Wrapper>
      </Modal>
    </>
  );
};
const Wrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InputStyled = styled(Input)`
  width: 460px;
`;
