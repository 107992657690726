import { DATE_FORMAT } from '~commons/constants';
import moment from 'moment';

export const KEYS = ['note'];
export const KEYS_OF_DATE = ['beginDate', 'endDate'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });
  KEYS_OF_DATE.forEach((item) => {
    result.push({
      name: [item],
      value: data[item]
        ? data[item] === null
          ? null
          : data[item] !== '0001-01-01T00:00:00+07:00'
          ? moment(data[item], DATE_FORMAT)
          : null
        : null,
    });
  });
  return result;
};
