import React, { useCallback, useEffect, useState } from 'react';
import { Input, Table, Space, Tag } from 'antd';
import styled from 'styled-components';
import { TableTitle, Wrapper } from '~commons/styles';
import Utils from '~commons/utils';
import moment from 'moment';
import { debounce } from 'lodash';
import ApiServices from '~services';
import { CONT_ORDER_TYPES } from '~commons/constants';
import { RangeDateFilter } from '~components';
import { LogoutOutlined, LoginOutlined } from '@ant-design/icons';

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('date').format('YYYY-MM-DD hh:mm'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD hh:mm'),
};

const USER_DEV = [
  {
    uniqueId: '4dd7eca00c42b34f',
    deviceID: 'k68v1_64_titan',
    model: 'SM-A315G',
    name: 'Hoa - SDDM',
  },
  {
    uniqueId: '0d820695f33ce086',
    deviceID: 'exynos9825',
    model: 'SM-N970F',
    name: 'An - SDDM',
  },
];

const AppUserAccount = () => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [taskJobDiaryList, setTaskJobDiaryList] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [filterSearch, setFilterSearch] = useState(null);

  // ================ Render Data  =======================
  const formatDataRender = (dataFormat = []) => {
    let dataFilter = [];
    let dataSortItem = [];
    let userId = '';
    dataFormat?.map((itemInit, indexInit) => {
      // Init
      if (indexInit === 0 && indexInit + 1 === dataFormat?.length) {
        userId = itemInit?.userId;
        dataSortItem.push(itemInit);
        dataFilter = [itemInit];
        return;
      }
      // Init
      if (indexInit === 0) {
        userId = itemInit?.userId;
        dataSortItem.push(itemInit);
        // dataFilter = [itemInit];
        return;
      }

      // Cung assignID nhung cai cuoi cung
      if (userId === itemInit.userId && indexInit + 1 === dataFormat?.length) {
        userId = itemInit?.userId;
        const dataSortItemEnd = [...dataSortItem]?.concat([itemInit]);

        dataSortItemEnd
          ?.sort((a, b) => {
            let da = a.id;
            let db = b.id;
            return da - db;
          })
          .map((dataEnd) => {
            dataFilter.push(dataEnd);
          });
        return;
      }

      // Neu cung userId
      if (userId === itemInit.userId) {
        dataSortItem.push(itemInit);
        return;
      }

      // Khac userId
      if (userId !== itemInit.userId) {
        userId = itemInit?.userId;
        const dataSortItemEnd = [...dataSortItem];
        dataSortItemEnd
          ?.sort((a, b) => {
            let da = a.id;
            let db = b.id;
            return da - db;
          })
          .map((dataEnd) => {
            dataFilter.push(dataEnd);
          });
        dataSortItem = [itemInit];
        return;
      }
    });

    let dataGroup = [];
    dataFilter?.forEach((item, index) => {
      if (index === 0) {
        const dataMap = {
          id: item?.userId,
          groupUserApp: item?.userAccount,
          children: [item],
        };
        dataGroup.push(dataMap);
        return;
      }
      const ind = dataGroup?.findIndex((el) => el?.id === item.userId);
      if (ind !== -1) {
        dataGroup?.[ind]?.children?.push(item);
        return;
      } else {
        const dataMap = {
          id: item?.userId,
          groupUserApp: item?.userAccount,
          children: [item],
        };
        dataGroup.push(dataMap);
        return;
      }
    });

    return dataGroup;
  };

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = {
      ...query,
    };

    return ApiServices.getUsersAppTracking(newQuery)
      .then(async (res) => {
        if (res?.data?.data) {
          setOriginData(res?.data?.data);
          console.log({ data: res?.data?.data });
          const dataResult = await formatDataRender(res?.data?.data);
          if (dataResult) {
            const activeExpanse = dataResult?.map((el) => el?.id);
            setExpandedRowKeys(activeExpanse);
            setTaskJobDiaryList(dataResult);
          }
        }
      })
      .catch((err) => {
        console.error('get Task Job Diary Detail Error: ', err);
      })
      .finally(() => setLoading(false));
  };

  const handleFilterData = async (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;

      if (query?.userAccount) {
        if (
          !Utils.convertValSearch(item?.userAccount)?.includes(
            Utils.convertValSearch(query?.userAccount)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.fullname) {
        if (
          !Utils.convertValSearch(item?.fullname)?.includes(Utils.convertValSearch(query?.fullname))
        ) {
          checkFilter = false;
        }
      }

      //// End
      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    // setTotal(dataFilter ? dataFilter : 0);
    const dataResult = await formatDataRender(dataFilter);

    const activeExpanse = dataResult?.map((el) => el?.id);
    setExpandedRowKeys(activeExpanse);
    setTaskJobDiaryList(dataResult);
  };

  const timeoutGetDataSearch = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    []
  );
  useEffect(() => {
    if (originData?.length) {
      timeoutGetDataSearch(filterSearch, originData);
    }
  }, [filterSearch]);

  const onColumnSearch = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilterSearch((prev) => {
      return { ...prev, [name]: value };
    });
  };

  // Get Data Init
  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 1000),
    []
  );

  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const onRowMerged = (record) => {
    if (record.groupUserApp) {
      return {
        colSpan: 0,
      };
    }
  };

  const renderToken = (_, record) => {
    switch (record?.deviceToken?.token) {
      case 'IN':
        return (
          <Tag
            style={{
              padding: '3px 6px',
            }}
            icon={<LogoutOutlined />}
            color='#3b5999'
          >
            Login
          </Tag>
        );
      case 'OUT':
        return (
          <Tag icon={<LoginOutlined />} color='#cd201f'>
            logout
          </Tag>
        );
      case 'ACCEPT':
        return <Tag color='green'>ACCEPT</Tag>;
      case 'START':
        return <Tag color='orange'>START</Tag>;
      case 'JOB':
        return <Tag color='cyan'>JOB</Tag>;

      default:
        break;
    }
  };

  const renderUniqueId = (_, record) => {
    const uniqueId = record?.deviceToken?.ipAddress || '';
    const findUserConstant = USER_DEV.find((item) => item.uniqueId === uniqueId);
    return (
      <div>
        <div>{uniqueId}</div>
        <b className='red'>{findUserConstant?.name}</b>
      </div>
    );
  };

  const columns = [
    {
      title: (
        <TableTitle>
          userAccount
          <Input name='userAccount' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'userAccount',
      width: 100,
      // align: 'center',
      render: (_, record) =>
        record.userAccount || (
          <b className='red'>
            User: <span className='blue'>{record.groupUserApp}</span>
          </b>
        ),
      onCell: (record) => {
        if (record.groupUserApp) {
          return {
            colSpan: 2,
          };
        }
      },
    },
    {
      title: (
        <TableTitle>
          fullname
          <Input name='fullname' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'fullname',
      width: 120,
      onCell: onRowMerged,
    },
    {
      title: <TableTitle>token</TableTitle>,
      dataIndex: 'token',
      width: 100,
      align: 'center',
      render: renderToken,
    },
    {
      title: <TableTitle>createdOn</TableTitle>,
      dataIndex: 'createdOn',
      render: (val) => val && Utils.formatDateHourFn_2(val),
      width: 100,
      align: 'center',
    },
    {
      title: <TableTitle>UniqueId</TableTitle>,
      dataIndex: 'ipAddress',
      width: 100,
      align: 'center',
      render: renderUniqueId,
    },
    {
      title: <TableTitle>currentVersion</TableTitle>,
      dataIndex: 'currentVersion',
      width: 100,
      align: 'center',
    },
    {
      title: <TableTitle>androidVersion</TableTitle>,
      dataIndex: 'androidVersion',
      width: 100,
      align: 'center',
      render: (_, record) => record?.deviceToken?.androidVersion,
    },
    {
      title: <TableTitle>deviceID</TableTitle>,
      dataIndex: 'deviceID',
      width: 100,
      align: 'center',
      render: (_, record) => record?.deviceToken?.deviceID,
    },
    {
      title: <TableTitle>deviceName</TableTitle>,
      dataIndex: 'deviceName',
      width: 100,
      align: 'center',
      render: (_, record) => record?.deviceToken?.deviceName,
    },
    {
      title: <TableTitle>locationName</TableTitle>,
      dataIndex: 'locationName',
      width: 100,
      align: 'center',
      render: (_, record) => record?.deviceToken?.locationName,
    },
    {
      title: <TableTitle>model</TableTitle>,
      dataIndex: 'model',
      width: 100,
      align: 'center',
      render: (_, record) => record?.deviceToken?.model,
    },
    {
      title: <TableTitle>note</TableTitle>,
      dataIndex: 'note',
      width: 300,
      align: 'center',
    },
  ];

  // const rowSelection = {
  //   checkStrictly: false,
  // };
  const expandable = { expandedRowKeys, onExpandedRowsChange: setExpandedRowKeys };

  const renderRowClassName = (record) => {
    if (record?.typeOrder === CONT_ORDER_TYPES.SUPPORT && record?.sort === 6) {
      return 'task-job-daily-background';
    }
    return '';
  };

  // const pagination = {
  //   current: 1,
  //   total: total || 0,
  //   pageSize: 20,
  //   pageSizeOptions: [10, 20, 30],
  // };

  return (
    <Wrapper>
      <h3>Tracking User App</h3>
      <Space>
        <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
      </Space>

      <div
        className='table-container-custom-assign-history'
        style={{
          maxHeight: `calc(100vh - 20px)`,
          marginTop: '15px',
        }}
      >
        <TableStyled
          loading={loading}
          columns={columns}
          dataSource={taskJobDiaryList}
          // pagination={pagination}
          expandable={expandable}
          // rowSelection={rowSelection}
          rowKey='id'
          size='small'
          scroll={{ x: '100vw', y: 'calc(100vh - 250px)' }}
          bordered
          rowClassName={renderRowClassName}
        />
      </div>
    </Wrapper>
  );
};

export { AppUserAccount };

const TableStyled = styled(Table)`
  & .ant-table-row-indent.indent-level-1 {
    display: none;
  }
  & button.ant-table-row-expand-icon-spaced {
    display: none;
  }
`;
