const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const layout = {
  labelCol: {
    span: 16,
  },
  wrapperCol: {
    span: 22,
  },
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      // offset: 0,
    },
    sm: {
      span: 24,
      // offset: 4,
    },
  },
};

export { layout, tailLayout, formItemLayout, formItemLayoutWithOutLabel };
