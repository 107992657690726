import React, { useState, useEffect, useCallback } from 'react';
import { Table, Input, Modal, Button, Space, Popconfirm, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { uid } from 'uid';
const { Column, ColumnGroup } = Table;
import Utils from '~commons/utils';
import { TableTitle } from '~commons/styles';
import { Form } from './form';
import ApiServices from '~services';
import { debounce } from 'lodash';
import { DATE_FORMAT } from '~commons/constants';
import moment from 'moment';
import { ViewHistory } from './view-history';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
};

const ACTION_KEY = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  NO_ACTION: '',
};

const CONT_TYPE_CODE = {
  CONT_20: 'cont_20"',
  CONT_40C: 'cont_40c',
  CONT_40T: 'cont_40t',
  CONT_45: 'cont_45"',
};

const CONT_TYPE_NAME = {
  CONT_20: '20"',
  CONT_40C: '40C',
  CONT_40T: '40T',
  CONT_45: '45"',
};

export const LiftingFees = () => {
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [titleForm, setTitleForm] = useState('');
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [contTypes, setContTypes] = useState([]);
  const [dataCreate, setDataCreate] = useState([]);
  const [keyAction, setKeyAction] = useState(ACTION_KEY.NO_ACTION);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchDate, setSearchDate] = useState(null);
  const [dataView, setDataView] = useState([]);

  const getContTypes = async () => {
    setLoading(true);
    const sortQuery = {
      sortColumn: 'code',
      sortColumnDirection: 'ASC',
    };
    try {
      const res = await ApiServices.getContTypes(sortQuery);
      if (res?.data?.data) {
        setContTypes(res?.data?.data || []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getLiftingFees = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    try {
      const res = await ApiServices.getLiftingFees(query);
      if (res?.data?.data) {
        const dataFormat = res?.data?.data?.map((item, index) => {
          item.key = index + 1;
          item?.a_Booking?.forEach((element) => {
            if (element.code === CONT_TYPE_CODE.CONT_20) {
              return (item.a_Booking_20 = element.fee);
            }
            if (element.code === CONT_TYPE_CODE.CONT_40C) {
              return (item.a_Booking_40C = element.fee);
            }
            if (element.code === CONT_TYPE_CODE.CONT_40T) {
              return (item.a_Booking_40T = element.fee);
            }
            if (element.code === CONT_TYPE_CODE.CONT_45) {
              return (item.a_Booking_45 = element.fee);
            }
          });
          item?.a_Bill?.forEach((element) => {
            if (element.code === CONT_TYPE_CODE.CONT_20) {
              return (item.a_Bill_20 = element.fee);
            }
            if (element.code === CONT_TYPE_CODE.CONT_40C) {
              return (item.a_Bill_40C = element.fee);
            }
            if (element.code === CONT_TYPE_CODE.CONT_40T) {
              return (item.a_Bill_40T = element.fee);
            }
            if (element.code === CONT_TYPE_CODE.CONT_45) {
              return (item.a_Bill_45 = element.fee);
            }
          });
          item?.b_Booking?.forEach((element) => {
            if (element.code === CONT_TYPE_CODE.CONT_20) {
              return (item.b_Booking_20 = element.fee);
            }
            if (element.code === CONT_TYPE_CODE.CONT_40C) {
              return (item.b_Booking_40C = element.fee);
            }
            if (element.code === CONT_TYPE_CODE.CONT_40T) {
              return (item.b_Booking_40T = element.fee);
            }
            if (element.code === CONT_TYPE_CODE.CONT_45) {
              return (item.b_Booking_45 = element.fee);
            }
          });

          item?.b_Bill?.forEach((element) => {
            if (element.code === CONT_TYPE_CODE.CONT_20) {
              return (item.b_Bill_20 = element.fee);
            }
            if (element.code === CONT_TYPE_CODE.CONT_40C) {
              return (item.b_Bill_40C = element.fee);
            }
            if (element.code === CONT_TYPE_CODE.CONT_40T) {
              return (item.b_Bill_40T = element.fee);
            }
            if (element.code === CONT_TYPE_CODE.CONT_45) {
              return (item.b_Bill_45 = element.fee);
            }
          });
          return item;
        });

        setDataList(dataFormat || []);
        setTotalRecords(res?.data?.totalRecords);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      await getContTypes();
      // await getLiftingFees();
    };
    getData();
  }, []);

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getLiftingFees(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const handleChangeDate = (date) => {
    setSearchDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'effectiveDate');
  };

  const generateDataByCont = (jobType = '') => {
    const dataCont = contTypes?.map((contType) => {
      return {
        id: uid(32) + jobType,
        typeId: contType.id,
        code: contType.code,
        name: contType.name,
        fee: 0,
      };
    });
    return dataCont;
  };

  const onCreate = () => {
    const dataInitCreate = {
      locationId: '',
      effectiveDate: null,
      a_Booking: generateDataByCont('a_Booking'),
      b_Booking: generateDataByCont('b_Booking'),
      a_Bill: generateDataByCont('a_Bill'),
      b_Bill: generateDataByCont('b_Bill'),
    };

    setDataCreate(dataInitCreate);
    setTitleForm('Thêm mới');
    setKeyAction(ACTION_KEY.CREATE);
    setShowForm(true);
  };

  const onUpdate = (record) => {
    setDataCreate(record);
    setTitleForm('Cập nhật');
    setKeyAction(ACTION_KEY.UPDATE);
    setShowForm(true);
  };

  const onView = async (record) => {
    if (!record.locationId) return;

    setLoading(true);
    try {
      const res = await ApiServices.getLiftingFeeHistory(record.locationId);
      if (res?.data?.data?.length) {
        setDataView(res?.data?.data || []);
      } else {
        window.TmsNoti?.error(
          'Thông báo',
          `Không có lịch sử chỉnh sửa cho: ${record?.locationName}`
        );
        setDataView([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onCloseForm = () => {
    setKeyAction(ACTION_KEY.NO_ACTION);
    setShowForm(false);
    setDataCreate([]);
  };
  const onCloseView = () => {
    setDataView([]);
  };

  const handleCreate = async (dataSend) => {
    setLoading(true);
    try {
      const res = await ApiServices.createLiftingFee(dataSend);
      if (res?.data?.data === 'success') {
        window.TmsNoti?.success('Thêm mới thành công');
        getLiftingFees(filter);
        onCloseForm();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (dataSend) => {
    setLoading(true);
    try {
      const res = await ApiServices.updateLiftingFee(dataSend);
      if (res?.data?.data === 'success') {
        window.TmsNoti?.success('Cập nhật thành công');
        getLiftingFees(filter);
        onCloseForm();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitForm = async () => {
    if (!dataCreate?.locationId) {
      window.TmsNoti?.error('Vui lòng nhập đầy đủ thông tin');
      return;
    }
    if (keyAction === ACTION_KEY.CREATE) {
      return handleCreate(dataCreate);
    }
    if (keyAction === ACTION_KEY.UPDATE) {
      return handleUpdate(dataCreate);
    }
  };
  const handleDelete = async (record) => {
    if (!record.locationId) return;

    setLoading(true);
    try {
      const res = await ApiServices.deleteLiftingFeeByLocation(record.locationId);
      if (res?.data?.data === 'success') {
        window.TmsNoti?.success('Xóa thành công');
        getLiftingFees();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleExportExcel = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const { data } = await ApiServices.exportExcelLiftingFeeHistory();
      if (data) {
        const outputFilename = `Bieu_Phi_Co_Dinh.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoading(false);
    }
  };

  // const renderTypeCont = (contType, jobType) => {
  //   if (!contType) return;
  //   return (
  //     <Column
  //       title={`${contType?.name}`}
  //       dataIndex={`${jobType}${contType?.name}`}
  //       key={`${jobType}${contType?.name}`}
  //       align='center'
  //       width={80}
  //       render={(_, record) => {
  //         if (!record[jobType] || !record[jobType]?.length) return '';
  //         const foundPrice = record[jobType]?.find((item) => item.typeId === contType.id);
  //         if (!foundPrice) return '';
  //         return foundPrice?.fee ? Utils.formatNumber(foundPrice?.fee) : '';
  //       }}
  //     />
  //   );
  // };

  const renderEffectDate = (val) => {
    return Utils.formatDate(val);
  };

  const pagination = {
    total: totalRecords || 0,
    showSizeChanger: false,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
      })),
  };

  return (
    <div>
      <Button type='primary' onClick={onCreate}>
        Thêm Mới
      </Button>
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        style={{ padding: '0px 20px', marginLeft: '10px' }}
        loading={loading}
        onClick={handleExportExcel}
      >
        Export Excel
      </Button>
      <div
        style={{
          marginTop: '10px',
        }}
      >
        <Table
          dataSource={dataList}
          bordered
          size='small'
          loading={loading}
          pagination={pagination}
          scroll={{
            x: 'calc(700px + 50%)',
            y: 'calc(100vh - 200px)',
          }}
        >
          <Column
            title={
              <TableTitle>
                Cảng/Bãi
                <Input name='locationName' placeholder='Search' onChange={onColumnSearch} />
              </TableTitle>
            }
            dataIndex='locationName'
            key='locationName'
            align='center'
            fixed={'left'}
            width={150}
          />
          <Column
            title={
              <TableTitle>
                NCC Hóa Đơn
                <Input name='supplierName' placeholder='Search' onChange={onColumnSearch} />
              </TableTitle>
            }
            dataIndex='supplierName'
            key='supplierName'
            align='center'
            // fixed={'left'}
            width={150}
          />
          {/* supplierName */}
          <ColumnGroup title='Lấy Rỗng Xuất'>
            <Column
              title={CONT_TYPE_NAME.CONT_20}
              dataIndex={'a_Booking_20'}
              key={'a_Booking_20'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            <Column
              title={CONT_TYPE_NAME.CONT_40C}
              dataIndex={'a_Booking_40C'}
              key={'a_Booking_40C'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            <Column
              title={CONT_TYPE_NAME.CONT_40T}
              dataIndex={'a_Booking_40T'}
              key={'a_Booking_40T'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            <Column
              title={CONT_TYPE_NAME.CONT_45}
              dataIndex={'a_Booking_45'}
              key={'a_Booking_45'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            {/* {contTypes?.map((item) => renderTypeCont(item, 'a_Booking'))} */}
          </ColumnGroup>
          <ColumnGroup title='Trả Rỗng Nhập'>
            <Column
              title={CONT_TYPE_NAME.CONT_20}
              dataIndex={'b_Bill_20'}
              key={'b_Bill_20'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            <Column
              title={CONT_TYPE_NAME.CONT_40C}
              dataIndex={'b_Bill_40C'}
              key={'b_Bill_40C'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            <Column
              title={CONT_TYPE_NAME.CONT_40T}
              dataIndex={'b_Bill_40T'}
              key={'b_Bill_40T'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            <Column
              title={CONT_TYPE_NAME.CONT_45}
              dataIndex={'b_Bill_45'}
              key={'b_Bill_45'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            {/* {contTypes?.map((item) => renderTypeCont(item, 'b_Bill'))} */}
          </ColumnGroup>
          <ColumnGroup title='Hạ Hàng Xuất'>
            <Column
              title={CONT_TYPE_NAME.CONT_20}
              dataIndex={'b_Booking_20'}
              key={'b_Booking_20'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            <Column
              title={CONT_TYPE_NAME.CONT_40C}
              dataIndex={'b_Booking_40C'}
              key={'b_Booking_40C'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            <Column
              title={CONT_TYPE_NAME.CONT_40T}
              dataIndex={'b_Booking_40T'}
              key={'b_Booking_40T'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            <Column
              title={CONT_TYPE_NAME.CONT_45}
              dataIndex={'b_Booking_45'}
              key={'b_Booking_45'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            {/* {contTypes?.map((item) => renderTypeCont(item, 'b_Booking'))} */}
          </ColumnGroup>
          <ColumnGroup title='Lấy Hàng Nhập'>
            <Column
              title={CONT_TYPE_NAME.CONT_20}
              dataIndex={'a_Bill_20'}
              key={'a_Bill_20'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            <Column
              title={CONT_TYPE_NAME.CONT_40C}
              dataIndex={'a_Bill_40C'}
              key={'a_Bill_40C'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            <Column
              title={CONT_TYPE_NAME.CONT_40T}
              dataIndex={'a_Bill_40T'}
              key={'a_Bill_40T'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            <Column
              title={CONT_TYPE_NAME.CONT_45}
              dataIndex={'a_Bill_45'}
              key={'a_Bill_45'}
              align='center'
              width={80}
              render={(value) => {
                return value ? Utils.formatNumber(value) : '';
              }}
            />
            {/* {contTypes?.map((item) => renderTypeCont(item, 'a_Bill'))} */}
          </ColumnGroup>
          <Column
            title={
              <TableTitle>
                Ngày Hiệu Lực
                <DatePicker
                  value={searchDate}
                  onChange={handleChangeDate}
                  placeholder='Search'
                  style={{ width: '100%' }}
                  format={DATE_FORMAT}
                  allowClear={true}
                />
              </TableTitle>
            }
            dataIndex='effectiveDate'
            key='effectiveDate'
            align='center'
            fixed={'right'}
            width={130}
            render={(val) => renderEffectDate(val)}
          />
          <Column
            title='Thao Tác'
            dataIndex='haDisplay'
            key='haDisplay'
            align='center'
            width={130}
            fixed={'right'}
            render={(_, record) => (
              <Space>
                <Button shape='circle' icon={<EyeOutlined />} onClick={() => onView(record)} />
                <Button
                  type='primary'
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => onUpdate(record)}
                />
                <Popconfirm
                  placement={'topRight'}
                  title={'Bạn có chắc chắn muốn xóa?'}
                  okText={'Có'}
                  cancelText={'Không'}
                  onConfirm={() => handleDelete(record)}
                >
                  <Button type='primary' shape='circle' icon={<DeleteOutlined />} danger />
                </Popconfirm>
              </Space>
            )}
          />
        </Table>
      </div>
      <Modal
        width={'700px'}
        title={titleForm}
        open={showForm}
        onCancel={onCloseForm}
        okButtonProps={{
          disabled: loadingBtn,
        }}
        onOk={handleSubmitForm}
      >
        <Form
          contTypes={contTypes}
          dataCreate={dataCreate}
          keyAction={keyAction}
          setDataCreate={setDataCreate}
          setLoadingBtn={setLoadingBtn}
        />
      </Modal>
      <Modal
        width={'500px'}
        style={{
          top: 20,
        }}
        title='Lịch sử chỉnh sửa'
        open={!!dataView?.length}
        onCancel={onCloseView}
        footer={null}
        okButtonProps={{
          disabled: loadingBtn,
        }}
        onOk={handleSubmitForm}
      >
        <ViewHistory data={dataView} />
      </Modal>
    </div>
  );
};
