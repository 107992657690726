import React, { useEffect, useState, useCallback } from 'react';
import { Space, Input, DatePicker } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import { Wrapper, TableTitle } from '~commons/styles';
import { RangeDateFilter, TableLayout } from '~components';
import ApiServices from '~services';
import { DATE_FORMAT } from '~commons/constants';
import Utils from '~commons/utils';

const DEFAULT_QUERIES = {
  PageSize: 20,
  PageNumber: 1,
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};

export const ReportTransitCont = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [searchDate, setSearchDate] = useState(null);
  const [searchTransitDate, setSearchTransitDate] = useState(null);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = {
      ...query,
    };
    return ApiServices.getReportTransitCont(newQuery)
      .then((res) => {
        if (res?.data) {
          setData(res?.data);
        }
      })
      .catch((err) => {
        console.error('get list report Accountants Booking Cont Unpaid Error: ', err);
        setData();
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );

  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const handleChangeDate = (date) => {
    setSearchDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'createdOn');
  };
  const handleChangeTransitDate = (date) => {
    setSearchTransitDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'transit_Date');
  };
  const columns = [
    {
      title: (
        <TableTitle>
          Tên Khách Hàng
          <Input name='customerName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'customerName',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Số Phiếu Chuyển Kho
          <Input name='subNoNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'subNoNumber',
      align: 'center',
      width: 200,
    },
    {
      title: (
        <TableTitle>
          Số Cont
          <Input name='contNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'contNumber',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Ngày Nhập
          <DatePicker
            value={searchDate}
            onChange={handleChangeDate}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'createdOn',
      align: 'center',
      width: 100,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Ngày Chuyển Kho
          <DatePicker
            value={searchTransitDate}
            onChange={handleChangeTransitDate}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'transit_Date',
      align: 'center',
      width: 100,
      render: (val) => Utils.formatDate(val),
    },
    // {
    //   title: (
    //     <TableTitle>
    //       Số Xe Kéo Rỗng
    //       <Input name='carDown' onChange={onColumnSearch} placeholder='Search' />
    //     </TableTitle>
    //   ),
    //   dataIndex: 'carDown',
    //   align: 'center',
    //   width: 130,
    // },
    {
      title: (
        <TableTitle>
          Số Xe
          <Input name='trace' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'trace',
      align: 'center',
      width: 130,
    },
    {
      title: (
        <TableTitle>
          Số Romooc
          <Input name='rmoocName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'rmoocName',
      align: 'center',
      width: 100,
    },
    // {
    //   title: (
    //     <TableTitle>
    //       Đ.Điểm Đóng Hàng
    //       <Input name='locationOfDeliveryName' onChange={onColumnSearch} placeholder='Search' />
    //     </TableTitle>
    //   ),
    //   dataIndex: 'locationOfDeliveryName',
    //   align: 'center',
    //   width: 200,
    // },
    {
      title: (
        <TableTitle>
          Địa Điểm
          <Input name='locationOfDeliveryName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'locationOfDeliveryName',
      align: 'center',
      width: 150,
    },
    // {
    //   title: (
    //     <TableTitle>
    //       Ghi Chú
    //       <Input name='portDown' onChange={onColumnSearch} placeholder='Search' />
    //     </TableTitle>
    //   ),
    //   dataIndex: 'portDown',
    //   align: 'center',
    //   width: 200,
    // },
  ];

  const pagination = {
    total: data?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <Wrapper>
      <h3>TỔNG HỢP PHIẾU CHUYỂN KHO</h3>
      <Space style={{ marginBottom: '10px' }}>
        <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
      </Space>
      <TableLayout
        columns={columns}
        rowKey='subnoNumber'
        data={data?.data}
        loading={loading}
        pagination={pagination}
        width={900}
        isDisplayCheckbox={false}
      />
    </Wrapper>
  );
};
