import { Table, Checkbox } from 'antd';
import Utils from '~commons/utils';

const Expenses = ({
  dataExpenses,
  expensesIdSelected,
  setExpensesIdSelected,
  setExpensesSelected,
  loadingData,
}) => {
  const handleSelectRow = (record) => {
    if (record.isExpenses) return;

    const ind = expensesIdSelected?.findIndex((item) => item === record?.id);
    if (ind === -1) {
      setExpensesIdSelected((prev) => {
        return [...prev, record?.id];
      });
      setExpensesSelected((prev) => {
        return [...prev, record];
      });
    } else {
      setExpensesIdSelected((prev) => {
        return prev.filter((item) => item !== record.id);
      });
      setExpensesSelected((prev) => {
        return prev.filter((item) => item.id !== record.id);
      });
    }
  };
  const renderRow = (value, record) => {
    return (
      <div
        style={{
          cursor: 'pointer',
        }}
        onClick={() => handleSelectRow(record)}
      >
        {value}
      </div>
    );
  };

  const renderIsExpenses = (value) => {
    if (value) {
      return <Checkbox checked />;
    }
    return '';
  };

  const columns = [
    {
      title: 'Tên chi phí',
      dataIndex: 'name',
      // width: 180,
      // fixed: true,
      align: 'center',
      render: renderRow,
    },
    {
      title: 'Đã giải trừ',
      dataIndex: 'isExpenses',
      // width: 100,
      align: 'center',
      // fixed: true,
      render: renderIsExpenses,
    },
    {
      title: 'Chưa VAT',
      dataIndex: 'price',
      // width: 100,
      align: 'center',
      render: (val) => val && Utils.formatNumber(val),
    },
    {
      title: '%VAT',
      dataIndex: 'vat',
      // width: 100,
      align: 'center',
    },
    {
      title: 'Tổng',
      dataIndex: 'total',
      // width: 100,
      align: 'center',
      render: (val) => val && Utils.formatNumber(val),
    },
    {
      title: 'Số Hoá đơn',
      dataIndex: 'billNumber',
      // width: 100,
      align: 'center',
    },
    {
      title: 'Ngày HĐ',
      dataIndex: 'billDate',
      // width: 130,
      render: (val) => Utils.formatDate(val),
      align: 'center',
    },
    {
      title: 'NCC Hoá đơn',
      dataIndex: 'supplierName',
      align: 'center',
      // width: 200,
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: expensesIdSelected,
    onChange: (selectedRowKeys, selectedRows) => {
      setExpensesIdSelected(selectedRowKeys);
      setExpensesSelected(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.isExpenses,
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleShowDataRequire = (record) => {
    if (record?.billRequired) {
      return 'order-from-ONP';
    }
  };

  return (
    <div
      className='table-container-custom'
      style={{
        maxHeight: '430px',
        overflow: 'auto',
      }}
    >
      <Table
        bordered
        size='small'
        loading={loadingData}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataExpenses}
        rowKey='id'
        // scroll={{
        //   x: '100%',
        //   y: 300,
        // }}
        pagination={false}
        rowClassName={handleShowDataRequire}
      />
    </div>
  );
};
export { Expenses };
