// const COLORS = ['#f5c6aa', '#93d9a3', '#ffbcbc'];
export const COLUMNS = [
  {
    title: 'STT',
    with: 40,
    dataIndex: 'index',
  },
  {
    title: 'Số xe',
    with: 40,
    dataIndex: 'CarCode',
  },
  {
    title: 'Tài xế',
    with: 40,
    dataIndex: 'DriverName',
  },
  {
    title: 'Lấy',
    with: 40,
    dataIndex: 'TotalUp',
  },
  {
    title: 'Hạ',
    with: 40,
    dataIndex: 'TotalDown',
  },

  {
    title: 'Số tour',
    with: 40,
    dataIndex: 'total',
  },
  {
    title: 'Số tour CK',
    with: 40,
    dataIndex: 'TotalTransfer',
  },
];
export const EXPORT_HEADER = [
  {
    index: 'STT',
    CarCode: 'Số Xe',
    DriverName: 'DriverName',
    TotalUp: 'Lấy',
    TotalDown: 'Hạ',
    total: 'Số Tour',
    TotalTransfer: 'Số Tour Ck',
  },
];
export const MERGE_COLUMN_SHEET = [];
