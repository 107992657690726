import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { DATE_FORMAT_FOR_COPY } from '~commons/constants';
import ApiServices from '~services';
import DatePicker from 'react-datepicker';

const UpdateActualDate = ({ dataSelected, handleCancel, reload }) => {
  const [loading, setLoading] = useState(false);
  const [actualDate, setActualDate] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  useEffect(() => {
    setActualDate(null);
  }, [dataSelected]);

  const handleUpdateActualDate = async () => {
    if (loading) return;
    setLoading(true);
    const formatDate = moment(actualDate).format('YYYY-MM-DD');
    return ApiServices.updateActualDate(dataSelected?.id, {
      actualDate: formatDate,
    })
      .then((res) => {
        if (res?.data?.data === 'success') {
          window.TmsNoti.success('Thao tác thành công', 'Đã cập nhật ngày đổ dầu thực tế', 8);
          reload();
        } else {
          window.TmsNoti.error('Cập nhật thất bại', '', 8);
        }
      })
      .catch((err) => {
        window.TmsNoti.error('Cập nhật thất bại', err, 8);
      })
      .finally(() => {
        handleCancel();
        setLoading(false);
      });
  };

  const handleCloseDatePicker = () => {
    setOpenDate(false);
  };

  const handleChangeActualDate = (date) => {
    setActualDate(date);
    handleCloseDatePicker();
  };

  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <b style={{ color: 'red' }}>
          Chọn ngày đổ dầu thực tế hiện tại:{' '}
          {dataSelected?.actualDate
            ? moment(dataSelected?.actualDate).format(DATE_FORMAT_FOR_COPY)
            : ''}
        </b>
      </div>

      <div
        style={{
          width: '100%',
          margin: '10px 0px',
          display: 'flex',
        }}
      >
        <b>Chọn ngày đổ dầu thực tế mới: </b>
        <div
          style={{
            marginLeft: '10px',
          }}
        >
          <DatePicker
            className='react-datepicker__day--today'
            isClearable
            toggleCalendarOnIconClick
            dateFormat='dd/MM/yyyy'
            selected={actualDate}
            open={openDate}
            onBlur={() => {
              handleCloseDatePicker();
            }}
            onChange={(date) => handleChangeActualDate(date)}
            onCalendarClose={handleCloseDatePicker}
            onInputError={(err) => console.log({ err })}
          >
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <button
                style={{
                  marginLeft: 'auto',
                }}
                onClick={handleCloseDatePicker}
              >
                Close
              </button>
            </div>
          </DatePicker>
        </div>
        <div>
          <button onClick={() => setOpenDate(!openDate)}>
            <CaretDownOutlined />
          </button>
        </div>
      </div>
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          marginTop: '40px',
        }}
      >
        <Button
          type='primary'
          loading={loading}
          disabled={!actualDate}
          onClick={handleUpdateActualDate}
        >
          Cập nhật
        </Button>
      </div>
    </>
  );
};

export { UpdateActualDate };
