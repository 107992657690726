import { useState } from 'react';

export default function useDataSelectSearch(apiSyncData, initData = []) {
  const [data, setData] = useState(initData);

  const getData = (query, queryField, filter) => {
    return apiSyncData(
      filter || (queryField && query?.Query) ? { Columns: { [queryField]: query?.Query } } : query
    )
      .then((result) => {
        setData(result.data?.data);
      })
      .catch((err) => console.log('get Locations Error', err));
  };

  return [data, getData, setData];
}
