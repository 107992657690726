import React, { useEffect, useRef, useState } from 'react';
import { Table, Button, Space, Affix, message, Tooltip, Modal } from 'antd';
import { PlusOutlined, FormOutlined } from '@ant-design/icons';
import { FormData } from './form-data';
import { calculation, calculationForCreate } from './calculate-data';
import FieldData, { KEYS_OF_DATE, KEYS_OF_DATE_TIME } from './field-data';
import { Popup } from '~components';
import moment from 'moment';
import ApiServices from '~services';
import { DATE_FORMAT } from '~commons/constants';
import { addCont, formatContData } from './api-helper';
import { UpdateActualDate } from './update-actual-date';

const DEFAULT_VALUE = FieldData({ contSum: 1 });

const ListContDetailUpdate = ({ onChange, formData, type, orderDetail, reload }) => {
  const [newCont, setNewCont] = useState(DEFAULT_VALUE);
  const formRef = useRef();
  const [data, setData] = useState([]);
  const [isVisiblePopup, setIsVisiblePopup] = useState(false); //fullAddress
  const [isLoading, setIsLoading] = useState(false); //fullAddress
  const [contTypes, setContTypes] = React.useState([]);
  const [showUpdateActualDate, setShowUpdateActualDate] = useState(null);

  const titleDate =
    type === 'Transit'
      ? 'Ngày Chuyển Kho'
      : type === 'Bill'
      ? 'Ngày yêu cầu lấy cont'
      : 'Ngày yêu cầu giao cont';

  useEffect(() => {
    setNewCont(DEFAULT_VALUE);
  }, [orderDetail?.id]);
  const getContTypes = async (Query = null) => {
    try {
      const { data } = await ApiServices.getContTypes({ Query });
      const types = {};
      data.data.forEach((item) => {
        types[item.id] = item.name;
      });

      setContTypes(types);
    } catch (err) {
      console.error('ERR - Get Cont Types', err);
    }
  };
  const initListCont = () => {
    if (orderDetail?.listCont?.length) {
      const dataFormat = orderDetail.listCont.map((item, index) => {
        const date =
          item.book_DeliveryContRequestDate ||
          item.bill_LiftingLadenContRequestDate ||
          item.transit_Date;
        const cargoReadyDate = item.book_CargoReadyDate
          ? moment(item.book_CargoReadyDate).format(DATE_FORMAT)
          : null;
        return {
          ...item,
          key: index,
          cargoReadyDate,
          deliveryContRequestDate: moment(date).format(DATE_FORMAT),
        };
      });
      setData(dataFormat);
      submitData(dataFormat);
    }
  };
  useEffect(() => {
    initListCont();
  }, [orderDetail]);
  useEffect(() => {
    getContTypes();
  }, []);

  const onSubmitForm = () => {
    formRef.current.submitForm();
  };
  const submitData = (data) => {
    onChange && onChange(calculationForCreate(data));
  };
  const addData = async (cont) => {
    setIsLoading(true);

    KEYS_OF_DATE.forEach((item) => {
      cont[item] = cont[item] ? moment(cont[item]).format('YYYY-MM-DD') : null;
    });
    KEYS_OF_DATE_TIME.forEach((item) => {
      cont[item] = cont[item] ? moment(cont[item]).format() : null;
    });
    let totalCont = data.filter((item) => item.typeCont == cont.typeCont).length;
    let dataRes = [];
    for (let i = 0; i < cont.contSum; i++) {
      dataRes.push(
        formatContData(
          {
            ...cont,
            orderNumber: totalCont + i + 1,
            quantityCont: totalCont + cont.contSum,
          },
          orderDetail,
          formData
        )
      );
    }
    await addCont(dataRes, orderDetail, reload, formData);
    setIsLoading(false);
    setIsVisiblePopup(false);
  };
  const deleteRow = async (record, dataContList) => {
    console.log({ dataContList });
    if (dataContList?.length <= 1) {
      window.TmsNoti?.error('Không được xóa', 'Lý do: Đơn hàng cần có ít nhất 1 số lượng Cont', 5);
      return;
    }
    if (record?.id) {
      try {
        const res = await ApiServices.deleteCont(record.id);
        if (res.data) {
          message.success('Xoá cont thành công!');
          setIsLoading(true);
          reload();
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
  };
  const handleUpdateActualDate = (record) => {
    setShowUpdateActualDate(record);
  };
  const handleCancel = () => {
    setShowUpdateActualDate(null);
  };

  const renderYCGCDate = (value, record) => {
    return (
      <Tooltip title='Chỉnh sửa ngày yêu cầu giao Cont' color='blue'>
        <Button type='link' icon={<FormOutlined />} onClick={() => handleUpdateActualDate(record)}>
          {value}
        </Button>
      </Tooltip>
    );
  };

  const formatData = calculation(data, contTypes);
  //calculationForCreate
  const columns = [
    {
      title: 'STT',
      dataIndex: 'orderNumber',
      align: 'center',
    },
    {
      title: 'Subno',
      dataIndex: 'subNoNumber',
      align: 'center',
    },
    {
      title: 'Loại Cont',
      dataIndex: 'typeCont',
      align: 'center',
    },
    {
      title: 'Số Cont',
      dataIndex: 'contNumber',
      align: 'center',
    },
    {
      title: titleDate,
      dataIndex: 'deliveryContRequestDate',
      align: 'center',
      render: renderYCGCDate,
    },
    {
      title: 'Trọng tải',
      dataIndex: 'cargoWeight',
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (_, record) => {
        if (record?.status === 9) {
          return <b className='green'>Hoàn thành</b>;
        } else {
          return (
            <Space size='middle'>
              <a onClick={() => deleteRow(record, formatData)}>Xoá</a>
            </Space>
          );
        }
      },
    },
  ];

  return (
    <>
      <Affix offsetTop={30} className='space-align-block'>
        <Space align='center'>
          <Button
            type='primary'
            shape='round'
            icon={<PlusOutlined />}
            onClick={() => setIsVisiblePopup(true)}
          >
            Thêm Mới
          </Button>
        </Space>
      </Affix>

      <Table
        size='small'
        columns={columns}
        dataSource={formatData}
        bordered
        loading={isLoading}
        // scroll={{ x: '100%', y: '100%' }}
        pagination={false}
      />
      <Modal
        title='Cập nhật ngày yêu cầu giao Cont:'
        open={!!showUpdateActualDate}
        onCancel={handleCancel}
        footer={null}
      >
        <UpdateActualDate
          dataSelected={showUpdateActualDate}
          handleCancel={handleCancel}
          reload={reload}
        />
      </Modal>
      <Popup
        title='Thêm mới Cont'
        visible={isVisiblePopup}
        closeModel={() => setIsVisiblePopup(false)}
        onSubmit={() => onSubmitForm()}
        width={500}
        body={
          <FormData
            type={type}
            infoData={formData}
            ref={formRef}
            fields={newCont}
            onSubmit={addData}
            onChange={setNewCont}
          />
        }
      />
    </>
  );
};
export { ListContDetailUpdate };
