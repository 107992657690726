import { Button, Input, Popconfirm, Tag } from 'antd';
import Utils from '~commons/utils';
import { LIFT_ON_CONT_STATUSES } from '~commons/constants';
import { ContRevisedContent } from '~components';
import styled from 'styled-components';
import { WBOrderTypeSelect } from '~components/common-select';

// const JobSuccessfulButton = styled(Tag)`
//   cursor: pointer;
// `;
const ButtonStyled = styled(Button)`
  padding: 0;
  height: auto;
`;
export const getColumns = (
  setQuery,
  setDataListFile,
  setDataAssignCar,
  canCelLiftOn,
  showJobSuccessful,
  onCancelTransferJob,
  showContForm,
  showCustomerDetail,
  onSelectChangeTour,
  selected,
  dataSelectedLiftOff
) => [
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Khách hàng</div>
        <Input
          style={{
            fontSize: '11px',
          }}
          placeholder=''
          allowClear
          onChange={(event) => setQuery({ customerName: event.target.value })}
        />
      </span>
    ),
    width: '20%',
    dataIndex: 'customerName',
    align: 'center',
    render: (value, record) => (
      <>
        <ContRevisedContent data={record} />
        {Utils.formatCustomerLiftOn(value, record, setDataListFile, showCustomerDetail)}
      </>
    ),
  },
  {
    title: 'Ghi chú',
    width: '15%',
    dataIndex: 'noteUp',
    render: (value) => <div className='red'>{value}</div>,
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>SubNo</div>
        <Input
          style={{
            fontSize: '11px',
          }}
          placeholder=''
          allowClear
          onChange={(event) => setQuery({ allDate: event.target.value })}
        />
      </span>
    ),
    width: '30%',
    dataIndex: 'subnoNumber',
    render: (value, record) => {
      return <>{Utils.formatSubNoForLiftOn(value, record, showContForm)}</>;
    },
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Lấy/Hạ</div>
        <Input
          style={{
            fontSize: '11px',
          }}
          placeholder=''
          allowClear
          onChange={(e) => setQuery({ allLocation: e.target.value })}
        />
      </span>
    ),
    width: '26%',
    dataIndex: 'liftOn',
    render: (value, record) => <>{Utils.renderTaskLocations(value, record)}</>,
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Mooc</div>
        <Input
          style={{
            fontSize: '11px',
          }}
          placeholder=''
          allowClear={true}
          onChange={(e) => setQuery({ allCar: e.target.value })}
        />
      </span>
    ),
    width: '16%',
    dataIndex: 'type',
    align: 'center',
    render: (_, record) => {
      // const showTextTour = record?.childJobName?.[0] || record?.parentJobName?.[0] ? false : true;
      const showTextTour = true;
      const isAssigned = record?.assignId || record?.childJobName?.[0];
      return (
        <div>
          {record?.childJobName?.[0] && (
            <>
              <b
                // color='#2db7f5'
                style={{
                  marginTop: '4px',
                  cursor: 'pointer',
                  color: 'blue',
                }}
                onClick={() => showJobSuccessful(record)}
              >
                {record?.childJobName?.[0]}
              </b>
              {record?.status === 9 && record?.childJobName?.length ? (
                <></>
              ) : (
                <Popconfirm
                  title='Xác nhận Hủy Chuyển Đối tác?'
                  align='left'
                  showCancel={false}
                  okText='Ok'
                  onConfirm={() => onCancelTransferJob(record)}
                  cancelButtonProps={{ style: { display: 'none' } }}
                >
                  <ButtonStyled
                    type='link'
                    style={{
                      fontSize: '11px',
                      paddingTop: '8px',
                      paddingBottom: '10px',
                      textTransform: 'none',
                    }}
                  >
                    (Hủy Chuyển)
                  </ButtonStyled>
                </Popconfirm>
              )}
            </>
          )}
          {record?.parentJobName?.[0] && (
            <>
              Nhận từ:
              <Tag>{record.parentJobName[0]}</Tag>
            </>
          )}
          <div className='red'>
            {Utils.renderDateRequestForLiftOn(
              record,
              setDataAssignCar,
              showTextTour,
              onSelectChangeTour,
              isAssigned,
              selected,
              dataSelectedLiftOff
            )}
          </div>
        </div>
        // {/* {record.trace && <div className='green'>{record.trace}</div>} */}
      );
    },
  },
  {
    title: (
      <>
        <WBOrderTypeSelect onChange={(type) => setQuery({ type })} />
      </>
    ),
    width: '9%',
    dataIndex: 'action',
    render: (_, record) => (
      <div>
        <div>{Utils.dispalySortType(record?.type)}</div>
        <Popconfirm
          title='Xác nhận?'
          align='left'
          showCancel={false}
          okText='Ok'
          onConfirm={() => canCelLiftOn(record.contId)}
          disabled={record.status !== LIFT_ON_CONT_STATUSES.PLANED}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <Button type='link' disabled={record.status !== LIFT_ON_CONT_STATUSES.PLANED}>
            Hủy
          </Button>
        </Popconfirm>
      </div>
    ),
  },
];
