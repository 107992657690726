import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Select } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';

const { Option } = Select;

const CargoTypeForm = ({ fields, isNew, id, reload, onChange, setLoading }) => {
  const [companies, setCompanies] = useState([]);

  const getDataInit = async () => {
    const [getCompany] = await Promise.all([ApiServices.getCompanies()]);
    setCompanies(getCompany?.data?.data || []);
  };

  useEffect(() => {
    getDataInit();
  }, []);

  const createCargoType = async (data) => {
    try {
      const res = await ApiServices.createCargoType(data);
      if (res.data) {
        message.success('Thêm mới Loại Hàng Hóa thành công');
        return reload();
      } else {
        message.error('Thêm mới Loại Hàng Hóa thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới Loại Hàng Hóa thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const updateCargoType = async (data) => {
    data.id = id;
    try {
      const res = await ApiServices.updateCargoType(id, data);
      if (res.data) {
        message.success('Cập nhật Loại Hàng Hóa thành công');
        return reload();
      } else {
        message.error('Cập nhật Loại Hàng Hóa thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật Loại Hàng Hóa thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createCargoType(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updateCargoType(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      name='global_state'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        style={{ marginTop: '10px' }}
        name='name'
        label='Tên Loại Hàng Hóa'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Tên Loại Hàng Hóa',
          },
        ]}
      >
        <Input placeholder='Tên Loại Hàng Hóa' />
      </Form.Item>
      <Form.Item
        name='companyId'
        label='Công Ty'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn Công Ty',
          },
        ]}
      >
        <Select showSearch placeholder='Lựa Chọn Công Ty'>
          {companies.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { CargoTypeForm };
