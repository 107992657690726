export const KEYS = ['id', 'name', 'billNumber', 'pay'];

export const keyOfForm = [
  'code', // "string",
  'name', // "string",
  'billNumber', // "true",
  'pay', // true,
  'note',
];
export default (data) => {
  return keyOfForm.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });
};
