import React, { useState, useEffect, useCallback } from 'react';
import { Space, Select } from 'antd';
import moment from 'moment';
import { debounce } from 'lodash';
import { Wrapper, TableTitle } from '~commons/styles';
import { TableLayout, RangeDateFilter } from '~components';
import Utils from '~commons/utils';
import ApiServices from '~services';
import { ContFiles } from '~components/cont-files';

const { Option } = Select;
const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().subtract(6, 'days').format('YYYY-MM-DD'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD'),
};

export const TaskJobsReceivedFromPartner = () => {
  const [loading, setLoading] = useState(false);
  const [ordersFromWMS, setOrdersFromWMS] = useState([]);
  const [partners, setPartners] = useState([]);
  const [partnerSelectedId, setPartnerSelectedId] = useState(null);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [totalRecords, setTotalRecords] = useState(0);

  const getOrdersFromWMS = async (partnerId, query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getTaskJobsReceivedFromPartner(partnerId, newQuery)
      .then((res) => {
        if (res?.data?.data) {
          const mapData = res?.data?.data?.map((item, index) => {
            item.key = item?.subnoNumber + item?.name + index;
            return item;
          });
          setOrdersFromWMS(mapData || []);
          setTotalRecords(res?.data?.totalRecords || 0);
        }
      })
      .catch((err) => {
        console.error('Get Task Jobs By Partner Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPartners = async (query = '') => {
    return ApiServices.getPartnerByCompany({ query })
      .then(({ data }) => {
        setPartners(data?.data || []);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getPartners();
  }, []);

  const timeoutGetData = useCallback(
    debounce((partnerId, newFilter) => {
      getOrdersFromWMS(partnerId, newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    if (partnerSelectedId) {
      timeoutGetData(partnerSelectedId, filter);
    }
  }, [partnerSelectedId, filter]);

  const columns = [
    {
      title: 'Subno Number',
      dataIndex: 'subnoNumber',
      width: 180,
      align: 'center',
    },
    {
      title: 'Tên công việc',
      dataIndex: 'name',
      width: 120,
      align: 'center',
    },
    {
      title: 'Loại ĐH',
      dataIndex: 'orderType',
      width: 130,
      align: 'center',
    },
    {
      title: 'Số Cont',
      dataIndex: 'contNumber',
      width: 130,
      align: 'center',
    },
    {
      title: <TableTitle>File Đính kèm</TableTitle>,
      dataIndex: 'contFiles',
      width: 240,
      render: (val) => <ContFiles files={val} />,
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'customerName',
      width: 180,
      align: 'center',
    },
    {
      title: 'Số Seal',
      dataIndex: 'sealNumber',
      width: 120,
      align: 'center',
    },
    {
      title: 'Điểm bắt đầu',
      dataIndex: 'beginLocation',
      width: 180,
      align: 'center',
    },
    {
      title: 'Điểm kết thúc',
      dataIndex: 'endLocation',
      width: 180,
      align: 'center',
    },
    {
      title: 'Ngày điều xe',
      dataIndex: 'assignDate',
      width: 120,
      align: 'center',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'Cắt SI',
      dataIndex: 'bookingSIDateTime',
      width: 100,
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'Cắt Máng',
      dataIndex: 'bookingCutOffDateTime',
      width: 100,
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'HH Lưu Cont',
      dataIndex: 'bill_LastFreeDayDEM',
      width: 120,
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'HH Trả Rỗng',
      dataIndex: 'bill_LastFreeDayDET',
      width: 120,
      render: (value) => Utils.formatDateFn(value),
    },
  ];

  const handleChangeSelectedPartner = async (partnerId) => {
    if (partnerId) {
      setPartnerSelectedId(partnerId);
    } else {
      setPartnerSelectedId(null);
      setOrdersFromWMS([]);
    }
  };

  const headerContent = (
    <div>
      <h3>Danh sách công việc đã nhận từ đối tác: </h3>
      <Space>
        <br />
        <b>Đối tác: </b>
        <Select
          placeholder='Chọn đối tác'
          style={{ width: 300 }}
          allowClear={true}
          onChange={handleChangeSelectedPartner}
        >
          {partners?.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
        <RangeDateFilter query={filter} onChange={setFilter} />
      </Space>
    </div>
  );

  const pagination = {
    total: totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <Wrapper>
      <TableLayout
        columns={columns}
        data={ordersFromWMS}
        rowKey='key'
        bordered
        loading={loading}
        headerContent={headerContent}
        pagination={pagination}
        isDisplayCheckbox={false}
      />
    </Wrapper>
  );
};
