export const KEYS = ['name', 'warningName', 'companyId'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });
  return result;
};
