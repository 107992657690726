import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Space } from 'antd';
const ExcelJS = require('exceljs');
import { PrinterOutlined, FileExcelOutlined } from '@ant-design/icons';
import moment from 'moment';
import ApiServices from '~services';
import ReactToPrint from 'react-to-print';
import { TableContent } from './table-content';
import { debounce } from 'lodash';
import { RangeDateFilter } from '~components';

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};

const AccountantsGoodExpenseCar = () => {
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [goodExpenseList, setGoodExpenseList] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getAccountantsGoodExpenseCar(newQuery)
      .then((res) => {
        if (res?.data?.data) {
          const dataMap = res?.data?.data?.map((item, index) => {
            item.key = index + 1;
            item.signature = '';
            return item;
          });
          setGoodExpenseList(dataMap);
        }
      })
      .catch((err) => {
        console.error('get Accountants Good Expense Car Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const exportExcelFile = async () => {
    if (loading) return;
    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Báo Cáo CP', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ state: 'frozen', ySplit: 3, activeCell: 'A1', showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 20;

    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getCell('B2').value = 'BÁO CÁO CHI PHÍ LÀM HÀNG TÀI XẾ';
    sheetSummary.getRow(3).font = {
      name: 'Arial',
      family: 2,
      size: 10,
      italic: true,
    };
    sheetSummary.getCell('B3').value = `Từ Ngày ${moment(filter?.DateFrom).format(
      'DD/MM/YYYY'
    )} -> ${moment(filter?.DateTo).format('DD/MM/YYYY')}`;

    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getColumn(2).width = 20;
    sheetSummary.getColumn(3).width = 25;
    for (let i = 4; i <= 6; i++) {
      sheetSummary.getColumn(i).numFmt = '#,##0';
      sheetSummary.getColumn(i).width = 20;
    }
    sheetSummary.getColumn(7).width = 20;

    sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };

    const arrDetail = await goodExpenseList?.map((item) => {
      const dataItem = [
        item?.key, // STT
        item?.carCode, // carCode
        item?.driverName, // driverName
        item?.totalWithBill, // totalWithBill
        item?.totalWithoutBill, // totalWithoutBill
        item?.total, // Tong
        item?.signature, // signature
      ];
      return dataItem;
    });

    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'A5',
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Số Xe', totalsRowLabel: '', filterButton: false },
        { name: 'Tên', totalsRowLabel: '', filterButton: false },
        {
          name: 'Có CT',
          totalsRowFunction: 'sum',
          filterButton: false,
        },
        {
          name: 'Không CT',
          totalsRowFunction: 'sum',
          filterButton: false,
        },
        {
          name: 'Thành Tiền',
          totalsRowFunction: 'sum',
          filterButton: false,
        },
        { name: 'Ký Nhận', filterButton: false },
      ],
      rows: arrDetail,
    });

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Bao_Cao_Chi_Phi_Lam_Hang_Tu_${moment(filter?.DateFrom).format(
          'DD-MM-YYYY'
        )}_Den_${moment(filter?.DateTo).format('DD-MM-YYYY')}_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const pageStyle = `
  @page {
     size: A4;
     margin: 5%;
     padding: 0 0 10%;
     @bottom-right {
      content: counter(page);
     }
  }
`;
  return (
    <>
      <Space
        style={{
          padding: '20px',
          marginBottom: '15px',
        }}
      >
        <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
        <ReactToPrint
          trigger={() => (
            <Button type='primary' disabled={!goodExpenseList.length} icon={<PrinterOutlined />}>
              Print
            </Button>
          )}
          content={() => componentRef.current}
          documentTitle={`Bao_Cao_Chi_Phi_Lam_Hang_Tai_Xe_${moment(filter?.DateFrom).format(
            'DD-MM-YYYY'
          )}_Den_${moment(filter?.DateTo).format('DD-MM-YYYY')}_Xuat_Excel_Luc_${moment().format(
            'DD-MM-YY HH:mm'
          )}.xlsx`}
          pageStyle={pageStyle}
        />
        {goodExpenseList?.length > 0 && (
          <Button
            type='primary'
            loading={loading}
            icon={<FileExcelOutlined />}
            style={{ padding: '0px 20px', marginLeft: '10px' }}
            disabled={!goodExpenseList.length}
            onClick={exportExcelFile}
          >
            Xuất Excel
          </Button>
        )}
      </Space>
      <div
        style={{
          border: '1px solid blue',
          borderRadius: '10px',
        }}
      >
        <div ref={componentRef}>
          <div
            style={{
              textAlign: 'center',
              marginTop: '20px',
            }}
          >
            <h1>
              <b>BÁO CÁO CHI PHÍ LÀM HÀNG TÀI XẾ</b>
            </h1>
            <i>{`Từ Ngày ${moment(filter?.DateFrom).format('DD/MM/YYYY')} -> ${moment(
              filter?.DateTo
            ).format('DD/MM/YYYY')}`}</i>
          </div>

          <div
            style={{
              margin: '0px 12px 12px 12px',
              marginTop: '20px',
            }}
          >
            <TableContent loading={loading} data={goodExpenseList} />
          </div>
        </div>
      </div>
    </>
  );
};

export { AccountantsGoodExpenseCar };
