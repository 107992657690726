import React from 'react';
import { Modal, message } from 'antd';
import ApiServices from '~services';
import storage from '~storage';
import FieldData from './field-data';
import formatData from './format-data';
import { ListContDetailUpdate } from './list-cont';

export const UpdateDetailCont = ({ orderId = null, onClose, reload }) => {
  const [orderDetail, setOrderDetail] = React.useState(null);
  const [cont, setCont] = React.useState([]);
  console.log({ cont });
  const [loading, setLoading] = React.useState(false);
  const initValue = FieldData({
    transportType: storage?.staticVariables?.TransportType?.TransportType_FCL,
    contSum: 1,
  });
  const [fields, setFields] = React.useState(initValue);

  const isOpenModal = Boolean(orderId);

  const getOrderById = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const { data } = await ApiServices.getOrderDetailsId(orderId);
      setFields(formatData(data));
      if (data) {
        data.listCont && data.listCont.length && setCont(data.listCont);
        setOrderDetail(data);
      }
    } catch (error) {
      console.log(error);
      message.warn('Đơn Hàng không tồn tại vui lòng thử lại');
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    !orderId && setFields(initValue);
    orderId && getOrderById();
  }, [orderId]);
  const reloadCont = () => {
    getOrderById();
    reload?.();
  };

  return (
    <Modal
      title='Sửa chi tiết Cont'
      open={isOpenModal}
      onCancel={onClose}
      onOk={onClose}
      width={1000}
    >
      <ListContDetailUpdate
        type={orderDetail?.orderType}
        formData={fields}
        orderDetail={orderDetail}
        reload={reloadCont}
        onChange={setCont}
      />
    </Modal>
  );
};
