import React from 'react';
import { Form, Input, Button, DatePicker, message } from 'antd';
import { DATE_FORMAT } from '~commons/constants';
import ApiServices from '~services';
import { UploadFile } from '~components/upload-file';
import moment from 'moment';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};
const tailLayout = {
  wrapperCol: { offset: 10, span: 16 },
};

const FormUpdateCarInspection = ({
  setIsShowFormUpdate,
  fields,
  onChange,
  romoocId,
  getCarInspections,
  isNew,
  dataCarInspection,
  setLoading,
}) => {
  const createCarInspection = async (data) => {
    data.romoocId = romoocId;
    data.inspectionDateNew = moment(data?.inspectionDateNew).format('YYYY-MM-DD');
    data.inspectionExpirationDateNew = moment(data?.inspectionExpirationDateNew).format(
      'YYYY-MM-DD'
    );

    try {
      const res = await ApiServices.createRomoocInspectionFormData(data);
      if (res.data) {
        message.success('Thêm mới kiểm định romooc thành công');
        setIsShowFormUpdate(false);
        return getCarInspections();
      } else {
        message.error('Thêm mới kiểm định romooc thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới kiểm định romooc thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };

  const updateCarInspection = async (data) => {
    const id = dataCarInspection.id;
    data.id = id;
    data.romoocId = romoocId;
    data.inspectionDateNew = moment(data?.inspectionDateNew).format('YYYY-MM-DD');
    data.inspectionExpirationDateNew = moment(data?.inspectionExpirationDateNew).format(
      'YYYY-MM-DD'
    );
    if (data?.files?.length > 0) {
      const checkRemove = data?.files?.filter((item) => item?.id)?.map((el) => el.id);
      if (checkRemove?.length > 0) {
        const mapDataAfterMoved = dataCarInspection?.orderFiles?.filter((item) => {
          const hasMove = checkRemove?.includes(item?.id);
          if (hasMove) {
            return item;
          }
        });
        data.orderFiles = mapDataAfterMoved;
      } else {
        data.orderFiles = null;
      }
    } else {
      data.orderFiles = null;
    }

    try {
      const res = await ApiServices.updateRomoocInspectionFormData(id, data);

      if (res.data) {
        message.success('Cập nhật kiểm định romooc thành công');
        setIsShowFormUpdate(false);
        return getCarInspections();
      } else {
        message.error('Cập nhật kiểm định romooc thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật kiểm định romooc thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };

  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createCarInspection(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updateCarInspection(value);
    setLoading(false);
  };

  return (
    <Form
      {...layout}
      name='control-hooks'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        label='Số Kiểm Định Mới'
        name='inspectionNumberNew'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập số kiểm định',
          },
        ]}
      >
        <Input placeholder='Số Kiểm Định' />
      </Form.Item>
      <Form.Item
        label='Ngày Kiểm Định Mới'
        name='inspectionDateNew'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập ngày kiểm định',
          },
        ]}
      >
        <DatePicker
          placeholder='Ngày Kiểm Định Mới'
          style={{ width: '100%' }}
          format={DATE_FORMAT}
        />
      </Form.Item>
      <Form.Item
        label='Ngày HH Kiểm Định Mới'
        name='inspectionExpirationDateNew'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập ngày HH kiểm định',
          },
        ]}
      >
        <DatePicker
          placeholder='Ngày HH Kiểm Định'
          style={{ width: '100%' }}
          format={DATE_FORMAT}
        />
      </Form.Item>
      <Form.Item
        label='Chi Phí KĐ Mới'
        name='inspectionCostNew'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập chi phí',
          },
        ]}
      >
        <Input placeholder='Chi Phí Kiểm Định' type='number' />
      </Form.Item>
      <Form.Item name='files' label='File Đính Kèm'>
        <UploadFile showUpdated />
      </Form.Item>
      <Form.Item label='Ghi Chú' name='note'>
        <Input.TextArea placeholder='Ghi Chú' />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type='danger' onClick={() => setIsShowFormUpdate(false)}>
          Hủy
        </Button>
        <Button type='primary' htmlType='submit' className='mg-l-5'>
          {isNew ? 'Thêm Mới' : 'Cập Nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { FormUpdateCarInspection };
