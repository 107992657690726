import React from 'react';
import { Row, Col } from 'antd';
// import Utils from '~commons/utils';
import { DisplayInfo } from '~components/display-info';

const HeaderFormCreate = ({ dataCar, dataInfo }) => {
  return (
    <Row gutter={16}>
      <Col span={6}>
        <DisplayInfo title='Số xe' value={dataCar?.code} />
      </Col>
      <Col span={6}>
        <DisplayInfo title='Tài xế' value={dataInfo?.driverName} />
      </Col>
      <Col span={6}>
        <DisplayInfo title='Tên công việc' value={dataInfo?.name} />
      </Col>
      <Col span={6}>
        <DisplayInfo title='Khách hàng' value={dataInfo?.customerName} />
      </Col>
      <Col span={6}>
        <DisplayInfo title='Số Cont' value={dataInfo?.contNumber} />
      </Col>
      <Col span={6}>
        <DisplayInfo title='Số SubNo' value={dataInfo?.subnoNumber} />
      </Col>
      <Col span={6}>
        <DisplayInfo title='Loại ĐH' value={dataInfo?.orderType} />
      </Col>
      {/* <Col span={6}>
        <DisplayInfo
          title='Ngày nhận data'
          value={dataInfo?.assignDate && Utils.formatDate(dataInfo?.assignDate)}
        />
      </Col> */}
    </Row>
  );
};

export { HeaderFormCreate };
