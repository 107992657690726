import React, { useEffect, useRef, useState } from 'react';
import { Table, Button, Space, Affix } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FormData } from './form-data';
import { calculation, calculationForCreate } from './calculate-data';
import FieldData, { KEYS_OF_DATE, KEYS_OF_DATE_TIME } from './field-data';
import { Popup } from '~components';
import moment from 'moment';
import ApiServices from '~services';

const DEFAULT_VALUE = FieldData({});
const ListContDetail = ({ onChange, formData, type }) => {
  const [newCont, setNewCont] = useState(DEFAULT_VALUE);
  const formRef = useRef();
  const [data, setData] = useState([]);
  const [top] = useState(30);
  const [isVisiblePopup, setIsVisiblePopup] = useState(false); //fullAddress
  const [isLoading, setIsLoading] = useState(false); //fullAddress
  const [contTypes, setContTypes] = React.useState([]);
  const getContTypes = async (Query = null) => {
    try {
      const { data } = await ApiServices.getContTypes({ Query });
      const types = {};
      data.data.forEach((item) => {
        types[item.id] = item.name;
      });

      setContTypes(types);
    } catch (err) {
      console.error('ERR - Get Cont Types', err);
    }
  };

  useEffect(() => {
    getContTypes();
  }, []);

  const onSubmitForm = () => {
    formRef.current.submitForm();
  };
  const submitData = (data) => {
    onChange && onChange(calculationForCreate(data));
  };
  const addData = (cont) => {
    setIsLoading(true);

    KEYS_OF_DATE.forEach((item) => {
      cont[item] = cont[item] ? moment(cont[item]).format('YYYY-MM-DD') : null;
    });
    KEYS_OF_DATE_TIME.forEach((item) => {
      cont[item] = cont[item] ? moment(cont[item]).format() : null;
    });
    const tmpData = [...data, cont].map((item, index) => {
      return {
        ...item,
        key: index,
      };
    });
    setData(tmpData);

    submitData(tmpData);
    setIsLoading(false);
    setIsVisiblePopup(false);
  };

  const deleteRow = (key) => {
    const dataSource = data.filter((item) => item.key !== key);
    setData(dataSource);
    submitData(dataSource);
  };

  const formatData = calculation(data, contTypes);
  //calculationForCreate
  const columns = [
    {
      title: 'STT',
      dataIndex: 'contOder',
    },
    {
      title: 'Số lượng cont',
      dataIndex: 'quantityCont',
    },
    {
      title: 'Loại Cont',
      dataIndex: 'typeCont',
    },
    {
      title: 'Ngày yêu cầu giao cont',
      dataIndex: 'deliveryContRequestDate',
    },
    {
      title: 'Trọng tải',
      dataIndex: 'cargoWeight',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <a onClick={() => deleteRow(record.key)}>Xoá</a>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Affix offsetTop={top} className='space-align-block'>
        <Space align='center'>
          <Button
            type='primary'
            shape='round'
            icon={<PlusOutlined />}
            onClick={() => setIsVisiblePopup(true)}
          >
            Thêm Mới
          </Button>
        </Space>
      </Affix>

      <Table
        columns={columns}
        dataSource={formatData}
        bordered
        loading={isLoading}
        scroll={{ x: '100%', y: '100%' }}
        pagination={false}
      />

      <Popup
        title='Thêm mới Cont'
        visible={isVisiblePopup}
        closeModel={() => setIsVisiblePopup(false)}
        onSubmit={() => onSubmitForm()}
        body={
          <FormData
            type={type}
            infoData={formData}
            ref={formRef}
            fields={newCont}
            onSubmit={addData}
            onChange={setNewCont}
          />
        }
      />
    </>
  );
};
export { ListContDetail };
