import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
const ExcelJS = require('exceljs');
import { debounce } from 'lodash';
import { Input, Space, message, DatePicker, Modal } from 'antd';
import { TableLayout } from '~components';
import { RangeDateFilter, UploadExcelButton, BtnConfirm } from '~components';
import ApiServices from '~services';
import { DATE_FORMAT } from '~commons/constants';
import { TableTitle, Wrapper } from '~commons/styles';
import Utils from '~commons/utils';
import { InfoDetails } from './info-details';
import { PrintTicket } from './print-ticket';

const EXCEL_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/Phieu_Nhap_Kho.xlsx';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().startOf('date').format('YYYY-MM-DD'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD'),
};

export const GoodsMaterialNotesInbound = ({ headerLength = '220px' }) => {
  const [loading, setLoading] = useState(false);
  const [dataGoodMaterialNoteList, setDataGoodMaterialNoteList] = useState([]);
  const [selected, setSelected] = useState();
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [tradeDate, setTradeDate] = useState(null);
  const [showDataDetails, setShowDataDetails] = useState(null);
  const [dataSelected, setDataSelected] = useState(null);
  const [dataPrintDetail, setDataPrintDetail] = useState(null);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getGoodsMaterialNotesInbound(newQuery)
      .then((res) => {
        if (res?.data) {
          setDataGoodMaterialNoteList(res?.data);
        }
      })
      .catch((err) => {
        console.error('Get Good material notes inbound list Error: ', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const handleDeleteItem = async (record) => {
    setLoading(true);
    return ApiServices.deleteGoodsMaterialNotesTicket(record.id)
      .then((res) => {
        if (res?.data) {
          message.success('Xoá thành công');
          timeoutGetData(filter);
        }
      })
      .catch((err) => {
        message.error('Xoá thất bại, vui lòng thử lại sau!');
        console.log('Delete goods material ticket failer: ', err);
      })
      .finally(() => setLoading(false));
  };

  const onUploadExcel = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importGoodsMaterialNotesInbound(file)
      .then((res) => {
        if (res?.data) {
          message.success(res?.data?.message);
          timeoutGetData(filter);
        }
      })
      .catch((err) => {
        message.error('Thao tác thất bại, Vui lòng thử lại sau!: ', err);
      })
      .finally(() => setLoading(false));
  };

  const renderStatus = (value) => {
    if (value === 0) {
      return 'Nhập';
    }
  };

  const handleChangeDate = (date) => {
    setTradeDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'tradingDate');
  };

  const onShowDetails = async (dataRow) => {
    setLoading(true);
    return ApiServices.getDetailsGoodsMaterialNotes(dataRow.id)
      .then((res) => {
        if (res?.data) {
          setShowDataDetails(res.data);
          setDataSelected(dataRow);
        }
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => setLoading(false));
  };
  const handleCancel = () => {
    setShowDataDetails(null);
    setDataSelected(null);
  };
  const onShowDetailPrintTicket = async (dataRow) => {
    setLoading(true);
    return ApiServices.getDetailsGoodsMaterialNotes(dataRow.id)
      .then((res) => {
        if (res?.data) {
          setDataPrintDetail(res.data);
          setDataSelected(dataRow);
        }
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => setLoading(false));
  };
  const handleCancelPrintTicket = () => {
    setDataPrintDetail(null);
    setDataSelected(null);
  };

  // Export excel
  const handleExportExcel = async (dataExport, dataRow) => {
    if (loading) return;

    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Phiếu Nhập Kho', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 30;
    sheetSummary.properties.defaultColWidth = 15;
    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getColumn(11).width = 25;
    sheetSummary.getColumn(13).width = 30;
    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getCell('F2').value = 'PHIẾU NHẬP KHO';
    sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };

    // summary table 1
    sheetSummary.getCell('C4').value = 'Số Phiếu Nhập:';
    sheetSummary.getCell('C5').value = 'Số Hóa Đơn:';
    sheetSummary.getCell('C6').value = 'Loại Hình:';
    sheetSummary.getCell('D4').value = dataRow?.code || '';
    sheetSummary.getCell('D5').value = dataRow?.billId || '';
    sheetSummary.getCell('D6').value = 'Nhập';
    //summary table 2
    sheetSummary.getCell('F4').value = 'Ngày Giao Dịch:';
    sheetSummary.getCell('F5').value = 'Trị Giá:';
    sheetSummary.getCell('F6').value = 'Ghi Chú:';
    sheetSummary.getCell('G4').value = `${
      dataRow?.tradingDate && moment(dataRow?.tradingDate).format(DATE_FORMAT)
    }`;
    sheetSummary.getCell('G5').value = dataRow?.totalPrice;
    sheetSummary.getCell('G6').value = `${dataRow?.note}`;

    const columnsBorderList = [
      'C4',
      'C5',
      'C6',
      'D4',
      'D5',
      'D6',
      'F4',
      'F5',
      'F6',
      'G4',
      'G5',
      'G6',
    ];
    columnsBorderList.forEach((item) => {
      sheetSummary.getCell(`${item}`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
    });

    // Add table
    const arrDetail = await dataExport?.materialNote?.details?.map((item, indexDetail) => {
      const dataItem = [
        indexDetail + 1, // STT
        item?.materialCode, // Ma VT
        item?.materialName, // Tên VT
        item?.supplierMaterialName, // NCC
        item?.materialUnit, // Đơn Vi Tính
        item?.amount, // So Lượng Nhập
        item?.price, // Đơn Giá
        item?.vat, // Vat
        item?.totalPrice, // Thanh Tiền
        item?.note, // Ghi Chu
      ];
      return dataItem;
    });

    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B8',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Mã Vật Tư', totalsRowLabel: '', filterButton: false },
        { name: 'Tên Vật Tư', totalsRowLabel: '', filterButton: false },
        {
          name: 'Nhà Cung Cấp',
          filterButton: false,
        },
        {
          name: 'Đơn Vị Tính',
          filterButton: false,
        },
        {
          name: 'Số Lượng Nhập',
          filterButton: false,
        },
        {
          name: 'Đơn Giá',
          filterButton: false,
        },
        {
          name: 'VAT',
          filterButton: false,
        },
        {
          name: 'Thành Tiền',
          filterButton: false,
        },
        {
          name: 'Ghi Chú',
          filterButton: false,
        },
      ],
      rows: arrDetail,
    });
    // ========== STYLES =====================
    sheetSummary.getRow(8).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getColumn(3).alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };
    sheetSummary.getColumn(4).alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    sheetSummary.getColumn(5).alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    sheetSummary.getCell('G5').numFmt = '#,##0';
    sheetSummary.getColumn(8).numFmt = '#,##0';
    sheetSummary.getColumn(10).numFmt = '#,##0';

    sheetSummary.getColumn(2).width = 5;
    sheetSummary.getColumn(3).width = 20;
    sheetSummary.getColumn(4).width = 25;
    sheetSummary.getColumn(5).width = 30;
    sheetSummary.getColumn(10).width = 20;

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Phieu_Nhap_Kho_${
          dataExport?.materialNote?.noteNumber
        }_Xuat_Excel_Luc_${moment().format('DD-MM-YY HH:mm')}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const handleGetDataToExportExcel = async (dataRow) => {
    setLoading(true);
    return ApiServices.getDetailsGoodsMaterialNotes(dataRow?.id)
      .then((res) => {
        if (res?.data) {
          handleExportExcel(res.data, dataRow);
        }
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Số phiếu
          <Input name='code' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'code',
      align: 'center',
      width: 130,
    },
    {
      title: (
        <TableTitle>
          Số Hoá đơn
          <Input name='billId' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'billId',
      align: 'center',
      width: 100,
    },
    {
      title: (
        <TableTitle>
          Ngày giao dịch
          <DatePicker
            value={tradeDate}
            onChange={handleChangeDate}
            placeholder='Ngày giao dịch'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'tradingDate',
      align: 'center',
      width: 100,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Loại hình
          <Input name='status' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'status',
      align: 'center',
      width: 80,
      render: renderStatus,
    },
    {
      title: (
        <TableTitle>
          Trị giá
          <Input name='totalPrice' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'totalPrice',
      align: 'center',
      width: 100,
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: (
        <TableTitle>
          Tên NCC
          <Input name='supplierMaterialName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'supplierMaterialName',
      align: 'center',
      width: 250,
    },
    {
      title: (
        <TableTitle>
          Ghi chú
          <Input name='note' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'note',
      align: 'center',
      width: 100,
    },
    {
      title: '#',
      fixed: 'right',
      width: 50,
      align: 'center',
      render: (_, record) => <a onClick={() => onShowDetails(record)}>Chi tiết</a>,
    },
    {
      title: '#',
      fixed: 'right',
      width: 50,
      align: 'center',
      render: (_, record) => (
        <a
          style={{
            color: 'red',
          }}
          onClick={() => onShowDetailPrintTicket(record)}
        >
          In phiếu
        </a>
      ),
    },
    {
      title: '#',
      fixed: 'right',
      width: 50,
      align: 'center',
      render: (_, record) => (
        <a
          style={{
            color: 'green',
          }}
          onClick={() => handleGetDataToExportExcel(record)}
        >
          Xuất Excel
        </a>
      ),
    },
    {
      title: '#',
      fixed: 'right',
      width: 70,
      render: (record) => <BtnConfirm onConfirm={() => handleDeleteItem(record)}>Xóa</BtnConfirm>,
    },
  ];
  const onCloseEditTab = () => setSelected();

  const pagination = {
    total: dataGoodMaterialNoteList?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  const headerContent = (
    <Space>
      <RangeDateFilter query={filter} onChange={setFilter} />
      <UploadExcelButton
        loading={loading}
        onChange={onUploadExcel}
        templateUrl={EXCEL_TEMPLATE_URL}
        importLabel='Import Excel'
      />
    </Space>
  );

  return (
    <Wrapper>
      <h3>Import Phiếu nhập kho</h3>
      <div className='table-container-custom'>
        <TableLayout
          rowKey='id'
          columns={columns}
          data={dataGoodMaterialNoteList?.data}
          loading={loading}
          titleTab={selected?.jobName}
          headerContent={headerContent}
          headerLength={headerLength}
          closeDrawer={onCloseEditTab}
          selected={selected}
          pagination={pagination}
          isDisplayCheckbox={false}
          isScrollX={false}
          customScrollSize='30px'
        />
      </div>
      <Modal
        title='Thông tin chi tiết:'
        open={!!showDataDetails}
        onCancel={handleCancel}
        footer={null}
        style={{
          top: 20,
        }}
        width='80%'
      >
        <InfoDetails dataDetail={showDataDetails || {}} dataSelected={dataSelected} />
      </Modal>
      <Modal
        title='Thông tin phiếu nhập kho:'
        open={!!dataPrintDetail}
        onCancel={handleCancelPrintTicket}
        footer={null}
        style={{
          top: 20,
        }}
        width='80%'
      >
        <PrintTicket dataDetail={dataPrintDetail || {}} dataSelected={dataSelected} />
      </Modal>
    </Wrapper>
  );
};
