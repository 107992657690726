import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Divider } from 'antd';
import { TableLayout } from '~components';
import ApiServices from '~services';
import Utils from '~commons/utils';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ReportVehicleReplacement = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];

  let query = useQuery();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [warningName, setWarningName] = useState('');
  const [categoryIdData, setCategoryIdData] = useState('');

  const getRPVehicleReplacement = async () => {
    const arrIdVendor = query.get('vendorId');
    const categoryId = query.get('categoryId');
    setCategoryIdData(categoryId);

    if (arrIdVendor) {
      try {
        setLoading(true);
        let { data } = await ApiServices.getRPVehicleReplacement(arrIdVendor.split(','));
        setLoading(false);
        if (data) {
          const ind = data.findIndex((item) => item.categoryId === categoryId);
          setWarningName(data[ind].warningName || '');
          return getListByArrayIdOfCar(data[ind].arrCars);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getListByArrayIdOfCar = async (arrId) => {
    try {
      setLoading(true);
      let { data } = await ApiServices.getListByArrayIdOfCar(arrId);
      setLoading(false);
      setData(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getInit = async () => {
      await getRPVehicleReplacement();
    };

    getInit();
  }, []);

  const showReplacement = (value, key) => {
    if (!Array.isArray(value)) return '';

    const ind = value.findIndex((item) => item.categoryId === categoryIdData);
    switch (key) {
      case 'name':
        return <>{value[ind].name}</>;
      case 'note':
        return <>{value[ind].note}</>;
      case 'distanceTotal':
        return <>{value[ind].distanceTotal}</>;
      case 'replacementKm':
        return <>{value[ind].replacementKm}</>;
      case 'replacementDate':
        return <>{Utils.formatDateFn(value[ind].replacementDate)}</>;
      default:
        break;
    }
  };

  const columns = [
    {
      title: 'Biển Số',
      dataIndex: 'code',
      width: 100,
    },
    {
      title: 'Hạng Mục',
      dataIndex: 'replacement',
      width: 150,
      render: (value) => showReplacement(value, 'name'),
    },
    {
      title: 'Ngày Thay',
      dataIndex: 'replacement',
      width: 150,
      render: (value) => showReplacement(value, 'replacementDate'),
    },
    {
      title: 'Số Km Thay',
      dataIndex: 'replacement',
      width: 100,
      render: (value) => showReplacement(value, 'replacementKm'),
    },
    {
      title: 'Tổng Khoảng Cách',
      dataIndex: 'replacement',
      width: 100,
      render: (value) => showReplacement(value, 'distanceTotal'),
    },
    {
      title: 'Ghi Chú',
      dataIndex: 'replacement',
      width: 150,
      render: (value) => showReplacement(value, 'note'),
    },
  ];

  return (
    <div style={{ margin: '2rem' }}>
      <Divider>
        <b>CẢNH BÁO ĐẾN HẠN THAY PHỤ TÙNG</b>(<span className='red'>{warningName}</span>)
      </Divider>
      <TableLayout
        rowKey='id'
        width={500}
        columns={columns}
        data={data}
        loading={loading}
        titleTab={''}
        tabPanels={defaultTabPanels}
        // pagination={pagination}
      />
    </div>
  );
};

export { ReportVehicleReplacement };
