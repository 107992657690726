import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Space } from 'antd';
const ExcelJS = require('exceljs');
import { PrinterOutlined, FileExcelOutlined } from '@ant-design/icons';
import moment from 'moment';
import ApiServices from '~services';
import ReactToPrint from 'react-to-print';
import { TableContent } from './table-content';
import { debounce } from 'lodash';
import { RangeDateFilter } from '~components';
import Utils from '~commons/utils';
import logo from '~assets/images/logo.png';

const toDataURL = (url) => {
  const promise = new Promise((resolve) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });

  return promise;
};

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('month').format(),
};

const AccountantsGoodExpenseDriver = () => {
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [ticketList, setTicketList] = useState([]);
  const [sumTotal, setSumTotal] = useState(0);
  const [company, setCompany] = useState(null);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getAccountantsGoodExpenseDriver(newQuery)
      .then((res) => {
        if (res?.data?.data?.company) {
          setCompany(res?.data?.data?.company);
        }
        const dataTicketJobs = res?.data?.data?.ticketJobs || [];
        const dataTicketUsers = res?.data?.data?.ticketUsers || [];
        const dataAll = dataTicketJobs.concat(dataTicketUsers);

        if (dataAll) {
          const dataSort = dataAll?.sort((a, b) => {
            let ca = new Date(a.dissolveDate);
            let cb = new Date(b.dissolveDate);
            let da = parseInt(a.folder);
            let db = parseInt(b.folder);

            if (ca < cb) {
              return -1;
            }
            if (ca > cb) {
              return 1;
            }
            if (da < db) {
              return -1;
            }
            if (da > db) {
              return 1;
            }
            return 0;
          });

          let calcTotal = 0;
          const dataEnd = dataSort?.map((item, index) => {
            calcTotal = calcTotal + item?.total;
            item.key = index;
            return item;
          });
          setTicketList(dataEnd);
          setSumTotal(calcTotal);
        }
      })
      .catch((err) => {
        console.error('get Accountants Good Expense Driver Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const exportExcelFile = async (dataExport) => {
    if (loading) return;
    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Chi Tiết', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ state: 'frozen', ySplit: 5, activeCell: 'A1', showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 20;

    // Add Image
    // const pathLogo = company?.logo?.split('~')[1];
    // const urlLogo = `${process.env.REACT_APP_API_URL}${pathLogo}`;
    // const resultTest = await toDataURL(urlLogo);
    // const splittedTest = urlLogo.split('.');
    // const extNameTest = splittedTest[splittedTest.length - 1];

    const resultTest = await toDataURL(logo);
    const extNameTest = 'png';
    // --------- Summary Sheet --------------------
    const imageId2Summary = workbook.addImage({
      base64: resultTest.base64Url,
      extension: extNameTest,
    });

    // sheet.mergeCells('A1:B5');
    sheetSummary.addImage(imageId2Summary, 'A1:B5');
    sheetSummary.getRow(1).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    sheetSummary.getCell('C1').value = company?.name || '';
    sheetSummary.getCell('C2').value = company?.address || '';
    sheetSummary.getCell('C3').value = company?.phone || '';

    sheetSummary.getRow(7).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getCell('B7').value = 'BẢNG TỔNG HỢP CHI PHÍ LÀM HÀNG VẬN TẢI';

    sheetSummary.getCell('C8').value = `Từ Ngày ${moment(filter?.DateFrom).format(
      'DD/MM/YYYY'
    )} -> ${moment(filter?.DateTo).format('DD/MM/YYYY')}`;

    sheetSummary.getRow(9).font = {
      name: 'Arial',
      family: 2,
      size: 15,
      bold: true,
      color: { argb: 'DA3F3F' },
    };
    sheetSummary.getCell('E9').numFmt = '#,##0';
    sheetSummary.getRow(9).alignment = { vertical: 'right', horizontal: 'right' };
    sheetSummary.getCell('D9').value = 'Tổng:';
    sheetSummary.getCell('E9').value = sumTotal;

    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getColumn(2).width = 15;
    sheetSummary.getColumn(3).width = 15;
    sheetSummary.getColumn(4).width = 25;
    sheetSummary.getColumn(5).width = 20;
    sheetSummary.getColumn(5).numFmt = '#,##0';

    sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };

    const arrDetail = await dataExport?.map((item) => {
      const dataItem = [
        item?.dissolveDate ? Utils.formatDate(item?.dissolveDate) : '', // Ngày
        item?.folder, // STT Folder
        item?.driverMainName, // Họ Tên
        item?.total, // Số Tiền
      ];
      return dataItem;
    });
    sheetSummary.getRow(10).alignment = { vertical: 'center', horizontal: 'center' };

    // Add Table
    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B10',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'Ngày', totalsRowLabel: '', filterButton: false },
        { name: 'STT Folder', filterButton: false },
        {
          name: 'Họ Tên',
          filterButton: false,
        },
        {
          name: 'Số Tiền',
          filterButton: false,
        },
      ],
      rows: arrDetail,
    });

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Bao_Cao_Chi_Phi_Lam_Hang_Van_Tai_Tu_${moment(filter?.DateFrom).format(
          'DD-MM-YYYY'
        )}_Den_${moment(filter?.DateTo).format('DD-MM-YYYY')}_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const pageStyle = `
  @page {
     size: A4;
     margin: 5%;
     padding: 0 0 10%;
     @bottom-right {
      content: counter(page);
     }
  }
`;
  return (
    <>
      <h1>5026 - BÁO CÁO GIẢI TRỪ LÀM HÀNG VẬN TẢI</h1>
      <div>
        <Space
          style={{
            marginBottom: '15px',
          }}
        >
          <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
          <ReactToPrint
            trigger={() => (
              <Button type='primary' disabled={!ticketList.length} icon={<PrinterOutlined />}>
                Print
              </Button>
            )}
            content={() => componentRef.current}
            documentTitle={`Bao_Cao_Chi_Phi_Lam_Hang_Van_Tai_${moment(filter?.DateFrom).format(
              'DD-MM-YYYY'
            )}_Den_${moment(filter?.DateTo).format('DD-MM-YYYY')}_Xuat_Excel_Luc_${moment().format(
              'DD-MM-YY HH:mm'
            )}.xlsx`}
            pageStyle={pageStyle}
          />
          <Button
            type='primary'
            loading={loading}
            icon={<FileExcelOutlined />}
            style={{ padding: '0px 20px', marginLeft: '10px' }}
            disabled={!ticketList.length}
            onClick={() => exportExcelFile(ticketList)}
          >
            Xuất Excel
          </Button>
        </Space>
      </div>
      <div
        style={{
          border: '1px solid blue',
          borderRadius: '10px',
          marginBottom: '20px',
        }}
      >
        <div ref={componentRef}>
          <div
            style={{
              textAlign: 'center',
              marginTop: '10px',
            }}
          >
            <h1>
              <b>BẢNG TỔNG HỢP CHI PHÍ LÀM HÀNG VẬN TẢI</b>
            </h1>
            <i>{`Từ Ngày ${moment(filter?.DateFrom).format('DD/MM/YYYY')} -> ${moment(
              filter?.DateTo
            ).format('DD/MM/YYYY')}`}</i>
          </div>

          <div
            style={{
              margin: '0px 12px 12px 12px',
              marginTop: '20px',
            }}
          >
            <TableContent loading={loading} data={ticketList} />
          </div>
        </div>
      </div>
    </>
  );
};

export { AccountantsGoodExpenseDriver };
