import React from 'react';
import storage from '~storage';
import { Select } from 'antd';
const { Option } = Select;

export const OrderTypeSelectReport = ({ selected, onChange }) => {
  const orderType = storage?.staticVariables?.OrderType || {};
  return (
    <Select
      onChange={onChange}
      defaultActiveFirstOption={false}
      value={selected}
      style={{ width: 100 }}
      placeholder='Loại đơn hàng'
      size='midle'
    >
      {Object.values(orderType).map((value) => {
        return (
          <Option key={value} value={value}>
            {value}
          </Option>
        );
      })}
    </Select>
  );
};
