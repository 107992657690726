import { useEffect, forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Input, message, Button } from 'antd';
import ApiServices from '~services';
import { formatFields } from './fields-data';

const defaultFieldData = formatFields({});

export const FormInput = forwardRef(({ dataInput, reload, onClose }, ref) => {
  const [fields, setFields] = useState(defaultFieldData);
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    submitForm() {
      form.submit();
    },
  }));

  useEffect(() => {
    form.resetFields();
    if (!dataInput) {
      return setFields(defaultFieldData);
    }
    setFields(formatFields(dataInput));
  }, [dataInput?.id]);

  const onSubmitForm = (value) => {
    if (!dataInput?.id) {
      return ApiServices.createFunction(value)
        .then(() => {
          reload?.();
          onClose?.();
          message.success('Thao tác thành công!!!');
        })
        .catch((err) => {
          console.error('Create Menu', err);
          message.error('Thao tác thất bại');
        });
    }
    return ApiServices.updateFunction(dataInput.id, { ...value, id: dataInput.id })
      .then(() => {
        reload?.();
        onClose?.();
        message.success('Thao tác thành công!!!');
      })
      .catch((err) => {
        console.error('update Menu', err);
        message.error('Thao tác thất bại');
      });
  };
  const onFieldsChanged = (_, allFields) => {
    setFields(allFields);
  };

  return (
    <>
      <Form
        labelCol={{
          span: 6,
        }}
        // labelAlign='left'
        form={form}
        layout='horizontal'
        name='global_state'
        className='form-job-handle'
        onFinish={onSubmitForm}
        fields={fields}
        onFieldsChange={onFieldsChanged}
      >
        <Form.Item name='code' label='Mã chức năng'>
          <Input placeholder='Mã' />
        </Form.Item>
        <Form.Item
          name='controllerName'
          label='Tên'
          rules={[
            {
              required: true,
              message: 'Bạn phải nhập Tên',
            },
          ]}
        >
          <Input placeholder='Tên' />
        </Form.Item>
        <Form.Item name='urlApi' label='URL'>
          <Input placeholder='URL' />
        </Form.Item>

        <Form.Item name='method' label='Phương thức'>
          <Input placeholder='Phương thức' />
        </Form.Item>
        <Form.Item name='description' label='Mô tả'>
          <Input.TextArea placeholder='Mô tả' />
        </Form.Item>

        <Form.Item label='Thao tác'>
          <Button type='primary' htmlType='submit'>
            Hoàn Thành
          </Button>
        </Form.Item>
      </Form>
    </>
  );
});
