import React from 'react';
import { Table } from 'antd';
import { Wrapper } from '~commons/styles';
import Utils from '~commons/utils';
import { TableTitle } from '~commons/styles';

const ShowHistoryOrder = ({ data }) => {
  const columns = [
    {
      title: <TableTitle>Khách Hàng</TableTitle>,
      dataIndex: 'customerName',
      width: 150,
      align: 'center',
    },
    {
      title: <TableTitle>Book/Bill</TableTitle>,
      dataIndex: 'code',
      width: 150,
      align: 'center',
    },
    {
      title: <TableTitle>Nội dung</TableTitle>,
      dataIndex: 'content',
      width: 530,
      render: (val) => (
        <span
          dangerouslySetInnerHTML={{
            __html: val,
          }}
        />
      ),
    },
    {
      title: <TableTitle>Người sửa</TableTitle>,
      dataIndex: 'createdBy',
      width: 100,
      align: 'center',
    },
    {
      title: <TableTitle>Ngày chỉnh sửa</TableTitle>,
      dataIndex: 'createdOn',
      render: (value) => Utils.formatDateHourFn_2(value),
      width: 90,
      align: 'center',
    },
  ];
  return (
    <Wrapper>
      <Table columns={columns} size='small' dataSource={data || []} rowKey='orderId' bordered />
    </Wrapper>
  );
};

export { ShowHistoryOrder };
