import React from 'react';
import logo from '~assets/images/logo.png';
import { Row, Col, Image } from 'antd';

export const ReportHeader = () => {
  return (
    <Row style={{ padding: '10px' }}>
      <Col>
        <Image src={logo} alt='Logo' width={150} />
      </Col>
      <Col>
        <h4>Công Ty Cổ Phần Vận Tải U&I</h4>
        <div>Số 9 Ngô Gia Tự, Chánh Nghĩa, TX TDM, Bình Dương</div>
        <p>MST: 3700970866</p>
      </Col>
    </Row>
  );
};
