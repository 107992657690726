import { DATE_FORMAT } from '~commons/constants';
import moment from 'moment';

export const KEYS = [
  'code',
  'driverMainCode',
  'driverSubCode',
  'driverMainName',
  'driverSubName',
  'companyId',
  'companyName',
  'labelCar',
  'typeCar',
  'engineType',
  'madeIn',
  'madeYear',
  'frameNumber',
  'machineNumber',
  'inspectionNumber',
  'inspectionCost',
  'currentLocationCode',
  'currentLocationName',
  'buyCost',
  'depreciation',
  'warrantyExpenses',
  'milestoneMaintenanceKm',
  'oilQuota',
  'oilOfCar',
  'cnSignNumber',
  'cnInsurranceNumber',
  'note',
  'vendorId',
  'vendorName',
];
export const KEYS_OF_DATE = [
  'inspectionDate',
  'inspectionExpirationDate',
  'buyDate',
  'useBeginDate',
  'cnSignExpDate',
  'cnInsurranceExpDate',
];
export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });

  KEYS_OF_DATE.forEach((item) => {
    result.push({
      name: [item],
      value: data[item]
        ? data[item] === null
          ? null
          : data[item] !== '0001-01-01T00:00:00+07:00'
          ? moment(data[item], DATE_FORMAT)
          : null
        : null,
    });
  });
  return result;
};
