import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Divider, DatePicker, Row, Col, Modal, Badge, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DATE_FORMAT } from '~commons/constants';
import ApiServices from '~services';
import { HeaderFormCreate } from './header-form-create';
import { FeeItem } from './fee-item';
import { AddNewFee } from './add-new-fee';

const FormUpdate = ({
  dataInfo,
  dataCurrent,
  reload,
  handleCancel,
  onDeleteFee,
  onReloadAfterAddNewFee,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [vat, setVat] = useState(0);
  const [fieldsExpense, setFieldsExpense] = useState([]);
  const [dateDissolveInit, setDateDissolveInit] = useState(null);
  // Add new fee
  const [showAddNewFee, setShowAddNewFee] = useState(false);
  const [expenseListAvailable, setExpenseListAvailable] = useState([]);
  const [expensesIdSelectedToAdd, setExpensesIdSelectedToAdd] = useState([]);
  const [expensesSelectedToAdd, setExpensesSelectedToAdd] = useState([]);

  const initDataExpense = async () => {
    const expenseInit = dataCurrent?.map((item) => {
      if (!vat || vat !== item?.objTicketExpenses?.vat) {
        setVat(item?.objTicketExpenses?.vat);
      }
      return {
        name: item?.name,
        pay: item?.pay,
        billRequired: item?.billRequired,
        expenseId: item?.objTicketExpenses?.expenseId,
        ticketExpenseId: item?.objTicketExpenses?.ticketExpenseId,
        ticketId: item?.objTicketExpenses?.ticketId,
        price: item?.objTicketExpenses?.price,
        vat: item?.objTicketExpenses?.vat,
        total: item?.objTicketExpenses?.total,
        supplierName: item?.objTicketExpenses?.supplierName,
        billNumber: item?.objTicketExpenses?.billNumber,
        billDate: item?.objTicketExpenses?.billDate
          ? moment(item?.objTicketExpenses?.billDate)
          : null,
      };
    });
    setFieldsExpense(expenseInit);
    setDateDissolveInit(null);
  };

  useEffect(() => {
    if (dataCurrent?.length) {
      initDataExpense();
    }
  }, [dataCurrent]);

  const getVatByDate = async (date) => {
    setLoading(true);
    try {
      let { data } = await ApiServices.getVatByDate(date);
      if (data?.data) {
        if (data?.data?.vat !== vat) {
          const expenseInit = fieldsExpense?.map((item) => {
            return {
              ...item,
              name: item?.name,
              pay: item?.pay,
              billRequired: item?.billRequired,
              expenseId: item.expenseId,
              price: 0,
              vat: data?.data?.vat || 0,
              total: 0,
              supplierName: item?.supplierName || '',
              billNumber: item?.billNumber || '',
              billDate: item?.billDate ? moment(item?.billDate) : null,
            };
          });
          setFieldsExpense(expenseInit);
          setVat(data?.data?.vat);
        }
      }
    } catch (error) {
      console.log('Get supplier bill failed:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleChangeDissolveDate = (date) => {
    if (!date) {
      setDateDissolveInit(moment());
      setVat(0);
      return;
    }

    setDateDissolveInit(date);
    const dateFormat = moment(date).format('YYYY-MM-DD');
    return getVatByDate(dateFormat);
  };

  const onFinish = async (values) => {
    if (loading) return;
    let showError = false;

    const dataExpense = fieldsExpense?.map((item) => {
      if (
        !item?.price ||
        !item.vat ||
        !item.total ||
        (item?.billRequired && !item.billNumber) ||
        (item?.billRequired && !item.billDate) ||
        (item?.billRequired && !item.supplierName)
      ) {
        showError = true;
        return;
      }
      return {
        expenseId: item?.expenseId,
        ticketExpenseId: item?.ticketExpenseId,
        ticketId: item?.ticketId,
        price: item?.price,
        vat: item?.vat,
        total: item?.total,
        supplierName: item?.supplierName,
        billNumber: item?.billNumber,
        billDate: item?.billDate && moment(item?.billDate).format('YYYY-MM-DD'),
      };
    });
    if (showError) {
      return window.TmsNoti?.error('Lỗi', 'Vui lòng nhập đầy đủ thông tin');
    }
    const dataSend = {
      expenses: dataExpense,
      dissolveDate: values?.dissolveDate && moment(values?.dissolveDate).format('YYYY-MM-DD'),
      folder: values?.folder || -1,
      note: values?.note,
      jobId: dataInfo?.id,
      driverMainName: dataInfo?.driverMainName,
      assignDate: dataInfo?.assignDate && moment(dataInfo?.assignDate).format('YYYY-MM-DD'),
    };
    setLoading(true);
    return ApiServices.updateTicketJob(dataInfo.id, dataSend)
      .then((res) => {
        if (res?.data) {
          handleCancel();
          reload();
        }
      })
      .catch((err) => {
        console.log('Update Job tickets failed: ', err);
      })
      .finally(() => setLoading(false));
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleAddFee = async (ticketId) => {
    setLoading(true);
    return ApiServices.getDetailTicket(ticketId)
      .then((res) => {
        if (res?.data?.data) {
          const dataIsCreateTicket = res?.data?.data?.expenses
            ?.filter((item) => {
              return !item?.objTicketExpenses;
            })
            ?.map((el) => {
              el.key = el.id;
              el.vat = vat;
              el.billRequired = el?.billRequired ? true : false;
              el.price = 0;
              el.vat = vat ? vat : 0;
              el.total = 0;
              el.billNumber = '';
              el.billDate = null;
              el.supplierName = '';
              return el;
            });
          setExpenseListAvailable(dataIsCreateTicket);
          setShowAddNewFee(true);
        }
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => setLoading(false));
  };
  const handleCloseAddNewFee = () => {
    setShowAddNewFee(false);
    setExpenseListAvailable([]);
    setExpensesIdSelectedToAdd([]);
    setExpensesSelectedToAdd([]);
  };

  const handleSubmitAddNewFee = () => {
    if (loading) return;
    let showError = false;

    const dataExpense = expensesSelectedToAdd?.map((item) => {
      if (
        !item?.price ||
        !item.vat ||
        !item.total ||
        (item?.billRequired && !item.billNumber) ||
        (item?.billRequired && !item.billDate) ||
        (item?.billRequired && !item.supplierName)
      ) {
        showError = true;
        return;
      }
      return {
        expenseId: item?.id,
        ticketId: dataInfo?.id,
        price: item?.price,
        vat: item?.vat,
        total: item?.total,
        supplierName: item?.supplierName,
        billNumber: item?.billNumber,
        billDate: item?.billDate && moment(item?.billDate).format('YYYY-MM-DD'),
      };
    });
    if (showError) {
      return window.TmsNoti?.error('Lỗi', 'Vui lòng nhập đầy đủ thông tin');
    }
    const dataSend = {
      expenses: dataExpense,
      dissolveDate: dataInfo?.dissolveDate && moment(dataInfo?.dissolveDate).format('YYYY-MM-DD'),
      folder: dataInfo?.folder || -1,
      note: dataInfo?.note,
      jobId: dataInfo?.id,
      driverMainName: dataInfo?.driverMainName,
      assignDate: dataInfo?.assignDate && moment(dataInfo?.assignDate).format('YYYY-MM-DD'),
    };
    setLoading(true);
    return ApiServices.updateTicketJob(dataInfo.id, dataSend)
      .then((res) => {
        if (res?.data) {
          handleCloseAddNewFee();
          message.success('Thêm mới chi phí thành công');
          onReloadAfterAddNewFee(dataInfo);
        }
      })
      .catch((err) => {
        console.log('Update Job tickets failed: ', err);
      })
      .finally(() => setLoading(false));
  };

  const onDeleteAddNewFee = (feeId) => {
    const deleteExpensesIdSelectedToAddItem = expensesIdSelectedToAdd?.filter(
      (item) => item !== feeId
    );
    const deleteExpensesSelectedToAddItem = expensesSelectedToAdd?.filter(
      (item) => item?.id !== feeId
    );
    setExpensesIdSelectedToAdd(deleteExpensesIdSelectedToAddItem);
    setExpensesSelectedToAdd(deleteExpensesSelectedToAddItem);
  };

  const renderItem = (item, index) => {
    return (
      <FeeItem
        key={item.expenseId}
        index={index}
        data={item}
        vat={vat}
        fieldsExpense={fieldsExpense}
        setFieldsExpense={setFieldsExpense}
        onDeleteFee={onDeleteFee}
        companyIdOfTicket={dataInfo?.companyId}
        statusTicket={dataInfo?.status}
      />
    );
  };

  return (
    <div
      style={{
        marginTop: '15px',
      }}
    >
      <Divider
        style={{
          color: 'blue',
        }}
      >
        Thông tin chi phí{' '}
      </Divider>
      <HeaderFormCreate dataInfo={dataInfo} />
      <Form
        name='basic'
        form={form}
        labelCol={{
          span: 16,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        layout='vertical'
        fields={[
          {
            name: ['dissolveDate'],
            value: dateDissolveInit ? moment(dateDissolveInit) : moment(dataInfo?.dissolveDate),
          },
          {
            name: ['folder'],
            value: dataInfo?.folder,
          },
          {
            name: ['note'],
            value: dataInfo?.note,
          },
        ]}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label='Ngày giải trừ'
              name='dissolveDate'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập ngày giải trừ!',
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                placeholder='Ngày giải trừ'
                style={{ width: '100%' }}
                format={DATE_FORMAT}
                onChange={handleChangeDissolveDate}
              />
            </Form.Item>
          </Col>
          {/* <Col span={6}>
            <Form.Item
              label='STT Folder'
              name='folder'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập STT Folder!',
                },
              ]}
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item label='Ghi chú' name='note'>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {fieldsExpense?.length ? fieldsExpense?.map(renderItem) : <></>}
        {dataInfo?.status === 1 && (
          <>
            <Button
              type='primary'
              loading={loading}
              icon={<PlusOutlined />}
              onClick={() => handleAddFee(dataInfo?.id)}
            >
              Thêm chi phí:
            </Button>
            <Form.Item
              wrapperCol={{
                offset: 10,
                span: 16,
              }}
            >
              <Button type='primary' htmlType='submit' loading={loading}>
                Cập nhật phiếu
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
      <Modal
        title='Thêm chi phí'
        open={showAddNewFee}
        onCancel={handleCloseAddNewFee}
        footer={null}
        style={{
          top: 20,
        }}
        width='80%'
      >
        <AddNewFee
          expenseListAvailable={expenseListAvailable}
          expensesIdSelectedToAdd={expensesIdSelectedToAdd}
          setExpensesIdSelectedToAdd={setExpensesIdSelectedToAdd}
          expensesSelectedToAdd={expensesSelectedToAdd}
          setExpensesSelectedToAdd={setExpensesSelectedToAdd}
          onDeleteAddNewFee={onDeleteAddNewFee}
          vat={vat}
          companyId={dataInfo?.companyId}
        />
        <div
          style={{
            margin: '5px auto',
            textAlign: 'center',
          }}
        >
          <Badge count={expensesSelectedToAdd?.length}>
            <Button
              disabled={!expensesSelectedToAdd?.length}
              type='primary'
              onClick={handleSubmitAddNewFee}
            >
              Thêm chi phí
            </Button>
          </Badge>
        </div>
      </Modal>
    </div>
  );
};

export { FormUpdate };
