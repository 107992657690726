import React, { useEffect, useState } from 'react';
// import { ReportOrder } from './report-order';
// import { ReportOrderVendor } from './report-order-by-vendor';
// import { ReportFuel } from './report-fuel-by-vendor';
// import { ReportOrderCustomer } from './report-order-by-customer';

import { Selection } from '~components';
import ApiServices from '~services';
import {
  // Spin,
  Row,
  Col,
  Card,
  Divider,
  Space,
  Button,
  Badge,
  Tooltip,
  Statistic,
} from 'antd';
// import { LoadingOutlined } from '@ant-design/icons';
import { VehicleReplacement } from './vehicle-replacement';
// import backgroundNotPermissionImage from '~assets/images/background_not_permission.jpg';

const RELOAD_TIME = 120 * 1000; //giây
const RELOAD_ON = true;

const Home = () => {
  // const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [reportRomooc, setReportRomooc] = useState([]);
  const [reportOrder, setReportOrder] = useState([]);
  const [reportFuel, setReportFuel] = useState([]);
  const [reportOrderByVendor, setReportOrderByVendor] = useState([]);
  const [reportOrderCustomer, setReportOrderCustomer] = useState([]);
  const [reportDrivingLicenseList, setReportDrivingLicenseList] = useState({});
  const [vendors, setVendor] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log({
    reportOrder,
    reportFuel,
    reportOrderByVendor,
    reportOrderCustomer,
    loading,
  });
  const [romoocRegister, setRomoocRegister] = useState([]);
  const [arrIdSelectVendor, setArrIdSelectVendor] = useState([]);
  const [vehicleRegister, setVehicleRegister] = useState([]);
  const [hasPermissionReport, setHasPermissionReport] = useState(true);
  const [reportCar, setReportCar] = useState({
    TotalCar: 0,
    EmptyCar: 0,
    BusyCar: 0,
  });
  const [loadingCountOrders, setLoadingCountOrders] = useState(false);
  const [countOrders, setCountOrders] = useState(null);

  // ====== Count Orders from ONP, WMS ======
  const getCountAllOrdersFromOnpWms = async () => {
    if (loadingCountOrders) return;

    setLoadingCountOrders(true);
    ApiServices.getCountAllOrdersFromOnpWms()
      .then((res) => {
        console.log({ res });
        if (res?.data?.data) {
          setCountOrders(res?.data?.data);
        }
      })
      .catch((err) => {
        console.error('Get count All orders from ONP or WMS', err);
      })
      .finally(() => setLoadingCountOrders(false));
  };
  useEffect(() => {
    getCountAllOrdersFromOnpWms();
  }, []);
  useEffect(() => {
    const handleReload = setInterval(() => {
      RELOAD_ON && getCountAllOrdersFromOnpWms();
    }, RELOAD_TIME);

    return () => clearInterval(handleReload);
  }, []);

  const getReportRomooc = async () => {
    if (!hasPermissionReport) return;
    try {
      const data = await ApiServices.reportRomooc();
      if (data?.data) {
        setReportRomooc(data?.data?.[0] || []);
      } else {
        setHasPermissionReport(false);
      }
    } catch (error) {
      console.log('getReportRomooc error:', error);
      setHasPermissionReport(false);
    }
  };

  const handleReportOrder = async (value) => {
    if (!hasPermissionReport) return;

    try {
      const data = await ApiServices.reportOrder(value);
      if (data?.data) {
        setReportOrder(data?.data || []);
      } else {
        setHasPermissionReport(false);
      }
    } catch (error) {
      console.log('reportOrder error:', error);
      setHasPermissionReport(false);
    }
  };
  const getReportOrderByVendor = async (value) => {
    if (!hasPermissionReport) return;

    try {
      const { data, status } = await ApiServices.reportOrderByVendor(value);
      if (status === 200) {
        setReportOrderByVendor(data || []);
      }
    } catch (error) {
      console.log('getReportOrderByVendor error:', error);
      setHasPermissionReport(false);
    }
  };
  const getReportFuel = async (value) => {
    if (!hasPermissionReport) return;

    try {
      const data = await ApiServices.reportFuelByVendor(value);
      if (data?.data) {
        setReportFuel(data.data);
      } else {
        setHasPermissionReport(false);
      }
    } catch (error) {
      console.log('getReportFuel error:', error);
      setHasPermissionReport(false);
    }
  };
  const getReportOrderCustomer = async (value) => {
    if (!hasPermissionReport) return;

    try {
      const data = await ApiServices.reportOrderByCustomer(value);
      if (data) {
        setReportOrderCustomer(data?.data);
      } else {
        setHasPermissionReport(false);
      }
    } catch (error) {
      console.log('getReportOrderCustomer error:', error);
      setHasPermissionReport(false);
    }
  };
  const getRPRomoocInspection = async () => {
    if (!hasPermissionReport) return;

    try {
      const data = await ApiServices.getRPRomoocInspection();
      if (data?.data) {
        setRomoocRegister(data?.data?.[0] || []);
      } else {
        setHasPermissionReport(false);
      }
    } catch (error) {
      console.log('getRPRomoocInspection error:', error);
      setHasPermissionReport(false);
    }
  };

  const getReportDrivingLicense = async () => {
    try {
      const data = await ApiServices.getReportDrivingLicense();
      if (data?.data?.data) {
        setReportDrivingLicenseList(data?.data?.data);
      }
    } catch (error) {
      console.log('get Report Driving License error:', error);
      setHasPermissionReport(false);
    }
  };

  const initData = async (value = []) => {
    if (!hasPermissionReport) {
      setHasPermissionReport(true);
    }

    await getReportRomooc();
    await handleReportOrder(value);
    await getReportOrderByVendor(value);
    await getReportFuel(value);
    await getReportOrderCustomer(value);
    await getRPRomoocInspection();
    await getReportDrivingLicense();
  };
  useEffect(() => {
    setLoading(true);
    ApiServices.getVendors()
      .then((res) => {
        res.data?.data && initData(res.data?.data?.map((item) => item.id));
        setVendor(res.data?.data || []);
      })
      .catch((e) => console.error(e))
      .finally(setLoading(false));
  }, []);

  const getReportRegisterByCar = async (selectedVendorIds) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getRPVehicleInspection(selectedVendorIds);
      setVehicleRegister(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const reportVehicleByVendorIds = async (ids) => {
    return ApiServices.reportVehicle(ids)
      .then((res) => {
        if (res?.data) {
          const reduceData = res?.data?.reduce((calc, current) => {
            return {
              BusyCar: calc.BusyCar + current.BusyCar,
              EmptyCar: calc.EmptyCar + current.EmptyCar,
              TotalCar: calc.TotalCar + current.TotalCar,
            };
          });
          setReportCar(reduceData || { TotalCar: 0, EmptyCar: 0, BusyCar: 0 });
        }
      })
      .catch((err) => {
        console.log('Get report Vehicle by vendor failed: ', err);
      });
  };

  useEffect(() => {
    if (vendors?.length > 0) {
      const defaultVendorIds = vendors?.map((item) => item.id);
      setArrIdSelectVendor(defaultVendorIds);
    }
  }, [vendors]);
  useEffect(() => {
    if (arrIdSelectVendor?.length > 0) {
      getReportRegisterByCar(arrIdSelectVendor);
      reportVehicleByVendorIds(arrIdSelectVendor);
      initData(arrIdSelectVendor);
    }
  }, [arrIdSelectVendor]);
  const onChangeVender = async (value) => {
    let dataSelect = value;
    if (!value || value?.length < 1) {
      const defaultVendorIds = vendors?.map((item) => item.id);
      dataSelect = defaultVendorIds;
    }
    setArrIdSelectVendor(dataSelect);
  };

  // const renderNotPermissionReport = () => {
  //   return (
  //     <>
  //       <div
  //         style={{
  //           overflow: 'hidden',
  //         }}
  //       >
  //         <img
  //           src={backgroundNotPermissionImage}
  //           alt='backgroundNotPermissionImage'
  //           style={{
  //             width: '100%',
  //             height: 'calc(100vh - 30px)',
  //             overflow: 'hidden',
  //           }}
  //         />
  //       </div>
  //     </>
  //   );
  // };

  const renderReportRomooc = () => (
    <Card
      className='register-romooc'
      size='small'
      style={{
        border: '1px solid blue',
        borderRadius: '10px',
        marginTop: '10px',
        marginLeft: '20px',
      }}
    >
      <Divider>Hạn Đăng Kiểm Romooc</Divider>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/report-romooc?key=ArrHetHanDangKiem`}
          target='_blank'
          disabled={romoocRegister?.ArrHetHanDangKiem?.length > 0 ? false : true}
        >
          <Space size={60}>
            <b>Đã hết hạn:</b>
            <span className='blue'>{romoocRegister?.ArrHetHanDangKiem?.length}</span>
          </Space>
        </Button>
      </div>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/report-romooc?key=ArrHetHanDangKiem10`}
          target='_blank'
          disabled={romoocRegister?.ArrHetHanDangKiem10?.length > 0 ? false : true}
        >
          <Space size={50}>
            <b>Còn 10 ngày:</b>
            <span className='blue'>{romoocRegister?.ArrHetHanDangKiem10?.length}</span>
          </Space>
        </Button>
      </div>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/report-romooc?key=ArrHetHanDangKiem20`}
          target='_blank'
          disabled={romoocRegister?.ArrHetHanDangKiem20?.length > 0 ? false : true}
        >
          <Space size={50}>
            <b>Còn 20 ngày:</b>
            <span className='blue'>{romoocRegister?.ArrHetHanDangKiem20?.length}</span>
          </Space>
        </Button>
      </div>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/report-romooc?key=ArrHetHanDangKiem30`}
          target='_blank'
          disabled={romoocRegister?.ArrHetHanDangKiem30?.length > 0 ? false : true}
        >
          <Space size={50}>
            <b>Còn 30 ngày:</b>
            <span className='blue'>{romoocRegister?.ArrHetHanDangKiem30?.length}</span>
          </Space>
        </Button>
      </div>
    </Card>
  );

  const renderReportVehicle = () => (
    <Card
      className='register-car'
      size='small'
      style={{
        border: '1px solid blue',
        borderRadius: '10px',
        marginTop: '10px',
        marginLeft: '20px',
      }}
    >
      <Divider
        style={{
          padding: '0px',
          margin: '1px',
        }}
      >
        Hạn Đăng Kiểm Xe
      </Divider>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/report-vehicle?id=${arrIdSelectVendor}&key=hetHanDangKiem`}
          target='_blank'
          disabled={vehicleRegister?.hetHanDangKiem?.length > 0 ? false : true}
        >
          <Space size={77}>
            <b>Đã hết hạn:</b>
            <span className='blue'>{vehicleRegister?.hetHanDangKiem?.length}</span>
          </Space>
        </Button>
      </div>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/report-vehicle?id=${arrIdSelectVendor}&key=hetHanDangKiem10`}
          target='_blank'
          disabled={vehicleRegister?.hetHanDangKiem10?.length > 0 ? false : true}
        >
          <Space size={67}>
            <b>Còn 10 ngày:</b>
            <span className='blue'>{vehicleRegister?.hetHanDangKiem10?.length}</span>
          </Space>
        </Button>
      </div>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/report-vehicle?id=${arrIdSelectVendor}&key=hetHanDangKiem20`}
          target='_blank'
          disabled={vehicleRegister?.hetHanDangKiem20?.length > 0 ? false : true}
        >
          <Space size={67}>
            <b>Còn 20 ngày:</b>
            <span className='blue'>{vehicleRegister?.hetHanDangKiem20?.length}</span>
          </Space>
        </Button>
      </div>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/report-vehicle?id=${arrIdSelectVendor}&key=hetHanDangKiem30`}
          target='_blank'
          disabled={vehicleRegister?.hetHanDangKiem30?.length > 0 ? false : true}
        >
          <Space size={67}>
            <b>Còn 30 ngày:</b>
            <span className='blue'>{vehicleRegister?.hetHanDangKiem30?.length}</span>
          </Space>
        </Button>
      </div>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/report-vehicle?id=${arrIdSelectVendor}&key=hetHanPhuHieu`}
          target='_blank'
          disabled={vehicleRegister?.hetHanPhuHieu?.length > 0 ? false : true}
        >
          <Space size={35}>
            <b>Hết hạn phù hiệu:</b>
            <span className='blue'>{vehicleRegister?.hetHanPhuHieu?.length}</span>
          </Space>
        </Button>
      </div>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/report-vehicle?id=${arrIdSelectVendor}&key=hetHanBaoHiem`}
          target='_blank'
          disabled={vehicleRegister?.hetHanBaoHiem?.length > 0 ? false : true}
        >
          <Space size={30}>
            <b>Hết hạn bảo hiểm:</b>
            <span className='blue'>{vehicleRegister?.hetHanBaoHiem?.length}</span>
          </Space>
        </Button>
      </div>
      <Divider
        style={{
          padding: '0px',
          margin: '2px',
        }}
      >
        Giấy Phép Lái Xe
      </Divider>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/report-driving-license?key=HetHan`}
          target='_blank'
          disabled={reportDrivingLicenseList?.HetHan?.length > 0 ? false : true}
        >
          <Space size={77}>
            <b>Đã hết hạn:</b>
            <span className='blue'>{reportDrivingLicenseList?.HetHan?.length}</span>
          </Space>
        </Button>
      </div>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/report-driving-license?key=HetHan10`}
          target='_blank'
          disabled={reportDrivingLicenseList?.HetHan10?.length > 0 ? false : true}
        >
          <Space size={67}>
            <b>Còn 10 ngày:</b>
            <span className='blue'>{reportDrivingLicenseList?.HetHan10?.length}</span>
          </Space>
        </Button>
      </div>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/report-driving-license?key=HetHan20`}
          target='_blank'
          disabled={reportDrivingLicenseList?.HetHan20?.length > 0 ? false : true}
        >
          <Space size={67}>
            <b>Còn 20 ngày:</b>
            <span className='blue'>{reportDrivingLicenseList?.HetHan20?.length}</span>
          </Space>
        </Button>
      </div>
    </Card>
  );
  const renderReportRepair = () => (
    <div
      className='table-container-custom'
      style={{
        width: '285px',
        marginLeft: '15px',
      }}
    >
      <Divider>Bảo Trì Sữa Chữa</Divider>
      <VehicleReplacement arrIdSelectVendor={arrIdSelectVendor} />
    </div>
  );
  const renderVendorSelected = () => (
    <div
      style={{
        alignItems: 'center',
        marginLeft: '40px',
      }}
    >
      <Row>
        <Col className='gutter-row'>
          <Row gutter={24}>Đội Xe</Row>
          <Row gutter={24}>
            <Selection
              mode='multiple'
              data={vendors}
              onSearch={false}
              style={{ width: 188 }}
              onChange={onChangeVender}
              value={arrIdSelectVendor}
            />
          </Row>
          <Row gutter={24} style={{ minWidth: 200 }} className='badge-report'>
            <Col span={12}>
              <Statistic title='Xe' value={reportCar.TotalCar} />
              <Tooltip title='Xe Trống'>
                <Badge status='success' text={reportCar.EmptyCar} />
              </Tooltip>
            </Col>
            <Col span={12}>
              <Statistic title='Romooc' value={reportRomooc.TotalRomooc} />
              <Tooltip title='Romooc Trống'>
                <Badge status='success' text={reportRomooc.EmptyRomooc} />
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );

  // const renderHasPermissionReport = () => (
  //   <div>
  //     <div
  //       style={{
  //         width: '100%',
  //         display: 'flex',
  //       }}
  //     >
  //       <div
  //         style={{
  //           width: '30%',
  //           padding: '20px',
  //         }}
  //       >
  //         {renderVendorSelected()}
  //         {renderReportVehicle()}
  //         {renderReportRomooc()}
  //         {renderReportRepair()}
  //       </div>
  //       <div
  //         style={{
  //           width: '70%',
  //         }}
  //       >
  //         <Spin indicator={antIcon} spinning={loading}>
  //           <Row>
  //             <ReportOrder
  //               data={reportOrder}
  //               vendors={vendors}
  //               reportRomooc={reportRomooc}
  //               reRender={initData}
  //               setArrIdSelectVendor={setArrIdSelectVendor}
  //             />
  //             <div
  //               style={{
  //                 display: 'flex',
  //                 marginBottom: '20px',
  //               }}
  //             >
  //               <div>
  //                 <ReportOrderVendor data={reportOrderByVendor} />
  //               </div>
  //               <div>
  //                 <ReportFuel data={reportFuel} />
  //               </div>
  //             </div>
  //             <ReportOrderCustomer data={reportOrderCustomer} />
  //           </Row>
  //         </Spin>
  //       </div>
  //     </div>
  //   </div>
  // );

  const renderOrders = () => (
    <Card
      className='register-romooc'
      size='small'
      style={{
        border: '1px solid blue',
        borderRadius: '10px',
        marginTop: '10px',
        marginLeft: '20px',
      }}
    >
      <Divider>Danh Sách Đơn Hàng</Divider>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/orders-waiting-receive-from-ONP`}
          target='_blank'
          disabled={!countOrders?.wONP}
        >
          <Space size={52}>
            <b>Chờ nhận từ ONP:</b>
            <span className='blue'>{countOrders?.wONP}</span>
          </Space>
        </Button>
      </div>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/orders-rvs-from-ONP`}
          target='_blank'
          disabled={!countOrders?.rONP}
        >
          <Space size={90}>
            <b>Rvs từ ONP:</b>
            <span className='blue'>{countOrders?.rONP}</span>
          </Space>
        </Button>
      </div>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/orders-cancel-from-ONP`}
          target='_blank'
          disabled={!countOrders?.cONP}
        >
          <Space size={88}>
            <b>Hủy từ ONP:</b>
            <span className='blue'>{countOrders?.cONP}</span>
          </Space>
        </Button>
      </div>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/orders-waiting-receive-from-WMS`}
          target='_blank'
          disabled={!countOrders?.wWMS}
        >
          <Space size={55}>
            <b>Chờ nhận từ Kho:</b>
            <span className='blue'>{countOrders?.wWMS}</span>
          </Space>
        </Button>
      </div>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/orders-rvs-from-WMS`}
          target='_blank'
          disabled={!countOrders?.rWMS}
        >
          <Space size={92}>
            <b>Rvs từ Kho:</b>
            <span className='blue'>{countOrders?.rWMS}</span>
          </Space>
        </Button>
      </div>
      <div className='button-text-home'>
        <Button
          type='text'
          href={`/orders-cancel-from-WMS`}
          target='_blank'
          disabled={!countOrders?.cWMS}
        >
          <Space size={90}>
            <b>Hủy từ Kho:</b>
            <span className='blue'>{countOrders?.cWMS}</span>
          </Space>
        </Button>
      </div>
    </Card>
  );

  const renderHasPermissionReportNew = () => (
    <div
      style={{
        backgroundColor: '#f0f2f5',
        width: '100%',
        height: 'calc(100vh - 30px)',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          marginBottom: '20px',
        }}
      >
        {renderVendorSelected()}
      </div>
      <Row justify='left' align='top'>
        <Col span={6}>{renderOrders()}</Col>
        <Col span={6}>{renderReportVehicle()}</Col>
        <Col span={6}>{renderReportRomooc()}</Col>
        <Col span={6}>{renderReportRepair()}</Col>
      </Row>
    </div>
  );
  if (hasPermissionReport) {
    return renderHasPermissionReportNew();
  }
  return renderHasPermissionReportNew();
  // return renderNotPermissionReport();
};

export { Home };
