import React, { forwardRef } from 'react';
import moment from 'moment';
import { ReadNumber } from '~commons/read-number';

const MultipleOilSupplyTicket = forwardRef(({ data, companyInfo }, ref) => {
  const convertNumberToText = (number) => {
    const isNumberType = Number?.isInteger(number);
    if (isNumberType) return ReadNumber(number);

    const getFloor = number.toString().split('.');
    const intNumber = ReadNumber(parseInt(getFloor[0]));
    const floorNumber = ReadNumber(parseInt(getFloor[1]));
    return `${intNumber} Chấm ${floorNumber}`;
  };

  const renderOilTicket = (dataItem) => {
    const dataNumberCode = dataItem?.numberCode?.split('U&I');
    const displayNumberCode = dataNumberCode ? `${dataNumberCode[0]} U&I ${dataNumberCode[1]}` : '';

    return (
      <>
        <div style={{ border: '2px solid blue', marginLeft: '20px', width: '50%' }}>
          <div style={{ paddingLeft: '10px' }}>{dataItem?.companyName ?? companyInfo?.name}</div>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <b>PHIẾU CẤP NHIÊN LIỆU</b>
            <div>Số: {displayNumberCode} </div>
          </div>

          <div>
            <div style={{ padding: '0px 0px  0px 30px', margin: '0px' }}>
              <span>- Kính Gửi: Cửa Hàng Xăng Dầu</span>
              <div>- Chúng Tôi Đề Nghị Cửa Hàng Cấp Cho:</div>
            </div>

            <div style={{ border: '2px solid red', margin: '0px 10px', paddingLeft: '10px' }}>
              <span>- Lái Xe: </span>
              <b>{dataItem?.driverMainName}</b>
              <br />
              <span>- Số Xe: </span>
              <b>{dataItem?.carCode}</b>
              <br />
              <span>- Lý Do: </span>
              <b>Chi Dầu Ngày {dataItem?.assignOn}</b>
              <br />
              <span>- Số Lượng: </span>
              <b>{Math.round(dataItem?.newOilToSupply)}</b> Lít{' '}
              {dataItem?.totalOilSupport > 0 && (
                <span>(bao gồm {dataItem?.totalOilSupport} Lít hỗ trợ)</span>
              )}
              <br />
              <span>- Bằng Chữ: </span>
              <b>{convertNumberToText(Math.round(dataItem?.newOilToSupply))}</b>
              <br />
              <span>- Ngày Cấp: </span>
              <b>{moment().format('DD/MM/YYYY')}</b>
              <br />
              <b>Phiếu có giá trị trong 3 ngày kể từ ngày cấp.</b>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginLeft: 'auto', paddingRight: '10px' }}>
                Ngày {moment().format('DD')} Tháng {moment().format('MM')} Năm{' '}
                {moment().format('YYYY')}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                textAlign: 'center',
                alignItems: 'center',
                marginBottom: '33px',
              }}
            >
              <div style={{ width: '32%', fontWeight: 'bold' }}>Duyệt</div>
              <div style={{ width: '32%', fontWeight: 'bold' }}>Lập phiếu</div>
              <div style={{ width: '32%', fontWeight: 'bold' }}>Lái Xe</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderTickets = (item) => {
    return (
      <div key={item?.id}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '10px',
          }}
        >
          {renderOilTicket(item)}
          {renderOilTicket(item)}
        </div>
        {/* {index > 0 && (index + 1) % 3 === 0 && <div style={{ height: '30px' }}></div>} */}
      </div>
    );
  };

  return <div ref={ref}>{data?.map(renderTickets)}</div>;
});

export { MultipleOilSupplyTicket };
