import actionTypes from './type';
import ApiServices from '~services';
import { DEFAULT_QUERY_WB } from '~commons/constants';
import Storage from '~storage';

export function fetchWaitingOfDelivery(query = DEFAULT_QUERY_WB) {
  if (Storage?.user?.configs?.PageSizeWB > 30) {
    query.PageSize = Storage?.user?.configs?.PageSizeWB;
  }
  return async (dispatch) => {
    try {
      const { data } = await ApiServices.getWhiteBoardOne(query);
      return dispatch({
        data: data,
        type: actionTypes.FETCH_DATA,
      });
    } catch (error) {
      console.error('fetch-Waiting_Of_Delivery-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_DATA });
  };
}

export function loadMore(query = {}) {
  return async (dispatch) => {
    try {
      const { data } = await ApiServices.getWhiteBoardOne(query);
      return dispatch({
        data: data,
        type: actionTypes.LOAD_MORE_DATA,
      });
    } catch (error) {
      console.error('Waiting_Of_Delivery_loadMore-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_DATA });
  };
}

export function updateQuery(query) {
  return (dispatch) => dispatch({ query, type: actionTypes.UPDATE_QUERY });
}
export function updateLoadingReport(loading = false) {
  return (dispatch) => dispatch({ loading, type: actionTypes.UPDATE_LOADING });
}
export function updateSelectedReport(selected = []) {
  return (dispatch) => dispatch({ selected, type: actionTypes.UPDATE_SELECTED });
}

export function updateSelectedEmptyCont(selected = []) {
  return (dispatch) => dispatch({ selected, type: actionTypes.UPDATE_SELECTED_EMPTYCONT });
}
export function updateOne(data) {
  return (dispatch) => dispatch({ data, type: actionTypes.UPDATE_ONE_DATA });
}
