import React, { useState } from 'react';
import { Button, Modal, Space, Tooltip } from 'antd';
import { HomeOutlined, SearchOutlined, ExpandAltOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Notifications } from './wb-notifications';
import { ContSearchNumber } from '../cont-serch-number';
import { SingleCont } from './single-conts';
import { OrderRvsByTms } from '../white-board/order-rvs-by-tms';

const ContSearchNumberBox = ({ show = false, onClose }) => {
  const onClickShowAll = () => {
    window.open('/cont-search-number', '_blank');
  };

  return (
    <Modal
      open={show}
      onCancel={onClose}
      footer={null}
      width={1200}
      style={{
        top: 20,
      }}
      title={
        <Space block>
          <Tooltip title='Mở rộng'>
            <ButtonExpand icon={<ExpandIcon />} onClick={onClickShowAll} />
          </Tooltip>
          <span>Tìm số cont </span>{' '}
        </Space>
      }
    >
      <ContSearchNumber headerLength='350px' />
    </Modal>
  );
};
export default function Header() {
  const [isShowSearch, setIsShowSearch] = useState(false);

  const onClickSearch = () => {
    setIsShowSearch((prev) => !prev);
  };
  const onCloseSearch = () => {
    setIsShowSearch(false);
  };

  return (
    <>
      <NotificationWrapper>
        <Notifications />
      </NotificationWrapper>
      <SearchWrapper>
        <Tooltip title='Tìm số cont'>
          <Button icon={<SearchOutlined />} type='primary' onClick={onClickSearch} />
        </Tooltip>
      </SearchWrapper>
      <ContSearchNumberBox show={isShowSearch} onClose={onCloseSearch} />
      <SingleCont />
      <OrderRvsByTms />
      <Wrapper className='z-index-100 scrollable-div position-fixed '>
        <div className='scrollable-container'>
          <Link to='/' target='_blank'>
            <Button className='btn-back-home' type='primary' shape='round' icon={<HomeOutlined />}>
              <span className='btn-back-home-txt'>Trang Chủ</span>
            </Button>
          </Link>
        </div>
      </Wrapper>
    </>
  );
}

const SearchWrapper = styled.div`
  bottom: 8px;
  left: 108px;
  position: fixed;
  z-index: 999999;
`;
const NotificationWrapper = styled.div`
  bottom: 8px;
  right: 16px;
  position: fixed;
  z-index: 999999;
`;
const Wrapper = styled.div`
  top: 8px;
  right: 10px;
`;
const ButtonExpand = styled(Button)`
  height: auto;
`;
const ExpandIcon = styled(ExpandAltOutlined)`
  font-size: 18px;
`;
