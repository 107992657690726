export const KEYS = [
  'code',
  'name',
  'materialTypeCode',
  'materialTypeName',
  'unitCode',
  'unitName',
  'otherInfo',
  'companyId',
  'companyName',
];
export default (data) => {
  return KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });
};
