import React from 'react';
import { TransportFeeMain } from './transport-fee-main';

const TransportFee = ({
  contTypeList,
  dataFeeMainTransport,
  setDataFeeMainTransport,
  locationSelected,
  reloadAfterUpdate,
  contractStatus,
  isNew,
  valueDetail = null,
}) => {
  return (
    <>
      <TransportFeeMain
        contTypeList={contTypeList}
        dataFeeMainTransport={dataFeeMainTransport}
        setDataFeeMainTransport={setDataFeeMainTransport}
        locationSelected={locationSelected}
        reloadAfterUpdate={reloadAfterUpdate}
        contractStatus={contractStatus}
        isNew={isNew}
        valueDetail={valueDetail}
      />
    </>
  );
};

export { TransportFee };
