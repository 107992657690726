import React, { useEffect, useState } from 'react';
import { DatePicker, Divider, Affix, Space } from 'antd';
import ApiServices from '~services';
import { EXPORT_HEADER, MERGE_COLUMN_SHEET, FIELDS_FORMAT_DATE } from './constants';
import { VendorSelection } from '../components/vendor-selection';
import { TableData } from './table-data';
import { OrderTypeSelectReport } from '../components/order-type-select';
import { ReportHeader, ExportCSV } from '~components';
import { DATE_TIME_FORMAT } from '~commons/constants';
import utils from '~commons/utils';
import moment from 'moment';
// import Pdf from 'react-to-pdf';
// import ReactExport from 'react-data-export';

const { RangePicker } = DatePicker;

const defaultFilter = { listVendorId: [], dateFrom: null, dateTo: null, orderType: '' };
export const ReportAllJob = () => {
  const [data, setData] = useState([]);
  const [rangeDays, setRangeDays] = useState([]);
  const [vendorSelected, setVendorSelected] = useState([]);
  const [orderType, setOrderType] = useState('Booking');
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    var tmpFilter = filter;
    rangeDays?.length > 0 && (tmpFilter.dateFrom = rangeDays[0].format());
    rangeDays?.length > 1 && (tmpFilter.dateTo = rangeDays[1].format());
    tmpFilter.orderType = orderType;
    tmpFilter.listVendorId = vendorSelected;
    setFilter(tmpFilter);
    if (firstTime) {
      setFirstTime(false);
      return;
    }
    getData();
  }, [rangeDays, vendorSelected, orderType]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getRPDetailAllJob(filter, { PageSize: 50 });

      const tmpData = data?.data?.map((item, index) => {
        const res = {};

        for (let [key, value] of Object.entries(item)) {
          if (FIELDS_FORMAT_DATE.includes(key)) {
            value = value ? moment(value).format(DATE_TIME_FORMAT) : null;
          }
          switch (key) {
            case 'TrangThaiCont':
              value = utils.formatStatusCont(value);
              break;
            case 'TrangThaiDonHang':
              value = utils.formatStatusOrder(value);
              break;
            case 'TrangThaiCongViec':
              value = utils.formatStatusTask(value);
              break;
            default:
              break;
          }
          res[key] = value;
        }
        res.index = index;
        res.key = index;
        return res;
      });
      setData(tmpData);
    } catch (error) {
      console.error('getRPDetailAllJob', error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Affix className='scrollable-container '>
        <div style={{ paddingTop: 5, background: 'rgba(0, 0, 0, 0.05)' }}>
          <Space direction='vertical'>
            <RangePicker
              value={rangeDays}
              onChange={setRangeDays}
              placeholder={['Ngày BĐ', 'Ngày KT']}
            />
          </Space>
          <VendorSelection
            setLoading={setLoading}
            onChange={setVendorSelected}
            selected={vendorSelected}
          />
          <OrderTypeSelectReport selected={orderType} onChange={setOrderType} />
          <ExportCSV
            fileName='report-order-job-detail'
            data={data}
            header={EXPORT_HEADER}
            mergeColumn={MERGE_COLUMN_SHEET}
          />

          <Divider />
        </div>
      </Affix>

      <div>
        <ReportHeader />
        <Divider plain={true}>
          <h1>
            <b>BÁO CÁO CHI TIẾT CÔNG VIỆC</b>
          </h1>
        </Divider>

        <TableData data={data} loading={loading} />
      </div>
    </>
  );
};
