import { Button } from 'antd';
import styled from 'styled-components';

export const TableCellTitle = styled.span`
  text-align: center;
`;

export const Wrapper = styled.div`
  width: ${({ width }) => width || '100%'};
  padding: 0px 10px;
  position: relative;
  height: fit-content;
`;
export const Title = styled.div`
  font-size: 20px;
  line-height: 14px;
  padding: 0px 8px;
  height: 30px;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-transform: capitalize;
`;
export const TableTitle = styled.div`
  text-align: center;
  line-height: 24px;
`;
export const TableButton = styled(Button)`
  padding: 0;
`;
