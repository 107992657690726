import { useState, useEffect } from 'react';
import { Button, List, Avatar, Divider, Skeleton, Menu, Dropdown } from 'antd';
import { BellOutlined, CommentOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { ModalDriverRefuse } from './modal-driver_refuse';
import {
  fetchNotifications,
  fetchCounterNotRead,
  updateLoading,
  loadMore,
} from '~store/reducers/notifications/action';
import { useDispatch, useSelector } from 'react-redux';
import { NOTIFICATION_STATUSES, NOTIFICATION_TYPES } from '~commons/constants';

const ListNotificationWB = ({ onShowNotification }) => {
  const history = useHistory();
  const { data, query, loading } = useSelector((state) => state.notifications);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actionRefuse, setActionRefuse] = useState(false);
  const [dataRow, setDataRow] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);

  const showModalDriverRefuse = async (item, titleRefuse) => {
    await onShowNotification();
    await setDataRow(item);
    await setActionRefuse(titleRefuse);
    await setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const menu = (item) => {
    return (
      <Menu>
        <Menu.Item key='0'>
          <Button type='primary' onClick={() => showModalDriverRefuse(item, true)}>
            Đồng Ý
          </Button>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key='1'>
          <Button type='ghost' onClick={() => showModalDriverRefuse(item, false)}>
            Từ Chối
          </Button>
        </Menu.Item>
      </Menu>
    );
  };
  const dispatch = useDispatch();
  const setLoading = (value) => dispatch(updateLoading(value));
  const reloadData = async () => {
    setLoading(true);
    await dispatch(fetchNotifications(query));
    await dispatch(fetchCounterNotRead());
    setLoading(false);
  };
  const initData = async () => {
    setLoading(true);
    await dispatch(fetchNotifications(query));
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);
  const LoadMoreData = async () => {
    if (loadingMore) return;
    if (data.pageNumber * query.PageSize <= data.totalRecords) {
      let tmpQuery = { ...query, PageNumber: data.pageNumber + 1 };
      setLoadingMore(true);
      await dispatch(loadMore(tmpQuery));
      setLoadingMore(false);
    }
  };
  const onReadNotification = async (item) => {
    try {
      const notiData = item.multicastMessage.data;

      if (item.status === 0) {
        let { data } = await ApiServices.readNotification(item.id);
        if (data === 'success') {
          await reloadData(item);

          switch (notiData?.Type) {
            case NOTIFICATION_TYPES.DRIVER_ACCEPT:
              return history.replace(`/whiteboard?id=${notiData?.CarId}&code=${notiData?.CarCode}`);
            case NOTIFICATION_TYPES.DRIVER_REFUSE:
              return history.replace(`/whiteboard?listId=${notiData?.Jobs}`);
            default:
              break;
          }
        }
      } else {
        switch (notiData?.Type) {
          case NOTIFICATION_TYPES.DRIVER_ACCEPT:
            return history.replace(`/whiteboard?id=${notiData?.CarId}&code=${notiData?.CarCode}`);
          case NOTIFICATION_TYPES.DRIVER_REFUSE:
            return history.replace(`/whiteboard?listId=${notiData?.Jobs}`);
          default:
            break;
        }
      }
    } catch (error) {
      console.log('Error - get CountNotificationNotRead', error);
      console.error(error);
    }
  };
  const isLoadMore = data?.pageNumber * query?.PageSize < data?.totalRecords;
  return (
    <div className='demo-infinite-container' style={{ marginRight: '2px' }}>
      <Divider>
        <b>Thông Báo</b>
      </Divider>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={LoadMoreData}
        hasMore={!loading && isLoadMore}
        useWindow={false}
      >
        <List
          itemLayout='horizontal'
          dataSource={data?.data}
          rowKey='id'
          renderItem={(item) => (
            <List.Item
              className={`list-notification ${
                item.status === NOTIFICATION_STATUSES.READ ? 'list-notification-opacity' : ''
              }`}
              onClick={
                item.multicastMessage.data?.Type !== 'Driver_Refuse'
                  ? () => onReadNotification(item)
                  : null
              }
            >
              <Skeleton avatar title={false} loading={loading && isLoadMore} active>
                <List.Item.Meta
                  className='pd-l-10'
                  avatar={
                    <Avatar
                      src={
                        item.multicastMessage.notification.image
                          ? item.multicastMessage.notification.image
                          : ''
                      }
                      icon={<BellOutlined />}
                      style={{
                        backgroundColor: `${item.status === 1 ? '#D1D9D9' : '#FF7171'}`,
                      }}
                    />
                  }
                  title={item.multicastMessage.notification.title}
                  description={item.multicastMessage.notification.body}
                />
                {item.status !== 1 && item.multicastMessage.data?.Type === 'Driver_Refuse' && (
                  <Dropdown overlay={() => menu(item)} placement='bottomRight' arrow>
                    <Button type='primary' shape='circle' icon={<CommentOutlined />} />
                  </Dropdown>
                )}
                {item.status !== NOTIFICATION_STATUSES.READ ? (
                  <Avatar
                    size={13}
                    className='icon-status-notification'
                    style={{
                      backgroundColor: 'rgb(0, 136, 254)',
                      marginRight: '10px',
                      marginLeft: '10px',
                    }}
                  />
                ) : null}
              </Skeleton>
            </List.Item>
          )}
        />
      </InfiniteScroll>
      <ModalDriverRefuse
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        actionRefuse={actionRefuse}
        dataRow={dataRow}
        getCountNotificationNotRead={() => dispatch(fetchCounterNotRead())}
      />
    </div>
  );
};

export { ListNotificationWB };
