import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchData,
  loadMore,
  updateQuery,
  updateLoading,
  // updateIsActiveDetail,
} from '~store/reducers/parking-cont/action';
import { showAssignCarToday } from '~store/reducers/white-board/action';
import { TableWhiteBoard, WBTableLoadMore } from '~components';
import { getColumns } from './columns';
import { Wrapper, Title } from '~commons/styles';
import { useWBTableScroll } from '~hooks';
import { CONT_STATUSES, PARKING_CONT_WIDTH, TOUR_KINDS } from '~commons/constants';
import { selectedDataRowLiftOn } from '~store/reducers/lift-on/action';
import { selectedDataRowLiftOff } from '~store/reducers/lift-off/action';
import ApiServices from '~services';
import { message } from 'antd';
import useApiHook from '../wb-hook/api-hook';

export const TableParkingCont = () => {
  const dispatch = useDispatch();
  const [loadingMore, setLoadingMore] = useState(false);
  const {
    data: parkingContData,
    query,
    loading,
    isActiveDetail,
  } = useSelector((state) => state.parkingCont);
  const timeOutRef = useRef();
  const { tableRef, isScroll } = useWBTableScroll();
  const { reloadSingleCont } = useApiHook();

  const initData = async () => {
    dispatch(updateLoading(true));
    await dispatch(fetchData(query));
    dispatch(updateLoading(false));
  };
  const loadMoreData = async () => {
    if (loadingMore) return;
    if (parkingContData.pageNumber * query.PageSize <= parkingContData.totalRecords) {
      let tmpQuery = { ...query, PageNumber: parkingContData.pageNumber + 1 };
      setLoadingMore(true);
      await dispatch(loadMore(tmpQuery));
      setLoadingMore(false);
    }
  };

  // useEffect(() => {
  //   if (!parkingContData?.totalRecords) {
  //     dispatch(updateIsActiveDetail(false));
  //   }
  // }, [parkingContData?.totalRecords]);

  useEffect(() => {
    if (isScroll) {
      loadMoreData();
    }
  }, [isScroll]);

  useEffect(() => {
    clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(initData, 1000);
  }, [query]);
  const setQuery = (columns) => {
    const tmpQuery = { ...query };
    tmpQuery.Columns = { ...tmpQuery.Columns, ...columns };
    dispatch(updateQuery(tmpQuery));
  };
  // const onSelectChange = (selectedRowKey, selectedRows) => {
  //   const mapData = selectedRows.map((item) => item.jobId);
  //   // mapData?.length && totalLiftOffSelect && dispatch(updateIndexSelected(0));
  //   dispatch(updateSelected(selectedRows));
  // };
  const countAssigned = parkingContData?.totalRecords - parkingContData?.countNotAssign;
  const setSelectedRow = (record) => {
    if (record.kind === TOUR_KINDS.LIFT_ON) {
      return dispatch(selectedDataRowLiftOn([record.jobId], [record]));
    }
    return dispatch(selectedDataRowLiftOff([record.jobId], [record]));
  };
  const renderRowKey = (record) => record.jobId?.join(',');
  const renderRowClassName = (record) =>
    `${
      record?.assignId || record?.childJobName?.[0]
        ? 'row-assigned'
        : record?.statusCont === CONT_STATUSES.CANCELED && 'row-cancelled'
    } ${record.type}`;
  const showListFile = (record) => dispatch(showListFile(record));
  const showAssign = (record) => {
    dispatch(showAssignCarToday(record));
  };
  const cancelSingleCont = (arrJobId) => {
    dispatch(updateLoading(true));
    ApiServices.returnEmptyContBack(arrJobId)
      .then((res) => {
        if (res.data) {
          message.success('Thao tác thành Cont');
          initData();
          reloadSingleCont();
        }
      })
      .catch((err) => console.error('Cancel Single Cont Error', err));
    dispatch(updateLoading(false));
  };
  if (!isActiveDetail) return null;

  return (
    <Wrapper width={PARKING_CONT_WIDTH} ref={tableRef}>
      <Title>
        Cont Chờ Tại Bãi <b className='red'>({parkingContData?.totalRecords || 0})</b>
        {!!countAssigned && <b>({countAssigned})</b>}
      </Title>
      <TableWhiteBoard
        headerLength='190px'
        renderRowKey={renderRowKey}
        rowClassName={renderRowClassName}
        columns={getColumns(setQuery, showListFile, setSelectedRow, showAssign, cancelSingleCont)}
        data={parkingContData?.data}
        loading={loading}
        showSelection={false}
      />
      <WBTableLoadMore loading={loadingMore} total={parkingContData?.data?.length} />
    </Wrapper>
  );
};
