import React from 'react';
import { Drawer, Spin } from 'antd';
import { TabDrawer } from './tab-drawer-left';

class DrawerLeft extends React.Component {
  constructor() {
    super();
  }

  render() {
    const { visible, tabPanels, onClose, title, isLoadingTab, width } = this.props;

    return (
      <Drawer
        title={title}
        width={width || 400}
        maskClosable={false}
        placement='right'
        onClose={onClose}
        getContainer={false}
        // style={{ position: 'absolute' }}
        open={visible}
        // footer={
        //   <div
        //     style={{
        //       textAlign: 'right',
        //     }}
        //   >
        //     <Button onClick={onClose} style={{ marginRight: 8 }}>
        //       Cancel
        //     </Button>
        //     <Button onClick={onClose} type="primary">
        //       Submit
        //     </Button>
        //   </div>
        // }
      >
        <Spin tip='Loading...' spinning={isLoadingTab}>
          <TabDrawer panes={tabPanels} />
        </Spin>
      </Drawer>
    );
  }
}
export default DrawerLeft;
