export const LIST_COLUMNS_TITLE_FIRST_DETAIL_SHEET = [
  '',
  'STT',
  'Ngày Đổ',
  'Đơn Vị Tính',
  'Giá Dầu',
  'Dư Đầu Kỳ',
  '',
  'Nhập',
  '',
  'Xuất',
  '',
  'Tồn Cuối Kỳ',
  '',
];

export const LIST_COLUMNS_TITLE_SECOND_DETAIL_SHEET = [
  '',
  '',
  '',
  '',
  '',
  'Số Lượng',
  'Trị Giá',
  'Số Lượng',
  'Trị Giá',
  'Số Lượng',
  'Trị Giá',
  'Số Lượng',
  'Trị Giá',
];

export const MERGE_TWO_ROWS_TITLE_DETAIL_SHEET = ['B', 'C', 'D', 'E'];

export const MERGE_TWO_COLUMNS_TITLE_DETAIL_SHEET = [
  {
    start: 'F',
    end: 'G',
  },
  {
    start: 'H',
    end: 'I',
  },
  {
    start: 'J',
    end: 'K',
  },
  {
    start: 'L',
    end: 'M',
  },
];

export const BORDER_CELL_TITLE_DETAIL_SHEET = ['F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'];

export const COLUMNS_BORDER_LIST_DETAIL_SHEET = [
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
];

export const LIST_COLUMN_SUM_DETAIL_SHEET = ['F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'];
