import React, { useEffect, useState } from 'react';
import { Modal, Typography, message } from 'antd';
import styled from 'styled-components';
import { Selection } from '~components';
import ApiServices from '~services';
import useApiHook from '../wb-hook/api-hook';

export const ChangeRomoocForm = ({ job, onClose, setLoading, reload }) => {
  const isOpen = !!job;
  const [romoocs, setRmoocs] = React.useState([]);
  const [romooc, setRomooc] = useState('');

  const { reloadLiftOff, reloadLiftOn, reloadParkingCont, reloadWaitingOfDelivery } = useApiHook();

  const disabledButton = React.useMemo(() => {
    return !romooc;
  }, [romooc]);

  const handleClose = () => {
    setRomooc('');
    onClose();
  };

  const getRomoos = (Code) => {
    setLoading?.(true);
    return ApiServices.getListRoomocByStatus(
      Code && {
        Columns: { Code },
      }
    )
      .then((result) => {
        setRmoocs(result.data.data);
      })
      .catch((err) => console.log('Get Romooc Error', err))
      .finally(() => setLoading?.(false));
  };

  useEffect(() => {
    job?.id && getRomoos();
  }, [job?.id]);

  const onSubmit = async () => {
    try {
      const res = await ApiServices.changeRomooc(job?.contId, `"${romooc}"`);
      if (res) {
        handleClose();
        reloadLiftOff();
        reloadLiftOn();
        reloadParkingCont();
        reloadWaitingOfDelivery();
        reload && reload();
        message.success('Thay đổi thành công');
      } else {
        message.error('Thay đổi thất bại, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thay đổi thất bại, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };

  return (
    <>
      <Modal
        title='Thay đổi Romooc'
        width={500}
        style={{
          top: 20,
          maxHeight: 'calc(100vh - 500px)',
        }}
        open={isOpen}
        onOk={onSubmit}
        onCancel={handleClose}
        okButtonProps={{
          disabled: disabledButton,
        }}
      >
        <Wrapper>
          <Typography>
            Romooc hiện tại: <RomoocCode>{job?.romoocCode?.split('-')[0]}</RomoocCode>
          </Typography>
          <Typography>
            Chọn Romooc thay thế: <RequiredTitle>*</RequiredTitle>
          </Typography>
          <Selection
            data={romoocs}
            onSearch={getRomoos}
            fieldValue='code'
            placeholder='Chọn Rmooc'
            onChange={(value) => setRomooc(value)}
            value={romooc}
          />
          {!romooc && <Error>Vui lòng chọn Romooc</Error>}
        </Wrapper>
      </Modal>
    </>
  );
};
const Wrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Error = styled.div`
  color: red;
  font-size: 12px;
`;

const RequiredTitle = styled.span`
  color: red;
`;

const RomoocCode = styled.span`
  color: blue;
  font-weight: bold;
  font-size: 14px;
`;
