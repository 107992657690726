import React from 'react';
import { Upload, Modal } from 'antd';

const ShowListFile = ({ setIsShowFile, listFiles, title = '' }) => {
  const [fileList, setFileList] = React.useState([]);
  React.useEffect(() => {
    const getInit = async () => {
      if (listFiles?.length == 1) {
        window.open(`${process.env.REACT_APP_API_URL}${listFiles[0].urlImage.substring(1)}`);
        setIsShowFile(false);
        return;
      }
      const data = await listFiles?.map((item, index) => ({
        uid: item.code + index,
        name: item.name,
        status: 'done',
        url: `${process.env.REACT_APP_API_URL}${item.urlImage.substring(1)}`,
      }));
      setFileList(data);
    };
    getInit();
  }, [listFiles]);
  const handleCancel = () => {
    setIsShowFile(false);
    setFileList([]);
  };

  const showUploadList = {
    showDownloadIcon: false,
    // downloadIcon: 'download ',
    showRemoveIcon: false,
    // removeIcon: <StarOutlined onClick={e => console.log(e, 'custom removeIcon event')} />,
  };
  const visible = !!fileList?.length;
  return (
    <Modal title={title} open={visible} onCancel={handleCancel} footer={null}>
      {fileList && fileList?.length > 0 && (
        <Upload
          listType='picture'
          // defaultFileList={fileList}
          fileList={fileList}
          className='upload-list-inline'
          onRemove={false}
          showUploadList={showUploadList}
          progress={true}
        />
      )}
    </Modal>
  );
};

export { ShowListFile };
