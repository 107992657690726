import React, { useState } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { ReceiveONP } from './orders/receive-onp';
import { ReceiveWMS } from './orders/receive-wms';
import { RvsONP } from './orders/rvs-onp';
import { RvsWMS } from './orders/rvs-wms';
import { CancelONP } from './orders/cancel-onp';
import { CancelWms } from './orders/cancel-wms';

import { OrderItem } from './order-item';
import { OrderRvsByTms } from '../order-rvs-by-tms';

const KEY_ORDER = {
  RECEIVE_ONP: 'RECEIVE_ONP',
  RECEIVE_WMS: 'RECEIVE_WMS',
  RVS_ONP: 'RVS_ONP',
  RVS_WMS: 'RVS_WMS',
  CANCEL_ONP: 'CANCEL_ONP',
  CANCEL_WMS: 'CANCEL_WMS',
};

export const OrderWaitingReceiveList = () => {
  const {
    countOrderWaitingReceiveONP,
    countOrderWaitingReceiveWMS,
    countOrderRevisedONP,
    countOrderRevisedWMS,
    countOrderCancelONP,
    countOrderCancelWMS,
  } = useSelector((state) => state.orderONPWMS);

  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [keyOrder, setKeyOrder] = useState('');

  const handleCloseModal = () => {
    setShowModal(false);
    setTitleModal('');
    setKeyOrder('');
  };

  const handleActionOrder = ({ title, key }) => {
    setTitleModal(title);
    setKeyOrder(key);
    setShowModal(true);
  };

  const renderOrders = (key) => {
    switch (key) {
      case KEY_ORDER.RECEIVE_ONP:
        return <ReceiveONP onClose={handleCloseModal} />;
      case KEY_ORDER.RECEIVE_WMS:
        return <ReceiveWMS onClose={handleCloseModal} />;
      case KEY_ORDER.RVS_ONP:
        return <RvsONP onClose={handleCloseModal} />;
      case KEY_ORDER.RVS_WMS:
        return <RvsWMS onClose={handleCloseModal} />;
      case KEY_ORDER.CANCEL_ONP:
        return <CancelONP onClose={handleCloseModal} />;
      case KEY_ORDER.CANCEL_WMS:
        return <CancelWms onClose={handleCloseModal} />;
      default:
        break;
    }
  };

  return (
    <ContainerWrapper>
      <Container>
        <Content>
          <OrderRvsByTms />
        </Content>
        <Content>
          {countOrderWaitingReceiveONP > 0 && (
            <OrderItem
              title='Chờ nhận từ ONP:'
              countOrder={countOrderWaitingReceiveONP}
              handleClick={() =>
                handleActionOrder({
                  title: `Danh sách Booking Chờ nhận từ ONP: ${countOrderWaitingReceiveONP}`,
                  key: KEY_ORDER.RECEIVE_ONP,
                })
              }
            />
          )}
          {countOrderWaitingReceiveWMS > 0 && (
            <OrderItem
              title={`${' '}Chờ nhận từ Kho:`}
              countOrder={countOrderWaitingReceiveWMS}
              handleClick={() =>
                handleActionOrder({
                  title: `Danh sách Booking Chờ nhận từ Kho: ${countOrderWaitingReceiveWMS}`,
                  key: KEY_ORDER.RECEIVE_WMS,
                })
              }
            />
          )}
        </Content>
        <Content>
          {countOrderRevisedONP > 0 && (
            <OrderItem
              title='RVS từ ONP:'
              countOrder={countOrderRevisedONP}
              handleClick={() =>
                handleActionOrder({
                  title: `Danh sách Booking RVS từ ONP: ${countOrderRevisedONP}`,
                  key: KEY_ORDER.RVS_ONP,
                })
              }
            />
          )}
          {countOrderRevisedWMS > 0 && (
            <OrderItem
              title='RVS từ Kho:'
              countOrder={countOrderRevisedWMS}
              handleClick={() =>
                handleActionOrder({
                  title: `Danh sách Booking RVS từ Kho: ${countOrderRevisedWMS}`,
                  key: KEY_ORDER.RVS_WMS,
                })
              }
            />
          )}
        </Content>
        <Content>
          {countOrderCancelONP > 0 && (
            <OrderItem
              title='Hủy từ ONP:'
              countOrder={countOrderCancelONP}
              handleClick={() =>
                handleActionOrder({
                  title: `Danh sách Booking Hủy từ ONP: ${countOrderCancelONP}`,
                  key: KEY_ORDER.CANCEL_ONP,
                })
              }
            />
          )}
          {countOrderCancelWMS > 0 && (
            <OrderItem
              title='Hủy từ Kho:'
              countOrder={countOrderCancelWMS}
              handleClick={() =>
                handleActionOrder({
                  title: `Danh sách Booking Hủy từ Kho: ${countOrderCancelWMS}`,
                  key: KEY_ORDER.CANCEL_WMS,
                })
              }
            />
          )}
        </Content>
      </Container>
      <Modal
        title={`${titleModal}`}
        open={showModal}
        onCancel={handleCloseModal}
        footer={null}
        style={{
          top: 20,
        }}
        width='90%'
      >
        {renderOrders(keyOrder)}
      </Modal>
    </ContainerWrapper>
  );
};

const ContainerWrapper = styled.div`
  bottom: 8px;
  left: 150px;
  position: fixed;
  z-index: 999999;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  text-align: right;
  align-items: center;
`;
const Content = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: center;
  background-color: rgba(153, 205, 50, 0.527);
`;
