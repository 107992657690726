import { Popconfirm, Button, Space } from 'antd';
import { TableTitle } from '~commons/styles';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import Utils from '~commons/utils';

export const getColumns = (onColumnSearch, handleDelete, onView) => [
  {
    title: (
      <TableTitle>
        Công ty
        {/* <Input name='childName' onChange={onColumnSearch} placeholder='Search' /> */}
      </TableTitle>
    ),
    width: 100,
    dataIndex: 'childName',
    align: 'center',
  },
  {
    title: 'Mã số thuế',
    width: 200,
    dataIndex: 'taxCodeChild',
    align: 'center',
  },

  {
    title: 'Ngày tạo',
    width: 150,
    dataIndex: 'createdOn',
    align: 'center',
    render: (val) => val && Utils.formatDateFn(val),
  },

  {
    title: 'Thao tác',
    fixed: 'right',
    align: 'center',
    width: 120,
    dataIndex: 'status',
    render: (_, record) => (
      <Space>
        <Button shape='circle' icon={<EyeOutlined />} onClick={() => onView(record)} />

        <Popconfirm
          placement={'topRight'}
          title={'Bạn có chắc chắn muốn xóa?'}
          okText={'Có'}
          cancelText={'Không'}
          onConfirm={() => handleDelete(record?.childId)}
        >
          <Button type='primary' shape='circle' icon={<DeleteOutlined />} danger />
        </Popconfirm>
      </Space>
    ),
  },
];
