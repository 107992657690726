const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 24,
  },
};
const layout = {
  labelCol: {
    span: 16,
  },
  wrapperCol: {
    span: 24,
  },
};

export { layout, tailLayout };
