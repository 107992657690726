import React, { useEffect, forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Input, Row, Col, DatePicker, Select, Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import storage from '~storage';
import { Selection } from '~components';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '~commons/constants';
import moment from 'moment';
import Validates from '~commons/validates';

const { Option } = Select;

const BookingForm = forwardRef(({ fields, onChange, onsubmit, orderDetail }, ref) => {
  const [locations, setLocations] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [shippingLines, setShippingLines] = useState([]);
  const [cargoTypes, setCargoTypes] = useState([]);
  const [ports, setPorts] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [preview, setPreview] = useState({
    visible: false,
    image: '',
    title: '',
  });

  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    submitForm() {
      form.submit();
    },
  }));

  const getCustomers = async (Query = null) => {
    try {
      const { data } = await ApiServices.getCustomer({ Query });
      setCustomers(data.data);
    } catch (err) {
      console.error('ERR - Get Customers', err);
    }
  };
  const getShippingLines = async (Query = null) => {
    try {
      const { data } = await ApiServices.getShippingLines({ Query });
      setShippingLines(data.data);
    } catch (err) {
      console.error('ERR - Get Shipping Lines', err);
    }
  };
  const getLocations = async (Query = null) => {
    try {
      const { data } = await ApiServices.getLocations({ Query });
      setLocations(data.data);
    } catch (err) {
      console.error('ERR - Get Shipping Lines', err);
    }
  };
  const getCargoTypes = async (Query = null) => {
    try {
      const { data } = await ApiServices.getCargoTypes({ Query });
      setCargoTypes(data.data);
    } catch (err) {
      console.error('ERR - Get Shipping Lines', err);
    }
  };
  const getPorts = async (Query = null) => {
    try {
      const { data } = await ApiServices.getPorts({ Query });
      setPorts(data.data);
    } catch (err) {
      console.error('ERR - Get Shipping Lines', err);
    }
  };
  const setDataInit = () => {
    if (fields) {
      const ind = fields.findIndex((item) => item.name[0] === 'orderFiles');
      if (fields[ind].value) {
        setFilePreviews(fields[ind].value.fileList);
      }
    }
  };
  const initOrderDetail = () => {
    // orderDetail
    if (!orderDetail) {
      return;
    }

    const files = orderDetail?.orderFiles?.map((item) => {
      const url = process.env.REACT_APP_API_URL + item.urlImage.substring(2);
      return {
        ...item,
        url,
        fileName: item.name + item.extension,
      };
    });
    setFileList(files);
    setFilePreviews(files);
  };
  useEffect(() => {
    initOrderDetail();
  }, [orderDetail]);
  useEffect(() => {
    getCustomers();
    getShippingLines();
    getLocations();
    getCargoTypes();
    getPorts();
    setDataInit();
  }, []);

  const onSubmitForm = () => {
    onsubmit();
  };

  const transportType = storage.staticVariables.TransportType;
  const transportTypeDiv = (
    <Select placeholder='Loại vận chuyển' showSearch>
      {Object.keys(transportType).map((item) => (
        <Option key={item} value={transportType[item]}>
          {transportType[item]}
        </Option>
      ))}
    </Select>
  );

  // Upload
  const beforeUpload = (file) => {
    setFileList([...fileList, file]);
    setFilePreviews([...filePreviews, file]);
    return false;
  };

  const handleChange = ({ fileList }) => {
    setFilePreviews(fileList);
  };

  const onRemove = (file) => {
    setFileList((curList) => {
      const index = curList?.length && curList.indexOf(file);
      const newFileList = (curList?.length && curList.slice()) || [];
      curList?.length && newFileList.splice(index, 1);
      return newFileList;
    });
  };

  const handleCancel = () => setPreview((preview) => ({ ...preview, visible: false }));

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreview({
      image: file.url || file.preview,
      visible: true,
      title: file.fileName || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <>
      <Form
        // {...layout}
        form={form}
        layout='vertical'
        name='global_state'
        onFinish={onSubmitForm}
        fields={fields}
        onFieldsChange={(_, allFields) => {
          onChange(allFields);
        }}
      >
        <Row gutter={18}>
          <Col className='gutter-row' span={12}>
            <Form.Item
              name='bookingNumber'
              label='Số Booking'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập Số Booking',
                },
              ]}
            >
              <Input placeholder='Số Booking' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={12}>
            <Form.Item
              name='customerCode'
              label='Khách hàng'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập Mã Khách hàng',
                },
              ]}
            >
              <Selection
                data={customers}
                labelDefault={orderDetail?.customerName}
                placeholder='Mã Khách hàng'
                onSearch={getCustomers}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col className='gutter-row' span={12}>
            <Form.Item name='transportType' label='Loại Vận Chuyển'>
              {transportTypeDiv}
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={12}>
            <Form.Item
              name='shippingLineCode'
              label='Hãng tàu'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Bạn phải nhập Hãng tàu',
              //   },
              // ]}
            >
              <Selection
                labelDefault={orderDetail?.shippingLineName}
                data={shippingLines}
                placeholder='Mã Hãng tàu'
                onSearch={getShippingLines}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col className='gutter-row' span={12}>
            <Form.Item
              name='liftingEmptyContRequestDate'
              label='Ngày Yêu Cầu Lấy Rỗng'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Bạn phải nhập Ngày Yêu Cầu Lấy Rỗng',
              //   },
              // ]}
            >
              <DatePicker
                placeholder='Ngày Yêu Cầu Lấy Rỗng'
                min={moment()}
                style={{ width: '100%' }}
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col className='gutter-row' span={12}>
            <Form.Item
              name='putDownContRequestDateAfter'
              label='Ngày Yêu Cầu Hạ Cont Sau'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Ngày Yêu Cầu Hạ Cont Sau',
              //   },
              //   ...utils.validateDatePicker(
              //     'Ngày Yêu Cầu Lấy Rỗng',
              //     null,
              //     form.getFieldValue().liftingEmptyContRequestDate
              //   ),
              // ]}
            >
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                placeholder='Ngày Yêu Cầu Hạ Cont Sau'
                style={{ width: '100%' }}
                format={DATE_TIME_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={12}>
            <Form.Item
              name='putDownContRequestDateBefore'
              label='Ngày Yêu Cầu Hạ Cont Trước'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Ngày Yêu Cầu Hạ Cont Trước',
              //   },
              //   ...utils.validateDatePicker(
              //     'Ngày Yêu Cầu Hạ Cont Sau',
              //     null,
              //     form.getFieldValue().putDownContRequestDateAfter
              //   ),
              // ]}
            >
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                placeholder='Ngày Yêu Cầu Hạ Cont Trước'
                style={{ width: '100%' }}
                format={DATE_TIME_FORMAT}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col className='gutter-row' span={12}>
            <Form.Item
              name='siDateTime'
              label='Ngày Giờ Cắt SI'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập Ngày Giờ Cắt SI',
                },
                // ...utils.validateDatePicker(
                //   'Ngày Yêu Cầu Hạ Cont Trước',
                //   null,
                //   form.getFieldValue().putDownContRequestDateBefore
                // ),
              ]}
            >
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                style={{ width: '100%' }}
                placeholder='Ngày Giờ Cắt SI'
                format={DATE_TIME_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={12}>
            <Form.Item
              name='cutOffDateTime'
              label='Ngày Giờ Cắt Máng'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập Ngày Giờ Cắt Máng',
                },
                ...Validates.validateDatePicker(
                  'Ngày Giờ Cắt SI',
                  null,
                  form.getFieldValue().siDateTime
                ),
              ]}
            >
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                style={{ width: '100%' }}
                placeholder='Ngày Giờ Cắt Máng'
                format={DATE_TIME_FORMAT}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col className='gutter-row' span={12}>
            <Form.Item
              name='ata'
              label='Ngày Tàu Chạy'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Bạn phải nhập Ngày Tàu Chạy',
              //   },
              //   ...utils.validateDatePicker(
              //     'Ngày Yêu Cắt Máng',
              //     null,
              //     form.getFieldValue().cutOffDateTime
              //   ),
              // ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                placeholder='Ngày Tàu Chạy'
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={12}>
            <Form.Item name='eta' label='Ngày Dự Kiến Tàu Chạy'>
              <DatePicker
                placeholder='Ngày Dự Kiến Tàu Chạy'
                style={{ width: '100%' }}
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col className='gutter-row' span={12}>
            <Form.Item
              name='vesselAndVoyage'
              label='Tên Chuyến Tàu'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Bạn phải nhập Tên Chuyến Tàu',
              //   },
              // ]}
            >
              <Input placeholder='Tên Chuyến Tàu' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={12}>
            <Form.Item
              name='portOfDischargeCode'
              label='Cảng Đến'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Bạn phải nhập Cảng Đến',
              //   },
              // ]}
            >
              <Selection
                data={ports}
                labelDefault={orderDetail?.book_PortOfDischargeName}
                placeholder='Cảng Đến'
                onSearch={getPorts}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col className='gutter-row' span={12}>
            <Form.Item
              name='locationDepotFromCode'
              label='Nơi Lấy Rỗng'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Bạn phải nhập Nơi Lấy Rỗng',
              //   },
              // ]}
            >
              <Selection
                data={locations}
                labelDefault={
                  orderDetail?.listCont && orderDetail?.listCont.length
                    ? orderDetail?.listCont[0]?.book_LocationDepotFromName
                    : ''
                }
                placeholder='Nơi Lấy Rỗng'
                onSearch={getLocations}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={12}>
            <Form.Item
              name='cargoTypeCode'
              label='Loại Hàng Hóa'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Bạn phải nhập Loại Hàng Hóa',
              //   },
              // ]}
            >
              <Selection
                data={cargoTypes}
                placeholder='Loại Hàng Hóa'
                fieldValue='name'
                onSearch={getCargoTypes}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col className='gutter-row' span={12}>
            <Form.Item
              name='locationOfReceiptCode'
              label='Nơi Lấy Hàng'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Bạn phải nhập Nơi Lấy Hàng',
              //   },
              // ]}
            >
              <Selection
                data={locations}
                placeholder='Nơi Lấy Hàng'
                labelDefault={
                  orderDetail?.listCont && orderDetail?.listCont.length
                    ? orderDetail?.listCont[0]?.locationOfReciptname
                    : ''
                }
                fieldValue='name'
                onSearch={getLocations}
              />
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={12}>
            <Form.Item
              name='locationOfDeliveryCode'
              label='Nơi Giao Hàng'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Bạn phải nhập Nơi Giao Hàng',
              //   },
              // ]}
            >
              <Selection
                data={locations}
                placeholder='Nơi Giao Hàng'
                fieldValue='name'
                onSearch={getLocations}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col className='gutter-row' span={12}>
            <Form.Item name='note' label='Ghi chú'>
              <Input.TextArea placeholder='Ghi chú' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={12}>
            <Form.Item
              name='orderFiles' //orderFiles
              label='File Đính Kèm'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải chọn file đính kèm',
                },
              ]}
            >
              <Upload
                multiple
                listType='picture-card'
                onChange={handleChange}
                beforeUpload={beforeUpload}
                fileList={filePreviews}
                onRemove={onRemove}
                onPreview={handlePreview}
              >
                <div>
                  <PlusOutlined style={{ color: 'blue', fontSize: '30px' }} />
                  <div style={{ marginTop: '10px' }}>Đính Kèm</div>
                </div>
              </Upload>
            </Form.Item>
            <Modal
              open={preview.visible}
              title={preview.title}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt='Preview of upload' style={{ width: '100%' }} src={preview.image} />
            </Modal>
          </Col>
        </Row>
      </Form>
    </>
  );
});

export { BookingForm };
