import React from 'react';

const DisplayInfo = ({
  title = '',
  value = '',
  titleColor = 'black',
  valueColor = 'blue',
  renderArr = false,
  valueArr = [],
}) => {
  const formatDisplayArr = (dataArr) => {
    return dataArr?.map((item, index) => (
      <ul key={index}>
        <li>{item}</li>
      </ul>
    ));
  };

  return (
    <div>
      <b
        style={{
          color: titleColor,
        }}
      >
        {title}:
      </b>
      <p
        style={{
          color: valueColor,
        }}
      >
        {renderArr ? formatDisplayArr(valueArr) : value}
      </p>
    </div>
  );
};

export { DisplayInfo };
