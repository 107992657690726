import React, { useEffect, useState } from 'react';
import { Divider, Input, Button, Row, Col } from 'antd';
import ApiServices from '~services';
import { DisplayInfo } from '~components/display-info';
const { Search } = Input;

const AddPartnerForm = ({ reloadSubmit, count }) => {
  const [valueInput, setValueInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [foundCompany, setFoundCompany] = useState(null);

  useEffect(() => {
    console.log({ count });
    setValueInput('');
    setFoundCompany(null);
  }, [count]);

  const handleChange = (event) => {
    setValueInput(event.target.value || '');
    setFoundCompany(null);
  };
  const onSearch = async (taxCode) => {
    if (loading || !taxCode) return;

    setLoading(true);
    try {
      const res = await ApiServices.getCompanyByTaxCode(taxCode);
      console.log({ res });
      if (res?.data?.data) {
        setFoundCompany(res?.data?.data);
      }
    } catch (error) {
      console.log('Found Error:::', error);
    } finally {
      setLoading(false);
    }
  };

  const onsubmit = async (partnerId) => {
    const currentCompany = JSON.parse(
      localStorage.getItem('user_data_decode_token_tms')
    )?.CompanyId;
    if (!partnerId || !currentCompany) return;

    setLoading(true);
    try {
      const res = await ApiServices.createConnectPartner(currentCompany, partnerId);
      if (res?.data?.data === 'success') {
        window.TmsNoti?.success('Thêm mới thành công');
        reloadSubmit();
      }
    } catch (error) {
      console.log('Create connect partner Error:::', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div>
        <b>Vui lòng nhập mã số thuế</b>
        <Search
          placeholder='Vui lòng nhập mã số thuế'
          enterButton='Tìm kiếm'
          size='large'
          allowClear
          value={valueInput}
          onChange={handleChange}
          onSearch={onSearch}
        />
      </div>

      <Divider>Thông tin tiềm kiếm</Divider>
      {foundCompany ? (
        <Row gutter={16}>
          <Col span={12}>
            <DisplayInfo title='Mã Công ty' value={foundCompany?.code} />
          </Col>
          <Col span={12}>
            <DisplayInfo title='Tên viết tắt' value={foundCompany?.title} />
          </Col>
          <Col span={12}>
            <DisplayInfo title='Tên Công ty' value={foundCompany?.name} />
          </Col>
          <Col span={12}>
            <DisplayInfo title='Mã số thuế' value={foundCompany?.taxCode} />
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <div
        style={{
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          marginTop: '30px',
        }}
      >
        <Button disabled={!foundCompany} type='primary' onClick={() => onsubmit(foundCompany?.id)}>
          Tạo Đối Tác
        </Button>
      </div>
    </div>
  );
};

export { AddPartnerForm };
