import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Modal } from 'antd';
import moment from 'moment';
import { debounce } from 'lodash';
import { Header } from './header';
import { CarList } from './car-list';
import { JobListOfCar } from './job-list-of-car';
import { Information } from './information';
import { Expenses } from './expenses';
import { Tickets } from './ticket-list';
import { FormCreate } from './form-create';
import ApiServices from '~services';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
};

const DEFAULT_QUERIES_USER_ACCOUNT = {
  PageSize: 500,
  PageNumber: 1,
};

const DEFAULT_QUERIES_JOB = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().subtract(1, 'month').format(),
  DateTo: moment().endOf('date').format(),
};

const CreateTicketJob = () => {
  const [loading, setLoading] = useState(false);
  const [carList, setCarList] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [carIdSelected, setCarIdSelected] = useState('');
  const [jobListOfCar, setJobListOfCar] = useState([]);
  const [jobSelected, setJobSelected] = useState(null);
  const [expensesList, setExpensesList] = useState([]);
  const [expensesIdSelected, setExpensesIdSelected] = useState([]);
  const [expensesSelected, setExpensesSelected] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dissolveDateDefault, setDissolveDateDefault] = useState(moment());
  const [accountList, setAccountList] = useState([]);
  // search of Job List
  const [filterJob, setFilterJob] = useState(DEFAULT_QUERIES_JOB);
  const [totalRecordsJob, setTotalRecordsJob] = useState(0);

  const getUsersAccount = async () => {
    setLoading(true);
    return ApiServices.getUsers(DEFAULT_QUERIES_USER_ACCOUNT)
      .then((res) => {
        if (res?.data?.data) {
          setAccountList(res?.data?.data);
        }
      })
      .catch((err) => {
        console.error('Get Car Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCars = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };

    return ApiServices.getCars(newQuery)
      .then((res) => {
        if (res?.data?.data) {
          const mapCars = res?.data?.data?.map((item) => {
            item.key = item?.id;
            return item;
          });
          setCarList(mapCars || []);
          setTotalRecords(res?.data?.totalRecords);
        }
      })
      .catch((err) => {
        console.error('Get Car Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getJobTicketByCarId = async (cardId, query = DEFAULT_QUERIES_JOB) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getJobTicketByCarId(cardId, newQuery)
      .then((res) => {
        if (res?.data?.data) {
          const mapJobs = res?.data?.data?.map((item) => {
            item.key = item?.jobId;
            return item;
          });
          setJobListOfCar(mapJobs);
          setTotalRecordsJob(res?.data?.totalRecords);
        }
      })
      .catch((err) => {
        console.error('Get Job by CarId: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  ///////////
  const getJobTicketByContId = async (contId) => {
    setLoading(true);
    return ApiServices.getJobTicketByContId(contId)
      .then((res) => {
        if (res?.data?.data) {
          const mapExpenses = res?.data?.data?.expenses?.map((item) => {
            item.key = item.id;
            item.isExpenses = item?.objTicketExpenses ? true : false;
            item.billRequired = item?.billRequired ? true : false;
            item.price = item?.objTicketExpenses ? item?.objTicketExpenses?.price : '';
            item.vat = item?.objTicketExpenses ? item?.objTicketExpenses?.vat : '';
            item.total = item?.objTicketExpenses
              ? item?.objTicketExpenses?.total
              : item?.fee
              ? item?.fee
              : '';
            item.billNumber = item?.objTicketExpenses ? item?.objTicketExpenses?.billNumber : '';
            item.billDate = item?.objTicketExpenses ? item?.objTicketExpenses?.billDate : null;
            item.supplierName = item?.objTicketExpenses
              ? item?.objTicketExpenses?.supplierName
              : item?.supplier
              ? item?.supplier
              : '';
            return item;
          });
          setExpensesList(mapExpenses || []);
          setTicketList(res?.data?.data?.tickets || []);
        }
      })
      .catch((err) => {
        console.error('Get Bill by ContId: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };
  const onColumnSearchJob = (event, select = '') => {
    setFilterJob((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  useEffect(() => {
    getUsersAccount();
  }, []);

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getCars(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const timeoutGetDataJob = useCallback(
    debounce((carId, newFilter) => {
      getJobTicketByCarId(carId, newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    if (carIdSelected) {
      timeoutGetDataJob(carIdSelected, filterJob);
    }
  }, [carIdSelected, filterJob]);

  useEffect(() => {
    if (jobSelected?.contId) {
      getJobTicketByContId(jobSelected?.contId);
    }
  }, [jobSelected?.contId]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setExpensesIdSelected([]);
    setExpensesSelected([]);
    setIsModalOpen(false);
  };
  const reload = () => {
    if (carIdSelected) {
      timeoutGetDataJob(carIdSelected, filterJob);
    } else {
      setJobListOfCar([]);
    }
    setExpensesList([]);
    setTicketList([]);
    setExpensesIdSelected([]);
    setExpensesSelected([]);
    setIsModalOpen(false);
  };

  const handleChangeCarSelected = (value) => {
    if (carIdSelected && carIdSelected !== value) {
      setExpensesList([]);
      setTicketList([]);
      setJobSelected([]);
      setExpensesIdSelected([]);
      setExpensesSelected([]);
    }
    setCarIdSelected(value);
  };

  const clearDataWhenSearch = () => {
    setCarIdSelected(null);
    setSelectedCar(null);
    setJobSelected([]);
    setJobListOfCar([]);
    setExpensesList([]);
    setTicketList([]);
    setExpensesIdSelected([]);
    setExpensesSelected([]);
  };

  const pagination = {
    total: totalRecords || 0,
    showSizeChanger: false,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
      })),
  };
  const paginationJobList = {
    total: totalRecordsJob || 0,
    showSizeChanger: false,
    pageSize: filterJob.PageSize,
    current: filterJob.PageNumber,
    onChange: (PageNumber) =>
      setFilterJob((prev) => ({
        ...prev,
        PageNumber,
      })),
  };

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Header
          expensesIdSelected={expensesIdSelected}
          showModal={showModal}
          filter={filterJob}
          setFilter={setFilterJob}
          dissolveDateDefault={dissolveDateDefault}
          setDissolveDateDefault={setDissolveDateDefault}
          clearDataWhenSearch={clearDataWhenSearch}
        />
      </div>
      <div
        style={{
          marginBottom: '10px',
          height: '620px',
        }}
      >
        <Row>
          <Col flex='320px'>
            <div style={{ width: '320px' }}>
              <CarList
                dataCars={carList}
                setSelectedCar={setSelectedCar}
                setCarIdSelected={handleChangeCarSelected}
                onColumnSearch={onColumnSearch}
                pagination={pagination}
              />
            </div>
            <div style={{ width: '320px' }}>
              <JobListOfCar
                dataJobs={jobListOfCar}
                setJobSelected={setJobSelected}
                onColumnSearchJob={onColumnSearchJob}
                paginationJobList={paginationJobList}
              />
            </div>
          </Col>
          <Col flex='auto'>
            <div
              style={{
                maxWidth: 'calc(100vw - 600px)',
                marginLeft: '10px',
              }}
            >
              <Expenses
                dataExpenses={expensesList}
                expensesIdSelected={expensesIdSelected}
                setExpensesIdSelected={setExpensesIdSelected}
                setExpensesSelected={setExpensesSelected}
                loadingData={loading}
              />
            </div>
            <div
              style={{
                fontSize: '12px',
                marginLeft: '10px',
                marginTop: '10px',
                borderRadius: '6px',
                border: '1px solid blue',
                padding: '10px',
                maxWidth: 'calc(100vw - 600px)',
              }}
            >
              <Information dataInfo={jobSelected} accountList={accountList} />
            </div>
            <div
              style={{
                maxWidth: 'calc(100vw - 600px)',
                marginLeft: '10px',
                marginTop: '10px',
              }}
            >
              <Tickets ticketList={ticketList} />
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        title='Thêm phiếu giải trừ vận tải'
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        style={{
          top: 20,
        }}
        width='80%'
      >
        <FormCreate
          dataCar={selectedCar}
          dataInfo={jobSelected}
          expensesSelected={expensesSelected}
          reload={reload}
          dissolveDateDefault={dissolveDateDefault}
        />
      </Modal>
    </div>
  );
};

export { CreateTicketJob };
