import React, { useEffect, forwardRef, useImperativeHandle, useState, createElement } from 'react';
import { Form, Input, message, Button, InputNumber } from 'antd';
import ApiServices from '~services';
import { Selection } from '~components';
import { SimpleSelection } from '~components/selection';
import * as antdIcons from '@ant-design/icons';

const FIRST_LEVEL = 0;
const DEFAULt_VALUES = {
  level: FIRST_LEVEL,
  keyApp: 'TmsWeb',
};
export const FormInput = forwardRef(({ dataInput, reload, onClose, parents }, ref) => {
  const [icons, setIcons] = useState(Object.keys(antdIcons));
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    submitForm() {
      form.submit();
    },
  }));

  useEffect(() => {
    form.resetFields();
    if (!dataInput?.id) {
      return form.setFieldsValue(DEFAULt_VALUES);
    }
    form.setFieldsValue(dataInput);
  }, [dataInput?.id]);

  const onSubmitForm = (value) => {
    if (!dataInput?.id) {
      return ApiServices.createMenu(value)
        .then(() => {
          reload?.();
          onClose?.();
          message.success('Thao tác thành công!!!');
        })
        .catch((err) => {
          console.error('Create Menu', err);
          message.error('Thao tác thất bại');
        });
    }
    return ApiServices.updateMenu(dataInput.id, { ...value, id: dataInput.id })
      .then(() => {
        reload?.();
        onClose?.();
        message.success('Thao tác thành công!!!');
      })
      .catch((err) => {
        console.error('update Menu', err);
        message.error('Thao tác thất bại');
      });
  };
  const getLabelIcon = (val) => {
    return (
      antdIcons[val]?.render && (
        <>
          {val} - {createElement(antdIcons[val])}
        </>
      )
    );
  };
  const onSearchIcon = (text) => {
    if (!text) {
      return setIcons(Object.keys(antdIcons));
    }
    return setIcons(Object.keys(antdIcons).filter((icon) => icon.includes(text)));
  };

  return (
    <>
      <Form
        labelCol={{
          span: 6,
        }}
        form={form}
        initialValues={DEFAULt_VALUES}
        layout='horizontal'
        name='global_state'
        className='form-job-handle'
        onFinish={onSubmitForm}
      >
        <Form.Item name='keyApp' label='keyApp'>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name='name_vi_VN'
          label='Tên'
          rules={[
            {
              required: true,
              message: 'Bạn phải nhập Tên',
            },
          ]}
        >
          <Input placeholder='Tên' />
        </Form.Item>
        {dataInput?.level !== FIRST_LEVEL && (
          <Form.Item name='parent' label='Menu cha'>
            <Selection placeholder='Chọn menu' data={parents} fieldValue='name_vi_VN' />
          </Form.Item>
        )}
        <Form.Item name='url' label='URL'>
          <Input placeholder='URL' />
        </Form.Item>
        <Form.Item
          name='level'
          label='Cấp độ'
          rules={[
            {
              required: true,
              message: 'Bạn phải nhập Cấp độ',
            },
          ]}
        >
          <InputNumber placeholder='Cấp độ' defaultValue={0} />
        </Form.Item>
        <Form.Item name='sort' label='Số Thứ tự'>
          <InputNumber placeholder='Số thứ tự' />
        </Form.Item>
        {!dataInput?.level && (
          <Form.Item name='icon' label='Icon'>
            <SimpleSelection
              placeholder='Icon'
              arrData={icons}
              getLabel={getLabelIcon}
              onSearch={onSearchIcon}
            />
          </Form.Item>
        )}

        <Form.Item label='Thao tác'>
          <Button type='primary' htmlType='submit'>
            Hoàn Thành
          </Button>
        </Form.Item>
      </Form>
    </>
  );
});
