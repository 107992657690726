export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_FORMAT_FOR_COPY = 'DD/MM/YYYY';
export const SHORTEN_DATE_FORMAT = 'DD/MM/YYYY';
export const SHORTEN_DATE_FORMAT_CUSTOM = 'DD/MM/YYYY';
export const SHORTEN_DATE_FORMAT_CUSTOM_2 = 'DD/MM/YY';
export const SHORTEN_DATE_FORMAT_WB = 'DD-MM';
export const DATE_FORMAT_2 = 'DD-MM-YYYY';
export const SHORTEN_DATE_TIME_FORMAT = 'DD-MM  HH:mm';
export const SHORTEN_DATE_TIME_FORMAT_WB = 'DD-MM  HH:mm';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_SEND_API = 'YYYY-MM-DD';

export const PERMISSION_CODES = {
  CAR_VIEW_ALL: 'cars-view-all',
  ROMOOC_VIEW_ALL: 'romoocs-view-all',
  ROMOOC_VIEW_ALL: 'romoocs-view-all',
  DRIVER_VIEW_ALL: 'drivers-view-all',
  CUSTOMER_VIEW_ALL: 'customers-view-all',
  LOCATION_VIEW_ALL: 'locations-view-all',
  LOCATION_TYPE_VIEW_ALL: 'location-types-view-all',
  SHIPPING_LINE_VIEW_ALL: 'shipping-lines-view-all',
  PORT_VIEW_ALL: 'ports-view-all',
  DISTANCE_VIEW_ALL: 'distances-view-all',
  VENDOR_VIEW_ALL: 'vendors-view-all',
  CARGO_TYPE_VIEW_ALL: 'cargo-types-view-all',
  CONT_TYPE_VIEW_ALL: 'cont-types-view-all',
  CONT_SEARCH_VIEW_ALL: 'cont-search-number-view-all',
  ORDER_VIEW_ALL: 'orders-view-all',
  BOOKING_VIEW_ALL: 'bookings-view-all',
  BILL_VIEW_ALL: 'bills-view-all',
  TRANSIT_VIEW_ALL: 'transits-view-all',
  REVISE_LOG_VIEW_ALL: 'revised-logs-view-all',
  WHITEBOARD_VIEW_ALL: 'whiteboard-view-all',
  WHITE_BOARD_VIEW_ALL: 'white-board-view-all',
  TASK_JOB_DIARY_VIEW_ALL: 'task-job-diary-view-all',
  DEPORT_CONT_VIEW_ALL: 'depot-conts-handle-view-all',
  BOOKING_CONT_VIEW_ALL: 'booking-conts-view-all',
  BILL_CONT_VIEW_ALL: 'bill-conts-view-all',
  JOB_HANDLE_VIEW_ALL: 'job-handle-view-all',
  ASSIGN_VIEW_ALL: 'assign-car-view-all',
  PRINT_OIL_VIEW_ALL: 'print-oil-view-all',
  UNIT_VIEW_ALL: 'units-view-all',
  MATERIAL_VIEW_ALL: 'materials-view-all',
  MATERIAL_TYPE_VIEW_ALL: 'material-types-view-all',
  GARA_VIEW_ALL: 'garas-view-all',
  SUPPLIER_MATERIAL_VIEW_ALL: 'supplier-materials-view-all',
  REPLACEMENT_CATEGORY_VIEW_ALL: 'replacement-categories-view-all',
  AREA_VIEW_ALL: 'areas-view-all',
  TIRE_VIEW_ALL: 'tires-view-all',
  REPORT_BY_CUSTOMER_VIEW_ALL: 'report-by-customer-view-all',
  REPORT_BY_CUSTOMER_1_VIEW_ALL: 'report-by-customer-1-view-all',
  REPORT_BY_CUSTOMER_2_VIEW_ALL: 'report-by-customer-2-view-all',
  REPORT_BY_SHIPPING_LINE_VIEW_ALL: 'report-by-shipping-view-all',
  REPORT_BY_JOB_DETAIL_VIEW_ALL: 'report-by-job-detail-view-all',
  REPORT_BY_FUEL_VIEW_ALL: 'report-by-fuel-view-all',
  REPORT_TOUR_BY_MONTH_VIEW_ALL: 'report-tour-by-month-view-all',
  REPORT_TOUR_BY_DATE_VIEW_ALL: 'report-tour-by-date-view-all',
  SETTING_VIEW_ALL: 'settings-view-all',
  MENU_VIEW_ALL: 'menus-view-all',
  GROUP_VIEW_ALL: 'groups-view-all',
  FUNCTION_VIEW_ALL: 'functions-view-all',
  COMPANY_VIEW_ALL: 'companies-view-all',
  HEAD_COMPANY_VIEW_ALL: 'head-companies-view-all',
  USER_MANAGEMENT_VIEW_ALL: 'users-management-view-all',
  ORDERS_CANCEL_VIEW_ALL: 'orders-cancel-view-all',
  TASKJOB_EXTRAS_VIEW_ALL: 'taskjob-extras-view-all',
  ORDERS_WAITING_RECEIVE_FROM_ONP_VIEW_ALL: 'orders-waiting-receive-from-onp-view-all',
  ORDERS_WAITING_RECEIVE_FROM_WMS_VIEW_ALL: 'orders-waiting-receive-from-WMS-view-all',
  ORDERS_RVS_FROM_ONP_VIEW_ALL: 'orders-rvs-from-ONP-view-all',
  ORDERS_RVS_FROM_WMS_VIEW_ALL: 'orders-rvs-from-WMS-view-all',
  ORDERS_CANCEL_FROM_ONP_VIEW_ALL: 'orders-cancel-from-ONP-view-all',
  ORDERS_CANCEL_FROM_WMS_VIEW_ALL: 'orders-cancel-from-WMS-view-all',
  OIL_PRICES_VIEW_ALL: 'oil-prices-view-all',
  REPORT_OIL_SUMMARY_VIEW_ALL: 'report-oil-summary-view-all',
  REPORT_OIL_FUEL_DISTRIBUTION_VIEW_ALL: 'report-oil-fuel-distribution-view-all',
  REPORT_OIL_KM_VIEW_ALL: 'report-oil-km-view-all',
  VATS_MANAGEMENT_VIEW_ALL: 'vats-management-view-all',
  EXPENSES_MANAGEMENT_VIEW_ALL: 'expenses-management-view-all',
  REPORT_OIL_FUEL_DISTRIBUTION_2_VIEW_ALL: 'report-oil-fuel-distribution-2-view-all',
  TASK_JOBS_GET_LIST_BY_PARTNER_VIEW_ALL: 'task-jobs-get-list-by-partner-view-all',
  TASK_JOBS_RECEIVED_FROM_PARTNER_VIEW_ALL: 'task-job-received-from-partner-view-all',
  CREATE_TICKET_JOB_VIEW_ALL: 'create-ticket-job-view-all',
  CREATE_TICKET_JOB_FOR_VENDOR_VIEW_ALL: 'create-ticket-job-for-vendor-view-all',
  CREATE_TICKET_JOB_BY_VENDOR_VIEW_ALL: 'create-ticket-job-by-vendor-view-all',
  UPDATE_TICKET_JOB_VIEW_ALL: 'update-ticket-job-view-all',
  UPDATE_TICKET_JOB_FOR_UI_VIEW_ALL: 'update-ticket-job-for-UI-view-all',
  UPDATE_TICKET_JOB_VENDOR_VIEW_ALL: 'update-ticket-job-vendor-view-all',
  CREATE_TICKET_BILL_VIEW_ALL: 'create-ticket-bill-view-all',
  UPDATE_TICKET_BILL_VIEW_ALL: 'update-ticket-bill-view-all',
  CREATE_TICKET_BOOKING_VIEW_ALL: 'create-ticket-booking-view-all',
  UPDATE_TICKET_BOOKING_VIEW_ALL: 'update-ticket-booking-view-all',
  DISSOLVE_LOCKS_VIEW_ALL: 'dissolve-locks-view-all',
  SUPPLIER_BILLS_VIEW_ALL: 'supplier-bills-view-all',
  MANAGEMENT_COSTS_VIEW_ALL: 'management-costs-view-all',
  MANAGEMENT_SALARY_VIEW_ALL: 'management-salary-view-all',
  CONTRACT_SERVICE_VIEW_ALL: 'contracts-service-view-all',
  CONTRACT_SERVICE_FOR_VENDOR_VIEW_ALL: 'contracts-service-for-vendor-view-all',
  GOODS_MATERIAL_OUTBOUND_NOTES_VIEW_ALL: 'goods-material-notes-outbound-view-all',
  GOODS_MATERIAL_NOTES_INBOUND_VIEW_ALL: 'goods-material-notes-inbound-view-all',
  GOODS_MATERIAL_NOTES_WAREHOUSE_VIEW_ALL: 'goods-material-notes-warehouse-view-all',
  ACCOUNTANTS_CUSTOMER_EXPENSE_VIEW_ALL: 'accountants_customerexpense_view_all',
  ACCOUNTANTS_CUSTOMER_EXPENSE_BILL_VIEW_ALL: 'accountants-customer-expense-bill-view-all',
  ACCOUNTANTS_CUSTOMER_EXPENSE_BILL_FOR_UI_VIEW_ALL:
    'accountants-customer-expense-bill-for-UI-view-all',
  ACCOUNTANTS_CUSTOMER_EXPENSE_BILL_FOR_VENDOR_VIEW_ALL:
    'accountants-customer-expense-bill-for-vendor-view-all',
  ACCOUNTANTS_GOOD_EXPENSE_CAR_VIEW_ALL: 'accountants-good-expense-car-view-all',
  CAR_REPLACEMENTS_HISTORY_VIEW_ALL: 'car-replacements-history-view-all',
  ACCOUNTANTS_BOOKING_CONT_UNPAID_VIEW_ALL: 'accountants-bookingcontunpaid-view-all',
  REPORT_ACCOUNTANTS_BILLS_VIEW_ALL: 'report-accountants-bills-view-all',
  ACCOUNTANTS_GOOD_EXPENSE_VIEW_ALL: 'accountants-good-expense-view-all',
  ACCOUNTANTS_GOOD_EXPENSE_DRIVER_VIEW_ALL: 'accountants-goods-expense-driver-view-all',
  ACCOUNTANTS_PARTNER_EXPENSE_VIEW_ALL: 'accountants-partner-expense-view-all',
  GOODS_MATERIAL_NOTES_OUTBOUND_HISTORY_VIEW_ALL: 'goods-material-notes-outbound-history-view-all',
  ACCOUNTANTS_TRANSIT_EXPENSE_VIEW_ALL: 'accountants-transit-expense-view-all',
  CONTS_DIFFERENCE_DEPOT_VIEW_ALL: 'conts-difference-depot-view-all',
  TICKETS_IMPORT_BOOKING_BILL_VIEW_ALL: 'ticket-import-booking-bill-view-all',
  REPORT_DETAIL_TOUR_VIEW_ALL: 'report-detail-tour-view-all',
  TASK_JOB_DIARY_2_VIEW_ALL: 'task-job-diary-2-view-all',
  TRANSIT_CONT_PAGE_VIEW_ALL: 'transit-cont-page-view-all',
  LOCATION_CONFIGS_VIEW_ALL: 'location-configs-view-all',
  ASSIGN_TRANSIT_VIEW_ALL: 'assign-transit-view-all',
  ASSIGN_TRANSIT_PARTNER_VIEW_ALL: 'assign-transit-partner-view-all',
  TASK_JOB_TRANSIT_RECEIVED_FROM_PARTNER_VIEW_ALL:
    'task-job-transit-received-from-partner-view-all',
  REPORT_TRANSIT_CONT_VIEW_ALL: 'report-transit-cont-view-all',
  APP_USER_ACCOUNT_VIEW_ALL: 'app-user-account-view-all',
  JOB_SKIP_TAKE_PHOTO_VIEW_ALL: 'job-skip-take-photo-view-all',
  LIFTING_FEES_VIEW_ALL: 'lifting-fees-view-all',
  BOOKING_CONT_TRACKING_VIEW_ALL: 'booking-cont-tracking-view-all',
  BILL_CONT_TRACKING_VIEW_ALL: 'bill-cont-tracking-view-all',
  OIL_COUPONS_OIL_INPUT_VIEW_ALL: 'oil-coupons-oil-input-view-all',
  OIL_COUPONS_OIL_TOTAL_VIEW_ALL: 'oil-coupons-oil-total-view-all',
  OIL_COUPONS_OIL_CHECK_VIEW_ALL: 'oil-coupons-oil-check-view-all',
  BOOKING_CONT_PARTNER_LIST_VIEW_ALL: 'booking-cont-partner-list-view-all',
  BILL_CONT_PARTNER_LIST_VIEW_ALL: 'bill-cont-partner-list-view-all',
};
export const NOTIFICATION_TYPES = {
  DRIVER_ACCEPT: 'Driver_Accept',
  DRIVER_REFUSE: 'Driver_Refuse',
  JOB_COMPLETE: 'Job_Complete',
};
export const TOUR_KINDS = {
  LIFT_ON: 'A',
  LIFT_OFF: 'B',
  TRANSIT: 'C',
};
export const NOTIFICATION_STATUSES = {
  READ: 1,
  UNREAD: 0,
};
export const PARKING_CONT_WIDTH = '500px';
export const JOB_HANDLE_WIDTH = 800;
export const CONT_ORDER_TYPES = {
  BOOKING: 'Booking',
  BILL: 'Bill',
  TRANSIT: 'Transit',
  SUPPORT: 'Support',
};
export const CONT_DEPOT_TYPES = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: CONT_ORDER_TYPES.BOOKING,
    label: 'booking',
  },
  {
    value: CONT_ORDER_TYPES.BILL,
    label: 'bill',
  },
];
export const TOUR_STATUSES = {
  WAITING: 0,
  SENDED_DRIVER: 1,
  DRIVER_RECEIVED: 2,
};
export const ORDER_STATUS = {
  INITIAL: 0,
  PLANED: 1,
  REVISED: 2,
  DOING: 3,
  FINISH: 9,
  CANCELED: -10,
};
export const TASK_STATUSES = {
  PREPARE: -1, //
  PENDING: 1,
  START: 3,
  SENT_PARTNER: 4,
  FINISH: 9,
  SENDED_DRIVER: 0,
  REPORTED_THE_PROBLEM: -3,
  CANCELED: -10,
};
export const CONT_STATUSES = {
  INITIAL: 0, //Khởi Tạo
  PLANED: 1, //Kế Hoạch
  PENDING: 2, //Đang Xử Lý || revised
  DOING: 3, // Đang Vận Chuyển
  PARTNER_TRANSFERRED: 4, //Đã chuyển ĐT
  REPLAN: -1, //Kế Hoạch Lại (Đơn KN)
  REDOING: 5, //Vận Chuyển Lại (Đơn KN)
  FINISH: 9, //Hoàn Thành
  CANCELED: -10, //Hủy
};
export const LIFT_ON_CONT_STATUSES = {
  PLANED: 0,
  PROCESSED: 1,
};
export const LIFT_OFF_CONT_STATUSES = {
  NOTICED: 0,
  PROCESSED: 1,
};
export const TASK_SORT_STATUSES = {
  LIFT_ON_START: 1, // lấy hàng nhập (bill) | lấy rỗng xuất (book)
  LIFT_ON_END: 2, // giao hàng nhập (bill) | giao rỗng xuất (book)
  LIFT_OFF_START: 3, // lấy rỗng nhập (bill) | lấy hàng xuất (book)
  LIFT_OFF_END: 4, // giao rỗng nhập (bill) | giao hàng xuất (book)
};
export const TASK_SORT_SUPPORT_STATUSES = {
  GET_ROMOOC: 1, //lấy romooc
  RETURN_PARKING: 5, //lấy romooc
  CHECK_ROMOOC: 7, //đăng kiểm romooc
  CHECK_CAR: 8, //đăng kiểm xe
  GET_ORDER: 9, //lấy lệnh

  REVERSE: 10, //di dời cont
};
export const CONT_OPTIONS_STATUSES = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: CONT_STATUSES.INITIAL,
    label: 'Khởi Tạo',
  },
  // {
  //   value: CONT_STATUSES.PLANED,
  //   label: 'Kế Hoạch',
  // },
  {
    value: CONT_STATUSES.PENDING,
    label: 'Đang Xử Lý',
  },
  // {
  //   value: CONT_STATUSES.DOING,
  //   label: 'Vận Chuyển',
  // },
  {
    value: CONT_STATUSES.PARTNER_TRANSFERRED,
    label: 'Đã Chuyển ĐT',
  },
  {
    value: CONT_STATUSES.FINISH,
    label: 'Hoàn Thành',
  },
];

export const CONT_DEPOT_STATUSES = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: 0,
    label: 'Khởi Tạo',
  },
  {
    value: 1,
    label: 'Kế Hoạch',
  },
  {
    value: 2,
    label: 'Đang Xử Lý',
  },
  // {
  //   value: 3,
  //   label: 'Vận Chuyển',
  // },
  {
    value: 4,
    label: 'Đã Chuyển ĐT',
  },
  {
    value: 9,
    label: 'Hoàn Thành',
  },
];
export const ORDER_OPTION_SELECTS = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: ORDER_STATUS.INITIAL,
    label: 'Khởi Tạo',
  },
  {
    value: ORDER_STATUS.DOING,
    label: 'Vận Chuyển',
  },
  {
    value: ORDER_STATUS.PLANED,
    label: 'Kế hoạch',
  },
  {
    value: ORDER_STATUS.REVISED,
    label: 'Đang xử lí',
  },
  {
    value: ORDER_STATUS.FINISH,
    label: 'Hoàn Thành',
  },
  {
    value: ORDER_STATUS.CANCELED,
    label: 'Đã Hủy',
  },
];

export const WB_POPUP_ASSIGN_HEIGHT = '300px';
export const WB_POPUP_ASSIGN_WIDTH = '1220px';

export const STORAGE = {
  USER_DATA: 'user_data_tms',
  IS_DARK_MODEL: 'dark_model',
  IS_COLLAPSED: 'is_collapsed',
  ACTIVE_MENU_ITEM: 'active_menu_item',
  USER_DATA_DECODE_TOKEN: 'user_data_decode_token_tms',
  TOKEN_FIREBASE: 'token_firebase_tms',
};

export const DEFAULT_QUERY_WB = {
  PageNumber: 1,
  PageSize: 150,
  Columns: {},
};

export const REPORT_COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export const DATE_RENDER = {
  SI: {
    name: 'Cat SI:',
    className: 'blue',
  },
  BX: {
    name: 'BX:',
    className: 'blue',
  },
  BR: {
    name: 'BR:',
    className: 'blue',
  },
  HHLC: {
    name: 'LC:',
    className: 'blue cut-off',
  },
  HHTR: {
    name: 'TR:',
    className: 'blue cut-off',
  },
  CM: {
    name: 'CM:',
    className: 'blue cut-off',
  },
};
export const DATE_RENDER_WB_LIFT_ON = {
  SI: {
    name: 'Cat SI:',
    className: 'red',
  },
  HHLC: {
    name: 'HHLC:',
    className: 'red',
  },
  CM: {
    name: 'CM:',
    className: 'purple-white-board',
  },
  HHTR: {
    name: 'HHTR:',
    className: 'purple-white-board',
  },
};

export const LOCATION_RENDER = {
  LR: {
    name: 'LR:',
    className: 'blue',
  },
  LH: {
    name: 'LH:',
    className: 'red',
  },
  TR: {
    name: 'TR:',
    className: 'green',
  },
  GH: {
    name: 'GH:',
    className: '',
  },
};

export const TASK_END_OPTIONS = [
  {
    value: 'main',
    label: 'Lấy Hàng Nhập',
    Bill: 'Giao Rỗng Nhập',
    Booking: 'Giao Rỗng Xuất',
  },
  {
    value: 'Anchorage',
    label: 'Lưu cont Tại Bãi',
  },
];

export const TASK_SUPPORT_SHOW_ROMOOC = ['GetRomooc', 'Reverse'];

export const TASK_SUPPORT_OPTIONS = [
  {
    value: 'GetRomooc',
    label: 'Lấy Romooc',
  },
  {
    value: 'Reverse',
    label: 'Đảo Romooc',
  },
  {
    value: 'CheckRomooc',
    label: 'Đăng kiểm Romooc',
  },
  {
    value: 'CheckCar',
    label: 'Đăng kiểm xe',
  },
  {
    value: 'GetOrder',
    label: 'Lấy Lệnh',
  },
  {
    value: 'Relocation',
    label: 'Di dời Cont',
  },
  {
    value: 'GoParking',
    label: 'Về bãi / Cắt Mooc',
  },
];

export const END_TASK_SUPPORT_OPTIONS = [
  {
    value: 'CheckCar',
    label: 'Đăng kiểm xe',
  },
  {
    value: 'GetOrder',
    label: 'Lấy Lệnh',
  },
  {
    value: 'Relocation',
    label: 'Di dời Cont',
  },
  {
    value: 'GoParking',
    label: 'Về bãi / Cắt Mooc',
  },
];

export const TASK_SUPPORT_LIfT_ON_END_OPTIONS = [
  {
    value: 'CheckRomooc',
    label: 'Đăng kiểm Romooc',
  },
  {
    value: 'CheckCar',
    label: 'Đăng kiểm xe',
  },
  {
    value: 'GetOrder',
    label: 'Lấy Lệnh',
  },
  {
    value: 'Relocation',
    label: 'Di dời Cont',
  },
  {
    value: 'Reverse',
    label: 'Đảo Romooc',
  },
  {
    value: 'GoParking',
    label: 'Về bãi / Cắt Mooc',
  },
];

export const CONTRACT_STATUS = {
  INIT: 0, // Khởi tạo
  ACCEPT: 9, // Xác nhận
  REOPEN: 2, // Mở lại
  CANCEL: 3, // Hết hạn
};
export const CONTRACT_STATUS_VAL = {
  0: 'Khởi tạo',
  9: 'Xác nhận',
  2: 'Mở lại',
  3: 'Hết hạn',
};
export const CONTRACT_STATUS_LIST = [
  {
    title: 'Khởi tạo',
    value: 0,
  },
  {
    title: 'Xác nhận',
    value: 9,
  },
  {
    title: 'Mở lại',
    value: 2,
  },
  {
    title: 'Hết hạn',
    value: 3,
  },
];
