const ExcelJS = require('exceljs');
import moment from 'moment';

export const handleExportExcel = async (
  dataExport,
  oilQuota = 0,
  oilPrice = 0,
  dateFrom,
  dateTo,
  loadingExport,
  setLoadingExport
) => {
  if (loadingExport) return;

  setLoadingExport(true);
  const workbook = new ExcelJS.Workbook();
  // Ten sheet
  const sheetSummary = workbook.addWorksheet('Sheet', {
    properties: { tabColor: { argb: 'FF00FF00' } },
    views: [{ showGridLines: false }],
  });
  sheetSummary.properties.defaultRowHeight = 15;
  sheetSummary.properties.defaultColWidth = 15;
  sheetSummary.getColumn(1).width = 5;
  sheetSummary.getRow(2).font = {
    name: 'Arial',
    family: 2,
    size: 16,
    bold: true,
  };
  sheetSummary.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
  sheetSummary.getCell('F2').value = 'BẢNG THEO DÕI CẤP PHÁT NHIÊN LIỆU 2';
  sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
  const date = `Từ ${moment(dateFrom).format('DD/MM/YYYY')} Đến ${moment(dateTo).format(
    'DD/MM/YYYY'
  )}`;
  sheetSummary.getCell('F3').value = date;
  // Add table
  console.log({ dataExport });
  const arrDetail = await dataExport?.map((item, indexDetail) => {
    let totalKmSupport = 0;

    if (item?.listTaskJobsExtra?.length > 0) {
      item?.listTaskJobsExtra?.forEach((element) => {
        totalKmSupport += element?.distance + element?.lossOfDistance;
      });
    }

    const countQuantity = ((item?.totalKm + totalKmSupport) * oilQuota) / 100;

    let sumPrice = 0;
    if (oilPrice && countQuantity) {
      sumPrice = Math.round(oilPrice * Math.round(countQuantity));
    }
    const totalKmAll = item?.totalKm + totalKmSupport;

    const dataItem = [
      indexDetail + 1, // STT
      item?.assignOn && new Date(moment(item?.assignOn).format('YYYY-MM-DD')), // Ngày chứng từ
      item?.carCode, // So Xe
      item?.driverMainName, // Lai Xe
      totalKmAll ?? 0, // So Km
      oilQuota ? oilQuota : 0, // ĐỊnh Mức (lit/100km)
      oilPrice ? oilPrice : 0, // Giá Dầu
      // item?.oilToSupply, // Số Lượng
      countQuantity ? Math.round(countQuantity) : 0,
      sumPrice ? sumPrice : 0, // Thanh Tiền
    ];
    return dataItem;
  });

  await sheetSummary.addTable({
    name: 'TableSummary',
    ref: 'B5',
    headerRow: true,
    totalsRow: true,
    style: {
      theme: 'TableStyleMedium23', // 23
      // showRowStripes: true,
    },
    columns: [
      { name: 'STT', totalsRowLabel: '', filterButton: false },
      { name: 'Ngày Điều Xe', totalsRowLabel: '', filterButton: false },
      {
        name: 'Số Xe',
        filterButton: false,
      },
      { name: 'Lái Xe', totalsRowLabel: '', filterButton: false },
      {
        name: 'Số Km',
        filterButton: false,
      },
      {
        name: 'Định Mức (lít/100km)',
        filterButton: false,
      },
      {
        name: 'Giá Dầu',
        filterButton: false,
      },
      {
        name: 'Số Lượng',
        filterButton: false,
        totalsRowFunction: 'sum',
      },
      {
        name: 'Thành Tiền',
        filterButton: false,
        totalsRowFunction: 'sum',
      },
    ],
    rows: arrDetail,
  });
  // ========== STYLES =====================
  sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };

  sheetSummary.getColumn(2).width = 5;
  sheetSummary.getColumn(3).width = 13;
  sheetSummary.getColumn(4).width = 15;
  sheetSummary.getColumn(5).width = 20;
  sheetSummary.getColumn(6).width = 10;
  sheetSummary.getColumn(7).width = 20;

  sheetSummary.getColumn(8).numFmt = '#,##0';
  sheetSummary.getColumn(8).width = 15;
  sheetSummary.getColumn(10).numFmt = '#,##0';
  sheetSummary.getColumn(10).width = 15;

  try {
    await workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `Bang_theo_doi_cap_phat_nhien_lieu_2_tu_ngay_${dateFrom}_den_ngay_${dateTo}.xlsx`; // name excel
      // anchor.download = `${moment().format('HH:mm')}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  } catch (error) {
    console.log({ error });
  } finally {
    setLoadingExport(false);
  }
};
