import React, { useEffect, useState, useCallback } from 'react';
const ExcelJS = require('exceljs');
import moment from 'moment';
import { debounce } from 'lodash';
import { DownloadOutlined } from '@ant-design/icons';
import { Space, Tabs, Button, Badge } from 'antd';
import ApiServices from '~services';
import { TableData } from './table-data';
import { RangeDateFilter, Selection, LoadingView } from '~components';
import { DATE_FORMAT_SEND_API } from '~commons/constants';
import Utils from '~commons/utils';
import {
  LIST_COLUMNS_TITLE_FIRST_SUMMARY_SHEET,
  LIST_COLUMNS_TITLE_SECOND_SUMMARY_SHEET,
  MERGE_TWO_ROWS_TITLE_SUMMARY_SHEET,
  MERGE_TWO_COLUMNS_TITLE_SUMMARY_SHEET,
  BORDER_CELL_TITLE_SUMMARY_SHEET,
  COLUMNS_BORDER_LIST_SUMMARY_SHEET,
  LIST_COLUMN_SUM_SUMMARY_SHEET,
  LIST_COLUMN_FILL_ORDER_TYPE,
} from './sheet-summary-constant';

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};

export const ReportByCustomer1 = () => {
  const [vendorSelectedId, setVendorSelectedId] = useState([]);
  const [vendorSelected, setVendorSelected] = useState([]);

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [vendorList, setVendorList] = useState([]);
  const [vendorListOrigin, setVendorListOrigin] = useState([]);

  const [dataTable, setDataTable] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);

  const getAllVendorById = async (Query = null) => {
    const companyId = JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId;
    if (!companyId) return;

    setLoading(true);
    try {
      const res = await ApiServices.getAllVendorById(companyId, { Query });
      if (res?.data?.data?.length) {
        const dataMap = res?.data?.data?.map((item, index) => {
          item.key = index + 1;
          return item;
        });
        setVendorList(dataMap || []);
        setVendorListOrigin(dataMap || []);
      }
    } catch (error) {
      console.error('get Vendors Error', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllVendorById();
  }, []);

  const getData = async (query, vendorIdList, vendorSelectedList) => {
    setLoading(true);
    const dataSend = {
      dateFrom: query?.DateFrom && moment(query?.DateFrom).format(DATE_FORMAT_SEND_API),
      dateTo: query?.DateTo && moment(query?.DateTo).format(DATE_FORMAT_SEND_API),
      listVendorId: vendorIdList,
      orderType: '',
    };
    try {
      const res = await ApiServices.getRPDetailContByCustomerCommission(dataSend);
      if (res?.data?.data) {
        const dataMap = await res?.data?.data.map((item, index) => {
          let sum = 0;
          for (const [key] of Object.entries(item)) {
            if (!isNaN(item[key])) {
              sum += item[key];
            }
          }
          item.sum = sum;
          item.key = index;
          return item;
        });
        const dataMapEnd = await vendorSelectedList?.map((el) => {
          const data = dataMap?.filter((item) => item?.vendorId === el?.key);
          el.data = data;
          return el;
        });

        setDataTable(dataMapEnd || []);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, newVendorSelectedId, newVendorSelected) => {
      getData(newFilter, newVendorSelectedId, newVendorSelected);
    }, 1000),
    []
  );
  useEffect(() => {
    if (vendorSelectedId?.length) {
      timeoutGetData(filter, vendorSelectedId, vendorSelected);
    } else {
      setDataTable([]);
    }
  }, [filter, vendorSelectedId]);

  const handleChangePartner = (vendorIdList, dataTest) => {
    setVendorSelectedId(vendorIdList);
    setVendorSelected(dataTest);
  };

  const handleSearchVendor = (text) => {
    if (!text) {
      setVendorList(vendorListOrigin);
      return;
    }

    let dataFilter = [];
    vendorListOrigin?.map((item) => {
      let checkFilter = true;
      if (!Utils.convertValSearch(item?.name)?.includes(Utils.convertValSearch(text))) {
        checkFilter = false;
      }

      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    setVendorList(dataFilter);
  };

  const handleExportExcelFile = async (dataExport = []) => {
    if (loadingExport || !dataExport?.length) return;
    setLoadingExport(true);

    const workbook = new ExcelJS.Workbook();

    dataExport?.forEach(async (itemExport) => {
      // Ten sheet
      const sheetSummary = workbook.addWorksheet(itemExport?.children, {
        properties: { tabColor: { argb: 'FF00FF00' } },
        views: [{ showGridLines: false }],
      });

      sheetSummary.properties.defaultRowHeight = 15;
      sheetSummary.properties.defaultColWidth = 10;

      sheetSummary.getColumn(1).width = 5;
      sheetSummary.getColumn(2).width = 30;
      // Title
      sheetSummary.getRow(2).font = {
        name: 'Arial',
        family: 2,
        size: 16,
        bold: true,
      };
      sheetSummary.getCell('C2').value = `BÁO CÁO SẢN LƯỢNG THEO NHÀ XE ỦY QUYỀN`;
      sheetSummary.mergeCells('B3:D3');
      sheetSummary.getCell('B3').font = {
        name: 'Arial',
        family: 2,
        size: 11,
        bold: true,
      };
      sheetSummary.getCell('B3').value = `${itemExport?.children}`;
      sheetSummary.getCell('E3').value = `Từ Ngày ${moment(filter?.DateFrom).format(
        'DD/MM/YYYY'
      )} -> ${moment(filter?.DateTo).format('DD/MM/YYYY')}`;
      sheetSummary.addRow(['']); // blank Row

      // add Title Table
      sheetSummary.addRow(LIST_COLUMNS_TITLE_FIRST_SUMMARY_SHEET);
      sheetSummary.addRow(LIST_COLUMNS_TITLE_SECOND_SUMMARY_SHEET);

      MERGE_TWO_ROWS_TITLE_SUMMARY_SHEET.forEach((item) => {
        sheetSummary.mergeCells(`${item}5:${item}6`);
        sheetSummary.getCell(`${item}5`).border = {
          top: { style: 'thin', color: { argb: '006699' } },
          left: { style: 'thin', color: { argb: '006699' } },
          right: { style: 'thin', color: { argb: '006699' } },
          bottom: { style: 'thin', color: { argb: '006699' } },
        };
        sheetSummary.getCell(`${item}5`).fill = {
          type: 'gradient',
          gradient: 'angle',
          stops: [
            { position: 0, color: { argb: '96B6C5' } },
            { position: 1, color: { argb: '96B6C5' } },
          ],
        };
      });
      MERGE_TWO_COLUMNS_TITLE_SUMMARY_SHEET.forEach((item) => {
        sheetSummary.mergeCells(`${item.start}5:${item.end}5`);
        sheetSummary.getCell(`${item.start}5`).border = {
          top: { style: 'thin', color: { argb: '006699' } },
          left: { style: 'thin', color: { argb: '006699' } },
          right: { style: 'thin', color: { argb: '006699' } },
          bottom: { style: 'thin', color: { argb: '006699' } },
        };
        sheetSummary.getCell(`${item.start}5`).fill = {
          type: 'gradient',
          gradient: 'angle',
          stops: [
            { position: 0, color: { argb: '96B6C5' } },
            { position: 1, color: { argb: '96B6C5' } },
          ],
        };
      });
      BORDER_CELL_TITLE_SUMMARY_SHEET.forEach((item) => {
        sheetSummary.getCell(`${item}6`).border = {
          top: { style: 'thin', color: { argb: '006699' } },
          left: { style: 'thin', color: { argb: '006699' } },
          right: { style: 'thin', color: { argb: '006699' } },
          bottom: { style: 'thin', color: { argb: '006699' } },
        };
        sheetSummary.getCell(`${item}6`).fill = {
          type: 'gradient',
          gradient: 'angle',
          stops: [
            { position: 0, color: { argb: '96B6C5' } },
            { position: 1, color: { argb: '96B6C5' } },
          ],
        };
      });

      sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };
      sheetSummary.getRow(6).alignment = { vertical: 'middle', horizontal: 'center' };

      // ================ Summary Body ====================
      itemExport?.data?.map((itemBody, indexRow) => {
        sheetSummary.addRow([
          '',
          itemBody?.customerName || '', // Khach hang
          itemBody?.bill_20 || 0, // bill_20
          itemBody?.bill_40C || 0, // bill_40C
          itemBody?.bill_40T || 0, // bill_40T
          itemBody?.bill_45 || 0, // bill_45
          itemBody?.book_20 || 0, // book_20
          itemBody?.book_40C || 0, // book_40C
          itemBody?.book_40T || 0, // book_40T
          itemBody?.book_45 || 0, // book_45
          itemBody?.transit_20 || 0, // transit_20
          itemBody?.transit_40C || 0, // transit_40C
          itemBody?.transit_40T || 0, // transit_40T
          itemBody?.transit_45 || 0, // transit_45
          itemBody?.sum || 0, // Sum
        ]);

        COLUMNS_BORDER_LIST_SUMMARY_SHEET.forEach((item) => {
          sheetSummary.getCell(`${item}${indexRow + 7}`).border = {
            top: { style: 'thin', color: { argb: '006699' } },
            left: { style: 'thin', color: { argb: '006699' } },
            right: { style: 'thin', color: { argb: '006699' } },
            bottom: { style: 'thin', color: { argb: '006699' } },
          };
        });
        LIST_COLUMN_FILL_ORDER_TYPE.forEach((item) => {
          sheetSummary.getCell(`${item.cell}${indexRow + 7}`).fill = {
            type: 'gradient',
            gradient: 'angle',
            stops: [
              { position: 0, color: { argb: item.color } },
              { position: 1, color: { argb: item.color } },
            ],
          };
        });
      });

      // SUm
      const dataLength = itemExport?.data?.length;
      const startPosition = 7;
      const endPosition = dataLength + 6;
      const showResult = dataLength + 7;
      if (dataLength > 0) {
        sheetSummary.getCell(`B${showResult}`).value = 'Tổng';
        LIST_COLUMN_SUM_SUMMARY_SHEET.forEach((column) => {
          sheetSummary.getCell(`${column + showResult}`).value = {
            formula: `=SUM(${column + startPosition}:${column + endPosition})`,
          };
        });
        COLUMNS_BORDER_LIST_SUMMARY_SHEET.forEach((item) => {
          sheetSummary.getCell(`${item + showResult}`).border = {
            top: { style: 'thin', color: { argb: '006699' } },
            left: { style: 'thin', color: { argb: '006699' } },
            right: { style: 'thin', color: { argb: '006699' } },
            bottom: { style: 'thin', color: { argb: '006699' } },
          };
          sheetSummary.getCell(`${item + showResult}`).fill = {
            type: 'gradient',
            gradient: 'angle',
            stops: [
              { position: 0, color: { argb: '96B6C5' } },
              { position: 1, color: { argb: '96B6C5' } },
            ],
          };
        });
        sheetSummary.getRow(showResult).font = {
          name: 'Arial',
          family: 4,
          size: 10,
          bold: true,
        };
      }

      // Styles
    });

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Bao_Cao_San_Luong_Theo_Nha_Xe_Uy_Quyen_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoadingExport(false);
    }
  };

  const onChangeTab = (key) => {
    console.log(key);
  };

  const items = dataTable?.map((item, index) => {
    return {
      key: `${index}`,
      label: (
        <Badge count={item?.data?.length} size='small' offset={[9, -5]}>
          <div>{item?.children}</div>{' '}
        </Badge>
      ),
      children: <TableData dataItem={item?.data || []} />,
    };
  });

  return (
    <>
      <h1>BÁO CÁO SẢN LƯỢNG THEO NHÀ XE ỦY QUYỀN</h1>
      <div
        style={{
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          padding: '20px 10px',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      >
        <Space>
          <div>
            <b
              style={{
                marginLeft: '10px',
              }}
            >
              Chọn ngày:
            </b>
            <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
          </div>
          <div>
            <b>
              Nhà Xe:{' '}
              {!vendorSelectedId?.length && (
                <i
                  style={{
                    color: 'red',
                    fontSize: '12px',
                  }}
                >
                  Bạn phải chọn nhà xe
                </i>
              )}
            </b>
            <br />
            <Selection
              status={!vendorSelectedId?.length ? 'error' : ''}
              data={vendorList}
              placeholder='Nhà xe'
              fieldValue='name'
              width={!vendorSelectedId?.length ? 300 : 'auto'}
              value={vendorSelectedId}
              mode='multiple'
              onChange={handleChangePartner}
              onSearch={handleSearchVendor}
            />
          </div>
          <div>
            <b></b>
            <br />
            <Button
              type='primary'
              loading={loading}
              icon={<DownloadOutlined />}
              style={{ padding: '0px 20px', marginLeft: '10px' }}
              disabled={!dataTable?.length}
              onClick={() => handleExportExcelFile(dataTable)}
            >
              Export Excel
            </Button>
          </div>
        </Space>
      </div>
      <Tabs defaultActiveKey='1' items={items} onChange={onChangeTab} />
      <LoadingView loading={loading} />
    </>
  );
};
