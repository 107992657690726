import React, { useState, useMemo } from 'react';
import { Table, Button, Modal } from 'antd';
import moment from 'moment';
import { TransportFeeMainForm } from './transport-fee-main-form';
import { TransportFeeUpdateForm } from './transport-fee-update-form';
import { BtnConfirm } from '~components';
import ApiServices from '~services';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { CONTRACT_STATUS } from '~commons/constants';

const TransportFeeMain = ({
  contTypeList,
  dataFeeMainTransport,
  setDataFeeMainTransport,
  locationSelected,
  isPartner,
  contractStatus = 0,
  isNew,
  reloadAfterUpdate,
  valueDetail,
}) => {
  const [loadingAddNew, setLoadingAddNew] = useState(false);
  const [loadingTransport, setLoadingTransport] = useState(false);
  const [showCreateFeeMain, setShowCreateFeeMain] = useState(false);
  const [countShow, setCountShow] = useState(0);
  const [showUpdate, setShowUpdate] = useState(false);
  const [dataUpdate, setDataUpdate] = useState(null);
  const [dataFees, setDataFees] = useState([]);

  const isAddNewFee = useMemo(() => {
    return !locationSelected?.length > 0;
  }, [locationSelected]);

  const handleDelete = (keyId, valueId) => {
    let dataList = [...dataFeeMainTransport];
    const ind = dataFeeMainTransport?.findIndex((item) => item?.[keyId] === valueId);
    dataList.splice(ind, 1);
    setDataFeeMainTransport(dataList);
  };

  const deleteFee = async (record) => {
    if (loadingTransport) return;

    if (record?.idGenerate) {
      setLoadingTransport(true);
      await handleDelete('idGenerate', record?.idGenerate);
      setLoadingTransport(false);
    }
    if (record?.id) {
      setLoadingTransport(true);
      return ApiServices.deleteTransportFee(record?.id, isPartner)
        .then((res) => {
          if (res?.data?.data) {
            handleDelete('id', record?.id);
            window.TmsNoti.success('Thao tác thành công');
          }
        })
        .catch((err) => {
          console.log('Delete Transport Fee Failed: ', err);
          window.TmsNoti.error('Thao tác thất bại');
        })
        .finally(() => setLoadingTransport(false));
    }
  };

  const renderRoute = (_, record) => {
    return (
      <span>
        {record?.dataObjectDistance?.locationFromName || record?.locationFromName}{' '}
        <b className='blue'>{'===>'} </b>
        {record?.dataObjectDistance?.locationToName || record?.locationToName}
      </span>
    );
  };
  const renderPriceCont = (value, record) => {
    if (!value) {
      return <span key={record?.id || record?.idGenerate}></span>;
    }
    return (
      <span key={record?.id || record?.idGenerate}>
        {`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    );
  };
  // const renderDistance = (_, record) => {
  //   return <span>{record?.dataObjectDistance?.distanceKm}</span>;
  // };

  const columns = [
    {
      title: 'Tuyến đường',
      dataIndex: 'distanceId',
      width: 180,
      fixed: true,
      render: renderRoute,
    },
    // {
    //   title: 'Cự ly (Km)',
    //   dataIndex: 'dataObjectDistance',
    //   width: 80,
    //   fixed: true,
    //   align: 'center',
    //   render: renderDistance,
    // },
  ];

  contTypeList?.map((item) => {
    columns.push({
      title: item.name,
      dataIndex: item.id,
      width: 80,
      align: 'center',
      render: renderPriceCont,
    });
  });

  columns.push({
    title: '#',
    align: 'center',
    fixed: 'right',
    width: 75,
    render: (record) => (
      <>
        {isNew ? (
          <Button
            type='link'
            style={{
              margin: '5px 0px',
            }}
            icon={<EditOutlined />}
            onClick={() => handleUpdateWithCreate(record)}
          ></Button>
        ) : (
          <Button
            type='link'
            style={{
              margin: '5px 0px',
            }}
            icon={<EditOutlined />}
            disabled={
              contractStatus === CONTRACT_STATUS.ACCEPT || contractStatus === CONTRACT_STATUS.CANCEL
            }
            onClick={() => handleUpdate(record)}
          ></Button>
        )}
        <BtnConfirm onConfirm={() => deleteFee(record)} icon={<DeleteOutlined />}></BtnConfirm>
      </>
    ),
  });

  const handleSubmitAddNewByApi = async (dataEndNew) => {
    if (loadingAddNew) return;

    const transportData = dataEndNew?.map((item) => {
      let dataTransport = {
        distanceId: item?.distanceId,
        fees: item?.fees,
        contractId: valueDetail?.contractId,
      };
      if (item?.id) {
        dataTransport.id = item.id;
      }
      return dataTransport;
    });
    const dataSend = {
      contractId: valueDetail?.contractId,
      contractNumber: valueDetail?.contractNumber,
      companyId: valueDetail?.companyId,
      customerId: valueDetail?.customerId,
      customerName: valueDetail?.customerName,
      contractDate:
        valueDetail?.contractDate && moment(valueDetail?.contractDate).format('YYYY-MM-DD'),
      expiredDate:
        valueDetail?.expiredDate && moment(valueDetail?.expiredDate).format('YYYY-MM-DD'),
      renewExpiredDate: null,
      locations: valueDetail.locations,
      arrLocations: valueDetail?.arrLocations,
      note: valueDetail?.note,
      partnerId: valueDetail?.partnerId,
      transport: transportData?.length > 0 ? transportData : null,
      transit: valueDetail?.transit,
      romoocs: valueDetail?.romoocs,
    };

    setLoadingAddNew(true);
    return ApiServices.updateContractForVendor(valueDetail?.contractId, dataSend)
      .then((res) => {
        if (res?.data?.data) {
          window.TmsNoti.success('Thêm phí vận chuyển thành công');
          return reloadAfterUpdate();
        } else {
          window.TmsNoti.error('Lỗi', 'Thêm phí vận chuyển thất bại');
        }
      })
      .catch((err) => {
        console.log({ err });
        window.TmsNoti.error('Lỗi', 'Thêm phí vận chuyển thất bại');
      })
      .finally(() => setLoadingAddNew(false));
  };
  const handleSubmitNoApi = (dataEndNew) => {
    setDataFeeMainTransport(dataEndNew);
  };

  const handleSubmit = (data) => {
    let dataEnd = [...dataFeeMainTransport];
    onCloseFormCreate();
    setCountShow(0);
    dataEnd.push(data);
    if (isNew) {
      return handleSubmitNoApi(dataEnd);
    } else {
      return handleSubmitAddNewByApi(dataEnd);
    }
  };

  const handleCreate = () => {
    setShowCreateFeeMain(true);
    setCountShow(countShow + 1);
  };
  const onCloseFormCreate = () => {
    setShowCreateFeeMain(false);
  };

  const handleUpdate = (dataRow) => {
    setDataUpdate(dataRow);
    const dataNewFees = dataRow?.fees?.map((el) => {
      el.fee = dataRow[el.contTypeId];
      return el;
    });
    setDataFees(dataNewFees);
    setShowUpdate(true);
  };
  const handleUpdateWithCreate = (dataRow) => {
    const dataRowNew = {
      ...dataRow,
      locationFromName: dataRow?.dataObjectDistance?.locationFromName,
      locationToName: dataRow?.dataObjectDistance?.locationToName,
    };

    setDataUpdate(dataRowNew);
    const dataNewFees = dataRow?.fees?.map((el) => {
      el.fee = dataRow[el.contTypeId];
      return el;
    });
    setDataFees(dataNewFees);
    setShowUpdate(true);
  };
  const onCloseUpdate = () => {
    setDataUpdate(null);
    setDataFees([]);
    setShowUpdate(false);
  };
  const handleSubmitUpdateWhenCreate = async (dataSendUpdate) => {
    let dataList = [...dataFeeMainTransport];
    const ind = await dataFeeMainTransport?.findIndex(
      (item) => item?.idGenerate === dataSendUpdate?.idGenerate
    );
    dataList[ind] = dataSendUpdate;
    await setDataFeeMainTransport(dataList);
    await onCloseUpdate();
  };

  return (
    <div>
      {isAddNewFee && (
        <div
          style={{
            marginTop: '-10px',
          }}
        >
          <i className='red'>Bạn phải chọn nơi đóng hàng trước, để thêm phí vận chuyển.</i>
        </div>
      )}
      <Button
        type='primary'
        style={{
          margin: '5px 0px',
        }}
        onClick={handleCreate}
        disabled={isAddNewFee}
      >
        Thêm phí vận chuyển
      </Button>
      <Table
        bordered
        size='small'
        loading={false}
        columns={columns}
        dataSource={dataFeeMainTransport}
        rowKey={isNew ? 'idGenerate' : 'id'}
        scroll={{
          x: '100%',
          y: 600,
        }}
        pagination={false}
      />
      <Modal
        title='Thêm phí vận chuyển'
        open={showCreateFeeMain}
        onCancel={onCloseFormCreate}
        footer={null}
        // style={{
        //   top: 20,
        // }}
        // width='80%'
      >
        <TransportFeeMainForm
          countShow={countShow}
          locationSelected={locationSelected}
          contTypeList={contTypeList}
          handleSubmit={handleSubmit}
          dataFeeMainTransport={dataFeeMainTransport}
        />
      </Modal>

      <Modal
        title='Cập nhật phí vận chuyển'
        open={showUpdate}
        onCancel={onCloseUpdate}
        footer={null}
      >
        <TransportFeeUpdateForm
          locationSelected={locationSelected}
          dataFeeMainTransport={dataFeeMainTransport}
          dataUpdate={dataUpdate}
          dataFees={dataFees}
          setDataFees={setDataFees}
          onCloseUpdate={onCloseUpdate}
          reloadAfterUpdate={reloadAfterUpdate}
          isNew={isNew}
          handleSubmitUpdateWhenCreate={handleSubmitUpdateWhenCreate}
        />
      </Modal>
    </div>
  );
};

export { TransportFeeMain };
