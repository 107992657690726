const COLORS = ['#f5c6aa', '#93d9a3', '#ffbcbc'];

const renderByColor = (text, color) => {
  return {
    props: {
      style: { background: color },
    },
    children: <div>{text}</div>,
  };
};
export const COLUMNS = [
  {
    title: 'Khách Hàng',
    with: 300,
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: 'Nhập',
    children: [
      {
        title: "20'",
        with: 30,
        dataIndex: 'bill_20',
        align: 'center',
        render: (text) => renderByColor(text, COLORS[1]),
      },
      {
        title: '40C',
        with: 30,
        align: 'center',
        dataIndex: 'bill_40C',
        render: (text) => renderByColor(text, COLORS[1]),
      },
      {
        title: '40T',
        with: 30,
        align: 'center',
        dataIndex: 'bill_40T',
        render: (text) => renderByColor(text, COLORS[1]),
      },
      {
        title: '45',
        with: 30,
        align: 'center',
        dataIndex: 'bill_45',
        render: (text) => renderByColor(text, COLORS[1]),
      },
    ],
  },
  {
    title: 'Xuất',
    children: [
      {
        title: "20'",
        with: 30,
        dataIndex: 'book_20',
        align: 'center',
        render: (text) => renderByColor(text, COLORS[0]),
      },
      {
        title: '40C',
        with: 30,
        dataIndex: 'book_40C',
        align: 'center',
        render: (text) => renderByColor(text, COLORS[0]),
      },
      {
        title: '40T',
        with: 30,
        dataIndex: 'book_40T',
        align: 'center',
        render: (text) => renderByColor(text, COLORS[0]),
      },
      {
        title: '45',
        with: 30,
        dataIndex: 'book_45',
        align: 'center',
        render: (text) => renderByColor(text, COLORS[0]),
      },
    ],
  },
  {
    title: 'Chuyển Kho',
    children: [
      {
        title: "20'",
        with: 30,
        dataIndex: 'transit_20',
        align: 'center',
        render: (text) => renderByColor(text, COLORS[2]),
      },
      {
        title: '40C',
        with: 30,
        dataIndex: 'transit_40C',
        align: 'center',
        render: (text) => renderByColor(text, COLORS[2]),
      },
      {
        title: '40T',
        with: 30,
        dataIndex: 'transit_40T',
        align: 'center',
        render: (text) => renderByColor(text, COLORS[2]),
      },
      {
        title: '45',
        with: 30,
        dataIndex: 'transit_45',
        align: 'center',
        render: (text) => renderByColor(text, COLORS[2]),
      },
    ],
  },
  {
    title: 'Tổng',
    with: 30,
    dataIndex: 'sum',
    align: 'center',
    render: (value) => <a>{value}</a>,
  },
];

export const EXPORT_HEADER = [
  {
    CustomerName: 'Khách Hàng',
    Bill_20: 'Nhập',
    Bill_40C: '',
    Bill_40T: '',
    Bill_45: '',

    Book_20: 'Xuất',
    Book_40C: '',
    Book_40T: '',
    Book_45: '',

    Transit_20: 'Chuyển Kho',
    Transit_40C: '',
    Transit_40T: '',
    Transit_45: '',
    sum: 'Tổng',
  },
  {
    CustomerName: '',
    Bill_20: "20'",
    Bill_40C: '40C',
    Bill_40T: '40T',
    Bill_45: '45',

    Book_20: "20'",
    Book_40C: '40C',
    Book_40T: '40T',
    Book_45: '45',

    Transit_20: "20'",
    Transit_40C: '40C',
    Transit_40T: '40T',
    Transit_45: '45',
    sum: '',
  },
];
export const MERGE_COLUMN_SHEET = [
  { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
  {
    s: { r: 0, c: 1 },
    e: { r: 0, c: 2 },
    e: { r: 0, c: 3 },
    e: { r: 0, c: 4 },
  },
  {
    s: { r: 0, c: 5 },
    e: { r: 0, c: 6 },
    e: { r: 0, c: 7 },
    e: { r: 0, c: 8 },
  },
  {
    s: { r: 0, c: 9 },
    e: { r: 0, c: 10 },
    e: { r: 0, c: 11 },
    e: { r: 0, c: 12 },
  },
  { s: { r: 0, c: 13 }, e: { r: 1, c: 13 } },
];
