import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { TableLayout } from '~components';
import { FormInput } from './form-input';
import DriverFields from './fields-data';
import { Button, Popconfirm, message, Space } from 'antd';
import { EditOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { TableTitle } from '~commons/styles';
import { RangeDateFilter } from '~components';
import { debounce } from 'lodash';

const DEFAULT_QUERIES = {
  PageSize: 15,
  PageNumber: 1,
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('month').format(),
  SortColumn: 'inputDate',
  SortColumnDirection: 'ASC',
};

const OilCouponsOilInput = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [drivers, setDrivers] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);

  const [filter, setFilter] = useState(DEFAULT_QUERIES);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getOilInputs(newQuery)
      .then((res) => {
        if (res?.data) {
          setDrivers(res?.data);
        }
      })
      .catch((err) => {
        console.error('get OilInputs Error:::', err);
      })
      .finally(() => setLoading(false));
  };

  // const onColumnSearch = (event, select = '') => {
  //   setFilter((prev) => {
  //     const Columns = { ...prev.Columns };
  //     const value = select ? event : event.target.value;
  //     const name = select ? select : event.target.name;
  //     if (!value) {
  //       delete Columns[name];
  //       return { ...prev, Columns, PageNumber: 1 };
  //     }
  //     Columns[name] = value;
  //     return { ...prev, Columns, PageNumber: 1 };
  //   });
  // };
  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);
  // ================= Search New ================

  const setFieldsForm = async (value) => {
    const driverFields = await DriverFields(value);
    await setFields(driverFields);
    return driverFields;
  };
  const reload = async () => {
    setSelected(null);
    await timeoutGetData(filter);
  };

  const getUpdatedDriverForm = async (value, isNew = false, id = null) => {
    const driverForm = await (
      <FormInput
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        reload={reload}
        onChange={setFields}
      />
    );
    if (isNew) {
      return setTabPanels([
        {
          title: 'Thông Tin Chi Tiết',
          content: driverForm,
        },
      ]);
    } else {
      return setTabPanels([
        {
          title: 'Thông Tin Chi Tiết',
          content: driverForm,
        },
      ]);
    }
  };

  const onRowSelected = async (value) => {
    await setIsLoadingTab(true);
    await setTitleTab('Cập nhật');
    await setFieldsForm(value)
      .then((res) => {
        return getUpdatedDriverForm(res, false, value.id);
      })
      .then(() => setSelected(value));
    setIsLoadingTab(false);
  };
  const handleDelete = async (record) => {
    try {
      const res = await ApiServices.deleteOilInput(record.id);
      if (res.data) {
        message.success('Xoá thành công');
        return reload();
      }
      message.error('Xoá thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: <TableTitle>STT</TableTitle>,
      dataIndex: 'key',
      align: 'center',
      width: 50,
    },
    {
      title: (
        <TableTitle>
          Ngày Nhập Dầu
          {/* <Input name='inputDate' onChange={onColumnSearch} placeholder='Search' /> */}
        </TableTitle>
      ),
      dataIndex: 'inputDate',
      align: 'center',
      width: 150,
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: <TableTitle>Giá Dầu</TableTitle>,
      width: 150,
      align: 'center',
      dataIndex: 'oilPrice',
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: (
        <TableTitle>
          Số Lượng
          {/* <Input name='inputAmount' onChange={onColumnSearch} placeholder='Search' /> */}
        </TableTitle>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'inputAmount',
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: <TableTitle>Ghi Chú</TableTitle>,
      width: 150,
      align: 'center',
      dataIndex: 'note',
    },
    {
      title: (
        <TableTitle>
          Ngày Tạo
          {/* <Input name='createdOn' onChange={onColumnSearch} placeholder='Search' /> */}
        </TableTitle>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'createdOn',
      render: (value) => Utils.formatDateHourFn_2(value),
    },
    {
      title: (
        <TableTitle>
          Người Tạo
          {/* <Input name='createdBy' onChange={onColumnSearch} placeholder='Search' /> */}
        </TableTitle>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'createdBy',
    },
    {
      title: 'Action',
      fixed: 'right',
      align: 'center',
      width: 90,
      render: (record) => (
        <Space size={12}>
          <Button
            type='primary'
            shape='circle'
            icon={<EditOutlined />}
            onClick={() => onRowSelected(record)}
          />
          <Popconfirm
            placement={'topRight'}
            title={'Bạn có chắc chắn muốn xóa?'}
            okText={'Có'}
            cancelText={'Không'}
            onConfirm={() => handleDelete(record)}
          >
            <Button type='primary' shape='circle' icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const createDriver = async () => {
    await setTitleTab('Thêm mới');
    await setFieldsForm({})
      .then((res) => getUpdatedDriverForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };

  const handleExportExcel = async (query) => {
    if (!query || loading) return;

    const dateFrom = moment(query?.DateFrom).format('YYYY-MM-DD');
    const dateTo = moment(query?.DateTo).format('YYYY-MM-DD');

    const dataSend = {
      DateFrom: query?.DateFrom && moment(query?.DateFrom).format('YYYY-MM-DD'),
      DateTo: query?.DateTo && moment(query?.DateTo).format('YYYY-MM-DD'),
      export: true,
    };
    setLoading(true);
    try {
      const { data } = await ApiServices.exportOilInput(dataSend);
      if (data) {
        const outputFilename = `Danh_Sach_Phieu_Nhap_Dau_Tu_ngay_${dateFrom}_den_ngay_${dateTo}.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoading(false);
    }
  };

  const headerContent = (
    <Space>
      <RangeDateFilter query={filter} onChange={setFilter} />
      <Button type='primary' onClick={createDriver}>
        Thêm Mới
      </Button>
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        disabled={drivers?.data?.length < 0}
        style={{ padding: '0px 20px', marginLeft: '10px' }}
        onClick={() => handleExportExcel(filter)}
      >
        Export Excel
      </Button>
    </Space>
  );

  const pagination = {
    total: drivers?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };
  const renderData = drivers?.data?.map((item, index) => {
    item.key = (drivers?.pageNumber - 1) * drivers?.pageSize + index + 1;
    return item;
  });
  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={renderData || []}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      pagination={pagination}
      isDisplayCheckbox={false}
      width={500}
      headerLength='190px'
    />
  );
};

export { OilCouponsOilInput };
