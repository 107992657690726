import React from 'react';
import { Divider } from 'antd';
import { RomoocFeeMain } from './romooc-fee-main';

const RomoocFee = ({
  dataFeeRomooc,
  setDataFeeRomooc,
  locationSelected,
  locationPackingList,
  isPartner = false,
  reloadAfterUpdate,
  // contractStatus = 0,
  isNew = false,
  valueDetail,
}) => {
  return (
    <>
      <Divider>Phí neo Romooc</Divider>
      <RomoocFeeMain
        dataFeeRomooc={dataFeeRomooc}
        setDataFeeRomooc={setDataFeeRomooc}
        locationSelected={locationSelected}
        locationPackingList={locationPackingList}
        isPartner={isPartner}
        reloadAfterUpdate={reloadAfterUpdate}
        // contractStatus={contractStatus}
        isNew={isNew}
        valueDetail={valueDetail}
      />
    </>
  );
};

export { RomoocFee };
