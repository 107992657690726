import { InputSearchColumns, BtnConfirm } from '~components';

export const getColumns = (
  searchByColumn,
  handleSearchColumnFn,
  deleteVendor,
  handleLocationDelivery
) => [
  {
    title: (
      <InputSearchColumns
        title='Mã'
        value={searchByColumn?.code}
        onChange={(e) => handleSearchColumnFn(e, 'code')}
      />
    ),
    dataIndex: 'code',
  },
  {
    title: (
      <InputSearchColumns
        title='Tên khách hàng'
        value={searchByColumn?.name}
        onChange={(e) => handleSearchColumnFn(e, 'name')}
      />
    ),
    dataIndex: 'name',
  },
  // {
  //   title: (
  //     <InputSearchColumns
  //       title='Tên viết tắt'
  //       value={searchByColumn?.shortName}
  //       onChange={(e) => handleSearchColumnFn(e, 'shortName')}
  //     />
  //   ),
  //   dataIndex: 'shortName',
  // },
  // {
  //   title: (
  //     <InputSearchColumns
  //       title='Số điện thoại'
  //       value={searchByColumn?.phone}
  //       onChange={(e) => handleSearchColumnFn(e, 'phone')}
  //     />
  //   ),
  //   dataIndex: 'phone',
  // },
  {
    title: 'Nơi đóng hàng',
    dataIndex: 'assignedLocations',
    align: 'center',
    width: 300,
    render: (value, record) => handleLocationDelivery(value, record),
  },
  {
    title: 'Action',
    fixed: 'right',
    width: 75,
    render: (record) => <BtnConfirm onConfirm={() => deleteVendor(record)}>Xóa</BtnConfirm>,
  },
];
