import React from 'react';
import { Table } from 'antd';
import DrawerLeft from './drawer-left';
import useApiHook from '~pages/white-board/wb-hook/api-hook';
import { WB_POPUP_ASSIGN_HEIGHT } from '~commons/constants';

// const HEADER_LENGTH = '128px';
const LIMIT_SELECTED_SIZE = 1;
export const TableWhiteBoardNew = ({
  onSelectChange = null,
  onHandleSelectRow,
  onHandleSelectAll,
  columns,
  data,
  loading,
  selected,
  closeDrawer,
  headerContent,
  titleTab,
  tabPanels = null,
  isLoadingTab = null,
  isSelectRadioBox,
  checkDisableSelection,
  pagination = false,
  selectedParent = null,
  rowClassName,
  rowKey = null,
  renderRowKey = null,
  limitSelected = false,
  disableSelectAll = false,
  isNotSubHeight = false,
  showSelection = true,
  width,
  tableRef = null,
  headerLength = '128px',
  onChange,
}) => {
  const { isShowAssignPopup } = useApiHook();
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  // console.log('isShowAssignPopup', isShowAssignPopup);
  const onSelectChangeState = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    onSelectChange && onSelectChange(selectedRowKeys, selectedRows);
  };

  const type = isSelectRadioBox ? 'radio' : 'checkbox';
  const disabledSelection = () => {
    const isDisabled = limitSelected && selectedParent.length > LIMIT_SELECTED_SIZE - 1;

    return (record) => ({
      disabled:
        (checkDisableSelection && checkDisableSelection(record)) ||
        (selectedParent && !selectedParent.includes(record.key) && isDisabled),
      key: record.id,
    });
  };
  const rowSelection = {
    type,
    selectedRowKeys: selectedParent !== null ? selectedParent : selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => onSelectChangeState(selectedRowKeys, selectedRows),
    getCheckboxProps: disabledSelection(),
    onSelect: onHandleSelectRow,
    onSelectAll: onHandleSelectAll,
  };

  const rowClass = (record) => rowClassName(record);
  let dataFormat = null;
  if (renderRowKey) {
    dataFormat = data?.map((item) => {
      return {
        ...item,
        key: renderRowKey(item),
      };
    });
  }
  return (
    <div className='table-content'>
      <div style={{ marginBottom: 4 }}>{headerContent}</div>
      <Table
        ref={tableRef}
        rowClassName={rowClassName ? rowClass : null}
        className={disableSelectAll ? ' tb-disable-select-all' : ''}
        bordered={true}
        rowKey={rowKey ? rowKey : 'key'}
        rowSelection={showSelection && rowSelection}
        columns={columns}
        dataSource={dataFormat || data}
        scroll={
          !isShowAssignPopup || isNotSubHeight
            ? {
                y: `calc(100vh - ${headerLength})`,
              }
            : {
                y: `calc(100vh - ${headerLength} - ${WB_POPUP_ASSIGN_HEIGHT})`,
              }
        }
        loading={loading}
        pagination={pagination}
        size='small'
        onChange={onChange}
      />
      {tabPanels && (
        <DrawerLeft
          isLoadingTab={isLoadingTab}
          visible={!!selected}
          title={titleTab}
          onClose={closeDrawer}
          tabPanels={tabPanels}
          width={width}
        />
      )}
    </div>
  );
};
