import React from 'react';
import { Form, Button, DatePicker, message, InputNumber } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';
import { DATE_FORMAT } from '~commons/constants';

const OilPricesForm = ({ fields, isNew, id, dataSelect, reload, onChange, setLoading }) => {
  const handleCreate = async (data) => {
    data.companyId = JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId;

    try {
      const res = await ApiServices.createOilPrice(data);
      if (res?.data?.data) {
        message.success('Thêm mới giá dầu thành công');
        return reload();
      } else {
        message.error('Thêm mới giá dầu thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới giá dầu thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const handleUpdate = async (data) => {
    data.id = id;
    data.companyId = dataSelect.companyId;
    try {
      const res = await ApiServices.updateOilPrice(id, data);
      if (res.data) {
        message.success('Cập nhật giá dầu thành công');
        return reload();
      } else {
        message.error('Cập nhật giá dầu thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật giá dầu thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await handleCreate(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await handleUpdate(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      name='global_state'
      className='form-input-data'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        // {...itemLayout}
        style={{ marginTop: '10px' }}
        name='price'
        label='Giá dầu'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập giá dầu',
          },
          {
            type: 'number',
            min: 10000,
            message: 'Giá dầu phải lớn hơn 10 000đ',
          },
        ]}
      >
        <InputNumber min={10000} style={{ width: '100%' }} placeholder='Vui lòng nhập giá dầu' />
      </Form.Item>
      <Form.Item
        name='effectiveDate'
        label='Ngày có hiệu lực'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn ngày có hiệu lực',
          },
        ]}
      >
        <DatePicker placeholder='Ngày có hiệu lực' style={{ width: '100%' }} format={DATE_FORMAT} />
      </Form.Item>
      <Form.Item {...tailLayout} className='submit-form-input-data'>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { OilPricesForm };
