import { Image, Input, Switch } from 'antd';
import { TableTitle } from '~commons/styles';

const formatDisplayArr = (value) => {
  return value?.map((item, index) => (
    <ul key={index}>
      <li>{item}</li>
    </ul>
  ));
};

function displayLogo(value, record) {
  return (
    <Image
      src={
        (value && `${process.env.REACT_APP_API_URL}${value?.slice(1)}`) ||
        `https://ui-avatars.com/api/?name=${record?.code}&length=3&size=5`
      }
      alt={record?.code}
      width={100}
    />
  );
}

export const getColumns = (onRowSelected, onColumnSearch, handleChangeStatus) => [
  {
    title: (
      <TableTitle>
        Mã
        <Input name='code' onChange={onColumnSearch} placeholder='Search' />
      </TableTitle>
    ),
    dataIndex: 'code',
    width: 100,
    align: 'center',
    render: (value, record) => <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>,
  },
  {
    title: (
      <TableTitle>
        Tên công ty
        <Input name='name' onChange={onColumnSearch} placeholder='Search' />
      </TableTitle>
    ),
    width: 150,
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: (
      <TableTitle>
        Tên viết tắt
        <Input name='title' onChange={onColumnSearch} placeholder='Search' />
      </TableTitle>
    ),
    width: 100,
    dataIndex: 'title',
    align: 'center',
  },
  // {
  //   title: (
  //     <TableTitle>
  //       Trụ sở
  //       <Input name='partner' onChange={onColumnSearch} placeholder='Search' />
  //     </TableTitle>
  //   ),
  //   width: 100,
  //   dataIndex: 'partner',
  //   align: 'center',
  // },
  {
    title: 'Logo',
    width: 150,
    dataIndex: 'logo',
    align: 'center',
    render: displayLogo,
  },
  {
    title: 'Địa chỉ',
    width: 250,
    align: 'center',
    dataIndex: 'arrAddressId',
    render: (value) => formatDisplayArr(value),
  },
  {
    title: 'Số điện thoại',
    width: 200,
    dataIndex: 'arrPhone',
    align: 'center',
    render: (value) => formatDisplayArr(value),
  },
  {
    title: 'Fax',
    width: 200,
    dataIndex: 'arrFax',
    align: 'center',
    render: (value) => formatDisplayArr(value),
  },
  {
    title: 'Email',
    width: 200,
    dataIndex: 'arrEmail',
    align: 'center',
    render: (value) => formatDisplayArr(value),
  },
  {
    title: 'Mã số thuế',
    width: 150,
    dataIndex: 'taxCode',
    align: 'center',
  },
  {
    title: 'Chủ công ty',
    width: 150,
    dataIndex: 'ownerName',
    align: 'center',
  },
  {
    title: 'Người liên hệ',
    width: 150,
    dataIndex: 'contactName',
    align: 'center',
  },
  {
    title: 'SĐT liên hệ',
    width: 200,
    dataIndex: 'arrContactPhone',
    align: 'center',
    render: (value) => formatDisplayArr(value),
  },
  {
    title: 'Trạng thái',
    fixed: 'right',
    align: 'center',
    width: 120,
    dataIndex: 'status',
    render: (status, record) => (
      <Switch
        checked={status === 0 ? true : false}
        checkedChildren='Hiện'
        unCheckedChildren='Ẩn'
        onClick={() => handleChangeStatus(record.id)}
      />
    ),
  },
];
