import React from 'react';
import { Table, Image } from 'antd';
import Utils from '~commons/utils';

const PrintTicket = ({ dataDetail, dataSelected }) => {
  const formatter = (value) => {
    const format = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return format;
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      align: 'center',
      width: 80,
    },
    {
      title: 'Tên hàng',
      dataIndex: 'materialName',
      align: 'center',
      width: 180,
    },
    {
      title: ' Đơn vị tính',
      dataIndex: 'materialUnit',
      align: 'center',
      width: 120,
    },
    {
      title: 'Số lượng',
      dataIndex: 'amount',
      align: 'center',
      width: 100,
    },
    {
      title: 'Đơn giá',
      dataIndex: 'price',
      align: 'center',
      width: 130,
      render: formatter,
    },
    {
      title: 'VAT(%)',
      dataIndex: 'vat',
      align: 'center',
      width: 80,
    },
    {
      title: 'Thành tiền',
      dataIndex: 'totalPrice',
      align: 'center',
      width: 150,
      render: formatter,
    },
    {
      title: 'Nhà cung cấp',
      dataIndex: 'supplierMaterialName',
      align: 'center',
      width: 150,
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      align: 'center',
      width: 150,
    },
  ];

  const dataSource = dataDetail?.materialNote?.details?.map((item, index) => {
    item.index = index + 1;
    return item;
  });

  return (
    <div>
      <div style={{ padding: '0px 10px', display: 'flex' }}>
        <div>
          <Image
            src={
              (dataDetail?.company?.logo &&
                `${process.env.REACT_APP_API_URL}${dataDetail?.company?.logo?.slice(1)}`) ||
              `https://ui-avatars.com/api/?name=${dataDetail?.company?.code}&length=3&size=`
            }
            alt={dataDetail?.company?.name}
            style={{ paddingLeft: 12 }}
            width={150}
          />
        </div>
        <div style={{ padding: '5px', margin: '0px' }}>
          <h4>{dataDetail?.company?.name}</h4>
          <div>{dataDetail?.company?.address}</div>
          <p>MST: {dataDetail?.company?.tax}</p>
        </div>
      </div>
      <hr />
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <h1>
          <b>PHIẾU NHẬP KHO VẬT TƯ</b>
        </h1>
        <b>Số Hoá Đơn: {dataSelected?.billId}</b>
      </div>
      <div>
        <b>Số phiếu: {dataSelected?.code}</b>
      </div>
      <div>
        <b>Ngày nhập: {dataSelected?.tradingDate && Utils.formatDate(dataSelected?.tradingDate)}</b>
      </div>
      Danh sách hàng nhập:
      <Table
        rowKey='id'
        size='small'
        bordered
        columns={columns}
        dataSource={dataSource || []}
        loading={false}
        pagination={false}
        scroll={{
          x: '100%',
          y: '100%',
        }}
      />
    </div>
  );
};

export { PrintTicket };
