import React, { useEffect, useState, useRef } from 'react';
import storage from '~storage';
import { Divider, Button, Steps, Affix, message } from 'antd';
import { TypeBooking } from './type-booking';
import FieldData from './fields-data';
import formatData from './format-data';
import { RenderInformation } from './render-information';
import { ListContDetail } from './list-cont-order'; //
import { ListContDetailUpdate } from './list-cont-order-update'; //
import { createOrder, updateOrder } from './api-helper';
import ApiServices from '~services';
import { useHistory, useParams } from 'react-router-dom';

const { Step } = Steps;

const OrderForm = () => {
  // rd();
  const orderType =
    storage.staticVariables && storage.staticVariables.OrderType
      ? storage.staticVariables.OrderType.Order_Booking
      : null;

  const defaultValue = FieldData({
    transportType:
      storage.staticVariables && storage.staticVariables.TransportType
        ? storage.staticVariables.TransportType.TransportType_FCL
        : null,
    // liftingEmptyContRequestDate: moment(null, DATE_FORMAT),
  });

  const history = useHistory();
  const [current, setCurrent] = React.useState(0);
  const [type, setType] = React.useState(orderType);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState(defaultValue);
  const [orderDetail, setOrderDetail] = useState(null);
  const [cont, setCont] = useState([]);
  const formRef = useRef();
  const { id } = useParams();
  const getOrderById = async () => {
    try {
      setIsLoading(true);
      let idTmp = id || orderDetail?.id;
      const { data } = await ApiServices.getOrderDetailsId(idTmp);
      if (data) {
        setType(data.orderType);
        data.listCont && data.listCont.length && setCont(data.listCont);
        setFields(formatData(data));
        !orderDetail && setCurrent(1);
        setOrderDetail(data);
      }
    } catch (error) {
      console.log(error);
      message.warn('Đơn Hàng không tồn tại vui lòng thử lại');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      getOrderById();
    }
  }, [id]);
  const steps = [
    {
      title: 'Loại Đơn Hàng',
      content: (
        <TypeBooking
          setType={(type) => {
            setFields(defaultValue);
            setType(type);
          }}
          type={type}
        />
      ),
      description: 'Chọn Loại đơn hàng.',
    },
    {
      title: 'Thông Tin Chung',
      content: (
        <RenderInformation
          formRef={formRef}
          type={type}
          orderDetail={orderDetail}
          fields={fields}
          setFields={setFields}
          onsubmit={() => onSubmitForm()}
          setIsLoadingTab={setIsLoadingTab}
          isLoadingTab={isLoadingTab}
        />
      ),
      // subTitle: '00:00:05',
      description: 'Nhập Thông tin đơn hàng.',
    },
    {
      title: 'Số Lượng',
      content: orderDetail ? (
        <ListContDetailUpdate
          type={type}
          formData={fields}
          orderDetail={orderDetail}
          reload={getOrderById}
          onChange={(value) => setCont(value)}
        />
      ) : (
        <ListContDetail type={type} formData={fields} onChange={(value) => setCont(value)} />
      ),
      description: 'Nhập số lượng cont chi tiết',
    },
  ];
  const updateOrderInfo = async () => {
    try {
      let result = await updateOrder(fields, type, orderDetail);
      if (result && result.data) {
        message.success('Cập nhật đơn hàng thành công!');
        setCurrent(current + 1);
      } else {
        message.error('Cập nhật đơn hàng thất bại !');
      }
    } catch (err) {
      console.error('ERR create order - err', err);
      message.error('Cập nhật đơn hàng Thất bại!, Vui lòng thử lại sau.');
    }
  };
  const onSubmitForm = async () => {
    if (!orderDetail) {
      setCurrent(current + 1);
      return;
    }
    return updateOrderInfo();
  };
  const next = () => {
    if (current != 1) {
      setCurrent(current + 1);
      return;
    }
    formRef.current.submitForm();
  };

  const prev = () => {
    if (current == 1) {
      setFields(defaultValue);
    }
    setCurrent(current - 1);
    return;
  };

  const finishSubmit = async () => {
    let result = null;
    if (orderDetail) {
      return history.push('/orders');
    }
    try {
      result = await createOrder(fields, type, cont);
      if (result && result.data) {
        message.success('Tạo đơn hàng thành công!');
        history.push('/orders');
      } else {
        message.error('Tạo đơn hàng thất bại !');
      }
    } catch (err) {
      console.error('ERR create order - err', err);
      message.error('Tạo đơn hàng Thất bại!, Vui lòng thử lại sau.');
    }
  };

  const isDisableBack = current == 0 || (current == 1 && orderDetail);
  return (
    <>
      <Steps current={current} type='navigation' size='small'>
        {steps.map((item, index) => (
          <Step
            key={item.title}
            {...item}
            status={index > current ? 'wait' : index < current ? 'finish' : 'process'}
          />
        ))}
      </Steps>
      <Divider orientation='left' plain style={{ textTransform: 'uppercase' }}>
        {type}
      </Divider>
      <div className='steps-content'>{steps[current].content}</div>
      <Divider />

      <Affix offsetBottom={40}>
        <div className='steps-action static-button'>
          {current < steps.length - 1 && (
            <Button type='primary' onClick={() => next()}>
              Tiếp tục
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              type='primary'
              onClick={finishSubmit}
              loading={isLoading}
              disabled={!cont.length}
            >
              Hoàn Thành
            </Button>
          )}
          {!isDisableBack && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Quay Lại
            </Button>
          )}
        </div>
      </Affix>
    </>
  );
};

export { OrderForm };
