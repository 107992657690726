import { Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Selection } from '~components';
import ApiServices from '~services';
import { ArrowRightOutlined } from '@ant-design/icons';

export const MoveCont = ({ selectedRows, reload }) => {
  const [partners, setPartners] = useState([]);
  const [partner, setPartner] = useState(null);
  const [loading, setLoading] = useState(false);
  const getPartners = async (query = '') => {
    return ApiServices.getPartnerByCompany({ query })
      .then(({ data }) => {
        setPartners(data.data);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    !selectedRows?.length && setPartner(null);
  }, [selectedRows]);
  useEffect(() => {
    getPartners();
  }, []);
  if (!selectedRows?.length) {
    return null;
  }
  const submitDataTransfer = () => {
    if (loading) return;
    setLoading(true);
    let conts = {};
    for (let select of selectedRows) {
      conts[select.orderId] = [...(conts[select.orderId] || []), select.id];
    }

    return ApiServices.transferConts({
      companyId: partner,
      conts,
    })
      .then((res) => {
        if (res.status == 200) {
          message.success('Chuyển Cont Thành Công');
          return reload();
        }
        message.error('Chuyển Cont Thất Bại');
      })
      .catch((err) => {
        message.error('Chuyển Cont Thất Bại');
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const renderContListToTranfer = (item, index) => {
    return (
      <div key={item?.id}>
        <b>
          {index + 1}
          {'. '}
        </b>
        <span className='blue'>{item?.subnoNumber}</span>
      </div>
    );
  };

  return (
    <>
      <div>
        <div>
          <h3>
            Danh sách Cont được phép chuyển đối tác: <b className='red'>({selectedRows?.length})</b>
          </h3>
          {selectedRows?.length && selectedRows?.map(renderContListToTranfer)}
        </div>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          <b>Chọn đối tác: </b>
          {!partner && <i className='red'>Vui lòng chọn đối tác</i>}
          <Selection
            data={partners}
            placeholder='Chọn đối tác'
            onSearch={getPartners}
            value={partner}
            onChange={(value) => setPartner(value)}
          />
        </div>
        <div
          style={{
            marginTop: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            type='primary'
            onClick={submitDataTransfer}
            disabled={!partner}
            icon={<ArrowRightOutlined />}
          >
            Chuyển
          </Button>
        </div>
      </div>
    </>
  );
};
