export const KEYS = [
  'code',
  'name',
  'phone',
  'address',
  'fax',
  'contactPerson',
  'companyId',
  'companyName',
];
export default (data) => {
  return KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });
};
