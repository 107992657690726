import { Button, Input, Modal, Tooltip, Upload } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import checkContNumber from '~commons/check-cont-number';
import Utils from '~commons/utils';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export const UploadFile = ({ onChange, value, onInputCont, showUpdated }) => {
  const [fileList, setFileList] = useState([]);
  const [contNumber, setContNumber] = useState();
  const [showSealInput, setShowSealInput] = useState(false);
  const [preview, setPreview] = useState({
    visible: false,
    image: '',
    title: '',
  });
  console.log('UploadFile', { value, showUpdated, fileList });

  const formatData = (val) => {
    return val?.map((file) => {
      if (file?.url || !file?.urlImage) return file;
      const url = Utils.getAppUrl() + file.urlImage.substring(2);
      return {
        ...file,
        url,
        fileName: `${file.name}${file.extension}`,
      };
    });
  };

  useEffect(() => {
    value && setFileList(formatData(value));
  }, [value]);

  const beforeUpload = (file) => {
    setFileList([...fileList, file]);
    onInputCont &&
      getBase64(file).then((image) => {
        setPreview({
          title: 'Preview',
          visible: true,
          image,
        });
      });

    return false;
  };
  const handleChange = ({ fileList }) => {
    onChange?.(fileList);
  };
  const onRemove = (file) => {
    setFileList((curList) => {
      const index = curList?.length && curList.indexOf(file);
      const newFileList = (curList?.length && curList.slice()) || [];
      curList?.length && newFileList.splice(index, 1);
      onchange?.(newFileList);
      return newFileList;
    });
  };
  const handleCancel = () => setPreview((preview) => ({ ...preview, visible: false }));
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreview({
      image: file.url || file.preview,
      visible: true,
      title: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  const onChangeContNumber = (e) => {
    setContNumber(e.target.value);
  };
  const onKeyDownCont = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      onClickOk();
    }
  };
  const onClickOk = useCallback(() => {
    if (!showSealInput && !checkContNumber(contNumber)) {
      return;
    }
    showSealInput ? onInputCont?.({ sealNumber: contNumber }) : onInputCont?.({ contNumber });
    handleCancel();
    setContNumber();
    setShowSealInput(false);
  }, [contNumber, showSealInput]);
  const onClickChangeInput = () => {
    setShowSealInput((prev) => !prev);
  };

  return (
    <>
      <Upload
        multiple
        listType='picture-card'
        onChange={handleChange}
        beforeUpload={beforeUpload}
        fileList={fileList}
        onRemove={onRemove}
        onPreview={handlePreview}
      >
        <div>
          <PlusOutlined style={{ color: 'blue', fontSize: '30px' }} />
          <div style={{ marginTop: '10px' }}>Đính Kèm</div>
        </div>
      </Upload>
      <Modal open={preview.visible} title={preview.title} footer={null} onCancel={handleCancel}>
        {onInputCont && (
          <InputWrapper>
            <Input.Group compact>
              <Tooltip
                title={
                  <>
                    Chuyển nhập <b>{showSealInput ? 'số Seal' : 'Số Cont'}</b> sang nhập{' '}
                    <b>{!showSealInput ? 'số Seal' : 'Số Cont'}</b>
                  </>
                }
              >
                <ButtonTransfer icon={<SyncOutlined />} onClick={onClickChangeInput} />
              </Tooltip>
              <Input
                allowClear
                value={contNumber}
                onChange={onChangeContNumber}
                onKeyDown={onKeyDownCont}
                placeholder={`Nhập  ${showSealInput ? 'số Seal' : 'Số Cont'}`}
                style={{ width: 'calc(100% - 89px)' }}
              />
              <Button
                type='primary'
                disabled={!showSealInput && !checkContNumber(contNumber)}
                onClick={onClickOk}
              >
                OK
              </Button>
            </Input.Group>
            <Error>
              {!showSealInput &&
                contNumber &&
                !checkContNumber(contNumber) &&
                'Số cont không hợp lệ!'}
            </Error>
          </InputWrapper>
        )}
        <Image alt='Preview of upload' src={preview.image} />
      </Modal>
    </>
  );
};
const Error = styled.div`
  color: red;
`;
const Image = styled.img`
  width: 100%;
`;
const InputWrapper = styled.div`
  margin-bottom: 8px;
`;
const ButtonTransfer = styled(Button)`
  width: 40px;
`;
