import moment from 'moment';
import _ from 'lodash';
import numeral from 'numeral';
import {
  DATE_FORMAT,
  DATE_RENDER,
  DATE_TIME_FORMAT,
  LOCATION_RENDER,
  ORDER_STATUS,
  SHORTEN_DATE_FORMAT,
  SHORTEN_DATE_FORMAT_CUSTOM,
  SHORTEN_DATE_FORMAT_WB,
  SHORTEN_DATE_TIME_FORMAT,
  SHORTEN_DATE_TIME_FORMAT_WB,
  TASK_STATUSES,
  DATE_RENDER_WB_LIFT_ON,
} from './constants';
import { ConvertViToEn } from './convert-vi-to-en';
import { Tag, message, Button } from 'antd';
export default class Utils {
  static isEmptyString = (str) => {
    const string = str !== undefined || str !== null ? String(str) : '';
    return !str || string.length === 0 || !string.trim();
  };

  static isEmptyArray = (array) => {
    return array === undefined || array === null || array.length === 0;
  };

  static isFunction = (func) => {
    return func && typeof func === 'function';
  };

  static isEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  static isResponseSuccess = (response) => {
    return response && response.status === 200 && response.data && response.data.code === 'ok';
  };

  static removeEmptyAttributes = (data) => {
    let obj = data;
    if (obj) {
      obj = { ...data };
      Object.keys(obj).forEach((key) => {
        if (obj[key] === undefined || obj[key] === null) {
          delete obj[key];
        }
      });
    }
    return obj;
  };

  static convertValSearch = (string) => {
    if (!string) return '';
    return ConvertViToEn(string).trim().toUpperCase();
  };

  static convertTimeToTimestamp = (time) => {
    return Date.parse(time);
  };

  static isNumber = (num) => {
    const regex = /^\d+$/;
    return regex.test(num);
  };

  static parseTime = (dateTime) => {
    return moment(dateTime).format('h:mm A');
  };

  static parseFullDateTime = (dateTime) => {
    return moment(dateTime).format('DD MMM [at] h:mm A');
  };

  static checkDateIsToday = (dateTime) => {
    return this.isSameDay(dateTime, new Date());
  };

  static checkDateIsYesterday = (dateTime) => {
    return moment().subtract(1, 'd').isSame(moment(dateTime), 'd');
  };

  static checkDateIsTomorrow = (dateTime) => {
    return moment(dateTime).subtract(1, 'd').isSame(moment(), 'd');
  };

  static isSameDay = (firstDate, secondDate) => {
    return moment(firstDate).isSame(moment(secondDate), 'd');
  };

  static isSameTime = (firstDate = new Date(), secondDate = new Date()) => {
    return moment(firstDate).isSame(moment(secondDate));
  };

  static calculateProgressTime = (from, to) => {
    const fromTime = moment(from);
    const toTime = moment(to);
    const today = moment();
    const diff = toTime.diff(fromTime, 'days');
    const diffToday = toTime.diff(today, 'days');

    return {
      range: diff,
      remain: diffToday,
    };
  };

  static formatNumber = (num) => {
    // return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return numeral(num).format('0,0');
  };

  static isNotNullAndUndefined = (value) => {
    return !_.isNull(value) && !_.isUndefined(value);
  };

  static generateMonthsInYear = (monthFormat = 'MM-yyyy') => {
    const months = [];
    const dateStart = moment().set('month', 0);
    const dateEnd = moment(dateStart).add(11, 'month');
    while (dateEnd.diff(dateStart, 'months') >= 0) {
      months.push(dateStart.format(monthFormat));
      dateStart.add(1, 'month');
    }
    return months;
  };

  static getShortenNumberString = (number) => {
    if (!number || !_.isNumber(number) || number < 1000) {
      return number;
    } else if (number < 1000000) {
      return `${~~(number / 1000)}K`;
    } else {
      return `${~~(number / 1000000)}M`;
    }
  };
  static disabledDate = (current, max = null, min = null) => {
    if (!current) {
      return false;
    }
    if (max) {
      return current >= max.endOf('day');
    }
    if (min) {
      return min >= current.endOf('day');
    }
  };
  static range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  static disabledTime = () => {
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  };

  static formatStatusOrder = (status) => {
    switch (status) {
      case ORDER_STATUS.INITIAL:
        return 'Khởi Tạo';
      case ORDER_STATUS.PLANED:
        return 'Kế Hoạch';
      case ORDER_STATUS.REVISED:
        return 'KH Đã Cập Nhật';
      case ORDER_STATUS.DOING:
        return 'Vận Chuyển';
      case ORDER_STATUS.FINISH:
        return 'Hoàn Thành';
      case ORDER_STATUS.CANCELED:
        return 'Hủy';
      default:
        return '';
    }
  };

  static formatStatusCont = (status) => {
    switch (status) {
      case 0:
        return 'Khởi Tạo';
      case 1:
        return 'Kế Hoạch';
      case 2:
        return 'KH Đã Cập Nhật';
      case 3:
        return 'Vận Chuyển';
      case 9:
        return 'Hoàn Thành';
      default:
        return '';
    }
  };
  static formatStatusTask = (status) => {
    switch (status) {
      case -1:
        return 'Có thế Bắt Đầu';
      case 1:
        return 'Chờ xác nhận';
      case 3:
        return 'Vận Chuyển';
      case 9:
        return 'Hoàn Thành';
      default:
        return 'Đang chờ';
    }
  };
  static convertJsonToFormData = (data, fileKey = 'orderFiles', fileItemKey = 'Files') => {
    var formData = new FormData();
    for (var key in data) {
      if (Array.isArray(data[key]) && key == fileKey) {
        data[key].forEach((file) => {
          if (file.originFileObj) {
            formData.append(fileItemKey, file.originFileObj);
          } else {
            formData.append(fileKey, file);
          }
        });
        continue;
      }
      if (Array.isArray(data[key])) {
        for (let i = 0; i < data[key].length; i++) {
          for (var keyInArray in data[key][i]) {
            formData.append(`${key}[${i}].${keyInArray}`, data[key][i][keyInArray]);
          }
        }
        continue;
      }
      if (key === 'orderFiles' && !Array.isArray(data['orderFiles'])) {
        formData.append(key, null);
      }
      data[key] && formData.append(key, data[key]);
    }
    return formData;
  };

  static formatAllDate = (date, format, select) => {
    if (date === '0001-01-01T00:00:00+07:00' || date === '1900-01-01T00:00:00+07:00') {
      return '';
    } else {
      return (
        <span>
          {moment(date).format('DD/MM/YYYY') === 'Invalid date'
            ? ''
            : moment(date).format(select ? `${select}` : format)}
        </span>
      );
    }
  };

  static formatDateFn = (date, select = null) => {
    return this.formatAllDate(date, SHORTEN_DATE_FORMAT, select);
  };

  static formatDateFnCustom = (date, select = null) => {
    return this.formatAllDate(date, SHORTEN_DATE_FORMAT_CUSTOM, select);
  };
  // For Cut Of Time
  static formatDateFoYCOfWBWaitPlan = (date, select = null) => {
    return this.formatAllDate(date, SHORTEN_DATE_FORMAT_WB, select);
  };
  static formatDateHourFn = (date) => {
    return this.formatAllDate(date, SHORTEN_DATE_TIME_FORMAT);
  };
  static formatDateHourWBDelivery = (date) => {
    return this.formatAllDate(date, SHORTEN_DATE_TIME_FORMAT_WB);
  };
  static formatDateHourFn_2 = (date) => {
    return this.formatAllDate(date, DATE_TIME_FORMAT);
  };

  static convertToDate = (date) => {
    if (!date) return null;
    if (
      date.includes('1970-01-01T') ||
      date.includes('0001-01-01T') ||
      date.includes('1900-01-01T') ||
      date.includes('1899-12-31T')
    ) {
      return null;
    }
    return moment(date);
  };
  static checkIsDate = (date) => {
    if (!date) return false;
    if (
      date.toString().includes('1970-01-01T') ||
      date.toString().includes('0001-01-01T') ||
      date.toString().includes('1900-01-01T') ||
      date.toString().includes('1899-12-31T')
    ) {
      return false;
    }
    return true;
  };
  static formatDate = (date) => {
    if (!date) return null;
    if (+date.split('-')[0] < 2000) {
      return null;
    }
    return date && moment(date).format(DATE_FORMAT);
  };
  static formatShortenDate = (date) => {
    if (!date) return null;
    if (+date.split('-')[0] < 2000) {
      return null;
    }
    return date && moment(date).format(SHORTEN_DATE_FORMAT);
  };
  static formatDateTime = (date) => {
    if (!date) return null;
    if (+date.split('-')[0] < 2000) {
      return null;
    }
    return date && moment(date).format(DATE_TIME_FORMAT);
  };
  static formatShortenDateTime = (date) => {
    if (!date) return null;
    if (+date.split('-')[0] < 2000) {
      return null;
    }
    return date && moment(date).format(SHORTEN_DATE_TIME_FORMAT);
  };

  static messageCustom = (type, content) => {
    return message[`${type}`]({
      content: content,
      className: 'custom-class',
      style: {
        marginTop: '8vh',
      },
    });
  };

  static showDateTimeWhiteBoard = (date, data) => {
    if (!date) return null;
    const className = this.renderWarningTimeClass(date) || data.className;
    return (
      <span className={className}>
        {data?.name} {this.formatDateHourFn(date)}
      </span>
    );
  };

  static showDateTimeWhiteBoardWaitingOfDelivery = (date, data) => {
    if (!date) return null;
    const className = this.renderWarningTimeClass(date) || data.className;
    return <span className={className}>{this.formatDateHourWBDelivery(date)}</span>;
  };
  static showDateTimeWhiteBoardLiftOff = (date, data) => {
    if (!date) return null;
    const className = this.renderWarningTimeClass(date) || data.className;
    return <span className={className}>{this.formatDateHourWBDelivery(date)}</span>;
  };

  static renderSubNo(data, value, record, showContForm) {
    return (
      <span className='wb-sub-no-number'>
        <span className='sub-no-number-txt'>{value}</span>
        {record.contNumber && (
          <div
            style={showContForm && { cursor: 'pointer' }}
            className='blue'
            onClick={() => showContForm?.(record)}
          >
            Cont: {record.contNumber}
          </div>
        )}
        {/* Hiển thị xe BX/BR trước đó   */}
        {record?.traceCont && <div>{record?.traceCont}</div>}

        {data.map(
          (item, index) =>
            item.value && (
              <div key={value + index + item.value} className={item.className}>
                {this.showDateTimeWhiteBoard(item.value, item.name)}
              </div>
            )
        )}
      </span>
    );
  }

  static renderSubNoWaitLiftOff(data, value, record, showContForm) {
    return (
      <div className='wb-sub-no-number'>
        {/* Show Dia Diem: Book-Giao Hang */}
        {record?.type === 'Booking' && record?.gh && <div>{record?.gh}</div>}
        {/* Show Dia Diem: Bill: Tra Rong */}
        {record?.type === 'Bill' && record?.tr && <div>{record?.tr}</div>}
        {/* Show SubNo Number */}
        <div className='blue'>{value}</div>
        {/* Show Cont Number */}
        {record.contNumber && (
          <span
            style={showContForm && { cursor: 'pointer' }}
            className='purple-white-board'
            onClick={() => showContForm?.(record)}
          >
            <span>{record.contNumber}</span>
          </span>
        )}

        {/* Hiển thị xe BX/BR trước đó   */}
        {record?.traceCont && (
          <span className='red'>
            {' '}
            <b style={{ color: 'black' }}> - </b>
            {record?.traceCont}
          </span>
        )}
        {/* Hiên tại ẩn ngày vì Bên TKS không thấy hiện ở đây */}
        {/* {data.map(
          (item, index) =>
            item.value && (
              <div key={value + index + item.value} className={item.className}>
                {this.showDateTimeWhiteBoard(item.value, item.name)}
              </div>
            )
        )} */}
      </div>
    );
  }
  static renderSubNoWaitForPlan(data, value, record, showContForm) {
    return (
      <span className='wb-sub-no-number'>
        <span>{value}</span>
        {record.contNumber && (
          <div
            style={showContForm && { cursor: 'pointer' }}
            className='blue'
            onClick={() => showContForm?.(record)}
          >
            Cont: {record.contNumber}
          </div>
        )}
        {/* Hiển thị xe BX/BR trước đó   */}
        {record?.traceCont && <div>{record?.traceCont}</div>}

        {data.map(
          (item, index) =>
            item.value && (
              <div key={value + index + item.value} className={item.className}>
                {this.showDateTimeWhiteBoard(item.value, item.name)}
              </div>
            )
        )}
      </span>
    );
  }

  static renderSubNoWaitLiftOn(data, value, record, showContForm) {
    const renderDate = () => {
      switch (record?.type) {
        case 'Booking':
          return (
            //GC:
            <div>
              YCGC: {Utils.formatDateFoYCOfWBWaitPlan(record.booking_DeliveryContRequestDate)}
            </div>
          );
        case 'Bill':
          return (
            //LC:
            <div>
              YCLC: {Utils.formatDateFoYCOfWBWaitPlan(record.bill_LiftingLadenContRequestDate)}
            </div>
          );

        default:
          return <div>{Utils.formatDateFoYCOfWBWaitPlan(record?.transit_Date)}</div>;
      }
    };
    return (
      <span className='wb-sub-no-number'>
        <span>{value}</span>
        {record.contNumber && (
          <div
            style={showContForm && { cursor: 'pointer' }}
            className='blue'
            onClick={() => showContForm?.(record)}
          >
            Cont: {record.contNumber}
          </div>
        )}
        {/* Hiển thị xe BX/BR trước đó   */}
        {record?.traceCont && <div>{record?.traceCont}</div>}

        {/* Hiển thị Note Cont */}
        {record?.noteCont && <div className='blue'>{record?.noteCont}</div>}

        {/* Hiển thị ngày YCGC/YCLC */}
        {renderDate()}

        {data.map(
          (item, index) =>
            item.value && (
              <div key={value + index + item.value} className={item.className}>
                {this.showDateTimeWhiteBoard(item.value, item.name)}
              </div>
            )
        )}
      </span>
    );
  }

  static formatSubNoForLiltOff(value, record, showContForm) {
    const data = [
      {
        value: record.booking_CargoReadyDate,
        name: DATE_RENDER.BX,
      },
      // {
      //   value: record.book_CargoReadyDate,
      //   name: DATE_RENDER.BX,
      // },
      {
        value: record.bill_EmptyContDate,
        name: DATE_RENDER.BR,
      },
      {
        value: record.bill_LastFreeDayDET,
        name: DATE_RENDER.HHTR,
      },
      {
        value: record.booking_CutOffDateTime,
        name: DATE_RENDER.CM,
      },
      {
        value: record.book_CutOffDateTime,
        name: DATE_RENDER.CM,
      },
    ];
    return this.renderSubNoWaitLiftOff(data, value, record, showContForm);
  }

  static formatSubNoForLiftOn(value, record, showContForm) {
    const data = [
      {
        value: record?.booking_SIDateTime,
        name: DATE_RENDER_WB_LIFT_ON?.SI,
      },
      {
        value: record?.book_SIDateTime,
        name: DATE_RENDER_WB_LIFT_ON.SI,
      },
      {
        value: record?.bill_LastFreeDayDEM,
        name: DATE_RENDER_WB_LIFT_ON?.HHLC,
      },
      {
        value: record?.bill_LastFreeDayDET,
        name: DATE_RENDER_WB_LIFT_ON?.HHTR,
      },
      {
        value: record.booking_CutOffDateTime,
        name: DATE_RENDER_WB_LIFT_ON?.CM,
      },
      {
        value: record.book_CutOffDateTime,
        name: DATE_RENDER_WB_LIFT_ON?.CM,
      },
    ];

    return this.renderSubNoWaitLiftOn(data, value, record, showContForm);
  }
  static formatSubNoForTask(value, record, showContForm) {
    const data = [
      {
        value: record.booking_SIDateTime,
        name: DATE_RENDER.SI,
      },
      {
        value: record.book_SIDateTime,
        name: DATE_RENDER.SI,
      },
      {
        value: record.booking_CargoReadyDate,
        name: DATE_RENDER.BX,
      },
      {
        value: record.book_CargoReadyDate,
        name: DATE_RENDER.BX,
      },
      {
        value: record.bill_EmptyContDate,
        name: DATE_RENDER.BR,
      },
      {
        value: record.bill_LastFreeDayDEM,
        name: DATE_RENDER.HHLC,
      },
      {
        value: record.bill_LastFreeDayDET,
        name: DATE_RENDER.HHTR,
      },
      // {
      //   value: record.booking_CutOffDateTime,
      //   name: DATE_RENDER.CM,
      // },
      // {
      //   value: record.book_CutOffDateTime,
      //   name: DATE_RENDER.CM,
      // },
    ];

    return this.renderSubNoWaitForPlan(data, value, record, showContForm);
  }
  static renderWarningTimeClass = (date) => {
    if (date) {
      var duration = moment.duration(moment(date).diff(moment()));
      if (duration.asHours() <= 12) {
        return ' wb-row-cm-warning lower-12-hours ';
      }
      if (duration.asHours() <= 24) {
        return ' wb-row-cm-warning ';
      }
    }
    return;
  };

  static renderTypeOrderBackground = (type) => {
    switch (type) {
      case 'Booking':
        return ' booking-wb-background ';
      case 'Bill':
        return ' bill-wb-background ';
      default:
        return ' ';
    }
  };

  static dispalySortType = (type) => {
    switch (type) {
      case 'Booking':
        return 'Book';
      case 'Bill':
        return 'Bill';
      default:
        return '';
    }
  };

  static formatSubNoWhiteBoardWaitingOfDelivery(
    value,
    record,
    showContForm,
    noteCont = '',
    showSwapCont
  ) {
    const data = [
      {
        value: record.book_SIDateTime,
        name: DATE_RENDER.SI,
      },
      {
        value: record.book_CargoReadyDate,
        name: DATE_RENDER.BX,
      },
      {
        value: record.bill_EmptyContDate,
        name: DATE_RENDER.BR,
      },
    ];

    return (
      <>
        <div
          style={{ cursor: 'pointer', marginBottom: '3px' }}
          onClick={() => showSwapCont(record)}
        >
          {value}
        </div>
        {record.contNumber && (
          <span className='blue' style={{ cursor: 'pointer' }} onClick={() => showContForm(record)}>
            <span>{record.contNumber}</span>
          </span>
        )}

        {/* Hiển thị xe BX/BR trước đó   */}
        {record?.trace && (
          <span className='red'>
            {' '}
            <b style={{ color: 'black' }}>-</b> {record?.trace}
          </span>
        )}
        {record.gh && (
          <div className={LOCATION_RENDER.GH.className}>
            {/* {LOCATION_RENDER.GH.name}  */}
            {record.gh}
          </div>
        )}
        {/* Hiển thị note Cont */}
        {noteCont && (
          <div
            style={{
              color: 'purple',
              fontWeight: '500',
            }}
          >
            {noteCont}
          </div>
        )}
        {data.map((item, index) => (
          <span key={value + index + item.value}>
            {this.showDateTimeWhiteBoard(item.value, item.name)}
          </span>
        ))}
      </>
    );
  }

  static unique = (arr) => {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      if (i === 0) {
        newArr.push(arr[0]);
      }
      if (i > 0) {
        if (
          !newArr.includes(arr[i].split('-')[0] + '-' + arr[i].split('-')[1]) &&
          !newArr.includes(arr[i].split('-')[1] + '-' + arr[i].split('-')[0])
        ) {
          newArr.push(arr[i]);
        }
      }
    }
    return newArr;
  };
  static uniqueCheckDistance = (arr) => {
    let newArr = [];
    for (let i = 0; i < arr.length; i++) {
      if (i === 0) {
        newArr.push(arr[0]);
      }
      if (i > 0) {
        if (
          !newArr.includes(arr[i].split('-')[0] + '-' + arr[i].split('-')[1]) &&
          !newArr.includes(arr[i].split('-')[1] + '-' + arr[i].split('-')[0])
        ) {
          newArr.push(arr[i]);
        }
      }
    }
    return newArr;
  };

  static getAppUrl = () => {
    let appUrl = process.env.REACT_APP_API_URL;
    return appUrl.endsWith('/') ? appUrl : `${appUrl}/`;
  };

  static renderDateWhiteBoardWaitingOfDelivery(value, record) {
    return (
      <>
        {record?.bill_LastFreeDayDEM &&
          this.showDateTimeWhiteBoardWaitingOfDelivery(
            record?.bill_LastFreeDayDEM,
            DATE_RENDER.HHTR
          )}
        {record?.book_CutOffDateTime &&
          this.showDateTimeWhiteBoardWaitingOfDelivery(record?.book_CutOffDateTime, DATE_RENDER.CM)}
        {/* {record?.trace && <div className='blue trade'> {record?.trace}</div>} */}
      </>
    );
  }
  static renderDateWhiteBoardLiftOff(record) {
    return (
      <>
        {/* Bill - HH Tra Rong  */}
        {record?.bill_LastFreeDayDET &&
          this.showDateTimeWhiteBoardLiftOff(record?.bill_LastFreeDayDET, DATE_RENDER.HHTR)}
        {/* Book - Ngay Cat Mang  */}
        {record?.booking_CutOffDateTime &&
          this.showDateTimeWhiteBoardLiftOff(record?.booking_CutOffDateTime, DATE_RENDER.CM)}
        {record?.book_CutOffDateTime &&
          this.showDateTimeWhiteBoardLiftOff(record?.book_CutOffDateTime, DATE_RENDER.CM)}
      </>
    );
  }

  static formatSubNo(value, record) {
    switch (record.orderType) {
      case 'Booking':
        return (
          <>
            <span>{value}</span>
            {record?.objOrders &&
              this.showDateTimeWhiteBoard(record?.objOrders[0]?.book_SIDateTime, DATE_RENDER.SI)}
            {record?.book_CargoReadyDate &&
              this.showDateTimeWhiteBoard(record?.book_CargoReadyDate, DATE_RENDER.BX)}
          </>
        );
      case 'Bill':
        return (
          <>
            <span>{value}</span>
            {record?.objOrders &&
              this.showDateTimeWhiteBoard(
                record?.objOrders[0]?.bill_LastFreeDayDEM,
                DATE_RENDER.HHLC
              )}
            {record?.objOrders &&
              this.showDateTimeWhiteBoard(
                record?.objOrders[0]?.bill_LastFreeDayDET,
                DATE_RENDER.HHTR
              )}
          </>
        );

      default:
        return (
          <>
            <span>{value}</span>
            {this.showDateTimeWhiteBoard(record?.book_CargoReadyDate, DATE_RENDER.BX)}
          </>
        );
    }
  }

  static renderDateRequest(value, record, key = '', setDataAssignCar) {
    const renderDate = () => {
      switch (value) {
        case 'Booking':
          if (key === 'book_DeliveryContRequestDate') {
            //GC:
            return <span>{Utils.formatDateFnCustom(record[key])}</span>;
          }
          return (
            //GC:
            <span>{Utils.formatDateFnCustom(record.booking_DeliveryContRequestDate)}</span>
          );
        case 'Bill':
          return (
            //LC:
            <span>{Utils.formatDateFnCustom(record.bill_LiftingLadenContRequestDate)}</span>
          );

        default:
          return Utils.formatDateFnCustom(record?.transit_Date);
      }
    };
    return (
      <>
        {renderDate()}
        {record?.romoocCode && <div className='red trade'>Mooc:{record?.romoocCode}</div>}
        {record?.carCode && record?.status !== 9 && (
          <div
            className='blue'
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setDataAssignCar?.(record?.carId)}
          >
            Xe: {record?.carCode}
          </div>
        )}
        {record?.carCode && record?.status === 9 && (
          <div
            className='blue'
            style={{
              cursor: 'pointer',
            }}
          >
            Xe: {record?.carCode}
          </div>
        )}
      </>
    );
  }
  // =============== render Date request for WB Wait For Plan ============

  static renderDateRequestForPlan(value, record, key = '') {
    const renderDate = () => {
      switch (value) {
        case 'Booking':
          if (key === 'book_DeliveryContRequestDate') {
            //GC:
            return <span>{Utils.formatDateFoYCOfWBWaitPlan(record[key])}</span>;
          }
          return (
            //GC:
            <span>{Utils.formatDateFnCustom(record.booking_DeliveryContRequestDate)}</span>
          );
        case 'Bill':
          return (
            //LC:
            <span>{Utils.formatDateFoYCOfWBWaitPlan(record.bill_LiftingLadenContRequestDate)}</span>
          );

        default:
          return Utils.formatDateFnCustom(record?.transit_Date);
      }
    };
    return <>{renderDate()}</>;
  }
  // =============== render Date request for WB Wait For Lift Off ============
  static renderDateRequestForLiftOff(
    record,
    setDataAssignCar,
    showTextTour = true,
    onSelectChangeTour,
    isAssigned = false,
    selected = [],
    dataSelectedLiftOn = []
  ) {
    const isActionPlan = selected?.length > 0 || dataSelectedLiftOn?.length > 0;
    return (
      <>
        {/* Hiển thị Romooc */}
        {record?.romoocCode && (
          <div
            style={{
              color: 'red',
            }}
          >
            {record?.romoocCode}
          </div>
        )}
        {/* Hiển thị Xe Ke Hoach */}
        {record?.carPlanName && !isAssigned && (
          <div
            className='blue'
            style={{
              cursor: 'pointer',
            }}
            onClick={() => !isActionPlan && onSelectChangeTour?.(record)}
          >
            {record?.carPlanName}
          </div>
        )}
        {/* Hiển thị Xe - Sau Khi Điều nhưng chua Hoan thành */}
        {record?.carCode && record?.status !== 9 && (
          <div
            className='blue'
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setDataAssignCar?.(record?.carId)}
          >
            {record?.carCode}
          </div>
        )}
        {/* Hiển thị Xe - Sau Khi Điều và Đã Hoan thành */}
        {record?.carCode && record?.status === 9 && (
          <div
            className='blue'
            style={{
              cursor: 'pointer',
            }}
          >
            {record?.carCode}
          </div>
        )}
        {/* Show Tour Nếu Có Kế Hoạch */}

        {showTextTour && (
          <b
            style={{ color: 'Purple', cursor: 'pointer' }}
            onClick={() => {
              !isAssigned && !isActionPlan && onSelectChangeTour?.(record);
            }}
          >
            ({record?.tour ? record?.tour : ''})
          </b>
        )}
      </>
    );
  }
  // ====================================================================
  // =============== render Date request for WB Wait For Lift On ============
  static renderDateRequestForLiftOn(
    record,
    setDataAssignCar,
    showTextTour = true,
    onSelectChangeTour,
    isAssigned = false,
    selected = [],
    dataSelectedLiftOff = []
  ) {
    const isActionPlan = selected?.length > 0 || dataSelectedLiftOff?.length > 0;
    return (
      <>
        {showTextTour && (
          <b
            style={{ color: 'Purple', cursor: 'pointer' }}
            onClick={() => {
              !isAssigned && !isActionPlan && onSelectChangeTour?.(record);
            }}
          >
            Tour: {record?.tour ? record?.tour : ''}
          </b>
        )}
        {/* Hiển thị Romooc */}
        {record?.romoocCode && (
          <div
            style={{
              color: 'black',
            }}
          >
            {record?.romoocCode}
          </div>
        )}
        {/* Hiển thị Romooc Ke Hoach */}
        {record?.romoocPlanName && !isAssigned && (
          <div
            style={{
              color: 'black',
            }}
          >
            {record?.romoocPlanName}
          </div>
        )}
        {/* Hiển thị Xe Ke Hoach */}
        {record?.carPlanName && !isAssigned && (
          <div
            className='blue'
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              const isAssigned = record?.assignId || record?.childJobName?.[0];
              !isAssigned && !isActionPlan && onSelectChangeTour?.(record);
            }}
          >
            {record?.carPlanName}
          </div>
        )}
        {/* Hiển thị Xe */}
        {record?.carCode && record?.status !== 9 && (
          <div
            className='blue'
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setDataAssignCar?.(record?.carId)}
          >
            {record?.carCode}
          </div>
        )}
        {record?.carCode && record?.status === 9 && (
          <div
            className='blue'
            style={{
              cursor: 'pointer',
            }}
          >
            Xe: {record?.carCode}
          </div>
        )}
      </>
    );
  }
  // ====================================================================
  static formatCustomer(
    _,
    record,
    onClickShowListFile,
    whileBoard = '',
    showCustomer,
    dataNote = '',
    textFile = ''
  ) {
    const disabled = !record?.files?.length;
    return (
      <>
        <span className='pointer' onClick={() => showCustomer?.(record)}>
          {record.customerName}
        </span>

        {whileBoard === 'waiting-for-delivery' && record.lh && (
          <div className={LOCATION_RENDER.LH.className}>
            {LOCATION_RENDER.LH.name} {record.lh}
          </div>
        )}
        {whileBoard === 'waiting-for-delivery' && record.tr && (
          <div className={LOCATION_RENDER.TR.className}>
            {LOCATION_RENDER.TR.name} {record.tr}
          </div>
        )}
        {whileBoard === 'lift-off' && record.tr && (
          <div className={DATE_RENDER.BX.className}>
            {this.showDateTimeWhiteBoard(record.book_CargoReadyDate, DATE_RENDER.BX)}
          </div>
        )}
        {whileBoard === 'lift-off' && record.tr && (
          <div className={DATE_RENDER.BR.className}>
            {this.showDateTimeWhiteBoard(record.bill_EmptyContDate, DATE_RENDER.BR)}
          </div>
        )}
        {dataNote && <p className='red'>{dataNote}</p>}
        {!!record.files?.length && (
          <Button
            shape='circle'
            type='link'
            style={{ color: 'blue', textTransform: 'none', fontWeight: '500', fontSize: '11px' }}
            onClick={() => onClickShowListFile(record)}
            disabled={disabled}
          >
            {!disabled ? `${textFile ? textFile : 'Files'} (${record.files.length})` : ''}
          </Button>
        )}
      </>
    );
  }
  // ====================================================
  static formatCustomerLiftOff(_, record, onClickShowListFile, showCustomer) {
    const disabled = !record?.files?.length;
    return (
      <>
        <span className='pointer' onClick={() => showCustomer?.(record)}>
          {record.customerName}
        </span>
        {!!record.files?.length && (
          <Button
            shape='circle'
            type='link'
            style={{
              color: 'blue',
              textTransform: 'none',
              fontWeight: '500',
              fontSize: '11px',
              margin: 'auto',
            }}
            onClick={() => onClickShowListFile(record)}
            disabled={disabled}
          >
            {!disabled ? `File đính kèm (${record.files.length})` : ''}
          </Button>
        )}
      </>
    );
  }
  // ====================================================
  static formatCustomerLiftOn(_, record, onClickShowListFile, showCustomer) {
    const disabled = !record?.files?.length;
    return (
      <>
        <span className='pointer' onClick={() => showCustomer?.(record)}>
          {record.customerName}
        </span>
        {!!record.files?.length && (
          <Button
            shape='circle'
            type='link'
            style={{
              color: 'blue',
              textTransform: 'none',
              fontWeight: '500',
              fontSize: '11px',
              marginLeft: 'auto',
            }}
            onClick={() => onClickShowListFile(record)}
            disabled={disabled}
          >
            {!disabled ? `File đính kèm (${record.files.length})` : ''}
          </Button>
        )}
      </>
    );
  }
  // ====================================================
  static formatCustomerForWBDelivery(_, record, onClickShowListFile, showCustomer, dataNote = '') {
    const disabled = !record?.files?.length;
    return (
      <>
        <span className='pointer' onClick={() => showCustomer?.(record)}>
          {record.customerName}
        </span>
        {dataNote && <p className='red'>{dataNote}</p>}
        {!!record.files?.length && (
          <Button
            shape='circle'
            type='link'
            style={{
              color: 'blue',
              textTransform: 'none',
              fontWeight: '500',
              fontSize: '11px',
              margin: 'auto',
            }}
            onClick={() => onClickShowListFile(record)}
            disabled={disabled}
          >
            {!disabled ? `File đính kèm (${record.files.length})` : ''}
          </Button>
        )}
      </>
    );
  }
  // ====================================================================
  static formatCustomerWaitForPlan(_, record, onClickShowListFile, showCustomer, dataNote = '') {
    const disabled = !record?.files?.length;
    return (
      <>
        <span className='pointer' onClick={() => showCustomer?.(record)}>
          {record.customerName}
        </span>
        {dataNote && <p className='red'>{dataNote}</p>}
        {!!record.files?.length && (
          <Button
            shape='circle'
            type='link'
            style={{
              color: 'blue',
              textTransform: 'none',
              fontWeight: '500',
              fontSize: '11px',
              margin: 'auto',
            }}
            onClick={() => onClickShowListFile(record)}
            disabled={disabled}
          >
            {!disabled ? `File đính kèm (${record.files.length})` : ''}
          </Button>
        )}
      </>
    );
  }
  // ======================================================================
  static statusActiveAssign = (isActive) => {
    switch (isActive) {
      case true:
        return <Tag color='success'>Sẵn sàng</Tag>;
      default:
        return <Tag color='warning'>Đang Chờ Nhận</Tag>;
    }
  };
  static formatContData = (data) => {
    let format = {};
    for (const [key, value] of Object.entries(data)) {
      if (moment.isMoment(value)) {
        format[key] = value.format();
      } else {
        format[key] = value;
      }
    }
    return format;
  };

  static renderWarningDate = (val, record) => {
    if (!val) {
      return null;
    }
    const date = <div>{moment(val).format('DD/MM H:ss')}</div>;
    if (record.status == TASK_STATUSES.FINISH) {
      return date;
    }
    const timeDiff = moment(val).diff(moment());
    var duration = moment.duration(timeDiff);
    const hoursDuration = duration.asHours();
    const label = hoursDuration > 0 ? 'Còn ' : 'Trễ ';
    if (hoursDuration <= 12) {
      return (
        <>
          {date}
          <b className='warning-date red'>
            {label} {duration.humanize()}
          </b>
        </>
      );
    }
    if (hoursDuration < 24) {
      return (
        <>
          {date}
          <b className='warning-date yellow'>
            {label} {duration.humanize()}
          </b>
        </>
      );
    }
    return <span className='warning-date blue'>{date}</span>;
  };
  static convertToPlain = (valueHTML) => {
    if (!valueHTML) return '';
    return <span dangerouslySetInnerHTML={{ __html: valueHTML }} />;
  };

  static renderCont = (value) => {
    return (
      <div>
        {value?.so_Luong_Cont_20 > 0 && (
          <div>
            <b>20:</b>
            <span style={{ color: 'red' }}>{value?.so_Luong_Cont_20}</span>
          </div>
        )}
        {value?.so_Luong_Cont_40_Cao > 0 && (
          <div>
            <b>40C: </b>
            {value?.so_Luong_Cont_40_Cao}
          </div>
        )}
        {value?.so_Luong_Cont_40_Thap > 0 && (
          <div>
            <b>40T: </b>
            {value?.so_Luong_Cont_40_Thap}
          </div>
        )}
        {value?.so_Luong_Cont_45 > 0 && (
          <div>
            <b>45: </b>
            {value?.so_Luong_Cont_45}
          </div>
        )}
        <div style={{ color: 'red' }}>
          <b>Tổng: </b>
          {value?.so_Luong_Cont_20 +
            value?.so_Luong_Cont_40_Cao +
            value?.so_Luong_Cont_40_Thap +
            value?.so_Luong_Cont_45}
        </div>
      </div>
    );
  };

  static renderContONPWMS = (value) => {
    return (
      <div>
        {value?.So_Luong_Cont_20 > 0 && (
          <div>
            <b>20:</b>
            <span style={{ color: 'red' }}>{value?.So_Luong_Cont_20}</span>
          </div>
        )}
        {value?.So_Luong_Cont_40_Cao > 0 && (
          <div>
            <b>40C: </b>
            {value?.So_Luong_Cont_40_Cao}
          </div>
        )}
        {value?.So_Luong_Cont_40_Thap > 0 && (
          <div>
            <b>40T: </b>
            {value?.So_Luong_Cont_40_Thap}
          </div>
        )}
        {value?.So_Luong_Cont_45 > 0 && (
          <div>
            <b>45: </b>
            {value?.So_Luong_Cont_45}
          </div>
        )}
        <div style={{ color: 'red' }}>
          <b>Tổng: </b>
          {value?.So_Luong_Cont_20 +
            value?.So_Luong_Cont_40_Cao +
            value?.So_Luong_Cont_40_Thap +
            value?.So_Luong_Cont_45}
        </div>
      </div>
    );
  };

  static renderTaskLocations(value, record) {
    const data = [
      {
        key: record?.key + 'tr',
        value: record?.tr,
        name: LOCATION_RENDER.TR,
      },
      {
        key: record?.key + 'lr',
        value: record.lr,
        name: LOCATION_RENDER.LR,
      },

      {
        key: record?.key + 'lh',
        value: record.lh,
        name: LOCATION_RENDER.LH,
      },
      {
        key: record?.key + 'gh',
        value: record.gh,
        name: LOCATION_RENDER.GH,
      },
    ];
    return (
      <>
        {data.map(
          (item, index) =>
            item.value && (
              <div key={value + index + item.value + item.key} className={item.name.className}>
                {item.name.name} {item.value}
              </div>
            )
        )}
      </>
    );
  }

  static displayOrderTypeOrder = (type) => {
    if (!type) return '';
    if (type === 'Booking') {
      return 'Xuất';
    }
    if (type === 'Bill') {
      return 'Nhập';
    }
    if (type === 'Transit') {
      return 'Chuyển kho';
    }
  };

  static countCheckDay = (dateStart, dateEnd, formatDate = 'YYYY-MM-DD') => {
    if (!dateStart || !dateEnd) return 0;
    let start = moment(dateStart, formatDate); //Pick any format
    let end = moment(dateEnd, formatDate); //right now (or define an end date yourself)

    let weekdayCounter = 0;
    while (start <= end) {
      if (start.locale('en').format('ddd') === 'Sun') {
        weekdayCounter++; //add 1 to your counter if its not a weekend day
      }
      start = moment(start, 'YYYY-MM-DD').add(1, 'days'); //increment by one day
    }
    return weekdayCounter;
  };
  static getDaysDiff = (dateStart, dateEnd, formatDate = 'YYYY-MM-DD') => {
    if (!dateStart || !dateEnd) return 0;
    const getDateAsArray = (date) => {
      return moment(date.split(/\D+/), formatDate);
    };
    return getDateAsArray(dateEnd).diff(getDateAsArray(dateStart), 'days') + 1;
  };
}
