import React, { useMemo } from 'react';
import { Button, Badge, DatePicker } from 'antd';
import { RangeDateFilter } from '~components';
import { DATE_FORMAT } from '~commons/constants';

const Header = ({
  expensesIdSelected,
  showModal,
  filter,
  setFilter,
  dissolveDateDefault,
  setDissolveDateDefault,
  clearDataWhenSearch,
}) => {
  const isSubmit = useMemo(() => {
    return !expensesIdSelected?.length;
  }, [expensesIdSelected]);

  const handleChangeDate = (date) => {
    setDissolveDateDefault && setDissolveDateDefault(date);
  };
  const handleChangeFilter = async (data) => {
    await clearDataWhenSearch();
    await setFilter(data);
  };

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <div
        style={{
          flex: '1',
        }}
      >
        <RangeDateFilter query={filter} onChange={handleChangeFilter} />
      </div>
      <div style={{ marginRight: '10px' }}>
        SetUp Ngày GT:{' '}
        <DatePicker
          value={dissolveDateDefault}
          onChange={handleChangeDate}
          placeholder='Ngày hợp đồng'
          style={{ width: '120px' }}
          format={DATE_FORMAT}
          allowClear={false}
        />
      </div>
      <Badge
        count={expensesIdSelected?.length || 0}
        style={{
          marginRight: '70px',
        }}
      >
        <Button
          style={{
            marginRight: '70px',
          }}
          type='primary'
          disabled={isSubmit}
          onClick={showModal}
        >
          Tạo phiếu
        </Button>
      </Badge>
    </div>
  );
};

export { Header };
