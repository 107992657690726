import React, { useState, useRef, useEffect } from 'react';
import { Form, Input, Button, message, Checkbox } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';
import { LocationInput, Popup, Selection } from '~components';
import { formatData } from './fields-data';
import styled from 'styled-components';

const LocationsForm = ({
  position = null,
  addressVal = '',
  fields = [],
  isNew = true,
  id = null,
  status,
  reload,
  onChange,
  setLoading,
  valueSelected,
}) => {
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  const [locationsType, setLocationsType] = useState([]);
  const [address, setAddress] = useState({});
  const [isVisiblePopup, setIsVisiblePopup] = useState(false); //fullAddress
  const [fullAddress, setFullAddress] = useState(''); //
  const [companies, setCompanies] = useState([]);
  const [anchorage, setAnchorage] = useState(status === 1 ? true : false);

  const getCompanies = async (query = '') => {
    const newQuery = {
      Columns: {
        name: query,
      },
    };
    try {
      const res = await ApiServices.getCompanies(query ? newQuery : null);
      if (res?.data?.data) {
        setCompanies(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getLocationTypes = async (query = '') => {
    try {
      const res = await ApiServices.getLocationTypes({ query });
      if (res?.data?.data) {
        setLocationsType(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocationTypes();
    getCompanies();
  }, []);

  const onCompareWithApi = (value, cb) => {
    if (inputRef.current) {
      clearTimeout(inputRef.current);
      cb();
    }
    inputRef.current = setTimeout(async () => {
      try {
        const { data } = await ApiServices.existsCodeLocation(null, value);
        const isUsed = data;
        cb(isUsed);
      } catch (error) {
        console.error(error);
      }
    }, 1000);
  };

  const checkExistsCode = async (_, value) => {
    if (value.length > 2) {
      const ind = fields.findIndex((item) => {
        return item.name[0] === 'code';
      });
      if (isNew === false && value !== fields[ind].value) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Mã Địa Điểm này đã tạo rồi !!!'));
        }
      }

      if (isNew === true) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Mã Địa Điểm này đã tạo rồi !!!'));
        }
      }
    }
  };

  const createLocation = async (data) => {
    if (anchorage) {
      data.status = 1;
    } else {
      data.status = 0;
    }
    try {
      const res = await ApiServices.createLocation(data);
      if (res.data) {
        message.success('Thêm mới Địa Điểm thành công');
        return reload();
      } else {
        message.error('Thêm mới Địa Điểm thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới Địa Điểm thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const updateLocation = async (data) => {
    data.id = id;
    if (anchorage) {
      data.status = 1;
    } else {
      data.status = 0;
    }
    try {
      const res = await ApiServices.updateLocation(id, data);
      if (res.data) {
        message.success('Cập nhật Địa Điểm thành công');
        return reload();
      } else {
        message.error('Cập nhật Địa Điểm thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật Địa Điểm thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };

  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createLocation(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updateLocation(value);
    setLoading(false);
  };
  const getPropertiesLocation = (places) => {
    const place = places.address;
    const location = {
      address: place.formatted_address,
    };
    setFullAddress(place.formatted_address);
    const address = place.address_components;
    address.forEach((item, index) => {
      switch (item.types[0]) {
        case 'street_number':
          location.houseNumber = address[index].long_name;
          break;
        case 'administrative_area_level_1':
          location.city = address[index].long_name;
          break;
        case 'administrative_area_level_2':
          location.district = address[index].long_name;
          break;
        case 'administrative_area_level_3':
          location.ward = address[index].long_name;
          break;
        case 'route':
          location.street = address[index].long_name;
      }
    });
    location.geo = {
      type: 'Point',
      coordinates: [places.coordinates.lng, places.coordinates.lat],
    };
    return location;
  };
  const onSubmitLocation = () => {
    if (address.coordinates && address.address) {
      const location = getPropertiesLocation(address);
      const data = formatData(location);
      form.setFields(data);
    }
    setIsVisiblePopup(false);
  };
  return (
    <Form
      form={form}
      {...layout}
      className='form-input-data'
      name='global_state'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
      scrollToFirstError
    >
      <Form.Item
        // {...itemLayout}
        style={{ marginTop: '10px' }}
        name='code'
        label='Mã Địa Điểm'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Mã Địa Điểm',
          },
          {
            validator: checkExistsCode,
          },
          {
            type: 'string',
            min: 3,
            message: 'Bạn phải nhập ít nhất 3 ký tự',
          },
        ]}
      >
        <Input placeholder='Mã Địa Điểm' />
      </Form.Item>
      <Form.Item
        name='name'
        label='Tên Địa Điểm'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Mã Địa Điểm',
          },
        ]}
      >
        <Input.TextArea placeholder='Tên Địa Điểm' />
      </Form.Item>
      <Form.Item
        name='typeLocationCode'
        label='Loại Địa Điểm'
        rules={[
          {
            required: true,
            message: 'bạn phải nhập Loại Địa Điểm',
          },
        ]}
      >
        <Selection
          labelDefault={valueSelected?.typeLocationName || ''}
          data={locationsType}
          placeholder='Bạn phải chọn Loại Địa Điểm'
          fieldValue='name'
          onSearch={getLocationTypes}
        />
      </Form.Item>
      <Form.Item
        name='address'
        label='Địa chỉ'
        rules={[
          {
            required: true,
            message: 'bạn phải nhập Loại Địa Điểm',
          },
        ]}
      >
        <Input.TextArea
          rows={3}
          placeholder='Địa chỉ Đầy đủ'
          value={fullAddress}
          onClick={() => setIsVisiblePopup(true)}
        />
      </Form.Item>
      <Form.Item name='city' label='Tỉnh/Thành Phố'>
        <Input placeholder='Tỉnh/Thành Phố' />
      </Form.Item>
      <Form.Item name='district' label='Quận/Huyện'>
        <Input placeholder='Quận/Huyện' />
      </Form.Item>
      <Form.Item name='ward' label='Phường/Xã'>
        <Input placeholder='Phường/Xã' />
      </Form.Item>
      <Form.Item
        name='companyId'
        label='Công Ty'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn Công Ty',
          },
        ]}
      >
        <Selection
          labelDefault={valueSelected?.companyName || ''}
          data={companies}
          placeholder='Bạn phải chọn Công Ty'
          fieldValue='name'
          onSearch={getCompanies}
        />
      </Form.Item>
      <Form.Item name='note' label='Ghi Chú'>
        <Input.TextArea rows={3} placeholder='Ghi Chú' />
      </Form.Item>
      <Form.Item name='geo' label='geo' style={{ display: 'none' }}>
        <span className='ant-form-text'>Tạo độ</span>
      </Form.Item>
      <CheckboxStyled checked={anchorage} onChange={(e) => setAnchorage(e.target.checked)}>
        Neo cont tại bãi
      </CheckboxStyled>
      <Form.Item {...tailLayout}>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>

      <Popup
        title='Chọn địa điểm'
        visible={isVisiblePopup}
        closeModel={() => setIsVisiblePopup(false)}
        onSubmit={() => onSubmitLocation()}
        body={<LocationInput position={position} address={addressVal} onChange={setAddress} />}
      />
    </Form>
  );
};

const CheckboxStyled = styled(Checkbox)`
  font-size: 13px;
  margin-bottom: 15px;
  fext-align: right;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export { LocationsForm };
