import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, message, Button } from 'antd';
import styled from 'styled-components';
import ApiServices from '~services';
import useWBApiHook from '~pages/white-board/wb-hook/api-hook';
import { ContNumberForm } from './cont-number-form';
import { ChangeLocationForm } from './change-location-getcont-form';
import { ReportProblemForm } from './report-problem-form';
import { TableData } from './table-data';
import { ChangeTaskJobForm } from './change-task-job-form';
import { ChangeRomoocForm } from './change-romooc-form';
import { CONT_ORDER_TYPES } from '~commons/constants';
import { PrinterOutlined } from '@ant-design/icons';
import { AssignCar } from '~pages/assign-car';

export const AssignCarList = ({ isShowAssignCar, setIsShowAssignCar, idCar = '', title = '' }) => {
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState({});
  const [jobs, setJobs] = useState([]);
  const [job, setJob] = useState(null);
  const [changeCont, setChangeCont] = useState(null);
  const [reportProblem, setReportProblem] = useState(null);
  const [changeTaskJob, setChangeTaskJob] = useState(null);
  const [changeRomooc, setChangeRomooc] = useState(null);

  const [carCode, setCarCode] = useState();
  const [printCarId, setPrintCarId] = useState();
  const [isUpdateContNumber, setIsUpdateContNumber] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const { reloadLiftOn, reloadLiftOff, reloadWaitingOfDelivery, reloadParkingCont } =
    useWBApiHook();

  const handleCancel = () => {
    setIsShowAssignCar();
  };

  // const isShowPrintAssignCar = useMemo(() => {
  //   let isShow = false;
  //   jobs?.map((item) => {
  //     if (isShow) return;
  //     if (item?.isActive) {
  //       isShow = true;
  //       return;
  //     }
  //   });
  //   return isShow;
  // }, [jobs]);

  const getListToAssignCar = async () => {
    setLoading(true);
    if (!idCar) {
      return;
    }
    ApiServices.getListForCar(idCar)
      .then((res) => {
        if (res.data?.data) {
          setDrivers(res.data.data?.driver);
          setJobs(res.data.data?.job);
          setCarCode(res.data.data?.carCode);
        } else {
          setDrivers({});
          setJobs([]);
          setCarCode('');
          setIsShowAssignCar();
          reloadParkingCont();
        }
      })
      .catch((error) => {
        console.error('GET ListToAssignCar Error', error);
      });
    setLoading(false);
  };
  useEffect(() => {
    if (idCar) {
      getListToAssignCar();
    }
  }, [idCar]);

  const cancelAssign = async (idAssign) => {
    try {
      let { data } = await ApiServices.cancelAssign(idAssign);
      if (data) {
        setDrivers({});
        setJobs([]);
        setCarCode('');
        setIsShowAssignCar();
        reloadParkingCont();
        reloadLiftOn();
        reloadLiftOff();
        reloadWaitingOfDelivery();
        message.success('Hủy thành công');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const receivedAssign = async (assignId) => {
    if (loadingButton) return;
    setLoadingButton(true);
    return ApiServices.acceptOrDeclineAssign({
      assignId: assignId.split('-')[0],
      response: true,
      description: '',
    })
      .then((res) => {
        if (res.data) {
          getListToAssignCar();
          message.success('Nhận việc thành công');
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoadingButton(false));
  };

  const onHandleChangeTaskJob = async (job) => {
    const indAssignId = jobs.findIndex((item) => item.id === job.assignId);

    const jobIdOutList = jobs[indAssignId]?.listTaskJobs
      .filter((item) => item.contId === job.contId && item.typeOrder !== CONT_ORDER_TYPES.SUPPORT)
      .map((item) => item.id);

    const dataSelected = { ...job, jobIdOutList };

    setChangeTaskJob(dataSelected);
  };

  const onShowPrint = () => idCar && setPrintCarId(idCar);
  const onClosePrint = () => setPrintCarId();

  return (
    <Modal
      title={title}
      open={isShowAssignCar}
      onCancel={handleCancel}
      width={1500}
      onOk={handleCancel}
    >
      <Modal
        title='In lệnh điều xe'
        open={!!printCarId}
        onCancel={onClosePrint}
        width={1500}
        onOk={onClosePrint}
      >
        <AssignCar idCar={printCarId} carCode={carCode} />
      </Modal>
      <Row
        style={{
          marginBottom: 5,
        }}
      >
        <Col span={6}>
          <b>Biển Số Xe: </b>
          <span>{carCode}</span>
        </Col>
        <Col span={6}>
          <b>Lái Xe: </b>
          <span>{drivers?.driverMainName}</span>
        </Col>
        <Col span={6}>
          <b>Phụ Xe: </b>
          <span>{drivers?.driverSubName}</span>
        </Col>
        <Col span={6}>
          <Button
            icon={<PrinterOutlined />}
            onClick={onShowPrint}
            //  disabled={!isShowPrintAssignCar}
          >
            In Lệnh
          </Button>
        </Col>
      </Row>
      <Container>
        {jobs?.map((item, index) => {
          const isShowAcceptJob =
            (!item?.isActive && index === 0) || (!item?.isActive && jobs?.[index - 1]?.isActive);
          return (
            <TableData
              key={item.id}
              index={index}
              item={item}
              loadingButton={loadingButton}
              setLoadingButton={setLoadingButton}
              cancelAssign={cancelAssign}
              loading={loading}
              reload={getListToAssignCar}
              setJob={setJob}
              setChangeCont={setChangeCont}
              setReportProblem={setReportProblem}
              setChangeRomooc={setChangeRomooc}
              onHandleChangeTaskJob={onHandleChangeTaskJob}
              jobsLength={jobs.length}
              receivedAssign={receivedAssign}
              setIsUpdateContNumber={setIsUpdateContNumber}
              setLoading={setLoading}
              isShowAcceptJob={isShowAcceptJob}
            />
          );
        })}
        <ContNumberForm
          job={job}
          onClose={() => setJob(null)}
          reload={getListToAssignCar}
          isUpdateContNumber={isUpdateContNumber}
          setLoading={setLoading}
        />
        <ChangeLocationForm
          jobsAssignAll={jobs || []}
          job={changeCont}
          onClose={() => setChangeCont(null)}
          reload={getListToAssignCar}
          setLoading={setLoading}
        />
        <ReportProblemForm
          jobReport={reportProblem}
          onClose={() => setReportProblem(null)}
          setLoading={setLoading}
          handleCancelAssignCar={handleCancel}
        />
        <ChangeTaskJobForm
          job={changeTaskJob}
          onClose={() => setChangeTaskJob(null)}
          setLoading={setLoading}
          reload={getListToAssignCar}
        />
        <ChangeRomoocForm
          job={changeRomooc}
          onClose={() => setChangeRomooc(null)}
          setLoading={setLoading}
          reload={getListToAssignCar}
        />
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  max-height: 'calc(100vh - 300px)',
  padding: '10px',
  overflow-y: 'auto',
`;
