import React, { useState } from 'react';
import { PieChart, Pie, Sector, Cell } from 'recharts';

import { REPORT_COLORS as COLORS } from '~commons/constants';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <p x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
      {/* {`${(percent * 100).toFixed(0)}%`} */}
      {`${(percent * 100).toFixed(0)}%`}
    </p>
  );
};
const renderActiveShape = (props, title, total) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, percent, value } =
    props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 15) * cos;
  const my = cy + (outerRadius + 15) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text
        x={cx}
        y={cy - 10}
        dy={8}
        textAnchor='middle'
        style={{ fontWeight: 'bold', fontSize: 13 }}
        fill={COLORS[0]}
      >
        {title}
      </text>
      <text
        x={cx}
        y={cy + 20}
        dy={8}
        textAnchor='middle'
        style={{ fontWeight: 'bold', fontSize: 30 }}
        fill={COLORS[0]}
        // fill={fill}
      >
        {total}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill='#333'
        style={{ fontSize: 14 }}
      >{
        // `${payload.name}: ${value}`
        `SL: ${value}`
      }</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill='#999'>
        {`( ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export default ({ data, title, total }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    // <ResponsiveContainer width="100%" height="100%">
    <PieChart width={250} height={180}>
      <Pie
        data={data}
        cx='50%'
        cy='50%'
        activeIndex={activeIndex}
        activeShape={(props) => renderActiveShape(props, title, total)}
        labelLine={false}
        onMouseEnter={(_, index) => setActiveIndex(index)}
        label={renderCustomizedLabel}
        outerRadius={55}
        innerRadius={40}
        fill='#8884d8'
        dataKey='value'
        paddingAngle={0}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};
