import moment from 'moment';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '~commons/constants';

export const KEYS = [
  'orderType', // "Bill",
  'transportType', //  "FCL",deliveryContRequestDate
  'billNumber', //  "TestBillOrder",
  'customerCode', //  "60b1dc05d33bd61359476dc8",
  'companyId',
  'shippingLineCode', //  "60aca5f5a32e16b886289531",
  'vesselAndVoyage', //  "Tàu U&I",
  'locationDepotReturnCode', //  "60b0d0ca2976e69da6cf50ac",
  'cargoTypeCode', //  "60b4b4bfef42f1213378e626",
  'note', //  "Ghi Chú Đơn Hàng Nhập",

  'locationOfReceiptCode', //  "60b055f5896f7718d7d7e7b1",
  'locationOfDeliveryCode', //  "60b1c5b52251856e74c6d887",
  'locationDepotFromCode', //  "60b055f5896f7718d7d7e7b1",
  'orderFiles',
];
export const KEYS_OF_DATE = [
  'liftingEmptyContRequestDate', //  "2021-06-05", Ngày Yêu Cầu Lấy Rỗng
  'eta', //  "2021-06-03", //Ngày Tàu Chạy
  'deliveryContRequestDate', //  "2021-05-26",
  'atd', //Ngày Tàu Đến
];
export const KEYS_OF_DATE_TIME = [
  'lastFreeDayDEM', //  "2021-06-10T12:39:11.300Z", Ngày Hết Hạn Lưu Cont
  'lastFreeDayDET', //  "2021-06-17T18:39:11.300Z",  Ngày Hết Hạn Trả Rỗng
];

export const KEYS_OF_UPLOAD_FILES = ['orderFiles'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });

  KEYS_OF_DATE.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? moment(data[item], DATE_FORMAT) : null,
    });
  });
  KEYS_OF_DATE_TIME.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? moment(data[item], DATE_TIME_FORMAT) : null,
    });
  });

  KEYS_OF_UPLOAD_FILES.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] || [],
      errors: [],
    });
  });

  return result;
};
