import React, { useCallback, useEffect, useState } from 'react';
import { Button, InputNumber, Col } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

export const Item = ({ data, onChange, onRemove, index, totalRow }) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (data?.total) {
      setTotal(data?.total);
    }
  }, [data]);

  const handleRemoveItem = (id, index) => {
    if (index === 0 && totalRow === 1) {
      setTotal(0);
    }
    onRemove && onRemove(id, index);
  };

  const handleChangeTotal = useCallback(
    (value) => {
      setTotal(value);
      onChange(index, { total: value, date: moment(), id: data.id });
    },
    [total, onChange]
  );

  return (
    <>
      <Col span={4}>
        <InputNumber
          style={{ width: '100%' }}
          placeholder='số lượng Cont'
          min={0}
          value={total}
          onChange={handleChangeTotal}
        />
      </Col>
      <Col span={10}>
        <Button
          color='green'
          type='primary'
          danger
          icon={<DeleteOutlined />}
          onClick={() => handleRemoveItem(data.id, index)}
        >
          Xóa
        </Button>
      </Col>
    </>
  );
};
