import styled from 'styled-components';
import { Button, Badge, Tooltip } from 'antd';
import { CarOutlined, CaretLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateIsActiveDetail } from '~store/reducers/parking-cont/action';
import { TableParkingCont } from './table-parking-cont';

export const ParkingCont = () => {
  const dispatch = useDispatch();
  const { data: parkingContData, isActiveDetail } = useSelector((state) => state.parkingCont);
  const setActive = () => {
    dispatch(updateIsActiveDetail(!isActiveDetail));
  };
  const total = parkingContData?.totalRecords || 0;

  return (
    <>
      <ButtonWrapper>
        <Tooltip title='Cont Chờ Tại Bãi'>
          <Badge count={total} showZero>
            <Button
              type={isActiveDetail ? 'default' : 'primary'}
              icon={isActiveDetail ? <CaretLeftOutlined /> : <CarOutlined />}
              onClick={setActive}
              // disabled={!total}
            />
          </Badge>
        </Tooltip>
      </ButtonWrapper>
      <ContentWrapper>
        <TableParkingCont />
      </ContentWrapper>
    </>
  );
};
const ButtonWrapper = styled.div`
  bottom: 8px;
  left: 16px;
  position: fixed;
  z-index: 999999;
`;
const ContentWrapper = styled.div`
  transition: width 0.5s linear;
`;
