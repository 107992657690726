import React from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import { useHistory } from 'react-router-dom';

const ModalDriverRefuse = ({
  isModalVisible,
  handleOk,
  handleCancel,
  actionRefuse,
  dataRow,
  getCountNotificationNotRead,
}) => {
  const history = useHistory();

  const acceptOrDecline = async (values) => {
    const dataSend = {
      assignId: dataRow.multicastMessage?.data?.AssignId,
      response: actionRefuse,
      description: values.description,
      fromDriver: false,
    };

    try {
      let { data } = await ApiServices.acceptOrDecline(dataSend);
      if (data) {
        handleCancel();
        if (!actionRefuse) {
          message.success('Từ chối yêu cầu thành công');
          await onHandleReadNotification();
          return history.go(0);
        } else {
          message.success('Đồng ý yêu cầu hủy công việc thành công');
          await onHandleReadNotification();
          return history.replace(`/whiteboard?listId=${dataRow.multicastMessage.data.Jobs}`);
        }
      } else {
        if (!actionRefuse) {
          message.error('Từ chối yêu cầu thất bại, vui lòng kiểm tra lại thông tin');
        } else {
          message.error('Đồng ý yêu cầu hủy công việc, vui lòng kiểm tra lại thông tin');
        }
      }
    } catch (error) {
      handleCancel();
      console.log('Error - acceptOrDecline ', error);
      console.error(error);
    }
  };

  const onHandleReadNotification = async () => {
    try {
      let { data } = await ApiServices.readNotification(dataRow.id);
      if (data === 'success') {
        await getCountNotificationNotRead();
      }
    } catch (error) {
      console.log('Error - get CountNotificationNotRead', error);
      console.error(error);
    }
  };

  return (
    <Modal
      title={actionRefuse === true ? 'Đồng Ý Yêu Cầu' : 'Từ Chối Yêu Cầu'}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name='basic'
        // layout="inline"
        onFinish={acceptOrDecline}
      >
        <Form.Item
          name='description'
          label='Lý Do'
          rules={[
            {
              required: true,
              message: 'Bạn phải nhập lý do!!!',
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 10,
            span: 16,
          }}
        >
          <Button type='primary' htmlType='submit' icon={<SendOutlined />}>
            Gửi
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { ModalDriverRefuse };
