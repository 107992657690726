import moment from 'moment';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '~commons/constants';

export const KEYS = [
  'transportType', //  "FCL",deliveryContRequestDate
  'bookingNumber', //  "ZIMUHCM8088446"
  'customerCode', //  "KH",

  'shippingLineCode', //  "hãng tàu",
  'vesselAndVoyage', //  Tên Tàu/Số chuyến "Tàu U&I",
  'cargoTypeCode', //  "60b4b4bfef42f1213378e626",

  'locationOfReceiptCode', //  "Nơi Đóng Hàng",
  'locationOfDeliveryCode', //  "Hạ bãi Thanh lý",
  'portOfDischargeCode', //Cảng đến
  'locationDepotFromCode', //Nơi Lấy Rỗng
  'orderFiles',
  'book_PortOfTransshipmentCode', // cảng chuyển tải
  'note', //  "Ghi Chú Đơn Hàng Nhập",
];
export const KEYS_OF_DATE = [
  'ata', // ngày tàu đi
];
export const KEYS_OF_DATE_TIME = [
  'liftingEmptyContRequestDate', //  "2021-05-30T02:17:35.164Z", Ngày Yêu Cầu Lấy Rỗng
  'cutOffDateTime', //  "2021-05-30T02:17:35.164Z", Ngày Giờ Cắt Máng
  'siDateTime', //  "2021-05-29T02:17:35.164Z", Ngày Giờ Cắt SI
  'putDownContRequestDateBefore', //  "2021-05-28T02:17:35.164Z", Ngày Yêu Cầu Hạ Cont Trước
  'putDownContRequestDateAfter', //  "2021-05-26T02:17:35.164Z",   Ngày Yêu Cầu Hạ Cont Sau
];

export const KEYS_OF_UPLOAD_FILES = ['orderFiles'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });

  KEYS_OF_DATE.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? moment(data[item], DATE_FORMAT) : null,
    });
  });
  KEYS_OF_DATE_TIME.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? moment(data[item], DATE_TIME_FORMAT) : null,
    });
  });

  KEYS_OF_UPLOAD_FILES.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] || [],
      errors: [],
    });
  });

  return result;
};
