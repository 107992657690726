import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { TableLayout } from '~components';
import { Input, Button, Space, DatePicker } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { TableTitle } from '~commons/styles';
import { debounce } from 'lodash';
import { SHORTEN_DATE_FORMAT_CUSTOM } from '~commons/constants';

const objSortType = {
  ascend: 'ASC',
  descend: 'DESC',
};

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  SortColumn: 'code',
  SortColumnDirection: 'ASC',
};

const BillContTracking = () => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState('');
  const [dataList, setDataList] = useState([]);

  const [filter, setFilter] = useState(DEFAULT_QUERIES);

  const [billLiftingLadenContRequestDate, setBillLiftingLadenContRequestDate] = useState(null);
  const [bookLiftingEmptyContAcctualDateSearch, setBookLiftingEmptyContAcctualDateSearch] =
    useState(null);
  const [billEmptyContDate, setBillEmptyContDate] = useState(null);
  const [completeDateSearch, setCompleteDateSearch] = useState(null);
  const [billDateSearch, setBillDateSearch] = useState(null);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getBillContTracking(newQuery)
      .then((res) => {
        if (res?.data) {
          setDataList(res?.data);
        }
      })
      .catch((err) => {
        console.error('Get BillContTracking Error: ', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };
  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);
  // ================= Search New ================
  const excSearch = (date, keySearch) => {
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, keySearch);
  };

  const handleChangeBookLiftingEmptyContRequestDateSearch = (date) => {
    setBillLiftingLadenContRequestDate(date);
    excSearch(date, 'bill_LiftingLadenContRequestDate');
  };
  const handleChangeBookLiftingEmptyContAcctualDateSearch = (date) => {
    setBookLiftingEmptyContAcctualDateSearch(date);
    excSearch(date, 'book_LiftingEmptyContAcctualDate');
  };
  const handleChangeBookCargoReadyDateSearch = (date) => {
    setBillEmptyContDate(date);
    excSearch(date, 'bill_EmptyContDate');
  };
  const handleChangeCompleteDateSearch = (date) => {
    setCompleteDateSearch(date);
    excSearch(date, 'completeDate');
  };
  const handleChangeBookingDateSearch = (date) => {
    setBillDateSearch(date);
    excSearch(date, 'billDate');
  };
  const columns = [
    {
      title: (
        <TableTitle>
          <div>STT</div>
        </TableTitle>
      ),
      width: 50,
      align: 'center',
      dataIndex: 'key',
    },
    {
      title: <TableTitle>Tên Khách Hàng</TableTitle>,
      sorter: true,
      dataIndex: 'customerName',
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      children: [
        {
          title: (
            <TableTitle>
              <Input name='customerName' onChange={onColumnSearch} placeholder='Search' />
            </TableTitle>
          ),
          dataIndex: 'customerName',
          align: 'center',
          width: 150,
        },
      ],
    },
    {
      title: <TableTitle>Subno</TableTitle>,
      sorter: true,
      dataIndex: 'subnoNumber',
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      children: [
        {
          title: (
            <TableTitle>
              <Input name='subnoNumber' onChange={onColumnSearch} placeholder='Search' />
            </TableTitle>
          ),
          width: 150,
          align: 'center',
          dataIndex: 'subnoNumber',
        },
      ],
    },
    {
      title: (
        <TableTitle>
          <div>Số Cont</div>
          <Input name='contNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'contNumber',
    },
    {
      title: (
        <TableTitle>
          <div>Số Seal</div>
          <Input name='sealNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'sealNumber',
    },
    {
      title: (
        <TableTitle>
          <div>Hãng Tàu</div>
          <Input name='shippingLineName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'shippingLineName',
    },
    {
      title: (
        <TableTitle>
          <div>YCLC</div>
          <DatePicker
            value={billLiftingLadenContRequestDate}
            onChange={handleChangeBookLiftingEmptyContRequestDateSearch}
            placeholder='Search'
            style={{ width: '100%', fontSize: '10px' }}
            format={SHORTEN_DATE_FORMAT_CUSTOM}
            allowClear={true}
          />
        </TableTitle>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'bill_LiftingLadenContRequestDate',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: (
        <TableTitle>
          <div>Xe Lấy</div>
          <Input name='trace' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'carUp',
    },
    {
      title: (
        <TableTitle>
          <div>Xe Hạ</div>
          <Input name='trace' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'carDown',
    },
    {
      title: (
        <TableTitle>
          <div>Romooc</div>
          <Input name='rmoocName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'rmoocName',
    },
    {
      title: (
        <TableTitle>
          <div>Ngày LR</div>
          <DatePicker
            value={bookLiftingEmptyContAcctualDateSearch}
            onChange={handleChangeBookLiftingEmptyContAcctualDateSearch}
            placeholder='Search'
            style={{ width: '100%', fontSize: '10px' }}
            format={SHORTEN_DATE_FORMAT_CUSTOM}
            allowClear={true}
          />
        </TableTitle>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'book_LiftingEmptyContAcctualDate',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: (
        <TableTitle>
          <div>Nơi Trả Rỗng</div>
          <Input
            name='bill_LocationDepotReturnName'
            onChange={onColumnSearch}
            placeholder='Search'
          />
        </TableTitle>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'bill_LocationDepotReturnName',
    },
    {
      title: (
        <TableTitle>
          <div>Ngày BH</div>
          <DatePicker
            value={billEmptyContDate}
            onChange={handleChangeBookCargoReadyDateSearch}
            placeholder='Search'
            style={{ width: '100%', fontSize: '10px' }}
            format={SHORTEN_DATE_FORMAT_CUSTOM}
            allowClear={true}
          />
        </TableTitle>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'bill_EmptyContDate',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: (
        <TableTitle>
          <div>Ngày HH</div>
          <DatePicker
            value={completeDateSearch}
            onChange={handleChangeCompleteDateSearch}
            placeholder='Search'
            style={{ width: '100%', fontSize: '10px' }}
            format={SHORTEN_DATE_FORMAT_CUSTOM}
            allowClear={true}
          />
        </TableTitle>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'completeDate',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: (
        <TableTitle>
          <div>Ngày Nhận</div>
          <DatePicker
            value={billDateSearch}
            onChange={handleChangeBookingDateSearch}
            placeholder='Search'
            style={{ width: '100%', fontSize: '10px' }}
            format={SHORTEN_DATE_FORMAT_CUSTOM}
            allowClear={true}
          />
        </TableTitle>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'billDate',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: (
        <TableTitle>
          <div>Tên Chuyến Tàu</div>
          <Input name='vesselAndVoyage' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'vesselAndVoyage',
    },
    {
      title: (
        <TableTitle>
          <div>Lấy Hàng</div>
          <Input name='lh' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'lh',
    },
    {
      title: (
        <TableTitle>
          <div>Giao Hàng</div>
          <Input name='gh' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'gh',
    },
  ];

  const handleExportExcel = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const { data } = await ApiServices.exportBillContTracking();
      if (data) {
        const outputFilename = `Danh_Sach_Cont_Cho_Bao_Rong_Va_Ha_Ngay_${moment().format(
          'DD-MM-YYYY'
        )}.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoading(false);
    }
  };

  const headerContent = (
    <div>
      <h2>6007 - DANH SÁCH CONT CHỜ BÁO RỖNG VÀ HẠ NGÀY {moment().format('DD-MM-YYYY')}</h2>

      <Space>
        <Button
          type='primary'
          icon={<DownloadOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px' }}
          onClick={handleExportExcel}
        >
          Export Excel
        </Button>
      </Space>
    </div>
  );

  const pagination = {
    total: dataList?.totalRecords || 0,
    showSizeChanger: false,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };
  const handleTableChange = (pagination, filters, sorter) => {
    console.log({ pagination, filters, sorter });
    if (sorter?.field && sorter?.order) {
      setFilter((prev) => ({
        ...prev,
        SortColumn: sorter?.field,
        SortColumnDirection: objSortType[sorter?.order],
      }));
    }
  };

  const renderData = dataList?.data?.map((item, index) => {
    item.key = (dataList?.pageNumber - 1) * dataList?.pageSize + index + 1;
    return item;
  });

  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={renderData || []}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      headerContent={headerContent}
      pagination={pagination}
      isDisplayCheckbox={false}
      width={600}
      headerLength='250px'
      onChange={handleTableChange}
    />
  );
};

export { BillContTracking };
