import actionTypes from './type';
import actionTypesLiftOn from '../lift-on/type';
import ApiServices from '~services';
import { DEFAULT_QUERY_WB, TOUR_KINDS } from '~commons/constants';
import store from '~store';
import { updateAssignLoading, updateDataAssign } from '../white-board/action';
import Storage from '~storage';

export function fetchLiftOff(query = DEFAULT_QUERY_WB) {
  if (Storage?.user?.configs?.PageSizeWB > 30) {
    query.PageSize = Storage?.user?.configs?.PageSizeWB;
  }
  return async (dispatch) => {
    try {
      const { data } = await ApiServices.getWhiteBoardThree(query);
      return dispatch({
        data: data,
        type: actionTypes.FETCH_DATA,
      });
    } catch (error) {
      console.error('fetchLiftOn-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_DATA });
  };
}

export function updateOne(data) {
  return (dispatch) => dispatch({ data, type: actionTypes.UPDATE_ONE_DATA });
}
export function loadMore(query = {}) {
  return async (dispatch) => {
    try {
      const { data } = await ApiServices.getWhiteBoardThree(query);
      return dispatch({
        data: data,
        type: actionTypes.LOAD_MORE_DATA,
      });
    } catch (error) {
      console.error('fetchLiftOn-loadMore-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_DATA });
  };
}
export function updateQuery(query) {
  return (dispatch) => dispatch({ query, type: actionTypes.UPDATE_QUERY });
}
export function updateLoading(loading = false) {
  return (dispatch) => dispatch({ loading, type: actionTypes.UPDATE_LOADING });
}
export function updateSelected(selected = []) {
  return (dispatch) => dispatch({ selected, type: actionTypes.UPDATE_SELECTED });
}
export function updateIndexSelected(index = 0) {
  return (dispatch) => {
    dispatch({ index, type: actionTypes.UPDATE_INDEX_SELECTED });
  };
}

export function selectedDataRowLiftOff(selected = [], rowData, isCheckPlan = true) {
  const state = store.getState();
  const dataAssign = state.whiteBoard.dataAssign;
  const selectedDataRowsLiftOff = state.liftOff.selectedDataRows;
  const selectedDataRowsLiftOn = state.liftOn.selectedDataRows;

  return async (dispatch) => {
    // Chỗ này xử lý khi bỏ tích 1 row trong luc assign => cập nhật lại data Assign.
    if (dataAssign.length && selectedDataRowsLiftOff.length > selected.length) {
      const newData = dataAssign.filter(
        (el) => el.kind !== TOUR_KINDS.LIFT_OFF || selected.find((taskIds) => taskIds.find(el.id))
      );
      dispatch(updateDataAssign(newData));
    }
    if (!selected.length) {
      return dispatch({
        data: [],
        type: actionTypes.SELECTED_DATA_ROW,
      });
    }
    try {
      const { data } = await ApiServices.getListByArrayId(selected);
      if (data) {
        const dataMap = selected.map((item) => {
          return data?.data?.filter((dt) => dt.id === item[0] || dt.id === item[1]);
        });

        const newRow = rowData[rowData.length - 1];
        const index = selectedDataRowsLiftOff.length + selectedDataRowsLiftOn.length;
        /// gợi ý thêm 1 cặp tour khi có carPlanName và tour trùng nhau
        if (newRow.carPlanName && newRow.tour && !selectedDataRowsLiftOn.length && isCheckPlan) {
          dispatch(updateAssignLoading(true));
          ApiServices.getListPlanByCar({
            tour: newRow.tour,
            carId: newRow.carPlanName,
            type: TOUR_KINDS.LIFT_ON,
          })
            .then((res) => {
              const dataLiftOn = res.data?.data?.filter((_, i) => i < 2);
              if (!dataLiftOn?.length) return;

              // Chú ý *** actionTypesLiftOn *** from ../lift-on/type
              // update checked row select
              dispatch({
                data: [dataLiftOn.map((el) => el.id).join(',')],
                type: actionTypesLiftOn.UPDATE_SELECTED_KEY,
              });
              // update add assign item
              dispatch({
                data: [dataLiftOn],
                index: index + 1,
                type: actionTypesLiftOn.SELECTED_DATA_ROW,
              });
            })
            .catch((err) => {
              console.error('Get List Plan By Car Error', err);
            })
            .finally(() => dispatch(updateAssignLoading(false)));
        }

        return dispatch({
          index,
          data: dataMap,
          type: actionTypes.SELECTED_DATA_ROW,
        });
      }
    } catch (error) {
      console.error('get List By Array Id Error', error);
    }
    return dispatch({ data: [], type: actionTypes.SELECTED_DATA_ROW });
  };
}
