import React from 'react';
import { Table } from 'antd';

const ExpensesAvailable = ({
  dataExpenses,
  expensesIdSelectedToAdd,
  setExpensesIdSelectedToAdd,
  setExpensesSelectedToAdd,
}) => {
  const columns = [
    {
      title: 'Tên chi phí',
      dataIndex: 'name',
      width: 250,
      fixed: true,
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: expensesIdSelectedToAdd,
    onChange: (selectedRowKeys, selectedRows) => {
      setExpensesIdSelectedToAdd(selectedRowKeys);
      setExpensesSelectedToAdd(selectedRows);
    },
    getCheckboxProps: (record) => ({
      // Column configuration not to be checked
      name: record.id,
    }),
  };
  const handleShowDataRequire = (record) => {
    if (record?.billRequired) {
      return 'order-from-ONP';
    }
  };
  return (
    <Table
      bordered
      size='small'
      // loading={loadingData}
      rowSelection={rowSelection}
      columns={columns}
      dataSource={dataExpenses}
      key='id'
      style={{
        height: '100%',
      }}
      scroll={{
        x: '100%',
        y: 200,
      }}
      pagination={false}
      rowClassName={handleShowDataRequire}
    />
  );
};

export { ExpensesAvailable };
