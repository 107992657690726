import React, { useState, useEffect, useMemo } from 'react';
import { Popconfirm, Button, Space, Badge } from 'antd';
import { Wrapper } from '~commons/styles';
import { TableLayout } from '~components';
import Utils from '~commons/utils';
import ApiServices from '~services';

import { useSelector, useDispatch } from 'react-redux';
import { fetchOrderCreateByTmsNotRead } from '~store/reducers/order-ONP-WMS/action';

export const CreateTMS = ({ onClose }) => {
  const dispatch = useDispatch();
  const { orderCreateByTmsNotReadList } = useSelector((state) => state.orderONPWMS);

  const [loading, setLoading] = useState(false);
  const [ordersFromONP, setOrdersFromONP] = useState([]);
  const [selectedKey, setSelectedKey] = useState([]);

  useEffect(() => {
    setOrdersFromONP(orderCreateByTmsNotReadList || []);
  }, [orderCreateByTmsNotReadList]);

  const countSelected = useMemo(() => {
    return selectedKey?.length;
  }, [selectedKey]);

  const handleAcceptOrderFromONP = async (dataRow) => {
    setLoading(true);
    return ApiServices.acceptReadOrderRvsByTms(dataRow)
      .then((res) => {
        // TH3: tât cả thành công
        if (res?.data?.data === 'success') {
          window.TmsNoti?.success('Xác nhận thành công');
          dispatch(fetchOrderCreateByTmsNotRead());
          onClose && onClose();
        }
      })
      .catch((err) => console.log('accept Read Order Create By Tms err:', err))
      .finally(() => {
        setLoading(false);
        setSelectedKey([]);
      });
  };

  const renderContentRVS = (value) => {
    return Utils.convertToPlain(value);
  };

  const columns = [
    {
      title: 'Số Booking',
      dataIndex: 'code',
      width: 150,
      align: 'center',
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'customerName',
      width: 200,
      align: 'center',
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      width: 300,
      align: 'center',
      render: renderContentRVS,
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'createdOn',
      width: 100,
      align: 'center',
      render: (value) => Utils.formatDateHourFn_2(value),
    },
    {
      title: 'Người tạo',
      dataIndex: 'createdBy',
      width: 100,
      align: 'center',
    },
    {
      title: 'Action',
      fixed: 'right',
      align: 'center',
      width: 140,
      render: (record) => {
        return (
          <Popconfirm
            onConfirm={() => handleAcceptOrderFromONP([record?.id])}
            title='Xác nhận?'
            okText='Đồng ý'
            cancelText='Không'
          >
            <Button type='primary' size='small' style={{ padding: '0px 20px' }}>
              Xác nhận
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const rowSelection = (selectKey) => {
    setSelectedKey(selectKey);
  };

  const headerContent = (
    <Space>
      <Badge count={countSelected}>
        <Popconfirm
          onConfirm={() => handleAcceptOrderFromONP(selectedKey)}
          title={`Xác nhận ${countSelected} đơn hàng?`}
          okText='Đồng ý'
          cancelText='Không'
          disabled={countSelected < 1}
        >
          <Button
            type='primary'
            style={{ padding: '0px 20px', marginLeft: '10px' }}
            disabled={countSelected < 1}
          >
            Xác nhận
          </Button>
        </Popconfirm>
      </Badge>
    </Space>
  );

  return (
    <Wrapper>
      <TableLayout
        columns={columns}
        data={ordersFromONP}
        rowKey='id'
        bordered
        loading={loading}
        selectParent={selectedKey}
        onSelectChange={rowSelection}
        headerContent={headerContent}
        headerLength='300px'
      />
    </Wrapper>
  );
};
