import actionTypes from './type';
import { DEFAULT_QUERY_WB } from '~commons/constants';

const initialState = {
  data: null,
  query: DEFAULT_QUERY_WB,
  loading: false,
  selected: [],
  selectedRows: [],
  selectedDataRows: [],
  indexSelected: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_DATA:
      return {
        ...state,
        data: action.data,
      };

    case actionTypes.UPDATE_ONE_DATA: {
      const newTmpData = state.data.data.map((el) => el);
      const fIndex = newTmpData.findIndex(
        (el) => el.jobId[0] === action.data.id || el.contId === action.data.id
      );
      if (fIndex !== -1) {
        newTmpData[fIndex] = {
          ...newTmpData[fIndex],
          ...action.data,
        };
      }

      return {
        ...state,
        data: {
          ...state.data,
          data: newTmpData,
        },
      };
    }
    case actionTypes.UPDATE_INDEX_SELECTED:
      return {
        ...state,
        indexSelected: action.index,
      };
    case actionTypes.LOAD_MORE_DATA: {
      const moreData = state.data.data.map((el) => el);

      for (var el of action.data.data) {
        moreData.find((item) => item.contId !== el.contId) && moreData.push(el);
      }
      const newData = {
        ...action.data,
        data: moreData,
      };
      return {
        ...state,
        data: newData,
      };
    }
    case actionTypes.UPDATE_QUERY:
      return {
        ...state,
        query: action.query,
      };
    case actionTypes.UPDATE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actionTypes.UPDATE_SELECTED:
      return {
        ...state,
        selected: action.selected.map((el) => el.key),
        selectedRows: action.selected,
      };
    case actionTypes.UPDATE_SELECTED_KEY:
      return {
        ...state,
        selected: action.data,
      };
    case actionTypes.SELECTED_DATA_ROW:
      return {
        ...state,
        selectedDataRows: action.data,
        indexSelected: action.index,
      };
    default:
      return state;
  }
}
