import React, { useEffect, useState, useRef, useMemo } from 'react';
import storage from '~storage';
import { BillForm } from './form-input';

import FieldData from './fields-data';
import { ListContInputNew } from '~components';
import { Button, message } from 'antd';
import styled from 'styled-components';
import ApiServices from '~services';
import { formatBillData } from './api-helper';
import { useHistory } from 'react-router-dom';
import { CONT_ORDER_TYPES } from '~commons/constants';

export const Bill = ({ dataOrderCopy = null, dataOrderDetail = null, onCloseModal, reload }) => {
  const history = useHistory();
  const formRef = useRef();
  const initValue = FieldData({
    transportType: storage?.staticVariables?.TransportType?.TransportType_FCL,
  });
  const [fields, setFields] = useState(initValue);
  const [conts, setConts] = useState({});
  const [contTypes, setContTypes] = useState(initValue);
  const [isLoadingTab, setIsLoadingTab] = useState(false);

  useEffect(() => {
    if (dataOrderCopy) {
      setFields(dataOrderCopy);
    }
  }, [dataOrderCopy]);

  useEffect(() => {
    ApiServices.getContTypes()
      .then((res) => {
        if (res?.data?.data) {
          const dataSort = res?.data?.data?.sort((a, b) => {
            let da = new Date(a.createdOn);
            let db = new Date(b.createdOn);
            return da - db;
          });
          setContTypes(dataSort);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const onSubmitForm = () => {
    formRef.current.submitForm();
  };
  const contsTotalError = useMemo(() => {
    let total = 0;
    for (let el of Object.values(conts)) {
      for (let cont of el.values) {
        if (!cont.date && cont.total) {
          return 'Vui lòng nhập ngày yêu cầu';
        }
        total += cont.total;
      }
    }
    if (total === 0) {
      return 'Vui lòng nhập số lượng cont';
    }
  }, [conts]);
  const onSubmitData = () => {
    ApiServices.createOrderBill(formatBillData(fields, conts))
      .then((res) => {
        if (res.status === 200) {
          message.success('Tạo đơn hàng thành công');
          if (dataOrderCopy) {
            onCloseModal && onCloseModal();
            reload && reload();
          } else {
            history.push('/orders');
          }
        }
      })
      .catch(() => {
        message.error('Tạo đơn hàng thất bại, vui lòng thử lại');
      });
  };
  return (
    <>
      <BillForm
        ref={formRef}
        fields={fields}
        setLoading={setIsLoadingTab}
        onsubmit={onSubmitData}
        onChange={setFields}
        isNew
        dataOrderDetail={dataOrderDetail}
      />
      <i className='red'>***Bạn phải nhập ít nhất 1 số lượng Cont để tạo đơn hàng.</i>
      <ListContInputNew types={contTypes} onChange={setConts} type={CONT_ORDER_TYPES.BILL} />
      <ButtonDiv>
        <ErrorMessage>{contsTotalError}</ErrorMessage>
        <ButtonSubmit
          type='primary'
          disabled={isLoadingTab || Boolean(contsTotalError)}
          onClick={onSubmitForm}
        >
          Lưu
        </ButtonSubmit>
      </ButtonDiv>
    </>
  );
};

const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px 0;
`;
const ButtonSubmit = styled(Button)`
  padding: 4px 24px;
  min-width: 200px;
  font-size: 14px;
  font-weight: bold;
`;
const ErrorMessage = styled.div`
  padding: 4px 24px;
  margin-left: 12px;
  font-size: 13px;
  color: red;
  font-style: italic;
`;
