import { Tabs } from 'antd';
const { TabPane } = Tabs;

const TabDrawer = ({ panes }) => {
  const tabPanes = panes.map((item, index) => {
    return (
      <TabPane tab={item.title} key={index}>
        {item.content}
      </TabPane>
    );
  });
  return <Tabs defaultActiveKey='1'>{tabPanes}</Tabs>;
};

export { TabDrawer };
