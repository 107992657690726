import React, { useEffect, useState } from 'react';
import { TableLayout } from '~components';
import { ContTypeForm } from './cont-types-form';
import FieldData from './fields-data';
import { Button, Popconfirm, message } from 'antd';

import ApiServices from '~services';

const ContTypes = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [data, setData] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);

  const getData = async () => {
    try {
      setLoading(true);
      let { data } = await ApiServices.getContTypes();
      setLoading(false);
      data.data = data.data.map((item, index) => {
        item.key = index;
        return item;
      });
      setData(data.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const setFieldsForm = async (value) => {
    const aataFields = await FieldData(value);
    await setFields(aataFields);
    return aataFields;
  };
  const reload = async () => {
    setSelected(null);
    await getData();
  };

  const getUpdateContTypeForm = async (value, isNew = false, id = null) => {
    const driverForm = await (
      <ContTypeForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        reload={reload}
        onChange={setFields}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: driverForm,
      },
    ]);
  };

  const onRowSelected = async (value) => {
    await setIsLoadingTab(true);
    await setTitleTab(value.code);
    await setFieldsForm(value)
      .then((res) => {
        return getUpdateContTypeForm(res, false, value.id);
      })
      .then(() => setSelected(value));
    setIsLoadingTab(false);
  };
  const deleteContType = async (record) => {
    try {
      const res = await ApiServices.deleteContType(record.id);
      if (res.data) {
        message.success('Xoá Loại Cont thành công');
        return reload();
      }
      message.error('Xoá Loại Cont thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: 'Mã Cont',
      dataIndex: 'code',
      width: 150,
      align: 'center',
      render: (value, record) => (
        <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>
      ),
      sorter: (a, b) => a.code.toString().localeCompare(b.code.toString()),
    },
    {
      title: 'Tên Cont',
      width: 150,
      dataIndex: 'name',
      align: 'center',
      sorter: (a, b) => a.name.toString().localeCompare(b.name.toString()),
    },
    {
      title: 'Công Ty',
      width: 150,
      dataIndex: 'companyName',
      sorter: (a, b) => a.companyName.toString().localeCompare(b.companyName.toString()),
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 75,
      render: (record) => (
        <Popconfirm
          onConfirm={() => deleteContType(record)}
          title='Xoá loại Cont này？'
          okText='Yes'
          cancelText='No'
        >
          <a href='#'>Xoá</a>
        </Popconfirm>
      ),
    },
  ];

  const createDriver = async () => {
    await setTitleTab('');
    await setFieldsForm({})
      .then((res) => getUpdateContTypeForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };
  const headerContent = (
    <>
      <Button type='primary' onClick={createDriver}>
        Thêm Mới
      </Button>
    </>
  );
  return (
    <TableLayout
      columns={columns}
      data={data}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      isDisplayCheckbox={false}
    />
  );
};

export { ContTypes };
