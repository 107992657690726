import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, DatePicker, InputNumber, Row, Col, Button, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { DATE_FORMAT_FOR_COPY } from '~commons/constants';
import { Selection } from '~components';
import ApiServices from '~services';
import moment from 'moment';

const FeeItem = ({ index, data, vat, setFieldsExpense, onDeleteFee, statusTicket }) => {
  const [supplierBill, setSupplierBill] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vatFee, setVatFee] = useState(0);
  const [priceFee, setPriceFee] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [billNumberFee, setBillNumberFee] = useState('');
  const [billDateFee, setBillDateFee] = useState(null);
  const [supplierNameFee, setSupplierNameFee] = useState('');

  const getSupplierBill = async (query = '') => {
    if (loading) return;
    setLoading(true);
    try {
      let { data } = await ApiServices.getSupplierBills({ query });
      if (data?.data) {
        setSupplierBill(data?.data);
      }
    } catch (error) {
      console.log('Get supplier bill failed:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSupplierBill();
  }, [data]);

  useEffect(() => {
    if (data.vat !== vatFee) {
      setVatFee(data.vat);
    }
    if (data.price !== priceFee) {
      setPriceFee(data.price);
    }
    if (data.total !== totalFee) {
      setTotalFee(data.total);
    }
    if (data.billNumber && data.billNumber !== billNumberFee) {
      setBillNumberFee(data.billNumber);
    }
    if (data.billDate && data.billDate !== billDateFee) {
      setBillDateFee(data.billDate);
    }
    if (data.supplierName && data.supplierName !== supplierNameFee) {
      setSupplierNameFee(data.supplierName);
    }
  }, [data]);

  const handleDeleteFee = () => {
    if (!data) return;
    onDeleteFee && onDeleteFee(data);
  };

  function financial(x) {
    return Math.round(Number.parseFloat(x));
  }

  const handleChangeFeeNoVat = useCallback(
    (value) => {
      const currentValue = value ? value : 0;
      const total = currentValue * (1 + vat / 100);

      setPriceFee(currentValue);
      setTotalFee(Number.parseInt(financial(total)));
      setFieldsExpense((prev) => {
        prev[index].price = currentValue;
        prev[index].total = Number.parseInt(financial(total));
        return [...prev];
      });
    },
    [vatFee]
  );
  const handleChangeTotalFee = useCallback(
    (value) => {
      const currentValue = value ? value : 0;
      const priceNoVat = currentValue / (1 + vat / 100);

      setTotalFee(currentValue);
      setPriceFee(Number.parseInt(financial(priceNoVat)));
      setFieldsExpense((prev) => {
        prev[index].total = currentValue;
        prev[index].price = Number.parseInt(financial(priceNoVat));
        return [...prev];
      });
    },
    [vatFee]
  );
  const handleChangeBillNumber = useCallback(
    (event) => {
      setBillNumberFee(event.target.value);
      setFieldsExpense((prev) => {
        prev[index].billNumber = event.target.value;
        return [...prev];
      });
    },
    [billNumberFee]
  );

  const handleChangeBillDate = useCallback(
    (date) => {
      const dateFormat = date ? moment(date).format('YYYY-MM-DD') : null;
      setBillDateFee(date);
      setFieldsExpense((prev) => {
        prev[index].billDate = dateFormat;
        return [...prev];
      });
    },
    [billDateFee]
  );

  const handleChangeSupplier = useCallback(
    (value) => {
      setSupplierNameFee(value);
      setFieldsExpense((prev) => {
        prev[index].supplierName = value;
        return [...prev];
      });
    },
    [supplierNameFee]
  );

  return (
    <div
      key={data.expenseId}
      style={{
        backgroundColor: 'rgb(234, 238, 243)',
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        borderRadius: '10px',
        fontSize: '12px',
        padding: '10px',
        marginBottom: '15px',
        position: 'relative',
      }}
    >
      <div
        style={{
          width: '30px',
          height: '30px',
          // backgroundColor: 'red',
          position: 'absolute',
          top: 0,
          right: 0,
          transform: 'translateY(-30%)',
          borderRadius: '50%',
        }}
      >
        {statusTicket !== 0 && (
          <Popconfirm
            onConfirm={() => handleDeleteFee()}
            title='Bạn chắc chắn Xóa chi phí này?'
            okText='Có'
            cancelText='Không'
          >
            <Button type='primary' shape='circle' danger icon={<CloseOutlined />} />
          </Popconfirm>
        )}
      </div>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item
            label={
              <b>
                <span className='red'>*</span> Tên chi phí
              </b>
            }
          >
            <i
              style={{
                color: 'blue',
                fontSize: '14px',
              }}
            >
              {data?.name}
            </i>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={
              <b>
                <span className='red'>*</span> Phí chưa VAT
              </b>
            }
          >
            <InputNumber
              value={priceFee}
              onChange={handleChangeFeeNoVat}
              status={!priceFee ? 'error' : ''}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '100%' }}
            />
            {!priceFee && (
              <i
                style={{
                  color: 'red',
                  fontSize: '12px',
                }}
              >
                Bạn phải nhập phí chưa VAT
              </i>
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={
              <b>
                <span className='red'>*</span> %VAT
              </b>
            }
          >
            <InputNumber
              disabled
              value={vatFee}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace('%', '')}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={
              <b>
                <span className='red'>*</span> Tổng
              </b>
            }
          >
            <InputNumber
              value={totalFee}
              onChange={handleChangeTotalFee}
              status={!totalFee ? 'error' : ''}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '100%' }}
            />
            {!totalFee && (
              <i
                style={{
                  color: 'red',
                  fontSize: '12px',
                }}
              >
                Bạn phải nhập tổng chi phí
              </i>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item
            label={<b>{data?.billRequired && <span className='red'>*</span>} Số hoá đơn</b>}
          >
            <Input
              placeholder='Số hoá đơn'
              status={data?.billRequired && !billNumberFee ? 'error' : ''}
              value={billNumberFee}
              onChange={handleChangeBillNumber}
            />
            {data?.billRequired && !billNumberFee && (
              <i
                style={{
                  color: 'red',
                  fontSize: '12px',
                }}
              >
                Bạn phải nhập số hoá đơn
              </i>
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={<b>{data?.billRequired && <span className='red'>*</span>} Ngày Hoá đơn</b>}
          >
            <DatePicker
              placeholder='Ngày Hoá đơn'
              style={{ width: '100%' }}
              status={data?.billRequired && !billDateFee ? 'error' : ''}
              format={DATE_FORMAT_FOR_COPY}
              value={billDateFee}
              onChange={handleChangeBillDate}
            />
            {data?.billRequired && !billDateFee && (
              <i
                style={{
                  color: 'red',
                  fontSize: '12px',
                }}
              >
                Bạn phải nhập ngày Hoá đơn
              </i>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={<b>{data?.billRequired && <span className='red'>*</span>} NCC Hoá đơn</b>}
          >
            <Selection
              status={data?.billRequired && !supplierNameFee ? 'error' : ''}
              data={supplierBill}
              placeholder='Chọn NCC Hoá đơn'
              fieldValue='name'
              fieldView='name'
              typeOption='supplier'
              value={supplierNameFee}
              onChange={handleChangeSupplier}
              onSearch={getSupplierBill}
            />
            {data?.billRequired && !supplierNameFee && (
              <i
                style={{
                  color: 'red',
                  fontSize: '12px',
                }}
              >
                Bạn phải nhập nhà cung cấp Hoá đơn
              </i>
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export { FeeItem };
