import React, { useEffect, useState, useRef } from 'react';
import { Button, Popconfirm, message, Input } from 'antd';
import moment from 'moment';
import { TableLayout } from '~components';
import { TireForm } from './tire-form';
import TireFields from './fields-data';

import ApiServices from '~services';

const Tires = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [tires, setTires] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);
  //-------------------------------------------------------------------
  const [searchTerm, setSearchTerm] = useState('');
  const typingTimeoutref = useRef(null);
  //-------------------------------------------------------------------
  const [searchByColumn, setSearchByColumn] = useState({
    code: '',
    carName: '',
    seriNumber: '',
    romoocName: '',
    manufactory: '',
    companyName: '',
    numberKmRun: '',
    price: '',
    depreciation: '',
  });

  const getTires = async () => {
    let dataFilterSend = { ...filter };
    if (Object.keys(dataFilterSend.Columns).length === 0) {
      delete dataFilterSend['Columns'];
    } else {
      const columnJSONSTRING = JSON.stringify(dataFilterSend.Columns);
      dataFilterSend.Columns = columnJSONSTRING;
    }

    try {
      setLoading(true);
      let { data } = await ApiServices.getTires(dataFilterSend);
      setLoading(false);
      let pag = pagination;
      pag.total = data?.totalRecords || 0;
      pag.pageSize = data?.pageSize;
      pag.current = data?.pageNumber;

      data = data?.data || [];
      setTires(data);
      setPagination(pag);
    } catch (error) {
      console.log('Error - get cars', error);
      console.error(error);
    }
  };

  useEffect(() => {
    getTires();
  }, []);

  //----------- Start ----- Pagination Search ---------
  //----------- Pagination --------------------------
  const onChangePagination = (page, size) => {
    let filterTemp = filter;
    filterTemp.PageNumber = page;
    filterTemp.PageSize = size;
    setFilter(filterTemp);
    getTires();
  };
  const [pagination, setPagination] = useState({
    total: 1,
    showSizeChanger: true,
    onChange: onChangePagination,
  });
  const [filter, setFilter] = useState({
    PageNumber: 1,
    PageSize: 10,
    Text: '',
    Columns: '',
  });

  // ------------ Search Text ------------------------
  const handleSearchTermChange = (e) => {
    const val = e.target.value;
    setSearchTerm(val);
    const filterTmp = filter;
    filterTmp.Text = val;

    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }

    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 2) {
        filter.PageNumber = 1;
        setFilter({ ...filterTmp, PageNumber: 1 });
        getTires();
      }
      if (val.length === 0) {
        setFilter({ ...filterTmp, PageNumber: 1 });
        getTires();
      }
    }, 1000);
  };
  // ------------ Search Column ----------------------
  const handleSearchColumnFn = (event, name) => {
    const filterTmp = filter;
    const val = event.target.value;
    let _searchByColumn = { ...searchByColumn };
    _searchByColumn[`${name}`] = val;
    setSearchByColumn(_searchByColumn);
    // Clear timeout
    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }
    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 0) {
        filter.PageNumber = 1;
        let searchColumnFn = { ...filter.Columns };
        searchColumnFn[`${name}`] = val;
        filter.Columns = searchColumnFn;

        setFilter({ ...filterTmp, PageNumber: 1 });
        getTires();
      }
      if (val.length === 0) {
        delete filter.Columns[`${name}`];
        setFilter({ ...filterTmp, PageNumber: 1 });
        getTires();
      }
    }, 1000);
  };
  // ------------- End Search --------------------

  const setFieldsForm = async (value) => {
    const tireFields = await TireFields(value);
    await setFields(tireFields);
    return tireFields;
  };
  const reload = async () => {
    setSelected(null);
    await getTires();
  };

  const getUpdatedTireForm = async (value, isNew = false, id = null, dataSelected) => {
    const tireForm = await (
      <TireForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        reload={reload}
        onChange={setFields}
        dataSelected={dataSelected}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: tireForm,
      },
    ]);
  };

  const onRowSelected = async (value) => {
    const _value = { ...value, useBeginDate: moment(value.useBeginDate) };
    await setIsLoadingTab(true);
    await setTitleTab(_value.code);
    await setFieldsForm(_value)
      .then((res) => {
        return getUpdatedTireForm(res, false, _value.id, value);
      })
      .then(() => setSelected(_value));
    setIsLoadingTab(false);
  };
  const deleteTire = async (record) => {
    try {
      const res = await ApiServices.deleteTire(record.id);
      if (res.data) {
        message.success('Xoá Vỏ Xe thành công');
        return reload();
      }
      message.error('Xoá Vỏ Xe thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      // title: 'Mã Vỏ Xe',
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Mã</div>
          <hr />
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.code}
            onChange={(event) => handleSearchColumnFn(event, 'code')}
          />
        </span>
      ),
      dataIndex: 'code',
      width: 150,
      render: (value, record) => <a onClick={() => onRowSelected(record)}>{value || 'undefine'}</a>,
    },
    {
      // title: 'Biển Số Xe',
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Biển Số Xe</div>
          <hr />
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.carName}
            onChange={(event) => handleSearchColumnFn(event, 'carName')}
          />
        </span>
      ),
      dataIndex: 'carName',
      width: 150,
    },
    {
      // title: 'Số Seri',
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Số Seri</div>
          <hr />
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.seriNumber}
            onChange={(event) => handleSearchColumnFn(event, 'seriNumber')}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'seriNumber',
    },
    {
      // title: 'Số Romooc',
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Số Romooc</div>
          <hr />
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.romoocName}
            onChange={(event) => handleSearchColumnFn(event, 'romoocName')}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'romoocName',
    },
    {
      // title: 'Nhà Sản Xuất',
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Nhà Sản Xuất</div>
          <hr />
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.manufactory}
            onChange={(event) => handleSearchColumnFn(event, 'manufactory')}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'manufactory',
    },
    {
      // title: 'Công Ty',
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Công Ty</div>
          <hr />
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.companyName}
            onChange={(event) => handleSearchColumnFn(event, 'companyName')}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'companyName',
    },
    {
      title: 'Ngày Sử Dụng',
      width: 150,
      dataIndex: 'useBeginDate',
      render: (value) => <span>{moment(value).format('DD/MM/YYYY')}</span>,
      sorter: (a, b) => a.useBeginDate.toString().localeCompare(b.useBeginDate.toString()),
    },
    {
      // title: 'Số Km Đã Chạy',
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Số Km Đã Chạy</div>
          <hr />
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.numberKmRun}
            onChange={(event) => handleSearchColumnFn(event, 'numberKmRun')}
          />
        </span>
      ),
      width: 100,
      dataIndex: 'numberKmRun',
    },
    {
      // title: 'Chi Phí Mua',
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Chi Phí Mua</div>
          <hr />
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.price}
            onChange={(event) => handleSearchColumnFn(event, 'price')}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'price',
    },
    {
      // title: 'Số Tháng Khấu Hao',
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Số Tháng Khấu Hao</div>
          <hr />
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.depreciation}
            onChange={(event) => handleSearchColumnFn(event, 'depreciation')}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'depreciation',
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 75,
      render: (record) => (
        <Popconfirm
          onConfirm={() => deleteTire(record)}
          title='Xoá Vỏ Xe này？'
          okText='Yes'
          cancelText='No'
        >
          <a href='#'>Xoá</a>
        </Popconfirm>
      ),
    },
  ];

  const createTire = async () => {
    await setTitleTab('');
    await setFieldsForm({})
      .then((res) => getUpdatedTireForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };

  const headerContent = (
    <>
      <Button type='primary' onClick={createTire}>
        Thêm Mới
      </Button>
      <Input.Search
        style={{ width: '200px' }}
        className='mg-l-15'
        placeholder='Search'
        value={searchTerm}
        onChange={handleSearchTermChange}
      />
    </>
  );
  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={tires}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      pagination={pagination}
    />
  );
};

export { Tires };
