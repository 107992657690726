export const keyOfForm = [
  'code', // "string",
  'name', // "string",
  'status', // 0,
  'isActive', // true,
];
export default (data) => {
  return keyOfForm.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });
};
