import React, { useEffect, forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Input, DatePicker, Modal, message, Row, Col, Button } from 'antd';
import ApiServices from '~services';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '~commons/constants';
import fieldData from './fields-data';
import Utils from '~commons/utils';
import checkContNumber from '~commons/check-cont-number';
import { UploadFile } from '~components/upload-file';
import { Selection } from '~components';

const initDataForm = fieldData({});
export const FormInput = forwardRef(({ dataInput, reload, onClose }, ref) => {
  const [fields, setFields] = useState(initDataForm);
  const [locations, setLocations] = useState([]);

  const [preview, setPreview] = useState({
    visible: false,
    image: '',
    title: '',
  });

  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    submitForm() {
      form.submit();
    },
  }));

  const getLocations = async (Query = '') => {
    try {
      const res = await ApiServices.getLocations({
        companyId: dataInput?.companyId,
        Columns: { typeLocationName: 'cảng', name: Query },
      });
      if (res?.data?.data) {
        setLocations(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getLocations();
  }, [dataInput]);

  const initData = () => {
    // dataInput
    if (!dataInput) {
      setFields(initDataForm);
      return;
    }

    setFields(fieldData(dataInput));
  };
  useEffect(() => {
    initData();
  }, [dataInput]);

  const validateCheckContNumber = async (_, text) => {
    if (!text) return;
    try {
      const waitMe = () =>
        new Promise((resolve, reject) => {
          const check = checkContNumber(text);
          if (check) {
            resolve();
          } else {
            reject();
          }
        });
      await waitMe();
    } catch (error) {
      return Promise.reject(new Error('Số Cont không hợp lệ !!!'));
    }
  };

  const onSubmitForm = (value) => {
    const locationSelected = locations?.find((item) => item?.id === value?.locationId);
    if (locationSelected) {
      value.locationName = locationSelected?.name;
    } else {
      value.locationName = '';
    }

    delete value.bill_LastFreeDayDET;
    delete value.bill_LastFreeDayDEM;
    value.actualContainerEndDate = value?.bill_AcctualDateDET;

    const res = Utils.formatContData(value);
    let contFiles = [];
    let uploadFiles = [];

    for (let file of value.contFiles) {
      if (file?.originFileObj) {
        uploadFiles.push(file);
        continue;
      }
      contFiles.push(file);
    }

    ApiServices.updateBillCont(dataInput.id, {
      ...res,
      contFiles: contFiles ? contFiles : [],
      files: uploadFiles,
    })
      .then((res) => {
        if (res?.status === 200) {
          message.success('Cập nhật thành công');
          reload();
          onClose && onClose();
        }
      })
      .catch((err) => {
        console.error('ERR - Update Booking Cont', err);
        message.error('Cập nhật thất bại');
      })
      .finally(() => {});
    // onsubmit();
  };

  const showNote = (value, index) => {
    return (
      <div
        key={index}
        style={{
          fontSize: '14px',
          color: 'blue',
        }}
      >
        **{value}
      </div>
    );
  };

  const renderNote = (value) => {
    if (!value) return '';
    const splitNote = value.split(';');
    if (splitNote?.length) {
      return splitNote?.map(showNote);
    } else {
      return <span>{value === 'null' ? '' : value}</span>;
    }
  };

  const handleCancel = () => setPreview((preview) => ({ ...preview, visible: false }));

  return (
    <>
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        // labelAlign='left'
        form={form}
        layout='horizontal'
        name='global_state'
        className='form-booking'
        onFinish={onSubmitForm}
        fields={fields}
        onFieldsChange={(_, allFields) => {
          setFields(allFields);
        }}
      >
        <Form.Item name='customerName' label='Khách hàng'>
          <Input placeholder='Khách hàng' disabled />
        </Form.Item>
        <Form.Item name='subnoNumber' label='Số subNo'>
          <Input placeholder='subNo' disabled />
        </Form.Item>
        <Form.Item
          name='contNumber'
          label='Số Cont'
          rules={[
            // {
            //   required: true,
            //   message: 'Bạn phải nhập số Cont',
            // },
            {
              validator: validateCheckContNumber,
            },
          ]}
        >
          <Input placeholder='Số cont' />
        </Form.Item>
        <Form.Item name='sealNumber' label='Số Seal'>
          <Input placeholder='Số Seal' />
        </Form.Item>
        {dataInput?.onlyView ? (
          <></>
        ) : (
          <Form.Item
            wrapperCol={{
              offset: 10,
              span: 16,
            }}
            className='submit-form-input-data'
          >
            <Button
              style={{
                padding: '0px 40px',
              }}
              type='primary'
              htmlType='submit'
            >
              Lưu
            </Button>
          </Form.Item>
        )}

        <Form.Item
          name='locationId'
          label='Địa điểm TR'
          // rules={[
          //   {
          //     required: true,
          //     message: 'Bạn phải chọn Địa điểm TR',
          //   },
          // ]}
        >
          <Selection
            labelDefault={
              dataInput?.bill_LocationDepotReturnName ? dataInput?.bill_LocationDepotReturnName : ''
            }
            data={locations}
            placeholder='Địa điểm TR'
            fieldValue='name'
            onSearch={getLocations}
          />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              name='bill_LastFreeDayDET'
              label='Ngày HHTR'
            >
              <DatePicker
                style={{
                  width: '100%',
                }}
                placeholder='Ngày HHTR'
                format={DATE_TIME_FORMAT}
                disabled
                showTime
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{
                span: 9,
              }}
              wrapperCol={{
                span: 16,
              }}
              name='bill_LastFreeDayDEM'
              label='Ngày HHLC'
            >
              <DatePicker
                style={{
                  width: '100%',
                }}
                placeholder='Ngày HHLC'
                format={DATE_TIME_FORMAT}
                disabled
                showTime
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              name='bill_LiftingLadenContRequestDate'
              label='Ngày YCLC'
            >
              <DatePicker
                style={{
                  width: '100%',
                }}
                disabled
                placeholder='Ngày YCLC'
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{
                span: 9,
              }}
              wrapperCol={{
                span: 16,
              }}
              name='pickupAcctualDate'
              label='Ngày Thực tế LH'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Bạn phải nhập Ngày Thực tế LH',
              //   },
              // ]}
            >
              <DatePicker
                style={{
                  width: '100%',
                }}
                placeholder='Ngày Thực tế Lấy Hàng'
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              name='deliveryAcctualDate'
              label='Ngày Thực tế GH'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Bạn phải nhập Ngày Thực tế GH',
              //   },
              // ]}
            >
              <DatePicker
                style={{
                  width: '100%',
                }}
                placeholder='Ngày Thực tế GH'
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            {/* <Form.Item
              labelCol={{
                span: 9,
              }}
              wrapperCol={{
                span: 16,
              }}
              name='actualContainerEndDate'
              label='Ngày Thực tế LR'
            >
              <DatePicker
                style={{
                  width: '100%',
                }}
                placeholder='Ngày Thực tế Lấy Rỗng'
                format={DATE_FORMAT}
              />
            </Form.Item> */}
            <Form.Item
              labelCol={{
                span: 9,
              }}
              wrapperCol={{
                span: 16,
              }}
              name='bill_AcctualDateDET'
              label='Ngày Thực tế TR'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Bạn phải nhập Ngày Thực tế TR',
              //   },
              // ]}
            >
              <DatePicker
                style={{
                  width: '100%',
                }}
                placeholder='Ngày Thực tế TR'
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}></Col>

          <Col span={12}></Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='note'
              label='Ghi chú KH'
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
            >
              {renderNote(dataInput?.note)}
              {/* <Input.TextArea rows={2} disabled placeholder='Ghi chú khách hàng' /> */}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='notePlan'
              label='Ghi chú xe ngoài'
              labelCol={{
                span: 9,
              }}
              wrapperCol={{
                span: 16,
              }}
            >
              <Input.TextArea rows={2} placeholder='Ghi chú xe ngoài' />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name='contFiles' label='File Đính Kèm'>
          <UploadFile showUpdated />
        </Form.Item>
      </Form>
      <Modal open={preview.visible} title={preview.title} footer={null} onCancel={handleCancel}>
        <img alt='Preview of upload' style={{ width: '100%' }} src={preview.image} />
      </Modal>
    </>
  );
});
