import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { Input, Typography, DatePicker, Table, Col, Row, Divider } from 'antd';
import ApiServices from '~services';
import { UploadExcelButton } from '~components';
import { TableTitle } from '~commons/styles';
import Utils from '~commons/utils';
import { DATE_FORMAT } from '~commons/constants';

const EXCEL_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/Giai_Tru_Booking_Bill.xlsx';
const { Text } = Typography;

const TicketsImportBookingBill = () => {
  const [loading, setLoading] = useState(false);
  const [dataOrigin, setDataOrigin] = useState([]);
  const [dataRenderTable, setDataRenderTable] = useState([]);
  const [fileNameImport, setFilenameImport] = useState('');
  const [filter, setFilter] = useState(null);
  const [dissolveDate, setDissolveDate] = useState(null);
  const [declarationDate, setDeclarationDate] = useState(null);
  const [error, setError] = useState([]);

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;
      if (query?.soPhieu) {
        if (
          !Utils.convertValSearch(item?.soPhieu)?.includes(Utils.convertValSearch(query?.soPhieu))
        ) {
          checkFilter = false;
        }
      }
      if (query?.soCont) {
        if (
          !Utils.convertValSearch(item?.soCont)?.includes(Utils.convertValSearch(query?.soCont))
        ) {
          checkFilter = false;
        }
      }
      if (query?.nguoiGiaiTru) {
        if (
          !Utils.convertValSearch(item?.nguoiGiaiTru)?.includes(
            Utils.convertValSearch(query?.nguoiGiaiTru)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.nguoiXacNhan) {
        if (
          !Utils.convertValSearch(item?.nguoiXacNhan)?.includes(
            Utils.convertValSearch(query?.nguoiXacNhan)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.ngayKhaiBao) {
        if (moment(item?.ngayKhaiBao).format('YYYY-MM-DD') !== query?.ngayKhaiBao) {
          checkFilter = false;
        }
      }
      if (query?.ngayGiaiTru) {
        if (moment(item?.ngayGiaiTru).format('YYYY-MM-DD') !== query?.ngayGiaiTru) {
          checkFilter = false;
        }
      }

      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    setDataRenderTable(dataFilter);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    []
  );
  useEffect(() => {
    if (dataOrigin?.length) {
      timeoutGetData(filter, dataOrigin);
    }
  }, [filter]);

  const onColumnSearch = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilter((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handleChangeDeclarationDate = (date) => {
    setDeclarationDate(date);
    const dateSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    setFilter((prev) => {
      return { ...prev, ngayKhaiBao: dateSearch };
    });
  };
  const handleChangeDissolveDate = (date) => {
    setDissolveDate(date);
    const dateSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    setFilter((prev) => {
      return { ...prev, ngayGiaiTru: dateSearch };
    });
  };

  const onUploadExcel = (file) => {
    if (loading || !file) return;
    setFilenameImport(file?.name);
    setLoading(true);
    ApiServices.importBookingBillTickets(file)
      .then((res) => {
        if (res?.data?.data?.result?.length > 0) {
          const dataMap = res?.data?.data?.result?.map((item) => {
            item.key = `${item?.aID + item?.soCont}`;
            item.note = '';
            return item;
          });
          setDataOrigin(dataMap);
          setDataRenderTable(dataMap);
          setError([]);
          window.TmsNoti.success('Import thành công');
        }
        if (res?.data?.errors?.length) {
          window.TmsNoti.error('Import thất bại');
          setError(res?.data?.errors);
          setDataOrigin([]);
          setDataRenderTable([]);
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT ERROR', err);
        window.TmsNoti.error('Import thất bại');
        setDataOrigin([]);
        setDataRenderTable([]);
      })
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Số Phiếu
          <Input name='soPhieu' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'soPhieu',
      align: 'center',
      width: 100,
    },
    {
      title: (
        <TableTitle>
          Số Cont
          <Input name='soCont' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'soCont',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Ngày Khai Báo
          <DatePicker
            value={declarationDate}
            onChange={handleChangeDeclarationDate}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'ngayKhaiBao',
      align: 'center',
      width: 130,
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          Ngày Giải Trừ
          <DatePicker
            value={dissolveDate}
            onChange={handleChangeDissolveDate}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'ngayGiaiTru',
      align: 'center',
      width: 130,
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          Người Giải Trừ
          <Input name='nguoiGiaiTru' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'nguoiGiaiTru',
      align: 'center',
      width: 130,
    },
    {
      title: (
        <TableTitle>
          Người Xác Nhận
          <Input name='nguoiXacNhan' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'nguoiXacNhan',
      align: 'center',
      width: 130,
    },
    {
      title: <TableTitle>Số tiền</TableTitle>,
      dataIndex: 'soTien',
      align: 'center',
      width: 130,
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: <TableTitle>Loại Hình</TableTitle>,
      dataIndex: 'loaiHinh',
      align: 'center',
      width: 100,
      render: (value) => Utils.displayOrderTypeOrder(value),
    },
    {
      title: <TableTitle>Ghi Chú</TableTitle>,
      dataIndex: 'note',
      align: 'center',
      width: 100,
    },
  ];

  const renderStringError = (string) => {
    if (!string) return '';
    const splitStringStart = string?.split(`(`);
    const splitStringCenter = splitStringStart?.[1]?.split(')');
    return (
      <i>
        {splitStringStart?.[0]}
        <i
          className='red'
          style={{
            fontWeight: '500',
          }}
        >
          {splitStringCenter?.[0]}
        </i>
        {splitStringCenter?.[1]}
      </i>
    );
  };

  const handleSummary = (pageData) => {
    let sumTotal = 0;

    pageData.forEach((page) => {
      sumTotal += page?.soTien;
    });
    return (
      <>
        <Table.Summary.Row
          style={{
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '13px',
          }}
        >
          <Table.Summary.Cell index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            <Text type='danger' strong>
              Tổng Cộng:
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}>
            <Text type='danger' strong>
              {Utils.formatNumber(sumTotal)}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const calcTotal = dataRenderTable?.reduce((sum, current) => {
    return sum + current?.soTien;
  }, 0);

  return (
    <>
      <h1>IMPORT GIẢI TRỪ BOOKING BILL</h1>

      <div
        style={{
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          padding: '20px 10px',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      >
        <UploadExcelButton
          loading={loading}
          onChange={onUploadExcel}
          templateUrl={EXCEL_TEMPLATE_URL}
          importLabel='Import Excel'
        />

        {fileNameImport && (
          <div
            style={{
              marginTop: '10px',
              fontSize: '13px',
            }}
          >
            <b>Tên File Import:</b> {fileNameImport}
          </div>
        )}
        {error?.length > 0 && (
          <>
            <Divider
              style={{
                marginTop: '0px',
                marginBottom: '0px',
              }}
            >
              <h3>
                NGUYÊN NHÂN IMPORT THẤT BẠI <span className='red'>({error?.length})</span>
              </h3>
            </Divider>

            <Row
              gutter={16}
              style={{
                padding: '0px 10px',
              }}
            >
              {error?.map((item, index) => (
                <Col className='gutter-row' span={12} key={index}>
                  <div
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    <b className='red'>
                      {index + 1}
                      {')'}{' '}
                    </b>
                    {renderStringError(item)}
                  </div>
                </Col>
              ))}
            </Row>
          </>
        )}
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h2>DANH SÁCH GIẢI TRỪ BOOKING - BILL ĐÃ IMPORT</h2>
      </div>
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          fontSize: '17px',
          color: 'red',
        }}
      >
        <b>Tổng Cộng: {Utils.formatNumber(calcTotal)}</b>
      </div>
      <Table
        rowKey='key'
        size='small'
        bordered
        columns={columns}
        dataSource={dataRenderTable}
        loading={loading}
        summary={handleSummary}
      />
    </>
  );
};

export { TicketsImportBookingBill };
