import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Popconfirm, Space, Table } from 'antd';
import Utils from '~commons/utils';
import { DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import useFinishTaskApi from './use-finish-task-api';
import useApiHook from '../wb-hook/api-hook';
import { TASK_STATUSES, CONT_ORDER_TYPES } from '~commons/constants';
// import WBNoteInput from '~components/wb-note-input';
import checkContNumber from '~commons/check-cont-number';
import { Wrapper } from '~commons/styles';
import useDataSelectSearch from '~hooks/use-data-select-search';
import ApiServices from '~services';
import { Selection } from '~components';
import { checkDistanceLocations } from '~store/reducers/white-board/action';

export const TableData = ({
  item,
  loadingButton,
  setLoadingButton,
  cancelAssign,
  loading,
  index,
  reload,
  setJob,
  jobsLength,
  receivedAssign,
  setIsUpdateContNumber,
  setLoading,
  setChangeCont,
  setReportProblem,
  // setChangeRomooc,
  onHandleChangeTaskJob,
  isShowAcceptJob,
}) => {
  const dispatch = useDispatch();

  const {
    finishTask,
    checkIsGetContNumber,
    checkIsDisabledSuccess,
    checkIsGetContNumberIsFinalTask,
    getDepotTasks,
    replaceLocation,
  } = useFinishTaskApi();
  const { reloadWaitingOfDelivery, reloadLiftOn, reloadLiftOff } = useApiHook();
  const finishTaskAssign = async (task) => {
    if (!checkIsGetContNumber(task)) {
      return finishTask(task, reload, setLoading);
    }
  };
  const dataTable = item?.listTaskJobs.map((item, index) => {
    item.key = index + 1;
    item.index = index;
    return item;
  });

  const taskProcessingList = item?.listTaskJobs
    .filter((item) => item.status === TASK_STATUSES.FINISH)
    .map((el) => el.contId);

  // const onHandleClickEditContNumber = (record) => {
  //   setIsUpdateContNumber(true);
  //   setJob(record);
  // };

  // const renderContNumber = (value, record) => {
  //   return (
  //     <Button
  //       type='link'
  //       onClick={() => onHandleClickEditContNumber(record)}
  //       style={{
  //         textAlign: 'left',
  //       }}
  //     >
  //       {checkContNumber(value?.contNumber) ? `Cont: ${value?.contNumber}` : 'Nhập số cont'}
  //       {value?.sealNumber && <div style={{ color: 'red' }}>Seal: {value?.sealNumber}</div>}
  //     </Button>
  //   );
  // };

  const renderNameTask = (value, record) => {
    const isGetContNumber = checkIsGetContNumber(record, dataTable);
    const isContNumberTask = checkIsGetContNumberIsFinalTask(record, dataTable);
    const isAnchorage = record?.objCont?.anchorageId;
    const isShowContSealNumber =
      (record?.sort === 12 && record?.typeOrder === CONT_ORDER_TYPES.SUPPORT) ||
      (record?.typeOrder === CONT_ORDER_TYPES.TRANSIT &&
        record?.sort === 1 &&
        record?.kind === 'C') ||
      (record?.typeOrder === CONT_ORDER_TYPES.BOOKING && record?.sort === 3) ||
      (record?.typeOrder === CONT_ORDER_TYPES.BILL && record?.sort === 3) ||
      (record?.typeOrder === CONT_ORDER_TYPES.BILL && record?.sort === 1);
    return (
      <>
        {isAnchorage && record?.typeOrder === CONT_ORDER_TYPES.BOOKING && record?.sort === 2 && (
          <b style={{ color: 'red' }}>(Lưu Cont Tại Bãi)</b>
        )}
        {isAnchorage && record?.typeOrder === CONT_ORDER_TYPES.BILL && record?.sort === 4 && (
          <b style={{ color: 'red' }}>(Lưu Cont Tại Bãi)</b>
        )}
        <div>{value}</div>
        {(isGetContNumber || isContNumberTask) && (
          <div className='blue'>
            {checkContNumber(record?.objCont?.contNumber) && `Cont: ${record?.objCont?.contNumber}`}
            {record?.objCont?.sealNumber && (
              <div style={{ color: 'red' }}>Seal: {record?.objCont?.sealNumber}</div>
            )}
          </div>
        )}
        {isShowContSealNumber && (
          <span style={{ color: 'red' }}>
            {record?.objCont?.contNumber && `Cont: ${record?.objCont?.contNumber}`}
          </span>
        )}
      </>
    );
  };

  // const onHandleClickEditRomooc = (record) => {
  //   setChangeRomooc(record);
  // };
  // const renderRomooc = (value, record) => (
  //   <>
  //     <Button type='link' onClick={() => onHandleClickEditRomooc(record)}>
  //       {record.objRomooc?.[0]?.code || value}
  //     </Button>
  //     {record?.reverseRomoocId && (
  //       <div>
  //         <i>
  //           Romooc đảo: <b className='red'>{record?.reverseRomoocId}</b>
  //         </i>
  //       </div>
  //     )}
  //   </>
  // );
  const onHandleAction = (record) => {
    setIsUpdateContNumber(false);
    setJob(record);
  };
  const changeLocationGetContNumber = (record) => {
    setChangeCont(record);
  };
  const onChangeReportProblem = (record) => {
    setReportProblem(record);
  };
  const onChangeTaskJob = (record) => {
    onHandleChangeTaskJob && onHandleChangeTaskJob(record);
  };
  const finishJob = (record) =>
    finishTaskAssign(record).then(() => {
      if (record.index === dataTable?.length - 1) {
        reloadLiftOn();
        reloadLiftOff();
        reloadWaitingOfDelivery();
      }
    });
  const renderAction = (_, record) => {
    // get cont for Book
    console.log({ record });
    const isGetContNumber = checkIsGetContNumber(record, dataTable);
    console.log({ isGetContNumber });
    const isDisabled = checkIsDisabledSuccess(record, dataTable);
    const hasChangeTaskJob =
      !taskProcessingList?.includes(record?.contId) &&
      !(record.sort === 3 && record.typeOrder === 'Support');
    const isShowChangeTask = hasChangeTaskJob && CONT_ORDER_TYPES.SUPPORT !== record.typeOrder;
    const isShowTrouble =
      !isDisabled &&
      ![CONT_ORDER_TYPES.TRANSIT, CONT_ORDER_TYPES.SUPPORT].includes(record.typeOrder);
    return (
      <>
        <Popconfirm
          title='Xác nhận?'
          disabled={isDisabled || isGetContNumber}
          onConfirm={() => finishJob(record)}
        >
          {record.status === TASK_STATUSES.FINISH ? (
            <b className='green'>Đã hoàn thành</b>
          ) : record.status === TASK_STATUSES.REPORTED_THE_PROBLEM ? (
            <b className='warning'>Chờ hoàn thành</b>
          ) : (
            <Button
              type='link'
              style={{ padding: '0 0' }}
              disabled={isDisabled}
              onClick={() => isGetContNumber && onHandleAction(record)}
            >
              {isGetContNumber ? 'Nhập Cont' : 'Hoàn thành'}
            </Button>
          )}
        </Popconfirm>

        {isGetContNumber && (record.typeOrder === 'Booking' || record.typeOrder === 'Support') && (
          <>
            <br />
            <Button
              type='link'
              danger
              style={{ padding: '0 0' }}
              disabled={isDisabled}
              onClick={() => changeLocationGetContNumber(record)}
            >
              Đổi địa điểm
            </Button>
          </>
        )}
        {isShowTrouble && (
          <>
            <br />
            <Button
              type='link'
              style={{ padding: '0 0', color: 'orange' }}
              // disabled={isDisabled}
              onClick={() => onChangeReportProblem(record)}
            >
              Báo sự cố
            </Button>
          </>
        )}
        {isShowChangeTask && (
          <>
            <br />
            <Button
              type='link'
              style={{ padding: '0 0' }}
              onClick={() => onChangeTaskJob(record)}
              disabled={record.status === TASK_STATUSES.PENDING}
            >
              Thay đổi công việc
            </Button>
          </>
        )}
      </>
    );
  };

  const renderEndLocation = (val, record) => {
    if (record.status === TASK_STATUSES.REPORTED_THE_PROBLEM) return <div>{val}</div>;

    const depotTasks = getDepotTasks(record, dataTable);
    const taskGetDepotIds = depotTasks?.map?.((el) => el.id);
    const submitChangDepot = (taskIds, locationId) => {
      setLoading(true);
      replaceLocation(taskIds, locationId, reload).then(() => setLoading(false));
    };

    const onOK = (locationId) => {
      if (!locationId) {
        return;
      }
      const dataCheckLocation = [{ begin: depotTasks[0]?.beginLocationCode, end: locationId }];
      if (depotTasks?.length > 1) {
        dataCheckLocation.push({ begin: locationId, end: depotTasks[1]?.endLocationCode });
      }

      setLoading(true);
      return ApiServices.checkIssetDistances(dataCheckLocation)
        .then((res) => {
          if (res.data?.data) {
            return dispatch(
              checkDistanceLocations(res.data?.data, () =>
                submitChangDepot(taskGetDepotIds, locationId)
              )
            );
          }
          return submitChangDepot(taskGetDepotIds, locationId);
        })
        .catch((error) => {
          console.error('check Is set Distances Error', error);
        })
        .finally(() => setLoading(false));
    };
    if (!taskGetDepotIds) {
      return val;
    }
    return <ChangeDepotLocation oldLocation={val} onOK={onOK} />;
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      width: 10,
      align: 'center',
    },
    {
      title: 'Khách Hàng',
      width: 150,
      dataIndex: 'customerName',
      align: 'center',
    },
    {
      title: 'Mooc',
      dataIndex: 'romoocCode',
      width: 100,
      align: 'center',
      render: (value, record) => <span> {record.objRomooc?.[0]?.code || value}</span>,
      // render: renderRomooc,
    },
    {
      title: 'Công Việc',
      dataIndex: 'name',
      width: 150,
      align: 'center',
      render: renderNameTask,
    },
    {
      title: 'Booking / Bill',
      dataIndex: 'code',
      width: 250,
      align: 'center',
    },
    {
      title: 'Khởi Hành',
      dataIndex: 'beginLocationName',
      width: 200,
      align: 'center',
    },
    {
      title: 'Kết Thúc',
      dataIndex: 'endLocationName',
      width: 200,
      align: 'center',
      render: renderEndLocation,
    },
    {
      title: 'Km',
      dataIndex: 'distance',
      with: 60,
      align: 'center',
    },
    // {
    //   title: 'Số Cont/Seal',
    //   dataIndex: 'objCont',
    //   render: (value, record) => renderContNumber(value, record),
    // },
    {
      title: 'Ghi Chú',
      dataIndex: 'note',
      align: 'center',
      width: 200,
      // render: (value, record) => (
      //   <WBNoteInput
      //     defaultValue={value}
      //     scroll={400}
      //     onSubmit={(data) => onUpdateNote(record?.id, data, reload, setLoading)}
      //   />
      // ),
    },
    {
      title: '#',
      fixed: 'right',
      width: 150,
      dataIndex: 'action',
      render: renderAction,
    },
  ];
  const showAccept =
    (!item?.isActive || item.status === TASK_STATUSES.SENDED_DRIVER) &&
    index === jobsLength * 1 - 1;
  const isShowReceived = !item?.isActive;

  return (
    <div key={item.id}>
      <div
        style={{
          display: 'flex',
          marginBottom: 5,
          marginTop: 10,
        }}
      >
        <Space>
          <i>
            <b style={{ color: 'blue' }}>{item?.code}</b>
          </i>
          {Utils.statusActiveAssign(item?.isActive)}
        </Space>
        {isShowReceived && isShowAcceptJob && (
          <Space>
            <Button
              type='primary'
              size='small'
              icon={<CheckCircleOutlined />}
              loading={loadingButton}
              onClick={() => {
                receivedAssign(item.id);
              }}
            >
              Nhận Việc
            </Button>
          </Space>
        )}
        {showAccept && (
          <Button
            style={{
              marginLeft: 'auto',
            }}
            color='error'
            danger
            size='small'
            icon={<DeleteOutlined />}
            loading={loadingButton}
            onClick={async () => {
              setLoadingButton(true);
              await cancelAssign(item.id);
              setLoadingButton(false);
            }}
          >
            Hủy
          </Button>
        )}
      </div>
      <Table
        columns={columns}
        dataSource={dataTable}
        loading={loading}
        pagination={false}
        bordered={true}
        rowKey='id'
        size='small'
        // scroll={{
        //   x: '100%',
        //   y: '100%',
        // }}
      />
    </div>
  );
};

const ChangeDepotLocation = ({ oldLocation, onOK }) => {
  const [location, setLocation] = useState();
  const [locations, getLocations] = useDataSelectSearch(ApiServices.getLocations);
  const onSearchLocation = (Query) => getLocations({ Query }, 'name');
  const onConfirm = () => onOK?.(location);

  return (
    <Popconfirm
      onConfirm={onConfirm}
      title={
        <Wrapper>
          <Space direction='vertical'>
            <Selection
              placeholder='Chọn Địa Điểm LR mới'
              data={locations}
              onSearch={onSearchLocation}
              value={location}
              onChange={setLocation}
            />
          </Space>
        </Wrapper>
      }
    >
      <a href='#' type='link'>
        {oldLocation}
      </a>
    </Popconfirm>
  );
};
