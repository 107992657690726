import React, { useEffect, forwardRef, useImperativeHandle, useState, useRef } from 'react';
import { Form, Input, Row, Col, DatePicker, Divider } from 'antd';
import ApiServices from '~services';
import { Selection } from '~components';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '~commons/constants';
import moment from 'moment';
// import { useExistsCodeValidator } from '~hooks';
import Validates from '~commons/validates';
import { UploadFileNew } from '~components/upload-file-new';

const BookingForm = forwardRef(
  (
    {
      fields,
      onChange,
      onsubmit,
      orderDetail,
      isNew = false,
      showDetail = false,
      dataOrderDetail = null,
    },
    ref
  ) => {
    const inputRef = useRef(null);
    const [locations, setLocations] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [shippingLines, setShippingLines] = useState([]);
    const [cargoTypes, setCargoTypes] = useState([]);
    const [ports, setPorts] = useState([]);

    // const checkExistOrderApi = (val) =>
    //   !isNew ? Promise.resolve(false) : ApiServices.checkOrderExistCode(val);
    // const existsCodeValidator = useExistsCodeValidator(
    //   checkExistOrderApi,
    //   'Mã booking đã tồn tại!'
    // );

    const onCompareWithApi = (value, cb) => {
      if (inputRef.current) {
        clearTimeout(inputRef.current);
        cb();
      }
      inputRef.current = setTimeout(async () => {
        try {
          const { data } = await ApiServices.checkOrderExistCode(value);
          const isUsed = data;
          cb(isUsed);
        } catch (error) {
          console.error(error);
        }
      }, 1000);
    };

    const checkExistsCode = async (_, valueInput) => {
      const value = valueInput.trim();
      if (value.length > 2) {
        if (isNew === false) {
          if (dataOrderDetail) {
            const ind = fields.findIndex((item) => {
              return item.name[0] === 'code';
            });
            if (value !== fields[ind]?.value) {
              try {
                const waitMe = () =>
                  new Promise((resolve, reject) => {
                    onCompareWithApi(value, (isUsed) => {
                      if (typeof isUsed !== 'undefined') {
                        if (isUsed) reject();
                        resolve();
                      }
                    });
                  });
                await waitMe();
              } catch (error) {
                return Promise.reject(new Error('Mã Số Booking này đã tạo rồi !!!'));
              }
            }
          }
          if (orderDetail) {
            if (value !== orderDetail?.code) {
              try {
                const waitMe = () =>
                  new Promise((resolve, reject) => {
                    onCompareWithApi(value, (isUsed) => {
                      if (typeof isUsed !== 'undefined') {
                        if (isUsed) reject();
                        resolve();
                      }
                    });
                  });
                await waitMe();
              } catch (error) {
                return Promise.reject(new Error('Mã Số Booking này đã tạo rồi !!!'));
              }
            }
          }
        }

        if (isNew === true) {
          try {
            const waitMe = () =>
              new Promise((resolve, reject) => {
                onCompareWithApi(value, (isUsed) => {
                  if (typeof isUsed !== 'undefined') {
                    if (isUsed) reject();
                    resolve();
                  }
                });
              });
            await waitMe();
          } catch (error) {
            return Promise.reject(new Error('Mã Số Booking này đã tạo rồi !!!'));
          }
        }
      }
    };

    const [form] = Form.useForm();
    useImperativeHandle(ref, () => ({
      submitForm() {
        form.submit();
      },
    }));

    const getCustomers = async (query = null) => {
      try {
        const { data } = await ApiServices.getCustomers({ query });
        setCustomers(data);
      } catch (err) {
        console.error('ERR - Get Customers', err);
      }
    };
    const getShippingLines = async (Query = null) => {
      try {
        const { data } = await ApiServices.getShippingLines({ Query });
        setShippingLines(data.data);
      } catch (err) {
        console.error('ERR - Get Shipping Lines', err);
      }
    };
    const getLocations = async (Query = null) => {
      try {
        const { data } = await ApiServices.getLocations({ Query });
        setLocations(data.data);
      } catch (err) {
        console.error('ERR - Get Shipping Lines', err);
      }
    };
    const getCargoTypes = async (Query = null) => {
      try {
        const { data } = await ApiServices.getCargoTypes({ Query });
        setCargoTypes(data.data);
      } catch (err) {
        console.error('ERR - Get Shipping Lines', err);
      }
    };
    const getPorts = async (Query = null) => {
      try {
        const { data } = await ApiServices.getPorts({ Query });
        setPorts(data.data);
      } catch (err) {
        console.error('ERR - Get Shipping Lines', err);
      }
    };

    useEffect(() => {
      getCustomers();
      getShippingLines();
      getLocations();
      getCargoTypes();
      getPorts();
    }, []);

    const onSubmitForm = () => {
      onsubmit();
    };

    return (
      <>
        <Form
          form={form}
          layout='vertical'
          name='global_state'
          className='form-booking'
          onFinish={onSubmitForm}
          fields={fields}
          onFieldsChange={(_, allFields) => onChange(allFields)}
        >
          <h2 style={{ marginTop: '-10px' }}>
            {' '}
            {orderDetail ? (showDetail ? 'Xem chi tiết' : 'Cập nhật ') : 'Tạo '} Booking
          </h2>
          <Divider style={{ margin: '4px 0 8px 0' }} />
          <Row gutter={8}>
            <Col className='gutter-row' span={12}>
              <Form.Item
                name='bookingNumber'
                label='Số Booking'
                rules={[
                  {
                    required: true,
                    message: 'Bạn phải nhập Số Booking',
                  },
                  { validator: checkExistsCode },
                ]}
              >
                <Input placeholder='Số Booking' />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={12}>
              <Form.Item
                name='customerCode'
                label='Khách hàng'
                rules={[
                  {
                    required: true,
                    message: 'Bạn phải nhập Mã Khách hàng',
                  },
                ]}
              >
                <Selection
                  data={customers}
                  labelDefault={
                    dataOrderDetail ? dataOrderDetail?.customerName : orderDetail?.customerName
                  }
                  placeholder='Mã Khách hàng'
                  onSearch={getCustomers}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={12}>
              <Form.Item name='putDownContRequestDateBefore' label='Ngày Yêu Cầu Hạ Cont Trước'>
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  placeholder='Ngày Yêu Cầu Hạ Cont Trước'
                  style={{ width: '100%' }}
                  format={DATE_TIME_FORMAT}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={12}>
              <Form.Item name='putDownContRequestDateAfter' label='Ngày Yêu Cầu Hạ Cont Sau'>
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  placeholder='Ngày Yêu Cầu Hạ Cont Sau'
                  style={{ width: '100%' }}
                  format={DATE_TIME_FORMAT}
                />
              </Form.Item>
            </Col>

            <Col className='gutter-row' span={12}>
              <Form.Item
                name='siDateTime'
                label='Ngày Giờ Cắt SI'
                rules={[
                  {
                    required: true,
                    message: 'Bạn phải nhập Ngày Giờ Cắt SI',
                  },
                ]}
              >
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  style={{ width: '100%' }}
                  placeholder='Ngày Giờ Cắt SI'
                  format={DATE_TIME_FORMAT}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={12}>
              <Form.Item
                name='cutOffDateTime'
                label='Ngày Giờ Cắt Máng'
                rules={[
                  {
                    required: true,
                    message: 'Bạn phải nhập Ngày Giờ Cắt Máng',
                  },
                  ...Validates.validateDatePicker(
                    'Ngày Giờ Cắt SI',
                    null,
                    form.getFieldValue().siDateTime
                  ),
                ]}
              >
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  style={{ width: '100%' }}
                  placeholder='Ngày Giờ Cắt Máng'
                  format={DATE_TIME_FORMAT}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={12}>
              <Form.Item name='liftingEmptyContRequestDate' label='Ngày Yêu Cầu Lấy Rỗng'>
                <DatePicker
                  placeholder='Ngày Yêu Cầu Lấy Rỗng'
                  // min={moment()}
                  showTime={{ format: 'HH:mm' }}
                  style={{ width: '100%' }}
                  format={DATE_TIME_FORMAT}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={12}>
              <Form.Item name='ata' label='Ngày Tàu Chạy'>
                <DatePicker
                  placeholder='Ngày Tàu Chạy'
                  min={moment()}
                  style={{ width: '100%' }}
                  format={DATE_FORMAT}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={12}>
              <Form.Item
                name='shippingLineCode'
                label='Hãng tàu'
                rules={[
                  {
                    required: true,
                    message: 'Bạn phải nhập Hãng tàu',
                  },
                ]}
              >
                <Selection
                  labelDefault={
                    dataOrderDetail
                      ? dataOrderDetail?.shippingLineName
                      : orderDetail?.shippingLineName
                  }
                  data={shippingLines}
                  placeholder='Mã Hãng tàu'
                  onSearch={getShippingLines}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={12}>
              <Form.Item name='vesselAndVoyage' label='Tên Tàu/Số chuyến'>
                <Input placeholder='Tên Tàu/Số chuyến' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col className='gutter-row' span={12}>
              <Form.Item
                name='locationOfReceiptCode'
                label='Nơi Đóng Hàng'
                rules={[
                  {
                    required: true,
                    message: 'Bạn phải nhập Ngày Nơi Đóng Hàng',
                  },
                ]}
              >
                <Selection
                  data={locations}
                  placeholder='Nơi Đóng Hàng'
                  labelDefault={
                    dataOrderDetail
                      ? dataOrderDetail?.locationOfReciptName
                      : orderDetail?.locationOfReciptName
                  }
                  fieldValue='name'
                  onSearch={getLocations}
                />
              </Form.Item>
            </Col>

            <Col className='gutter-row' span={12}>
              <Form.Item
                name='locationOfDeliveryCode'
                label='Hạ bãi Thanh lý'
                rules={[
                  {
                    required: true,
                    message: 'Bạn phải nhập Hạ bãi Thanh lý',
                  },
                ]}
              >
                <Selection
                  data={locations}
                  labelDefault={
                    dataOrderDetail
                      ? dataOrderDetail?.locationOfDeliveryName
                      : orderDetail?.locationOfDeliveryName
                  }
                  placeholder='Hạ bãi Thanh lý'
                  fieldValue='name'
                  onSearch={getLocations}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={12}>
              <Form.Item name='locationDepotFromCode' label='Nơi Lấy Rỗng'>
                <Selection
                  data={locations}
                  labelDefault={
                    dataOrderDetail
                      ? dataOrderDetail?.locationDepotFromName
                      : orderDetail?.locationDepotFromName
                  }
                  placeholder='Nơi Lấy Rỗng'
                  onSearch={getLocations}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={12}>
              <Form.Item name='portOfDischargeCode' label='Cảng Đến'>
                <Selection
                  data={ports}
                  labelDefault={
                    dataOrderDetail
                      ? dataOrderDetail?.book_PortOfDischargeName
                      : orderDetail?.book_PortOfDischargeName
                  }
                  placeholder='Cảng Đến'
                  onSearch={getPorts}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={12}>
              <Form.Item name='book_PortOfTransshipmentCode' label='Cảng Chuyển Tải'>
                <Selection
                  data={ports}
                  labelDefault={
                    dataOrderDetail
                      ? dataOrderDetail?.book_PortOfTransshipmentName
                      : orderDetail?.book_PortOfTransshipmentName
                  }
                  placeholder='Chuyển Tải'
                  onSearch={getPorts}
                />
              </Form.Item>
            </Col>

            <Col className='gutter-row' span={12}>
              <Form.Item name='cargoTypeCode' label='Loại Hàng Hóa'>
                <Selection
                  data={cargoTypes}
                  labelDefault={
                    dataOrderDetail ? dataOrderDetail?.cargoTypeName : orderDetail?.cargoTypeName
                  }
                  placeholder='Loại Hàng Hóa'
                  fieldValue='name'
                  onSearch={getCargoTypes}
                  autoSelectFirstOption
                />
              </Form.Item>
            </Col>
            {/* <Col className='gutter-row' span={12}>
              <Form.Item name='transportType' label='Loại Vận Chuyển'>
                <TransportTypeSelect disabled />
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={8}>
            <Col className='gutter-row' span={12}>
              <Form.Item name='note' label='Ghi chú'>
                <Input.TextArea placeholder='Ghi chú' />
              </Form.Item>
            </Col>

            <Col className='gutter-row' span={12}>
              {' '}
              <Form.Item
                name='orderFiles'
                label='File Đính Kèm'
                rules={[
                  {
                    required: true,
                    message: 'Bạn phải thêm File đính kèm!',
                  },
                ]}
              >
                <UploadFileNew />
              </Form.Item>
            </Col>
          </Row>

          {/* <Divider style={{ margin: '0 0 4px 0' }} orientation="left" children="Nhập số cont" /> */}
        </Form>
      </>
    );
  }
);

export { BookingForm };
