import React, { useState, useEffect, useCallback } from 'react';
import { Input, Col, Row, Popconfirm, Button, Tooltip } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Selection } from '~components';
import ApiServices from '~services';
import { uid } from 'uid';
import { FormJobSupportTour } from './form-job-support-tour';
import checkContNumber from '~commons/check-cont-number';
import { debounce } from 'lodash';

const TRANSIT_CONT_DATA = [0];

const FormTourItem = ({
  tourIndex,
  hasCreateNew = false,
  handleAddNewTour,
  handleDeleteTour,
  assignTransitTourList = [],
  setAssignTransitTourList,
  dataItem,
}) => {
  const [assignTransitList, setAssignTransitList] = useState([]);
  const [assignTransitTicketId, setAssignTransitTicketId] = useState('');
  const [assignTransitPicked, setAssignTransitPicked] = useState(null);
  const [romoocList, setRomoocList] = useState([]);
  const [romoocId, setRomoocId] = useState('');
  const [isMoocOutSite, setIsMoocOutSite] = useState(false);
  const [isValidContNumber, setIsValidContNumber] = useState('');
  const [currentRomoocLocation, setCurrentRomoocLocation] = useState('');
  const [currentRomoocLocationId, setCurrentRomoocLocationId] = useState('');
  const [locationList, setLocationList] = useState([]);
  const [locationListForPickup, setLocationListForPickup] = useState([]);
  const [locationListForDeliveryLocation, setLocationListForDeliveryLocation] = useState([]);
  const [pickupLocation, setPickupLocation] = useState('');
  const [pickupLocationLabel, setPickupLocationLabel] = useState('');
  const [deliveryLocation, setDeliveryLocation] = useState('');
  const [deliveryLocationLabel, setDeliveryLocationLabel] = useState('');
  const [contNumber, setContNumber] = useState('');

  const getAssignTransitList = (Query) => {
    ApiServices.getContsByStatus(Query, TRANSIT_CONT_DATA, true)
      .then((res) => {
        if (res?.data?.data) {
          setAssignTransitList(res?.data?.data);
        }
      })
      .catch((err) => console.error('GetContsByStatus Error', err));
  };

  const getRomooc = async (query = '') => {
    try {
      const { data } = await ApiServices.getRomoocs({ Columns: { code: query } });
      if (data?.data) {
        setRomoocList(data?.data);
      }
    } catch (err) {
      console.error('ERR - Get Romoocs', err);
    }
  };
  const getLocations = async (Query = null, callbackSearch) => {
    try {
      const { data } = await ApiServices.getLocations({ Query });
      if (callbackSearch) {
        callbackSearch && callbackSearch(data?.data || []);
      } else {
        setLocationListForPickup(data?.data);
        setLocationList(data?.data);
        setLocationListForDeliveryLocation(data?.data);
      }
    } catch (err) {
      console.error('ERR - Get Shipping Lines', err);
    }
  };

  const handleSearchPickupLocation = async (text = '') => {
    getLocations(text, (value) => setLocationListForPickup(value));
  };
  const handleSearchLocations = async (text = '') => {
    getLocations(text, (value) => setLocationList(value));
  };
  const handleSearchDeliveryLocation = async (text = '') => {
    getLocations(text, (value) => setLocationListForDeliveryLocation(value));
  };

  useEffect(() => {
    getRomooc();
    getLocations();
    getAssignTransitList();
  }, []);

  useEffect(() => {
    if (!dataItem?.contId) {
      setAssignTransitTicketId('');
      setAssignTransitPicked('');
      setContNumber('');
      setPickupLocation('');
      setPickupLocationLabel('');
      setDeliveryLocation('');
      setDeliveryLocationLabel('');
    }
    if (!dataItem?.romoocId) {
      setRomoocId('');
      setCurrentRomoocLocationId('');
    }
  }, [dataItem?.id]);

  const handleChangeAssignTransit = useCallback(
    (id) => {
      setAssignTransitTicketId(id);
      const contTransitSelected = assignTransitList?.find((item) => item?.id === id);
      if (contTransitSelected) {
        setAssignTransitPicked(contTransitSelected);
      } else {
        setAssignTransitPicked({});
      }

      setPickupLocation(contTransitSelected?.locationOfReciptCode);
      setPickupLocationLabel(contTransitSelected?.locationOfReciptName);
      setDeliveryLocation(contTransitSelected?.locationOfDeliveryCode);
      setDeliveryLocationLabel(contTransitSelected?.locationOfDeliveryName);

      setAssignTransitTourList((prev) => {
        prev[tourIndex].contId = id;
        prev[tourIndex].pickupLocation = contTransitSelected?.locationOfReciptCode;
        prev[tourIndex].deliveryLocation = contTransitSelected?.locationOfDeliveryCode;

        return [...prev];
      });
    },
    [assignTransitTicketId, assignTransitList]
  );

  const handleChangeRomooc = useCallback(
    (id) => {
      setRomoocId(id);
      const romoocSelected = romoocList?.find((item) => item?.id === id);
      if (romoocSelected && romoocSelected?.code === 'MOOC_NGOAI') {
        setCurrentRomoocLocationId('');
        setCurrentRomoocLocation('');
        setIsMoocOutSite(true);
        setAssignTransitTourList((prev) => {
          prev[tourIndex].romoocId = id;
          prev[tourIndex].romoocLocationId = '';
          prev[tourIndex].contNumber = '';
          prev[tourIndex].isRomoocOutSite = true;
          return [...prev];
        });
      } else if (romoocSelected) {
        setCurrentRomoocLocationId(romoocSelected?.currentLocationCode);
        setCurrentRomoocLocation(romoocSelected?.currentLocationName);
        if (
          romoocSelected?.moocConts &&
          romoocSelected?.moocConts?.isCont &&
          romoocSelected?.moocConts?.infoConts?.length
        ) {
          setContNumber(romoocSelected?.moocConts?.infoConts?.[0]?.contNumber);
        } else {
          setContNumber('');
        }
        setIsMoocOutSite(false);
        setAssignTransitTourList((prev) => {
          prev[tourIndex].romoocId = id;
          prev[tourIndex].romoocLocationId = romoocSelected?.currentLocationCode;
          prev[tourIndex].contNumber = '';
          prev[tourIndex].isRomoocOutSite = false;

          return [...prev];
        });
      } else {
        setCurrentRomoocLocationId('');
        setCurrentRomoocLocation('');
        setIsMoocOutSite(false);
        setAssignTransitTourList((prev) => {
          prev[tourIndex].romoocId = id;
          prev[tourIndex].romoocLocationId = '';
          prev[tourIndex].contNumber = '';
          prev[tourIndex].isRomoocOutSite = false;
          return [...prev];
        });
      }
    },
    [romoocId, romoocList]
  );
  const handleChangeLocationRomooc = useCallback(
    (id) => {
      setCurrentRomoocLocationId(id);
      setAssignTransitTourList((prev) => {
        prev[tourIndex].romoocLocationId = id;
        return [...prev];
      });
    },
    [currentRomoocLocationId]
  );
  const handleChangePickupLocation = useCallback(
    (id) => {
      setPickupLocation(id);
      setAssignTransitTourList((prev) => {
        prev[tourIndex].pickupLocation = id;
        return [...prev];
      });
    },
    [pickupLocation]
  );
  const handleChangeDeliveryLocation = useCallback(
    (id) => {
      setDeliveryLocation(id);
      setAssignTransitTourList((prev) => {
        prev[tourIndex].deliveryLocation = id;
        return [...prev];
      });
    },
    [deliveryLocation]
  );

  const timeoutCheckAndSetContNumber = useCallback(
    debounce((newValue) => {
      const checkValidContNumber = checkContNumber(newValue);
      if (checkValidContNumber) {
        setIsValidContNumber(false);
        setAssignTransitTourList((prev) => {
          prev[tourIndex].contNumber = newValue;
          return [...prev];
        });
      } else {
        setIsValidContNumber(true);
        setAssignTransitTourList((prev) => {
          prev[tourIndex].contNumber = '';
          return [...prev];
        });
      }
    }, 1000),
    []
  );

  const handleChangeContNumber = useCallback(
    (event) => {
      const value = event.target.value;
      setContNumber(value);
      timeoutCheckAndSetContNumber(value);
    },
    [contNumber]
  );
  const dataIsActive = assignTransitTourList?.map((item) => item?.contId);
  const removeContTransitIsPicked = assignTransitList?.filter((item) => {
    return !dataIsActive?.includes(item?.id);
  });
  if (assignTransitPicked) {
    removeContTransitIsPicked?.unshift(assignTransitPicked);
  }

  const handleAddNewEndJob = () => {
    setAssignTransitTourList((prev) => {
      const dataAdd = {
        id: uid(32),
        jobName: '',
        locationId: '',
      };
      prev[tourIndex].endJob = [...prev[tourIndex].endJob, dataAdd];
      return [...prev];
    });
  };

  const handleDeleteStartJob = (jobId) => {
    const removeStartJob = dataItem?.endJob?.filter((item) => item?.id !== jobId);
    setAssignTransitTourList((prev) => {
      prev[tourIndex].endJob = removeStartJob;
      return [...prev];
    });
  };

  const renderAddJobEnd = (item, index) => {
    return (
      <FormJobSupportTour
        key={item.id}
        dataItemStart={item}
        indexJob={index}
        handleDeleteStartJob={handleDeleteStartJob}
        setAssignTransitTourList={setAssignTransitTourList}
        tourIndex={tourIndex}
      />
    );
  };

  return (
    <div
      style={{
        backgroundColor: 'rgb(234, 238, 243)',
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        borderRadius: '10px',
        fontSize: '12px',
        padding: '10px',
        marginBottom: '15px',
        marginTop: '15px',
        position: 'relative',
      }}
    >
      <div
        style={{
          width: '30px',
          height: '30px',
          position: 'absolute',
          top: -4,
          right: 10,
          transform: 'translateY(-30%)',
          borderRadius: '50%',
        }}
      >
        <Popconfirm
          onConfirm={() => handleDeleteTour(dataItem?.id)}
          title='Bạn chắc chắn Xóa Tour này?'
          okText='Có'
          cancelText='Không'
        >
          <Tooltip title='Xóa Tour' placement='leftTop' color='blue'>
            <Button type='primary' shape='circle' danger icon={<CloseOutlined />} />
          </Tooltip>
        </Popconfirm>
        {hasCreateNew && (
          <Tooltip title='Thêm Tour' placement='leftTop' color='blue'>
            <Button
              type='primary'
              style={{
                marginTop: '10px',
              }}
              icon={<PlusOutlined />}
              onClick={handleAddNewTour}
            />
          </Tooltip>
        )}
      </div>
      <h2> Tour: {tourIndex + 1} Kéo hàng</h2>
      <Row gutter={16}>
        <Col span={12}>
          Phiếu Chuyển Kho <b className='red'>(*)</b>{' '}
          {!assignTransitTicketId && <i className='red'>Vui lòng chọn Phiếu CK</i>}
          <Selection
            data={removeContTransitIsPicked}
            fieldValue='subNoNumber'
            placeholder='Phiếu Chuyển Kho'
            value={assignTransitTicketId}
            onChange={handleChangeAssignTransit}
            onSearch={getAssignTransitList}
          />
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          marginTop: '10px',
        }}
      >
        <Col span={12}>
          Romooc <b className='red'>(*)</b>{' '}
          {!romoocId && <i className='red'>Vui lòng chọn Romooc</i>}
          <Selection
            data={romoocList}
            fieldValue='code'
            placeholder='Romooc'
            value={romoocId}
            onChange={handleChangeRomooc}
            onSearch={getRomooc}
          />
        </Col>
        {romoocId && (
          <Col span={12}>
            Vị trí <b className='red'>(*)</b>{' '}
            {!currentRomoocLocationId && <i className='red'>Vui lòng chọn Vị trí</i>}
            <Selection
              data={locationList}
              placeholder='Vị Trí'
              fieldValue='name'
              labelDefault={currentRomoocLocation}
              value={currentRomoocLocationId}
              onChange={handleChangeLocationRomooc}
              onSearch={handleSearchLocations}
            />
          </Col>
        )}
      </Row>
      <Row
        gutter={16}
        style={{
          marginTop: '10px',
        }}
      >
        <Col span={12}>
          Số Cont{' '}
          {!contNumber ? (
            <i className='red'>Chưa có số Cont</i>
          ) : isMoocOutSite && contNumber && isValidContNumber ? (
            <i className='red'>Số Cont không hợp lệ</i>
          ) : (
            ''
          )}
          {isMoocOutSite ? (
            <Input value={contNumber} onChange={handleChangeContNumber} />
          ) : (
            <Input value={contNumber} />
          )}
        </Col>
      </Row>

      <Row
        gutter={16}
        style={{
          marginTop: '10px',
        }}
      >
        <Col span={8}>
          Công việc
          <Input value='Lấy Hàng CK' />
        </Col>
        <Col span={16}>
          Địa điểm <b className='red'>(*)</b>{' '}
          {(!pickupLocation || !deliveryLocation) && <i className='red'>Vui lòng chọn Địa điểm</i>}
          <Selection
            data={locationListForPickup}
            placeholder='Địa điểm'
            fieldValue='name'
            labelDefault={pickupLocationLabel}
            value={pickupLocation}
            onChange={handleChangePickupLocation}
            onSearch={handleSearchPickupLocation}
          />
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          marginTop: '10px',
        }}
      >
        <Col span={8}>
          <Input value='Giao Hàng CK' />
        </Col>
        <Col span={16}>
          <Selection
            data={locationListForDeliveryLocation}
            placeholder='Địa điểm'
            fieldValue='name'
            labelDefault={deliveryLocationLabel}
            value={deliveryLocation}
            onChange={handleChangeDeliveryLocation}
            onSearch={handleSearchDeliveryLocation}
          />
        </Col>
      </Row>
      {dataItem?.endJob?.length > 0 && dataItem?.endJob?.map(renderAddJobEnd)}
      <Row
        gutter={16}
        style={{
          marginTop: '5px',
        }}
      >
        <Col span={8}></Col>
        <Col span={12}></Col>
        <Col span={4}>
          <Button type='link' size='small' onClick={handleAddNewEndJob}>
            Thêm Công Việc
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export { FormTourItem };
