import { Tooltip } from 'antd';
import styled from 'styled-components';
import { color } from '~theme';

export const ContRevisedContent = ({ data }) => {
  if (!data?.rvS_Logs?.length) {
    return null;
  }
  const logs = data.rvS_Logs.map((log) => (
    <span key={log} dangerouslySetInnerHTML={{ __html: log }} />
  ));
  return (
    <Tooltip
      title={
        <div
          style={{
            maxHeight: 'calc(100vh - 100px)',
            overflow: 'auto',
          }}
        >
          {logs}
        </div>
      }
      color={color.warning}
    >
      <Icon>!</Icon>
    </Tooltip>
  );
};

const Icon = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${color.error};
  cursor: pointer;
  padding: 0 4px;
  //   &:hover {
  //     background: ${color.border};
  //     border-radius: 2px;
  //   }
`;
