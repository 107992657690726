let arrayNumber = ['Không', 'Một', 'Hai', 'Ba', 'Bốn', 'Năm', 'Sáu', 'Bảy', 'Tám', 'Chín'];

function dochangchuc(so, daydu) {
  let chuoi = '';
  let chuc = Math.floor(so / 10);
  let donvi = so % 10;
  if (chuc > 1) {
    chuoi = ' ' + arrayNumber[chuc] + ' Mươi';
    if (donvi == 1) {
      chuoi += ' Mốt';
    }
  } else if (chuc == 1) {
    chuoi = ' Mười';
    if (donvi == 1) {
      chuoi += ' Một';
    }
  } else if (daydu && donvi > 0) {
    chuoi = ' Lẻ';
  }
  if (donvi == 5 && chuc > 1) {
    chuoi += ' Lăm';
  } else if (donvi > 1 || (donvi == 1 && chuc == 0)) {
    chuoi += ' ' + arrayNumber[donvi];
  }
  return chuoi;
}

function docblock(so, daydu) {
  var chuoi = '';
  let tram = Math.floor(so / 100);
  so = so % 100;
  if (daydu || tram > 0) {
    chuoi = ' ' + arrayNumber[tram] + ' Trăm';
    chuoi += dochangchuc(so, true);
  } else {
    chuoi = dochangchuc(so, false);
  }
  return chuoi;
}

function dochangtrieu(so, daydu) {
  let chuoi = '';
  let trieu = Math.floor(so / 1000000);
  so = so % 1000000;
  if (trieu > 0) {
    chuoi = docblock(trieu, daydu) + ' Triệu';
    daydu = true;
  }
  let nghin = Math.floor(so / 1000);
  so = so % 1000;
  if (nghin > 0) {
    chuoi += docblock(nghin, daydu) + ' Nghìn';
    daydu = true;
  }
  if (so > 0) {
    chuoi += docblock(so, daydu);
  }
  return chuoi;
}

function ReadNumber(so) {
  if (so == 0) return arrayNumber[0];
  let chuoi = '';
  let hauto = '';
  do {
    let ty = so % 1000000000;
    so = Math.floor(so / 1000000000);
    if (so > 0) {
      chuoi = dochangtrieu(ty, true) + hauto + chuoi;
    } else {
      chuoi = dochangtrieu(ty, false) + hauto + chuoi;
    }
    hauto = ' Tỷ';
  } while (so > 0);
  return chuoi;
}

export { ReadNumber };
