import { Button, Upload } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';

export const UploadExcelButton = ({
  loading = false,
  onChange,
  templateUrl,
  isShowDownloadTemplate = true,
  importLabel = 'Import Excel',
  downloadLabel = 'Tải Mẫu Excel',
}) => {
  const handleCustomRequest = (request) => {
    onChange?.(request.file);
  };
  const onDownloadTemplate = () => {
    window.open(templateUrl);
  };

  return (
    <>
      <Upload showUploadList={false} maxCount={1} customRequest={handleCustomRequest}>
        <Button loading={loading} icon={<UploadOutlined />} type='ghost' danger>
          {importLabel}
        </Button>
      </Upload>
      {isShowDownloadTemplate && (
        <Button
          className='mg-l-15'
          icon={<DownloadOutlined />}
          onClick={onDownloadTemplate}
          type='primary'
          ghost
        >
          {downloadLabel}
        </Button>
      )}
    </>
  );
};
