import React, { useEffect, useState, useCallback } from 'react';
import { Popconfirm, message, Input, Space } from 'antd';
import { TableLayout } from '~components';
import { debounce } from 'lodash';
import ApiServices from '~services';

const DEFAULT_QUERIES = {
  PageSize: 15,
  PageNumber: 1,
};

const LocationNoteUse = ({ keyTab }) => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [locationNotUse, setLocationNotUse] = useState([]);
  const [loadingChangeLocation, setLoadingChangeLocation] = useState(false);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getLocationsNotUse(newQuery)
      .then((res) => {
        if (res?.data) {
          setLocationNotUse(res?.data);
        }
      })
      .catch((err) => {
        console.error('get Locations Not Use Error: ', err);
      })
      .finally(() => setLoading(false));
  };
  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };
  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter, keyTab]);

  const changeNotUse = async (record) => {
    if (loadingChangeLocation) return;
    setLoadingChangeLocation(true);
    try {
      const res = await ApiServices.changeLocation(record.id);
      console.log({ res });
      if (res?.data?.data === 'success') {
        message.success('Chuyển sử dụng, thành công');
        timeoutGetData(filter);
      }
    } catch (error) {
      message.error('Chuyển sử dụng, thất bại, vui lòng thử lại sau!');
      console.log(error);
    } finally {
      setLoadingChangeLocation(false);
    }
  };

  const columns = [
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Mã Địa Điểm</div>
          <Input placeholder='Search' allowClear name='code' onChange={onColumnSearch} />
        </span>
      ),
      dataIndex: 'code',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Tên Địa Điểm</div>
          <Input placeholder='Search' allowClear name='name' onChange={onColumnSearch} />
        </span>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Loại Địa Điểm</div>
          <Input
            placeholder='Search'
            allowClear
            name='typeLocationName'
            onChange={onColumnSearch}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'typeLocationName',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Địa Chỉ</div>
          <Input placeholder='Search' allowClear name='address' onChange={onColumnSearch} />
        </span>
      ),
      width: 300,
      dataIndex: 'address',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Công Ty</div>
          <Input placeholder='Search' allowClear name='companyName' onChange={onColumnSearch} />
        </span>
      ),
      width: 180,
      dataIndex: 'companyName',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Ghi Chú</div>
          <Input placeholder='Search' allowClear name='note' onChange={onColumnSearch} />
        </span>
      ),
      width: 200,
      dataIndex: 'note',
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 110,
      align: 'center',
      render: (record) => (
        <Space direction='vertical'>
          <Popconfirm
            onConfirm={() => changeNotUse(record)}
            title='Chuyển địa điểm này sang Không sử dụng'
            okText='Có'
            cancelText='Không'
          >
            <a style={{ color: 'blue' }} href='#'>
              Chuyển Sử Dụng
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const pagination = {
    total: locationNotUse?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };
  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={locationNotUse?.data || []}
      loading={loading}
      closeDrawer={() => setSelected(null)}
      pagination={pagination}
      isDisplayCheckbox={false}
      width={600}
      headerLength='190px'
    />
  );
};

export { LocationNoteUse };
