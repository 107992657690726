import React from 'react';
import { ExpensesAvailable } from './expenses-available';
import { FeeAddNewItem } from './fee-add-new-item';

const AddNewFee = ({
  expenseListAvailable,
  expensesIdSelectedToAdd,
  setExpensesIdSelectedToAdd,
  expensesSelectedToAdd,
  setExpensesSelectedToAdd,
  onDeleteAddNewFee,
  vat,
}) => {
  const renderFeeItem = (value, index) => {
    return (
      <FeeAddNewItem
        key={value?.id}
        data={value}
        vat={vat}
        index={index}
        onDeleteAddNewFee={onDeleteAddNewFee}
        setExpensesSelectedToAdd={setExpensesSelectedToAdd}
      />
    );
  };

  return (
    <>
      <h2>Vui lòng chọn chi phí để thêm:</h2>
      <div>
        <ExpensesAvailable
          dataExpenses={expenseListAvailable}
          expensesIdSelectedToAdd={expensesIdSelectedToAdd}
          setExpensesIdSelectedToAdd={setExpensesIdSelectedToAdd}
          setExpensesSelectedToAdd={setExpensesSelectedToAdd}
        />
      </div>
      <h2>
        Chi phí đã chọn để thêm: <b className='red'>{expensesIdSelectedToAdd?.length}</b>
      </h2>
      {expensesSelectedToAdd?.map(renderFeeItem)}
    </>
  );
};

export { AddNewFee };
