import { useCallback, useEffect, useState } from 'react';
import { Input, Modal, Typography } from 'antd';
import checkContNumber from '~commons/check-cont-number';
import { UploadFileContNumber } from '~components/upload-file-cont-number';
import styled from 'styled-components';
import useFinishTaskApi from './use-finish-task-api';
import Utils from '~commons/utils';

export const ContNumberForm = ({ cont, job, onClose, reload, isUpdateContNumber, setLoading }) => {
  const objCont = job?.objCont || cont;
  const isOpen = !!objCont;
  const { finishTask, updateCont } = useFinishTaskApi();
  const [contNumber, setContNumber] = useState(objCont?.contNumber);
  const [sealNumber, setSealNumber] = useState(objCont?.sealNumber || null);
  const [files, setFiles] = useState([]);

  const resetInput = () => {
    setContNumber();
    setSealNumber();
    setFiles([]);
  };
  useEffect(() => {
    if (!objCont) {
      return resetInput();
    }
    setContNumber(objCont?.contNumber);
    setSealNumber(objCont?.sealNumber || '');
    if (objCont?.contFiles) {
      const fileList = objCont?.contFiles?.map((item) => {
        const url = Utils.getAppUrl() + item.urlImage.substring(2);
        return {
          ...item,
          url,
          fileName: item.name + item.extension,
        };
      });
      setFiles(fileList);
    }
  }, [objCont]);

  const onSubmit = useCallback(() => {
    onClose();
    if (isUpdateContNumber) {
      let contFiles = [];
      let uploadFiles = [];

      for (let file of files) {
        if (file.originFileObj) {
          uploadFiles.push(file);
          continue;
        }
        contFiles.push(file);
      }

      return updateCont(
        job?.contId || objCont?.contId,
        contNumber,
        reload,
        setLoading,
        contFiles,
        uploadFiles,
        sealNumber
      );
    }
    return finishTask(job, reload, setLoading, files, contNumber, sealNumber);
  }, [contNumber, sealNumber, files]);

  return (
    <>
      <Modal
        title={isUpdateContNumber ? 'Cập nhật số cont' : 'Nhập số cont'}
        width={700}
        style={{
          top: 20,
        }}
        open={isOpen}
        onOk={onSubmit}
        onCancel={onClose}
        okButtonProps={{
          disabled: !checkContNumber(contNumber),
        }}
      >
        <Wrapper>
          <ContainerInput>
            <ItemList>
              <Typography>Số cont:</Typography>
              <Input
                placeholder='Số cont'
                onChange={(e) => setContNumber(e.target.value)}
                value={contNumber}
              />
              <Error>
                {contNumber
                  ? !checkContNumber(contNumber) && 'Số cont không hợp lệ'
                  : 'Vui lòng nhập số cont'}
              </Error>
            </ItemList>
            <ItemList>
              <Typography>Số Seal:</Typography>
              <Input
                placeholder='Số seal'
                onChange={(e) => setSealNumber(e.target.value)}
                value={sealNumber}
              />
              <Error>{!sealNumber && 'Vui lòng nhập số Seal'}</Error>
            </ItemList>
          </ContainerInput>
          <Typography>Chọn File:</Typography>
          <UploadFileContNumber onchange={setFiles} value={files} />
        </Wrapper>
      </Modal>
    </>
  );
};
const Wrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ContainerInput = styled.div`
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ItemList = styled.div`
  flex-direction: column;
  flex: 1;
`;

const Error = styled.div`
  color: red;
`;
