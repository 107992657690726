import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Divider, Button, Space, Input, Table } from 'antd';
import { debounce } from 'lodash';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
const ExcelJS = require('exceljs');
import { TableTitle } from '~commons/styles';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ReportRomooc = () => {
  let query = useQuery();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataKey, setDataKey] = useState('');
  const [filter, setFilter] = useState(null);
  const [originData, setOriginData] = useState([]);

  const getRPRomoocInspection = async () => {
    const key = query.get('key');
    switch (key) {
      case 'ArrHetHanDangKiem':
        setDataKey('Đã hết hạn');
        break;
      case 'ArrHetHanDangKiem10':
        setDataKey('Còn 10 ngày');
        break;
      case 'ArrHetHanDangKiem20':
        setDataKey('Còn 20 ngày');
        break;
      case 'ArrHetHanDangKiem30':
        setDataKey('Còn 30 ngày');
        break;
      default:
        break;
    }

    if (key) {
      try {
        setLoading(true);
        let { data } = await ApiServices.getRPRomoocInspection();
        setLoading(false);
        if (data[0][key]) {
          return getListByArrayIdOfRomooc(data[0][key]);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getListByArrayIdOfRomooc = async (arrId) => {
    try {
      setLoading(true);
      let { data } = await ApiServices.getListByArrayIdOfRomooc(arrId);
      setLoading(false);
      if (data?.data) {
        const dataSort = data?.data?.sort((a, b) => {
          let da = new Date(a.inspectionDeadline);
          let db = new Date(b.inspectionDeadline);
          return da - db;
        });
        const dataMap = dataSort?.map((item, index) => {
          item.key = index + 1;
          return item;
        });
        setData(dataMap);
        setOriginData(dataMap);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getInit = async () => {
      await getRPRomoocInspection();
    };

    getInit();
  }, []);

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;
      if (query?.code) {
        if (!Utils.convertValSearch(item?.code)?.includes(Utils.convertValSearch(query?.code))) {
          checkFilter = false;
        }
      }
      if (query?.name) {
        if (!Utils.convertValSearch(item?.name)?.includes(Utils.convertValSearch(query?.name))) {
          checkFilter = false;
        }
      }

      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    setData(dataFilter);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    []
  );
  useEffect(() => {
    if (originData?.length) {
      timeoutGetData(filter, originData);
    }
  }, [filter]);

  const onColumnSearch = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilter((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      width: 50,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Mã Số
          <Input name='code' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'code',
      width: 130,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Số Romooc
          <Input name='name' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'name',
      width: 150,
      align: 'center',
    },
    {
      title: 'Nhãn Hiệu',
      dataIndex: 'label',
      width: 100,
      align: 'center',
    },
    {
      title: 'Ngày Kiểm Định',
      dataIndex: 'inspectionDate',
      width: 130,
      align: 'center',
      render: (value) => Utils.formatDate(value),
    },
    {
      title: 'HH Kiểm Định',
      dataIndex: 'inspectionDeadline',
      width: 130,
      align: 'center',
      render: (value) => Utils.formatDate(value),
    },
    {
      title: 'Chủng Loại',
      dataIndex: 'typeContCode',
      width: 130,
      align: 'center',
    },
    {
      title: 'Nơi SX',
      dataIndex: 'madeIn',
      width: 130,
      align: 'center',
    },
    {
      title: 'Trọng Lượng',
      dataIndex: 'weight',
      width: 100,
      align: 'center',
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: 'Tải Trọng',
      dataIndex: 'loads',
      width: 100,
      align: 'center',
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: 'Số Khung',
      dataIndex: 'frameNumber',
      width: 130,
      align: 'center',
    },
    {
      title: 'Số Kiểm Định',
      dataIndex: 'inspectionNumber',
      width: 120,
      align: 'center',
    },
    {
      title: 'Ghi Chú',
      dataIndex: 'note',
      width: 150,
    },
  ];

  const exportExcelFile = async () => {
    if (loading) return;

    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet(`${dataKey}`, {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 20;
    sheetSummary.properties.defaultColWidth = 15;
    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getColumn(11).width = 25;
    sheetSummary.getColumn(13).width = 30;
    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getCell('F2').value = 'HẠN KIỂM ĐỊNH ROMOOC';
    sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(3).font = {
      name: 'Arial',
      family: 2,
      size: 13,
      bold: true,
    };
    sheetSummary.getCell('F3').value = `${dataKey}`;

    const arrDetail = await data?.map((item) => {
      const dataItem = [
        item?.key, // STT
        item?.code, //  Ma So
        item?.name, // So Romooc
        item?.label, // Nhan Hieu
        item?.inspectionDate && new Date(moment(item?.inspectionDate).format('YYYY-MM-DD')), // Ngay Kiem Dinh
        item?.inspectionDeadline && new Date(moment(item?.inspectionDeadline).format('YYYY-MM-DD')), // HH KĐ
        item?.typeContCode, // Chung Loai
        item?.madeIn, // Noi San Xuat
        item?.weight, // Trong Luong
        item?.loads, // Tai Trong
        item?.frameNumber, // So Khung
        item?.inspectionNumber, // So Kiem Dinh
        item?.note, // Ghi Chu
      ];
      return dataItem;
    });
    sheetSummary.getRow(5).alignment = { vertical: 'center', horizontal: 'center' };

    // Add Table
    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'A5',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Mã Số', filterButton: false },
        { name: 'Số Romooc', filterButton: false },
        { name: 'Nhãn Hiệu', filterButton: false },
        {
          name: 'Ngày Kiểm Định',
          filterButton: false,
        },
        {
          name: 'HH Kiểm Định',
          filterButton: false,
        },
        {
          name: 'Chủng Loại',
          filterButton: false,
        },
        {
          name: 'Nơi Sản Xuất',
          filterButton: false,
        },
        {
          name: 'Trọng Lượng',
          filterButton: false,
        },
        {
          name: 'Tải Trọng',
          filterButton: false,
        },
        {
          name: 'Số Khung',
          filterButton: false,
        },
        {
          name: 'Số Kiểm Định',
          filterButton: false,
        },
        {
          name: 'Ghi Chú',
          filterButton: false,
        },
      ],
      rows: arrDetail,
    });

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Han_Kiem_Dinh_Romooc_${dataKey}_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ margin: '2rem' }}>
      <Divider>
        <b>HẠN KIỂM ĐỊNH ROMOOC </b>(<span className='red'>{dataKey}</span>)
      </Divider>
      <Space>
        <Button
          type='primary'
          icon={<DownloadOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px', marginBottom: '20px' }}
          onClick={exportExcelFile}
        >
          Export Excel
        </Button>
      </Space>
      <Table
        rowKey='id'
        size='small'
        bordered
        columns={columns}
        dataSource={data}
        loading={loading}
      />
    </div>
  );
};

export { ReportRomooc };
