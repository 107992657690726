import React, { useRef, useEffect, useState } from 'react';
import { TableLayout, UploadExcelButton } from '~components';
import { RomoocForm } from './romooc-form';
import RomoocFields from './fields-data';
import { Input, Button, message, Space } from 'antd';
import moment from 'moment';
const ExcelJS = require('exceljs');
import { DownloadOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { RomoocInspections } from './romooc-inspection';
import { getColumns } from './columns';

const EXCEL_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/Romooc.xlsx';
const Romoocs = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const typingTimeoutref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [romoocs, setRomoocs] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchByColumn, setSearchByColumn] = useState({
    code: '',
    name: '',
    label: '',
    typeRomooc: '',
    typeContCode: '',
    madeIn: '',
    loads: '',
    frameNumber: '',
    inspectionNumber: '',
    inspectionCost: '',
    otherInfo: '',
    currentLocationName: '',
    contNumber: '',
    ownerRomooc: '',
  });

  const onChangePagination = (page, size) => {
    let filterTemp = filter;
    filterTemp.PageNumber = page;
    filterTemp.PageSize = size;
    setFilter(filterTemp);
    getRomoocs();
  };
  const [pagination, setPagination] = useState({
    total: 1,
    showSizeChanger: true,
    onChange: onChangePagination,
  });
  const [filter, setFilter] = useState({
    PageNumber: 1,
    PageSize: 10,
    Text: '',
    Columns: '',
  });

  const getRomoocs = async () => {
    let dataFilterSend = { ...filter };
    console.log(typeof dataFilterSend.Columns);
    if (Object.keys(dataFilterSend.Columns).length === 0) {
      delete dataFilterSend['Columns'];
    } else {
      const columnJSONSTRING = JSON.stringify(dataFilterSend.Columns);
      console.log(columnJSONSTRING);
      dataFilterSend.Columns = columnJSONSTRING;
    }
    try {
      setLoading(true);
      let { data } = await ApiServices.getRomoocs(dataFilterSend);
      setLoading(false);
      let pag = pagination;
      pag.total = data?.totalRecords || 0;
      pag.pageSize = data?.pageSize;
      pag.current = data?.pageNumber;

      setPagination(pag);
      const dataMap = data?.data?.map((item, index) => {
        const indexData = (data?.pageNumber - 1) * data.pageSize + index + 1;
        item.index = indexData;
        item.key = index;
        return item;
      });
      setRomoocs(dataMap || []);
    } catch (error) {
      console.log(error);
      console.error(error);
    }
  };
  useEffect(() => {
    getRomoocs();
  }, []);

  const setFieldsForm = async (value) => {
    const romoocFields = await RomoocFields(value);
    await setFields(romoocFields);
    return romoocFields;
  };
  const reload = async () => {
    setSelected(null);
    await getRomoocs();
  };

  const getUpdatedForm = async (
    value,
    isNew = false,
    id = null,
    idLocationCurrent = null,
    nameLocationCurrent = null,
    dataSelected
  ) => {
    const romoocForm = await (
      <RomoocForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        idLocationCurrent={idLocationCurrent}
        nameLocationCurrent={nameLocationCurrent}
        reload={reload}
        onChange={setFields}
        dataSelected={dataSelected}
      />
    );

    const romoocInspection = await (<RomoocInspections id={id} />);

    if (isNew) {
      return setTabPanels([
        {
          title: 'Thông Tin Chi Tiết',
          content: romoocForm,
        },
      ]);
    } else {
      return setTabPanels([
        {
          title: 'Thông Tin Chi Tiết',
          content: romoocForm,
        },
        {
          title: 'Kiểm Định Romooc',
          content: romoocInspection,
        },
      ]);
    }
  };

  const onRowSelected = async (value) => {
    const _value = {
      ...value,
      inspectionDate: Utils.convertToDate(value.inspectionDate),
      inspectionDeadline: Utils.convertToDate(value.inspectionDeadline),
      useBeginDate: Utils.convertToDate(value.useBeginDate),
    };
    await setIsLoadingTab(true);
    await setTitleTab(_value.code);
    await setFieldsForm(_value)
      .then((res) => {
        return getUpdatedForm(
          res,
          false,
          _value.id,
          value.currentLocationCode,
          value.currentLocationName,
          value
        );
      })
      .then(() => setSelected(_value));
    setIsLoadingTab(false);
  };
  const deleteRomooc = async (record) => {
    try {
      const res = await ApiServices.deleteRomooc(record.id);
      if (res.data) {
        message.success('Xoá Romooc thành công');
        return reload();
      }
      message.error('Xoá Romooc thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchColumnFn = (event, name) => {
    const val = event.target.value;

    const filterTmp = filter;
    let _searchByColumn = { ...searchByColumn };
    _searchByColumn[`${name}`] = val;
    setSearchByColumn(_searchByColumn);
    // Clear timeout
    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }
    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 0) {
        filter.PageNumber = 1;
        let searchColumnFn = { ...filter.Columns };
        searchColumnFn[`${name}`] = val;

        filter.Columns = searchColumnFn;
        setFilter({ ...filterTmp, PageNumber: 1 });
        getRomoocs();
      }
      if (val.length === 0) {
        delete filter.Columns[`${name}`];
        setFilter({ ...filterTmp, PageNumber: 1 });
        getRomoocs();
      }
    }, 1000);
  };

  const handleSearchTermChange = (e) => {
    const val = e.target.value;
    setSearchTerm(val);

    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }

    typingTimeoutref.current = setTimeout(() => {
      const filterTmp = filter;
      filterTmp.Text = val;

      if (val.length > 2) {
        filter.PageNumber = 1;
        setFilter(filterTmp);

        getRomoocs();
      }
      if (val.length === 0) {
        setFilter(filterTmp);
        getRomoocs();
      }
    }, 1000);
  };

  const createCustomer = async () => {
    await setTitleTab('');
    await setFieldsForm({})
      .then((res) => getUpdatedForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };
  const onUploadExcel = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importRomooc(file)
      .then((res) => {
        if (res.data) {
          message.success(res?.data?.message);
          reload();
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT CARR ERROR', err);
        message.error('Thao tác thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };

  const exportExcel = async (dataExport) => {
    if (loading) return;
    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Danh sách xe', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ state: 'frozen', ySplit: 3, activeCell: 'A1', showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 20;
    sheetSummary.properties.defaultColWidth = 15;

    sheetSummary.getCell('F2').value = 'DANH SÁCH ROMOOC';

    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };

    const arrDetail = await dataExport?.data?.map((item, index) => {
      const dataItem = [
        index + 1, // STT
        item?.code || '', // Mã
        item?.name || '', // Số
        item?.companyName || '', // Công Ty
        item?.label || '', // Nhãn Hiệu
        item?.typeRomooc || '', // Loai Hinh
        item?.typeContCode || '', // Chủng loại
        item?.madeIn || '', // Nơi Sản Xuất
        item?.price || '', // Giá Mua
        item?.inspectionDate && new Date(moment(item?.inspectionDate).format('YYYY-MM-DD')), // Ngày KĐ
        item?.inspectionDeadline && new Date(moment(item?.inspectionDeadline).format('YYYY-MM-DD')), // Ngày HH KĐinh
        item?.useBeginDate && new Date(moment(item?.useBeginDate).format('YYYY-MM-DD')), // Ngày SD
        item?.depreciation || '', // Số Tháng Khấu Hao
        item?.warrantyExpenses || '', // Phí Bảo Hành Thang
        item?.loads || 0, // Tai Trong
        item?.weight || 0, // Trong Luong
        item?.frameNumber || '', // Số Khung
        item?.inspectionNumber || '', // Số Kiểm Định
        item?.inspectionCost || '', // C Phí Kiểm Định
        item?.currentLocationName || '', // DD Hien Tai
        item?.note || '', // Ghi Chu
      ];
      return dataItem;
    });

    // Add Table
    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B5',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Mã', filterButton: false },
        {
          name: 'Số',
          filterButton: false,
        },
        {
          name: 'Công Ty',
          filterButton: false,
        },
        {
          name: 'Nhãn Hiệu',
          filterButton: false,
        },
        {
          name: 'Loại Hình',
          filterButton: false,
        },
        {
          name: 'Chủng Loại',
          filterButton: false,
        },
        {
          name: 'Nơi Sản Xuất',
          filterButton: false,
        },
        {
          name: 'Giá Mua',
          filterButton: false,
        },
        {
          name: 'Ngày Kiểm Định',
          filterButton: false,
        },
        {
          name: 'Hết Hạn Kiểm Định',
          filterButton: false,
        },
        {
          name: 'Ngày Sử Dụng',
          filterButton: false,
        },
        {
          name: 'Số Tháng Khấu Hao',
          filterButton: false,
        },
        {
          name: 'Phí Bảo Hành Tháng',
          filterButton: false,
        },
        {
          name: 'Tải Trọng',
          filterButton: false,
        },
        {
          name: 'Trọng Lượng',
          filterButton: false,
        },
        {
          name: 'Số Khung',
          filterButton: false,
        },
        {
          name: 'Số Kiểm Định',
          filterButton: false,
        },
        {
          name: 'Chi Phí Kiểm Định',
          filterButton: false,
        },
        {
          name: 'Địa Điểm Hiện Tại',
          filterButton: false,
        },
        {
          name: 'Ghi Chú',
          filterButton: false,
        },
      ],
      rows: arrDetail,
    });
    // Styles
    sheetSummary.getColumn(1).width = 2;
    sheetSummary.getColumn(2).width = 5;
    sheetSummary.getColumn(3).width = 20;
    sheetSummary.getColumn(4).width = 20;
    sheetSummary.getColumn(5).width = 40;
    sheetSummary.getColumn(6).width = 20;
    sheetSummary.getColumn(7).width = 25;
    sheetSummary.getColumn(12).width = 20;
    sheetSummary.getColumn(13).width = 20;
    sheetSummary.getColumn(16).width = 20;
    sheetSummary.getColumn(19).width = 20;
    sheetSummary.getColumn(21).width = 25;
    sheetSummary.getColumn(22).width = 35;

    sheetSummary.getColumn(14).numFmt = '#,##0';
    sheetSummary.getColumn(14).width = 20;
    sheetSummary.getColumn(20).numFmt = '#,##0';
    sheetSummary.getColumn(20).width = 20;

    sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Danh_Sach_Romooc_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const handleExportExcel = async () => {
    setLoading(true);
    return ApiServices.getDataToExportExcelForRomooc()
      .then((res) => {
        if (res?.data) {
          return exportExcel(res?.data);
        }
      })
      .catch((err) => {
        console.error('get Cars To Export Excel Error: ', err);
      })
      .finally(() => setLoading(false));
  };

  const headerContent = (
    <Space>
      <Button type='primary' onClick={createCustomer}>
        Thêm Mới
      </Button>
      <Input.Search
        style={{ width: '200px' }}
        placeholder='Search'
        value={searchTerm}
        onChange={handleSearchTermChange}
      />
      <UploadExcelButton
        loading={loading}
        onChange={onUploadExcel}
        templateUrl={EXCEL_TEMPLATE_URL}
      />
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        style={{ padding: '0px 20px', marginLeft: '10px' }}
        onClick={handleExportExcel}
      >
        Export Excel
      </Button>
    </Space>
  );
  return (
    <TableLayout
      columns={getColumns(onRowSelected, searchByColumn, handleSearchColumnFn, deleteRomooc)}
      data={romoocs}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      pagination={pagination}
      width={750}
      isDisplayCheckbox={false}
      headerLength='190px'
    />
  );
};

export { Romoocs };
