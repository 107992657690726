import React, { useRef, useEffect, useState } from 'react';
import { TableLayout, UploadExcelButton } from '~components';
import { LocationTypeForm } from './location-type-form';
import LocationTypeFields from './fields-data';
import { Input, Button, Popconfirm, message, Space } from 'antd';
import ApiServices from '~services';
import Utils from '~commons/utils';

const EXCEL_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/LocationType.xlsx';
const LocationTypes = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [locationTypes, setLocationTypes] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);
  //-------------------------------------------------------------------
  const typingTimeoutref = useRef(null);
  const [searchByColumn, setSearchByColumn] = useState({
    code: '',
    name: '',
    companyName: '',
  });

  const getLocationTypes = async () => {
    let dataFilterSend = { ...filter };
    if (Object.keys(dataFilterSend.Columns).length === 0) {
      delete dataFilterSend['Columns'];
    } else {
      const columnJSONSTRING = JSON.stringify(dataFilterSend.Columns);
      dataFilterSend.Columns = columnJSONSTRING;
    }

    try {
      setLoading(true);
      let { data } = await ApiServices.getLocationTypes(dataFilterSend);
      setLoading(false);
      let pag = pagination;
      pag.total = data?.totalRecords || 0;
      pag.pageSize = data?.pageSize;
      pag.current = data?.pageNumber;

      data = data?.data || [];

      setLocationTypes(data);
      setPagination(pag);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLocationTypes();
  }, []);
  //----------- Start ----- Pagination Search ---------
  //----------- Pagination --------------------------
  const onChangePagination = (page, size) => {
    let filterTemp = filter;
    filterTemp.PageNumber = page;
    filterTemp.PageSize = size;
    setFilter(filterTemp);
    getLocationTypes();
  };
  const [pagination, setPagination] = useState({
    total: 1,
    showSizeChanger: true,
    onChange: onChangePagination,
  });
  const [filter, setFilter] = useState({
    PageNumber: 1,
    PageSize: 10,
    Text: '',
    Columns: '',
  });

  // ------------ Search Column ----------------------
  const handleSearchColumnFn = (event, name) => {
    const filterTmp = filter;
    const val = event.target.value;
    let _searchByColumn = { ...searchByColumn };
    _searchByColumn[`${name}`] = val;
    setSearchByColumn(_searchByColumn);
    // Clear timeout
    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }
    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 0) {
        filter.PageNumber = 1;
        let searchColumnFn = { ...filter.Columns };
        searchColumnFn[`${name}`] = val;
        filter.Columns = searchColumnFn;

        setFilter({ ...filterTmp, PageNumber: 1 });
        getLocationTypes();
      }
      if (val.length === 0) {
        delete filter.Columns[`${name}`];
        setFilter({ ...filterTmp, PageNumber: 1 });
        getLocationTypes();
      }
    }, 1000);
  };
  // ------------- End Search --------------------

  const setFieldsForm = async (value) => {
    const locationTypeFields = await LocationTypeFields(value);
    await setFields(locationTypeFields);
    return locationTypeFields;
  };
  const reload = async () => {
    setSelected(null);
    await getLocationTypes();
  };

  const getUpdatedLocationTypeForm = async (value, isNew = false, id = null, dataSelected) => {
    const driverForm = await (
      <LocationTypeForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        reload={reload}
        onChange={setFields}
        dataSelected={dataSelected}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: driverForm,
      },
    ]);
  };

  const onRowSelected = async (value) => {
    await setIsLoadingTab(true);
    await setTitleTab(value.code);
    await setFieldsForm(value)
      .then((res) => {
        return getUpdatedLocationTypeForm(res, false, value.id, value);
      })
      .then(() => setSelected(value));
    setIsLoadingTab(false);
  };
  const deleteLocationType = async (record) => {
    try {
      const res = await ApiServices.deleteLocationType(record.id);
      if (res.data) {
        message.success('Xoá Loại địa điểm thành công');
        return reload();
      }
      message.error('Xoá Loại địa điểm thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Mã</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.code}
            onChange={(event) => handleSearchColumnFn(event, 'code')}
          />
        </span>
      ),
      dataIndex: 'code',
      align: 'center',
      width: 150,
      render: (value, record) => (
        <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>
      ),
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Tên</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.name}
            onChange={(event) => handleSearchColumnFn(event, 'name')}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Công Ty</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.companyName}
            onChange={(event) => handleSearchColumnFn(event, 'companyName')}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'companyName',
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 75,
      align: 'center',
      render: (record) => (
        <Popconfirm
          onConfirm={() => deleteLocationType(record)}
          title='Xoá loại điạ điểm này？'
          okText='Yes'
          cancelText='No'
        >
          <a style={{ color: 'red' }} href='#'>
            Xoá
          </a>
        </Popconfirm>
      ),
    },
  ];

  const createDriver = async () => {
    await setTitleTab('');
    await setFieldsForm({})
      .then((res) => getUpdatedLocationTypeForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };
  const onUploadExcel = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importLocationType(file)
      .then((res) => {
        if (!res.data?.error) {
          message.success(res?.data?.message);
          reload();
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT CARR ERROR', err);
        message.error('Thao tác thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };
  const headerContent = (
    <Space>
      <Button type='primary' onClick={createDriver}>
        Thêm Mới
      </Button>
      <UploadExcelButton
        loading={loading}
        onChange={onUploadExcel}
        templateUrl={EXCEL_TEMPLATE_URL}
      />
    </Space>
  );
  return (
    <TableLayout
      columns={columns}
      rowKey='id'
      data={locationTypes}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      isDisplayCheckbox={false}
    />
  );
};

export { LocationTypes };
