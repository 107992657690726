import React, { useRef, useState } from 'react';
import moment from 'moment';
import { Modal, Button, Row, Col } from 'antd';
import { TableDetails } from './table-details';
import ReactToPrint from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import { OilSupplyTicket } from './oil-supply-ticket';

const OnlyOilTicketDetail = ({ selectedItem, data, onClose, showDetails = false, companyInfo }) => {
  const oilTicketRef = useRef();
  const [itemSelectedToPrint, setItemSelectedToPrint] = useState({});

  const handleOk = () => {
    onClose && onClose();
  };

  const handleCancel = () => {
    onClose && onClose();
  };

  const renderCarInfo = (carInfo) => (
    <>
      <Col span={6}>
        <b>Số Xe: </b>
        <span>{carInfo?.carCode}</span>
      </Col>
      <Col span={8}>
        <b>Tài Xế: </b>
        <span>{carInfo?.driverMain}</span>
      </Col>
      <Col span={6}>
        <b>Phụ Xe: </b>
        <span>{carInfo?.driverSub}</span>
      </Col>
    </>
  );

  const sumInfo = (carInfo) => {
    const sumOilTotalSupply = carInfo?.oilToSupply;
    return (
      <>
        <Col span={8} offset={14} style={{ textAlign: 'center', marginTop: '5px', color: 'blue' }}>
          <p>
            Tổng Số Lít Dầu Tồn:{' '}
            <b className='red'>{carInfo?.totalOilInCar && Math.round(carInfo?.totalOilInCar)}</b>{' '}
            (Lit)
          </p>
          <p>
            Tổng Số Lít Dầu Cần Chi: <b className='red'>{sumOilTotalSupply}</b> (Lit){' '}
            {carInfo?.oilToSupplyExtra > 0 && (
              <span className='red'>(bao gồm {carInfo?.oilToSupplyExtra} Lít hỗ trợ)</span>
            )}
          </p>
        </Col>
      </>
    );
  };

  const onHandleReportStatus = async () => {
    await setItemSelectedToPrint(selectedItem);
    await handleCancel();
  };

  const pageStyle = `
  @page {
     size: A4;
     margin: 5%;
     padding: 0 0 10%;
     @bottom-right {
      content: counter(page);
     }
  }
`;

  const footer = (
    <div onClick={onHandleReportStatus}>
      <ReactToPrint
        trigger={() => (
          <Button type='primary' icon={<PrinterOutlined />}>
            In phiếu
          </Button>
        )}
        content={() => oilTicketRef.current}
        documentTitle={`Phieu_cap_dau_${moment().format('DD-MM-YYYY')}`}
        pageStyle={pageStyle}
      />
    </div>
  );

  return (
    <>
      <Modal
        title={`Mã Phiếu: ${selectedItem?.code}`}
        open={selectedItem ? true : false}
        onOk={handleOk}
        onCancel={handleCancel}
        width='auto'
        footer={!showDetails ? footer : null}
      >
        {data?.map((item, index) => {
          let jobList = [];
          if (item?.taskJobs?.length > 0) {
            jobList = jobList.concat(item?.taskJobs);
          }
          if (item?.extraJobs?.length > 0) {
            const extraJobsMap = item?.extraJobs?.map((item) => {
              item.code = 'Điều xe hỗ trợ';
              item.isSupport = true;
              return item;
            });
            jobList = jobList.concat(extraJobsMap);
          }
          return (
            <div key={index}>
              <Row style={{ marginBottom: '10px' }}>{renderCarInfo(item?.car)}</Row>
              <TableDetails dataDetails={jobList} />
              <Row>{sumInfo(item?.car)}</Row>
              <div style={{ display: 'none' }}>
                <OilSupplyTicket
                  ref={oilTicketRef}
                  dataCar={item?.car}
                  itemSelectedToPrint={itemSelectedToPrint}
                  companyInfo={companyInfo}
                />
              </div>
            </div>
          );
        })}
      </Modal>
    </>
  );
};

export { OnlyOilTicketDetail };
