import React from 'react';
import { Table, Typography } from 'antd';
import { COLUMNS } from './constants';
const { Text } = Typography;
export const TableData = ({ dataItem = [] }) => {
  return (
    <Table
      dataSource={dataItem}
      // loading={loading}
      bordered
      size='small'
      columns={COLUMNS}
      rowKey={'key'}
      pagination={false}
      // scroll={{ x: '100%', y: '100%' }}
      summary={(pageData) => {
        let total = {
          bill_20: 0,
          bill_40C: 0,
          bill_40T: 0,
          bill_45: 0,
          book_20: 0,
          book_40C: 0,
          book_40T: 0,
          book_45: 0,
          transit_20: 0,
          transit_40C: 0,
          transit_40T: 0,
          transit_45: 0,
          sum: 0,
        };
        pageData.forEach((item) => {
          total = {
            bill_20: total.bill_20 + item.bill_20,
            bill_40C: total.bill_40C + item.bill_40C,
            bill_40T: total.bill_40T + item.bill_40T,
            bill_45: total.bill_45 + item.bill_45,
            book_20: total.book_20 + item.book_20,
            book_40C: total.book_40C + item.book_40C,
            book_40T: total.book_40T + item.book_40T,
            book_45: total.book_45 + item.book_45,
            transit_20: total.transit_20 + item.transit_20,
            transit_40C: total.transit_40C + item.transit_40C,
            transit_40T: total.transit_40T + item.transit_40T,
            transit_45: total.transit_45 + item.transit_45,
            sum: total.sum + item.sum,
          };
        });
        return (
          <>
            <Table.Summary.Row
              style={{
                textAlign: 'center',
              }}
            >
              <Table.Summary.Cell>
                <Text mark strong>
                  Tổng
                </Text>
              </Table.Summary.Cell>
              {Object.keys(total).map((key) => {
                return (
                  <Table.Summary.Cell key={`Cell_total_${key}`}>
                    <Text strong type='danger'>
                      {total[key]}
                    </Text>
                  </Table.Summary.Cell>
                );
              })}
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
};
