import React, { useState, useEffect } from 'react';
import { Space, Tabs } from 'antd';
import moment from 'moment';
import { Wrapper } from '~commons/styles';
import { RangeDateFilter, Selection } from '~components';
import ApiServices from '~services';

import { PendingList } from './pending';
import { CompletedList } from './completed';

const DEFAULT_QUERIES = {
  DateFrom: moment().subtract(6, 'days').format('YYYY-MM-DD'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD'),
};

const BillContPartnerList = () => {
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [partners, setPartners] = useState([]);
  const [partnerSelectedId, setPartnerSelectedId] = useState('');
  const [tabKey, setTabKey] = useState('1');

  const getPartners = async (query = '') => {
    return ApiServices.getPartnerByCompany({ query })
      .then(({ data }) => {
        setPartners(data?.data || []);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getPartners();
  }, []);

  const handleChangePartner = (value) => {
    setPartnerSelectedId(value);
  };

  const onChangeTab = (key) => {
    setTabKey(key);
  };

  return (
    <>
      <div>
        <h1>DANH SÁCH XỬ LÝ CONT XE NGOÀI XUẤT: </h1>
        <Space>
          <br />
          <b>Đối tác: </b>
          <Selection
            status={!partnerSelectedId ? 'error' : ''}
            data={partners}
            placeholder='Chọn đối tác'
            fieldValue='code'
            width={300}
            value={partnerSelectedId}
            onChange={handleChangePartner}
            onSearch={getPartners}
            allowClear={false}
          />
          <RangeDateFilter query={filter} onChange={setFilter} />
        </Space>
      </div>
      <Wrapper>
        <Tabs defaultActiveKey='1' onChange={onChangeTab}>
          <Tabs.TabPane tab='Chưa Hoàn Thành' key='1'>
            <PendingList tabKey={tabKey} partnerSelectedId={partnerSelectedId} filter={filter} />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Đã Hoàn Thành' key='2'>
            <CompletedList tabKey={tabKey} partnerSelectedId={partnerSelectedId} />
          </Tabs.TabPane>
        </Tabs>
      </Wrapper>
    </>
  );
};

export { BillContPartnerList };
