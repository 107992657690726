import { useState, useEffect, useRef } from 'react';
import { Button, Tooltip, Badge } from 'antd';
import styled from 'styled-components';
import {
  SendOutlined,
  CheckCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Selection } from '~components';
import ApiServices from '~services';
import { color } from '~theme';
import useApiHook from '../wb-hook/api-hook';

const AntFloatButton = styled.div`
  position: absolute;
  bottom: -33px;
  right: 80px;
  display: flex;
  gap: 10px;
`;

const AntButton = styled(Button)`
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
`;
export const JobsTransferWbPlan = ({
  onChange,
  selected,
  selectedRows,
  updateLoading,
  reloadFinish,
}) => {
  const timeOutRef = useRef(null);
  const [partner, setPartner] = useState();
  const [partners, setPartners] = useState([]);

  useEffect(() => onChange?.(partner), [partner]);

  const { transferJobsWbPlan } = useApiHook();

  const getPartners = (Query) => {
    return ApiServices.getPartnerByCompany({ Query })
      .then((result) => {
        const data = result.data.data;
        setPartners(data);
      })
      .catch((err) => console.log('Get Partners Error', err));
  };
  useEffect(() => {
    timeOutRef.current && clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => getPartners(), 2500);
  }, []);

  const onChangePartner = (_, objData) => {
    setPartner(objData?.data);
  };
  const onClickTransfer = () => {
    if (!selected?.length) {
      return;
    }
    let conts = {};
    for (let select of selectedRows) {
      conts[select.orderId] = [...(conts[select.orderId] || []), select.id];
    }
    updateLoading?.(true);
    return transferJobsWbPlan(partner?.id, conts)
      .then(() => {
        setPartner();
      })
      .finally(() => {
        updateLoading?.(false);
        reloadFinish?.();
      });
  };
  const onCancelSelect = () => {
    setPartner();
  };
  const tooltip = (
    <>
      Chuyển tới:{' '}
      <Selection
        data={partners}
        width={240}
        onSearch={getPartners}
        fieldValue='code'
        placeholder='Chọn đối tác'
        value={partner?.id}
        onChange={onChangePartner}
        typeOption='partner-whiteboard'
      />
    </>
  );

  return (
    <>
      <AntFloatButton>
        {partner && (
          <Tooltip title={`Chuyển cho ${partner.code} (${selected?.length}) CV`}>
            <Badge count={selected?.length}>
              <AntButton
                // size='small'
                shape='square'
                style={{ color: 'green' }}
                onClick={onClickTransfer}
                icon={<SendOutlined style={{ color: color.primary }} />}
              />
            </Badge>
          </Tooltip>
        )}
        <Tooltip title={tooltip}>
          <AntButton
            // size='small'
            type='primary'
            shape='square'
            style={{ marginLeft: '8px' }}
            width={partner ? 'auto' : '40px'}
            icon={partner ? <CheckCircleOutlined /> : <PlusCircleOutlined />}
          />
        </Tooltip>
        {partner && (
          <Tooltip title='Hủy'>
            <AntButton
              shape='square'
              // size='small'
              tooltip=''
              style={{ marginLeft: '8px' }}
              onClick={onCancelSelect}
              icon={<DeleteOutlined style={{ color: color.error }} />}
            />
          </Tooltip>
        )}
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            margin: '0 auto',
            marginTop: 'auto',
            color: 'blue',
          }}
        >
          <b>Chuyển Cont</b>
        </div>
      </AntFloatButton>
    </>
  );
};
