import { Tag } from 'antd';
import { memo } from 'react';
import { CONT_STATUSES, ORDER_STATUS } from '~commons/constants';

export const PlantStatus = memo(({ status }) => {
  switch (status) {
    case 0:
      return <Tag color='#5A5A5A'>Kế Hoạch</Tag>;
    case 1:
      return <Tag color='#2F89FC'>Đang Chờ Nhận</Tag>;
    case 2:
      return <Tag color='#83BD75'>Đã Nhận</Tag>;
    case 3:
      return <Tag color='#F7B32B'>Vận Chuyển</Tag>;
    case 9:
      return <Tag color='#5FAD56'>Hoàn Thành</Tag>;
    case -1:
      return <Tag color='#2F8F9D'>Đang làm</Tag>;

    default:
      return null;
  }
});
export const OrderStatus = memo(({ status }) => {
  switch (status) {
    case ORDER_STATUS.INITIAL:
      return <Tag color='#5A5A5A'>Khởi Tạo</Tag>;
    case ORDER_STATUS.PLANED:
      return <Tag color='#2F89FC'>Kế Hoạch</Tag>;
    case ORDER_STATUS.REVISED:
      return <Tag color='#143F6B'>Đang xử lí</Tag>;
    case ORDER_STATUS.DOING:
      return <Tag color='#F7B32B'>Vận Chuyển</Tag>;
    case ORDER_STATUS.FINISH:
      return <Tag color='#5FAD56'>Hoàn Thành</Tag>;
    case ORDER_STATUS.CANCELED:
      return <Tag color='#FF6363'>Hủy</Tag>;

    default:
      return null;
  }
});
export const ContStatus = memo(({ status, revisedText = 'Đang xử lí' }) => {
  switch (status) {
    case CONT_STATUSES.INITIAL:
      return <Tag color='#5A5A5A'>Khởi Tạo</Tag>;
    case CONT_STATUSES.PLANED:
      return <Tag color='#2F89FC'>Kế Hoạch</Tag>;
    case CONT_STATUSES.PENDING:
      return <Tag color='#143F6B'>{revisedText}</Tag>;
    case CONT_STATUSES.DOING:
      return <Tag color='#F7B32B'>Vận Chuyển</Tag>;
    case CONT_STATUSES.PARTNER_TRANSFERRED:
      return <Tag color='#B3B622'>Đã Chuyển ĐT</Tag>;
    case CONT_STATUSES.REDOING:
      return <Tag color='#F7B32B'>Vận Chuyển(KN)</Tag>;
    case CONT_STATUSES.REPLAN:
      return <Tag color='#2F89FC'>Kế Hoạch(KN)</Tag>;
    case CONT_STATUSES.FINISH:
      return <Tag color='#5FAD56'>Hoàn Thành</Tag>;
    case CONT_STATUSES.CANCELED:
      return <Tag color='#FF6363'>Đã Hủy</Tag>;
    default:
      return '';
  }
});
