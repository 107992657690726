import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import { ItemInput } from './item-input';
import styled from 'styled-components';
import { CONT_ORDER_TYPES } from '~commons/constants';

export const ListContInput = ({ types = [], onChange, type }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    let res = {};
    for (let el of types) {
      res[el.id] = {
        id: el.id,
        name: el.name,
        values: [
          {
            total: 0,
            date: null,
            id: el.id,
          },
        ],
      };
    }
    setData(res);
  }, [types]);

  const onChangeValues = (id, newValues) => {
    setData((prev) => {
      let res = { ...prev };
      res[id].values = newValues;
      onChange && onChange(res);
      return res;
    });
  };
  const renderData = useMemo(() => {
    return Object.values(data);
  }, [data]);

  const dateLabel = useMemo(() => {
    switch (type) {
      case CONT_ORDER_TYPES.BOOKING:
        return 'Ngày YCGC';
      case CONT_ORDER_TYPES.BILL:
        return 'Ngày YCLC';
      case CONT_ORDER_TYPES.TRANSIT:
        return 'Ngày CK';
      default:
        return 'Ngày Yêu Cầu';
    }
  }, [type]);

  return (
    <>
      <Row>
        <Title span={4}>Loại cont</Title>
        <Title span={4}>Số lượng cont</Title>
        <Title span={6}>{dateLabel}</Title>
        <Title span={4}>#</Title>
      </Row>
      {renderData.map((el) => {
        return (
          <div key={'obj-' + el.id}>
            <ItemInput data={el} onChange={onChangeValues} datePlaceholder={dateLabel} />
          </div>
        );
      })}
    </>
  );
};
const Title = styled(Col)`
  font-weight: bold;
  display: flex;
  justify-content: center;
`;
