import React, { useEffect } from 'react';
import storage from '~storage';
import { Form, Switch, Button, Divider } from 'antd';

const Settings = () => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      isDarkTheme: storage.isDarkTheme,
    });
  });
  const changeModelTheme = async (value) => {
    return storage.changeIsDarkTheme(value).then(window.location.reload());
  };
  const onSubmitForm = async (value) => {
    await changeModelTheme(value.isDarkTheme);
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const formLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  return (
    <>
      <Divider orientation='left' plain>
        GIAO DIÊN HỆ THỐNG
      </Divider>
      <Form {...formLayout} form={form} layout='horizontal' onFinish={onSubmitForm}>
        <Form.Item
          label='Chế Độ Tối'
          name='isDarkTheme'
          valuePropName={storage.isDarkTheme ? 'checked' : ''}
        >
          <Switch checkedChildren='Dark' unCheckedChildren='Light' />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type='primary' htmlType='submit'>
            Cập Nhật
          </Button>
        </Form.Item>
      </Form>
      <Divider />
    </>
  );
};

export { Settings };
