import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';
import { Selection } from '~components';
const { Option } = Select;

const UserManagementForm = ({
  fields,
  isNew,
  id,
  isDriver,
  reload,
  onChange,
  setLoading,
  dataSelected,
}) => {
  const inputRef = useRef(null);
  const [groups, setGroups] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loadingGetData, setLoadingGetData] = useState(false);

  const getVendors = async (query = '') => {
    if (loadingGetData) return;
    setLoadingGetData(true);
    const newQuery = { Query: query, PageNumber: 1, PageSize: 500 };
    try {
      let { data } = await ApiServices.getVendors(newQuery);
      if (data?.data) {
        setVendors(data?.data);
      }
    } catch (error) {
      console.log('Get vendor list failed:', error);
    } finally {
      setLoadingGetData(false);
    }
  };

  const getDataInit = async () => {
    const [getGroup] = await Promise.all([ApiServices.getGroups(), ApiServices.getCompanies()]);
    setGroups(getGroup.data);
  };

  const getCompanies = async (query = '') => {
    const newQuery = {
      Columns: {
        name: query,
      },
    };
    try {
      const res = await ApiServices.getCompanies(query ? newQuery : null);
      if (res?.data?.data) {
        setCompanies(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDataInit();
    getVendors();
    getCompanies();
  }, []);

  const onCompareWithApi = (value, cb) => {
    if (inputRef.current) {
      clearTimeout(inputRef.current);
      cb();
    }
    inputRef.current = setTimeout(async () => {
      try {
        const { data } = await ApiServices.existsCodeUser(null, value);
        const isUsed = data;
        cb(isUsed);
      } catch (error) {
        console.error(error);
      }
    }, 1000);
  };

  const checkExistsCode = async (_, value) => {
    if (value.length > 3) {
      const ind = fields.findIndex((item) => {
        return item.name[0] === 'code';
      });
      if (isNew === false && value !== fields[ind].value) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('User này đã tạo rồi !!!'));
        }
      }

      if (isNew === true) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('User này đã tạo rồi !!!'));
        }
      }
    }
  };

  const createUser = async (data) => {
    data.password = '123456';
    data.isDriver = false;
    try {
      const res = await ApiServices.createUser(data);
      if (res.data) {
        message.success('Thêm mới Người Dùng thành công');
        return reload();
      } else {
        message.error('Thêm mới Người Dùng thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới Người Dùng thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const updateUser = async (data) => {
    data.id = id;
    try {
      const res = await ApiServices.updateUser(id, data);
      if (res.data) {
        message.success('Cập nhật Người Dùng thành công');
        return reload();
      } else {
        message.error('Cập nhật Người Dùng thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật Người Dùng thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createUser(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updateUser(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      name='global_state'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        // {...itemLayout}
        style={{ marginTop: '10px' }}
        name='code'
        label='Tên Đăng Nhập'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Tên Đăng Nhập',
          },
          {
            validator: checkExistsCode,
          },
          {
            type: 'string',
            min: 3,
            message: 'Bạn phải nhập ít nhất 3 ký tự',
            // max: 10,
            // message: 'Tên đăng nhập tối thiểu 3 ký tự, tối đa 10 ký tự',
          },
        ]}
      >
        <Input placeholder='Tên Đăng Nhập' />
      </Form.Item>

      <Form.Item
        style={{ marginTop: '10px' }}
        name='fullName'
        label='Họ Tên'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Họ Tên',
          },
        ]}
      >
        <Input placeholder='Họ Tên' />
      </Form.Item>
      <Form.Item
        name='email'
        label='Email'
        rules={[
          {
            type: 'email',
            message: 'Email không hợp lệ',
          },
        ]}
      >
        <Input placeholder='Email' />
      </Form.Item>
      <Form.Item
        name='arrMemberGroup'
        label='Nhóm Thành Viên'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn Nhóm Thành Viên',
          },
        ]}
      >
        {/* <AutoComplete
          asyncApi={ApiServices.getGroups}
          placeholder='Nhóm Thành Viên'
          fieldValue='code'
          fieldLabel='name'
          multiple
        /> */}
        <Select mode='multiple' showArrow showSearch placeholder='Nhóm Thành Viên'>
          {groups.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='vendorId' label='Nhà Xe'>
        <Selection
          data={vendors}
          placeholder='Lựa Chọn Nhà Xe'
          fieldValue='name'
          disabled={isDriver ? true : false}
          onSearch={getVendors}
          mode='multiple'
        />
      </Form.Item>
      <Form.Item name='companyId' label='Công Ty'>
        <Selection
          labelDefault={dataSelected?.companyName || ''}
          data={companies}
          placeholder='Bạn phải chọn Công Ty'
          fieldValue='name'
          onSearch={getCompanies}
        />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { UserManagementForm };
