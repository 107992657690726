import React, { useEffect, useState, useMemo } from 'react';
import { Form, Button, DatePicker, Input, Row, Col, Tabs, Divider } from 'antd';
import moment from 'moment';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';
import { DATE_FORMAT } from '~commons/constants';
import { Selection } from '~components';

// fee
import { TransportFee } from './transport-fee';
import { TransitFee } from './transit-fee';
import { RomoocFee } from './romooc-fee';

const { TextArea } = Input;

const ContractForm = ({
  fields,
  isNew,
  contractIdUpdate,
  dataSelect,
  valueDetail,
  onChange,
  setLoading,
  reload,
  countAddNew,
  setCountAddNew,
}) => {
  const [form] = Form.useForm();
  const [loadingForm, setLoadingForm] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locationSelected, setLocationSelected] = useState([]);
  const [locationPackingList, setLocationPackingList] = useState([]);
  // Cont Type
  const [contTypeList, setContTypeList] = useState([]);

  // Data Fee Transport
  const [dataFeeMainTransport, setDataFeeMainTransport] = useState([]);
  // Data Fee Transit
  const [dataFeeMainTransit, setDataFeeMainTransit] = useState([]);
  // Data Fee Romooc
  const [dataFeeRomooc, setDataFeeRomooc] = useState([]);

  const isSubmitForm = useMemo(() => {
    return !dataFeeMainTransport?.length > 0 && !dataFeeMainTransit?.length > 0;
  }, [dataFeeMainTransport, dataFeeMainTransit]);

  useEffect(() => {
    // Transport Fee
    if (valueDetail && valueDetail?.transport?.length > 0) {
      const dataMapFeeMainTransport = valueDetail?.transport?.map((item) => {
        item?.fees?.map((el) => {
          item[el.contTypeId] = el.fee;
        });
        if (item?.locations?.length > 0) {
          item.locationFromName = item?.locations[0]?.name;
          item.locationToName = item?.locations[1]?.name;
        }
        return item;
      });
      setDataFeeMainTransport(dataMapFeeMainTransport);
    } else {
      setDataFeeMainTransport([]);
    }
    // Transit Fee
    if (valueDetail && valueDetail?.transit?.length > 0) {
      const dataMapFeeMainTransit = valueDetail?.transit?.map((item) => {
        item?.fees?.map((el) => {
          item[el.contTypeId] = el.fee;
        });
        if (item?.locations?.length > 0) {
          item.locationFromName = item?.locations[0]?.name;
          item.locationToName = item?.locations[1]?.name;
        }
        return item;
      });
      setDataFeeMainTransit(dataMapFeeMainTransit);
    } else {
      setDataFeeMainTransit([]);
    }
    // Romooc Fee
    if (valueDetail && valueDetail?.romoocs?.length > 0) {
      setDataFeeRomooc(valueDetail?.romoocs);
    } else {
      setDataFeeRomooc([]);
    }
    // locations nơi đóng hàng
    if (valueDetail && valueDetail?.locations?.length > 0) {
      const arrLocationsDetail = valueDetail?.locations?.map((el) => el.id);
      setLocationPackingList(valueDetail?.locations);
      setLocationSelected(arrLocationsDetail);
    } else {
      setLocationPackingList([]);
      setLocationSelected([]);
    }
  }, [valueDetail]);

  const reloadAfterUpdate = async () => {
    setLoading(true);
    return ApiServices.getContractDetails(valueDetail?.contractId)
      .then((res) => {
        // Transport Fee
        if (res?.data?.data?.transport?.length > 0) {
          const dataMapFeeMainTransport = res?.data?.data?.transport?.map((item) => {
            item?.fees?.map((el) => {
              item[el.contTypeId] = el.fee;
            });
            if (item?.locations?.length > 0) {
              item.locationFromName = item?.locations[0]?.name;
              item.locationToName = item?.locations[1]?.name;
            }
            return item;
          });
          setDataFeeMainTransport(dataMapFeeMainTransport);
        } else {
          setDataFeeMainTransport([]);
        }
        // Transit Fee
        if (res?.data?.data?.transit?.length > 0) {
          const dataMapFeeMainTransit = res?.data?.data?.transit?.map((item) => {
            item?.fees?.map((el) => {
              item[el.contTypeId] = el.fee;
            });
            if (item?.locations?.length > 0) {
              item.locationFromName = item?.locations[0]?.name;
              item.locationToName = item?.locations[1]?.name;
            }
            return item;
          });
          setDataFeeMainTransit(dataMapFeeMainTransit);
        } else {
          setDataFeeMainTransit([]);
        }
        // Romooc Fee
        if (res?.data?.data?.romoocs?.length > 0) {
          setDataFeeRomooc(res?.data?.data?.romoocs);
        } else {
          setDataFeeRomooc([]);
        }
      })
      .catch((err) => {
        console.log('Get details contract failed: ', err);
      })
      .finally(() => setLoading(false));
  };

  const getCustomer = async (Query = null) => {
    setLoadingForm(true);
    try {
      let { data } = await ApiServices.getCustomer({ Query });
      if (data?.data) {
        setCustomers(data.data);
      }
    } catch (error) {
      console.log('Error - get customers', error);
    } finally {
      setLoadingForm(false);
    }
  };
  const getLocations = async (Query = null) => {
    try {
      let { data } = await ApiServices.getLocations({ Query });
      if (data?.data) {
        let dataLocations = data?.data;

        if (valueDetail && !isNew && valueDetail?.locations) {
          const listId = data?.data?.map((item) => item.id);
          valueDetail?.locations?.length > 0 &&
            valueDetail?.locations?.map((item) => {
              if (!listId.includes(item.id)) {
                dataLocations.unshift(item);
              }
            });
        }
        setLocations(dataLocations);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getContType = async () => {
    setLoadingForm(true);
    try {
      let { data } = await ApiServices.getContTypes();
      if (data?.data) {
        setContTypeList(data?.data);
      }
    } catch (error) {
      console.log('Error - get contType', error);
    } finally {
      setLoadingForm(false);
    }
  };

  useEffect(() => {
    getCustomer();
    getLocations();
    getContType();
  }, [dataSelect?.contractId]);

  const clearDate = () => {
    setDataFeeMainTransport([]);
    setDataFeeMainTransit([]);
    setDataFeeRomooc([]);
  };

  useEffect(() => {
    if (isNew) {
      clearDate();
    }
  }, [isNew, countAddNew]);

  ///////////// --- CREATE ------ /////////////////
  const handleCreate = async (values) => {
    const transportData = dataFeeMainTransport?.map((item) => {
      return {
        distance: item?.distanceId,
        fees: item?.fees,
      };
    });
    const transitData = dataFeeMainTransit?.map((item) => {
      return {
        distance: item?.distanceId,
        fees: item?.fees,
      };
    });
    const romoocData = dataFeeRomooc?.map((item) => {
      return {
        locationId: item?.locationId,
        price: item?.price,
        freeDate: item?.freeDate,
      };
    });
    const dataSend = {
      info: {
        contractNumber: values?.contractNumber,
        customerId: values?.customerId,
        contractDate: values?.contractDate && moment(values?.contractDate).format('YYYY-MM-DD'),
        expiredDate: values?.expiredDate && moment(values?.expiredDate).format('YYYY-MM-DD'),
        renewExpiredDate: null,
        arrLocations: values?.arrLocations,
        note: values?.note,
      },
      transport: transportData?.length > 0 ? transportData : null,
      transit: transitData?.length > 0 ? transitData : null,
      romoocs: romoocData?.length > 0 ? romoocData : null,
    };
    setLoadingForm(true);
    return ApiServices.createContract(dataSend)
      .then((res) => {
        if (res?.data?.data) {
          window.TmsNoti.success('Thêm mới Hợp đồng thành công');
          return reload();
        } else {
          window.TmsNoti.error('Lỗi', 'Thêm mới thất bại');
        }
      })
      .catch((err) => {
        console.log({ err });
        window.TmsNoti.error('Lỗi', 'Thêm mới thất bại');
      })
      .finally(() => {
        clearDate();
        setCountAddNew(0);
        setLoadingForm(false);
      });
  };
  ///////////// --- UPDATE ------ /////////////////
  const handleUpdate = async (values) => {
    const transportData = dataFeeMainTransport?.map((item) => {
      let dataTransport = {
        distanceId: item?.distanceId,
        fees: item?.fees,
        contractId: contractIdUpdate,
      };
      if (item?.id) {
        dataTransport.id = item.id;
        // dataTransport.contractId = item.contractId;
      }
      return dataTransport;
    });
    const transitData = dataFeeMainTransit?.map((item) => {
      let dataTransit = {
        distanceId: item?.distanceId,
        fees: item?.fees,
        contractId: contractIdUpdate,
      };
      if (item?.id) {
        dataTransit.id = item.id;
        // dataTransit.contractId = item.contractId;
      }
      return dataTransit;
    });
    const romoocData = dataFeeRomooc?.map((item) => {
      let dataRomooc = {
        locationId: item?.locationId,
        price: item?.price,
        freeDate: item?.freeDate,
        contractId: contractIdUpdate,
      };
      if (item?.id) {
        dataRomooc.id = item.id;
      }
      return dataRomooc;
    });
    const dataSend = {
      contractId: valueDetail?.contractId,
      contractNumber: valueDetail?.contractNumber,
      companyId: valueDetail?.companyId,
      customerId: valueDetail?.customerId,
      customerName: valueDetail?.customerName,
      contractDate: values?.contractDate && moment(values?.contractDate).format('YYYY-MM-DD'),
      expiredDate: values?.expiredDate && moment(values?.expiredDate).format('YYYY-MM-DD'),
      renewExpiredDate: null,
      locations: valueDetail.locations,
      arrLocations: values?.arrLocations,
      note: values?.note,
      transport: transportData?.length > 0 ? transportData : null,
      transit: transitData?.length > 0 ? transitData : null,
      romoocs: romoocData?.length > 0 ? romoocData : null,
    };

    setLoadingForm(true);
    return ApiServices.updateContractService(contractIdUpdate, dataSend)
      .then((res) => {
        if (res?.data?.data) {
          window.TmsNoti.success('Cập nhật thành công');
          return reload();
        } else {
          window.TmsNoti.error('Lỗi', 'Cập nhật thất bại');
        }
      })
      .catch((err) => {
        console.log({ err });
        window.TmsNoti.error('Lỗi', 'Cập nhật thất bại');
      })
      .finally(() => setLoadingForm(false));
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await handleCreate(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await handleUpdate(value);
    setLoading(false);
  };

  const getContractNumberByCustomer = async (customerId) => {
    setLoadingForm(true);
    try {
      let { data } = await ApiServices.getContractNumberByCustomerId(customerId);
      if (data?.data) {
        form.setFieldsValue({
          contractNumber: data?.data,
        });
      } else {
        form.setFieldsValue({
          contractNumber: '',
        });
      }
    } catch (error) {
      console.log('Error - get contract number', error);
    } finally {
      setLoadingForm(false);
    }
  };

  const handleChangeCustomer = async (customerId) => {
    if (!customerId) {
      return form.setFieldsValue({
        contractNumber: '',
      });
    }
    return getContractNumberByCustomer(customerId);
  };

  const handleChangeSelectedLocation = (value, valueAll) => {
    const mapValueAll = valueAll?.map((item) => {
      return {
        id: item.value,
        name: item.children,
      };
    });
    setLocationSelected(value);
    setLocationPackingList(mapValueAll);
  };

  const onChangeTabs = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: '1',
      label: `Phí vận chuyển`,
      children: (
        <TransportFee
          contTypeList={contTypeList}
          dataFeeMainTransport={dataFeeMainTransport}
          setDataFeeMainTransport={setDataFeeMainTransport}
          locationSelected={locationSelected}
          reloadAfterUpdate={reloadAfterUpdate}
          contractStatus={!isNew ? valueDetail?.status : 0}
          isNew={isNew}
          valueDetail={valueDetail ? valueDetail : null}
        />
      ),
    },
    {
      key: '2',
      label: `Phí chuyển kho`,
      children: (
        <TransitFee
          contTypeList={contTypeList}
          dataFeeMainTransit={dataFeeMainTransit}
          setDataFeeMainTransit={setDataFeeMainTransit}
          locationSelected={locationSelected}
          reloadAfterUpdate={reloadAfterUpdate}
          // contractStatus={!isNew ? valueDetail?.status : 0}
          isNew={isNew}
          valueDetail={valueDetail ? valueDetail : null}
        />
      ),
    },
  ];

  return (
    <>
      <Form
        {...layout}
        form={form}
        name='global_state'
        className='form-input-data'
        onFinish={onSubmitForm}
        fields={fields}
        onFieldsChange={(_, allFields) => {
          onChange(allFields);
        }}
        layout='vertical'
      >
        <Divider>
          <b>I: Thông tin hợp đồng</b>
        </Divider>
        <div
          style={{
            backgroundColor: 'rgb(234, 238, 243)',
            boxShadow:
              'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
            borderRadius: '10px',
            fontSize: '12px',
            padding: '10px',
            marginBottom: '15px',
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              {!isNew ? (
                <Form.Item label='Số hợp đồng:'>
                  <b className='blue'>{valueDetail?.customerName}</b>
                </Form.Item>
              ) : (
                <Form.Item
                  name='customerId'
                  label='Khách hàng'
                  rules={[
                    {
                      required: true,
                      message: 'Bạn phải chọn khách hàng',
                    },
                  ]}
                >
                  <Selection
                    disabled={!isNew}
                    data={customers}
                    labelDefault={dataSelect?.customerName}
                    fieldValue='name'
                    placeholder='Khách hàng'
                    onSearch={getCustomer}
                    onChange={handleChangeCustomer}
                  />
                </Form.Item>
              )}
            </Col>
            <Col span={12}>
              {!isNew ? (
                <Form.Item label='Số hợp đồng:' name='contractNumber'>
                  <b className='blue'>{valueDetail?.contractNumber}</b>
                </Form.Item>
              ) : (
                <Form.Item label='Số hợp đồng:' name='contractNumber'>
                  <Input readOnly />
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='contractDate'
                label='Ngày hợp đồng'
                rules={[
                  {
                    required: true,
                    message: 'Bạn phải chọn ngày hợp đồng',
                  },
                ]}
              >
                <DatePicker
                  placeholder='Ngày hợp đồng'
                  style={{ width: '100%' }}
                  format={DATE_FORMAT}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='expiredDate'
                label='Ngày hết hạn'
                // rules={[
                //   {
                //     required: true,
                //     message: 'Bạn phải chọn ngày hết hạn',
                //   },
                // ]}
              >
                <DatePicker
                  placeholder='Ngày hết hạn'
                  style={{ width: '100%' }}
                  format={DATE_FORMAT}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            {/* <Col span={12}> */}
            {/* <Form.Item
              name='renewExpiredDate'
              label='Ngày gia hạn'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải chọn ngày gia hạn',
                },
              ]}
            >
              <DatePicker
                placeholder='Ngày gia hạn'
                style={{ width: '100%' }}
                format={DATE_FORMAT}
              />
            </Form.Item> */}
            {/* </Col> */}
            <Col span={16}>
              <Form.Item
                label='Nơi đóng hàng'
                name='arrLocations'
                rules={[
                  {
                    required: true,
                    message: 'Bạn phải chọn nơi đóng hàng',
                  },
                ]}
              >
                <Selection
                  data={locations}
                  placeholder='Nơi đóng hàng'
                  fieldValue='name'
                  onSearch={getLocations}
                  mode='multiple'
                  onChange={handleChangeSelectedLocation}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label='Ghi chú' name='note'>
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>

          {!isNew && (
            <Form.Item {...tailLayout} className='submit-form-input-data'>
              <Button
                type='primary'
                htmlType='submit'
                loading={loadingForm}
                disabled={isSubmitForm}
              >
                Cập nhật thông tin
              </Button>
            </Form.Item>
          )}
        </div>
        <Divider>
          <b>II: Chi phí hợp đồng</b>
        </Divider>
        <div
          style={{
            backgroundColor: 'rgb(234, 238, 243)',
            boxShadow:
              'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
            borderRadius: '10px',
            fontSize: '12px',
            padding: '10px',
            marginBottom: '15px',
          }}
        >
          {/* Transport & Transit Fee */}
          {isSubmitForm && (
            <div
              style={{
                marginTop: '20px',
              }}
            >
              <i className='blue'>
                Note: Bạn phải có ít nhất 1 chi phí vận chuyển hoặc phí chuyển kho, hoặc có cả 2:
              </i>
            </div>
          )}
          <Tabs defaultActiveKey='1' items={items} onChange={onChangeTabs} />
          {/* Romooc Fee */}
          <RomoocFee
            dataFeeRomooc={dataFeeRomooc}
            setDataFeeRomooc={setDataFeeRomooc}
            locationSelected={locationSelected}
            locationPackingList={locationPackingList}
            reloadAfterUpdate={reloadAfterUpdate}
            // contractStatus={!isNew ? valueDetail?.status : 0}
            isNew={isNew}
            valueDetail={valueDetail ? valueDetail : null}
          />
        </div>
        {isSubmitForm && (
          <div
            style={{
              marginTop: '20px',
              marginBottom: '-20px',
              textAlign: 'center',
            }}
          >
            <i className='red'>
              Bạn phải có ít nhất 1 chi phí vận chuyển hoặc phí chuyển kho, hoặc có cả 2
            </i>
          </div>
        )}
        {/* Thêm mới */}
        {isNew && (
          <Form.Item
            {...tailLayout}
            className='submit-form-input-data'
            style={{
              marginTop: '30px',
            }}
          >
            <Button type='primary' htmlType='submit' loading={loadingForm} disabled={isSubmitForm}>
              Thêm Mới Hợp Đồng
            </Button>
          </Form.Item>
        )}
      </Form>
    </>
  );
};

export { ContractForm };
