import { Button, Input, Select, Tooltip } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import Utils from '~commons/utils';
import { CONT_OPTIONS_STATUSES } from '~commons/constants';
import styled from 'styled-components';
import { ContStatus } from '~components/common-status';
const { Option } = Select;
const SuccessButton = styled(Button)`
  padding: 0 8px;
  &.ant-btn-link:not([disabled]) {
    color: green;
  }
`;

export const getColumns = (onColumnSearch, onColumnSearchSelect, showListFile, onClickUpdate) => [
  {
    title: '#',
    width: '110px',
    fixed: 'left',
    dataIndex: 'action',
    align: 'center',
    render: (_, record) => (
      <>
        <Button
          shape='round'
          type='link'
          onClick={() => onClickUpdate(record)}
          style={{ padding: '0px 8px 5px 8px' }}
          disabled={record?.status !== 0 && record?.status !== 2 && record?.status !== 9}
        >
          <Tooltip title='Xử lý' placement='topLeft' color='pink'>
            <SyncOutlined
              style={{
                fontSize: '15px',
              }}
            />{' '}
            Xử lý
          </Tooltip>
        </Button>
      </>
    ),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Khách hàng</div>
      </span>
    ),
    sorter: true,
    dataIndex: 'customerName',
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    children: [
      {
        title: (
          <span style={{ textAlign: 'center' }}>
            <Input placeholder='Search' allowClear name='customerName' onChange={onColumnSearch} />
          </span>
        ),
        width: '150px',
        dataIndex: 'customerName',
        align: 'center',
      },
    ],
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Hãng tàu</div>
      </span>
    ),
    sorter: true,
    dataIndex: 'shippingLine',
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    children: [
      {
        title: (
          <span style={{ textAlign: 'center' }}>
            <Input name='shippingLine' placeholder='Search' allowClear onChange={onColumnSearch} />
          </span>
        ),
        width: '130px',
        dataIndex: 'shippingLine',
        align: 'center',
      },
    ],
  },

  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>SubNo</div>
      </span>
    ),
    sorter: true,
    dataIndex: 'subnoNumber',
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    children: [
      {
        title: (
          <span style={{ textAlign: 'center' }}>
            <Input name='subnoNumber' placeholder='Search' allowClear onChange={onColumnSearch} />
          </span>
        ),
        width: '180px',
        dataIndex: 'subnoNumber',
        render: (value, record) => (
          <>
            {value}
            <br />
            <SuccessButton type='link' onClick={() => showListFile(record)}>
              File Đk
            </SuccessButton>
          </>
        ),
      },
    ],
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ngày Booking</div>
      </span>
    ),
    width: '90px',
    dataIndex: 'createdOn',
    sorter: true,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (value, record) => Utils.formatDateHourFn(value, record),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Số cont</div>
        <Input name='contNumber' placeholder='Search' allowClear onChange={onColumnSearch} />
      </span>
    ),
    width: '120px',
    align: 'center',
    dataIndex: 'contNumber',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Số seal</div>
        <Input name='sealNumber' placeholder='Search' allowClear onChange={onColumnSearch} />
      </span>
    ),
    width: '100px',
    dataIndex: 'sealNumber',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Trạng thái</div>
        <Select
          // mode="multiple"
          placeholder='Search'
          defaultValue={''}
          style={{ width: 100, fontSize: '11px' }}
          onChange={(status) => onColumnSearchSelect(status, 'status')}
        >
          {CONT_OPTIONS_STATUSES.map((item) => (
            <Option key={item.label + item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </span>
    ),
    width: '120px',
    textAlign: 'center',
    align: 'center',
    dataIndex: 'status',
    render: (value, record) => (
      <>
        <ContStatus status={value} revisedText='Đang xử lý' />
        {record.partnerName && (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            ({record.partnerName})
          </div>
        )}
      </>
    ),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Cắt Máng</div>
      </span>
    ),
    width: '90px',
    dataIndex: 'book_CutOffDateTime',
    sorter: true,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (value, record) => Utils.renderWarningDate(value, record),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Cắt SI</div>
      </span>
    ),
    width: '90px',
    dataIndex: 'book_SIDateTime',
    sorter: true,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (value, record) => Utils.renderWarningDate(value, record),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ngày YCGC</div>
      </span>
    ),
    width: '90px',
    dataIndex: 'book_DeliveryContRequestDate',
    sorter: true,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (value) => Utils.formatDate(value),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Nơi Đóng Hàng</div>
      </span>
    ),
    width: '130px',
    dataIndex: 'lh',
    align: 'center',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Hạ/Bãi T.Lý</div>
      </span>
    ),
    width: '130px',
    dataIndex: 'gh',
    align: 'center',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Điểm Lấy Rỗng</div>
      </span>
    ),
    width: '130px',
    dataIndex: 'book_LocationDepotFromName',
    align: 'center',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Nguồn</div>
        <Input name='source' placeholder='Search' allowClear onChange={onColumnSearch} />
      </span>
    ),
    width: '80px',
    textAlign: 'center',
    align: 'center',
    dataIndex: 'source',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ghi chú khách hàng</div>
      </span>
    ),
    width: '200px',
    dataIndex: 'note',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ghi chú xe ngoài</div>
      </span>
    ),
    width: '200px',
    dataIndex: 'notePlan',
  },
];
