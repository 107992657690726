import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchData,
  loadMore,
  updateQuery,
  updateLoading,
  updateSelected,
} from '~store/reducers/depot-cont/action';
import { RangeDateFilter, TableWhiteBoard, WBTableLoadMore } from '~components';
import { getColumns } from './columns';
import { Wrapper, Title } from '~commons/styles';
import { useWBTableScroll } from '~hooks';
import ApiServices from '~services';
import { message } from 'antd';
import { showListFile } from '~store/reducers/white-board/action';

export const DepotContHandle = () => {
  const dispatch = useDispatch();
  const timeOutRef = useRef(null);

  const { data: contsData, query, loading, selected } = useSelector((state) => state.depotCont);
  //================= Scroll ======================
  const [loadingMore, setLoadingMore] = useState(false);
  const [locations, setLocations] = useState([]);
  const { tableRef, isScroll } = useWBTableScroll(null);

  const setLoading = (value) => dispatch(updateLoading(value));

  const getLocations = async (Query = null) => {
    try {
      const { data } = await ApiServices.getLocations({ Query });
      setLocations(data.data);
    } catch (err) {
      console.error('ERR - Get Shipping Lines', err);
    }
  };
  React.useEffect(() => {
    getLocations();
  }, []);
  const LoadMoreData = async () => {
    if (loadingMore) return;
    if (contsData.pageNumber * query.PageSize <= contsData.totalRecords) {
      let tmpQuery = { ...query, PageNumber: contsData.pageNumber + 1 };
      setLoadingMore(true);
      await dispatch(loadMore(tmpQuery));
      setLoadingMore(false);
    }
  };
  useEffect(() => {
    if (isScroll) {
      LoadMoreData();
    }
  }, [isScroll]);

  const initData = async () => {
    dispatch(updateSelected([]));
    setLoading(true);
    await dispatch(fetchData(query));
    setLoading(false);
  };
  useEffect(() => {
    timeOutRef.current && clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => initData(), 500);
  }, [query]);

  const setQuery = (columns) => {
    const tmpQuery = { ...query };
    tmpQuery.Columns = { ...tmpQuery.Columns, ...columns };
    dispatch(updateQuery(tmpQuery));
  };

  const onSelectChange = (selectedRowKey) => {
    dispatch(updateSelected(selectedRowKey));
  };
  const onLocationChange = (id, value) => {
    setLoading(true);
    if (!value) {
      setLoading(false);

      return message.error('Vui Lòng Chọn Địa Điểm!');
    }
    ApiServices.updateDepotCont(id, value)
      .then((res) => {
        if (res?.status === 200) {
          initData();
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <Wrapper ref={tableRef}>
      <Title>
        Lấy Rỗng/Trả Rỗng Kế Hoạch <b>({contsData?.totalRecords || 0})</b>
        <RangeDateFilter query={query} updateQuery={updateQuery} />
      </Title>
      <TableWhiteBoard
        rowKey='id'
        columns={getColumns(setQuery, locations, getLocations, onLocationChange, (record) =>
          dispatch(showListFile(record))
        )}
        data={contsData?.data}
        loading={loading}
        onSelectChange={onSelectChange}
        isNotSubHeight
        selectedParent={selected}
      />
      <WBTableLoadMore loading={loadingMore} />
    </Wrapper>
  );
};
