import React, { useState, useEffect, useCallback } from 'react';
import { Space, Select, Input } from 'antd';
import moment from 'moment';
import { debounce } from 'lodash';
import { Wrapper, TableTitle } from '~commons/styles';
import { TableLayout, RangeDateFilter } from '~components';
import Utils from '~commons/utils';
import ApiServices from '~services';

const { Option } = Select;
const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD'),
};

export const TaskJobsTransitReceivedFromPartner = () => {
  const [loading, setLoading] = useState(false);
  const [ordersFromWMS, setOrdersFromWMS] = useState([]);
  const [partners, setPartners] = useState([]);
  const [partnerSelectedId, setPartnerSelectedId] = useState(null);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [totalRecords, setTotalRecords] = useState(0);

  const getOrdersFromWMS = async (partnerId, query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getTaskJobsTransitReceivedFromPartner(partnerId, newQuery)
      .then((res) => {
        console.log({ res });
        if (res?.data?.data) {
          setOrdersFromWMS(res?.data?.data || []);
          setTotalRecords(res?.data?.totalRecords || 0);
        }
      })
      .catch((err) => {
        console.error('Get Task Jobs By Partner Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPartners = async (query = '') => {
    return ApiServices.getPartnerByCompany({ query })
      .then(({ data }) => {
        setPartners(data?.data || []);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getPartners();
  }, []);

  const onColumnSearch = (event) => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = event.target.value;
      const name = event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;

      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((partnerId, newFilter) => {
      getOrdersFromWMS(partnerId, newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    if (partnerSelectedId) {
      timeoutGetData(partnerSelectedId, filter);
    }
  }, [partnerSelectedId, filter]);

  const columns = [
    {
      title: (
        <TableTitle>
          Đối tác
          <Input name='tracePartnerName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'tracePartnerName',
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Phiếu CK
          <Input name='code' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'code',
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Khách Hàng
          {/* <Input name='customerName' onChange={onColumnSearch} placeholder='Search' /> */}
        </TableTitle>
      ),
      dataIndex: 'customerName',
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Địa Điểm Bắt Đầu
          {/* <Input name='beginLocationName' onChange={onColumnSearch} placeholder='Search' /> */}
        </TableTitle>
      ),
      dataIndex: 'beginLocationName',
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Địa Điểm Kết Thúc
          {/* <Input name='endLocationName' onChange={onColumnSearch} placeholder='Search' /> */}
        </TableTitle>
      ),
      dataIndex: 'endLocationName',
      align: 'center',
    },
    {
      title: <TableTitle>Ngày điều</TableTitle>,
      dataIndex: 'createdOn',
      align: 'center',
      width: 150,
      render: (val) => Utils.formatDate(val),
    },
  ];

  const handleChangeSelectedPartner = async (partnerId) => {
    if (partnerId) {
      setPartnerSelectedId(partnerId);
    } else {
      setPartnerSelectedId(null);
      setOrdersFromWMS([]);
    }
  };

  const headerContent = (
    <div>
      <h3>DANH SÁCH CÔNG VIỆC CHUYỂN KHO NHẬN TỪ ĐỐI TÁC </h3>
      <Space>
        <br />
        <b>Đối tác: </b>
        <Select
          placeholder='Chọn đối tác'
          style={{ width: 300 }}
          allowClear={true}
          onChange={handleChangeSelectedPartner}
        >
          {partners?.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
        <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
      </Space>
    </div>
  );

  const pagination = {
    total: totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <Wrapper>
      <TableLayout
        columns={columns}
        data={ordersFromWMS}
        rowKey='id'
        bordered
        loading={loading}
        headerContent={headerContent}
        pagination={pagination}
        isDisplayCheckbox={false}
      />
    </Wrapper>
  );
};
