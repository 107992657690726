import React, { useState, useEffect, useMemo } from 'react';
import { Popconfirm, Button, Space, Badge, Modal, Input } from 'antd';
import { Wrapper } from '~commons/styles';
import { TableLayout } from '~components';
import Utils from '~commons/utils';
import ApiServices from '~services';

const RELOAD_TIME = 60 * 1000;
const RELOAD_ON = true;

export const OrdersRVSFromWMS = ({ headerLength = '220px', handleCloseModalInWhiteBoard }) => {
  const [loading, setLoading] = useState(false);
  const [ordersFromWMS, setOrdersFromWMS] = useState([]);
  const [selectedKey, setSelectedKey] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [showRefused, setShowRefused] = useState(null);
  const [noteRefused, setNoteRefused] = useState('');
  const [loadingRefused, setLoadingRefused] = useState(false);

  const countSelected = useMemo(() => {
    return selectedKey?.length;
  }, [selectedKey]);

  const getOrdersRVSFromWMS = async () => {
    setLoading(true);
    ApiServices.getOrdersRVSFromWMS()
      .then((res) => {
        if (res?.data?.data) {
          setOrdersFromWMS(res?.data?.data || []);
        }
      })
      .catch((err) => {
        console.error('Get Orders RVS from WMS Error', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getOrdersRVSFromWMS();
  }, []);

  useEffect(() => {
    const handleReload = setInterval(() => {
      RELOAD_ON && getOrdersRVSFromWMS();
    }, RELOAD_TIME);

    return () => clearInterval(handleReload);
  }, []);

  const handleAcceptOrderFromWMS = async (dataRow) => {
    setLoading(true);
    return ApiServices.importOrdersFromWMSRVS(dataRow)
      .then((res) => {
        // TH 1: Tất cả đều import thất bại (data = null)
        if (!res?.data?.orders?.data) {
          window.TmsNoti?.error('Import không thành công');
          res?.data?.orders?.errors?.length > 0 &&
            window.TmsNoti?.error(
              'Lý do',
              res?.data?.orders?.errors?.map((item, index) => (
                <div key={index}>
                  <b className='red'>
                    {index + 1}
                    {')'}
                  </b>
                  {item}
                </div>
              )),
              20
            );
          handleCloseModalInWhiteBoard && handleCloseModalInWhiteBoard();
        }
        // TH2: import 10 item, thất bại 5, thành công 5
        if (res?.data?.orders?.data && res?.data?.orders?.errors?.length > 0) {
          window.TmsNoti?.error(res?.data?.orders?.data, res?.data?.orders?.message);
          window.TmsNoti?.error(
            'Lý do',
            res?.data?.orders?.errors?.map((item, index) => (
              <div key={index}>
                <b className='red'>
                  {index + 1}
                  {')'}
                </b>
                {item}
              </div>
            )),
            20
          );
          getOrdersRVSFromWMS();
          handleCloseModalInWhiteBoard && handleCloseModalInWhiteBoard();
        }
        // TH3: tât cả thành công
        if (res?.data?.orders?.data === 'success') {
          window.TmsNoti?.success('Import thành công');
          getOrdersRVSFromWMS();
          handleCloseModalInWhiteBoard && handleCloseModalInWhiteBoard();
        }
      })
      .catch((err) => console.log('Import Orders RVS from WMS err: ', err))
      .finally(() => {
        setLoading(false);
        setSelectedRowData([]);
        setSelectedKey([]);
      });
  };

  const showModalRefused = async (record) => {
    setShowRefused(record);
  };

  const onCloseRefused = () => {
    setShowRefused(null);
    setNoteRefused('');
  };

  const handleRefused = async () => {
    if (
      !showRefused ||
      !showRefused?.auto_ID ||
      !showRefused?.booking_ID ||
      !noteRefused ||
      loadingRefused
    )
      return;
    const dataSend = {
      ids: [`${showRefused?.auto_ID}`, `${showRefused?.booking_ID}`],
      note: noteRefused,
    };
    setLoadingRefused(true);
    try {
      const res = await ApiServices.refusedRVS(dataSend);
      if (res?.data) {
        onCloseRefused();
        getOrdersRVSFromWMS();
      }
    } catch (error) {
      console.log(`error:::`, error);
    } finally {
      setLoadingRefused(false);
    }
  };

  const handleChangeNote = (event) => {
    const value = event.target?.value ?? '';

    setNoteRefused(value);
  };

  const renderCont = (_, record) => {
    return Utils.renderCont(record);
  };

  const renderContentRVS = (value) => {
    return Utils.convertToPlain(value);
  };

  const columns = [
    {
      title: 'Số Booking',
      dataIndex: 'so_Booking',
      width: 120,
      align: 'center',
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'ten_Khach_Hang',
      width: 180,
      align: 'center',
    },
    {
      title: 'Nội dung RVS',
      dataIndex: 'noi_Dung_Revive',
      width: 250,
      align: 'center',
      render: renderContentRVS,
    },
    {
      title: 'Ngày Booking',
      dataIndex: 'ngay_Booking',
      width: 120,
      align: 'center',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'Ngày cắt máng',
      dataIndex: 'ngay_Cat_Mang',
      width: 120,
      align: 'center',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'Ngày cắt SI',
      dataIndex: 'ngay_Cat_SI',
      width: 120,
      align: 'center',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'Mã hãng tàu',
      dataIndex: 'ma_Hang_Tau',
      width: 150,
      align: 'center',
    },
    {
      title: 'Tên chuyến tàu',
      dataIndex: 'ten_Chuyen_Tau',
      width: 120,
      align: 'center',
    },
    {
      title: 'Số lượng cont: 20, 40C, 40T, 45',
      dataIndex: 'auto_ID',
      width: 125,
      align: 'center',
      render: renderCont,
    },
    {
      title: 'Action',
      fixed: 'right',
      align: 'center',
      width: 140,
      render: (record) => {
        return (
          <Space direction='vertical'>
            <Popconfirm
              onConfirm={() => handleAcceptOrderFromWMS([record])}
              title='Xác nhận đơn hàng này?'
              okText='Đồng ý'
              cancelText='Không'
            >
              <Button type='primary' size='small' style={{ padding: '0px 20px' }}>
                Xác nhận
              </Button>
            </Popconfirm>
            <Button
              onClick={() => showModalRefused(record)}
              type='primary'
              danger
              size='small'
              style={{ padding: '0px 20px' }}
            >
              Từ Chối
            </Button>
          </Space>
        );
      },
    },
  ];

  const rowSelection = (selectKey, rowData) => {
    setSelectedKey(selectKey);
    setSelectedRowData(rowData);
  };

  const headerContent = (
    <Space>
      <h3>
        Booking RVS từ Kho: <b className='red'>({ordersFromWMS?.length}) </b>{' '}
      </h3>
      <Badge count={countSelected}>
        <Popconfirm
          onConfirm={() => handleAcceptOrderFromWMS(selectedRowData)}
          title={`Xác nhận ${countSelected} đơn hàng?`}
          okText='Đồng ý'
          cancelText='Không'
          disabled={countSelected < 1}
        >
          <Button
            type='primary'
            style={{ padding: '0px 20px', marginLeft: '10px' }}
            disabled={countSelected < 1}
          >
            Xác nhận
          </Button>
        </Popconfirm>
      </Badge>
    </Space>
  );
  const renderRowClassName = (record) => {
    if (record?.xe_Ngoai_ID === 0) {
      return '';
    } else {
      return 'order-from-ONP';
    }
  };
  return (
    <Wrapper>
      <TableLayout
        columns={columns}
        data={ordersFromWMS}
        rowKey='auto_ID'
        bordered
        loading={loading}
        selectParent={selectedKey}
        onSelectChange={rowSelection}
        headerContent={headerContent}
        headerLength={headerLength}
        rowClassName={renderRowClassName}
      />
      <Modal
        title='Từ Chối Nhận Rvs'
        open={!!showRefused}
        onCancel={onCloseRefused}
        onOk={handleRefused}
        okButtonProps={{
          disabled: !noteRefused,
        }}
      >
        <div>
          <b>Số Booking: </b> <span>{showRefused?.so_Booking}</span>
        </div>
        <br />
        <b>Lý Do Từ Chối: </b>
        <span className='red'>(*)</span>
        <Input.TextArea
          placeholder='Lý Do Từ Chối'
          value={noteRefused}
          onChange={handleChangeNote}
        />
      </Modal>
    </Wrapper>
  );
};
