import { Input, Button, Badge, Select, Tooltip, Space } from 'antd';
import { PrinterOutlined, EyeOutlined } from '@ant-design/icons';
import Utils from '~commons/utils';
import { TableTitle } from '~commons/styles';

const STATUS_OPTION_SELECTS = [
  {
    label: 'Tất cả',
    value: '',
  },
  {
    label: 'Đã cấp',
    value: '1',
  },
  {
    label: 'Chưa cấp',
    value: '0',
  },
];

const statusTag = (text = '', bgColor = '', count = null) => {
  return (
    <Badge count={count}>
      <div
        style={{
          backgroundColor: bgColor,
          width: '100px',
          padding: '5px 10px',
          textAlign: 'center',
          borderRadius: '4px',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        {text}
      </div>
    </Badge>
  );
};
const { Option } = Select;

const renderStatus = (value, record) => {
  if (value) {
    return statusTag('Đã cấp', 'green', record?.count);
  }
  return statusTag(' Chưa cấp', '#EB455F');
};

/**
 * cover for TMS-714 by duongnm
 * 65defc351c5425d639b23bac Vũ Văn Biên
 * 65debd98792f2170b807033e Nguyễn Hữu Tài
 */
const DRIVER_ID_LIST = ['65defc351c5425d639b23bac', '65debd98792f2170b807033e'];

export const getColumns = (onColumnSearch, handleShowDetails, handleSelectItem) => [
  {
    title: <TableTitle>Ngày điều xe</TableTitle>,
    dataIndex: 'assignOn',
    align: 'center',
    width: 130,
  },
  {
    title: (
      <TableTitle>
        Số xe
        <Input name='carCode' onChange={onColumnSearch} placeholder='Search' />
      </TableTitle>
    ),
    dataIndex: 'carCode',
    align: 'center',
    width: 130,
  },
  {
    title: (
      <TableTitle>
        Lái xe
        <Input name='driverMainName' onChange={onColumnSearch} placeholder='Search' />
      </TableTitle>
    ),
    dataIndex: 'driverMainName',
    align: 'center',
    width: 150,
  },
  {
    title: <TableTitle>Số Km</TableTitle>,
    dataIndex: 'totalKmAll',
    align: 'center',
    width: 130,
  },
  {
    title: <TableTitle>Định mức (lít/100Km)</TableTitle>,
    dataIndex: 'oilQuota',
    align: 'center',
    width: 130,
  },
  {
    title: <TableTitle>Giá dầu</TableTitle>,
    dataIndex: 'oilPrice',
    align: 'center',
    width: 130,
    render: (val) => val && Utils.formatNumber(val),
  },
  {
    title: <TableTitle>Số lượng</TableTitle>,
    dataIndex: 'newOilToSupply',
    align: 'center',
    width: 130,
  },
  {
    title: <TableTitle>Thành tiền</TableTitle>,
    dataIndex: 'newOilTotalMoney',
    align: 'center',
    width: 130,
    render: (val) => val && Utils.formatNumber(val),
  },
  {
    title: (
      <TableTitle>
        <div>Trạng thái</div>
        <Select
          placeholder='Search'
          style={{ width: 110 }}
          defaultValue=''
          onChange={(e) => onColumnSearch(e, 'status')}
        >
          {STATUS_OPTION_SELECTS.map((item) => (
            <Option key={item.label + item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </TableTitle>
    ),
    dataIndex: 'status',
    fixed: 'right',
    align: 'center',
    width: 120,
    render: renderStatus,
  },
  {
    title: '#',
    fixed: 'right',
    align: 'center',
    width: 100,
    render: (record) => {
      if (record?.status === 0) return;
      // cover for TMS-714 by duongnm
      if (DRIVER_ID_LIST.includes(record.id)) {
        return <></>;
      }
      return (
        <Space size={18}>
          <Tooltip title='Xem chi tiết' color='#108ee9'>
            <Button
              shape='circle'
              icon={<EyeOutlined />}
              onClick={() => handleShowDetails(record)}
            />
          </Tooltip>
          <Tooltip title='In phiếu dầu' color='#108ee9'>
            <Button
              onClick={() => handleSelectItem(record)}
              type='primary'
              shape='circle'
              icon={<PrinterOutlined />}
            ></Button>
          </Tooltip>
        </Space>
      );
    },
  },
];
