import { combineReducers } from 'redux';
import app from './app';
import waitingOfPlan from './waiting-of-plan';
import liftOn from './lift-on';
import waitingOfDelivery from './waiting-for-delivery';
import liftOff from './lift-off';
import whiteBoard from './white-board';
import notifications from './notifications';
import bookingCont from './booking-cont';
import bookingContCompleted from './booking-cont-completed';
import billCont from './bill-cont';
import billContCompleted from './bill-cont-completed';
import depotCont from './depot-cont';
import parkingCont from './parking-cont';
import singleCont from './single-cont';
import orderONPWMS from './order-ONP-WMS';

const rootReducer = combineReducers({
  app,
  waitingOfPlan,
  liftOn,
  liftOff,
  waitingOfDelivery,
  whiteBoard,
  notifications,
  bookingCont,
  bookingContCompleted,
  billCont,
  billContCompleted,
  depotCont,
  parkingCont,
  singleCont,
  orderONPWMS,
});

export default rootReducer;
