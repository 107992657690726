import React, { useState, useEffect } from 'react';
import { Space, Button, Form, Modal, Input, Row, Col, Tabs } from 'antd';
import { Selection } from '~components';
import ApiServices from '~services';
import { tailLayout } from './constant';
import moment from 'moment';

// fee
import { TransportFee } from '../transport-fee';
import { TransitFee } from '../transit-fee';
import { RomoocFee } from '../romooc-fee';
//
const { TextArea } = Input;

const CusItemAddForm = ({
  customerList,
  onClose,
  contTypeList,
  partnerId,
  contractNumber,
  isShow,
  handleCloseForm,
  detailInfo,
  dataDetail = [],
  getDetailByCode,
  getCustomer,
}) => {
  const [customerId, setCustomerId] = useState('');
  const [locations, setLocations] = useState([]);
  const [locationSelected, setLocationSelected] = useState([]);
  const [note, setNote] = useState('');
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  // Data Fee Transport
  const [dataFeeMainTransport, setDataFeeMainTransport] = useState([]);
  // Data Fee Transit
  const [dataFeeMainTransit, setDataFeeMainTransit] = useState([]);
  // Data Fee Romooc
  const [dataFeeRomooc, setDataFeeRomooc] = useState([]);
  const [locationPackingList, setLocationPackingList] = useState([]);

  const getLocations = async (Query = null) => {
    try {
      let { data } = await ApiServices.getLocations({ Query });
      if (data?.data) {
        let dataLocations = data?.data;
        setLocations(dataLocations);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  function handleReloadAfterCreate(newContractNumber) {
    // Close form create
    setNote('');
    setLocationSelected([]);
    setCustomerId('');
    setDataFeeMainTransport([]);
    setDataFeeMainTransit([]);
    setDataFeeRomooc([]);
    getDetailByCode(newContractNumber);
    handleClose();
    // add vao list show
  }

  ///////////// --- UPDATE ------ /////////////////
  const handleCreate = async (info, newPartnerId, dataNew) => {
    if (loadingUpdate) return;
    if (info?.expiredDate && info.contractDate) {
      if (moment(info?.expiredDate) < moment(info.contractDate)) {
        window.TmsNoti.warning('Cảnh báo', 'Ngày hết hạn phải lớn hơn hoặc bằng ngày hợp đồng !!!');
        return;
      }
    }

    if (
      !newPartnerId ||
      !info?.contractNumber ||
      !dataNew?.customerId ||
      !info?.contractDate ||
      !dataNew?.locationSelected?.length ||
      (!dataFeeMainTransport?.length && !dataFeeMainTransit?.length)
    ) {
      window.TmsNoti.warning('Thông báo', 'Vui lòng nhập đầy đủ thông tin yêu cầu!!!');
      return;
    }

    const partnerIdSend = newPartnerId;
    let codeSend = info?.contractNumber;

    const transportData = dataFeeMainTransport?.map((item) => {
      return {
        distance: item?.distanceId,
        fees: item?.fees,
      };
    });
    const transitData = dataFeeMainTransit?.map((item) => {
      return {
        distance: item?.distanceId,
        fees: item?.fees,
      };
    });
    const romoocData = dataFeeRomooc?.map((item) => {
      return {
        locationId: item?.locationId,
        price: item?.price,
        freeDate: item?.freeDate,
      };
    });
    const dataSend = {
      info: {
        contractNumber: info?.contractNumber,
        customerId: dataNew?.customerId,
        contractDate: info?.contractDate && moment(info?.contractDate).format('YYYY-MM-DD'),
        expiredDate: info?.expiredDate && moment(info?.expiredDate).format('YYYY-MM-DD'),
        renewExpiredDate: null,
        arrLocations: dataNew?.locationSelected,
        note: dataNew?.note,
      },
      transport: transportData?.length > 0 ? transportData : null,
      transit: transitData?.length > 0 ? transitData : null,
      romoocs: romoocData?.length > 0 ? romoocData : null,
    };
    setLoadingUpdate(true);
    return ApiServices.createContractForVendor(partnerIdSend, codeSend, dataSend)
      .then((res) => {
        if (res?.data?.data) {
          window.TmsNoti.success('Thêm mới Hợp đồng thành công');
          handleReloadAfterCreate(res?.data?.data?.info?.contractNumber);
        }
      })
      .catch((err) => {
        console.log({ err });
        window.TmsNoti.error('Lỗi', 'Thêm mới Hợp đồng thất bại');
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };
  ///////////// --- UPDATE ------ /////////////////
  const handleChangeCustomer = async (id) => {
    if (!id) return;
    setCustomerId(id);
  };
  function handleChangeNote(event) {
    const text = event?.target?.value || '';
    setNote(text);
  }
  const handleChangeSelectedLocation = (value, valueAll) => {
    const mapValueAll = valueAll?.map((item) => {
      return {
        id: item.value,
        name: item.children,
      };
    });
    setLocationSelected(value);
    setLocationPackingList(mapValueAll);
  };

  const items = [
    {
      key: '1',
      label: `Phí vận chuyển`,
      children: (
        <TransportFee
          contTypeList={contTypeList}
          dataFeeMainTransport={dataFeeMainTransport}
          setDataFeeMainTransport={setDataFeeMainTransport}
          locationSelected={locationSelected}
          isNew={false}
        />
      ),
    },
    {
      key: '2',
      label: `Phí chuyển kho`,
      children: (
        <TransitFee
          contTypeList={contTypeList}
          dataFeeMainTransit={dataFeeMainTransit}
          setDataFeeMainTransit={setDataFeeMainTransit}
          locationSelected={locationSelected}
          isNew={false}
        />
      ),
    },
  ];
  const onChangeTabs = (key) => {
    console.log(key);
  };

  const handleClose = () => {
    onClose();
  };

  const customerIds = dataDetail?.map((item) => item?.customerId);
  const customerListNew = customerList?.filter((el) => {
    return !customerIds.includes(el?.id);
  });

  return (
    <Modal
      title={
        <span>
          Thêm mới hợp đồng vào: <b className='blue'>{contractNumber}</b>
        </span>
      }
      open={isShow}
      onCancel={handleCloseForm}
      footer={null}
      style={{
        top: 40,
      }}
      width='80%'
    >
      <div
        style={{
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          padding: '10px',
          marginBottom: '20px',
          position: 'relative',
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <div>
              <div>
                <span>
                  <b className='red'>*</b> Khách hàng
                </span>
                <Selection
                  data={customerListNew}
                  fieldValue='shortName'
                  placeholder='Khách hàng'
                  value={customerId}
                  onChange={handleChangeCustomer}
                  onSearch={getCustomer}
                />
              </div>
              <div>
                <span>Ghi chú</span>
                <TextArea rows={2} value={note} onChange={handleChangeNote} />
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div>
              <span>
                <b className='red'>*</b> Nơi đóng hàng
              </span>
              <Selection
                data={locations}
                placeholder='Nơi đóng hàng'
                fieldValue='name'
                onSearch={getLocations}
                mode='multiple'
                value={locationSelected}
                onChange={handleChangeSelectedLocation}
              />
            </div>
          </Col>
        </Row>

        <Tabs defaultActiveKey='1' items={items} onChange={onChangeTabs} />
        {/* Romooc Fee */}
        <RomoocFee
          dataFeeRomooc={dataFeeRomooc}
          setDataFeeRomooc={setDataFeeRomooc}
          locationSelected={locationSelected}
          locationPackingList={locationPackingList}
          isNew={false}
        />

        <Form.Item
          {...tailLayout}
          className='submit-form-input-data'
          style={{
            marginTop: '30px',
          }}
        >
          <Space size='large'>
            <Button
              loading={loadingUpdate}
              type='primary'
              style={{ padding: '0 40px' }}
              onClick={() => {
                const dataAtLocation = {
                  note,
                  customerId,
                  locationSelected,
                };
                return handleCreate(detailInfo, partnerId, dataAtLocation);
              }}
            >
              Thêm mới
            </Button>
            <Button
              disabled={loadingUpdate}
              danger
              style={{ padding: '0 40px' }}
              type='primary'
              onClick={handleCloseForm}
            >
              Hủy
            </Button>
          </Space>
        </Form.Item>
      </div>
    </Modal>
  );
};

export { CusItemAddForm };
