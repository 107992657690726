import React from 'react';
import styled from 'styled-components';
import { Layout, Dropdown, Avatar, Menu } from 'antd';
import { UserOutlined, LogoutOutlined, SettingFilled, DownCircleOutlined } from '@ant-design/icons';
import logo from '~assets/images/logo.png';
import { MenuSidebar } from './items-menu';
import storage from '~storage';
import { Link } from 'react-router-dom';
import { Notifications } from '~pages/white-board/wb-notifications';

const { Header, Content, Sider } = Layout;

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: storage.isCollapsed,
      infoUser: '',
      isShowNotification: false,
      counterNotification: 0,
    };
  }

  setShowNotification(isShowNotification) {
    this.setState({ isShowNotification });
  }

  setCounterNotification(counterNotification) {
    this.setState({ counterNotification });
  }

  componentDidMount() {
    const getDatainit = async () => {
      await this.getInit();
    };

    getDatainit();
  }

  getInit = () => {
    this.setState({
      infoUser: storage.getDataDecodeToken(),
    });
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed }, () => storage.changeIsCollapsed(collapsed));
  };

  onShowNotification = async () => {
    await this.setShowNotification(!this.state.isShowNotification);
  };

  render() {
    const { collapsed } = this.state;
    const { component, isNotUseLeftMenu, isNotUseMenu } = this.props;
    const menu = (
      <Menu>
        <Menu.Item danger key='change password'>
          <Link to='/change-password'>
            <div className='logo'>
              <SettingFilled /> Đổi Mật Khẩu
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item danger key='logout'>
          <Link to='/login'>
            <div className='logo'>
              <LogoutOutlined /> Đăng Xuất
            </div>
          </Link>
        </Menu.Item>
      </Menu>
    );

    const mainLogo = (
      <Link to='/'>
        <div className='top-header-logo'>
          <img className='top-header-app-logo' src={logo} />
        </div>
      </Link>
    );
    const header = <Header className='main-header'>{mainLogo}</Header>;
    const leftMenu = isNotUseLeftMenu ? (
      header
    ) : (
      <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
        <Link to='/'>
          <div className='logo'>
            <img className='app-logo' src={logo} />
          </div>
        </Link>
        <MenuWrapper>
          <MenuSidebar />
        </MenuWrapper>

        <div className='logo mg-t-10'>
          <Avatar size='large' icon={<UserOutlined />} />

          {/* <h4 style={{color: "white"}}>{this.state.infoUser.FullName}</h4> */}
          <Dropdown overlay={menu} className='mg-t-5'>
            <h4 style={{ color: 'white', cursor: 'pointer' }}>
              {this.state?.infoUser?.FullName}{' '}
              <DownCircleOutlined style={{ fontSize: '20px', paddingLeft: '5px' }} />
            </h4>
          </Dropdown>
        </div>
      </Sider>
    );
    return (
      <>
        <Layout className='app' style={{ minHeight: '100vh' }}>
          {isNotUseMenu ? null : leftMenu}

          <Layout className='site-layout'>
            {/* <Header className="site-layout-background" style={{ padding: 0 }} /> */}
            <Content style={{ margin: '0px 16px', paddingTop: '12px' }}>
              {component}
              <div className='test-notification-icon'>
                <Notifications />
              </div>
            </Content>
            {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer> */}
          </Layout>
        </Layout>
      </>
    );
  }
}
export { MainLayout };

const MenuWrapper = styled.div`
  max-height: calc(100vh - 270px);
  overflow-y: auto;
  overflow-x: hidden;
  webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;
