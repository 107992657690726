export default {
  FETCH_DATA: 'off_fetch_data',
  UPDATE_QUERY: 'off_update_query',
  LOAD_MORE_DATA: 'off_load_more_data',
  UPDATE_LOADING: 'off_update_loading',
  UPDATE_ONE_DATA: 'off_update_one_data',
  UPDATE_SELECTED: 'off_update_selected',
  UPDATE_SELECTED_KEY: 'off_update_selected_key',
  SELECTED_DATA_ROW: 'off_selected_data_row',
  UPDATE_INDEX_SELECTED: 'off_update_index_selected',
};
