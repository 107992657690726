import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';

const PortForm = ({ fields, isNew, id, reload, onChange, setLoading }) => {
  const createPort = async (data) => {
    try {
      const res = await ApiServices.createPort(data);
      if (res.data) {
        message.success('Thêm mới Cảng thành công');
        return reload();
      } else {
        message.error('Thêm mới Cảng thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới Cảng thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const updatePort = async (data) => {
    data.id = id;
    try {
      const res = await ApiServices.updatePort(id, data);
      if (res.data) {
        message.success('Cập nhật Cảng thành công');
        return reload();
      } else {
        message.error('Cập nhật Cảng thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật Cảng thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createPort(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updatePort(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      name='global_state'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        style={{ marginTop: '10px' }}
        name='name'
        label='Tên Cảng'
        rules={[
          {
            required: true,
            message: 'bạn phải nhập Tên Cảng',
          },
        ]}
      >
        <Input placeholder='Tên Cảng' />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { PortForm };
