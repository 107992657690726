import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RangeDateFilter } from '~components';
import { Input as AntInput, Table } from 'antd';
import moment from 'moment';
import Utils from '~commons/utils';
import { TableTitle, Wrapper } from '~commons/styles';
import { debounce } from 'lodash';
import ApiServices from '~services';
import styled from 'styled-components';
import { ChangeLocationForm } from './change-location-form';
import { CONT_ORDER_TYPES } from '~commons/constants';
import { ContFiles } from '~components/cont-files';

const DEFAULT_FILTER = {
  DateFrom: moment().startOf('date').format('YYYY-MM-DD hh:mm'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD hh:mm'),
  PageNumber: 1,
  PageSize: 10,
  Text: '',
};
const Input = (props) => {
  return <AntInput {...props} placeholder='Tìm kiếm...' size='small' />;
};
export const TaskJobDiary = () => {
  const [loading, setLoading] = useState(false);
  const [dataRow, setDataRow] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [changeLocation, setChangeLocation] = useState(null);

  const getData = (query) => {
    setLoading(true);
    const Columns = {};
    if (query?.Columns) {
      for (const key of Object.keys(query?.Columns)) {
        Columns[key] =
          (moment.isMoment(query.Columns[key]) && query.Columns[key].format('YYYY-MM-DD')) ||
          query?.Columns?.[key];
      }
    }

    return ApiServices.getTaskJobDiary({ ...query, Columns })
      .then((res) => {
        setDataRow(res.data);
        setExpandedRowKeys(res.data?.data?.map?.((o) => o.carCode));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error('Error get list transfer A ', err);
      });
  };

  const onChangeColumnSearch = (event) => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = event.target.value;
      const name = event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const pagination = {
    total: dataRow?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );

  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const dataSearch = filter.Columns;

  const onRowMerged = (record) => {
    if (record.groupCar) {
      return {
        colSpan: 0,
      };
    }
  };
  const renderImageCurrent = (files) => {
    if (!files) return;
    const initImage = files?.filter((item) => item?.status === 0);
    return <ContFiles files={initImage} />;
  };
  const renderImageEdited = (files) => {
    if (!files) return;
    const initImage = files?.filter((item) => item?.status === 1);
    return <ContFiles files={initImage} />;
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Số Xe
          <Input value={dataSearch?.customerName} name='carCode' onChange={onChangeColumnSearch} />
        </TableTitle>
      ),
      dataIndex: 'carCode',
      width: 130,
      render: (_, record) =>
        record.carCode || (
          <span className='red'>
            SỐ XE: <span className='blue'>{record.groupCar}</span>
          </span>
        ),
      onCell: (record) => {
        if (record.groupCar) {
          return {
            colSpan: 3,
          };
        }
      },
    },
    {
      title: (
        <TableTitle>
          Ngày Điều
          {/* <DatePicker
            format={SHORTEN_DATE_FORMAT}
            value={dataSearch?.assignDate}
            onChange={onChangeDateColumnSearch}
          /> */}
        </TableTitle>
      ),
      dataIndex: 'assignDate',
      render: (val) => Utils.formatShortenDateTime(val),
      width: 120,
      onCell: onRowMerged,
    },
    {
      title: (
        <TableTitle>
          Công việc
          <Input value={dataSearch?.name} name='name' onChange={onChangeColumnSearch} />
        </TableTitle>
      ),
      dataIndex: 'name',
      width: 130,
    },
    {
      title: (
        <TableTitle>
          Người Điều
          <Input value={dataSearch?.assignUser} name='assignUser' onChange={onChangeColumnSearch} />
        </TableTitle>
      ),
      dataIndex: 'assignUser',
      onCell: onRowMerged,
      width: 120,
    },
    {
      title: 'Khách Hàng',
      title: (
        <TableTitle>
          Khách Hàng
          <Input
            value={dataSearch?.customerName}
            name='customerName'
            onChange={onChangeColumnSearch}
          />
        </TableTitle>
      ),
      dataIndex: 'customerName',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Tài Xế
          <Input value={dataSearch?.driverName} name='driverName' onChange={onChangeColumnSearch} />
        </TableTitle>
      ),
      dataIndex: 'driverName',
      width: 120,
    },
    {
      title: (
        <TableTitle>
          Số Romooc
          <Input value={dataSearch?.romoocCode} name='romoocCode' onChange={onChangeColumnSearch} />
        </TableTitle>
      ),
      dataIndex: 'romoocCode',
      width: 100,
    },
    {
      title: (
        <TableTitle>
          Subno
          <Input
            value={dataSearch?.subnoNumber}
            name='subnoNumber'
            onChange={onChangeColumnSearch}
          />
        </TableTitle>
      ),
      dataIndex: 'subnoNumber',
      width: 200,
    },

    {
      title: (
        <TableTitle>
          Khởi Hành
          <Input
            value={dataSearch?.beginLocationName}
            name='beginLocationName'
            onChange={onChangeColumnSearch}
          />
        </TableTitle>
      ),
      dataIndex: 'beginLocationName',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Kết Thúc
          <Input
            value={dataSearch?.endLocationName}
            name='endLocationName'
            onChange={onChangeColumnSearch}
          />
        </TableTitle>
      ),
      dataIndex: 'endLocationName',
      width: 150,
    },
    {
      title: 'Số Km',
      dataIndex: 'distance',
      width: 60,
    },
    {
      title: 'Km Hao Hụt',
      dataIndex: 'lossOfDistance',
      width: 60,
    },
    {
      dataIndex: 'contNumber',
      title: (
        <TableTitle>
          Số Cont
          <Input value={dataSearch?.contNumber} name='contNumber' onChange={onChangeColumnSearch} />
        </TableTitle>
      ),
      width: 130,
    },
    {
      dataIndex: 'files',
      title: <TableTitle>File đính kèm</TableTitle>,
      width: 130,
      render: renderImageCurrent,
    },
    {
      dataIndex: 'files',
      title: <TableTitle>Đã chỉnh sửa</TableTitle>,
      width: 130,
      render: renderImageEdited,
    },
    {
      title: (
        <TableTitle>
          Ghi Chú
          <Input value={dataSearch?.note} name='note' onChange={onChangeColumnSearch} />
        </TableTitle>
      ),
      dataIndex: 'note',
      width: 120,
    },
    {
      title: <TableTitle>Thao tác</TableTitle>,
      // dataIndex: 'typeOrder',
      fixed: 'right',
      width: 120,
      render: (value, record) => renderAction(value, record),
    },
  ];
  const getChildren = (row) => {
    let data = [];
    for (const job of row.jobs) {
      data = [...data, ...job.assignJobs];
    }
    return data;
  };
  const formattedData = useMemo(() => {
    return dataRow?.data?.map?.((el) => ({
      groupCar: el.carCode,
      id: el.carCode,
      children: getChildren(el),
    }));
  }, [dataRow?.data]);

  const handleChangeLocation = (record) => {
    setChangeLocation(record);
  };

  const renderAction = (values, record) => {
    if (
      (record?.sort === 1 && record?.typeOrder === CONT_ORDER_TYPES.BOOKING) ||
      (record?.sort === 6 && record?.typeOrder === CONT_ORDER_TYPES.SUPPORT)
    ) {
      const ind = formattedData?.findIndex((item) => item?.id === record?.carCode);
      // Check the next job is Lấy Rỗng Lại_SP: typeOrder === 'Support' vaf Sort = 6
      let indexTask = -1;
      if (ind !== -1) {
        indexTask = formattedData[ind]?.children?.findIndex((el) => el?.id === record?.id);
      }
      if (
        ind !== -1 &&
        indexTask !== -1 &&
        formattedData[ind]?.children?.[indexTask + 1]?.sort === 6
      ) {
        return <></>;
      }
      return (
        <a
          type='link'
          style={{ padding: '0 0', color: 'red' }}
          onClick={() => handleChangeLocation(record)}
        >
          Đổi địa điểm lấy rỗng
        </a>
      );
    }
  };

  const rowSelection = {
    checkStrictly: false,
  };
  const expandable = { expandedRowKeys, onExpandedRowsChange: setExpandedRowKeys };

  const renderRowClassName = (record) => {
    if (record?.typeOrder === CONT_ORDER_TYPES.SUPPORT && record?.sort === 6) {
      return 'task-job-daily-background';
    }
    return '';
  };

  return (
    <Wrapper>
      <RangeDateFilter query={filter} onChange={setFilter} />
      <br />
      <TableStyled
        loading={loading}
        columns={columns}
        dataSource={formattedData}
        pagination={pagination}
        expandable={expandable}
        rowSelection={rowSelection}
        rowKey='id'
        scroll={{ x: '100%', y: 'calc(100vh - 230px)' }}
        bordered
        rowClassName={renderRowClassName}
      />
      <ChangeLocationForm
        job={changeLocation}
        onClose={() => setChangeLocation(null)}
        reload={() => getData(filter)}
        setLoading={setLoading}
      />
    </Wrapper>
  );
};

const TableStyled = styled(Table)`
  & .ant-table-row-indent.indent-level-1 {
    display: none;
  }
  & button.ant-table-row-expand-icon-spaced {
    display: none;
  }
`;
