import React, { useEffect, useRef } from 'react';
import { message } from 'antd';

export const SearchBar = ({ onChange, address, mapService }) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const handlePlaceChanged = async () => {
    const place = await autoCompleteRef.current.getPlace?.();
    if (!place?.address_components) {
      return message.error('Không tồn tại vị trí địa điểm này, vui lòng thử lại sau!');
    }
    onChange?.(place);
  };
  const options = {
    componentRestrictions: { country: 'Vn' },
    types: ['geocode'],
  };

  useEffect(() => {
    if (mapService) {
      const { Autocomplete } = mapService.places;
      autoCompleteRef.current = new Autocomplete(inputRef.current, options);
      autoCompleteRef.current.addListener('place_changed', handlePlaceChanged);
    }
  }, [mapService]);
  useEffect(() => {
    address && (inputRef.current.value = address);
  }, [address]);

  return (
    <input
      ref={inputRef}
      className='ant-input autocomplete-input'
      placeholder='Nhập địa điểm cần tìm kiếm!'
      type='text'
    />
  );
};
