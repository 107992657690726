import { Table, Typography } from 'antd';
import Utils from '~commons/utils';
const { Text } = Typography;

const TableContent = ({ loading, data }) => {
  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
    },
    {
      title: 'Số xe',
      with: 140,
      dataIndex: 'carCode',
    },
    {
      title: 'Tên',
      dataIndex: 'driverName',
      with: 140,
    },
    {
      title: 'Có CT',
      dataIndex: 'totalWithBill',
      align: 'center',
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: 'Không CT',
      dataIndex: 'totalWithoutBill',
      align: 'center',
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: 'Thành Tiền',
      dataIndex: 'total',
      align: 'center',
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: 'Ký Nhận',
      dataIndex: 'signature',
    },
  ];

  const handleSummary = (pageData) => {
    let sumTotalWithBill = 0;
    let sumTotalWithoutBill = 0;
    let sumTotal = 0;

    pageData.forEach((page) => {
      sumTotalWithBill += page?.totalWithBill;
      sumTotalWithoutBill += page?.totalWithoutBill;
      sumTotal += page?.total;
    });
    return (
      <>
        <Table.Summary.Row
          style={{
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '13px',
          }}
        >
          <Table.Summary.Cell index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            <Text type='danger' strong>
              Tổng Cộng:
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            <Text type='danger' strong>
              {Utils.formatNumber(sumTotalWithBill)}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            <Text type='danger' strong>
              {Utils.formatNumber(sumTotalWithoutBill)}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}>
            <Text type='danger' strong>
              {Utils.formatNumber(sumTotal)}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7}>
            <Text type='danger' strong></Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };
  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={false}
      bordered={true}
      rowKey='id'
      size='small'
      summary={handleSummary}
      scroll={{
        y: `calc(100vh - 260px`,
      }}
    />
  );
};

export { TableContent };
