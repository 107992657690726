import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Button, DatePicker, Input, Tooltip } from 'antd';
import {
  SendOutlined,
  SaveOutlined,
  DeleteOutlined,
  SyncOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Selection } from '~components';
import ApiServices from '~services';
import { useSelector } from 'react-redux';
import useApiHook from '../wb-hook/api-hook';
import { DATE_TIME_FORMAT } from '~commons/constants';

export const Header = ({ onChangeLoading, loading, dataLoaded }) => {
  const [cars, setCars] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [partners, setPartners] = useState([]);
  const [partner, setPartner] = useState();
  const [assignDate, setAssignDate] = useState(moment());
  const [tour, setTour] = useState();
  const [showPartner, setShowPartner] = useState(false);

  const [isSearchDriver, setIsSearchDriver] = useState(false);
  const [isSearchPartner, setIsSearchPartner] = useState(false);

  const [locationCurrent, setLocationCurrent] = useState(null);
  const [changeLocationLoading, setChangeLocationLoading] = useState(false);

  const { carAssign, driverAssign, dataAssign, romoocAssign, finalTask } = useSelector(
    (state) => state.whiteBoard
  );
  const { selected: dataSelectedLiftOn } = useSelector((state) => state.liftOn);
  const { selected: dataSelectedLiftOff } = useSelector((state) => state.liftOff);

  const {
    updateAssignCar,
    updateAssignDriver,
    closeAssign,
    submitAssignPlan,
    submitAssign,
    transferJobs,
  } = useApiHook();

  const getCars = async (text) => {
    onChangeLoading(true);
    let dataFilterSend = {
      PageNumber: 0,
      PageSize: 10,
      Columns: '',
    };
    if (text) {
      const columnJSONSTRING = JSON.stringify({ code: text });
      dataFilterSend.Columns = columnJSONSTRING;
    } else {
      delete dataFilterSend['Columns'];
    }

    return ApiServices.getCars(dataFilterSend)
      .then((result) => {
        const data = result.data.data;
        setCars(data);
      })
      .catch((err) => console.log('Get Cars Error', err))
      .finally(() => onChangeLoading(false));
  };
  const getDrivers = async (text) => {
    onChangeLoading(true);
    let dataFilterSend = {
      PageNumber: 1,
      PageSize: 10,
      Columns: '',
    };
    if (text) {
      const columnJSONSTRING = JSON.stringify({ name: text });
      dataFilterSend.Columns = columnJSONSTRING;
    } else {
      delete dataFilterSend['Columns'];
    }

    return ApiServices.getDrivers(dataFilterSend)
      .then((result) => {
        const data = result.data.data;
        setDriverList(data);
      })
      .catch((err) => console.log('Get Drivers Error', err))
      .finally(() => onChangeLoading(false));
  };
  // ======= Get Location =============
  const getLocations = async (Query = null) => {
    try {
      const res = await ApiServices.getLocations({ Query });
      if (res?.data?.data) {
        const dataNew = res?.data?.data?.filter(
          (item) => item.id !== carAssign?.currentLocationCode
        );
        setLocations(dataNew);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ===================================
  const getPartners = async (query = null) => {
    onChangeLoading(true);
    return ApiServices.getPartnerByCompany({ query })
      .then((result) => {
        const data = result?.data?.data || [];
        setPartners(data);
      })
      .catch((err) => console.log('Get Partners Error', err))
      .finally(() => onChangeLoading(false));
  };

  useEffect(() => {
    dataLoaded.cars && setCars(dataLoaded.cars);
    dataLoaded.drivers && !isSearchDriver && setDriverList(dataLoaded.drivers);
    dataLoaded.partners && !isSearchPartner && setPartners(dataLoaded.partners);
  }, [dataLoaded]);
  useEffect(() => {
    !dataLoaded.cars && getCars(dataLoaded.cars);
  }, []);

  useEffect(() => {
    carAssign?.tour && setTour(carAssign.tour);
  }, [carAssign?.tour]);

  useEffect(() => {
    if (carAssign?.id) {
      updateAssignDriver(carAssign?.listDriverCode?.[0]);
    }
  }, [carAssign?.id]);

  useEffect(() => {
    carAssign?.driverMainCode && updateAssignDriver(carAssign?.driverMainCode);
  }, [carAssign?.driverMainCode]);

  useEffect(() => {
    if (carAssign?.tracePartnerId) {
      setShowPartner(true);
      setPartner(carAssign?.tracePartnerId);
      return;
    }
    setShowPartner(false);
    setPartner();
  }, [carAssign?.tracePartnerId]);
  useEffect(() => {
    if (carAssign?.currentLocationCode) {
      setLocationCurrent(carAssign?.currentLocationCode);
    } else {
      setLocationCurrent(null);
    }
  }, [carAssign?.currentLocationCode]);

  useEffect(() => {
    getLocations();
  }, []);

  const onChangeAssignCar = (_, obj) => {
    // If clear select car => get car list with default filter
    if (!obj?.data) {
      getCars();
      updateAssignDriver(null);
    }
    updateAssignCar(obj?.data || {});
  };
  const onChangePartner = (value) => {
    setPartner(value);
  };
  const onChangeAssignDriver = (value) => {
    updateAssignDriver(value || carAssign?.listDriverCode?.[0]);
  };
  const onChangeCurrentLocationOfCar = async (locationId) => {
    if (changeLocationLoading) return;
    setChangeLocationLoading(true);
    return ApiServices.updateCurrentLocationOfCar(carAssign?.id, locationId)
      .then((res) => {
        if (res?.data?.data === 'success') {
          const locationInfo = locations?.find((item) => item?.id === locationId);

          const indCar = cars?.findIndex((item) => item?.id === carAssign?.id);
          if (indCar !== -1) {
            cars[indCar].currentLocationCode = locationId;
            cars[indCar].currentLocationName = locationInfo?.name;
          }
          updateAssignCar({
            ...carAssign,
            currentLocationCode: locationId,
            currentLocationName: locationInfo?.name,
          });
        }
      })
      .catch((err) => console.log('Cập nhật địa điểm hiện tại của xe thất bại', err))
      .finally(() => setChangeLocationLoading(false));
  };
  const isDisabledSubmitAssignPlan = () => {
    if (loading || !dataAssign?.length) return true;
    return false;
  };
  const isDisabledSubmit = () => {
    if (loading || !carAssign?.id || driverAssign?.id || !dataAssign?.length) return true;
    for (const key in dataAssign) {
      const prevAssign = key > 0 && dataAssign[key - 1];
      if (!dataAssign[key].endLocation && prevAssign?.anchorage !== true) {
        return true;
      }
      if (prevAssign?.anchorage === true && !prevAssign.parking) {
        return true;
      }
    }
    return false;
  };
  const onSubmit = useCallback(async () => {
    onChangeLoading(true);
    await submitAssign(carAssign, dataAssign, romoocAssign, finalTask, assignDate, driverAssign);
    onChangeLoading(false);
  }, [carAssign, romoocAssign, dataAssign, finalTask, assignDate, driverAssign]);

  const onSubmitAssignPlan = useCallback(async () => {
    onChangeLoading(true);
    await submitAssignPlan(dataAssign, carAssign, romoocAssign, tour);
    onChangeLoading(false);
  }, [carAssign, romoocAssign, dataAssign, tour]);

  const onSubmitCancelAssignPlan = useCallback(async () => {
    const carAssignClear = {};
    const romoocAssignClear = {};
    const tourClear = null;
    onChangeLoading(true);
    await submitAssignPlan(dataAssign, carAssignClear, romoocAssignClear, tourClear);
    onChangeLoading(false);
  }, [carAssign, romoocAssign, dataAssign, tour]);

  const onChangeTour = (e) => setTour(e.target.value);
  const onClickShowPartner = () => setShowPartner((prev) => !prev);
  const onSubmitTransferJobs = useCallback(() => {
    transferJobs(partner, dataAssign, false, romoocAssign?.romoocId);
  }, [partner, romoocAssign]);

  const handleSearchDriver = (text) => {
    if (text) {
      setIsSearchDriver(true);
    } else {
      setIsSearchDriver(false);
    }
    getDrivers(text);
  };
  const handleSearchPartner = (text) => {
    if (text) {
      setIsSearchPartner(true);
    } else {
      setIsSearchPartner(false);
    }
    getPartners(text);
  };

  return (
    <HeaderWrapper>
      <div>
        <Label>Chọn {!showPartner ? 'Xe' : 'Đối tác'}</Label>
        <DriverSelectionView>
          <Tooltip title={`Chọn ${showPartner ? 'Xe' : 'Đối tác'}`}>
            <Button type='default' icon={<SyncOutlined />} onClick={onClickShowPartner} />
          </Tooltip>
          {showPartner ? (
            <Selection
              data={partners}
              width={300}
              onSearch={handleSearchPartner}
              placeholder='Chọn đối tác'
              labelDefault={carAssign?.tracePartnerName}
              value={partner}
              onChange={onChangePartner}
              typeOption='partner-whiteboard'
            />
          ) : (
            <Selection
              data={cars}
              width={130}
              onSearch={getCars}
              fieldValue='code'
              placeholder='Chọn xe'
              onChange={onChangeAssignCar}
              value={carAssign?.id}
              labelDefault={carAssign?.code}
              typeOption='carWhiteBoard'
            />
          )}
        </DriverSelectionView>
      </div>
      {!showPartner && (
        <>
          <div>
            <Label>Tài xế</Label>
            <Selection
              data={driverList}
              width={170}
              onSearch={handleSearchDriver}
              fieldValue='name'
              placeholder='Chọn tài xế'
              onChange={onChangeAssignDriver}
              value={driverAssign}
              labelDefault={carAssign?.driverMainName}
              disabled={!carAssign?.id}
              allowClear={false}
            />
          </div>
          <div>
            <Label>
              Điểm bắt đầu{' '}
              {/* {carAssign?.currentLocationName && (
                <Tooltip title='Đổi địa điểm bắt đầu của xe'>
                  <SyncOutlined
                    style={{
                      cursor: 'pointer',
                      color: 'red',
                    }}
                    twoToneColor='#52c41a'
                  />
                </Tooltip>
              )} */}
            </Label>{' '}
            <Selection
              data={locations}
              width={170}
              onSearch={getLocations}
              fieldValue='name'
              placeholder='Chọn địa điểm'
              onChange={onChangeCurrentLocationOfCar}
              value={locationCurrent}
              labelDefault={carAssign?.currentLocationName}
              allowClear={false}
              disabled={!carAssign?.id}
            />
            {/* <InputStyled value={carAssign?.currentLocationName || '-'} disabled /> */}
          </div>
        </>
      )}

      <div>
        <Label>STT tour </Label>
        <InputStyled value={tour} onChange={onChangeTour} placeholder='STT tour' />
      </div>
      <DateInput>
        <Label
          style={{
            color: 'red',
            fontSize: '15px',
            fontWeight: 'bold',
          }}
        >
          Ngày Điều Xe
        </Label>
        <DateAssignInput
          status={!assignDate ? 'error' : ''}
          showTime={{ format: 'HH:mm' }}
          format={DATE_TIME_FORMAT}
          placeholder='Ngày điều xe'
          value={assignDate}
          onChange={setAssignDate}
        />
      </DateInput>
      {dataSelectedLiftOn?.length > 0 || dataSelectedLiftOff?.length > 0 || showPartner ? (
        <div
          style={{
            width: '80px',
            height: '100%',
          }}
        ></div>
      ) : (
        <>
          <SavePlanBtn
            type='primary'
            loading={loading}
            icon={<SaveOutlined size={20} />}
            disabled={isDisabledSubmitAssignPlan()}
            onClick={onSubmitAssignPlan}
          >
            Lưu K.Hoạch
          </SavePlanBtn>

          <CancelPlanBtn
            type='ghost'
            loading={loading}
            icon={<DeleteOutlined size={25} />}
            disabled={isDisabledSubmitAssignPlan()}
            onClick={onSubmitCancelAssignPlan}
          >
            Hủy K.Hoạch
          </CancelPlanBtn>
        </>
      )}
      {showPartner ? (
        <SubmitButton
          type='primary'
          loading={loading}
          icon={<SendOutlined size={20} />}
          disabled={!partner}
          onClick={onSubmitTransferJobs}
        >
          Chuyển ĐT
        </SubmitButton>
      ) : dataSelectedLiftOn?.length > 0 || dataSelectedLiftOff?.length > 0 ? (
        <SubmitButton
          type='primary'
          loading={loading}
          icon={<SendOutlined size={20} />}
          disabled={isDisabledSubmit()}
          onClick={onSubmit}
        >
          Điều xe
        </SubmitButton>
      ) : (
        <></>
      )}
      <CloseButton type='danger' loading={loading} onClick={closeAssign} icon={<CloseOutlined />}>
        Đóng
      </CloseButton>
    </HeaderWrapper>
  );
};
const DriverSelectionView = styled.div`
  display: flex;
  gap: 8px;
`;
const CloseButton = styled(Button)`
  width: 80px;
  height: 100%;
`;
const SavePlanBtn = styled(Button)`
  width: 120px;
  height: 100%;
  margin-left: auto;
`;
const CancelPlanBtn = styled(Button)`
  width: 120px;
  height: 100%;
`;
const SubmitButton = styled(Button)`
  width: 160px;
  height: 100%;
  margin-left: auto;
`;
const DateInput = styled.div``;
const Label = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  margin-bottom: 4px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 4px;
  & .ant-input[disabled] {
    font-weight: 500;
    color: #1a3c40;
  }
`;
const InputStyled = styled(Input)`
  width: 140px;
`;
const DateAssignInput = styled(DatePicker)`
  width: 150px;
`;
