import React, { useEffect, forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Input, message, Button, Row, Col } from 'antd';
import ApiServices from '~services';
import { formatFields } from './fields-data';
import { UploadFile } from '~components/upload-file';
import { CONT_ORDER_TYPES, JOB_HANDLE_WIDTH, TASK_STATUSES } from '~commons/constants';
import styled from 'styled-components';
import checkContNumber from '~commons/check-cont-number';

const defaultFieldData = formatFields({});
export const FormInput = forwardRef(({ dataInput, reload, onClose, onSubmit }, ref) => {
  const [fields, setFields] = useState(defaultFieldData);
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    submitForm() {
      form?.submit();
    },
  }));

  useEffect(() => {
    form.resetFields();
    if (!dataInput) {
      setFields(defaultFieldData);
    } else {
      setFields(formatFields(dataInput));
    }
  }, [dataInput?.subnoNumber]);

  const onSubmitForm = (value) => {
    const formData = new FormData();
    formData.append('jobsId', dataInput.jobId?.[0]);
    formData.append('jobsId', dataInput.jobId?.[1]);
    formData.append('updateValue', TASK_STATUSES.FINISH);
    formData.append('contNumber', value.contNumber);
    formData.append('sealNumber', value.sealNumber);
    if (value.contFiles?.length) {
      for (let file of value.contFiles) {
        formData.append('files', file.originFileObj);
      }
    }

    return ApiServices.completeJobsFromPartner(formData)
      .then((res) => {
        if (res.data) {
          reload?.();
          onClose?.();
          onSubmit?.();
          return message.success('Thao tác thành công!!!');
        }
        message.error('Thao tác thất bại');
      })
      .catch((err) => {
        console.error('Complete Jobs From Partner Error', err);
      });
  };
  const onFieldsChanged = (_, allFields) => {
    setFields(allFields);
  };
  const onContInputChange = (val) => {
    val.contNumber && form.setFieldsValue({ contNumber: val.contNumber });
    val.sealNumber && form.setFieldsValue({ sealNumber: val.sealNumber });
  };

  return (
    <>
      <FormStyled
        labelCol={{
          span: 6,
        }}
        // labelAlign='left'
        form={form}
        layout='vertical'
        name='global_state'
        // className='form-job-handle'
        onFinish={onSubmitForm}
        fields={fields}
        onFieldsChange={onFieldsChanged}
      >
        <Row gutter={8}>
          {/* <Col span={12}>
            <Form.Item name='subnoNumber' label='Số SubNo'>
              <Input disabled />
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item
              name='contNumber'
              label='Số Cont'
              rules={[
                {
                  validator: async (_, value) =>
                    value &&
                    !checkContNumber(value) &&
                    Promise.reject(new Error('Số cont không hợp lệ!')),
                },
                dataInput?.type === CONT_ORDER_TYPES.BOOKING && {
                  required: true,
                  message: 'Bạn phải nhập Số Cont',
                },
              ]}
            >
              <Input placeholder='Số cont' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='sealNumber' label='Số Seal'>
              <Input placeholder='Số Seal' />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name='note' label='Ghi chú'>
          <Input.TextArea rows={3} placeholder='Ghi chú' />
        </Form.Item>

        <Form.Item name='contFiles' label='File đính kèm'>
          <UploadFile onInputCont={onContInputChange} />
        </Form.Item>
        <Form.Item label='Thao tác'>
          <Button type='primary' htmlType='submit'>
            Hoàn Thành
          </Button>
        </Form.Item>
      </FormStyled>
    </>
  );
});
const FormStyled = styled(Form)`

  & .ant-form-item {
    margin-bottom: 8px;
    .ant-form-item-label{
      padding: 0 0 4px;
    }
    // .ant-upload.ant-upload-select-picture-card,
    // .ant-upload-list-picture-card-container {
    //   width: calc(${JOB_HANDLE_WIDTH}px - 250px);
    //   height: auto;
    // }
`;
