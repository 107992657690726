import React, { useState, useEffect, useMemo } from 'react';
import { Popconfirm, Button, Space, Badge } from 'antd';
import { Wrapper } from '~commons/styles';
import { TableLayout } from '~components';
import Utils from '~commons/utils';
import ApiServices from '~services';

import { useSelector, useDispatch } from 'react-redux';
import { fetchOrderRevisedWMS } from '~store/reducers/order-ONP-WMS/action';

export const RvsWMS = ({ onClose }) => {
  const dispatch = useDispatch();
  const { orderRevisedWMS } = useSelector((state) => state.orderONPWMS);
  const [loading, setLoading] = useState(false);
  const [ordersFromWMS, setOrdersFromWMS] = useState([]);
  const [selectedKey, setSelectedKey] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);

  useEffect(() => {
    setOrdersFromWMS(orderRevisedWMS || []);
  }, [orderRevisedWMS]);

  const countSelected = useMemo(() => {
    return selectedKey?.length;
  }, [selectedKey]);

  const handleAcceptOrderFromWMS = async (dataRow) => {
    setLoading(true);
    return ApiServices.importOrdersFromWMSRVS(dataRow)
      .then((res) => {
        // TH 1: Tất cả đều import thất bại (data = null)
        if (!res?.data?.orders?.data) {
          window.TmsNoti?.error('Import thất bại');
          res?.data?.orders?.errors?.length > 0 &&
            window.TmsNoti?.error(
              'Lý do',
              res?.data?.orders?.errors?.map((item, index) => (
                <div key={index}>
                  <b className='red'>
                    {index + 1}
                    {')'}
                  </b>
                  {item}
                </div>
              )),
              20
            );
        }
        // TH2: import 10 item, thất bại 5, thành công 5
        if (res?.data?.orders?.data && res?.data?.orders?.errors?.length > 0) {
          window.TmsNoti?.error(res?.data?.orders?.data, res?.data?.orders?.message);
          window.TmsNoti?.error(
            'Lý do',
            res?.data?.orders?.errors?.map((item, index) => (
              <div key={index}>
                <b className='red'>
                  {index + 1}
                  {')'}
                </b>
                {item}
              </div>
            )),
            20
          );
          dispatch(fetchOrderRevisedWMS());
          onClose && onClose();
        }
        // TH3: tât cả thành công
        if (res?.data?.orders?.data === 'success') {
          window.TmsNoti?.success('Import thành công');
          dispatch(fetchOrderRevisedWMS());
          onClose && onClose();
        }
      })
      .catch((err) => console.log('Import Orders RVS from WMS err: ', err))
      .finally(() => {
        setLoading(false);
        setSelectedRowData([]);
        setSelectedKey([]);
      });
  };

  const renderCont = (_, record) => {
    return Utils.renderCont(record);
  };

  const renderContentRVS = (value) => {
    return Utils.convertToPlain(value);
  };

  const columns = [
    {
      title: 'Số Booking',
      dataIndex: 'so_Booking',
      width: 120,
      align: 'center',
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'ten_Khach_Hang',
      width: 180,
      align: 'center',
    },
    {
      title: 'Nội dung RVS',
      dataIndex: 'noi_Dung_Revive',
      width: 250,
      align: 'center',
      render: renderContentRVS,
    },
    {
      title: 'Ngày Booking',
      dataIndex: 'ngay_Booking',
      width: 120,
      align: 'center',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'Ngày cắt máng',
      dataIndex: 'ngay_Cat_Mang',
      width: 120,
      align: 'center',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'Ngày cắt SI',
      dataIndex: 'ngay_Cat_SI',
      width: 120,
      align: 'center',
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'Mã hãng tàu',
      dataIndex: 'ma_Hang_Tau',
      width: 150,
      align: 'center',
    },
    {
      title: 'Tên chuyến tàu',
      dataIndex: 'ten_Chuyen_Tau',
      width: 120,
      align: 'center',
    },
    {
      title: 'Số lượng cont: 20, 40C, 40T, 45',
      dataIndex: 'auto_ID',
      width: 125,
      align: 'center',
      render: renderCont,
    },
    {
      title: 'Action',
      fixed: 'right',
      align: 'center',
      width: 140,
      render: (record) => {
        return (
          <Popconfirm
            onConfirm={() => handleAcceptOrderFromWMS([record])}
            title='Xác nhận đơn hàng này?'
            okText='Đồng ý'
            cancelText='Không'
          >
            <Button type='primary' size='small' style={{ padding: '0px 20px' }}>
              Xác nhận
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const rowSelection = (selectKey, rowData) => {
    setSelectedKey(selectKey);
    setSelectedRowData(rowData);
  };

  const headerContent = (
    <Space>
      <Badge count={countSelected}>
        <Popconfirm
          onConfirm={() => handleAcceptOrderFromWMS(selectedRowData)}
          title={`Xác nhận ${countSelected} đơn hàng?`}
          okText='Đồng ý'
          cancelText='Không'
          disabled={countSelected < 1}
        >
          <Button
            type='primary'
            style={{ padding: '0px 20px', marginLeft: '10px' }}
            disabled={countSelected < 1}
          >
            Xác nhận
          </Button>
        </Popconfirm>
      </Badge>
    </Space>
  );
  const renderRowClassName = (record) => {
    if (record?.xe_Ngoai_ID === 0) {
      return '';
    } else {
      return 'order-from-ONP';
    }
  };
  return (
    <Wrapper>
      <TableLayout
        columns={columns}
        data={ordersFromWMS}
        rowKey='auto_ID'
        bordered
        loading={loading}
        selectParent={selectedKey}
        onSelectChange={rowSelection}
        headerContent={headerContent}
        headerLength='300px'
        rowClassName={renderRowClassName}
      />
    </Wrapper>
  );
};
