import React, { useState, useCallback, useEffect } from 'react';
import { TableLayout } from '~components';
import { message, Input, DatePicker } from 'antd';
import { TableTitle } from '~commons/styles';
import Utils from '~commons/utils';
import { debounce } from 'lodash';
import ApiServices from '~services';
import { DATE_FORMAT } from '~commons/constants';
import moment from 'moment';

const DEFAULT_QUERIES = {
  PageSize: 20,
  PageNumber: 1,
  // SortColumn: 'code',
  // SortColumnDirection: 'ASC',
};

const ViewAllConnectPartner = ({ tabKey }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [searchDate, setSearchDate] = useState(null);

  const getCompanies = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    try {
      let res = await ApiServices.getCompaniesPartner(newQuery);

      if (res?.data?.data) {
        const newData = res?.data?.data.map((item, index) => {
          item.key = `${item.id}-main-${index}`;
          item.stt = index + 1;
          return item;
        });
        setData(newData);
        setTotalRecord(res?.data?.totalRecords ?? 0);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getCompanies(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [tabKey, filter]);

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const handleChangeSearchDate = (date) => {
    setSearchDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'createdOn');
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Công ty
          <Input name='parentName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      width: 200,
      dataIndex: 'parentName',
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Mã số thuế
          <Input name='taxCodeParent' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      width: 130,
      dataIndex: 'taxCodeParent',
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Đối tác
          <Input name='childName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      width: 150,
      dataIndex: 'childName',
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          MST đối tác
          <Input name='taxCodeChild' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      width: 130,
      dataIndex: 'taxCodeChild',
      align: 'center',
    },

    {
      title: (
        <TableTitle>
          Ngày tạo
          <DatePicker
            value={searchDate}
            onChange={handleChangeSearchDate}
            placeholder='Search'
            style={{ width: '100%', fontSize: '10px' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      width: 150,
      dataIndex: 'createdOn',
      align: 'center',
      render: (val) => val && Utils.formatDateFn(val),
    },
  ];

  const pagination = {
    total: totalRecord || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <>
      <TableLayout
        key='key'
        columns={columns}
        data={data}
        loading={loading}
        closeDrawer={() => setSelected(null)}
        width={850}
        isDisplayCheckbox={false}
        pagination={pagination}
      />
    </>
  );
};

export { ViewAllConnectPartner };
