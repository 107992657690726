import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { Space, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import { RangeDateFilter } from '~components';
import { TableDetail } from './table-detail';
const ExcelJS = require('exceljs');

import {
  LIST_COLUMNS_TITLE_FIRST_DETAIL_SHEET,
  LIST_COLUMNS_TITLE_SECOND_DETAIL_SHEET,
  MERGE_TWO_ROWS_TITLE_DETAIL_SHEET,
  MERGE_TWO_COLUMNS_TITLE_DETAIL_SHEET,
  BORDER_CELL_TITLE_DETAIL_SHEET,
  COLUMNS_BORDER_LIST_DETAIL_SHEET,
  LIST_COLUMN_SUM_DETAIL_SHEET,
} from './sheet-detail-constants';

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('month').format(),
  SortColumn: 'inputDate',
  SortColumnDirection: 'ASC',
};

const OilCouponsOilTotal = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getOilTotals(newQuery)
      .then((res) => {
        if (res?.data?.data) {
          const dataFormat = res?.data?.data?.map((item, index) => {
            item.key = index + 1;
            item.unit = 'Lít';
            item.oilPriceFirst = item?.oilPrice * item?.firstAmount ?? 0;
            item.oilPriceIn = item?.oilPrice * item?.inputAmount ?? 0;
            item.oilPriceOut = item?.oilPrice * item?.outputAmount ?? 0;
            item.oilPriceLast = item?.oilPrice * item?.lastAmount ?? 0;

            return item;
          });

          setData(dataFormat ?? []);
        }
      })
      .catch((err) => {
        setData([]);
        console.error('get OilTotals Error:::', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const exportExcelFile = async (dataExport = []) => {
    if (loading) return;
    setLoading(true);
    const workbook = new ExcelJS.Workbook();

    //            XUAT NHAP TON
    // Ten sheet
    const sheetDetail = workbook.addWorksheet('Xuất Nhập Tồn', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ showGridLines: false }],
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });
    // chieu cao - chieu rong cot
    sheetDetail.properties.defaultRowHeight = 21;
    sheetDetail.properties.defaultColWidth = 15;

    const sheetDetailTitle = 'THEO DÕI XUẤT NHẬP TỒN';
    const dateStartToEnd = `Từ ${moment(filter?.DateFrom).format('DD/MM/YYYY')} Đến ${moment(
      filter?.DateTo
    ).format('DD/MM/YYYY')}`;

    sheetDetail.addRow(['']); // blank Row
    sheetDetail.addRow(['']); // blank Row
    sheetDetail.addRow(['']); // blank Row
    sheetDetail.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetDetail.getCell('G2').value = sheetDetailTitle;
    sheetDetail.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetDetail.getCell('G3').value = dateStartToEnd;
    sheetDetail.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetDetail.addRow(['']); // blank Row
    // add Title

    sheetDetail.addRow(LIST_COLUMNS_TITLE_FIRST_DETAIL_SHEET);
    sheetDetail.addRow(LIST_COLUMNS_TITLE_SECOND_DETAIL_SHEET);

    MERGE_TWO_ROWS_TITLE_DETAIL_SHEET.forEach((item) => {
      sheetDetail.mergeCells(`${item}5:${item}6`);
      sheetDetail.getCell(`${item}5`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetDetail.getCell(`${item}5`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });

    MERGE_TWO_COLUMNS_TITLE_DETAIL_SHEET.forEach((item) => {
      sheetDetail.mergeCells(`${item.start}5:${item.end}5`);
      sheetDetail.getCell(`${item.start}5`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetDetail.getCell(`${item.start}5`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });

    BORDER_CELL_TITLE_DETAIL_SHEET.forEach((item) => {
      sheetDetail.getCell(`${item}6`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetDetail.getCell(`${item}6`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });
    // ================ Summary Body ====================
    await dataExport?.map((item, indexRow) => {
      sheetDetail.addRow([
        '',
        indexRow + 1, // STT
        item?.inputDate ? new Date(moment(item?.inputDate).format('YYYY-MM-DD')) : '', // Ngay Do
        item?.unit ?? '', // unit
        item?.oilPrice ?? 0, // oilPrice Gia Dau
        // Du dau ky
        item?.firstAmount ?? 0, // San Luong
        item?.oilPriceFirst ?? 0, // Tri Gia
        // Nhap
        item?.inputAmount ?? 0, // San Luong
        item?.oilPriceIn ?? 0, // Tri Gia
        // Xuat
        item?.outputAmount ?? 0, // San Luong
        item?.oilPriceOut ?? 0, // Tri Gia
        // Ton Cuoi Ky
        item?.lastAmount ?? 0, // San Luong
        item?.oilPriceLast ?? 0, // Tri Gia
      ]);

      COLUMNS_BORDER_LIST_DETAIL_SHEET.forEach((element) => {
        sheetDetail.getCell(`${element}${indexRow + 7}`).border = {
          top: { style: 'thin', color: { argb: '006699' } },
          left: { style: 'thin', color: { argb: '006699' } },
          right: { style: 'thin', color: { argb: '006699' } },
          bottom: { style: 'thin', color: { argb: '006699' } },
        };

        if (item?.outputAmount > 0 || item?.oilPriceOut > 0) {
          sheetDetail.getCell(`${element}${indexRow + 7}`).fill = {
            type: 'gradient',
            gradient: 'angle',
            stops: [
              { position: 0, color: { argb: 'BBBBBB' } },
              { position: 1, color: { argb: 'BBBBBB' } },
            ],
          };
        }
      });
    });
    // SUm
    const dataLengthDetailSheet = data?.length;
    const startPositionDetailSheet = 7;
    const endPositionDetailSheet = dataLengthDetailSheet + 6;
    const showResultDetailSheet = dataLengthDetailSheet + 7;

    for (
      let i = startPositionDetailSheet;
      i <= dataLengthDetailSheet + startPositionDetailSheet;
      i++
    ) {
      sheetDetail.getRow(i).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      };
    }

    if (dataLengthDetailSheet > 0) {
      LIST_COLUMN_SUM_DETAIL_SHEET.forEach((column) => {
        sheetDetail.getCell(`${column + showResultDetailSheet}`).value = {
          formula: `=SUM(${column + startPositionDetailSheet}:${column + endPositionDetailSheet})`,
        };
      });

      COLUMNS_BORDER_LIST_DETAIL_SHEET.forEach((item) => {
        sheetDetail.getCell(`${item + showResultDetailSheet}`).border = {
          top: { style: 'thin', color: { argb: '006699' } },
          left: { style: 'thin', color: { argb: '006699' } },
          right: { style: 'thin', color: { argb: '006699' } },
          bottom: { style: 'thin', color: { argb: '006699' } },
        };
        sheetDetail.getCell(`${item + showResultDetailSheet}`).fill = {
          type: 'gradient',
          gradient: 'angle',
          stops: [
            { position: 0, color: { argb: '96B6C5' } },
            { position: 1, color: { argb: '96B6C5' } },
          ],
        };
      });
      sheetDetail.getRow(showResultDetailSheet).font = {
        name: 'Arial',
        family: 4,
        size: 10,
        bold: true,
      };
    }

    // ================= Style ==========================
    sheetDetail.getColumn(1).width = 3;
    sheetDetail.getColumn(2).width = 4;
    sheetDetail.getColumn(3).width = 10;
    sheetDetail.getColumn(4).width = 13;

    const alignCenterRowsDetailSheet = [5, 6, showResultDetailSheet + 2, showResultDetailSheet + 3];
    alignCenterRowsDetailSheet.forEach((row) => {
      sheetDetail.getRow(row).alignment = { vertical: 'middle', horizontal: 'center' };
    });
    sheetDetail.getRow(5).font = {
      name: 'Arial',
      family: 2,
      size: 10,
      bold: true,
    };
    sheetDetail.getRow(6).font = {
      name: 'Arial',
      family: 2,
      size: 10,
      bold: true,
    };
    for (let i = 5; i <= 13; i++) {
      sheetDetail.getColumn(i).numFmt = '_(* #,##0_);_(* -#,##0_);_(* "-"??_)';
      sheetDetail.getColumn(i).width = 18;
    }
    for (let i = 7; i <= 3000; i++) {
      sheetDetail.getRow(i).height = 21;
    }

    // ================= Style ==========================
    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Theo_Doi_Xuat_Nhap_Ton_Tu_${moment(filter?.DateFrom).format(
          'DD-MM-YYYY'
        )}_Den_${moment(filter?.DateTo).format('DD-MM-YYYY')}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h1>THEO DÕI XUÂT NHẬP TỒN</h1>

      <div
        style={{
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          padding: '20px 10px',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      >
        <Space>
          <div>
            <b
              style={{
                marginLeft: '10px',
              }}
            >
              Chọn ngày:
            </b>
            <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
          </div>

          <div>
            <br />
            <Button
              type='primary'
              loading={loading}
              icon={<DownloadOutlined />}
              style={{ padding: '0px 20px', marginLeft: '10px' }}
              disabled={!data}
              onClick={() => exportExcelFile(data)}
            >
              Export Excel
            </Button>
          </div>
        </Space>
      </div>
      <TableDetail dataTable={data || []} loading={loading} />
    </>
  );
};

export { OilCouponsOilTotal };
