import Utils from '~commons/utils';

export const KEYS = ['id', 'note'];
export const KEYS_OF_NUMBER = ['inputAmount', 'oilPrice'];
export const KEYS_OF_DATE = ['inputDate'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });

  KEYS_OF_NUMBER.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] || 1,
    });
  });
  KEYS_OF_DATE.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? Utils.convertToDate(data[item]) : null,
    });
  });
  return result;
};
