import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { REPORT_COLORS as COLORS } from '~commons/constants';
import { Selection } from '~components';
import ApiServices from '~services';

export const ReportFuelByVendor = () => {
  const [vendors, setVendors] = useState([]);
  const [arrIdSelectVendor, setArrIdSelectVendor] = useState([]);
  const [reportFuel, setReportFuel] = useState([]);

  const getVendors = async () => {
    return ApiServices.getVendors()
      .then((res) => {
        if (res?.data?.data) {
          const defaultVendorSelected = res.data?.data?.map((item) => item.id);
          setArrIdSelectVendor(defaultVendorSelected);
          setVendors(res.data?.data || []);
        }
      })
      .catch((err) => {
        console.log('Get vendor error: ', err);
      });
  };

  useEffect(() => {
    getVendors();
  }, []);

  const getReportFuel = async (vendorId) => {
    try {
      const data = await ApiServices.reportFuelByVendor(vendorId);
      if (data?.data) {
        setReportFuel(data?.data);
        s;
      }
    } catch (error) {
      console.log('getReportFuel error:', error);
    }
  };
  useEffect(() => {
    if (arrIdSelectVendor?.length > 0) {
      return getReportFuel(arrIdSelectVendor);
    }
  }, [arrIdSelectVendor]);

  const onChangeVender = async (value) => {
    let dataSelect = value;
    if (!value || value?.length < 1) {
      const defaultVendorIds = vendors?.map((item) => item.id);
      dataSelect = defaultVendorIds;
    }
    setArrIdSelectVendor(dataSelect);
  };

  return (
    <div>
      <h3>Đội xe</h3>
      <Selection
        mode='multiple'
        data={vendors}
        onSearch={false}
        style={{ width: 300, marginBottom: '10px' }}
        onChange={onChangeVender}
        value={arrIdSelectVendor}
      />
      <div className='text-align-center'>
        <h3>THỐNG KÊ NHIÊN LIỆU THEO ĐỘI XE</h3>
        <BarChart
          width={800}
          height={500}
          data={reportFuel}
          margin={{
            top: 5,
            right: 30,
            left: 10,
            bottom: -10,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='VendorName' />
          <YAxis />
          {/* <Tooltip content={<CustomTooltip />} /> */}
          <Tooltip />
          <Legend />
          <Bar dataKey='TotalOil' barSize={20} fill={COLORS[0]} />
          <Bar dataKey='TotalKm' barSize={20} fill={COLORS[1]} />
        </BarChart>
      </div>
    </div>
  );
};
