import { Button, Input, Select, Tooltip } from 'antd';
import Utils from '~commons/utils';
import { CONT_OPTIONS_STATUSES } from '~commons/constants';
import { SyncOutlined, CheckCircleOutlined, EyeOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ContStatus } from '~components/common-status';
const { Option } = Select;
const SuccessButton = styled(Button)`
  padding: 0 8px;
  &.ant-btn-link:not([disabled]) {
    color: green;
  }
`;
export const getColumns = (
  setQuery,
  onClickUpdate,
  deleteRow,
  isNotValidToComplete,
  onComplete,
  showListFile
) => [
  {
    title: '#',
    width: '110px',
    fixed: 'left',
    dataIndex: 'action',
    render: (_, record) => (
      <>
        {record?.partnerName || record?.status === 9 ? (
          <Button
            shape='round'
            type='link'
            onClick={() => onClickUpdate({ ...record, onlyView: true })}
            style={{ padding: '0 2px', color: 'orange' }}
          >
            <Tooltip title='Xem Chi tiết' placement='topLeft' color='pink'>
              <EyeOutlined
                style={{
                  fontSize: '15px',
                }}
              />{' '}
              Xem Chi tiết
            </Tooltip>
          </Button>
        ) : (
          <>
            <Button
              shape='round'
              type='link'
              style={{ padding: '0px 8px 5px 8px' }}
              onClick={() => onClickUpdate(record)}
              disabled={record?.status !== 0 && record?.status !== 2 && record?.status !== 9}
            >
              <Tooltip title='Xử lý' placement='topLeft' color='pink'>
                <SyncOutlined
                  style={{
                    fontSize: '15px',
                  }}
                />{' '}
                Xử lý
              </Tooltip>
            </Button>
            <hr />
            <SuccessButton
              style={{ '&:not([disabled])': { color: 'green' }, padding: '8px 2px 1px 2px' }}
              shape='round'
              type='link'
              onClick={() => onComplete(record)}
              disabled={
                (record?.status !== 0 && record?.status !== 2) || isNotValidToComplete(record)
              }
            >
              <Tooltip title='Hoàn thành' placement='topRight' color='pink'>
                <CheckCircleOutlined
                  style={{
                    fontSize: '15px',
                  }}
                  twoToneColor='#52c41a'
                />{' '}
                Hoàn thành
              </Tooltip>
            </SuccessButton>
          </>
        )}
      </>
    ),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Trạng thái</div>
        <Select
          // mode="multiple"
          placeholder='Search'
          defaultValue={''}
          style={{ width: 100, fontSize: '11px' }}
          onChange={(status) => setQuery({ status })}
        >
          {CONT_OPTIONS_STATUSES.map((item) => (
            <Option key={item.label + item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </span>
    ),
    width: '120px',
    textAlign: 'center',
    align: 'center',
    dataIndex: 'status',
    render: (value, record) => (
      <>
        <ContStatus status={value} />
        {record.partnerName && (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            ({record.partnerName})
          </div>
        )}
      </>
    ),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Khách hàng</div>
      </span>
    ),
    sorter: true,
    dataIndex: 'customerName',
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    children: [
      {
        title: (
          <span style={{ textAlign: 'center' }}>
            <Input
              placeholder='Search'
              allowClear
              onChange={(event) => setQuery({ customerName: event.target.value })}
            />
          </span>
        ),
        width: '150px',
        align: 'center',
        dataIndex: 'customerName',
      },
    ],
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Hãng tàu</div>
      </span>
    ),
    sorter: true,
    dataIndex: 'shippingLine',
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    children: [
      {
        title: (
          <span style={{ textAlign: 'center' }}>
            <Input
              placeholder='Search'
              allowClear
              onChange={(event) => setQuery({ shippingLine: event.target.value })}
            />
          </span>
        ),
        width: '130px',
        dataIndex: 'shippingLine',
        align: 'center',
      },
    ],
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>SubNo</div>
      </span>
    ),
    sorter: true,
    dataIndex: 'subnoNumber',
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    children: [
      {
        title: (
          <span style={{ textAlign: 'center' }}>
            <Input
              placeholder='Search'
              allowClear
              onChange={(event) => setQuery({ subnoNumber: event.target.value })}
            />
          </span>
        ),
        width: '200px',
        dataIndex: 'subnoNumber',
        render: (value, record) => (
          <>
            {value}
            <SuccessButton type='link' onClick={() => showListFile(record)}>
              File Đk
            </SuccessButton>
          </>
        ),
      },
    ],
  },
  // {
  //   title: (
  //     <span style={{ textAlign: 'center' }}>
  //       <div>SubNo</div>
  //       <Input
  //         placeholder='Search'
  //         allowClear
  //         onChange={(event) => setQuery({ subnoNumber: event.target.value })}
  //       />
  //     </span>
  //   ),
  //   width: '200px',
  //   dataIndex: 'subnoNumber',
  //   render: (value, record) => (
  //     <>
  //       {value}
  //       <SuccessButton type='link' onClick={() => showListFile(record)}>
  //         File Đk
  //       </SuccessButton>
  //     </>
  //   ),
  // },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ngày nhận D/O</div>
      </span>
    ),
    width: '90px',
    dataIndex: 'createdOn',
    sorter: true,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (value, record) => Utils.formatDateHourFn(value, record),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Số cont</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(event) => setQuery({ contNumber: event.target.value })}
        />
      </span>
    ),
    width: '120px',
    dataIndex: 'contNumber',
    align: 'center',
  },
  {
    //
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Số seal</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(event) => setQuery({ sealNumber: event.target.value })}
        />
      </span>
    ),
    width: '100px',
    dataIndex: 'sealNumber',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Cảng lấy hàng</div>
      </span>
    ),
    width: '150px',
    align: 'center',
    dataIndex: 'lh',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ngày Thực tế LH</div>
      </span>
    ),
    width: '100px',
    dataIndex: 'pickupAcctualDate',
    render: (value) => Utils.formatDate(value),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ngày Thực tế GH</div>
      </span>
    ),
    width: '100px',
    dataIndex: 'deliveryAcctualDate',
    render: (value) => Utils.formatDate(value),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ngày Thực tế TR</div>
      </span>
    ),
    width: '100px',
    dataIndex: 'bill_AcctualDateDET',
    render: (value) => Utils.formatDate(value),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Điểm Trả Rỗng</div>
      </span>
    ),
    width: '130px',
    dataIndex: 'bill_LocationDepotReturnName',
    align: 'center',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>HHLC</div>
      </span>
    ),
    width: '100px',
    dataIndex: 'bill_LastFreeDayDEM',
    sorter: true,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (value, record) => Utils.renderWarningDate(value, record),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>HHTR</div>
      </span>
    ),
    width: '100px',
    dataIndex: 'bill_LastFreeDayDET',
    sorter: true,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (value) => Utils.formatDate(value),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ngày YCLC</div>
      </span>
    ),
    width: '100px',
    align: 'center',
    dataIndex: 'bill_LiftingLadenContRequestDate',
    sorter: true,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (value) => Utils.formatDate(value),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Giao hàng</div>
      </span>
    ),
    width: '150px',
    align: 'center',
    dataIndex: 'gh',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Nguồn</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(event) => setQuery({ source: event.target.value })}
        />
      </span>
    ),
    width: '80px',
    align: 'center',
    dataIndex: 'source',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ghi chú khách hàng</div>
      </span>
    ),
    width: '200px',
    dataIndex: 'note',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ghi chú xe ngoài</div>
      </span>
    ),
    width: '200px',
    dataIndex: 'notePlan',
  },
  // {
  //   title: '#',
  //   width: '80px',
  //   fixed: 'right',
  //   dataIndex: 'delete',
  //   render: (value, record) => (
  //     <Popconfirm
  //       onConfirm={() => deleteRow(record)}
  //       title="Xác nhận Hủy？"
  //       okText="Yes"
  //       cancelText="No"
  //       disabled={record?.status !== 0}
  //     >
  //       <Button shape="round" type="link" danger disabled={record?.status !== 0}>
  //         Hủy
  //       </Button>
  //     </Popconfirm>
  //   ),
  // },
];
