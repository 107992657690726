import React from 'react';
import { Input, Row, Col } from 'antd';
import styled from 'styled-components';

export const CreateDistanceLocation = ({ isNotDistanceList, setIsNotDistanceList }) => {
  const onChangeChange = (value, index, type) => {
    if (isNaN(value)) return;
    setIsNotDistanceList((prev) => {
      prev[index][type] = +value;
      return [...prev];
    });
  };

  const renderInputs = (item, index) => {
    return (
      <Row
        gutter={24}
        key={`${index.begin}${item.end}`}
        align='middle'
        style={{ marginTop: '8px' }}
      >
        <Col span={7}>{item?.beginName}</Col>
        <Col span={1}>{'>'}</Col>
        <Col span={7}>{item?.endName}</Col>
        <Col span={4}>
          <InputStyled
            defaultValue={0}
            min={0}
            isError={!item?.distanceKm}
            className='red'
            size='small'
            value={item?.distanceKm}
            onChange={({ target }) => onChangeChange(target.value, index, 'distanceKm')}
            placeholder='Cự ly'
          />
        </Col>
        <Col span={4}>
          <InputStyled
            defaultValue={0}
            className='red'
            size='small'
            value={item?.loss}
            min={0}
            onChange={({ target }) => onChangeChange(target.value, index, 'loss')}
            placeholder='Hao hụt'
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row gutter={24}>
        <Col span={7}>
          <b>Điểm bắt đầu</b>
        </Col>
        <Col span={1}></Col>
        <Col span={7}>
          <b>Điểm kết thúc</b>
        </Col>
        <Col span={4}>
          <b>Cự ly(Km)</b>
        </Col>
        <Col span={4}>
          <b>Hao hụt(Km)</b>
        </Col>
      </Row>
      {isNotDistanceList?.map((item, index) => renderInputs(item, index))}
    </>
  );
};
const InputStyled = styled(Input)`
  border-color: #00bfff;
  border-radius: 5px;
  border: ${({ isError }) => (isError ? '1px solid red' : '1px solid #00BFFF')};
  padding: 4px;
`;
