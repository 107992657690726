import { Table } from 'antd';
import Utils from '~commons/utils';

const Tickets = ({ ticketList }) => {
  const columns = [
    {
      title: 'Số phiếu',
      dataIndex: 'aid',
      width: 100,
      align: 'center',
    },
    {
      title: 'Folder',
      dataIndex: 'folder',
      width: 100,
      align: 'center',
    },
    {
      title: 'SubNo',
      dataIndex: 'subnoNumber',
      width: 150,
      align: 'center',
    },
    {
      title: 'Khách hàng',
      dataIndex: 'customerName',
      width: 150,
      align: 'center',
    },
    {
      title: 'Ngày giải trừ',
      dataIndex: 'dissolveDate',
      width: 120,
      align: 'center',
      render: (val) => Utils.formatDate(val),
    },
    {
      title: 'Số tiền',
      dataIndex: 'total',
      width: 100,
      align: 'center',
    },
    {
      title: 'Người giải trừ',
      dataIndex: 'createdBy',
      width: 130,
      align: 'center',
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      width: 100,
      align: 'center',
    },
  ];

  return (
    <div
      className='table-container-custom'
      style={{
        maxHeight: 'calc(100vh - 510px)',
        overflow: 'auto',
      }}
    >
      <Table
        bordered
        size='small'
        columns={columns}
        dataSource={ticketList}
        rowKey='id'
        // scroll={{
        //   x: '100%',
        //   y: 'calc(100vh - 300px)',
        // }}
        pagination={false}
      />
    </div>
  );
};
export { Tickets };
