import { Button, Input } from 'antd';

export const getColumns = (setQuery, setDataListFile, returnEmptyCont) => [
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Xe Thực hiện</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(event) => setQuery({ carCode: event.target.value })}
        />
      </span>
    ),
    width: '15%',
    dataIndex: 'carCode',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Romooc</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(e) => setQuery({ romoocCode: e.target.value })}
        />
      </span>
    ),
    width: '15%',
    dataIndex: 'romoocCode',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Số Cont</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(e) => setQuery({ contNumber: e.target.value })}
        />
      </span>
    ),
    width: '20%',
    dataIndex: 'contNumber',
  },

  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Vị trí</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(e) => setQuery({ locationName: e.target.value })}
        />
      </span>
    ),
    width: '20%',
    dataIndex: 'locationName',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ghi chú</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(e) => setQuery({ note: e.target.value })}
        />
      </span>
    ),
    width: '30%',
    dataIndex: 'note',
  },
  {
    title: <div>#</div>,
    width: '10%',
    dataIndex: 'ACTION',
    render: (_, record) => (
      <Button type='link' onClick={() => returnEmptyCont(record)}>
        Trả Rỗng
      </Button>
    ),
  },
];
