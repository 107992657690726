import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Popconfirm, Button, Space, Badge, Input, DatePicker } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import { Wrapper } from '~commons/styles';
import { TableLayout } from '~components';
import Utils from '~commons/utils';
import ApiServices from '~services';
import { TableTitle } from '~commons/styles';
import { DATE_FORMAT } from '~commons/constants';

import { useSelector, useDispatch } from 'react-redux';
import { fetchOrderWaitingReceiveONP } from '~store/reducers/order-ONP-WMS/action';

export const ReceiveONP = ({ onClose }) => {
  const dispatch = useDispatch();
  const { orderWaitingReceiveONP } = useSelector((state) => state.orderONPWMS);

  const [loading, setLoading] = useState(false);
  const [originData, setOriginData] = useState([]);
  const [ordersFromONP, setOrdersFromONP] = useState([]);
  const [selectedKey, setSelectedKey] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [filter, setFilter] = useState(null);
  const [bookingDate, setBookingDate] = useState(null);
  const [cutOffTimeDate, setCutOffTimeDate] = useState(null);
  const [cutSIDate, setCutSIDate] = useState(null);

  useEffect(() => {
    setOriginData(orderWaitingReceiveONP || []);
    if (filter) {
      handleFilterData(filter, orderWaitingReceiveONP);
    } else {
      setOrdersFromONP(orderWaitingReceiveONP || []);
    }
  }, [orderWaitingReceiveONP]);

  const countSelected = useMemo(() => {
    return selectedKey?.length;
  }, [selectedKey]);

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;
      if (query?.ten_Khach_Hang) {
        if (
          !Utils.convertValSearch(item?.ten_Khach_Hang)?.includes(
            Utils.convertValSearch(query?.ten_Khach_Hang)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.so_Booking) {
        if (
          !Utils.convertValSearch(item?.so_Booking)?.includes(
            Utils.convertValSearch(query?.so_Booking)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.ma_Hang_Tau) {
        if (
          !Utils.convertValSearch(item?.ma_Hang_Tau)?.includes(
            Utils.convertValSearch(query?.ma_Hang_Tau)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.ten_Chuyen_Tau) {
        if (
          !Utils.convertValSearch(item?.ten_Chuyen_Tau)?.includes(
            Utils.convertValSearch(query?.ten_Chuyen_Tau)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.ngay_Booking) {
        if (moment(item?.ngay_Booking).format('YYYY-MM-DD') !== query?.ngay_Booking) {
          checkFilter = false;
        }
      }
      if (query?.ngay_Cat_Mang) {
        if (moment(item?.ngay_Cat_Mang).format('YYYY-MM-DD') !== query?.ngay_Cat_Mang) {
          checkFilter = false;
        }
      }
      if (query?.ngay_Cat_SI) {
        if (moment(item?.ngay_Cat_SI).format('YYYY-MM-DD') !== query?.ngay_Cat_SI) {
          checkFilter = false;
        }
      }

      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    setOrdersFromONP(dataFilter);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    []
  );
  useEffect(() => {
    if (originData?.length) {
      timeoutGetData(filter, originData);
    }
  }, [filter]);

  const handleAcceptOrderFromONP = async (dataRow) => {
    setLoading(true);
    return ApiServices.importOrdersFromONP(dataRow)
      .then((res) => {
        // TH 1: Tất cả đều import thất bại (data = null)
        if (!res?.data?.orders?.data) {
          window.TmsNoti?.error('Import thất bại');
          res?.data?.orders?.errors?.length > 0 &&
            window.TmsNoti?.error(
              'Lý do',
              res?.data?.orders?.errors?.map((item, index) => (
                <div key={index}>
                  <b className='red'>
                    {index + 1}
                    {')'}
                  </b>
                  {item}
                </div>
              )),
              20
            );
        }
        // TH2: import 10 item, thất bại 5, thành công 5
        if (res?.data?.orders?.data && res?.data?.orders?.errors?.length > 0) {
          window.TmsNoti?.error(res?.data?.orders?.data, res?.data?.orders?.message);
          window.TmsNoti?.error(
            'Lý do',
            res?.data?.orders?.errors?.map((item, index) => (
              <div key={index}>
                <b className='red'>
                  {index + 1}
                  {')'}
                </b>
                {item}
              </div>
            )),
            20
          );
          dispatch(fetchOrderWaitingReceiveONP());
          onClose && onClose();
        }
        // TH3: tât cả thành công
        if (res?.data?.orders?.data === 'success') {
          window.TmsNoti?.success('Import thành công');
          dispatch(fetchOrderWaitingReceiveONP());
          onClose && onClose();
        }
      })
      .catch((err) => console.log('Import Orders waiting receive from ONP err: ', err))
      .finally(() => {
        setLoading(false);
        setSelectedRowData([]);
        setSelectedKey([]);
      });
  };

  const onColumnSearch = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilter((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleChangeBookingDate = (date) => {
    setBookingDate(date);
    const dateSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    setFilter((prev) => {
      return { ...prev, ngay_Booking: dateSearch };
    });
  };

  const handleChangeCutOffTimeDate = (date) => {
    setCutOffTimeDate(date);
    const dateSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    setFilter((prev) => {
      return { ...prev, ngay_Cat_Mang: dateSearch };
    });
  };
  const handleChangeCutSIDate = (date) => {
    setCutSIDate(date);
    const dateSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    setFilter((prev) => {
      return { ...prev, ngay_Cat_SI: dateSearch };
    });
  };

  const renderCont = (_, record) => {
    return Utils.renderCont(record);
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Số Booking
          <Input name='so_Booking' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'so_Booking',
      width: 120,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Ngày Booking
          <DatePicker
            value={bookingDate}
            onChange={handleChangeBookingDate}
            placeholder='Ngày Booking'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'ngay_Booking',
      width: 150,
      align: 'center',
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          Tên khách hàng
          <Input name='ten_Khach_Hang' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'ten_Khach_Hang',
      width: 180,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Ngày cắt máng
          <DatePicker
            value={cutOffTimeDate}
            onChange={handleChangeCutOffTimeDate}
            placeholder='Ngày cắt máng'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'ngay_Cat_Mang',
      width: 150,
      align: 'center',
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          Ngày cắt SI
          <DatePicker
            value={cutSIDate}
            onChange={handleChangeCutSIDate}
            placeholder='Ngày cắt SI'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'ngay_Cat_SI',
      width: 150,
      align: 'center',
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          Mã hãng tàu
          <Input name='ma_Hang_Tau' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'ma_Hang_Tau',
      width: 150,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Tên chuyến tàu
          <Input name='ten_Chuyen_Tau' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'ten_Chuyen_Tau',
      width: 120,
      align: 'center',
    },
    {
      title: 'Số lượng cont: 20, 40C, 40T, 45',
      dataIndex: 'Auto_ID',
      width: 125,
      align: 'center',
      render: renderCont,
    },
    {
      title: 'Action',
      fixed: 'right',
      align: 'center',
      width: 140,
      render: (record) => {
        return (
          <Popconfirm
            onConfirm={() => handleAcceptOrderFromONP([record])}
            title='Xác nhận đơn hàng này?'
            okText='Đồng ý'
            cancelText='Không'
          >
            <Button type='primary' size='small' style={{ padding: '0px 20px' }}>
              {record?.xe_Ngoai_ID === 0 ? 'Xác nhận' : 'Điều ủy quyền'}
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const rowSelection = (selectKey, rowData) => {
    setSelectedKey(selectKey);
    setSelectedRowData(rowData);
  };

  const headerContent = (
    <Space>
      <Badge count={countSelected}>
        <Popconfirm
          onConfirm={() => handleAcceptOrderFromONP(selectedRowData)}
          title={`Xác nhận cấp ${countSelected} đơn hàng?`}
          okText='Đồng ý'
          cancelText='Không'
          disabled={countSelected < 1}
        >
          <Button
            type='primary'
            style={{ padding: '0px 20px', marginLeft: '10px' }}
            disabled={countSelected < 1}
          >
            Xác nhận
          </Button>
        </Popconfirm>
      </Badge>
    </Space>
  );

  const renderRowClassName = (record) => {
    if (record?.xe_Ngoai_ID === 0) {
      return '';
    } else {
      return 'order-from-ONP';
    }
  };

  return (
    <Wrapper>
      <TableLayout
        columns={columns}
        data={ordersFromONP}
        rowKey='auto_ID'
        bordered
        loading={loading}
        selectParent={selectedKey}
        onSelectChange={rowSelection}
        headerContent={headerContent}
        headerLength='300px'
        rowClassName={renderRowClassName}
      />
    </Wrapper>
  );
};
