import React from 'react';
import { Table, Typography } from 'antd';
const { Column, ColumnGroup } = Table;
import Utils from '~commons/utils';

const { Text } = Typography;

export const TableDetail = ({ dataTable = [], loading }) => {
  const handleSummary = (pageData) => {
    let sumFirstAmount = 0;
    let sumOilPriceFirst = 0;
    let sumInputAmount = 0;
    let sumOilPriceIn = 0;
    let sumOutputAmount = 0;
    let sumOilPriceOut = 0;
    let sumLastAmount = 0;
    let sumOilPriceLast = 0;

    pageData.forEach((page) => {
      sumFirstAmount += page?.firstAmount;
      sumOilPriceFirst += page?.oilPriceFirst;

      sumInputAmount += page?.inputAmount;
      sumOilPriceIn += page?.oilPriceIn;

      sumOutputAmount += page?.outputAmount;
      sumOilPriceOut += page?.oilPriceOut;

      sumLastAmount += page?.lastAmount;
      sumOilPriceLast += page?.oilPriceLast;
    });
    return (
      <>
        <Table.Summary.Row
          style={{
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '13px',
          }}
        >
          <Table.Summary.Cell index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            <Text type='danger' strong></Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            <Text type='danger' strong></Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            <Text type='danger' strong>
              {sumFirstAmount && Utils.formatNumber(sumFirstAmount)}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}>
            <Text type='danger' strong>
              {sumOilPriceFirst && Utils.formatNumber(sumOilPriceFirst)}
            </Text>
          </Table.Summary.Cell>

          <Table.Summary.Cell index={7}>
            <Text type='danger' strong>
              {sumInputAmount && Utils.formatNumber(sumInputAmount)}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8}>
            <Text type='danger' strong>
              {sumOilPriceIn && Utils.formatNumber(sumOilPriceIn)}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={9}>
            <Text type='danger' strong>
              {sumOutputAmount && Utils.formatNumber(sumOutputAmount)}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            <Text type='danger' strong>
              {sumOilPriceOut && Utils.formatNumber(sumOilPriceOut)}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            <Text type='danger' strong>
              {sumLastAmount && Utils.formatNumber(sumLastAmount)}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}>
            <Text type='danger' strong>
              {sumOilPriceLast && Utils.formatNumber(sumOilPriceLast)}
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const renderRowClassName = (record) => {
    if (record?.outputAmount > 0) {
      return 'task-job-daily-background';
    }
    return '';
  };
  return (
    <Table
      dataSource={dataTable}
      bordered
      size='small'
      summary={handleSummary}
      loading={loading}
      rowClassName={renderRowClassName}
    >
      <Column title='STT' dataIndex='key' key='key' align='center' />
      <Column
        title='Ngày Đổ'
        dataIndex='inputDate'
        key='inputDate'
        align='center'
        render={(val) => Utils.formatDateFn(val)}
      />
      <Column title='Đơn Vị Tính' dataIndex='unit' key='unit' align='center' />
      <Column
        title='Giá Dầu'
        dataIndex='oilPrice'
        key='oilPrice'
        align='center'
        render={(val) => (val ? Utils.formatNumber(val) : '-')}
      />
      <ColumnGroup title='Dư Đầu Kỳ'>
        <Column
          title='Số Lượng'
          dataIndex='firstAmount'
          key='firstAmount'
          align='center'
          render={(val) => (val ? Utils.formatNumber(val) : '-')}
        />
        <Column
          title='Trị Giá'
          dataIndex='oilPriceFirst'
          key='oilPriceFirst'
          align='center'
          render={(val) => (val ? Utils.formatNumber(val) : '-')}
        />
      </ColumnGroup>
      <ColumnGroup title='Nhập'>
        <Column
          title='Số Lượng'
          dataIndex='inputAmount'
          key='inputAmount'
          align='center'
          render={(val) => (val ? Utils.formatNumber(val) : '-')}
        />
        <Column
          title='Trị Giá'
          dataIndex='oilPriceIn'
          key='oilPriceIn'
          align='center'
          render={(val) => (val ? Utils.formatNumber(val) : '-')}
        />
      </ColumnGroup>
      <ColumnGroup title='Xuất'>
        <Column
          title='Số Lượng'
          dataIndex='outputAmount'
          key='outputAmount'
          align='center'
          render={(val) => (val ? Utils.formatNumber(val) : '-')}
        />
        <Column
          title='Trị Giá'
          dataIndex='oilPriceOut'
          key='oilPriceOut'
          align='center'
          render={(val) => (val ? Utils.formatNumber(val) : '-')}
        />
      </ColumnGroup>

      <ColumnGroup title='Tồn Cuối Kỳ'>
        <Column
          title='Số Lượng'
          dataIndex='lastAmount'
          key='lastAmount'
          align='center'
          render={(val) => (val ? Utils.formatNumber(val) : '-')}
        />
        <Column
          title='Trị Giá'
          dataIndex='oilPriceLast'
          key='oilPriceLast'
          align='center'
          render={(val) => (val ? Utils.formatNumber(val) : '-')}
        />
      </ColumnGroup>
    </Table>
  );
};
