import React from 'react';
import { Modal, message } from 'antd';
import ApiServices from '~services';
import storage from '~storage';

import FieldData from './fields-data';
import formatData from './format-data';

import { Transit } from '../../create-transit';
import { Booking } from '../../create-booking';
import { Bill } from '../../create-bill';

export const CreateOrderByCopy = ({ orderId = null, onClose, reload }) => {
  const [loading, setLoading] = React.useState(false);
  const initValue = FieldData({
    transportType: storage?.staticVariables?.TransportType?.TransportType_FCL,
  });
  const [orderDetail, setOrderDetail] = React.useState(null);
  const [fields, setFields] = React.useState(initValue);
  const isOpenModal = Boolean(orderId);
  const getOrderById = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const { data } = await ApiServices.getOrderDetailsId(orderId);
      if (data) {
        const dataFormat = { ...data };
        dataFormat.code = '';
        dataFormat.orderFiles = [];
        if (data?.orderType === storage?.staticVariables?.OrderType?.Order_Transit) {
          dataFormat.customerCode = '';
          dataFormat.customerName = '';
          dataFormat.transitDate = null;
        }
        setFields(formatData(dataFormat));
        setOrderDetail(data);
      }
    } catch (error) {
      console.log(error);
      message.warn('Đơn Hàng không tồn tại vui lòng thử lại');
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    orderId && getOrderById();
  }, [orderId]);

  const renderForm = () => {
    switch (orderDetail?.orderType) {
      case storage?.staticVariables?.OrderType?.Order_Bill:
        return (
          <Bill
            dataOrderCopy={fields}
            dataOrderDetail={orderDetail}
            onCloseModal={onClose}
            reload={reload}
          />
        );
      case storage?.staticVariables?.OrderType?.Order_Transit:
        return (
          <Transit
            dataOrderCopy={fields}
            dataOrderDetail={orderDetail}
            onCloseModal={onClose}
            reload={reload}
          />
        );

      default:
        return (
          <Booking
            dataOrderCopy={fields}
            dataOrderDetail={orderDetail}
            onCloseModal={onClose}
            reload={reload}
          />
        );
    }
  };

  return (
    <Modal
      title='Đã Copy Thông Tin Và Tạo Đơn Hàng'
      open={isOpenModal}
      onCancel={() => onClose && onClose()}
      footer={null}
      confirmLoading={loading}
      style={{
        top: 20,
      }}
      width='80%'
    >
      {renderForm()}
    </Modal>
  );
};
