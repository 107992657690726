import { useEffect, useState } from 'react';
import { Form, Input, message, Modal, Popconfirm, Space, Table } from 'antd';
import ApiServices from '~services';
import { AutoComplete } from '~components/selection';

export const MenuFunction = ({ dataInput, reload, onLoading }) => {
  const [selected, setSelected] = useState();

  useEffect(() => setSelected(), [dataInput]);

  const onUpdateRow = (e, record) => {
    e?.preventDefault();
    setSelected(record);
  };

  const onDeleteRow = (id) => {
    onLoading(true);
    return ApiServices.deleteMenuFunction(id)
      .then(() => {
        reload?.();
        message.success('Thao tác thành công!!!');
      })
      .catch((err) => {
        console.error('Create Menu', err);
        message.error('Thao tác thất bại');
      })
      .finally(() => onLoading(false));
  };
  const onCreateRow = (e) => {
    e?.preventDefault();
    setSelected({});
  };

  const columns = [
    {
      title: 'Mã',
      dataIndex: 'code',
      render: (val, record) => (
        <span className={!record?.notInGroup ? 'green bold' : ''}>{val}</span>
      ),
    },
    {
      title: 'Tên',
      dataIndex: 'name',
    },
    {
      title: 'Chức năng',
      dataIndex: 'arrSystemsCode',
      render: (val) => val?.join(', '),
    },
    {
      title: '#',
      dataIndex: 'id',
      render: (id, record) => (
        <Space>
          <a href='#' onClick={(e) => onUpdateRow(e, record)}>
            Sửa
          </a>
          <Popconfirm
            className='red pointer'
            title='Xác nhận xóa!'
            onConfirm={() => onDeleteRow(id)}
          >
            Xóa
          </Popconfirm>
        </Space>
      ),
    },
  ];

  if (!dataInput?.arrSysFuncs) {
    return null;
  }

  return (
    <>
      <h3>
        Danh sách Nhóm chức năng ({dataInput?.arrSysFuncs?.length || 0}){' '}
        <a href='#' onClick={onCreateRow}>
          Thêm Mới
        </a>
      </h3>
      <Table dataSource={dataInput?.arrSysFuncs || []} rowKey='id' columns={columns} />
      <FormInput reload={reload} data={selected} menuId={dataInput?.id} />
    </>
  );
};
const FormInput = ({ data, menuId, reload }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const onSubmitForm = (value) => {
    if (!data?.id) {
      return ApiServices.createMenuFunction(menuId, [value])
        .then(() => {
          reload?.();
          onCancelModal();
          message.success('Thao tác thành công!!!');
        })
        .catch((err) => {
          console.error('Create Menu', err);
          message.error('Thao tác thất bại');
        });
    }
    return ApiServices.updateMenuFunction(data.id, value)
      .then(() => {
        reload?.();
        onCancelModal();
        message.success('Thao tác thành công!!!');
      })
      .catch((err) => {
        console.error('update Menu', err);
        message.error('Thao tác thất bại');
      });
  };

  useEffect(() => {
    data ? setVisible(true) : setVisible(false);
    if (!data?.id) {
      form.resetFields();
    }
    form.setFieldsValue(data);
  }, [data]);

  const onCancelModal = () => setVisible(false);
  const onOkModal = () => form.submit();

  if (!data) {
    return null;
  }

  return (
    <Modal
      title='Thông tin Nhóm chức năng'
      open={visible}
      onCancel={onCancelModal}
      onOk={onOkModal}
    >
      <Form
        form={form}
        layout='vertical'
        name='global_state'
        className='form-job-handle'
        onFinish={onSubmitForm}
      >
        <Form.Item
          name='code'
          label='Mã Nhóm Chức Năng'
          rules={[
            {
              required: true,
              message: 'Bạn phải nhập Mã Nhóm',
            },
          ]}
        >
          <Input placeholder='Mã Nhóm' />
        </Form.Item>
        <Form.Item
          name='name'
          label='Tên Nhóm Chức Năng'
          rules={[
            {
              required: true,
              message: 'Bạn phải nhập Tên Nhóm',
            },
          ]}
        >
          <Input placeholder='Tên Nhóm' />
        </Form.Item>
        <Form.Item
          name='arrSystemsCode'
          label='Chức năng'
          rules={[
            {
              required: true,
              message: 'Bạn phải nhập Chức năng',
            },
          ]}
        >
          <AutoComplete
            asyncApi={ApiServices.getFunctions}
            placeholder='Chọn Chức năng'
            fieldValue='code'
            fieldLabel='code'
            multiple
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
