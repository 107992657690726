import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';

export const ExportCSV = ({ fileName, data, header, mergeColumn = [] }) => {
  let [wscols, setWscols] = useState([]);

  useEffect(() => {
    let wcols = [];
    for (let el of Object.keys(header[0])) {
      wcols.push({ wch: Math.max(...data.map((item) => item[el]?.length || 5)) });
    }
    setWscols(wcols);
  }, [data]);

  // ******** XLSX with new header *************
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx'; //xlsb || csv || xlsx

  const exportToCSV = (csvData, fileName, wscols) => {
    const headerSheet = Object.keys(header[0]);

    const Heading = header;
    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: headerSheet,
      skipHeader: true,
      origin: 0, //ok
    });
    ws['!cols'] = wscols;
    XLSX.utils.sheet_add_json(ws, csvData, {
      header: headerSheet,
      // header: ['firstName', 'lastName', 'email', 'address', 'postcode'],
      skipHeader: true,
      origin: -1, //ok
    });
    if (mergeColumn.length) {
      ws['!merges'] = mergeColumn;
    }
    ws['A2'] &&
      (ws['A2'].s = {
        fill: { patternType: 'solid', fgColor: { rgb: 'FFFFFF00' } },
        font: { sz: '10.5', bold: true },
      });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataExel = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataExel, fileName + fileExtension);
  };

  const formatData = data.map((item) => {
    let tmp = {};
    for (let key of Object.keys(header[0])) {
      tmp[key] = item[key];
    }
    return tmp;
  });

  return <Button onClick={() => exportToCSV(formatData, fileName, wscols)}>Export XLSX</Button>;
};

export default ExportCSV;

// This component is a presentational component which takes the data to download and file name as props. The exportToCSV method is invoked when the export button is clicked on line 20.
