import React, { useEffect, useState } from 'react';
import { Table, Space, Modal, Form, Input, message, Popconfirm } from 'antd';
import styled from 'styled-components';
import { ModalInfoDetails } from './modal-info-details';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { Selection } from '~components';
import useDataSelectSearch from '~hooks/use-data-select-search';
import { CONT_STATUSES } from '~commons/constants';
import { ContStatus } from '~components/common-status';

const ContRollBackForm = ({ cont, onClose, onFinish }) => {
  const visible = !!cont;
  const [form] = Form.useForm();
  const [locations, getLocations] = useDataSelectSearch(ApiServices.getLocations);

  useEffect(() => {
    cont && !locations.length && getLocations();
    form.setFieldsValue({
      endLocation: cont?.locationOfReciptCode,
    });
  }, [cont]);

  const onClickSubmit = () => {
    form.submit();
  };
  const onSubmitForm = (val) => {
    ApiServices.rollBackCont({ id: cont.id, ...val })
      .then(() => {
        message.success('Khiếu nại thành công!');
        onFinish?.();
      })
      .catch((error) => {
        message.error('Khiếu nại thất bại!');
        console.error('RollBack Cont Error', error);
      });
  };
  const onSearchLocation = (Query) => getLocations({ Query }, 'name');

  return (
    <Modal
      title={`Khiếu nại CONT(${cont?.subNoNumber})`}
      open={visible}
      onCancel={onClose}
      onOk={onClickSubmit}
    >
      <Form
        name='basic'
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete='off'
        onFinish={onSubmitForm}
      >
        <Form.Item
          label='Địa điểm giao cont'
          name='endLocation'
          rules={[{ required: true, message: 'Vui lòng nhập địa điểm!' }]}
        >
          <Selection
            data={locations}
            placeholder='Chọn Địa Điểm'
            fieldValue='name'
            labelDefault={cont?.locationOfReciptName}
            onSearch={onSearchLocation}
          />
        </Form.Item>
        <Form.Item
          label='Lý do'
          name='note'
          rules={[{ required: true, message: 'Vui lòng nhập lý do!' }]}
        >
          <Input placeholder='lý do' />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export const ExpandedRowRender = ({ reload, loading = false, dataTest = [] }) => {
  const [loadingModal, setLoadingModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rollBackCont, setRollBackCont] = useState();
  const [infoDetails, setInfoDetails] = useState([]);
  const [dataProcess, setDataProcess] = useState([]);

  const showModal = async (rowData) => {
    setLoadingModal(true);
    try {
      const { data } = await ApiServices.getTaskJobsDetailsId(rowData.id);
      if (data) {
        const dataNew = await data.map((item) => {
          item.key = `A${item.id}`;
          return item;
        });
        setDataProcess(data);
        setInfoDetails(dataNew);
        setIsModalVisible(true);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingModal(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onCloseRollBackCont = () => {
    setRollBackCont();
  };
  const onOpenRollBackCont = (cont) => {
    setRollBackCont(cont);
  };
  const onDeleteCont = (cont) => {
    ApiServices.deleteCont(cont?.id)
      .then((res) => {
        if (res.data) {
          reload();
          message.success('Hủy Cont Thành Công!');
        }
      })
      .catch((err) => {
        console.error('Delete Cont Error', err);
      });
  };
  const columns = [
    {
      title: '#',
      key: 'operation',
      width: 80,
      align: 'center',
      render: (record) => (
        <>
          {record?.status !== CONT_STATUSES.INITIAL && record?.rmoocId && (
            <div>
              <Space size='middle' onClick={() => showModal(record)}>
                <a>Chi tiết</a>
              </Space>
            </div>
          )}
          {record?.status === CONT_STATUSES.FINISH && !record?.partnerName && (
            <Popconfirm title='Xác nhận khiếu nại?' onConfirm={() => onOpenRollBackCont(record)}>
              <div>
                <Space size='middle'>
                  <a className='green'>Khiếu nại</a>
                </Space>
              </div>
            </Popconfirm>
          )}
          {record.status !== CONT_STATUSES.CANCELED && !record?.partnerName && (
            <Popconfirm title='Xác nhận HỦY?' onConfirm={() => onDeleteCont(record)}>
              <div>
                <Space size='middle'>
                  <a className='red'>Hủy</a>
                </Space>
              </div>
            </Popconfirm>
          )}
        </>
      ),
    },
    {
      title: 'Trạng Thái',
      dataIndex: 'status',
      width: 120,
      render: (value, record) => (
        <>
          <ContStatus status={value} />
          {record?.partnerName && (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              ({record.partnerName})
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Số SubNo',
      dataIndex: 'subNoNumber',
      width: 200,
    },
    {
      title: 'Số Cont',
      dataIndex: 'contNumber',
      width: 150,
    },
    {
      title: 'Số Seal',
      dataIndex: 'sealNumber',
      width: 120,
    },
    {
      title: 'YCGC',
      dataIndex: 'book_DeliveryContRequestDate',
      width: 100,
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'YCLC',
      dataIndex: 'bill_LiftingLadenContRequestDate',
      width: 100,
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'Chuyển Kho',
      dataIndex: 'transit_Date',
      width: 150,
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'Trọng Tải',
      dataIndex: 'cargoWeight',
      width: 100,
    },
    {
      title: 'Nơi LH',
      dataIndex: 'locationOfReciptName',
      width: 130,
    },
    {
      title: 'Nơi GH',
      dataIndex: 'locationOfDeliveryName',
      width: 130,
    },
    {
      title: 'Nơi LR',
      dataIndex: 'book_LocationDepotFromName',
      width: 130,
    },
    {
      title: 'Nơi TR',
      dataIndex: 'bill_LocationDepotReturnName',
      width: 130,
    },
    {
      title: 'Ghi Chú',
      dataIndex: 'note',
      width: 250,
    },
  ];
  const onFinisRollBack = () => {
    reload?.();
    setRollBackCont();
  };

  return (
    <Container className='list-order-container'>
      <Table
        bordered
        rowKey='id'
        size='small'
        scroll={{ x: '100%' }}
        loading={loading}
        columns={columns}
        dataSource={dataTest}
        pagination={false}
      />
      <ContRollBackForm
        cont={rollBackCont}
        onClose={onCloseRollBackCont}
        onFinish={onFinisRollBack}
      />
      <ModalInfoDetails
        infoDetails={infoDetails}
        dataProcess={dataProcess}
        isModalVisible={isModalVisible}
        loadingModal={loadingModal}
        handleCancel={handleCancel}
      />
    </Container>
  );
};

const Container = styled.div`
  // width: 100%;
  // margin-left: 30px;
  // margin-right: 15px;
  padding-top: 5px;
  padding-bottom: 15px;
  display: flex;
  position: relative;
  height: fit-content;
  display: flex;
  transition: height 0.5s linear;
`;
