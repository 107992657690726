import React, { useState, useEffect } from 'react';
import { Button, Form, InputNumber } from 'antd';
import { Selection } from '~components';
import ApiServices from '~services';

const TransportFeeMainForm = ({
  countShow,
  locationSelected,
  contTypeList,
  handleSubmit,
  dataFeeMainTransport,
}) => {
  const [form] = Form.useForm();
  const [loadingFeeMainForm, setLoadingFeeMainForm] = useState(false);
  const [distanceFindLocations, setDistanceFindLocations] = useState([]);
  const [showErrMessage, setShowErrMessage] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [countShow]);

  const getDistanceByFindLocation = async (arrLocationId, text = '') => {
    setLoadingFeeMainForm(true);
    try {
      let { data } = await ApiServices.getDistanceByFindLocation(arrLocationId, { name: text });
      if (data?.data) {
        setDistanceFindLocations(data?.data);
      }
    } catch (error) {
      console.log('Error - getDistanceByFindLocation failed:', error);
    } finally {
      setLoadingFeeMainForm(false);
    }
  };
  useEffect(() => {
    if (locationSelected?.length > 0) {
      getDistanceByFindLocation(locationSelected);
    }
  }, [locationSelected]);

  const handleSearchRoute = (text) => {
    if (locationSelected?.length > 0) {
      getDistanceByFindLocation(locationSelected, text);
    }
  };

  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  const generateId = `${s4()}-${s4()}-${s4()}-${s4()}`;

  const onFinish = async (values) => {
    const checkHasPrice = contTypeList?.some((item) => values[item.id] > 0);
    if (!checkHasPrice) {
      setShowErrMessage(true);
      return;
    }
    const dataObjectDistance = distanceFindLocations?.find((item) => item.id === values.distanceId);
    const dataFee = contTypeList?.map((item) => {
      return {
        contTypeId: item?.id,
        name: item?.name,
        fee: values[item.id],
      };
    });
    const dataSend = {
      ...values,
      dataObjectDistance,
      fees: dataFee,
      idGenerate: generateId,
    };
    handleSubmit && handleSubmit(dataSend);
    form.resetFields();
  };
  const onFinishFailed = () => {};

  const handleClearShowMessage = () => {
    if (!showErrMessage) return;
    setShowErrMessage(false);
  };

  const currentRouteLocation = dataFeeMainTransport?.map((el) => el?.distanceId);
  const routeLocationAvailable = distanceFindLocations?.filter((item) => {
    return !currentRouteLocation.includes(item.id);
  });

  return (
    <div>
      <Form
        name='basic'
        form={form}
        labelCol={{
          span: 16,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        layout='vertical'
      >
        <Form.Item
          label='Chọn tuyến đường'
          name='distanceId'
          rules={[
            {
              required: true,
              message: 'Bạn phải Chọn tuyến đường!',
            },
          ]}
        >
          <Selection
            data={routeLocationAvailable}
            placeholder='Chọn tuyến đường'
            typeOption='route-location'
            onSearch={handleSearchRoute}
          />
        </Form.Item>
        {contTypeList?.map((item) => {
          return (
            <Form.Item key={item.id} label={item?.name} name={item?.id}>
              <InputNumber
                onChange={handleClearShowMessage}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
              />
            </Form.Item>
          );
        })}
        {showErrMessage && (
          <div
            style={{
              marginBottom: '20px',
            }}
          >
            <i className='red'>Bạn phải nhập ít nhất 1 chi phí !!!</i>
          </div>
        )}

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type='primary' htmlType='submit' loading={loadingFeeMainForm}>
            Tạo phí
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { TransportFeeMainForm };
