import { ListNotificationWB } from './list-notification-wb';
import { Dropdown, Avatar, Menu, Badge, Tooltip } from 'antd';
import { BellFilled } from '@ant-design/icons';
import { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCounterNotRead } from '~store/reducers/notifications/action';

export const Notifications = () => {
  const timeOutRef = useRef(null);
  const dispatch = useDispatch();
  const [isShowNotification, setIsShowNotification] = useState(false);
  const { countNotRead: counterNotification } = useSelector((state) => state.notifications);

  const getData = async () => {
    await dispatch(fetchCounterNotRead());
  };

  useEffect(() => {
    // getData();
    timeOutRef.current && clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => getData(), 3000);
    const channel = new BroadcastChannel('sw-messages');
    channel.addEventListener('message', (event) => {
      console.log('Received', event.data);
      dispatch(fetchCounterNotRead());
    });
    return () =>
      channel.removeEventListener('message', (event) => {
        console.log('Received', event.data);
      });
  }, []);

  const onShowNotification = async () => {
    setIsShowNotification(!isShowNotification);
  };

  const menuNotification = useMemo(
    () => (
      <Menu style={{ width: '480px', borderRadius: '15px' }}>
        <ListNotificationWB onShowNotification={onShowNotification} />
      </Menu>
    ),
    [onShowNotification]
  );

  return (
    <div className='test-notification'>
      <Dropdown
        overlay={menuNotification}
        trigger={['click']}
        onVisibleChange={onShowNotification}
        visible={isShowNotification}
        placement='bottomRight'
        arrow
      >
        <Tooltip placement='bottom' title={<span>Thông Báo</span>}>
          <Badge count={counterNotification}>
            <Avatar size='default' icon={<BellFilled style={{ color: 'blue' }} />} />
          </Badge>
        </Tooltip>
      </Dropdown>
    </div>
  );
};
