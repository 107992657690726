import { message, Popconfirm, Table } from 'antd';
import ApiServices from '~services';

export const GroupUser = ({ dataInput, reload, onLoading }) => {
  if (!dataInput?.info) {
    return null;
  }

  const onActionUser = (e, userId, addToGroup = true) => {
    e?.preventDefault();

    onLoading(true);
    return ApiServices.setUserGroup({
      groupId: dataInput?.id,
      arrUsers: [userId],
      addToGroup,
    })
      .then((res) => {
        if (res.data) {
          message.success('Theo tác thành công!');
          reload?.();
        }
      })
      .finally(() => onLoading(false));
  };

  const columns = [
    {
      title: 'Mã',
      dataIndex: 'code',
      render: (val, record) => (
        <span className={!record?.notInGroup ? 'green bold' : ''}>{val}</span>
      ),
    },
    {
      title: 'Tên',
      dataIndex: 'fullName',
    },
    {
      title: 'Mã',
      dataIndex: 'id',
      render: (id, record) => (
        <>
          {record?.notInGroup ? (
            <a href='#' onClick={(e) => onActionUser(e, id)}>
              Thêm vào nhóm
            </a>
          ) : (
            <Popconfirm
              className='red pointer'
              title='Xác nhận xóa!'
              onConfirm={() => onActionUser(null, id, false)}
            >
              Xóa khỏi nhóm
            </Popconfirm>
          )}
        </>
      ),
    },
  ];
  const data = [
    ...(dataInput?.users?.inGroup || []),
    ...(dataInput?.users?.notInGroup.map((el) => ({ ...el, notInGroup: true })) || []),
  ];

  return (
    <>
      <h3>
        Danh sách người dùng ({dataInput?.users?.inGroup?.length || 0}/{data?.length})
      </h3>
      <Table dataSource={data} rowKey='id' columns={columns} />
    </>
  );
};
