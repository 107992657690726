import React from 'react';
import { Typography, Table } from 'antd';
import Utils from '~commons/utils';
const { Text } = Typography;

export const RenderSummary = ({ pageData }) => {
  let sumTotalKm = 0;
  let sumTotalOilQuota = 0;
  let sumTotalOilPrice = 0;
  let sumTotalOilToSupply = 0;
  let sumTotalMoney = 0;

  pageData.forEach((page) => {
    sumTotalKm += page.totalKm;
    sumTotalOilQuota = page.oilQuota;
    sumTotalOilPrice = page.oilPrice;
    sumTotalOilToSupply += page.newOilToSupply;
    sumTotalMoney += page.newOilTotalMoney;
  });
  return (
    <>
      <Table.Summary.Row
        style={{
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Table.Summary.Cell index={0}></Table.Summary.Cell>
        <Table.Summary.Cell index={1}></Table.Summary.Cell>
        <Table.Summary.Cell index={2}></Table.Summary.Cell>
        <Table.Summary.Cell index={3}>
          <Text type='danger' strong>
            Tổng:
          </Text>
        </Table.Summary.Cell>

        <Table.Summary.Cell index={4}>
          <Text type='danger' strong>
            {sumTotalKm && Utils.formatNumber(sumTotalKm)}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5}>
          <Text type='danger' strong>
            {sumTotalOilQuota}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6}>
          <Text type='danger' strong>
            {sumTotalOilPrice && Utils.formatNumber(sumTotalOilPrice)}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={7}>
          <Text type='danger' strong>
            {sumTotalOilToSupply && Utils.formatNumber(sumTotalOilToSupply)}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={8}>
          <Text type='danger' strong>
            {sumTotalMoney && Utils.formatNumber(sumTotalMoney)}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={9}></Table.Summary.Cell>
        <Table.Summary.Cell index={10}></Table.Summary.Cell>
        {/* <Table.Summary.Cell index={11}></Table.Summary.Cell> */}
      </Table.Summary.Row>
    </>
  );
};
