import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import { Input, Button, Popconfirm, message, Space } from 'antd';
import { TableLayout, RangeDateFilter } from '~components';
import { OilPricesForm } from './oil-price-form';
import DriverFields from './fields-data';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { TableTitle } from '~commons/styles';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD'),
};

const AssignTransitPartner = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [managementCosts, setManagementCosts] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getListAssignTransitPartner(newQuery)
      .then((res) => {
        if (res?.data) {
          setManagementCosts(res?.data);
        }
      })
      .catch((err) => {
        console.error('Get Vats Paging Error', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event) => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = event.target.value;
      const name = event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;

      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const setFieldsForm = async (value) => {
    const driverFields = await DriverFields(value);
    await setFields(driverFields);
    return driverFields;
  };
  const reload = async () => {
    setSelected(null);
    await getData(filter);
  };

  const getUpdatedOilPriceForm = async (value, isNew = false, dataSelect, id = null) => {
    const driverForm = await (
      <OilPricesForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        dataSelect={dataSelect}
        reload={reload}
        onChange={setFields}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: driverForm,
      },
    ]);
  };

  const deleteItem = async (record) => {
    if (!record?.trackingTransit) return;

    setLoading(true);
    return ApiServices.deleteAssignTransitPartner(record?.trackingTransit)
      .then((res) => {
        if (res?.data?.data) {
          message.success('Xoá thành công');
          return reload();
        } else {
          message.error('Xoá thất baị, vui lòng kiểm tra lại thông tin');
        }
      })
      .catch((err) => {
        console.log('Delete assign transit vendor failed: ', err);
        message.error('Xoá thất baị, vui lòng kiểm tra lại thông tin');
      })
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Đối tác
          <Input name='tracePartnerName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'tracePartnerName',
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Phiếu CK
          <Input name='code' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'code',
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Khách Hàng
          {/* <Input name='customerName' onChange={onColumnSearch} placeholder='Search' /> */}
        </TableTitle>
      ),
      dataIndex: 'customerName',
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Địa Điểm Bắt Đầu
          {/* <Input name='beginLocationName' onChange={onColumnSearch} placeholder='Search' /> */}
        </TableTitle>
      ),
      dataIndex: 'beginLocationName',
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Địa Điểm Kết Thúc
          {/* <Input name='endLocationName' onChange={onColumnSearch} placeholder='Search' /> */}
        </TableTitle>
      ),
      dataIndex: 'endLocationName',
      align: 'center',
    },
    {
      title: <TableTitle>Ngày điều</TableTitle>,
      dataIndex: 'createdOn',
      align: 'center',
      width: 150,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: 'Action',
      fixed: 'right',
      align: 'center',
      width: 90,
      render: (record) => (
        <Popconfirm
          onConfirm={() => deleteItem(record)}
          title='Xoá Lương quản lí này?'
          okText='Yes'
          cancelText='No'
        >
          <a href='#' style={{ color: 'red' }}>
            Xoá
          </a>
        </Popconfirm>
      ),
    },
  ];

  const createDriver = async () => {
    await setTitleTab('Thêm mới');
    await setFieldsForm({})
      .then((res) => getUpdatedOilPriceForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };

  const headerContent = (
    <>
      <h2>ĐIỀU CHUYỂN KHO XE NGOÀI</h2>
      <Space>
        <RangeDateFilter query={filter} onChange={setFilter} />
        <Button type='primary' onClick={createDriver}>
          Thêm Mới
        </Button>
      </Space>
    </>
  );

  const pagination = {
    total: managementCosts?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={managementCosts?.data || []}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      pagination={pagination}
      isDisplayCheckbox={false}
    />
  );
};

export { AssignTransitPartner };
