import React from 'react';
import { TransitFeeMain } from './transit-fee-main';

const TransitFee = ({
  contTypeList,
  dataFeeMainTransit,
  setDataFeeMainTransit,
  locationSelected,
  isPartner = false,
  reloadAfterUpdate,
  // contractStatus = 0,
  isNew = false,
  valueDetail,
}) => {
  return (
    <>
      <TransitFeeMain
        contTypeList={contTypeList}
        dataFeeMainTransit={dataFeeMainTransit}
        setDataFeeMainTransit={setDataFeeMainTransit}
        locationSelected={locationSelected}
        isPartner={isPartner}
        reloadAfterUpdate={reloadAfterUpdate}
        // contractStatus={contractStatus}
        isNew={isNew}
        valueDetail={valueDetail}
      />
    </>
  );
};

export { TransitFee };
