import React from 'react';
import { Table } from 'antd';
import DrawerLeft from './drawer-left';

class TableLayoutNew extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedRowKeys: [],
      loading: false,
      isDrawerVisible: false,
    };

    this.onSelectChangeState = this.onSelectChangeState.bind(this);
  }
  onSelectChangeState(selectedRowKeys) {
    this.setState({ selectedRowKeys });
  }
  render() {
    const {
      showSelection = true,
      selectedParent = null,
      onHandleSelectRow,
      onHandleSelectAll,
      renderRowKey = null,
      columns,
      data,
      loading,
      selected,
      closeDrawer,
      headerContent,
      titleTab,
      tabPanels,
      isLoadingTab,
      // selectParent,
      onSelectChange,
      isSelectRadioBox,
      checkDisableSelection,
      pagination,
      rowClassName,
      rowKey = null,
      width,
      headerLength = '220px',
      // isDisplayCheckbox = true,
      isScrollX = true,
      customScrollSize = '0px',
      onChange,
    } = this.props;

    const type = isSelectRadioBox ? 'radio' : 'checkbox';

    const rowSelection = {
      type,
      selectedRowKeys: selectedParent !== null ? selectedParent : this.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.onSelectChangeState(selectedRowKeys, selectedRows);
        onSelectChange && onSelectChange(selectedRowKeys, selectedRows);
      },
      getCheckboxProps: checkDisableSelection
        ? (record) => ({
            disabled: checkDisableSelection(record),
            key: record.id,
          })
        : null,
      onSelect: onHandleSelectRow,
      onSelectAll: onHandleSelectAll,
    };

    const rowClass = (record) => rowClassName(record);
    let dataFormat = null;
    if (renderRowKey) {
      dataFormat = data?.map((item) => {
        return {
          ...item,
          key: renderRowKey(item),
        };
      });
    }
    return (
      <div className='table-content'>
        <div style={{ marginBottom: 10 }}>{headerContent}</div>
        <Table
          className={this.props?.className}
          rowClassName={rowClassName ? rowClass : null}
          bordered={true}
          expandable={this.props?.expandable}
          rowKey={rowKey ? rowKey : 'key'}
          rowSelection={showSelection && rowSelection}
          columns={columns}
          dataSource={dataFormat || data}
          scroll={
            this.props?.scroll || isScrollX
              ? { x: '100%', y: `calc(100vh - ${headerLength})` }
              : { y: `calc(100vh - ${headerLength} - ${customScrollSize})` }
          }
          loading={loading}
          pagination={pagination}
          size='small'
          onChange={onChange}
        />
        <DrawerLeft
          isLoadingTab={isLoadingTab}
          visible={!!selected}
          title={titleTab}
          onClose={closeDrawer}
          tabPanels={tabPanels}
          width={width}
        />
      </div>
    );
  }
}

export default TableLayoutNew;
