export const KEYS = ['locationId', 'locationName'];
export const KEY_OF_NUMBER = ['loss'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });
  KEY_OF_NUMBER.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] || 0,
    });
  });
  return result;
};
