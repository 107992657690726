import React, { useEffect, useState } from 'react';
import { TableLayout } from '~components';
import { ReplacementCategoryForm } from './replacement-category-form';
import ReplacementCategoryFields from './fields-data';
import { Button, Popconfirm, message } from 'antd';

import ApiServices from '~services';

const ReplacementCategories = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [replacementCategories, setReplacementCategories] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);
  const getReplacementCategories = async () => {
    try {
      setLoading(true);
      let { data } = await ApiServices.getReplacementCategories();
      setLoading(false);
      if (data) {
        const dataMap = data?.map((item, ind) => {
          item.index = ind + 1;
          return item;
        });
        setReplacementCategories(dataMap || []);
      }
    } catch (error) {
      console.log('Error - get cars', error);
      console.error(error);
    }
  };

  useEffect(() => {
    getReplacementCategories();
  }, []);

  const setFieldsForm = async (value) => {
    const replacementCategoryFields = await ReplacementCategoryFields(value);
    await setFields(replacementCategoryFields);
    return replacementCategoryFields;
  };
  const reload = async () => {
    setSelected(null);
    await getReplacementCategories();
  };

  const getUpdatedReplacementCategories = async (value, isNew = false, id = null, dataSelected) => {
    const replacementCategoryForm = await (
      <ReplacementCategoryForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        reload={reload}
        onChange={setFields}
        dataSelected={dataSelected}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: replacementCategoryForm,
      },
    ]);
  };

  const onRowSelected = async (value) => {
    await setIsLoadingTab(true);
    await setTitleTab(value.code);
    await setFieldsForm(value)
      .then((res) => {
        return getUpdatedReplacementCategories(res, false, value.id, value);
      })
      .then(() => setSelected(value));
    setIsLoadingTab(false);
  };
  const deleteReplacementCategory = async (record) => {
    try {
      const res = await ApiServices.deleteReplacementCategory(record.id);
      if (res.data) {
        message.success('Xoá Tài xế thành công');
        return reload();
      }
      message.error('Xoá Tài xế thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: 'STT',
      width: 50,
      dataIndex: 'index',
      align: 'center',
    },
    {
      title: 'Tên Hạng Mục',
      dataIndex: 'name',
      width: 150,
      align: 'center',
      render: (value, record) => (
        <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>
      ),
    },
    {
      title: 'Tên Cảnh Báo',
      width: 150,
      dataIndex: 'warningName',
      align: 'center',
    },
    {
      title: 'Thao Tác',
      fixed: 'right',
      width: 90,
      align: 'center',
      render: (record) => (
        <Popconfirm
          onConfirm={() => deleteReplacementCategory(record)}
          title='Xoá hạng mục thay thế này？'
          okText='Yes'
          cancelText='No'
        >
          <a style={{ color: 'red' }} href='#'>
            Xoá
          </a>
        </Popconfirm>
      ),
    },
  ];

  const createReplacementCategories = async () => {
    await setTitleTab('');
    await setFieldsForm({})
      .then((res) => getUpdatedReplacementCategories(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };

  const headerContent = (
    <>
      <Button type='primary' onClick={createReplacementCategories}>
        Thêm Mới
      </Button>
    </>
  );
  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={replacementCategories}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      isDisplayCheckbox={false}
    />
  );
};

export { ReplacementCategories };
