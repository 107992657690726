import React from 'react';
import { Modal, Button } from 'antd';

class Popup extends React.Component {
  state = {
    loading: false,
    visible: false,
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  render() {
    const { loading } = this.state;
    const {
      visible,
      body,
      title,
      closeModel,
      onSubmit,
      width,
      otherButtons,
      nameButton,
      disableButton,
    } = this.props;
    const footer = otherButtons ? (
      [
        ...otherButtons,
        <Button key='submit' type='primary' loading={loading} onClick={onSubmit}>
          Xác nhận
        </Button>,
      ]
    ) : (
      <Button
        key='submit'
        type='primary'
        loading={loading}
        onClick={onSubmit}
        disabled={disableButton ? disableButton : false}
      >
        {nameButton ? nameButton : 'Xác nhận'}
      </Button>
    );
    return (
      <Modal
        width={width || 'auto'}
        open={visible}
        title={title}
        onOk={onSubmit}
        onCancel={closeModel}
        footer={footer}
      >
        {body}
      </Modal>
    );
  }
}
export { Popup };
