import { Table } from 'antd';
import Utils from '~commons/utils';

const Tickets = ({ ticketList }) => {
  const columns = [
    {
      title: 'Số phiếu',
      dataIndex: 'aid',
      // width: 100,
      align: 'center',
    },
    {
      title: 'Folder',
      dataIndex: 'folder',
      align: 'center',
      // width: 100,
    },
    {
      title: 'SubNo',
      dataIndex: 'subnoNumber',
      align: 'center',
      // width: 150,
    },
    {
      title: 'Khách hàng',
      dataIndex: 'customerName',
      // width: 150,
      align: 'center',
    },
    {
      title: 'Ngày điều xe',
      dataIndex: 'assignDate',
      // width: 120,
      align: 'center',
      render: (val) => Utils.formatDate(val),
    },
    {
      title: 'Ngày giải trừ',
      dataIndex: 'dissolveDate',
      // width: 120,
      align: 'center',
      render: (val) => Utils.formatDate(val),
    },
    {
      title: 'Số tiền',
      dataIndex: 'total',
      // width: 100,
      align: 'center',
      render: (val) => val && Utils.formatNumber(val),
    },
    {
      title: 'Người giải trừ',
      dataIndex: 'createdBy',
      // width: 100,
      align: 'center',
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      // width: 100,
      align: 'center',
    },
  ];

  return (
    <div
      // style={{
      //   marginTop: '-100px',
      // }}
      className='table-container-custom'
    >
      <Table
        bordered
        size='small'
        columns={columns}
        dataSource={ticketList}
        rowKey='id'
        scroll={{
          x: '100%',
          y: 'calc(100vh - 610px)',
        }}
        pagination={false}
      />
    </div>
  );
};
export { Tickets };
