import actionTypes from './type';
import { DEFAULT_QUERY_WB } from '~commons/constants';

const initialState = {
  data: null,
  query: {
    ...DEFAULT_QUERY_WB,
    Columns: {},
  },
  loading: false,
  isActiveDetail: false,
  selected: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_DATA:
      return {
        ...state,
        data: action.data,
      };
    case actionTypes.LOAD_MORE_DATA: {
      const moreData = state.data.data.map((el) => el);

      for (var el of action.data.data) {
        moreData.find((item) => item.id !== el.id) && moreData.push(el);
        // moreData.push(el);
      }
      const newData = {
        ...action.data,
        data: moreData,
      };
      return {
        ...state,
        data: newData,
      };
    }
    case actionTypes.UPDATE_QUERY: {
      let Columns = action.query.Columns;

      for (let key in Columns) {
        if (!Columns[key] && Columns[key] !== 0) {
          delete Columns[key];
        }
      }
      return {
        ...state,
        query: {
          ...action.query,
          Columns,
        },
      };
    }
    case actionTypes.UPDATE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actionTypes.UPDATE_SELECTED:
      return {
        ...state,
        selected: action.selected,
      };
    case actionTypes.UPDATE_ACTIVE_DETAIL:
      return {
        ...state,
        isActiveDetail: action.data,
      };
    default:
      return state;
  }
}
