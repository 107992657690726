import actionTypes from './type';
import { DEFAULT_QUERY_WB } from '~commons/constants';

const initialState = {
  data: null,
  query: DEFAULT_QUERY_WB,
  loading: false,
  selected: [],
  selectedCargoReady: [],
  selectedEmptyCont: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_DATA:
      return {
        ...state,
        data: action.data,
      };
    case actionTypes.LOAD_MORE_DATA: {
      let moreData = state.data.data.map((el) => el);

      for (var el of action.data.data) {
        moreData.find((item) => item.id !== el.id) && moreData.push(el);
      }
      let newData = {
        ...action.data,
        data: moreData,
      };
      return {
        ...state,
        data: newData,
      };
    }

    case actionTypes.UPDATE_QUERY:
      return {
        ...state,
        query: action.query,
      };
    case actionTypes.UPDATE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case actionTypes.UPDATE_SELECTED: {
      return {
        ...state,
        selected: action.selected.map((item) => item.id),
        selectedCargoReady: action.selected
          .filter((item) => item.orderType === 'Booking')
          .map((data) => data.id),
        selectedEmptyCont: action.selected
          .filter((item) => item.orderType === 'Bill')
          .map((data) => data.id),
      };
    }

    case actionTypes.UPDATE_ONE_DATA: {
      let newTmpData = state.data.data.map((el) => el);
      let fIndex = newTmpData.findIndex((el) => el.id === action.data.id);
      if (fIndex !== -1) {
        newTmpData[fIndex] = {
          ...newTmpData[fIndex],
          ...action.data,
        };
      }

      return {
        ...state,
        data: {
          ...state.data,
          data: newTmpData,
        },
      };
    }
    default:
      return state;
  }
}
