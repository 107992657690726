import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import { Input, Button, Space, Modal, message, DatePicker, Select } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { TableLayout, RangeDateFilter, BtnConfirm, UploadExcelButton } from '~components';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { TableTitle, Wrapper } from '~commons/styles';
import { TicketIsAdded } from './ticket-is-added';
import { FormUpdate } from './form-update';
import { FormChangeDissolveDate } from './form-change-dissolvedate';
import { DATE_FORMAT, DATE_FORMAT_SEND_API } from '~commons/constants';
const ExcelJS = require('exceljs');

const EXCEL_TICKET_JOB_TEMPLATE_URL =
  Utils.getAppUrl() + 'Files/Import_Excel/Giai_Tru_VanTai_TaiXe.xlsx';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};
const STATUS_OPTION_SELECTS = [
  {
    label: 'Tất cả',
    value: '',
  },
  {
    label: 'Chờ xác nhận',
    value: '1',
  },
  {
    label: 'Xác nhận',
    value: '0',
  },
];
const { Option } = Select;

const UpdateTicketJobVendor = () => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState('');
  const [bookingList, setBookingList] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [dataRowSelected, setDataRowSelected] = useState(null);
  const [isCreateTicketList, setCreateTicketList] = useState([]);
  const [currentTicketSelected, setCurrentTicketSelected] = useState(null);
  const [selectedMultipleTicket, setSelectedMultipleTicket] = useState([]);
  const [showChangeDissolveData, setShowChangeDissolveData] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [assignDateSearch, setAssignDateSearch] = useState(null);
  const [dissolveDateSearch, setDissolveDateSearch] = useState(null);

  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingExportNew, setLoadingExportNew] = useState(false);

  // const countSelectedTicket = useMemo(() => {
  //   return selectedMultipleTicket?.length;
  // }, [selectedMultipleTicket]);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = {
      ...query,
      DateFrom: moment(query.DateFrom).format('YYYY-MM-DD'),
      DateTo: moment(query.DateTo).format('YYYY-MM-DD'),
    };

    return ApiServices.getTicketJobVendorList(false, newQuery)
      .then((res) => {
        if (res?.data) {
          setBookingList(res?.data);
        }
      })
      .catch((err) => {
        console.error('get get Ticket Job Vendor List Error', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;

      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };
  const onColumnSearchDate = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const handleChangeAssignDateSearch = (date) => {
    setAssignDateSearch(date);
    const dataSearch = date ? moment(date).format(DATE_FORMAT_SEND_API) : null;
    onColumnSearchDate(dataSearch, 'createdOn');
  };
  const handleChangeDissolveDateSearch = (date) => {
    setDissolveDateSearch(date);
    const dataSearch = date ? moment(date).format(DATE_FORMAT_SEND_API) : null;
    onColumnSearchDate(dataSearch, 'dissolveDate');
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const handleCancel = () => {
    setDataRowSelected(null);
    setCreateTicketList([]);
    setCurrentTicketSelected(null);
    timeoutGetData(filter);
  };

  const handleCancelChangeDissolveDate = () => {
    setShowChangeDissolveData(false);
  };

  // const handlePrintMultipleTicket = () => {
  //   setShowChangeDissolveData(true);
  // };
  const reloadChangeDissolveDate = () => {
    setSelectedMultipleTicket([]);
    setShowChangeDissolveData(false);
    timeoutGetData(filter);
  };

  const reload = () => {
    return timeoutGetData(filter);
  };

  const onRowSelected = async (record) => {
    if (loading) return;

    setLoading(true);
    return ApiServices.getDetailTicket(record.id)
      .then((res) => {
        if (res?.data?.data) {
          const dataIsCreateTicket = res?.data?.data?.expenses?.filter((item) => {
            return item?.objTicketExpenses;
          });
          const differentTicket = dataIsCreateTicket?.filter(
            (item) => item?.objTicketExpenses?.ticketId !== record.id
          );
          const mapExpenses = differentTicket?.map((item) => {
            item.key = item.id;
            item.isExpenses = item?.objTicketExpenses ? true : false;
            item.price = item?.objTicketExpenses ? item?.objTicketExpenses?.price : '';
            item.vat = item?.objTicketExpenses ? item?.objTicketExpenses?.vat : '';
            item.total = item?.objTicketExpenses ? item?.objTicketExpenses?.total : '';
            item.billNumber = item?.objTicketExpenses ? item?.objTicketExpenses?.billNumber : '';
            item.billDate = item?.objTicketExpenses ? item?.objTicketExpenses?.billDate : null;
            item.supplierName = item?.objTicketExpenses
              ? item?.objTicketExpenses?.supplierName
              : '';

            return item;
          });
          const currentTicket = dataIsCreateTicket?.filter(
            (item) => item?.objTicketExpenses?.ticketId === record.id
          );
          setCreateTicketList(mapExpenses || []);
          setCurrentTicketSelected(currentTicket);
          setDataRowSelected(record);
        }
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = (ticketId) => {
    if (loading) return;
    setLoading(true);
    ApiServices.deleteTicket(ticketId)
      .then((res) => {
        if (res.data) {
          reload();
          message.success('Xóa thành công');
        }
      })
      .catch((err) => {
        console.error('Delete failed: ', err);
        message.error('Xoá thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };
  const handleDeleteFeeOfTicket = (ticketExpenseId) => {
    if (loadingDelete) return;
    setLoadingDelete(true);
    ApiServices.deleteFeeOfTicket(ticketExpenseId)
      .then((res) => {
        if (res.data) {
          message.success('Xóa thành công');
          return onRowSelected(dataRowSelected);
        }
      })
      .catch((err) => {
        console.error('Delete failed: ', err);
        message.error('Xoá thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoadingDelete(false));
  };

  const onDeleteFee = (data) => {
    // Delete Fee of Ticket
    if (currentTicketSelected?.length > 1) {
      return handleDeleteFeeOfTicket(data?.ticketExpenseId);
    }
    // Delete ticketId
    if (currentTicketSelected?.length === 1) {
      handleCancel();
      return handleDelete(data?.ticketId);
    }
  };

  const renderContNumber = (contValue, record) => {
    return (
      <>
        <div>{contValue}</div>
        {record?.commission && (
          <i
            className='red'
            style={{
              fontSize: '11px',
            }}
          >
            Cont Ủy Quyền
          </i>
        )}
      </>
    );
  };

  const statusTag = (text = '', bgColor = '') => {
    return (
      <div
        style={{
          backgroundColor: bgColor,
          width: '100px',
          padding: '8px 10px',
          textAlign: 'center',
          borderRadius: '4px',
          color: 'white',
          // fontWeight: 'bold',
          fontSize: '11px',
          cursor: 'pointer',
        }}
      >
        {text}
      </div>
    );
  };

  const renderStatus = (status) => {
    if (status === 0) {
      return statusTag('Xác nhận', 'green');
    } else {
      return statusTag('Chờ xác nhận', '#EB455F');
    }
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Số phiếu
          <Input name='aid' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'aid',
      align: 'center',
      width: 100,
      render: (value, record) => (
        <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>
      ),
    },
    {
      title: (
        <TableTitle>
          Số Cont
          <Input name='contNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'contNumber',
      align: 'center',
      width: 150,
      render: renderContNumber,
    },
    {
      title: (
        <TableTitle>
          Số SubNo
          <Input name='subnoNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'subnoNumber',
      align: 'center',
      width: 200,
    },
    {
      title: (
        <TableTitle>
          Khách hàng
          <Input name='customerName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'customerName',
      align: 'center',
      width: 200,
    },
    {
      title: (
        <TableTitle>
          Ngày nhận
          <DatePicker
            value={assignDateSearch}
            onChange={handleChangeAssignDateSearch}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'createdOn',
      align: 'center',
      width: 130,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Tài xế/Nhà xe
          <Input name='driverMainName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'driverMainName',
      align: 'center',
      width: 200,
    },
    {
      title: (
        <TableTitle>
          Ngày giải trừ
          <DatePicker
            value={dissolveDateSearch}
            onChange={handleChangeDissolveDateSearch}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'dissolveDate',
      align: 'center',
      width: 130,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: <TableTitle>Số tiền</TableTitle>,
      dataIndex: 'total',
      align: 'center',
      width: 150,
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: (
        <TableTitle>
          Người giải trừ
          <Input name='createdBy' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'createdBy',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Ghi chú
          <Input name='note' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'note',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Trạng thái
          <Select
            placeholder='Search'
            style={{ width: 110 }}
            defaultValue=''
            onChange={(e) => onColumnSearch(e, 'status')}
          >
            {STATUS_OPTION_SELECTS.map((item) => (
              <Option key={item.label + item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </TableTitle>
      ),
      dataIndex: 'status',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (val) => renderStatus(val),
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 75,
      render: (record) => {
        if (record?.status !== 1) return;
        return (
          <BtnConfirm
            onConfirm={() => {
              handleDelete(record?.id);
            }}
          >
            Xóa
          </BtnConfirm>
        );
      },
    },
  ];

  const pagination = {
    total: bookingList?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  const renderError = (item) => {
    return <div>{item}</div>;
  };
  const onUploadTicketJobExcel = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importExcelVendorTicket(file)
      .then((res) => {
        if (res?.data?.data) {
          if (res?.data?.errors?.length > 0) {
            window.TmsNoti.error(res?.data?.message, res?.data?.errors?.map(renderError), 0);
          } else if (res?.data?.data === 'error') {
            window.TmsNoti.error('Import thất bại', res?.data?.message, 0);
          } else {
            let countPrice = 0;
            res?.data?.data?.result?.map((item) => {
              if (item?.soTien) {
                countPrice += item?.soTien;
              }
            });
            window.TmsNoti.success(
              res?.data?.message,
              `Tổng số tiền: ${Utils.formatNumber(countPrice)}`,
              0
            );
            timeoutGetData(filter);
          }
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT ERROR', err);
        message.error('Thao tác thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };

  const handleExportExcel = async (dataExport = []) => {
    console.log({ dataExport });
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Giải trừ Vận Tải', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ showGridLines: false }],
    });
    // sheetSummary.properties.defaultRowHeight = 18;
    sheetSummary.properties.defaultColWidth = 15;
    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getCell('F2').value = 'DANH SÁCH GIẢI TRỪ VẬN TẢI';
    sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
    const date = `Từ ${moment(filter?.DateFrom).format('DD/MM/YYYY')} Đến ${moment(
      filter?.DateTo
    ).format('DD/MM/YYYY')}`;
    sheetSummary.getCell('F3').value = date;
    // Add table
    const arrDetail = await dataExport?.map((item) => {
      const dataItem = [
        item?.aid, // sO pHIEU
        item?.contNumber || '', // So Cont
        item?.subnoNumber || '', // So SubNo
        item?.customerName || '', // Khach Hang
        item?.createdOn && new Date(moment(item?.createdOn).format('YYYY-MM-DD')), // Ngày Nhận
        item?.dissolveDate && new Date(moment(item?.dissolveDate).format('YYYY-MM-DD')), // Ngày Giải Trừ
        item?.total || 0, // Số Tiền
        item?.createdBy || '', // Người Giải Trừ
        item?.driverMainName || '', // Tài Xe
        item?.note || '', // Ghi Chu
      ];
      return dataItem;
    });

    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B5',
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'Số Phiếu', totalsRowLabel: '', filterButton: false },
        {
          name: 'Số Cont',
          filterButton: false,
        },
        { name: 'Booking/Bill', totalsRowLabel: '', filterButton: false },
        {
          name: 'Khách Hàng',
          filterButton: false,
        },
        {
          name: 'Ngày Nhận',
          filterButton: false,
        },
        {
          name: 'Ngày Giải Trừ',
          filterButton: false,
        },
        {
          name: 'Số Tiền',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Người Giải Trừ',
          filterButton: false,
        },
        {
          name: 'Tài Xế/Nhà Xe',
          filterButton: false,
        },
        {
          name: 'Ghi Chú',
          filterButton: false,
        },
      ],
      rows: arrDetail,
    });
    // ========== STYLES =====================
    sheetSummary.getColumn(5).alignment = { wrapText: true };
    sheetSummary.getColumn(10).alignment = { wrapText: true };
    sheetSummary.getColumn(11).alignment = { wrapText: true };

    sheetSummary.getRow(5).font = {
      name: 'Arial',
      family: 2,
      size: 10,
      bold: true,
    };
    sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(5).height = 21;
    sheetSummary.getColumn(12).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getColumn(8).numFmt = '#,##0';

    sheetSummary.getColumn(2).width = 10;
    sheetSummary.getColumn(4).width = 30;
    sheetSummary.getColumn(5).width = 25;
    sheetSummary.getColumn(8).width = 15;
    sheetSummary.getColumn(9).width = 20;
    sheetSummary.getColumn(10).width = 30;
    sheetSummary.getColumn(11).width = 30;

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Danh_Sach_Giai_Tru_Van_Tai_${date}_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const handleGetDataExportNew = async (query = DEFAULT_QUERIES) => {
    if (!query?.DateFrom || !query?.DateTo || loadingExport) return;

    setLoadingExportNew(true);
    return ApiServices.getTicketJobVendorList(true, query)
      .then((res) => {
        if (res?.data?.data) {
          return handleExportExcel(res?.data?.data || []);
        }
      })
      .catch((err) => {
        console.error('get Data Export getDataExportTicketJob: ', err);
      })
      .finally(() => {
        setLoadingExportNew(false);
      });
  };

  const handleGetDataExport = async (query = DEFAULT_QUERIES) => {
    const companyId = JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId;

    if (!query?.DateFrom || !query?.DateTo || loadingExport || !companyId) return;

    const dateFrom = moment(query?.DateFrom).format('YYYY-MM-DD');
    const dateTo = moment(query?.DateTo).format('YYYY-MM-DD');

    setLoadingExport(true);

    try {
      const { data } = await ApiServices.getDataExportTicketJobVendor(companyId, dateFrom, dateTo);
      if (data) {
        const outputFilename = `Danh_Sach_Giai_Tru_Van_Tai.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoadingExport(false);
    }
  };

  const rowSelection = (selectedRowKeys) => {
    setSelectedMultipleTicket(selectedRowKeys);
  };

  const renderRowClassName = (record) => {
    if (record?.byVendor) {
      return 'order-from-ONP';
    } else {
      return '';
    }
  };

  return (
    <Wrapper>
      {/* <h3>Danh sách giải trừ vận tải</h3> */}
      <Space
        style={{
          marginBottom: '10px',
        }}
      >
        <RangeDateFilter query={filter} onChange={setFilter} />
        {/* <Badge count={countSelectedTicket}>
          <Button
            danger
            icon={<SwapOutlined />}
            style={{ padding: '0px 20px', marginLeft: '10px' }}
            disabled={countSelectedTicket < 1}
            onClick={handlePrintMultipleTicket}
          >
            Đổi ngày giải trừ
          </Button>
        </Badge> */}
        <Button
          type='primary'
          icon={<DownloadOutlined />}
          style={{ padding: '0px 20px', marginRight: '10px' }}
          loading={loadingExportNew}
          disabled={!bookingList?.data?.length}
          onClick={() => handleGetDataExportNew(filter)}
        >
          Tải Báo Cáo
        </Button>
        <UploadExcelButton
          loading={loading}
          onChange={onUploadTicketJobExcel}
          templateUrl={EXCEL_TICKET_JOB_TEMPLATE_URL}
          importLabel='Import Excel'
          isShowDownloadTemplate={false}
        />
        <Button
          type='primary'
          icon={<DownloadOutlined />}
          style={{ padding: '0px 20px', marginRight: '5px' }}
          onClick={() => handleGetDataExport(filter)}
          loading={loadingExport}
        >
          Tải mẫu giải trừ
        </Button>
      </Space>

      <TableLayout
        rowKey='id'
        headerLength='250px'
        columns={columns}
        data={bookingList?.data || []}
        loading={loading}
        selected={selected}
        selectParent={selectedMultipleTicket}
        onSelectChange={rowSelection}
        closeDrawer={() => setSelected(null)}
        pagination={pagination}
        rowClassName={renderRowClassName}
      />
      <Modal
        title='Thông tin chi tiết:'
        open={!!dataRowSelected}
        onCancel={handleCancel}
        footer={null}
        style={{
          top: 20,
        }}
        width='80%'
      >
        <TicketIsAdded dataExpenses={isCreateTicketList} />
        <FormUpdate
          dataCurrent={currentTicketSelected}
          dataInfo={dataRowSelected}
          handleCancel={handleCancel}
          reload={reload}
          onDeleteFee={onDeleteFee}
          onReloadAfterAddNewFee={(dataRow) => onRowSelected(dataRow)}
        />
      </Modal>
      <Modal
        title='Đổi ngày giải trừ:'
        open={showChangeDissolveData}
        onCancel={handleCancelChangeDissolveDate}
        footer={null}
        style={{
          top: 20,
        }}
      >
        <FormChangeDissolveDate
          selectedMultipleTicket={selectedMultipleTicket}
          reloadChangeDissolveDate={reloadChangeDissolveDate}
        />
      </Modal>
    </Wrapper>
  );
};

export { UpdateTicketJobVendor };
