import React, { useState } from 'react';
import { Form, Input, Button, Divider, message } from 'antd';
import ApiServices from '~services';
import { PasswordInput } from 'antd-password-input-strength';

const ChangePassWord = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onChangePassword = async (values) => {
    setLoading(true);
    try {
      const res = await ApiServices.changePassword({
        code: values.username,
        oldPassword: values.oldPassword,
        password: values.password,
      });
      if (res.data) {
        message.success('Đổi Mật Khẩu thành công');
        form.resetFields();
      } else {
        message.error('Đổi Mật Khẩu thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Đổi Mật Khẩu thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Divider>Đổi Mật Khẩu</Divider>
      <Form
        name='basic'
        labelCol={{
          span: 9,
        }}
        wrapperCol={{
          span: 6,
        }}
        initialValues={{
          remember: true,
        }}
        form={form}
        onFinish={onChangePassword}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label='Tài Khoản'
          name='username'
          rules={[
            {
              required: true,
              message: 'Bạn phải nhập tên tài khoản!',
              whitespace: true,
            },
          ]}
          hasFeedback
        >
          <Input placeholder='Tài Khoản' />
        </Form.Item>

        <Form.Item
          label='Mật Khẩu Cũ'
          name='oldPassword'
          rules={[
            {
              required: true,
              message: 'Bạn phải nhập mật khẩu cũ!',
            },
          ]}
          hasFeedback
        >
          <PasswordInput placeholder='Mật khẩu cũ' />
        </Form.Item>

        <Form.Item
          label='Mật Khẩu Mới'
          name='password'
          dependencies={['oldPassword']}
          rules={[
            {
              required: true,
              message: 'Bạn phải nhập mật khẩu mới!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue('oldPassword') === value) {
                  return Promise.reject(new Error('Giống mật khẩu cũ! Vui lòng nhập mật khẩu mới'));
                }
                return Promise.resolve();
              },
            }),
          ]}
          hasFeedback
        >
          <PasswordInput placeholder='Mật khẩu mới' />
        </Form.Item>
        <Form.Item
          label='Nhập Lại Mật Khẩu Mới'
          name='confirm'
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập lại mật khẩu mới!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('Mật khẩu mới không khớp!'));
              },
            }),
          ]}
          hasFeedback
        >
          <PasswordInput placeholder='Nhập lại mật khẩu mới' />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 10,
            span: 16,
          }}
        >
          <Button type='primary' htmlType='submit' loading={loading}>
            Xác Nhận
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export { ChangePassWord };
