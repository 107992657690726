import { Table, Typography } from 'antd';
import moment from 'moment';
import Utils from '~commons/utils';
const { Text } = Typography;

const TableContent = ({ loading, data = [], totalPriceCod = 0, totalPriceInit = 0 }) => {
  const renderIndex = (value, record) => {
    if (record?.key === 'parent') {
      return <b>{value}</b>;
    }
    return value;
  };
  const renderName = (value, record) => {
    if (record?.key === 'parent') {
      return <b>{value}</b>;
    }
    return value;
  };

  const renderCODPrice = (value, record) => {
    if (record?.key === 'parent') {
      return <b>{Utils.formatNumber(record?.totalPriceCODCustomer)}</b>;
    }
    return Utils.formatNumber(value);
  };
  const renderTotalPrice = (value, record) => {
    if (record?.key === 'parent') {
      return <b>{Utils.formatNumber(record?.totalPrice)}</b>;
    }
    return Utils.formatNumber(value);
  };
  const renderTotal = (value, record) => {
    if (record?.key === 'parent') {
      return <b>{Utils.formatNumber(record?.totalAll)}</b>;
    }
    return Utils.formatNumber(value);
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'keyLabel',
      align: 'center',
      render: renderIndex,
    },
    {
      title: 'Diễn Giải',
      dataIndex: 'name',
      with: 140,
      render: renderName,
    },
    {
      title: 'Thu Lại Khách Hàng',
      dataIndex: 'codCustomerPrice',
      align: 'center',
      render: renderCODPrice,
    },
    {
      title: 'Chi Phí',
      dataIndex: 'normalPrice',
      align: 'center',
      render: renderTotalPrice,
    },
    {
      title: 'Thành Tiền',
      dataIndex: 'total',
      align: 'center',
      render: renderTotal,
    },
  ];

  const handleSummary = (pageData) => {
    let sumTotalCodCustomerPrice = 0;
    let sumTotalNormalPrice = 0;
    let sumTotal = 0;

    pageData.forEach((page) => {
      sumTotalCodCustomerPrice += page?.codCustomerPrice ? page?.codCustomerPrice : 0;
      sumTotalNormalPrice += page?.normalPrice ? page?.normalPrice : 0;
      sumTotal += page?.totalAll ? page?.totalAll : 0;
    });
    return (
      <>
        <Table.Summary.Row
          style={{
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '13px',
          }}
        >
          <Table.Summary.Cell index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Text type='danger' strong>
              Tổng Cộng:
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2}>
            <Text type='danger' strong>
              {Utils.formatNumber(sumTotalCodCustomerPrice)}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            <Text type='danger' strong>
              {Utils.formatNumber(sumTotalNormalPrice)}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            <Text type='danger' strong>
              {Utils.formatNumber(sumTotal)}
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const renderRowClassName = (record) => {
    if (record?.key === 'parent') {
      return 'task-job-daily-background';
    }
    return '';
  };

  const renderFooter = () => {
    return (
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          fontSize: '17px',
        }}
      >
        <b>Chi Phí: {Utils.formatNumber(totalPriceCod + totalPriceInit)}</b>
      </div>
    );
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
        bordered={true}
        rowKey='keyMap'
        size='small'
        summary={handleSummary}
        rowClassName={renderRowClassName}
        footer={renderFooter}
      />
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            marginLeft: 'auto',
          }}
        >
          <p
            style={{
              marginRight: '100px',
            }}
          >
            Ngày {moment().format('LL')}
          </p>
          <p
            style={{
              textAlign: 'center',
              marginRight: '60px',
            }}
          >
            Lập Biểu
          </p>
        </div>
      </div>
    </>
  );
};

export { TableContent };
