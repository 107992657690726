import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
export default function ({ loading }) {
  if (!loading) return null;
  return (
    <LoadingView>
      <LoadingOutlined spin style={{ fontSize: 24 }} />
    </LoadingView>
  );
}
const LoadingView = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
`;
