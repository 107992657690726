import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';
import { Selection } from '~components';

const ReplacementCategoryForm = ({
  fields,
  isNew,
  id,
  reload,
  onChange,
  setLoading,
  dataSelected,
}) => {
  const [companies, setCompanies] = useState([]);

  const getCompanies = async (query = '') => {
    const newQuery = {
      Columns: {
        name: query,
      },
    };
    try {
      const res = await ApiServices.getCompanies(query ? newQuery : null);
      if (res?.data?.data) {
        setCompanies(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const createReplacementCategory = async (data) => {
    try {
      const res = await ApiServices.createReplacementCategory(data);
      if (res.data) {
        message.success('Thêm mới hạng mục thay thế thành công');
        return reload();
      } else {
        message.error('Thêm mới hạng mục thay thế thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới hạng mục thay thế thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const updateReplacementCategory = async (data) => {
    data.id = id;
    try {
      const res = await ApiServices.updateReplacementCategory(id, data);
      if (res.data) {
        message.success('Cập nhật hạng mục thay thế thành công');
        return reload();
      } else {
        message.error('Cập nhật hạng mục thay thế thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật hạng mục thay thế thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createReplacementCategory(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updateReplacementCategory(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      name='global_state'
      className='form-input-data'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        // {...itemLayout}
        style={{ marginTop: '10px' }}
        name='name'
        label='Tên Hạng Mục'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Tên Hạng Mục',
          },
        ]}
      >
        <Input placeholder='Tên Hạng Mục' />
      </Form.Item>

      <Form.Item
        style={{ marginTop: '10px' }}
        name='warningName'
        label='Tên Cảnh Báo'
        rules={[
          {
            required: true,
            message: 'bạn phải nhập Tên Cảnh Báo',
          },
        ]}
      >
        <Input placeholder='Tên Cảnh Báo' />
      </Form.Item>
      <Form.Item
        name='companyId'
        label='Công Ty'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn Công Ty',
          },
        ]}
      >
        <Selection
          labelDefault={dataSelected?.companyName || ''}
          data={companies}
          placeholder='Bạn phải chọn Công Ty'
          fieldValue='name'
          onSearch={getCompanies}
        />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { ReplacementCategoryForm };
