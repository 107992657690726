import { useState, useEffect } from 'react';
import { Table, Input } from 'antd';
import { TableTitle } from '~commons/styles';

const JobListOfCar = ({ dataJobs = [], setJobSelected, onColumnSearchJob, paginationJobList }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [dataJobs]);

  const handleSelectRow = (record) => {
    if (record) {
      setSelectedRowKeys([record?.key]);
      setJobSelected(record);
    }
  };

  const renderRow = (value, record) => {
    return (
      <div
        style={{
          cursor: 'pointer',
        }}
        onClick={() => handleSelectRow(record)}
      >
        {value}
      </div>
    );
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Công việc
          <Input name='name' onChange={onColumnSearchJob} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'name',
      align: 'center',
      render: renderRow,
    },
    {
      title: (
        <TableTitle>
          Số Cont
          <Input name='contNumber' onChange={onColumnSearchJob} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'contNumber',
      align: 'center',
      render: renderRow,
    },
    {
      title: (
        <TableTitle>
          Booking/Bill
          <Input name='orderType' onChange={onColumnSearchJob} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'orderType',
      align: 'center',
      render: renderRow,
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (rowKey, selectedRows) => {
      setSelectedRowKeys(rowKey);
      selectedRows?.[0] && setJobSelected(selectedRows?.[0]);
    },
  };
  return (
    <div className='table-container-custom'>
      <Table
        bordered
        size='small'
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        rowKey='jobId'
        columns={columns}
        dataSource={dataJobs}
        scroll={{
          x: '100%',
          y: 'calc(100vh - 535px)',
        }}
        pagination={paginationJobList}
      />
    </div>
  );
};
export { JobListOfCar };
