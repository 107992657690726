import React, { useState } from 'react';
import { Form, Button, DatePicker, InputNumber, Input } from 'antd';
import { layout, tailLayout } from './constant';
import { DATE_FORMAT } from '~commons/constants';
import ApiServices from '~services';
import { DATE_FORMAT_SEND_API } from '~commons/constants';
import moment from 'moment';

const FormInput = ({ fields, isNew, id, reload, onChange, setLoading }) => {
  const [form] = Form.useForm();
  const [dateCheck, setDateCheck] = useState(null);

  const createDriver = async (data) => {
    data.inputDate = moment(data.inputDate).format(DATE_FORMAT_SEND_API);

    try {
      const res = await ApiServices.createOilInput(data);
      if (res.data) {
        window.TmsNoti?.success('Thêm mới thành công');
        return reload();
      } else {
        window.TmsNoti?.error('Thêm mới thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      window.TmsNoti?.error('Thêm mới thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const updateDriver = async (data) => {
    data.id = id;
    data.inputDate = moment(data.inputDate).format(DATE_FORMAT_SEND_API);
    try {
      const res = await ApiServices.updateOilInput(id, data);
      if (res.data) {
        window.TmsNoti?.success('Cập nhật thành công');
        return reload();
      } else {
        window.TmsNoti?.error('Cập nhật thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      window.TmsNoti?.error('Cập nhật thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createDriver(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updateDriver(value);
    setLoading(false);
  };

  const handleChangeDate = async (date) => {
    setDateCheck(date);
    if (!date) {
      form.setFieldsValue({
        inputAmount: 0,
      });
      return;
    }
    const dateSend = moment(date).format(DATE_FORMAT_SEND_API);
    try {
      const res = await ApiServices.getOilPriceByDate(dateSend);
      if (res?.data?.data) {
        form.setFieldsValue({
          oilPrice: res?.data?.data,
        });
      } else {
        form.setFieldsValue({
          oilPrice: 0,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Form
      {...layout}
      form={form}
      name='global_state'
      className='form-input-data'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        style={{ marginTop: '10px' }}
        name='inputDate'
        label='Ngày Nhập Dầu'
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập Ngày Nhập Dầu',
          },
        ]}
      >
        <DatePicker
          placeholder='Ngày Nhập Dầu'
          style={{ width: '100%' }}
          format={DATE_FORMAT}
          value={dateCheck}
          onChange={handleChangeDate}
        />
      </Form.Item>
      <Form.Item name='oilPrice' label='Giá Dầu'>
        <InputNumber style={{ width: '100%' }} placeholder='Giá Dầu' disabled />
      </Form.Item>
      <Form.Item
        name='inputAmount'
        label='Số Lượng'
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn Số Lượng',
          },
          {
            type: 'number',
            min: 1,
            message: 'Số lượng phải lớn hơn 0',
          },
        ]}
      >
        <InputNumber min={1} style={{ width: '100%' }} placeholder='Số Lượng' />
      </Form.Item>
      <Form.Item name='note' label='Ghi Chú'>
        <Input.TextArea placeholder='Ghi Chú' />
      </Form.Item>

      <Form.Item {...tailLayout} className='submit-form-input-data'>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { FormInput };
