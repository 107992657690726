import storage from '~storage';
import { BillForm } from './bill-form';
import { BookingForm } from './booking-form';
import { TransitForm } from './transit-form';

const RenderInformation = ({
  type,
  fields,
  setFields,
  setIsLoadingTab,
  formRef,
  onsubmit,
  orderDetail,
}) => {
  const orderTypes = storage.staticVariables.OrderType;
  switch (type) {
    case orderTypes.Order_Bill:
      return (
        <BillForm
          onsubmit={onsubmit}
          fields={fields}
          ref={formRef}
          orderDetail={orderDetail}
          setLoading={setIsLoadingTab}
          onChange={(newFields) => {
            setFields(newFields);
          }}
        />
      );

    case orderTypes.Order_Transit:
      return (
        <TransitForm
          onsubmit={onsubmit}
          fields={fields}
          ref={formRef}
          orderDetail={orderDetail}
          setLoading={setIsLoadingTab}
          onChange={(newFields) => {
            setFields(newFields);
          }}
        />
      );
  }
  return (
    <BookingForm
      onsubmit={onsubmit}
      ref={formRef}
      fields={fields}
      orderDetail={orderDetail}
      setLoading={setIsLoadingTab}
      onChange={(newFields) => {
        setFields(newFields);
      }}
    />
  );
};

export { RenderInformation };
