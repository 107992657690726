import { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Form, Input, Select, Divider, Row, Col, DatePicker } from 'antd';
import ApiServices from '~services';
import storage from '~storage';
import { Selection } from '~components';
import moment from 'moment';
// import { useExistsCodeValidator } from '~hooks';
import { DATE_TIME_FORMAT } from '~commons/constants';

const { Option } = Select;

const AUTO_FIELD_NAME = {
  KEY: 'transitNumber',
  GENERATE: 'customerCode',
  DATE: 'transitDate',
};
// const DATE_GENERATED = moment().format('DDMMYYYY');
export const TransitForm = forwardRef(
  (
    {
      fields,
      onChange,
      onsubmit,
      orderDetail,
      isNew = false,
      showDetail = false,
      dataOrderDetail = null,
    },
    ref
  ) => {
    const inputRef = useRef(null);
    const [locations, setLocations] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [cargoTypes, setCargoTypes] = useState([]);
    const [customerSelected, setCustomerSelected] = useState(null);
    const [transitDate, setTransitDate] = useState(null);

    const onCompareWithApi = (value, cb) => {
      if (inputRef.current) {
        clearTimeout(inputRef.current);
        cb();
      }
      inputRef.current = setTimeout(async () => {
        try {
          const { data } = await ApiServices.checkOrderExistCode(value);
          const isUsed = data;
          cb(isUsed);
        } catch (error) {
          console.error(error);
        }
      }, 1000);
    };

    const checkExistsCode = async (_, valueInput) => {
      const value = valueInput.trim();
      if (value.length > 2) {
        if (isNew === false) {
          if (dataOrderDetail) {
            const ind = fields.findIndex((item) => {
              return item.name[0] === 'code';
            });
            if (value !== fields[ind]?.value) {
              try {
                const waitMe = () =>
                  new Promise((resolve, reject) => {
                    onCompareWithApi(value, (isUsed) => {
                      if (typeof isUsed !== 'undefined') {
                        if (isUsed) reject();
                        resolve();
                      }
                    });
                  });
                await waitMe();
              } catch (error) {
                return Promise.reject(new Error('Mã Số Transit này đã tạo rồi !!!'));
              }
            }
          }
          if (orderDetail) {
            if (value !== orderDetail?.code) {
              try {
                const waitMe = () =>
                  new Promise((resolve, reject) => {
                    onCompareWithApi(value, (isUsed) => {
                      if (typeof isUsed !== 'undefined') {
                        if (isUsed) reject();
                        resolve();
                      }
                    });
                  });
                await waitMe();
              } catch (error) {
                return Promise.reject(new Error('Mã Số Transit này đã tạo rồi !!!'));
              }
            }
          }
        }

        if (isNew === true) {
          try {
            const waitMe = () =>
              new Promise((resolve, reject) => {
                onCompareWithApi(value, (isUsed) => {
                  if (typeof isUsed !== 'undefined') {
                    if (isUsed) reject();
                    resolve();
                  }
                });
              });
            await waitMe();
          } catch (error) {
            return Promise.reject(new Error('Mã Số Transit này đã tạo rồi !!!'));
          }
        }
      }
    };

    const [form] = Form.useForm();
    window.TransitForm = form;
    useImperativeHandle(ref, () => ({
      submitForm() {
        form.submit();
      },
    }));

    const getCustomers = async (query = null) => {
      try {
        const { data } = await ApiServices.getCustomers({ query });
        setCustomers(data);
      } catch (err) {
        console.error('ERR - Get Customers', err);
      }
    };
    const getLocations = async (Query = null) => {
      try {
        const { data } = await ApiServices.getLocations({ Query });
        setLocations(data.data);
      } catch (err) {
        console.error('ERR - Get Locations', err);
      }
    };
    const getCargoTypes = async (Query = null) => {
      try {
        const { data } = await ApiServices.getCargoTypes({ Query });
        if (data?.data) {
          const dataFind = data?.data?.find((item) => item?.id === '60b4b462ef42f1213378e623');
          if (dataFind) {
            const dataFilter = data?.data?.filter((el) => el?.id !== '60b4b462ef42f1213378e623');
            dataFilter.unshift(dataFind);
            setCargoTypes(dataFilter || []);
          } else {
            setCargoTypes(data?.data);
          }
        }
      } catch (err) {
        console.error('ERR - Get CargoTypes', err);
      }
    };

    useEffect(() => {
      getCustomers();
      getLocations();
      getCargoTypes();
    }, []);

    const onSubmitForm = async () => {
      onsubmit();
    };

    const transportType = storage.staticVariables.TransportType;
    const transportTypeDiv = (
      <Select placeholder='Loại vận chuyển' showSearch>
        {Object.keys(transportType).map((item) => (
          <Option key={item} value={transportType[item]}>
            {transportType[item]}
          </Option>
        ))}
      </Select>
    );
    const onFieldsChange = (_, allFields) => {
      onChange(allFields);
    };

    const handleGenerateCode = async (objCustomer, transitDateSelected) => {
      const transitDateString = moment(transitDateSelected).format('YYYY-MM-DD');
      const dateGenerated = moment(transitDateSelected).format('DDMMYYYY');
      const shortName = objCustomer?.data?.shortName?.toUpperCase();
      const customerId = objCustomer.value;

      return ApiServices.getCountCodeTransit(transitDateString)
        .then((res) => {
          if (res?.data?.data) {
            let countFormat = '';
            if (res?.data?.data < 10) {
              countFormat = `00${res?.data?.data}`;
            } else if (res?.data?.data < 100) {
              countFormat = `0${res?.data?.data}`;
            } else {
              countFormat = `${res?.data?.data}`;
            }
            return onChange([
              ...fields,
              {
                name: [AUTO_FIELD_NAME.GENERATE],
                value: customerId,
              },
              {
                name: [AUTO_FIELD_NAME.DATE],
                value: transitDateSelected,
              },
              {
                name: [AUTO_FIELD_NAME.KEY],
                value: `${shortName}/${dateGenerated}/${countFormat}CK`,
              },
            ]);
          }
        })
        .catch((err) => {
          console.log('Get Count Code Transit err: ', err);
        });
    };

    const onCustomerChange = async (_, obj) => {
      setCustomerSelected(obj);
      if (transitDate) {
        return handleGenerateCode(obj, transitDate);
      }
    };

    const handleChangeTransitDate = async (date) => {
      setTransitDate(date);
      if (customerSelected) {
        return handleGenerateCode(customerSelected, date);
      }
    };

    return (
      <Form
        layout='vertical'
        name='global_state'
        className='form-booking'
        form={form}
        onFinish={onSubmitForm}
        fields={fields}
        onFieldsChange={onFieldsChange}
      >
        <h2 style={{ marginTop: '-10px' }}>
          {orderDetail ? (showDetail ? 'Xem chi tiết' : 'Cập nhật ') : 'Tạo '} Đơn hàng Nội địa
        </h2>
        <Divider style={{ margin: '4px 0 8px 0' }} />
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name='customerCode'
              label='Khách hàng'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập Mã Khách hàng',
                },
              ]}
            >
              <Selection
                data={customers}
                onChange={onCustomerChange}
                labelDefault={dataOrderDetail ? '' : orderDetail?.customerName}
                placeholder='Mã Khách hàng'
                onSearch={getCustomers}
                allowClear={false}
                disabled={orderDetail}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='transitDate'
              label='Ngày chuyển kho'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập Ngày Chuyển Kho',
                },
              ]}
            >
              <DatePicker
                placeholder='Ngày chuyển kho'
                style={{ width: '100%' }}
                showTime={{ format: 'HH:mm' }}
                format={DATE_TIME_FORMAT}
                allowClear={false}
                onChange={handleChangeTransitDate}
                disabled={orderDetail}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='transitNumber'
              label='Số Phiếu CK'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập Số Phiếu CK',
                },
                {
                  validator: checkExistsCode,
                },
              ]}
            >
              <Input placeholder='Số Phiếu CK' disabled={orderDetail} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='transportType' label='Loại Vận Chuyển'>
              {transportTypeDiv}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='locationOfReceiptCode'
              label='Nơi Lấy Hàng'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập Nơi Lấy Hàng',
                },
              ]}
            >
              <Selection
                data={locations}
                labelDefault={
                  dataOrderDetail
                    ? dataOrderDetail
                      ? dataOrderDetail?.locationOfReciptName
                      : ''
                    : orderDetail
                    ? orderDetail?.locationOfReciptName
                    : ''
                }
                placeholder='Nơi Lấy Hàng'
                onSearch={getLocations}
                allowClear={false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='locationOfDeliveryCode'
              label='Nơi Giao Hàng'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập Nơi Giao Hàng',
                },
              ]}
            >
              <Selection
                data={locations}
                labelDefault={
                  dataOrderDetail
                    ? dataOrderDetail
                      ? dataOrderDetail?.locationOfDeliveryName
                      : ''
                    : orderDetail
                    ? orderDetail?.locationOfDeliveryName
                    : ''
                }
                placeholder='Nơi Giao Hàng'
                onSearch={getLocations}
                allowClear={false}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name='cargoTypeCode' label='Loại Hàng Hóa'>
              <Selection
                data={cargoTypes}
                placeholder='Loại Hàng Hóa'
                fieldValue='name'
                onSearch={getCargoTypes}
                autoSelectFirstOption
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name='note' label='Ghi chú'>
              <Input.TextArea placeholder='Ghi chú' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
);
