import React, { useState } from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import { TableTitle } from '~commons/styles';
import { BtnConfirm } from '~components';
import Utils from '~commons/utils';

const ViewAuthority = ({ dataView, deleteItem, deleteItemAll, loading }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const columns = [
    {
      title: <TableTitle>#</TableTitle>,
      dataIndex: 'key',
      width: 250,
      // align: 'center',
      render: (_, record) =>
        record.customerName ? (
          <div
            style={{
              margin: 'auto',
              textAlign: 'right',
            }}
          >
            <b className='red'>{record.keyChild}. </b>
          </div>
        ) : (
          <b className='red'>
            Đối tác: <span className='blue'>{record.groupName}</span> ({record.children?.length})
          </b>
        ),
      onCell: (record) => {
        if (record.groupCar) {
          return {
            colSpan: 3,
          };
        }
      },
    },
    {
      title: <TableTitle>Khách hàng</TableTitle>,
      dataIndex: 'customerName',
      align: 'center',
    },
    {
      title: <TableTitle>Ngày Set</TableTitle>,
      dataIndex: 'createdOn',
      width: 100,
      align: 'center',
      render: (val) => val && Utils.formatDate(val),
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 75,
      render: (record) =>
        record?.groupName ? (
          record.children?.length ? (
            <BtnConfirm onConfirm={() => deleteItemAll(record)}>Xóa tất cả</BtnConfirm>
          ) : (
            <></>
          )
        ) : (
          <BtnConfirm onConfirm={() => deleteItem(record)}>Xóa</BtnConfirm>
        ),
    },
  ];

  const expandable = { expandedRowKeys, onExpandedRowsChange: setExpandedRowKeys };

  const groupByPartner = dataView?.map((item, index) => {
    return {
      id: item?.id,
      key: index + 1,
      groupName: item?.name,
      children: item?.customers?.length
        ? item?.customers?.map((el, indexChild) => {
            return {
              ...el,
              keyChild: indexChild + 1,
              partnerId: item?.childId,
            };
          })
        : [],
    };
  });

  return (
    <>
      <TableStyled
        loading={loading}
        columns={columns}
        dataSource={groupByPartner || []}
        expandable={expandable}
        rowKey='id'
        size='small'
        bordered
      />
    </>
  );
};

const TableStyled = styled(Table)`
  & .ant-table-row-indent.indent-level-1 {
    display: none;
  }
  & button.ant-table-row-expand-icon-spaced {
    display: none;
  }
`;

export { ViewAuthority };
