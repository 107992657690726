import { useState } from 'react';
import styled from 'styled-components';
import { color } from '~theme';
import { Selection } from '../selection';

const PLACEHOLDER = 'Thêm vị trí';

export default function ({ value = null, labelDefault, locations, onSearch, onChange }) {
  const [visible, setVisible] = useState(false);
  const [val, setVal] = useState(value);
  return (
    <Wrapper onClick={() => setVisible(true)}>
      {!visible ? (
        labelDefault ? (
          <Text>{labelDefault}</Text>
        ) : (
          <TextWrapper>{labelDefault || PLACEHOLDER} </TextWrapper>
        )
      ) : (
        <Selection
          onBlur={() => setVisible(false)}
          data={locations}
          placeholder={PLACEHOLDER}
          onSearch={onSearch}
          labelDefault={labelDefault}
          width='160px'
          onChange={(val) => {
            setVal(val);
            val && onChange(val);
          }}
          value={val}
          showSearch
          autoFocus
        />
      )}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  cursor: text;
  min-height: 40px;
  z-index: 1;
`;
const TextWrapper = styled.div`
  display: none;
  font-size: 14px;
  align-items: center;
  ${Wrapper}:hover & {
    display: flex;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
  }
`;
const Text = styled.span`
  cursor: pointer;
  flex: 1;
  display: block;
  border-radius: 8px;
  padding: 0px;
  color: ${color.link};
  // color: #f806cc;
`;
