export const KEYS = ['code', 'name', 'desc'];
export const formatFields = (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });

  return result;
};
