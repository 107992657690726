import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Select } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';
import { Selection } from '~components';
const { Option } = Select;

const UserAppForm = ({ fields, reload, onChange, setLoading, dataSelected }) => {
  const [driversMain, setDriversMain] = useState([]);
  const [isDriver, setIsDriver] = useState(false);
  const [infoDriverFields, setInfoDriverFields] = useState({});
  const [driverId, setDriverId] = useState('');
  const [groups, setGroups] = useState([]);
  const [companies, setCompanies] = useState([]);

  const getDataInit = async () => {
    const [getGroup, getDriversMain] = await Promise.all([
      ApiServices.getGroups(),
      ApiServices.getListToCreateAccountDriver(),
    ]);
    setGroups(getGroup.data);
    setDriversMain(getDriversMain.data);
  };

  const getCompanies = async (query = '') => {
    const newQuery = {
      Columns: {
        name: query,
      },
    };
    try {
      const res = await ApiServices.getCompanies(query ? newQuery : null);
      if (res?.data?.data) {
        setCompanies(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataInit();
    getCompanies();
  }, []);

  const createUserApp = async (data) => {
    data.driverId = driverId;
    data.isDriver = true;
    data.password = 'u&i_tms';
    data.vendorId = [data.vendorId];
    try {
      const res = await ApiServices.createUser(data);
      if (res.data) {
        message.success('Thêm mới Người Dùng thành công');
        onBack();
        return reload();
      } else {
        message.error('Thêm mới Người Dùng thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới Người Dùng thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    setLoading(true);
    await createUserApp(value);
    setLoading(false);
  };

  const selectDriversMain = async (value) => {
    const id = value.driverMainCode;
    try {
      const { data } = await ApiServices.getDriverId(null, id);
      if (data) {
        const infoFields = [
          {
            name: ['code'],
            value: data.phone,
            error: [],
          },
          {
            name: ['fullName'],
            value: data.name,
            error: [],
          },
          {
            name: ['email'],
            value: data.email || '',
            error: [],
          },
          {
            name: ['companyId'],
            value: data.companyId || '',
            error: [],
          },
          // {
          //   name: ["vendorId"],
          //   value: null ,
          //   error: []
          // },
        ];

        setInfoDriverFields(infoFields);
        setDriverId(data.id);
        setIsDriver(true);
        message.success('Chọn Tài Xế thành công');
      }
    } catch (err) {
      message.error('Chọn Tài Xế thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };

  const onBack = () => {
    setInfoDriverFields([]);
    setIsDriver(false);
  };

  return (
    <div>
      {!isDriver ? (
        <Form
          {...layout}
          name='global_state'
          onFinish={selectDriversMain}
          fields={fields}
          onFieldsChange={(_, allFields) => {
            onChange(allFields);
          }}
        >
          <Form.Item
            name='driverMainCode'
            label='Tên Tài Xế'
            rules={[
              {
                required: true,
                message: 'Bạn phải chọn Tài Xế',
              },
            ]}
          >
            <Select
              showSearch
              placeholder='Lựa Chọn Tài Xế'
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {driversMain.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type='primary' htmlType='submit'>
              Chọn Tài Xế
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Form
          {...layout}
          name='global_state'
          onFinish={onSubmitForm}
          fields={infoDriverFields}
          onFieldsChange={(_, allFields) => {
            onChange(allFields);
          }}
        >
          <Form.Item
            // {...itemLayout}
            style={{ marginTop: '10px' }}
            name='code'
            label='Tên Đăng Nhập'
            rules={[
              {
                required: true,
                message: 'Tên Đăng Nhập Không Được Để Trống',
              },
            ]}
          >
            <Input disabled placeholder='Tên Đăng Nhập' />
          </Form.Item>

          <Form.Item
            style={{ marginTop: '10px' }}
            name='fullName'
            label='Họ Tên'
            rules={[
              {
                required: true,
                message: 'Họ Tên Không Được Để Trống',
              },
            ]}
          >
            <Input disabled placeholder='Họ Tên' />
          </Form.Item>
          <Form.Item
            name='email'
            label='Email'
            rules={[
              {
                type: 'email',
                message: 'Email không hợp lệ',
              },
            ]}
          >
            <Input placeholder='Email' />
          </Form.Item>
          <Form.Item
            name='arrMemberGroup'
            label='Nhóm Thành Viên'
            rules={[
              {
                required: true,
                message: 'Bạn phải chọn Nhóm Thành Viên',
              },
            ]}
          >
            <Select mode='multiple' showArrow showSearch placeholder='Nhóm Thành Viên'>
              {groups.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name='companyId' label='Công Ty'>
            <Selection
              labelDefault={dataSelected?.companyName || ''}
              data={companies}
              placeholder='Bạn phải chọn Công Ty'
              fieldValue='name'
              onSearch={getCompanies}
            />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type='primary' onClick={onBack}>
              Quay Lại
            </Button>
            <Button type='primary' htmlType='submit' className='mg-l-10'>
              Thêm Mới
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export { UserAppForm };
