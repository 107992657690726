import React, { useState, useEffect, useCallback } from 'react';
import { Space, Select, Badge, Input, DatePicker, message, Button } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import moment from 'moment';
import { debounce } from 'lodash';
import { Wrapper, TableTitle } from '~commons/styles';
import { TableLayout, RangeDateFilter, BtnConfirm } from '~components';
import Utils from '~commons/utils';
import ApiServices from '~services';
import { DATE_FORMAT } from '~commons/constants';
import { ContFiles } from '~components/cont-files';
import { ContStatus } from '~components/common-status';

const { Option } = Select;
const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().subtract(6, 'days').format('YYYY-MM-DD'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD'),
};

const ORDERS_OPTION_SELECTS = [
  {
    label: 'Tất cả',
    value: '',
  },
  {
    label: 'Booking',
    value: 'Booking',
  },
  {
    label: 'Bill',
    value: 'Bill',
  },
];

const statusTag = (text = '', bgColor = '') => {
  return (
    <Badge>
      <div
        style={{
          backgroundColor: bgColor,
          width: '100px',
          padding: '5px 10px',
          textAlign: 'center',
          borderRadius: '4px',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        {text}
      </div>
    </Badge>
  );
};

export const TaskJobsByPartner = () => {
  const [loading, setLoading] = useState(false);
  const [ordersFromWMS, setOrdersFromWMS] = useState([]);
  const [partners, setPartners] = useState([]);
  const [partnerSelectedId, setPartnerSelectedId] = useState(null);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);

  const [assignDateText, setAssignDateText] = useState(null);
  const [bookingSIDateTime, setBookingSIDateTime] = useState(null);
  const [bookingCutOffDateTime, setBookingCutOffDateTime] = useState(null);
  const [bill_LastFreeDayDEM, setBill_LastFreeDayDEM] = useState(null);
  const [bill_LastFreeDayDET, setBill_LastFreeDayDET] = useState(null);

  const [orderTypeSearch, setOrderTypeSearch] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);

  const getOrdersFromWMS = async (partnerId, query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getTaskJobsByPartner(partnerId, newQuery)
      .then((res) => {
        if (res?.data?.data) {
          const mapData = res?.data?.data?.map((item, index) => {
            item.key = item?.id + item?.subnoNumber + index;
            // item.id = item?.contId + item?.customerId + index;
            return item;
          });

          setTotalRecords(res?.data?.totalRecords || 0);
          setOrdersFromWMS(mapData || []);
        }
      })
      .catch((err) => {
        console.error('Get Task Jobs By Partner Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPartners = async (query = '') => {
    return ApiServices.getPartnerByCompany({ query })
      .then(({ data }) => {
        setPartners(data?.data || []);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getPartners();
  }, []);

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((partnerId, newFilter) => {
      getOrdersFromWMS(partnerId, newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    if (partnerSelectedId) {
      timeoutGetData(partnerSelectedId, filter);
    }
  }, [filter, partnerSelectedId]);

  const handleChangeAssignDateText = (date) => {
    setAssignDateText(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'assignDate');
  };

  const handleChangeBookingSIDateTime = (date) => {
    setBookingSIDateTime(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'bookingSIDateTime');
  };

  const handleChangeBookingCutOffDateTime = (date) => {
    setBookingCutOffDateTime(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'bookingCutOffDateTime');
  };

  const handleChangeBill_LastFreeDayDEM = (date) => {
    setBill_LastFreeDayDEM(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'bill_LastFreeDayDEM');
  };

  const handleChangeBill_LastFreeDayDET = (date) => {
    setBill_LastFreeDayDET(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'bill_LastFreeDayDET');
  };

  const handleChangeOrderType = (value) => {
    setOrderTypeSearch(value);
    onColumnSearch(value, 'orderType');
  };

  const renderTyOrder = (value) => {
    switch (value) {
      case 'Booking':
        return statusTag('Booking', 'green');
      case 'Bill':
        return statusTag('Bill', '#EB455F');

      default:
        break;
    }
  };

  const deleteJobByPartnerSuccess = async (record) => {
    if (!record?.id) return;
    try {
      const res = await ApiServices.deleteTransferBackJob(record?.id);
      if (res?.data?.data === 'success') {
        message.success('Xoá thành công');
        return getOrdersFromWMS(partnerSelectedId, filter);
      }
      message.error('Xoá thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportExcel = async (id, query) => {
    if (!query || loading) return;

    const dateFrom = moment(query?.DateFrom).format('YYYY-MM-DD');
    const dateTo = moment(query?.DateTo).format('YYYY-MM-DD');

    const dataSend = {
      DateFrom: query?.DateFrom && moment(query?.DateFrom).format('YYYY-MM-DD'),
      DateTo: query?.DateTo && moment(query?.DateTo).format('YYYY-MM-DD'),
      Columns: query?.Columns,
      export: true,
    };

    const partnerName = partners.find((item) => item.id === id);
    setLoading(true);
    try {
      const { data } = await ApiServices.exportTaskJobByPartner(id, dataSend);
      if (data) {
        const outputFilename = `${partnerName?.title}_Tu_ngay_${dateFrom}_den_ngay_${dateTo}.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoading(false);
    }
  };
  const columns = [
    {
      title: (
        <TableTitle>
          Tên Khách Hàng
          <Input name='customerName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'customerName',
      width: 180,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Subno Number
          <Input name='subnoNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'subnoNumber',
      width: 180,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Tên công việc
          <Input name='name' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'name',
      width: 120,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Loại ĐH
          <Select
            placeholder='Search'
            style={{ width: 150 }}
            defaultValue=''
            value={orderTypeSearch}
            onChange={handleChangeOrderType}
          >
            {ORDERS_OPTION_SELECTS.map((item) => (
              <Option key={item.label + item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </TableTitle>
      ),
      dataIndex: 'orderType',
      width: 170,
      align: 'center',
      render: renderTyOrder,
    },
    {
      title: (
        <TableTitle>
          Số Cont
          <Input name='contNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'contNumber',
      width: 130,
      align: 'center',
    },
    {
      title: <TableTitle>File Đính kèm</TableTitle>,
      dataIndex: 'contFiles',
      width: 240,
      render: (val) => <ContFiles files={val} />,
    },
    {
      title: (
        <TableTitle>
          Số Seal
          <Input name='sealNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'sealNumber',
      width: 130,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Điểm bắt đầu
          <Input name='beginLocation' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'beginLocation',
      width: 180,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Điểm kết thúc
          <Input name='endLocation' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'endLocation',
      width: 180,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Ngày điều xe
          <DatePicker
            value={assignDateText}
            onChange={handleChangeAssignDateText}
            placeholder='Ngày điều xe'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'assignDate',
      width: 130,
      align: 'center',
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          Cắt SI
          <DatePicker
            value={bookingSIDateTime}
            onChange={handleChangeBookingSIDateTime}
            placeholder='Cắt SI'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'bookingSIDateTime',
      width: 130,
      align: 'center',
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          Cắt Máng
          <DatePicker
            value={bookingCutOffDateTime}
            onChange={handleChangeBookingCutOffDateTime}
            placeholder='Cắt Máng'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'bookingCutOffDateTime',
      width: 130,
      align: 'center',
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          HH Lưu Cont
          <DatePicker
            value={bill_LastFreeDayDEM}
            onChange={handleChangeBill_LastFreeDayDEM}
            placeholder='HH Lưu Cont'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'bill_LastFreeDayDEM',
      width: 130,
      align: 'center',
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          HH Trả Rỗng
          <DatePicker
            value={bill_LastFreeDayDET}
            onChange={handleChangeBill_LastFreeDayDET}
            placeholder='HH Trả Rỗng'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'bill_LastFreeDayDET',
      width: 130,
      align: 'center',
      render: (value) => Utils.formatDate(value),
    },
    {
      title: <TableTitle>Trạng Thái</TableTitle>,
      dataIndex: 'status',
      width: 100,
      align: 'center',
      fixed: 'right',
      render: (value) => <ContStatus status={value} />,
    },
    {
      title: '#',
      fixed: 'right',
      width: 75,
      render: (record) => {
        if (!record?.id) return <></>;
        if (record?.status === 9) {
          return <BtnConfirm onConfirm={() => deleteJobByPartnerSuccess(record)}>Xóa</BtnConfirm>;
        }
        return <></>;
      },
    },
  ];

  const handleChangeSelectedPartner = async (partnerId) => {
    if (partnerId) {
      setPartnerSelectedId(partnerId);
    } else {
      setPartnerSelectedId(null);
      setOrdersFromWMS([]);
    }
  };

  const headerContent = (
    <div>
      <h1>DANH SÁCH CÔNG VIỆC XE NGOÀI ĐÃ NHẬN: </h1>
      <Space>
        <br />
        <b>Đối tác: </b>
        <Select
          placeholder='Chọn đối tác'
          style={{ width: 300 }}
          allowClear={true}
          onChange={handleChangeSelectedPartner}
        >
          {partners?.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
        <RangeDateFilter query={filter} onChange={setFilter} />
        <Button
          type='primary'
          loading={loading}
          icon={<FileExcelOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px' }}
          disabled={!ordersFromWMS?.length}
          onClick={() => handleExportExcel(partnerSelectedId, filter)}
        >
          Xuất Excel
        </Button>
      </Space>
    </div>
  );
  const pagination = {
    total: totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <Wrapper>
      <TableLayout
        columns={columns}
        data={ordersFromWMS}
        rowKey='key'
        bordered
        loading={loading}
        headerContent={headerContent}
        pagination={pagination}
        isDisplayCheckbox={false}
      />
    </Wrapper>
  );
};
