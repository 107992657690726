import React from 'react';
import { Popover } from 'antd';

const Marker = ({ text = '', title = '' }) => {
  const content = (
    <div>
      <p>{text}</p>
    </div>
  );

  return (
    <Popover content={content} title={title}>
      <div className='google-marker'></div>
    </Popover>
  );
};

export { Marker };
