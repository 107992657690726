import React, { useState, useMemo } from 'react';
import { Table, Button, Modal } from 'antd';
import moment from 'moment';
import { TransitFeeMainForm } from './transit-fee-main-form';
import { BtnConfirm } from '~components';
import ApiServices from '~services';
import { DeleteOutlined } from '@ant-design/icons';

const RomoocFeeMain = ({
  dataFeeRomooc,
  setDataFeeRomooc,
  locationSelected,
  locationPackingList,
  isPartner,
  // contractStatus = 0,
  isNew,
  reloadAfterUpdate,
  valueDetail,
}) => {
  const [loadingAddNew, setLoadingAddNew] = useState(false);
  const [loadingRomooc, setLoadingRomooc] = useState(false);
  const [showCreateFeeMain, setShowCreateFeeMain] = useState(false);
  const [countShow, setCountShow] = useState(0);

  const isAddNewFee = useMemo(() => {
    return !locationSelected?.length > 0;
  }, [locationSelected]);

  const handleDelete = (keyId, valueId) => {
    let dataList = [...dataFeeRomooc];
    const ind = dataFeeRomooc?.findIndex((item) => item?.[keyId] === valueId);
    dataList.splice(ind, 1);
    setDataFeeRomooc(dataList);
  };

  const deleteFee = async (record) => {
    if (loadingRomooc) return;

    if (record?.idGenerate) {
      setLoadingRomooc(true);
      await handleDelete('idGenerate', record?.idGenerate);
      setLoadingRomooc(false);
    }
    if (record?.id) {
      setLoadingRomooc(true);
      return ApiServices.deleteRomoocFee(record?.id, isPartner)
        .then((res) => {
          if (res?.data?.data) {
            handleDelete('id', record?.id);
            window.TmsNoti.success('Thao tác thành công');
          }
        })
        .catch((err) => {
          console.log('Delete Romooc Fee Failed: ', err);
          window.TmsNoti.error('Thao tác thất bại');
        })
        .finally(() => setLoadingRomooc(false));
    }
  };

  const renderPrice = (value) => {
    return <span>{`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>;
  };

  const columns = [
    {
      title: 'Nơi đóng hàng',
      dataIndex: 'locationName',
      width: 180,
      fixed: true,
    },
    {
      title: 'Phí neo Romooc/Ngày',
      dataIndex: 'price',
      width: 80,
      fixed: true,
      align: 'center',
      render: renderPrice,
    },
    {
      title: 'Số ngày neo miễn phí',
      dataIndex: 'freeDate',
      width: 80,
      fixed: true,
      align: 'center',
    },
    {
      title: '#',
      fixed: 'right',
      align: 'center',
      width: 75,
      render: (record) => (
        <BtnConfirm onConfirm={() => deleteFee(record)} icon={<DeleteOutlined />}>
          Xóa
        </BtnConfirm>
      ),
    },
  ];

  const handleSubmitAddNewByApi = async (dataEndNew) => {
    if (loadingAddNew) return;

    const romoocData = dataEndNew?.map((item) => {
      let dataRomooc = {
        locationId: item?.locationId,
        price: item?.price,
        freeDate: item?.freeDate,
        contractId: valueDetail?.contractId,
      };
      if (item?.id) {
        dataRomooc.id = item.id;
      }
      return dataRomooc;
    });

    const dataSend = {
      contractId: valueDetail?.contractId,
      contractNumber: valueDetail?.contractNumber,
      companyId: valueDetail?.companyId,
      customerId: valueDetail?.customerId,
      customerName: valueDetail?.customerName,
      contractDate:
        valueDetail?.contractDate && moment(valueDetail?.contractDate).format('YYYY-MM-DD'),
      expiredDate:
        valueDetail?.expiredDate && moment(valueDetail?.expiredDate).format('YYYY-MM-DD'),
      renewExpiredDate: null,
      locations: valueDetail.locations,
      arrLocations: valueDetail?.arrLocations,
      note: valueDetail?.note,
      partnerId: valueDetail?.partnerId,
      transport: valueDetail?.transport,
      transit: valueDetail?.transit,
      romoocs: romoocData?.length > 0 ? romoocData : null,
    };

    setLoadingAddNew(true);
    return ApiServices.updateContractForVendor(valueDetail?.contractId, dataSend)
      .then((res) => {
        if (res?.data?.data) {
          window.TmsNoti.success('Thêm phí neo Romooc thành công');
          return reloadAfterUpdate();
        } else {
          window.TmsNoti.error('Lỗi', 'Thêm phí neo Romooc thất bại');
        }
      })
      .catch((err) => {
        console.log({ err });
        window.TmsNoti.error('Lỗi', 'Thêm phí neo Romooc thất bại');
      })
      .finally(() => setLoadingAddNew(false));
  };
  const handleSubmitNoApi = (dataEndNew) => {
    setDataFeeRomooc(dataEndNew);
  };

  const handleSubmit = (data) => {
    let dataEnd = [...dataFeeRomooc];
    onCloseFormCreate();
    setCountShow(0);
    dataEnd.push(data);
    if (isNew) {
      return handleSubmitNoApi(dataEnd);
    } else {
      return handleSubmitAddNewByApi(dataEnd);
    }
  };
  // const handleSubmit = (data) => {
  //   let dataEnd = [...dataFeeRomooc];
  //   onCloseFormCreate();
  //   setCountShow(0);
  //   dataEnd.push(data);
  //   setDataFeeRomooc(dataEnd);
  // };

  const handleCreate = () => {
    setShowCreateFeeMain(true);
    setCountShow(countShow + 1);
  };
  const onCloseFormCreate = () => {
    setShowCreateFeeMain(false);
  };

  return (
    <div>
      {isAddNewFee && (
        <div
          style={{
            marginTop: '-10px',
          }}
        >
          <i className='red'>Bạn phải chọn nơi đóng hàng trước, để thêm phí neo Romooc.</i>
        </div>
      )}
      <Button
        type='primary'
        style={{
          margin: '5px 0px',
        }}
        onClick={handleCreate}
        disabled={isAddNewFee}
      >
        Thêm phí neo Romooc
      </Button>
      <Table
        bordered
        size='small'
        loading={false}
        columns={columns}
        dataSource={dataFeeRomooc}
        // key='locationId'
        rowKey={isNew ? 'idGenerate' : 'id'}
        scroll={{
          x: '100%',
          y: 600,
        }}
        pagination={false}
      />
      <Modal
        title='Thêm phí neo Romooc'
        open={showCreateFeeMain}
        onCancel={onCloseFormCreate}
        footer={null}
        // style={{
        //   top: 20,
        // }}
        // width='80%'
      >
        <TransitFeeMainForm
          countShow={countShow}
          locationSelected={locationSelected}
          handleSubmit={handleSubmit}
          dataFeeRomooc={dataFeeRomooc}
          locationPackingList={locationPackingList}
        />
      </Modal>
    </div>
  );
};

export { RomoocFeeMain };
