import React, { useState, useEffect, useMemo } from 'react';
import { Popconfirm, Button, Space, Badge } from 'antd';
import { Wrapper } from '~commons/styles';
import { TableLayout } from '~components';
import ApiServices from '~services';

const RELOAD_TIME = 60 * 1000;
const RELOAD_ON = true;

export const OrdersCancelFromONP = ({ headerLength = '220px', handleCloseModalInWhiteBoard }) => {
  const [loading, setLoading] = useState(false);
  const [ordersFromONP, setOrdersFromONP] = useState([]);
  const [selectedKey, setSelectedKey] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);

  const countSelected = useMemo(() => {
    return selectedKey?.length;
  }, [selectedKey]);

  const getOrdersCancelFromONP = async () => {
    setLoading(true);
    ApiServices.getOrdersCancelFromONP()
      .then((res) => {
        if (res?.data?.data) {
          setOrdersFromONP(res?.data?.data || []);
        }
      })
      .catch((err) => {
        console.error('Get Orders Error', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getOrdersCancelFromONP();
  }, []);

  useEffect(() => {
    const handleReload = setInterval(() => {
      RELOAD_ON && getOrdersCancelFromONP();
    }, RELOAD_TIME);

    return () => clearInterval(handleReload);
  }, []);

  const handleAcceptOrderFromONP = async (dataRow) => {
    setLoading(true);
    return ApiServices.importOrdersFromONPCancel(dataRow)
      .then((res) => {
        // TH 1: Tất cả đều import thất bại (data = null)
        if (!res?.data?.orders?.data) {
          window.TmsNoti?.error('Import không thành công');
          res?.data?.orders?.errors?.length > 0 &&
            window.TmsNoti?.error(
              'Lý do',
              res?.data?.orders?.errors?.map((item, index) => (
                <div key={index}>
                  <b className='red'>
                    {index + 1}
                    {')'}
                  </b>
                  {item}
                </div>
              )),
              20
            );
          handleCloseModalInWhiteBoard && handleCloseModalInWhiteBoard();
        }
        // TH2: import 10 item, thất bại 5, thành công 5
        if (res?.data?.orders?.data && res?.data?.orders?.errors?.length > 0) {
          window.TmsNoti?.error(res?.data?.orders?.data, res?.data?.orders?.message);
          window.TmsNoti?.error(
            'Lý do',
            res?.data?.orders?.errors?.map((item, index) => (
              <div key={index}>
                <b className='red'>
                  {index + 1}
                  {')'}
                </b>
                {item}
              </div>
            )),
            20
          );
          getOrdersCancelFromONP();
          handleCloseModalInWhiteBoard && handleCloseModalInWhiteBoard();
        }
        // TH3: tât cả thành công
        if (res?.data?.orders?.data === 'success') {
          window.TmsNoti?.success('Import thành công');
          getOrdersCancelFromONP();
          handleCloseModalInWhiteBoard && handleCloseModalInWhiteBoard();
        }
      })
      .catch((err) => console.log('Import cancel from onp failed: ', err))
      .finally(() => {
        setLoading(false);
        setSelectedRowData([]);
        setSelectedKey([]);
      });
  };

  const columns = [
    {
      title: 'Số Booking',
      dataIndex: 'so_Booking',
      width: 120,
      align: 'center',
    },
    {
      title: 'Tên Khách Hàng',
      dataIndex: 'ten_Khach_Hang',
      width: 120,
      align: 'center',
    },
    {
      title: 'Lý do huỷ',
      dataIndex: 'ly_Do_Huy',
      width: 150,
      align: 'center',
    },
    {
      title: 'Action',
      fixed: 'right',
      align: 'center',
      width: 140,
      render: (record) => {
        return (
          <Popconfirm
            onConfirm={() => handleAcceptOrderFromONP([record])}
            title='Xác nhận đơn hàng này?'
            okText='Đồng ý'
            cancelText='Không'
          >
            <Button type='primary' size='small' style={{ padding: '0px 20px' }}>
              Xác nhận
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const rowSelection = (selectKey, rowData) => {
    setSelectedKey(selectKey);
    setSelectedRowData(rowData);
  };

  const headerContent = (
    <Space>
      <h3>
        Booking Huỷ từ ONP: <b className='red'>({ordersFromONP?.length}) </b>{' '}
      </h3>
      <Badge count={countSelected}>
        <Popconfirm
          onConfirm={() => handleAcceptOrderFromONP(selectedRowData)}
          title={`Xác nhận cấp dầu cho ${countSelected} đơn hàng?`}
          okText='Đồng ý'
          cancelText='Không'
          disabled={countSelected < 1}
        >
          <Button
            type='primary'
            style={{ padding: '0px 20px', marginLeft: '10px' }}
            disabled={countSelected < 1}
          >
            Xác nhận
          </Button>
        </Popconfirm>
      </Badge>
    </Space>
  );
  const renderRowClassName = (record) => {
    if (record?.xe_Ngoai_ID === 0) {
      return '';
    } else {
      return 'order-from-ONP';
    }
  };
  return (
    <Wrapper>
      <TableLayout
        columns={columns}
        data={ordersFromONP}
        rowKey='auto_ID'
        bordered
        loading={loading}
        selectParent={selectedKey}
        onSelectChange={rowSelection}
        headerContent={headerContent}
        headerLength={headerLength}
        rowClassName={renderRowClassName}
      />
    </Wrapper>
  );
};
