import { useState, useEffect, useRef } from 'react';
import { findDOMNode } from 'react-dom';
const HEIGHT_DEFAULT = 250;
export default function useWBTableScroll(initHeigh = 400) {
  const tableRef = useRef();
  const tableBodyRef = useRef();
  const [isScroll, setIsScroll] = useState(false);

  const onScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;

    if (scrollHeight - clientHeight <= scrollTop + (initHeigh || HEIGHT_DEFAULT)) {
      setIsScroll(true);
      return () => setTimeout(() => setIsScroll(false), 300);
    }
    setIsScroll(false);
  };
  useEffect(() => {
    if (tableRef.current) {
      tableBodyRef.current = findDOMNode(tableRef.current).getElementsByClassName(
        'ant-table-body'
      )[0];

      tableBodyRef.current?.addEventListener('scroll', onScroll);
      return () => tableBodyRef.current?.removeEventListener('scroll', onScroll);
    }
  }, [tableRef]);
  return { tableRef, isScroll };
}
