import React, { useEffect, forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Input, DatePicker, Select, Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import storage from '~storage';
import { Selection } from '~components';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '~commons/constants';
import utils from '~commons/utils';
import moment from 'moment';

const { Option } = Select;

const BillForm = forwardRef(({ fields, onChange, onsubmit, orderDetail }, ref) => {
  const [locations, setLocations] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [shippingLines, setShippingLines] = useState([]);
  const [cargoTypes, setCargoTypes] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [preview, setPreview] = useState({
    visible: false,
    image: '',
    title: '',
  });

  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    submitForm() {
      form.submit();
    },
  }));

  const getCustomers = async (Query = null) => {
    try {
      const { data } = await ApiServices.getCustomer({ Query });
      setCustomers(data.data);
    } catch (err) {
      console.error('ERR - Get Customers', err);
    }
  };
  const getShippingLines = async (Query = null) => {
    try {
      const { data } = await ApiServices.getShippingLines({ Query });
      setShippingLines(data.data);
    } catch (err) {
      console.error('ERR - Get Shipping Lines', err);
    }
  };
  const getLocations = async (Query = null) => {
    try {
      const { data } = await ApiServices.getLocations({ Query });
      setLocations(data.data);
    } catch (err) {
      console.error('ERR - Get Shipping Lines', err);
    }
  };

  const getLocationsDetail = async (id) => {
    try {
      const { data } = await ApiServices.getDetailsLocation(id);
      setLocations(data.data);
    } catch (err) {
      console.error('ERR - Get Shipping Lines', err);
    }
  };

  const getLocationsGetInit = () => {
    if (fields) {
      const ind = fields.findIndex((item) => item.name[0] === 'locationDepotReturnCode');
      if (fields[ind].value) {
        const ID = fields[ind].value;
        getLocationsDetail(ID);
      }
    }
  };

  const getCargoTypes = async (Query = null) => {
    try {
      const { data } = await ApiServices.getCargoTypes({ Query });
      setCargoTypes(data.data);
    } catch (err) {
      console.error('ERR - Get Shipping Lines', err);
    }
  };
  const setDataInit = () => {
    if (fields) {
      const ind = fields.findIndex((item) => item.name[0] === 'orderFiles');
      if (fields[ind].value) {
        setFilePreviews(fields[ind].value.fileList);
      }
    }
  };
  const initOrderDetail = () => {
    if (!orderDetail) {
      return;
    }

    const files = orderDetail?.orderFiles?.map((item) => {
      const url = process.env.REACT_APP_API_URL + item.urlImage.substring(2);
      return {
        ...item,
        url,
        fileName: item.name + item.extension,
      };
    });
    setFileList(files);
    setFilePreviews(files);
  };
  useEffect(() => {
    initOrderDetail();
  }, [orderDetail]);

  useEffect(() => {
    getCustomers();
    getShippingLines();
    getLocations();
    getLocationsGetInit();
    getCargoTypes();
    setDataInit();
  }, []);

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const onSubmitForm = async () => {
    onsubmit();
  };
  const transportType = storage.staticVariables.TransportType;
  const transportTypeDiv = (
    <Select placeholder='Loại vận chuyển' showSearch>
      {Object.keys(transportType).map((item) => (
        <Option key={item} value={transportType[item]}>
          {transportType[item]}
        </Option>
      ))}
    </Select>
  );

  // Upload
  const beforeUpload = (file) => {
    setFileList([...fileList, file]);
    setFilePreviews([...filePreviews, file]);
    return false;
  };

  const handleChange = ({ fileList }) => {
    setFilePreviews(fileList);
  };

  const onRemove = (file) => {
    setFileList((curList) => {
      const index = curList?.length && curList.indexOf(file);
      const newFileList = (curList?.length && curList.slice()) || [];
      curList?.length && newFileList.splice(index, 1);
      return newFileList;
    });
  };

  const handleCancel = () => setPreview((preview) => ({ ...preview, visible: false }));

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreview({
      image: file.url || file.preview,
      visible: true,
      title: file.fileName || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <Form
      {...layout}
      form={form}
      name='global_state'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        name='billNumber'
        label='Số Bill'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Số Bill',
          },
        ]}
      >
        <Input placeholder='Số Bill' />
      </Form.Item>
      <Form.Item name='transportType' label='Loại Vận Chuyển'>
        {transportTypeDiv}
      </Form.Item>

      <Form.Item
        name='customerCode'
        label='Khách hàng'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Mã Khách hàng',
          },
        ]}
      >
        <Selection
          data={customers}
          labelDefault={orderDetail?.customerName}
          placeholder='Mã Khách hàng'
          onSearch={getCustomers}
        />
      </Form.Item>

      <Form.Item
        name='shippingLineCode'
        label='Hãng tàu'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Bạn phải nhập Hãng tàu',
        //   },
        // ]}
      >
        <Selection data={shippingLines} placeholder='Mã Hãng tàu' onSearch={getShippingLines} />
      </Form.Item>

      <Form.Item
        name='atd'
        label='Ngày Tàu Đến'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Bạn phải nhập Ngày Tàu Đến',
        //   },
        // ]}
      >
        <DatePicker
          placeholder='Ngày Hết Ngày Tàu Đến'
          style={{ width: '100%' }}
          disabledDate={(current) => utils.disabledDate(current, null, moment())}
          format={DATE_FORMAT}
        />
      </Form.Item>

      <Form.Item name='etd' label='Ngày Dự Kiến Tàu Đến'>
        <DatePicker
          placeholder='Ngày Hết Ngày Dự Kiến Tàu Đến'
          style={{ width: '100%' }}
          disabledDate={(current) => utils.disabledDate(current, null, moment())}
          format={DATE_FORMAT}
        />
      </Form.Item>

      {/* <Form.Item
        name="deliveryContRequestDate"
        label="Ngày Yêu Cầu Lấy Công"
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Ngày Yêu Cầu Lấy Công',
          },
          ...Validates.validateDatePicker(
            'Ngày tàu đến',
            null,
            // 'Ngày Hết Hạn Lưu Cont', 
            form.getFieldValue().atd
          ),
        ]}
      >
        <DatePicker
          placeholder="Ngày Yêu Cầu Lấy Cont"
          style={{ width: '100%' }}
          format={DATE_FORMAT}
        />
      </Form.Item> */}

      <Form.Item
        name='lastFreeDayDEM'
        label='Ngày Hết Hạn Lưu Cont'
        rules={
          [
            // {
            //   required: true,
            //   message: 'Bạn phải nhập Ngày Hết Hạn Lưu Cont',
            // },
            // ...utils.validateDatePicker(
            //   'Ngày Yêu Cầu Lấy Công',
            //   null,
            //   // 'Ngày Hết Hạn Trả Rỗng',
            //   form.getFieldValue().deliveryContRequestDate
            //   // form.getFieldValue().LastFreeDayDET
            // ),
          ]
        }
      >
        <DatePicker
          showTime={{ defaultValue: moment('00:00', 'HH:mm'), format: 'HH:mm' }}
          disabledTime={utils.disabledTime}
          placeholder='Ngày Hết Hạn Lưu Cont'
          style={{ width: '100%' }}
          format={DATE_TIME_FORMAT}
        />
      </Form.Item>

      <Form.Item
        name='lastFreeDayDET'
        label='Ngày Hết Hạn Trả Rỗng'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Ngày Hết Hạn Trả Rỗng',
          },
          // ...utils.validateDatePicker(
          //   'Ngày Hết Hạn Lưu Cont',
          //   null,
          //   form.getFieldValue().LastFreeDayDEM
          // ),
        ]}
      >
        <DatePicker
          showTime={{ format: 'HH:mm' }}
          placeholder='Ngày Hết Hạn Trả Rỗng'
          style={{ width: '100%' }}
          format={DATE_TIME_FORMAT}
        />
      </Form.Item>

      <Form.Item
        name='vesselAndVoyage'
        label='Tên Chuyến Tàu'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Bạn phải nhập Tên Chuyến Tàu',
        //   },
        // ]}
      >
        <Input placeholder='Tên Chuyến Tàu' />
      </Form.Item>

      <Form.Item
        name='locationDepotReturnCode'
        label='Nơi Trả Rỗng'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Bạn phải nhập Nơi Trả Rỗng',
        //   },
        // ]}
      >
        <Selection
          labelDefault={
            orderDetail?.listCont && orderDetail?.listCont.length
              ? orderDetail?.listCont[0]?.bill_LocationDepotReturnName
              : ''
          }
          data={locations}
          placeholder='Nơi Trả Rỗng'
          onSearch={getLocations}
        />
      </Form.Item>
      <Form.Item
        name='locationOfReceiptCode'
        label='Nơi Lấy Hàng'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Bạn phải nhập Nơi Lấy Hàng',
        //   },
        // ]}
      >
        <Selection
          data={locations}
          labelDefault={
            orderDetail?.listCont && orderDetail?.listCont.length
              ? orderDetail?.listCont[0]?.locationOfReciptName
              : ''
          }
          placeholder='Nơi Lấy Hàng'
          fieldValue='name'
          onSearch={getLocations}
        />
      </Form.Item>

      <Form.Item
        name='locationOfDeliveryCode'
        label='Nơi Giao Hàng'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Bạn phải nhập Nơi Giao Hàng',
        //   },
        // ]}
      >
        <Selection
          labelDefault={
            orderDetail?.listCont && orderDetail?.listCont.length
              ? orderDetail?.listCont[0]?.locationOfDeliveryName
              : ''
          }
          data={locations}
          placeholder='Nơi Giao Hàng'
          fieldValue='name'
          onSearch={getLocations}
        />
      </Form.Item>

      <Form.Item
        name='cargoTypeCode'
        label='Loại Hàng Hóa'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Bạn phải nhập Loại Hàng Hóa',
        //   },
        // ]}
      >
        <Selection
          data={cargoTypes}
          placeholder='Loại Hàng Hóa'
          fieldValue='name'
          onSearch={getCargoTypes}
        />
      </Form.Item>
      <Form.Item name='note' label='Ghi chú'>
        <Input.TextArea placeholder='Ghi chú' />
      </Form.Item>
      <Form.Item name='orderFiles' label='File Đính Kèm'>
        <Upload
          multiple
          listType='picture-card'
          onChange={handleChange}
          beforeUpload={beforeUpload}
          fileList={filePreviews}
          onRemove={onRemove}
          onPreview={handlePreview}
        >
          <div>
            <PlusOutlined style={{ color: 'blue', fontSize: '30px' }} />
            <div style={{ marginTop: '10px' }}>Đính Kèm</div>
          </div>
        </Upload>
      </Form.Item>
      <Modal open={preview.visible} title={preview.title} footer={null} onCancel={handleCancel}>
        <img alt='Preview of upload' style={{ width: '100%' }} src={preview.image} />
      </Modal>
    </Form>
  );
});

export { BillForm };
