import React from 'react';
import { Table } from 'antd';
import { COLUMNS } from './constants';

export const TableData = ({ loading, data }) => {
  return (
    <Table
      dataSource={data}
      loading={loading}
      rowKey='index'
      bordered
      columns={COLUMNS}
      pagination={false}
      size='small'
      // scroll={{ x: 'calc(700px + 50%)', y: 240 }}
      scroll={{ x: '3000px', y: '100%' }}
    />
  );
};
