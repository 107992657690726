import React from 'react';
import { Form, Button, message, Input, Checkbox } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';

const OilPricesForm = ({ fields, isNew, id, dataSelect, reload, onChange, setLoading }) => {
  const handleCreate = async (data) => {
    const dataSend = {
      name: data?.name,
      companyId: JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId,
      billNumber: data?.billNumber ? data?.billNumber : false,
      pay: data?.pay ? data?.pay : false,
      note: data?.note,
    };
    try {
      const res = await ApiServices.createExpenses(dataSend);
      if (res?.data?.data) {
        message.success('Thêm mới chi phí thành công');
        return reload();
      } else {
        message.error('Thêm mới chi phí thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới chi phí thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const handleUpdate = async (data) => {
    const dataSend = {
      id: id,
      name: data?.name,
      companyId: dataSelect.companyId,
      billNumber: data?.billNumber ? data?.billNumber : false,
      pay: data?.pay ? data?.pay : false,
      note: data?.note,
    };
    try {
      const res = await ApiServices.updateExpenses(id, dataSend);
      if (res.data) {
        message.success('Cập nhật chi phí thành công');
        return reload();
      } else {
        message.error('Cập nhật chi phí thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật chi phí thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await handleCreate(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await handleUpdate(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      name='global_state'
      className='form-input-data'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        // {...itemLayout}
        style={{ marginTop: '10px' }}
        name='name'
        label='Tên chi phí'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập tên chi phí',
          },
        ]}
      >
        <Input placeholder='Vui lòng nhập tên chi phí' />
      </Form.Item>
      <Form.Item name='billNumber' valuePropName='checked' label='Bắt buộc nhập hoá đơn?'>
        <Checkbox />
      </Form.Item>
      <Form.Item name='pay' valuePropName='checked' label='Chi hộ?'>
        <Checkbox />
      </Form.Item>
      <Form.Item name='note' label='Ghi Chú'>
        <Input.TextArea placeholder='Ghi Chú' />
      </Form.Item>
      <Form.Item {...tailLayout} className='submit-form-input-data'>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { OilPricesForm };
