import { createElement } from 'react';
import User from '../models/user';
import { STORAGE } from '~commons/constants';
import Utils from '~commons/utils';
import ApiServices from '~services';
import * as antdIcons from '@ant-design/icons';
import { configPathWithComponent } from '~pages/routes';

class Storage {
  constructor() {
    this.user = new User();
    this.isDarkTheme = false;
    this.isCollapsed = false;
    this.activeMenuItem = {
      openKey: 'sub1',
      key: 'car',
    };
    this.staticVariables = {};
    this.permissions = [];
    this.menus = [];
  }
  loadData = async () => {
    await Promise.all([this.retrieveUser(), this.retrieveDarkModel(), this.retrieveIsCollapsed()]);
    if (this.isLoggedIn()) {
      await this.retrievePermissions();
      // lấy danh sách permission trước để render menus
      return Promise.all([this.retrieveStaticVariables(), this.retrieveMenus()]);
    }
  };
  retrievePermissions = async () => {
    try {
      const res = await ApiServices.getPermissions();
      this.permissions = res.data?.data;
      // chứa array code Ex: ['car_view', ......]
    } catch (error) {
      console.log('[Storage] Retrieve Permissions data error:', error);
    }
  };
  changeMenu = (menus) => {
    this.menus = menus;
  };
  retrieveMenus = async () => {
    try {
      const res = await ApiServices.getMenus();
      const menusFormatted = [];
      for (let menu of res.data) {
        if (!menu.child?.length && menu.url) {
          const config = configPathWithComponent.get(menu.url);

          this.permissions.includes(config?.permission) &&
            menusFormatted.push({
              key: menu.id,
              title: menu.name_vi_VN,
              icon: antdIcons[menu.icon]?.render && createElement(antdIcons[menu.icon]),
              subData: {
                to: menu.url,
                inner: menu.name_vi_VN,
              },
            });
          continue;
        }
        if (menu.childs?.length) {
          menusFormatted.push({
            key: menu.id,
            title: menu.name_vi_VN,
            icon: antdIcons[menu.icon]?.render && createElement(antdIcons[menu.icon]),
            subData: menu.childs
              .map((child) => {
                const configChild = configPathWithComponent.get(child.url);
                return (
                  this.permissions.includes(configChild?.permission) && {
                    to: child.url,
                    key: child.id,
                    inner: child.name_vi_VN,
                  }
                );
              })
              .filter((el) => el?.key),
          });
        }
      }

      this.menus = menusFormatted.filter((el) => el.subData?.length || el.subData?.to);
    } catch (error) {
      console.log('[Storage] Retrieve staticVariables data error:', error);
    }
  };
  retrieveStaticVariables = async () => {
    try {
      const res = await ApiServices.getStaticVariables();
      this.staticVariables = res.data;
    } catch (error) {
      console.log('[Storage] Retrieve staticVariables data error:', error);
    }
  };

  retrieveIsCollapsed = async () => {
    try {
      this.isCollapsed = await localStorage.getItem(STORAGE.IS_COLLAPSED);
      return this.isCollapsed;
    } catch (error) {
      console.log('[Storage] Retrieve isCollapsed theme data error:', error);
    }
    return null;
  };
  retrieveDarkModel = async () => {
    try {
      this.isDarkTheme = await localStorage.getItem(STORAGE.IS_DARK_MODEL);
      return this.isDarkTheme;
    } catch (error) {
      console.log('[Storage] Retrieve model theme data error:', error);
    }
    return null;
  };
  changeIsDarkTheme = async (isDarkTheme) => {
    this.isDarkTheme = isDarkTheme;
    await localStorage.setItem(STORAGE.IS_DARK_MODEL, isDarkTheme || '');
    return this.isDarkTheme;
  };
  changeIsCollapsed = async (isCollapsed) => {
    this.isCollapsed = isCollapsed;
    await localStorage.setItem(STORAGE.IS_COLLAPSED, isCollapsed || '');
    return;
  };

  saveUser = async (user) => {
    try {
      this.user = User.clone({
        ...this.user,
        ...Utils.removeEmptyAttributes(user),
      });
      console.log(this.user);
      await localStorage.setItem(STORAGE.USER_DATA, JSON.stringify(this.user));
    } catch (error) {
      console.log('[Storage] Save user error', error);
    }
    return this.user;
  };

  retrieveUser = async () => {
    try {
      const user = await localStorage.getItem(STORAGE.USER_DATA);
      if (user) {
        this.user.update(JSON.parse(user));
        return this.user;
      }
    } catch (error) {
      console.log('[Storage] Retrieve user data error:', error);
    }
    return null;
  };

  removeUser = async () => {
    console.log('Remove');
    try {
      this.user = new User();
      await localStorage.removeItem(STORAGE.USER_DATA);
      await this.removeDataDecodeToken();
      await this.removeTokenFireBase();
    } catch (error) {
      console.log('[Storage] Remove user data error', error);
    }
  };

  isLoggedIn = () => {
    return this.user && this.user.token;
  };

  // Decode Data By Token
  saveDataDecodeToken = (dataUser) => {
    return localStorage.setItem(STORAGE.USER_DATA_DECODE_TOKEN, JSON.stringify(dataUser));
  };
  getDataDecodeToken = () => {
    return JSON.parse(localStorage.getItem(STORAGE.USER_DATA_DECODE_TOKEN));
  };
  removeDataDecodeToken = () => {
    return localStorage.removeItem(STORAGE.USER_DATA_DECODE_TOKEN);
  };

  // Firebase
  saveTokenFireBase = (tokenFirebase) => {
    return localStorage.setItem(STORAGE.TOKEN_FIREBASE, JSON.stringify(tokenFirebase));
  };
  getTokenFireBase = () => {
    return JSON.parse(localStorage.getItem(STORAGE.TOKEN_FIREBASE));
  };
  removeTokenFireBase = () => {
    return localStorage.removeItem(STORAGE.TOKEN_FIREBASE);
  };
}

const storage = new Storage();
export default storage;
