import React, { useState } from 'react';
import { Button, Form, DatePicker } from 'antd';
import moment from 'moment';
import { DATE_FORMAT } from '~commons/constants';
import ApiServices from '~services';

const FormChangeDissolveDate = ({ selectedMultipleTicket, reloadChangeDissolveDate }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    if (loading) return;
    const dataSend = {
      ids: selectedMultipleTicket,
      dissolveDate: moment(values?.dissolveDate).format('YYYY-MM-DD'),
    };
    setLoading(true);
    return ApiServices.updateMultipleDissolveDate(dataSend)
      .then((res) => {
        if (res) {
          if (res?.data) {
            reloadChangeDissolveDate();
          }
        }
      })
      .catch((err) => {
        console.log('Update booking cont failed: ', err);
      })
      .finally(() => setLoading(false));
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Form
        name='basic'
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
      >
        <Form.Item
          label='Ngày giải trừ mới'
          name='dissolveDate'
          rules={[
            {
              required: true,
              message: 'Bạn phải nhập ngày giải trừ!',
            },
          ]}
        >
          <DatePicker placeholder='Ngày giải trừ' style={{ width: '100%' }} format={DATE_FORMAT} />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 10,
            span: 16,
          }}
        >
          <Button type='primary' htmlType='submit' loading={loading}>
            Cập nhật
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export { FormChangeDissolveDate };
