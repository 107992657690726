export const LIST_COLUMNS_TITLE_FIRST_SUMMARY_SHEET = [
  '',
  'Khách Hàng',
  'Nhập',
  '',
  '',
  '',
  'Xuất',
  '',
  '',
  '',
  'Chuyển Kho',
  '',
  '',
  '',
  'Tổng',
];
export const LIST_COLUMNS_TITLE_FIRST_SUMMARY_SHEET_SUM = [
  '',
  'Nhà Xe',
  'Nhập',
  '',
  '',
  '',
  'Xuất',
  '',
  '',
  '',
  'Chuyển Kho',
  '',
  '',
  '',
  'Tổng',
];

export const LIST_COLUMNS_TITLE_SECOND_SUMMARY_SHEET = [
  '',
  '',
  // Nhap
  `20"`,
  `40C`,
  `40T`,
  `45"`,
  // Xuat
  `20"`,
  `40C`,
  `40T`,
  `45"`,
  // Chuyen Kho
  `20"`,
  `40C`,
  `40T`,
  `45"`,
  '', // Tong
];

export const MERGE_TWO_ROWS_TITLE_SUMMARY_SHEET = ['B', 'O'];

export const MERGE_TWO_COLUMNS_TITLE_SUMMARY_SHEET = [
  {
    start: 'C',
    end: 'F',
  },
  {
    start: 'G',
    end: 'J',
  },
  {
    start: 'K',
    end: 'N',
  },
];

export const BORDER_CELL_TITLE_SUMMARY_SHEET = [
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
];

export const COLUMNS_BORDER_LIST_SUMMARY_SHEET = [
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
];

export const LIST_COLUMN_SUM_SUMMARY_SHEET = [
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
];

export const LIST_COLUMN_FILL_ORDER_TYPE = [
  // Xuat
  {
    cell: 'G',
    color: 'FFCCCC',
  },
  {
    cell: 'H',
    color: 'FFCCCC',
  },
  {
    cell: 'I',
    color: 'FFCCCC',
  },
  {
    cell: 'J',
    color: 'FFCCCC',
  },
  // Total
  {
    cell: 'O',
    color: '96B6C5',
  },
];
