import actionTypes from './type';

export const DEFAULT_QUERY = {
  PageSize: 10,
  PageNumber: 1,
};

const initialState = {
  data: null,
  query: DEFAULT_QUERY,
  loading: false,
  selected: null,
  countNotRead: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_DATA:
      return {
        ...state,
        data: action.data,
      };
    case actionTypes.LOAD_MORE_DATA: {
      const moreData = state.data.data.map((el) => el);

      for (var el of action.data.data) {
        moreData.push(el);
      }
      const newData = {
        ...action.data,
        data: moreData,
      };
      console.log('newData', newData);
      return {
        ...state,
        data: newData,
      };
    }
    case actionTypes.UPDATE_ONE_DATA: {
      const newTmpData = state.data.data.map((el) => el);
      const fIndex = newTmpData.findIndex((el) => el.jobId[0] === action.data.id);
      if (fIndex !== -1) {
        newTmpData[fIndex] = {
          ...newTmpData[fIndex],
          ...action.data,
        };
      }

      return {
        ...state,
        data: {
          ...state.data,
          data: newTmpData,
        },
      };
    }
    case actionTypes.UPDATE_QUERY:
      return {
        ...state,
        query: action.query,
      };
    case actionTypes.FETCH_COUNTER_DATA:
      return {
        ...state,
        countNotRead: action.data || 0,
      };
    case actionTypes.UPDATE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actionTypes.UPDATE_SELECTED:
      return {
        ...state,
        selected: action.selected.map((el) => el.key),
        selectedRows: action.selected,
      };
    default:
      return state;
  }
}
