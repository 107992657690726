export const COLUMNS = [
  {
    title: 'STT',
    with: 40,
    dataIndex: 'index',
    // fixed: true,
  },
  {
    title: 'Số Subno',
    with: 200,
    dataIndex: 'SoSubno',
  }, //TrangThaiCont
  {
    title: 'Trạng Thái Cont',
    with: 150,
    dataIndex: 'TrangThaiCont',
  }, //TrangThaiCont
  {
    title: 'Số ĐH',
    with: 150,
    dataIndex: 'SoDonHang',
  },
  {
    title: 'Số Cont',
    with: 150,
    dataIndex: 'SoCont',
  },
  {
    title: 'Số Seal',
    with: 150,
    dataIndex: 'SoSeal',
  },
  {
    title: 'Khách Hàng',
    with: 150,
    dataIndex: 'KhachHang',
  },
  {
    title: 'Hãng Tàu',
    with: 100,
    dataIndex: 'HangTau',
  },
  {
    title: 'Tên Chuyến Tàu',
    with: 150,
    dataIndex: 'TenChuyenTau',
  },
  {
    title: 'Cảng Đến',
    with: 150,
    dataIndex: 'CangDen',
  },
  {
    title: 'Loại Hàng Hoá',
    with: 150,
    dataIndex: 'LoaiHangHoa',
  },
  {
    title: 'Ngày Tạo ĐH',
    with: 150,
    dataIndex: 'NgayTaoDonHang',
  },
  {
    title: 'Trạng Thái ĐH',
    with: 150,
    dataIndex: 'TrangThaiDonHang',
  },
  {
    title: 'Ngày Cắt Máng',
    with: 150,
    dataIndex: 'NgayCatMang',
  },
  {
    title: 'Ngày cắt SI',
    with: 150,
    dataIndex: 'NgayCatSI',
  }, //
  {
    title: 'Ngày Dự Kiến Tàu Chạy',
    with: 150,
    dataIndex: 'NgayDuKienTauChay',
  },
  {
    title: 'Ngày Thực Tế Tàu Chạy',
    with: 150,
    dataIndex: 'NgayThucTeTauChay',
  },
  {
    title: 'Loại Cont',
    with: 150,
    dataIndex: 'LoaiCont',
  },
  {
    title: 'Số Cont',
    with: 150,
    dataIndex: 'SoCont',
  },
  {
    title: 'Số Seal',
    with: 150,
    dataIndex: 'SoSeal',
  },
  {
    title: 'Trọng Tải',
    with: 150,
    dataIndex: 'TrongTai',
  },
  {
    title: 'Ngày YCGC',
    with: 150,
    dataIndex: 'NgayYeuCauGiaoCont',
  },
  {
    title: 'Ngày Lấy Rỗng Thực Tế',
    with: 150,
    dataIndex: 'NgayLayRongThucTe',
  },
  {
    title: 'Ngày Báo Xuất Hàng',
    with: 150,
    dataIndex: 'NgayBaoXuatHang',
  }, //
  {
    title: 'Ngày Cắt Rmooc Cont Rỗng',
    with: 150,
    dataIndex: 'NgayCatMoocContRong',
  },
  {
    title: 'Ngày Lấy Hàng Thực Tế',
    with: 150,
    dataIndex: 'NgayLayHangThucTe',
  },
  {
    title: 'Ngày Giao Hàng ThựcTế',
    with: 150,
    dataIndex: 'NgayGiaoHangThucTe',
  },
  {
    title: 'Công Việc',
    with: 150,
    dataIndex: 'CongViec',
  },
  {
    title: 'Trạng Thái Công Việc',
    with: 150,
    dataIndex: 'TrangThaiCongViec',
  },
  {
    title: 'Nơi Lấy Hàng',
    with: 150,
    dataIndex: 'NoiLayHang',
  },
  {
    title: 'Nơi Giao Hàng',
    with: 150,
    dataIndex: 'NoiGiaoHang',
  },
  {
    title: 'Nơi Lấy Rỗng',
    with: 150,
    dataIndex: 'NoiLayRong',
  },
  {
    title: 'Ghi Chú',
    with: 150,
    dataIndex: 'GhiChu',
  },
  {
    title: 'Số Xe',
    with: 150,
    dataIndex: 'SoXe',
  },
  {
    title: 'Số Romooc',
    with: 150,
    dataIndex: 'SoRomooc',
  },
  {
    title: 'Đội xe',
    with: 150,
    dataIndex: 'DoiXe',
  },
  {
    title: 'Tạo Bởi',
    with: 150,
    dataIndex: 'NguoiTaoDonHang',
  },
];

export const EXPORT_HEADER = [
  {
    index: 'STT',
    KhachHang: 'Khách hàng',
    HangTau: 'Hãng Tàu',
    TenChuyenTau: 'Tên Chuyến Tàu',
    CangDen: 'Cảng Đến',
    SoDonHang: 'Số ĐH',
    LoaiHangHoa: 'Loại ĐH',
    NgayTaoDonHang: 'Ngày Tạo ĐH',
    TrangThaiDonHang: 'Trạng Thái ĐH',
    NgayCatMang: 'Ngày Cắt Máng',
    NgayCatSI: 'Ngày Cắt SI',
    NgayDuKienTauChay: 'Ngày Dự Kiến Tàu Chạy',
    NgayThucTeTauChay: 'Ngày Thực Tế Tàu Chạy',
    SoSubno: 'Số Subno',
    LoaiCont: 'Loại Cont',
    SoCont: 'Số Cont',
    SoSeal: 'Số Seal',
    TrongTai: 'Trọng Tải',
    NgayYeuCauGiaoCont: 'Ngày Yêu Cầu Giao Công',
    NgayLayRongThucTe: 'Ngày Lẫy Rỗng Thực Tế',
    NgayBaoXuatHang: 'Ngày Báo Xuất Hàng',
    NgayCatMoocContRong: 'Ngày Cắt Rmooc Cont Rỗng',
    NgayLayHangThucTe: 'Nơi Lấy Hàng Thực Tế',
    NgayGiaoHangThucTe: 'Nơi Giao Hàng Thực Tế',
    TrangThaiCont: 'Trạng Thái Cont',
    CongViec: 'Công Việc',
    TrangThaiCongViec: 'Trạng Thái CV',
    NoiLayHang: 'Nơi Lấy Hàng',
    NoiGiaoHang: 'Nơi Giao Hàng',
    NoiLayRong: 'Nơi Lấy Rỗng',
    GhiChu: 'Ghi chú',
    SoXe: 'Số Xe',
    SoRomooc: 'Số Rmooc',
    DoiXe: 'Đội Xe',
    NguoiTaoDonHang: 'Người Tạo ĐH',
  },
];
export const MERGE_COLUMN_SHEET = [];
export const FIELDS_FORMAT_DATE = [
  'NgayTaoDonHang',
  'NgayCatMang',
  'NgayCatSI',
  'NgayDuKienTauChay',
  'NgayYeuCauGiaoCont',
  'NgayLayRongThucTe',
  'NgayBaoXuatHang',
  'NgayCatMoocContRong',
  'NgayLayHangThucTe',
  'NgayGiaoHangThucTe',
];
