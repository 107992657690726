import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { uid } from 'uid';
import { Item } from './item';

export const ItemInput = ({ data, onChange, datePlaceholder }) => {
  const [values, setValues] = useState(data.values);
  useEffect(() => {
    setValues(data.values);
  }, [data]);

  const onChangeData = (index, nData) => {
    setValues((prev) => {
      let newData = [...prev];
      newData[index] = {
        ...newData[index],
        ...nData,
      };

      onChange && onChange(data.id, newData);
      return newData;
    });
  };
  const onAddData = (id) => {
    setValues((prev) => {
      let newData = [...prev];
      newData.push({
        total: 0,
        date: null,
        id,
        itemId: uid(32),
      });

      onChange && onChange(data.id, newData);
      return newData;
    });
  };
  const onRemoveData = (id, index) => {
    if (values?.length === 1) {
      setValues((prev) => {
        let newData = [...prev];
        newData[index] = {
          ...newData[index],
          total: 0,
          date: null,
        };
        onChange && onChange(data.id, newData);
        return newData;
      });
    } else {
      setValues((prev) => {
        let newData = [...prev];
        newData.splice(index, 1);
        onChange && onChange(id, newData);
        return newData;
      });
    }
  };

  return (
    <RowWrapper gutter={12} key={data.id}>
      <Col span={4} style={{ justifyContent: 'center', display: 'flex' }}>
        Cont {data.name}
      </Col>
      <Col span={20}>
        {values?.map((el, index) => {
          return (
            <Row key={data.itemId} gutter={8} style={{ margin: '4px 0' }}>
              <Item
                data={el}
                index={index}
                onChange={onChangeData}
                onRemove={onRemoveData}
                onAdd={onAddData}
                totalRow={values?.length}
                placeholder={datePlaceholder}
              />
            </Row>
          );
        })}
      </Col>
    </RowWrapper>
  );
};
const RowWrapper = styled(Row)`
  display: flex;
  align-items: center;
  border: 1px solid #e8e8e8;
  padding: 5px 0px;
`;
