import { Table, Typography } from 'antd';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '~commons/constants';

const SHOW_CM_TASK_NAMES = ['Lấy Hàng Xuất', 'Giao Hàng Xuất'];
const SHOW_SI_TASK_NAMES = ['Lấy Rỗng Xuất'];
const SHOW_HHLC_TASK_NAMES = ['Lấy Hàng Nhập'];
const SHOW_TRADE_CONT_NAMES = ['Lấy Hàng Nhập', 'Lấy Rỗng Nhập', 'Lấy Hàng Xuất', 'Giao Hàng Xuất'];

const { Text } = Typography;

const getTradeCar = (record) =>
  SHOW_TRADE_CONT_NAMES.includes(record.name) && record.objCont?.trace;
const TableContent = ({ loading, data }) => {
  const columns = [
    {
      title: '-',
      dataIndex: 'key',
      align: 'center',
    },
    {
      title: 'Khách Hàng',
      with: 140,
      dataIndex: 'customerName',
      align: 'center',
    },
    {
      title: 'Số Mooc',
      dataIndex: 'romoocCode',
      align: 'center',
    },
    {
      title: 'Công Việc',
      dataIndex: 'name',
    },
    {
      title: 'Booking / Bill',
      dataIndex: 'code',
      align: 'center',
      with: 120,
      render: (value, record) => {
        let res;
        if (value) {
          res = value.split('/');
          res.pop();
          res = res.join('/');
        }
        let startSubno = '';
        let endSubno = '';
        if (res) {
          const splitText = res.split('/');
          const splitTextLength = splitText?.length || 0;
          switch (splitTextLength) {
            case 3:
              startSubno = `${splitText[0]}`;
              endSubno = `/${splitText[1]}/${splitText[2]}`;
              break;
            case 4:
              startSubno = `${splitText[0]}`;
              endSubno = `/${splitText[1]}/${splitText[2]}/${splitText[3]}`;
              break;
            case 5:
              startSubno = `${splitText[0]}/${splitText[1]}`;
              endSubno = `/${splitText[2]}/${splitText[3]}/${splitText[4]}`;
              break;
            default:
              break;
          }
        }

        const cm =
          SHOW_CM_TASK_NAMES.includes(record.name) &&
          record.objCont?.objOrders?.[0]?.book_CutOffDateTime;
        const si =
          SHOW_SI_TASK_NAMES.includes(record.name) &&
          record.objCont?.objOrders?.[0]?.book_SIDateTime;
        const lc =
          SHOW_HHLC_TASK_NAMES.includes(record.name) &&
          record.objCont?.objOrders?.[0]?.bill_LastFreeDayDEM;

        return (
          <>
            {/* <p>{res}</p> */}
            <div>{startSubno}</div>
            <div>{endSubno}</div>
            {cm && <div className='blue'>{`CM: ${moment(cm).format(DATE_TIME_FORMAT)}`}</div>}
            {si && <div className='blue'>{`SI: ${moment(si).format(DATE_TIME_FORMAT)}`}</div>}
            {lc && <div className='blue'>{`HHLC: ${moment(lc).format(DATE_TIME_FORMAT)}`}</div>}
          </>
        );
      },
    },
    {
      title: 'Khởi Hành',
      dataIndex: 'beginLocationName',
      align: 'center',
    },
    {
      title: 'Kết Thúc',
      dataIndex: 'endLocationName',
      align: 'center',
    },
    {
      title: 'Số Km',
      dataIndex: 'distance',
      align: 'center',
    },
    {
      title: 'Số Cont',
      dataIndex: 'objCont',
      align: 'center',
      render: (value, record) => {
        const tradeCar = getTradeCar(record) || '';
        return `${value.contNumber || ''} ${tradeCar && `- ${tradeCar}`}`;
      },
    },
    {
      title: 'Ghi Chú',
      dataIndex: 'note',
      align: 'center',
    },
  ];

  const handleSummary = (pageData) => {
    let sumTotalKm = 0;

    pageData.forEach((page) => {
      sumTotalKm += page?.distance;
    });
    return (
      <>
        <Table.Summary.Row
          style={{
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Table.Summary.Cell index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}>
            <Text type='danger'>Tổng Km:</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7}>
            <span className='red'>{sumTotalKm}</span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8}>
            <Text type='danger'></Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={9}>
            <Text type='danger'></Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
        bordered={true}
        rowKey='id'
        size='small'
        summary={handleSummary}
      />
    </>
  );
};

export { TableContent };
