import React, { useRef, useEffect, useState } from 'react';
import { TableLayout } from '~components';
import { UserManagementForm } from './users-management-form';
import { UserAppForm } from './user-app-form';
import UsersManagementFields from './fields-data';
import { Input, Button, Popconfirm, message } from 'antd';
import ApiServices from '~services';

export const UsersManagement = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [users, setUsers] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log({ fields });
  //-------------------------------------------------------------------
  const typingTimeoutref = useRef(null);
  //-------------------------------------------------------------------
  const [searchByColumn, setSearchByColumn] = useState({
    code: '',
    fullName: '',
    email: '',
    vendorName: '',
    companyName: '',
  });

  const getUsers = async () => {
    let dataFilterSend = { ...filter };
    if (Object.keys(dataFilterSend.Columns).length === 0) {
      delete dataFilterSend['Columns'];
    } else {
      const columnJSONSTRING = JSON.stringify(dataFilterSend.Columns);
      dataFilterSend.Columns = columnJSONSTRING;
    }

    try {
      setLoading(true);
      let { data } = await ApiServices.getUsers(dataFilterSend);
      setLoading(false);
      let pag = pagination;
      pag.total = data?.totalRecords || 0;
      pag.pageSize = data?.pageSize;
      pag.current = data?.pageNumber;

      data = data?.data || [];
      setUsers(data);
      setPagination(pag);
    } catch (error) {
      console.log('Error - get cars', error);
      console.error(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);
  //----------- Start ----- Pagination Search ---------
  //----------- Pagination --------------------------
  const onChangePagination = (page, size) => {
    let filterTemp = filter;
    filterTemp.PageNumber = page;
    filterTemp.PageSize = size;
    setFilter(filterTemp);
    getUsers();
  };
  const [pagination, setPagination] = useState({
    total: 1,
    showSizeChanger: false,
    onChange: onChangePagination,
  });
  const [filter, setFilter] = useState({
    PageNumber: 1,
    PageSize: 10,
    Text: '',
    Columns: '',
  });

  // ------------ Search Column ----------------------
  const handleSearchColumnFn = (event, name) => {
    const filterTmp = filter;
    const val = event.target.value;
    let _searchByColumn = { ...searchByColumn };
    _searchByColumn[`${name}`] = val;
    setSearchByColumn(_searchByColumn);
    // Clear timeout
    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }
    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 0) {
        filter.PageNumber = 1;
        let searchColumnFn = { ...filter.Columns };
        searchColumnFn[`${name}`] = val;
        filter.Columns = searchColumnFn;

        setFilter({ ...filterTmp, PageNumber: 1 });
        getUsers();
      }
      if (val.length === 0) {
        delete filter.Columns[`${name}`];
        setFilter({ ...filterTmp, PageNumber: 1 });
        getUsers();
      }
    }, 1000);
  };
  // ------------- End Search --------------------

  const setFieldsForm = async (value) => {
    const usersManagementFields = await UsersManagementFields(value);
    await setFields(usersManagementFields);
    return usersManagementFields;
  };
  const reload = async () => {
    setSelected(null);
    await getUsers();
  };

  const getUpdatedDriverForm = async (
    value,
    isNew = false,
    id = null,
    code = null,
    isDriver = null,
    dataSelected
  ) => {
    const userManagementForm = await (
      <UserManagementForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        isDriver={isDriver}
        reload={reload}
        onChange={setFields}
        dataSelected={dataSelected}
      />
    );
    const changeUserAppForm = await (
      <UserAppForm
        fields={value}
        setLoading={setIsLoadingTab}
        code={code}
        reload={reload}
        onChange={setFields}
        dataSelected={dataSelected}
      />
    );

    if (isNew) {
      return setTabPanels([
        {
          title: 'Người Dùng Website',
          content: userManagementForm,
        },
        {
          title: 'App Tài Xế',
          content: changeUserAppForm,
        },
      ]);
    } else {
      return setTabPanels([
        {
          title: 'Thông Tin Chi Tiết',
          content: userManagementForm,
        },
        // {
        //   title: 'Đổi Mật Khẩu',
        //   content: changePasswordForm,
        // },
      ]);
    }
  };

  const onRowSelected = async (value) => {
    await setIsLoadingTab(true);
    await setTitleTab(value.isDriver ? 'Tài khoản App Tài xế' : 'Tài khoản Website');
    await setFieldsForm(value)
      .then((res) => {
        return getUpdatedDriverForm(res, false, value.id, value.code, value.isDriver, value);
      })
      .then(() => setSelected(value));
    setIsLoadingTab(false);
  };

  /*
contractId
: 
"64a279fefb4f9c67bd2b7249"
distanceId
: 
"6486165fac7f5db3a07df25a"
fees
: 
[{contTypeId: "60b21ca38c68b8c7662efd1c", name: "45"", fee: 22},…]
id
: 
null
locations
: 
null

*/

  const deleteUser = async (record) => {
    if (loading) return;

    setLoading(true);
    try {
      const res = await ApiServices.deleteUser(record.id);
      if (res.data) {
        message.success('Xoá Người Dùng thành công');
        return reload();
      }
      message.error('Xoá Người Dùng thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
      message.error('Xoá Người Dùng thất bại, vui lòng thử lại sau!');
    } finally {
      setLoading(false);
    }
  };
  const handleResetPassword = async (record) => {
    if (loading) return;

    setLoading(true);
    try {
      const res = await ApiServices.resetPasswordUser(record.id);
      if (res.data) {
        message.success('Reset Mật khẩu thành công');
        return reload();
      }
      message.error('Reset Mật khẩu thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
      message.error('Reset Mật khẩu thất bại, vui lòng thử lại sau!');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Tên Đăng Nhập</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.code}
            onChange={(event) => handleSearchColumnFn(event, 'code')}
          />
        </span>
      ),
      dataIndex: 'code',
      align: 'center',
      width: 150,
      render: (value, record) => (
        <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>
      ),
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Họ Tên</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.fullName}
            onChange={(event) => handleSearchColumnFn(event, 'fullName')}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'fullName',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Email</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.email}
            onChange={(event) => handleSearchColumnFn(event, 'email')}
          />
        </span>
      ),
      width: 200,
      dataIndex: 'email',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Nhà Xe</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.vendorName}
            onChange={(event) => handleSearchColumnFn(event, 'vendorName')}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'vendorName',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Công Ty</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.companyName}
            onChange={(event) => handleSearchColumnFn(event, 'companyName')}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'companyName',
    },
    {
      title: 'Loại người dùng',
      width: 150,
      dataIndex: 'isDriver',
      render: (value) => (value ? 'App Tài xế' : 'Website'),
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 90,
      align: 'center',
      render: (record) => (
        <Popconfirm
          onConfirm={() => handleResetPassword(record)}
          title='Bạn có muốn reset lại mật khẩu không?'
          okText='Yes'
          cancelText='No'
        >
          <a style={{ color: 'blue' }} href='#'>
            Reset Mật Khẩu
          </a>
        </Popconfirm>
      ),
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 90,
      align: 'center',
      render: (record) => (
        <Popconfirm
          onConfirm={() => deleteUser(record)}
          title='Xoá Người Dùng này?'
          okText='Yes'
          cancelText='No'
        >
          <a style={{ color: 'red' }} href='#'>
            Xoá
          </a>
        </Popconfirm>
      ),
    },
  ];

  const createDriver = async () => {
    await setTitleTab('Thêm Mới');
    await setFieldsForm({})
      .then((res) => getUpdatedDriverForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };
  const headerContent = (
    <>
      <Button type='primary' onClick={createDriver}>
        Thêm Mới
      </Button>
    </>
  );
  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={users}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      pagination={pagination}
      width={600}
    />
  );
};
