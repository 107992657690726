import React, { memo } from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { ROUTE_ITEMS } from '~pages/routes';
import storage from '~storage';
const { SubMenu } = Menu;

const getActiveKey = (key, menus) => {
  const menu = menus.find((el) => el.subData && el.subData.find?.((item) => item.to === key));
  return menu && (menu.key || menu.sub);
};

export const MenuSidebar = memo(() => {
  const url = useLocation().pathname;
  const menus = storage.user?.isSupper() ? ROUTE_ITEMS : storage.menus;
  const activeMenuBar = {
    key: [url],
    openKey: [getActiveKey(url, menus)],
  };

  return (
    <Menu
      theme='dark'
      mode='inline'
      // mode={isHorizontal ? 'horizontal' : 'inline'}
      defaultOpenKeys={activeMenuBar.openKey}
      defaultSelectedKeys={activeMenuBar.key}
    >
      {/* {mainLogo && mainLogo} */}
      {menus.map((keyMenu) => {
        if (!keyMenu.subData?.length) {
          return (
            <Menu.Item key={keyMenu.subData.to} icon={keyMenu.icon}>
              <NavLink to={keyMenu.subData.to}>{keyMenu.subData.inner}</NavLink>
            </Menu.Item>
          );
        }
        return (
          <SubMenu key={keyMenu.key || keyMenu.sub} icon={keyMenu.icon} title={keyMenu.title}>
            {keyMenu.subData.map(({ to, inner }) => (
              <Menu.Item key={to} style={{ paddingLeft: '-10px' }} className='sub-menu-layout'>
                <NavLink to={to}>
                  <div style={{ fontSize: '11.5px', marginLeft: '0px' }}>{inner}</div>
                </NavLink>
              </Menu.Item>
            ))}
          </SubMenu>
        );
      })}
    </Menu>
  );
});
