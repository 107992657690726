import { useEffect, useState } from 'react';
import { ExpandedRowRender } from './expanded-row-render';
import ApiServices from '~services';

export const SubRow = ({ id, expanded }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getOrders = async () => {
    try {
      setLoading(true);
      let { data } = await ApiServices.getOrderDetailsId(id);
      const dataNew = data.listCont.map((item, index) => {
        item.key = index;
        return item;
      });
      setData(dataNew);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    expanded && getOrders();
  }, [id, expanded]);

  return <ExpandedRowRender loading={loading} reload={getOrders} dataTest={data} />;
};
