import actionTypes from './type';
import ApiServices from '~services';
import { DEFAULT_QUERY } from './index';

export function fetchNotifications(query = DEFAULT_QUERY) {
  return async (dispatch) => {
    try {
      const { data } = await ApiServices.getLogNotifications(query);
      return dispatch({
        data: data,
        type: actionTypes.FETCH_DATA,
      });
    } catch (error) {
      console.error('fetchLiftOn-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_DATA });
  };
}
export function updateOne(data) {
  return (dispatch) => dispatch({ data, type: actionTypes.UPDATE_ONE_DATA });
}
export function loadMore(query = {}) {
  return async (dispatch) => {
    try {
      const { data } = await ApiServices.getLogNotifications(query);
      return dispatch({
        data: data,
        type: actionTypes.LOAD_MORE_DATA,
      });
    } catch (error) {
      console.error('fetchWaitingOfPlan-loadMore-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_DATA });
  };
}
export function fetchCounterNotRead() {
  return async (dispatch) => {
    try {
      const { data } = await ApiServices.getCountNotificationNotRead();
      return dispatch({
        data: data.totalNotifications_NotRead,
        type: actionTypes.FETCH_COUNTER_DATA,
      });
    } catch (error) {
      console.error('fetchWaitingOfPlan-loadMore-ERR', error);
    }
    return dispatch({ data: 0, type: actionTypes.FETCH_COUNTER_DATA });
  };
}
export function updateQuery(query) {
  return (dispatch) => dispatch({ query, type: actionTypes.UPDATE_QUERY });
}
export function updateLoading(loading = false) {
  return (dispatch) => dispatch({ loading, type: actionTypes.UPDATE_LOADING });
}
export function updateSelected(selected = null) {
  return (dispatch) => dispatch({ selected, type: actionTypes.UPDATE_SELECTED });
}
