export default {
  SHOW_LIST_FILE: 'white_board_show_list_file',
  SHOW_ASSIGN_CAR_TODAY: 'white_board_show_assign_car_today',
  ASSIGN_DATA_CHANGED: 'wb_assign-data-assign-changed',
  ASSIGN_ITEM_CHANGED: 'wb_assign-item-assign-changed',
  ASSIGN_ITEM_DELETE: 'wb_assign-item-assign-delete',
  ASSIGN_ROMOOC_ADDED_CHANGED: 'wb_assign-romooc-added-changed',
  ASSIGN_CAR_CHANGED: 'wb_assign-car-assign-changed',
  ASSIGN_LOCATIONS_CHECK_DISTANCES: 'wb_assign-locations-check-distances',
  ASSIGN_LOCATIONS_DISTANCE_DATA_CHANGE: 'wb_assign_location_distance_data_changed',
  ASSIGN_FINAL_TASK_CHANGED: 'wb_assign-final-task-changed',
  ASSIGN_ROMOOC_DATA_CHANGED: 'assign-romooc-id-changed',
  ASSIGN_DRIVER_CHANGED: 'wb_assign-driver-assign-changed',
  ASSIGN_LOADING_CHANGED: 'wb_assign_loading_changed',
  SHOW_CUSTOMER_DETAIL: 'wb_show_customer_detail',
};
