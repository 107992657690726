import { createElement, useEffect, useState } from 'react';
import { TableLayout } from '~components';
import ApiServices from '~services';
import { FormInput } from './form-input';
import styled from 'styled-components';
import { Button, message, Popconfirm } from 'antd';
import * as antdIcons from '@ant-design/icons';
import { MenuFunction } from './menu-function';
import Storage from '~storage';
export const Menus = () => {
  const [loading, setLoading] = useState(false);
  const [loadingTab, setLoadingTab] = useState(false);
  const [dataRow, setDataRow] = useState([]);
  const [selected, setSelected] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const getData = async () => {
    setLoading(true);
    return ApiServices.getMenus()
      .then((res) => {
        const menus = [];
        const keys = [];
        if (res?.data) {
          for (let el of res.data) {
            menus.push({
              ...el,
              children: el.childs,
            });
            // el.childs?.length && keys.push(el.id);
          }
        }

        setDataRow(menus);
        setExpandedRowKeys(keys);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setDataRow([]);
      });
  };
  const getMenuDetail = (id) => {
    setLoadingTab(true);
    return ApiServices.getMenu(id || selected?.id)
      .then((res) => {
        if (res.data?.data) {
          setSelected({ ...res.data.data.detail, ...res.data.data });
        }
      })
      .catch((err) => console.error('get Detail Group Error', err))
      .finally(() => setLoadingTab(false));
  };

  const onClickShowDetail = (e, record) => {
    e.preventDefault();
    setSelected(record);
  };
  const onClickDelete = (record) => {
    return ApiServices.deleteMenu(record.id)
      .then(() => {
        getData();
        message.success('Thao tác thành công!');
      })
      .catch((err) => {
        console.error('update Menu', err);
        message.error('Thao tác thất bại');
      });
  };
  const columns = [
    {
      title: 'STT',
      dataIndex: 'sort',
      width: 60,
    },
    {
      title: 'Tên',
      dataIndex: 'name_vi_VN',
      render: (val, record) => (
        <a
          href='#'
          onClick={(e) => onClickShowDetail(e, record)}
          className={record.childs?.length && 'bold'}
        >
          {val}
        </a>
      ),
    },
    {
      title: 'Url',
      dataIndex: 'url',
    },

    {
      title: 'Cấp độ',
      dataIndex: 'level',
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      render: (val) =>
        val && (
          <>
            {antdIcons?.[val] && createElement(antdIcons[val])} - {val}
          </>
        ),
    },
    {
      title: 'App',
      dataIndex: 'keyApp',
    },
    {
      title: '#',
      dataIndex: 'action',
      render: (_, record) => (
        <Popconfirm
          className='red pointer'
          title='Xác nhận xóa!'
          onConfirm={() => onClickDelete(record)}
        >
          Xóa
        </Popconfirm>
      ),
    },
  ];
  const onCloseEditTab = () => setSelected();

  useEffect(() => {
    if (!selected?.id) {
      return;
    }
    getMenuDetail(selected.id);
  }, [selected?.id]);

  useEffect(() => {
    getData();
  }, []);

  const expandable = { expandedRowKeys, onExpandedRowsChange: setExpandedRowKeys };
  const rowSelection = {
    checkStrictly: true,
  };
  const createNew = () => setSelected({});
  const headerContent = (
    <Button type='primary' onClick={createNew}>
      Thêm Mới
    </Button>
  );

  return (
    <>
      <TableStyled
        rowKey='id'
        columns={columns}
        data={dataRow}
        headerContent={headerContent}
        loading={loading}
        titleTab={selected?.name_vi_VN || 'Thêm Mới'}
        expandable={expandable}
        rowSelection={rowSelection}
        width={800}
        tabPanels={
          Storage?.user?.code === 'trumcuoi'
            ? [
                {
                  title: 'Cập nhật thông tin',
                  content: (
                    <FormInput
                      dataInput={selected}
                      reload={getData}
                      onClose={onCloseEditTab}
                      parents={dataRow?.filter?.((el) => el.level === 0)}
                    />
                  ),
                },
                {
                  title: 'Quản lý chức năng',
                  content: (
                    <MenuFunction
                      dataInput={selected}
                      reload={getMenuDetail}
                      onLoading={setLoadingTab}
                    />
                  ),
                },
              ]
            : [
                {
                  title: 'Cập nhật thông tin',
                  content: (
                    <FormInput
                      dataInput={selected}
                      reload={getData}
                      onClose={onCloseEditTab}
                      parents={dataRow?.filter?.((el) => el.level === 0)}
                    />
                  ),
                },
              ]
        }
        isLoadingTab={loadingTab}
        closeDrawer={onCloseEditTab}
        selected={selected}
        isDisplayCheckbox={false}
      />
    </>
  );
};

const TableStyled = styled(TableLayout)`
  & .ant-table-row-indent.indent-level-1 {
    display: none;
  }
  & button.ant-table-row-expand-icon-spaced {
    display: none;
  }
`;
