import axios from 'axios';
// import { Endpoint} from '~services/endpoints';
import Utils from '~commons/utils';
import storage from '~storage';

export default class ApiClient {
  constructor({ baseURL, logOutput = true, pathPrefix = '' }) {
    this.instance = axios.create({
      baseURL,
      timeout: 300000,
    });
    if (logOutput) {
      this.instance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (window.location.pathname !== '/login' && error.response?.status === 401) {
            window.location.replace('/login');
            return;
          }
          console.warn('error response: ', error);
          (typeof error?.response?.data == 'string' &&
            window.TmsNoti?.error('Thao tác thất bại', '', 5)) ||
            (typeof error?.response?.data?.message == 'string' &&
              window.TmsNoti?.error(error.response.data.message)) ||
            console.warn(error.message);
          return error;
        }
      );
    }
    this.pathPrefix = pathPrefix || '';
    this.refreshTokenRequest = null;
    this.isExpiredToken = false;
  }

  request = async (
    method,
    path,
    requestHeaders,
    params = null,
    data = null,
    authenticate = false,
    isFormData = false,
    isFormatData = true
  ) => {
    const headers = requestHeaders || {};
    if (authenticate || storage.user) {
      headers.Authorization = `Bearer ${storage.user.token}`;
    }
    return this.instance.request({
      method,
      url: this.pathPrefix + path,
      params: Utils.removeEmptyAttributes(params),
      data: isFormData || !isFormatData ? data : Utils.removeEmptyAttributes(data),
      headers,
    });
  };

  requestCustom = async (
    method,
    path,
    requestHeaders,
    params = null,
    data = null,
    authenticate = false
  ) => {
    const headers = requestHeaders || {};
    if (authenticate || storage.user) {
      headers.Authorization = `Bearer ${storage.user.token}`;
    }
    return this.instance.request({
      method,
      url: this.pathPrefix + path,
      params: Utils.removeEmptyAttributes(params),
      data: data,
      headers,
    });
  };

  requestCustomBlob = async (
    method,
    path = '',
    requestHeaders,
    params = null,
    data = null,
    authenticate = false,
    fullPath = null
  ) => {
    const headers = requestHeaders || {};
    if (authenticate || storage.user) {
      headers.Authorization = `Bearer ${storage.user.token}`;
    }
    return this.instance.request({
      method,
      url: fullPath || this.pathPrefix + path,
      params: Utils.removeEmptyAttributes(params),
      data: data,
      headers,
      responseType: 'blob',
    });
  };

  postCustomBlob = async (
    path,
    data = null,
    params = null,
    authenticate = false,
    requestHeaders = null
  ) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.requestCustomBlob('POST', path, headers, params, data, authenticate);
  };

  getCustomBlod = async (path, params = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.requestCustomBlob('GET', path, headers, params, null, authenticate);
  };

  putCustom = async (path, data = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.requestCustom('PUT', path, headers, null, data, authenticate);
  };
  postCustom = async (
    path,
    data = null,
    params = null,
    authenticate = false,
    requestHeaders = null
  ) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.requestCustom('POST', path, headers, params, data, authenticate);
  };

  getCustom = async (path, params = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.requestCustom('GET', path, headers, params, null, authenticate);
  };

  get = async (path, params = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.request('GET', path, headers, params, null, authenticate);
  };

  post = async (
    path,
    data = null,
    authenticate = false,
    requestHeaders = null,
    isFormatData = true
  ) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.request('POST', path, headers, null, data, authenticate, false, isFormatData);
  };

  postFormData = async (path, data = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'multipart/form-data', ...headers };
    return this.request('POST', path, headers, null, data, authenticate, true);
  };
  postFileConverted = async (path, file, authenticate = false, requestHeaders = null) => {
    const data = new FormData();
    data.append('file', file);
    return this.postFormData(path, data, authenticate, requestHeaders);
  };
  putFormData = async (path, data = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'multipart/form-data', ...headers };
    return this.request('PUT', path, headers, null, data, authenticate, true);
  };
  put = async (path, data = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.request('PUT', path, headers, null, data, authenticate);
  };

  delete = async (path, data = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.request('DELETE', path, headers, null, data, authenticate);
  };
  deleteCustom = async (path, data = null, authenticate = false, requestHeaders = null) => {
    let headers = requestHeaders || {};
    headers = { 'Content-Type': 'application/json', ...headers };
    return this.requestCustom('DELETE', path, headers, null, data, authenticate);
  };
}
