import React from 'react';
import { Space } from 'antd';
export const OrderItem = ({ title = '', countOrder = 0, handleClick }) => {
  return (
    <Space>
      <div>{title}</div>
      <b>
        <b className='red'>{countOrder}</b>
      </b>
      <span>Booking</span>
      <a
        style={{
          marginLeft: 'auto',
          fontSize: '14px',
          marginRight: '15px',
          color: 'red',
        }}
        onClick={handleClick}
      >
        Xử lí
      </a>
    </Space>
  );
};
