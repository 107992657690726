import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Modal, DatePicker } from 'antd';
import { layout, tailLayout, tailLayoutAddJob } from './constant';
import ApiServices from '~services';
import { Selection } from '~components';
import { CreateDistanceLocation } from './create-distance-location';
import { DATE_FORMAT } from '~commons/constants';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

const TaskJobExtrasForm = ({ fields, reload, onChange, dataRow, isNew }) => {
  const [form] = Form.useForm();
  const [cars, setCars] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCheckDistance, setLoadingCheckDistance] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [isNotDistanceList, setIsNotDistanceList] = useState([]);
  const [showCreateDistance, setShowCreateDistance] = useState(false);
  const [values, setValues] = useState({});

  const [carId] = useState('');
  const [driverLabel, setDriverLabel] = useState('');

  useEffect(() => {
    if (isNew) {
      form.resetFields();
      setDriverLabel('');
    }
  }, [isNew]);

  const onCloseModal = () => {
    setShowCreateDistance(false);
    setLoadingCheckDistance(false);
  };

  const getCars = async () => {
    try {
      setLoading(true);
      let { data } = await ApiServices.getCars();
      setLoading(false);
      setCars(data.data);
    } catch (error) {
      console.log('Error - get cars', error);
      console.error(error);
    }
  };
  const getDrivers = async (query = '') => {
    return ApiServices.getDrivers({ query })
      .then(({ data }) => {
        setDrivers(data.data);
      })
      .catch((err) => console.error(err));
  };
  const getLocations = async (query = '') => {
    return ApiServices.getLocations({ query })
      .then(({ data }) => {
        setLocations(data.data);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    getCars();
    getDrivers();
    getLocations();
  }, []);

  useEffect(() => {
    if (!isNew && dataRow?.driverName) {
      setDriverLabel(dataRow?.driverName);
    } else {
      setDriverLabel('');
    }
  }, [dataRow?.driverName]);

  const handleReload = async () => {
    form.resetFields();
    setDriverLabel('');
    reload();
  };

  const onSearchFn = async (text) => {
    const dataSend = JSON.stringify({ code: text ? text : '' });
    try {
      setLoading(true);
      let { data } = await ApiServices.getCars({ Columns: dataSend });
      setLoading(false);
      setCars(data.data);
    } catch (error) {
      console.log('Error - get cars', error);
      console.error(error);
    }
  };
  // Create Task Job Extras
  const createTaskJobExtras = async (data) => {
    const dataSend = {
      carId: data?.carId,
      driverId: data?.driverId,
      assignOn: data?.assignOn,
      jobs: data?.jobs,
    };
    setLoadingCreate(true);
    return ApiServices.createTaskJobExtras(dataSend)
      .then((res) => {
        if (res?.data) {
          message.success('Thêm mới thành công');
          return handleReload();
        } else {
          message.error('Thêm mới thất baị, vui lòng kiểm tra lại thông tin');
        }
      })
      .catch((err) => {
        console.log('Create Task Job Extras Failed:', err);
        message.error('Thêm mới thất baị, vui lòng kiểm tra lại thông tin');
      })
      .finally(() => {
        setLoadingCreate(false);
        setValues({});
        setIsNotDistanceList([]);
      });
  };

  //  Check & Create Distance Location
  const handleCreateDistance = async (dataList, valueCreate) => {
    if (!valueCreate) return;

    const dataSend = dataList?.map((item) => {
      return {
        locationFromCode: item.begin,
        locationToCode: item.end,
        distanceKm: item.distanceKm,
        loss: item.loss || 0,
        companyId: JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId,
      };
    });
    setLoadingCreate(true);
    return ApiServices.createDistanceMultiple(dataSend)
      .then((res) => {
        if (res.data) {
          return createTaskJobExtras(valueCreate);
        } else {
          setLoadingCreate(false);
        }
      })
      .catch((err) => {
        console.log('Failed to create distance for location:', err);
        message.error('Thêm mới Cự Ly thất baị, vui lòng kiểm tra lại thông tin');
        setLoadingCreate(false);
      })
      .finally(() => {
        setShowCreateDistance(false);
      });
  };
  const checkDistances = async (value) => {
    let checkDuplicateLocation = [];
    const arrayLocation = value?.jobs
      ?.map((item) => {
        const dataCheckString = `${item?.beginLocationCode}-${item?.endLocationCode}`;

        if (checkDuplicateLocation.includes(dataCheckString)) return;

        checkDuplicateLocation.push(dataCheckString);
        return {
          begin: item?.beginLocationCode,
          end: item?.endLocationCode,
        };
      })
      ?.filter((el) => el);

    try {
      const { data, status } = await ApiServices.checkIssetDistances(arrayLocation);
      if (status === 200) {
        if (data?.data?.length) {
          setValues(value);
          setIsNotDistanceList(data?.data || []);
          setShowCreateDistance(true);
        } else {
          return createTaskJobExtras(value);
        }
      }
    } catch (err) {
      console.error('checkDistances', err);
    } finally {
      setLoadingCheckDistance(false);
    }
  };

  const onSubmitForm = async (value) => {
    if (loadingCheckDistance) return;
    setLoadingCheckDistance(true);
    await checkDistances(value);
  };

  const handleChangeCar = (value) => {
    if (value) {
      const ind = cars?.find((item) => item.id === value);
      if (ind) {
        form.setFieldsValue({
          driverId: ind?.driverMainCode,
        });
        setDriverLabel(ind?.driverMainName);
      } else {
        form.setFieldsValue({
          driverId: '',
        });
        setDriverLabel('');
      }
    }
  };

  const disabledSubmit = isNotDistanceList?.findIndex((item) => item?.distanceKm) === -1;

  return (
    <Form
      {...layout}
      form={form}
      name='global_state'
      className='form-input-data'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        name='assignOn'
        label='Ngày điều xe'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn ngày điều xe',
          },
        ]}
      >
        <DatePicker placeholder='Ngày điều xe' style={{ width: '100%' }} format={DATE_FORMAT} />
      </Form.Item>
      <Form.Item
        style={{ marginTop: '10px' }}
        name='carId'
        label='Xe'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập chọn xe',
          },
        ]}
      >
        <Selection
          data={cars}
          placeholder='Chọn Số Xe'
          fieldValue='code'
          value={carId}
          onChange={handleChangeCar}
          labelDefault={dataRow?.carCode}
          onSearch={onSearchFn}
        />
      </Form.Item>
      <Form.Item
        name='driverId'
        label='Tài xế'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập tài xế',
          },
        ]}
      >
        <Selection
          data={drivers}
          placeholder='Chọn tài xế'
          fieldValue='name'
          labelDefault={driverLabel}
          onSearch={getDrivers}
        />
      </Form.Item>
      {isNew ? (
        <>
          <Form.List
            name='jobs'
            initialValue={[
              {
                name: '',
                beginLocationCode: '',
                endLocationCode: '',
                note: '',
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      margin: '10px',
                      paddingTop: '10px',
                      backgroundColor: 'rgb(234, 238, 243)',
                      boxShadow:
                        'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                      borderRadius: '10px',
                      fontSize: '12px',
                      padding: '10px',
                      marginBottom: '20px',
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{
                        width: '30px',
                        height: '30px',
                        // backgroundColor: 'red',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        transform: 'translateY(-30%)',
                        borderRadius: '50%',
                      }}
                    >
                      {fields?.length > 1 ? (
                        <>
                          {' '}
                          <Button
                            type='primary'
                            shape='circle'
                            danger
                            icon={<CloseOutlined />}
                            onClick={() => remove(name)}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <Form.Item
                      label='Tên công việc'
                      {...restField}
                      name={[name, 'name']}
                      rules={[{ required: true, message: 'Vui lòng nhập tên công việc' }]}
                    >
                      <Input placeholder='Tên công việc' />
                    </Form.Item>
                    <Form.Item
                      label='Địa điểm bắt đầu'
                      {...restField}
                      name={[name, 'beginLocationCode']}
                      rules={[{ required: true, message: 'Bạn phải chọn địa điểm bắt đầu' }]}
                    >
                      <Selection
                        data={locations}
                        placeholder='Chọn địa điểm bắt đầu'
                        fieldValue='name'
                        labelDefault={dataRow?.beginLocationName}
                        onSearch={getLocations}
                      />
                    </Form.Item>
                    <Form.Item
                      label='Địa điểm kết thúc'
                      {...restField}
                      name={[name, 'endLocationCode']}
                      rules={[{ required: true, message: 'Bạn phải chọn địa điểm kết thúc' }]}
                    >
                      <Selection
                        data={locations}
                        placeholder='Chọn địa điểm kết thúc'
                        fieldValue='name'
                        labelDefault={dataRow?.endLocationName}
                        onSearch={getLocations}
                      />
                    </Form.Item>
                    <Form.Item label='Ghi chú' {...restField} name={[name, 'note']}>
                      <Input placeholder='Ghi chú' />
                    </Form.Item>
                  </div>
                ))}
                <Form.Item {...tailLayoutAddJob} className='submit-form-input-data'>
                  <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                    Thêm công việc
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </>
      ) : (
        <>
          <Form.Item
            name='name'
            label='Tên công việc'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Tên công việc',
              },
            ]}
          >
            <Input placeholder='Tên công việc' />
          </Form.Item>
          <Form.Item
            name='beginLocationCode'
            label='Địa điểm bắt đầu'
            rules={[
              {
                required: true,
                message: 'Bạn phải chọn địa điểm bắt đầu',
              },
            ]}
          >
            <Selection
              data={locations}
              placeholder='Chọn địa điểm bắt đầu'
              fieldValue='name'
              labelDefault={dataRow?.beginLocationName}
              onSearch={getLocations}
            />
          </Form.Item>
          <Form.Item
            name='endLocationCode'
            label='Địa điểm kết thúc'
            rules={[
              {
                required: true,
                message: 'Bạn phải chọn địa điểm kết thúc',
              },
            ]}
          >
            <Selection
              data={locations}
              placeholder='Chọn địa điểm kết thúc'
              fieldValue='name'
              labelDefault={dataRow?.endLocationName}
              onSearch={getLocations}
            />
          </Form.Item>
          {/* <Form.Item
        name='updateLocation'
        valuePropName='checked'
        wrapperCol={{
          offset: 4,
          span: 16,
        }}
      >
        <Checkbox>Cập nhật địa điểm sau khi điều</Checkbox>
      </Form.Item> */}
          <Form.Item name='note' label='Ghi chú'>
            <Input placeholder='Ghi chú' />
          </Form.Item>
        </>
      )}

      {isNew && (
        <Form.Item {...tailLayout} className='submit-form-input-data'>
          <Button
            type='primary'
            htmlType='submit'
            loading={loadingCreate || loading || loadingCheckDistance}
          >
            Thêm Mới
          </Button>
        </Form.Item>
      )}

      <Modal
        width={750}
        title='Địa điểm chưa có khoảng cách'
        open={!!showCreateDistance}
        onCancel={onCloseModal}
        okButtonProps={{
          disabled: disabledSubmit,
        }}
        onOk={() => handleCreateDistance(isNotDistanceList, values)}
      >
        <CreateDistanceLocation
          isNotDistanceList={isNotDistanceList}
          setIsNotDistanceList={setIsNotDistanceList}
        />
      </Modal>
    </Form>
  );
};

export { TaskJobExtrasForm };
