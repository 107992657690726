import React, { useEffect, useState, useMemo } from 'react';
import { Button, DatePicker, Input, Col, Row, Modal } from 'antd';
import moment from 'moment';
import { uid } from 'uid';
import styled from 'styled-components';
import { FormTourItem } from './form-tour-item';
import { Selection } from '~components';
import ApiServices from '~services';
import { DATE_TIME_FORMAT } from '~commons/constants';
import Utils from '~commons/utils';
import checkContNumber from '~commons/check-cont-number';
const ASSIGN_FORM_DEFAULT = {
  contId: '',
  romoocId: '',
  romoocLocationId: '',
  isRomoocOutSite: false,
  pickupLocation: '',
  deliveryLocation: '',
  contNumber: '',
  startJob: [],
  endJob: [],
};

const AssignTransit = () => {
  const [assignTransitTourList, setAssignTransitTourList] = useState([
    { ...ASSIGN_FORM_DEFAULT, id: uid(32) },
  ]);

  const [loading, setLoading] = useState(false);
  const [carList, setCarList] = useState([]);
  const [carId, setCarId] = useState(null);
  const [currentLocation, setCurrentLocation] = useState('');
  const [currentLocationId, setCurrentLocationId] = useState(null);
  const [driverList, setDriverList] = useState([]);
  const [driverMainId, setDriverMainId] = useState(null);
  const [driverMainName, setDriverMainName] = useState('');
  const [assignDate, setAssignDate] = useState(moment());

  const [locationNotDistance, setLocationNotDistance] = useState([]);

  const disabledSubmit = useMemo(() => {
    return locationNotDistance?.findIndex((item) => item?.distanceKm) === -1;
  }, [locationNotDistance]);

  const onCloseModalCheckDistance = () => {
    setLocationNotDistance([]);
  };

  const getCars = async (text = '') => {
    const dataSend = JSON.stringify({ code: text });
    try {
      setLoading(true);
      let { data } = await ApiServices.getCars({ Columns: dataSend });
      setCarList(data?.data);
    } catch (error) {
      console.log('Error - get cars', error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getDrivers = async (query = '') => {
    return ApiServices.getDrivers({ query })
      .then(({ data }) => {
        setDriverList(data?.data);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    getCars();
    getDrivers();
  }, []);

  useEffect(() => {
    if (!carId) {
      setDriverMainId('');
      setDriverMainName('');
    }
  }, [carId]);

  const reload = async () => {
    setAssignTransitTourList([{ ...ASSIGN_FORM_DEFAULT, id: uid(32) }]);
    setCarId('');
    setCurrentLocation('');
    setCurrentLocationId('');
    setDriverMainId('');
    setDriverMainName('');
    setAssignDate(moment());
    await getCars();
  };

  const submitAssignTransitFinal = async () => {
    let formAssignList = [];
    assignTransitTourList?.forEach((item) => {
      // Start

      // Center
      formAssignList.push({
        contId: item?.contId,
        romoocId: item?.romoocId,
        romoocLocationId: item?.romoocLocationId,
        pickupLocation: item?.pickupLocation,
        deliveryLocation: item?.deliveryLocation,
        contNumber: item?.contNumber,
      });
      // End
      if (item?.endJob?.length > 0) {
        item?.endJob?.forEach((itemStart) => {
          formAssignList.push({
            jobName: itemStart?.jobName,
            isMain: false,
            contId: item?.contId,
            pickupLocation: itemStart?.locationId,
            deliveryLocation: itemStart?.locationId,
          });
        });
      }
    });
    const dataSend = {
      formAssigns: formAssignList,
      // assignDate: moment(assignDate).format(DATE_TIME_FORMAT),
      assignDate: assignDate,
      driverId: driverMainId,
    };

    setLoading(true);
    return ApiServices.createAssignTransit(carId, dataSend)
      .then((res) => {
        if (res?.data?.data === 'success') {
          reload();
          window.TmsNoti?.success('Thành công', 'Điều chuyển kho thành công');
        } else {
          window.TmsNoti?.error('Lỗi', 'Điều chuyển kho thất bại');
        }
      })
      .catch((err) => {
        console.log({ err });
        window.TmsNoti?.error('Lỗi', 'Điều chuyển kho thất bại');
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitAssignTransit = async () => {
    let showError = false;
    if (!carId || !driverMainId || !assignDate) {
      showError = true;
    }
    let locationCheck = [];
    // Current Car Location
    locationCheck.push(currentLocationId);

    assignTransitTourList?.forEach((item) => {
      if (
        !item?.contId ||
        !item.romoocId ||
        !item.romoocLocationId ||
        !item?.pickupLocation ||
        !item.deliveryLocation ||
        (item.isRomoocOutSite && !item?.contNumber)
      ) {
        showError = true;
        return;
      }

      if (item?.isRomoocOutSite && item?.contNumber && !checkContNumber(item?.contNumber)) {
        showError = true;
        return;
      }
      locationCheck.push(item.romoocLocationId);
      // Start

      // Center Main
      locationCheck.push(item.pickupLocation);
      locationCheck.push(item.deliveryLocation);
      // End
      if (item?.endJob?.length > 0) {
        item?.endJob?.forEach((itemStart) => {
          if (!itemStart?.jobName || !itemStart.locationId) {
            showError = true;
            return;
          }
          locationCheck.push(itemStart.locationId);
        });
      }
    });
    if (showError) {
      return window.TmsNoti?.error('Lỗi', 'Vui lòng nhập đầy đủ thông tin');
    }

    const dataCheckDistance = locationCheck
      ?.map((itemLocation, index) => {
        if (index === 0) return;
        if (locationCheck[index - 1] === itemLocation) return;
        return {
          begin: locationCheck[index - 1],
          end: itemLocation,
          check: locationCheck[index - 1] + '-' + itemLocation,
        };
      })
      .filter((data) => data);
    const dataArrCheck = dataCheckDistance.map((item) => item.check);
    const dataCheckDistanceFinal = Utils.uniqueCheckDistance(dataArrCheck).map((data) => {
      return {
        begin: data.split('-')[0],
        end: data.split('-')[1],
      };
    });

    setLoading(true);
    return ApiServices.checkIssetDistances(dataCheckDistanceFinal)
      .then((res) => {
        if (res?.data?.data) {
          setLocationNotDistance(res?.data?.data);
          return;
        }
        return submitAssignTransitFinal();
      })
      .catch((err) => {
        console.log({ err });
        window.TmsNoti?.error('Lỗi', 'Chưa có khoảng cách');
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitCreateNewDistance = async () => {
    const dataSend = locationNotDistance?.map((item) => {
      return {
        locationFromCode: item.begin,
        locationToCode: item.end,
        distanceKm: item.distanceKm,
        loss: item.loss || 0,
        companyId: JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId,
      };
    });
    return ApiServices.createDistanceMultiple(dataSend)
      .then((res) => {
        if (res.data) {
          submitAssignTransitFinal();
          onCloseModalCheckDistance();
        }
      })
      .catch((err) => {
        console.log({ err });
        window.TmsNoti?.error('Lỗi', 'Tạo mới cự ly thất bại');
      });
  };

  const handleChangeCar = (id) => {
    const carSelected = carList?.find((item) => item.id === id);
    if (carSelected) {
      setCurrentLocation(carSelected?.currentLocationName);
      setCurrentLocationId(carSelected?.currentLocationCode);
      setDriverMainId(carSelected?.driverMainCode);
      setDriverMainName(carSelected?.driverMainName);
    } else {
      setCurrentLocation('');
      setDriverMainId('');
      setDriverMainName('');
    }
    setCarId(id);
  };
  const handleChangeDriverMain = (id) => {
    setDriverMainId(id);
  };
  const handleChangeAssignDate = (date) => {
    setAssignDate(date);
  };

  const renderHeaderAssignTransit = () => {
    return (
      <>
        <Row gutter={16}>
          <Col span={6}>
            Xe <b className='red'>(*)</b> {!carId && <i className='red'>Vui lòng chọn Xe</i>}
            <Selection
              status={!carId ? 'error' : ''}
              data={carList}
              placeholder='Chọn Xe'
              fieldValue='code'
              value={carId}
              onChange={handleChangeCar}
              onSearch={getCars}
            />
          </Col>
          <Col span={6}>
            Vị trí bắt đầu
            <Input value={currentLocation} disabled />
          </Col>
        </Row>
        <Row gutter={16}>
          {carId ? (
            <Col span={6}>
              Tài xế <b className='red'>(*)</b>
              <Selection
                status={!driverMainId ? 'error' : ''}
                data={driverList}
                placeholder='Tài Xế'
                fieldValue='name'
                labelDefault={driverMainName}
                value={driverMainId}
                onChange={handleChangeDriverMain}
                onSearch={getDrivers}
                allowClear={false}
              />
            </Col>
          ) : (
            <Col span={6}></Col>
          )}

          <Col span={6}>
            Phụ xế
            <Input />
          </Col>
          <Col span={6}>
            Ngày điều xe <b className='red'>(*)</b>
            <DatePicker
              status={!assignDate ? 'error' : ''}
              placeholder='Ngày điều xe'
              showTime={{ format: 'HH:mm' }}
              style={{ width: '100%' }}
              format={DATE_TIME_FORMAT}
              value={assignDate}
              onChange={handleChangeAssignDate}
              allowClear={false}
            />
          </Col>
        </Row>
      </>
    );
  };

  const handleAddNewTour = () => {
    setAssignTransitTourList((prev) => {
      return [...prev, { ...ASSIGN_FORM_DEFAULT, id: uid(32) }];
    });
  };

  const handleDeleteTour = (tourId) => {
    const removeTour = assignTransitTourList?.filter((item) => item?.id !== tourId);
    setAssignTransitTourList(removeTour);
  };

  const renderAssignTransitTour = (item, index) => {
    const hasCreateNew = index === assignTransitTourList?.length - 1;
    return (
      <Col span={12} key={item?.id}>
        <FormTourItem
          tourIndex={index}
          hasCreateNew={hasCreateNew}
          dataItem={item}
          handleAddNewTour={handleAddNewTour}
          handleDeleteTour={handleDeleteTour}
          assignTransitTourList={assignTransitTourList}
          setAssignTransitTourList={setAssignTransitTourList}
        />
      </Col>
    );
  };

  const onChangeChange = (value, index, type) => {
    if (isNaN(value)) return;
    const newCheckDistance = locationNotDistance?.map?.((el) => el);
    newCheckDistance[index][type] = +value;
    setLocationNotDistance(newCheckDistance);
  };

  const renderInputs = (item, index) => {
    return (
      <Row
        gutter={24}
        key={`${index.begin}${item.end}`}
        align='middle'
        style={{ marginTop: '8px' }}
      >
        <Col span={7}>{item?.beginName}</Col>
        <Col span={1}>{'>'}</Col>
        <Col span={7}>{item?.endName}</Col>
        <Col span={4}>
          <InputStyled
            defaultValue={0}
            min={0}
            isError={!item?.distanceKm}
            className='red'
            size='small'
            value={item?.distanceKm}
            onChange={({ target }) => onChangeChange(target.value, index, 'distanceKm')}
            placeholder='Cự ly'
          />
        </Col>
        <Col span={4}>
          <InputStyled
            defaultValue={0}
            className='red'
            size='small'
            value={item?.loss}
            min={0}
            onChange={({ target }) => onChangeChange(target.value, index, 'loss')}
            placeholder='Hao hụt'
          />
        </Col>
      </Row>
    );
  };
  return (
    <>
      <h1>ĐIỀU CHUYỂN KHO</h1>
      {renderHeaderAssignTransit()}
      <Row gutter={16}>
        {assignTransitTourList && assignTransitTourList?.map(renderAssignTransitTour)}
      </Row>
      <div
        style={{
          display: 'flex',
          margin: '5px auto',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {assignTransitTourList?.length > 0 ? (
          <Button
            type='primary'
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0px 20px',
            }}
            loading={loading}
            onClick={handleSubmitAssignTransit}
          >
            Tạo Chuyển Kho
          </Button>
        ) : (
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0px 20px',
              textAlign: 'center',
            }}
          >
            <Button
              type='primary'
              style={{
                padding: '0px 20px',
              }}
              onClick={handleAddNewTour}
            >
              Thêm Tour
            </Button>
            <br />
            <div
              style={{
                marginTop: '10px',
              }}
            >
              <i className='red'>Vui lòng thêm Tour để tạo đơn chuyển kho</i>
            </div>
          </div>
        )}
      </div>
      <Modal
        width={650}
        title='Danh sách địa điểm chưa có khoảng cách'
        open={!!locationNotDistance?.length}
        onCancel={onCloseModalCheckDistance}
        okButtonProps={{
          disabled: disabledSubmit,
        }}
        onOk={handleSubmitCreateNewDistance}
      >
        <Row gutter={24}>
          <Col span={7}>
            <b>Điểm bắt đầu</b>
          </Col>
          <Col span={1}></Col>
          <Col span={7}>
            <b>Điểm kết thúc</b>
          </Col>
          <Col span={4}>
            <b>Cự ly(Km)</b>
          </Col>
          <Col span={4}>
            <b>Hao hụt(Km)</b>
          </Col>
        </Row>
        {locationNotDistance?.map((item, index) => renderInputs(item, index))}
      </Modal>
    </>
  );
};
const InputStyled = styled(Input)`
  border-color: #00bfff;
  border-radius: 5px;
  border: ${({ isError }) => (isError ? '1px solid red' : '1px solid #00BFFF')};
  padding: 4px;
`;

export { AssignTransit };
