import React, { useRef, useEffect, useState } from 'react';
import { Form, Input, Button, message, Select } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';

const { Option } = Select;

const ContTypeForm = ({ fields, isNew, id, reload, onChange, setLoading }) => {
  const inputRef = useRef(null);
  const [companies, setCompanies] = useState([]);

  const getDataInit = async () => {
    const [getCompany] = await Promise.all([ApiServices.getCompanies()]);
    setCompanies(getCompany?.data?.data);
  };

  useEffect(() => {
    getDataInit();
  }, []);

  const onCompareWithApi = (value, cb) => {
    if (inputRef.current) {
      clearTimeout(inputRef.current);
      cb();
    }
    inputRef.current = setTimeout(async () => {
      try {
        const { data } = await ApiServices.existsCodeContType(null, value);
        const isUsed = data;
        cb(isUsed);
      } catch (error) {
        console.error(error);
      }
    }, 1000);
  };

  const checkExistsCode = async (_, value) => {
    if (value.length > 2) {
      const ind = fields.findIndex((item) => {
        return item.name[0] === 'code';
      });
      if (isNew === false && value !== fields[ind].value) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Mã Loại Cont này đã tạo rồi !!!'));
        }
      }

      if (isNew === true) {
        try {
          const waitMe = () =>
            new Promise((resolve, reject) => {
              onCompareWithApi(value, (isUsed) => {
                if (typeof isUsed !== 'undefined') {
                  if (isUsed) reject();
                  resolve();
                }
              });
            });
          await waitMe();
        } catch (error) {
          return Promise.reject(new Error('Mã Loại Cont này đã tạo rồi !!!'));
        }
      }
    }
  };

  const createContType = async (data) => {
    try {
      const res = await ApiServices.createContType(data);
      if (res.data) {
        message.success('Thêm mới Loại Cont thành công');
        return reload();
      } else {
        message.error('Thêm mới Loại Cont thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới Loại Cont thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const updateContType = async (data) => {
    data.id = id;
    try {
      const res = await ApiServices.updateContType(id, data);
      if (res.data) {
        message.success('Cập nhật Loại Cont thành công');
        return reload();
      } else {
        message.error('Cập nhật Loại Cont thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật Loại Cont thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await createContType(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await updateContType(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      name='global_state'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        // {...itemLayout}
        style={{ marginTop: '10px' }}
        name='code'
        label='Mã Loại Cont'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Loại Cont',
          },
          {
            type: 'string',
            min: 3,
            message: 'Bạn phải nhập ít nhất 3 ký tự',
          },
          {
            validator: checkExistsCode,
          },
        ]}
      >
        <Input placeholder='Mã Loại Cont' />
      </Form.Item>

      <Form.Item
        style={{ marginTop: '10px' }}
        name='name'
        label='Tên Loại Cont'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Tên Loại Cont',
          },
        ]}
      >
        <Input placeholder='Tên Loại Cont' />
      </Form.Item>
      <Form.Item
        name='companyId'
        label='Công Ty'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn Công Ty',
          },
        ]}
      >
        <Select showSearch placeholder='Lựa Chọn Công Ty'>
          {companies.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { ContTypeForm };
