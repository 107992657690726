import React, { useEffect, useState, useCallback } from 'react';
import { Input, Row, Col } from 'antd';
import { debounce } from 'lodash';
import { DisplayInfo } from '~components/display-info';
import Utils from '~commons/utils';
import { TableLayout } from '~components';
import { TableTitle } from '~commons/styles';

const INIT_VALUE = {
  materialCode: '',
  materialName: '',
  supplierMaterialName: '',
  materialUnit: '',
  note: '',
};

const InfoDetails = ({ dataDetail, dataSelected }) => {
  const [originData, setOriginData] = useState([]);
  const [dataTableList, setDataTableList] = useState([]);
  const [filter, setFilter] = useState(null);
  const [defaultValueSearch, setDefaultValueSearch] = useState(INIT_VALUE);

  useEffect(() => {
    if (dataDetail) {
      setOriginData(dataDetail?.materialNote?.details);
      setDataTableList(dataDetail?.materialNote?.details);
      setDefaultValueSearch(INIT_VALUE);
    }
  }, [dataDetail]);

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;
      if (query?.materialCode) {
        if (
          !Utils.convertValSearch(item?.materialCode)?.includes(
            Utils.convertValSearch(query?.materialCode)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.materialName) {
        if (
          !Utils.convertValSearch(item?.materialName)?.includes(
            Utils.convertValSearch(query?.materialName)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.supplierMaterialName) {
        if (
          !Utils.convertValSearch(item?.supplierMaterialName)?.includes(
            Utils.convertValSearch(query?.supplierMaterialName)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.materialUnit) {
        if (
          !Utils.convertValSearch(item?.materialUnit)?.includes(
            Utils.convertValSearch(query?.materialUnit)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.note) {
        if (!Utils.convertValSearch(item?.note)?.includes(Utils.convertValSearch(query?.note))) {
          checkFilter = false;
        }
      }

      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    setDataTableList(dataFilter);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    []
  );
  useEffect(() => {
    if (originData?.length) {
      timeoutGetData(filter, originData);
    }
  }, [filter]);

  const onColumnSearch = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    setDefaultValueSearch((prev) => {
      return { ...prev, [name]: value };
    });
    setFilter((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Mã vật tư
          <Input
            name='materialCode'
            value={defaultValueSearch?.materialCode}
            onChange={onColumnSearch}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'materialCode',
      align: 'center',
      width: 100,
    },
    {
      title: (
        <TableTitle>
          Tên vật tư
          <Input
            name='materialName'
            value={defaultValueSearch?.materialName}
            onChange={onColumnSearch}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'materialName',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Nhà cung cấp
          <Input
            name='supplierMaterialName'
            value={defaultValueSearch?.supplierMaterialName}
            onChange={onColumnSearch}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'supplierMaterialName',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Đơn vị tính
          <Input
            name='materialUnit'
            value={defaultValueSearch?.materialUnit}
            onChange={onColumnSearch}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'materialUnit',
      align: 'center',
      width: 80,
    },
    {
      title: <TableTitle>Số lượng nhập</TableTitle>,
      dataIndex: 'amount',
      align: 'center',
      width: 100,
    },
    {
      title: <TableTitle>Đơn giá</TableTitle>,
      dataIndex: 'price',
      align: 'center',
      width: 80,
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: <TableTitle>VAT(%)</TableTitle>,
      dataIndex: 'vat',
      align: 'center',
      width: 80,
    },
    {
      title: <TableTitle>Thành tiền</TableTitle>,
      dataIndex: 'totalPrice',
      align: 'center',
      width: 120,
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: (
        <TableTitle>
          Ghi chú
          <Input
            name='note'
            value={defaultValueSearch?.note}
            onChange={onColumnSearch}
            placeholder='Search'
          />
        </TableTitle>
      ),
      dataIndex: 'note',
      align: 'center',
      width: 150,
    },
  ];

  return (
    <>
      <Row gutter={16}>
        <Col span={6}>
          <DisplayInfo title='Số phiếu nhập' value={dataDetail?.materialNote?.noteNumber} />
        </Col>
        <Col span={5}>
          <DisplayInfo title='Số Hoá đơn' value={dataDetail?.materialNote?.bill} />
        </Col>
        <Col span={4}>
          <DisplayInfo
            title='Ngày giao dịch'
            value={dataSelected?.tradingDate && Utils.formatDate(dataSelected?.tradingDate)}
          />
        </Col>
        <Col span={6}>
          <DisplayInfo title='Ghi chú' value={dataSelected?.note} />
        </Col>
      </Row>
      <div className='table-container-custom'>
        <TableLayout
          rowKey='id'
          columns={columns}
          data={dataTableList}
          loading={false}
          isDisplayCheckbox={false}
          isScrollX={false}
          customScrollSize='50px'
        />
      </div>
    </>
  );
};

export { InfoDetails };
