import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Geocode from 'react-geocode';
import { Spin } from 'antd';
import { SearchBar } from './search-bar';
import { Marker } from './markers';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);
const DEFAULT_CENTER = {
  lat: 10.810153461557944,
  lng: 106.67789345383305,
};
const DEFAULT_ZOOM = 11;
const DEFAULT_MAP_OPTION = {
  key: process.env.REACT_APP_GOOGLE_MAP_KEY,
  language: 'vi',
  region: 'VN',
  libraries: ['places'],
};
export const LocationInput = ({ position, onChange, address }) => {
  console.log({ position, address });
  const [center, setCenter] = useState(DEFAULT_CENTER);
  const [maker, setMarker] = useState();
  const [addressInput, setAddressInput] = useState();
  const [map, setMap] = useState({ isLoading: true });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }, []);
  useEffect(() => {
    if (address && position) {
      const center = {
        lat: position[1],
        lng: position[0],
      };
      setCenter(center);
      setMarker(center);
      setAddressInput(address);
    }
  }, [address, position]);

  const onHandleClickMap = (t) => {
    return Geocode.fromLatLng(t.lat, t.lng).then(
      (response) => {
        const pos = {
          lat: t.lat,
          lng: t.lng,
        };
        setCenter(pos);
        setMarker(pos);
        setAddressInput(response.results[0].formatted_address);
        onChange?.({ coordinates: pos, address: response.results[0] });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handlePlaceChanged = (value) => {
    console.log({ value });
    if (value.geometry) {
      const pos = {
        lat: value.geometry.location.lat(),
        lng: value.geometry.location.lng(),
      };
      setCenter(pos);
      setMarker(pos);
      setAddressInput(value.formatted_address);
      onChange?.({ coordinates: pos, address: value });
      return;
    }
    setCenter();
    setMarker();
    setAddressInput();
  };
  const handleApiLoaded = ({ maps, map }) => {
    setMap({
      isLoading: false,
      googlemaps: maps,
      map,
    });
  };

  return (
    <Spin spinning={map.isLoading}>
      <SearchBar
        onChange={handlePlaceChanged}
        address={addressInput}
        mapService={map?.googlemaps}
      />

      <div style={{ height: '400px', width: '100%', marginTop: '10px' }}>
        <GoogleMapReact
          bootstrapURLKeys={DEFAULT_MAP_OPTION}
          center={center}
          defaultZoom={DEFAULT_ZOOM}
          onClick={onHandleClickMap}
          onGoogleApiLoaded={handleApiLoaded}
          yesIWantToUseGoogleMapApiInternals
        >
          {maker && <Marker lat={maker.lat} lng={maker.lng} />}
        </GoogleMapReact>
      </div>
    </Spin>
  );
};
