import React, { useEffect, useState, useCallback } from 'react';
import { Table, Typography, Button, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import moment from 'moment';
import { Wrapper } from '~commons/styles';
import { RangeDateFilter } from '~components';
import ApiServices from '~services';

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};
const { Text } = Typography;

export const ReportOilKm = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = {
      dateFrom: query?.DateFrom,
      dateTo: query?.DateTo,
      export: false,
    };
    return ApiServices.getReportOilKm(newQuery)
      .then((res) => {
        if (res?.data?.data) {
          const mapDataFromObject = Object.keys(res?.data?.data);
          if (mapDataFromObject) {
            const mapData = mapDataFromObject?.map((item, index) => {
              return {
                ind: index + 1,
                carCode: item,
                currentDistance: item?.totalDistance - item?.totalLossDistance || 0,
                ...res?.data?.data?.[item],
              };
            });

            setData(mapData);
          }
        }
      })
      .catch((err) => {
        console.error('get list report oil km Error', err);
        setData();
      })
      .finally(() => setLoading(false));
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );

  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const columns = [
    {
      title: 'STT',
      dataIndex: 'ind',
      width: 80,
      fixed: true,
      align: 'center',
    },
    {
      title: 'Số xe',
      dataIndex: 'carCode',
      width: 120,
      fixed: true,
      align: 'center',
    },
    {
      title: 'Tài xế',
      dataIndex: 'driverName',
      width: 180,
      fixed: true,
      align: 'center',
    },
    {
      title: 'Km Hao hụt',
      dataIndex: 'totalLossDistance',
      width: 100,
      align: 'center',
    },
    {
      title: 'Tổng Km',
      dataIndex: 'totalDistance',
      width: 100,
      align: 'center',
    },
    {
      title: 'Km thực tế',
      dataIndex: 'currentDistance',
      width: 100,
      align: 'center',
    },
    {
      title: 'Số dầu',
      dataIndex: 'totalOil',
      width: 100,
      align: 'center',
    },
  ];

  const pagination = {
    total: data?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  const handleSummary = (pageData) => {
    let sumTotalDistance = 0;
    let sumTotalLossDistance = 0;
    let sumTotalOil = 0;
    let sumCurrentDistance = 0;

    pageData.forEach((page) => {
      sumTotalDistance += page.totalDistance;
      sumTotalLossDistance += page.totalLossDistance;
      sumTotalOil += page.totalOil;
      sumCurrentDistance += page.currentDistance;
    });
    return (
      <>
        <Table.Summary.Row
          style={{
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Table.Summary.Cell index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}>
            <Text type='danger' strong>
              Tổng:
            </Text>
          </Table.Summary.Cell>

          <Table.Summary.Cell index={3}>
            <Text type='danger' strong>
              {sumTotalDistance}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            <Text type='danger' strong>
              {sumTotalLossDistance}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            <Text type='danger' strong>
              {sumCurrentDistance}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            <Text type='danger' strong>
              {sumTotalOil}
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const handleExportExcel = async () => {
    if (!filter?.DateFrom || !filter?.DateTo || loading) return;

    const dateFrom = moment(filter?.DateFrom).format('YYYY-MM-DD');
    const dateTo = moment(filter?.DateTo).format('YYYY-MM-DD');

    setLoading(true);
    try {
      const { data } = await ApiServices.exportReportOilKm(dateFrom, dateTo);
      if (data) {
        const outputFilename = `Bao_cao_tong_so_lit_dau_tu_ngay_${dateFrom}_den_ngay_${dateTo}.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <h3>Bảng Tổng Hợp Cự Ly KM Dầu</h3>
      <Space style={{ marginBottom: '10px' }}>
        <RangeDateFilter query={filter} onChange={setFilter} />
        <Button
          type='primary'
          icon={<DownloadOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px' }}
          onClick={handleExportExcel}
        >
          Export Excel
        </Button>
      </Space>
      <Table
        columns={columns}
        dataSource={data || []}
        rowKey='carCode'
        bordered
        loading={loading}
        scroll={{ x: '100%', y: '100%' }}
        pagination={pagination}
        summary={handleSummary}
      />
    </Wrapper>
  );
};
