import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Form, DatePicker, Input, Row, Col } from 'antd';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '~commons/constants';

export const FormBillEmptyCont = forwardRef(
  ({ fields, selectedCont, onChange, onSubmit, selectedEmptyCont }, ref) => {
    const [formRef] = Form.useForm();

    useImperativeHandle(ref, () => ({
      submitForm() {
        formRef.submit();
      },
    }));

    useEffect(() => {
      formRef.setFieldsValue({
        dateTime: moment(),
        note: '',
      });
    }, [selectedCont]);

    return (
      <Form
        name='global_state'
        layout='vertical'
        form={formRef}
        className='form-input-data'
        onFinish={onSubmit}
        fields={fields}
        onFieldsChange={(_, allFields) => {
          onChange(allFields);
        }}
      >
        {selectedCont?.record && (
          <>
            <Row gutter={8}>
              <Col span={9}>
                Số CONT: <b>{selectedCont.record?.contNumber}</b>
              </Col>
              <Col span={15}>
                Nơi Hạ: <b>{selectedCont.record?.gh}</b>
              </Col>
            </Row>
            <hr />
          </>
        )}

        <Form.Item
          name='dateTime'
          label={
            selectedEmptyCont?.type === 'reportEmpty'
              ? 'Ngày Giờ Hạ Rỗng'
              : selectedEmptyCont?.type === 'reportCargoReady'
              ? 'Ngày Giờ Báo Xuất'
              : 'Ngày Giờ Báo Xuất/ Hạ Rỗng'
          }
          rules={[
            {
              required: true,
              message: `Bạn Phải Chọn ${
                selectedEmptyCont?.type === 'reportEmpty'
                  ? 'Ngày Giờ Hạ Rỗng'
                  : selectedEmptyCont?.type === 'reportCargoReady'
                  ? 'Ngày Giờ Báo Xuất'
                  : 'Ngày Giờ Báo Xuất/ Hạ Rỗng'
              }`,
            },
          ]}
        >
          <DatePicker
            showTime={{ format: 'HH:mm' }}
            style={{ width: '100%' }}
            placeholder={
              selectedEmptyCont?.type === 'reportEmpty'
                ? 'Ngày Giờ Hạ Rỗng'
                : selectedEmptyCont?.type === 'reportCargoReady'
                ? 'Ngày Giờ Báo Xuất'
                : 'Ngày Giờ Báo Xuất/ Hạ Rỗng'
            }
            format={DATE_TIME_FORMAT}
          />
        </Form.Item>
        <Form.Item name='note' label='Ghi Chú'>
          <Input.TextArea placeholder='Ghi Chú' />
        </Form.Item>
      </Form>
    );
  }
);
