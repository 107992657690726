import { Button, Input, Popconfirm, Tag, DatePicker, Tooltip } from 'antd';
import Utils from '~commons/utils';
import { ContRevisedContent } from '~components';
import { LIFT_OFF_CONT_STATUSES } from '~commons/constants';
import styled from 'styled-components';
import { WBOrderTypeSelect } from '~components/common-select';
import { SHORTEN_DATE_FORMAT_WB } from '~commons/constants';

// const JobSuccessfulButton = styled(Tag)`
//   cursor: pointer;
// `;
const ButtonStyled = styled(Button)`
  padding: 0;
  height: auto;
`;
export const getColumns = (
  setQuery,
  setDataListFile,
  setDataAssignCar,
  canCelLiftOff,
  showJobSuccessful,
  onCancelTransferJob,
  showContFiles,
  showContForm,
  showCustomerDetail,
  searchDate,
  handleChangeSearchDate,
  onSelectChangeTour,
  selected,
  dataSelectedLiftOn
) => [
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Khách hàng</div>
        <Input
          style={{
            fontSize: '11px',
          }}
          placeholder=''
          allowClear
          onChange={(event) => setQuery({ customerName: event.target.value })}
        />
      </span>
    ),
    width: '20%',
    align: 'center',
    dataIndex: 'customerName',
    render: (value, record) => (
      <>
        <ContRevisedContent data={record} />
        {Utils.formatCustomerLiftOff(value, record, setDataListFile, showCustomerDetail)}
        {!!record?.contFiles?.length && (
          <Button
            type='link'
            style={{
              margin: 'auto',
            }}
            disabled={!record?.contFiles?.length}
            onClick={() => showContFiles(record?.contFiles)}
          >
            Cont File
          </Button>
        )}
      </>
    ),
  },
  {
    title: 'Ghi chú',
    width: '15%',
    dataIndex: 'noteDown',
    render: (value) => <div className='red'>{value}</div>,
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>SubNo</div>
        <Input
          style={{
            fontSize: '11px',
          }}
          placeholder=''
          allowClear
          onChange={(event) => setQuery({ allLocation: event.target.value })}
        />
      </span>
    ),
    width: '30%',
    dataIndex: 'subnoNumber',
    render: (value, record) => (
      <>
        {Utils.formatSubNoForLiltOff(value, record, showContForm)}
        {record?.noteCont && <div className='blue'>{record?.noteCont}</div>}
      </>
    ),
  },
  // {
  //   title: (
  //     <span style={{ textAlign: 'center' }}>
  //       <div>Lấy/Hạ</div>
  //       <Input
  //         style={{
  //           fontSize: '11px',
  //         }}
  //         placeholder='Search'
  //         allowClear
  //         onChange={(e) => setQuery({ allLocation: e.target.value })}
  //       />
  //     </span>
  //   ),
  //   width: '25%',
  //   dataIndex: 'liftOn',
  //   render: (value, record) => (
  //     <>
  //       {Utils.renderTaskLocations(value, record)}
  //       {(record?.carPlanName || record?.romoocPlanName) && (
  //         <div>
  //           <div className='red'>KẾ HOẠCH: </div>
  //           {record?.carPlanName && (
  //             <div>
  //               Xe: <span className='blue'>{record.carPlanName}</span>
  //               {record?.tour && (
  //                 <div>
  //                   Tour: <span className='blue'>{record.tour} </span>
  //                 </div>
  //               )}
  //             </div>
  //           )}
  //           {record?.romoocPlanName && (
  //             <div>
  //               Mooc: <span className='blue'>{record?.romoocPlanName}</span>
  //             </div>
  //           )}
  //         </div>
  //       )}
  //       {record?.childJobName?.[0] && (
  //         <>
  //           Đã chuyển ĐT:{' '}
  //           <JobSuccessfulButton color='#2db7f5' onClick={() => showJobSuccessful(record)}>
  //             {record?.childJobName[0]}
  //           </JobSuccessfulButton>
  //           {record?.status === 9 && record?.childJobName?.length ? (
  //             <></>
  //           ) : (
  //             <Popconfirm
  //               title='Xác nhận Hủy?'
  //               align='left'
  //               showCancel={false}
  //               okText='Ok'
  //               onConfirm={() => onCancelTransferJob(record)}
  //               cancelButtonProps={{ style: { display: 'none' } }}
  //             >
  //               <ButtonStyled type='link'>(Hủy)</ButtonStyled>
  //             </Popconfirm>
  //           )}
  //         </>
  //       )}
  //       {record.parentJobName?.[0] && (
  //         <>
  //           Nhận từ ĐT:
  //           <Tag>{record.parentJobName[0]}</Tag>
  //         </>
  //       )}
  //     </>
  //   ),
  // },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Tooltip
          title={
            <div>
              <div>CM: Cắt Máng (Book)</div>
              <div>HHTR: Hết Hạn Trả Rỗng (Bill)</div>
            </div>
          }
          placement='top'
          color='#108ee9'
        >
          <div style={{ color: 'red', cursor: 'pointer' }}>CM/HHTR</div>
        </Tooltip>
        <DatePicker
          // suffixIcon={null}
          value={searchDate}
          onChange={handleChangeSearchDate}
          placeholder=''
          style={{ width: '100%', padding: '2px 1px', fontSize: '10px' }}
          format={SHORTEN_DATE_FORMAT_WB}
          allowClear={true}
        />
      </div>
    ),
    width: '14%',
    dataIndex: 'type',
    align: 'center',
    render: (_, record) => (
      <b
        style={{
          fontSize: '11px',
        }}
      >
        {Utils.renderDateWhiteBoardLiftOff(record)}
      </b>
    ),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Mooc</div>
        <Input
          style={{
            fontSize: '11px',
          }}
          placeholder=''
          allowClear
          onChange={(e) => setQuery({ allCar: e.target.value })}
        />
      </span>
    ),
    width: '14%',
    dataIndex: 'type',
    align: 'center',
    render: (_, record) => {
      // const showTextTour = record?.childJobName?.[0] || record?.parentJobName?.[0] ? false : true;
      const showTextTour = true;
      const isAssigned = record?.assignId || record?.childJobName?.[0];
      return (
        <div>
          <div>
            {Utils.renderDateRequestForLiftOff(
              record,
              setDataAssignCar,
              showTextTour,
              onSelectChangeTour,
              isAssigned,
              selected,
              dataSelectedLiftOn
            )}
          </div>
          {record?.childJobName?.[0] && (
            <>
              <b
                // color='#2db7f5'
                style={{
                  marginTop: '4px',
                  cursor: 'pointer',
                  color: 'blue',
                }}
                onClick={() => showJobSuccessful(record)}
              >
                {record?.childJobName?.[0]}
              </b>
              {record?.status === 9 && record?.childJobName?.length ? (
                <></>
              ) : (
                <Popconfirm
                  title='Xác nhận Hủy Chuyển Đối tác?'
                  align='left'
                  showCancel={false}
                  okText='Ok'
                  onConfirm={() => onCancelTransferJob(record)}
                  cancelButtonProps={{ style: { display: 'none' } }}
                >
                  <ButtonStyled
                    type='link'
                    style={{
                      fontSize: '11px',
                      paddingTop: '4px',
                      paddingBottom: '4px',
                      textTransform: 'none',
                    }}
                  >
                    (Hủy Chuyển)
                  </ButtonStyled>
                </Popconfirm>
              )}
            </>
          )}
          {record?.parentJobName?.[0] && (
            <>
              Nhận từ:
              <Tag>{record.parentJobName[0]}</Tag>
            </>
          )}
        </div>
      );
    },
    // render: (value, record) => (
    //   <>
    //     {Utils.renderDateRequest(value, record, '', setDataAssignCar)}
    //     {record?.tracePartnerId ? (
    //       <div>
    //         BX/BR: <Tag color='red'>{record.tracePartnerName}</Tag>
    //       </div>
    //     ) : (
    //       // record.trace && <div className='red'>{record.trace}</div>
    //       <></>
    //     )}
    //   </>
    // ),
  },
  {
    title: (
      <>
        <WBOrderTypeSelect onChange={(type) => setQuery({ type })} />
      </>
    ),
    width: '10%',
    dataIndex: 'action',
    align: 'center',
    render: (_, record) => (
      <div>
        <div>{Utils.dispalySortType(record?.type)}</div>
        <Popconfirm
          title='Xác Nhận Hủy?'
          align='left'
          showCancel={false}
          okText='Ok'
          onConfirm={() => canCelLiftOff(record.contId)}
          disabled={record.status !== LIFT_OFF_CONT_STATUSES.NOTICED}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <Button
            style={{
              margin: 'auto',
              marginTop: '5px',
            }}
            type='link'
            disabled={record.status !== LIFT_OFF_CONT_STATUSES.NOTICED}
          >
            Hủy
          </Button>
        </Popconfirm>
      </div>
    ),
  },
];
