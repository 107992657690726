import { useState, useEffect } from 'react';
import { Table, Input } from 'antd';
import { TableTitle } from '~commons/styles';

const CarList = ({
  dataCars = [],
  setSelectedCar,
  setCarIdSelected,
  onColumnSearch,
  // pagination,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [dataCars]);

  const handleSelectRow = (record) => {
    if (record) {
      setSelectedRowKeys([record?.id]);
      setSelectedCar(record);
      setCarIdSelected(record?.id);
    }
  };

  const renderRow = (value, record) => {
    return (
      <div
        style={{
          cursor: 'pointer',
        }}
        onClick={() => handleSelectRow(record)}
      >
        {value}
      </div>
    );
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Mã
          {/* <Input name='code' onChange={onColumnSearch} placeholder='Search' /> */}
        </TableTitle>
      ),
      dataIndex: 'code',
      align: 'center',
      width: 80,
      render: renderRow,
    },
    {
      title: (
        <TableTitle>
          Nhà xe
          <Input name='name' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'name',
      align: 'center',
      render: renderRow,
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (rowKey, selectedRows) => {
      setSelectedRowKeys(rowKey);
      selectedRows?.[0] && setSelectedCar(selectedRows?.[0]);
      rowKey?.[0] && setCarIdSelected(rowKey?.[0]);
    },
  };
  return (
    <div className='table-container-custom'>
      <Table
        bordered
        size='small'
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        rowKey='id'
        columns={columns}
        dataSource={dataCars}
        scroll={{
          x: '100%',
          y: 220,
        }}
        // pagination={pagination}
      />
    </div>
  );
};
export { CarList };
