import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { InfoCarInspection } from './inforCarInspection';
import { FormUpdateCarInspection } from './form-update';
import CarInspectionFields from './fields-data';
import { DisplayFilesSimple } from '~components/display-files-simple';
import { TableTitle } from '~commons/styles';

const CarSigns = ({ id }) => {
  const [infoCarInspection, setInfoCarInspection] = useState({});
  const [inspectionList, setInspectionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowFormUpdate, setIsShowFormUpdate] = useState(false);
  const [fields, setFields] = useState({});
  const [isNew, setIsNew] = useState(false);
  const [dataCarInspection, setDataCarInspection] = useState({});

  const getCarInspections = async () => {
    try {
      setLoading(true);
      let { data } = await ApiServices.getCarSign(id);
      setLoading(false);
      if (data) {
        const datainspectionList =
          data.signList.data.map((item, index) => {
            item.index = index;
            return item;
          }) || [];
        setInfoCarInspection(data.infoCar);
        setInspectionList(datainspectionList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCarInspections();
    setIsShowFormUpdate(false);
  }, [id]);

  const setFieldsForm = async (value) => {
    const carInspectionFields = await CarInspectionFields(value);
    await setFields(carInspectionFields);
    return carInspectionFields;
  };

  const isShowFormUpdateFn = async () => {
    await setFieldsForm({}).then(() => {
      setIsNew(true);
      setIsShowFormUpdate(true);
    });
  };

  const onRowSelected = async (value) => {
    const _value = {
      ...value,
      cnSignDate: Utils.convertToDate(value.cnSignDate),
      cnSignExpDate: Utils.convertToDate(value.cnSignExpDate),
      files: value?.orderFiles,
    };
    await setFieldsForm(_value).then(() => {
      setIsNew(false);
      setDataCarInspection(value);
      setIsShowFormUpdate(true);
    });
  };

  const columns = [
    {
      title: 'Số Chứng Nhận',
      dataIndex: 'cnSignNumber',
      width: 100,
      render: (value, record) => {
        if (record?.index === 0) {
          return <a onClick={() => onRowSelected(record)}>{value ? value : <FormOutlined />}</a>;
        } else {
          return <span>{value}</span>;
        }
      },
    },
    {
      title: 'Ngày Chứng Nhận',
      dataIndex: 'cnSignDate',
      width: 100,
      render: (value) => Utils.formatDate(value),
    },
    {
      title: 'HH Chứng Nhận',
      dataIndex: 'cnSignExpDate',
      width: 100,
      render: (value) => Utils.formatDate(value),
    },
    {
      title: 'Cơ Quan Chứng Nhận',
      dataIndex: 'cnOrganization',
      width: 100,
    },
    {
      title: <TableTitle>File Đính kèm</TableTitle>,
      dataIndex: 'orderFiles',
      width: 150,
      render: (val) => <DisplayFilesSimple files={val} />,
    },
    {
      title: 'Ghi Chú',
      dataIndex: 'note',
      width: 100,
    },
  ];

  return (
    <>
      {isShowFormUpdate ? (
        <FormUpdateCarInspection
          setIsShowFormUpdate={setIsShowFormUpdate}
          fields={fields}
          onChange={(newFields) => {
            setFields(newFields);
          }}
          idCar={id}
          getCarInspections={getCarInspections}
          isNew={isNew}
          dataCarInspection={dataCarInspection}
          setLoading={setLoading}
        />
      ) : (
        <>
          <InfoCarInspection infoCarInspection={infoCarInspection} />
          <Button type='primary' className='mg-b-10 mg-t-10' onClick={isShowFormUpdateFn}>
            Thêm Mới
          </Button>
        </>
      )}

      <Table
        rowKey='id'
        bordered
        loading={loading}
        dataSource={inspectionList}
        columns={columns}
        scroll={{ x: 1000, y: 300 }}
      />
    </>
  );
};

export { CarSigns };
