import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { TableLayout, Selection } from '~components';
import { debounce } from 'lodash';
import { CompanyForm } from './companies-form';
import DriverFields from './fields-data';
import { Button, message, Space, Tabs } from 'antd';
import { getColumns } from './columns';
import ApiServices from '~services';
import { ViewAllConnectPartner } from './view-all-connect-partner';
import { PartnerCustomerManagement } from './partner-customer-management';
import Utils from '~commons/utils';
import { AuthorityLv2 } from './authority-lv2';

const HeadCompanies = () => {
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const [selected, setSelected] = useState('');
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  const [filter, setFilter] = useState(null);
  const [dataSearchColumn, setDataSearchColumn] = useState([]);
  const [originDataSearch, setOriginDataSearch] = useState([]);

  const [tabKey, setTabKey] = useState(1);

  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState('');

  // const getCompaniesInit = async () => {
  //   setLoading(true);
  //   try {
  //     let res = await ApiServices.getCompanyAll();
  //     if (res?.data?.data) {
  //       const newData = res?.data?.data.map((item, index) => {
  //         item.key = `${item.id}-main-${index}`;
  //         item.partner = 'Trụ sở chính';
  //         return item;
  //       });

  //       setDataSearchColumn(newData);
  //       setOriginDataSearch(newData);
  //     }
  //   } catch (error) {
  //     message.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getCompanies = async () => {
    setLoading(true);
    try {
      let res = await ApiServices.getCompanyAll();
      if (res?.data?.data) {
        const newData = res?.data?.data.map((item, index) => {
          item.key = `${item.id}-main-${index}`;
          item.partner = 'Trụ sở chính';
          return item;
        });

        setDataSearchColumn(newData);
        setOriginDataSearch(newData);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getCustomer = async (query = '') => {
    if (loading) return;
    const newQuery = {
      Columns: {
        shortName: query,
      },
    };
    setLoading(true);
    try {
      let { data } = await ApiServices.getCustomer(newQuery);
      if (data?.data) {
        setCustomers(data?.data);
      }
    } catch (error) {
      console.log('Get customer list failed:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const getDataInit = async () => {
      await getCompanies();
      await getCustomer();
    };

    getDataInit();
  }, []);

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;
      if (query?.code) {
        if (!Utils.convertValSearch(item?.code)?.includes(Utils.convertValSearch(query?.code))) {
          checkFilter = false;
        }
      }
      if (query?.name) {
        if (!Utils.convertValSearch(item?.name)?.includes(Utils.convertValSearch(query?.name))) {
          checkFilter = false;
        }
      }
      if (query?.title) {
        if (!Utils.convertValSearch(item?.title)?.includes(Utils.convertValSearch(query?.title))) {
          checkFilter = false;
        }
      }

      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    setDataSearchColumn(dataFilter);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, dataInit) => {
      console.log({ newFilter, dataInit });
      handleFilterData(newFilter, dataInit);
    }, 500),
    []
  );
  useEffect(() => {
    if (originDataSearch?.length) {
      timeoutGetData(filter, originDataSearch);
    }
  }, [filter]);

  const onColumnSearch = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilter((prev) => {
      return { ...prev, [name]: value };
    });
  };

  // const timeoutGetData = useCallback(
  //   debounce((newFilter) => {
  //     getCompanies(newFilter);
  //   }, 500),
  //   []
  // );
  // useEffect(() => {
  //   timeoutGetData(filter);
  // }, [filter]);

  const handleChangeStatus = async (id) => {
    if (loadingChangeStatus || !id) return;

    setLoadingChangeStatus(true);
    try {
      let res = await ApiServices.changeStatusCompany(id);
      if (res?.data?.data === 'success') {
        getCompanies(filter);
        return;
      }
    } catch (error) {
      message.error(error);
    } finally {
      setLoadingChangeStatus(false);
    }
  };

  const setFieldsForm = async (value) => {
    const driverFields = await DriverFields(value);
    await setFields(driverFields);
    return driverFields;
  };
  const reload = async () => {
    setSelected(null);
    getCompanies(filter);
  };

  const tabPanel = useMemo(() => {
    if (selected) {
      if (selected.isNew) {
        setFields(DriverFields());
        return [
          {
            title: 'Thông Tin Chi Tiết',
            content: (
              <CompanyForm
                fields={fields}
                setLoading={setIsLoadingTab}
                isNew={selected?.isNew}
                id={selected?.id}
                reload={reload}
                onChange={(newFields) => {
                  setFields(newFields);
                }}
              />
            ),
          },
        ];
      }
      setFields(DriverFields(selected));
      return [
        {
          title: 'Thông Tin Chi Tiết',
          content: (
            <CompanyForm
              key='1'
              fields={fields}
              setLoading={setIsLoadingTab}
              isNew={selected?.isNew}
              id={selected?.id}
              reload={reload}
              onChange={(newFields) => {
                setFields(newFields);
              }}
            />
          ),
        },
        {
          title: 'Quản Lý Khách Hàng',
          content: <PartnerCustomerManagement key='2' selectedCompany={selected} />,
        },
        {
          title: 'Cấu hình ủy quyền Level 2',
          content: <AuthorityLv2 key='3' dataSelect={selected} />,
        },
      ];
    }
  }, [selected]);

  const onRowSelected = async (value) => {
    await setIsLoadingTab(true);
    await setTitleTab('Chi tiết');
    await setFieldsForm(value);
    setSelected(value);

    setIsLoadingTab(false);
  };

  const createCompany = async () => {
    await setTitleTab('Thêm mới');
    await setFieldsForm({}).then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };

  const getDataCustomer = async (customerId) => {
    setLoading(true);
    return ApiServices.findCompanyWithCustomer(customerId)
      .then((res) => {
        if (res?.data?.data) {
          const dataMap = res?.data?.data.map((item, index) => {
            item.key = `${item.id}-search-${index}`;
            item.partner = 'Đối tác';
            return item;
          });
          setDataSearchColumn(dataMap || []);
          setOriginDataSearch(dataMap || []);
        } else {
          setDataSearchColumn([]);
          setOriginDataSearch([]);
        }
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => setLoading(false));
  };

  const getDataWithCustomer = useCallback(
    debounce((newCustomerId) => {
      getDataCustomer(newCustomerId);
    }, 500),
    []
  );
  useEffect(() => {
    if (customerId) {
      getDataWithCustomer(customerId);
    }
  }, [customerId]);

  const handleChangeCustomer = async (value) => {
    if (!value) {
      setCustomerId('');
      setDataSearchColumn([]);
      setOriginDataSearch([]);
      await getCompanies();
      return;
    }
    setCustomerId(value);
  };

  const headerContent = (
    <Space>
      <div>
        <b></b>
        <br />
        <Button type='primary' onClick={createCompany}>
          Thêm Mới
        </Button>
      </div>
      <div>
        <b>Tìm kiếm khách hàng được gán cho xe vệ tinh ủy quyền: </b>
        <br />
        <Selection
          data={customers}
          placeholder='Khách hàng'
          fieldValue='shortName'
          width={400}
          value={customerId}
          onChange={handleChangeCustomer}
          onSearch={getCustomer}
        />
      </div>
    </Space>
  );
  const onChangeTab = (key) => {
    setTabKey(key);
  };
  return (
    <Tabs defaultActiveKey='1' onChange={onChangeTab}>
      <Tabs.TabPane tab='Danh Sách Công Ty' key='1'>
        <div
          style={{
            width: '100%',
            maxHeight: '100vh',
            height: 'calc(100vh - 80px)',
          }}
        >
          <TableLayout
            key='key'
            columns={getColumns(
              onRowSelected,
              onColumnSearch,
              handleChangeStatus,
              loadingChangeStatus
            )}
            data={dataSearchColumn}
            loading={loading}
            selected={selected}
            closeDrawer={() => setSelected(null)}
            isLoadingTab={isLoadingTab}
            headerContent={headerContent}
            titleTab={titleTab}
            tabPanels={tabPanel}
            width={850}
            isDisplayCheckbox={false}
            headerLength='270px'
          />
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab='Danh Sách Đối Tác' key='2'>
        <ViewAllConnectPartner tabKey={tabKey} />
      </Tabs.TabPane>
    </Tabs>
  );
};

export { HeadCompanies };
