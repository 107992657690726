import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Space, Image } from 'antd';
const ExcelJS = require('exceljs');
import { PrinterOutlined, FileExcelOutlined } from '@ant-design/icons';
import moment from 'moment';
import ApiServices from '~services';
import ReactToPrint from 'react-to-print';
import { TableContent } from './table-content';
import { debounce } from 'lodash';
import { RangeDateFilter } from '~components';
import Utils from '~commons/utils';
import logo from '~assets/images/logo.png';
const toDataURL = (url) => {
  const promise = new Promise((resolve) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });

  return promise;
};

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};

const AccountantsGoodExpense = () => {
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [goodExpenseList, setGoodExpenseList] = useState([]);
  const [company, setCompany] = useState(null);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [totalPriceCod, setTotalPriceCod] = useState(0);
  const [totalPriceInit, setTotalPriceInit] = useState(0);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getAccountantsGoodExpense(newQuery)
      .then((res) => {
        let dataIsBill = [];
        let dataIsNotBill = [];
        let calcTotalPriceCod = 0;
        let calcTotalPrice = 0;

        if (res?.data?.data?.company) {
          setCompany(res?.data?.data?.company);
        }

        if (res?.data?.data?.isBill) {
          let totalPriceCODCustomer = 0;
          let totalPrice = 0;

          const dataIsBillMap = res?.data?.data?.isBill?.map((item, index) => {
            item.keyLabel = `${index + 1}`;
            item.key = index + 1;
            item.keyMap = `parent-1-${index}`;
            if (item?.pay) {
              item.codCustomerPrice = item?.price;
              item.normalPrice = 0;
              totalPriceCODCustomer = totalPriceCODCustomer + item?.price;
            } else {
              item.codCustomerPrice = 0;
              item.normalPrice = item?.price;
              totalPrice = totalPrice + item.price;
            }
            item.total = item?.price;
            return item;
          });
          dataIsBill = [
            {
              keyLabel: 'I',
              key: 'parent',
              keyMap: 'parent-1',
              name: 'Có Chứng Từ',
              totalPriceCODCustomer,
              totalPrice,
              totalAll: totalPriceCODCustomer + totalPrice,
            },
            ...dataIsBillMap,
          ];
          calcTotalPriceCod = calcTotalPriceCod + totalPriceCODCustomer;
          calcTotalPrice = calcTotalPrice + totalPrice;
        }
        if (res?.data?.data?.isNotBill) {
          let totalPriceCODCustomer = 0;
          let totalPrice = 0;

          const dataIsNotBillMap = res?.data?.data?.isNotBill?.map((item, index) => {
            item.keyLabel = `${index + 1}`;
            item.key = index + 1;
            item.keyMap = `parent-2-${index}`;
            if (item?.pay) {
              item.codCustomerPrice = item?.price;
              item.normalPrice = 0;
              totalPriceCODCustomer = totalPriceCODCustomer + item?.price;
            } else {
              item.codCustomerPrice = 0;
              item.normalPrice = item?.price;
              totalPrice = totalPrice + item.price;
            }
            item.total = item?.price;
            return item;
          });
          dataIsNotBill = [
            {
              keyLabel: 'II',
              key: 'parent',
              keyMap: 'parent-2',
              name: 'Không Có Chứng Từ',
              totalPriceCODCustomer,
              totalPrice,
              totalAll: totalPriceCODCustomer + totalPrice,
            },
            ...dataIsNotBillMap,
          ];
          calcTotalPriceCod = calcTotalPriceCod + totalPriceCODCustomer;
          calcTotalPrice = calcTotalPrice + totalPrice;
        }

        const dataEnd = dataIsBill.concat(dataIsNotBill);
        setGoodExpenseList(dataEnd);
        setTotalPriceCod(calcTotalPriceCod);
        setTotalPriceInit(calcTotalPrice);
      })
      .catch((err) => {
        console.error('get Accountants Good Expense Car Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const exportExcelFile = async () => {
    if (loading) return;
    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Chi Phí', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ state: 'frozen', ySplit: 5, activeCell: 'A1', showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 20;

    // Add Image
    // const pathLogo = company?.logo?.split('~')[1];
    // const urlLogo = `${process.env.REACT_APP_API_URL}${pathLogo}`;
    // const resultTest = await toDataURL(urlLogo);
    // const splittedTest = urlLogo.split('.');
    // const extNameTest = splittedTest[splittedTest.length - 1];
    const resultTest = await toDataURL(logo);
    const extNameTest = 'png';
    // --------- Summary Sheet --------------------
    const imageId2Summary = workbook.addImage({
      base64: resultTest.base64Url,
      extension: extNameTest,
    });

    // sheet.mergeCells('A1:B5');
    sheetSummary.addImage(imageId2Summary, 'A1:B5');
    sheetSummary.getRow(1).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    sheetSummary.getCell('C1').value = company?.name || '';
    sheetSummary.getCell('C2').value = company?.address || '';
    sheetSummary.getCell('C3').value = company?.phone || '';

    sheetSummary.getRow(7).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getCell('B7').value = 'BÁO CÁO CHI PHÍ LÀM HÀNG';

    sheetSummary.getCell('B8').value = `Từ Ngày ${moment(filter?.DateFrom).format(
      'DD/MM/YYYY'
    )} -> ${moment(filter?.DateTo).format('DD/MM/YYYY')}`;

    sheetSummary.getRow(9).font = {
      name: 'Arial',
      family: 2,
      size: 10,
      italic: true,
    };
    sheetSummary.getCell('E9').value = `Đơn vị tính: Đồng`;

    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getColumn(2).width = 20;
    sheetSummary.getColumn(3).width = 25;
    for (let i = 4; i <= 6; i++) {
      sheetSummary.getColumn(i).numFmt = '#,##0';
      sheetSummary.getColumn(i).width = 20;
    }
    sheetSummary.getColumn(7).width = 20;

    sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };

    // Total
    const tableLength = goodExpenseList?.length;
    const rowAfterTable1 = tableLength + 11;
    sheetSummary.mergeCells(`A${rowAfterTable1}:B${rowAfterTable1}`);
    const arrayBorder = ['B', 'C', 'D', 'E'];
    arrayBorder.forEach((item) => {
      sheetSummary.getCell(`${item}${rowAfterTable1}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      sheetSummary.getCell(`${item}${rowAfterTable1}`).fill = {
        type: 'gradient',
        gradient: 'angle',
        degree: 0,
        stops: [
          { position: 0, color: { argb: 'BFB8B8' } },
          { position: 0.5, color: { argb: 'BFB8B8' } },
          { position: 1, color: { argb: 'BFB8B8' } },
        ],
      };
    });
    sheetSummary.getRow(rowAfterTable1).alignment = { vertical: 'right', horizontal: 'right' };
    sheetSummary.getCell(`B${rowAfterTable1}`).value = 'Cộng';
    sheetSummary.getCell(`C${rowAfterTable1}`).value = totalPriceCod
      ? Utils.formatNumber(totalPriceCod)
      : 0;
    sheetSummary.getCell(`D${rowAfterTable1}`).value = totalPriceInit
      ? Utils.formatNumber(totalPriceInit)
      : 0;
    sheetSummary.getCell(`E${rowAfterTable1}`).value = Utils.formatNumber(
      totalPriceCod + totalPriceInit
    );
    const rowAfterTable2 = tableLength + 12;
    sheetSummary.getRow(rowAfterTable2).alignment = { vertical: 'center', horizontal: 'center' };
    sheetSummary.getRow(rowAfterTable2).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    sheetSummary.mergeCells(`A${rowAfterTable2}:B${rowAfterTable2}`);
    sheetSummary.getCell(`B${rowAfterTable2}`).fill = {
      type: 'gradient',
      gradient: 'angle',
      degree: 0,
      stops: [
        { position: 0, color: { argb: 'BFB8B8' } },
        { position: 0.5, color: { argb: 'BFB8B8' } },
        { position: 1, color: { argb: 'BFB8B8' } },
      ],
    };
    sheetSummary.getCell(`C${rowAfterTable2}`).fill = {
      type: 'gradient',
      gradient: 'angle',
      degree: 0,
      stops: [
        { position: 0, color: { argb: 'BFB8B8' } },
        { position: 0.5, color: { argb: 'BFB8B8' } },
        { position: 1, color: { argb: 'BFB8B8' } },
      ],
    };
    sheetSummary.getCell(`B${rowAfterTable2}`).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    sheetSummary.getCell(`C${rowAfterTable2}`).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    sheetSummary.getCell(`B${rowAfterTable2}`).value = 'Chi phí';
    sheetSummary.mergeCells(`C${rowAfterTable2}:E${rowAfterTable2}`);
    sheetSummary.getCell(`D${rowAfterTable2}`).value = Utils.formatNumber(
      totalPriceCod + totalPriceInit
    );
    const rowAfterTable3 = tableLength + 13;
    sheetSummary.mergeCells(`D${rowAfterTable3}:E${rowAfterTable3}`);
    sheetSummary.getRow(rowAfterTable3).alignment = { vertical: 'center', horizontal: 'center' };
    sheetSummary.getCell(`D${rowAfterTable3}`).value = 'Ngày ' + moment().format('LL');
    const rowAfterTable4 = tableLength + 14;
    sheetSummary.mergeCells(`D${rowAfterTable4}:E${rowAfterTable4}`);
    sheetSummary.getRow(rowAfterTable4).alignment = { vertical: 'center', horizontal: 'center' };
    sheetSummary.getCell(`D${rowAfterTable4}`).value = 'Lập Biểu';

    const arrDetail = await goodExpenseList?.map((item) => {
      const dataItem = [
        item?.keyLabel, // STT
        item?.name, // Diễn Giải
        item?.key === 'parent' ? item?.totalPriceCODCustomer : item?.codCustomerPrice, // Thu Lại Khách Hàng
        item?.key === 'parent' ? item?.totalPrice : item?.normalPrice, // Chi Phí
        item?.key === 'parent' ? item?.totalAll : item?.total, // Tong Thanh Tien
      ];
      return dataItem;
    });
    sheetSummary.getRow(10).alignment = { vertical: 'center', horizontal: 'center' };

    // Add Table
    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'A10',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Diễn Giải', filterButton: false },
        {
          name: 'Thu Lại Khách Hàng',
          filterButton: false,
        },
        {
          name: 'Chi Phí',
          filterButton: false,
        },
        {
          name: 'Thành Tiền',
          filterButton: false,
        },
      ],
      rows: arrDetail,
    });

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Bao_Cao_Chi_Phi_Lam_Hang_Tu_${moment(filter?.DateFrom).format(
          'DD-MM-YYYY'
        )}_Den_${moment(filter?.DateTo).format('DD-MM-YYYY')}_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const pageStyle = `
  @page {
     size: A4;
     margin: 5%;
     padding: 0 0 10%;
     @bottom-right {
      content: counter(page);
     }
  }
`;
  return (
    <>
      <Space
        style={{
          padding: '20px',
          marginBottom: '15px',
        }}
      >
        <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
        <ReactToPrint
          trigger={() => (
            <Button type='primary' disabled={!goodExpenseList.length} icon={<PrinterOutlined />}>
              Print
            </Button>
          )}
          content={() => componentRef.current}
          documentTitle={`Bao_Cao_Chi_Phi_Lam_Hang_${moment(filter?.DateFrom).format(
            'DD-MM-YYYY'
          )}_Den_${moment(filter?.DateTo).format('DD-MM-YYYY')}_Xuat_Excel_Luc_${moment().format(
            'DD-MM-YY HH:mm'
          )}.xlsx`}
          pageStyle={pageStyle}
        />
        <Button
          type='primary'
          loading={loading}
          icon={<FileExcelOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px' }}
          disabled={!goodExpenseList.length}
          onClick={exportExcelFile}
        >
          Xuất Excel
        </Button>
      </Space>
      <div
        style={{
          border: '1px solid blue',
          borderRadius: '10px',
          marginBottom: '10px',
        }}
      >
        <div ref={componentRef}>
          <div
            style={{
              display: 'flex',
              marginTop: '10px',
            }}
          >
            <div>
              <Image
                src={
                  (company?.logo && `${process.env.REACT_APP_API_URL}${company?.logo?.slice(1)}`) ||
                  `https://ui-avatars.com/api/?name=${company?.code}&length=3&size=`
                }
                alt={company?.name}
                style={{ paddingLeft: 12 }}
                width={150}
              />
            </div>
            <div>
              <h4>{company?.name}</h4>
              <p>{company?.address}</p>
              <p>{company?.phone}</p>
            </div>
          </div>
          <div
            style={{
              textAlign: 'center',
              // marginTop: '10px',
            }}
          >
            <h1>
              <b>BÁO CÁO CHI PHÍ LÀM HÀNG</b>
            </h1>
            <i>{`Từ Ngày ${moment(filter?.DateFrom).format('DD/MM/YYYY')} -> ${moment(
              filter?.DateTo
            ).format('DD/MM/YYYY')}`}</i>
          </div>

          <div
            style={{
              margin: '0px 12px 12px 12px',
              marginTop: '20px',
            }}
          >
            <TableContent
              loading={loading}
              data={goodExpenseList}
              totalPriceCod={totalPriceCod}
              totalPriceInit={totalPriceInit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { AccountantsGoodExpense };
