import { InputSearchColumns, BtnConfirm } from '~components';
import Utils from '~commons/utils';

export const getColumns = (onRowSelected, searchByColumn, handleSearchColumnFn, deleteCar) => [
  {
    title: 'STT',
    width: 50,
    dataIndex: 'index',
    align: 'center',
  },
  {
    title: (
      <InputSearchColumns
        title='Biển số'
        value={searchByColumn?.code}
        onChange={(e) => handleSearchColumnFn(e, 'code')}
      />
    ),
    dataIndex: 'code',
    align: 'center',
    width: 100,
    render: (value, record) => <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>,
  },
  {
    title: (
      <InputSearchColumns
        title='Tên tài xế'
        value={searchByColumn?.driverMainName}
        onChange={(e) => handleSearchColumnFn(e, 'driverMainName')}
      />
    ),
    width: 130,
    align: 'center',
    dataIndex: 'driverMainName',
  },
  {
    title: (
      <InputSearchColumns
        title='Tên phụ xe'
        value={searchByColumn?.driverSubName}
        onChange={(e) => handleSearchColumnFn(e, 'driverSubName')}
      />
    ),
    width: 100,
    align: 'center',
    dataIndex: 'driverSubName',
  },
  {
    title: (
      <InputSearchColumns
        title='Nhà xe'
        value={searchByColumn?.vendorName}
        onChange={(e) => handleSearchColumnFn(e, 'vendorName')}
      />
    ),
    width: 100,
    align: 'center',
    dataIndex: 'vendorName',
  },
  {
    title: (
      <InputSearchColumns
        title='Công ty'
        value={searchByColumn?.companyName}
        onChange={(e) => handleSearchColumnFn(e, 'companyName')}
      />
    ),
    width: 150,
    align: 'center',
    dataIndex: 'companyName',
  },
  {
    title: (
      <InputSearchColumns
        title='Nhãn hiệu'
        value={searchByColumn?.labelCar}
        onChange={(e) => handleSearchColumnFn(e, 'labelCar')}
      />
    ),
    width: 120,
    align: 'center',
    dataIndex: 'labelCar',
  },
  {
    title: (
      <InputSearchColumns
        title='Chủng loại'
        value={searchByColumn?.typeCar}
        onChange={(e) => handleSearchColumnFn(e, 'typeCar')}
      />
    ),
    width: 100,
    align: 'center',
    dataIndex: 'typeCar',
  },
  {
    title: (
      <InputSearchColumns
        title='Loại động cơ'
        value={searchByColumn?.engineType}
        onChange={(e) => handleSearchColumnFn(e, 'engineType')}
      />
    ),
    width: 100,
    align: 'center',
    dataIndex: 'engineType',
  },
  {
    title: (
      <InputSearchColumns
        title='Nơi SX'
        value={searchByColumn?.madeIn}
        onChange={(e) => handleSearchColumnFn(e, 'madeIn')}
      />
    ),
    width: 100,
    align: 'center',
    dataIndex: 'madeIn',
  },
  {
    title: (
      <InputSearchColumns
        title='Năm SX'
        value={searchByColumn?.madeYear}
        onChange={(e) => handleSearchColumnFn(e, 'madeYear')}
      />
    ),
    width: 80,
    align: 'center',
    dataIndex: 'madeYear',
  },
  {
    title: (
      <InputSearchColumns
        title='Số khung'
        value={searchByColumn?.frameNumber}
        onChange={(e) => handleSearchColumnFn(e, 'frameNumber')}
      />
    ),
    width: 150,
    align: 'center',
    dataIndex: 'frameNumber',
  },
  {
    title: (
      <InputSearchColumns
        title='Số máy'
        value={searchByColumn?.machineNumber}
        onChange={(e) => handleSearchColumnFn(e, 'machineNumber')}
      />
    ),
    width: 150,
    align: 'center',
    dataIndex: 'machineNumber',
  },
  {
    title: (
      <InputSearchColumns
        title='Số KĐ'
        value={searchByColumn?.inspectionNumber}
        onChange={(e) => handleSearchColumnFn(e, 'inspectionNumber')}
      />
    ),
    width: 100,
    align: 'center',
    dataIndex: 'inspectionNumber',
  },
  {
    title: 'Ngày KĐ',
    dataIndex: 'inspectionDate',
    width: 90,
    align: 'center',
    render: (value) => Utils.formatDate(value),
  },
  {
    title: 'Ngày HHKĐ',
    width: 90,
    align: 'center',
    dataIndex: 'inspectionExpirationDate',
    render: (value) => Utils.formatDate(value),
  },
  {
    title: 'HH Phù Hiệu',
    width: 100,
    align: 'center',
    dataIndex: 'cnSignExpDate',
    render: (value) => Utils.formatDate(value),
  },
  {
    title: 'HH Bảo Hiểm',
    width: 100,
    align: 'center',
    dataIndex: 'cnInsurranceExpDate',
    render: (value) => Utils.formatDate(value),
  },
  {
    title: 'Ngày Mua',
    width: 90,
    align: 'center',
    dataIndex: 'buyDate',
    render: (value) => Utils.formatDate(value),
  },
  {
    title: 'Ngày SD',
    width: 90,
    align: 'center',
    dataIndex: 'useBeginDate',
    render: (value) => Utils.formatDate(value),
  },
  {
    title: 'C.Phí KĐ',
    width: 90,
    align: 'center',
    dataIndex: 'inspectionCost',
  },
  {
    title: 'Đ.Điểm Hiện Tại',
    width: 150,
    align: 'center',
    dataIndex: 'currentLocationName',
  },
  {
    title: 'Giá Mua',
    width: 90,
    align: 'center',
    dataIndex: 'buyCost',
  },
  {
    title: 'Khấu Hao',
    width: 80,
    align: 'center',

    dataIndex: 'depreciation',
  },
  {
    title: 'Phí Bảo Hành',
    width: 100,
    align: 'center',
    dataIndex: 'warrantyExpenses',
  },
  {
    title: 'Mốc BT Km',
    width: 100,
    align: 'center',
    dataIndex: 'milestoneMaintenanceKm',
  },
  {
    title: 'Định Mức Dầu',
    width: 100,
    align: 'center',
    dataIndex: 'oilQuota',
  },
  {
    title: 'Dầu Trong Xe',
    width: 100,
    align: 'center',
    dataIndex: 'oilOfCar',

    render: (value) => Math.round(value),
  },
  {
    title: 'Số CN P.Hiệu',
    width: 100,
    align: 'center',
    dataIndex: 'cnSignNumber',
  },
  {
    title: 'Số CN B.Hiểm',
    width: 100,
    align: 'center',
    dataIndex: 'cnInsurranceNumber',
  },
  {
    title: 'Ghi Chú',
    width: 300,
    align: 'center',
    dataIndex: 'note',
  },
  {
    title: 'Action',
    fixed: 'right',
    width: 75,
    render: (record) => <BtnConfirm onConfirm={() => deleteCar(record)}>Xóa</BtnConfirm>,
  },
];
