import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { color } from '~theme/index';
import { useSelector } from 'react-redux';
import { AssignItem } from './assign-item';
import useApiHook from '../wb-hook/api-hook';
import ApiServices from '~services';
const SUBNO_REGEX = /\/CV-[0-9/b]/gi;
export const AssignList = ({ dataLoaded }) => {
  const { selectedDataRows: selectedDataRowsLiftOn } = useSelector((state) => state.liftOn);
  const { selectedDataRows: selectedDataRowsLiftOff, indexSelected: indexSelectedLiftOff } =
    useSelector((state) => state.liftOff);

  const { updateAssignCar } = useApiHook();

  const firstSelectedLiftOn = selectedDataRowsLiftOn?.[0]?.[0];
  const firstSelectedLiftOff = selectedDataRowsLiftOff?.[0]?.[0];

  const carPlan = useMemo(() => {
    if (firstSelectedLiftOn?.carPlanId || firstSelectedLiftOff?.carPlanId) {
      /**
       * carPlanLiftOn: là xe kế of tour
       * => Nếu có carPlanLiftOn thì return data vs xe kế hoạch => còn k return 1 (empty)
       */
      const carPlanLiftOn = firstSelectedLiftOn?.carPlanId
        ? {
            id: firstSelectedLiftOn.carPlanId,
            code: firstSelectedLiftOn.carPlanName,
            driverMainName: firstSelectedLiftOn.driverPlanName,
            driverMainCode: firstSelectedLiftOn.driverPlanId,
            currentLocationName: firstSelectedLiftOn.locationPlanName,
            currentLocationCode: firstSelectedLiftOn.locationPlanId,
            tour: firstSelectedLiftOn.tour,
          }
        : 1; // else equal 1 for carPlanLiftOn != carPlanLiftOff (1 != 2 or somethings)
      /**
       * carPlanLiftOff: là xe kế of tour
       * => Nếu có carPlanLiftOff thì return data vs xe kế hoạch => còn k return 2 (empty)
       */
      const carPlanLiftOff = firstSelectedLiftOff?.carPlanId
        ? {
            id: firstSelectedLiftOff.carPlanId,
            code: firstSelectedLiftOff.carPlanName,
            driverMainCode: firstSelectedLiftOff.driverPlanId,
            driverMainName: firstSelectedLiftOff.driverPlanName,
            currentLocationName: firstSelectedLiftOff.locationPlanName,
            currentLocationCode: firstSelectedLiftOff.locationPlanId,
            tour: firstSelectedLiftOff.tour,
          }
        : 2; // else equal 2 for carPlanLiftOn != carPlanLiftOff (2 != 1  or somethings)
      return (
        (carPlanLiftOn?.id === carPlanLiftOff?.id && carPlanLiftOn) ||
        (carPlanLiftOn?.id && !carPlanLiftOff?.id && carPlanLiftOn) ||
        (!carPlanLiftOn?.id && carPlanLiftOff?.id && carPlanLiftOff)
      );
    }
    if (firstSelectedLiftOn?.tracePartnerId || firstSelectedLiftOff?.tracePartnerId) {
      return {
        tracePartnerId: firstSelectedLiftOn?.tracePartnerId || firstSelectedLiftOff?.tracePartnerId,
        tracePartnerName:
          firstSelectedLiftOn?.tracePartnerName || firstSelectedLiftOff?.tracePartnerName,
      };
    }
  }, [firstSelectedLiftOn, firstSelectedLiftOff]);

  useEffect(async () => {
    if (carPlan?.id) {
      return ApiServices.getCarDetails(carPlan?.id)
        .then((res) => {
          if (res?.data) {
            const dataCarGetNew = {
              ...carPlan,
              driverMainCode: res?.data?.driverMainCode,
              driverMainName: res?.data?.driverMainName,
              currentLocationCode: res?.data?.currentLocationCode,
              currentLocationName: res?.data?.currentLocationName,
            };
            updateAssignCar(dataCarGetNew);
          } else {
            updateAssignCar(carPlan);
          }
        })
        .catch((err) => {
          console.log({ err });
          updateAssignCar(carPlan);
        });
    } else {
      updateAssignCar(carPlan);
    }
  }, [carPlan]);

  const renderOrderIndex = (index, isLiftOff) => {
    if (indexSelectedLiftOff === 0) {
      return isLiftOff ? index + 1 : index + 1 + selectedDataRowsLiftOff.length;
    }
    return isLiftOff ? selectedDataRowsLiftOn.length + index + 1 : index + 1;
  };
  const renderItems = (list, style = {}, isLiftOff = false) => {
    return list.map((item, index) => {
      const orderIndex = renderOrderIndex(index, isLiftOff);
      return (
        <ItemWrapper style={style} key={item?.length ? index : item[0]?.code}>
          <SubNo>
            <span className='order-index'>{orderIndex}</span>{' '}
            {item?.length && item[0].code.replace(SUBNO_REGEX, '')}{' '}
            {item?.[0]?.tour && <span>(TOUR: {item[0].tour})</span>}
          </SubNo>
          {item.map((data, ind) => (
            <AssignItem
              data={data}
              listData={item}
              ind={ind}
              key={data.id}
              parentLocations={dataLoaded.locations}
              getLocations={dataLoaded.getLocations}
              orderIndex={orderIndex}
              romoocs={dataLoaded.romoocs}
              conts={dataLoaded.conts}
            />
          ))}
        </ItemWrapper>
      );
    });
  };

  return (
    <Wrapper>
      <LiftList>
        {renderItems(selectedDataRowsLiftOff, { background: color.liftOff }, true)}
      </LiftList>
      <LiftList>{renderItems(selectedDataRowsLiftOn, { background: color.liftOn })}</LiftList>
    </Wrapper>
  );
};
const SubNo = styled.p`
  padding: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  padding-left: 4px;
  span.order-index {
    font-family: 'Tahoma', 'Arial', 'Helvetica', sans-serif;
    font-size: 14px;
    line-height: 12px;
    font-weight: 900;
    color: white;
    position: absolute;
    top: 2px;
    right: 4px;
    padding: 6px;
    border-radius: 50px;
    background: ${color.error};
  }
`;
const ItemWrapper = styled.div`
  border-radius: 8px;
  padding: 8px;
  color: white;
  position: relative;
`;
const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  flex: 1;
  margin-top: 8px;
  overflow-y: auto;
  border-top: 1px solid ${color.border};
  border-bottom: 1px solid ${color.border};
`;
const LiftList = styled.div`
  width: calc(50% - 4px);
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 8px;
  padding-bottom: 0px;
`;
