import React, { useEffect, useState } from 'react';
import { Form, DatePicker, Input, Row, Col, Modal } from 'antd';
import moment from 'moment';
// import moment from 'moment';
import { layout } from './constant';
import ApiServices from '~services';
import { DATE_FORMAT, DATE_FORMAT_SEND_API } from '~commons/constants';
import { Selection } from '~components';
import { CusItemAddForm } from './cus-add-item-form';
import { UpdateFormAfterCreated } from '../update-after-create';
import { LoadingView } from '~components';

const initData = {
  contractNumber: '',
  customerId: '',
  contractDate: null,
  expiredDate: null,
  renewExpiredDate: null,
  arrLocations: [],
  note: '',
};

const CreateForm = ({
  isNew = true,
  showCreateForm,
  onCloseCreateFrom,
  isUpdate,
  setIsUpdate,
  reload,
  countClear,
}) => {
  const [form] = Form.useForm();
  const [loadingForm, setLoadingForm] = useState(false);

  const [partnerList, setPartnerList] = useState([]);
  const [partnerId, setPartnerId] = useState('');
  const [customerList, setCustomerList] = useState([]);
  const [showAddCusItem, setShowAddCusItem] = useState(false);
  const [contractNumber, setContractNumber] = useState('');
  const [dataAll, setDataAll] = useState(initData);
  const [contractDate, setContractDate] = useState(null);
  const [expiredDate, setExpiredDate] = useState(null);
  // Cont Type
  const [contTypeList, setContTypeList] = useState([]);
  // const isadd
  const [isUpdateNew, setIsUpdateNew] = useState(false);
  const [dataSelect, setDataSelect] = useState(null);

  const getPartners = async (Query = null) => {
    setLoadingForm(true);
    try {
      const res = await ApiServices.getPartnerByCompany({ Query });
      if (res?.data?.data) {
        setPartnerList(res?.data?.data || []);
      }
    } catch (error) {
      console.log('Error -  getPartners', error);
    } finally {
      setLoadingForm(false);
    }
  };

  const getContType = async () => {
    setLoadingForm(true);
    try {
      let { data } = await ApiServices.getContTypes();
      if (data?.data) {
        setContTypeList(data?.data);
      }
    } catch (error) {
      console.log('Error - get contType', error);
    } finally {
      setLoadingForm(false);
    }
  };

  useEffect(() => {
    getPartners();
    getContType();
  }, []);

  const getContractNumberByPartnerId = async (partnerId) => {
    setLoadingForm(true);
    try {
      let { data } = await ApiServices.getContractNumberByPartnerId(partnerId);
      if (data?.data) {
        setContractNumber(data?.data);
        setDataAll((prev) => {
          prev.contractNumber = data?.data;
          return prev;
        });
      } else {
        setContractNumber('');
        setDataAll((prev) => {
          prev.contractNumber = '';
          return prev;
        });
      }
    } catch (error) {
      console.log('Error - getContractNumberByPartnerId::', error);
      setContractNumber('');
      setDataAll((prev) => {
        prev.contractNumber = '';
        return prev;
      });
    } finally {
      setLoadingForm(false);
    }
  };

  const getCustomer = async (query = '') => {
    setLoadingForm(true);
    const newQuery = {
      Columns: {
        shortName: query,
      },
    };
    try {
      const res = await ApiServices.getCustomer(newQuery);
      if (res?.data?.data) {
        setCustomerList(res?.data?.data || []);
      } else {
        setCustomerList([]);
      }
    } catch (error) {
      console.log('Error -  getPartners', error);
      setCustomerList([]);
    } finally {
      setLoadingForm(false);
    }
  };

  useEffect(() => {
    getCustomer();
  }, []);

  useEffect(() => {
    const getDataByPartnerId = async (newPartnerId) => {
      await getContractNumberByPartnerId(newPartnerId);
    };

    if (partnerId) {
      getDataByPartnerId(partnerId);
    }
  }, [partnerId]);

  const handleCreate = async () => {};
  const handleUpdate = async () => {};

  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await handleCreate(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await handleUpdate(value);
    setLoading(false);
  };

  const handleChangePartner = async (id) => {
    if (!id) return;
    setPartnerId(id);
  };
  function handleChangeExpireDate(dateExpire) {
    setExpiredDate(dateExpire);
    const dataSend = dateExpire ? moment(dateExpire).format(DATE_FORMAT_SEND_API) : null;
    setDataAll((prev) => {
      prev.expiredDate = dataSend;
      return prev;
    });
  }
  function handleChangeContractDate(dateContract) {
    setContractDate(dateContract);
    const dataSend = dateContract ? moment(dateContract).format(DATE_FORMAT_SEND_API) : null;
    setDataAll((prev) => {
      prev.contractDate = dataSend;
      return prev;
    });
  }

  function onCloseAddNewCusItem() {
    setShowAddCusItem(false);
  }

  function handleCloseForm() {
    setPartnerId('');
    setShowAddCusItem(false);
    setContractNumber('');
    setDataAll([]);
    setContractDate(null);
    setExpiredDate(null);
    setCustomerList([]);
    onCloseCreateFrom();
    form.resetFields();
  }

  return (
    <Modal
      Modal
      title='Thêm mới'
      open={showCreateForm}
      onCancel={handleCloseForm}
      footer={null}
      style={{
        top: 20,
      }}
      width='60%'
    >
      {isUpdate ? (
        <UpdateFormAfterCreated
          isShow={showCreateForm}
          onClose={handleCloseForm}
          dataSelect={dataSelect}
          reload={reload}
        />
      ) : (
        <Form
          {...layout}
          form={form}
          name='global_state'
          className='form-input-data'
          onFinish={onSubmitForm}
          fields={{}}
          onFieldsChange={(_, allFields) => {
            onChange(allFields);
          }}
          layout='vertical'
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    <b className='red'>*</b> Nhà xe ngoài
                  </span>
                }
              >
                <Selection
                  disabled={showAddCusItem}
                  data={partnerList}
                  // labelDefault={rowData?.driverSubName}
                  fieldValue='name'
                  placeholder='Nhà xe ngoài'
                  allowClear={false}
                  value={partnerId}
                  onChange={handleChangePartner}
                  onSearch={getPartners}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Số hợp đồng'>
                <Input value={contractNumber} readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    <b className='red'>*</b> Ngày hợp đồng
                  </span>
                }
              >
                <DatePicker
                  placeholder='Ngày hợp đồng'
                  style={{ width: '100%' }}
                  format={DATE_FORMAT}
                  value={contractDate}
                  onChange={handleChangeContractDate}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Ngày hết hạn'>
                <DatePicker
                  placeholder='Ngày hết hạn'
                  style={{ width: '100%' }}
                  format={DATE_FORMAT}
                  value={expiredDate}
                  onChange={handleChangeExpireDate}
                />
              </Form.Item>
            </Col>
          </Row>
          <CusItemAddForm
            onClose={onCloseAddNewCusItem}
            customerList={customerList}
            dataAll={dataAll}
            setDataAll={setDataAll}
            contTypeList={contTypeList}
            partnerId={partnerId}
            isUpdateNew={isUpdateNew}
            setIsUpdateNew={setIsUpdateNew}
            setIsUpdate={setIsUpdate}
            setDataSelect={setDataSelect}
            reload={reload}
            countClear={countClear}
            getCustomer={getCustomer}
          />
        </Form>
      )}
      <LoadingView loading={loadingForm} />
    </Modal>
  );
};

export { CreateForm };
