import { memo } from 'react';
import { Image } from 'antd';
import styled from 'styled-components';
import Utils from '~commons/utils';

const FileName = styled.div`
  white-space: nowrap;
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const DisplayFilesSimple = memo(({ files }) => {
  return (
    !!files?.length && (
      <Image.PreviewGroup>
        {files?.map((file, index) => {
          const url = `${Utils.getAppUrl()}${file.urlImage?.substring(2)}`;
          return (
            <FileName key={file?.id || file?.code || index}>
              <a target='_blank' href={url} rel='noreferrer'>
                {'>'} {file.name}
              </a>
            </FileName>
          );
        })}
      </Image.PreviewGroup>
    )
  );
});
