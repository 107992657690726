import { Button, Input } from 'antd';
import { CONT_STATUSES, LIFT_ON_CONT_STATUSES } from '~commons/constants';
import Utils from '~commons/utils';
import { WBOrderTypeSelect } from '~components/common-select';

const renderCustomer = (value, record, setDataListFile) => {
  return (
    <div>
      {Utils.formatCustomer(value, record, setDataListFile)}
      {record?.trace && <div className='blue'>({record?.trace})</div>}
    </div>
  );
};

export const getColumns = (
  setQuery,
  setDataListFile,
  setSelectedRow,
  showAssignCar,
  cancelSingleCont
) => [
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Khách hàng</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(event) => setQuery({ customerName: event.target.value })}
        />
      </span>
    ),
    width: '18%',
    dataIndex: 'customerName',
    render: (value, record) => renderCustomer(value, record, setDataListFile),

    // render: (value, record) => Utils.formatCustomer(value, record, setDataListFile),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>SubNo</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(event) => setQuery({ allLocation: event.target.value })}
        />
      </span>
    ),
    width: '25%',
    dataIndex: 'subnoNumber',
    render: (value, record) => Utils.formatSubNoForTask(value, record),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Lấy/Hạ</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(e) => setQuery({ allLocation: e.target.value })}
        />
      </span>
    ),
    width: '20%',
    dataIndex: 'liftOn',
    render: (value, record) => Utils.renderTaskLocations(value, record),
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Bãi</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(e) => setQuery({ bai: e.target.value })}
        />
      </span>
    ),
    width: '16%',
    dataIndex: 'bai',
  },
  {
    title: (
      <span style={{ textAlign: 'center' }}>
        <div>Ngày YC</div>
        <Input
          placeholder='Search'
          allowClear
          onChange={(e) => setQuery({ allDate: e.target.value })}
        />
      </span>
    ),
    width: '16%',
    dataIndex: 'type',
    render: (value, record) => Utils.renderDateRequest(value, record, '', showAssignCar),
  },
  {
    title: <WBOrderTypeSelect onChange={(orderType) => setQuery({ orderType })} />,
    width: '10%',
    dataIndex: 'action',
    render: (_, record) => (
      <>
        <Button
          type='link'
          disabled={record?.status !== LIFT_ON_CONT_STATUSES.PLANED}
          onClick={() => setSelectedRow(record)}
        >
          Điều
        </Button>
        {record?.statusCont === CONT_STATUSES.CANCELED && (
          <>
            <hr />
            <Button
              shape='circle'
              type='link'
              style={{ color: 'red' }}
              onClick={() => cancelSingleCont(record.jobId)}
            >
              Hủy
            </Button>
          </>
        )}
      </>
    ),
  },
];
