import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchData,
  loadMore,
  updateQuery,
  updateLoading,
} from '~store/reducers/booking-cont-completed/action';
import { showListFile } from '~store/reducers/white-board/action';
import { RangeDateFilter, TableWhiteBoardNew, WBTableLoadMore } from '~components';
import { getColumns } from './columns';
import { Wrapper, Title } from '~commons/styles';
import { useWBTableScroll } from '~hooks';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';

import ApiServices from '~services';
import { TASK_STATUSES } from '~commons/constants';

const objSortType = {
  ascend: 'ASC',
  descend: 'DESC',
};

export const BookingContCompleted = ({ tabKey }) => {
  const dispatch = useDispatch();
  const timeOutRef = useRef(null);

  const {
    data: bookingContDataCompleted,
    query,
    loading,
    selected,
  } = useSelector((state) => state.bookingContCompleted);
  //================= Scroll ======================
  const [loadingMore, setLoadingMore] = useState(false);
  const { tableRef, isScroll } = useWBTableScroll(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const LoadMoreData = async () => {
    if (loadingMore) return;
    if (
      bookingContDataCompleted.pageNumber * query.PageSize <=
      bookingContDataCompleted.totalRecords
    ) {
      let tmpQuery = { ...query, PageNumber: bookingContDataCompleted.pageNumber + 1 };
      setLoadingMore(true);
      await dispatch(loadMore(tmpQuery));
      setLoadingMore(false);
    }
  };
  useEffect(() => {
    if (isScroll) {
      LoadMoreData();
    }
  }, [isScroll]);

  const initData = async () => {
    dispatch(updateLoading(true));
    await dispatch(fetchData(query));
    dispatch(updateLoading(false));
  };
  useEffect(() => {
    timeOutRef.current && clearTimeout(timeOutRef.current);
    if (tabKey === 2 || tabKey === '2') {
      timeOutRef.current = setTimeout(() => initData(), 500);
    }
  }, [query, tabKey]);

  const setQuery = (columns) => {
    const tmpQuery = { ...query };
    tmpQuery.Columns = { ...tmpQuery.Columns, ...columns };
    dispatch(updateQuery(tmpQuery));
  };

  const changeToNotComplete = async (id) => {
    if (!id || loadingChangeStatus) return;
    setLoadingChangeStatus(true);
    try {
      const res = await ApiServices.changeBookBillContToNotComplete([id]);
      console.log({ res });
      if (res?.data?.errors?.length > 0) {
        res?.data?.errors?.length > 0 &&
          window.TmsNoti?.error(
            'Đổi trạng thái không thành công',
            res?.data?.errors?.map((item, index) => (
              <div key={index}>
                <b className='red'>
                  {index + 1}
                  {') '}
                </b>
                {item}
              </div>
            )),
            20
          );
      }
      if (res.data?.data === 'success') {
        window.TmsNoti?.success('Đổi trạng thái thành công');
        return await dispatch(fetchData(query));
      }
    } catch (error) {
      console.log(`Error:::`, error);
      window.TmsNoti?.error('Thao tác thất bại!');
    } finally {
      setLoadingChangeStatus(false);
    }
  };

  const handleExportExcel = async () => {
    if (!query || loadingExport) return;

    const dateFrom = moment(query?.DateFrom).format('YYYY-MM-DD');
    const dateTo = moment(query?.DateTo).format('YYYY-MM-DD');

    const dataSend = {
      DateFrom: query?.DateFrom && moment(query?.DateFrom).format('YYYY-MM-DD'),
      DateTo: query?.DateTo && moment(query?.DateTo).format('YYYY-MM-DD'),
      Columns: query?.Columns,
      export: true,
    };
    setLoadingExport(true);
    try {
      const { data } = await ApiServices.exportExcelHandleCompletedCont(dataSend);
      if (data) {
        const outputFilename = `Xu_Ly_Cont_Xuat_Da_Hoan_Thanh_Tu_ngay_${dateFrom}_den_ngay_${dateTo}.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoadingExport(false);
    }
  };

  const checkDisable = (record) =>
    record.status === TASK_STATUSES.FINISH || record.status === TASK_STATUSES.SENT_PARTNER;

  const handleTableChange = (pagination, filters, sorter) => {
    console.log({ pagination, filters, sorter });
    if (sorter?.field && sorter?.order) {
      const tmpQuery = { ...query };
      tmpQuery.SortColumn = sorter?.field;
      tmpQuery.SortColumnDirection = objSortType[sorter?.order];

      dispatch(updateQuery(tmpQuery));
    }
  };
  return (
    <Wrapper ref={tableRef}>
      <Title>
        Total: <b className='red'>({bookingContDataCompleted?.totalRecords || 0})</b>
        <RangeDateFilter query={query} updateQuery={updateQuery} mgLeft={false} />
        <span style={{ marginLeft: '10px' }}> </span>
        <Button
          type='primary'
          icon={<DownloadOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px' }}
          onClick={handleExportExcel}
          disabled={!bookingContDataCompleted?.data?.length}
          loading={loadingExport}
        >
          Export Excel
        </Button>
      </Title>

      <TableWhiteBoardNew
        rowKey='id'
        headerLength='200px'
        columns={getColumns(
          setQuery,
          (record) => dispatch(showListFile(record)),
          changeToNotComplete,
          loadingChangeStatus
        )}
        data={bookingContDataCompleted?.data}
        loading={loading}
        isNotSubHeight
        selectedParent={selected}
        checkDisableSelection={checkDisable}
        onChange={handleTableChange}
      />
      <WBTableLoadMore loading={loadingMore} />
    </Wrapper>
  );
};
