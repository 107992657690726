import React, { useEffect, useState } from 'react';
import ApiServices from '~services';
import { Selection } from '~components';
export const VendorSelection = ({ selected, onChange, setLoading }) => {
  const [vendors, setVendors] = useState([]);
  const getVendor = async (Query = null) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getVendors({ Query });
      console.log('getVendors', data);
      setVendors(data.data);
    } catch (error) {
      console.error('get Vendors Error', error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getVendor();
  }, []);
  return (
    <Selection
      mode='multiple'
      data={vendors}
      onSearch={getVendor}
      style={{ width: 188 }}
      onChange={onChange}
      value={selected}
      placeholder='Nhà xe'
    />
  );
};
