import storage from '~storage';
import { KEYS_OF_DATE, KEYS_OF_DATE_TIME } from './fields-data';
import moment from 'moment';

const formatFields = (data) => {
  const format = {};
  for (const item of data) {
    if (KEYS_OF_DATE.includes(item.name[0])) {
      format[item.name[0]] = item.value ? moment(item.value).format('YYYY-MM-DD') : null;
      continue;
    }
    if (KEYS_OF_DATE_TIME.includes(item.name[0])) {
      format[item.name[0]] = item.value ? moment(item.value).format() : null;
      continue;
    }

    format[item.name[0]] = item.value;
  }
  return format;
};
export const formatBookingData = (fields, conts) => {
  let format = formatFields(fields);
  // console.log('formatBookingData', format, fields);
  format.orderType = storage?.staticVariables?.OrderType?.Order_Booking;
  let dataConts = [];
  if (conts) {
    for (let cont of Object.values(conts)) {
      let total = cont.values?.reduce((prev, curr) => prev + curr.total, 0);
      let count = 0;

      if (total) {
        for (let el of cont.values) {
          if (el.total) {
            for (let i = 0; i < el.total; i++) {
              dataConts.push({
                orderNumber: ++count,
                quantityCont: total,
                code: format.bookingNumber,
                isCargo: false,
                cargoWeight: 0,
                typeCont: el.id,
                // book_CargoReadyDate: null,
                Book_DeliveryContRequestDate: el.date?.format(),
              });
            }
          }
        }
      }
    }
    format.conts = dataConts;
  }
  return format;
};
