import { useEffect, useState } from 'react';
import { Upload, Image } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const DEFAULT_VISIBLE = {
  visible: false,
  image: '',
  title: '',
};
export const UploadFileContNumber = ({ onchange, value }) => {
  const [visible, setVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [preview, setPreview] = useState(DEFAULT_VISIBLE);
  useEffect(() => {
    if (value) {
      setFileList(value);
      setFilePreviews(value);
      handlePreview(value[value.length - 1]);
    }
  }, [value]);

  const beforeUpload = (file) => {
    setFileList([...fileList, file]);
    setFilePreviews([...filePreviews, file]);
    return false;
  };

  const handleChange = ({ fileList }) => {
    setFilePreviews(fileList);

    if (fileList.length > 0) {
      handlePreview(fileList[fileList.length - 1]);
    }
    onchange?.(fileList);
  };

  const onRemove = (file) => {
    setFileList((curList) => {
      const index = curList?.length && curList.indexOf(file);
      const newFileList = (curList?.length && curList.slice()) || [];
      curList?.length && newFileList.splice(index, 1);
      onchange?.(newFileList);
      if (newFileList.length > 0) {
        handlePreview(newFileList[newFileList.length - 1]);
      }
      return newFileList;
    });
  };

  const handlePreview = async (file) => {
    if (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file?.originFileObj);
      }

      return setPreview({
        image: file.url || file.preview,
        visible: true,
        title: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
      });
    }
    setPreview(DEFAULT_VISIBLE);
  };

  return (
    <>
      <Upload
        multiple
        listType='picture-card'
        onChange={handleChange}
        beforeUpload={beforeUpload}
        fileList={filePreviews}
        onRemove={onRemove}
        onPreview={handlePreview}
      >
        <div>
          <PlusOutlined style={{ color: 'blue', fontSize: '30px' }} />
          <div style={{ marginTop: '10px' }}>Đính Kèm</div>
        </div>
      </Upload>
      {filePreviews.length > 0 && (
        <div
          style={{ maxHeight: 'calc(100vh - 415px)', overflow: 'auto', cursor: 'pointer' }}
          onClick={() => setVisible(true)}
        >
          <img
            alt='Preview of upload'
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            src={preview?.image}
          />
        </div>
      )}
      <div
        style={{
          display: 'none',
        }}
      >
        <Image.PreviewGroup
          preview={{
            visible,
            onVisibleChange: (vis) => setVisible(vis),
          }}
        >
          <Image src={preview?.image} />
        </Image.PreviewGroup>
      </div>
    </>
  );
};
