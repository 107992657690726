import React, { useCallback, useEffect, useState } from 'react';
import { Input, Table, DatePicker, Popconfirm, Space, message, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
const ExcelJS = require('exceljs');
import styled from 'styled-components';
import { TableTitle, Wrapper } from '~commons/styles';
import Utils from '~commons/utils';
import { RangeDateFilter } from '~components';
import moment from 'moment';
import { debounce } from 'lodash';
import ApiServices from '~services';
import { CONT_ORDER_TYPES, DATE_FORMAT } from '~commons/constants';
import { ContFiles } from '~components/cont-files';
import { ChangeLocationForm } from './change-location-form';
import useDataSelectSearch from '~hooks/use-data-select-search';
import { Selection } from '~components';
import useFinishTaskApi from '~pages/white-board/assign-car-list/use-finish-task-api';
import { ChangeEndLocation } from './change-end-location';
import {
  LIST_COLUMNS_TITLE_FIRST_SUMMARY_SHEET,
  MERGE_TWO_ROWS_TITLE_SUMMARY_SHEET,
} from './sheet-summary-constant';

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('date').format('YYYY-MM-DD hh:mm'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD hh:mm'),
};
const TaskJobDiary2 = () => {
  const { checkDistanceMultiple } = useFinishTaskApi();

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [changeLocation, setChangeLocation] = useState(null);
  const [taskJobDiaryList, setTaskJobDiaryList] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [filterSearch, setFilterSearch] = useState(null);
  const [checkDistance, setCheckDistance] = useState([]);
  const [showChangeEndLocation, setShowChangeEndLocation] = useState(false);
  const [dataSendSubmit, setDataSendSubmit] = useState(null);
  // const [total, setTotal] = useState(0);
  //
  const [assignDate, setAssignDate] = useState(null);
  // ================ Render Data  =======================
  const formatDataRender = (dataFormat = []) => {
    let dataFilter = [];
    let dataSortItem = [];
    let assignId = '';
    dataFormat?.map((itemInit, indexInit) => {
      // Init
      if (indexInit === 0 && indexInit + 1 === dataFormat?.length) {
        assignId = itemInit?.assignId;
        dataSortItem.push(itemInit);
        dataFilter = [itemInit];
        return;
      }

      // Init
      if (indexInit === 0) {
        assignId = itemInit?.assignId;
        dataSortItem.push(itemInit);
        return;
      }

      // Cung assignID nhung cai cuoi cung
      if (assignId === itemInit.assignId && indexInit + 1 === dataFormat?.length) {
        assignId = itemInit?.assignId;
        const dataSortItemEnd = [...dataSortItem]?.concat([itemInit]);

        dataSortItemEnd
          ?.sort((a, b) => {
            let da = a.sortAssign;
            let db = b.sortAssign;
            return da - db;
          })
          .map((dataEnd) => {
            dataFilter.push(dataEnd);
          });
        return;
      }

      // Neu cung assignId
      if (assignId === itemInit.assignId) {
        dataSortItem.push(itemInit);
        return;
      }

      // Khac assignId
      if (assignId !== itemInit.assignId) {
        assignId = itemInit?.assignId;
        const dataSortItemEnd = [...dataSortItem];
        // if (itemInit?.name === 'Đi nhậu') {
        //   console.log(`data DI nhau::`, itemInit);
        //   console.log({ dataSortItemEnd });
        // }
        dataSortItemEnd
          ?.sort((a, b) => {
            let da = a.sortAssign;
            let db = b.sortAssign;
            return da - db;
          })
          .map((dataEnd) => {
            dataFilter.push(dataEnd);
          });
        if (indexInit + 1 === dataFormat?.length) {
          dataFilter.push(itemInit);
        }
        dataSortItem = [itemInit];
        return;
      }
    });

    let dataGroup = [];
    dataFilter?.forEach((item, index) => {
      if (index === 0) {
        const dataMap = {
          id: item?.carId,
          groupCar: item?.carCode,
          children: [item],
        };
        dataGroup.push(dataMap);
        return;
      }
      const ind = dataGroup?.findIndex((el) => el?.id === item.carId);
      if (ind !== -1) {
        dataGroup?.[ind]?.children?.push(item);
        return;
      } else {
        const dataMap = {
          id: item?.carId,
          groupCar: item?.carCode,
          children: [item],
        };
        dataGroup.push(dataMap);
        return;
      }
    });

    const dataFinalMap = dataGroup?.map((dataFinal) => {
      dataFinal.children = dataFinal.children?.sort((a, b) => {
        let da = new Date(a.assignDate);
        let db = new Date(b.assignDate);
        return da - db;
      });
      return dataFinal;
    });
    return dataFinalMap;
  };

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = {
      ...query,
    };

    return ApiServices.getTaskJobDiaryDetail(newQuery)
      .then(async (res) => {
        if (res?.data?.data) {
          setOriginData(res?.data?.data);
          // setTotal(res?.data?.data ? res?.data?.data?.length : 0);
          const dataResult = await formatDataRender(res?.data?.data);
          if (dataResult) {
            const activeExpanse = dataResult?.map((el) => el?.id);
            setExpandedRowKeys(activeExpanse);
            setTaskJobDiaryList(dataResult);
          }
        }
      })
      .catch((err) => {
        console.error('get Task Job Diary Detail Error: ', err);
      })
      .finally(() => setLoading(false));
  };

  const handleFilterData = async (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;
      if (query?.carCode) {
        if (
          !Utils.convertValSearch(item?.carCode)?.includes(Utils.convertValSearch(query?.carCode))
        ) {
          checkFilter = false;
        }
      }
      if (query?.assignDate) {
        if (moment(item?.assignDate).format('YYYY-MM-DD') !== query?.assignDate) {
          checkFilter = false;
        }
      }
      if (query?.name) {
        if (!Utils.convertValSearch(item?.name)?.includes(Utils.convertValSearch(query?.name))) {
          checkFilter = false;
        }
      }
      if (query?.customerName) {
        if (
          !Utils.convertValSearch(item?.customerName)?.includes(
            Utils.convertValSearch(query?.customerName)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.assignUser) {
        if (
          !Utils.convertValSearch(item?.assignUser)?.includes(
            Utils.convertValSearch(query?.assignUser)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.driverName) {
        if (
          !Utils.convertValSearch(item?.driverName)?.includes(
            Utils.convertValSearch(query?.driverName)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.romoocCode) {
        if (
          !Utils.convertValSearch(item?.romoocCode)?.includes(
            Utils.convertValSearch(query?.romoocCode)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.subnoNumber) {
        if (
          !Utils.convertValSearch(item?.subnoNumber)?.includes(
            Utils.convertValSearch(query?.subnoNumber)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.beginLocationName) {
        if (
          !Utils.convertValSearch(item?.beginLocationName)?.includes(
            Utils.convertValSearch(query?.beginLocationName)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.endLocationName) {
        if (
          !Utils.convertValSearch(item?.endLocationName)?.includes(
            Utils.convertValSearch(query?.endLocationName)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.contNumber) {
        if (
          !Utils.convertValSearch(item?.contNumber)?.includes(
            Utils.convertValSearch(query?.contNumber)
          )
        ) {
          checkFilter = false;
        }
      }
      //// End
      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    // setTotal(dataFilter ? dataFilter : 0);
    const dataResult = await formatDataRender(dataFilter);

    const activeExpanse = dataResult?.map((el) => el?.id);
    setExpandedRowKeys(activeExpanse);
    setTaskJobDiaryList(dataResult);
  };

  const timeoutGetDataSearch = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    []
  );
  useEffect(() => {
    if (originData?.length) {
      timeoutGetDataSearch(filterSearch, originData);
    }
  }, [filterSearch]);

  const onColumnSearch = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilterSearch((prev) => {
      return { ...prev, [name]: value };
    });
  };

  // Get Data Init
  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 1000),
    []
  );

  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);
  const handleChangeAssignDate = (date) => {
    setAssignDate(date);
    const dateSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    setFilterSearch((prev) => {
      return { ...prev, assignDate: dateSearch };
    });
  };

  const onRowMerged = (record) => {
    if (record.groupCar) {
      return {
        colSpan: 0,
      };
    }
  };

  const handleChangeLocation = (record) => {
    setChangeLocation(record);
  };

  const renderAction = (values, record) => {
    if (
      (record?.sort === 1 &&
        record?.typeOrder === CONT_ORDER_TYPES.BOOKING &&
        record?.status === 9) ||
      (record?.sort === 6 && record?.typeOrder === CONT_ORDER_TYPES.SUPPORT && record?.status === 9)
    ) {
      const ind = taskJobDiaryList?.findIndex((item) => item?.id === record?.carId);
      // Check the next job is Lấy Rỗng Lại_SP: typeOrder === 'Support' vaf Sort = 6
      let indexTask = -1;
      if (ind !== -1) {
        indexTask = taskJobDiaryList[ind]?.children?.findIndex((el) => el?.id === record?.id);
      }
      if (
        ind !== -1 &&
        indexTask !== -1 &&
        taskJobDiaryList[ind]?.children?.[indexTask + 1]?.sort === 6
      ) {
        return <></>;
      }
      const jobNext = taskJobDiaryList[ind]?.children?.[indexTask + 1];
      return (
        <a
          type='link'
          style={{ padding: '0 0', color: 'red' }}
          onClick={() =>
            handleChangeLocation({
              ...record,
              endLocationCodeNext: jobNext?.endLocationCode,
              endLocationNameNext: jobNext?.endLocationName,
            })
          }
        >
          Đổi địa điểm lấy rỗng
        </a>
      );
    }
  };
  const renderImageCurrent = (files) => {
    if (!files) return;
    const initImage = files?.filter((item) => item?.status === 0);
    return <ContFiles files={initImage} />;
  };
  const renderImageEdited = (files) => {
    if (!files) return;
    const initImage = files?.filter((item) => item?.status === 1);
    return <ContFiles files={initImage} />;
  };

  // ==========================================================
  const reloadAfterChangeLocation = () => {
    getData(filter);
  };

  const handleChangeEndLocation = async (jobsId, locationId) => {
    return ApiServices.replaceLocation({
      jobsId,
      replaceLocation: locationId,
    })
      .then((res) => {
        if (res.data) {
          message.success('Thao tác thành công!');
          reloadAfterChangeLocation();
        }
      })
      .catch((err) => {
        console.error('Change Depot Location Error', err);
      });
  };

  const renderEndLocation = (val, record) => {
    // if (record.status === TASK_STATUSES.REPORTED_THE_PROBLEM) return <div>{val}</div>;

    const onOK = async (locationId) => {
      if (!locationId) {
        return;
      }
      const ind = taskJobDiaryList?.findIndex((item) => item?.id === record?.carId);
      const indexTask = taskJobDiaryList[ind]?.children?.findIndex((el) => el?.id === record?.id);
      const jobNext = taskJobDiaryList[ind]?.children?.[indexTask + 1];

      let dataCheckLocation = [];
      let jobIdList = [];
      if (jobNext) {
        dataCheckLocation = [
          {
            begin: record?.beginLocationCode,
            end: locationId,
          },
          {
            begin: locationId,
            end: jobNext?.endLocationCode || '',
          },
        ];
        jobIdList = [record?.id, jobNext?.id];
      } else {
        dataCheckLocation = [
          {
            begin: record?.beginLocationCode,
            end: locationId,
          },
        ];
        jobIdList = [record?.id];
      }
      const checkDistance = await checkDistanceMultiple(dataCheckLocation);
      if (checkDistance) {
        const dataSend = {
          jobIdList,
          locationId,
        };
        setDataSendSubmit(dataSend);
        setCheckDistance(checkDistance);
        setShowChangeEndLocation(true);
      } else {
        handleChangeEndLocation(jobIdList, locationId);
      }
    };
    return <ChangeDepotLocation oldLocation={val} onOK={onOK} />;
  };

  const handleCloseChangeEndLocation = () => {
    setShowChangeEndLocation(false);
    setAssignDate(null);
    setDataSendSubmit(null);
  };

  const handleSubmitEndLocation = async (dataSend) => {
    await handleChangeEndLocation(dataSend?.jobIdList, dataSend?.locationId);
    handleCloseChangeEndLocation();
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Số Xe
          <Input name='carCode' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'carCode',
      width: 100,
      // align: 'center',
      render: (_, record) =>
        record.carCode || (
          <b className='red'>
            SỐ XE: <span className='blue'>{record.groupCar}</span>
          </b>
        ),
      onCell: (record) => {
        if (record.groupCar) {
          return {
            colSpan: 6,
          };
        }
      },
    },
    {
      title: (
        <TableTitle>
          Ngày Điều
          <DatePicker
            value={assignDate}
            onChange={handleChangeAssignDate}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'assignDate',
      render: (val) => Utils.formatDate(val),
      width: 100,
      align: 'center',
      onCell: onRowMerged,
    },
    {
      title: (
        <TableTitle>
          Công Việc
          <Input name='name' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'name',
      width: 120,
    },
    {
      title: (
        <TableTitle>
          Người Điều
          <Input name='assignUser' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'assignUser',
      width: 100,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Khách Hàng
          <Input name='customerName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'customerName',
      width: 150,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Tài Xế
          <Input name='driverName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'driverName',
      width: 100,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Số Romooc
          <Input name='romoocCode' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'romoocCode',
      width: 80,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Subno
          <Input name='subnoNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'subnoNumber',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Khởi Hành
          <Input name='beginLocationName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'beginLocationName',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Kết Thúc
          <Input name='endLocationName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'endLocationName',
      width: 150,
      render: renderEndLocation,
    },
    {
      title: <TableTitle>Số Km</TableTitle>,
      dataIndex: 'distance',
      width: 60,
      align: 'center',
    },
    {
      title: <TableTitle>Km H.Hụt</TableTitle>,
      dataIndex: 'lossOfDistance',
      width: 60,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Số Cont
          <Input name='contNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'contNumber',
      width: 120,
      align: 'center',
    },
    {
      dataIndex: 'files',
      title: <TableTitle>File đính kèm</TableTitle>,
      width: 220,
      render: renderImageCurrent,
    },
    {
      dataIndex: 'files',
      title: <TableTitle>Đã chỉnh sửa</TableTitle>,
      width: 130,
      render: renderImageEdited,
    },
    {
      title: <TableTitle>Ghi Chú</TableTitle>,
      dataIndex: 'note',
      width: 120,
    },
    {
      title: <TableTitle>Thao tác</TableTitle>,
      // dataIndex: 'typeOrder',
      fixed: 'right',
      width: 120,
      render: (value, record) => renderAction(value, record),
    },
  ];

  const exportExcelFile = async (dataExport = []) => {
    let convertDataExport = [];
    dataExport?.map((item) => {
      if (item?.children?.length) {
        convertDataExport = convertDataExport?.concat(item?.children);
      }
    });
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('NKHT', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ state: 'frozen', ySplit: 4, activeCell: 'A1', showGridLines: false }],
    });
    // chieu cao - chieu rong cot
    sheetSummary.properties.defaultRowHeight = 15;
    sheetSummary.properties.defaultColWidth = 15;

    sheetSummary.getRow(3).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getCell('G3').value = 'NHẬT KÝ HÀNH TRÌNH';
    sheetSummary.getRow(4).font = {
      name: 'Arial',
      family: 2,
      size: 10,
      italic: true,
    };
    sheetSummary.getCell('G4').value = `Từ Ngày ${moment(filter?.DateFrom).format(
      'DD/MM/YYYY'
    )} -> ${moment(filter?.DateTo).format('DD/MM/YYYY')}`;

    sheetSummary.addRow(['']); // blank Row
    // add Title
    sheetSummary.addRow(LIST_COLUMNS_TITLE_FIRST_SUMMARY_SHEET);
    MERGE_TWO_ROWS_TITLE_SUMMARY_SHEET.forEach((item) => {
      sheetSummary.getCell(`${item}6`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetSummary.getCell(`${item}6`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });
    // ================= Body  ==========================
    let show = false;
    let carId = '';
    await convertDataExport?.map((itemExport, indexRow) => {
      if (indexRow === 0) {
        carId = itemExport?.carId;
      } else if (carId !== itemExport.carId) {
        carId = itemExport?.carId;
        show = !show;
      }

      sheetSummary.addRow([
        '',
        itemExport?.carCode || '', // So Xe
        itemExport?.assignDate && new Date(moment(itemExport?.assignDate).format('YYYY-MM-DD')), // Ngày điều
        itemExport?.name || '', // Cong Viec
        itemExport?.assignUser || '', // Nguoi Dieu
        itemExport?.customerName || '', // Khach Hang
        itemExport?.driverName || '', // Tai Xe
        itemExport?.romoocCode || '', // So Romooc
        itemExport?.subnoNumber || '', // So SubNo
        itemExport?.beginLocationName || '', // Khoi hanh
        itemExport?.endLocationName || '', // Ket thuc
        itemExport?.distance || 0, // So Km
        itemExport?.lossOfDistance || 0, // So Km Hao Hut
        itemExport?.contNumber || '', // So Cont
        itemExport?.note || '', // Ghi Chu
      ]);
      MERGE_TWO_ROWS_TITLE_SUMMARY_SHEET.forEach((item) => {
        sheetSummary.getCell(`${item}${indexRow + 7}`).border = {
          top: { style: 'thin', color: { argb: '006699' } },
          left: { style: 'thin', color: { argb: '006699' } },
          right: { style: 'thin', color: { argb: '006699' } },
          bottom: { style: 'thin', color: { argb: '006699' } },
        };
        if (!show) {
          sheetSummary.getCell(`${item}${indexRow + 7}`).fill = {
            type: 'gradient',
            gradient: 'angle',
            stops: [
              { position: 0, color: { argb: 'DBE4EE' } },
              { position: 1, color: { argb: 'DBE4EE' } },
            ],
          };
        }
      });
    });
    // ================= Style ==========================
    sheetSummary.getRow(6).font = {
      name: 'Arial',
      family: 4,
      size: 11,
      bold: true,
    };
    sheetSummary.getRow(6).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getColumn(2).width = 13;
    sheetSummary.getColumn(3).width = 13;
    sheetSummary.getColumn(4).width = 20;
    sheetSummary.getColumn(5).width = 13;
    sheetSummary.getColumn(6).width = 20;
    sheetSummary.getColumn(7).width = 20;
    sheetSummary.getColumn(8).width = 10;
    sheetSummary.getColumn(9).width = 25;
    sheetSummary.getColumn(10).width = 25;
    sheetSummary.getColumn(11).width = 25;
    sheetSummary.getColumn(12).width = 10;
    sheetSummary.getColumn(13).width = 13;
    sheetSummary.getColumn(15).width = 30;

    // ================= Export ==========================
    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Nhat_Ky_Hanh_Trinh_Tu_${moment(filter?.DateFrom).format(
          'DD-MM-YYYY'
        )}_Den_${moment(filter?.DateTo).format('DD-MM-YYYY')}_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  // const rowSelection = {
  //   checkStrictly: false,
  // };
  const expandable = { expandedRowKeys, onExpandedRowsChange: setExpandedRowKeys };

  const renderRowClassName = (record) => {
    if (record?.typeOrder === CONT_ORDER_TYPES.SUPPORT && record?.sort === 6) {
      return 'task-job-daily-background';
    }
    return '';
  };

  // const pagination = {
  //   current: 1,
  //   total: total || 0,
  //   pageSize: 20,
  //   pageSizeOptions: [10, 20, 30],
  // };

  return (
    <Wrapper>
      <h3>Nhật Ký Hành Trình</h3>
      <Space>
        <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
        <Button
          type='primary'
          loading={loading}
          icon={<DownloadOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px' }}
          disabled={!taskJobDiaryList?.length}
          onClick={() => exportExcelFile(taskJobDiaryList)}
        >
          Export Excel
        </Button>
      </Space>

      <div
        className='table-container-custom-assign-history'
        style={{
          maxHeight: `calc(100vh - 20px)`,
          marginTop: '15px',
        }}
      >
        <TableStyled
          loading={loading}
          columns={columns}
          dataSource={taskJobDiaryList}
          // pagination={pagination}
          expandable={expandable}
          // rowSelection={rowSelection}
          rowKey='id'
          size='small'
          scroll={{ x: '100vw', y: 'calc(100vh - 250px)' }}
          bordered
          rowClassName={renderRowClassName}
        />
      </div>
      <ChangeLocationForm
        job={changeLocation}
        onClose={() => setChangeLocation(null)}
        reload={() => getData(filter)}
        setLoading={setLoading}
      />
      <ChangeEndLocation
        checkDistance={checkDistance}
        setCheckDistance={setCheckDistance}
        showChangeEndLocation={showChangeEndLocation}
        onClose={handleCloseChangeEndLocation}
        dataSendSubmit={dataSendSubmit}
        handleSubmitEndLocation={handleSubmitEndLocation}
      />
    </Wrapper>
  );
};

export { TaskJobDiary2 };

const ChangeDepotLocation = ({ oldLocation, onOK }) => {
  const [location, setLocation] = useState();
  const [locations, getLocations] = useDataSelectSearch(ApiServices.getLocations);
  const onSearchLocation = (Query) => getLocations({ Query }, 'name');
  const onConfirm = () => onOK?.(location);

  return (
    <Popconfirm
      onConfirm={onConfirm}
      title={
        <Wrapper>
          <Space direction='vertical'>
            <Selection
              placeholder='Chọn Địa Điểm mới'
              data={locations}
              onSearch={onSearchLocation}
              value={location}
              onChange={setLocation}
            />
          </Space>
        </Wrapper>
      }
    >
      <a href='#' type='link'>
        {oldLocation}
      </a>
    </Popconfirm>
  );
};

const TableStyled = styled(Table)`
  & .ant-table-row-indent.indent-level-1 {
    display: none;
  }
  & button.ant-table-row-expand-icon-spaced {
    display: none;
  }
`;
