import React from 'react';
import { Descriptions } from 'antd';

const InfoCarInspection = ({ infoCarInspection }) => {
  return (
    <Descriptions
      // title="Thông Tin Xe:"
      bordered
      column={2}
      size='small'
      labelStyle={{ backgroundColor: '#CEE5D0' }}
    >
      <Descriptions.Item label='Số Romooc'>{infoCarInspection?.romoocCode}</Descriptions.Item>
      <Descriptions.Item label='Nhãn Hiệu'>{infoCarInspection?.label}</Descriptions.Item>
      <Descriptions.Item label='Chủng Loại'>{infoCarInspection?.type}</Descriptions.Item>
      <Descriptions.Item label='Nơi Sản Xuất'>{infoCarInspection?.madeIn}</Descriptions.Item>
      <Descriptions.Item label='Số Khung'>{infoCarInspection?.frameNumber}</Descriptions.Item>
    </Descriptions>
  );
};

export { InfoCarInspection };
