import React, { useEffect, useState } from 'react';
import { Table, Typography, Button, Space, Divider } from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
// import { debounce } from 'lodash';
import moment from 'moment';
import { Wrapper } from '~commons/styles';
import { RangeDateFilter } from '~components';
import ApiServices from '~services';
const ExcelJS = require('exceljs');
import Utils from '~commons/utils';

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};
const { Text } = Typography;

const taskListJob = [
  'veBai', // + 'Về Bãi / Cắt Mooc' nếu length là 27
  'traRongNhapSP',
  'traRongNhap',
  'layRongSP',
  'layRongXuat',
  'layRongNhap',
  'layRongLaiSP',
  'layRomoocContUiCk',
  'layRomooc',
  'layLenhGiay',
  'layLaiHangXuatTuCang',
  'layHangXuatSP',
  'layHangXuat',
  'layHangNhap',
  'giaoRongXuatSP',
  'giaoRongXuat',
  'giaoLaiHangXuatTaiKho',
  'giaoHangXuatSP',
  'giaoHangXuat',
  'giaoHangNhap',
  'giaoHangCK',
  'dongHangCK',
  'diDoiTraSP',
  'diDoiCont',
  'dangKiemXe',
  'dangKiemRomooc',
];

export const ReportOilSummary = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [dateFromValid, setDateFromValid] = useState(null);
  const [dateToValid, setDateToValid] = useState(null);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = {
      dateFrom: query?.DateFrom,
      dateTo: query?.DateTo,
      export: false,
    };
    setDateFromValid(query?.DateFrom);
    setDateToValid(query?.DateTo);
    return ApiServices.getReportOilSummary(newQuery)
      .then((res) => {
        if (res?.data?.data) {
          // console.log({ dataOrrigin: res?.data?.data });
          const mapData = res?.data?.data?.map((item) => {
            const objectKey = Object.keys(item)[0];
            let dataEnd = {};
            let calcOil = 0;
            const lengthObjectKeyData = item?.[objectKey]?.length;

            item?.[objectKey]?.map((el, index) => {
              // nếu length = 26: chỉ có name: 'Về Bãi / Cắt Mooc';
              if (lengthObjectKeyData === 26) {
                dataEnd[taskListJob[index]] = el?.totalOil || 0;
                if (el?.totalOil) {
                  calcOil = calcOil + el.totalOil;
                }
              }
              // nếu length = 27:  có name: 'Về Bãi / Cắt Mooc' và Về Bãi;
              if (lengthObjectKeyData === 27) {
                // bỏ bớt 1 thằng đi
                if (index === 1) return;
                let totalOilAll = el?.totalOil || 0;
                if (index === 0) {
                  totalOilAll += item?.[objectKey][1].totalOil ? item?.[objectKey][1].totalOil : 0;
                  dataEnd[taskListJob[index]] = totalOilAll;
                } else {
                  dataEnd[taskListJob[index - 1]] = totalOilAll;
                }
                if (totalOilAll) {
                  calcOil = calcOil + totalOilAll;
                }
              }
            });

            const cutStringKey = objectKey?.split('/');

            return {
              carCode: cutStringKey?.[0] || '',
              driverName: cutStringKey?.[1] || '',
              ...dataEnd,
              calcOil,
            };
          });

          setData(mapData);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        console.error('get list report oil summary Error', err);
        setData([]);
      })
      .finally(() => setLoading(false));
  };

  // const timeoutGetData = useCallback(
  //   debounce((newFilter) => {
  //     getData(newFilter);
  //   }, 1000),
  //   []
  // );

  // useEffect(() => {
  //   timeoutGetData(filter);
  // }, [filter]);

  useEffect(() => {
    getData();
  }, []);

  const handleGetDateByFilter = async (newFilter) => {
    await getData(newFilter);
  };

  const displayEmptyValue = (value) => {
    if (value) {
      return Utils.formatNumber(value);
    } else {
      return 0;
    }
  };

  const columns = [
    {
      title: 'Số xe',
      dataIndex: 'carCode',
      width: 120,
      fixed: true,
      align: 'center',
    },
    {
      title: 'Tài xế',
      dataIndex: 'driverName',
      width: 180,
      fixed: true,
      align: 'center',
    },
    {
      title: 'Về bãi',
      dataIndex: 'veBai',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Trả Rỗng Nhập_SP',
      dataIndex: 'traRongNhapSP',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Trả Rỗng Nhập',
      dataIndex: 'traRongNhap',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Lấy Rỗng_SP',
      dataIndex: 'layRongSP',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Lấy Rỗng Xuất',
      dataIndex: 'layRongXuat',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Lấy Rỗng Nhập',
      dataIndex: 'layRongNhap',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Lấy Rỗng Lại_SP',
      dataIndex: 'layRongLaiSP',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Lấy Romooc Cont UI CK',
      dataIndex: 'layRomoocContUiCk',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Lấy Romooc',
      dataIndex: 'layRomooc',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Lấy Lệnh (Giấy)',
      dataIndex: 'layLenhGiay',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Lấy Lại Hàng Xuất - Từ Cảng',
      dataIndex: 'layLaiHangXuatTuCang',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Lấy Hàng Xuất_SP',
      dataIndex: 'layHangXuatSP',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Lấy Hàng Xuất',
      dataIndex: 'layHangXuat',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Lấy Hàng Nhập',
      dataIndex: 'layHangNhap',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Giao Rỗng Xuất_SP',
      dataIndex: 'giaoRongXuatSP',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Giao Rỗng Xuất',
      dataIndex: 'giaoRongXuat',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Giao Lại Hàng Xuất - Tại Kho',
      dataIndex: 'giaoLaiHangXuatTaiKho',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Giao Hàng Xuất_SP',
      dataIndex: 'giaoHangXuatSP',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Giao Hàng Xuất',
      dataIndex: 'giaoHangXuat',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Giao Hàng Nhập',
      dataIndex: 'giaoHangNhap',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Giao Hàng CK',
      dataIndex: 'giaoHangCK',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Đóng Hàng CK',
      dataIndex: 'dongHangCK',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Di Dời Trả_SP',
      dataIndex: 'diDoiTraSP',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Di Dời Cont',
      dataIndex: 'diDoiCont',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Đăng Kiểm Xe',
      dataIndex: 'dangKiemXe',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Đăng Kiểm Romooc',
      dataIndex: 'dangKiemRomooc',
      width: 100,
      render: displayEmptyValue,
      align: 'center',
    },
    {
      title: 'Tổng dầu',
      dataIndex: 'calcOil',
      width: 100,
      align: 'center',
      render: (value) => <b className='red'>{displayEmptyValue(value)}</b>,
    },
  ];

  const handleSummary = (pageData) => {
    let totalVeBai = 0;
    let totalTraRongNhapSP = 0;
    let totalTraRongNhap = 0;
    let totalLayRongSP = 0;
    let totalLayRongXuat = 0;
    let totalLayRongNhap = 0;
    let totalLayRongLaiSP = 0;
    let totalLayRomoocContUiCk = 0;
    let totalLayRomooc = 0;
    let totalLayLenhGiay = 0;
    let totalLayLaiHangXuatTuCang = 0;
    let totalLayHangXuatSP = 0;
    let totalLayHangXuat = 0;
    let totalLayHangNhap = 0;
    let totalGiaoRongXuatSP = 0;
    let totalGiaoRongXuat = 0;
    let totalGiaoLaiHangXuatTaiKho = 0;
    let totalGiaoHangXuatSP = 0;
    let totalGiaoHangXuat = 0;
    let totalGiaoHangNhap = 0;
    let totalGiaoHangCK = 0;
    let totalDongHangCK = 0;
    let totalDiDoiTraSP = 0;
    let totalDiDoiCont = 0;
    let totalDangKiemXe = 0;
    let totalDangKiemRomooc = 0;
    let totalCalcOil = 0;

    pageData.forEach((page) => {
      totalVeBai += page.veBai;
      totalTraRongNhapSP += page.traRongNhapSP;
      totalTraRongNhap += page.traRongNhap;
      totalLayRongSP += page.layRongSP;
      totalLayRongXuat += page.layRongXuat;
      totalLayRongNhap += page.layRongNhap;
      totalLayRongLaiSP += page.layRongLaiSP;
      totalLayRomoocContUiCk += page.layRomoocContUiCk;
      totalLayRomooc += page.layRomooc;
      totalLayLenhGiay += page.layLenhGiay;
      totalLayLaiHangXuatTuCang += page.layLaiHangXuatTuCang;
      totalLayHangXuatSP += page.layHangXuatSP;
      totalLayHangXuat += page.layHangXuat;
      totalLayHangNhap += page.layHangNhap;
      totalGiaoRongXuatSP += page.giaoRongXuatSP;
      totalGiaoRongXuat += page.giaoRongXuat;
      totalGiaoLaiHangXuatTaiKho += page.giaoLaiHangXuatTaiKho;
      totalGiaoHangXuatSP += page.giaoHangXuatSP;
      totalGiaoHangXuat += page.giaoHangXuat;
      totalGiaoHangNhap += page.giaoHangNhap;
      totalGiaoHangCK += page.giaoHangCK;
      totalDongHangCK += page.dongHangCK;
      totalDiDoiTraSP += page.diDoiTraSP;
      totalDiDoiCont += page.diDoiCont;
      totalDangKiemXe += page.dangKiemXe;
      totalDangKiemRomooc += page.dangKiemRomooc;
      totalCalcOil += page.calcOil;
    });
    return (
      <Table.Summary fixed>
        <Table.Summary.Row
          style={{
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Table.Summary.Cell index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}>Tổng</Table.Summary.Cell>

          <Table.Summary.Cell index={2}>
            <Text type='danger' strong>
              {totalVeBai ? Utils.formatNumber(totalVeBai) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            <Text type='danger' strong>
              {totalTraRongNhapSP ? Utils.formatNumber(totalTraRongNhapSP) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            <Text type='danger' strong>
              {totalTraRongNhap ? Utils.formatNumber(totalTraRongNhap) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            <Text type='danger' strong>
              {totalLayRongSP ? Utils.formatNumber(totalLayRongSP) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}>
            <Text type='danger' strong>
              {totalLayRongXuat ? Utils.formatNumber(totalLayRongXuat) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7}>
            <Text type='danger' strong>
              {totalLayRongNhap ? Utils.formatNumber(totalLayRongNhap) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8}>
            <Text type='danger' strong>
              {totalLayRongLaiSP ? Utils.formatNumber(totalLayRongLaiSP) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={9}>
            <Text type='danger' strong>
              {totalLayRomoocContUiCk ? Utils.formatNumber(totalLayRomoocContUiCk) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            <Text type='danger' strong>
              {totalLayRomooc ? Utils.formatNumber(totalLayRomooc) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            <Text type='danger' strong>
              {totalLayLenhGiay ? Utils.formatNumber(totalLayLenhGiay) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}>
            <Text type='danger' strong>
              {totalLayLaiHangXuatTuCang ? Utils.formatNumber(totalLayLaiHangXuatTuCang) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={13}>
            <Text type='danger' strong>
              {totalLayHangXuatSP ? Utils.formatNumber(totalLayHangXuatSP) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={14}>
            <Text type='danger' strong>
              {totalLayHangXuat ? Utils.formatNumber(totalLayHangXuat) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={15}>
            <Text type='danger' strong>
              {totalLayHangNhap ? Utils.formatNumber(totalLayHangNhap) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={16}>
            <Text type='danger' strong>
              {totalGiaoRongXuatSP ? Utils.formatNumber(totalGiaoRongXuatSP) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={17}>
            <Text type='danger' strong>
              {totalGiaoRongXuat ? Utils.formatNumber(totalGiaoRongXuat) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={18}>
            <Text type='danger' strong>
              {totalGiaoLaiHangXuatTaiKho ? Utils.formatNumber(totalGiaoLaiHangXuatTaiKho) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={19}>
            <Text type='danger' strong>
              {totalGiaoHangXuatSP ? Utils.formatNumber(totalGiaoHangXuatSP) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={20}>
            <Text type='danger' strong>
              {totalGiaoHangXuat ? Utils.formatNumber(totalGiaoHangXuat) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={21}>
            <Text type='danger' strong>
              {totalGiaoHangNhap ? Utils.formatNumber(totalGiaoHangNhap) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={22}>
            <Text type='danger' strong>
              {totalGiaoHangCK ? Utils.formatNumber(totalGiaoHangCK) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={23}>
            <Text type='danger' strong>
              {totalDongHangCK ? Utils.formatNumber(totalDongHangCK) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={24}>
            <Text type='danger' strong>
              {totalDiDoiTraSP ? Utils.formatNumber(totalDiDoiTraSP) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={25}>
            <Text type='danger' strong>
              {totalDiDoiCont ? Utils.formatNumber(totalDiDoiCont) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={26}>
            <Text type='danger' strong>
              {totalDangKiemXe ? Utils.formatNumber(totalDangKiemXe) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={27}>
            <Text type='danger' strong>
              {totalDangKiemRomooc ? Utils.formatNumber(totalDangKiemRomooc) : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={27}>
            <Text type='success' strong>
              {totalCalcOil ? Utils.formatNumber(totalCalcOil) : 0}
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const handleExportExcel = async (dataExport) => {
    if (loading) return;

    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('BC Số lít dầu', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 30;
    sheetSummary.properties.defaultColWidth = 15;
    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getColumn(11).width = 25;
    sheetSummary.getColumn(13).width = 30;
    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getCell('H2').value = 'BÁO CÁO SỐ LÍT DẦU';
    sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(3).font = {
      name: 'Arial',
      family: 2,
      size: 10,
      italic: true,
    };
    sheetSummary.getCell('H3').value = `Từ Ngày ${moment(filter?.DateFrom).format(
      'DD/MM/YYYY'
    )} -> ${moment(filter?.DateTo).format('DD/MM/YYYY')}`;

    // Add table
    const arrDetail = await dataExport?.map((item, indexDetail) => {
      const dataItem = [
        indexDetail + 1, // STT
        item?.carCode, //So Xe
        item?.driverName, // Hang Muc
        item?.veBai, // So KM
        item?.traRongNhapSP, // Ghi Chu
        item?.traRongNhap, //So Xe
        item?.layRongSP, // Hang Muc
        item?.layRongXuat, // So KM
        item?.layRongNhap, // Ghi Chu
        item?.layRongLaiSP, // Ghi Chu
        item?.layRomoocContUiCk, //So Xe
        item?.layRomooc, // Hang Muc
        item?.layLenhGiay, // So KM
        item?.layLaiHangXuatTuCang, // Ghi Chu
        item?.layHangXuatSP, // Ghi Chu
        item?.layHangXuat, //So Xe
        item?.layHangNhap, // Hang Muc
        item?.giaoRongXuatSP, // So KM
        item?.giaoRongXuat, // Ghi Chu
        item?.giaoLaiHangXuatTaiKho, //So Xe
        item?.giaoHangXuatSP, // Hang Muc
        item?.giaoHangXuat, // So KM
        item?.giaoHangNhap, // Ghi Chu
        item?.giaoHangCK, //So Xe
        item?.dongHangCK, // Hang Muc
        item?.diDoiTraSP, // So KM
        item?.diDoiCont, // Ghi Chu
        item?.dangKiemXe, //So Xe
        item?.dangKiemRomooc, // Hang Muc
        item?.calcOil, // So KM
      ];
      return dataItem;
    });

    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B5',
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        {
          name: 'Số Xe',
          filterButton: false,
        },
        {
          name: 'Tài xế',
          filterButton: false,
        },
        {
          name: 'Về bãi',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Trả Rỗng Nhập_SP',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Trả Rỗng Nhập',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Lấy Rỗng_SP',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Lấy Rỗng Xuất',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Lấy Rỗng Nhập',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Lấy Rỗng Lại_SP',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Lấy Romooc Cont UI CK',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Lấy Romooc',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Lấy Lệnh (Giấy)',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Lấy Lại Hàng Xuất - Từ Cảng',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Lấy Hàng Xuất_SP',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Lấy Hàng Xuất',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Lấy Hàng Nhập',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Giao Rỗng Xuất_SP',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Giao Rỗng Xuất',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Giao Lại Hàng Xuất - Tại Kho',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Giao Hàng Xuất_SP',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Giao Hàng Xuất',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Giao Hàng Nhập',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Giao Hàng CK',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Đóng Hàng CK',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Di Dời Trả_SP',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Di Dời Cont',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Đăng Kiểm Xe',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Đăng Kiểm Romooc',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Tổng dầu',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
      ],
      rows: arrDetail,
    });

    // ========== STYLES =====================
    sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    sheetSummary.getColumn(2).width = 5;

    for (let i = 4; i <= 32; i++) {
      sheetSummary.getColumn(i).numFmt = '#,##0';
      sheetSummary.getColumn(i).width = 15;
    }
    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Bao_cao_tong_so_lit_dau__Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Space style={{ marginBottom: '10px' }} size='middle'>
        <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
        <Button
          type='primary'
          icon={<EyeOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px' }}
          onClick={() => handleGetDateByFilter(filter)}
          loading={loading}
        >
          Xem Báo Cáo
        </Button>
        <Button
          type='primary'
          icon={<DownloadOutlined />}
          style={{ padding: '0px 20px', marginLeft: '10px' }}
          onClick={() => handleExportExcel(data)}
          loading={loading}
          disabled={loading || !data?.length}
        >
          Xuất Báo Cáo
        </Button>
      </Space>

      <Divider>
        <h1>
          <b>DANH SÁCH BÁO CÁO SỐ LÍT DẦU</b>
        </h1>
        <div>
          Từ ngày <span className='blue'>{moment(dateFromValid).format('DD/MM/YYYY')}</span> đến
          ngày <span className='blue'>{moment(dateToValid).format('DD/MM/YYYY')}</span>
        </div>
      </Divider>

      <Table
        columns={columns}
        dataSource={data || []}
        rowKey='carCode'
        bordered
        loading={loading}
        scroll={{ x: '100%', y: 'calc(100vh - 340px)' }}
        // pagination={pagination}
        summary={handleSummary}
      />
    </Wrapper>
  );
};
