import React, { useEffect, useState, useCallback } from 'react';
const ExcelJS = require('exceljs');
import moment from 'moment';
import { debounce } from 'lodash';
import { DownloadOutlined } from '@ant-design/icons';
import { Space, Button } from 'antd';
import ApiServices from '~services';
import { TableData } from './table-data';
import { RangeDateFilter, LoadingView } from '~components';
import { DATE_FORMAT_SEND_API } from '~commons/constants';

import {
  LIST_COLUMNS_TITLE_FIRST_SUMMARY_SHEET,
  LIST_COLUMNS_TITLE_SECOND_SUMMARY_SHEET,
  MERGE_TWO_ROWS_TITLE_SUMMARY_SHEET,
  MERGE_TWO_COLUMNS_TITLE_SUMMARY_SHEET,
  BORDER_CELL_TITLE_SUMMARY_SHEET,
  COLUMNS_BORDER_LIST_SUMMARY_SHEET,
  LIST_COLUMN_SUM_SUMMARY_SHEET,
  LIST_COLUMN_FILL_ORDER_TYPE,
} from './sheet-summary-constant';

const DEFAULT_QUERIES = {
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};

export const ReportByCustomer2 = () => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);

  const [dataTable, setDataTable] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);

  const getData = async (query) => {
    setLoading(true);
    const dataSend = {
      dateFrom: query?.DateFrom && moment(query?.DateFrom).format(DATE_FORMAT_SEND_API),
      dateTo: query?.DateTo && moment(query?.DateTo).format(DATE_FORMAT_SEND_API),
      orderType: '',
    };
    try {
      const res = await ApiServices.getRPDetailContByCustomerUnitrans(dataSend);
      if (res?.data?.data) {
        console.log(`data Get unitrans:::`, res?.data?.data);
        const dataMap = await res?.data?.data.map((item, index) => {
          let sum = 0;
          for (const [key] of Object.entries(item)) {
            if (!isNaN(item[key])) {
              sum += item[key];
            }
          }
          item.sum = sum;
          item.key = index;
          return item;
        });

        setDataTable(dataMap || []);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 1000),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const handleExportExcelFile = async (dataExport = []) => {
    if (loadingExport || !dataExport?.length) return;
    setLoadingExport(true);

    const workbook = new ExcelJS.Workbook();

    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Unitrans', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ showGridLines: false }],
    });

    sheetSummary.properties.defaultRowHeight = 15;
    sheetSummary.properties.defaultColWidth = 10;

    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getColumn(2).width = 30;
    // Title
    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getCell('C2').value = `BÁO CÁO SẢN LƯỢNG CONT CỦA UNITRANS`;
    sheetSummary.mergeCells('B3:D3');
    sheetSummary.getCell('B3').font = {
      name: 'Arial',
      family: 2,
      size: 11,
      bold: true,
    };
    sheetSummary.getCell('B3').value = ``;
    sheetSummary.getCell('E3').value = `Từ Ngày ${moment(filter?.DateFrom).format(
      'DD/MM/YYYY'
    )} -> ${moment(filter?.DateTo).format('DD/MM/YYYY')}`;
    sheetSummary.addRow(['']); // blank Row

    // add Title Table
    sheetSummary.addRow(LIST_COLUMNS_TITLE_FIRST_SUMMARY_SHEET);
    sheetSummary.addRow(LIST_COLUMNS_TITLE_SECOND_SUMMARY_SHEET);

    MERGE_TWO_ROWS_TITLE_SUMMARY_SHEET.forEach((item) => {
      sheetSummary.mergeCells(`${item}5:${item}6`);
      sheetSummary.getCell(`${item}5`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetSummary.getCell(`${item}5`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });
    MERGE_TWO_COLUMNS_TITLE_SUMMARY_SHEET.forEach((item) => {
      sheetSummary.mergeCells(`${item.start}5:${item.end}5`);
      sheetSummary.getCell(`${item.start}5`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetSummary.getCell(`${item.start}5`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });
    BORDER_CELL_TITLE_SUMMARY_SHEET.forEach((item) => {
      sheetSummary.getCell(`${item}6`).border = {
        top: { style: 'thin', color: { argb: '006699' } },
        left: { style: 'thin', color: { argb: '006699' } },
        right: { style: 'thin', color: { argb: '006699' } },
        bottom: { style: 'thin', color: { argb: '006699' } },
      };
      sheetSummary.getCell(`${item}6`).fill = {
        type: 'gradient',
        gradient: 'angle',
        stops: [
          { position: 0, color: { argb: '96B6C5' } },
          { position: 1, color: { argb: '96B6C5' } },
        ],
      };
    });

    sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(6).alignment = { vertical: 'middle', horizontal: 'center' };

    // ================ Summary Body ====================
    dataExport?.map((itemBody, indexRow) => {
      sheetSummary.addRow([
        '',
        itemBody?.customerName || '', // Khach hang
        itemBody?.bill_20 || 0, // bill_20
        itemBody?.bill_40C || 0, // bill_40C
        itemBody?.bill_40T || 0, // bill_40T
        itemBody?.bill_45 || 0, // bill_45
        itemBody?.book_20 || 0, // book_20
        itemBody?.book_40C || 0, // book_40C
        itemBody?.book_40T || 0, // book_40T
        itemBody?.book_45 || 0, // book_45
        itemBody?.transit_20 || 0, // transit_20
        itemBody?.transit_40C || 0, // transit_40C
        itemBody?.transit_40T || 0, // transit_40T
        itemBody?.transit_45 || 0, // transit_45
        itemBody?.sum || 0, // Sum
      ]);

      COLUMNS_BORDER_LIST_SUMMARY_SHEET.forEach((item) => {
        sheetSummary.getCell(`${item}${indexRow + 7}`).border = {
          top: { style: 'thin', color: { argb: '006699' } },
          left: { style: 'thin', color: { argb: '006699' } },
          right: { style: 'thin', color: { argb: '006699' } },
          bottom: { style: 'thin', color: { argb: '006699' } },
        };
      });
      LIST_COLUMN_FILL_ORDER_TYPE.forEach((item) => {
        sheetSummary.getCell(`${item.cell}${indexRow + 7}`).fill = {
          type: 'gradient',
          gradient: 'angle',
          stops: [
            { position: 0, color: { argb: item.color } },
            { position: 1, color: { argb: item.color } },
          ],
        };
      });
    });

    // SUm
    const dataLength = dataExport?.length;
    const startPosition = 7;
    const endPosition = dataLength + 6;
    const showResult = dataLength + 7;
    if (dataLength > 0) {
      sheetSummary.getCell(`B${showResult}`).value = 'Tổng';
      LIST_COLUMN_SUM_SUMMARY_SHEET.forEach((column) => {
        sheetSummary.getCell(`${column + showResult}`).value = {
          formula: `=SUM(${column + startPosition}:${column + endPosition})`,
        };
      });
      COLUMNS_BORDER_LIST_SUMMARY_SHEET.forEach((item) => {
        sheetSummary.getCell(`${item + showResult}`).border = {
          top: { style: 'thin', color: { argb: '006699' } },
          left: { style: 'thin', color: { argb: '006699' } },
          right: { style: 'thin', color: { argb: '006699' } },
          bottom: { style: 'thin', color: { argb: '006699' } },
        };
        sheetSummary.getCell(`${item + showResult}`).fill = {
          type: 'gradient',
          gradient: 'angle',
          stops: [
            { position: 0, color: { argb: '96B6C5' } },
            { position: 1, color: { argb: '96B6C5' } },
          ],
        };
      });
      sheetSummary.getRow(showResult).font = {
        name: 'Arial',
        family: 4,
        size: 10,
        bold: true,
      };
    }

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Bao_Cao_San_Luong_Theo_San_Luong_Cont_Cua_Unitrans_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoadingExport(false);
    }
  };

  return (
    <>
      <h1>BÁO CÁO SẢN LƯỢNG CONT CỦA UNITRANS</h1>
      <div
        style={{
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          padding: '20px 10px',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      >
        <Space>
          <div>
            <b
              style={{
                marginLeft: '10px',
              }}
            >
              Chọn ngày:
            </b>
            <RangeDateFilter query={filter} onChange={setFilter} allowClear={false} />
          </div>
          <div>
            <b></b>
            <br />
            <Button
              type='primary'
              loading={loading}
              icon={<DownloadOutlined />}
              style={{ padding: '0px 20px', marginLeft: '10px' }}
              disabled={!dataTable?.length}
              onClick={() => handleExportExcelFile(dataTable)}
            >
              Export Excel
            </Button>
          </div>
        </Space>
      </div>
      <TableData dataItem={dataTable || []} />
      <br />
      <LoadingView loading={loading} />
    </>
  );
};
