import React, { useEffect, useState } from 'react';
import { Divider, Space, Table, Button } from 'antd';
import moment from 'moment';
import ApiServices from '~services';
import { COLUMNS } from './constants';
import { ReportHeader, RangeDateFilter } from '~components';
import { DownloadOutlined } from '@ant-design/icons';
import { SHORTEN_DATE_FORMAT_CUSTOM } from '~commons/constants';
const ExcelJS = require('exceljs');
import logo from '~assets/images/logo.png';

const toDataURL = (url) => {
  const promise = new Promise((resolve) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
  return promise;
};
const defaultFilter = {
  DateFrom: moment().startOf('month').format('YYYY-MM-DD'),
  DateTo: moment().endOf('month').format('YYYY-MM-DD'),
};

export const ReportTourByMonth = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [loadingExport, setLoadingExport] = useState(false);

  const getData = async (query) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getReportTourByMoth(query || filter);
      const tmpData = data?.data?.map((item, index) => ({
        ...item,
        index: index + 1,
        total: (item?.TotalUp + item.TotalDown) / 2,
      }));
      setData(tmpData);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  const onChangeTimeFilter = (val) => {
    setFilter(val);
    getData(val);
  };

  // Export excel
  const handleExportExcel = async (dataExport, filterDate) => {
    if (loadingExport) return;
    setLoadingExport(true);

    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Báo Cáo Tour Theo Tháng', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ state: 'frozen', ySplit: 5, activeCell: 'A1', showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 17;
    sheetSummary.properties.defaultColWidth = 13;
    sheetSummary.getColumn(1).width = 5;
    // add Image
    const resultTest = await toDataURL(logo);
    const extNameTest = 'png';

    const imageId2Summary = workbook.addImage({
      base64: resultTest.base64Url,
      extension: extNameTest,
    });
    sheetSummary.addImage(imageId2Summary, {
      tl: { col: 1, row: 0 },
      ext: { width: 150, height: 100 },
    });

    sheetSummary.getCell('D1').value = 'Công Ty Cổ Phần Vận Tải U&I';
    sheetSummary.getCell('D2').value = 'Số 9 Ngô Gia Tự, Chánh Nghĩa, TX TDM, Bình Dương';
    sheetSummary.getCell('D3').value = 'MST: 3700970866';

    sheetSummary.getCell('E7').value = 'BẢNG TỔNG HỢP TOUR THEO THÁNG';
    const date = `${moment(filterDate?.DateFrom).format('DD/MM/YYYY')} -> ${moment(
      filterDate?.DateTo
    ).format('DD/MM/YYYY')}`;
    sheetSummary.getCell('E8').value = date;

    // Add table
    const arrDetail = await dataExport?.map((item) => {
      const dataItem = [
        item.index, // STT
        item?.CarCode, // XE
        item?.DriverName, // Tai Xe
        item?.TotalUp, // Lay
        item?.TotalDown, // Ha
        item?.total, // So Tour
        item?.TotalTransfer, // So Tour CK
        '', // Ghi Chu
      ];
      return dataItem;
    });

    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B10',
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        {
          name: 'Số Xe',
          filterButton: false,
        },
        {
          name: 'Tài Xế',
          filterButton: false,
        },
        {
          name: 'Lấy',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Hạ',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Số Tour',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Số Tour CK',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Ghi Chú',
          filterButton: false,
        },
      ],
      rows: arrDetail,
    });
    // ========== STYLES =====================
    sheetSummary.getRow(7).font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
    };
    sheetSummary.getRow(8).font = {
      name: 'Arial',
      family: 2,
      size: 10,
      italic: true,
    };
    sheetSummary.getRow(9).font = {
      name: 'Arial',
      family: 2,
      size: 11,
      bold: true,
    };
    sheetSummary.getRow(7).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(8).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getRow(10).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getColumn(2).width = 5;
    sheetSummary.getColumn(4).width = 25;
    sheetSummary.getColumn(9).width = 30;

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Bang_Tong_Hop_Tour_Theo_Thang_Từ ${moment(filterDate?.DateFrom).format(
          'DD/MM/YYYY'
        )} - ${moment(filterDate?.DateTo).format('DD/MM/YYYY')}_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoadingExport(false);
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: 'rgb(234, 238, 243)',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          borderRadius: '10px',
          fontSize: '12px',
          padding: '20px 10px',
          marginBottom: '15px',
          width: '95%',
        }}
      >
        <Space>
          <RangeDateFilter query={filter} onChange={onChangeTimeFilter} allowClear={false} />
          <Button
            type='primary'
            icon={<DownloadOutlined />}
            style={{ padding: '0px 20px', marginLeft: '10px' }}
            onClick={() => handleExportExcel(data, filter)}
            loading={loadingExport}
          >
            Export Excel
          </Button>
        </Space>
      </div>
      <div
        style={{
          marginBottom: '30px',
        }}
      >
        <ReportHeader />
        <Divider plain={true}>
          <h1>
            <b>BÁO CÁO TOUR THEO THÁNG</b>
          </h1>
        </Divider>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '-15px',
            marginBottom: '5px',
            textAlign: 'center',
          }}
        >
          <div>
            <i>
              {filter?.DateFrom && moment(filter?.DateFrom).format(SHORTEN_DATE_FORMAT_CUSTOM)}{' '}
              {'-> '}
              {filter?.DateTo && moment(filter?.DateTo).format(SHORTEN_DATE_FORMAT_CUSTOM)}
            </i>
          </div>
        </div>
        <Table
          dataSource={data}
          loading={loading}
          rowKey='index'
          bordered
          columns={COLUMNS}
          pagination={false}
          size='small'
          scroll={{ x: '100%', y: '100%' }}
        />
      </div>
    </>
  );
};
