import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CarOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Button, Badge, Tooltip, Modal, message } from 'antd';
import {
  fetchData,
  loadMore,
  updateQuery,
  updateLoading,
  updateIsActiveDetail,
} from '~store/reducers/single-cont/action';
import { TableWhiteBoard, WBTableLoadMore } from '~components';
import { getColumns } from './columns';
import { Wrapper, Title } from '~commons/styles';
import { useWBTableScroll } from '~hooks';
import Utils from '~commons/utils';
import ApiServices from '~services';
import useApiHook from '../wb-hook/api-hook';

export const SingleCont = () => {
  const dispatch = useDispatch();
  const [loadingMore, setLoadingMore] = useState(false);
  const {
    data: singleContData,
    query,
    loading,
    isActiveDetail,
  } = useSelector((state) => state.singleCont);
  const timeOutRef = useRef(null);
  const { tableRef, isScroll } = useWBTableScroll(null);
  const { reloadParkingCont } = useApiHook();

  const loadMoreData = async () => {
    if (loadingMore) return;
    if (singleContData.pageNumber * query.PageSize <= singleContData.totalRecords) {
      let tmpQuery = { ...query, PageNumber: singleContData.pageNumber + 1 };
      setLoadingMore(true);
      await dispatch(loadMore(tmpQuery));
      setLoadingMore(false);
    }
  };
  const initData = async () => {
    dispatch(updateLoading(true));
    await dispatch(fetchData(query));
    dispatch(updateLoading(false));
  };

  useEffect(() => {
    clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => initData(), 1000);
  }, [query]);

  useEffect(() => {
    if (isScroll) {
      loadMoreData();
    }
  }, [isScroll]);

  const setQuery = (columns) => {
    const tmpQuery = { ...query };
    tmpQuery.Columns = { ...tmpQuery.Columns, ...columns };
    dispatch(updateQuery(tmpQuery));
  };
  const returnEmptyCont = (record) => {
    dispatch(updateLoading(true));
    return ApiServices.returnEmptyCont(record.contReferenceId)
      .then((res) => {
        if (res.data) {
          reloadParkingCont();
          message.success(
            `Thao tác thành công! Vui lòng điều Cv ${record.name} ở bảng CONT CHỜ TẠI BÃI!`
          );
        }
      })
      .catch((err) => console.error('returnEmptyCont Error', err))
      .finally(() => dispatch(updateLoading(false)));
  };
  const renderRowKey = (record) => record.jobId?.join(',');
  const renderRowClassName = (record) =>
    `${
      record?.assignId || record?.childJobName?.[0]
        ? 'row-assigned'
        : Utils.renderTypeOrderBackground(record?.type)
    } ${record.type}`;
  const showListFile = (record) => dispatch(showListFile(record));
  const setActive = () => {
    dispatch(updateIsActiveDetail(!isActiveDetail));
  };
  const countAssigned = singleContData?.totalRecords - singleContData?.countNotAssign;
  const total = singleContData?.totalRecords || 0;
  const renderContent = () => {
    return (
      <Modal
        open={isActiveDetail}
        title={
          <>
            <Title>
              Cont Mồ Côi <b className='red'>({total})</b>
              {!!countAssigned && <b>({countAssigned})</b>}
            </Title>
          </>
        }
        onCancel={setActive}
        footer
        width={800}
      >
        <ContentWrapper>
          <Wrapper ref={tableRef}>
            <TableWhiteBoard
              renderRowKey={renderRowKey}
              rowClassName={renderRowClassName}
              columns={getColumns(setQuery, showListFile, returnEmptyCont)}
              data={singleContData?.data}
              loading={loading}
              showSelection={false}
              rowKey='id'
            />
            <WBTableLoadMore loading={loadingMore} total={singleContData?.data?.length} />
          </Wrapper>
        </ContentWrapper>
      </Modal>
    );
  };
  return (
    <>
      <ButtonWrapper>
        <Tooltip title='Cont Mồ Côi'>
          <Badge count={total} showZero>
            <Button type='default' icon={<CarOutlined />} onClick={setActive} />
          </Badge>
        </Tooltip>
      </ButtonWrapper>
      {renderContent()}
    </>
  );
};
const ButtonWrapper = styled.div`
  bottom: 8px;
  left: 64px;
  position: fixed;
  z-index: 999999;
`;
const ContentWrapper = styled.div`
  transition: width 0.5s linear;
`;
