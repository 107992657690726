import React, { useEffect, useState } from 'react';
import ApiServices from '~services';
import { InfoCarInspection } from './inforCarInspection';
import { FormUpdateCarInspection } from './form-update';

const ReplacementCategories = ({ id }) => {
  const [infoCarInspection, setInfoCarInspection] = useState({});
  const [inspectionList, setInspectionList] = useState([]);

  const getCarDetails = async () => {
    try {
      let { data } = await ApiServices.getCarDetails(id);

      if (data) {
        setInfoCarInspection(data);
        setInspectionList(data.replacement);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCarDetails();
  }, [id]);

  return (
    <>
      <div style={{ margin: '0px 10px 10px 10px' }}>
        <InfoCarInspection infoCarInspection={infoCarInspection} />
      </div>
      <div style={{ margin: 10 }}>
        <FormUpdateCarInspection
          idCar={id}
          inspectionList={inspectionList}
          getCarDetails={getCarDetails}
        />
      </div>
    </>
  );
};

export { ReplacementCategories };
