import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import { Input, Table, Space, Button, Badge } from 'antd';
import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { RangeDateFilter } from '~components';
import ApiServices from '~services';
import { Wrapper } from '~commons/styles';
import styled from 'styled-components';
import { getColumns } from './columns';
import { handleExportExcel } from './export-excel';
import { RenderSummary } from './render-summary';
import { OnlyOilTicketDetail } from './only-oil-ticket-detail';
import { MultipleOilTicketDetail } from './multiple-oil-ticket-detail';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};
const DEFAULT_QUOTA = 55;

const ReportOilFuelDistribution2 = () => {
  const [loading, setLoading] = useState(false);
  const [reportOilFuelDistributions, setReportOilFuelDistributions] = useState([]);
  const [dataRender, setDataRender] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [quota, setQuota] = useState(55);
  const [price, setPrice] = useState(0);
  const [loadingExport, setLoadingExport] = useState(false);
  const [viewDetail, setViewDetail] = useState(null);
  const [showFooter, setShowFooter] = useState(false);
  const [keySelected, setKeySelected] = useState([]);
  const [rowDataSelected, setRowDataSelected] = useState([]);
  const [showMultiple, setShowMultiple] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);

  const getCompany = async (companyId) => {
    try {
      const res = await ApiServices.getCompanyDetails(companyId);
      if (res?.data?.data) {
        setCompanyInfo(res?.data?.data);
      }
    } catch (error) {
      console.log(`get Company Details Error:::`, error);
    }
  };

  useEffect(() => {
    const companyId = JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId;
    if (companyId) {
      getCompany(companyId);
    }
  }, []);

  const getData = async (query = DEFAULT_QUERIES, dataQuota = DEFAULT_QUOTA, dataPrice = 0) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getReportOilFuelDistribution2(
      {
        oilQuota: dataQuota,
        oilPrice: dataPrice,
      },
      newQuery
    )
      .then((res) => {
        if (res?.data?.data) {
          setReportOilFuelDistributions(res?.data);
          const mapDataFromObject = Object.keys(res?.data?.data);
          if (mapDataFromObject) {
            const mapData = mapDataFromObject?.map((item) => {
              return {
                ...res?.data?.data?.[item],
              };
            });

            const mapDataEnd = mapData?.map((el, index) => {
              let totalKmSupport = 0;
              let totalOilSupport = 0;

              if (el?.supportJobs?.length > 0) {
                el?.supportJobs?.forEach((item) => {
                  totalKmSupport += item?.distance + item?.lossOfDistance;
                  totalOilSupport += item?.oil + item?.lossOfOil;
                });
              }

              const countQuantity = ((el?.totalKm + totalKmSupport) * el?.oilQuota) / 100;
              let sumPrice = 0;
              if (el?.oilPrice && countQuantity) {
                sumPrice = Math.round(el?.oilPrice * Math.round(countQuantity));
              }
              return {
                ...el,
                id: `${el?.driverId}-${index}`,
                totalKmAll: el?.totalKm + totalKmSupport,
                totalKmSupport,
                totalOilSupport,
                newOilToSupply: countQuantity ? Math.round(countQuantity) : 0,
                newOilTotalMoney: sumPrice ? sumPrice : 0,
              };
            });

            setDataRender(mapDataEnd || []);
          }
        }
      })
      .catch((err) => {
        console.error('get Report Oil Fuel Distribution 2 Error', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;

      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, quota, price) => {
      getData(newFilter, quota, price);
    }, 1000),
    []
  );
  useEffect(() => {
    if (quota >= 55) {
      timeoutGetData(filter, quota, price);
    }
  }, [filter, quota, price]);

  const handleGetDataExport = async (query = DEFAULT_QUERIES, quota, price) => {
    setLoadingExport(true);
    if (!query?.DateFrom || !query?.DateTo || loading) return;
    const dateFrom = moment(query?.DateFrom).format('YYYY-MM-DD');
    const dateTo = moment(query?.DateTo).format('YYYY-MM-DD');

    return ApiServices.getDataExportOilFuelDistribution2(dateFrom, dateTo, {
      oilQuota: quota,
      oilPrice: price,
    })
      .then((res) => {
        if (res?.data?.data) {
          return handleExportExcel(
            res?.data?.data,
            quota,
            price,
            dateFrom,
            dateTo,
            loadingExport,
            setLoadingExport
          );
        }
      })
      .catch((err) => {
        console.error('get Data Export Oil Fuel Distribution 2 Error: ', err);
      })
      .finally(() => {
        setLoadingExport(false);
      });
  };

  const pagination = {
    total: reportOilFuelDistributions?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  const handleRenderSummary = (pageData) => {
    return <RenderSummary pageData={pageData} />;
  };

  const handleChangeQuota = (event) => {
    const value = event.target?.value;
    if (!value) {
      setQuota(DEFAULT_QUOTA);
    }
    if (isNaN(value)) return;
    setQuota(+value);
  };

  const handleOnBlur = (event) => {
    const value = event.target?.value;
    if (value < 55) {
      setQuota(DEFAULT_QUOTA);
    }
  };

  const handleChangePrice = (event) => {
    const value = event.target?.value;
    if (!value) {
      setPrice(0);
    }
    if (isNaN(value)) return;
    setPrice(+value);
  };

  const handleShowDetails = (dataSelected) => {
    if (!dataSelected) return;
    setViewDetail(dataSelected);
  };
  const handleSelectItem = (dataSelected) => {
    if (!dataSelected) return;
    setShowFooter(true);
    setViewDetail(dataSelected);
  };
  const onCloseViewDetail = () => {
    setViewDetail(null);
    setShowFooter(false);
  };
  const handlePrintMultipleTicket = () => {
    setShowMultiple(true);
  };
  const onCloseMultiple = () => {
    setShowMultiple(false);
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: keySelected,
    onChange: (selectedRowKeys, selectedRows) => {
      setKeySelected(selectedRowKeys);
      setRowDataSelected(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record?.status === 0,
      // Column configuration not to be checked
      // name: record.name,
    }),
  };

  return (
    <Wrapper>
      <h3>7019 - BẢNG THEO DÕI CẤP PHÁT NHIÊN LIỆU 2</h3>
      <div>
        <Space>
          <div>
            <b></b>
            <br />
            <RangeDateFilter query={filter} onChange={setFilter} />
          </div>
          <div>
            <b style={{ marginLeft: '5px' }}>Định Mức(lit/100km):</b>
            <br />
            <InputStyled
              min={55}
              defaultValue={55}
              isError={!quota}
              className='red'
              size='small'
              value={quota}
              onChange={handleChangeQuota}
              onBlur={handleOnBlur}
              placeholder='Vui lòng nhập định mức'
            />
          </div>
          <div>
            <b>Giá dầu:</b>
            <br />
            <InputStyled
              defaultValue={0}
              className='red'
              size='small'
              value={price}
              min={0}
              onChange={handleChangePrice}
              placeholder='Vui lòng nhập giá dầu'
            />
          </div>

          <div>
            <b></b>
            <br />
            <Button
              type='primary'
              icon={<DownloadOutlined />}
              loading={loadingExport}
              style={{ padding: '0px 20px', marginLeft: '10px' }}
              onClick={() => handleGetDataExport(filter, quota, price)}
            >
              Export Excel
            </Button>
          </div>
          <div>
            <b></b>
            <br />
            <Badge count={keySelected?.length ?? 0}>
              <Button
                danger
                icon={<PrinterOutlined />}
                style={{ padding: '0px 20px', marginLeft: '10px' }}
                disabled={keySelected?.length < 1}
                onClick={handlePrintMultipleTicket}
              >
                In phiếu dầu
              </Button>
            </Badge>
          </div>
        </Space>
      </div>
      <br />
      <Table
        columns={getColumns(onColumnSearch, handleShowDetails, handleSelectItem)}
        dataSource={dataRender || []}
        rowKey='id'
        size='small'
        bordered
        rowSelection={rowSelection}
        loading={loading}
        scroll={{ x: '100%', y: 'calc(100vh - 250px)' }}
        pagination={pagination}
        summary={handleRenderSummary}
      />
      <OnlyOilTicketDetail
        selectedItem={viewDetail}
        showFooter={showFooter}
        onClose={onCloseViewDetail}
        companyInfo={companyInfo}
      />
      <MultipleOilTicketDetail
        open={showMultiple}
        data={rowDataSelected}
        onClose={onCloseMultiple}
        companyInfo={companyInfo}
      />
    </Wrapper>
  );
};

const InputStyled = styled(Input)`
  border-color: #00bfff;
  border-radius: 5px;
  border: ${({ isError }) => (isError ? '1px solid red' : '1px solid #00BFFF')};
  padding: 4px;
  width: 160px;
  margin-left: 8px;
`;

export { ReportOilFuelDistribution2 };
