export const keyOfForm = [
  'code', // "",
  'name', // "",
  'logo', // "",
  'arrImage', // [],
  'title', // "",
  'arrAddressId', // [],
  'lstAddress', // null,
  'arrPhone', // [],
  'arrFax', // [],
  'arrEmail', // [],
  'taxCode', // "",
  'ownerName', // "",
  'contactName', // "",
  'arrContactPhone', // [],
  'arrVendorId', // [],
  'objVendors', // null,
];
export default (data) => {
  return keyOfForm.map?.((item) => {
    return {
      name: [item],
      value: data?.[item] || null,
      errors: [],
    };
  });
};
