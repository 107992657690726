import React, { useCallback, useEffect, useState } from 'react';
import { Button, InputNumber, Col, DatePicker } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { DATE_TIME_FORMAT } from '~commons/constants';

export const Item = ({ data, onChange, onRemove, onAdd, index, totalRow, placeholder }) => {
  const [total, setTotal] = useState(0);
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (data?.total) {
      setTotal(data?.total);
    }
    if (data?.date) {
      setDate(data?.date);
    }
  }, [data]);

  const handleRemoveItem = (id, index) => {
    if (index === 0 && totalRow === 1) {
      setTotal(0);
      setDate(null);
    }
    onRemove && onRemove(id, index);
  };

  const handleChangeTotal = useCallback(
    (value) => {
      setTotal(value);
      onChange(index, { total: value, date, id: data.id });
    },
    [total, onChange]
  );
  const handleChangeDate = useCallback(
    (value) => {
      setDate(value);
      onChange(index, { total, date: value, id: data.id });
    },
    [date, onChange]
  );
  return (
    <>
      <Col span={4}>
        <InputNumber
          style={{ width: '100%' }}
          placeholder='số lượng Cont'
          min={0}
          value={total}
          onChange={handleChangeTotal}
        />
      </Col>
      <Col span={10}>
        <DatePicker
          disabled={total === 0}
          showTime={{ format: 'HH:mm' }}
          placeholder={placeholder}
          style={{ width: '100%' }}
          format={DATE_TIME_FORMAT}
          value={date}
          onChange={handleChangeDate}
        />
      </Col>
      <Col span={10}>
        <Button
          color='green'
          type='primary'
          danger
          icon={<DeleteOutlined />}
          onClick={() => handleRemoveItem(data.id, index)}
        >
          Xóa
        </Button>
        {index == totalRow - 1 && (
          <Button
            color='green'
            type='primary'
            icon={<PlusOutlined />}
            style={{
              marginLeft: '10px',
            }}
            onClick={() => onAdd && onAdd(data.id)}
          >
            Thêm
          </Button>
        )}
      </Col>
    </>
  );
};
