import storage from '~storage';
import { DATE_FORMAT, DATE_TIME_FORMAT } from './constants';

export default class Validates {
  static async validateMinDate(validator, date, name, min = null, hasTime = false) {
    let promise = new Promise(function (resolve, reject) {
      if (min && date && date.isBefore(min)) {
        reject(
          new Error(
            `Vui lòng nhập ngày sau ${name}: ${min.format(
              hasTime ? DATE_TIME_FORMAT : DATE_FORMAT
            )}`
          )
        );
      }
      resolve('done');
    });
    return promise;
  }
  static validateMaxDate(validator, date, name, max = null, hasTime = false) {
    let promise = new Promise(function (resolve, reject) {
      if (max && date && date.isAfter(max)) {
        reject(
          new Error(
            `Vui lòng nhập ngày trước ${name}: ${max.format(
              hasTime ? DATE_TIME_FORMAT : DATE_FORMAT
            )}`
          )
        );
      }
      resolve('done');
    });
    return promise;
  }
  static validateDeliveryContByType(type, data, min = null, hasTime = false) {
    const orderType = storage.staticVariables ? storage.staticVariables.OrderType : {};
    if (type == orderType.Order_Booking) {
      min = min || data.liftingEmptyContRequestDate;
      return this.validateDatePicker(
        'Ngày yêu cầu lấy rỗng',
        'Ngày yêu cầu hạ Cont Trước',
        min,
        data.putDownContRequestDateBefore,
        hasTime
      );
    }
    if (type == orderType.Order_Bill) {
      min = min || data.atd;
      return this.validateDatePicker('Ngày tàu đến', 'Hạn lưu Cont', min, data.lastFreeDayDEM);
    }
    if (min) {
      return this.validateDatePicker('Ngày Có hàng', null, min);
    }
    return [];
  }
  static validateIsCargoByType(type, data, max, hasTime = false) {
    const orderType = storage.staticVariables ? storage.staticVariables.OrderType : {};

    if (type == orderType.Order_Booking) {
      return this.validateDatePicker(
        'Ngày yêu cầu lấy rỗng',
        'Ngày yêu cầu hạ Cont sau',
        data.liftingEmptyContRequestDate,
        max,
        hasTime
      );
    }
    if (type == orderType.Order_Bill) {
      return this.validateDatePicker('Ngày tàu đến', 'Hạn lưu Cont', data.atd, max);
    }
    if (max) {
      return this.validateDatePicker('Ngày Chuyển kho', null, null, max);
    }
    return [];
  }
  static validateDatePicker(fieldMinName, fieldMaxName, min = null, max = null, hasTime = false) {
    let validators = [];
    if (min) {
      validators.push({
        validator: async (validator, value) =>
          this.validateMinDate(validator, value, fieldMinName, min, hasTime),
      });
    }
    if (max) {
      validators.push({
        validator: async (validator, value) =>
          this.validateMaxDate(validator, value, fieldMaxName, max, hasTime),
      });
    }
    return validators;
  }
}
