import React, { useEffect, useState } from 'react';
import { DatePicker, Divider, Affix, Space } from 'antd';
import ApiServices from '~services';
import { EXPORT_HEADER, MERGE_COLUMN_SHEET } from './constants';
import { VendorSelection } from '../components/vendor-selection';
import { TableData } from './table-data';
import { ReportHeader, ExportCSV } from '~components';

// import Pdf from 'react-to-pdf';
// import ReactExport from 'react-data-export';

const { RangePicker } = DatePicker;

const defaultFilter = { listVendorId: [], dateFrom: null, dateTo: null, orderType: '' };
export const ReportByShippingLine = () => {
  const [data, setData] = useState([]);
  const [rangeDays, setRangeDays] = useState([]);
  const [vendorSelected, setVendorSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    var tmpFilter = filter;
    rangeDays?.length > 0 && (tmpFilter.dateFrom = rangeDays[0].format());
    rangeDays?.length > 1 && (tmpFilter.dateTo = rangeDays[1].format());
    tmpFilter.listVendorId = vendorSelected;
    setFilter(tmpFilter);
    if (firstTime) {
      setFirstTime(false);
      return;
    }
    getData();
  }, [rangeDays, vendorSelected]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getRPDetailContByShippingLine(filter);

      console.log('getRPDetailContByShippingLine', data);
      setData(
        data.map((item, index) => {
          let sum = 0;
          for (const [key] of Object.entries(item)) {
            if (!isNaN(item[key])) {
              sum += item[key];
            }
          }
          item.sum = sum;
          item.key = index;
          return item;
        })
      );
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Affix className='scrollable-container '>
        <div style={{ paddingTop: 5, background: 'rgba(0, 0, 0, 0.05)' }}>
          <Space direction='vertical'>
            <RangePicker
              value={rangeDays}
              onChange={setRangeDays}
              placeholder={['Ngày BĐ', 'Ngày KT']}
            />
          </Space>
          <VendorSelection
            setLoading={setLoading}
            onChange={setVendorSelected}
            selected={vendorSelected}
          />
          <ExportCSV
            fileName='report-order-by-shipping-line'
            data={data}
            header={EXPORT_HEADER}
            mergeColumn={MERGE_COLUMN_SHEET}
          />

          <Divider />
        </div>
      </Affix>

      <div>
        <ReportHeader />
        <Divider plain={true}>
          <h1>
            <b>BÁO CÁO SẢN LƯỢNG THEO HÃNG TÀU</b>
          </h1>
        </Divider>

        <TableData data={data} loading={loading} />
      </div>
    </>
  );
};
