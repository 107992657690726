import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Button, Badge, Drawer } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { debounce } from 'lodash';
import { Wrapper, Title } from '~commons/styles';
import ApiServices from '~services';
import { TableLayoutNew, UploadExcelButton } from '~components';
import { getColumns } from './columns';
import { showListFile } from '~store/reducers/white-board/action';
import Utils from '~commons/utils';
import { FormInput } from './form-input';

const EXCEL_VENDOR_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/Import_SoCont.xlsx';

const DEFAULT_QUERIES = {
  Columns: {
    orderType: 'Booking',
  },
};
const WIDTH = '700px';
const objSortType = {
  ascend: 'ASC',
  descend: 'DESC',
};

export const PendingList = ({ partnerSelectedId, tabKey, filter }) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [dataPendingList, setDataPendingList] = useState([]);
  const [filterNew, setFilterNew] = useState(DEFAULT_QUERIES);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [updated, setUpdated] = useState(null);

  const getData = async (partnerId, queryDate, query = DEFAULT_QUERIES) => {
    const newQuery = {
      ...query,
      DateFrom: queryDate?.DateFrom,
      DateTo: queryDate?.DateTo,
    };
    setLoading(true);
    try {
      const res = await ApiServices.getListContByPartnerId(partnerId, newQuery);
      if (res?.data) {
        setDataPendingList(res?.data);
      }
    } catch (error) {
      console.log('getListContByPartnerId:::', error);
    } finally {
      setLoading(false);
    }
  };

  const onColumnSearch = (event, select = '') => {
    setFilterNew((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };
  const onColumnSearchSelect = (event, select = '') => {
    setFilterNew((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value && value !== 0) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newPartnerId, filterOrigin, newFilter) => {
      getData(newPartnerId, filterOrigin, newFilter);
    }, 500),
    []
  );

  useEffect(() => {
    if (partnerSelectedId && tabKey === '1') {
      timeoutGetData(partnerSelectedId, filter, filterNew);
    }
  }, [partnerSelectedId, filter, tabKey, filterNew]);

  const showOrderFiles = (record) => {
    dispatch(showListFile(record));
  };

  const reload = () => {
    timeoutGetData(partnerSelectedId, filter, filterNew);
  };

  const onUploadExcelVendor = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importBookingContNumber(file)
      .then((res) => {
        if (res?.data?.data) {
          if (res?.data?.errors?.length > 0) {
            window.TmsNoti.error(res?.data?.message, res?.data?.errors?.map(renderError), 0);
          } else {
            window.TmsNoti.success(res?.data?.message);
            reload();
          }
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT importBookingContNumber ERROR', err);
        window.TmsNoti?.error('Thao tác thất bại!', 'Không có dữ liệu');
      })
      .finally(() => setLoading(false));
  };

  const handleExportContTemplate = async (
    newPartnerId,
    filterDate,
    newFilter,
    newSelectedRows = []
  ) => {
    if (!newPartnerId || !filterDate || !newFilter || loadingExport) return;
    let dataSelect = [];
    if (newSelectedRows?.length > 0) {
      const arrayId = newSelectedRows?.map((item) => item.id);
      dataSelect = arrayId;
    }

    const dateFrom = moment(filterDate?.DateFrom).format('YYYY-MM-DD');
    const dateTo = moment(filterDate?.DateTo).format('YYYY-MM-DD');

    const dataSend = {
      DateFrom: filterDate?.DateFrom && moment(filterDate?.DateFrom).format('YYYY-MM-DD'),
      DateTo: filterDate?.DateTo && moment(filterDate?.DateTo).format('YYYY-MM-DD'),
      Columns: newFilter?.Columns,
    };
    setLoadingExport(true);
    try {
      const { data } = await ApiServices.exportExcelHandleContNumberByPartnerId(
        newPartnerId,
        dataSelect,
        dataSend
      );
      if (data) {
        const outputFilename = `Mau_Import_So_Cont_Xuat_Tu_ngay_${dateFrom}_den_ngay_${dateTo}.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoadingExport(false);
    }
  };

  const handleSelectRow = (record, selectedStatus) => {
    let listId = [...selectedIds];
    let listRowData = [...selectedRows];
    if (selectedStatus) {
      // add key Id
      listId.push(record?.id);
      setSelectedIds(listId);
      // add key row data
      listRowData.push(record);
      setSelectedRows(listRowData);
    } else {
      // remove key Id
      const removeSelectedId = listId?.filter((el) => el !== record?.id);
      setSelectedIds(removeSelectedId);

      // remove row data
      const removeListRowSelected = listRowData?.filter((el) => el.id !== record?.id);
      setSelectedRows(removeListRowSelected);
    }
  };
  const handleSelectAll = (selectedNew, _, changeRowsNew) => {
    const listKeyIdOld = [...selectedIds];
    const listKeyIdNew = changeRowsNew?.map((item) => item.id);

    let listRowDataOld = [...selectedRows];
    let listRowDataNew = [...changeRowsNew];

    if (selectedNew) {
      // add all id
      const listIdKeyAdd = listKeyIdOld.concat(listKeyIdNew);
      setSelectedIds(listIdKeyAdd);
      // add all data
      const listRowDataAdd = listRowDataOld.concat(listRowDataNew);
      setSelectedRows(listRowDataAdd);
    } else {
      // remove all id
      const listIdKeyRemove = listKeyIdOld?.filter((el) => !listKeyIdNew.includes(el));
      setSelectedIds(listIdKeyRemove);
      // remove all data
      const listRowDataRemove = listRowDataOld?.filter((el) => !listKeyIdNew.includes(el.id));
      setSelectedRows(listRowDataRemove);
    }
  };

  const pagination = {
    total: dataPendingList?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filterNew.PageSize,
    current: filterNew.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilterNew((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };
  const handleTableChange = (pagination, filters, sorter) => {
    console.log({ pagination, filters, sorter });
    if (sorter?.field && sorter?.order) {
      setFilterNew((prev) => ({
        ...prev,
        SortColumn: sorter?.field,
        SortColumnDirection: objSortType[sorter?.order],
      }));
    }
  };
  return (
    <Wrapper>
      <Title>
        Total: <b className='red'>({dataPendingList?.totalRecords || 0})</b>
        <span style={{ marginLeft: '10px' }}> </span>
        <UploadExcelButton
          loading={loading}
          onChange={onUploadExcelVendor}
          templateUrl={EXCEL_VENDOR_TEMPLATE_URL}
          importLabel='Import Số Cont'
          downloadLabel='Mẫu Import Số Cont'
          isShowDownloadTemplate={false}
        />
        <Badge count={selectedRows?.length}>
          <Button
            type='primary'
            icon={<DownloadOutlined />}
            loading={loadingExport}
            style={{ padding: '0px 20px', marginLeft: '10px' }}
            onClick={() =>
              handleExportContTemplate(partnerSelectedId, filter, filterNew, selectedRows)
            }
          >
            Mẫu Import Số Cont
          </Button>
        </Badge>
      </Title>
      <TableLayoutNew
        rowKey='id'
        headerLength='320px'
        columns={getColumns(onColumnSearch, onColumnSearchSelect, showOrderFiles, setUpdated)}
        data={dataPendingList?.data || []}
        loading={loading}
        // selected={selected}
        selectedParent={selectedIds}
        onHandleSelectRow={handleSelectRow}
        onHandleSelectAll={handleSelectAll}
        // closeDrawer={() => setSelected(null)}
        pagination={pagination}
        // rowClassName={renderRowClassName}
        onChange={handleTableChange}
      />
      <Drawer
        title={`${updated?.subnoNumber}`}
        placement='right'
        onClose={() => setUpdated(null)}
        open={!!updated}
        width={WIDTH}
        footer={null}
      >
        <div style={{ padding: '12px' }}>
          <FormInput
            ref={formRef}
            onClose={() => setUpdated()}
            onsubmit={() => {}}
            dataInput={updated}
            reload={reload}
          />
        </div>
      </Drawer>
    </Wrapper>
  );
};
