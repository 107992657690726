import React, { useEffect, useRef } from 'react';
import useApiHook from '../wb-hook/api-hook';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { WB_POPUP_ASSIGN_HEIGHT, WB_POPUP_ASSIGN_WIDTH } from '~commons/constants';
import { LoadingView } from '~components';
import { Header } from './header';
import { AssignList } from './assign-list';
import ApiServices from '~services';
import { color } from '~theme/index';
import useDataSelectSearch from '~hooks/use-data-select-search';

export const AssignForm = () => {
  const timeOutRef = useRef(null);

  const loading = useSelector((state) => state.whiteBoard.assignLoading);

  const [locations, getLocations] = useDataSelectSearch(ApiServices.getLocations);
  const [romoocs, getRomoocs] = useDataSelectSearch(ApiServices.getRomoocsWhiteboard);
  const [conts, getConts] = useDataSelectSearch(ApiServices.getContsByStatus);
  // const [cars, getCars] = useDataSelectSearch(ApiServices.getCars);
  const [partners, getPartners] = useDataSelectSearch(ApiServices.getPartnerByCompany);
  const [drivers, getDrivers] = useDataSelectSearch(ApiServices.getDrivers);

  const { isShowAssignPopup, setAssignLoading: setLoading } = useApiHook();

  const initData = async () => {
    getLocations();
    getRomoocs();
    getConts();
    // getCars();
    getDrivers({
      PageNumber: 1,
      PageSize: 200,
    });
    getPartners({
      PageNumber: 1,
      PageSize: 200,
    });
  };

  useEffect(() => {
    timeOutRef.current && clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => initData(), 1000);
  }, []);

  /* Data loaded dùng để init data hết da ta 1 lần ở ngoài, khi vào trong sẽ ko load lại thêm => nhẹ vkl */
  const dataLoadedBody = {
    locations,
    getLocations,
    romoocs,
    getRomoocs,
    conts,
    getConts,
  };
  const dataLoadedHeader = {
    // cars, bật reload mỗi khi mở popup(bật hàm này lên = OFF RE_INIT_DATA)
    partners,
    drivers,
  };

  return (
    <Wrapper show={isShowAssignPopup}>
      {!!isShowAssignPopup && (
        <Container>
          <LoadingView loading={loading} />
          <Header loading={loading} onChangeLoading={setLoading} dataLoaded={dataLoadedHeader} />
          <AssignList dataLoaded={dataLoadedBody} />
        </Container>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: ${({ show }) => (show ? ` calc(${WB_POPUP_ASSIGN_HEIGHT} - 4px)` : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: height 0.2s ease-in-out;
  position: relative;
`;
const Container = styled.div`
  border: 1px solid ${color.primary};
  border-radius: 8px;
  height: 100%;
  width: 100%;
  max-width: ${WB_POPUP_ASSIGN_WIDTH};
  position: relative;
  padding: 4px 12px;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 4px;
`;
