import React, { useState } from 'react';
import { Input, Modal, Typography, message } from 'antd';
import styled from 'styled-components';
import ApiServices from '~services';
import { LocationInput, Popup } from '~components';
import useApiHook from '~pages/white-board/wb-hook/api-hook';

export const ReportProblemForm = ({ jobReport, onClose, handleCancelAssignCar }) => {
  const isOpen = !!jobReport;
  const [address, setAddress] = useState({});
  const [isVisiblePopup, setIsVisiblePopup] = useState(false);
  const [fullAddress, setFullAddress] = useState('');
  const [locationInfo, setLocationInfo] = useState({});
  const [locationCode, setLocationCode] = useState('');
  const [locationName, setLocationName] = useState('');
  const [reason, setReason] = useState('');
  const [note, setNote] = useState('');

  const { reloadLiftOff, reloadLiftOn, reloadParkingCont, reloadWaitingOfDelivery } = useApiHook();

  const disabledButton = React.useMemo(() => {
    return !reason || !address || !locationCode || !locationName;
  }, [reason, address, note, locationCode, locationName]);

  const handleClose = () => {
    setLocationCode('');
    setLocationName('');
    setAddress({});
    setIsVisiblePopup(false);
    setFullAddress('');
    setLocationInfo({});
    setNote('');
    setReason('');
    onClose();
  };

  const onSubmit = async () => {
    const dataSend = {
      jobId: jobReport.id,
      code: locationCode,
      name: locationName,
      address: fullAddress,
      district: locationInfo?.district,
      city: locationInfo?.city,
      reason: reason,
      note: note,
      geo: locationInfo?.geo,
    };
    try {
      const res = await ApiServices.reportProblem(dataSend);
      if (res) {
        reloadParkingCont();
        reloadLiftOff();
        reloadLiftOn();
        reloadWaitingOfDelivery();
        handleClose();
        handleCancelAssignCar();
        // message.success('Thêm mới Địa Điểm thành công');
      } else {
        message.error('Thêm mới Địa Điểm thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới Địa Điểm thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };

  const getPropertiesLocation = (places) => {
    const place = places.address;
    const location = {
      address: place.formatted_address,
    };
    setFullAddress(place.formatted_address);
    const address = place.address_components;
    address.forEach((item, index) => {
      switch (item.types[0]) {
        case 'street_number':
          location.houseNumber = address[index].long_name;
          break;
        case 'administrative_area_level_1':
          location.city = address[index].long_name;
          break;
        case 'administrative_area_level_2':
          location.district = address[index].long_name;
          break;
        case 'administrative_area_level_3':
          location.ward = address[index].long_name;
          break;
        case 'route':
          location.street = address[index].long_name;
      }
    });
    location.geo = {
      type: 'Point',
      coordinates: [places.coordinates.lng, places.coordinates.lat],
    };
    return location;
  };

  const onSubmitLocation = () => {
    if (address.coordinates && address.address) {
      const location = getPropertiesLocation(address);
      setLocationInfo(location);
    }
    setIsVisiblePopup(false);
  };

  return (
    <>
      <Modal
        title='Báo cáo sự cố'
        width={500}
        style={{
          top: 20,
          maxHeight: 'calc(100vh - 500px)',
        }}
        open={isOpen}
        onOk={onSubmit}
        onCancel={handleClose}
        okButtonProps={{
          disabled: disabledButton,
        }}
      >
        <Wrapper>
          <Typography>
            Mã địa điểm: <RequiredTitle>*</RequiredTitle>
          </Typography>
          <Input
            placeholder='Mã địa điểm'
            value={locationCode}
            onChange={({ target }) => setLocationCode(target.value)}
          />
          <Typography>
            Tên địa điểm: <RequiredTitle>*</RequiredTitle>
          </Typography>
          <Input
            placeholder='Tên địa điểm'
            value={locationName}
            onChange={({ target }) => setLocationName(target.value)}
          />
          <Typography>
            Địa chỉ: <RequiredTitle>*</RequiredTitle>
          </Typography>
          <Input.TextArea
            rows={3}
            placeholder='Địa chỉ Đầy đủ'
            value={fullAddress}
            onClick={() => setIsVisiblePopup(true)}
          />
          {!reason && <Error>Vui lòng nhập địa điểm</Error>}
          <Typography>Tỉnh/Thành Phố:</Typography>
          <Input placeholder='Tỉnh/Thành Phố' value={locationInfo?.city} disabled />
          <Typography>Quận/Huyện:</Typography>
          <Input placeholder='Quận/Huyện' value={locationInfo?.district} disabled />
          <Typography>Phường/Xã:</Typography>
          <Input placeholder='Phường/Xã' disabled />

          <Typography>
            Lý do: <RequiredTitle>*</RequiredTitle>
          </Typography>
          <Input.TextArea
            placeholder='Lý do'
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          {!reason && <Error>Vui lòng nhập lý do</Error>}

          <Typography>Ghi chú:</Typography>
          <Input.TextArea
            placeholder='Ghi Chú'
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <Popup
            title='chọn địa điểm'
            visible={isVisiblePopup}
            closeModel={() => setIsVisiblePopup(false)}
            onSubmit={() => onSubmitLocation()}
            body={
              <LocationInput
                position={locationInfo?.geo?.coordinates || null}
                onChange={setAddress}
              />
            }
          />
        </Wrapper>
      </Modal>
    </>
  );
};
const Wrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Error = styled.div`
  color: red;
  font-size: 12px;
`;

const RequiredTitle = styled.span`
  color: red;
`;
