import React, { useMemo, useState } from 'react';
import { Table, Button, Modal } from 'antd';
import moment from 'moment';
import { TransitFeeMainForm } from './transit-fee-main-form';
import { BtnConfirm } from '~components';
import ApiServices from '~services';
import { DeleteOutlined } from '@ant-design/icons';

const TransitFeeMain = ({
  contTypeList,
  dataFeeMainTransit,
  setDataFeeMainTransit,
  locationSelected,
  isPartner,
  isNew,
  reloadAfterUpdate,
  valueDetail,
}) => {
  const [loadingAddNew, setLoadingAddNew] = useState(false);
  const [loadingTransit, setLoadingTransit] = useState(false);
  const [showCreateFeeMain, setShowCreateFeeMain] = useState(false);
  const [countShow, setCountShow] = useState(0);

  const isAddNewFee = useMemo(() => {
    return !locationSelected?.length > 0;
  }, [locationSelected]);

  const handleDelete = (keyId, valueId) => {
    let dataList = [...dataFeeMainTransit];
    const ind = dataFeeMainTransit?.findIndex((item) => item?.[keyId] === valueId);
    dataList.splice(ind, 1);
    setDataFeeMainTransit(dataList);
  };

  const deleteFee = async (record) => {
    if (loadingTransit) return;

    if (record?.idGenerate) {
      setLoadingTransit(true);
      await handleDelete('idGenerate', record?.idGenerate);
      setLoadingTransit(false);
    }
    if (record?.id) {
      setLoadingTransit(true);
      return ApiServices.deleteTransitFee(record?.id, isPartner)
        .then((res) => {
          if (res?.data?.data) {
            handleDelete('id', record?.id);
            window.TmsNoti.success('Thao tác thành công');
          }
        })
        .catch((err) => {
          console.log('Delete Transit Fee Failed: ', err);
          window.TmsNoti.error('Thao tác thất bại');
        })
        .finally(() => setLoadingTransit(false));
    }
  };
  const renderRoute = (_, record) => {
    return (
      <span>
        {record?.dataObjectDistance?.locationFromName || record?.locationFromName}{' '}
        <b className='blue'>{'===>'} </b>
        {record?.dataObjectDistance?.locationToName || record?.locationToName}
      </span>
    );
  };
  const renderPriceCont = (value, record) => {
    if (!value) {
      return <span key={record?.id || record?.idGenerate}></span>;
    }
    return (
      <span key={record?.id || record?.idGenerate}>
        {`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    );
  };
  // const renderDistance = (_, record) => {
  //   return <span>{record?.dataObjectDistance?.distanceKm}</span>;
  // };

  const columns = [
    {
      title: 'Tuyến đường',
      dataIndex: 'distanceId',
      width: 180,
      fixed: true,
      render: renderRoute,
    },
    // {
    //   title: 'Cự ly (Km)',
    //   dataIndex: 'dataObjectDistance',
    //   width: 80,
    //   fixed: true,
    //   align: 'center',
    //   render: renderDistance,
    // },
  ];

  contTypeList?.map((item) => {
    columns.push({
      title: item.name,
      dataIndex: item.id,
      width: 80,
      align: 'center',
      render: renderPriceCont,
    });
  });

  columns.push({
    title: '#',
    fixed: 'right',
    align: 'center',
    width: 75,
    render: (record) => (
      <BtnConfirm onConfirm={() => deleteFee(record)} icon={<DeleteOutlined />}>
        Xóa
      </BtnConfirm>
    ),
  });

  const handleSubmitAddNewByApi = async (dataEndNew) => {
    if (loadingAddNew) return;

    const transitData = dataEndNew?.map((item) => {
      let dataTransit = {
        distanceId: item?.distanceId,
        fees: item?.fees,
        contractId: valueDetail?.contractId,
      };
      if (item?.id) {
        dataTransit.id = item.id;
      }
      return dataTransit;
    });

    const dataSend = {
      contractId: valueDetail?.contractId,
      contractNumber: valueDetail?.contractNumber,
      companyId: valueDetail?.companyId,
      customerId: valueDetail?.customerId,
      customerName: valueDetail?.customerName,
      contractDate:
        valueDetail?.contractDate && moment(valueDetail?.contractDate).format('YYYY-MM-DD'),
      expiredDate:
        valueDetail?.expiredDate && moment(valueDetail?.expiredDate).format('YYYY-MM-DD'),
      renewExpiredDate: null,
      locations: valueDetail.locations,
      arrLocations: valueDetail?.arrLocations,
      note: valueDetail?.note,
      partnerId: valueDetail?.partnerId,
      transport: valueDetail?.transport,
      transit: transitData?.length > 0 ? transitData : null,
      romoocs: valueDetail?.romoocs,
    };

    setLoadingAddNew(true);
    return ApiServices.updateContractForVendor(valueDetail?.contractId, dataSend)
      .then((res) => {
        if (res?.data?.data) {
          window.TmsNoti.success('Thêm phí chuyển kho thành công');
          return reloadAfterUpdate();
        } else {
          window.TmsNoti.error('Lỗi', 'Thêm phí chuyển kho thất bại');
        }
      })
      .catch((err) => {
        console.log({ err });
        window.TmsNoti.error('Lỗi', 'Thêm phí chuyển kho thất bại');
      })
      .finally(() => setLoadingAddNew(false));
  };

  const handleSubmitNoApi = (dataEndNew) => {
    setDataFeeMainTransit(dataEndNew);
  };

  const handleSubmit = (data) => {
    let dataEnd = [...dataFeeMainTransit];
    onCloseFormCreate();
    setCountShow(0);
    dataEnd.push(data);

    if (isNew) {
      return handleSubmitNoApi(dataEnd);
    } else {
      return handleSubmitAddNewByApi(dataEnd);
    }
  };

  // const handleSubmit11 = (data) => {
  //   let dataEnd = [...dataFeeMainTransit];
  //   onCloseFormCreate();
  //   setCountShow(0);
  //   dataEnd.push(data);
  //   setDataFeeMainTransit(dataEnd);
  // };

  const handleCreate = () => {
    setShowCreateFeeMain(true);
    setCountShow(countShow + 1);
  };
  const onCloseFormCreate = () => {
    setShowCreateFeeMain(false);
  };

  return (
    <div>
      {isAddNewFee && (
        <div
          style={{
            marginTop: '-10px',
          }}
        >
          <i className='red'>Bạn phải chọn nơi đóng hàng trước, để thêm phí chuyển kho.</i>
        </div>
      )}
      <Button
        type='primary'
        style={{
          margin: '5px 0px',
        }}
        onClick={handleCreate}
        disabled={isAddNewFee}
      >
        Thêm phí chuyển kho
      </Button>
      <Table
        bordered
        size='small'
        loading={false}
        columns={columns}
        dataSource={dataFeeMainTransit}
        rowKey={isNew ? 'idGenerate' : 'id'}
        scroll={{
          x: '100%',
          y: 600,
        }}
        pagination={false}
      />
      <Modal
        title='Thêm phí chuyển kho'
        open={showCreateFeeMain}
        onCancel={onCloseFormCreate}
        footer={null}
        // style={{
        //   top: 20,
        // }}
        // width='80%'
      >
        <TransitFeeMainForm
          countShow={countShow}
          locationSelected={locationSelected}
          contTypeList={contTypeList}
          handleSubmit={handleSubmit}
          dataFeeMainTransit={dataFeeMainTransit}
        />
      </Modal>
    </div>
  );
};

export { TransitFeeMain };
