import React, { useRef, useEffect, useState } from 'react';
import { TableLayout, UploadExcelButton } from '~components';
import moment from 'moment';
const ExcelJS = require('exceljs');
import { CarForm } from './car-form';
import CarFields from './fields-data';
import { Input, Button, message, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import Utils from '~commons/utils';
import { CarInspections } from './car-inspection';
import { CarInsurances } from './car-insurance';
import { CarSigns } from './car-sign';
import { ReplacementCategories } from './replacement-category';
import { getColumns } from './columns';
import ApiServices from '~services';

const EXCEL_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/Car.xlsx';
const Cars = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [cars, setCars] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const typingTimeoutref = useRef(null);
  console.log(fields);

  const [searchByColumn, setSearchByColumn] = useState({
    code: '',
    driverMainName: '',
    driverSubName: '',
    vendorName: '',
    companyName: '',
    labelCar: '',
    typeCar: '',
    engineType: '',
    madeIn: '',
    madeYear: '',
    frameNumber: '',
    machineNumber: '',
  });

  const onChangePagination = (page, size) => {
    let filterTemp = filter;
    filterTemp.PageNumber = page;
    filterTemp.PageSize = size;

    setFilter(filterTemp);
    getCars();
  };
  const [pagination, setPagination] = useState({
    total: 1,
    showSizeChanger: true,
    onChange: onChangePagination,
  });
  const [filter, setFilter] = useState({
    PageNumber: 1,
    PageSize: 10,
    Text: '',
    Columns: '',
  });

  const getCars = async () => {
    let dataFilterSend = { ...filter };
    if (Object.keys(dataFilterSend.Columns).length === 0) {
      delete dataFilterSend['Columns'];
    } else {
      const columnJSONSTRING = JSON.stringify(dataFilterSend.Columns);
      dataFilterSend.Columns = columnJSONSTRING;
    }

    try {
      setLoading(true);
      let { data } = await ApiServices.getCars(dataFilterSend);
      setLoading(false);
      let pag = pagination;
      pag.total = data?.totalRecords || 0;
      pag.pageSize = data?.pageSize;
      pag.current = data?.pageNumber;

      const dataMap = data?.data?.map((item, index) => {
        const indexData = (data?.pageNumber - 1) * data.pageSize + index + 1;
        item.index = indexData;
        return item;
      });

      setCars(dataMap || []);
      setPagination(pag);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getCars();
  }, []);

  const handleSearchColumnFn = (event, name) => {
    const filterTmp = filter;
    const val = event.target.value;
    let _searchByColumn = { ...searchByColumn };
    _searchByColumn[`${name}`] = val;
    setSearchByColumn(_searchByColumn);
    // Clear timeout
    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }
    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 0) {
        filter.PageNumber = 1;

        let searchColumnFn = { ...filter.Columns };
        searchColumnFn[`${name}`] = val;

        filter.Columns = searchColumnFn;
        setFilter({ ...filterTmp, PageNumber: 1 });
        getCars();
      }
      if (val.length === 0) {
        delete filter.Columns[`${name}`];
        setFilter({ ...filterTmp, PageNumber: 1 });
        getCars();
      }
    }, 1000);
  };

  const handleSearchTermChange = (e) => {
    const val = e.target.value;
    setSearchTerm(val);
    const filterTmp = filter;
    filterTmp.Text = val;

    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }

    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 2) {
        filter.PageNumber = 1;
        setFilter(filterTmp);
        getCars();
      }
      if (val.length === 0) {
        setFilter(filterTmp);
        getCars();
      }
    }, 1000);
  };

  const setFieldsForm = async (value) => {
    const carFields = await CarFields(value);
    await setFields(carFields);
    return carFields;
  };
  const reload = async () => {
    setSelected(null);
    await getCars();
  };

  const getUpdatedForm = async (
    value,
    isNew = false,
    id = null,
    rowData,
    idLocationCurrent = null,
    nameLocationCurrent = null
  ) => {
    const carForm = await (
      <CarForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        idLocationCurrent={idLocationCurrent}
        nameLocationCurrent={nameLocationCurrent}
        rowData={rowData}
        reload={reload}
        onChange={setFields}
      />
    );

    if (isNew) {
      return setTabPanels([
        {
          title: 'Thông Tin Xe',
          content: carForm,
        },
      ]);
    } else {
      return setTabPanels([
        {
          title: 'Thông Tin Xe',
          content: carForm,
        },
        {
          title: 'Kiểm Định Xe',
          content: <CarInspections id={id} />,
        },
        {
          title: 'Gia Hạn Bảo Hiểm',
          content: <CarInsurances id={id} />,
        },
        {
          title: 'Gia Hạn Phù Hiệu',
          content: <CarSigns id={id} />,
        },
        {
          title: 'Hạng Mục Thay Thế',
          content: <ReplacementCategories id={id} />,
        },
      ]);
    }
  };

  const onRowSelected = async (value) => {
    const _value = {
      ...value,
      inspectionExpirationDate: Utils.convertToDate(value.inspectionExpirationDate),
      inspectionDate: Utils.convertToDate(value.inspectionDate),
      buyDate: Utils.convertToDate(value.buyDate),
      useBeginDate: Utils.convertToDate(value.useBeginDate),
      cnSignExpDate: Utils.convertToDate(value.cnSignExpDate),
      cnInsurranceExpDate: Utils.convertToDate(value.cnInsurranceExpDate),
    };

    await setIsLoadingTab(true);
    await setTitleTab(`Cập nhật: ${_value?.code}`);
    await setFieldsForm(_value)
      .then((res) => {
        return getUpdatedForm(
          res,
          false,
          _value.id,
          value,
          value.currentLocationCode,
          value.currentLocationName
        );
      })
      .then(() => setSelected(_value));
    setIsLoadingTab(false);
  };

  const deleteCar = async (record) => {
    try {
      const res = await ApiServices.deleteCar(record.id);
      if (res.data) {
        message.success('Xoá khách hàng thành công');
        return reload();
      }
      message.error('Xoá khách hàng thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };

  const createCustomer = async () => {
    await setTitleTab('');
    await setFieldsForm({})
      .then((res) => getUpdatedForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };

  const onUploadExcel = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importCar(file)
      .then((res) => {
        if (res.data) {
          message.success(res?.data?.message);
          reload();
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT CARR ERROR', err);
        message.error('Thao tác thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };

  const exportExcel = async (dataExport) => {
    if (loading) return;
    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Danh sách xe', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ state: 'frozen', ySplit: 3, activeCell: 'A1', showGridLines: false }],
    });
    sheetSummary.properties.defaultRowHeight = 20;
    sheetSummary.properties.defaultColWidth = 15;

    sheetSummary.getCell('F2').value = 'DANH SÁCH XE';

    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };

    const arrDetail = await dataExport?.data?.map((item, index) => {
      const dataItem = [
        index + 1, // STT
        item?.code || '', // Biển Số
        item?.driverMainName || '', // Tài Xế
        item?.vendorName || '', // Đội Xe
        item?.companyName || '', // Công Ty
        item?.labelCar || '', // Nhãn Hiệu
        item?.typeCar || '', // Chủng Loại
        item?.engineType || '', // Động Cơ
        item?.madeIn || '', // Nơi Sản Xuất
        item?.madeYear || '', // Năm Sản Xuất
        item?.buyCost || '', // Giá Mua
        item?.inspectionDate && new Date(moment(item?.inspectionDate).format('YYYY-MM-DD')), // Ngày KĐ
        item?.inspectionExpirationDate &&
          new Date(moment(item?.inspectionExpirationDate).format('YYYY-MM-DD')), // Ngày HH KĐinh
        item?.cnSignExpDate && new Date(moment(item?.cnSignExpDate).format('YYYY-MM-DD')), // HH Phù Hiệu
        item?.cnInsurranceExpDate &&
          new Date(moment(item?.cnInsurranceExpDate).format('YYYY-MM-DD')), // HH Bao Hiem
        item?.buyDate && new Date(moment(item?.buyDate).format('YYYY-MM-DD')), // Ngày Mua
        item?.useBeginDate && new Date(moment(item?.useBeginDate).format('YYYY-MM-DD')), // Ngày SD
        item?.depreciation || '', // Số Tháng Khấu Hao
        item?.warrantyExpenses || '', // Phí Bảo Hành
        item?.milestoneMaintenanceKm || '', // Mốc Bảo Trì KM
        item?.oilQuota || 0, // Định Mức Dầu
        item?.oilOfCar ? Number.parseFloat(item?.oilOfCar).toFixed(2) : 0, // Dầu Trong Xe
        item?.frameNumber || '', // Số Khung
        item?.machineNumber || '', // Số Máy
        item?.inspectionNumber || '', // Số Kiểm Định
        item?.inspectionCost || '', // C Phí Kiểm Định
        item?.cnSignNumber || '', // Số CN Phù Hiệu
        item?.cnInsurranceNumber || '', // Số CN BHiem
        item?.currentLocationName || '', // DD Hien Tai
        item?.note || '', // Ghi Chu
      ];
      return dataItem;
    });

    // Add Table
    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B5',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'STT', totalsRowLabel: '', filterButton: false },
        { name: 'Biển Số', filterButton: false },
        {
          name: 'Tài Xế',
          filterButton: false,
        },
        {
          name: 'Đội Xe',
          filterButton: false,
        },
        {
          name: 'Công Ty',
          filterButton: false,
        },
        {
          name: 'Nhãn Hiệu',
          filterButton: false,
        },
        {
          name: 'Chủng Loại',
          filterButton: false,
        },
        {
          name: 'Động Cơ',
          filterButton: false,
        },
        {
          name: 'Nơi Sản Xuất',
          filterButton: false,
        },
        {
          name: 'Năm Sản Xuất',
          filterButton: false,
        },
        {
          name: 'Giá Mua',
          filterButton: false,
        },
        {
          name: 'Ngày Kiểm Định',
          filterButton: false,
        },
        {
          name: 'Hết Hạn Kiểm Định',
          filterButton: false,
        },
        {
          name: 'Hết Hạn Phù Hiệu',
          filterButton: false,
        },
        {
          name: 'Hết Hạn Bảo Hiểm',
          filterButton: false,
        },
        {
          name: 'Ngày Mua',
          filterButton: false,
        },
        {
          name: 'Ngày Sử Dụng',
          filterButton: false,
        },
        {
          name: 'Số Tháng Khấu Hao',
          filterButton: false,
        },
        {
          name: 'Phí Bảo Hành',
          filterButton: false,
        },
        {
          name: 'Mốc Bảo Trì Km',
          filterButton: false,
        },
        {
          name: 'Định Mức Dầu',
          filterButton: false,
        },
        {
          name: 'Dầu Trong Xe',
          filterButton: false,
        },
        {
          name: 'Số Khung',
          filterButton: false,
        },
        {
          name: 'Số Máy',
          filterButton: false,
        },
        {
          name: 'Số Kiểm Định',
          filterButton: false,
        },
        {
          name: 'Chi Phí Kiểm Định',
          filterButton: false,
        },
        {
          name: 'Số Chứng Nhận Phù Hiệu',
          filterButton: false,
        },
        {
          name: 'Số Chứng Nhận Bảo Hiểm',
          filterButton: false,
        },
        {
          name: 'Địa Điểm Hiện Tại',
          filterButton: false,
        },
        {
          name: 'Ghi Chú',
          filterButton: false,
        },
      ],
      rows: arrDetail,
    });
    // Styles
    sheetSummary.getColumn(1).width = 2;
    sheetSummary.getColumn(2).width = 5;
    sheetSummary.getColumn(3).width = 13;

    sheetSummary.getColumn(4).width = 25;
    sheetSummary.getColumn(5).width = 25;
    sheetSummary.getColumn(6).width = 30;
    sheetSummary.getColumn(14).width = 18;
    sheetSummary.getColumn(15).width = 18;
    sheetSummary.getColumn(16).width = 18;
    sheetSummary.getColumn(19).width = 20;
    sheetSummary.getColumn(24).width = 25;
    sheetSummary.getColumn(25).width = 25;

    sheetSummary.getColumn(21).width = 20;
    sheetSummary.getColumn(27).width = 20;
    sheetSummary.getColumn(28).width = 25;
    sheetSummary.getColumn(29).width = 25;
    sheetSummary.getColumn(30).width = 30;
    sheetSummary.getColumn(31).width = 50;

    sheetSummary.getColumn(20).numFmt = '#,##0';
    sheetSummary.getColumn(20).width = 20;
    sheetSummary.getColumn(21).numFmt = '#,##0';
    sheetSummary.getColumn(21).width = 20;
    sheetSummary.getColumn(27).numFmt = '#,##0';
    sheetSummary.getColumn(27).width = 20;
    sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Danh_Sach_Xe_Xuat_Excel_Luc_${moment().format('DD-MM-YY HH:mm')}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const handleExportExcel = async () => {
    setLoading(true);
    return ApiServices.getDataToExportExcelForCar()
      .then((res) => {
        if (res?.data) {
          return exportExcel(res?.data);
        }
      })
      .catch((err) => {
        console.error('get Cars To Export Excel Error: ', err);
      })
      .finally(() => setLoading(false));
  };

  const headerContent = (
    <Space>
      <Button type='primary' onClick={createCustomer}>
        Thêm Mới
      </Button>
      <Input.Search
        style={{ width: '200px' }}
        className='mg-l-15'
        placeholder='Search'
        value={searchTerm}
        onChange={handleSearchTermChange}
      />
      <UploadExcelButton
        loading={loading}
        onChange={onUploadExcel}
        templateUrl={EXCEL_TEMPLATE_URL}
      />
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        style={{ padding: '0px 20px', marginLeft: '10px' }}
        onClick={handleExportExcel}
      >
        Export Excel
      </Button>
    </Space>
  );

  return (
    <TableLayout
      columns={getColumns(onRowSelected, searchByColumn, handleSearchColumnFn, deleteCar)}
      rowKey='id'
      data={cars}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      pagination={pagination}
      width={900}
      isDisplayCheckbox={false}
      headerLength='190px'
    />
  );
};

export { Cars };
