export const KEYS = [
  'code',
  'password',
  'fullName',
  'companyId',
  'companyName',
  'email',
  // 'arrMemberGroup',
  'deviceId',
  // 'vendorId',
  'vendorName',
  'customerId',
  'customerName',
  'isDriver',
  'driverId',
  'status',
  'isActive',
  'driverMainCode',
];
export const KEYS_ARRAY = ['arrMemberGroup', 'vendorId'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });

  KEYS_ARRAY.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] || [],
    });
  });
  return result;
};

// export default (data) => {
//   return KEYS.map((item) => {
//     return {
//       name: [item],
//       value: data[item] || null,
//       errors: [],
//     };
//   });
// };
