import { Result, Button } from 'antd';
import { NavLink } from 'react-router-dom';

export const NotFound = () => {
  return (
    <Result
      status='404'
      title='404'
      subTitle='Xin lỗi, trang của bạn KHÔNG TỒN TẠI, vui lòng thử lại!'
      extra={
        <NavLink to='/'>
          <Button type='primary'>Về trang chủ</Button>
        </NavLink>
      }
    />
  );
};
