import React, { useEffect, useState, useRef } from 'react';
import { Button, Popconfirm, message, Input } from 'antd';

import { TableLayout } from '~components';
import { CargoTypeForm } from './cargo-type-form';
import CargoTypeFields from './fields-data';

import ApiServices from '~services';

const CargoTypes = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [CargoTypes, setCargoTypes] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);
  //-------------------------------------------------------------------
  const typingTimeoutref = useRef(null);
  //-------------------------------------------------------------------
  const [searchByColumn, setSearchByColumn] = useState({
    name: '',
    companyName: '',
  });

  const getCargoTypes = async () => {
    let dataFilterSend = { ...filter };
    if (Object.keys(dataFilterSend.Columns).length === 0) {
      delete dataFilterSend['Columns'];
    } else {
      const columnJSONSTRING = JSON.stringify(dataFilterSend.Columns);
      dataFilterSend.Columns = columnJSONSTRING;
    }

    try {
      setLoading(true);
      let { data } = await ApiServices.getCargoTypes(dataFilterSend);
      setLoading(false);
      let pag = pagination;
      pag.total = data?.totalRecords || 0;
      pag.pageSize = data?.pageSize;
      pag.current = data?.pageNumber;

      data = data?.data || [];

      setCargoTypes(data);
      setPagination(pag);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCargoTypes();
  }, []);

  //----------- Start ----- Pagination Search ---------
  //----------- Pagination --------------------------
  const onChangePagination = (page, size) => {
    let filterTemp = filter;
    filterTemp.PageNumber = page;
    filterTemp.PageSize = size;
    setFilter(filterTemp);
    getCargoTypes();
  };
  const [pagination, setPagination] = useState({
    total: 1,
    showSizeChanger: true,
    onChange: onChangePagination,
  });
  const [filter, setFilter] = useState({
    PageNumber: 0,
    PageSize: 10,
    Text: '',
    Columns: '',
    // QueryField: '',
    // SortColumn: '',
    // SortColumnDirection: ''
  });

  // ------------ Search Column ----------------------
  const handleSearchColumnFn = (event, name) => {
    const filterTmp = filter;
    const val = event.target.value;
    let _searchByColumn = { ...searchByColumn };
    _searchByColumn[`${name}`] = val;
    setSearchByColumn(_searchByColumn);
    // Clear timeout
    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }
    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 0) {
        filter.PageNumber = 1;
        let searchColumnFn = { ...filter.Columns };
        searchColumnFn[`${name}`] = val;
        filter.Columns = searchColumnFn;

        setFilter({ ...filterTmp, PageNumber: 1 });
        getCargoTypes();
      }
      if (val.length === 0) {
        delete filter.Columns[`${name}`];
        setFilter({ ...filterTmp, PageNumber: 1 });
        getCargoTypes();
      }
    }, 1000);
  };
  // ------------- End Search --------------------

  const setFieldsForm = async (value) => {
    const tireFields = await CargoTypeFields(value);
    await setFields(tireFields);
    return tireFields;
  };
  const reload = async () => {
    setSelected(null);
    await getCargoTypes();
  };

  const getUpdatedCargoTypeForm = async (value, isNew = false, id = null) => {
    const cargoTypeForm = await (
      <CargoTypeForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        reload={reload}
        onChange={setFields}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: cargoTypeForm,
      },
    ]);
  };

  const onRowSelected = async (value) => {
    await setIsLoadingTab(true);
    await setTitleTab('');
    await setFieldsForm(value)
      .then((res) => {
        return getUpdatedCargoTypeForm(res, false, value.id);
      })
      .then(() => setSelected(value));
    setIsLoadingTab(false);
  };
  const deleteCargoType = async (record) => {
    try {
      const res = await ApiServices.deleteCargoType(record.id);
      if (res.data) {
        message.success('Xoá Loại Hàng Hóa thành công');
        return reload();
      }
      message.error('Xoá Loại Hàng Hóa thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Tên Loại Hàng Hóa</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.name}
            onChange={(event) => handleSearchColumnFn(event, 'name')}
          />
        </span>
      ),
      dataIndex: 'name',
      align: 'center',
      width: 150,
      render: (value, record) => (
        <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>
      ),
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Công Ty</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn.companyName}
            onChange={(event) => handleSearchColumnFn(event, 'companyName')}
          />
        </span>
      ),
      width: 150,
      dataIndex: 'companyName',
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 90,
      align: 'center',
      render: (record) => (
        <Popconfirm
          onConfirm={() => deleteCargoType(record)}
          title='Xoá Loại Hàng Hóa này？'
          okText='Yes'
          cancelText='No'
        >
          <a style={{ color: 'red' }} href='#'>
            Xoá
          </a>
        </Popconfirm>
      ),
    },
  ];

  const createTire = async () => {
    await setTitleTab('');
    await setFieldsForm({})
      .then((res) => getUpdatedCargoTypeForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };
  const headerContent = (
    <>
      <Button type='primary' onClick={createTire}>
        Thêm Mới
      </Button>
    </>
  );
  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={CargoTypes}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      pagination={pagination}
      isDisplayCheckbox={false}
    />
  );
};

export { CargoTypes };
