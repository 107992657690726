import { DatePicker } from 'antd';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '~commons/constants';

// window.moment = moment;
const DateTimePicker = ({
  placeholder = '',
  onChange = null,
  showTime = null,
  max = null,
  min = null,
  style = {},
}) => {
  const disabledDate = (current) => {
    if (!current) {
      return false;
    }
    if (max) {
      return current >= max.endOf('day');
    }
    if (min) {
      return min >= current.endOf('day');
    }
  };

  return (
    <DatePicker
      onChange={onChange}
      // defaultValue={min || null}
      showTime={showTime || false}
      placeholder={placeholder}
      disabledDate={disabledDate}
      style={{ width: '100%', ...style }}
      format={showTime ? DATE_TIME_FORMAT : DATE_FORMAT}
    />
  );
};
export { DateTimePicker };
