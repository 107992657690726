import React, { useState, useCallback, useEffect } from 'react';
import { Input } from 'antd';
import { debounce } from 'lodash';
import styled from 'styled-components';
// import Utils from '~commons/utils';

export const Item = ({ jobName, dataItem, setDataCreate, setLoadingBtn }) => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    if (dataItem?.fee === 0) {
      setValue(0);
    } else {
      setValue(dataItem?.fee || 0);
    }
  }, [dataItem]);

  const setData = useCallback(
    debounce((newValue) => {
      setDataCreate((prev) => {
        const dataChange = [...prev[jobName]];
        const ind = dataChange?.findIndex((el) => el.id === dataItem.id);
        dataChange[ind].fee = +newValue;

        return { ...prev, [jobName]: [...dataChange] };
      });
      setLoadingBtn(false);
    }, 200),
    []
  );

  const onChangeFee = useCallback(
    (value) => {
      if (Number.isNaN(+value)) {
        return;
      }
      setLoadingBtn(true);
      setValue(+value);
      setData(value);
    },
    [setDataCreate]
  );

  return (
    <InputStyled
      key={dataItem.id}
      defaultValue={0}
      min={0}
      className='red'
      size='small'
      value={value}
      onChange={({ target }) => onChangeFee(target.value)}
      placeholder='Chi phí'
    />
  );
};

const InputStyled = styled(Input)`
  border-color: #00bfff;
  border-radius: 5px;
  border: ${({ isError }) => (isError ? '1px solid red' : '1px solid #00BFFF')};
  padding: 4px;
`;
