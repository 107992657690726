import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import { Input, Button, Popconfirm, Space, Badge, Modal, DatePicker, Select } from 'antd';
import { TableLayout, RangeDateFilter } from '~components';
import { ContractForm } from './contract-form';
import DriverFields from './fields-data';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { TableTitle } from '~commons/styles';
import { CONTRACT_STATUS, CONTRACT_STATUS_VAL, CONTRACT_STATUS_LIST } from '~commons/constants';
import { EditContractHistory } from './edit-contract-history';
import { DATE_FORMAT } from '~commons/constants';
import styled from 'styled-components';

const DEFAULT_QUERIES = {
  PageSize: 20,
  PageNumber: 1,
  DateFrom: moment().subtract(2, 'years').format(),
  DateTo: moment().endOf('date').format(),
};
const statusTag = (text = '', bgColor = '') => {
  return (
    <Badge>
      <div
        style={{
          backgroundColor: bgColor,
          width: '100px',
          padding: '5px 10px',
          textAlign: 'center',
          borderRadius: '4px',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        {text}
      </div>
    </Badge>
  );
};

const ContractService = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [contractServiceList, setContractServiceList] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [showHistoryContract, setShowHistoryContract] = useState(false);
  const [dataSelectToHistoryContract, setDataSelectToHistoryContract] = useState(null);
  const [countAddNew, setCountAddNew] = useState(0);

  const [contractDate, setContractDate] = useState(null);
  const [expireContractDate, setExpireContractDate] = useState(null);
  const [statusVal, setStatusVal] = useState('');

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getCurrentContracts(newQuery)
      .then((res) => {
        if (res?.data) {
          setContractServiceList(res?.data);
        }
      })
      .catch((err) => {
        console.error('Get Vats Paging Error', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };
  const onColumnSearchForStatus = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value && value !== 0) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const onChangeSelect = (value) => {
    setStatusVal(value);
    onColumnSearchForStatus(value, 'status');
  };

  const handleChangeContractDate = (date) => {
    setContractDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'contractDate');
  };
  const handleChangeExpireContractDate = (date) => {
    setExpireContractDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'expiredDate');
  };

  const setFieldsForm = async (value) => {
    const driverFields = await DriverFields(value);
    await setFields(driverFields);
    return driverFields;
  };
  const reload = async () => {
    setSelected(null);
    await getData(filter);
  };

  const getUpdatedOilPriceForm = async (
    value,
    isNew = false,
    dataSelect,
    id = null,
    valueDetail
  ) => {
    const driverForm = await (
      <ContractForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        contractIdUpdate={id}
        dataSelect={dataSelect}
        valueDetail={valueDetail}
        reload={reload}
        onChange={setFields}
        countAddNew={countAddNew}
        setCountAddNew={setCountAddNew}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: driverForm,
      },
    ]);
  };

  const handleSelected = async (value, valueDetail) => {
    const _value = {
      ...value,
      contractDate: Utils.convertToDate(value.contractDate),
      expiredDate: Utils.convertToDate(value.expiredDate),
      renewExpiredDate: Utils.convertToDate(value.renewExpiredDate),
      arrLocations: valueDetail?.locations?.map((item) => item.id),
    };

    await setIsLoadingTab(true);
    await setTitleTab('Cập nhật');
    await setFieldsForm(_value)
      .then((res) => {
        return getUpdatedOilPriceForm(res, false, value, _value.contractId, valueDetail);
      })
      .then(() => setSelected(_value));
    setIsLoadingTab(false);
  };

  const onRowSelected = async (value) => {
    setLoading(true);
    return ApiServices.getContractDetails(value?.contractId)
      .then((res) => {
        if (res?.data?.data) {
          return handleSelected(value, res?.data?.data);
        }
      })
      .catch((err) => {
        console.log('Get details contract failed: ', err);
      })
      .finally(() => setLoading(false));
  };

  const handleAcceptContract = async (record) => {
    setLoading(true);
    return ApiServices.acceptContractService(record?.contractId)
      .then((res) => {
        if (res?.data?.data) {
          window.TmsNoti.success('Xác nhận Hợp đồng thành công');
          return reload();
        } else {
          window.TmsNoti.error('Lỗi', 'Xác nhận Hợp đồng thất baị');
        }
      })
      .catch((err) => {
        console.log('Accept contract failed: ', err);
        window.TmsNoti.error('Lỗi', 'Xác nhận Hợp đồng thất baị');
      })
      .finally(() => setLoading(false));
  };
  const handleReOpenContract = async (record) => {
    setLoading(true);
    return ApiServices.reOpenContractService(record?.contractId)
      .then((res) => {
        if (res?.data?.data) {
          window.TmsNoti.success('Mở lại Hợp đồng thành công');

          return reload();
        } else {
          window.TmsNoti.error('Lỗi', 'Mở lại Hợp đồng thất baị');
        }
      })
      .catch((err) => {
        console.log('Accept contract failed: ', err);
        window.TmsNoti.error('Lỗi', 'Mở lại Hợp đồng thất baị');
      })
      .finally(() => setLoading(false));
  };
  const deleteContract = async (record) => {
    setLoading(true);
    return ApiServices.deleteContract(record?.contractId)
      .then((res) => {
        if (res?.data?.data) {
          window.TmsNoti.success('Xoá Hợp đồng thành công');
          return reload();
        } else {
          window.TmsNoti.error('Lỗi', 'Xoá Hợp đồng thất baị');
        }
      })
      .catch((err) => {
        console.log('Delete contract failed: ', err);
        window.TmsNoti.error('Lỗi', 'Xoá Hợp đồng thất baị');
      })
      .finally(() => setLoading(false));
  };

  const handleShowHistory = (record) => {
    setDataSelectToHistoryContract(record);
    setShowHistoryContract(true);
  };
  const onCloseHistoryContract = () => {
    setDataSelectToHistoryContract(null);
    setShowHistoryContract(false);
  };
  const handleActionStatusContract = (record) => {
    if (record?.status === CONTRACT_STATUS.INIT || record?.status === CONTRACT_STATUS.REOPEN) {
      return (
        <Popconfirm
          onConfirm={() => handleAcceptContract(record)}
          title='Xác nhận Hợp đồng này?'
          okText='Có'
          cancelText='Không'
        >
          <a href='#' style={{ color: 'green' }}>
            Xác nhận
          </a>
        </Popconfirm>
      );
    }
    if (record?.status === CONTRACT_STATUS.ACCEPT) {
      return (
        <Popconfirm
          onConfirm={() => handleReOpenContract(record)}
          title='Mở lại Hợp đồng này?'
          okText='Có'
          cancelText='Không'
        >
          <a href='#' style={{ color: 'orange' }}>
            Mở lại
          </a>
        </Popconfirm>
      );
    }
  };
  const handleActionContract = (record) => {
    if (record?.status === CONTRACT_STATUS.INIT) {
      return (
        <Popconfirm
          onConfirm={() => deleteContract(record)}
          title='Xoá Hợp đồng này?'
          okText='Có'
          cancelText='Không'
        >
          <a href='#' style={{ color: 'red' }}>
            Xoá
          </a>
        </Popconfirm>
      );
    }
    if (record?.status !== CONTRACT_STATUS.INIT) {
      return (
        <a href='#' onClick={() => handleShowHistory(record)}>
          Xem LS chỉnh sửa
        </a>
      );
    }
  };

  const renderStatus = (value) => {
    switch (value) {
      case CONTRACT_STATUS.ACCEPT:
        return statusTag(CONTRACT_STATUS_VAL[CONTRACT_STATUS.ACCEPT], 'green');
      case CONTRACT_STATUS.REOPEN:
        return statusTag(CONTRACT_STATUS_VAL[CONTRACT_STATUS.REOPEN], 'orange');
      case CONTRACT_STATUS.CANCEL:
        return statusTag(CONTRACT_STATUS_VAL[CONTRACT_STATUS.CANCEL], 'red');
      default:
        return statusTag(CONTRACT_STATUS_VAL[CONTRACT_STATUS.INIT], '#867070');
    }
  };
  const columns = [
    {
      title: (
        <TableTitle>
          Số hợp đồng
          <Input name='contractNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'contractNumber',
      align: 'center',
      width: 200,
      render: (value, record) => (
        <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>
      ),
    },
    {
      title: (
        <TableTitle>
          Trạng thái
          <SelectStyled
            placeholder='Trạng thái'
            value={statusVal}
            onChange={onChangeSelect}
            size='small'
            width='100%'
          >
            <Select.Option value=''>Tất cả</Select.Option>
            {CONTRACT_STATUS_LIST?.map((item, index) => {
              const { value, title } = item;
              return (
                <Select.Option key={index} value={value}>
                  {title}
                </Select.Option>
              );
            })}
          </SelectStyled>
        </TableTitle>
      ),
      dataIndex: 'status',
      align: 'center',
      width: 120,
      render: renderStatus,
    },
    {
      title: (
        <TableTitle>
          Ngày hợp đồng
          <DatePicker
            value={contractDate}
            onChange={handleChangeContractDate}
            placeholder='Ngày hợp đồng'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'contractDate',
      align: 'center',
      width: 150,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Ngày hết hạn
          <DatePicker
            value={expireContractDate}
            onChange={handleChangeExpireContractDate}
            placeholder='Ngày hết hạn'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'expiredDate',
      align: 'center',
      width: 150,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: (
        <TableTitle>
          Khách hàng
          <Input name='customerName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'customerName',
      align: 'center',
      width: 180,
    },
    {
      title: (
        <TableTitle>
          Ghi chú
          <Input name='note' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'note',
      align: 'center',
      width: 200,
    },
    {
      title: '#',
      fixed: 'right',
      align: 'center',
      width: 90,
      render: handleActionStatusContract,
    },
    {
      title: '#',
      fixed: 'right',
      align: 'center',
      width: 120,
      render: handleActionContract,
    },
  ];

  const createDriver = async () => {
    await setTitleTab('Thêm mới');
    await setCountAddNew(countAddNew + 1);
    await setFieldsForm({})
      .then((res) => getUpdatedOilPriceForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };

  const headerContent = (
    <Space>
      <RangeDateFilter query={filter} onChange={setFilter} />
      <Button type='primary' onClick={createDriver}>
        Thêm Mới
      </Button>
    </Space>
  );

  const pagination = {
    total: contractServiceList?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <>
      <TableLayout
        rowKey='contractId'
        columns={columns}
        data={contractServiceList?.data || []}
        loading={loading}
        selected={selected}
        closeDrawer={() => setSelected(null)}
        isLoadingTab={isLoadingTab}
        headerContent={headerContent}
        titleTab={titleTab}
        tabPanels={tabPanels}
        pagination={pagination}
        width={900}
        isDisplayCheckbox={false}
        headerLength='190px'
      />
      <Modal
        title='Lịch sử chỉnh sửa Hợp đồng'
        open={showHistoryContract}
        onCancel={onCloseHistoryContract}
        footer={null}
        style={{
          top: 20,
        }}
        width='80%'
      >
        <EditContractHistory dataRow={dataSelectToHistoryContract} />
      </Modal>
    </>
  );
};

const SelectStyled = styled(Select)`
  width: ${({ width }) => width};
`;

export { ContractService };
