import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { Form, Input, InputNumber, Checkbox } from 'antd';
import ApiServices from '~services';
import { Selection, DateTimePicker } from '~components';
import { DATE_TIME_FORMAT } from '~commons/constants';
import storage from '~storage';
import Utils from '~commons/utils';

const FormData = forwardRef(({ fields, onChange, onSubmit, infoData, type }, ref) => {
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const info = {};
  infoData.forEach((item) => {
    info[item.name[0]] = item.value;
  });
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    submitForm() {
      form.submit();
    },
  }));
  const orderType = storage.staticVariables ? storage.staticVariables.OrderType : {};

  const labelDate =
    type == orderType.Order_Bill
      ? 'Ngày Yêu Cầu Lấy Cont'
      : type == orderType.Order_Booking
      ? 'Ngày Yêu Cầu Giao Cont'
      : 'Ngày Chuyển Kho';

  const [contTypes, setContTypes] = React.useState([]);
  const getContTypes = async (Query = null) => {
    try {
      const { data } = await ApiServices.getContTypes({ Query });
      setContTypes(data.data);
    } catch (err) {
      console.error('ERR - Get ContTypes', err);
    }
  };

  useEffect(() => {
    getContTypes();
  }, []);
  return (
    <Form
      {...layout}
      form={form}
      name='global_state'
      className='form-input-data'
      onFinish={onSubmit}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        name='typeCont'
        label='Loại Cont'
        rules={[
          {
            required: true,
            message: 'bạn phải nhập Loại cont',
          },
        ]}
      >
        <Selection data={contTypes} placeholder='Loại Cont' onSearch={getContTypes} />
      </Form.Item>

      {type === orderType.Order_Bill && (
        <>
          <Form.Item
            name='contNumber'
            label='Số Cont'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Số Cont',
              },
              {
                type: 'string',
                min: 11,
                message: 'Số Cont phải có 11 kí tự',
              },
              {
                type: 'string',
                max: 11,
                message: 'Số Cont có tối đa 11 kí tự',
              },
            ]}
          >
            <Input placeholder='Số Cont' />
          </Form.Item>

          <Form.Item
            name='sealNumber'
            label='Số Seal'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập Số Seal',
              },
            ]}
          >
            <Input placeholder='Số Seal' />
          </Form.Item>
        </>
      )}

      {type === orderType.Order_Booking && (
        <>
          <Form.Item
            name='contNumber'
            label='Số Cont'
            rules={[
              {
                type: 'string',
                min: 11,
                message: 'Số Cont phải có 11 kí tự',
              },
              {
                type: 'string',
                max: 11,
                message: 'Số Cont có tối đa 11 kí tự',
              },
            ]}
          >
            <Input placeholder='Số Cont' />
          </Form.Item>

          <Form.Item name='sealNumber' label='Số Seal'>
            <Input placeholder='Số Seal' />
          </Form.Item>
        </>
      )}

      <Form.Item
        name='deliveryContRequestDate'
        label={labelDate}
        rules={[
          {
            required: true,
            message: 'bạn phải nhập' + labelDate,
          },
          ...Utils.validateDeliveryContByType(type, info, form.getFieldValue().cargoReadyDate),
        ]}
      >
        <DateTimePicker placeholder={labelDate} />
      </Form.Item>
      {type != orderType.Order_Bill && (
        <Form.Item name='isCargo' valuePropName='checked' label='Đã Có Hàng'>
          <Checkbox />
        </Form.Item>
      )}

      {form.getFieldValue().isCargo && (
        <Form.Item
          name='cargoReadyDate'
          label='Ngày có hàng'
          rules={[
            {
              required: true,
              message: 'bạn phải nhập Ngày có Hàng',
            },
            ...Utils.validateIsCargoByType(
              type,
              info,
              form.getFieldValue().deliveryContRequestDate
            ),
          ]}
        >
          <DateTimePicker placeholder='Ngày có Hàng' showTime format={DATE_TIME_FORMAT} />
        </Form.Item>
      )}
      <Form.Item name='cargoWeight' label='Trọng tải'>
        <InputNumber placeholder='Trọng tải' />
      </Form.Item>
    </Form>
  );
});

export { FormData };
