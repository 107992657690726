import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { TableLayout, UploadExcelButton } from '~components';
import { DriverForm } from './driver-form';
import DriverFields from './fields-data';
import { Input, Button, Popconfirm, message, Space, Badge } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { DrivingCertificate } from './driving-certificate';
import { TableTitle } from '~commons/styles';
import { debounce } from 'lodash';

const EXCEL_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/Driver.xlsx';

const statusTag = (text = '', bgColor = '') => {
  return (
    <Badge>
      <div
        style={{
          backgroundColor: bgColor,
          width: '100px',
          padding: '5px 10px',
          textAlign: 'center',
          borderRadius: '4px',
          color: 'white',
          opacity: '0.7',
          // fontWeight: 'bold',
        }}
      >
        {text}
      </div>
    </Badge>
  );
};

const objSortType = {
  ascend: 'ASC',
  descend: 'DESC',
};

const DEFAULT_QUERIES = {
  PageSize: 15,
  PageNumber: 1,
  SortColumn: 'code',
  SortColumnDirection: 'ASC',
};

const Drivers = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [drivers, setDrivers] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);

  const [filter, setFilter] = useState(DEFAULT_QUERIES);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getDrivers(newQuery)
      .then((res) => {
        if (res?.data) {
          setDrivers(res?.data);
        }
      })
      .catch((err) => {
        console.error('Get Drivers Error: ', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };
  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);
  // ================= Search New ================

  const setFieldsForm = async (value) => {
    const driverFields = await DriverFields(value);
    await setFields(driverFields);
    return driverFields;
  };
  const reload = async () => {
    setSelected(null);
    await getData(filter);
  };

  const getUpdatedDriverForm = async (value, isNew = false, id = null, dataSelected) => {
    const driverForm = await (
      <DriverForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        reload={reload}
        onChange={setFields}
        dataSelected={dataSelected}
      />
    );
    if (isNew) {
      return setTabPanels([
        {
          title: 'Thông Tin Chi Tiết',
          content: driverForm,
        },
      ]);
    } else {
      return setTabPanels([
        {
          title: 'Thông Tin Chi Tiết',
          content: driverForm,
        },
        {
          title: 'Giấy Phép Lái Xe',
          content: <DrivingCertificate id={id} />,
        },
      ]);
    }
  };

  const onRowSelected = async (value) => {
    await setIsLoadingTab(true);
    await setTitleTab(value.code);
    await setFieldsForm(value)
      .then((res) => {
        return getUpdatedDriverForm(res, false, value.id, value);
      })
      .then(() => setSelected(value));
    setIsLoadingTab(false);
  };
  const deleteDriver = async (record) => {
    try {
      const res = await ApiServices.deleteDriver(record.id);
      if (res.data) {
        message.success('Xoá Tài xế thành công');
        return reload();
      }
      message.error('Xoá Tài xế thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };

  const renderStatus = (value) => {
    switch (value) {
      case 1:
        return statusTag('Nghỉ việc', 'red');
      default:
        return statusTag('Đang làm', 'green');
    }
  };

  const columns = [
    {
      title: <TableTitle>Mã tài Xế</TableTitle>,
      sorter: true,
      dataIndex: 'code',
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      children: [
        {
          title: (
            <TableTitle>
              <Input name='code' onChange={onColumnSearch} placeholder='Search' />
            </TableTitle>
          ),
          dataIndex: 'code',
          align: 'center',
          width: 100,
          render: (value, record) => (
            <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>
          ),
        },
      ],
    },
    {
      title: <TableTitle>Tên Tài Xế</TableTitle>,
      sorter: true,
      dataIndex: 'name',
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      children: [
        {
          title: (
            <TableTitle>
              <Input name='name' onChange={onColumnSearch} placeholder='Search' />
            </TableTitle>
          ),
          dataIndex: 'name',
          align: 'center',
          width: 150,
        },
      ],
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Trạng Thái</div>
        </span>
      ),
      width: 120,
      align: 'center',
      dataIndex: 'status',
      render: renderStatus,
    },
    {
      title: <TableTitle>Lương Cơ Bản</TableTitle>,
      sorter: true,
      dataIndex: 'salary',
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      children: [
        {
          title: (
            <TableTitle>
              <Input name='salary' onChange={onColumnSearch} placeholder='Search' />
            </TableTitle>
          ),
          width: 150,
          align: 'center',
          dataIndex: 'salary',
          render: (value) => Utils.formatNumber(value),
        },
      ],
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Nhóm Tài Xế</div>
          <Input placeholder='Search' allowClear />
        </span>
      ),
      width: 100,
      align: 'center',
      dataIndex: 'typeDriver',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Số Điện Thoại</div>
          <Input placeholder='Search' allowClear />
        </span>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'phone',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Email</div>
          <Input placeholder='Search' allowClear />
        </span>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'email',
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>CCCD</div>
          <Input placeholder='Search' allowClear />
        </span>
      ),
      width: 150,
      align: 'center',
      dataIndex: 'idCard',
    },
    {
      title: 'Action',
      fixed: 'right',
      align: 'center',
      width: 90,
      render: (record) => (
        <Popconfirm
          onConfirm={() => deleteDriver(record)}
          title='Xoá tài xế này?'
          okText='Yes'
          cancelText='No'
        >
          <a href='#' style={{ color: 'red' }}>
            Xoá
          </a>
        </Popconfirm>
      ),
    },
  ];

  const createDriver = async () => {
    await setTitleTab('');
    await setFieldsForm({})
      .then((res) => getUpdatedDriverForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };
  const onUploadExcel = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importDriver(file)
      .then((res) => {
        if (res.data) {
          message.success(res?.data?.message);
          reload();
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT CARR ERROR', err);
        message.error('Thao tác thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };
  const handleExportExcel = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const { data } = await ApiServices.exportDriver();
      if (data) {
        const outputFilename = `Danh_Sach_Tai_Xe_Xuat_Excel_Vao_Luc_${moment().format(
          'DD-MM-YYYY HHgiomm'
        )}.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoading(false);
    }
  };

  const headerContent = (
    <Space>
      <Button type='primary' onClick={createDriver}>
        Thêm Mới
      </Button>
      <UploadExcelButton
        loading={loading}
        onChange={onUploadExcel}
        templateUrl={EXCEL_TEMPLATE_URL}
      />
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        style={{ padding: '0px 20px', marginLeft: '10px' }}
        onClick={handleExportExcel}
      >
        Export Excel
      </Button>
    </Space>
  );

  const pagination = {
    total: drivers?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log({ pagination, filters, sorter });
    if (sorter?.field && sorter?.order) {
      setFilter((prev) => ({
        ...prev,
        SortColumn: sorter?.field,
        SortColumnDirection: objSortType[sorter?.order],
      }));
    }
  };

  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={drivers?.data || []}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      pagination={pagination}
      isDisplayCheckbox={false}
      width={600}
      headerLength='190px'
      onChange={handleTableChange}
    />
  );
};

export { Drivers };
