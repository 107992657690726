import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
export default function ({ loading, total = null }) {
  if (!loading) return null;
  return (
    <LoadingView>
      <Total>{total}</Total>
      <LoadingOutlined spin style={{ fontSize: 24 }} />
    </LoadingView>
  );
}
const Total = styled.span`
  font-weight: bold;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  bottom: 10;
  right: 10px;
  z-index: 1000;
`;
const LoadingView = styled.div`
  position: absolute;
  bottom: -14px;
  border-right-bottom-radius: 4px;
  border-left-bottom-radius: 4px;
  left: 0;
  right: 0;
  height: 50px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.15);
  margin: 15px 10px;
`;
