import React, { useEffect, useState, useCallback } from 'react';
import { TableLayout } from '~components';
import { debounce } from 'lodash';
import { Button, message, Space, Modal } from 'antd';
import { getColumns } from './columns';
import ApiServices from '~services';
import { AddPartnerForm } from './add-partner-form';
import { ViewInfo } from './view-info';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
};

const Companies = () => {
  const [loading, setLoading] = useState(false);
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const [selected, setSelected] = useState('');
  const [filter, setFilter] = useState(null);
  const [dataSearchColumn, setDataSearchColumn] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [count, setCount] = useState(1);
  const [showView, setShowView] = useState(false);
  const [dataView, setDataView] = useState(null);

  const getCompanies = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    try {
      let res = await ApiServices.getPartnerByCompany(newQuery);
      if (res?.data?.data) {
        const newData = res?.data?.data.map((item, index) => {
          item.key = `${item.id}-main-${index}`;
          item.partner = 'Đối tác';
          return item;
        });
        setDataSearchColumn(newData);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };
  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getCompanies(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const reload = () => {
    getCompanies(filter);
  };

  const reloadSubmit = () => {
    setShowModal(false);
    getCompanies(filter);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };
  const onCloseShowView = () => {
    setShowView(false);
  };
  const handleAddPartner = () => {
    const newCount = count + 1;
    setCount(newCount);
    setShowModal(true);
  };

  const onView = async (record) => {
    if (!record?.childId || loading) return;
    setLoading(true);
    try {
      let res = await ApiServices.getCompanyDetails(record?.childId);
      if (res?.data?.data) {
        setShowView(true);
        setDataView(res?.data?.data);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (parentId) => {
    if (loadingChangeStatus || !parentId) return;
    const currentCompany = JSON.parse(
      localStorage.getItem('user_data_decode_token_tms')
    )?.CompanyId;
    setLoadingChangeStatus(true);
    try {
      let res = await ApiServices.deleteConnectPartner(currentCompany, parentId);
      if (res?.data?.data === 'success') {
        reload();
      }
    } catch (error) {
      message.error(error);
    } finally {
      setLoadingChangeStatus(false);
    }
  };

  const headerContent = (
    <Space>
      <div>
        <h2>3029 - XE VỆ TINH/ ỦY QUYỀN</h2>
        <Button type='primary' onClick={handleAddPartner}>
          Thêm Đối tác
        </Button>
      </div>
    </Space>
  );

  return (
    <>
      <TableLayout
        key='key'
        columns={getColumns(onColumnSearch, handleDelete, onView)}
        data={dataSearchColumn}
        loading={loading}
        selected={selected}
        closeDrawer={() => setSelected(null)}
        headerContent={headerContent}
        width={850}
        isDisplayCheckbox={false}
      />
      <Modal
        title='Tìm kiếm vào tạo đối tác'
        open={showModal}
        onCancel={onCloseModal}
        footer={null}
        style={{
          top: 20,
        }}
        width='500px'
      >
        <AddPartnerForm reloadSubmit={reloadSubmit} count={count} />
      </Modal>
      <Modal
        title='Thông tin chi tiết'
        open={showView}
        onCancel={onCloseShowView}
        footer={null}
        style={{
          top: 20,
        }}
        width='70%'
      >
        <ViewInfo dataView={dataView} />
      </Modal>
    </>
  );
};

export { Companies };
