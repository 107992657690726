import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

import { TableDetails } from './table-details';

const ItemOilTicket = ({ itemData }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const renderCarInfo = (carInfo) => (
    <>
      <Col span={6}>
        <b>Số Xe: </b>
        <span>{carInfo?.carCode}</span>
      </Col>
      <Col span={8}>
        <b>Tài Xế: </b>
        <span>{carInfo?.driverMain}</span>
      </Col>
      <Col span={6}>
        <b>Phụ Xe: </b>
        <span>{carInfo?.driverSub}</span>
      </Col>
    </>
  );
  const sumInfo = (carInfo) => {
    const sumOilTotalSupply = carInfo?.oilToSupply;
    return (
      <>
        <Col span={8} offset={14} style={{ textAlign: 'center', marginTop: '5px', color: 'blue' }}>
          <p>
            Tổng Số Lít Dầu Tồn:{' '}
            <b className='red'>{carInfo?.totalOilInCar && Math.round(carInfo?.totalOilInCar)}</b>{' '}
            (Lit)
          </p>
          <p>
            Tổng Số Lít Dầu Cần Chi: <b className='red'>{sumOilTotalSupply}</b> (Lit){' '}
            {carInfo?.oilToSupplyExtra > 0 && (
              <span className='red'>(bao gồm {carInfo?.oilToSupplyExtra} Lít hỗ trợ)</span>
            )}
          </p>
        </Col>
      </>
    );
  };

  let jobList = [];
  if (itemData?.taskJobs?.length > 0) {
    jobList = jobList.concat(itemData?.taskJobs);
  }
  if (itemData?.extraJobs?.length > 0) {
    const extraJobsMap = itemData?.extraJobs?.map((itemData) => {
      itemData.code = 'Điều xe hỗ trợ';
      itemData.isSupport = true;
      return itemData;
    });
    jobList = jobList.concat(extraJobsMap);
  }

  return (
    <>
      <div style={{ marginTop: '5px', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          danger
          type='link'
          size='small'
          icon={isOpen ? <DownOutlined /> : <RightOutlined />}
          onClick={handleOpen}
          style={{ marginRight: '5px' }}
        >
          <span style={{ color: 'blue' }}>
            {itemData?.codeOil}
            <span style={{ color: 'black' }}> - Số xe: {itemData?.car?.carCode}</span>
          </span>
        </Button>
      </div>
      {isOpen && (
        <div
          style={{
            borderRadius: '6px',
            transition: 2,
            transitionDelay: 2,
            boxShadow:
              'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          }}
        >
          <Row style={{ marginBottom: '10px', padding: '5px 10px' }}>
            {renderCarInfo(itemData?.car)}
          </Row>
          <TableDetails dataDetails={jobList} />
          <Row>{sumInfo(itemData?.car)}</Row>
        </div>
      )}
    </>
  );
};

export { ItemOilTicket };
