import React, { useState } from 'react';
import { Button } from 'antd';
import { Selection } from '~components';

const AddLocation = ({ dataNotAssign = [], handleAddLocationDelivery }) => {
  const [valueAddLocation, setValueAddLocation] = useState('');

  const handleAddLocation = () => {
    handleAddLocationDelivery && handleAddLocationDelivery(valueAddLocation);
  };

  const handleChangeValueAdd = (locationId) => {
    setValueAddLocation(locationId);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
        }}
      >
        <Selection
          width={220}
          data={dataNotAssign}
          placeholder='Thêm Nơi đóng hàng'
          fieldValue='name'
          value={valueAddLocation}
          onChange={handleChangeValueAdd}
        />
        <Button
          style={{
            marginLeft: '5px',
          }}
          disabled={!valueAddLocation}
          type='primary'
          onClick={handleAddLocation}
        >
          Thêm
        </Button>
      </div>
    </div>
  );
};

export { AddLocation };
