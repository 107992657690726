import React, { useState, useEffect } from 'react';
import { Button, Form, InputNumber } from 'antd';
import { Selection } from '~components';

const TransitFeeMainForm = ({ countShow, handleSubmit, dataFeeRomooc, locationPackingList }) => {
  const [form] = Form.useForm();
  const [loadingFeeMainForm, setLoadingFeeMainForm] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [countShow]);

  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  const generateId = `${s4()}-${s4()}-${s4()}-${s4()}`;

  const onFinish = async (values) => {
    setLoadingFeeMainForm(true);
    const dataObjectDistance = locationPackingList?.find((item) => item.id === values.locationId);

    const dataSend = {
      ...values,
      locationName: dataObjectDistance?.name,
      idGenerate: generateId,
    };
    handleSubmit && handleSubmit(dataSend);
    form.resetFields();
    setLoadingFeeMainForm(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const currentPacking = dataFeeRomooc?.map((el) => el.locationId);
  const packingListAvailable = locationPackingList?.filter((item) => {
    return !currentPacking.includes(item.id);
  });

  return (
    <div>
      <Form
        name='basic'
        form={form}
        labelCol={{
          span: 16,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        layout='vertical'
      >
        <Form.Item
          label='Nơi đóng hàng'
          name='locationId'
          rules={[
            {
              required: true,
              message: 'Bạn phải Nơi đóng hàng!',
            },
          ]}
        >
          <Selection data={packingListAvailable} placeholder='Nơi đóng hàng' fieldValue='name' />
        </Form.Item>
        <Form.Item
          label='Phí neo Romooc/Ngày'
          name='price'
          rules={[
            {
              required: true,
              message: `Bạn phải nhập phí neo Romooc/Ngày`,
            },
          ]}
        >
          <InputNumber
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label='Số ngày neo miễn phí'
          name='freeDate'
          rules={[
            {
              required: true,
              message: `Bạn phải nhập số ngày neo miễn phí`,
            },
          ]}
        >
          <InputNumber
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type='primary' htmlType='submit' loading={loadingFeeMainForm}>
            Tạo phí
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { TransitFeeMainForm };
