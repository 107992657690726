import React from 'react';
import { Descriptions } from 'antd';

const InfoCarInspection = ({ infoCarInsurance }) => {
  return (
    <Descriptions
      // title="Thông Tin Xe:"
      bordered
      column={2}
      size='small'
      labelStyle={{ backgroundColor: '#CEE5D0' }}
    >
      <Descriptions.Item label='Biển Số'>{infoCarInsurance?.carCode}</Descriptions.Item>
      <Descriptions.Item label='Nhãn Hiệu'>{infoCarInsurance?.label}</Descriptions.Item>
      <Descriptions.Item label='Chủng Loại'>{infoCarInsurance?.type}</Descriptions.Item>
      <Descriptions.Item label='Nơi Sản Xuất'>{infoCarInsurance?.madeIn}</Descriptions.Item>
      <Descriptions.Item label='Năm Sản Xuất'>{infoCarInsurance?.madeYear}</Descriptions.Item>
    </Descriptions>
  );
};

export { InfoCarInspection };
