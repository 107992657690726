import React, { useState } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

import { RvsTMS } from './orders/rvs-tms';
import { CreateTMS } from './orders/create-tms';
import { OrderItem } from './order-item';

const KEY_ORDER = {
  RVS_TMS: 'RVS_TMS',
  CREATE_TMS: 'CREATE_TMS',
};

export const OrderRvsByTms = () => {
  const { countOrderRvsByTmsNotRead, countOrderCreateByTmsNotRead } = useSelector(
    (state) => state.orderONPWMS
  );

  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [keyOrder, setKeyOrder] = useState('');

  const handleCloseModal = () => {
    setShowModal(false);
    setTitleModal('');
    setKeyOrder('');
  };

  const handleActionOrder = ({ title, key }) => {
    setTitleModal(title);
    setKeyOrder(key);
    setShowModal(true);
  };

  const renderOrders = (key) => {
    switch (key) {
      case KEY_ORDER.RVS_TMS:
        return <RvsTMS onClose={handleCloseModal} />;
      case KEY_ORDER.CREATE_TMS:
        return <CreateTMS onClose={handleCloseModal} />;
      default:
        break;
    }
  };

  return (
    <>
      {countOrderRvsByTmsNotRead > 0 && (
        <OrderItem
          title='Booking RVS của TMS:'
          countOrder={countOrderRvsByTmsNotRead}
          handleClick={() =>
            handleActionOrder({
              title: `Booking RVS của TMS: ${countOrderRvsByTmsNotRead}`,
              key: KEY_ORDER.RVS_TMS,
            })
          }
        />
      )}

      {countOrderCreateByTmsNotRead > 0 && (
        <OrderItem
          title='Booking tạo  bởi TMS:'
          countOrder={countOrderCreateByTmsNotRead}
          handleClick={() =>
            handleActionOrder({
              title: `Booking tạo bởi TMS: ${countOrderCreateByTmsNotRead}`,
              key: KEY_ORDER.CREATE_TMS,
            })
          }
        />
      )}

      <Modal
        title={`${titleModal}`}
        open={showModal}
        onCancel={handleCloseModal}
        footer={null}
        style={{
          top: 20,
        }}
        width='90%'
      >
        {renderOrders(keyOrder)}
      </Modal>
    </>
  );
};
