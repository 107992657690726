import { InputSearchColumns, BtnConfirm } from '~components';

export const getColumns = (onRowSelected, searchByColumn, handleSearchColumnFn, deleteVendor) => [
  {
    title: (
      <InputSearchColumns
        title='Mã'
        value={searchByColumn?.code}
        onChange={(e) => handleSearchColumnFn(e, 'code')}
      />
    ),
    dataIndex: 'code',
    width: 150,
    render: (value, record) => <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>,
  },
  {
    title: (
      <InputSearchColumns
        title='Tên Nhà Xe'
        value={searchByColumn?.name}
        onChange={(e) => handleSearchColumnFn(e, 'name')}
      />
    ),
    width: 150,
    dataIndex: 'name',
  },
  {
    title: 'Công Ty',
    width: 150,
    dataIndex: 'companyName',
  },
  {
    title: 'SL Khách hàng',
    width: 150,
    dataIndex: 'arrCustomerId',
    align: 'center',
    render: (value) => value?.length,
  },
  {
    title: 'Ghi Chú',
    width: 150,
    dataIndex: 'note',
  },
  {
    title: 'Action',
    fixed: 'right',
    width: 75,
    render: (record) => <BtnConfirm onConfirm={() => deleteVendor(record)}>Xóa</BtnConfirm>,
  },
];
