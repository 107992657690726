import React, { useEffect, useState } from 'react';
import { Form, Button, message, InputNumber } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';
import { Selection } from '~components';

const InputForm = ({ fields, isNew, id, dataSelect, reload, onChange, setLoading }) => {
  const [locations, setLocations] = useState([]);

  const getLocations = async (query = null) => {
    try {
      const { data } = await ApiServices.getLocations({ query });
      if (data?.data) {
        setLocations(data?.data);
      }
    } catch (err) {
      console.error('ERR - Get ContTypes', err);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  const handleCreate = async (data) => {
    data.companyId = JSON.parse(localStorage.getItem('user_data_decode_token_tms'))?.CompanyId;

    try {
      const res = await ApiServices.createLocationConfig(data);
      if (res?.data?.data) {
        message.success('Thêm mới thành công');
        return reload();
      } else {
        message.error('Thêm mới thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const handleUpdate = async (data) => {
    data.id = id;
    data.companyId = dataSelect.companyId;
    try {
      const res = await ApiServices.updateLocationConfig(id, data);
      if (res.data) {
        message.success('Cập nhật thành công');
        return reload();
      } else {
        message.error('Cập nhật thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Cập nhật thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };
  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await handleCreate(value);
      setLoading(false);
      return;
    }
    setLoading(true);
    await handleUpdate(value);
    setLoading(false);
  };
  return (
    <Form
      {...layout}
      name='global_state'
      className='form-input-data'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        name='locationId'
        label='Tên Địa Điểm'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn Địa Điểm',
          },
        ]}
      >
        <Selection
          data={locations}
          labelDefault={dataSelect?.locationName}
          fieldValue='name'
          placeholder='Tên Địa Điểm'
          onSearch={getLocations}
          disabled={!isNew}
        />
      </Form.Item>
      <Form.Item
        // {...itemLayout}
        style={{ marginTop: '10px' }}
        name='loss'
        label='Số KM Hao Hụt'
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Số KM Hao Hụt',
          },
          {
            type: 'number',
            min: 0,
            message: 'Số KM Hao Hụt phải lớn hơn 0',
          },
          {
            type: 'number',
            max: 10,
            message: 'Số KM Hao Hụt phải nhỏ hơn 10',
          },
        ]}
      >
        <InputNumber min={0} style={{ width: '100%' }} placeholder='Vui lòng nhập Số KM Hao Hụt' />
      </Form.Item>
      <Form.Item {...tailLayout} className='submit-form-input-data'>
        <Button type='primary' htmlType='submit'>
          {isNew ? 'Thêm Mới' : 'Cập nhật'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { InputForm };
