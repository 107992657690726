import actionTypes from './type';
// import ONPServices from '~services/onp-service';
import ApiServices from '~services';

export function fetchOrderWaitingReceiveONP() {
  return async (dispatch) => {
    try {
      // const { data } = await ONPServices.getOrdersWaitingReceiveONP();
      const { data } = await ApiServices.getOrdersFromONP();
      return dispatch({
        data: data?.data || [],
        type: actionTypes.FETCH_ORDERS_WAITING_RECEIVE_ONP_DATA,
      });
    } catch (error) {
      console.error('FETCH_ORDERS_WAITING_RECEIVE_ONP_DATA-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_ORDERS_WAITING_RECEIVE_ONP_DATA });
  };
}

export function fetchOrderWaitingReceiveWMS() {
  return async (dispatch) => {
    try {
      // const { data } = await ONPServices.getOrdersWaitingReceiveWMS();
      const { data } = await ApiServices.getOrdersFromWMS();
      return dispatch({
        data: data?.data || [],
        type: actionTypes.FETCH_ORDERS_WAITING_RECEIVE_WMS_DATA,
      });
    } catch (error) {
      console.error('FETCH_ORDERS_WAITING_RECEIVE_WMS_DATA-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_ORDERS_WAITING_RECEIVE_WMS_DATA });
  };
}

export function fetchOrderRevisedONP() {
  return async (dispatch) => {
    try {
      // const { data } = await ONPServices.getOrdersRevisedONP();
      const { data } = await ApiServices.getOrdersRVSFromONP();
      return dispatch({
        data: data?.data || [],
        type: actionTypes.FETCH_ORDERS_REVISED_ONP_DATA,
      });
    } catch (error) {
      console.error('FETCH_ORDERS_REVISED_ONP_DATA-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_ORDERS_REVISED_ONP_DATA });
  };
}

export function fetchOrderRevisedWMS() {
  return async (dispatch) => {
    try {
      // const { data } = await ONPServices.getOrdersRevisedWMS();
      const { data } = await ApiServices.getOrdersRVSFromWMS();
      return dispatch({
        data: data?.data || [],
        type: actionTypes.FETCH_ORDERS_REVISED_WMS_DATA,
      });
    } catch (error) {
      console.error('FETCH_ORDERS_REVISED_WMS_DATA-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_ORDERS_REVISED_WMS_DATA });
  };
}

export function fetchOrderCancelONP() {
  return async (dispatch) => {
    try {
      // const { data } = await ONPServices.getOrdersCancelONP();
      const { data } = await ApiServices.getOrdersCancelFromONP();
      return dispatch({
        data: data?.data || [],
        type: actionTypes.FETCH_ORDERS_CANCEL_ONP_DATA,
      });
    } catch (error) {
      console.error('FETCH_ORDERS_CANCEL_ONP_DATA-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_ORDERS_CANCEL_ONP_DATA });
  };
}

export function fetchOrderCancelWMS() {
  return async (dispatch) => {
    try {
      // const { data } = await ONPServices.getOrdersCancelWMS();
      const { data } = await ApiServices.getOrdersCancelFromWMS();
      return dispatch({
        data: data?.data || [],
        type: actionTypes.FETCH_ORDERS_CANCEL_WMS_DATA,
      });
    } catch (error) {
      console.error('FETCH_ORDERS_CANCEL_WMS_DATA-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_ORDERS_CANCEL_WMS_DATA });
  };
}

export function fetchOrderByTmsNotRead() {
  return async (dispatch) => {
    try {
      const { data } = await ApiServices.getOrderByTmsNotRead(1);
      return dispatch({
        data: data?.data || [],
        type: actionTypes.FETCH_ORDERS_RVS_BY_TMS_NOT_READ,
      });
    } catch (error) {
      console.error('FETCH_ORDERS_RVS_BY_TMS_NOT_READ-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_ORDERS_RVS_BY_TMS_NOT_READ });
  };
}

export function fetchOrderCreateByTmsNotRead() {
  return async (dispatch) => {
    try {
      const { data } = await ApiServices.getOrderByTmsNotRead(2);
      return dispatch({
        data: data?.data || [],
        type: actionTypes.FETCH_ORDERS_CREATE_BY_TMS_NOT_READ,
      });
    } catch (error) {
      console.error('FETCH_ORDERS_CREATE_BY_TMS_NOT_READ-ERR', error);
    }
    return dispatch({ data: [], type: actionTypes.FETCH_ORDERS_CREATE_BY_TMS_NOT_READ });
  };
}
