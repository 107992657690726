export const KEYS = [
  'id',
  'code',
  'id',
  'name',
  'companyId',
  'companyName',
  'salary',
  'typeDriver',
  'phone',
  'email',
  'idCard',
  'objDrivers',
  'objCars',
];
export const KEYS_OF_NUMBER = ['status'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });
  KEYS_OF_NUMBER.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] || 0,
    });
  });
  return result;
};
