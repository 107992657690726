import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchLiftOn,
  loadMore,
  updateQuery,
  updateLoading,
  updateSelected,
  selectedDataRowLiftOn,
} from '~store/reducers/lift-on/action';

import { updateIndexSelected } from '~store/reducers/lift-off/action';
import { showListFile, showAssignCarToday } from '~store/reducers/white-board/action';
import { TableWhiteBoard, WBTableLoadMore } from '~components';
import { getColumns } from './columns';
import { Wrapper, Title } from '~commons/styles';
import { useWBTableScroll } from '~hooks';
import useApiHook from '../wb-hook/api-hook';
import Utils from '~commons/utils';
import { JobsTransfer } from '../job-transfer-button';
import { FormInput } from '../../job-handle/form-input';
import { message, Modal } from 'antd';
import { JOB_HANDLE_WIDTH } from '~commons/constants';
import ApiServices from '~services';
import { ContNumberForm } from '../assign-car-list/cont-number-form';
const WIDTH = '600px';

export const LiftOn = () => {
  const dispatch = useDispatch();
  const [loadingMore, setLoadingMore] = useState(false);
  const [partnerSelect, setPartner] = useState();
  const [contDataUpdate, setContDataUpdate] = useState();
  const [partnerJobSuccessful, setPartnerJobSuccessful] = useState(); //Job Successful To Partner

  const { data: liftOnData, query, loading, selected } = useSelector((state) => state.liftOn);
  const { selected: dataSelectedLiftOff } = useSelector((state) => state.liftOff);

  const timeOutRef = useRef(null);
  const timeOutSetQueryRef = useRef(null);

  const { tableRef, isScroll } = useWBTableScroll(null);
  const { totalLiftOffSelect, canCelLiftOn, reloadWaitingOfDelivery, showCustomerDetail } =
    useApiHook();

  const setLoading = (val) => dispatch(updateLoading(val));

  const LoadMoreData = async () => {
    if (loadingMore) return;
    if (liftOnData.pageNumber * query.PageSize <= liftOnData.totalRecords) {
      setLoadingMore(true);
      let tmpQuery = { ...query, PageNumber: liftOnData.pageNumber + 1 };
      return dispatch(loadMore(tmpQuery)).finally(() => {
        setLoadingMore(false);
      });
    }
  };

  useEffect(() => {
    if (isScroll) {
      LoadMoreData();
    }
  }, [isScroll]);

  const initData = async () => {
    dispatch(updateLoading(true));
    await dispatch(fetchLiftOn(query));
    dispatch(updateLoading(false));
  };

  useEffect(() => {
    clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => initData(), 50);
  }, [query]);

  const setQuery = (columns) => {
    const tmpQuery = { ...query };
    tmpQuery.Columns = { ...tmpQuery.Columns, ...columns };

    clearTimeout(timeOutSetQueryRef.current);
    timeOutSetQueryRef.current = setTimeout(() => dispatch(updateQuery(tmpQuery)), 250);
  };
  const onSelectChange = (_, selectedRows) => {
    if (partnerSelect) {
      dispatch(updateSelected(selectedRows));
      return;
    }

    const mapData = selectedRows.map((item) => item.jobId);
    mapData?.length && totalLiftOffSelect && dispatch(updateIndexSelected(0));
    dispatch(selectedDataRowLiftOn(mapData, selectedRows));
    dispatch(updateSelected(selectedRows));
  };

  const onSelectChangeTour = (dataRow) => {
    if (!dataRow) return;
    const selectedRows = [dataRow];

    const mapData = selectedRows.map((item) => item.jobId);
    mapData?.length && totalLiftOffSelect && dispatch(updateIndexSelected(0));
    const isCheckPlan = false;
    dispatch(selectedDataRowLiftOn(mapData, selectedRows, isCheckPlan));
  };

  const onChangeTransfer = (partner) => {
    if (!partner) {
      dispatch(updateSelected([]));
    }
    setPartner(partner);
  };
  const onShowJobSuccessful = (record) => {
    setPartnerJobSuccessful(record);
  };
  const onCloseJobSuccessful = () => {
    setPartnerJobSuccessful();
  };
  const onSubmitJobSuccessful = () => {
    reloadWaitingOfDelivery();
  };
  const onCancelTransferJob = (record) => {
    setLoading(true);
    ApiServices.cancelTransferJob(record?.jobId)
      .then(() => {
        message.success('Hủy Chuyển CV Thành Công!');
        initData();
      })
      .catch((err) => {
        console.error('Cancel Transfer Jobs error', err);
        message.error('Hủy CV Chuyển Thất Bại!');
      })
      .finally(() => {
        reloadWaitingOfDelivery();
        setLoading(false);
      });
  };
  const showOrderFiles = (record) => {
    dispatch(showListFile(record));
  };
  const showAssign = (record) => {
    dispatch(showAssignCarToday(record));
  };
  const showContForm = (record) => {
    setContDataUpdate({
      contFiles: record?.contFiles,
      contNumber: record?.contNumber,
      sealNumber: record?.sealNumber,
      contId: record?.id || record?.contId,
    });
  };
  const onCloseContForm = () => setContDataUpdate();
  const renderRowKey = (record) => record.jobId?.join(',');
  const renderRowClassName = (record) =>
    `${
      record?.assignId || record?.childJobName?.[0]
        ? 'row-assigned'
        : Utils.renderTypeOrderBackground(record?.type)
    } ${record.type}`;

  return (
    <Wrapper width={WIDTH}>
      <Title>
        Nâng(Lấy Rỗng Xuất - Lấy Hàng Nhập){' '}
        <b className='red'>({liftOnData?.countNotAssign || 0})</b>
        <b> ({liftOnData?.totalRecords - liftOnData?.countNotAssign})</b>
      </Title>
      <TableWhiteBoard
        tableRef={tableRef}
        headerLength='190px'
        renderRowKey={renderRowKey}
        rowClassName={renderRowClassName}
        columns={getColumns(
          setQuery,
          showOrderFiles,
          showAssign,
          canCelLiftOn,
          onShowJobSuccessful,
          onCancelTransferJob,
          showContForm,
          showCustomerDetail,
          onSelectChangeTour,
          selected,
          dataSelectedLiftOff
        )}
        data={liftOnData?.data}
        loading={loading}
        onSelectChange={onSelectChange}
        selectedParent={selected}
        limitSelected={!partnerSelect}
        disableSelectAll={!partnerSelect}
        checkDisableSelection={(record) => !!record?.carCode || record?.childJobName?.length}
      />
      <WBTableLoadMore loading={loadingMore} total={liftOnData?.data?.length} />
      <JobsTransfer
        onChange={onChangeTransfer}
        selected={selected}
        updateLoading={setLoading}
        reloadFinish={reloadWaitingOfDelivery}
      />
      <ContNumberForm
        cont={contDataUpdate}
        isUpdateContNumber
        reload={initData}
        onClose={onCloseContForm}
        setLoading={setLoading}
      />
      <Modal
        title='Hoàn Thành Công Việc Cho ĐT'
        open={!!partnerJobSuccessful}
        footer={null}
        width={JOB_HANDLE_WIDTH}
        onCancel={onCloseJobSuccessful}
      >
        <FormInput
          dataInput={partnerJobSuccessful}
          reload={initData}
          onClose={onCloseJobSuccessful}
          onSubmit={onSubmitJobSuccessful}
        />
      </Modal>
    </Wrapper>
  );
};
