import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import { Input, Button, Popconfirm, message, Space } from 'antd';
import { TableLayout } from '~components';
import { InputForm } from './input-form';
import DriverFields from './fields-data';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { TableTitle } from '~commons/styles';
import { DownloadOutlined } from '@ant-design/icons';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().startOf('month').format('YYYY-MM-DD'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD'),
};

const TransitContPage = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [managementCosts, setManagementCosts] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getTransitConts(newQuery)
      .then((res) => {
        if (res?.data) {
          setManagementCosts(res?.data);
        }
      })
      .catch((err) => {
        console.error('Get Vats Paging Error', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event) => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = event.target.value;
      const name = event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const setFieldsForm = async (value) => {
    const driverFields = await DriverFields(value);
    await setFields(driverFields);
    return driverFields;
  };
  const reload = async () => {
    setSelected(null);
    await getData(filter);
  };

  const getUpdatedOilPriceForm = async (value, isNew = false, dataSelect, id = null) => {
    const driverForm = await (
      <InputForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        dataSelect={dataSelect}
        reload={reload}
        onChange={setFields}
      />
    );
    return setTabPanels([
      {
        title: 'Thông Tin Chi Tiết',
        content: driverForm,
      },
    ]);
  };

  const onRowSelected = async (value) => {
    const _value = {
      ...value,
      inputDate: Utils.convertToDate(value?.inputDate),
    };

    await setIsLoadingTab(true);
    await setTitleTab(`Cập nhật: ${value?.code?.toUpperCase()}`);
    await setFieldsForm(_value)
      .then((res) => {
        return getUpdatedOilPriceForm(res, false, value, _value.id);
      })
      .then(() => setSelected(_value));
    setIsLoadingTab(false);
  };
  const deleteSupplierBills = async (record) => {
    setLoading(true);
    return ApiServices.deleteTransitCont(record?.id)
      .then((res) => {
        if (res?.data?.data) {
          message.success('Xoá thành công');
          return reload();
        } else {
          message.error('Xoá thất baị, vui lòng kiểm tra lại thông tin');
        }
      })
      .catch((err) => {
        console.log('Delete oil prices failed: ', err);
        message.error('Xoá thất baị, vui lòng kiểm tra lại thông tin');
      })
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Mã
          <Input name='code' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'code',
      align: 'center',
      render: (value, record) => (
        <a onClick={() => onRowSelected(record)}>{value?.toUpperCase() || 'underFine'}</a>
      ),
    },
    {
      title: (
        <TableTitle>
          Số Cont
          <Input name='contNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'contNumber',
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Loại Cont
          <Input name='typeContName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'typeContName',
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Số Romooc
          <Input name='romoocCode' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'romoocCode',
      align: 'center',
    },
    {
      title: 'Action',
      fixed: 'right',
      align: 'center',
      width: 90,
      render: (record) => (
        <Popconfirm
          onConfirm={() => deleteSupplierBills(record)}
          title='Bạn có chắc chắn xóa?'
          okText='Có'
          cancelText='Không'
        >
          <a href='#' style={{ color: 'red' }}>
            Xoá
          </a>
        </Popconfirm>
      ),
    },
  ];

  const createDriver = async () => {
    await setTitleTab('Thêm mới');
    await setFieldsForm({})
      .then((res) => getUpdatedOilPriceForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };

  const handleExportExcel = async () => {
    if (loading) return;

    const dataSend = {
      export: true,
    };
    setLoading(true);
    try {
      const { data } = await ApiServices.exportExcelTransitConts(dataSend);
      if (data) {
        const outputFilename = `Danh_Sach_Cont_Chuyen_Kho.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti?.error('Export thất bại!', 'Không có dữ liệu');
      }
    } catch (err) {
      window.TmsNoti?.error('Export thất bại!');
    } finally {
      setLoading(false);
    }
  };

  const headerContent = (
    <Space>
      <Button type='primary' onClick={createDriver}>
        Thêm Mới
      </Button>
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        style={{ padding: '0px 20px', marginLeft: '10px' }}
        onClick={handleExportExcel}
      >
        Export Excel
      </Button>
    </Space>
  );

  const pagination = {
    total: managementCosts?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  return (
    <TableLayout
      rowKey='id'
      columns={columns}
      data={managementCosts?.data || []}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      pagination={pagination}
      isDisplayCheckbox={false}
    />
  );
};

export { TransitContPage };
