import { useMemo } from 'react';
import { Button, Col, Form, Input, Modal, Row, Space, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { updateShowCustomerDetail } from '~store/reducers/white-board/action';
import Utils from '~commons/utils';
import styled from 'styled-components';
import ApiServices from '~services';
import { useState, useEffect } from 'react';
import useApiHook from '~pages/white-board/wb-hook/api-hook';

export const CustomerDetail = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { updateAllNote } = useApiHook();

  const data = useSelector((state) => state.whiteBoard.showCustomerDetail);
  const onClose = () => {
    dispatch(updateShowCustomerDetail());
  };

  const getNotes = (contId) => {
    if (!contId) {
      form.resetFields();
      return;
    }
    setLoading(true);
    ApiServices.getContNote(contId)
      .then((res) => {
        const dataNote = res.data.data;
        form.setFieldsValue({
          notePlan: dataNote?.notePlan,
          noteDelivery: dataNote?.noteDelivery,
          noteUp: dataNote?.noteUp,
          noteDown: dataNote?.noteDown,
        });
      })
      .catch((err) => {
        console.error('Get Cont Note Error', err);
        form.resetFields();
      })
      .then(() => {
        setLoading(false);
      });
  };
  const contId = data?.contId || data?.id;

  useEffect(() => {
    getNotes(contId);
  }, [contId]);

  const visible = !!data;

  const onSubmitForm = (val) => {
    if (!contId) return;
    ApiServices.updateAllNote(contId, val)
      .then(() => {
        onClose();
        updateAllNote(contId, val);
      })
      .catch((err) => {
        console.error('Update Note Error', err);
      });
  };

  const noteContent = (
    <FormStyled layout='vertical' form={form} onFinish={onSubmitForm}>
      <Form.Item label='Ghi chú kế hoạch' name='notePlan'>
        <Input placeholder='Ghi chú kế hoạch' />
      </Form.Item>
      <Form.Item label='Ghi chú Báo xuất/Hạ rỗng' name='noteDelivery'>
        <Input placeholder='Ghi chú Báo xuất/Hạ rỗng' />
      </Form.Item>
      <Form.Item label='Ghi chú nâng' name='noteUp'>
        <Input placeholder='Ghi chú nâng' />
      </Form.Item>
      <Form.Item label='Ghi chú hạ' name='noteDown'>
        <Input placeholder='Ghi chú hạ' />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type='primary' htmlType='submit'>
            LƯU GHI CHÚ
          </Button>
          {/* <Popconfirm title='Xác nhận hủy?' disabled>
            <Button type='danger' ghost disabled>
              HỦY ĐƠN HÀNG
            </Button>
          </Popconfirm> */}
        </Space>
      </Form.Item>
    </FormStyled>
  );
  const detail = useMemo(
    () => (
      <DetailForm>
        <Form.Item label='Khách hàng'>
          <Input value={data?.customerName} readOnly />
        </Form.Item>
        <Form.Item label='Subno'>
          <Input value={data?.subnoNumber} readOnly />
        </Form.Item>
        <Form.Item label='Ngày yêu cầu lấy/Giao'>
          <Input
            value={Utils.formatShortenDate(
              data?.booking_DeliveryContRequestDate || data?.bill_LiftingLadenContRequestDate
            )}
            readOnly
          />
        </Form.Item>
        <Form.Item label='Nơi lấy hàng'>
          <Input value={data?.lh} readOnly />
        </Form.Item>
        <Form.Item label='Nơi giao hàng'>
          <Input value={data?.gh} readOnly />
        </Form.Item>
        <Form.Item label='Hãng tàu'>
          <Input value={data?.shippingLine} readOnly />
        </Form.Item>
      </DetailForm>
    ),
    [data]
  );
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      title='Thông Tin Chi Tiết Cont'
      width={800}
    >
      <Spin spinning={loading}>
        <Row gutter={24}>
          <Col span={13}>{noteContent}</Col>
          <Col span={11}>{detail}</Col>
        </Row>
      </Spin>
    </Modal>
  );
};

const FormStyled = styled(Form)`
  & .ant-form-item {
    margin-bottom: 12px;
  }
`;
const DetailForm = styled(Form)`
  background: var(--wb-hover);
  padding: 12px 8px;
  padding-bottom: 4px;
  border-radius: 4px;
  & .ant-form-item {
    margin-bottom: 12px;
  }
  & .ant-col.ant-form-item-control input {
    background: var(--wb-hover);
    font-weight: bold;
    font-size: 11px;
    display: block;
  }
`;
