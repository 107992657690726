export const KEYS = ['partnerId'];
export const KEYS_ARRAY = ['arrTicketTransit'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });
  KEYS_ARRAY.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] || [],
    });
  });

  return result;
};
