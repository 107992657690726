export const KEYS = [
  'code',
  'name',
  'typeLocationCode',
  'address',
  'note',
  'city',
  'district',
  'ward',
  'geo',
  'status',
  'isActive',
  'companyId',
  'companyName',
];
export const KEYS_FORMAT = ['address', 'city', 'district', 'ward', 'geo'];

export const formatData = (data) => {
  let result = KEYS_FORMAT.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });
  return result;
};

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });
  return result;
};
