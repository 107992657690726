import { Button, message } from 'antd';
import React, { useState } from 'react';
import ApiServices from '~services';
import { CheckCircleOutlined } from '@ant-design/icons';

export const CompletedAll = ({ selectedRows, reload }) => {
  const [loading, setLoading] = useState(false);

  const submitDataTransfer = async () => {
    if (loading) return;
    setLoading(true);
    const contIdList = selectedRows?.map((item) => item.id);

    return ApiServices.completedAllBillCont(contIdList)
      .then((res) => {
        if (res?.data?.data === 'success') {
          message.success('Hoàn thành thành công');
          return reload();
        }
        message.error('Hoàn thành thất bại');
      })
      .catch((err) => {
        message.error('Hoàn thành thất bại');
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const renderContListToTranfer = (item, index) => {
    return (
      <div key={item?.id}>
        <b>
          {index + 1}
          {'. '}
        </b>
        <span className='blue'>{item?.subnoNumber}</span>{' '}
        <span className='red'>(Số Cont: {item?.contNumber})</span>
      </div>
    );
  };

  return (
    <>
      <div>
        <div>
          <h3>
            Danh sách Cont được phép hoàn thành: <b className='red'>({selectedRows?.length})</b>
          </h3>
          {selectedRows?.length && selectedRows?.map(renderContListToTranfer)}
        </div>
        <div
          style={{
            marginTop: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            type='primary'
            onClick={submitDataTransfer}
            icon={
              <CheckCircleOutlined
                style={{
                  fontSize: '15px',
                }}
              />
            }
          >
            Hoàn thành
          </Button>
        </div>
      </div>
    </>
  );
};
