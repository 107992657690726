import moment from 'moment';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '~commons/constants';

export const KEYS = [
  'typeCont', // "string",
  'cargoWeight', // "string",
  'isCargo', // "string",
  'companyId',
  'companyName',
  'vendorId',
  'vendorName',
  'contNumber',
  'sealNumber',
  'contSum',
];
export const KEYS_OF_DATE = [
  'deliveryContRequestDate', // "string",
];
export const KEYS_OF_DATE_TIME = [
  'cargoReadyDate', // "string",
];
// export const KEYS_OF_CHECKED = [
//   'isCargo', // "string",
// ];
export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });

  KEYS_OF_DATE.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? moment(data[item], DATE_FORMAT) : null,
    });
  });
  KEYS_OF_DATE_TIME.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? moment(data[item], DATE_TIME_FORMAT) : null,
    });
  });
  // KEYS_OF_DATE_TIME.forEach((item) => {
  //   result.push({
  //     name: [item],
  //     value: data[item] || false,
  //   });
  // });
  return result;
};
