import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
const { Option } = Select;

export const Selection = ({
  data = [],
  placeholder = '',
  onSearch = null,
  onClear = null,
  onChange = null,
  onFocus = null,
  onBlur = null,
  fieldValue = null,
  labelDefault = null,
  labelDefaultArray = [],
  value = [],
  typeOption = '',
  mode = null,
  disabled = null,
  checkDisabledWithArrId = false,
  disabledWithArrId = [],
  ref = null,
  width = null,
  autoFocus = false,
  showSearch = false,
  autoSelectFirstOption = false,
  status = '',
  allowClear = true,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const searchRef = useRef(null);
  const onSearchText = async (text) => {
    clearTimeout(searchRef.current);
    searchRef.current = setTimeout(async () => {
      setLoading(true);
      await onSearch(text);
      setLoading(false);
    }, 500);
  };

  const fistOption =
    labelDefault && data.findIndex((element) => element.id == value) == -1 ? (
      <Option key={value} value={value}>
        {labelDefault}
      </Option>
    ) : null;

  const fistArrayOption = labelDefaultArray?.map((element) => {
    const ind = data.map((element) => element.id).findIndex((el) => el === element.id);
    if (ind !== -1) return;
    return (
      <Option key={element.id} value={element.id}>
        {element.name}
      </Option>
    );
  });

  const options = (typeOption) => {
    return data.map((item) => {
      switch (typeOption) {
        case 'route-location':
          return (
            <Option key={item.id} value={item?.code} data={item}>
              {item?.locationFromName} {'->'} {item?.locationToName}{' '}
              <i className='red'>{item?.distanceKm}Km</i>
            </Option>
          );
        case 'partner-whiteboard':
          return (
            <Option key={item.id} value={item?.id} data={item}>
              {item?.name}
            </Option>
          );
        case 'supplier':
          return (
            <Option key={item.id} value={item?.name} data={item}>
              {fieldValue ? item[fieldValue] : item?.name}
            </Option>
          );
        case 'car':
          return (
            <Option key={item.id} value={item.id} data={item}>
              {fieldValue ? item[fieldValue] : item.name || item.code}{' '}
              <span className='red'>(Count tour)</span> - X Ngày kiểm định
            </Option>
          );
        case 'carWhiteBoard':
          return (
            <Option key={item.id} value={item.id} data={item}>
              {/* {fieldValue ? item[fieldValue] : item.name || item.code}{' '} */}
              {item?.code}
              {item?.moocConts && item?.moocConts?.isCont && (
                <div
                  className='red'
                  style={{
                    color: 'red',
                    marginTop: '-5px',
                    paddingTop: '-5px',
                    fontSize: '11px',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {item?.moocConts?.infoConts?.[0]?.subnoNumber}
                </div>
              )}
            </Option>
          );
        case 'romoocWhiteBoard':
          return (
            <Option
              className={item?.moocConts ? 'rommooc-white-board-custom' : ''}
              key={item.id}
              value={item.id}
              data={item}
            >
              {item.code} {item.romoocName ? <span className='red'>{item.romoocName}</span> : null}
              {item?.moocConts && item?.moocConts?.isCont && (
                <div
                  className='red'
                  style={{
                    color: 'red',
                    marginTop: '-5px',
                    paddingTop: '-5px',
                    fontSize: '11px',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {item?.moocConts?.infoConts?.[0]?.contNumber
                    ? item?.isSingleCont
                      ? `Rỗng: ${item?.moocConts?.infoConts?.[0]?.contNumber}`
                      : item?.moocConts?.infoConts?.[0]?.contNumber
                    : item?.moocConts?.infoConts?.[0]?.subnoNumber}
                </div>
              )}
            </Option>
          );
        case 'romooc':
          return (
            <Option key={item.id} value={item.id} data={item}>
              {item.code} {item.romoocName ? <span className='red'>{item.romoocName}</span> : null}
            </Option>
          );
        case 'changeTaskJob':
          return (
            <Option key={item.contId} value={item.contId} data={item}>
              {item?.subnoNumber} - {item?.type} - {item?.customerName}
            </Option>
          );
        default:
          return (
            <Option
              key={item.id}
              value={item.id}
              data={item}
              disabled={checkDisabledWithArrId ? disabledWithArrId.includes(item.id) : false}
            >
              {fieldValue ? item[fieldValue] : item.name || item.code}
            </Option>
          );
      }
    });
  };

  useEffect(() => {
    if (data.length && autoSelectFirstOption) {
      onChange?.(data[0].id);
    }
  }, [data?.length]);

  return (
    <Select
      ref={ref}
      status={status ? status : ''}
      disabled={disabled ? disabled : null}
      style={{
        width: width ? width : '100%',
        ...props.style,
      }}
      placeholder={placeholder}
      allowClear={allowClear}
      onChange={onChange}
      showSearch={showSearch || !!onSearch}
      onFocus={onFocus}
      onBlur={onBlur}
      defaultActiveFirstOption={false}
      // showArrow={false}
      filterOption={false}
      value={value}
      onSearch={onSearch ? onSearchText : null}
      onClear={onClear ? onClear : null}
      autoFocus={autoFocus}
      loading={loading}
      size='middle'
      mode={mode}
      autoClearSearchValue
      notFoundContent={loading ? <Spin size='small' /> : null}
    >
      {fistOption && fistOption}
      {fistArrayOption && fistArrayOption}
      {options(typeOption)}
    </Select>
  );
};
export const SimpleSelection = ({
  data = [],
  arrData = [],
  placeholder = '',
  onSearch = null,
  onChange = null,
  onFocus = null,
  onBlur = null,
  disabled = false,
  ref,
  width,
  autoFocus = false,
  showSearch = false,
  defaultValue,
  value,
  allowClear = false,
  getLabel,
  ...props
}) => {
  const options = arrData?.length
    ? arrData.map((val) => (
        <Option key={val} value={val}>
          {getLabel && getLabel(val) ? getLabel(val) : val}
        </Option>
      ))
    : data.map((el) => (
        <Option key={el.value} value={el.value}>
          {getLabel && getLabel(el) ? getLabel(el) : el.label}
        </Option>
      ));

  return (
    <Select
      ref={ref}
      disabled={disabled ? disabled : null}
      style={{
        width: width ? width : '100%',
        ...props.style,
      }}
      defaultValue={defaultValue}
      placeholder={placeholder}
      allowClear={allowClear}
      onChange={onChange}
      onSearch={onSearch}
      showSearch={showSearch || !!onSearch}
      onFocus={onFocus}
      onBlur={onBlur}
      defaultActiveFirstOption={false}
      filterOption={false}
      value={value}
      autoFocus={autoFocus}
      size='middle'
      autoClearSearchValue
    >
      {options}
    </Select>
  );
};

export const AutoComplete = ({
  placeholder = '',
  fieldValue,
  fieldLabel,
  onChange,
  size = 'middle',
  width,
  value,
  allowClear = false,
  getLabel,
  multiple = false,
  style,
  asyncApi,
  formatData,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const options = data.map((el) => (
    <Option key={el.id} value={fieldValue ? el[fieldValue] : el.id}>
      {getLabel?.(el) || (fieldLabel && el[fieldLabel]) || el.name}
    </Option>
  ));

  const getData = (Query) => {
    setLoading(true);
    return asyncApi({ Query })
      .then((res) => {
        if (formatData) {
          return setData(formatData(res));
        }
        if (Array.isArray(res.data)) {
          return setData(res.data);
        }
        if (res.data?.data && Array.isArray(res.data.data)) {
          return setData(res.data.data);
        }
      })
      .catch((err) => console.error('asyncApi Error', err))
      .finally(() => setLoading(false));
  };

  useEffect(() => getData(), []);

  const newStyle = {
    width: width ? width : '100%',
    ...style,
  };
  const handleSearch = useCallback(
    debounce((text) => getData(text), 300),
    []
  );

  return (
    <Select
      style={newStyle}
      mode={multiple && 'multiple'}
      placeholder={placeholder}
      allowClear={allowClear}
      onChange={onChange}
      onSearch={handleSearch}
      showSearch={true}
      filterOption={false}
      value={value}
      autoClearSearchValue
      loading={loading}
      size={size}
    >
      {options}
    </Select>
  );
};
