import React, { useMemo, useState } from 'react';
import { Button, Badge, DatePicker, Space } from 'antd';
import { RangeDateFilter } from '~components';
import { DATE_FORMAT } from '~commons/constants';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import ApiServices from '~services';

const Header = ({
  expensesIdSelected,
  showModal,
  filter,
  setFilter,
  dissolveDateDefault,
  setDissolveDateDefault,
  clearDataWhenSearch,
}) => {
  const [loadingExport, setLoadingExport] = useState(false);

  const handleExportExcel = async (query) => {
    if (!query || loadingExport) return;

    const dateFrom = moment(query?.DateFrom).format('DD/MM/YYYY');
    const dateTo = moment(query?.DateTo).format('DD/MM/YYYY');

    const dataSend = {
      DateFrom: query?.DateFrom && moment(query?.DateFrom).format('YYYY-MM-DD'),
      DateTo: query?.DateTo && moment(query?.DateTo).format('YYYY-MM-DD'),
    };
    setLoadingExport(true);
    try {
      const res = await ApiServices.exportTicketJobAllCars(dataSend);
      const data = res?.data;
      if (data) {
        const outputFilename = `Danh_sach_cong_viec_giai_tru_theo_xe_tu_ngay_${dateFrom}_den_ngay_${dateTo}.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.TmsNoti.error('Export thất bại!');
      }
    } catch (err) {
      window.TmsNoti.error('Export thất bại!');
    } finally {
      setLoadingExport(false);
    }
  };

  const isSubmit = useMemo(() => {
    return !expensesIdSelected?.length;
  }, [expensesIdSelected]);

  const handleChangeDate = (date) => {
    console.log({ date });
    setDissolveDateDefault && setDissolveDateDefault(date);
  };
  const handleChangeFilter = async (data) => {
    await clearDataWhenSearch();
    await setFilter(data);
  };

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <div
        style={{
          flex: '1',
        }}
      >
        <Space>
          <RangeDateFilter query={filter} onChange={handleChangeFilter} allowClear={false} />
          <Button
            type='primary'
            icon={<DownloadOutlined />}
            style={{ padding: '0px 20px', marginLeft: '10px' }}
            loading={loadingExport}
            onClick={() => handleExportExcel(filter)}
          >
            Export Excel
          </Button>
        </Space>
      </div>
      <div style={{ marginRight: '10px' }}>
        SetUp Ngày GT:{' '}
        <DatePicker
          value={dissolveDateDefault}
          onChange={handleChangeDate}
          placeholder='Ngày hợp đồng'
          style={{ width: '120px' }}
          format={DATE_FORMAT}
          allowClear={false}
        />
      </div>
      {/* <TableTitle>
          Ngày hợp đồng
          <DatePicker
            value={contractDate}
            onChange={handleChangeContractDate}
            placeholder='Ngày hợp đồng'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle> */}
      <Badge
        count={expensesIdSelected?.length || 0}
        style={{
          marginRight: '70px',
        }}
      >
        <Button
          style={{
            marginRight: '70px',
          }}
          type='primary'
          disabled={isSubmit}
          onClick={showModal}
        >
          Tạo phiếu
        </Button>
      </Badge>
    </div>
  );
};

export { Header };
