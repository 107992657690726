import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { Input, Space, DatePicker } from 'antd';
import { TableLayout } from '~components';
// import { RangeDateFilter } from '~components';
import ApiServices from '~services';
import { TableTitle } from '~commons/styles';
import Utils from '~commons/utils';
import { DATE_FORMAT } from '~commons/constants';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
};

export const EditContractHistory = ({ dataRow }) => {
  const [loading, setLoading] = useState(false);
  const [editContractHistoryList, setEditContractHistoryList] = useState([]);
  const [selected, setSelected] = useState();
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [editDate, setEditDate] = useState(null);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };
    return ApiServices.getHistoryEditContract(dataRow?.contractId, newQuery)
      .then((res) => {
        if (res?.data) {
          setEditContractHistoryList(res?.data);
        }
      })
      .catch((err) => {
        console.error('Get Edit Contract History Error: ', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns };
      }
      Columns[name] = value;
      return { ...prev, Columns };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const handleEditContractDate = (date) => {
    setEditDate(date);
    const dataSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    onColumnSearch(dataSearch, 'createdOn');
  };

  const renderContent = (value) => {
    return Utils.convertToPlain(value);
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Người chỉnh sửa
          <Input name='createdBy' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'createdBy',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Thời gian chỉnh sửa
          <DatePicker
            value={editDate}
            onChange={handleEditContractDate}
            placeholder='Ngày chứng từ'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
          {/* <Input name='createdOn' onChange={onColumnSearch} placeholder='Search' /> */}
        </TableTitle>
      ),
      dataIndex: 'createdOn',
      align: 'center',
      width: 150,
      render: (val) => Utils.formatDateTime(val),
    },
    {
      title: (
        <TableTitle>
          Nội dung
          <Input name='content' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'content',
      width: 300,
      render: renderContent,
    },
  ];
  const onCloseEditTab = () => setSelected();

  const pagination = {
    total: editContractHistoryList?.totalRecords || 0,
    showSizeChanger: false,
    pageSize: filter.PageSize,
    onChange: (PageNumber) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
      })),
  };

  const headerContent = (
    <Space>{/* <RangeDateFilter query={filter} onChange={setFilter} /> */}</Space>
  );

  return (
    <TableLayout
      rowKey='createdOn'
      columns={columns}
      data={editContractHistoryList?.data}
      loading={loading}
      titleTab={selected?.jobName}
      headerContent={headerContent}
      closeDrawer={onCloseEditTab}
      selected={selected}
      pagination={pagination}
      checkDisableSelection={(record) => moment(record?.createdOn) < moment().subtract(3, 'days')}
    />
  );
};
