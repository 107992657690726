import React, { useEffect, useState } from 'react';
import { Form, Button, message, Input, InputNumber, DatePicker } from 'antd';
import { layout, tailLayout } from './constant';
import ApiServices from '~services';
import { DATE_FORMAT } from '~commons/constants';
import { Selection } from '~components';
import moment from 'moment';

const CarReplacementsHistoryForm = ({ fields, isNew, reload, onChange, setLoading }) => {
  const [carList, setCarList] = useState([]);
  const [replacementCategoryList, setReplacementCategoryList] = useState([]);

  const getCar = async (text) => {
    const dataSend = JSON.stringify({ code: text ? text : '' });

    setLoading(true);
    return ApiServices.getCars({ Columns: dataSend })
      .then((res) => {
        if (res?.data?.data) {
          setCarList(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => setLoading(false));
  };

  const getReplacementCategory = async () => {
    setLoading(true);
    return ApiServices.getReplacementCategories()
      .then((res) => {
        if (res?.data) {
          setReplacementCategoryList(res?.data);
        }
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getCar();
    getReplacementCategory();
  }, []);

  const handleCreate = async (data) => {
    const dataSend = {
      carId: data?.carId,
      categoryId: data?.categoryId,
      rDate: data?.rDate ? moment(data?.rDate).format('YYYY-MM-DD') : null,
      erDate: data?.erDate ? moment(data?.erDate).format('YYYY-MM-DD') : null,
      note: data?.note,
    };
    try {
      const res = await ApiServices.createCarReplacement(dataSend);
      if (res?.data?.data) {
        message.success('Thêm mới Lịch sử bảo dưỡng thành công');
        return reload();
      } else {
        message.error('Thêm mới Lịch sử bảo dưỡng thất baị, vui lòng kiểm tra lại thông tin');
      }
    } catch (err) {
      message.error('Thêm mới Lịch sử bảo dưỡng thất baị, vui lòng kiểm tra lại thông tin');
      console.error(err);
    }
  };

  const onSubmitForm = async (value) => {
    if (isNew) {
      setLoading(true);
      await handleCreate(value);
      setLoading(false);
      return;
    }
  };
  return (
    <Form
      {...layout}
      name='global_state'
      className='form-input-data'
      onFinish={onSubmitForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item
        name='rDate'
        label='Ngày thay'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn ngày thay',
          },
        ]}
      >
        <DatePicker placeholder='Ngày thay' style={{ width: '100%' }} format={DATE_FORMAT} />
      </Form.Item>
      <Form.Item
        name='erDate'
        label='Ngày thay dự kiến'
        rules={[
          {
            required: true,
            message: 'Bạn phải chọn ngày thay dự kiến',
          },
        ]}
      >
        <DatePicker
          placeholder='Ngày thay dự kiến'
          style={{ width: '100%' }}
          format={DATE_FORMAT}
        />
      </Form.Item>
      {isNew && (
        <>
          <Form.Item
            name='carId'
            label='Số Xe'
            rules={[
              {
                required: true,
                message: 'Bạn phải chọn số xe',
              },
            ]}
          >
            <Selection
              data={carList}
              // labelDefault={rowData?.driverSubName}
              fieldValue='code'
              placeholder='Số Xe'
              onSearch={getCar}
            />
          </Form.Item>
          <Form.Item
            name='categoryId'
            label='Hạng Mục'
            rules={[
              {
                required: true,
                message: 'Bạn phải chọn Hạng mục thay thế',
              },
            ]}
          >
            <Selection data={replacementCategoryList} fieldValue='name' placeholder='Hạng Mục' />
          </Form.Item>
        </>
      )}

      {!isNew && (
        <>
          <Form.Item
            name='carCode'
            label='Số xe'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập số xe',
              },
            ]}
          >
            <Input placeholder='Vui lòng nhập số xe' />
          </Form.Item>

          <Form.Item
            name='categoryName'
            label='Hạng mục'
            rules={[
              {
                required: true,
                message: 'Bạn phải nhập hạng mục',
              },
            ]}
          >
            <Input placeholder='Vui lòng nhập hạng mục' />
          </Form.Item>
        </>
      )}
      {!isNew && (
        <Form.Item
          style={{ marginTop: '10px' }}
          name='oldEKm'
          label='Số Km'
          rules={[
            {
              required: true,
              message: 'Bạn phải nhập Số Km',
            },
            {
              type: 'number',
              min: 1,
              message: 'Số Km phải lớn hơn 0',
            },
          ]}
        >
          <InputNumber min={1} style={{ width: '100%' }} placeholder='Vui lòng nhập Số Km' />
        </Form.Item>
      )}
      <Form.Item name='note' label='Ghi chú'>
        <Input.TextArea placeholder='Vui lòng nhập ghi chú' />
      </Form.Item>
      {isNew && (
        <Form.Item {...tailLayout} className='submit-form-input-data'>
          <Button type='primary' htmlType='submit'>
            Thêm Mới
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export { CarReplacementsHistoryForm };
