import { Radio, Row, Col } from 'antd';
import storage from '~storage';
const TypeBooking = ({ setType, type }) => {
  const orderType = storage.staticVariables.OrderType;

  const orderTypeDiv = (
    <Radio.Group onChange={(e) => setType(e.target.value)} value={type}>
      {Object.keys(orderType).map((item) => (
        <Radio key={`Radio-order-type ${item}`} value={orderType[item]}>
          {orderType[item]}
        </Radio>
      ))}
    </Radio.Group>
  );

  return (
    <Col>
      <Row style={{ textAlign: 'right', marginTop: 5 }}>Chọn loại đơn hàng:</Row>
      <br />
      <Row span={24} style={{ justifyContent: 'center', display: 'flex' }}>
        {orderTypeDiv}
      </Row>
    </Col>
  );
};
export { TypeBooking };
