import { Button, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import Utils from '~commons/utils';

export const UploadFileNew = ({ onChange, value }) => {
  const [fileList, setFileList] = useState([]);

  // console.log('UploadFile', { value, fileList });

  const formatData = (val) => {
    return val?.map((file) => {
      if (file?.url || !file?.urlImage) return file;
      const url = Utils.getAppUrl() + file.urlImage.substring(2);
      return {
        ...file,
        url,
        fileName: `${file.name}${file.extension}`,
        name: `${
          file?.name?.length > 70
            ? file?.name?.slice(0, 60) +
              '...' +
              file?.name?.slice(file?.name?.length - 10, file?.name?.length)
            : file?.name
        }${file.extension}`,
      };
    });
  };

  useEffect(() => {
    value && setFileList(formatData(value));
  }, [value]);

  const beforeUpload = (file) => {
    setFileList([...fileList, file]);

    return false;
  };
  const handleChange = ({ fileList }) => {
    onChange?.(fileList);
  };
  const onRemove = (file) => {
    setFileList((prev) => {
      const curList = [...prev];
      const index = curList?.length && curList.indexOf(file);
      const newFileList = (curList?.length && curList.slice()) || [];
      curList?.length && newFileList.splice(index, 1);

      onchange?.(newFileList);
      return newFileList;
    });
  };

  return (
    <>
      <Upload
        multiple
        onChange={handleChange}
        beforeUpload={beforeUpload}
        fileList={fileList}
        onRemove={onRemove}
        // onPreview={handlePreview}
      >
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
    </>
  );
};
