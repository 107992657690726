import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchWaitingOfPlan,
  loadMore,
  updateQuery,
  updateLoading,
  updateSelected,
  updateSelectedRowKey,
} from '~store/reducers/waiting-of-plan/action';
import { showListFile } from '~store/reducers/white-board/action';
import { TableWhiteBoard, WBTableLoadMore } from '~components';
import { getColumns } from './columns';
import { Wrapper, Title } from '~commons/styles';
import { useWBTableScroll } from '~hooks';
import useApiHook from '../wb-hook/api-hook';
import { FileDoneOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Utils from '~commons/utils';
import { JobsTransferWbPlan } from '../job-transfer-button-wb-plan';

const WIDTH = '450px';
export const WaitingOfPlan = () => {
  const dispatch = useDispatch();
  const { planedContHandle, updateContNote, showCustomerDetail, reloadWaitingOfPlan } =
    useApiHook();
  const timeOutRef = useRef(null);
  const timeOutSetQueryRef = useRef(null);

  const {
    data: waitingOfPlanData,
    query,
    loading,
    selected,
    selectedRows,
  } = useSelector((state) => state.waitingOfPlan);

  //================= Scroll ======================
  const [loadingMore, setLoadingMore] = useState(false);
  const [partnerSelect, setPartner] = useState();
  const [searchDate, setSearchDate] = useState(null);
  const { tableRef, isScroll } = useWBTableScroll(null);
  const [loadingExePlan, setLoadingExePlan] = useState(false);

  const setLoading = (val) => dispatch(updateLoading(val));

  const LoadMoreData = async () => {
    if (loadingMore) return;
    if (waitingOfPlanData.pageNumber * query.PageSize <= waitingOfPlanData.totalRecords) {
      let tmpQuery = { ...query, PageNumber: waitingOfPlanData.pageNumber + 1 };
      setLoadingMore(true);
      await dispatch(loadMore(tmpQuery));
      setLoadingMore(false);
    }
  };
  useEffect(() => {
    if (isScroll) {
      LoadMoreData();
    }
  }, [isScroll]);

  const initData = async () => {
    dispatch(updateLoading(true));
    await dispatch(fetchWaitingOfPlan(query));
    dispatch(updateLoading(false));
  };
  useEffect(() => {
    timeOutRef.current && clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => initData(), 50);
  }, [query]);
  const setQuery = (columns) => {
    const tmpQuery = { ...query };
    tmpQuery.Columns = { ...tmpQuery.Columns, ...columns };

    clearTimeout(timeOutSetQueryRef.current);
    timeOutSetQueryRef.current = setTimeout(() => dispatch(updateQuery(tmpQuery)), 250);
  };

  const onChangeTransfer = (partner) => {
    if (!partner) {
      dispatch(updateSelected([]));
      dispatch(updateSelectedRowKey([]));
    }
    setPartner(partner);
  };

  const onSelectChange = (selectedRowKey, selectedRows) => {
    if (partnerSelect) {
      dispatch(updateSelected(selectedRowKey));
      dispatch(updateSelectedRowKey(selectedRows));
      return;
    }
    dispatch(updateSelectedRowKey(selectedRows));
    dispatch(updateSelected(selectedRowKey));
  };
  const onShowFile = (record) => {
    dispatch(showListFile(record));
  };
  const renderRowClassName = (record) =>
    record.orderType + Utils.renderTypeOrderBackground(record?.orderType);

  const handleChangeSearchDate = (dateValue) => {
    setSearchDate(dateValue);
    const dataSearch = dateValue ? moment(dateValue).format('DD/MM') : '';
    setQuery({ allDate: dataSearch });
  };

  const handlePlanedContHandle = async (dataToPlan) => {
    if (loadingExePlan) return;
    await setLoadingExePlan(true);
    await planedContHandle(dataToPlan);
    await setLoadingExePlan(false);
  };

  return (
    <Wrapper width={WIDTH}>
      <Title>
        Cont Chờ kế hoạch <b>({waitingOfPlanData?.totalRecords || 0})</b>
        <Button
          type='primary'
          className='mg-l-10'
          shape='round'
          icon={<FileDoneOutlined />}
          disabled={!selected?.length || partnerSelect}
          loading={loading}
          onClick={() => handlePlanedContHandle(selected)}
        >
          Lập Kế hoạch
        </Button>
      </Title>
      <TableWhiteBoard
        tableRef={tableRef}
        headerLength='190px'
        rowKey='id'
        columns={getColumns(
          setQuery,
          handlePlanedContHandle,
          onShowFile,
          updateContNote,
          showCustomerDetail,
          searchDate,
          handleChangeSearchDate,
          partnerSelect
        )}
        rowClassName={renderRowClassName}
        data={waitingOfPlanData?.data}
        loading={loading}
        onSelectChange={onSelectChange}
        selectedParent={selected}
        // disableSelectAll={!partnerSelect}
      />
      <WBTableLoadMore loading={loadingMore} />
      <JobsTransferWbPlan
        onChange={onChangeTransfer}
        selected={selected}
        selectedRows={selectedRows}
        updateLoading={setLoading}
        reloadFinish={reloadWaitingOfPlan}
      />
    </Wrapper>
  );
};
