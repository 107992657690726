import React, { useEffect, useState } from 'react';
import { Button, InputNumber, Row, Col, DatePicker } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { DATE_TIME_FORMAT } from '~commons/constants';
import styled from 'styled-components';
//DownloadOutlined
const Item = ({ data, onChange, onRemove, onAdd, index, totalRow, placeholder }) => {
  const [total, setTotal] = useState(0);
  const [date, setDate] = useState(null);
  //   useEffect(() => {
  //     setTotal(data.total);
  //     setDate(data.date);
  //   }, [data.date, data.total]);
  useEffect(() => {
    onChange && onChange(index, { total, date, id: data.id });
  }, [date, total]);
  return (
    <>
      <Col span={4}>
        <InputNumber
          style={{ width: '100%' }}
          placeholder='số lượng Cont'
          min={0}
          value={total}
          onChange={(val) => setTotal(val || 0)}
        />
      </Col>
      <Col span={10}>
        <DatePicker
          disabled={total === 0}
          showTime={{ format: 'HH:mm' }}
          placeholder={placeholder}
          style={{ width: '100%' }}
          format={DATE_TIME_FORMAT}
          value={date}
          onChange={setDate}
        />
      </Col>
      <Col span={10}>
        {index == totalRow - 1 ? (
          <Button
            color='green'
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => onAdd && onAdd(data.id)}
            disabled={total === 0}
          >
            Thêm
          </Button>
        ) : (
          <Button
            color='green'
            type='primary'
            danger
            icon={<DeleteOutlined />}
            onClick={() => onRemove && onRemove(data.id, index)}
            disabled={total === 0}
          >
            Xóa
          </Button>
        )}
      </Col>
    </>
  );
};
export const ItemInput = ({ data, onChange, datePlaceholder }) => {
  const [values, setValues] = useState(data.values);
  useEffect(() => {
    setValues(data.values);
  }, [data]);

  const onChangeData = (index, nData) => {
    setValues((prev) => {
      let newData = [...prev];
      newData[index] = {
        ...newData[index],
        ...nData,
      };
      onChange && onChange(data.id, newData);
      return newData;
    });
  };
  const onAndData = (id) => {
    setValues((prev) => {
      let newData = [...prev];
      newData.push({
        total: 0,
        date: null,
        id,
      });
      onChange && onChange(data.id, newData);
      return newData;
    });
  };
  const onRemoveData = (id, index) => {
    setValues((prev) => {
      let newData = [...prev];
      newData.splice(index, 1);
      onChange && onChange(id, newData);
      return newData;
    });
  };

  return (
    <RowWrapper gutter={12} key={data.id}>
      <Col span={4} style={{ justifyContent: 'center', display: 'flex' }}>
        Cont {data.name}
      </Col>
      <Col span={20}>
        {values?.map((el, index) => (
          <Row key={data.id + index} gutter={8} style={{ margin: '4px 0' }}>
            <Item
              data={el}
              index={index}
              onChange={onChangeData}
              onRemove={onRemoveData}
              onAdd={onAndData}
              totalRow={values?.length}
              placeholder={datePlaceholder}
            />
          </Row>
        ))}
      </Col>
    </RowWrapper>
  );
};
const RowWrapper = styled(Row)`
  display: flex;
  align-items: center;
  border: 1px solid #e8e8e8;
`;
