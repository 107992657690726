import React, { useState, useEffect, useRef } from 'react';
import { Input, Table } from 'antd';
import moment from 'moment';
import { ORDER_STATUS } from '~commons/constants';
import { Wrapper } from '~commons/styles';
import { RangeDateFilter } from '~components';
import { OrderTypeSelect } from '~components/common-select';
import Utils from '~commons/utils';
import ApiServices from '~services';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 0,
  Text: '',
  Columns: '',
  DateFrom: moment().startOf('date').format(),
  DateTo: moment().endOf('date').format(),
};
export const OrdersCancel = () => {
  const filterRef = useRef();
  const [loading, setLoading] = useState(false);
  const [ordersCancelList, setOrdersCancelList] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [pagination, setPagination] = useState({
    total: 1,
    showSizeChanger: false,
    pageSize: 20,
    onChange: (value) => {
      let filterTemp = filter;
      filterTemp.PageNumber = value;
      setFilter(filterTemp);
      // searchCarsByColumn();
      getOrders();
    },
  });

  const getOrders = async (currentFilter) => {
    setLoading(true);
    const filterFormatted = { ...currentFilter };
    if (filterFormatted?.Columns && Object.keys(filterFormatted?.Columns).length) {
      const columnsSearch = {};
      for (const key of Object.keys(filterFormatted?.Columns)) {
        (filterFormatted.Columns[key] === 0 || filterFormatted.Columns[key]) &&
          (columnsSearch[key] = filterFormatted.Columns[key]);
      }
      filterFormatted.Columns = JSON.stringify(columnsSearch);
    } else {
      delete filterFormatted?.Columns;
    }
    filterFormatted.Columns = JSON.stringify({ status: ORDER_STATUS.CANCELED });

    ApiServices.getOrders(filterFormatted)
      .then((res) => {
        setPagination((prev) => ({
          ...prev,
          total: res.data?.totalRecords,
        }));
        setOrdersCancelList(res.data?.data || []);
      })
      .catch((err) => {
        console.error('Get Orders Error', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    clearTimeout(filterRef.current);
    filterRef.current = setTimeout(() => {
      getOrders(filter);
    }, 500);
    return () => clearTimeout(filterRef.current);
  }, [filter]);
  //----------- Start ----- Pagination Search ---------
  const searchByColumn = filter?.Columns;
  const handleSearchColumnFn = (val, name) => {
    setFilter((prev) => ({ ...prev, Columns: { ...prev?.Columns, [name]: val } }));
  };
  const handleInputSearchColumn = (e) => {
    const { value, name } = e.target;
    handleSearchColumnFn(value, name);
  };

  const columns = [
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Đơn Hàng</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn?.code}
            name='code'
            onChange={handleInputSearchColumn}
          />
        </span>
      ),
      dataIndex: 'code',
      width: 160,
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Khách Hàng</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn?.customerName}
            name='customerName'
            onChange={handleInputSearchColumn}
          />
        </span>
      ),
      dataIndex: 'customerName',
      width: 120,
    },
    {
      title: 'SL Cont',
      dataIndex: 'arrIdCont',
      width: 70,
      render: (value) => value?.length,
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Loại ĐH</div>
          <OrderTypeSelect
            value={searchByColumn?.orderType}
            onChange={handleInputSearchColumn}
            name='orderType'
          />
        </span>
      ),
      dataIndex: 'orderType',
      width: 130,
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Nguồn</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn?.source}
            name='source'
            onChange={handleInputSearchColumn}
          />
        </span>
      ),
      dataIndex: 'source',
      width: 120,
    },
    {
      title: (
        <span style={{ textAlign: 'center' }}>
          <div>Hãng Tàu</div>
          <Input
            placeholder='Search'
            allowClear
            value={searchByColumn?.shippingLineName}
            name='shippingLineName'
            onChange={handleInputSearchColumn}
          />
        </span>
      ),
      dataIndex: 'shippingLineName',
      width: 120,
    },
    {
      title: 'Cắt SI',
      dataIndex: 'book_SIDateTime',
      width: 100,
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'Cắt Máng',
      dataIndex: 'book_CutOffDateTime',
      width: 100,
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'HH Lưu Cont',
      dataIndex: 'bill_LastFreeDayDEM',
      width: 120,
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'HH Trả Rỗng',
      dataIndex: 'bill_LastFreeDayDET',
      width: 120,
      render: (value) => Utils.formatDateFn(value),
    },
    {
      title: 'Ghi Chú',
      dataIndex: 'note',
      width: 250,
      render: (value) => <span>{value === 'null' ? '' : value}</span>,
    },
  ];

  return (
    <Wrapper>
      <div style={{ display: 'flex', marginBottom: '10px' }}>
        <h3>Đơn hàng hủy trong ngày: </h3>
        <RangeDateFilter query={filter} onChange={setFilter} />
        <br />
      </div>

      <Table
        columns={columns}
        dataSource={ordersCancelList}
        rowKey='createdOn'
        bordered
        loading={loading}
        scroll={{ x: '100%', y: '100%' }}
        pagination={pagination}
      />
    </Wrapper>
  );
};
