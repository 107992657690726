import React from 'react';
import { Modal, message } from 'antd';
import ApiServices from '~services';
import storage from '~storage';
import { BookingForm } from '../../create-booking/form-input';
import { formatBookingData } from '../../create-booking/api-helper';
import { BillForm } from '../../create-bill/form-input';
import { formatBillData } from '../../create-bill/api-helper';
import { TransitForm } from '../../create-transit/form-input';
import { formatTransitData } from '../../create-transit/api-helper';
import FieldData from './fields-data';
import formatData from './format-data';
import { updateOrder } from './api-helper';
import { ShowListContDetail } from './list-cont';

export const UpdateInformation = ({ orderId = null, showDetail = false, onClose, reload }) => {
  const formRef = React.useRef();
  const [orderDetail, setOrderDetail] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const initValue = FieldData({
    transportType: storage?.staticVariables?.TransportType?.TransportType_FCL,
  });
  const [fields, setFields] = React.useState(initValue);
  const isOpenModal = Boolean(orderId);
  const getOrderById = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const { data } = await ApiServices.getOrderDetailsId(orderId);
      setFields(formatData(data));

      if (data) {
        setOrderDetail(data);
      }
    } catch (error) {
      console.log(error);
      message.warn('Đơn Hàng không tồn tại vui lòng thử lại');
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    orderId && getOrderById();
  }, [orderId]);

  const renderForm = () => {
    switch (orderDetail?.orderType) {
      case storage?.staticVariables?.OrderType?.Order_Bill:
        return (
          <BillForm
            orderDetail={orderDetail}
            showDetail={showDetail}
            fields={fields}
            onChange={(newFields) => {
              setFields(newFields);
            }}
            ref={formRef}
            onsubmit={onSubmitData}
          />
        );
      case storage?.staticVariables?.OrderType?.Order_Transit:
        return (
          <TransitForm
            orderDetail={orderDetail}
            showDetail={showDetail}
            fields={fields}
            onChange={(newFields) => {
              setFields(newFields);
            }}
            ref={formRef}
            onsubmit={onSubmitData}
          />
        );

      default:
        return (
          <BookingForm
            orderDetail={orderDetail}
            showDetail={showDetail}
            fields={fields}
            onChange={(newFields) => {
              setFields(newFields);
            }}
            ref={formRef}
            onsubmit={onSubmitData}
          />
        );
    }
  };

  const renderListCont = (dataDetail) => {
    console.log({ dataDetail });
    return (
      <>
        <h4>Danh Sách Chi Tiết Cont</h4>
        <ShowListContDetail
          dataInfoDetail={dataDetail}
          dataListContDetail={dataDetail?.listCont || []}
        />
      </>
    );
  };

  const onSubmitData = async () => {
    let dataUpdate = null;
    switch (orderDetail?.orderType) {
      case storage?.staticVariables?.OrderType?.Order_Bill:
        dataUpdate = formatBillData(fields, null);
        break;

      case storage?.staticVariables?.OrderType?.Order_Transit:
        dataUpdate = formatTransitData(fields, null);
        break;
      default:
        dataUpdate = formatBookingData(fields, null);
        break;
    }
    setLoading(true);
    return updateOrder(dataUpdate, orderDetail)
      .then(({ data }) => {
        if (data) {
          message.success('Cập nhật thông tin thành công');
          onClose?.();
          reload?.();
        }
      })
      .catch((error) => {
        console.log(error);
        message.warn('Cập nhật thông tin thất bại');
      })
      .finally(() => setLoading(false));
  };
  const onSubmit = () => {
    formRef?.current.submitForm();
  };
  if (showDetail) {
    return (
      <Modal
        title='Xem chi tiết Đơn hàng'
        open={isOpenModal}
        onCancel={() => onClose && onClose()}
        // onOk={onSubmit}
        confirmLoading={loading}
        footer={null}
        style={{
          top: 20,
        }}
        width='80%'
      >
        {renderForm()}
        {orderDetail && renderListCont(orderDetail)}
      </Modal>
    );
  } else {
    return (
      <Modal
        title='Sửa thông tin Đơn hàng'
        open={isOpenModal}
        onCancel={() => onClose && onClose()}
        onOk={onSubmit}
        confirmLoading={loading}
        style={{
          top: 20,
        }}
        width='80%'
      >
        {renderForm()}
      </Modal>
    );
  }
};
