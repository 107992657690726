import React from 'react';
import { TransitFeeMain } from './transit-fee-main';

const TransitFee = ({
  contTypeList,
  dataFeeMainTransit,
  setDataFeeMainTransit,
  locationSelected,
  reloadAfterUpdate,
  // contractStatus,
  isNew,
  valueDetail = null,
}) => {
  return (
    <>
      <TransitFeeMain
        contTypeList={contTypeList}
        dataFeeMainTransit={dataFeeMainTransit}
        setDataFeeMainTransit={setDataFeeMainTransit}
        locationSelected={locationSelected}
        reloadAfterUpdate={reloadAfterUpdate}
        // contractStatus={contractStatus}
        isNew={isNew}
        valueDetail={valueDetail}
      />
    </>
  );
};

export { TransitFee };
