import React, { useRef, useEffect, useState } from 'react';
import { TableLayout } from '~components';
import { DriverForm } from './vendor-form';
import DriverFields from './fields-data';
import { Input, Button, message } from 'antd';
import { getColumns } from './columns';
// import { VenderCustomerManagerment } from './vender-customer-managerment';

import ApiServices from '~services';
const Vendors = () => {
  const defaultTabPanels = [
    {
      title: 'Detail',
      content: 'detail content',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selected, setSelected] = useState('');
  const [vendors, setVendors] = useState([]);
  const [tabPanels, setTabPanels] = useState(defaultTabPanels);
  const [titleTab, setTitleTab] = useState('');
  const [fields, setFields] = useState({});
  console.log(fields);
  //-------------------------------------------------------------------
  const [searchTerm, setSearchTerm] = useState('');
  const typingTimeoutref = useRef(null);
  //-------------------------------------------------------------------
  const [searchByColumn, setSearchByColumn] = useState({
    code: '',
    name: '',
    salary: '',
    typeDriver: '',
    phone: '',
    email: '',
    idCard: '',
  });

  const getVendors = async () => {
    let dataFilterSend = { ...filter };
    if (Object.keys(dataFilterSend.Columns).length === 0) {
      delete dataFilterSend['Columns'];
    } else {
      const columnJSONSTRING = JSON.stringify(dataFilterSend.Columns);
      dataFilterSend.Columns = columnJSONSTRING;
    }

    try {
      setLoading(true);
      let { data } = await ApiServices.getVendors(dataFilterSend);
      setLoading(false);

      let pag = pagination;
      pag.total = data?.totalRecords || 0;
      pag.pageSize = data?.pageSize;
      pag.current = data?.pageNumber;

      data = data?.data || [];
      setVendors(data);
      setPagination(pag);
    } catch (error) {
      console.log('Error - get cars', error);
      console.error(error);
    }
  };

  useEffect(() => {
    getVendors();
  }, []);

  const onChangePagination = (page, size) => {
    let filterTemp = filter;
    filterTemp.PageNumber = page;
    filterTemp.PageSize = size;
    setFilter(filterTemp);
    getVendors();
  };
  const [pagination, setPagination] = useState({
    total: 1,
    showSizeChanger: true,
    onChange: onChangePagination,
  });
  const [filter, setFilter] = useState({
    PageNumber: 1,
    PageSize: 10,
    Text: '',
    Columns: '',
  });

  const handleSearchTermChange = (e) => {
    const val = e.target.value;
    setSearchTerm(val);
    const filterTmp = filter;
    filterTmp.Text = val;

    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }

    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 2) {
        filter.PageNumber = 1;
        setFilter({ ...filterTmp, PageNumber: 1 });
        getVendors();
      }
      if (val.length === 0) {
        setFilter({ ...filterTmp, PageNumber: 1 });
        getVendors();
      }
    }, 1000);
  };
  // ------------ Search Column ----------------------
  const handleSearchColumnFn = (event, name) => {
    const filterTmp = filter;
    const val = event.target.value;
    let _searchByColumn = { ...searchByColumn };
    _searchByColumn[`${name}`] = val;
    setSearchByColumn(_searchByColumn);
    // Clear timeout
    if (typingTimeoutref.current) {
      clearTimeout(typingTimeoutref.current);
    }
    typingTimeoutref.current = setTimeout(() => {
      if (val.length > 0) {
        filter.PageNumber = 1;
        let searchColumnFn = { ...filter.Columns };
        searchColumnFn[`${name}`] = val;
        filter.Columns = searchColumnFn;

        setFilter({ ...filterTmp, PageNumber: 1 });
        getVendors();
      }
      if (val.length === 0) {
        delete filter.Columns[`${name}`];
        setFilter({ ...filterTmp, PageNumber: 1 });
        getVendors();
      }
    }, 1000);
  };
  // ------------- End Search --------------------

  const setFieldsForm = async (value) => {
    const driverFields = await DriverFields(value);
    await setFields(driverFields);
    return driverFields;
  };
  const reload = async () => {
    setSelected(null);
    await getVendors();
  };

  const getUpdatedDriverForm = async (value, isNew = false, id = null, dataSelected) => {
    const driverForm = await (
      <DriverForm
        fields={value}
        setLoading={setIsLoadingTab}
        isNew={isNew}
        id={id}
        reload={reload}
        onChange={setFields}
        dataSelected={dataSelected}
      />
    );

    // const customerManagermentForm = await (
    //   <VenderCustomerManagerment idVendor={id} selectedVendor={selected} />
    // );

    if (isNew) {
      return setTabPanels([
        {
          title: 'Thông tin chi tiết',
          content: driverForm,
        },
      ]);
    } else {
      return setTabPanels([
        {
          title: 'Thông tin chi tiết',
          content: driverForm,
        },
        // {
        //   title: 'Quản lý khách hàng',
        //   content: customerManagermentForm,
        // },
      ]);
    }
  };

  const onRowSelected = async (value) => {
    await setIsLoadingTab(true);
    await setTitleTab(value.code);
    await setFieldsForm(value)
      .then((res) => {
        return getUpdatedDriverForm(res, false, value.id, value);
      })
      .then(() => setSelected(value));
    setIsLoadingTab(false);
  };
  const deleteVendor = async (record) => {
    try {
      const res = await ApiServices.deleteVendor(record.id);
      if (res.data) {
        message.success('Xoá Nhà Xe thành công');
        return reload();
      }
      message.error('Xoá Nhà Xe thất bại, vui lòng thử lại sau!');
    } catch (error) {
      console.log(error);
    }
  };

  const createDriver = async () => {
    await setTitleTab('');
    await setFieldsForm({})
      .then((res) => getUpdatedDriverForm(res, true))
      .then(() => setSelected({ isNew: true }));
    await setIsLoadingTab(false);
  };

  const headerContent = (
    <>
      <Button type='primary' onClick={createDriver}>
        Thêm Mới
      </Button>
      <Input.Search
        style={{ width: '200px' }}
        className='mg-l-15'
        placeholder='Search'
        value={searchTerm}
        onChange={handleSearchTermChange}
      />
    </>
  );
  return (
    <TableLayout
      rowKey='id'
      width={600}
      columns={getColumns(onRowSelected, searchByColumn, handleSearchColumnFn, deleteVendor)}
      data={vendors}
      loading={loading}
      selected={selected}
      closeDrawer={() => setSelected(null)}
      isLoadingTab={isLoadingTab}
      headerContent={headerContent}
      titleTab={titleTab}
      tabPanels={tabPanels}
      pagination={pagination}
      isDisplayCheckbox={false}
    />
  );
};

export { Vendors };
